import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import { colors } from "../../../constants/colors";
import { Col } from "react-bootstrap";
import styleds from 'styled-components'

export const Container = styled('div')(({theme}) => ({
  height: '100vh',
  overflow: 'auto',
  width: '100%'
}))

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: '100vh',
}));

export const TableContainer = styled(Box)(({theme}) => ({
    marginTop: 24,
    
}))

export const ButtonInTable = styled(Button)(({theme}) => ({
  backgroundColor: colors.green,
  borderRadius: 4,
  color: colors.white
}))

export const BgInputZone = styled(Box)(({theme}) => ({
  backgroundColor: colors.lightGrayBG,
  width: '100%',
  padding: '24px 63px',
  [theme.breakpoints.down('xs')] : {
    padding: 24
  }
}))

export const Gridhidden = styled(Col)(({theme}) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

export const Styles = styleds.div`
  padding: 1px;

  table {
    border-spacing: 0;
    
    th,
    td {
      margin: 0;
      padding: 1px;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  } 
`