import { menuSeconds } from "../../constants/menu"
import ReportRentStall from "../../pages/stall/receive-stall/reportRentStall"

const routeReportRentStall = [
  {
    ...menuSeconds.REPORT_RENT_STALL,
    key: menuSeconds.REPORT_RENT_STALL.key,
    name: menuSeconds.REPORT_RENT_STALL.name,
    textName: menuSeconds.REPORT_RENT_STALL.textName,
    active: false,
    appBar: true,
    component: ReportRentStall
  },
]

export default routeReportRentStall