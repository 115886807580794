import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";

/** API, UTIL */
import { numberFormat } from "../../../../utils/common";
import { dateToView } from "../../../../utils/date.utils";
import LeasesTypeApi from "../../../../api/setting/leases/leases.api";

/** COMPONENT */
import TableRowCommon from "../../../../component/table/TableRowCommon";
import TableCustom from "../../../../component/table/tableCustom";
import InputTextField from "../../../../component/input/inputTextField";
import ButtonCustom from "../../../../component/button/buttonCustom";
import Map from "../../../../component/marketMap/map";
import FilterSelect from "../../../../component/select/filterSelect";

/** STYLE, CONSTANT */
import * as UseStyle from "../marketMap.style";
import { colors } from "../../../../constants/colors";
import { routeName } from "../../../../routes/routers-name";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "../../../../app/slice/modal.slice";
import Stall from "../../../../assets/icon/stall";
import Arrowleft from "../../../../assets/icon/arrow_left";
import Arrowright from "../../../../assets/icon/arrow_right";
import Arrowup from "../../../../assets/icon/arrow_up";
import Arrowdown from "../../../../assets/icon/arrow_down";
import ZoneApi from "../../../../api/zone/zone.api";
import Loading from "../../../../component/loading/loading";
import { InputCheckBox } from "../../../announce/announceStyle";
import LeaseAgreementApi from "../../../../api/stall/leaseAgreement/leaseAgreement.api";
import { decode } from "js-base64";
import { getProfile } from "../../../../utils/app.utils";
import BidderApi from "../../../../api/stall/bidder/bidder.api";
import DeleteIcon from '@mui/icons-material/Delete';


export default function CustomerNew() {
  const history = useHistory();
  const location: any = useLocation();
  const round = location?.state?.round
  const announceId = location?.state?.announceId;
  const profile: any = JSON.parse(getProfile() || '{}')

  const { t } = useTranslation();
  const [stallData, setStallData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [leasesType, setLeasesType] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [bidderAmount, setBidderAmount] = useState<string>();
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [zone, setZone] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false)
  const [leasesAgreementLength, setLeasesAgreementLength] = useState<number>(Number);

  const dispatch = useDispatch();
  // console.log(round.filter(((e: any) => {e.stall.status == 'OPEN' })))
  //OPEN

  const handleSelect = async (x: any, zoneName: string) => {

    // if (round.map((data: any) => data?.stall.stallId).includes(x?.stallId)) {
    if (x.status !== "CONTRACT" && dataTable.length < 4) {
      setZone({ ...x, zoneName: zoneName });
      dispatch(showModal());
    }
    // }

    // if (round !== undefined) {
    //   if (round.map((data: any) => data?.stall.stallId).includes(x?.stallId)) {
    //     if (!dataTable.includes(x) && x.status == "OPEN" && dataTable < 4) {
    //       setZone(Object.assign(x, { zoneName: zoneName }));
    //       dispatch(showModal());
    //     }
    //   }
    // }
    // else {
    //   if (!dataTable.includes(x)) {
    //     setZone(Object.assign(x, { zoneName: zoneName }));
    //     dispatch(showModal());
    //   }
    // }
  }


  useEffect(() => {
    const handleGetLeasesAgreement = async () => {
      let condition: any = {
        page: 1,
        limit: 10,
        // userId: JSON.parse(decode(profile))?.userId || 0,
        // status: "ACTIVE"
      }
      await BidderApi.findAll(condition)
        .then(res => {
          if (res.status === 200) {
            setLeasesAgreementLength(res.data?.length || 0)
          }
        })
    }
    handleGetLeasesAgreement()
  }, [])


  const handleDelete = async (x: any) => {
    if (dataTable.includes(x)) {
      setDataTable(dataTable.filter((item: any) => item.stallId !== x.stallId))
    }
  };
  const handlesubmit = () => {
    const data = { ...zone, bidderAmount: bidderAmount || 0 };
    if (data) {
      setDataTable([...dataTable, data]);
      dispatch(closeModal());
      setBidderAmount("")
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true)
    let condition: any = {
      pageLimit: 4000,
    };
    if (search) condition = { ...condition, search: search };
    if (leasesTypeId) condition = { ...condition, leasesTypeId: leasesTypeId };

    const res = await ZoneApi.findAll(condition);

    if (res.status === 200) {
      setStallData(res.data);
      setLoading(false)
    } else if (res.status === 400) {
      setStallData([]);
      setLoading(false)
    }
  }, [search, leasesTypeId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const loadFilter = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      sortBy: "modifiedDate",
      sortType: "ASC",
      status: "ACTIVE",
    };

    const res = await LeasesTypeApi.findAll(condition);
    if (res.status === 200) {
      setLeasesType(res.data);
    }
  }, []);

  useEffect(() => {
    loadFilter();
  }, [loadFilter]);

  const headCellsRental = [
    {
      id: "no",
      label: '#',
      align: "center",
    },
    {
      id: "no",
      label: t('MARKET_MAP.HEADCELL.ZONE_H'),
      align: "center",
    },
    {
      id: "no",
      label: t('MARKET_MAP.HEADCELL.STALL_H'),
      align: "center",
    },
    {
      id: "no",
      label: t('MARKET_MAP.HEADCELL.TYPE_STALL'),
      align: "center",
    },
    {
      id: "no",
      label: t('MARKET_MAP.HEADCELL.START_TO_END_DATE'),
      align: "center",
    },
    {
      id: "no",
      label: t('MARKET_MAP.HEADCELL.ACTION'),
      align: "center",
    },
  ];

  const checkedRound = (stallId: number): boolean => {
    let validateChecked: boolean = false;
    if (round) {
      let validateRounded = dataTable?.some((list: any) => list?.stallId === stallId)
      return validateRounded
    }
    return validateChecked;
  }

  const renderDataRental = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no;
    const {
      bidderDate,
      zone,
      stallName,
      bidderAmount,
      stallType,
      status,
      stallId
    } = objData;


    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no + 1,
        },
        {
          option: "TEXT",
          align: "center",
          label: zone?.zoneName,
        },
        {
          option: "TEXT",
          align: "center",
          label: stallName,
        },
        {
          option: "TEXT",
          align: "center",
          label: stallType?.stallTypeNameTh,
        },
        {
          option: "TEXT",
          align: "center",
          // label: dateToView(round[0].startDate) + "-" + dateToView(round[0].endDate),
          label: dateToView(round[no].startDate) + "-" + dateToView(round[no].endDate),
        },
        {
          option: "TEXT",
          align: "center",
          width: 50,
          label: (
            <>
              {!checkedRound(stallId) && dataTable.length < 3 && status == 'OPEN' && (
                <p style={{
                  color: colors.themeSecondColor,
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }} onClick={() =>
                  handleSelect(objData, zone?.zoneName)}>
                  {status !== "CONTRACT" ? "ยื่นประสงค์เช่าแผงค้า" : "มีแผงค้าแล้ว"}
                </p>
              ) || (
                  <p style={{
                    color: colors.red
                  }}>
                    {'เต็ม'}
                  </p>
                )}
              {/* <button type="button" onClick={()=>console.log(objData)} >Checked</button> */}
            </>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const headCells = [
    {
      id: "no",
      label: t("MARKET_MAP.HEADCELL.LIST"),
      align: "left",
      colSpan: 5,
    },
    {
      id: "no",
      label: t("MARKET_MAP.HEADCELL.SELECT") + " " + dataTable.length + " " + t("MARKET_MAP.HEADCELL.UNIT"),
      align: "center",
      colSpan: 2,
    },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no;
    const {
      bidderDate,
      zoneName,
      stallName,
      bidderAmount,
      stallType,
    } = objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        {
          option: "COMPONENT",
          align: "center",
          component: <UseStyle.NumberInTable>{no + 1}</UseStyle.NumberInTable>,

          width: 80,
        },
        {
          option: "TEXT",
          align: "center",
          label: t("MARKET_MAP.TABLE.ZONE") + zoneName,
        },
        {
          option: "TEXT",
          align: "center",
          label: t("MARKET_MAP.TABLE.STALL") + stallName,
        },
        {
          option: "TEXT",
          align: "center",
          label: t("MARKET_MAP.TABLE.TYPE_STALL") + stallType?.stallTypeNameTh,
        },
        {
          option: "TEXT",
          align: "center",
          label:
            t("MARKET_MAP.TABLE.BIDDERAMOUNT") +
            " " +
            ":" +
            " " +
            numberFormat(bidderAmount),
        },
        {
          option: "TEXT",
          align: "center",
          label: t("MARKET_MAP.TABLE.EXPIRE_DATE") + dateToView(bidderDate),
        },
        {
          option: "COMPONENT",
          align: "center",
          width: 50,
          component: (
            <Tooltip title="ลบรายการ">
              <IconButton onClick={() => handleDelete(objData)}>
                <DeleteIcon color='error' />
                {/* <FontAwesomeIcon icon={faTrashAlt} color="red" /> */}
              </IconButton>
            </Tooltip>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  return (
    <UseStyle.MainContainer>
      <Card className="border-0">
        <Card.Body className="overflow-auto mb-3">
          <div className="text-center py-3 " style={{ fontSize: "24px", fontWeight: "500" }}>
            {t("MARKET_MAP.CUSTOMER_TITLE")}
          </div>
          <div className="px-5">
            <TableCustom
              headCells={headCellsRental}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={"ASC"}
              sortBy=""
              rowCount={1}
              rowsData={round && round.map((value: any) => value.stall)?.map((d: any, i: any) => {
                return renderDataRental(d, i);
              })}
              onSort={() => { }}
              setPageLimit={(value: any) => { setPageLimit(value) }}
              setPage={(value: any) => { setPage(value) }}
            // tableMinWidth={1000}
            />
          </div>
          {dataTable?.length > 0 &&
            <>
              <UseStyle.SliderContainer  className="px-5">
                <TableCustom
                  headCells={headCells}
                  customScroll
                  page={1}
                  pageLimit={1}
                  sortType={"ASC"}
                  sortBy=""
                  rowCount={1}
                  rowsData={dataTable && dataTable?.map((d: any, i: any) => {
                    return renderData(d, i);
                  })}
                  onSort={() => { }}
                  setPageLimit={() => { }}
                  setPage={() => { }}
                  hidePagination
                // tableMinWidth={1000}
                />
              </UseStyle.SliderContainer>

              <Box className="my-5 px-5">
                <ButtonCustom
                  variant="contained"
                  onClick={() => {
                    history.push(routeName.marketMap + "/submit-stall", {
                      params: {
                        dataTable: dataTable,
                        round: round,
                        announceId: announceId
                      },
                    })
                    setDataTable([])
                  }
                  }
                  disabled={dataTable.length === 0 ? true : false}
                  textButton={t("MARKET_MAP.BUTTON.SUBMIT")}
                  btnStyle={{
                    fontSize: "16px",
                    width: "100%",
                    padding: "8px 25px",
                  }}
                />
              </Box>
            </>}
        </Card.Body>
      </Card>
      <ModalCustom
        title="ระบบเปิดให้จองแผง"
        size="lg"
        closeButton
        component={
          <UseStyle.Boxconteiner>
            <UseStyle.Boxrow>
              <UseStyle.Boxcolumn>
                <UseStyle.Boxrowal>
                  {" "}
                  <Arrowleft />{" "}
                  <Typography sx={{ margin: "0 15px" }}>
                    {zone && Math.floor(zone?.realWidth)} ม
                  </Typography>
                  <Arrowright />
                </UseStyle.Boxrowal>

                <Stall />
              </UseStyle.Boxcolumn>
              <UseStyle.Boxflexcolumn>
                <Arrowup />
                <Typography>
                  {zone && Math.floor(zone?.realHeight)} ม
                </Typography>
                <Arrowdown />
              </UseStyle.Boxflexcolumn>
              <UseStyle.Boxflexcolumn>
                <Row>
                  <Col lg={6} className="my-2">
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      {t("MARKET_MAP.HEADCELL.UNIT")} :
                    </Typography>
                  </Col>

                  <Col lg={6} className="my-2">
                    {" "}
                    <Typography>{zone?.stallName}</Typography>
                  </Col>

                  <Col lg={6} className="my-2">
                    {" "}
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      {t("MARKET_MAP.TABLE.ZONE")}
                    </Typography>
                  </Col>

                  <Col lg={6} className="my-2">
                    {" "}
                    <Typography>{zone?.zoneName}</Typography>
                  </Col>

                  <Col lg={6} className="my-2">
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                      {t("MARKET_MAP.HEADCELL.ZONE")} :
                    </Typography>
                  </Col>
                  <Col lg={6} className="my-2">
                    <Typography>{zone?.stallType?.stallTypeNameTh}</Typography>
                  </Col>
                </Row>
              </UseStyle.Boxflexcolumn>
            </UseStyle.Boxrow>
            {/* {zone?.status === "CONTRACT" && Number(round?.filter((e: any) => e?.stall?.stallId === zone.stallId)?.map((value: any) => value.stall.stallId)) === zone.stallId && */}
            <UseStyle.Boxfiled>
              {/* <Row
                style={{
                  width: "100%",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                }}
              >
                <Col lg={1}></Col>
                <Col lg={3}>
                  <Typography className="mt-2">{t("MARKET_MAP.TABLE.BIDDERAMOUNT")}</Typography>
                </Col>
                <Col lg={5}>
                  <InputTextField
                    key="bidderAmount"
                    type="number"
                    onchange={(e) => setBidderAmount(e.target.value)}
                    value={bidderAmount}
                    inputProps={{ inputProps: { min: 0 } }}
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}

                  />
                </Col>
                <Col lg={3} className="mt-2">{t("MARKET_MAP.TABLE.BATH")}</Col>
              </Row> */}
            </UseStyle.Boxfiled>
            {/* } */}
            {/* {zone?.status !== "CONTRACT" && Number(round?.filter((e: any) => e?.stall?.stallId === zone.stallId)?.map((value: any) => value.stall.stallId)) === zone.stallId &&  */}
            <UseStyle.Boxjustify>
              <ButtonCustom
                onClick={handlesubmit}
                // disabled={!bidderAmount}
                textButton={t("MARKET_MAP.TABLE.SUBMITMODAL")}
                style={{ marginTop: "20px" }}
              />
            </UseStyle.Boxjustify>
            {/* } */}
          </UseStyle.Boxconteiner>
        }
      />
      {loading && <Loading show={loading} type="fullLoading" />}

    </UseStyle.MainContainer>
  );
}
