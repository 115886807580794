/** STYLE */
import { icons, logo } from "../../constants/images";
import * as Style from "./style";
import Store from "../../assets/slide-menu.tsx/store";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import ButtonCustom from "../../component/button/buttonCustom";
import { useEffect, useRef, useState } from "react";
import BranchApi from "../../api/setting/branch/branch.api";
import { colors } from "../../constants/colors";
import { setBranch } from "../../utils/app.utils";
import { faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { routeName } from "../../routes/routers-name";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SelectBranches() {
  const { t } = useTranslation();
  const sliderRef: any = useRef(null);

  const [branchData, setBranchData] = useState<any[]>([])
  const [select, setSelect] = useState<any>()

  const settingsSlick = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    arrows: false,
    initialSlide: 0,
    afterChange: (e: any) => console.log(e, "pp"),
    beforeChange: (e: any) => console.log(e, "pp2"),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3
        }
      },
      // {
      //   breakpoint: 768,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 2
      //   }
      // },
      // {
      //   breakpoint: 576,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1
      //   }
      // },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    
  }
  const loadBranchData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50
    }

    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setBranchData(res.data)
    } else {
      setBranchData([])
    }
  }

  useEffect(() => {
    loadBranchData()
  }, []);

  return (
    <>
      <Style.SelectbranchContainer>
        <div className="d-flex justify-content-start px-5 flex-sm-row flex-column w-100">
          <img src={logo.OTK_Logo} alt="" width={250} height={100} />
          <img src={logo.management_system} alt="" />
        </div>
        <Style.SelectbranchBox>
          <Style.Slicker>
            <FontAwesomeIcon
              style={{ zIndex: 20, cursor: 'pointer' }}
              icon={faAngleDoubleLeft}
              color={colors.themeMainColor}
              size="xl"
              onClick={() => sliderRef?.current?.slickPrev()}

            />
            <Slider {...settingsSlick} ref={sliderRef} className="w-100">
              {branchData.map((d: any) => {
                return (

                  <Style.Cardstill

                  >
                    <Style.CardBranch
                      onClick={() => {
                        setSelect(d.branchId)
                        setBranch(d.branchId)
                      }}
                      style={{
                        backgroundColor: select === d.branchId ? colors.themeMainColor : "",
                        color: select === d.branchId ? colors.white : "",
                        margin: '0px 8px'
                      }}
                    >
                      <div className="d-flex justify-content-center pt-4">
                        <Store
                        //  color={select === d.branchId ? colors.white : ""}
                          />
                      </div>

                      <Typography sx={{ marginTop: "40px", width: '100%', textAlign: 'center' }}>
                        {d.branchNameTh}
                      </Typography>
                    </Style.CardBranch>
                  </Style.Cardstill>

                )
              })}

            </Slider>
            <FontAwesomeIcon
              style={{ zIndex: 20, cursor: 'pointer' }}
              icon={faAngleDoubleRight}
              size="xl"
              color={colors.themeMainColor}
              onClick={() => sliderRef?.current?.slickNext()} />
          </Style.Slicker>
          <ButtonCustom
            textButton={t("BUTTON.ACCESS")}
            style={{ width: "100%", marginTop: 2 }}
            onClick={() => {
              window.location.href = routeName.home;
            }}
            disabled={select ? false : true}
          />
        </Style.SelectbranchBox>
      </Style.SelectbranchContainer>
    </>
  );
}
