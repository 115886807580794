import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'cancel-lease-agreement'

export interface CancelContractInterface {
    branchId: number
    leaseAgreementId: number
    status: string
    refId: number
    fileType: string

}
export default class CancelContractApi extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(branchId: number, leaseAgreementId: number) {
        return this.api.post(path, { branchId, leaseAgreementId })
    }

    static approve(approveDate: string, cancelLeaseAgreementId: number, remark: string, noDoc: string, reason: string, employeeId: number) {
        return this.api.post(`${path}/approve`, { approveDate, cancelLeaseAgreementId, remark, noDoc, reason, employeeId })
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: CancelContractInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status })
    }

    static delete(id: number): Promise<any> {
        return this.api.delete(`${path}/${id}`)
    }
}
