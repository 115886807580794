import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import { HiDocumentRemove } from 'react-icons/hi'
import { PiCertificateFill } from 'react-icons/pi'
/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import { useDispatch } from "react-redux";
import PopupReqCertificate from "../../certificate/popupRequestCertificate";
import PopupReqCancelContract from "../../cancelContract/popupRequestCancelContract";
import Loading from "../../../component/loading/loading";
import ButtonCustom from "../../../component/button/buttonCustom";
import { ExportProps } from "../../../constants/reportConstant";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { notiSuccess } from "../../../component/notification/notifications";
import FunctionPrint, { useFunctionPrint, } from "../../../component/Print/print";
import PopupViewDetail from "./popup/popupViewDetail";
import PopupViewDetailCustomer from "./popup/popupViewDetailCustomer";
/** STYLE, CONSTANT */
import * as Style from "./stallAgreement.style";
import { colors } from "../../../constants/colors";
import { icons, imgs, logo } from "../../../constants/images";

/** API, UTIL */
import AppointmentApi from "../../../api/stall/appointment/appointment";
import LeaseAgreementApi from "../../../api/stall/leaseAgreement/leaseAgreement.api";
import CertificateAPI from "../../../api/stall/certificate/certificate";
import CancelContractApi from "../../../api/stall/cancelcontract/cancelcontract";
import { isCreate, isDelete, isUpdate, isView, } from "../../../utils/permission.utils";
import { closeModal, resetModal, showModal, submitModal, unSubmitModal, } from "../../../app/slice/modal.slice";
import { dateTimeToView, dateToView, fullDate, } from "../../../utils/date.utils";
import { getProfile } from "../../../utils/app.utils";
import FilterSelect from "../../../component/select/filterSelect";
import { routeName } from "../../../routes/routers-name";
import { decode } from 'js-base64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFile, faFilePen, faFileZipper, faPrint } from "@fortawesome/free-solid-svg-icons";
import InvoiceApi from "../../../api/invoid/invoid.api";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";
import PopupAgreementCreate from "./popup/popupAgreementCreate";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

const initStateErrorMessage = {};

export default function StallAgreement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const componentRef = useRef<HTMLDivElement>(null);
  const profile: any = JSON.parse(getProfile() || "{}");

  const [popupReqCer, setPopupReqCer] = useState<boolean>(false);
  const [renew, setRenew] = useState<boolean>(false);
  const [checkAccept, setCheckAccept] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId);
  const [reqUserId, setReqUserId] = useState<number>(JSON.parse(decode(profile))?.userId);
  const [leaseData, getLeaseData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('DESC')
  const [rowCount, setRowCount] = useState<number>()
  const [popupCancelContract, setPopupCancelContract] = useState<boolean>(false);
  const [appointmentId, setAppointmentId] = useState<number>();
  const [reqFirstname, setReqFirstname] = useState<string>("");
  const [reqLastname, setReqLastname] = useState<string>("");
  const [zoneName, setZoneName] = useState<string>("");
  const [stallName, setStallName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [stallTypeName, setStallTypeName] = useState<string>("");
  const [typePrint, setTypePrint] = useState<string>("")
  const [leaseAgreementNo, setLeaseAgreementNo] = useState<string>("");
  const [printAppointment, setPrintAppointment] = useState<any[]>([])
  const [printSelectAdmin, setPrintSelectAdmin] = useState<any[]>([])
  const [printCustomerSelect, setPrintCustomerSelect] = useState<any[]>([])
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("ALL")
  const [dataModal, setDatamodal] = useState<any>()
  const [modalType, setModalType] = useState<string>("")
  const [invoiceData, setInvoiceData] = useState<any[]>([])

  const [leaseAgreementDateStart, setLeaseAgreementDateStart] =
    useState<string>("");
  const [leaseAgreementDateEnd, setLeaseAgreementDateEnd] =
    useState<string>("");
  const [leaseAgreementId, setLeaseAgreementId] = useState(Number);
  const [branchId, setBranchId] = useState(Number)

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const loadDataAppointment = useCallback(async () => {
    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: JSON.parse(decode(profile))?.userId,
    };
    if (search !== "") condition = { ...condition, search: search };
    if (status !== 'ALL') condition = { ...condition, status: status };
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    let conditionAdmin: any = {
      page: page,
      pageLimit: pageLimit,
      sortBy: "appointmentId"
    };
    if (search !== "") conditionAdmin = { ...conditionAdmin, search: search };
    if (sortType) conditionAdmin = { ...conditionAdmin, sortType: sortType }
    if (status !== 'ALL') conditionAdmin = { ...conditionAdmin, status: 'APPROVE' };
    if (roleId) {
      if (roleId === 4) {
        const res = await LeaseAgreementApi.findAll(condition);
        if (res.status === 200) {

          setRowCount(res.headers['total'])
          getLeaseData(res.data);
        } else {
          getLeaseData([]);
        }
      } else {
        const res = await AppointmentApi.findAll(conditionAdmin);
        if (res.status === 200) {

          setRowCount(res.headers['total'])
          getLeaseData(res.data);
        } else {
          getLeaseData([]);
        }
      }
    }
  }, [page, pageLimit, search, status, sortBy, sortType,]);

  useEffect(() => {
    loadDataAppointment();
  }, [page, pageLimit, search, status, sortBy, sortType,]);

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
  }, [])

  const Adminselect = useCallback(async (prop) => {
    try {
      const condition: any = {
        page: page,
        pageLimit: pageLimit,
      };
      setAppointmentId(prop)
      setTypePrint("ADMIN_SELECT")
      setLoading(true);

      if (roleId !== 4) {
        const appointment = await AppointmentApi.findAll(condition)

        const [testResult] = await Promise.all([
          appointment,
        ]);

        setPrintSelectAdmin(testResult.data);
      }
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [AppointmentApi])

  const handlePrintAdminselect = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printSelectAdmin && typePrint === "ADMIN_SELECT") {
      handlePrintAdminselect();
    }
  }, [printSelectAdmin])

  const dataAppointment = useCallback(async () => {
    try {
      const condition: any = {
        page: page,
        pageLimit: pageLimit,

      };
      setTypePrint("APPOINTMENT")
      setLoading(true);

      const appointment = await AppointmentApi.findAll(condition)

      const [testResult] = await Promise.all([
        appointment,
      ]);

      setPrintAppointment(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [AppointmentApi])

  const handlePrintdataAppointment = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printAppointment && typePrint === "APPOINTMENT") {
      handlePrintdataAppointment();
    }
  }, [printAppointment])

  const customerSelect = useCallback(async (id: any) => {
    try {
      const condition: any = {
        page: page,
        pageLimit: pageLimit,
        userId: JSON.parse(decode(profile))?.userId,
      };
      setTypePrint("CUSTOMER_SELECT")
      setAppointmentId(id)
      setLoading(true);

      const appointment = await LeaseAgreementApi.findAll(condition)

      const [testResult] = await Promise.all([
        appointment,
      ]);
      setPrintCustomerSelect(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [AppointmentApi])

  const handlePrintcustomerSelect = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printCustomerSelect && typePrint === "CUSTOMER_SELECT") {
      handlePrintcustomerSelect();
    }
  }, [printCustomerSelect])

  const checkInvoice = async (data: any) => {
    const condition: any = {
      page: 1,
      pageLimit: 10,
      userId: JSON.parse(decode(profile))?.userId,
      zoneId: data?.leaseAgreementApprove?.bidder?.zoneId,
      stallId: data?.leaseAgreementApprove?.bidder?.stallId,
      status: "NOT_PAY"
    }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      setInvoiceData(res.data)
    } else {
      setInvoiceData([])
    }
  }

  const downloadFile = (file: any) => {
    file?.map((item: any) => {
      const url = process.env.REACT_APP_FILE_URL + item?.filePath + item?.fileName
      // const blob = new Blob([file], { type: file.mimetype });
      window.open(url, "_blank");
    })

  }
  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      label: t("STALL_AGREEMENT.HEADER.TIME_DATE"),
      align: "center",
    },
    {
      id: "transferNo",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.DOC_NUMBER"),
    },
    // {
    //   id: "name",
    //   disablePadding: false,
    //   align: "left",
    //   label: t("STALL_AGREEMENT.HEADER.NAME_RENTER"),
    // },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ZONE"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.STALL"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("เลขเเผงค้า"),
    },
    {
      id: "zoneType",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ZONE_TYPE"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.APPOINTMENT_DATE"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.START_DATE"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.EXPIRE_DATE"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.STATUS"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ACTION"),
    },
  ];

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.DOC_DATE"),
    },
    {
      id: "documentNumber",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.DOC_NUMBER"),
    },
    {
      id: "name",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.NAME_RENTER"),
    },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ZONE"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.STALL"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("เลขเเผงค้า"),
    },
    {
      id: "zoneType",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ZONE_TYPE"),
    },
    {
      id: "appointmentDate",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.APPOINTMENT_DATE"),
      // sortable: true
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.START_DATE"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.EXPIRE_DATE"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.STATUS"),
    },
    {
      id: "recorder",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.RECORDER"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ACTION"),
    },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      createDate,
      firstname,
      lastname,
      leaseAgreementApprove,
      status,
      leaseAgreementNo,
      leaseAgreement,
      leaseAgreementDateStart,
      leaseAgreementDateEnd
    } = objData;

    const setStatus =
      status === "ACTIVE"
        ? t("STALL_AGREEMENT.STATUS.REBRAL")
        : status === "INACTIVE"
          ? t("STALL_AGREEMENT.STATUS.INACTIVE")
          : status === "TRANSFER"
            ? t("STALL_AGREEMENT.STATUS.TRANSFER")
            : status === "CANCEL"
              ? t("STALL_AGREEMENT.STATUS.CANCEL")
              : ''

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(createDate),
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementNo || "-"
        },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: firstname + " " + lastname || "-",
        // },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.zoneName,
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stallName,
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stall?.stallNumber,
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stallTypeName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreement?.leaseAgreementDateEnd) || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreementDateStart),
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreementDateEnd),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              sx={{
                color:
                  status === "INACTIVE"
                    ? colors.red
                    : status === "ACTIVE"
                      ? colors.green
                      : colors.red,
                textAlign: 'center'
              }}
            >
              {setStatus}
            </Typography>
            // <Button
            //   sx={{
            //     backgroundColor:
            //       status === "INACTIVE"
            //         ? colors.red
            //         : status === "ACTIVE"
            //           ? colors.green
            //           : colors.red,
            //     color: colors.white,
            //     margin: "auto",
            //     width: '100%',
            //     ':hover': {
            //       backgroundColor: `${status === "INACTIVE"
            //         ? colors.red
            //         : status === "ACTIVE"
            //           ? colors.green
            //           : colors.red} !important`,
            //     }

            //   }}
            // >
            //   {setStatus}
            // </Button>
          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton onClick={() => {
                  downloadFile(objData.fileUpload)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  dispatch(showModal())
                  setModalType("VIEW_CUSTOMER")
                  setDatamodal(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    downloadFile(objData.fileUpload)
                    // customerSelect(objData.appointmentId)
                  }}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    dispatch(showModal())
                    setModalType("VIEW_CUSTOMER")
                    setDatamodal(objData)
                  }}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => history.push(routeName.stallAgreement + "/agreement", {
                    params: {
                      state: objData,
                    },
                  })}
                />
              </div> */}
              {/* <div className="pr-3">
                <PiCertificateFill
                  style={{
                    fontSize: 20,
                    marginBottom: 3,
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    checkInvoice(objData)
                    onRequestCertificate(objRenderData.obj)
                    setModalType('')
                  }}
                />
              </div> */}
              {/* <div className="pr-3">
                <HiDocumentRemove
                  style={{
                    fontSize: 20,
                    marginBottom: 3,
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    checkInvoice(objData)
                    onRequestCancelContract(objRenderData.obj)}}
                />
              </div> */}

            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      createDate,
      leaseAgreement,
      customer,
      bidder,
      modifiedBy,
      status,
      modifiedDate,
      appointmentDate,
    } = objData;

    const setStatus =
      status === "ACTIVE" && leaseAgreement !== null
        ? t("STALL_AGREEMENT.STATUS.REBRAL")
        : status === "ACTIVE" ?
          t("STALL_AGREEMENT.STATUS.CONTRACT")
          : status === "INACTIVE"
            ? t("STALL_AGREEMENT.STATUS.INACTIVE")
            : status === "TRANSFER"
              ? t("STALL_AGREEMENT.STATUS.TRANSFER")
              : status === "CANCEL"
                ? t("STALL_AGREEMENT.STATUS.CANCEL")
                : ''

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(createDate) || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementNo || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: customer?.firstname + " " + customer?.lastname || "-",
        },
        { option: "TEXT", align: "center", label: bidder?.zoneName || "-" },
        { option: "TEXT", align: "center", label: bidder?.stallName || "-" },
        { option: "TEXT", align: "center", label: bidder?.stall?.stallNumber || "-" },
        {
          option: "TEXT", align: "center",
          label: bidder?.stallTypeName || "-"
        },
        {
          option: "TEXT",
          align: "center",
          label:
            dateToView(appointmentDate) ||
            "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: (leaseAgreement !== null &&
            dateToView(leaseAgreement?.leaseAgreementDateStart)) ||
            "-",
        },
        {
          option: "TEXT",
          align: "center",
          label:
            (leaseAgreement !== null &&
              dateToView(leaseAgreement?.leaseAgreementDateEnd)) ||
            "-",
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                color:
                  status === "ACTIVE" && leaseAgreement !== null ? colors.green
                    : colors.yellow,
                textAlign: 'center'
              }}
            >
              {setStatus}
            </Typography>
            // <Button
            //   sx={{
            //     whiteSpace:'nowrap',
            //     backgroundColor:
            //       status === "ACTIVE" && leaseAgreement !== null ? colors.green
            //         : colors.yellow,
            //     width: '100%',
            //     color: colors.white,
            //     margin: "auto",
            //     ':hover': {
            //       backgroundColor: `${status === "ACTIVE" && leaseAgreement !== null ? colors.green
            //         : colors.yellow} !important`,
            //     }
            //   }}
            // >
            //   {setStatus}
            // </Button>
          ),
        },
        {
          option: "UPDATE_BY",
          align: "center",
          label: {
            updatedBy:
              (modifiedBy?.firstname + " " + modifiedBy?.lastname) ||
              "-",
            updatedAt: modifiedDate,
          },
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton onClick={() => {
                  if (leaseAgreement.fileUpload?.length > 0) {
                    downloadFile(leaseAgreement.fileUpload)
                  } else {
                    setPrintSelectAdmin([objData])
                    setTypePrint("ADMIN_SELECT")
                  }

                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {status === "ACTIVE" && leaseAgreement === null && <Tooltip title="นัดหมาย">
                <IconButton onClick={() => history.push(routeName.stallAgreement + "/agreement", {
                  params: {
                    state: objData,
                  },
                })}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>}
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  dispatch(showModal())
                  setModalType("VIEW")
                  setDatamodal(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>

              {leaseAgreement && 
              // leaseAgreement.fileUpload?.length === 0 &&
                <Tooltip title="แก้ไข">
                  <IconButton onClick={() => {
                    dispatch(showModal())
                    setModalType("CREATE")
                    setDatamodal(objData)
                  }}>
                    <FontAwesomeIcon
                      icon={faFilePen}
                      style={{
                        fontSize: "20px",
                        width: "22px",
                        color: colors.themeMainColor
                      }}
                    />
                  </IconButton>
                </Tooltip>}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    // Adminselect(objData.appointmentId)
                    setPrintSelectAdmin([objData])
                    setTypePrint("ADMIN_SELECT")
                  }
                  }
                />
              </div> */}
              {/* {status === "ACTIVE" && leaseAgreement === null &&
                <div className="pr-3">
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      cursor: "pointer"
                      , color: colors.themeMainColor
                    }}
                    onClick={() => history.push(routeName.stallAgreement + "/agreement", {
                      params: {
                        state: objData,
                      },
                    })}
                  />
                </div>} */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    dispatch(showModal())
                    setModalType("VIEW")
                    setDatamodal(objData)
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon
        {...objRenderData}

        onprint={() => Adminselect(objData.appointmentId)}
        ondocumentdetail={() => {
          history.push(routeName.stallAgreement + "/agreement", {
            params: {
              state: objData,
            },
          });
        }}
      />
    );
  };

  const onRequestCertificate = (data: any) => {
    const { firstname, lastname, leaseAgreementApprove, leaseAgreementDateStart, leaseAgreementDateEnd, leaseAgreementId, branchId, } = data;
    setReqFirstname(firstname);
    setReqLastname(lastname);
    setZoneName(leaseAgreementApprove.bidder.zoneName);
    setStallName(leaseAgreementApprove.bidder.stallName);
    setStallTypeName(leaseAgreementApprove.bidder.stallTypeName);
    setLeaseAgreementDateStart(leaseAgreementDateStart);
    setLeaseAgreementDateEnd(leaseAgreementDateEnd);
    setReqUserId(reqUserId);
    setLeaseAgreementId(leaseAgreementId);
    setBranchId(branchId);
    setPopupReqCer(true);
    setPopupCancelContract(false)
    dispatch(showModal());
  };

  const onRequestCancelContract = (data: any) => {
    const { firstname, lastname, leaseAgreementApprove, leaseAgreementDateStart, leaseAgreementDateEnd, leaseAgreementId, branchId, leaseAgreementNo } = data;
    setReqFirstname(firstname);
    setReqLastname(lastname);
    setZoneName(leaseAgreementApprove.bidder.zoneName);
    setStallName(leaseAgreementApprove.bidder.stallName);
    setStallTypeName(leaseAgreementApprove.bidder.stallTypeName);
    setLeaseAgreementDateStart(leaseAgreementDateStart);
    setLeaseAgreementDateEnd(leaseAgreementDateEnd);
    setReqUserId(reqUserId);
    setLeaseAgreementId(leaseAgreementId);
    setLeaseAgreementNo(leaseAgreementNo)
    setBranchId(branchId);
    setPopupReqCer(false);
    setPopupCancelContract(true)
    dispatch(showModal());
  };

  const onSubmitRequestCertificate = () => {
    dispatch(submitModal());
    CertificateAPI.create(leaseAgreementId, branchId, reqUserId, "WAITING", String(moment(Date.now()).format('YYYY-MM-DD')), String(moment(Date.now()).add(60, 'days').format('YYYY-MM-DD')))
      .then((res: any) => {
        if (res.status === 201) {
          notiSuccess(t("CERTIFICATE.MESSAGE.SUCCESS.CREATE"), "go", "/certificate", null);
          resetForm();
          dispatch(resetModal());
        } else {
          const err = res.response.data;
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CERTIFICATE.MESSAGE.${err.error_description}`), }, });
          dispatch(unSubmitModal());
        }
      })
      .catch((e) => {
        const err = e.response.data;
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CERTIFICATE.MESSAGE.${err.error_description}`), }, });
        dispatch(unSubmitModal());
      });
  };

  const onSubmitRequestCancelContract = () => {

    dispatch(submitModal());
    CancelContractApi.create(branchId, leaseAgreementId)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t("CANCELCONTRACT.MESSAGE.SUCCESS.CREATE"), "go", "/cancel-contract", null);
          resetForm();
          dispatch(resetModal());
        } else {
          const err = res.response.data;
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`), }, });
          dispatch(unSubmitModal());
        }
      })
      .catch((e) => {
        const err = e.response.data;
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`), }, });
        dispatch(unSubmitModal());
      });
  };

  const checkDisableSubmitModal: boolean =
    popupReqCer && invoiceData.length >= 3 ? true :
      popupReqCer && invoiceData.length < 3 ? false :
        popupCancelContract && invoiceData.length >= 3 ? true :
          popupCancelContract && invoiceData.length < 3 ? false : false

  return (
    <Style.Container>
      <HeaderCard text={t("STALL_AGREEMENT.TITLE")} />
      <Style.MainContainer>
        <Box>
          <Row>
            <Col lg={2} md={6} xs={12} className="mt-2">
              <InputTextField
                label="ค้นหา"
                value={search}
                onchange={(e: any) => setSearch(e.target.value)}
              />
            </Col>
            {/* <Col lg={2} md={6} xs={12} className="mt-2">
              <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => {}}
                placeholder={t("STALL_AGREEMENT.FILTER.ZONE.SEARCH")}
                labelId="zone"
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="mt-2">
              <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => {}}
                placeholder={t("STALL_AGREEMENT.FILTER.STALL.SEARCH")}
                labelId="zone"
              />
            </Col> */}
            <Col lg={2} md={6} xs={12} className="mt-2">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setStatus(value)
                  }
                }}
                label={t("STALL_AGREEMENT.FILTER.STATUS")}
                labelId="status"
                selectId="status"
                fullWidth
                value={status}
                renderValue={() => `${t(`STALL_AGREEMENT.STATUS.${status}`)}`}
                options={[
                  <MenuItem key="ALL" value="ALL">
                    {t('STALL_AGREEMENT.STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="1" value="ACTIVE">
                    {t('STALL_AGREEMENT.STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STALL_AGREEMENT.STATUS.INACTIVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="TRANSFER">
                    {t('STALL_AGREEMENT.STATUS.TRANSFER')}
                  </MenuItem>,
                  <MenuItem key="4" value="CANCEL">
                    {t('STALL_AGREEMENT.STATUS.CANCEL')}
                  </MenuItem>
                ]} />
            </Col>
            <Col lg={4} md={6} xs={12} className="mt-2" />

            {roleId !== 4 && (
              <Col lg={2} md={6} xs={12} className="mt-2">
                <ButtonCustom
                  textButton={t("เพิ่มสัญญา")}
                  style={{ width: "100%" }}
                  // endIcon={<img src={icons.print} />}
                  onClick={() => {
                    dispatch(showModal())
                    setModalType("CREATE")
                  }}
                />
              </Col>
            )}
            {roleId !== 4 && (
              <Col lg={2} md={6} xs={12} className="mt-2">
                <ButtonCustom
                  textButton={t("STALL_AGREEMENT.BUTTON.PRINT")}
                  style={{ width: "100%" }}
                  endIcon={<img src={icons.print} />}
                  onClick={() => dataAppointment()}
                />
              </Col>
            )}
          </Row>
        </Box>
        <Style.TableContainer>
          {roleId === 4 && (
            <TableCustom
              headCells={headCells}
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={leaseData.map((d: any, i: any) => {
                return renderData(d, i);
              })}
              onSort={onRequestSort}
              setPageLimit={(value: number) => setPageLimit(value)}
              setPage={(value: number) => setPage(value)}
              tableMinWidth={1700}
            />
          )}
          {roleId !== 4 && (
            <TableCustom
              headCells={headCellsAdmin}
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={
                leaseData &&
                leaseData?.map((d: any, i: any) => {
                  return renderDataAdmin(d, i);
                })
              }
              onSort={onRequestSort}
              setPageLimit={(value: number) => setPageLimit(value)}
              setPage={(value: number) => setPage(value)}
              tableMinWidth={1700}
            />
          )}
        </Style.TableContainer>
      </Style.MainContainer>

      {modalType === "" &&
        <ModalCustom
          title={popupReqCer ? t("CERTIFICATE.TITLE_REQUEST") : t("CANCELCONTRACT.TITLE_VERIFY")}
          size="lg"
          onSubmit={popupReqCer ? onSubmitRequestCertificate : onSubmitRequestCancelContract}
          textBtnConfirm={popupReqCer ? t("CERTIFICATE.BUTTON.APPLY_REQUEST") : t("CANCELCONTRACT.BUTTON.CONFIRM_CANCEL")}
          closeButton
          disabledSubmit={checkDisableSubmitModal}
          component={
            <>
              {renew && (
                <>
                  <Box className="d-flex justify-content-between">
                    <Typography>{t("DEPOSIT.MODAL.TITLE")}</Typography>
                    <Typography>{t("DEPOSIT.MODAL.DOC_NO")}</Typography>
                  </Box>
                  <Divider />
                  <Style.ModalBg>
                    <Box>
                      <Typography>{t("DEPOSIT.MODAL.LIST_RENTER")}</Typography>
                    </Box>
                    <Style.BoxAddImage>
                      <img src={imgs.defaultProfile} />
                    </Style.BoxAddImage>
                  </Style.ModalBg>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <Checkbox
                      value={checkAccept}
                      onChange={() => setCheckAccept(!checkAccept)}
                    />
                    <Typography>{t("DEPOSIT.MODAL.CHECK_ACCEPT")}</Typography>
                  </Box>
                  <Style.ButtonSubmitModal onClick={() => dispatch(closeModal())}>
                    {t("DEPOSIT.MODAL.CHECK_ACCEPT")}
                  </Style.ButtonSubmitModal>
                </>
              )}

              {popupReqCer && (
                <PopupReqCertificate
                  firstname={reqFirstname}
                  lastname={reqLastname}
                  leaseAgreementDateStart={leaseAgreementDateStart}
                  leaseAgreementDateEnd={leaseAgreementDateEnd}
                  zoneName={zoneName}
                  stallName={stallName}
                  stallTypeName={stallTypeName}
                  isShow={popupReqCer}
                  setIsShow={(show: boolean) => setPopupReqCer(show)}
                />
              )}

              {popupCancelContract && (
                <PopupReqCancelContract
                  firstname={reqFirstname}
                  lastname={reqLastname}
                  leaseAgreementNo={leaseAgreementNo}
                  leaseAgreementDateStart={leaseAgreementDateStart}
                  leaseAgreementDateEnd={leaseAgreementDateEnd}
                  zoneName={zoneName}
                  stallName={stallName}
                  stallTypeName={stallTypeName}
                  isShow={popupCancelContract}
                  setIsShow={(show: boolean) => setPopupCancelContract(show)}
                />
              )}


            </>
          }
        />}
      {modalType === "VIEW" && <PopupViewDetail data={dataModal} onClose={() => setDatamodal(null)}/>}
      {modalType === "VIEW_CUSTOMER" && <PopupViewDetailCustomer data={dataModal} />}
      {modalType === "CREATE" && <PopupAgreementCreate data={dataModal} setData={setDatamodal} loadData={loadDataAppointment} onClose={() => setDatamodal(null)}/>}

      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={""}
          componant={
            <>
              {typePrint === "APPOINTMENT" && <Style.Styles className="pt-3">
                {printAppointment && printAppointment.map((d: any) => {
                  return (
                    <>
                      {/* <div className="row d-flex justify-content-center">
                        <img
                          src={imgs.defaultAvatar}
                          alt=""
                          style={{ width: 200, height: 200 }}
                        />
                      </div> */}
                      <div className="row mt-4">
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.PREFIX")}
                          </p>
                          <div className="">
                            {d?.customer?.prefix?.prefixNameTh || "-"}
                          </div>
                        </div>
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.FIRST_NAME")}
                          </p>
                          <div className="">
                            {d?.customer?.firstname || "-"}
                          </div>
                        </div>

                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.LAST_NAME")}
                          </p>
                          <div className="">{d?.customer?.lastname || "-"}</div>
                        </div>

                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.ID_CARD")}
                          </p>
                          <div className="">{d?.customer?.idCard || "-"}</div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.BIRTH_DAY")}
                          </p>
                          <div className="">
                            {fullDate(d?.customer?.birthDay) || "-"}
                          </div>
                        </div>
                        <div className="col-4 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.AGE")}
                          </p>
                          <div className="">
                            {(d?.customer?.birthDay &&
                              moment().diff(d?.customer?.birthDay, "years")) ||
                              "0"}{" "}
                            {t("AGREEMENT.INPUT.YEAR")}
                          </div>
                        </div>
                        <div className="col-4 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.TEL")}
                          </p>
                          <div className="">
                            {d?.customer?.mobilePhone || "-"}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-4 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.EMAIL")}
                          </p>
                          <div className="">{d?.customer?.email || "-"}</div>
                        </div>
                        <div className="col-4 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.TAX_ID")}
                          </p>
                          <div className="">{d?.customer?.taxId || "-"}</div>
                        </div>
                        <div className="col-4 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.ADRESS")}
                          </p>
                          <div className="">
                            {d?.customer?.houseNumber || "-"}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.MOO")}
                          </p>
                          <div className="">{d?.customer?.moo || "-"}</div>
                        </div>
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.VILLAGE")}
                          </p>
                          <div className="">{d?.customer?.village || "-"}</div>
                        </div>
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.SOI")}
                          </p>
                          <div className="">{d?.customer?.soi || "-"}</div>
                        </div>

                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.ROAD")}
                          </p>
                          <div className="">{d?.customer?.road || "-"}</div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.PROVINCE")}
                          </p>
                          <div className="">
                            {d?.customer?.province?.provinceNameTh || "-"}
                          </div>
                        </div>

                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.DISTRICT")}
                          </p>
                          <div className="">
                            {d?.customer?.district?.districtNameTh || "-"}
                          </div>
                        </div>

                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.SUB_DISTRICT")}
                          </p>
                          <div className="">
                            {d?.customer?.subDistrict?.subDistrictNameTh || "-"}
                          </div>
                        </div>

                        <div className="col-3 d-flex flex-column">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.INPUT.ZIPCODE")}
                          </p>
                          <div className="">{d?.customer?.zipCode || "-"}</div>
                        </div>
                      </div>

                      <div className="row mt-5">
                        <p style={{ fontSize: 18, fontWeight: 800 }}>
                          {t("AGREEMENT.DETAIL.HEAD")}
                        </p>
                        <div className="col-6 d-flex flex-row">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.DETAIL.ZONE")}
                          </p>
                          <p>{d?.bidder?.zoneName}</p>
                        </div>
                        <div className="col-6 d-flex flex-row">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.DETAIL.STALL")}
                          </p>
                          <p>{d?.bidder?.stallName}</p>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-6 d-flex flex-row">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.DETAIL.STATRT_DATE")}
                          </p>
                          <p>
                            {fullDate(
                              d?.leaseAgreement?.leaseAgreementDateStart
                            )}
                          </p>
                        </div>
                        <div className="col-6 d-flex flex-row">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.DETAIL.END_DATE")}
                          </p>
                          <p>
                            {fullDate(d?.leaseAgreement?.leaseAgreementDateEnd)}
                          </p>
                        </div>
                      </div>

                      <div className="row mt-2" >
                        <div className="col-12 d-flex flex-row">
                          <p style={{ fontSize: 16, fontWeight: 700 }}>
                            {t("AGREEMENT.DETAIL.RECCOMENT")} :{" "}
                          </p>
                          <p>{d?.leaseAgreement?.leaseAgreementRemark}</p>
                        </div>
                      </div>
                      <div style={{ pageBreakAfter: 'always', marginBottom: "80px" }}></div>
                    </>
                  );
                })}
              </Style.Styles>}

              {typePrint === "ADMIN_SELECT" && <Style.Styles className="pt-3">
                {
                  printSelectAdmin
                    //  && printSelectAdmin.filter((item: any) => item.appointmentId === appointmentId)
                    .map((d: any) => {
                      return (
                        // <>
                        //   <div className="row d-flex justify-content-center">
                        //     <img
                        //       src={imgs.defaultAvatar}
                        //       alt=""
                        //       style={{ width: 200, height: 200 }}
                        //     />
                        //   </div>
                        //   <div className="row mt-4">
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.PREFIX")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.prefix?.prefixNameTh || "-"}
                        //       </div>
                        //     </div>
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.FIRST_NAME")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.firstname || "-"}
                        //       </div>
                        //     </div>

                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.LAST_NAME")}
                        //       </p>
                        //       <div className="">{d?.customer?.lastname || "-"}</div>
                        //     </div>

                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.ID_CARD")}
                        //       </p>
                        //       <div className="">{d?.customer?.idCard || "-"}</div>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-4">
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.BIRTH_DAY")}
                        //       </p>
                        //       <div className="">
                        //         {fullDate(d?.customer?.birthDay) || "-"}
                        //       </div>
                        //     </div>
                        //     <div className="col-4 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.BIRTH_DAY")}
                        //       </p>
                        //       <div className="">
                        //         {(d?.customer?.birthDay &&
                        //           moment().diff(d?.customer?.birthDay, "years")) ||
                        //           "0"}{" "}
                        //         {t("AGREEMENT.INPUT.YEAR")}
                        //       </div>
                        //     </div>
                        //     <div className="col-4 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.TEL")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.mobilePhone || "-"}
                        //       </div>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-4">
                        //     <div className="col-4 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.EMAIL")}
                        //       </p>
                        //       <div className="">{d?.customer?.email || "-"}</div>
                        //     </div>
                        //     <div className="col-4 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.TAX_ID")}
                        //       </p>
                        //       <div className="">{d?.customer?.taxId || "-"}</div>
                        //     </div>
                        //     <div className="col-4 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.ADRESS")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.houseNumber || "-"}
                        //       </div>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-4">
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.MOO")}
                        //       </p>
                        //       <div className="">{d?.customer?.moo || "-"}</div>
                        //     </div>
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.VILLAGE")}
                        //       </p>
                        //       <div className="">{d?.customer?.village || "-"}</div>
                        //     </div>
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.SOI")}
                        //       </p>
                        //       <div className="">{d?.customer?.soi || "-"}</div>
                        //     </div>

                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.ROAD")}
                        //       </p>
                        //       <div className="">{d?.customer?.road || "-"}</div>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-4">
                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.PROVINCE")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.province?.provinceNameTh || "-"}
                        //       </div>
                        //     </div>

                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.DISTRICT")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.district?.districtNameTh || "-"}
                        //       </div>
                        //     </div>

                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.SUB_DISTRICT")}
                        //       </p>
                        //       <div className="">
                        //         {d?.customer?.subDistrict?.subDistrictNameTh || "-"}
                        //       </div>
                        //     </div>

                        //     <div className="col-3 d-flex flex-column">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.INPUT.ZIPCODE")}
                        //       </p>
                        //       <div className="">{d?.customer?.zipCode || "-"}</div>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-5">
                        //     <p style={{ fontSize: 18, fontWeight: 800 }}>
                        //       {t("AGREEMENT.DETAIL.HEAD")}
                        //     </p>
                        //     <div className="col-6 d-flex flex-row">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.DETAIL.ZONE")}
                        //       </p>
                        //       <p>{d?.bidder?.zoneName}</p>
                        //     </div>
                        //     <div className="col-6 d-flex flex-row">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.DETAIL.STALL")}
                        //       </p>
                        //       <p>{d?.bidder?.stallName}</p>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-2">
                        //     <div className="col-6 d-flex flex-row">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.DETAIL.STATRT_DATE")}
                        //       </p>
                        //       <p>
                        //         {fullDate(
                        //           d?.leaseAgreement?.leaseAgreementDateStart
                        //         )}
                        //       </p>
                        //     </div>
                        //     <div className="col-6 d-flex flex-row">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.DETAIL.END_DATE")}
                        //       </p>
                        //       <p>
                        //         {fullDate(d?.leaseAgreement?.leaseAgreementDateEnd)}
                        //       </p>
                        //     </div>
                        //   </div>

                        //   <div className="row mt-2">
                        //     <div className="col-12 d-flex flex-row">
                        //       <p style={{ fontSize: 16, fontWeight: 700 }}>
                        //         {t("AGREEMENT.DETAIL.RECCOMENT")} :{" "}
                        //       </p>
                        //       <p>{d?.leaseAgreement?.leaseAgreementRemark}</p>
                        //     </div>
                        //   </div>
                        // </>
                        <div>
                          <div className="row">
                            <div className="col-6 d-flex flex-row">
                              <p>วันที่ ...........................................</p>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                              <div className="d-flex flex-column">
                                <p className="text-right mr-2">กลุ่มบริหารงานตลาด</p>
                                <img src={logo.OTK_Logo} alt="" width="180" height="80" />
                              </div>

                            </div>
                          </div>
                          <div className="row" style={{ margin: '40px 0px' }}>
                            <div className="col-12">
                              <div className="text-center">
                                แบบคำร้องทำสัญญาเช่า
                              </div>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="d-flex flex-row col-9">
                              <p className="ml-5">ข้าพเจ้า</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.prefix?.prefixNameTh + " "} {d?.customer?.firstname} {d?.customer?.lastname}</p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p>อายุ</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.birthDay && moment().diff(d?.bidderBy?.birthDay, "years")}</p>
                              <p>ปี</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-3 d-flex flex-row">
                              <p>สัญชาติ</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p className="w-50">เชื้อชาติ</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p className="w-75">บ้านเลขที่</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.houseNumber + " " || "-"}</p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p className="w-50">หมู่ที่</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.moo + " " || "-"}</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-3 d-flex flex-row">
                              <p>ถนน</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.road + " " || "-"}</p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p className="w-100">แขวง/ตำบล</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                                {d?.customer?.district?.districtNameTh || "-"}
                              </p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p className="w-100">เขต/อำเภอ</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                                {d?.customer?.subDistrict?.subDistrictNameTh || "-"}
                              </p>
                            </div>
                            <div className="col-3 d-flex flex-row">
                              <p className="w-75">จังหวัด</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.province?.provinceNameTh || "-"}</p>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-6 d-flex flex-row">
                              <p>โทรศัพท์</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                                {d?.customer?.mobilePhone || "-"}
                              </p>
                            </div>
                            <div className="col-6 d-flex flex-row">
                              <p>อีเมล</p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                                {d?.customer?.email || "-"}
                              </p>
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-3">
                              <p>มีความประสงค์</p>
                            </div>
                            <div className="col-1">
                              <div>
                                <input type="checkbox" style={{ width: 20, height: 20 }} />
                              </div>
                            </div>
                            <div className="col-8">
                              <div>
                                <div className="d-flex flex-row">
                                  <p className="w-100">ขอเช่าพื้นที่ตลาด </p>
                                  <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                                  <p className="w-100">แผง/ห้อง/คูหาเลขที่ </p>
                                  <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                                </div>
                                <div className="d-flex flex-row">
                                  <p className="w-25">ประเภทสินค้า </p>
                                  <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-3">

                            </div>
                            <div className="col-1">
                              <div>
                                <input type="checkbox" style={{ width: 20, height: 20 }} />
                              </div>
                            </div>
                            <div className="col-8">
                              <div>
                                <div className="d-flex flex-row">
                                  <p className="w-100">ขอต่อสัญญา (สัญญาเลขที่................/................. ลงวันที่.........................................) </p>
                                </div>
                                <div className="d-flex flex-row">
                                  <p className="w-100">ประเภทสินค้า </p>
                                  <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                                  <p className="w-100">แผง/ห้อง/คูหาเลขที่ </p>
                                  <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12">
                              <p>จึงยื่นคำร้องต่อหัวหน้ากลุ่มบริหารงานตลาด องค์การตลาดเพื่อเกษตรกร โดยยินดีปฏิบัติตามระเบียบ ข้อบังคับ
                                คำสั่ง คำแนะนำ ประกาศ ขององค์การตลาดเพื่อเกษตรกร และหรือการพิจารณาของหัวหน้ากลุ่มบริหารงาน
                                ตลาดทุกประการ โดยไม่มีเงื่อนไขใดๆ ทั้งสิ้น</p>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12">
                              <p className="ml-5">จึงเรียนมาเพื่อโปรดพิจารณา</p>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12">
                              <p className="ml-5 text-right">ลงชื่อ...........................................................ผู้ยื่นคำร้อง</p>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12">
                              <p className=" text-right" style={{ marginRight: 56 }}>(.............................................................)</p>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12">
                              <p className=" text-right" style={{ marginRight: 56 }}>.............../....................../.........................</p>
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div>
                              <p style={{ marginRight: 56 }}>หมายเลขติดต่อ</p>
                            </div>
                          </div>
                          <div className="row mb-2" style={{ pageBreakAfter: 'always' }}>
                            <div>
                              <p style={{ marginRight: 56 }}>โทร. 02-279-6215</p>
                            </div>
                          </div>
                          <div className="row" >
                            <div className="col-12 d-flex flex-row">
                              <p style={{ fontWeight: 700 }}>Ⅰ.</p>
                              <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex flex-row">
                                <p className="ml-3">กลุ่มวางแผนจัดเก็บรายได้ ตรวจสอบยอดหนี้ค่าเช่าและค่าอื่นๆของผู้ประกอบการแผงค้าที่..................................................</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-2">
                              <p >ชื่อ-นามสกุล</p>
                            </div>
                            <div className="col-5">
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.customer?.prefix?.prefixNameTh + " "} {d?.customer?.firstname} {d?.customer?.lastname}</p>
                            </div>
                            <div className="col-5">
                              <p>ที่ต้องชำระในวันทำสัญญาตามรายการดังนี้</p>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-6">
                              <p>1.ค่าเช่าตั้งแต่...................................................................</p>
                            </div>
                            <div className="col-6">
                              <p>7.ค่าประกันความเสียหาย........................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>.....................................................................................บาท</p>
                            </div>
                            <div className="col-6">
                              <p>8.ค่าประกันการผิดนัดค่าเช่า....................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>2.ค่าปรับ.......................................................................บาท</p>
                            </div>
                            <div className="col-6">
                              <p>9.ค่าประกันอัคคีภัย.................................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>3.ค่าภาษีโรงเรือน............................................................</p>
                            </div>
                            <div className="col-6">
                              <p>10.ค่าใบอนุญาต.....................................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>....................................................................................บาท</p>
                            </div>
                            <div className="col-6">
                              <p>11.ค่าโอนสิทธิ........................................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>4.ค่าน้ำประปา..............................................................บาท</p>
                            </div>
                            <div className="col-6">
                              <p>12.อื่นๆ..................................................................บาท<span style={{ color: '#fff' }}>................................</span></p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>5.ค่าไฟฟ้า....................................................................บาท</p>
                            </div>
                            <div className="col-6">
                              <p className="pl-3">รวมเป็นเงินทั้งสิ้น................................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                              <p>6.ค่าเช่าจ่ายล่วงหน้า 15 วัน...........................................บาท</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                              <p className="pl-3">ลงชื่อ..................................................ผู้ตรวจสอบ</p>
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                              <p className="pl-5">(...................................................)</p>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-6">
                              <div className="d-flex flex-row">
                                <p style={{ fontWeight: 700 }}>ⅠⅠ.</p>
                                <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex flex-row">
                                <p>เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <p className="pl-5">การเงินและบัญชีได้รับเงิน.......................รายการ</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5">บริหารตลาดได้ตรวจสอบเอกสารหลักฐาน</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <p>เป็นเงิน.............................บาท ตามใบเสร็จรับเงินเล่มที่</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>
                                <p>เรียบร้อยแล้ว เห็นควรอนุญาติให้ทำสัญญาเช่า</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <p>............เลขที่...........ไว้เรียบร้อยแล้ว และโปรดพิจารณา</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <p className="pl-5">ลงชื่อ........................................ผู้รับเงิน</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5">ลงชื่อ.........................................</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">(.........................................)</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">(.........................................)</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">............../............./..............</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">............../............./..............</p>
                              </div>
                            </div>
                          </div>
                          <p style={{ borderBottom: '1px dotted black', width: '100%' }}></p>
                          <div className="row mt-2">
                            <div className="col-12">
                              <div className="d-flex flex-row">
                                <p style={{ fontWeight: 700 }}>ⅠⅠⅠ.</p>
                                <p className="ml-2">การพิจารณา</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="d-flex flex-row justify-content-center">
                                <input type="checkbox" style={{ width: 20, height: 20 }} />
                                <p className="ml-3">อนุญาต</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="d-flex flex-row justify-content-center">
                                <input type="checkbox" style={{ width: 20, height: 20 }} />
                                <p className="ml-3">ไม่อนุญาต</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5">ลงชื่อ.........................................</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">(.........................................)</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">หัวหน้ากลุ่มบริหารงานตลาด</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                              <div>
                                <p className="pl-5 ml-5">............../............./..............</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </Style.Styles>}

              {typePrint === "CUSTOMER_SELECT" && <Style.Styles className="pt-3">
                {printCustomerSelect && printCustomerSelect
                  .filter((item: any) => item.appointmentId === appointmentId)
                  .map((d: any) => {
                    console.log(d);
                    return (
                      <>
                        <div className="row d-flex justify-content-center"  >
                          <img
                            src={imgs.defaultAvatar}
                            alt=""
                            style={{ width: 200, height: 200 }}
                          />
                        </div>
                        <div className="row mt-4">
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.PREFIX")}
                            </p>
                            <div className="">
                              {d?.prefix?.prefixNameTh || "-"}
                            </div>
                          </div>
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.FIRST_NAME")}
                            </p>
                            <div className="">{d?.firstname || "-"}</div>
                          </div>

                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.LAST_NAME")}
                            </p>
                            <div className="">{d?.lastname || "-"}</div>
                          </div>

                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.ID_CARD")}
                            </p>
                            <div className="">{d?.idCard || "-"}</div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.BIRTH_DAY")}
                            </p>
                            <div className="">
                              {fullDate(d?.birthDay) || "-"}
                            </div>
                          </div>
                          <div className="col-4 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.BIRTH_DAY")}
                            </p>
                            <div className="">
                              {(d?.birthDay &&
                                moment().diff(d?.birthDay, "years")) ||
                                "0"}{" "}
                              {t("AGREEMENT.INPUT.YEAR")}
                            </div>
                          </div>
                          <div className="col-4 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.TEL")}
                            </p>
                            <div className="">{d?.mobilePhone || "-"}</div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-4 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.EMAIL")}
                            </p>
                            <div className="">{d?.email || "-"}</div>
                          </div>
                          <div className="col-4 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.TAX_ID")}
                            </p>
                            <div className="">{d?.taxId || "-"}</div>
                          </div>
                          <div className="col-4 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.ADRESS")}
                            </p>
                            <div className="">{d?.houseNumber || "-"}</div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.MOO")}
                            </p>
                            <div className="">{d?.moo || "-"}</div>
                          </div>
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.VILLAGE")}
                            </p>
                            <div className="">{d?.village || "-"}</div>
                          </div>
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.SOI")}
                            </p>
                            <div className="">{d?.soi || "-"}</div>
                          </div>

                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.ROAD")}
                            </p>
                            <div className="">{d?.road || "-"}</div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.PROVINCE")}
                            </p>
                            <div className="">
                              {d?.province?.provinceNameTh || "-"}
                            </div>
                          </div>

                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.DISTRICT")}
                            </p>
                            <div className="">
                              {d?.district?.districtNameTh || "-"}
                            </div>
                          </div>

                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.SUB_DISTRICT")}
                            </p>
                            <div className="">
                              {d?.subDistrict?.subDistrictNameTh || "-"}
                            </div>
                          </div>

                          <div className="col-3 d-flex flex-column">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.INPUT.ZIPCODE")}
                            </p>
                            <div className="">{d?.zipCode || "-"}</div>
                          </div>
                        </div>

                        <div className="row mt-5">
                          <p style={{ fontSize: 18, fontWeight: 800 }}>
                            {t("AGREEMENT.DETAIL.HEAD")}
                          </p>
                          <div className="col-6 d-flex flex-row">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.DETAIL.ZONE")}
                            </p>
                            <p>{d?.leaseAgreementApprove?.bidder?.zoneName}</p>
                          </div>
                          <div className="col-6 d-flex flex-row">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.DETAIL.STALL")}
                            </p>
                            <p>{d?.leaseAgreementApprove?.bidder?.stallName}</p>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-6 d-flex flex-row">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.DETAIL.STATRT_DATE")}
                            </p>
                            <p>
                              {fullDate(
                                d?.leaseAgreement?.leaseAgreementDateStart
                              )}
                            </p>
                          </div>
                          <div className="col-6 d-flex flex-row">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.DETAIL.END_DATE")}
                            </p>
                            <p>
                              {fullDate(
                                d?.leaseAgreement?.leaseAgreementDateEnd
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="row mt-2" >
                          <div className="col-12 d-flex flex-row">
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {t("AGREEMENT.DETAIL.RECCOMENT")} :{" "}
                            </p>
                            <p>{d?.leaseAgreementApprove?.remark}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Style.Styles>}
            </>
          }
        />
      </div>
      {loading && <Loading show={loading} type="fullLoading" />}

    </Style.Container>
  );
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}

function resetForm() {
  throw new Error("Function not implemented.");
}
