import { useTranslation } from "react-i18next";
import { Box, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../../component/input/inputTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../../constants/colors";
import * as Style from "../rentStall.style"
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";


interface PopupRentStallProps {
  zone?: string;
  stall?: string;
  amount?: string;
  time?: string;
  annotation?: string;
  onClick?: () => void;
}

export default function PopupSetupDeposit(props: PopupRentStallProps) {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(props.amount || "");
  const [annotation, setAnnotation] = useState(props.annotation || "");
  const [appointmentDate, setAppointmentDate] = useState(props.time || "");
  return (
    <div className="overflow-hidden">
      

      <Style.RowStyle >
        <Col lg={3} />
        <Col>
          <Style.SubMessage>
            {t("รายละเอียดกำหนดค่ามัดจำแผงค้า")}
          </Style.SubMessage>
        </Col>
      </Style.RowStyle>

      <Row className="my-3 d-flex align-items-center">
        <Col lg={3} />
        <Col lg={3}>
          <Style.SubMessage>{t("โซนแผงค้า")}</Style.SubMessage>
        </Col>
        <Col lg={3}>
          <Style.SubDetail>{props.zone}</Style.SubDetail>
        </Col>
      </Row>

      <Row className="my-4 d-flex  align-items-center">
        <Col lg={3} />
        <Col lg={3}>
          <Style.SubMessage>{t("แผงค้า")}</Style.SubMessage>
        </Col>
        <Col lg={3}>
          <Style.SubDetail>{props.stall}</Style.SubDetail>
        </Col>
      </Row>

      <Row className="my-3 d-flex justify-content-center align-items-center">
        <Col lg={3} />
        <Col lg={3}>
          <Style.SubMessage>{t("จำนวนเงิน")}</Style.SubMessage>
        </Col>
        <Col lg={3}>
          <Style.SubDetail>
            <InputTextField
              label=""
              value={amount}
              onchange={() => {}}
              required={true}
            />
          </Style.SubDetail>
        </Col>
        <Col lg={3}>
          <Style.SubMessage>{t("บาท")}</Style.SubMessage>
        </Col>
      </Row>

      <Row className="my-3 d-flex align-items-center">
          <Col lg={3}/>
          <Col lg={3}>
            <Style.SubMessage>{t("วันที่สิ้นสุด")}</Style.SubMessage>
          </Col>
          <Col>
          < Style.SubDetail>{props.time}</Style.SubDetail>
          </Col>
          <Col>
            <Style.BoxEditDate>
                <FontAwesomeIcon icon={faPenSquare} color={colors.themeSecondColor} />
                <Style.Editdate onClick={() => {}}>{t("แก้ไขวันที่นัด")}</Style.Editdate>
            </Style.BoxEditDate>
          </Col>
      </Row>
    </div>
  );
}
