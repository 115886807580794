import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from "./news.style"

/** API */
import NewsTypeApi from "../../../api/setting/news/news.api";
import { swalActive } from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";

const initStateErrorMessage = {
  NEWS_NAME_TH: ``,
  IS_DUPLICATE_NEWS_NAME_TH: ``,
  NEWS_NAME_TH_STRING_BASE: ``,
  NEWS_NAME_TH_STRING_EMPTY: ``,
  NEWS_NAME_TH_MAXIMUM_LENGTH: ``,
  NEWS_NAME_TH_ANY_REQUIRED: ``,

  NEWS_NAME_EN: ``,
  IS_DUPLICATE_NEWS_NAME_EN: ``,
  NEWS_NAME_EN_STRING_BASE: ``,
  NEWS_NAME_EN_STRING_EMPTY: ``,
  NEWS_NAME_EN_MAXIMUM_LENGTH: ``,
  NEWS_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function NewsType() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [newstypeId, setNewstypeId] = useState(Number)
  const [newstypeNameTh, setNewstypeNameTh] = useState<string>('')
  const [newstypeNameEn, setNewstypeNameEn] = useState<string>('')
  const [announceType, setAnnounceType] = useState<string>('')
  const [newstypeStatus, setNewstypeStatus] = useState<string>('ACTIVE')
  const [newstypeData, setNewstypeData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')
  const [type, setType] = useState<string>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    if (type) condition = { ...condition, announceType: type === 'ALL' ? '' : type }


    const res = await NewsTypeApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setNewstypeData(res.data)
    } else {
      setRowCount(0)
      setNewstypeData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status, type])


  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)
  }

  const handleChangeType = (newType: string) => {
    setType(newType)
  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "newstypeId", disablePadding: false, label: "NO" },
    { id: "newstypeNameTh", disablePadding: false, label: t("NEWS.HEADCELL.NEWSTYPENAMETH"), sortable: true },
    { id: "newstypeNameEn", disablePadding: false, label: t("NEWS.HEADCELL.NEWSTYPENAMEEN"), sortable: true },
    { id: "announceType", disablePadding: false, label: t("NEWS.HEADCELL.ANNOUNCETYPE"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("NEWS.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("NEWS.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("NEWS.HEADCELL.ACTION") }
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('NEWS.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          NewsTypeApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('NEWS.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)

              loadData()
            })
            .catch((e) => {
              notiError(t('NEWS.MESSAGE.ERROR'), '', null, null)

            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { newstypeId, newstypeNameTh, newstypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('NEWS.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('NEWS.ALERT.CONFIRM_DELETE', {
        newstypeNameTh: newstypeNameTh, newstypeNameEn: newstypeNameEn ? '' + newstypeNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          NewsTypeApi.delete(newstypeId)
            .then((res) => {
              notiSuccess(t('NEWS.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('NEWS.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    const { newstypeId, newstypeNameTh, newstypeNameEn, status, announceType } = data
    setNewstypeId(newstypeId)
    setNewstypeNameTh(newstypeNameTh)
    setNewstypeNameEn(newstypeNameEn)
    setAnnounceType(announceType)
    setStatus(status)
    dispatch(showModal())
  }

  const resetForm = () => {
    setNewstypeId(0)
    setNewstypeNameTh('')
    setNewstypeNameEn('')
    setAnnounceType('')
    setNewstypeStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { newstypeId, newstypeNameTh, newstypeNameEn, announceType, modifiedBy, modifiedDate, status } = objData;

    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: newstypeId,
      id: newstypeId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: newstypeNameTh || "-" },
        { option: "TEXT", align: "left", label: newstypeNameEn || "-" },
        { option: "TEXT", align: "left", label: announceType === "HELP" ? t("STATUS.HELP") : t("STATUS.RENT") || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        {
          option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };


  const submit = () => {
    if (!newstypeNameTh) return setErrorMessage({ ...errorMessage, ...{ NEWS_NAME_TH: t('NEWS.MESSAGE.NEWS_NAME_TH') } })
    if (!newstypeNameEn) return setErrorMessage({ ...errorMessage, ...{ NEWS_NAME_EN: t('NEWS.MESSAGE.NEWS_NAME_EN') } })

    dispatch(submitModal())
    NewsTypeApi.create(newstypeNameTh, newstypeNameEn, announceType)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('NEWS.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({
            ...errorMessage,
            ...{
              [err.error_description]: t(`NEWS.MESSAGE.${err.error_description}`)
            }
          })
          if (err.statusCode === 400) {
            if (err.error_description === t('NEWS.MESSAGE.IS_DUPLICATE_NEWS_NAME_TH')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  NEWS_NAME_TH: t(`${t(err.error_description)}`),

                }
              })
            } else if (err.error_description === t('NEWS.MESSAGE.IS_DUPLICATE_NEWS_NAME_EN')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  NEWS_NAME_EN: t(`${t(err.error_description)}`),

                }
              })
            }

          }
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`NEWS.MESSAGE.${err.error_description}`) } })
        if (err.statusCode === 400) {
          if (err.error_description === t('NEWS.MESSAGE.IS_DUPLICATE_NEWS_NAME_TH')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                NEWS_NAME_TH: t(`${t(err.error_description)}`),

              }
            })
          } else if (err.error_description === t('NEWS.MESSAGE.IS_DUPLICATE_NEWS_NAME_EN')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                NEWS_NAME_EN: t(`${t(err.error_description)}`),

              }
            })
          }

        }

        dispatch(unSubmitModal())
      })

  }

  const submitEdit = () => {
    if (!newstypeNameTh) return setErrorMessage({ ...errorMessage, ...{ NEWS_NAME_TH: t('NEWS.MESSAGE.NEWS_NAME_TH') } })
    if (!newstypeNameEn) return setErrorMessage({ ...errorMessage, ...{ NEWS_NAME_EN: t('NEWS.MESSAGE.NEWS_NAME_EN') } })
    dispatch(submitModal())
    NewsTypeApi.update(newstypeId, { newstypeNameTh, newstypeNameEn, announceType, status: newstypeStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`NEWS.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`NEWS.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`NEWS.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  return (
    <>
      <HeaderCard text={t("NEWS.TITLE")} />
      <Style.MainContainer>

        <Row>
          <Col lg={3} md={6} xs={12} className="my-1">
            <InputTextField label={t("NEWS.INPUT.SEARCH")}
              value={search}
              onchange={(event) => {
                setSearch(event.target.value)
                setPage(1)
              }}
            />
          </Col>
          <Col lg={3} md={6} xs={12} className="my-1">
            <FilterSelect
              onchange={(event) => {
                const value = event.target.value
                if (value) {
                  handleChangeStatus(value)
                  setPage(1)
                }
              }}
              renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
              label={t('STATUS.LABEL')}
              selectId="select-status"
              value={status}
              labelId="label-status"
              options={[
                <MenuItem key="1" value="ALL">
                  {t('STATUS.ALL')}
                </MenuItem>,
                <MenuItem key="2" value="ACTIVE">
                  {t('STATUS.ACTIVE')}
                </MenuItem>,
                <MenuItem key="3" value="INACTIVE">
                  {t('STATUS.INACTIVE')}
                </MenuItem>
              ]}
            />
          </Col>
          <Col lg={3} md={6} xs={12} className="my-1">
            <FilterSelect
              onchange={(event) => {
                const value = event.target.value
                if (value) {
                  handleChangeType(value)
                  setPage(1)
                }
              }}
              renderValue={() => `${t('STATUS.TYPE')}: ${t(`STATUS.${type}`)}`}
              label={t('STATUS.TYPE')}
              selectId="select-type"
              value={type || ''}
              labelId="label-type"
              options={[
                <MenuItem key="1" value="ALL">
                  {t('STATUS.ALL')}
                </MenuItem>,
                <MenuItem key="2" value="RENT">
                  {t('STATUS.RENT')}
                </MenuItem>,
                <MenuItem key="3" value="HELP">
                  {t('STATUS.HELP')}
                </MenuItem>
              ]}
            />
          </Col>
          <Col lg={3} md={6} xs={12} className="my-1">
            <ButtonCustom style={{ width: "100%" }}
              type="button"
              disabled={permissions.isCreate.disabled}
              textButton={t('NEWS.BUTTON.ADD')}
              endIcon={<img src={icons.add} />}
              onClick={() => dispatch(showModal())}
            />
          </Col>
        </Row>

        <Style.TableContainer>
          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={newstypeData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}
            tableMinWidth={1200}
          />
        </Style.TableContainer>

      </Style.MainContainer>

      <ModalCustom
        title={newstypeId ? t('NEWS.TITLE_UPDATE') : t('NEWS.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
              onchange={(event) => {
                setNewstypeNameTh(event.target.value)
                clearErrorMessage()
              }}
              value={newstypeNameTh}
              helperText={
                errorMessage.NEWS_NAME_TH ||
                errorMessage.IS_DUPLICATE_NEWS_NAME_TH ||
                errorMessage.NEWS_NAME_TH_STRING_BASE ||
                errorMessage.NEWS_NAME_TH_STRING_EMPTY ||
                errorMessage.NEWS_NAME_TH_MAXIMUM_LENGTH ||
                errorMessage.NEWS_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('NEWS.INPUT.NEWS_NAME_TH')}
            />
            <InputTextField
              key="newstypeNameEn"
              onchange={(event) => {
                setNewstypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={newstypeNameEn}
              helperText={
                errorMessage.NEWS_NAME_EN ||
                errorMessage.IS_DUPLICATE_NEWS_NNEWS_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.NEWS_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('NEWS.INPUT.NEWS_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.TYPE')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setAnnounceType(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${announceType}`)}`}
                label={t('STATUS.TYPE')}
                selectId="select-type"
                value={announceType}
                labelId="label-update"
                options={[
                  <MenuItem key="1" value="RENT">
                    {t('STATUS.RENT')}
                  </MenuItem>,
                  <MenuItem key="2" value="HELP">
                    {t('STATUS.HELP')}
                  </MenuItem>
                ]}
              />
            </div>

            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setNewstypeStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${newstypeStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={newstypeId ? submitEdit : submit}
        textBtnCancel={t('NEWS.BUTTON.CANCEL')}
        textBtnConfirm={t('NEWS.BUTTON.SAVE')}
      />

    </>
  );
}
