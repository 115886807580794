import BaseAPI from "../../api";

const path = "bidder";

export interface BidderInterface {
  page?: number;
  pageLimit?: number;
  userId?: number;
  branchId?: number;
  roundId?: number;
  pageId?: number;
  bidderDate?: string;
  bidNo?: string;
  bidTypeId?: number;
  zoneId?: number;
  stallId?: number;
  bidderAmount?: number;
  status?: string;
  arrayBidderId?: any[];
  remark?: string;
}

export interface bidderStatusType {
  arrayBidderId?: string[],
  status?: string;
  remark?: string;
}

export interface IParamsFindAll {
  page?: number;
  pageLimit?: number;
  status?: "PENDING" | "PRELIMINARY" | "APPROVE" | "NOT_APPROVE" | "DELETE";
  search?: string;
  isNotDeposit?: "TRUE" | "FALSE" | null;
}

export default class BidderApi extends BaseAPI {
  static findAll(params: IParamsFindAll): Promise<any> {
    return this.api.get(path, { params: { ...params } }).then((res) => res);
  }
  static findAllAdminCheck(props: BidderInterface): Promise<any> {
    return this.api.get(`${path}/admin-check`, { params: { ...props } }).then(res => res)
  }

  static create(payload: BidderInterface): Promise<any> {
    return this.api.post(path, payload);
  }

  static findAllAdmin(params: BidderInterface): Promise<any> {
    return this.api
      .get(`${path}/admin`, { params: { ...params } })
      .then((res) => res);
  }

  static createbulk(payload: any): Promise<any> {
    return this.api.post(`${path}/bulk`, payload);
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`${path}/${id}`).then((res) => res);
  }

  static update(id: number, body: BidderInterface): Promise<any> {
    return this.api.patch(`${path}/${id}`, body);
  }

  static remove(id: number): Promise<any> {
    return this.api.delete(`${path}/${id}`);
  }

  static updateStatus(payload: any): Promise<any> {
    return this.api.patch(`${path}/status`, payload);
  }
  static updateStatusNotApprove(payload: bidderStatusType): Promise<any> {
    return this.api.patch(`${path}/status`, payload);
  }

  static updateStatusId(id: number, status: string): Promise<any> {
    return this.api.patch(`${path}/${id}`, { id, status });
  }
}
