import BaseAPI from "../api";

const path = "prosecute";

export interface ProsecuteInterface {
    page?: number;
    pageLimit?: number;
}

export default class ProsecuteApi extends BaseAPI {
    static findAll(params: ProsecuteInterface): Promise<any> {
        return this.api.get(path, { params: { ...params } }).then((res) => res);
    }

    static create(props: any): Promise<any> {
        return this.api.post(path, props).then((res) => res);
    }

}