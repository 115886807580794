import BaseAPI from "../../api";

const path = "appointment";

export interface AppointmentInterface {
  page?: number;
  pageLimit?: number;
  userId?: number;
  sortType?: "ASC" | "DESC";
  sortBy?: string;
  // branchId?: number
  // roundId?: number
  // pageId?: number
  // bidderDate?: string
  // bidNo?: string
  // bidTypeId?: number
  // zoneId?: number
  // stallId?: number
  // bidderAmount?: number
  // status?: string
}

export default class AppointmentApi extends BaseAPI {
  static findAll(params: AppointmentInterface): Promise<any> {
    return this.api.get(path, { params: { ...params } }).then((res) => res);
  }

  static create(
    branchId: number,
    userId: number,
    bidderId: number,
    appointmentType: string,
    appointmentDate: string,
    phoneNumber: string | number,
    transferId?: number,
  ) {
    return this.api.post(path, {
      branchId,
      userId,
      bidderId,
      appointmentType,
      appointmentDate,
      phoneNumber,
      transferId,
    });
  }
  static createAgreement(props:any) {
    return this.api.post(`${path}/agreement`, {...props});
  }
}
