import { useTranslation } from "react-i18next";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { Col, Row } from "react-bootstrap";
import * as Style from './popup.style'
import { imgs } from "../../../constants/images";
import InputTextField from "../../../component/input/inputTextField";
import { useState } from "react";
import ButtonCustom from "../../../component/button/buttonCustom";
import { colors } from "../../../constants/colors";

type popup = {
    isShow?: boolean | false
    remark: string;
    onChange: (remark: string) => void;
    setIsShow?: (show: boolean) => void
    onSubmit?: () => void
    onUpload?: () => void
}

export default function PopupRemark(props: popup) {
    const { t } = useTranslation();

    const [errorMessageRemark, setErrorMessageRemark] = useState<string>("");
    const handleSubmit = () => {
        if (!props.remark) {
            setErrorMessageRemark("ระบุเหตุผลในการไม่อนุมัติ")
            return;
        }
        if (props.onSubmit) props.onSubmit()
    }
    const handleChangeRemark = (value: string) => {
        setErrorMessageRemark("");
        props.onChange(value);
    }

    return (
        <>
            <ModalCustom
                title={t("เหตุผลไม่อนุมัติเบื้องต้น")}
                size="xl"
                isShowProps={props.isShow}
                closeButton
                onClose={() => {
                    props.setIsShow && props.setIsShow(false)
                    props.onChange("");
                }}
                // onSubmit={() => handleSubmit()}
                // textBtnConfirm={t('REQUEST_STALL.POPUP.SAVE')}
                // textBtnCancel="ยกเลิก"
                // onCancel={() => {
                //     props.setIsShow && props.setIsShow(false)
                //     props.onChange("");
                // }}
                component={
                    <>

                        <Style.ContainerUpload>
                            <Style.ContainerBoxUpload>
                                <Style.BoxModal>
                                    <Row>
                                        <Col>
                                            <InputTextField
                                                helperText={errorMessageRemark}
                                                value={props.remark}
                                                onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRemark(e.target.value)}
                                                heading="ระบุเหตุผล"
                                                multiline
                                                maxRows={4}
                                                rows={4}
                                                inputHeight={'auto'}
                                            />
                                        </Col>
                                    </Row>
                                </Style.BoxModal>

                            </Style.ContainerBoxUpload>
                        </Style.ContainerUpload>
                        <div className="d-flex justify-content-center w-100">
                            <ButtonCustom textButton={t('REQUEST_STALL.POPUP.SAVE')} style={{
                                margin: '0px 10px',
                                width:'200px',
                            }} onClick={() => handleSubmit()} />
                            <ButtonCustom textButton={t('ยกเลิก')} style={{
                                margin: '0px 10px',
                                width:'200px',
                                '&.MuiButton-contained': {
                                    backgroundColor: `${colors.red} !important`,
                                    ':hover': {
                                      backgroundColor: `${colors.red} !important`,
                                    }
                                  }
                            }} onClick={() => {
                                props.setIsShow && props.setIsShow(false)
                                props.onChange("");
                            }} />
                        </div>

                    </>
                }
            />
        </>
    )
}