import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, MenuItem, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { closeModal, showModal } from "../../../app/slice/modal.slice";
import moment from "moment";

/** STYLE */
import * as Style from "./utility.style";

/** API */
import ElectricityApi from "../../../api/setting/electricity/electricity.api";
import {
  isCreate,
  isDelete,
  isUpdate,
  isView,
} from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../constants/colors";
import UtilityDayApi from "../../../api/setting/utilityDay/utilityDay.api";
import {
  notiError,
  notiSuccess,
} from "../../../component/notification/notifications";

const initStateErrorMessage = {
  DATE_START: "",
  DATE_END: "",
};

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function OpenRound() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>();
  const [sortBy, setSortBy] = useState<string>("createDate");
  const [sortType, setSortType] = useState<string>("DESC");
  const [dateStart, setDateStart] = useState<number>(0);
  const [dateEnd, setDateEnd] = useState<number>(0);
  const [utilityDayId, setUtilityDayId] = useState<number>(0);

  const loadData = useCallback(async () => {
    const res = await UtilityDayApi.findAll();

    if (res.status === 200) {
      setRowCount(res.headers["total"]);
      setData(res.data);
    } else {
      setRowCount(0);
      setData([]);
    }
  }, [page, pageLimit, sortBy, sortType]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handlePageLimitChange = (newPageLimit: number) =>
    setPageLimit(newPageLimit);

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType);
    setSortBy(sortBy);
  };

  const headCells = [
    { id: "electricityTypeId", disablePadding: false, label: "NO" },
    {
      id: "createDate",
      disablePadding: false,
      label: "วันที่เริ่มจดบันทึก",
      sortable: true,
    },
    {
      id: "dateUsed",
      disablePadding: false,
      label: "วันที่สิ้นสุดในการจดบันทึก",
    },
    {
      id: "electricityUsed",
      disablePadding: false,
      label: "สถานะ",
      align: "left",
    },
    {
      id: "amount",
      disablePadding: false,
      label: "ผู้บันทึก",
      align: "center",
    },
    {
      id: "pricePerUnit",
      disablePadding: false,
      label: "Action",
      align: "center",
    },
  ];

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      electricityTypeId,
      status,
      modifiedBy,
      modifiedDate,
      startDate,
      endDate,
    } = objData;

    const statusBtnActive =
      status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive =
      status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: electricityTypeId,
      id: electricityTypeId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        {
          option: "TEXT",
          align: "left",
          label: startDate || "-",
        },
        {
          option: "TEXT",
          align: "left",
          label: endDate || "-",
        },
        { option: "STATUS", align: "left", label: status || "-" },
        {
          option: "UPDATE_BY",
          align: "left",
          label: {
            updatedBy: `${
              modifiedBy && modifiedBy.firstname && modifiedBy.lastname
                ? modifiedBy.firstname + " " + modifiedBy.lastname
                : modifiedBy?.firstname || modifiedBy?.lastname || "-"
            }`,
            updatedAt: modifiedDate,
          },
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-between mx-auto w-50">
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{
                    cursor:
                      permissions.isView.disabled === true ? "auto" : "pointer",
                    color:
                      permissions.isView.disabled === true
                        ? colors.themeMainColor
                        : colors.themeMainColor,
                  }}
                  onClick={() => {
                    setUtilityDayId(objData?.utilitiesDayId);
                    setDateStart(objData?.startDate);
                    setDateEnd(objData?.endDate);
                    dispatch(showModal());
                  }}
                />
              </div>
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const submitEdit = async () => {
    if (!dateStart)
      return setErrorMessage({
        ...errorMessage,
        ...{ DATE_START: t(`TEXTALERT.EMPLOYEE`) },
      });
    if (!dateEnd)
      return setErrorMessage({
        ...errorMessage,
        ...{ DATE_END: t(`TEXTALERT.EMPLOYEE`) },
      });
    const condition: any = {
      startDate: dateStart,
      endDate: dateEnd,
    };
    const res = await UtilityDayApi.updateById(utilityDayId, condition);
    if (res.status === 200) {
      setDateEnd(0);
      setDateStart(0);
      setUtilityDayId(0);
      dispatch(closeModal());
      notiSuccess("บันทึกข้อมูลเรียบร้อย");
    } else {
      notiError("บันทึกข้อมูลไม่สำเร็จ");
    }
  };

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage);

  return (
    <>
      <Style.PageContainer>
        <HeaderCard text="ตั้งค่าการเปิด-ปิด บันทึกค่าน้ำ-ค่าไฟ" />
        <Style.MainContainer>
          <Style.TableContainer>
            <TableCustom
              headCells={headCells}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={
                data &&
                data?.map((data: any, index: number) => {
                  return renderData(data, index);
                })
              }
              onSort={onRequestSort}
              setPageLimit={handlePageLimitChange}
              setPage={handlePageChange}
            />
          </Style.TableContainer>
        </Style.MainContainer>
      </Style.PageContainer>

      <ModalCustom
        title={"แก้ไขการเปิด-ปิดบันทึกค่าน้ำ-ค่าไฟ"}
        size="lg"
        closeButton
        modalScroll
        textBtnConfirm="บันทึก"
        textCancel="ยกเลิก"
        onCancel={() => {
          dispatch(closeModal());
          setDateEnd(0);
          setDateStart(0);
          setUtilityDayId(0);
        }}
        onClose={() => dispatch(closeModal())}
        onSubmit={() => {
          submitEdit();
        }}
        component={
          <>
            <Row className="my-2">
              <Col lg={2}>
                <Typography className="my-2">วันที่เปิด</Typography>
              </Col>
              <Col lg={4}>
                <InputTextField
                  value={dateStart}
                  onchange={(e: any) => {
                    setDateStart(e.target.value);
                    clearErrorMessage();
                  }}
                  helperText={errorMessage.DATE_START}
                />
              </Col>
              <Col lg={2}>
                <Typography className="my-2">วันที่ปิด</Typography>
              </Col>
              <Col lg={4}>
                <InputTextField
                  value={dateEnd}
                  onchange={(e: any) => {
                    setDateEnd(e.target.value);
                    clearErrorMessage();
                  }}
                  helperText={errorMessage.DATE_END}
                />
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
}
