import { useTranslation } from "react-i18next";

//**STYLE */
import * as Style from "./certificate.style"
import { Col, Row } from "react-bootstrap";
import { colors } from "../../constants/colors";
import InputTextField from "../../component/input/inputTextField";
import { dateToView } from "../../utils/date.utils";

interface PopupCertificateProps {
    firstname?: string
    lastname?: string
    zoneName?: string
    stallName?: string
    stallTypeName?: string
    leaseAgreementDateStart?: string
    leaseAgreementDateEnd?: string
    isShow: boolean | false;
    setIsShow: (show: boolean) => void;
    enableRequestDetail?:boolean;
    requestDetail?: string;
    errorMessageRequestDetail?:string;
    setRequestDetail?: (value: string) => void;
    // onSubmit: (data: any) => void; 
}

export default function PopupReqCertificate(props: PopupCertificateProps) {
    const { t } = useTranslation();

    const handleSetRequestDetail = (value: string) => {
        if (props.setRequestDetail) {
            props.setRequestDetail(value)
        }
    }
    return (
        <>
            <Style.ContainerModal>
                <Row className="mt-3">
                    <Col lg={2} xs={6} >
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.DETAIL')}</Style.SubDetail>
                    </Col>
                    <Col lg={6} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.REQUEST')}</Style.SubDetail>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.NAME')}</Style.SubDetail>
                    </Col>

                    <Col lg={6} xs={6}>
                        <Style.SubDetail>{props.firstname + " " + props.lastname}</Style.SubDetail>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col lg={5} xs={6}  >
                        <Style.SubDetail style={{ backgroundColor: colors.headerBackground, borderRadius: 4, width: 593 }}>
                            {t('CERTIFICATE.MODAL.STALLDETAIL')}
                        </Style.SubDetail>
                    </Col>

                </Row>

                <Row className="mt-3" >
                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.ZONE')}</Style.SubDetail>
                    </Col>
                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{props.zoneName || "-"}</Style.SubDetail>
                    </Col>
                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.ZONE_TYPE')}</Style.SubDetail>
                    </Col>
                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{props.stallTypeName || "-"}</Style.SubDetail>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.STALL')}</Style.SubDetail>
                    </Col>

                    <Col lg={2} xs={6}>
                        <Style.SubDetail>{props.stallName || "-"}</Style.SubDetail>
                    </Col>
                </Row>

                <Row className="mt-3 mb-5">
                    <Col lg={3} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.START_DATE')}</Style.SubDetail>
                    </Col>
                    <Col lg={3} xs={6}>
                        <Style.SubDetail>{dateToView(props.leaseAgreementDateStart) || "-"}</Style.SubDetail>
                    </Col>

                    <Col lg={3} xs={6}>
                        <Style.SubDetail>{t('CERTIFICATE.MODAL.EXPIRE_DATE')}</Style.SubDetail>
                    </Col>
                    <Col lg={3} xs={6}>
                        <Style.SubDetail>{dateToView(props.leaseAgreementDateEnd) || "-"}</Style.SubDetail>
                    </Col>
                </Row>
                {props.enableRequestDetail && (
                    <Row>
                        <InputTextField
                            helperText={props.errorMessageRequestDetail}
                            value={props.requestDetail}
                            heading="เหตุผลในการขอใบรับรอง"
                            onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetRequestDetail(e.target.value)}
                        />
                    </Row>
                )}
            </Style.ContainerModal>
        </>
    );
}
