import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'newstype'

export interface NewsTypeInterface {
    newstypeId?: number
    newstypeNameTh: string
    newstypeNameEn?: string
    announceType?: string
    status: string
  }

export default class NewsTypeApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(newstypeNameTh: string, newstypeNameEn: string , announceType : string) {
        return this.api.post(path , { newstypeNameTh, newstypeNameEn, announceType })
    }

    static findById(id: number): Promise<NewsTypeInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: NewsTypeInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
