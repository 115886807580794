import { menuFirsts } from "../../constants/menu"
import TransferStall from "../../pages/transferStall/transferStall"
import RegisterFromTransfer from "../../pages/transferStall/registerFromTransfer"
import { routeName } from "../routers-name"
import ContractTransfer from "../../pages/transferStall/contractTransfer"


const routeTransferStall = [
    {
      ...menuFirsts.TRANSFER_STALL,
      key: menuFirsts.TRANSFER_STALL.key,
      name: menuFirsts.TRANSFER_STALL.name,
      textName: menuFirsts.TRANSFER_STALL.textName,
      active: false,
      appBar: true,
      component: TransferStall
    },
    {
      ...menuFirsts.TRANSFER_STALL,
      key: menuFirsts.TRANSFER_STALL.key,
      name: menuFirsts.TRANSFER_STALL.name,
      textName: menuFirsts.TRANSFER_STALL.textName,
      active: false,
      appBar: true,
      path: routeName.transferStall + "/register-from-transfer",
      component: RegisterFromTransfer
    },
    {
      ...menuFirsts.TRANSFER_STALL,
      key: menuFirsts.TRANSFER_STALL.key,
      name: menuFirsts.TRANSFER_STALL.name,
      textName: menuFirsts.TRANSFER_STALL.textName,
      active: false,
      appBar: true,
      path: routeName.transferStall + "/contract-transfer",
      component: ContractTransfer
    }
  ]
  
  export default routeTransferStall