import { useTranslation } from "react-i18next";

//**COMPONENT */
import { icons, imgs } from "../../../constants/images";
import { numberFormat } from "../../../utils/common";
import PreviewImage from "../../../component/image/PreviewImage";

//**STYLE */
import * as Style from "../payMentStyle";
import { Col, Row } from "react-bootstrap";
import Cloudupload from "../../../assets/icon/cloudupload";
import ButtonCustom from "../../../component/button/buttonCustom";
import InputTextField from "../../../component/input/inputTextField";
import { colors } from "../../../constants/colors";
import InputDatePicker from "../../../component/input/inputDatePicker";
import moment from "moment";

interface PopupPaymentProps {
  receipt?: string;
  amount?: string;
  time?: string;
  image?: any;
  getInputProps?: any;
  getRootProps?: any;
  showInput?: boolean;
  inputvalue?: string;
  onChangeInput?: any;
  payment?: any
  data?: any
  roleId?: any
  dateApprove?:string
  timeApprove?:string
  setDateApprove?:any
  setTimeApprove?:any
}

export default function PopupCheckPayment(props: PopupPaymentProps) {
  const { t } = useTranslation();

  return (
    <>
      <Style.MainModal>
        <Style.MainBoxModal>
          <Style.BoxModal>
            <Row className="my-3" >
              <Col>
                <Style.HeaderMessage>
                  {t("PAYBILL.REPORT.PAYMENT_DETAILS")}
                </Style.HeaderMessage>
              </Col>
            </Row>

            <Row className="my-3" >
              <Col xs={4}>
                <Style.HeadMessage>
                  {t("PAYBILL.REPORT.RECEIPT_NUMBER")}
                </Style.HeadMessage>
              </Col>
              <Col xs={4}>
                <Style.HeadMessage>{props.receipt}</Style.HeadMessage>
              </Col>
            </Row>

            <Row className="my-3">

              <Col xs={4}>
                <Style.SubDetail>{t("PAYBILL.POPUP.PAYMENT_AMOUNT")}</Style.SubDetail>
              </Col>
              <Col xs={4}>
                <Style.SubDetail>{numberFormat(props.amount)}</Style.SubDetail>
              </Col>
              <Col>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            {/* <Row className="my-3" >

              <Col xs={4}>
                <Style.SubDetail>{t("PAYBILL.POPUP.PAYMENT_DATE_TIME")}</Style.SubDetail>
              </Col>
              <Col xs={4}>
                <Style.SubDetail>{props.time}</Style.SubDetail>
              </Col>
            </Row> */}
            <Row className="my-3" >

              <Col xs={4}>
                <Style.SubDetail sx={{ fontWeight: '500', fontSize: '16px' }}>{t("สถานะ")}</Style.SubDetail>
              </Col>
              <Col xs={8}>
                <Style.SubDetail sx={{ fontWeight: '500', fontSize: '16px', color: props?.data?.status === 'PAY_APPROVE' ? colors.green : colors.red }}>{props?.data?.status === 'PAY_APPROVE' ? 'ยืนยันการชำระเงิน' : 'ยังไม่ได้รับรายการชำระเงิน'}</Style.SubDetail>
              </Col>
            </Row>
            {props?.roleId === 4 ?
              <>
                {props?.data?.status === 'PAY_APPROVE' && <Row className="my-3" >

                  <Col xs={4}>
                    <Style.SubDetail>{t("PAYBILL.POPUP.PAYMENT_DATE_TIME")}</Style.SubDetail>
                  </Col>
                  <Col xs={4}>
                    <Style.SubDetail>{props.time}</Style.SubDetail>
                  </Col>
                </Row>}
              </>
              : <>
                {(props?.data?.status === 'PAY' || props?.data?.status === 'PAY_APPROVE') && <>
                  {props?.data?.status === 'PAY_APPROVE' ? <Row className="my-3" >

                    <Col xs={4}>
                      <Style.SubDetail>{t("PAYBILL.POPUP.PAYMENT_DATE_TIME")}</Style.SubDetail>
                    </Col>
                    <Col xs={4}>
                      <Style.SubDetail>{props.time}</Style.SubDetail>
                    </Col>
                  </Row> : <><Row className="my-3" >

                    <Col xs={4}>
                      วันที่ชำระ
                    </Col>
                    <Col xs={8}>
                      <InputDatePicker value={props.dateApprove} onChange={(val: any) => props.setDateApprove(moment(val).format("YYYY-MM-DD"))} disabled={props?.data?.status === 'PAY_APPROVE'} />
                    </Col>
                  </Row>
                    <Row className="my-3" >

                      <Col xs={4}>
                        เวลา
                      </Col>
                      <Col xs={8}>
                        <InputTextField value={props.timeApprove} onchange={(e) => props.setTimeApprove(e.target.value)} type="time" disabled={props?.data?.status === 'PAY_APPROVE'} />
                      </Col>
                    </Row></>}
                </>}
              </>}

            {/* {props.payment &&
            <Row className="my-3">
              <div {...props.getRootProps}>
                <input {...props.getInputProps} />
                <ButtonCustom textButton={"อัพโหลดใบเสร็จ"} />
              </div>
            </Row>} */}

          </Style.BoxModal>

          <Style.BoxImage>
            {/* <PreviewImage imageFile={process.env.REACT_APP_FILE_URL + props?.image?.invoicePay?.fileUpload?.filePath
                            + props?.image?.invoicePay?.fileUpload?.fileName || props.image} /> */}
            <PreviewImage imageFile={props?.image} />

          </Style.BoxImage>
        </Style.MainBoxModal>

      </Style.MainModal>
      {props.showInput ? <><InputTextField
        value={props.inputvalue}
        onchange={props.onChangeInput}
        heading="เหตุผลไม่อนุมัติ"
      /></> : <></>}
    </>
  );
}
