import { useCallback, useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { NumberInTable, TypographyHead } from "./announceStyle";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import upload from "../../assets/images/upload.png";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonCustom from "../../component/button/buttonCustom";
import TableRowCommon from "../../component/table/TableRowCommon";

import TableCustom from "../../component/table/tableCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Style from "./announceStyle";
import Map from "../../component/marketMap/map";
import InputNewRangePicker from "../../component/input/InputRangePicker";
import moment from "moment";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import { Col } from "react-bootstrap";
import NewsTypeApi from "../../api/setting/news/news.api";
import AnnounceApi from "../../api/home/announce.api";
import ZoneApi from "../../api/zone/zone.api";
import FileuploadApi from "../../api/uploadfile/upload.api";
import Loading from "../../component/loading/loading";
import { useLocation } from "react-router-dom";

const initStateErrorMessage = {
  PRFIX_NAME: ``,
  ROLDID: ``,
  NAME: ``,
  EMPLOYEE_CODE: ``,
  LASTNAME: ``,
  PHONE_NUBER: ``,
  EMAIL: ``,
  USERNAME: ``,
  PASSOWORD: ``,
  CONFIRM_PASSWORD: ``,
  BARNCH: ``,
  PW: ``,
  IDCARD: ``,
  HEAD: ``,
};

export default function AddAnnounceRental() {
  const location: any = useLocation()

  const type: any = location?.state?.type
  const id: any = location?.state?.id

  const [file, setFile] = useState<any[]>([]);
  const [mapData, setMapData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(1);
  const [title, setTitle] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  // const [newType, setNewtype] = useState<any[]>([]);

  const [dataTable, setDataTable] = useState<any>([]);
  const [newstypeId, setNewTyperId] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([]);
  const [dateStart, setDateStart] = useState<any>([]);
  const [dateEnd, setDateEnd] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [select, setSelectfile] = useState<any>();
  const { t } = useTranslation();

  const handleClearRangeDate = () => {
    setSelectedDateRange([]);
    setDateStart("");
    setDateEnd("");
  };

  const handleSelect = async (x: any, zoneName: string) => {
    Object.assign(x, { zoneName: zoneName });
    Object.assign(x, { selectedDateRange: [] });
    if (!dataTable.includes(x)) {
      if (x.status === "BLANK") {
        setDataTable([...dataTable, x]);
      }
    }
  };
  const handleDelete = async (x: any) => {
    setDataTable(dataTable.filter((item: any) => item.stallId !== x));
  };
  const handlepreview = (x: any) => {
    // let name = ''
    // if (x?.refId) {
    //   name = x?.fileName?.split(".")
    // } else {
    //   name = x?.type?.split(".")
    // }

    // if (name[name?.length - 1] === "application/pdf") {
    //   window.open(URL.createObjectURL(x), "_blank");
    // } else {
    //   setSelectfile(URL.createObjectURL(x));
    // }
    if (x?.filePath && x?.fileName) {
      if (x.mimetype === "application/pdf") {
        window.open(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName, "_blank");
      } else {
        setSelectfile(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName);
      }
    } else {
      const name = x.type.split(".");

      if (name[name?.length - 1] === "application/pdf") {
        window.open(URL.createObjectURL(x), "_blank");
      } else {
        setSelectfile(URL.createObjectURL(x));
      }
    }

  };
  const handleRangeDate = (value: any) => {
    setSelectedDateRange(value);
    setDateStart(moment(value[0]).format("YYYY-MM-DD"));
    setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
  };
  // const handleRangeDate = (value: any, obj: any) => {
  //   Object.assign(obj, { startDate: moment(value[0]).format("YYYY-MM-DD") });
  //   Object.assign(obj, { endDate: moment(value[1]).format("YYYY-MM-DD") });
  //   Object.assign(obj, { selectedDateRange: value });
  //   setSelectedDateRange(value);
  // };
  const handledeleteImg = (x: any) => {
    setFile(file.filter((y: any, index: number) => x !== index));
    setSelectfile(null);
  };
  // const handleClearRangeDate = (x: any) => {
  //   Object.assign(x, { selectedDateRange: [] });
  //   setDateStart("");
  //   setDateEnd("");
  // };

  // const getnewType = async () => {
  //   const codition = {
  //     page: 1,
  //     pageLimit: 50,
  //     announceType: "RENT",
  //   };
  //   await NewsTypeApi.findAll(codition).then((res) => {
  //     if (res.status === 200) {
  //       setNewtype(res?.data);
  //     }
  //   });
  // };

  const getDetail = async () => {
    await AnnounceApi.details(id).then((res) => {
      if (res?.status === 200) {
        const data: any = res?.data
        setDataTable(data?.round?.map((item: any) => {
          console.log(item);
          return {
            ...item,
            zoneName: item?.stall?.zone?.zoneName,
            stallName: item?.stall?.stallName,
            stallType: item?.stall?.stallType
          }
        }) || [])
        setDetail(data?.detail || '')
        setDateStart(data.startDate)
        setDateEnd(data.endDate)
        setNewTyperId(data?.newstypeId)
        setTitle(data?.title)
        setLeasesTypeId(data?.leasesTypeId)
        setFile(data?.fileUpload || [])
        setSelectedDateRange([
          `${moment(data?.startDate)}`, `${moment(data?.endDate)}`
        ])

        setStatus(data?.status)

      }
    });
  }

  useEffect(() => {
    if (id) {
      getDetail()
    }
  }, [])

  const loadData = useCallback(async () => {
    setLoading(true)
    let condition: any = {
      page: 1,
      pageLimit: 4000
    };
    if (search) condition = { ...condition, search: search };
    if (leasesTypeId) condition = { ...condition, leasesTypeId: leasesTypeId };

    const res = await ZoneApi.findAll(condition);

    if (res.status === 200) {
      setMapData(res.data);
      setLoading(false)
    } else if (res.status === 400) {
      setMapData([]);
      setLoading(false)
    } else {
      setMapData([]);
      setLoading(false)
    }
  }, [search, leasesTypeId]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  const prefixData = [
    { bidTypeId: 1, label: "ราคาเช่า" },
    { bidTypeId: 2, label: "ประมูล" },
  ];
  const onDrop = useCallback(
    (acceptedFiles) => {

      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf"
      ) {
        setFile([...file, acceptedFiles[0]]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = () => {
    if (!newstypeId)
      return setErrorMessage({
        ...errorMessage,
        ...{ HEAD: t(`ANNOUNCE.NOTI.HEAD`) },
      });
    const condition = {
      newstypeId: newstypeId,
      title: title,
      detail: detail,

      bookmark: false,
      announceType: "RENT",
      status: "PENDING",
      // startDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: dateStart ? moment(dateStart).format("YYYY-MM-DD") : null,
      endDate: dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : null,
      // file: file,
      round: dataTable?.map((obj: any) => {
        return {
          startDate: dateStart ? moment(dateStart).format("YYYY-MM-DD") : null,
          endDate: dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : null,
          // startDate: obj.startDate,
          // endDate: obj.endDate,
          stallId: obj.stallId,
          bidTypeId: obj.bidTypeId,
          price: parseInt(obj.price),
        };
      }),
    };
    if (id) {
      AnnounceApi.updateAnnounce(condition, id).then((res) => {
        if (res.status === 201) {
          if (file?.length > 0) {
            const formData = new FormData();
            formData.append("refId", `${res?.data?.refId}`);
            formData.append("fileType", `${res?.data?.fileType}`);
            file.forEach((data: any, i) => {
              formData.append(`files[]`, data);
            });

            FileuploadApi.uploadfilearray(formData).then((result) => {
              notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
            });

          } else {
            notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
          }
        } else {
          notiError('บันทึกประกาศแผงค้าไม่สำเร็จ')
        }
      });
    } else {
      AnnounceApi.createAnnounce(condition).then((res) => {
        if (res.status === 201) {
          if (file?.length > 0) {
            const formData = new FormData();
            formData.append("refId", `${res?.data?.refId}`);
            formData.append("fileType", `${res?.data?.fileType}`);
            file.forEach((data: any, i) => {
              formData.append(`files[]`, data);
            });

            FileuploadApi.uploadfilearray(formData).then((result) => {
              notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
            });

          } else {
            notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
          }
        } else {
          notiError('สร้างประกาศแผงค้าไม่สำเร็จ')
        }
      });
    }

  };


  const headCells = [
    {
      id: "no",

      label: (
        <div
          className="d-flex flex-row justify-content-between"
          style={{ minHeight: 48, alignItems: "center", padding: "0px 10px" }}
        >
          <div>{t("STALL.TABLE.LIST")}</div>
          <div>{t("STALL.TABLE.SELECT") + t("STALL.TABLE.UNIT")}</div>
        </div>
      ),
      align: "left",
      colSpan: 9,
    },
  ];
  const renderData = (objData: any, index: number) => {
    let no = page * pageLimit - pageLimit + index + 1;
    const {
      date,
      price,
      status,
      stallName,
      stallType,
      zoneName,
      stall,
      stallId,
      selectedDateRange,
    } = objData;

    const objRenderData = {
      key: `${index}`,
      id: `${index}`,
      obj: objData,
      columns: [
        {
          option: "COMPONENT",
          align: "center",
          component: <NumberInTable>{no}</NumberInTable>,
        },
        {
          option: "TEXT",
          align: "left",
          label: t("STALL.ROW.ZONE") + zoneName
        },
        {
          option: "TEXT",
          align: "left",
          label: t("STALL.ROW.STALL") + stallName,
        },
        {
          option: "TEXT",
          align: "left",
          label: t("STALL.ROW.TYPE_STALL") + stallType?.stallTypeNameTh,
        },
        // {
        //   option: "COMPONENT",
        //   align: "left",
        //   component: (
        //     <AutocompleteSelect
        //       labelId="announcerental"
        //       options={prefixData}
        //       onChange={(e, newvalue) => {
        //         Object.assign(objData, { bidTypeId: newvalue?.bidTypeId });
        //       }}
        //     />
        //   ),
        //   width: "15%",
        // },

        {
          option: "COMPONENT",
          align: "left",
          component: (
            <InputTextField
              label={'ราคาเช่า'}
              value={price || ''}
              onchange={(e) => {
                setDataTable(
                  dataTable?.map((item: any, i: number) => {
                    item.price = index === i ? e.target.value : item?.price
                    return item
                  })
                )
                // Object.assign(objData, { price: e.target.value });
              }}
            />
          ),
          width: "15%",
        },

        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: t("ANNOUNCE.TABLEHEAD.DATE_TIME"),
        // },
        // {
        //   option: "COMPONENT",
        //   align: "left",
        //   component: (
        //     <InputNewRangePicker
        //       key={"date-appointment"}
        //       label={t("REPAIR_TYPE_MANGEMENT.INPUT.START_END_DATE")}
        //       value={selectedDateRange}
        //       onClear={() => handleClearRangeDate(objData)}
        //       onchange={(value) => handleRangeDate(value, objData)}
        //       disablePast={true}
        //     />
        //   ),
        //   width: "20%",
        // },
        {
          option: "COMPONENT",
          align: "left",
          width: 50,
          component: (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(stallId)}
              color="red"
            />
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };
  // useEffect(() => {
  //   getnewType();
  // }, []);

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };

      reader.onerror = (error: any) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  const uploadAdapter = (loader: any) => {

    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        return {
          default: "data:image/jpeg;base64," + base64,
        };
      },
    };
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <Box className='mb-5'>
      <Style.headerannoce>
        <TypographyHead>{t("ANNOUNCE.HEAD.ADDANNOUNCE")}</TypographyHead>
      </Style.headerannoce>
      <Style.boxcontainer>
        <Style.boxinsite>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container spacing={2}>
              <Grid item lg={2} xs={12}>
                {t("ประเภทประกาศ")}
                {/* {t("ANNOUNCE.CREATE.NEW_TYPE")} */}
              </Grid>
              <Grid item lg={10} xs={12}>
                {/* <AutocompleteSelect
                  options={newType}
                  required={true}
                  value={
                    newstypeId
                      ? newType.find(
                        (data: any) => data.newstypeId === newstypeId
                      )
                      : null
                  }
                  getOptionLabel={(option: any) => option.newstypeNameTh}
                  // onChange={(data: any) => setPrefixId(data.value)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setNewTyperId(newValue.newstypeId);
                    }
                  }}
                  helperText={errorMessage.HEAD}
                  labelId="add"
                /> */}
                ประเภทเช่าแผงค้า
              </Grid>
              <Grid item lg={2} xs={12}>
                {t("ANNOUNCE.CREATE.HEAD")}
              </Grid>
              <Grid item lg={10} xs={12}>
                <InputTextField value={title} onchange={(e) => setTitle(e.target.value)} />
              </Grid>
              <Grid item lg={2} xs={12}>
                {t("ระยะเวลาการประกาศ")}
              </Grid>
              <Grid item lg={10} xs={12}>
                <InputNewRangePicker
                  key={"date-appointment"}
                  label={t("วันที่เริ่มต้นใช้งาน และสิ้นสุดการใช้งาน")}
                  value={selectedDateRange}
                  onClear={handleClearRangeDate}
                  onchange={handleRangeDate}

                />
              </Grid>
              <Grid item lg={2} xs={12}>
                {t("ANNOUNCE.CREATE.DETAILCREATE")}
              </Grid>
              <Grid item lg={10} xs={12}>

                <div style={{ width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetail(data);
                    }}
                    data={detail || ''}
                    config={{
                      extraPlugins: [uploadPlugin],
                      toolbar: {
                        items: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          '|',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'insertTable',
                          '|',
                          'imageUpload',
                          '|',
                          'undo',
                          'redo'
                        ]
                      },
                    }}

                  />
                </div>
              </Grid>
              <Grid item lg={12}>
                {t("ANNOUNCE.HEAD.STALL")}
              </Grid>

              <div className="w-100 h-100" style={{ position: "relative" }}>
                <Map
                  mapData={mapData}
                  dragGridZone={false}
                  onClick={(valZone: any, zoneName: string) =>
                    handleSelect(valZone, zoneName)
                  }
                  enableResizingZone={false}
                  disableDraggingZone={true}
                  dragGridStall={false}
                  enableResizingStall={false}
                  disableDraggingStall={true}
                />
              </div>

              <Grid item lg={12} xs={12}>
                <TableCustom
                  headCells={headCells}
                  customScroll
                  page={1}
                  pageLimit={1}
                  sortType={"ASC"}
                  sortBy=""
                  rowCount={1}
                  tableMinWidth={1000}
                  rowsData={dataTable?.map((d: any, index: any) => {
                    return renderData(d, index);
                  })}
                  onSort={() => { }}
                  setPageLimit={() => { }}
                  setPage={() => { }}
                  hidePagination
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ marginTop: "43px" }}>
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid item lg={1} xs={12}>
                  {t("ANNOUNCE.CREATE.FILE")}
                </Grid>
                <Grid item lg={11} xs={12}>
                  <Style.boxupload>
                    {" "}
                    <div {...getRootProps()}>
                      <input type="file" multiple {...getInputProps()} />
                      {select ? (
                        <img
                          src={select}
                          alt="Thumb"
                          style={{ width: "100%", height: "250px", cursor: "pointer", }}
                        />
                      ) : (
                        <img src={upload} style={{ width: "100%", cursor: "pointer" }} />
                      )}
                    </div>
                  </Style.boxupload>
                </Grid>

                {file?.map((e: any, index: any) => (
                  <>
                    {/* <Col item lg={1} xs={12}></Col> */}
                    <Col item lg={2} xs={12} className="my-2 d-flex align-items-center">
                      ไฟล์แนบลำดับที่ {index + 1}
                    </Col>
                    <Col item lg={10} xs={12} className="my-2">
                      <Style.filearray>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>{e?.name || e?.fileName}</Box>
                          <Box>
                            <VisibilityIcon
                              onClick={() => handlepreview(e)}
                              sx={{ cursor: "pointer", marginRight: "10px" }}
                            />
                            <DeleteIcon
                              onClick={() => handledeleteImg(index)}
                              color="error"
                              sx={{ cursor: "pointer" }}
                            />
                          </Box>
                        </Box>
                      </Style.filearray>
                    </Col>
                  </>
                ))}

                <Grid item lg={12} xs={12}>
                  <ButtonCustom
                    type="submit"
                    onClick={handleSubmit}
                    textButton={t("REGISTER.BUTTON.SUMMIT")}
                    btnStyle={{
                      fontSize: "16px",
                      width: "100%",
                      padding: "0px",
                      marginTop: "32px",
                      marginBottom: "14px",
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Style.boxinsite>
      </Style.boxcontainer>
      {loading && <Loading show={loading} type="fullLoading" />}
    </Box>
  );
}
