import React from 'react'
import { styled } from "@mui/material/styles";
import { colors } from '../../../constants/colors';
import { Box, Button, Typography } from "@mui/material";
import { imgs } from '../../../constants/images';
import { useTranslation } from "react-i18next";
import ModalCustom from '../../../component/modalCustom/modalCustom';
import { logout } from '../../../utils/app.utils';

type Props = {
    show: boolean;
    suspend?: boolean;
}
const ModalContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
}));

const MainRedText = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    color: colors.red,
    textAlign: "center",
}));
const BlackText = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    color: colors.black,
}));
const ClickPayDebt = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    color: colors.red,
    marginLeft: 4,
    ":hover": {
        textDecoration: "underline",
        cursor: "pointer"
    },
}));



const PopupNotPayments = (props: Props) => {
    const { t } = useTranslation();
    const handleLogout = () => {
        logout();
        window.location.href = '/login';
    }
    return (
        <>
            <ModalCustom
                title=''
                isShowProps={props.show}
                size='xl'
                component={
                    <>
                        <ModalContainer sx={{ position: "relative" }}>
                            <Button
                                onClick={handleLogout}
                                sx={{ position: "absolute", top: 20, right: 20 }}
                                variant='contained'
                                color='error'>
                                {"ออกจากระบบ"}
                            </Button>
                            <img src={imgs.notPayAlert} />
                            <Box>
                                <MainRedText>{t("บัญชีของคุณถูกปิดการใช้งานชั่วคราว")}</MainRedText>
                                <Box sx={{display:'flex', flexDirection: {md:'row', xs:'column'}, justifyContent:"center", alignItems:'center'}}>
                                    <BlackText>{t("กรุณาติดต่อเจ้าหน้าที่ หรือสอบถามเพิ่มเติมได้ที่ 086 724 6344")}</BlackText>
                                    {!props.suspend && (
                                        <ClickPayDebt onClick={() => {
                                            window.location.pathname = '/pay-bill'
                                        }}>
                                            {t("HOME.ALERT.PAY_DEBT")}
                                        </ClickPayDebt>
                                    )}
                                </Box>
                            </Box>
                        </ModalContainer>
                    </>
                }
            />
        </>
    )
}

export default PopupNotPayments