import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'
import { Box, Button, Typography } from '@mui/material'

export const TypographyHead = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontFamily: 'kanit',
}))
export const NumberInTable = styled(Box)(({theme}) => ({
    backgroundColor: colors.themeMainColor,
    width:20,
    color: colors.white,
    borderRadius: 2
  }))
export const Boxjusttify = styled(Box)(({theme}) => ({
    display: "flex", justifyContent: "space-between", width: '100%',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        flexDirection:'column'
      },
  }))
export const Typographymodal = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontWeight:'bold',
    fontFamily: 'kanit',
}))
export const ButtonInTable = styled(Button)(({theme}) => ({
    backgroundColor: colors.green,
    borderRadius: 4,
    color: colors.white
  }))

export const boxcontainer = styled(Box)(({theme}) => ({
    paddingLeft: '45px',
    paddingRight: '45px',

}))

export const boxend = styled(Box)(({theme}) => ({
    display:'flex',
    justifyContent:'end'

}))

export const headerannoce = styled(Box)(({theme}) => ({    
    display: 'flex',
    backgroundColor: '#1B4BBB0D',
    paddingTop: '29px',
    paddingBottom: '29px',
    paddingLeft: '45px',
    paddingRight: '45px',

}))

export const flexmarginTop = styled(Box)(({theme}) => ({
    display: 'flex', 
    marginTop: '24px',
    marginBottom:'24px'  

}))

export const boxjusttify = styled(Box)(({theme}) => ({
    display: "flex", justifyContent: "space-between", width: '100%'

}))

export const boxupload = styled(Box)(({theme}) => ({
    border: '1px solid #D9D9D9', borderRadius: '8px', display: 'flex', justifyContent: 'center', padding: '51px', backgroundColor: '#fff'

}))

export const boxinsite = styled(Box)(({theme}) => ({
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "42px",
    marginTop: "27px",

}))

export const boxinsitebackground = styled(Box)(({theme}) => ({    
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    padding: "24px",

}))

export const filearray = styled(Box)(({theme}) => ({    
    border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'

}))

export const typographySuccess = styled(Typography)(({theme}) => ({    
    fontFamily: 'Kanit',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '20px',
    color: '#707070'

}))

export const flex = styled(Box)(({theme}) => ({    
    display:'flex'

}))

export const flexcolumcenter = styled(Box)(({theme}) => ({    
    display:'flex',justifyContent:'center',flexDirection:'column'

}))

export const flexHeader = styled(Box)(({theme}) => ({    
    display: 'flex',
    boxShadow: '1px 2px 9px #707070',
    padding: '1em',

}))

export const InputCheckBox = styled(Typography)(({ theme }) => ({
    '& $label': {
      fontFamily: 'Kanit',
      fontSize: '16px',
      fontWeight: 500,
    },
    '& $span': {
      fontSize: '16px',
    },
  }));
  
  export const Card = styled(Box)(({ theme }) => ({
    backgroundColor: colors.white,
    margin: 'auto',
    maxWidth: 400,
    width: '100%',
    borderRadius: '10px !important',
    opacity: 1,
    '& .card-body': {
      backgroundColor: colors.white,
      borderRadius: 10,
    },
    '&.forget-password .btn-submit': {
      lineHeight: '44px',
      marginTop: 14,
      boxShadow: 'none',
      fontSize: '1rem',
    },
  }));
  
  export const CardBody = styled(Box)(({ theme }) => ({
    backgroundColor: colors.white,
    margin: 'auto',
    width: '300px',
    padding: '30px 15px',
  }));
  
  export const Checked = styled(Box)(({ theme }) => ({
    color: colors.green,
  }));
  
  export const License = styled(Box)(({ theme }) => ({
    padding: 20,
  }));
  
  export const Hide = styled(Box)(({ theme }) => ({
    marginTop: '24px',
    height: '250px',
  }));
  
  export const SpaceBetween = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '15px',
    marginTop: '25px',
  }));
  
  export const CarRegister = styled(Box)(({ theme }) => ({
    border: '1px solid rgba(0, 0, 0, 0.3)',
    width: '100%',
    height: 'auto',
    borderRadius: '5px',
    marginTop: '15px',
    marginBottom: '35px',
    backgroundColor: colors.white,
    padding: '24px',
  }));
  
  export const CarInputRegister = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    backgroundColor: '#fafafa',
    marginTop: '15px',
    padding: '24px',
  }));

  export const FileContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }));

  export const PicBox = styled(Box)(({theme}) => ({
    border: '1px solid #D9D9D9', 
    borderRadius: '8px', 
    display: 'flex', 
    justifyContent: 'center', 
    width: '100%',
    height: 400,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'

}))