import IconProps from "./iconProps"
import { colors } from "../../constants/colors"
export default function TrackBill(props: IconProps) {
    return (
<svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_460_29358)">
        <path d="M21.375 56.9985H2.375C1.74511 56.9985 1.14102 56.7483 0.695621 56.3029C0.250223 55.8575 0 55.2534 0 54.6235C0 53.9936 0.250223 53.3895 0.695621 52.9441C1.14102 52.4988 1.74511 52.2485 2.375 52.2485H21.375C22.0049 52.2485 22.609 52.4988 23.0544 52.9441C23.4998 53.3895 23.75 53.9936 23.75 54.6235C23.75 55.2534 23.4998 55.8575 23.0544 56.3029C22.609 56.7483 22.0049 56.9985 21.375 56.9985Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
        <path d="M16.625 47.5H2.375C1.74511 47.5 1.14102 47.2498 0.695621 46.8044C0.250223 46.359 0 45.7549 0 45.125C0 44.4951 0.250223 43.891 0.695621 43.4456C1.14102 43.0002 1.74511 42.75 2.375 42.75H16.625C17.2549 42.75 17.859 43.0002 18.3044 43.4456C18.7498 43.891 19 44.4951 19 45.125C19 45.7549 18.7498 46.359 18.3044 46.8044C17.859 47.2498 17.2549 47.5 16.625 47.5Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
        <path d="M11.875 38.0015H2.375C1.74511 38.0015 1.14102 37.7513 0.695621 37.3059C0.250223 36.8605 0 36.2564 0 35.6265C0 34.9966 0.250223 34.3925 0.695621 33.9471C1.14102 33.5017 1.74511 33.2515 2.375 33.2515H11.875C12.5049 33.2515 13.109 33.5017 13.5544 33.9471C13.9998 34.3925 14.25 34.9966 14.25 35.6265C14.25 36.2564 13.9998 36.8605 13.5544 37.3059C13.109 37.7513 12.5049 38.0015 11.875 38.0015Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
        <path d="M30.8754 56.8922C30.2455 56.9202 29.6303 56.6969 29.1651 56.2713C28.6999 55.8457 28.4228 55.2528 28.3947 54.6229C28.3667 53.993 28.59 53.3778 29.0156 52.9126C29.4412 52.4473 30.0341 52.1702 30.664 52.1422C35.1586 51.731 39.443 50.0475 43.0153 47.2891C46.5876 44.5307 49.3 40.8114 50.8347 36.567C52.3694 32.3226 52.663 27.7287 51.681 23.3235C50.699 18.9183 48.482 14.884 45.2899 11.6934C42.0977 8.50266 38.0625 6.28759 33.6568 5.30761C29.2511 4.32762 24.6574 4.62329 20.4137 6.15998C16.17 7.69666 12.452 10.4107 9.69524 13.9843C6.93845 17.5579 5.257 21.8431 4.84782 26.3378C4.79113 26.9652 4.48754 27.5444 4.00383 27.9479C3.52013 28.3514 2.89594 28.5463 2.26857 28.4896C1.6412 28.4329 1.06205 28.1293 0.658516 27.6456C0.254985 27.1619 0.0601321 26.5377 0.116822 25.9103C0.779182 18.6032 4.23413 11.833 9.76247 7.00905C15.2908 2.18511 22.4666 -0.320915 29.7961 0.0126484C37.1256 0.346212 44.0441 3.49366 49.1114 8.79981C54.1787 14.106 57.0045 21.162 57.0004 28.4991C57.0368 35.6201 54.3913 42.494 49.5901 47.7532C44.789 53.0125 38.1841 56.2718 31.0892 56.8827C31.0179 56.8898 30.9443 56.8922 30.8754 56.8922Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
        <path d="M28.5004 14.25C27.8705 14.25 27.2664 14.5002 26.821 14.9456C26.3756 15.391 26.1254 15.9951 26.1254 16.625V28.5C26.1255 29.1298 26.3758 29.7338 26.8212 30.1791L33.9462 37.3041C34.3942 37.7368 34.9941 37.9761 35.6168 37.9707C36.2395 37.9653 36.8352 37.7155 37.2756 37.2752C37.7159 36.8349 37.9657 36.2392 37.9711 35.6165C37.9765 34.9937 37.7371 34.3938 37.3045 33.9459L30.8754 27.5168V16.625C30.8754 15.9951 30.6251 15.391 30.1797 14.9456C29.7343 14.5002 29.1303 14.25 28.5004 14.25Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
    </g>
    <defs>
        <clipPath id="clip0_460_29358">
            <rect width="57" height="57" fill="white" />
        </clipPath>
    </defs>
</svg>
    )}