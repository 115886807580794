import BaseAPI from '../../api'

const path = 'repair-type'

export interface RepairTypeCreate {
    repairTypeName?: string
    repairTypeNameEn?: string
}

export interface RepairInterface {
page?: number
pageLimit?: number
search?: string
status: string
}

export interface RepairTypeInterface {
    repairTypeId?: number
    repairTypeName: string
    repairTypeNameEn?: string
    status: string
  }

export default class RepairTypeApi extends BaseAPI {
    static findAll(props: RepairInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(payload: RepairTypeCreate) {
        return this.api.post(path , { ...payload })
    }

    static findById(id: number): Promise<RepairTypeInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: RepairTypeInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }


}
