import moment from "moment"
import { Dispatch } from "react"
import { STORAGE_KEY } from "./enum"


export type LoginContextData = {
    isLoggedIn: boolean
    token?: string
}

export type LoginContextPayload = {
    loginData: LoginContextData
    loginDispatch: Dispatch<any>
}

export type LoginContextAction = {
    type: string
    data: LoginContextData
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'

export function loginFailed(): LoginContextAction {
    return { type: LOGIN_FAILED, data: { isLoggedIn: false } }
}

export function logout(): LoginContextAction {
    return { type: LOGOUT, data: { isLoggedIn: false } }
}

export function loginSuccess(token: string, remember: boolean): LoginContextAction {
    localStorage.setItem(STORAGE_KEY.TOKEN, token)
    if (!remember) localStorage.setItem(STORAGE_KEY.SESSION_REMEMBER, String(moment().add(7, 'days').format('YYYY-MM-DD HH:mm')))
    return { type: LOGIN_SUCCESS, data: { isLoggedIn: true, token } }
}