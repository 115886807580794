export enum STORAGE_KEY {
    TOKEN = 'TOKEN',
    PERMISSION = 'PERMISSION',
    SESSION_REMEMBER = 'SESSION_REMEMBER',
    PROFILE = 'PROFILE',
}

export const removeAllStorage = () => {
    localStorage.removeItem(STORAGE_KEY.TOKEN)
    localStorage.removeItem(STORAGE_KEY.PERMISSION)
    localStorage.removeItem(STORAGE_KEY.SESSION_REMEMBER)
    localStorage.removeItem(STORAGE_KEY.PROFILE)
}