import { menuSeconds } from "../../constants/menu"
import RequsetForStall from "../../pages/stall/request-for-stall/requsetForStall"
import RenterDetail from "../../pages/stall/request-for-stall/renterDetail"
import { routeName } from "../routers-name"
import RequesterDetail from "../../pages/stall/request-for-stall/requesterDetail"
import HistoryApproval from "../../pages/stall/request-for-stall/historyAppoval"
const routeRequsetForStall = [
    {
      ...menuSeconds.REQUEST_FOR_STALL,
      key: menuSeconds.REQUEST_FOR_STALL.key,
      name: menuSeconds.REQUEST_FOR_STALL.name,
      textName: menuSeconds.REQUEST_FOR_STALL.textName,
      active: false,
      appBar: true,
      component: RequsetForStall
    },
    {
      ...menuSeconds.REQUEST_FOR_STALL,
      key: menuSeconds.REQUEST_FOR_STALL.key,
      name: menuSeconds.REQUEST_FOR_STALL.name,
      textName: menuSeconds.REQUEST_FOR_STALL.textName,
      path: routeName.requestForStall + '/renter',
      active: false,
      appBar: true,
      component: RenterDetail
    },
    {
      ...menuSeconds.REQUEST_FOR_STALL,
      key: menuSeconds.REQUEST_FOR_STALL.key,
      name: menuSeconds.REQUEST_FOR_STALL.name,
      textName: menuSeconds.REQUEST_FOR_STALL.textName,
      path: routeName.requestForStall + '/requester',
      active: false,
      appBar: true,
      component: RequesterDetail
    },
    {
      ...menuSeconds.REQUEST_FOR_STALL,
      key: menuSeconds.REQUEST_FOR_STALL.key,
      name: menuSeconds.REQUEST_FOR_STALL.name,
      textName: menuSeconds.REQUEST_FOR_STALL.textName,
      path: routeName.requestForStall + '/history-approval',
      active: false,
      appBar: true,
      component: HistoryApproval
    }
  ]
  
  export default routeRequsetForStall