import { Box } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

//** STYLE */
import {
  AccordionDetail,
  AccordionSummarys,
  Accordions,
  Boxcontainer,
  Boxgray,
  TypographyDetail,
  TypographyDetailsum,
  TypographyHead,
} from "./sueStyle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import upload from "../../assets/images/upload.png";
//** COMPONENT */
import { colors } from "../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../../app/slice/modal.slice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Col, Dropdown, Row } from "react-bootstrap";
import TableRowCommon from "../../component/table/TableRowCommon";
import HeaderCard from "../../component/cardCustom/headerCard";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import TableCustom from "../../component/table/tableCustom";
import ModalCustom from "../../component/modalCustom/modalCustom";
import Cloudupload from "../../assets/icon/cloudupload";
import ButtonCustom from "../../component/button/buttonCustom";
import { useDropzone } from "react-dropzone";
import { debouncedApiCall, getProfile } from "../../utils/app.utils";
import { decode } from 'js-base64';

//** API */
import ZoneApi from "../../api/zone/zone.api";
import InvoiceApi from "../../api/invoid/invoid.api";
import _ from "lodash";
import { numberFormat } from "../../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import ProsecuteApi from "../../api/prosecute/prosecute.api";
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import { dateTimeToView, dateToView } from "../../utils/date.utils";
import { ExportProps } from "../../constants/reportConstant";
import { icons, imgs } from "../../constants/images";
import Loading from "../../component/loading/loading";
import { useFunctionPrint } from "../../component/Print/print";
import InputDatePicker from "../../component/input/inputDatePicker";
import TableButton from "../../component/table/tableButton";

export default function Sue() {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const printRef = useRef<HTMLDivElement>(null);
  const isShow = useSelector((state: any) => state.modal.isShow);
  const [file, setFile] = useState<any>([]);
  const profile: any = JSON.parse(getProfile() || "{}");
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>();
  const [sortBy, setSortBy] = useState<string>("modifiedDate");
  const [sortType, setSortType] = useState<string>("ASC");
  const [search, setSearch] = useState<string>("");
  const [zoneId, setZoneId] = useState<number>();
  const [stallId, setStallId] = useState<number>();
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [stallOption, setStallOption] = useState<any[]>([]);
  const [invoiceData, setInvoiceData] = useState<any[]>([])
  const [sueDetail, setSueDetail] = useState<any[]>([])
  const [select, setSelectfile] = useState<any>();
  const [dateStart, setDateStart] = useState<string>(moment().subtract(90, 'days').format('YYYY-MM-DD'));
  const [printer, setPrinter] = useState<boolean>(false)
  const [printData, setPrintData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPdf, setIsPdf] = useState<boolean>(false);

  const isUser: boolean = JSON.parse(decode(profile))?.roleId === 4 ? true : false;
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(Number);
  const [keywordLeasesTypeData, setKeywordLeasesTypeData] = useState<string>("")

  const handleGetLeasesTypeData = useCallback(async () => {
    let condition: any = {}
    if (keywordLeasesTypeData) condition = { ...condition, search: keywordLeasesTypeData }
    await LeasesTypeApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          // console.log(res.data)
          setLeasesTypeData(res?.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [keywordLeasesTypeData])
  useEffect(() => {
    if (!isUser) {
      handleGetLeasesTypeData();
    }
  }, [handleGetLeasesTypeData])



  const dataPrint = useCallback(async () => {
    try {
      let condition: any = {
        page: page,
        pageLimit: pageLimit,
        // status: "NOT_PAY"
      }
      if (zoneId) condition = { ...condition, zoneId: zoneId };
      if (stallId) condition = { ...condition, stallId: stallId };
      if (sortBy) condition = { ...condition, sortBy: sortBy }
      if (leasesTypeId) condition = { ...condition, leasesTypeId: leasesTypeId }

      setIsLoading(true);
      const bidderPremilinary = await InvoiceApi.findAll(condition)
      const [testResult] = await Promise.all([
        bidderPremilinary,
      ]);
      setPrinter(true)
      const newApt: any = _.chain(testResult.data)
        .map((i: any) => { return i })
        .groupBy('stallId')
        .map((value: any) => value)
        .value()
      setPrintData(newApt);
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false);
    }
  }, [zoneId, page, pageLimit, stallId, sortBy, leasesTypeId])


  const loadData = useCallback(async () => {
    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      // status: "NOT_PAY"
      prosecute: 1
    }
    if (zoneId) condition = { ...condition, zoneId: zoneId };
    if (stallId) condition = { ...condition, stallId: stallId };
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (leasesTypeId) condition = { ...condition, leasesTypeId: leasesTypeId }
    if (roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }
    if (dateStart) condition = { ...condition, dateStart: dateStart }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res?.headers["total"]);
      const newApt: any = _.chain(res.data)
        .map((i: any) => { return i })
        .groupBy('stallId')
        .map((value: any) => value)
        .value()

      setInvoiceData(newApt)
    } else {
      setInvoiceData([])
    }
  }, [page, pageLimit, stallId, zoneId, sortBy, dateStart, leasesTypeId])

  useEffect(() => {
    loadData()
  }, [loadData]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf"
      ) {
        setFile([...file, acceptedFiles[0]]);
        // console.log(fileName[fileName?.length - 1])
        // if (fileName[fileName?.length - 1] === "application/pdf" || 'pdf') {
        //   setIsPdf(true)
        // } else {
        //   setSelectfile(URL.createObjectURL(files));
        //   setIsPdf(false)
        // }
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
    };
    const res = await ZoneApi.findAll(condition);

    if (res.status === 200) {
      setZoneOption(res.data);
    } else {
      setZoneOption([]);
    }
  };

  useEffect(() => {
    getZone();
  }, []);

  const headCells = [
    { id: "no", disablePadding: false, label: "#", width: 100, align: "center", },
    {
      id: "date",
      disablePadding: false,
      label: t("SUE.TABLE_HEAD.DOCUMENT_DATE"),
      align: "center",
    },
    {
      id: "billId",
      disablePadding: false,
      label: t("SUE.TABLE_HEAD.DOCUMENT_ID"),
      align: "center",
    },
    // { id: "name", disablePadding: false, label: t("SUE.TABLE_HEAD.NAME"), align: "center", },
    {
      id: "zoneName",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.ZONE"),
    },
    {
      id: "stallName",
      disablePadding: false,
      align: "center",
      label: t("SUE.MODAL.STALL"),
    },
    {
      id: "stallName",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },
    {
      id: "stallTypeName",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.TYPE_ZONE"),
    },
    {
      id: "amount",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.AMOUNT"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.STATUS"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.ACTION"),
    },
  ];

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#", align: "center", },
    {
      id: "date",
      disablePadding: false,
      label: t("SUE.TABLE_HEAD.DOCUMENT_DATE"),
      align: "center",
    },
    {
      id: "leasesId",
      disablePadding: false,
      label: t("SUE.TABLE_HEAD.DOCUMENT_ID"),
      align: "center",
    },
    // { id: "billId", disablePadding: false, label: t("SUE.TABLE_HEAD.BILL_ID") },
    { id: "name", disablePadding: false, label: t("SUE.TABLE_HEAD.NAME"), align: "center", },
    {
      id: "zoneName",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.ZONE"),
    },
    {
      id: "stallName",
      disablePadding: false,
      align: "center",
      label: t("SUE.MODAL.STALL"),
    },
    {
      id: "stallName",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },
    {
      id: "paymentDate",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.PAYMENTDATE"),
    },
    {
      id: "amount",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.AMOUNT"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.STATUS"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("SUE.TABLE_HEAD.ACTION"),
    },
  ];

  const handleClick = (data: any) => {
    setSueDetail(data)
    dispatch(showModal());
  };

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,

      columns: [
        { option: "TEXT", align: "center", label: no, },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{dateToView(d?.invoiceDate ? d?.invoiceDate : '') || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.invoiceNo || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p className="d-inline-block text-truncate">{d?.leaseAgreement?.firstname + " " + d?.leaseAgreement?.lastname || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stall?.stallNumber || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p className="text-danger">{dateToView(d?.invoiceDateEnd ? d?.invoiceDateEnd : '') || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{numberFormat(d?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)) || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "center", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p className="text-danger">{t(`STATUS.${d?.status}`)}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT",
          align: "center",
          width: 100,
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <TableButton title="พิมพ์เอกสาร" disabled={!objData?.some((list: any) => list?.status === "PRO_SECUTE")} icon={faPrint} onClick={() => {

              }} />
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: !objData?.some((list: any) => list?.status === "PRO_SECUTE") ? "#7e7e7e" : colors.themeMainColor

                  }}
                  onClick={() => { }}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                icon={faFile}
                style={{
                  cursor: "pointer"
                  , color: colors.themeMainColor
                }}
                onClick={() => handleClick(objData)}
              />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,


      columns: [
        { option: "TEXT", align: "center", width: 50, label: no, },
        {
          option: "COMPONENT", align: "component", component:
            <div className="d-flex flex-column">
              {objData.map((d: any) => {
                return <p>{dateToView(d?.invoiceDate ? d?.invoiceDate : '') || "-"}</p>
              })}
            </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.invoiceNo || "-"}</p>
            })}
          </div>
        },
        // {
        //   option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
        //     {objData.map((d: any) => {
        //       return <p>{d?.invoiceNo || "-"}</p>
        //     })}
        //   </div>
        // },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p className="d-inline-block text-truncate">{d?.leaseAgreement?.firstname + " " + d?.leaseAgreement?.lastname || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stall?.stallNumber || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p className="text-danger">{dateToView(d?.invoiceDateEnd ? d?.invoiceDateEnd : '') || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p>{numberFormat(d?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)) || "-"}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT", align: "component", component: <div className="d-flex flex-column">
            {objData.map((d: any) => {
              return <p className="text-danger">{t(`STATUS.${d?.status}`)}</p>
            })}
          </div>
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <TableButton title="รายละเอียด" disabled={objData?.some((list: any) => list?.status === "PRO_SECUTE")} icon={faFile} onClick={() => {
                handleClick(objData)
              }} />
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: objData?.some((list: any) => list?.status === "PRO_SECUTE") ? "#7e7e7e" : colors.themeMainColor
                  }}
                  onClick={() => objData?.some((list: any) => list?.status === "PRO_SECUTE") ? {} : handleClick(objData)}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const handlepreview = (x: any) => {
    const name = x.type.split(".");

    if (name[name?.length - 1] === "application/pdf") {

      window.open(URL.createObjectURL(x), "_blank");
    } else {
      setSelectfile(URL.createObjectURL(x));
    }

  };

  const handledelete = (index: number) => {
    setFile(file.filter((_: any, i: number) => index !== i));
    setSelectfile(null);
  };

  const handleSubmit = async () => {
    const invoiceId: any = []
    for (const data of sueDetail) {
      invoiceId.push({ invoiceId: data.invoiceId })
    }
    const payload = {
      leaseAgreementId: sueDetail[0]?.leaseAgreementId,
      prosecuteDate: moment(new Date()).format("YYYY-MM-DD"),
      remark: '',
      prosecuteDetail: invoiceId,
      status: "ACTIVE"
    }
    // console.log(payload)

    await ProsecuteApi.create(payload)
      .then(res => {
        if (res.status === 201) {
          notiSuccess(t('SUE.NOTI.SUCCESS', 'go', '/sue'))
          dispatch(closeModal())
        } else {
          notiError(t('SUE.NOTI.ERROR'))
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        loadData()
        window.location.reload()
      })
  }

  const handlePrint = useFunctionPrint({
    content: () => printRef.current,
    pageStyle: () => `@page { size: 1000.7007874px 1122.519685px;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });
  useEffect(() => {
    if (printData && printer) {
      handlePrint();
      setPrinter(false)
    }
  }, [printData]);
  const handleClearFile = () => {
    setSueDetail([])
    setFile([])
    setSelectfile(null)

  }

  // console.log(sueDetail)

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && <Loading show />}
      <HeaderCard text={t("SUE.FILTER.HEAD")} />
      {roleId === 4 && (
        <Boxcontainer>
          <Row>
            <Col className="my-2" lg={2} md={12} xs={12}>
              <InputTextField
                placeholder={t("SUE.FILTER.SEARCH")}
                label={t("SUE.FILTER.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </Col>
            <Col className="my-2" lg={2} md={12} xs={12}>
              <AutocompleteSelect
                options={zoneOption || []}
                getOptionLabel={(option: any) => option.zoneName}
                onChange={(e: any, val: any) => {
                  setZoneId(val?.zoneId);
                  setStallOption(val?.stall);
                }}
                labelId="zone-name"
                placeholder={t("SUE.FILTER.SEARCH_ZONE")}
              />
            </Col>
            <Col className="my-2" lg={2} md={12} xs={12}>
              <AutocompleteSelect
                options={stallOption || []}
                labelId="rental"
                placeholder={t("SUE.FILTER.SEARCH_STALL")}
                getOptionLabel={(option: any) => option.stallName}
                onChange={(e: any, val: any) => {
                  setStallId(val?.stallId)
                }}
              />
            </Col>
            <Col lg={2} md={6} sm={12} xs={12}></Col>
            <Col lg={2} md={6} sm={12} xs={12}></Col>
            <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
              {roleId !== 4 && <ButtonCustom style={{ width: '100%' }}
                type="button"
                textButton={t("SYSTEM.BUTTON.PRINT")}
                endIcon={<img src={icons.print} />}
                onClick={() => { }}
              />}
            </Col>
          </Row>

          <TableCustom
            tableFixedWidth
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={invoiceData && invoiceData.map((d: any) => { return d }).map((value: any, i: number) => {
              return renderData(value, i)
            })}
            onSort={() => { }}
            setPageLimit={(val: any) => setPageLimit(val)}
            setPage={(val: any) => setPage(val)}
            tableMinWidth={1200}
          />
        </Boxcontainer>
      )}

      {roleId !== 4 && (
        <Boxcontainer>
          <Row>
            {/* <Col className="my-2" lg={2} md={12} xs={12}>
              <InputDatePicker
                onChange={(e: any) => setDateStart(moment(e).format("YYYY-MM-DD"))}
                value={dateStart}
                allowClear
                onClear={() => setDateStart("")}
              />
            </Col> */}
            <Col className="my-2" lg={2} md={12} xs={12}>
              <InputTextField
                placeholder={t("SUE.FILTER.SEARCH")}
                label={t("SUE.FILTER.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value);
                  setPage(1);
                }}
              />
            </Col>
            <Col className="my-2" lg={2} md={12} xs={12}>
              <AutocompleteSelect
                options={zoneOption || []}
                getOptionLabel={(option: any) => option.zoneName}
                onChange={(e: any, val: any) => {
                  setZoneId(val?.zoneId)
                  setStallOption(val?.stall)
                }}
                labelId="zone-name"
                placeholder={t("SUE.FILTER.SEARCH_ZONE")}
              />
            </Col>
            <Col className="my-2" lg={2} md={12} xs={12}>
              <AutocompleteSelect
                options={stallOption || []}
                getOptionLabel={(option: any) => option.stallName}
                labelId="rental"
                placeholder={t("SUE.FILTER.SEARCH_STALL")}
                onChange={(e: any, val: any) => {
                  setStallId(val?.stallId)
                }}
              />
            </Col>
            {!isUser && (
              <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  options={leasesTypeData || []}
                  value={leasesTypeData
                    ? leasesTypeData?.find(
                      (data: any) => data?.leasesTypeId === leasesTypeId
                    )
                    : null}
                  onInputChange={(_, value) => {
                    debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                  }}
                  placeholder={t("ประเภทผู้ใช้งาน")}
                  getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                  labelId="leasesType"
                  onChange={(_, newValue) => {
                    setLeasesTypeId(newValue?.leasesTypeId);
                  }}
                />
              </Col>
            )}
            <Col lg={2} md={6} sm={12} xs={12}></Col>
            <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
              <ButtonCustom style={{ width: '100%' }}
                type="button"
                textButton={t("SYSTEM.BUTTON.PRINT")}
                endIcon={<img src={icons.print} />}
                onClick={dataPrint}
              />
            </Col>
          </Row>

          <TableCustom
            tableFixedWidth
            headCells={headCellsAdmin}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={invoiceData && invoiceData.map((d: any) => { return d }).map((value: any, i: number) => {
              return renderDataAdmin(value, i)
            })}
            onSort={() => { }}
            setPageLimit={(value: any) => setPageLimit(value)}
            setPage={(value: any) => setPage(value)}
            tableMinWidth={1500}
          />
        </Boxcontainer>
      )}

      {JSON.parse(decode(profile))?.roleId !== 4 && <ModalCustom
        closeButton
        title={t("SUE.MODAL.TITLE")}
        size="xl"
        onClose={() => {
          handleClearFile()
        }}
        modalScroll
        onSubmit={handleSubmit}
        textBtnConfirm="บันทึก"
        component={
          <Box>
            <Boxgray>
              <Row>
                <Col lg={6} md={12} xs={12}>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <TypographyHead>
                        {t("SUE.MODAL.DETAIL_STALL")}
                      </TypographyHead>
                    </Col>
                    <Col lg={3} md={6} xs={6} className="my-2">
                      <TypographyDetail>
                        {t("SUE.MODAL.ZONE_STALL")}:
                      </TypographyDetail>
                    </Col>
                    <Col lg={3} md={6} xs={6} className="my-2">
                      <TypographyDetail>{sueDetail[0]?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-"}</TypographyDetail>
                    </Col>
                    <Col lg={3} md={6} xs={6}>
                      <TypographyDetail className="my-2">
                        {t("SUE.MODAL.STALL")}:
                      </TypographyDetail>
                    </Col>
                    <Col lg={3} md={6} xs={6}>
                      <TypographyDetail className="my-2">
                        {sueDetail[0]?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-"}
                      </TypographyDetail>
                    </Col>
                    <Col lg={3} md={6} xs={6}>
                      <TypographyDetail className="my-2">
                        {t("ชื่อผู้เช่า")}:
                      </TypographyDetail>
                    </Col>
                    <Col lg={3} md={6} xs={6}>
                      <TypographyDetail className="my-2">
                        {`${sueDetail[0]?.leaseAgreement?.firstname || "-"} ${sueDetail[0]?.leaseAgreement?.lastname || "-"}`}
                      </TypographyDetail>
                    </Col>
                    <Col lg={12} xs={12} className="my-2">
                      <TypographyHead>
                        {t("SUE.MODAL.LIABILITIES_DETAIL")}
                      </TypographyHead>
                    </Col>
                    {sueDetail && sueDetail.map((d: any, i: number) => {
                      return (
                        <Accordions>
                          <AccordionSummarys expandIcon={<ExpandMoreIcon />}>
                            <Col lg={4} className="my-2">
                              <TypographyDetail>
                                {t("SUE.MODAL.OVERDUE_LIST")}
                              </TypographyDetail>
                            </Col>
                            <Col lg={4} className="my-2">
                              <TypographyDetail>
                                {moment(d?.invoiceDateEnd).format('DD/MM/YY')}
                              </TypographyDetail>
                            </Col>
                            <Col lg={4} className="my-2">
                              <TypographyDetail className="ml-2">
                                {t("SUE.MODAL.PRICE")} :
                                {numberFormat(d?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0) || 0)}
                              </TypographyDetail>
                            </Col>
                          </AccordionSummarys>
                          <AccordionDetail>
                            {d?.invoiceDetail?.map((value: any) => {
                              return (
                                <Row>
                                  <Col xs={4} className="my-2">
                                    <TypographyDetail>
                                      {value?.paymentService?.paymentName}
                                    </TypographyDetail>
                                  </Col>
                                  <Col xs={4} className="my-2">
                                    {numberFormat(value?.amount || 0)}
                                  </Col>
                                  <Col xs={4} className="my-2">
                                    {t("SUE.MODAL.UNIT")}
                                  </Col>
                                </Row>
                              )
                            })}

                          </AccordionDetail>
                        </Accordions>
                      )
                    })}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        margin: "24px 0",
                      }}
                    >
                      <TypographyDetail>
                        {'ยอดรวมทั้งหมด '}
                        {numberFormat(sueDetail?.reduce((x: any, y: any) => x + y?.invoiceDetail?.reduce((a: any, b: any) => a + Number(b?.amount), 0), 0)) || "-"} {" "}
                        {t("SUE.MODAL.UNIT")}


                      </TypographyDetail>
                    </Box>
                  </Row>
                </Col>
                <Col lg={6} md={12}>
                  <Box
                    sx={{
                      backgroundColor: "#FAFAFA",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <div {...getRootProps()}>
                      <input type="file" multiple {...getInputProps()} />
                      {select ? (
                        <img
                          src={select}
                          alt="Thumb"
                          style={{ width: "100%", height: "250px" }}
                        />
                      ) : (
                        <img src={upload} style={{ width: "100%" }} />
                      )}
                    </div>
                  </Box>
                  {file?.map((e: any, index: number) => (
                    <>
                      <Col item lg={12} xs={12} className="my-2 d-flex justify-content-start align-items-center">
                        <div style={{ width: 100 }} className="p-1">
                          {`ไฟล์ที่ ${index + 1}`}
                        </div>
                        <div className="flex-1 w-100" style={{ border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px' }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{e.name}</Box>
                            <Box>
                              <VisibilityIcon
                                onClick={() => handlepreview(e)}
                                sx={{ cursor: "pointer", marginRight: "10px" }}
                              />
                              <DeleteIcon
                                onClick={() => handledelete(index)}
                                color="error"
                                sx={{ cursor: "pointer" }}
                              />
                            </Box>
                          </Box>
                        </div>
                      </Col>
                    </>
                  ))}
                </Col>
              </Row>

            </Boxgray>
            {/* <ButtonCustom
                onClick={() => { handleSubmit() }}
                textButton="บันทึก"
                style={{ width: "100%", marginTop: "24px" }}
              /> */}
          </Box>
        }
      />}

      <div className="print-show" ref={printRef}>
        <ExportData
          headCells={""}
          componant={
            <>
              {printer &&
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          #
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.TABLE_HEAD.DOCUMENT_DATE")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.TABLE_HEAD.DOCUMENT_ID")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.TABLE_HEAD.NAME")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.TABLE_HEAD.ZONE")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.MODAL.STALL")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.TABLE_HEAD.PAYMENTDATE")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px" }}>
                          {t("SUE.MODAL.PRICE")}
                        </th>
                        <th scope="col" style={{ fontSize: "16px", textAlign: 'center' }}>
                          {t("SUE.TABLE_HEAD.STATUS")}
                        </th>
                      </tr>
                    </thead>
                    {printData && printData.map((objData: any, i: number) => {
                      const setMethod = objData?.method === "GET" ?
                        t('SYSTEM.METHOD.GET') : objData?.method === "POST" ?
                          t('SYSTEM.METHOD.POST') : objData?.method === "PATCH" ?
                            t('SYSTEM.METHOD.PATCH') : t('SYSTEM.METHOD.DELETE')
                      const no = page * pageLimit - pageLimit + i + 1
                      return (
                        <tbody>
                          <tr>
                            <td style={{ fontSize: "16px" }} scope="row">
                              {no}
                            </td>
                            <td style={{ fontSize: "16px" }} scope="row">
                              <div className="d-flex flex-column">
                                {objData?.map((d: any) => {
                                  return <p>{dateToView(d?.invoiceDate ? d?.invoiceDate : '') || "-"}</p>
                                })}
                              </div>
                            </td>
                            <td style={{ fontSize: "16px" }} scope="row">
                              {objData.map((d: any) => {
                                return <p>{d?.invoiceNo || "-"}</p>
                              })}
                            </td>
                            <td style={{ fontSize: "16px" }} scope="row">
                              {objData.map((d: any) => {
                                return <p className="text-truncate">{d?.leaseAgreement?.firstname + " " + d?.leaseAgreement?.lastname || "-"}</p>
                              })}
                            </td>
                            <td style={{ fontSize: "16px" }} className="text-center" scope="row">
                              {objData.map((d: any) => {
                                return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-"}</p>
                              })}
                            </td>
                            <td style={{ fontSize: "16px" }} className="text-center" scope="row">
                              {objData.map((d: any) => {
                                return <p>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-"}</p>
                              })}
                            </td>
                            <td style={{ fontSize: "16px" }} className="text-center" scope="row">
                              {objData.map((d: any) => {
                                return <p className="text-danger">{dateToView(d?.invoiceDateEnd ? d?.invoiceDateEnd : '') || "-"}</p>
                              })}
                            </td>
                            <td style={{ fontSize: "16px" }} className="text-center" scope="row">
                              {objData.map((d: any) => {
                                return <p>{numberFormat(d?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)) || "-"}</p>
                              })}
                            </td>
                            <td style={{ fontSize: "16px", width: 120 }} className="text-center" scope="row">
                              {objData.map((d: any) => {
                                return <p className={`
                              ${d?.status === 'PAY' ? 'text-primary' : 'text-danger'}`}>{t(`STATUS.${d?.status}`)}</p>
                              })}
                            </td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>
                </div>}
            </>
          }
        />
      </div>

    </Box>

  );
}


export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
