import React from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Box, Button } from '@mui/material'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { styled } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { colors } from '../../constants/colors'
import { fileUrl } from '../../api/api'
import { onImgError } from '../../constants/images'



type PreviewImageProps = {
  fileName?: string
  filePath?: string
  fullPath?: string
  base64?: string
  fileExtension?: string
}

type Image = {
  image?: PreviewImageProps
  imageFile?: string
  objectFit?: string
  height?:string;
}

const ToolsBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: 0,
  transform: 'translateX(-50%)',
  zIndex: 1,
  backgroundColor: colors.white,
  borderRadius: 100,
  padding: '6px 16px',
  [theme.breakpoints.down('lg')]: {
    padding: '4px 10px'
  }
}))

const Tools = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 'unset',
  width: 30,
  height: 30,
  backgroundColor: colors.extraLightGray,
  borderRadius: 100,
  color: colors.textPrimary,
  svg: {
    fontSize: 18
  },
  '&.btn-reset-position': {
    minWidth: 56,
    width: 'auto',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 2,
    textTransform: 'capitalize'
  },
  '&:hover': {
    backgroundColor: colors.lightGray
  },
  [theme.breakpoints.down('lg')]: {
    width: 24,
    height: 24
  }
}))

export default function PreviewImage(props: Image) {
  const { t } = useTranslation()

  const renderImage = (img: any) => {
    return `${fileUrl}/${img.fullPath}`
  }

  return (
    <Box className="position-relative h-100 overflow-hidden" style={{ border: `1px solid ${colors.lightGray}` }}>
      <TransformWrapper centerOnInit>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <Tools onClick={() => resetTransform()} className="btn-reset-position">
              {t('BUTTON.RESET')}
            </Tools>
            <ToolsBox className="d-flex align-items-center justify-content-center mb-2">
              <Tools onClick={() => zoomOut()} className="mr-1 mr-xl-2">
                <RemoveIcon />
              </Tools>
              <Tools onClick={() => zoomIn()} className="ml-1 ml-xl-2">
                <AddIcon />
              </Tools>
            </ToolsBox>
            <TransformComponent wrapperClass="w-100 h-100" contentClass="w-100" contentStyle={{ cursor: 'move', height: '100%', backgroundColor: colors.lightGray }}>
              {props.image?.base64 ? (
                <img src={`data:image/${props.image?.fileExtension};base64,${props.image?.base64}`} alt={props.image?.fileName} className="w-100 m-auto" onError={onImgError} />
              ) : (
                <img src={props.image ? renderImage(props.image) : props.imageFile} alt={props.image?.fileName} className={`w-100 m-auto ${props.objectFit && `object-fit-${props.objectFit}`}`} onError={onImgError} />
              )}
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </Box>
  )
}
