import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

/** COMPONENT */
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import FilterSelect from "../../component/select/filterSelect";
import HeaderCard from "../../component/cardCustom/headerCard";
import TableRowCommon from "../../component/table/TableRowCommon";
import ModalCustom from "../../component/modalCustom/modalCustom";
import { colors } from "../../constants/colors";
import {
  closeModal,
  resetModal,
  showModal,
  submitModal,
  unSubmitModal,
} from "../../app/slice/modal.slice";
import { Col, Row } from "react-bootstrap";
import { getProfile } from "../../utils/app.utils";
import { useFunctionPrint } from "../../component/Print/print";
import logo from "../../assets/logo/logotest.png";
import { ExportProps } from "../../constants/reportConstant";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import { swalActive } from "../../component/notification/swal";
import PopupReqCertificate from "./popupRequestCertificate";
import { dateToView, fullDate } from "../../utils/date.utils";

/** STYLE */
import * as Style from "./certificate.style";

/** API */
import CertificateAPI from "../../api/stall/certificate/certificate";
import ZoneApi from "../../api/zone/zone.api";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import moment from "moment";
import Loading from "../../component/loading/loading";
import { decode } from 'js-base64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faCheck, faEye, faFile, faFileZipper, faPrint, faQuestion, faXmark } from "@fortawesome/free-solid-svg-icons";
import ButtonCustom from "../../component/button/buttonCustom";
import PopupAddCer from "./PopupAddCer";
import LeaseAgreementApi from "../../api/stall/leaseAgreement/leaseAgreement.api";
import AppointmentApi from "../../api/stall/appointment/appointment";
import { imgs } from "../../constants/images";
import PopupViewDetailCustomer from "../stall/stall-agreement/popup/popupViewDetailCustomer";
import { PiCertificateFill } from "react-icons/pi";
import InvoiceApi from "../../api/invoid/invoid.api";
import CancelContractApi from "../../api/stall/cancelcontract/cancelcontract";
import ModalHaveDept from "../../component/modalCustom/modalHaveDept";
import { isApporve, isCreate, isDelete, isExport, isUpdate } from "../../utils/permission.utils";



const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isExport: isExport(),
  isApporve: isApporve()
};

const initStateErrorMessage = {};

export default function Certificate() {
  const componentRef = useRef<HTMLDivElement>(null);
  const dateApprove = moment().format("YYYY-MM-DD");
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const componentApproval = useRef<HTMLDivElement>(null);
  const profile: any = JSON.parse(getProfile() || '{}')

  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId)
  const [employeeId, setEmployeeId] = useState<number>(JSON.parse(decode(profile))?.empId)
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('DESC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')
  const [certificateData, setCertificateData] = useState<any[]>([])
  const [zoneName, setZoneName] = useState<string>("")
  const [stallTypeName, setStallTypeName] = useState<string>("")
  const [stallName, setStallName] = useState<string>("")
  const [reqCertificate, setReqCertificate] = useState<boolean>(false)
  const [certificateId, setCertificateId] = useState(Number)
  const [remark, setRemark] = useState<string>("")
  const [firstname, setFirstname] = useState<string>("")
  const [lastname, setLastname] = useState<string>("")
  const [leaseAgreementDateStart, setLeaseAgreementDateStart] = useState<string>("")
  const [leaseAgreementDateEnd, setLeaseAgreementDateEnd] = useState<string>("")
  const [popupReqCer, setPopupReqCer] = useState<boolean>(false);
  const [reqCertificateDetail, setReqCertificateDetail] = useState<boolean>(false)
  const [zoneOption, setZoneOption] = useState<any[]>([])
  const [stallOptiion, setStallOption] = useState<any[]>([])
  const [zoneId, setZoneId] = useState<number | undefined>();
  const [stallId, setStallId] = useState<number | undefined>();
  const [printCertificate, setPrintCertificate] = useState<any>();
  const [typePrint, setTypePrint] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false);

  const [addCertificate, setAddCertificate] = useState<boolean>(false);
  const [leaseData, getLeaseData] = useState<any[]>([]);
  const [printCustomerSelect, setPrintCustomerSelect] = useState<any[]>([])
  const [appointmentId, setAppointmentId] = useState<number>();
  const [printSelectAdmin, setPrintSelectAdmin] = useState<any[]>([])
  const [printAppointment, setPrintAppointment] = useState<any[]>([])
  const [modalType, setModalType] = useState<string>("")
  const [dataModal, setDatamodal] = useState<any>()
  const [reqFirstname, setReqFirstname] = useState<string>("");
  const [reqLastname, setReqLastname] = useState<string>("");
  const [reqUserId, setReqUserId] = useState<number>(JSON.parse(decode(profile))?.userId);
  const [leaseAgreementId, setLeaseAgreementId] = useState<number>(Number);
  const [branchId, setBranchId] = useState<number>(Number)
  const [popupCancelContract, setPopupCancelContract] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any[]>([])
  const [modeApprove, setModeApprove] = useState<boolean>(false);
  const [errorMessageRemark, setErrorMessageRemark] = useState<string>("");
  const [requestDetail, setRequestDetail] = useState<string>("");
  const [errorMessageRequestDetail, setErrorMessageRequestDetail] = useState<string>("");
  const [depted, setDepted] = useState<boolean>(false)
  const [certificateBidderDetail, setCertificateBidderDetail] = useState<any>(null);
  const [certificateDetail, setCertificateDetail] = useState<any>(null);


  type formApprove = {
    reason: string;
    noDoc: string;
  }
  const [errorMessageRemarkKorSor, setErrorMessageRemarkKorSor] = useState<formApprove>({
    noDoc: ``,
    reason: ``,
  });
  const [formApproveCertificate, setFormApproveCertificate] = useState<formApprove>({
    noDoc: "",
    reason: ""
  })

  const checkInvoice = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 10,
      userId: JSON.parse(decode(profile))?.userId,
      status: "NOT_PAY"
    }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      if (res.data.length >= 1) {
        setDepted(true)
        dispatch(showModal())
      }

    } else {

    }
  }

  useEffect(() => {
    checkInvoice()
  }, [])

  const onRequestCertificate = (data: any) => {
    const { firstname, lastname, leaseAgreementApprove, leaseAgreementDateStart, leaseAgreementDateEnd, leaseAgreementId, branchId, } = data;
    setReqFirstname(firstname);
    setReqLastname(lastname);
    setZoneName(leaseAgreementApprove?.bidder?.zoneName);
    setStallName(leaseAgreementApprove?.bidder?.stallName);
    setStallTypeName(leaseAgreementApprove?.bidder?.stallTypeName);
    setLeaseAgreementDateStart(leaseAgreementDateStart);
    setLeaseAgreementDateEnd(leaseAgreementDateEnd);
    setReqUserId(reqUserId);
    setLeaseAgreementId(leaseAgreementId);
    setBranchId(branchId);
    setPopupReqCer(true);
    setPopupCancelContract(false)
    handleCloseModal(false)
    // dispatch(showModal());
  };

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
  }, []);

  const loadData = useCallback(async () => {
    let condition: any = {
      sortBy: "transferDate"
    }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status !== 'ALL') condition = { ...condition, status: status }
    if (JSON.parse(decode(profile))?.roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }

    const res = await CertificateAPI.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setCertificateData(res.data)

    } else {
      setRowCount(0)
      setCertificateData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status, zoneId, stallId])

  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      status: "CONTRACT",
      skipcalculation: 0
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
    } else {
      setZoneOption([])
    }
  };

  useEffect(() => {
    getZone()
  }, [])

  useEffect(() => {
    loadData()
  }, [page, pageLimit, search, sortBy, sortType, status, zoneId, stallId])


  const dataCertificate = useCallback(async (id: any) => {
    try {
      setLoading(true);
      const certificateFind = await CertificateAPI.findById(id)
      const [dataPrint] = await Promise.all([
        certificateFind,
      ]);
      setTypePrint("PRINT_CERTIFICATE")
      setPrintCertificate(dataPrint.data);
      // console.log(dataPrint.data);
      console.log(dataPrint.data)
      setFormApproveCertificate((prev) => ({ ...prev, noDoc: dataPrint?.data?.certificateApporve[0]?.noDoc, reason: dataPrint?.data?.certificateApporve[0]?.reason }))
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [])

  const handlePrintCertificate = useFunctionPrint({
    content: () => componentApproval.current,
    pageStyle: () => `@page { size:2480px 3500px;  margin: 8.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printCertificate && typePrint === "PRINT_CERTIFICATE") {
      handlePrintCertificate();
    }
  }, [printCertificate])

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handlePageLimitChange = (newPageLimit: number) => setPageLimit(newPageLimit);

  const handleChangeStatus = (newStatus: string) => setStatus(newStatus);

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType);
    setSortBy(sortBy);
  };

  const onDisapproved = (data: any) => {
    const { leaseAgreement, certificateId } = data;
    setCertificateId(certificateId);
    setFirstname(leaseAgreement.firstname);
    setLastname(leaseAgreement.lastname);
    setReqCertificate(true);
    dispatch(showModal());
  };
  const onApproveAccept = (data: any) => {
    const { leaseAgreement, certificateId } = data;
    setCertificateId(certificateId);
    setFirstname(leaseAgreement.firstname);
    setLastname(leaseAgreement.lastname);
  };

  const onViewDetail = (data: any) => {
    const { leaseAgreement, remark } = data;
    setPopupReqCer(false);
    setReqCertificateDetail(true);
    setFirstname(leaseAgreement.firstname);
    setLastname(leaseAgreement.lastname);
    setRemark(remark);
    dispatch(showModal());
  };

  const onViewDocument = (data: any) => {
    const { leaseAgreement } = data;
    setFirstname(leaseAgreement.firstname);
    setLastname(leaseAgreement.lastname);
    setZoneName(leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName);
    setStallName(leaseAgreement?.leaseAgreementApprove?.bidder?.stallName);
    setStallTypeName(leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName);
    setLeaseAgreementDateStart(leaseAgreement?.leaseAgreementDateStart);
    setLeaseAgreementDateEnd(leaseAgreement?.leaseAgreementDateEnd);
    setPopupReqCer(true);
    dispatch(showModal());
  };

  const resetForm = () => {
    clearErrorMessage();
    setRemark("");
    setEmployeeId(0);
    setCertificateId(0);
  };

  const onClose = () => {
    setPopupReqCer(false);
    setReqCertificateDetail(false);
    setReqCertificate(false);
    dispatch(closeModal());
  };

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    { id: "documentDate", disablePadding: false, label: t("วันที่ขอเอกสาร"), sortable: true, align: "center", },
    { id: "documentId", disablePadding: false, label: t("เลขที่เอกสาร"), sortable: true, align: "center", },
    // { id: "name", disablePadding: false, label: t("ชื่อผู้เช่าแผงค้า"), align: "left", },
    // { id: "zone", disablePadding: false, label: t("โซน"), align: "left", },
    // { id: "stall", disablePadding: false, label: t("แผงค้า"), align: "left", },
    // { id: "typeZone", disablePadding: false, label: t("ประเภท"), align: "left", },
    { id: "endContact", disablePadding: false, label: t("ให้ได้ถึง"), align: "center", },
    { id: "status", disablePadding: false, align: "center", label: t("สถานะ"), },
    { id: "remark", disablePadding: false, align: "center", label: t("หมายเหตุ") },
    { id: "action", disablePadding: false, label: t("Action") },
  ];

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#", align: "center", },
    { id: "documentDate", disablePadding: false, label: t("วันที่เอกสาร"), align: "center", },
    { id: "name", disablePadding: false, label: t("ชื่อผู้เช่าแผงค้า"), align: "center", },
    { id: "zone", disablePadding: false, label: t("โซน"), align: "center", },
    { id: "stall", disablePadding: false, label: t("แผงค้า"), align: "center", },
    { id: "stall", disablePadding: false, label: t("เลขที่แผง"), align: "center", },
    { id: "typeZone", disablePadding: false, label: t("ประเภท") },
    { id: "endContact", disablePadding: false, label: t("วันที่สิ้นสุดเอกสาร"), align: "center", },
    { id: "status", disablePadding: false, align: "center", label: t("สถานะ") },
    { id: "request", disablePadding: false, align: "center", label: t("คำขอ") },
    {
      id: "modifiedId",
      disablePadding: false,
      align: "center",
      label: t("ผู้บันทึก"),
    },
    { id: "action", disablePadding: false, label: t("Action") },
  ];

  const renderData = (objData: any, no: any) => {
    const {
      requestDetail,
      createDate,
      certificateNo,
      leaseAgreement,
      certificateDateEnd,
      certificateApporve,
      action,
      status
    } = objData;
    no = page * pageLimit - pageLimit + no + 1;
    const setStatus =
      status === "WAITING"
        ? t("CERTIFICATE.STATUS.WAITING")
        : status === "APPROVE"
          ? t("CERTIFICATE.STATUS.APPROVE")
          : t("CERTIFICATE.STATUS.NOT_APPROVE");

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(createDate) },
        { option: "TEXT", align: "center", label: certificateNo },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label:
        //     leaseAgreement?.firstname + " " + leaseAgreement?.lastname || "-",
        // },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-",
        // },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: leaseAgreement?.leaseAgreementApprove?.bidder.stallName || "-",
        // },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: leaseAgreement?.leaseAgreementApprove?.bidder.stallTypeName || "-",
        // },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(certificateDateEnd),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              style={{
                color:
                  status === "WAITING"
                    ? colors.lightOrange :
                    status === "APPROVE"
                      ? colors.green
                      : colors.red,
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              {setStatus}
            </Typography>
            // <Button
            //   style={{
            //     backgroundColor:
            //       status === "WAITING"
            //         ? colors.lightOrange :
            //         status === "APPROVE"
            //           ? colors.green
            //           : colors.red,
            //     color: colors.white,
            //     margin: "auto",
            //     borderRadius: 4,
            //     padding: 'auto',
            //     height: "36px",
            //     width: '100%'
            //   }}
            // >
            //   {setStatus}
            // </Button>
          ),
        },
        {
          option: "TEXT",
          align: "center",
          label: certificateApporve.length > 0 ? certificateApporve[0]?.remark : "-",
        },

        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton disabled={status === "NOT_APPROVE" || status === 'WAITING'} onClick={() => {
                  setCertificateDetail(objData?.leaseAgreement)
                  dataCertificate(objData.certificateId)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "NOT_APPROVE" || status === 'WAITING' ? colors.gray :
                        colors.green,
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  onViewDocument(objRenderData.obj)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color:
                        colors.themeMainColor,
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: status === "NOT_APPROVE" || status === 'WAITING' ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => {
                    if (status === "NOT_APPROVE" || status === 'WAITING') {
                    } else {
                      // console.log(objData)
                      // setIsShowKorSor(true)
                      setCertificateDetail(objData?.leaseAgreement)
                      dataCertificate(objData.certificateId)
                    }
                  }}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => onViewDocument(objRenderData.obj)}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const renderDataAdmin = (objData: any, no: any) => {
    const {
      requestDetail,
      createDate,
      leaseAgreement,
      certificateDateEnd,
      modifiedBy,
      modifiedDate,
      status,
    } = objData;
    no = page * pageLimit - pageLimit + no + 1;
    const setStatus =
      status === "WAITING"
        ? t("CERTIFICATE.STATUS.WAITING")
        : status === "APPROVE"
          ? t("CERTIFICATE.STATUS.APPROVE")
          : t("CERTIFICATE.STATUS.NOT_APPROVE");

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(createDate) },
        {
          option: "TEXT",
          align: "center",
          label:
            leaseAgreement?.firstname + " " + leaseAgreement?.lastname || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder.zoneName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder.stallName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder.stall?.stallNumber || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder.stallTypeName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(certificateDateEnd),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              style={{
                color:
                  status === "WAITING"
                    ? colors.lightOrange
                    : status === "APPROVE"
                      ? colors.green
                      : colors.red,
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              {setStatus}
            </Typography>
            // <Box>

            //   <Button
            //     style={{
            //       backgroundColor:
            //         status === "WAITING"
            //           ? colors.lightOrange
            //           : status === "APPROVE"
            //             ? colors.green
            //             : colors.red,
            //       color: colors.white,
            //       textDecoration: "none",
            //       borderRadius: 4,
            //       height: "36px",
            //       margin: "auto",
            //       width: '100%'
            //     }}
            //   >
            //     {setStatus}
            //   </Button>
            // </Box>
          ),
        },
        {
          option: "TEXT",
          align: "center",
          label: requestDetail ? requestDetail : "-",
        },
        {
          option: "UPDATE_BY",
          align: "center",
          label: {
            updatedBy: `${modifiedBy?.firstname} ${modifiedBy?.lastname}`,
            updatedAt: modifiedDate,
          },
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="อนุมัติ">
                <IconButton disabled={status === "WAITING" && permissions.isApporve.disabled} onClick={() => {
                  handleSetModeApprove(true)
                  onApproveAccept(objRenderData.obj)
                }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "WAITING" ?
                        permissions.isApporve.disabled ? colors.gray :
                          colors.green : colors.gray,
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    cursor: "pointer"
                    , color: status === "WAITING" ?
                      permissions.isApporve.disabled ? colors.gray :
                        colors.green : colors.gray,
                  }}
                  onClick={() => {
                    if (status === "WAITING") {
                      if (permissions.isApporve.disabled) {
                        return;
                      } else {
                        handleSetModeApprove(true)
                        onApproveAccept(objRenderData.obj)
                        // onApprove(objRenderData.obj)
                      }

                    } else { }
                  }}
                />
              </div> */}
              <Tooltip title="ไม่อนุมัติ">
                <IconButton disabled={status === "WAITING" && permissions.isApporve.disabled} onClick={() => {
                  onDisapproved(objRenderData.obj)
                }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "WAITING" ?
                        permissions.isApporve.disabled ? colors.gray :
                          colors.red : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{
                    cursor: "pointer"
                    , color: status === "WAITING" ?
                      permissions.isApporve.disabled ? colors.gray :
                        colors.red : colors.gray
                  }}
                  onClick={() => {
                    if (status === "WAITING") {
                      if (permissions.isApporve.disabled) {
                        return;
                      } else {
                        onDisapproved(objRenderData.obj)
                      }
                    }
                    else { }
                  }}
                />
              </div> */}
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  onViewDocument(objRenderData.obj)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => onViewDocument(objRenderData.obj)}
                />
              </div> */}
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton disabled={status === "NOT_APPROVE"} onClick={() => {
                  setCertificateBidderDetail(objData?.leaseAgreement?.leaseAgreementApprove?.bidder || null)
                  dataCertificate(objData.certificateId)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "NOT_APPROVE" ? colors.gray : colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: status === "NOT_APPROVE" ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => {
                    if (status === "NOT_APPROVE") {
                    } else {
                      // setIsShowKorSor(true)
                      setCertificateBidderDetail(objData?.leaseAgreement?.leaseAgreementApprove?.bidder || null)
                      dataCertificate(objData.certificateId)
                    }
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };

    return (
      <TableRowCommon {...objRenderData} />
    )
  };
  const handleClearRemark = () => {
    setRemark("")
  }
  const onApprove = async () => {

    if (!formApproveCertificate.noDoc) {
      setErrorMessageRemarkKorSor((prev) => ({ ...prev, noDoc: 'ระบุเลขที่ กษ.' }))
      return;
    } else if (!formApproveCertificate.reason) {
      setErrorMessageRemarkKorSor((prev) => ({ ...prev, reason: 'ระบุเหตุผลในการขอใบรับรองของผู้ยื่น' }))
      return;
    } else if (!remark) {
      setErrorMessageRemark('ระบุหมายเหตุ')
      return;
    }
    setLoading(true)
    await CertificateAPI.approve(dateApprove, certificateId, remark, employeeId, formApproveCertificate.noDoc, formApproveCertificate.reason, "APPROVE")
      .then((res) => {
        if (res.status === 201) {
          setLoading(false)
          notiSuccess(t('CERTIFICATE.MESSAGE.SUCCESS.APPROVE'), '', null, () => setModeApprove(false))
          setFormApproveCertificate({ noDoc: '', reason: '' })
          loadData()
          resetForm()
        }
      })
      .catch((e) => {
        setLoading(false)
        notiError(t('CERTIFICATE.MESSAGE.ERROR'), '', null, null)
      })
  }

  const onSubmitDisapproved = async () => {
    if (!remark) {
      setErrorMessageRemark("ระบุหมายเหตุ")
      return;
    }
    dispatch(submitModal())
    await CertificateAPI.approve(dateApprove, certificateId, remark, employeeId, "", "", "NOT_APPROVE")
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          // CertificateAPI.updateStatus(certificateId, "NOT_APPROVE")
          notiSuccess(t(`CERTIFICATE.MESSAGE.SUCCESS.DISAPPROVED`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CERTIFICATE.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CERTIFICATE.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage)


  const loadDataAppointment = useCallback(async () => {
    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: JSON.parse(decode(profile))?.userId,
    };
    if (search !== "") condition = { ...condition, search: search };
    if (status !== "ALL") condition = { ...condition, status: status };
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    let conditionAdmin: any = {
      page: page,
      pageLimit: pageLimit,
      sortBy: "appointmentId"
    };
    if (search !== "") conditionAdmin = { ...conditionAdmin, search: search };
    if (sortType) conditionAdmin = { ...conditionAdmin, sortType: sortType }
    if (status !== "ALL") conditionAdmin = { ...conditionAdmin, status: status };
    if (roleId) {
      if (roleId === 4) {
        const res = await LeaseAgreementApi.findAll(condition);
        if (res.status === 200) {
          setRowCount(res.headers['total'])
          getLeaseData(res.data);
        } else {
          getLeaseData([]);
        }
      } else {
        getLeaseData([])
      }
    }
  }, [page, pageLimit, search, status, sortBy, sortType,]);
  useEffect(() => {
    loadDataAppointment();
  }, [])
  const customerSelect = useCallback(async (id: any) => {
    try {
      const condition: any = {
        page: page,
        pageLimit: pageLimit,
        userId: JSON.parse(decode(profile))?.userId,
      };
      setTypePrint("CUSTOMER_SELECT")
      setAppointmentId(id)
      setLoading(true);

      const appointment = await LeaseAgreementApi.findAll(condition)

      const [testResult] = await Promise.all([
        appointment,
      ]);
      setPrintCustomerSelect(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [AppointmentApi])
  const handlePrintcustomerSelect = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printCustomerSelect && typePrint === "CUSTOMER_SELECT") {
      handlePrintcustomerSelect();
    }
  }, [printCustomerSelect])

  const handleCloseModal = (status: boolean) => setAddCertificate(status)
  const headCellsAddCertificate = [
    {
      id: "no", disablePadding: false, label: "#", align: "center",
    },
    {
      id: "date",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.TIME_DATE"),
    },
    {
      id: "transferNo",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.DOC_NUMBER"),
    },
    // {
    //   id: "name",
    //   disablePadding: false,
    //   align: "left",
    //   label: t("STALL_AGREEMENT.HEADER.NAME_RENTER"),
    // },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ZONE"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.STALL"),
    },
    {
      id: "zoneType",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ZONE_TYPE"),
    },
    // {
    //   id: "dataBill",
    //   disablePadding: false,
    //   align: "left",
    //   label: t("STALL_AGREEMENT.HEADER.APPOINTMENT_DATE"),
    // },
    {
      id: "dataBill",
      disablePadding: false,
      align: "left",
      label: t("STALL_AGREEMENT.HEADER.START_DATE"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.EXPIRE_DATE"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.STATUS"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("STALL_AGREEMENT.HEADER.ACTION"),
    },
  ];

  const checkDisableSubmitModal: boolean =
    popupReqCer && invoiceData.length >= 3 ? true :
      popupReqCer && invoiceData.length < 3 ? false :
        popupCancelContract && invoiceData.length >= 3 ? true :
          popupCancelContract && invoiceData.length < 3 ? false : false



  const renderDataAddCertificate = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      createDate,
      firstname,
      lastname,
      leaseAgreementApprove,
      status,
      leaseAgreementNo,
      leaseAgreement,
      leaseAgreementDateStart,
      leaseAgreementDateEnd
    } = objData;

    const today: Date = new Date();
    const priorDate = moment(leaseAgreementDateEnd).add(30, 'days')
    const dayExpiredCheck = moment(priorDate).isAfter(today)

    // console.log(dayExpiredCheck)
    // const todayMoment = moment(today)


    const setStatus =
      status === "ACTIVE"
        ? t("STALL_AGREEMENT.STATUS.ACTIVE")
        : status === "INACTIVE"
          ? t("STALL_AGREEMENT.STATUS.INACTIVE")
          : status === "INACTIVE" ? t("STALL_AGREEMENT.STATUS.TRANSFER") :
            t("STALL_AGREEMENT.STATUS.CANCEL")

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(createDate),
        },
        { option: "TEXT", align: "center", label: leaseAgreementNo || "-" },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: firstname + " " + lastname || "-",
        // },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.zoneName,
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stallName,
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stallTypeName || "-",
        },
        // {
        //   option: "TEXT",
        //   align: "center",
        //   label: dateToView(leaseAgreement?.leaseAgreementDateEnd) || "-",
        // },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreementDateStart),
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreementDateEnd),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Button
              sx={{
                backgroundColor:
                  status === "INACTIVE"
                    ? colors.red
                    : status === "ACTIVE"
                      ? colors.green
                      : colors.red,
                color: colors.white,
                margin: "auto",
                width: '100%',
              }}
            >
              {setStatus}
            </Button>
          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-between mx-auto w-50">
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFileZipper}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => customerSelect(objData.appointmentId)}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    // dispatch(showModal())
                    setModalType("VIEW_CUSTOMER")
                    setDatamodal(objData)
                  }}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCertificate}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => history.push(routeName.stallAgreement + "/agreement", {
                    params: {
                      state: objData,
                    },
                  })}
                />
              </div> */}
              <div className="pr-3">
                {dayExpiredCheck && status === "ACTIVE" ? (
                  <Tooltip title="ขอใบรับรอง">
                    <IconButton onClick={() => {
                      setModalType("ADD-CERTIFICATE")
                      onRequestCertificate(objRenderData.obj)
                    }}>
                      {/* <FontAwesomeIcon
                        icon={faCertificate}
                        style={{
                          fontSize: "20px",
                          width: "22px",
                          color: colors.themeMainColor
                        }}
                      /> */}
                      <PiCertificateFill
                        style={{
                          fontSize: "20px",
                          width: "28px",
                          height: '28px',
                          color: colors.themeMainColor
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                ) : <div>{""}</div>}
              </div>
              {/* <div className="pr-3">
                <HiDocumentRemove
                  style={{
                    fontSize: 20,
                    marginBottom: 3,
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    checkInvoice(objData)
                    onRequestCancelContract(objRenderData.obj)}}
                />
              </div> */}

            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const handleClosingModal = (status: boolean) => {
    setModalType(status === false ? "" : "")
  }
  const onSubmitRequestCertificate = async () => {
    dispatch(submitModal());
    if (!requestDetail) {
      setErrorMessageRequestDetail('ระบุคำขอข้อมูลความต้องการใบรับรอง')
    } else {
      setLoading(true)
      await CertificateAPI.createAndRequestDetail(leaseAgreementId, branchId, reqUserId, "WAITING", String(moment(Date.now()).format('YYYY-MM-DD')), String(moment(Date.now()).add(60, 'days').format('YYYY-MM-DD')), requestDetail)
        .then((res: any) => {
          if (res.status === 201) {
            notiSuccess(t("CERTIFICATE.MESSAGE.SUCCESS.CREATE"), "go", "/certificate", null);
            resetForm();
            setRequestDetail("")
            dispatch(resetModal());
            setLoading(false)
          } else {
            const err = res.response.data;
            setLoading(false)
            setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CERTIFICATE.MESSAGE.${err.error_description}`), }, });
            dispatch(unSubmitModal());
          }
        })
        .catch((e) => {
          const err = e.response.data;
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CERTIFICATE.MESSAGE.${err.error_description}`), }, });
          dispatch(unSubmitModal());
        });
    }

  };
  const onSubmitRequestCancelContract = async () => {
    await CancelContractApi.create(branchId, leaseAgreementId)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t("CANCELCONTRACT.MESSAGE.SUCCESS.CREATE"), "go", "/cancel-contract", null);
          resetForm();
          dispatch(resetModal());
        } else {
          const err = res.response.data;
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`), }, });
          dispatch(unSubmitModal());
        }
      })
      .catch((e) => {
        const err = e.response.data;
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`), }, });
        dispatch(unSubmitModal());
      });
  };

  const handleCloseAddCerModal = () => {
    setModalType('CLOSE-MODAL')
  }
  const handleSetModeApprove = (status: boolean) => {
    setModeApprove(status)
  }
  const handleSetRequestDetail = (value: string) => {
    setErrorMessageRequestDetail("")
    setRequestDetail(value)
  }

  // console.log(certificateBidderDetail)
  // console.log(printCertificate)

  return (
    <div className="position-relative">
      {loading && <Loading show={loading} type="fullLoading" />}

      <HeaderCard text={t("CERTIFICATE.TITLE")} />
      {roleId === 4 && (
        <>
          {modalType === "VIEW_CUSTOMER" && <PopupViewDetailCustomer
            show={modalType === "VIEW_CUSTOMER" ? true : false}
            onClose={() => handleClosingModal(false)}
            data={dataModal} />}

          {modalType === "ADD-CERTIFICATE" && (
            <>
              <ModalCustom
                title={popupReqCer ? t("CERTIFICATE.TITLE_REQUEST") : t("CANCELCONTRACT.TITLE_VERIFY")}
                size="lg"
                onSubmit={popupReqCer ? onSubmitRequestCertificate : onSubmitRequestCancelContract}
                textBtnConfirm={popupReqCer ? t("CERTIFICATE.BUTTON.APPLY_REQUEST") : t("CANCELCONTRACT.BUTTON.CONFIRM_CANCEL")}
                closeButton
                isShowProps={popupReqCer && modalType === "ADD-CERTIFICATE" ? true : false}
                onClose={() => {
                  handleCloseAddCerModal()
                  setRequestDetail("")
                }}
                // disabledSubmit={checkDisableSubmitModal}
                component={
                  <PopupReqCertificate
                    firstname={reqFirstname}
                    lastname={reqLastname}
                    leaseAgreementDateStart={leaseAgreementDateStart}
                    leaseAgreementDateEnd={leaseAgreementDateEnd}
                    zoneName={zoneName}
                    stallName={stallName}
                    stallTypeName={stallTypeName}
                    isShow={popupReqCer}
                    requestDetail={requestDetail}
                    enableRequestDetail={true}
                    errorMessageRequestDetail={errorMessageRequestDetail}
                    setRequestDetail={(value: string) => handleSetRequestDetail(value)}
                    setIsShow={(show: boolean) => setPopupReqCer(show)}
                  />}

              />

            </>
          )}


          <div className="print-show" ref={componentRef}>
            <ExportData
              headCells={""}
              componant={
                <>
                  {typePrint === "APPOINTMENT" && <Style.Styles className="pt-3">
                    {printAppointment && printAppointment.map((d: any) => {
                      return (
                        <>
                          <div className="row d-flex justify-content-center">
                            <img
                              src={imgs.defaultAvatar}
                              alt=""
                              style={{ width: 200, height: 200 }}
                            />
                          </div>
                          <div className="row mt-4">
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.PREFIX")}
                              </p>
                              <div className="">
                                {d?.customer?.prefix?.prefixNameTh || "-"}
                              </div>
                            </div>
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.FIRST_NAME")}
                              </p>
                              <div className="">
                                {d?.customer?.firstname || "-"}
                              </div>
                            </div>

                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.LAST_NAME")}
                              </p>
                              <div className="">{d?.customer?.lastname || "-"}</div>
                            </div>

                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.ID_CARD")}
                              </p>
                              <div className="">{d?.customer?.idCard || "-"}</div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.BIRTH_DAY")}
                              </p>
                              <div className="">
                                {fullDate(d?.customer?.birthDay) || "-"}
                              </div>
                            </div>
                            <div className="col-4 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.BIRTH_DAY")}
                              </p>
                              <div className="">
                                {(d?.customer?.birthDay &&
                                  moment().diff(d?.customer?.birthDay, "years")) ||
                                  "0"}{" "}
                                {t("AGREEMENT.INPUT.YEAR")}
                              </div>
                            </div>
                            <div className="col-4 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.TEL")}
                              </p>
                              <div className="">
                                {d?.customer?.mobilePhone || "-"}
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-4 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.EMAIL")}
                              </p>
                              <div className="">{d?.customer?.email || "-"}</div>
                            </div>
                            <div className="col-4 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.TAX_ID")}
                              </p>
                              <div className="">{d?.customer?.taxId || "-"}</div>
                            </div>
                            <div className="col-4 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.ADRESS")}
                              </p>
                              <div className="">
                                {d?.customer?.houseNumber || "-"}
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.MOO")}
                              </p>
                              <div className="">{d?.customer?.moo || "-"}</div>
                            </div>
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.VILLAGE")}
                              </p>
                              <div className="">{d?.customer?.village || "-"}</div>
                            </div>
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.SOI")}
                              </p>
                              <div className="">{d?.customer?.soi || "-"}</div>
                            </div>

                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.ROAD")}
                              </p>
                              <div className="">{d?.customer?.road || "-"}</div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.PROVINCE")}
                              </p>
                              <div className="">
                                {d?.customer?.province?.provinceNameTh || "-"}
                              </div>
                            </div>

                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.DISTRICT")}
                              </p>
                              <div className="">
                                {d?.customer?.district?.districtNameTh || "-"}
                              </div>
                            </div>

                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.SUB_DISTRICT")}
                              </p>
                              <div className="">
                                {d?.customer?.subDistrict?.subDistrictNameTh || "-"}
                              </div>
                            </div>

                            <div className="col-3 d-flex flex-column">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.INPUT.ZIPCODE")}
                              </p>
                              <div className="">{d?.customer?.zipCode || "-"}</div>
                            </div>
                          </div>

                          <div className="row mt-5">
                            <p style={{ fontSize: 18, fontWeight: 800 }}>
                              {t("AGREEMENT.DETAIL.HEAD")}
                            </p>
                            <div className="col-6 d-flex flex-row">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.DETAIL.ZONE")}
                              </p>
                              <p>{d?.bidder?.zoneName}</p>
                            </div>
                            <div className="col-6 d-flex flex-row">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.DETAIL.STALL")}
                              </p>
                              <p>{d?.bidder?.stallName}</p>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-6 d-flex flex-row">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.DETAIL.STATRT_DATE")}
                              </p>
                              <p>
                                {fullDate(
                                  d?.leaseAgreement?.leaseAgreementDateStart
                                )}
                              </p>
                            </div>
                            <div className="col-6 d-flex flex-row">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.DETAIL.END_DATE")}
                              </p>
                              <p>
                                {fullDate(d?.leaseAgreement?.leaseAgreementDateEnd)}
                              </p>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-12 d-flex flex-row">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("AGREEMENT.DETAIL.RECCOMENT")} :{" "}
                              </p>
                              <p>{d?.leaseAgreement?.leaseAgreementRemark}</p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Style.Styles>}

                  {typePrint === "ADMIN_SELECT" && <Style.Styles className="pt-3">
                    {printSelectAdmin && printSelectAdmin.filter((item: any) => item.appointmentId === appointmentId)
                      .map((d: any) => {
                        return (
                          <>
                            <div className="row d-flex justify-content-center">
                              <img
                                src={imgs.defaultAvatar}
                                alt=""
                                style={{ width: 200, height: 200 }}
                              />
                            </div>
                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.PREFIX")}
                                </p>
                                <div className="">
                                  {d?.customer?.prefix?.prefixNameTh || "-"}
                                </div>
                              </div>
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.FIRST_NAME")}
                                </p>
                                <div className="">
                                  {d?.customer?.firstname || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.LAST_NAME")}
                                </p>
                                <div className="">{d?.customer?.lastname || "-"}</div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ID_CARD")}
                                </p>
                                <div className="">{d?.customer?.idCard || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.BIRTH_DAY")}
                                </p>
                                <div className="">
                                  {fullDate(d?.customer?.birthDay) || "-"}
                                </div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.BIRTH_DAY")}
                                </p>
                                <div className="">
                                  {(d?.customer?.birthDay &&
                                    moment().diff(d?.customer?.birthDay, "years")) ||
                                    "0"}{" "}
                                  {t("AGREEMENT.INPUT.YEAR")}
                                </div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.TEL")}
                                </p>
                                <div className="">
                                  {d?.customer?.mobilePhone || "-"}
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.EMAIL")}
                                </p>
                                <div className="">{d?.customer?.email || "-"}</div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.TAX_ID")}
                                </p>
                                <div className="">{d?.customer?.taxId || "-"}</div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ADRESS")}
                                </p>
                                <div className="">
                                  {d?.customer?.houseNumber || "-"}
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.MOO")}
                                </p>
                                <div className="">{d?.customer?.moo || "-"}</div>
                              </div>
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.VILLAGE")}
                                </p>
                                <div className="">{d?.customer?.village || "-"}</div>
                              </div>
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.SOI")}
                                </p>
                                <div className="">{d?.customer?.soi || "-"}</div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ROAD")}
                                </p>
                                <div className="">{d?.customer?.road || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.PROVINCE")}
                                </p>
                                <div className="">
                                  {d?.customer?.province?.provinceNameTh || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.DISTRICT")}
                                </p>
                                <div className="">
                                  {d?.customer?.district?.districtNameTh || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.SUB_DISTRICT")}
                                </p>
                                <div className="">
                                  {d?.customer?.subDistrict?.subDistrictNameTh || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ZIPCODE")}
                                </p>
                                <div className="">{d?.customer?.zipCode || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-5">
                              <p style={{ fontSize: 18, fontWeight: 800 }}>
                                {t("AGREEMENT.DETAIL.HEAD")}
                              </p>
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.ZONE")}
                                </p>
                                <p>{d?.bidder?.zoneName}</p>
                              </div>
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.STALL")}
                                </p>
                                <p>{d?.bidder?.stallName}</p>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.STATRT_DATE")}
                                </p>
                                <p>
                                  {fullDate(
                                    d?.leaseAgreement?.leaseAgreementDateStart
                                  )}
                                </p>
                              </div>
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.END_DATE")}
                                </p>
                                <p>
                                  {fullDate(d?.leaseAgreement?.leaseAgreementDateEnd)}
                                </p>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-12 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.RECCOMENT")} :{" "}
                                </p>
                                <p>{d?.leaseAgreement?.leaseAgreementRemark}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </Style.Styles>}

                  {typePrint === "CUSTOMER_SELECT" && <Style.Styles className="pt-3">
                    {printCustomerSelect && printCustomerSelect
                      .filter((item: any) => item.appointmentId === appointmentId)
                      .map((d: any) => {
                        return (
                          <>
                            <div className="row d-flex justify-content-center">
                              <img
                                src={imgs.defaultAvatar}
                                alt=""
                                style={{ width: 200, height: 200 }}
                              />
                            </div>
                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.PREFIX")}
                                </p>
                                <div className="">
                                  {d?.prefix?.prefixNameTh || "-"}
                                </div>
                              </div>
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.FIRST_NAME")}
                                </p>
                                <div className="">{d?.firstname || "-"}</div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.LAST_NAME")}
                                </p>
                                <div className="">{d?.lastname || "-"}</div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ID_CARD")}
                                </p>
                                <div className="">{d?.idCard || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.BIRTH_DAY")}
                                </p>
                                <div className="">
                                  {fullDate(d?.birthDay) || "-"}
                                </div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.BIRTH_DAY")}
                                </p>
                                <div className="">
                                  {(d?.birthDay &&
                                    moment().diff(d?.birthDay, "years")) ||
                                    "0"}{" "}
                                  {t("AGREEMENT.INPUT.YEAR")}
                                </div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.TEL")}
                                </p>
                                <div className="">{d?.mobilePhone || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.EMAIL")}
                                </p>
                                <div className="">{d?.email || "-"}</div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.TAX_ID")}
                                </p>
                                <div className="">{d?.taxId || "-"}</div>
                              </div>
                              <div className="col-4 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ADRESS")}
                                </p>
                                <div className="">{d?.houseNumber || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.MOO")}
                                </p>
                                <div className="">{d?.moo || "-"}</div>
                              </div>
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.VILLAGE")}
                                </p>
                                <div className="">{d?.village || "-"}</div>
                              </div>
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.SOI")}
                                </p>
                                <div className="">{d?.soi || "-"}</div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ROAD")}
                                </p>
                                <div className="">{d?.road || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.PROVINCE")}
                                </p>
                                <div className="">
                                  {d?.province?.provinceNameTh || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.DISTRICT")}
                                </p>
                                <div className="">
                                  {d?.district?.districtNameTh || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.SUB_DISTRICT")}
                                </p>
                                <div className="">
                                  {d?.subDistrict?.subDistrictNameTh || "-"}
                                </div>
                              </div>

                              <div className="col-3 d-flex flex-column">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.INPUT.ZIPCODE")}
                                </p>
                                <div className="">{d?.zipCode || "-"}</div>
                              </div>
                            </div>

                            <div className="row mt-5">
                              <p style={{ fontSize: 18, fontWeight: 800 }}>
                                {t("AGREEMENT.DETAIL.HEAD")}
                              </p>
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.ZONE")}
                                </p>
                                <p>{d?.leaseAgreementApprove?.bidder?.zoneName}</p>
                              </div>
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.STALL")}
                                </p>
                                <p>{d?.leaseAgreementApprove?.bidder?.stallName}</p>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.STATRT_DATE")}
                                </p>
                                <p>
                                  {fullDate(
                                    d?.leaseAgreement?.leaseAgreementDateStart
                                  )}
                                </p>
                              </div>
                              <div className="col-6 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.END_DATE")}
                                </p>
                                <p>
                                  {fullDate(
                                    d?.leaseAgreement?.leaseAgreementDateEnd
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-12 d-flex flex-row">
                                <p style={{ fontSize: 16, fontWeight: 700 }}>
                                  {t("AGREEMENT.DETAIL.RECCOMENT")} :{" "}
                                </p>
                                <p>{d?.leaseAgreementApprove?.remark}</p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </Style.Styles>}
                </>
              }
            />
          </div>
          <Style.MainContainer>
            {addCertificate &&
              <ModalCustom
                title="ขอใบรับรองผู้ประกอบการค้า"
                size="xl"
                closeButton
                onSubmit={() => { }}
                isShowProps={addCertificate}
                onClose={() => handleCloseModal(false)}
                component={
                  <div className="w-100 d-flex flex-column" >
                    <Style.TableContainer>
                      <TableCustom
                        headCells={headCellsAddCertificate}
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        rowCount={rowCount}
                        rowsData={leaseData && leaseData?.map((data: any, index: number) => {
                          return renderDataAddCertificate(data, index);
                        })}
                        onSort={onRequestSort}
                        setPageLimit={handlePageLimitChange}
                        setPage={handlePageChange}
                      />
                    </Style.TableContainer>
                  </div>
                }
              />
            }
            <div className="d-flex justify-content-between w-100 flex-wrap">
              <Row>
                <Col lg={6} md={6} xs={12} className="my-1">
                  <InputTextField
                    label={t("CERTIFICATE.FILTER.SEARCH")}
                    value={search}
                    onchange={(event) => {
                      setSearch(event.target.value);
                      setPage(1);
                    }}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col lg={6} md={6} xs={12} className="my-1">
                  <FilterSelect
                    onchange={(event) => {
                      const value = event.target.value;
                      if (value) {
                        handleChangeStatus(value);
                        setPage(1);
                      }
                    }}
                    renderValue={() =>
                      `${t("STATUS.LABEL")}: ${t(`STATUS.${status}`)}`
                    }
                    label={t("CERTIFICATE.STALL")}
                    selectId="select-status"
                    labelId="label-status"
                    value={status}
                    options={[
                      <MenuItem key="1" value="ALL">
                        {t("STATUS.ALL")}
                      </MenuItem>,
                      <MenuItem key="2" value="WAITING">
                        {t("STATUS.WAITING")}
                      </MenuItem>,
                      <MenuItem key="3" value="APPROVE">
                        {t("STATUS.APPROVE")}
                      </MenuItem>,
                      <MenuItem key="4" value="NOT_APPROVE">
                        {t("STATUS.NOT_APPROVE")}
                      </MenuItem>,
                    ]}
                  />
                </Col>
              </Row>
              <Row className="d-flex flex-1">
                <Col>
                  <ButtonCustom
                    onClick={() => setAddCertificate(!addCertificate)}
                    textButton={'ขอใบรับรองผู้ประกอบการค้า'}
                  />
                </Col>
              </Row>
            </div>

            <Style.TableContainer>
              <TableCustom
                headCells={headCells}
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                rowsData={certificateData && certificateData.map((data: any, index: number) => {
                  return renderData(data, index);
                })}
                onSort={onRequestSort}
                setPageLimit={handlePageLimitChange}
                setPage={handlePageChange}
              />
            </Style.TableContainer>


          </Style.MainContainer>
        </>
      )}

      {roleId !== 4 && (
        <Style.MainContainer>

          <Row>
            <Col lg={2} md={6} xs={12} className="my-1">
              <InputTextField
                label={t("CERTIFICATE.FILTER.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value);
                  setPage(1);
                }}
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value;
                  if (value) {
                    handleChangeStatus(value);
                    setPage(1);
                  }
                }}
                renderValue={() =>
                  `${t("STATUS.LABEL")}: ${t(`STATUS.${status}`)}`
                }
                label="สถานะ"
                selectId="select-status"
                labelId="label-status"
                value={status}
                options={[
                  <MenuItem key="1" value="ALL">
                    {t("STATUS.ALL")}
                  </MenuItem>,
                  <MenuItem key="2" value="WAITING">
                    {t("STATUS.WAITING")}
                  </MenuItem>,
                  <MenuItem key="3" value="APPROVE">
                    {t("STATUS.APPROVE")}
                  </MenuItem>,
                  <MenuItem key="4" value="NOT_APPROVE">
                    {t("STATUS.NOT_APPROVE")}
                  </MenuItem>,
                ]}
              />
            </Col>
          </Row>

          <Style.TableContainer>
            <TableCustom
              headCells={headCellsAdmin}
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={certificateData && certificateData.map((data: any, index: number) => {
                return renderDataAdmin(data, index);
              })}
              onSort={onRequestSort}
              setPageLimit={handlePageLimitChange}
              setPage={handlePageChange}
            />
          </Style.TableContainer>
        </Style.MainContainer>
      )}
      {modeApprove && (
        <ModalCustom
          size="lg"
          title="อนุมัติคำขอใบรับรองผู้ประกอบการค้า"
          isShowProps={modeApprove}
          closeButton
          onReset={() => {
            handleSetModeApprove(false)
            handleClearRemark()
            setErrorMessageRemarkKorSor({ noDoc: '', reason: '' })
            setFormApproveCertificate({ noDoc: '', reason: '' })
          }}
          onClose={() => {
            handleSetModeApprove(false)
            handleClearRemark()
            setErrorMessageRemarkKorSor({ noDoc: '', reason: '' })
            setFormApproveCertificate({ noDoc: '', reason: '' })
          }}
          textBtnCancel="ยกเลิก"
          onSubmit={() => onApprove()}
          textBtnConfirm="ยืนยัน"
          component={
            <Style.ContainerModal>
              <Row className="mt-lg-3">
                <Col lg={2} xs={6}>
                  <Style.SubDetail sx={{ fontSize: "1em" }}>
                    {t("CERTIFICATE.MODAL.NAME")}
                  </Style.SubDetail>
                </Col>

                <Col lg={10} xs={10}>
                  <Style.SubDetail sx={{ fontSize: '1em' }}>
                    {firstname + " " + lastname}
                  </Style.SubDetail>
                </Col>
              </Row>
              <Row className="mt-lg-3">
                <Col sm={12} md={12} lg={12}>
                  <InputTextField
                    heading="เลขที่ กษ. ของเอกสาร"
                    onchange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormApproveCertificate((prev) => ({ ...prev, noDoc: e.target.value.trim() }))
                      setErrorMessageRemarkKorSor({ noDoc: '', reason: '' })
                    }}
                    value={formApproveCertificate.noDoc}
                    helperText={errorMessageRemarkKorSor.noDoc}
                  />
                </Col>
              </Row>
              <Row className="mt-lg-3">
                <Col sm={12} md={12} lg={12}>
                  <InputTextField
                    heading="เหตุผลในการขอใบรับรองของผู้ยื่น"
                    onchange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormApproveCertificate((prev) => ({ ...prev, reason: e.target.value.trim() }))
                      setErrorMessageRemarkKorSor({ noDoc: '', reason: '' })
                    }}
                    value={formApproveCertificate.reason}
                    helperText={errorMessageRemarkKorSor.reason}
                  />
                </Col>
              </Row>

              <Row className="mt-lg-3">
                <Col sm={12} md={12} lg={12}>
                  <InputTextField
                    heading="หมายเหตุ"
                    helperText={errorMessageRemark}
                    label="ข้อความหมายเหตุ"
                    onchange={(event) => {
                      setErrorMessageRemark("")
                      setRemark(event.target.value);
                      clearErrorMessage();
                    }}
                    value={remark}
                    size="small"
                    inputHeight={100}
                  />
                </Col>
              </Row>
            </Style.ContainerModal>
          }
        />
      )}

      {!depted && <ModalCustom
        title={
          reqCertificateDetail
            ? t("CERTIFICATE.TITLE_DISAPPROVED_ANNOTATION")
            : reqCertificate
              ? t("ไม่อนุมัติการขอใบรับรองผู้ประกอบการค้า")
              : t("CERTIFICATE.TITLE")
        }
        size="lg"
        closeButton
        onClose={() => onClose()}
        textBtnCancel={reqCertificate ? 'ยกเลิก' : ''}
        textBtnConfirm={
          reqCertificate
            ? t("ยืนยัน")
            : t("CERTIFICATE.BUTTON.COMPLETED")
        }
        onSubmit={reqCertificate ? onSubmitDisapproved : onClose}
        component={
          <div className="pb-2">
            {reqCertificateDetail && (
              <Style.ContainerModal>
                <Row className="mt-lg-3">
                  <Col lg={6} xs={6} className="d-flex flex-row">
                    <Style.SubDetail className="mr-2">
                      {t("CERTIFICATE.MODAL.NAME")}
                    </Style.SubDetail>
                    <Style.SubDetail>
                      {firstname + " " + lastname || "-"}
                    </Style.SubDetail>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col lg={6} xs={6} className="d-flex flex-row mt-3">
                    <Style.SubDetail className="mr-2">
                      {t("CERTIFICATE.MODAL.ANNOTATION")}
                    </Style.SubDetail>
                    <Style.SubDetail>{remark || "-"}</Style.SubDetail>
                  </Col>
                </Row>
              </Style.ContainerModal>
            )}

            {reqCertificate && (
              <Style.ContainerModal>
                <Row className="mt-lg-3">
                  <Col lg={2} xs={6}>
                    <Style.SubDetail>
                      {t("CERTIFICATE.MODAL.NAME")}
                    </Style.SubDetail>
                  </Col>

                  <Col lg={10} xs={10}>
                    <Style.SubDetail>
                      {firstname + " " + lastname}
                    </Style.SubDetail>
                  </Col>
                </Row>

                <Row className="mt-lg-3">
                  <Col lg={2} xs={6}>
                    <Style.SubDetail>
                      {t("หมายเหตุ")}
                    </Style.SubDetail>
                  </Col>

                  {/* <Col lg={10} xs={10}>
                    <Style.SubDetail>
                      {t("หมายเหตุในการไม่อนุมัติ")}
                    </Style.SubDetail>
                  </Col> */}
                </Row>

                <Row className="mt-lg-3">
                  <InputTextField
                    helperText={errorMessageRemark}
                    label="ข้อความหมายเหตุ"
                    onchange={(event) => {
                      setErrorMessageRemark("")
                      setRemark(event.target.value);
                      clearErrorMessage();
                    }}
                    value={remark}
                    size="small"
                    inputHeight={100}
                  />
                </Row>
              </Style.ContainerModal>
            )}

            {popupReqCer && (
              <PopupReqCertificate
                firstname={firstname}
                lastname={lastname}
                leaseAgreementDateStart={leaseAgreementDateStart}
                leaseAgreementDateEnd={leaseAgreementDateEnd}
                zoneName={zoneName}
                stallName={stallName}
                stallTypeName={stallTypeName}
                isShow={popupReqCer}
                setIsShow={(show: boolean) => setPopupReqCer(show)}
              />
            )}
          </div>
        }
      />}

      {depted && <ModalHaveDept />}
      <div className="print-show" ref={componentApproval}>
        <ExportData
          // headCells={t("CERTIFICATE.CERTIFICATE")}
          componant={
            <>
              {typePrint === "PRINT_CERTIFICATE" &&
                <div className="print-pdf-sarabun" style={{ display: "flex", width: "100%", height: "100%", flexDirection: 'column' }}>

                  {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: "500px", height: "300px", display: "flex", justifyContent: 'center', alignItems: 'center', marginBottom: 24, }} src={logo} />
                  </div> */}

                  <div className="w-100 h-25 d-flex justify-content-between">
                    <span>
                      <p style={{ fontSize: "54px", fontWeight: 200, }}>{`ที่ กษ ${formApproveCertificate.noDoc || "ยังไม่ระบุ"}`}</p>
                    </span>
                    <span>
                      <p className="print-pdf-sarabun" style={{ fontSize: "54px", fontWeight: 200, }}>
                        {`องค์การตลาดเพื่อเกษตรกร`}<br />
                        {`๑๐๑ ถนนย่านพหลโยธิน`}<br />
                        {`แขวง/เขตจตุจักร กทม. ๑๐๙๐๐`}
                      </p>
                    </span>
                  </div>
                  <div style={{ display: 'flex', marginTop: "9rem", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: "60px", fontWeight: 600, }} className="mb-4">{t("หนังสือรับรองผู้ประกอบการค้า")} </p>
                    {/* <div style={{ fontSize: "54px", fontWeight: 600, }} className="mb-2"> {t("ใบรับรองผู้ประกอบการค้าฉบับนี้ออกไว้ให้กับ")} </div> */}
                    {/* <div style={{ fontSize: "44px", fontWeight: 400, }} className="mt-5 mb-5"> {printCertificate?.leaseAgreement?.firstname + " " + printCertificate?.leaseAgreement?.lastname || "-"} </div> */}
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "5rem" }}>
                      <p style={{ fontSize: "50px", fontWeight: 100, textIndent: "300px", textAlign: "justify" }}>
                        {`โดยหนังสือฉบับนี้ องค์การตลาดเพื่อเกษตรกร ขอรับรองว่า ${printCertificate?.leaseAgreement?.firstname || "-"} ${printCertificate?.leaseAgreement?.lastname || "-"}`}
                        {`เป็นผู้ประอบการค้าตลาด อ.ต.ก. (องค์การตลาดเพื่อเกษตรกร) แผงค้าเลขที่ ${certificateBidderDetail?.zoneName || certificateDetail?.leaseAgreementApprove?.bidder?.zoneName}/${certificateBidderDetail?.stallName || certificateDetail?.leaseAgreementApprove?.bidder?.stallName} `}
                        {`ประเภทสินค้าที่จำหน่าย ได้แก่ ${certificateBidderDetail?.stallTypeName || certificateDetail?.leaseAgreementApprove?.bidder?.stallTypeName || "-"}`}
                      </p>
                      <p style={{ fontSize: "50px", marginTop: "0.7", fontWeight: 100, textIndent: "300px" }}>
                        {`${formApproveCertificate.reason || "ยังไม่ระบุ"}`}
                      </p>
                      <p style={{ fontSize: "50px", marginTop: "5rem", fontWeight: 100, textIndent: "150px", margin: "0 auto" }}>
                        {`ให้ไว้ ณ วันที่ ${new Date(printCertificate?.modifiedDate || null).toLocaleDateString("th-TH", {
                          month: 'long',
                          day: 'numeric'
                        })} ${new Date(printCertificate?.modifiedDate || null).toLocaleDateString("th-TH", {
                          year: 'numeric',
                        })}`}
                      </p>
                    </div>
                    <div style={{ marginTop: "10rem" }} className="w-100 d-flex justify-content-end">
                      <div style={{ width: "900px", display: "flex", justifyContent: 'center', flexDirection: "column" }}>
                        <div className="w-100" style={{ display: "flex", justifyContent: 'center' }}>
                          {/* <p style={{ fontSize: "50px", fontWeight: 100 }}>{`หัวหน้ากลุ่มบริหารงานตลาด`}</p> */}
                        </div>

                        <div className="w-100" style={{ marginTop: "7rem", display: "flex", justifyContent: "space-between" }}>
                          <span className="w-auto">
                            <p style={{ fontSize: "60px", fontWeight: 100 }}>
                              {/* {`(`} */}
                            </p >
                          </span>
                          <span className="w-auto">
                            <p style={{ fontSize: "60px", fontWeight: 100 }}>
                              {/* {`)`} */}
                            </p>
                          </span>
                        </div>
                        <div className="w-100" style={{ marginTop: "4rem", display: "flex", position: 'relative', justifyContent: "center" }}>
                          <div style={{ position: 'absolute', left: -200, bottom: 106 }}>
                            <p style={{ fontSize: "50px", fontWeight: 100 }}>{"ลงชื่อ"}</p>
                          </div>
                          <div className="w-100 d-flex justify-content-center align-items-center- flex-column">
                            <span className="w-100 d-flex justify-content-center align-items-center">
                              <p style={{ fontSize: "60px", fontWeight: 100 }}>
                                {`..................................................`}
                              </p >
                            </span>
                            <span className="w-100 d-flex justify-content-center align-items-center">
                              <p style={{ fontSize: "60px", fontWeight: 100 }}>
                                {`(..................................................)`}
                              </p >
                            </span>
                          </div>
                        </div>
                        <div className="w-100" style={{ display: "flex", justifyContent: 'center' }}>
                          <p style={{ fontSize: "50px", fontWeight: 100 }}>{`หัวหน้ากลุ่มบริหารงานตลาด`}</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                      <p style={{ fontSize: "50px", lineHeight: "100px", fontWeight: 100, }}>
                        {"กลุ่มบริหารงานตลาด"}
                        <br />
                        {"โทรศัพท์ ๐ ๒๒๗๙ ๖๒๑๕"}
                        <br />
                        {"โทรศัพท์ ๐ ๒๓๕๗ ๑๙๖๗"}
                        <br />
                      </p>
                    </div>
                    {/* <div className="my-box mt-5 mb-3" style={{ width: "500px", height: "200px", backgroundColor: colors.lightGray }}></div> */}
                    {/* <div style={{ fontSize: "44px", fontWeight: 400, }} className="mt-2 mb-2"> {printCertificate?.modifiedBy?.firstname + " " + printCertificate?.modifiedBy?.lastname || "-"} </div>
                    <div style={{ fontSize: "44px", fontWeight: 400, }}>{"เจ้าหน้าที่ อกต"} </div>
                  </div><div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', marginTop: '400px' }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ fontSize: "44px", fontWeight: 400, }} className="mr-3">{"ออกให้ ณ วันที่:"} </div>
                      <div style={{ fontSize: "44px", fontWeight: 400, }}>{dateToView(printCertificate?.createDate) || "-"} </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ fontSize: "44px", fontWeight: 400, }} className="mr-3">{"มีผลถึงวันที่ วันที่:"} </div>
                      <div style={{ fontSize: "44px", fontWeight: 400, }} >{dateToView(printCertificate?.certificateDateEnd) || "-"} </div>
                    </div> */}
                  </div>
                </div>
              }
            </>
          }
        />
      </div>

    </div>
  );
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
