/** COMPONENT */
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { Chart } from "primereact/chart";
import InputDatePicker from "../../component/input/inputDatePicker";
import moment from "moment";
import TableRowCommon from "../../component/table/TableRowCommon";
import TableCustom from "../../component/table/tableCustom";

/** CONSTANT */
import { colors } from "../../constants/colors";

/** STYLE */
import * as Styles from "./statistic.style";
import LoginApi from "../../api/auth/auth.api";
import _ from "lodash";
import InputNewRangePicker from "../../component/input/InputRangePicker";
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { Tab, Tabs } from "@mui/material";

export default function Statistic() {
  const { t } = useTranslation();

  const date: Date = new Date();
  const startOfMonth = moment(date).startOf('year').format('YYYY-MM-DD');
  const endOfMonth = moment(date).endOf('year').format('YYYY-MM-DD');

  const [staticData, setStaticData] = useState<any>([])
  const [staticLeaseData, setStaticLeaseData] = useState<any>([])

  const [dateStart, setDateStart] = useState<string>(moment(startOfMonth).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState<string>(moment(endOfMonth).format('YYYY-MM-DD'));
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([
    dateStart, dateEnd
  ]);
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(Number);
  const [tabsValue, setTabsValue] = useState<string>("INFO");



  const [chartData, setChartData] = useState<any>(
    {
      labels: [],
      datasets: [
        {
          label: 'a',
          data: [],
          backgroundColor: '#7FBFAF',
        },
      ]
    }
  )

  const [chartLeaseData, setChartLeaseData] = useState<any>(
    {
      labels: [],
      datasets: [
        {
          label: 'a',
          data: [],
          backgroundColor: '#7FBFAF',
        },
      ]
    }
  )



  const handleRangeDate = (value: any[]) => {
    setSelectedDateRange(value);
    setDateStart(moment(value[0]).format("YYYY-MM-DD"));
    setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
  };
  const handleClearRangeDate = () => {
    setSelectedDateRange([]);
    setDateStart("");
    setDateEnd("");
  };


  const loadData = useCallback(async () => {
    let condition: any = {
      dateStart: dateStart,
      dateEnd: dateEnd
    }
    const chrome: any = [];
    const safari: any = [];
    const other: any = [];
    const edge: any = [];
    const res = await LoginApi.statistic(condition)
    if (res.status === 200) {
      setStaticData(res.data)
      const summary: any = res?.data;
      for (let index = 0; index < 12; index++) {
        chrome.push(0);
        safari.push(0);
        other.push(0)
      }

      if (!_.isEmpty(summary.Chrome)) {
        _.map(summary.Chrome, (x) => {
          const monthIndex = Number(x.month) - 1;
          if (x.webBrowser === "Chrome") {
            chrome[monthIndex] = Number(x.countNumber);
          }
        });
      }

      if (!_.isEmpty(summary.Safari)) {
        _.map(summary.Safari, (y) => {
          const monthIndex = Number(y.month) - 1;
          if (y.webBrowser === "Safari") {
            safari[monthIndex] = Number(y.countNumber);
          }
        });
      }

      if (!_.isEmpty(summary.Edge)) {
        _.map(summary.Edge, (y) => {
          const monthIndex = Number(y.month) - 1;
          if (y.webBrowser === "Edge") {
            edge[monthIndex] = Number(y.countNumber);
          }
        });
      }

      if (!_.isEmpty(summary.Other)) {
        _.map(summary.Other, (z) => {
          const monthIndex = Number(z.month) - 1; // Adjust the month to 0-based index
          if (z.webBrowser === "Other") {
            other[monthIndex] = Number(z.countNumber);
          }
        });
      }
    }



    setChartData({
      labels: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "June",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: t("Chrome"),
          data: chrome,
          fill: false,
          backgroundColor: "#7FBFAF",
          borderRadius: 10,
        },
        {
          label: t("Safari"),
          data: safari,
          fill: false,
          backgroundColor: "#8B9BC4",
          borderRadius: 50,
        },
        {
          label: t("Edge"),
          data: edge,
          fill: false,
          backgroundColor: "#469acc",
          borderRadius: 50,
        },
        {
          label: t("Other"),
          data: other,
          fill: false,
          backgroundColor: "#96B2FB",
          borderRadius: 10,
        },
      ],
    })
  }, [dateStart, dateEnd])



  const loadDataLease = useCallback(async () => {
    let condition: any = {
      dateStart: dateStart,
      dateEnd: dateEnd
    }
    if (leasesTypeId) condition = { ...condition, leasesTypeId: leasesTypeId }
    const day: any = [];
    const month: any = [];
    const year: any = [];
    const res = await LoginApi.statisticLease(condition)
    if (res.status === 200) {
      setStaticLeaseData(res.data)
      const summary: any = res?.data;
      for (let index = 0; index < 12; index++) {
        day.push(0);
        month.push(0);
        year.push(0);
      }

      if (!_.isEmpty(summary.daily)) {
        _.map(summary.daily, (x) => {
          const monthIndex = Number(x.month) - 1;
          if (x.leasesTypeId === 1) {
            day[monthIndex] = Number(x.countNumber);
          }
        });
      }

      if (!_.isEmpty(summary.monthly)) {
        _.map(summary.monthly, (y) => {
          const monthIndex = Number(y.month) - 1;
          if (y.leasesTypeId === 2) {
            month[monthIndex] = Number(y.countNumber);
          }
        });
      }

      if (!_.isEmpty(summary.yearly)) {
        _.map(summary.yearly, (y) => {
          const monthIndex = Number(y.month) - 1;
          if (y.leasesTypeId === 3) {
            year[monthIndex] = Number(y.countNumber);
          }
        });
      }
    }



    setChartLeaseData({
      labels: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "June",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: t("รายวัน"),
          data: day,
          fill: false,
          backgroundColor: "#7FBFAF",
          borderRadius: 10,
        },
        {
          label: t("รายเดือน"),
          data: month,
          fill: false,
          backgroundColor: "#8B9BC4",
          borderRadius: 50,
        },
        {
          label: t("รายปี"),
          data: year,
          fill: false,
          backgroundColor: "#469acc",
          borderRadius: 50,
        },
        // {
        //   label: t("Other"),
        //   data: other,
        //   fill: false,
        //   backgroundColor: "#96B2FB",
        //   borderRadius: 10,
        // },
      ],
    })
  }, [dateStart, dateEnd, leasesTypeId])


  const handleGetLeasesTypeApi = async () => {
    let condition: any = {};
    await LeasesTypeApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          setLeasesTypeData(res.data)
        }
      })
      .catch((error) => console.log(error))
  }


  useEffect(() => {
    handleGetLeasesTypeApi();
  }, [])

  useEffect(() => {
    loadDataLease()
  }, [loadDataLease]);
  useEffect(() => {
    loadData()
  }, [loadData]);

  const chartRef = useRef<Chart | null>(null);
  const chartRefLaease = useRef<Chart | null>(null);

  const headCells = [
    { id: "userType", disablePadding: false, label: t("ประเภทBrowser") },
    // { id: "chrome", disablePadding: false, label: t("Jan") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Jan") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Feb") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("March") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Apr") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("May") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("June") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Jul") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Aug") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Sep") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Oct") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Nov") },
    { id: "chrome", disablePadding: false, align: 'center', label: t("Dec") }
  ];

  const options = {
    plugins: {
      responsive: true,
      title: {
        display: true,
        text: t("STATISTIC.CHART.ACCESS"),
        font: {
          size: 16
        },
      },
      legend: {
        position: 'top'
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: colors.lightGray
        },
        ticks: {
          color: colors.textExtraLightGray,
          fontSize: 16,
          // stepSize: 000,
          borderRadius: 8,
          callback: function (val: any, index: number) {
            return val + "ครั้ง"
          },
        }
      }
    }
  }

  const optionsLease = {
    plugins: {
      responsive: true,
      title: {
        display: true,
        text: t("การเข้าใช้ตามกลุ่มผู้ใช้งาน"),
        font: {
          size: 16
        },
      },
      legend: {
        position: 'top'
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: colors.lightGray
        },
        ticks: {
          color: colors.textExtraLightGray,
          fontSize: 16,
          // stepSize: 000,
          borderRadius: 8,
          callback: function (val: any, index: number) {
            return val + "ครั้ง"
          },
        }
      }
    }
  }

  const renderData = (objData: any, no: any) => {

    const countNumber = objData.map((d: any, i: number) => d.countNumber)
    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "center", label: countNumber[0] || "0" },
        { option: "TEXT", align: "center", label: countNumber[1] || "0" },
        { option: "TEXT", align: "center", label: countNumber[2] || "0" },
        { option: "TEXT", align: "center", label: countNumber[3] || "0" },
        { option: "TEXT", align: "center", label: countNumber[4] || "0" },
        { option: "TEXT", align: "center", label: countNumber[5] || "0" },
        { option: "TEXT", align: "center", label: countNumber[6] || "0" },
        { option: "TEXT", align: "center", label: countNumber[7] || "0" },
        { option: "TEXT", align: "center", label: countNumber[8] || "0" },
        { option: "TEXT", align: "center", label: countNumber[9] || "0" },
        { option: "TEXT", align: "center", label: countNumber[10] || "0" },
        { option: "TEXT", align: "center", label: countNumber[11] || "0" },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };


  const onChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    console.log(newValue)
    setTabsValue(newValue);
    setDateStart(moment(startOfMonth).format('YYYY-MM-DD'))
    setDateEnd(moment(endOfMonth).format('YYYY-MM-DD'))
  };

  return (
    <>
      <Styles.MainContainer>
        <Styles.PageContainer>
          <Card.Body className="px-5 ">
            <Tabs
              value={tabsValue}
              onChange={onChangeTabs}
              TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
              variant="scrollable"
              sx={{
                borderBottom: `1px solid ${colors.lightGray}`,
                "& button": {
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  backgroundColor: colors.lightGray,
                  marginRight: 1,
                  minHeight: "41px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                },
                "& button:focus": {
                  backgroundColor: colors.themeMainColor,
                  color: colors.white,
                  // height: 40,
                },
              }}
            >
              <Tab
                value="INFO"
                onClick={() => setTabsValue("INFO")}
                iconPosition="start"
                label={t("วิเคราะห์ระบบปฏิบัติการ")}
                sx={{
                  "&.Mui-selected": {
                    color: colors.white,
                    backgroundColor: colors.themeMainColor,
                  },
                }}
              />
              <Tab
                value="CONDITION"
                onClick={() => setTabsValue("CONDITION")}
                iconPosition="start"
                label="วิเคราะห์กลุ่มผู้ใช้งานระบบ"
                sx={{
                  "&.Mui-selected": {
                    color: colors.white,
                    backgroundColor: colors.themeMainColor,
                  },
                }}
              />
            </Tabs>

            {tabsValue == 'INFO' && (
              <>
                <Row style={{ gap: "12px 0" }} className="mt-3">
                  <Col lg={3}>
                    <InputNewRangePicker
                      onchange={handleRangeDate}
                      value={selectedDateRange}
                      onClear={handleClearRangeDate}
                      label="วันที่เริ่มต้น - วันที่สิ้นสุด"
                      allowClear
                      heading="เลือกช่วงเวลา"
                    />
                  </Col>
                  {/* <Col lg={3}>
                <AutocompleteSelect
                  placeholder="เลือกกลุ่มผู้ใช้งาน"
                  heading="กลุ่มผู้ใช้งาน"
                  labelId="SEARCH_DATA"
                  options={leasesTypeData || []}
                  getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                  value={leasesTypeData?.find((list: any) => list?.leasesTypeId === leasesTypeId) || null}
                  onChange={(_, value) => {
                    setLeasesTypeId(value ? value?.leasesTypeId : undefined)
                  }}
                />
              </Col> */}
                </Row>
                <Styles.ChartContainer >
                  <Chart ref={chartRef} type="bar" data={chartData} options={options} />
                </Styles.ChartContainer>

                <Styles.Title>{t('STATISTIC.HEADER.USAGE_STATISTIC')}</Styles.Title>
                <Styles.TableContainer sx={{ pb: 10 }}>
                  <TableCustom
                    headCells={headCells}
                    customScroll
                    page={1}
                    pageLimit={10}
                    sortType={""}
                    sortBy={""}
                    rowCount={1}
                    rowsData={_.map(staticData, (data: any, index: number) => {
                      return renderData(data, index);
                    })}
                    onSort={() => { }}
                    setPageLimit={() => { }}
                    setPage={() => { }}
                    hidePagination
                  />
                </Styles.TableContainer>
              </>
            )}
            {tabsValue == 'CONDITION' && (
              <>
                <Row style={{ gap: "12px 0" }} className="mt-3">
                  <Col lg={3}>
                    <InputNewRangePicker
                      onchange={handleRangeDate}
                      value={selectedDateRange}
                      onClear={handleClearRangeDate}
                      label="วันที่เริ่มต้น - วันที่สิ้นสุด"
                      allowClear
                      heading="เลือกช่วงเวลา"
                    />
                  </Col>
                  {/* <Col lg={3}>
                    <AutocompleteSelect
                      placeholder="เลือกกลุ่มผู้ใช้งาน"
                      heading="กลุ่มผู้ใช้งาน"
                      labelId="SEARCH_DATA"
                      options={leasesTypeData || []}
                      getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                      value={leasesTypeData?.find((list: any) => list?.leasesTypeId === leasesTypeId) || null}
                      onChange={(_, value) => {
                        setLeasesTypeId(value ? value?.leasesTypeId : undefined)
                      }}
                    />
                  </Col> */}
                </Row>
                <Styles.ChartContainer >
                  <Chart ref={chartRefLaease} type="bar" data={chartLeaseData} options={optionsLease} />
                </Styles.ChartContainer>

              </>
            )}
          </Card.Body>
        </Styles.PageContainer>
      </Styles.MainContainer>
    </>
  );
}
