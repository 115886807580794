import { menuFirsts } from "../../constants/menu"
import SystemLog from "../../pages/systemlog/systemlog"

const routeSystemLog = [
    {
      ...menuFirsts.SYSTEM_LOG,
      key: menuFirsts.SYSTEM_LOG.key,
      name: menuFirsts.SYSTEM_LOG.name,
      textName: menuFirsts.SYSTEM_LOG.textName,
      active: false,
      appBar: true,
      component: SystemLog
    }
  ]
  
  export default routeSystemLog