import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../constants/colors";

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: "100%",
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
}));

export const ButtonInTable = styled(Button)(({ theme }) => ({
  backgroundColor: colors.green,
  borderRadius: 4,
  color: colors.white,
}));

export const ButtonPrint = styled(Button)(({ theme }) => ({
  backgroundColor: colors.themeMainColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
  height: "100%",
  borderRadius: 4,
  padding: "8px 16px",
  cursor: "pointer",
  ":hover": {
    backgroundColor: colors.green,
  },
}));

export const DetailModal = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
}));

export const SubDetail = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
}));


export const ContainerModal = styled(Box)(({ theme }) => ({
  paddingLeft: 80,
  paddingRight: 80,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 40,
    paddingRight: 40,
  }
}));

export const CheckBox = styled(Box)(({ theme }) => ({
  backgroundColor: colors.headerBackground,
  display: "flex",
  justifyContent: "space-between",
  height: 40,
  alignItems: "center",
  marginBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
}));

export const ContainerHeir = styled(Box)(({theme}) => ({
  backgroundColor: colors.bgModal,
  padding: '48px 24px',
  borderRadius: 4,
  display: "flex",
  flexDirection: 'row',
  marginBottom: 8
}))

export const GreenButtonInformation = styled(Box)(({theme}) => ({
  marginTop: 8,
  backgroundColor: colors.themeMainColor,
  borderRadius: 4,
  width: '100%',
  display: "flex",
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.white,
  padding: '8px 16px',
  fontSize: 18,
  fontWeight: 400,
  marginBottom: 8
}))

export const InformationContainer = styled(Box)(({ theme }) => ({
  padding: '16px 64px 16px 64px',
  backgroundColor: colors.white,
  border: `1px solid ${colors.bgText}`,
  borderRadius: 8,
  [theme.breakpoints.down('lg')]: {
    padding: '0px 24px 24px 24px',
    backgroundColor: colors.white,
  }
}));

export const PicRenter = styled('img')(({theme}) => ({
  width: 200,
  height: 200
}))

export const BgInformation = styled(Box)(({theme}) => ({
  backgroundColor: colors.lightGrayBG,
  borderRadius: 8,
  padding: 32,
  marginTop: 8
}))

export const FlexRow = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 5
}))

export const AddImage = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))

export const RequestDetail = styled(Typography)(({theme}) => ({
  color: colors.danger,
  fontSize: 16
}))

export const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))

export const ContainerUpload = styled(Box)(({ theme }) => ({
  padding: "32px 8px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  }));

export const ContainerBoxUpload = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('lg')]: {
      flexDirection: "column",
    }
}));

export const BoxModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "start",
}));

export const BoxUpload = styled(Box)(({ theme }) => ({
  borderColor: colors.disabledGray,
  width: '100%',
  height: 304,
}));

export const BoxUploadImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 300,
}));