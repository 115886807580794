import { Card } from 'react-bootstrap'
import { colors } from '../../constants/colors'
import { styled } from '@mui/material/styles'

const StyledHeader = styled(Card.Header)((theme) => ({
  minHeight: '80px',
  // backgroundColor: `${colors.white} !important`,
  border: `1px solid ${colors.borderHeaderCard} !important`,
  backgroundColor: 'rgba(0, 128, 96, 0.05)',
  // boxShadow: `0 0.188rem 0.375rem 0 ${colors.black_10} !important`,
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.25rem',
  fontWeight: 500,
  position:'sticky'
}))

type CardProps = {
  text?: string
}
export default function HeaderCard(props: CardProps) {
  return <StyledHeader className={`static-top px-5 py-3`}>{props.text}</StyledHeader>
}
