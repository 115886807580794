import { Box, Container, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getProfile, handleTypingNumber } from '../../../utils/app.utils';
import Loading from '../../../component/loading/loading';
import HeaderCard from '../../../component/cardCustom/headerCard';
import { Col, Row } from 'react-bootstrap';
import InputTextField from '../../../component/input/inputTextField';
import { Boxbackground, Boxconteiner, Boxinsite } from '../../repair/add/addRepairStyle';
import InputCheckbox from '../../../component/input/inputCheckbox';
import ButtonCustom from '../../../component/button/buttonCustom';
import LeasesTypeApi from '../../../api/setting/leases/leases.api';
import { notiError, notiSuccess } from '../../../component/notification/notifications';
import { colors } from '../../../constants/colors';
import HelperText from '../../../component/input/helperText';
import { useParams } from 'react-router-dom';
const initStateErrorMessage = {
  LEASES_NAME_TH: ``,
  LEASES_TYPE: ``,
  RENTAL_PENALTY_RATE: ``,
  MOVE_OUT_PENALTY_RATE: ``,
  DAMAGE_INSURANCE_LIMIT: ``,
  RENTALRATE: ``,
  payAt: ``
}
export default function DetailLeasesType() {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }
  const { id: id } = useParams<any>();
  const profile: any = JSON.parse(getProfile() || "{}");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leasesTypeNameTh, setLeasesTypeNameTh] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string>('MONTHLY');
  const [yearType, setYearType] = useState<string>('1');
  const [payAtDateCheck, setPayAtDateCheck] = useState<boolean>(false);
  const [payAtDate, setPayAtDate] = useState<string>('');
  const [payAtEndOfMonth, setPayAtEndOfMonth] = useState<boolean>(false);
  const [rentalRate, setRentalRate] = useState<number>();
  const [rentalRate2, setRentalRate2] = useState<number>();
  const [rentalRate3, setRentalRate3] = useState<number>();
  const [rentalPenaltyRate, setRentalPenaltyRate] = useState<number>();
  const [moveOutPenaltyRate, setMoveOutPenaltyRate] = useState<number>();
  const [damageInsuranceLimit, setDamageInsuranceLimit] = useState<number>();

  const [detail, setDetail] = useState<any>()

  const getData = async () => {
    await LeasesTypeApi.findById(id)
      .then((res: any) => {
        setDetail(res.data)
        setLeasesTypeNameTh(res.data.leasesTypeNameTh)
        setYearType(res.data.yearType)
        setPaymentType(res.data.paymentType)
        setPayAtDateCheck(res.data.payAtDate ? true : false)
        setPayAtDate(res.data.payAtDate)
        setPayAtEndOfMonth(res.data.payAtEndOfMonth)
        setRentalRate(res.data.rentalRate)
        setRentalRate2(res.data.rentalRate2)
        setRentalRate3(res.data.rentalRate3)
        setRentalPenaltyRate(res.data.rentalPenaltyRate)
        setMoveOutPenaltyRate(res.data.moveOutPenaltyRate)
        setDamageInsuranceLimit(res.data.damageInsuranceLimit)
      })
  }
  useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType((event.target as HTMLInputElement).value);
    setPayAtDateCheck(false)
    setPayAtDate('')
    setPayAtEndOfMonth(false)
  };
  const handleSubmit = async () => {
    if (!leasesTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ LEASES_NAME_TH: t('กรุณากรอกชื่อรายการ') } })
    // if (!paymentType) return setErrorMessage({ ...errorMessage, ...{ LEASES_TYPE: t('กรุณาเลือกประเภท') } })
    // if (paymentType === 'MONTHLY' && !payAtDateCheck && !payAtEndOfMonth) return setErrorMessage({ ...errorMessage, ...{ payAt: t('กรุณาเลือกประเภท') } })
    // if (!rentalRate) return setErrorMessage({ ...errorMessage, ...{ RENTALRATE: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalRate2 && yearType === '3') return setErrorMessage({ ...errorMessage, ...{ RENTALRATE2: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalRate3 && yearType === '3') return setErrorMessage({ ...errorMessage, ...{ RENTALRATE3: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalPenaltyRate) return setErrorMessage({ ...errorMessage, ...{ RENTAL_PENALTY_RATE: t('กรุณากรอกค่าปรับค่าเช่า') } })
    // if (!moveOutPenaltyRate) return setErrorMessage({ ...errorMessage, ...{ MOVE_OUT_PENALTY_RATE: t('กรุณากรอกค่าปรับกรณีไม่ยอมออกจากพื้นที่') } })
    // if (!damageInsuranceLimit) return setErrorMessage({ ...errorMessage, ...{ DAMAGE_INSURANCE_LIMIT: t('กรุณากรอกวงเงินประกันความเสียหาย') } })

    const body = {
      leasesTypeNameTh: leasesTypeNameTh,
      leasesTypeNameEn: leasesTypeNameTh,
      paymentType: paymentType,
      yearType: +yearType,
      payAtDate: payAtDate || '',
      payAtEndOfMonth: payAtEndOfMonth,
      rentalRate: rentalRate || 0,
      rentalRate2: rentalRate2 || 0,
      rentalRate3: rentalRate3 || 0,
      rentalPenaltyRate: rentalPenaltyRate || 0,
      moveOutPenaltyRate: moveOutPenaltyRate || 0,
      damageInsuranceLimit: damageInsuranceLimit || 0,
    }
    if (id) {
      await LeasesTypeApi.update(id, { ...body, status: 'ACTIVE' })
        .then((res: any) => {
          if (res.status !== undefined && res.status === 205) {
            notiSuccess(t('LEASES.MESSAGE.SUCCESS.CREATE'), 'go', '/leases-type')
          } else {
            notiError('บันทึกไม่สำเร็จ')
          }
        })
    } else {
      await LeasesTypeApi.create(body)
        .then((res: any) => {
          if (res.status !== undefined && res.status === 201) {
            notiSuccess(t('LEASES.MESSAGE.SUCCESS.CREATE'), 'go', '/leases-type')
          } else {
            notiError('บันทึกไม่สำเร็จ')
          }
        })
    }


  }
  return (
    <Box sx={{ position: "relative" }}>
      {isLoading && <Loading show />}
      <HeaderCard text={t("เพิ่มรูปแบบสัญญาเช่า")} />
      <Container maxWidth="xl">
        <Boxinsite>
          <Boxbackground>
            <Boxconteiner>
              <Row>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("รูปแบบสัญญาเช่า")} :
                </Col>
                <Col item md={9} xs={12} className="my-2">
                  <InputTextField value={leasesTypeNameTh || ''} onchange={(e) => {
                    setLeasesTypeNameTh(e.target.value)
                    clearErrorMessage()
                  }} helperText={errorMessage.LEASES_NAME_TH || ''} />
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("Rate ค่าเช่า")} :
                </Col>
                <Col item md={9} xs={12} className="my-2">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={paymentType}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel value="MONTHLY" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} />} label={'รายเดือน'} />
                      <FormControlLabel value="DAILY" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} />} label={'รายวัน'} />
                    </RadioGroup>
                  </FormControl>
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("ระยะสัญญา")} :
                </Col>
                <Col item md={9} xs={12} className="my-2">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={yearType}
                      onChange={(e) => setYearType(e.target.value)}
                      row
                    >
                      <FormControlLabel value="1" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} />} label={'1ปี'} />
                      <FormControlLabel value="3" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} />} label={'3ปี'} />
                    </RadioGroup>
                  </FormControl>
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("กำหนดการชำระเงิน")} :
                </Col>
                <Col item md={9} xs={12} className="my-2">
                  <div className='d-flex gap-3'>
                    <InputCheckbox checked={payAtDateCheck} onChange={(e) => {
                      setPayAtDateCheck(e.target.checked)
                      clearErrorMessage()
                    }} label={<Typography sx={{ whiteSpace: 'nowrap' }}>ทุกวันที่</Typography>} />
                    <InputTextField value={payAtDate} onchange={(e) => setPayAtDate(e.target.value)} style={{ width: '150px' }} disabled={!payAtDateCheck}
                      onkeypress={handleTypingNumber}
                    />
                    <InputCheckbox checked={payAtEndOfMonth} onChange={(e) => {
                      setPayAtEndOfMonth(e.target.checked)
                      clearErrorMessage()
                    }} label={<Typography sx={{ whiteSpace: 'nowrap' }} >สิ้นเดือน</Typography>} />
                  </div>
                  {errorMessage.payAt && <HelperText label={errorMessage.payAt} />}
                </Col>
                {yearType == '1' ? <>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("อัตราค่าเช่า")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate} onchange={(e) => {
                    setRentalRate(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE || ''} style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                    /> <Typography>บาท</Typography>
                </Col></>:<>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("อัตราค่าเช่าปีที่ 1")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate} onchange={(e) => {
                    setRentalRate(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE || ''} style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                    /> <Typography>บาท</Typography>
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("อัตราค่าเช่าปีที่ 2")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate2} onchange={(e) => {
                    setRentalRate2(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE2 || ''} style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                    /> <Typography>บาท</Typography>
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("อัตราค่าเช่าปีที่ 3")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate3} onchange={(e) => {
                    setRentalRate3(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE3 || ''} style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                    /> <Typography>บาท</Typography>
                </Col>
                </>}
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("ค่าปรับค่าเช่า")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center gap-2">
                  <InputTextField value={rentalPenaltyRate || ''} onchange={(e) => {
                    setRentalPenaltyRate(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTAL_PENALTY_RATE || ''} style={{ width: "180px" }} 
                    onkeypress={handleTypingNumber}
                    /> <Typography>ร้อยละ</Typography>
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("ค่าปรับกรณีไม่ยอมออกจากพื้นที่")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center  gap-2">
                  <InputTextField value={moveOutPenaltyRate || ''} onchange={(e) => {
                    setMoveOutPenaltyRate(e.target.value)
                    clearErrorMessage()
                  }} helperText={errorMessage.MOVE_OUT_PENALTY_RATE || ''} style={{ width: "180px" }} 
                  onkeypress={handleTypingNumber}
                  /> <Typography>บาท</Typography>
                </Col>
                <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                  {t("วงเงินประกันความเสียหาย")} :
                </Col>
                <Col item md={9} xs={12} className="my-2 d-flex align-items-center  gap-2">
                  <InputTextField value={damageInsuranceLimit || ''} onchange={(e) => {
                    setDamageInsuranceLimit(e.target.value)
                    clearErrorMessage()
                  }} helperText={errorMessage.DAMAGE_INSURANCE_LIMIT || ''} style={{ width: "180px" }} 
                  onkeypress={handleTypingNumber}
                  /> <Typography>บาท</Typography>
                </Col>

              </Row>
              <ButtonCustom textButton={'บันทึกข้อมูล'} style={{
                marginTop: '20px',
                '.MuiButton-contained': {
                  width: '100%'
                }
              }} onClick={() => {
                handleSubmit()
              }} />
            </Boxconteiner>
          </Boxbackground>
        </Boxinsite>


      </Container>
    </Box>
  )
}
