import React, { useCallback } from 'react'
import * as Style from './submitStall.style'
import { useTranslation } from 'react-i18next'
import FilterSelect from '../../../component/select/filterSelect';
import InputTextField from '../../../component/input/inputTextField';
import { Divider, Grid, MenuItem, Typography } from '@mui/material';
import { faCirclePlus, faEye, faEyeSlash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imgs } from '../../../constants/images';
import _ from 'lodash';
import InputDatePicker from '../../../component/input/inputDatePicker';
import moment from 'moment';
import AutocompleteSelect from '../../../component/select/autoCompleteSelect';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ProvincesApi from '../../../api/setting/provinces/provinces.api';
import DeleteIcon from '@mui/icons-material/Delete';
import { errorMessageRequestToRentStallForm, requestToRentStallType } from './submitStall';
import RelationshipsApi from '../../../api/setting/relationship/relationship.api';
import { debouncedApiCall, handleTypingNumber } from '../../../utils/app.utils';
import HelperText from '../../../component/input/helperText';
import { useDropzone } from 'react-dropzone';
import { notiError } from '../../../component/notification/notifications';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UserApi from '../../../api/auth/users.api';
import { colors } from '../../../constants/colors';


type Props = {
    index: number
    onDeleteForm: (index: number) => void;
    onChange: (name: string, value: any, index: number) => void;
    errorMessage: errorMessageRequestToRentStallForm;
    onClearErrorMessage: () => void;
    prefixData: any[];
    provinceData: any[];
    districtsData: any[];
    data: requestToRentStallType
}

const RequestToRentStall: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const [searchRelationship, setSearchRelationship] = useState<string>("")

    const [relationshipData, setRelationShipData] = useState<any[]>([]);
    const [districtData, setDistrictData] = useState<any[]>([]);
    const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
    const [fileType, setFileType] = useState<string>("");


    const [keywordLoading, setKeywordLoading] = useState<boolean>(false)
    const handleChangeData = (name: string, value: any, index: number) => {
        if (name === 'email') {
            setIsValidEmail(emailRegex.test(value));
        }
        props.onClearErrorMessage()
        props.onChange(name, value, index)
    }


    const handleGetRelationShip = async () => {
        let condition: any = {}
        // if (searchRelationship) condition = { ...condition, search: searchRelationship }
        setKeywordLoading(true)
        await RelationshipsApi.findAll(condition)
            .then(res => {
                if (res.status === 200) {
                    setKeywordLoading(false)
                    setRelationShipData(res.data)
                } else {
                    setKeywordLoading(false)
                    setRelationShipData([])
                }
            })
            .catch(error => {
                setKeywordLoading(false)
                console.log(error)
            })
    }

    const handleGetDistricts = useCallback(async () => {
        let condition = {}
        if (props.data.provinceId) condition = { ...condition, provinceId: props.data.provinceId }
        await ProvincesApi.districts(condition)
            .then(res => {
                if (res.status === 200) {
                    setDistrictData(res.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [props.data.provinceId])

    const handleGetSubDistricts = useCallback(async () => {
        let condition = {}
        if (props.data.districtId) condition = { ...condition, districtId: props.data.districtId }
        await ProvincesApi.subDistricts(condition)
            .then(res => {
                if (res.status === 200) {
                    setSubDistrictData(res.data)
                    // console.log(res.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [props.data.districtId])


    const onDropFile = useCallback(
        (acceptedFiles) => {
            const fileImage = acceptedFiles[0];
            const fileName = fileImage?.name.split(".");
            setFileType(fileName[fileName?.length - 1])
            // console.log();
            if (
                fileName[fileName?.length - 1] == "jpg" ||
                fileName[fileName?.length - 1] == "jpeg" ||
                fileName[fileName?.length - 1] == "png" ||
                fileName[fileName?.length - 1] == "pdf"
            ) {
                handleChangeData('imageFile', fileImage, props.index)
                handleChangeData('isShowImage', true, props.index)
                // setProfileImg(URL.createObjectURL(acceptedFiles[0]));
            } else {
                notiError(
                    "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
                    "",
                    null,
                    null
                );
            }
        },
        []
    );
    const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropFile });

    useEffect(() => {
        handleGetRelationShip();
    }, [])


    useEffect(() => {
        if (props.data.districtId) {
            handleGetSubDistricts();
        }
    }, [handleGetSubDistricts])
    useEffect(() => {
        if (props.data.provinceId) {
            handleGetDistricts();
        }
    }, [handleGetDistricts])


    return (
        <>

            <Style.SubtitleContainer>
                <Style.FlexRow className="mb-2 d-flex justify-content-between">
                    {/* <Style.Rentaller>{t("STALL.TEXT.SUCCESSOR")} {props.ranking}</Style.Rentaller> */}
                    {/* {props.ondelete && <FontAwesomeIcon icon={faTrashAlt} color="red" size='lg' onClick={props.ondelete} />} */}
                </Style.FlexRow>
            </Style.SubtitleContainer>


            <div className='w-100 d-flex flex-row justify-content-between'>
                <span>
                    {`ผู้สืบทอด ${props.index + 1}`}
                </span>
                <span>
                    <DeleteIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => props.onDeleteForm(props.index)} />
                </span>
            </div>
            <hr style={{ border: '1px solid #000' }} />
            <Row className='mt-4 px-2'>
                <Col className='px-1' sm={12} md={3} lg={3}>
                    <FilterSelect
                        labelId='prefixId'
                        options={
                            props.prefixData && props.prefixData?.map((list: any) => {
                                return <MenuItem value={list?.prefixId}>{list?.prefixNameTh || list?.prefixNameEn}</MenuItem>;
                            }) || []
                        }
                        disabled
                        value={props.data.prefixId}
                        helperText={props.errorMessage.prefixId}
                        onchange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeData('prefixId', Number(e.target.value), props.index)}
                        selectId='prefixId-select'
                        heading='คำนำหน้า'
                        required
                    />
                    {props.errorMessage.prefixId}
                </Col>
                <Col className='px-1' sm={12} md={3} lg={5}>
                    <InputTextField
                        disabled
                        value={props.data.firstname}
                        helperText={props.errorMessage.firstname}
                        heading='ชื่อ'
                        required
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('firstname', e.target.value, props.index)}
                    />
                </Col>
                <Col className='px-1' sm={12} md={3} lg={4}>
                    <InputTextField
                        disabled
                        value={props.data.lastname}
                        helperText={props.errorMessage.lastname}
                        heading='นามสกุล'
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('lastname', e.target.value, props.index)}
                        required

                    />
                </Col>
            </Row>
            <Row className='mt-2 px-2'>
                <Col className="px-1" sm={12} md={6} lg={4}>
                    <Style.Heading sx={{ display: "flex" }}>{t("STALL.DETAIL.NUMBER_ID")}<div className='text-danger ps-1'>*</div></Style.Heading>
                    <Style.CustomPatternFormat
                        sx={{
                            '&:disabled': {
                                backgroundColor: colors.black_12
                            }
                        }}
                        disabled
                        format={"#-####-#####-#-##"}
                        mask={"_"}
                        allowEmptyFormatting={true}
                        value={props.data.idCard}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('idCard', e.target.value, props.index)}
                    />
                    {props.errorMessage.idCard && <HelperText label={props.errorMessage.idCard} />}
                </Col>
                <Col className="px-1" sm={12} md={6} lg={4}>
                    <InputDatePicker
                        disabled
                        required
                        helperText={props.errorMessage.birthDay}
                        disableEndDate={moment(new Date()).subtract(18, 'years')}
                        onChange={(e: any) => handleChangeData('birthDay', moment(e).format('YYYY-MM-DD'), props.index)}
                        value={props.data.birthDay}
                        allowClear
                        disableFuture
                        onClear={() => handleChangeData('birthDay', '', props.index)}
                        heading='วัน / เดือน /ปีเกิด'
                    />
                </Col>
                <Col className="px-1" sm={12} md={6} lg={2}>
                    <InputTextField
                        heading='ปี'
                        inputTextAlign="center"
                        type='text'
                        size="small"
                        value={props.data.birthDay && moment().diff(props.data.birthDay, "years") || props.data.birthDay && moment().diff(props.data.birthDay, "years") || "0"}
                        disabled
                    />
                </Col>
                <Col className="px-1" sm={12} md={6} lg={2}>
                    <AutocompleteSelect
                        // disabled
                        loading={keywordLoading}
                        onInputChange={(_, value) => {
                            debouncedApiCall({ searchItem: value, setItem: setSearchRelationship })
                        }}
                        options={relationshipData}
                        getOptionLabel={(option) => option?.relationshipsNameTh}
                        onChange={(_, value) => {
                            console.log(value)
                            if (value) {
                                handleChangeData('relationshipsId', value?.relationshipsId, props.index)
                            } else {
                                handleChangeData('relationshipsId', null, props.index)
                            }
                        }}
                        value={relationshipData?.find((d) => d?.relationshipsId === props.data.relationshipsId) || null}
                        labelId="label-relationshipId"
                        required={true}
                        heading={"ความสัมพันธ์"}
                    />
                    {props.errorMessage.relationshipId && <HelperText label={props.errorMessage.relationshipId} />}
                </Col>
            </Row>
            <Row className='px-2 mt-2'>
                <Col className='px-1' sm={12} md={4} lg={4}>
                    <InputTextField
                        disabled
                        onkeypress={handleTypingNumber}
                        required
                        helperText={props.errorMessage.mobilePhone}
                        value={props.data.mobilePhone}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('mobilePhone', e.target.value, props.index)}
                        size="medium"
                        heading={t("STALL.DETAIL.TEL")}
                        type='text'
                        disabledAutoComplete
                        inputProps={{ maxlength: 10 }}
                    />
                </Col>
                <Col className='px-1' sm={12} md={4} lg={4}>
                    <InputTextField
                        disabled
                        required
                        value={props.data.email}
                        helperText={props.errorMessage.email || !isValidEmail ? 'รูปแบบอีเมลไม่ถูกต้อง' : ""}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('email', e.target.value, props.index)}
                        size="medium"
                        heading={t("STALL.DETAIL.EMAIL")}
                        type='text'
                    // inputProps={{ mode: '' }}
                    />
                </Col>
                <Col className='px-1' sm={12} md={4} lg={4}>
                    <Style.Heading sx={{ display: "flex" }}>{t("STALL.DETAIL.TAXPAYER")} <div className='text-danger ps-1'>{"(ถ้ามี)"}</div></Style.Heading>
                    <Style.CustomPatternFormat
                        sx={{
                            '&:disabled': {
                                backgroundColor: colors.black_12
                            }
                        }}
                        disabled
                        format={"#-####-#####-#-##"}
                        mask={"_"}
                        allowEmptyFormatting={true}
                        value={props.data.taxId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('taxId', e.target.value, props.index)}
                    />
                    {props.errorMessage.taxId && <HelperText label={props.errorMessage.taxId} />}
                </Col>
            </Row>

            <Row className='mt-2 px-2'>
                <Col className='px-1' sm={12} md={3} lg={3}>
                    <InputTextField
                        disabled
                        heading='ที่อยู่เลขที่'
                        value={props.data.houseNumber}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('houseNumber', e.target.value, props.index)}
                    // required
                    />
                </Col>
                <Col className='px-1' sm={12} md={3} lg={3}>
                    <InputTextField
                        disabled
                        heading='หมู่ที่'
                        // required
                        value={props.data.moo}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('moo', e.target.value, props.index)}
                    />
                </Col>
                <Col className='px-1' sm={12} md={3} lg={3}>
                    <InputTextField
                        disabled
                        heading='หมู่บ้าน/ อาคาร'
                        // required
                        value={props.data.village}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('village', e.target.value, props.index)}
                    />
                </Col>
                <Col className='px-1' sm={12} md={3} lg={3}>
                    <InputTextField
                        disabled
                        heading='ตรอก/ซอย'
                        value={props.data.soi}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('soi', e.target.value, props.index)}
                    />
                </Col>
            </Row>

            <Row className='px-2 mt-2'>
                <Col className="px-1" sm={12} md={3} lg={3}>
                    <InputTextField
                        disabled
                        heading='ถนน'
                        value={props.data.road}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeData('road', e.target.value, props.index)}
                    />
                </Col>
                <Col className="px-1" sm={12} md={3} lg={3}>
                    <AutocompleteSelect
                        disabled
                        helperText={props.errorMessage.provinceId}
                        options={props?.provinceData || []}
                        getOptionLabel={({ provinceNameTh, provinceNameEn }) => provinceNameTh || provinceNameEn}
                        onChange={(_, value) => {
                            handleChangeData('provinceId', value?.provinceId, props.index)
                            handleChangeData('districtId', null, props.index)
                            handleChangeData('subDistrictId', null, props.index)
                            handleChangeData('zipCode', "", props.index)
                        }}
                        value={props?.provinceData.find((d: any) => d.provinceId === Number(props.data?.provinceId)) || Number(props.data?.provinceId) || null}
                        labelId="label-provinceId"
                        required={true}
                        heading={t("STALL.DETAIL.CITY")}
                    />
                </Col>
                <Col className="px-1" sm={12} md={3} lg={3}>
                    <AutocompleteSelect
                        disabled
                        helperText={props.errorMessage.districtId}
                        // disabled={!props.data.provinceId}
                        options={districtData || []}
                        getOptionLabel={({ districtNameTh, districtNameEn }) => districtNameTh || districtNameEn}
                        onChange={(_, value) => {
                            handleChangeData('districtId', Number(value?.districtId), props.index)
                            handleChangeData('subDistrictId', null, props.index)
                            handleChangeData('zipCode', "", props.index)
                        }}
                        value={districtData?.find((d: any) => d?.districtId === Number(props.data.districtId)) || null}
                        labelId="label-districtId"
                        required={true}
                        heading={t("STALL.DETAIL.DISTRICT")}
                    />
                </Col>
                <Col className="px-1" sm={12} md={3} lg={3}>
                    <AutocompleteSelect
                        disabled
                        helperText={props.errorMessage.subDistrictId}
                        // disabled={!props.data.districtId}
                        options={subDistrictData || []}
                        getOptionLabel={({ subDistrictNameTh, subDistrictNameEn }) => subDistrictNameTh || subDistrictNameEn}
                        onChange={(_, value) => {
                            handleChangeData('subDistrictId', Number(value?.subDistrictId), props.index)
                            handleChangeData('zipCode', value?.postcode, props.index)

                        }}
                        value={subDistrictData?.find((d: any) => d.subDistrictId === Number(props.data.subDistrictId)) || Number(props.data.subDistrictId) || null}
                        labelId="label-subDistrictId"
                        required={true}
                        heading={t("STALL.DETAIL.SUB_DISTRICT")}
                    />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col sm={3} md={3} lg={3}>
                    <InputTextField
                        required
                        value={props.data.zipCode}
                        size="medium"
                        heading={t("STALL.DETAIL.ZIP_CODE")}
                        disabled
                    />
                </Col>
            </Row>
            <Row className='mt-1'>
                <Col sm={12} md={12} lg={12}>
                    {/* <Style.GreenButtonInformation>
                        <Style.FlexRow className="d-flex align-items-center justify-content-between">
                            <Style.AddImage {...getRootProps()}>
                                <FontAwesomeIcon icon={faCirclePlus} className="mr-4" />
                                <Typography>{t("อัพโหลดสำเนาบัตรประชาชนผู้สืบทอดแผงค้า")}</Typography>
                                <Style.RequestDetail sx={{ pl: 1 }}>*</Style.RequestDetail>
                            </Style.AddImage>

                        </Style.FlexRow>
                        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={props.data.isShowImage ? faEye : faEyeSlash} onClick={() => {
                            if (props.data.imageFile?.type === "pdf" || props.data.imageFile?.type === "application/pdf") {
                                window.open(URL.createObjectURL(props.data.imageFile), "_blank");
                            } else {
                                handleChangeData('isShowImage', !props.data.isShowImage, props.index)
                            }
                        }} />
                    </Style.GreenButtonInformation> */}
                    {/* <input {...getInputProps()} />
                    {props.data.imageFile && (
                        <Row className='mt-2 px-2'>
                            <Col className='px-1' sm={12} md={3} lg={3}>
                                <Typography variant='h6' sx={{ fontSize: "16px" }}>
                                    {`รายละเอียดไฟล์`}
                                </Typography>
                            </Col>
                            <Col className='px-1' sm={12} md={3} lg={3}>
                                {`ชื่อไฟล์ : ${props.data.imageFile.name.length > 20 ? `${props.data.imageFile.name.substring(0, 20)}...` : `${props.data.imageFile.name}`}`}
                            </Col>
                            <Col className='px-1' sm={12} md={3} lg={3}>
                                {`ขนาดไฟล์ : ${props.data.imageFile.size ? `${props.data.imageFile.size} kbps` : ''}`}
                            </Col>
                            {props.data.imageFile?.type === "pdf" || props.data.imageFile?.type === "application/pdf" && (
                                <Col className='px-1 d-flex justify-content-center align-items-center' sm={12} md={3} lg={3}>
                                    {'ตัวอย่างไฟล์ :'}
                                    <InsertDriveFileIcon
                                        sx={{
                                            scale: 2, '&:hover': {
                                                opacity: .5,
                                                cursor: 'pointer'
                                            }
                                        }}
                                        onClick={() =>
                                            props.data.imageFile
                                            && window.open(URL.createObjectURL(props.data.imageFile), "_blank")} />
                                </Col>
                            )}

                        </Row>
                    )} */}
                    {/* {props.data.isShowImage && (
                        <>
                            {props.data.imageFile?.type === "pdf" || props.data.imageFile?.type === "application/pdf" ? (
                                <>
                                </>
                            ) : (
                                <Row>
                                    <img
                                        style={{ objectFit: 'contain' }}
                                        src={props.data.imageFile ? URL.createObjectURL(props.data.imageFile) : ''}
                                        alt={"-"}
                                        loading="lazy"
                                    />
                                </Row>
                            )}
                        </>
                    )} */}
                </Col>
            </Row >
        </>
    )
}

export default RequestToRentStall