import { useCallback, useEffect, useState } from "react";
import { Box, Container, MenuItem, Typography } from "@mui/material";
import { TypographyHead } from "./announceStyle";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import upload from "../../assets/images/upload.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonCustom from "../../component/button/buttonCustom";
import { Col, Row } from "react-bootstrap";
import Add from "../../assets/icon/addannonce.svg";
import NewsTypeApi from "../../api/setting/news/news.api";

import VisibilityIcon from "@mui/icons-material/Visibility";
import * as Style from "./announceStyle";
import DebtTypeApi from "../../api/setting/debttype/debttype.api";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import moment from "moment";
import InputNewRangePicker from "../../component/input/InputRangePicker";
import InputCheckbox from "../../component/input/inputCheckbox";
import AnnounceApi from "../../api/home/announce.api";

import FileuploadApi from "../../api/uploadfile/upload.api";
import { useLocation } from "react-router-dom";
import FilterSelect from "../../component/select/filterSelect";
import LeasesTypeApi from "../../api/setting/leases/leases.api";

const initStateErrorMessage = {
  PRFIX_NAME: ``,
  ROLDID: ``,
  NAME: ``,
  EMPLOYEE_CODE: ``,
  LASTNAME: ``,
  PHONE_NUBER: ``,
  EMAIL: ``,
  USERNAME: ``,
  PASSOWORD: ``,
  CONFIRM_PASSWORD: ``,
  BARNCH: ``,
  PW: ``,
  IDCARD: ``,
  HEAD: ``,
};
export default function Addannounce() {
  const [file, setFile] = useState<any>([]);
  const location: any = useLocation()
  const type: any = location?.state?.type
  const id: any = location?.state?.id
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [newType, setNewtype] = useState<any>([]);
  const [leasesOption, setLeasesOption] = useState<any>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>();
  const [debtType, setDebtType] = useState<any>([]);
  const [newstypeId, setNewTyperId] = useState<number>(3);
  const [page, setPage] = useState<number>(1);
  const [discountPrice, setDiscountPrice] = useState<any>([]);
  const [select, setSelectfile] = useState<any>();
  const [title, setTitle] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [data, setData] = useState<any>();
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([]);
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [checkDate, setCheckDate] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleSubmit = async () => {
    if (!newstypeId)
      return setErrorMessage({
        ...errorMessage,
        ...{ HEAD: t(`ANNOUNCE.NOTI.HEAD`) },
      });
    const condition = {
      newstypeId: newstypeId,
      title: title,
      detail: detail,
      startDate: checkDate ? null : dateStart ? moment(dateStart).format("YYYY-MM-DD") : null,
      endDate: checkDate ? null : dateEnd ? moment(dateEnd).format("YYYY-MM-DD") : null,
      bookmark: false,
      announceType: type,
      leasesTypeId: leasesTypeId,
      // zoneStall: 1,
      status: status || "PENDING",
      discountPrice: discountPrice || [],
      round: []
    };
    if (id) {
      const res = await AnnounceApi.updateAnnounce(condition, id)
      if (res.status === 200) {
        if (file?.filter((item:any) => !item?.fileId)?.length > 0) {
          const formData = new FormData();

          formData.append("refId", `${res?.data?.refId}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          file.forEach((data: any, i: any) => {
            const blob = new Blob([data], { type: data?.type });
            formData.append(`files[]`, data);
          });

          FileuploadApi.uploadfilearray(formData).then((result) => {
            notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
          });
        } else {
          notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);

        }
      }
    } else {
      const res = await AnnounceApi.createAnnounce(condition)
      if (res.status === 201) {
        if (file?.length > 0) {
          const formData = new FormData();

          formData.append("refId", `${res?.data?.refId}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          file.forEach((data: any, i: any) => {
            const blob = new Blob([data], { type: data?.type },);
            formData.append(`files[]`, data);
          });

          FileuploadApi.uploadfilearray(formData).then((result) => {
            notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
          });
        } else {
          notiSuccess(`${t("ANNOUNCE.NOTI.SUCESS")}`, "go", "/announce", null);
        }

      }
    }


  };
  const getDeptType = async () => {
    const condition = {
      page: page,
      pageLimit: 50,
      status: "ACTIVE",
    };

    await DebtTypeApi.findAll(condition).then((res) => {
      if (res?.status === 200) {
        setDebtType(res?.data);
      }
    });
  };

  const getLeasesType = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "ACTIVE"
    };
    const res = await LeasesTypeApi.findAll(condition);
    if (res.status === 200) {
      setLeasesOption(res.data);
    } else {
      setLeasesOption([])
    }

  };
  const getDetail = async () => {
    await AnnounceApi.details(id).then((res) => {
      if (res?.status === 200) {
        const data: any = res?.data

        setData(data)
        setDetail(data?.detail || '')
        setDateStart(data.startDate)
        setDateEnd(data.endDate)
        setNewTyperId(data?.newstypeId)
        setTitle(data?.title)
        setLeasesTypeId(data?.leasesTypeId)
        setDiscountPrice(data?.discountPrice)
        setFile(data?.fileUpload || [])
        setSelectedDateRange([
          `${moment(data?.startDate)}`, `${moment(data?.endDate)}`
        ])

        setStatus(data?.status)

      }
    });
  }
  useEffect(() => {
    if (id) {
      getDetail()
    }
    getLeasesType()
    getDeptType();
  }, []);
  const addDiscount = () => {
    const row = {
      paymentServiceId: null,
      rowId: Math.floor(3 * discountPrice.length),
      amount: null,
      status: "ACTIVE",
    };
    setDiscountPrice([...discountPrice, row]);
  };
  const handlepreview = (x: any) => {
    if (x?.filePath && x?.fileName) {
      if (x.mimetype === "application/pdf") {
        window.open(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName, "_blank");
      } else {
        setSelectfile(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName);
      }
    } else {
      const name = x.type.split(".");

      if (name[name?.length - 1] === "application/pdf") {
        window.open(URL.createObjectURL(x), "_blank");
      } else {
        setSelectfile(URL.createObjectURL(x));
      }
    }

  };

  const handleRangeDate = (value: any) => {
    setSelectedDateRange(value);
    setDateStart(moment(value[0]).format("YYYY-MM-DD"));
    setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
  };
  const handledelete = (x: any) => {
    setFile(file.filter((y: any) => y.path !== x.path));
    setSelectfile(null);
  };
  const deletediscount = (rowId: any) => {
    const newData = discountPrice.filter((x: any) => x.rowId !== rowId);
    setDiscountPrice(newData);
  };

  const getnewType = async () => {
    const codition = {
      page: 1,
      pageLimit: 50,
      announceType: type,
    };
    await NewsTypeApi.findAll(codition).then((res) => {
      if (res.status === 200) {
        setNewtype(res?.data);
      }
    });
  };
  const handleClearRangeDate = () => {
    setSelectedDateRange([]);
    setDateStart("");
    setDateEnd("");
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf"
      ) {
        setFile([...file, acceptedFiles[0]]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    getnewType();
  }, []);

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };

      reader.onerror = (error: any) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  const uploadAdapter = (loader: any) => {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await getBase64(file);
        const blob = new Blob([file], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        return {
          default: "data:image/jpeg;base64," + base64,
          // default: url.split("blob,:")
        };
      },
    };
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <Box>
      <Style.headerannoce>
        <TypographyHead>{type === 'HELP' ? t("ANNOUNCE.HEAD.ADDANNOUNCEHELP") : 'ประกาศข่าวสารจากระบบ'}</TypographyHead>
      </Style.headerannoce>
      <Style.boxcontainer>
        <Style.boxinsite>
          <Style.boxinsitebackground>
            <Container maxWidth="lg">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Row>
                  <Col item lg={2} xs={12} className="my-1">
                    {t("ANNOUNCE.CREATE.NEW_TYPE")}
                  </Col>
                  <Col item lg={10} xs={12} className="my-1">
                    {/* <AutocompleteSelect
                      options={newType}
                      required={true}
                      value={
                        newstypeId
                          ? newType.find(
                              (data: any) => data.newstypeId === newstypeId
                            )
                          : null
                      }
                      getOptionLabel={(option: any) => option.newstypeNameTh}
                      // onChange={(data: any) => setPrefixId(data.value)}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setNewTyperId(newValue.newstypeId);
                        }
                      }}
                      helperText={errorMessage.HEAD}
                      labelId="add"
                    /> */}
                    {type === 'HELP' ? 'ประกาศมาตรการช่วยเหลือ' : 'ประกาศข่าวสารจากระบบ'}
                  </Col>
                  <Col lg={2} xs={12} className="my-1">
                    {t("ANNOUNCE.CREATE.HEAD")}
                  </Col>
                  <Col lg={10} xs={12} className="my-1">
                    <InputTextField value={title}
                      onchange={(e) => setTitle(e.target.value)}
                    />
                  </Col>
                  <Col lg={2} xs={12} className="my-1">
                    {t("ประเภทลูกค้า")}
                  </Col>
                  <Col lg={10} xs={12} className="my-1">
                    <FilterSelect
                      onchange={(e: any) => {
                        setLeasesTypeId(e.target.value)

                      }}
                      options={leasesOption && leasesOption.map((e: any) => {
                        return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                      })}
                      selectId="select-user-type"
                      value={leasesTypeId || ''}
                      labelId=""
                    // renderValue={() =>
                    //   `${t("PAYBILL.INPUT.DEPT")}: `
                    // }
                    />
                  </Col>
                  <Col lg={2} xs={12} className="my-1">
                    {t("ANNOUNCE.CREATE.DETAILCREATE")}
                  </Col>
                  <Col lg={10} xs={12} className="my-1">
                    <div style={{ width: "100%" }}>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {
                        }}
                        config={{
                          extraPlugins: [uploadPlugin],
                          toolbar: {
                            items: [
                              'heading',
                              '|',
                              'bold',
                              'italic',
                              '|',
                              'bulletedList',
                              'numberedList',
                              '|',
                              'insertTable',
                              '|',
                              'imageUpload',
                              '|',
                              'undo',
                              'redo'
                            ]
                          },
                        }}
                        data={detail}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDetail(data);
                        }}
                      />
                    </div>
                  </Col>
                  {type === 'HELP' && <>
                    <Col lg={2} xs={12} className="my-2">
                      {t("ANNOUNCE.CREATE.DISCOUNT")}
                    </Col>
                    <Col lg={10} xs={12} className="my-2">
                      <Box sx={{ backgroundColor: "#E8EBF0", padding: "8px" }}>
                        <Container>
                          <Box
                            sx={{
                              width: "100%",
                              backgroundColor: "#E8EBF0",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Typography>
                                {t("ANNOUNCE.CREATE.DISCOUNT")}
                              </Typography>
                            </Box>
                            <Box onClick={addDiscount} sx={{ cursor: "pointer" }}>
                              <img src={Add} />
                            </Box>
                          </Box>
                        </Container>
                      </Box>
                      <Box>
                        {discountPrice.map((obj: any, index: any) => (
                          <Row
                            key={index}
                            className="my-3"
                            style={{ alignItems: "center" }}
                          >
                            <Col lg={2} className="my-3">
                              <Typography>
                                {index + 1} {t("ANNOUNCE.CREATE.DETAIL")}
                              </Typography>
                            </Col>
                            <Col lg={4} className="my-3">
                              <AutocompleteSelect
                                options={debtType}
                                value={
                                  debtType?.find(
                                    (item: any) =>
                                      item.paymentServiceId === obj.paymentServiceId
                                  ) || null
                                }
                                getOptionLabel={(option: any) =>
                                  option.paymentName
                                }
                                onChange={(data: any, newValue) => {
                                  const newData = discountPrice?.map(
                                    (item: any) => {
                                      if (item.rowId === obj.rowId) {
                                        return {
                                          ...item,
                                          paymentServiceId:
                                            newValue?.paymentServiceId || newValue,
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                  setDiscountPrice(newData);
                                }}
                                labelId="add"
                              />
                            </Col>
                            <Col lg={4} className="my-3">
                              <InputTextField
                                onkeypress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                value={obj?.amount}
                                onchange={(e) => {
                                  const newData = discountPrice?.map(
                                    (item: any) => {
                                      if (item.rowId === obj.rowId) {
                                        return {
                                          ...item,
                                          amount: parseInt(e.target.value),
                                        };
                                      }
                                      return item;
                                    }
                                  );
                                  setDiscountPrice(newData);
                                }}
                              />
                            </Col>

                            <Col lg={1} className="my-3">
                              <Typography sx={{whiteSpace:"nowrap"}}>{t("ร้อยละ %")}</Typography>
                            </Col>
                            <Col lg={1} className="my-3">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <DeleteIcon
                                  onClick={(e) => deletediscount(obj.rowId)}
                                  color="error"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Box>
                            </Col>
                          </Row>
                        ))}

                        <Box></Box>
                      </Box>
                    </Col></>}
                  <Col lg={2} xs={12} className="my-1">
                    {t("ANNOUNCE.CREATE.FILE")}
                  </Col>
                  <Col lg={10} xs={12} className="my-1">
                    <Style.boxupload>
                      {" "}
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {select ? (
                          <>
                            <img
                              src={select}
                              alt="Thumb"
                              style={{ height: "250px", cursor: "pointer" }}
                            />
                          </>
                        ) : (

                          <img src={upload} style={{ width: "100%", cursor: "pointer" }} />

                        )}
                      </div>
                    </Style.boxupload>
                  </Col>

                  {file?.map((e: any, index: any) => (
                    <>
                      <Col item lg={1} xs={12}></Col>
                      <Col item lg={1} xs={12} className="my-2">
                        {index + 1}
                      </Col>
                      <Col item lg={10} xs={12} className="my-2">
                        <Style.filearray>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{e.fileName || e.name}</Box>
                            <Box>
                              <VisibilityIcon
                                onClick={() => handlepreview(e)}
                                sx={{ cursor: "pointer", marginRight: "10px" }}
                              />
                              <DeleteIcon
                                onClick={() => handledelete(e)}
                                color="error"
                                sx={{ cursor: "pointer" }}
                              />
                            </Box>
                          </Box>
                        </Style.filearray>
                      </Col>
                    </>
                  ))}

                  <Col item lg={2} xs={12} className="my-2">
                    {t("ANNOUNCE.CREATE.DATE_TIME")}
                  </Col>
                  <Col item lg={4} xs={12} className="my-2">
                    <InputNewRangePicker
                      key={"date-appointment"}
                      label={t("REPAIR_TYPE_MANGEMENT.INPUT.START_END_DATE")}
                      value={selectedDateRange}
                      onClear={handleClearRangeDate}
                      onchange={handleRangeDate}

                    />
                  </Col>
                  <Col item lg={5} xs={12} className="my-2">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <InputCheckbox
                        style={{ marginLeft: "10px" }}
                        checked={checkDate} onChange={(e) => setCheckDate(e.target.checked)}
                      />

                      {t("ANNOUNCE.CREATE.NO_TIME")}
                    </Box>
                  </Col>
                  <Col item lg={12} xs={12}>
                    <ButtonCustom
                      type="submit"
                      onClick={handleSubmit}
                      textButton={t("REGISTER.BUTTON.SUMMIT")}
                      btnStyle={{
                        fontSize: "16px",
                        width: "100%",
                        padding: "0px",
                        marginTop: "32px",
                        marginBottom: "14px",
                      }}
                    />
                  </Col>
                </Row>
              </Box>
            </Container>
          </Style.boxinsitebackground>
        </Style.boxinsite>
      </Style.boxcontainer>
    </Box>
  );
}
