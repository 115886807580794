import { Card } from 'react-bootstrap'
import { Divider, Grid, Typography } from '@mui/material'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from '../../constants/colors'

/** COMPONENT */
import InputTextField from '../../component/input/inputTextField'

/** STYLE */
import useStyles, { ButtonGoBack, TitalForgetPassword } from './style'
import ButtonCustom from '../../component/button/buttonCustom'

type Props = {
  onClick?: (value: any) => void
  submit?: (value: any) => void
  onChange?: (value: any) => void
  disable?: boolean
  helperText?: string
  isSucceeded?: boolean
}
export default function ForgetPassword(props: Props) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card className={`shadow ${classes.card} forget-password`}>
      <Card.Body>
        <Card.Title>
          <TitalForgetPassword>{t('FORGET_PASSWORD.TITLE')}</TitalForgetPassword>
        </Card.Title>
        <Card.Body>
          {!props.isSucceeded ? (
            <>
              <Grid>
                <Card.Text>{`${t('FORGET_PASSWORD.LABEL')}`}</Card.Text>
              </Grid>
              <Grid>
                <br />
                <InputTextField onchange={props.onChange} label={t('FORGET_PASSWORD.INPUT.EMAIL')} helperText={props.helperText} />
                <br />
              </Grid>
              <Grid className="pb-2 mt-3">
                <ButtonCustom onClick={props.submit} type="submit" textButton={t('FORGET_PASSWORD.BUTTON.SUBMMIT')} />
              </Grid>
            </>
          ) : (
            <Typography className={classes.typographySuccess}> {t('FORGET_PASSWORD.SUCCESS')}</Typography>
          )}
        </Card.Body>
        <Divider />
        <ButtonGoBack onClick={props.onClick} style={{ color: colors.green }} disabled={props.disable}>
          {t('FORGET_PASSWORD.BUTTON.GO_LOGIN')}
        </ButtonGoBack>
      </Card.Body>
    </Card>
  )
}
