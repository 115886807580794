import { styled } from "@mui/material/styles";
import { colors } from "../../constants/colors";
import { Box, Typography } from "@mui/material";
import { width, height, margin, color } from "@mui/system";
import { Padding } from "@mui/icons-material";
import { theme } from "antd";
import { Card,  Button, Dropdown } from 'react-bootstrap'


export const PageContainer = styled('div')(({ theme }) => ({
  height:'100vh',
  overflow:'auto',
  flex:1
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
}));

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "12px 24px",
  height: "100%",
  boxSizing:'border-box',

  [theme.breakpoints.down("md")]: {
    
    
  },
  
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  backgroundColor: colors.headerBackground,
  borderRadius: 4,
  height:'30px',
  lineHeight: "30px",
}));

export const SubDetail = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
}));

export const SubDetailAlert = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  color:colors.red
}));

export const ContainerModal = styled(Box)(({ theme }) => ({
  paddingLeft: 80,
  paddingRight: 80,
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

export const ButtonStyle = styled(Button)(({theme}) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  ':hover' : {
      backgroundColor: colors.green
  }
}))

export const ButtonInTableStyle = styled(Button)(({theme}) => ({
  backgroundColor: "#F0B760",
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  // ':hover' : {
  //     backgroundColor: colors.green
  // }
}))


export const ButtonInTableVerify = styled(Button)(({theme}) => ({
  backgroundColor: "#F0B760",
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '8px 16px',
  cursor: 'pointer',
  whiteSpace:'nowrap',
  border: 'none', 
  '&:hover': {
    backgroundColor: 'transparent !important', 
  },
  '&:focus': {
    outline: 0,
    boxShadow: 'none !important'
  
  },
  '&:active': {
    boxShadow: 'none', 
  },
}))

export const BoxUploadImgContainer = styled(Box)(({ theme }) => ({
  backgroundColor: colors.lightGrayBG,
  borderRadius: 2,
  display: "flex",
  justifyContent: "space-around",
  
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const ContainerUpload = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

export const ContainerBoxUpload = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",

  [theme.breakpoints.down('md')]: {
    flexDirection: "column",
  }
}));



export const BoxUploadImage = styled('img')(({ theme }) => ({
  width: 355,
  height: 300,
}));

export const ModalContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection:'column'
}))

export const MainRedText = styled(Typography)(({theme}) => ({
  fontSize: 18,
  color: colors.red,
  textAlign: 'center'
}))

export const BlackText = styled(Typography)(({theme}) => ({
  fontSize: 16,
  color: colors.black
}))

export const ClickPayDebt = styled(Typography)(({theme}) => ({
  fontSize: 16,
  color: colors.red,
  marginLeft: 4,
  ':hover' : {
    textDecoration: "underline"
  }
}))

export const CustomDropdownToggle = styled(Dropdown.Toggle)(({ theme }) => ({
    backgroundColor: colors.lightOrange , 
    color: colors.white,
    outlineColor:'none',
    border: 'none',
    boxShadow: 'none', 
    '&:hover': {
      backgroundColor: colors.lightOrange, 
    },
    '&:focus': {
      outline: 0,
      boxShadow: 'none !important'    
    },
    '&:active': {
      boxShadow: 'none', 
    },
    '&:inactive': {
      backgroundColor: colors.lightOrange , 
    },
    
  })
);

export const TextBoxUpload = styled(Box)(({theme}) => ({
  width:'100%',
  marginTop: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 8,
  marginBottom: 8
}))

export const MainBoxModal = styled(Box)(({ theme }) => ({
  backgroundColor: colors.lightGrayBG,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  
  
}));

export const MainModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  
}));

export const BoxModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: "1px",
  marginRight: "30px",
  justifyContent: "start",
  padding: "20px 20px",
  // height:'300px'
 
  
}));

export const ItemUpload = styled(Box)(({ theme }) => ({

  display: 'flex', justifyContent: 'center',  backgroundColor: '#fff'

}))
export const BoxDash = styled(Box)(({ theme }) => ({

  border: "2px dashed #70798E",
  width: "100%",
  height: "300px",
  padding: "24px",
  display: 'flex',
  paddingTop: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection:'column'

}))

export const BoxText = styled(Box)(({ theme }) => ({

  [theme.breakpoints.down('sm')]:
  {
      display: "none"
  }
}))

export const BoxFileArray = styled(Box)(({ theme }) => ({
  border: '1px solid #D9D9D9',
   backgroundColor: '#fff', 
   padding: '12px', 
   borderRadius: '8px'
}))

export const SubDetail1 = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
}));

export const ButtonSubmit = styled(Button)(({theme}) => ({
  height:'44px',
  color: colors.white,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colors.themeMainColor,
  fontSize: '1rem',
  borderRadius: '4px',
  lineHeight: '44px',
  padding: 0
    
}))

export const fileArray = styled(Box)(({theme}) => ({    
  border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'

}))
export const boxupload = styled(Box)(({theme}) => ({
    border: '1px solid #D9D9D9', borderRadius: '8px', display: 'flex', justifyContent: 'center', padding: '51px', backgroundColor: '#fff'

}))

export const BoxUpload = styled(Box)(({ theme }) => ({
  borderColor: colors.disabledGray,
  width: '100%',
  height: '100%',
}));

export const Array = styled(Box)(({ theme }) => ({
  border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'
}));

export const Boxdash = styled(Box)(({ theme }) => ({
  border: "2px dashed #000",
  width: "100%",
  height: "200px",
  padding: "24px",
  display: "flex",
  paddingTop: "24px",
  justifyContent: "center",
  alignItems: "center",
}));