import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box, Checkbox, MenuItem, Typography, Button, Tooltip, IconButton
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Dropdown, Row } from "react-bootstrap";
import { decode } from 'js-base64';

//**COMPONENT */
import { routeName } from "../../routes/routers-name";
import { colors } from "../../constants/colors";
import TableRowCommon from "../../component/table/TableRowCommon";
import HeaderCard from "../../component/cardCustom/headerCard";
import ButtonCustom from "../../component/button/buttonCustom";
import { icons } from "../../constants/images";
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import FilterSelect from "../../component/select/filterSelect";
import { getProfile } from "../../utils/app.utils";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { useDropzone } from "react-dropzone";
import moment from "moment";

//**STYLE */
import * as Style from "./payMentStyle";

//** API */
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import ZoneApi from "../../api/zone/zone.api";
import InvoiceApi from "../../api/invoid/invoid.api";
import { dateToView } from "../../utils/date.utils";
import { numberFormat } from "../../utils/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns, faFile, faQrcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputDatePicker from "../../component/input/inputDatePicker";

export default function Invoice() {
  const profile: any = JSON.parse(getProfile() || "{}");
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId);
  const { t } = useTranslation();
  const history = useHistory();
  const location: any = useLocation()
  const date: any = location?.state?.periodDate || ''
  const [searchData, setSearchData] = useState<any>();

  const [invoiceData, setInvoiceData] = useState<any[]>([])
  const [checkAccept, setCheckAccept] = useState<boolean>(false);
  const [leasesOption, setLeasesOption] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState(Number);
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [stallOption, setStallOption] = useState<any[]>([]);
  const [searchZone, setSearchZone] = useState(Number);
  const [searchStall, setSearchStall] = useState(Number);
  const [search, setSearch] = useState<string>('')
  const [imageBill, setImageBill] = useState<any | null>(null)
  const [file, setFile] = useState<any>(null);
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>(1)
  const [periodDate, setPeriodDate] = useState<string>("");


  const handleChangeZone = (newZone: number) => setSearchZone(newZone);

  const getLeasesType = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "ACTIVE"
    };
    const res = await LeasesTypeApi.findAll(condition);
    if (res.status === 200) {
      setLeasesOption(res.data);
    } else {
      setLeasesOption([])
    }

  };

  useEffect(() => {
    getLeasesType();
  }, []);
  useEffect(() => {
    if (date) {
      setPeriodDate(date)
      setSearchData({ periodDate: date })
    }
  }, [date])
  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT",
      skipcalculation: 0
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
    } else {
      setZoneOption([])
    }
  };

  useEffect(() => {
    getZone();
  }, []);

  const loadData = useCallback(async () => {
    let condition: any = {
      status: "ACTIVE"
    }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (searchData?.search) condition = { ...condition, search: searchData?.search }
    if (searchData?.searchZone) condition = { ...condition, zoneId: searchData?.searchZone }
    if (searchData?.searchStall) condition = { ...condition, stallId: searchData?.searchStall?.stallId }
    if (searchData?.periodDate) condition = { ...condition, periodDate: searchData?.periodDate }

    const res = await InvoiceApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res?.headers["total"]);
      setInvoiceData(res.data)
    } else {
      setInvoiceData([])
    }
  }, [page, pageLimit, searchData])

  useEffect(() => {
    if (searchData) {
      loadData()

    }
  }, [loadData])

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
  }, []);

  const viewDebtReport = async (obj: any) => {
    const res = await InvoiceApi.detail(obj.invoiceId)
    if (res.status === 200) {
      history.push(routeName.payBill + "/edit-payment", {
        params: {
          data: res.data,
          status: "ACTIVE"
        },
      })
    }
  }

  const headCells = [
    { id: "no", disablePadding: false, label: "#", align: "center" },
    { id: "documentDate", disablePadding: false, label: t("PAYBILL.HEADCELL.DOCUMENTDATE"), align: "center" },
    { id: "overdue", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.OVERDUE"), },
    { id: "billID", disablePadding: false, label: t("งวดวันที่"), align: "center" },
    { id: "billID", disablePadding: false, label: t("PAYBILL.HEADCELL.BILLID"), align: "center" },
    { id: "zone", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.ZONE"), },
    { id: "stall", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STALL"), },
    { id: "stall", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STALL_NUMBER"), },
    { id: "price", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.PRICE"), },
    { id: "status", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STATUS"), },
    { id: "action", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.ACTION"), },];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { createDate, invoiceNo, leaseAgreement, invoiceDateEnd, periodDate, invoiceDetail, stall } =
      objData;
    const sumAll = invoiceDetail.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
    const status = "WAITING"
    const setState =
      status === "WAITING"
        ? t("STATUS.WAITING")
        : status === "NOT_PAY"
          ? t("STATUS.OVERDUE")
          : t("STATUS.PAY");

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(createDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(invoiceDateEnd), style: { color: colors.red }, },
        { option: "TEXT", align: "center", label: dateToView(periodDate) || "-" },
        { option: "TEXT", align: "center", label: invoiceNo || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallNumber || "-" },
        { option: "TEXT", align: "center", label: numberFormat(sumAll) },
        {
          option: "COMPONENT", align: "center", label: status,
          component: (
            <><Dropdown>
              <Dropdown.Toggle
                variant="link"
                bsPrefix="p-0  "
                className="btn-table-action text-black-50 shadow-none"

              >
                <Button
                  style={{
                    backgroundColor:
                      status === "WAITING"
                        ? colors.lightOrange
                        : status === "NOT_PAY"
                          ? colors.red
                          : colors.green,
                    color: colors.white,
                    margin: "auto",
                    borderRadius: "4px",
                    width: "auto",
                    height: "36px",
                    whiteSpace: 'nowrap',
                  }}
                >
                  {setState}

                </Button>
              </Dropdown.Toggle>
            </Dropdown>
            </>


          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-between mx-auto w-50">
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  viewDebtReport(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor:  "pointer" 
                    , color: colors.themeMainColor
                  }}
                  onClick={() => viewDebtReport(objData)}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };

    return (
      <TableRowCommon
        {...objRenderData}
      />
    );
  };

  const updateStatusAll = async () => {
    let condition: any = {
      date: periodDate || date,
      status: "WAITING"
    }

    const res = await InvoiceApi.updateAll(condition)
    if (res.status === 200) {
      notiSuccess(t('PAYBILL.INVOICE.SUCCESS'))
      history.push(routeName.payBill)
    } else {
      notiError(t('PAYBILL.INVOICE.ERROR'))
    }
  }
  const shouldDisableDate = (date: any) => {
    const day = new Date(date).getDate();
    // อนุญาตให้เลือกวันที่ 1 และ 16 เท่านั้น
    return day !== 1 && day !== 16;
  };
  return (
    <>
      <HeaderCard text={t("PAYBILL.INVOICE.TITLE")} />
      <Style.PageContainer>
        <Style.MainContainer>
          <Row>
            {/* <Col lg={2} md={6} xs={12} className="my-2">
                <FilterSelect
                  onchange={(e: any)=> {
                    setLeasesTypeId(e.target.value)
                    
                  }}
                    options={leasesOption && leasesOption.map((e: any) => {
                      return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                    })}
                    selectId="select-prefix"
                    value={leasesTypeId}
                    labelId="label-prefixId"
                    renderValue={() =>
                      `${t("ประเภทสัญญา")} `
                      // `${t("PAYBILL.INPUT.DEPT")}: `
                    }
                  />
              </Col> */}

            <Col lg={2} md={6} xs={12} className="my-2">
              <InputTextField
                placeholder={t("PAYBILL.INPUT.SEARCH")}
                label={t("PAYBILL.INPUT.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="my-2">
              <AutocompleteSelect
                options={zoneOption || []}
                value={zoneOption && zoneOption.find((e) => e.zoneId === searchZone)}
                getOptionLabel={(option: any) => option.zoneName}
                onChange={(e: any, val: any) => {
                  handleChangeZone(val?.zoneId)
                  setPage(1)
                  setStallOption(val?.stall)
                }}
                labelId="zone-name"
                placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="my-2">
              <AutocompleteSelect
                placeholder={t("PAYBILL.INPUT.SEARCH_STALL")}
                onChange={(e: any, val: any) => { setSearchStall(val) }}
                getOptionLabel={(option: any) => option.stallNumber}
                labelId="label-rental"
                value={stallOption && stallOption.find((e) => e.zoneId === searchStall)}
                options={stallOption || []}
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="my-2">
              <InputDatePicker
                dateFormat="DD/MM/YY"
                key={"date-appointment"}
                label="งวดวันที่"
                value={periodDate}
                allowClear
                onClear={() => setPeriodDate("")}
                shouldDisableDate={shouldDisableDate}
                onChange={(e: any) =>
                  setPeriodDate(moment(e).format('YYYY-MM-DD'))
                }
              />
            </Col>
            <Col lg={2} md={6} xs={12} className="my-2">
              <ButtonCustom
                onClick={() => setSearchData({ periodDate: periodDate || '', searchStall: searchStall || '', searchZone: searchZone || '', search: search || '' })}
                textButton={t("ค้นหา")}
                style={{ width: '100%' }}
              />
            </Col>

          </Row>

          <Style.TableContainer>
            <TableCustom
              headCells={headCells}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={"ASC"}
              sortBy=""
              rowCount={rowCount}
              rowsData={invoiceData && invoiceData.map((d: any, i: number) => {
                return renderData(d, i);
              })}
              onSort={() => { }}
              setPageLimit={(value: number) => setPageLimit(value)}
              setPage={(value: number) => setPage(value)}
              tableMinWidth={1200}
            />
          </Style.TableContainer>
          <Row>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <Checkbox
                value={checkAccept}
                onChange={() => setCheckAccept(!checkAccept)}
              />
              <Typography>{t('PAYBILL.INVOICE.CHECK')}</Typography>
            </Box>
          </Row>
          <Row>
            <ButtonCustom
              textButton={t('PAYBILL.INVOICE.TITLE')}
              style={{ width: '100%', marginTop: 2 }}
              disabled={!checkAccept ? true : false}
              onClick={() => updateStatusAll()}
            />
          </Row>

        </Style.MainContainer>


      </Style.PageContainer>

    </>
  );
}


