import { useTranslation } from "react-i18next";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../../component/input/inputTextField";
import { useEffect, useState } from "react";
import { Divider, MenuItem } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { colors } from "../../../../constants/colors";
import FilterSelect from "../../../../component/select/filterSelect";
import _, { groupBy } from "lodash";
import { IMeterNumber, IStall, IZone } from "../../../../api/zone/zone.api";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initStateErrorMessage = {
  HEIGHT_ZONE: "",
  STALL_NAME: "",
  WIDTH_ZONE: "",
  STALL_NUMBER: "",
  METER: [],
};

type popup = {
  data: IStall;
  zone?: IZone;
  stallType: any;
  isShow: boolean | false;
  setIsShow: (show: boolean) => void;
  onSubmit: (data: any) => void;
  onprintElectricity?: any
  onprintWater?: any
};
let initMeters: IMeterNumber[] = [
  { meterNumber: "", meterType: "ELECTRICITY" },
  { meterNumber: "", meterType: "WATER" },
];
export default function PopupStall(props: popup) {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [stallName, setStallName] = useState<string>("");
  const [stallNumber, setStallNumber] = useState<string>('');
  const [stallTypeId, setStallTypeId] = useState<number | null>(null);
  const [heightZone, setHeightZone] = useState<number | null>();
  const [widthZone, setWidthZone] = useState<number | null>();
  const [meters, setMeters] = useState<IMeterNumber[]>(initMeters);

  useEffect(() => {
    init();
    // const meters = groupBy(payload.data)
    const { stallName, stallNumber, stallTypeId, realHeight, realWidth, meterNumber } =
      props.data;
    console.log(props.data)
    setStallName(stallName);
    setStallNumber(stallNumber)
    setStallTypeId(stallTypeId);
    setHeightZone(realHeight);
    setWidthZone(realWidth);
    const tempInit = [...initMeters];
    const meterWater = _.filter(
      meterNumber,
      (meter: IMeterNumber) => meter.meterType === "WATER"
    );
    const meterElectric = _.filter(
      meterNumber,
      (meter: IMeterNumber) => meter.meterType === "ELECTRICITY"
    );
    setMeters([
      ...(meterElectric.length > 0 ? meterElectric : [tempInit[0]]),
      ...(meterWater.length > 0 ? meterWater : [tempInit[1]]),
    ]);
  }, [props.data]);

  const init = () => {
    setStallName("");
    setStallNumber('')
    setStallTypeId(null);
    setHeightZone(undefined);
    setWidthZone(undefined);
    initMeters = [
      { meterNumber: "", meterType: "ELECTRICITY" },
      { meterNumber: "", meterType: "WATER" },
    ];
    setMeters(initMeters);
  };

  const onSubmit = () => {
    if (!stallName)
      return setErrorMessage({
        ...errorMessage,
        ...{ STALL_NAME: t("กรุณากรอกชื่อแผงค้า") },
      });
    // if (!stallNumber)
    //   return setErrorMessage({
    //     ...errorMessage,
    //     ...{ STALL_NUMBER: t("กรุณากรอกเลขแผงค้า") }
    //   })
    if (!heightZone)
      return setErrorMessage({
        ...errorMessage,
        ...{ HEIGHT_ZONE: t("กรุณากรอกความสูงแผงค้า") },
      });
    if (!widthZone)
      return setErrorMessage({
        ...errorMessage,
        ...{ WIDTH_ZONE: t("กรุณากรอกความกว้างแผงค้า") },
      });
    // const filterMetherFail = _.filter(
    //   meters,
    //   (meter: IMeterNumber) => !meter.meterNumber
    // );
    // if (filterMetherFail.length > 0) {
    //   return setErrorMessage({
    //     ...errorMessage,
    //     ...{
    //       METER: _.map(meters, (meter: IMeterNumber) => {
    //         let errMsg = "";
    //         if (meter.meterNumber === "") {
    //           errMsg = t("กรุณากรอกเลขมิเตอร์");
    //         }
    //         return errMsg;
    //       }),
    //     },
    //   });
    // }
    const newDataArr: IStall = {
      ...props.data,
      stallName: stallName,
      stallNumber: stallNumber,
      realWidth: Number(heightZone),
      realHeight: Number(widthZone),
      stallTypeId: stallTypeId,
      meterNumber: meters,
    };
    init();
    props.onSubmit(newDataArr);
    props.setIsShow(false);
    return newDataArr;
  };

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage);
  };

  const onRemoveMeter = (i: number) => {
    setMeters([..._.filter(meters, (meter, index) => i !== index)]);
  };
  const onAddMeter = (meterType: "WATER" | "ELECTRICITY") => {
    setMeters([...meters, { meterNumber: "", meterType: meterType }]);
  };

  useEffect(() => {
    console.log(stallNumber)
  }, [stallNumber]);
  const renderMeters = (mType: "WATER" | "ELECTRICITY") => {
    const arrType = groupBy(
      _.map(meters, (meter: IMeterNumber, i: number) => ({
        index: i,
        meterType: meter.meterType,
      })),
      "meterType"
    );
    return _.map(
      meters,
      (meter: IMeterNumber, i: number) =>
        meter.meterType === mType && (
          <Row className="d-flex flex-row">
            <Col className="m-auto">
              <InputTextField
                key="horizontal-stall"
                type="tel"
                value={meter.meterNumber}
                helperText={errorMessage.METER[i]}
                onchange={(event) => {
                  setMeters(
                    meters.map((m, index) => {
                      if (i === index) {
                        m.meterNumber = event.target.value;
                      }
                      return m;
                    })
                  );
                  clearErrorMessage();
                }}
                inputProps={{ inputProps: { min: 0 } }}
                onkeypress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
            {i === arrType[mType][0].index ? (
              <Col
                className="m-auto d-flex flex-row"
                lg={2}
                xs={2}

              >
                <AddCircleIcon style={{ color: colors.themeMainColor }} onClick={() => onAddMeter(mType)} />
                <div className="pl-2">
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      cursor: "pointer"
                      , color: colors.themeMainColor
                    }}
                    onClick={
                      mType === "ELECTRICITY" ?
                        props?.onprintElectricity
                        : mType === "WATER" ?
                          props?.onprintWater : ""
                    }
                  />
                </div>
              </Col>
            ) : (
              <Col
                className="m-auto"
                lg={1}
                xs={1}
                onClick={() => onRemoveMeter(i)}
              >
                <RemoveCircleIcon style={{ color: colors.red }} />

              </Col>
            )}
          </Row>
        )
    );
  };
  return (
    <ModalCustom
      title={t("MARKET_MAP.POPUP.ADD_DETAIL.HEADER")}
      size="lg"
      closeButton
      onSubmit={() => onSubmit()}
      textBtnConfirm={t("PREFIX.BUTTON.SAVE")}
      component={
        <>
          <Row className={`mx-0 pt-2 pt-lg-0`}>
            <Col lg={6} xs={12} className="m-auto">
              {t("MARKET_MAP.POPUP.ADD_DETAIL.ZONE_NAME")}
              {props.zone?.zoneName}
            </Col>
            <Col lg={6} xs={12} className="d-flex">
              <div className=" pr-2" style={{ whiteSpace: 'nowrap', margin: "auto" }}>
                {t("MARKET_MAP.POPUP.ADD_DETAIL.STALL_NAME")}
              </div>
              <InputTextField
                key="stall-name"
                type="text"
                helperText={errorMessage.STALL_NAME}
                onchange={(event) => {
                  setStallName(event.target.value);
                  clearErrorMessage();
                }}
                value={stallName}
              />
            </Col>
          </Row>

          <Row className={`mx-0 pt-2 pt-lg-4 `}>
            <Col lg={6} xs={12} className="d-flex">
              <div className="mt-auto pr-2" style={{ whiteSpace: 'nowrap', margin: "auto" }}>
                {t("MARKET_MAP.POPUP.ADD_DETAIL.STALL_NUMBER")}
              </div>
              <InputTextField
                key="stall-number"
                type="text"
                helperText={errorMessage.STALL_NUMBER}
                onchange={(event) => {
                  setStallNumber(event.target.value);
                  clearErrorMessage();
                }}
                value={stallNumber}
              />
            </Col>
            <Col lg={6} xs={12} className="d-flex">
              <div className="mt-auto pr-2" style={{ whiteSpace: 'nowrap', margin: "auto" }}>
                {t("MARKET_MAP.POPUP.ADD_DETAIL.TYPE")}
              </div>
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value;
                  setStallTypeId(value);
                }}
                renderValue={() =>
                  `${_.find(
                    props.stallType,
                    (p: any) => p.stallTypeId === stallTypeId
                  )?.stallTypeNameTh
                  }`
                }
                label="ประเภทแผงค้า"
                selectId="select-stallType"
                labelId="label-stallType"
                value={stallTypeId}
                options={[
                  _.map(props.stallType, (props: any, i: number) => (
                    <MenuItem key={i} value={props.stallTypeId}>
                      {props.stallTypeNameTh}
                    </MenuItem>
                  )),
                ]}
              />
            </Col>
          </Row>

          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col lg={2} xs={12} className="m-auto">
              {t("MARKET_MAP.POPUP.ADD_DETAIL.STALL_SIZE")}
            </Col>
            <Col lg={10} xs={10}>
              <Row>
                <Col lg={2} xs={6} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.WIDTH")}
                </Col>
                <Col lg={2} xs={5} style={{ width: 100 }} className="m-auto">
                  <InputTextField
                    key="height-zone"
                    type="tel"
                    helperText={errorMessage.HEIGHT_ZONE}
                    onchange={(event) => {
                      setHeightZone(event.target.value);
                      clearErrorMessage();
                    }}
                    value={heightZone}
                    inputProps={{ inputProps: { min: 0 } }}
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col lg={2} xs={1} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.UNIT")}
                </Col>
                <Col lg={2} xs={6} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.HEIGHT")}
                </Col>
                <Col lg={2} xs={5} style={{ width: 100 }} className="m-auto">
                  <InputTextField
                    key="width-zone"
                    type="tel"
                    helperText={errorMessage.WIDTH_ZONE}
                    onchange={(event) => {
                      setWidthZone(event.target.value);
                      clearErrorMessage();
                    }}
                    value={widthZone}
                    inputProps={{ inputProps: { min: 0 } }}
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col lg={2} xs={1} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.UNIT")}
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider className="mx-0 mt-2" />

          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col className="m-auto" lg={3} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.ELECT_TYPE")}
            </Col>
            <Col className="m-auto" lg={3} xs={6}>
              <div>ตามมิเตอร์</div>
            </Col>
            <Col className="m-auto" lg={2} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.METER_NUMBER")}
            </Col>
            <Col className="m-auto" lg={4} xs={6}>
              {renderMeters("ELECTRICITY")}
            </Col>
          </Row>

          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col className="m-auto" lg={3} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.WATER_TYPE")}
            </Col>
            <Col className="m-auto" lg={3} xs={6}>
              <div>ตามมิเตอร์</div>
            </Col>
            <Col className="m-auto" lg={2} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.METER_NUMBER")}
            </Col>
            <Col className="m-auto" lg={4} xs={6}>
              {renderMeters("WATER")}
            </Col>
          </Row>
        </>
      }
    />
  );
}
