import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Divider, Grid, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from "./depositPay.style";
import { colors } from "../../../constants/colors";
import { closeModal, showModal } from "../../../app/slice/modal.slice";
import { Col, Dropdown, Row } from "react-bootstrap";
import { numberFormat } from "../../../utils/common";
import { dateToView, fullDate } from "../../../utils/date.utils";
import PopupPaymentStall from "../../store/popup/popupPaymentStall";
import DepositApi from "../../../api/stall/deposit/deposit.api";
import { getProfile } from "../../../utils/app.utils";
import FilterSelect from "../../../component/select/filterSelect";
import moment from "moment";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { ExportProps } from "../../../constants/reportConstant";
import FunctionPrint, { useFunctionPrint } from "../../../component/Print/print";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { useDropzone } from "react-dropzone";
import Loading from "../../../component/loading/loading";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import { decode } from 'js-base64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns, faFile, faFileInvoiceDollar, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from 'file-saver'
import PopupPaymentBank from "../../store/popup/popupPaymentBank";
import TableButton from "../../../component/table/tableButton";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function DepositPay() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const InformationBidder = useRef<HTMLDivElement>(null);
  const profile: any = JSON.parse(getProfile() || '{}')

  const [imageฺBill, setImageBill] = useState<any | null>(null)
  const [qrPay, setQrPay] = useState<boolean>(false)
  const [payBank, setPayBank] = useState<boolean>(false)

  const [datadeposit, getDataDeposit] = useState<any[]>([])
  const [datadetail, getDataDetail] = useState<any>([])

  const [qrDetail, setQrDetail] = useState<any>([])

  const [search, setSearch] = useState<string>("");

  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(10)
  const [rowCount, setRowCount] = useState<number>()
  const [status, setStatus] = useState<string>("ALL")
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId)
  const [bidderAmount, setBidderAmount] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [depositType, setDepositType] = useState<number>(0)
  const [depositId, setDepositId] = useState<number>(0)
  const [typePrint, setTypePrint] = useState<string>("")
  const [printDeposit, setPrintDeposit] = useState<any[]>([])
  const [file, setFile] = useState<any>(null);
  const [qrCode, setQrCode] = useState<any>()

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId)
  }, [])

  const loadDataDeposit = useCallback(async () => {
    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: roleId === 4 ? JSON.parse(decode(profile))?.userId : undefined,
      status: roleId === 4 ? undefined : 'SUCCESS',

    }
    if (search !== "") condition = { ...condition, search: search };
    if (status !== "ALL") condition = { ...condition, status: status };
    if (roleId) {
      if (roleId === 4) {
        const res = await DepositApi.findAll(condition)
        if (res.status === 200) {
          setRowCount(res.headers['total'])
          getDataDeposit(res.data)
        } else {
          getDataDeposit([])
        }
      } else {
        const res = await DepositApi.findAll(condition)
        if (res.status === 200) {
          setRowCount(res.headers['total'])
          getDataDeposit(res.data)
        } else {
          getDataDeposit([])
        }
      }
    }
  }, [page, pageLimit, search, status])

  useEffect(() => {
    loadDataDeposit()
  }, [page, pageLimit, search, status])
  const onDowloadReceipt = async (invoiceId: number) => {
    setLoading(true)
    const res = await DepositApi.receipt(invoiceId)
    if (res.status === 200) {
      const data = res.data
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, 'ใบเสร็จ');
    }
    setLoading(false)
  }
  const getDetail = async (id: number) => {
    const res = await DepositApi.findById(id)
    if (res.status === 200) {
      getDataDetail(res.data)
    } else {
      getDataDetail([])
    }
    setQrPay(false)
    setPayBank(false)
    dispatch(showModal())
  }

  const PayDeposit = async () => {
    if (imageฺBill === null) {
      notiError(t(`กรุณาแนบใบเสร็จ`))
      dispatch(closeModal())
    } else {
      const condition: any = {
        branchId: 1,
        depositId: Number(depositId),
        depositTypeId: depositType,
        depositPayDate: moment(Date.now()).format("YYYY-MM-DD"),
        depositPayAmount: bidderAmount
      }
      await DepositApi.createPay(condition)
        .then((res) => {
          if (res.status === 201) {
            if (res?.data?.refId) {
              let ref = res.data.refId || null;
              const uploadBillImg = new FormData();
              uploadBillImg.append("refId", `${ref}`);
              uploadBillImg.append("fileType", "deposit-pay");
              uploadBillImg.append("file", file, "image.jpg");
              FileuploadApi.uploadfile(uploadBillImg).then((result) => {
                notiSuccess(t(`ชำระเงินสำเร็จ`), "", "", null);
                loadDataDeposit()
              });
            }

          } else {
            notiError(t(`ชำระไม่เงินสำเร็จ`))
          }
        })
    }

  }

  const BankPayDeposit = async () => {
    if (imageฺBill === null) {
      notiError(t(`กรุณาแนบใบเสร็จ`))
      dispatch(closeModal())
    } else {
      const condition: any = {
        branchId: 1,
        depositId: Number(depositId),
        depositTypeId: depositType,
        depositPayDate: moment(Date.now()).format("YYYY-MM-DD"),
        depositPayAmount: bidderAmount
      }
      await DepositApi.createPay(condition)
        .then((res) => {
          if (res.status === 201) {
            if (res?.data?.refId) {
              let ref = res.data.refId || null;
              const uploadBillImg = new FormData();
              uploadBillImg.append("refId", `${ref}`);
              uploadBillImg.append("fileType", "deposit-pay");
              uploadBillImg.append("file", file, "image.jpg");
              FileuploadApi.uploadfile(uploadBillImg).then((result) => {
                notiSuccess(t(`ชำระเงินสำเร็จ`), "", "", null);
                loadDataDeposit()
              });
            }

          } else {
            notiError(t(`ชำระไม่เงินสำเร็จ`))
          }
        })
    }

  }

  const dataDeposit = useCallback(async (props: any) => {
    try {
      setTypePrint("PRINT_DEPOSIT")
      const condition: any = {
        page: page,
        pageLimit: pageLimit,
        userId: JSON.parse(decode(profile))?.userId,
        search: props
      }

      const conditionAdmin: any = {
        page: page,
        pageLimit: pageLimit,
        search: props
      }
      setLoading(true);

      const depositFind = await DepositApi.findAll(roleId !== 4 ? conditionAdmin : condition)

      const [testResult] = await Promise.all([
        depositFind,
      ]);
      setPrintDeposit(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [DepositApi])

  const handlePrintdataDeposit = useFunctionPrint({
    content: () => InformationBidder.current,
    pageStyle: () => `@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printDeposit && typePrint === "PRINT_DEPOSIT") {
      handlePrintdataDeposit();
    }
  }, [printDeposit])

  const onPayWithQR = async (obj: any) => {
    console.log(obj)
    const res = await DepositApi.findById(obj.depositId)
    const data: any = res.data
    if (res.status === 200) {
      console.log(res.data)
      setQrPay(true)
      setPayBank(false)
      setBidderAmount(data.depositAmount)
      setEndDate(data.depositDateEnd)
      setDepositType(1)
      setDepositId(data.depositId)
      setQrCode(data.qrcode)
      dispatch(showModal())
      setQrDetail(data)
    }

  }

  const onPayWithBank = (props: any) => {
    setQrPay(false)
    setPayBank(true)
    setDepositType(2)
    setBidderAmount(props.depositAmount)
    setEndDate(props.depositDateEnd)
    setDepositId(props.depositId)
    dispatch(showModal())
  }

  const payWithBank = async () => {
    setLoading(true)
    const res = await DepositApi.bank(depositId)
    if (res.status === 200) {
      const data = res.data
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, 'แบบฟอร์มชำระเงินผ่านธนาคาร');
    }
    setLoading(false)
  }

  const headCells = [
    { id: "no", disablePadding: false, label: "#", align: "center", },
    { id: "date", disablePadding: false, label: t("DEPOSIT.HEADCELL.DOC_DATE"), align: "center", },
    { id: "petitionNumber", disablePadding: false, label: t("DEPOSIT.HEADCELL.NO"), align: "center", },
    // { id: "name", disablePadding: false, label: t("DEPOSIT.HEADCELL.NAME"), align: "center", },
    { id: "zone", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.ZONE"), },
    { id: "stall", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.STALL") },
    { id: "stall", disablePadding: false, align: 'center', label: t("เลขเเผงค้า") },
    { id: "zoneType", disablePadding: false, label: t("DEPOSIT.HEADCELL.TYPE"), align: "center", },
    { id: "dataBill", disablePadding: false, label: t("DEPOSIT.HEADCELL.PAY_BE_DATE"), align: "center", },
    { id: "dataBill", disablePadding: false, label: t("DEPOSIT.HEADCELL.PAY_WITH"), align: "center", },
    { id: "status", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.STATUS") },
    { id: "action", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.ACTION") },
  ];
  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#", align: "center", },
    { id: "date", disablePadding: false, label: t("DEPOSIT.HEADCELL.DOC_DATE"), align: "center", },
    { id: "petitionNumber", disablePadding: false, label: t("DEPOSIT.HEADCELL.NO"), align: "center", },
    { id: "name", disablePadding: false, label: t("DEPOSIT.HEADCELL.NAME"), align: "center", },
    { id: "zone", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.ZONE"), },
    { id: "stall", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.STALL") },
    { id: "stall", disablePadding: false, align: 'center', label: t("เลขเเผงค้า") },
    { id: "zoneType", disablePadding: false, label: t("DEPOSIT.HEADCELL.TYPE"), align: "center", },
    { id: "dataBill", disablePadding: false, label: t("DEPOSIT.HEADCELL.PAY_BE_DATE"), align: "center", },
    { id: "dataBill", disablePadding: false, label: t("DEPOSIT.HEADCELL.PAY_WITH"), align: "center", },
    { id: "status", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.STATUS") },
    { id: "action", disablePadding: false, align: 'center', label: t("DEPOSIT.HEADCELL.ACTION") },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { depositDateEnd, status, depositDate, bidder, depositNo, depositId, depositBy, depositPay } =
      objData;

    const setState =
      status === "WAITING"
        ? t("DEPOSIT.STATUS.WAITING")
        : status === "PROGRESS"
          ? t("ชำระแล้ว")
          // ? t("DEPOSIT.STATUS.PROGRESS")

          : status === "NOT_APPROVE" ? t("DEPOSIT.STATUS.INACTIVE") :
            status === "APPOINTMENT" ? t("นัดหมาย") :
              status === "SUCCESS_AGREEMENT" ? t("เสร็จสิ้น") :
                status === 'SUCCESS'
                  ? t("รอนัดหมาย") : ''
    // : t("DEPOSIT.STATUS.SUCCESS")

    const objRenderData = {
      key: depositId,
      id: depositId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(depositDate) },
        { option: "TEXT", align: "center", label: depositNo || "-" },
        // { option: "TEXT", align: "center", label: depositBy.firstname + " " + depositBy.lastname || "-" },
        { option: "TEXT", align: "center", label: bidder.zoneName || "-" },
        { option: "TEXT", align: "center", label: bidder.stallName || "-" },
        { option: "TEXT", align: "center", label: bidder.stall?.stallNumber || "-" },
        { option: "TEXT", align: "center", label: bidder.stallTypeName || "-" },
        { option: "TEXT", align: "center", label: dateToView(depositDateEnd) || "-" },
        { option: "TEXT", align: "center", label: depositPay !== null ? depositPay?.depositType.depositTypeName : "-" },
        {
          option: "COMPONENT",
          align: "left",
          component:
            <Typography
              sx={{
                color:
                  status === "WAITING"
                    ? colors.yellow
                    : status === "PROGRESS"
                      ? colors.themeSecondColor
                      : status === "SUCCESS"
                        ? colors.orange
                        : status === "APPOINTMENT"
                          ? colors.blue :
                          status === "NOT_APPROVE" ? colors.danger
                            : colors.green,
                textAlign: "center"

              }}
            >
              {setState}
            </Typography>
          // <Button
          //   sx={{
          //     backgroundColor:
          //       status === "WAITING"
          //         ? colors.yellow
          //         : status === "PROGRESS"
          //           ? colors.themeSecondColor
          //           : status === "SUCCESS"
          //             ? colors.orange
          //             : status === "APPOINTMENT"
          //               ? colors.blue :
          //               status === "NOT_APPROVE" ? colors.danger
          //                 : colors.green,
          //     color: colors.white,
          //     margin: "auto",
          //     width: '100%',
          //     "&.MuiButtonBase-root:hover": {
          //       backgroundColor:
          //         status === "WAITING"
          //           ? colors.yellow
          //           : status === "PROGRESS"
          //             ? colors.themeSecondColor
          //             : status === "SUCCESS"
          //               ? colors.orange
          //               : status === "APPOINTMENT"
          //                 ? colors.blue :
          //                 status === "NOT_APPROVE" ? colors.danger
          //                   : colors.green,
          //       color: colors.white,
          //       margin: "auto",
          //     }
          //   }}
          // >
          //   {setState}
          // </Button>

        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">

              <TableButton title="อนุมัติเบื่องต้น" icon={faQrcode} disabled={!(status === "WAITING" && new Date() <= new Date(depositDateEnd))} onClick={() => {
                onPayWithQR(objData)
              }} />
              <TableButton title="รายละเอียด" icon={faFile} onClick={() => {
                getDetail(objRenderData.id)
              }} />

              {status === "SUCCESS" || status === 'APPOINTMENT' || status === 'SUCCESS_AGREEMENT' ?
                <TableButton title="ใบเสร็จ" icon={faFileInvoiceDollar} onClick={() => {
                  onDowloadReceipt(objData.depositId)
                }} />

                : <></>}
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData}
      onprint={() => dataDeposit(objData.depositNo)}
      onactive={() => getDetail(objRenderData.id)} />;
  };
  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { depositDateEnd, status, depositDate, bidder, depositNo, depositId, depositBy, depositPay } =
      objData;

    const setState =
      status === "WAITING"
        ? t("DEPOSIT.STATUS.WAITING")
        : status === "PROGRESS"
          ? t("ชำระแล้ว")
          // ? t("DEPOSIT.STATUS.PROGRESS")

          : status === "NOT_APPROVE" ? t("DEPOSIT.STATUS.INACTIVE") :
            status === "APPOINTMENT" ? t("นัดหมาย") :
              status === "SUCCESS_AGREEMENT" ? t("เสร็จสิ้น") :
                status === 'SUCCESS'
                  ? t("รอนัดหมาย") : ''
    // : t("DEPOSIT.STATUS.SUCCESS")

    const objRenderData = {
      key: depositId,
      id: depositId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(depositDate) },
        { option: "TEXT", align: "center", label: depositNo || "-" },
        { option: "TEXT", align: "center", label: depositBy.firstname + " " + depositBy.lastname || "-" },
        { option: "TEXT", align: "center", label: bidder.zoneName || "-" },
        { option: "TEXT", align: "center", label: bidder.stallName || "-" },
        { option: "TEXT", align: "center", label: bidder.stall?.stallNumber || "-" },
        { option: "TEXT", align: "center", label: bidder.stallTypeName || "-" },
        { option: "TEXT", align: "center", label: dateToView(depositDateEnd) || "-" },
        { option: "TEXT", align: "center", label: depositPay !== null ? depositPay?.depositType.depositTypeName : "-" },
        {
          option: "COMPONENT",
          align: "left",
          component:
            <Typography
              sx={{
                color:
                  status === "WAITING"
                    ? colors.yellow
                    : status === "PROGRESS"
                      ? colors.themeSecondColor
                      : status === "SUCCESS"
                        ? colors.orange
                        : status === "APPOINTMENT"
                          ? colors.blue :
                          status === "NOT_APPROVE" ? colors.danger
                            : colors.green,
                textAlign: "center"

              }}
            >
              {setState}
            </Typography>
          // <Button
          //   sx={{
          //     backgroundColor:
          //       status === "WAITING"
          //         ? colors.yellow
          //         : status === "PROGRESS"
          //           ? colors.themeSecondColor
          //           : status === "SUCCESS"
          //             ? colors.orange
          //             : status === "APPOINTMENT"
          //               ? colors.blue :
          //               status === "NOT_APPROVE" ? colors.danger
          //                 : colors.green,
          //     color: colors.white,
          //     margin: "auto",
          //     width: '100%',
          //     "&.MuiButtonBase-root:hover": {
          //       backgroundColor:
          //         status === "WAITING"
          //           ? colors.yellow
          //           : status === "PROGRESS"
          //             ? colors.themeSecondColor
          //             : status === "SUCCESS"
          //               ? colors.orange
          //               : status === "APPOINTMENT"
          //                 ? colors.blue :
          //                 status === "NOT_APPROVE" ? colors.danger
          //                   : colors.green,
          //       color: colors.white,
          //       margin: "auto",
          //     }
          //   }}
          // >
          //   {setState}
          // </Button>

        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">

              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faQrcode}
                  style={{
                    cursor: "pointer"
                    , color: status === "WAITING" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "WAITING" && moment(Date.now()).isBefore(depositDateEnd)) {
                      onPayWithQR(objData)
                    }
                  }}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  style={{
                    cursor: status === "WAITING" ? "pointer" : "none"
                    , color: status === "WAITING" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "WAITING" && (dateToView(Date.now()) <= dateToView(depositDateEnd))) {
                      onPayWithBank(objData)
                    }
                  }}
                />
              </div> */}
              <TableButton title="รายละเอียด" icon={faFile} onClick={() => {
                getDetail(objRenderData.id)
              }} />


              {status === "SUCCESS" &&
                <TableButton title="ใบเสร็จ" icon={faFileInvoiceDollar} onClick={() => {
                  onDowloadReceipt(objData.depositId)
                }} />
              }
              {/* <div className="pr-3" >
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => getDetail(objRenderData.id)}
                />
              </div >
              {status === "SUCCESS" && <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => onDowloadReceipt(objData.depositId)}
                />
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => printById(objData.invoiceId)}
                />
              </div>
              } */}
            </div >
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData}
      onprint={() => dataDeposit(objData.depositNo)}
      onactive={() => getDetail(objRenderData.id)} />;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileImg = acceptedFiles[0];

      const fileName = fileImg?.name.split(".");
      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {
        setFile(acceptedFiles[0]);
        setImageBill(URL.createObjectURL(acceptedFiles[0]));
      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
    },
    [file, imageฺBill]

  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <HeaderCard text={t("DEPOSIT.HEADER")} />
      <Style.MainContainer>
        <Box>
          <Row>
            <Col lg={3} md={6} xs={12} className="my-1">
              <InputTextField
                label="ค้นหา"
                value={search}
                onchange={(e: any) => setSearch(e.target.value)}
              />
            </Col>
            {/* <Grid item lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={[]}
                onChange={() => {}}
                getOptionLabel={(option: any) => {}}
                placeholder={t('DEPOSIT.FILTER.ZONE.SEARCH')}
                labelId="zone"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
                options={stallType}
                onChange={(e,value: any) => console.log()}
                getOptionLabel={(option: any) => option.stallTypeNameTh}
                value={""}
                placeholder={t('DEPOSIT.FILTER.STALL.SEARCH')}
                labelId="stall"
              />
            </Grid> */}
            <Col lg={2} md={6} xs={12} className="my-1">
              {roleId === 4 ? <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setStatus(value)
                  }
                }}
                label={t("REQUEST_STALL.FILTER.STATUS")}
                labelId="status"
                selectId="status"
                fullWidth
                value={status}
                // renderValue={() => `${t(`DEPOSIT.STATUS.${status}`)}`}
                options={[
                  <MenuItem key="ALL" value="ALL">
                    {t('DEPOSIT.STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="1" value="WAITING">
                    {t('DEPOSIT.STATUS.WAITING')}
                  </MenuItem>,
                  <MenuItem key="2" value="PROGRESS">
                    {t('ชำระแล้ว')}
                  </MenuItem>,
                  <MenuItem key="3" value="SUCCESS">
                    {t('รอนัดหมาย')}
                  </MenuItem>,
                  <MenuItem key="4" value="APPOINTMENT">
                    {t('นัดหมาย')}
                  </MenuItem>,
                  <MenuItem key="5" value="SUCCESS_AGREEMENT">
                    {t('เสร็จสิ้น')}
                  </MenuItem>,

                ]} /> : <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setStatus(value)
                  }
                }}
                label={t("REQUEST_STALL.FILTER.STATUS")}
                labelId="status"
                selectId="status"
                fullWidth
                value={status}
                // renderValue={() => `${t(`DEPOSIT.STATUS.${status}`)}`}
                options={[
                  <MenuItem key="ALL" value="ALL">
                    {t('DEPOSIT.STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="1" value="WAITING">
                    {t('DEPOSIT.STATUS.WAITING')}
                  </MenuItem>,
                  <MenuItem key="2" value="PROGRESS">
                    {t('ชำระแล้ว')}
                  </MenuItem>,
                  <MenuItem key="3" value="SUCCESS">
                    {t('รอนัดหมาย')}
                  </MenuItem>,


                ]} />}
            </Col>
          </Row>
        </Box>
        <Style.TableContainer>
          <TableCustom
            headCells={roleId === 4 ? headCells : headCellsAdmin}
            customScroll={true}
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={datadeposit.map((d: any, i: any) => {
              return roleId === 4 ? renderData(d, i) : renderDataAdmin(d, i);
            })}
            onSort={() => { }}
            setPageLimit={(value: number) => setPageLimit(value)}
            setPage={(value: number) => setPage(value)}
            tableMinWidth={1400}
          />
        </Style.TableContainer>
        <ModalCustom
          title={qrPay ? t("DEPOSIT.MODAL.PAY_QR") : payBank ? t("DEPOSIT.MODAL.PAY_BANK") : t("DEPOSIT.TITLE")}
          modalScroll={true}
          fullscreen="sm-down"
          size="xl"
          onClose={() => {
            dispatch(closeModal())
            setQrPay(false)
            setPayBank(false)
            setImageBill(null)
          }}
          closeButton={true}
          component={
            <>
              {!payBank && !qrPay && (<Box>
                <Divider className="mb-2" />
                <Style.BgInputZone>
                  <Box className="mb-4">{t('DEPOSIT.TABLE.DETAIL')}</Box>
                  <Row >
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.ZONE')}</p>
                    </Col >
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{datadetail?.bidder?.zoneName || "-"}</p>
                    </Col >
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.AMOUNT')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{numberFormat(datadetail?.depositAmount)} {" "} {t('DEPOSIT.TABLE.BATH') || "-"}</p>
                    </Col>

                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.STALL')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{datadetail?.bidder?.stallName}</p>
                    </Col>
                    <Style.Gridhidden item lg={6} />

                    <Col lg={3} md={6} sm={6} xs={6}>
                      {/* <p>{t('DEPOSIT.TABLE.EXPIRE_DATE')}</p> */}
                      <p>{t('กรุณาชำระเงินภายในวันที่')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{dateToView(datadetail?.depositDateEnd) || "-"}</p>
                    </Col>
                    {/* <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.PAY_DATE')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{dateToView(datadetail?.depositPayDate) || "-"}</p>
                    </Col> */}

                  </Row>

                  <Divider className="my-3" />

                  <Row>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.APPOITMENT_DATE')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{dateToView(datadetail?.appointment?.appointmentDate) || "-"}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.PLACE')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{datadetail?.appointment?.branch.branchNameTh || "-"}</p>
                    </Col>

                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{t('DEPOSIT.TABLE.TEL')}</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <p>{datadetail?.appointment?.phoneNumber || "-"}</p>
                    </Col>
                  </Row>
                </Style.BgInputZone>
              </Box>)}

              {qrPay && !payBank && (<PopupPaymentStall
                amount={bidderAmount}
                image={imageฺBill}
                qrCode={qrCode}
                time={fullDate(endDate)}
                onsubmit={() => {
                  PayDeposit()
                  dispatch(closeModal())
                  // getDetail()
                }}
                onClose={() => {
                  setImageBill(null)
                }}
                getInputProps={getInputProps()}
                getRootProps={getRootProps()}
                detail={qrDetail}
              />)}

              {!qrPay && payBank && (<PopupPaymentBank
                amount={bidderAmount}
                image={imageฺBill}
                qrCode={qrCode}
                time={fullDate(endDate)}
                onsubmit={() => {
                  BankPayDeposit()
                  dispatch(closeModal())
                }}
                ondowload={() => {
                  payWithBank()
                }}
                getInputProps={getInputProps()}
                getRootProps={getRootProps()}
              />)}


            </>
          }


        />

      </Style.MainContainer>

      <div className="print-show" ref={InformationBidder}>
        <ExportData
          headCells={t("")}
          // headCells={t("DEPOSIT.TABLE.DETAIL")}
          componant={
            <>
              {typePrint === "PRINT_DEPOSIT" && <Style.Styles className="pt-3">
                {printDeposit && printDeposit.map((d: any, i: number) => {
                  return (
                    <div key={i}>
                      <div className="row mb-3">
                        <div className="col-6">
                          {t('DEPOSIT.TABLE.BIDDER_NAME')} : {d?.depositBy?.firstname || "-"} {d?.depositBy?.lastname || "-"}
                        </div>
                        <div className="col-6">
                          {t('DEPOSIT.TABLE.TEL')} : {d?.depositBy?.mobilePhone || "-"}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.ZONE')}</p>
                        </div >
                        <div className="col-3">
                          <p>{d?.bidder?.zoneName || "-"}</p>
                        </div >
                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.AMOUNT')}</p>
                        </div>
                        <div className="col-3">
                          <p>{numberFormat(d?.depositAmount) || "-"} {" "} {t('DEPOSIT.TABLE.BATH') || "-"}</p>
                        </div>

                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.STALL')}</p>
                        </div>
                        <div className="col-3">
                          <p>{d?.bidder?.stallName}</p>
                        </div>
                        <Style.Gridhidden item lg={6} />

                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.EXPIRE_DATE')}</p>
                        </div>
                        <div className="col-3">
                          <p>{dateToView(d?.depositDateEnd) || "-"}</p>
                        </div>
                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.PAY_DATE')}</p>
                        </div>
                        <div className="col-3">
                          <p>{dateToView(d?.depositPayDate) || "-"}</p>
                        </div>

                      </div>

                      <div className="row" >
                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.APPOITMENT_DATE')}</p>
                        </div>
                        <div className="col-3">
                          <p>{dateToView(d?.appointment?.appointmentDate) || "-"}</p>
                        </div>
                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.PLACE')}</p>
                        </div>
                        <div className="col-3">
                          <p>{d?.appointment?.branch?.branchNameTh || "-"}</p>
                        </div>

                        <div className="col-3">
                          <p>{t('DEPOSIT.TABLE.TEL')}</p>
                        </div>
                        <div className="col-3">
                          <p>{d?.appointment?.phoneNumber || "-"}</p>
                        </div>
                      </div>

                      <div className="row" >
                        {d?.depositPay?.depositRef && <img src={d?.depositPay?.depositRef} alt="" style={{ width: '100%', height: "500px" }} />}
                      </div>
                      <Divider className="mx-0" />
                    </div>
                  )
                })}
              </Style.Styles>}
            </>
          }

        />
      </div>

      {loading && <Loading show={loading} type="fullLoading" />}
    </>
  );
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}