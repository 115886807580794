import { useTranslation } from "react-i18next";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../../component/input/inputTextField";
import { useEffect, useState } from "react";
import { IZone } from "../../../../api/zone/zone.api";
import FilterSelect from "../../../../component/select/filterSelect";
import _ from "lodash";
import { MenuItem } from "@mui/material";

const initStateErrorMessage = {
  NAME_ZONE: "",
  HEIGHT_ZONE: "",
  WIDTH_ZONE: "",
};

type popup = {
  data?: IZone;
  isShow: boolean | false;
  stallType: any[];
  setIsShow: (show: boolean) => void;
  onSubmit: (data: any) => void;
};

export default function PopupZone(props: popup) {
  const { t } = useTranslation();
  const { data: dataZone, isShow } = props;

  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [nameZone, setNameZone] = useState<string>("");
  const [heightZone, setHeightZone] = useState<number>();
  const [widthZone, setWidthZone] = useState<number>();

  const [vertical, setVertical] = useState<number>();
  const [horizontal, setHorizontal] = useState<number>();

  const [stallTypeId, setStallTypeId] = useState<number | null>(null);
  const [heightStall, setHeightStall] = useState<number | null>(null);
  const [widthStall, setWidthStall] = useState<number | null>(null);

  useEffect(() => {
    if (isShow === true) {
      if (dataZone) {
        const { zoneName, realHeight, realWidth } = dataZone;
        setNameZone(zoneName);
        setHeightZone(realHeight);
        setWidthZone(realWidth);
      }
    }
  }, [isShow]);

  const onSubmit = () => {
    if (!nameZone)
      return setErrorMessage({
        ...errorMessage,
        ...{ NAME_ZONE: t("กรุณากรอกชื่อโซน") },
      });
    if (!heightZone)
      return setErrorMessage({
        ...errorMessage,
        ...{ HEIGHT_ZONE: t("กรุณากรอกความสูงโซน") },
      });
    if (!widthZone)
      return setErrorMessage({
        ...errorMessage,
        ...{ WIDTH_ZONE: t("กรุณากรอกความกว้างโซน") },
      });
    let newDataArr = {
      nameZone: nameZone,
      heightZone: Number(heightZone),
      widthZone: Number(widthZone),
      vertical: vertical ? Number(vertical) : null,
      horizontal: horizontal ? Number(horizontal) : null,
      stallTypeId,
      heightStall,
      widthStall,
    };
    if (props.onSubmit) props.onSubmit(newDataArr);
    props.setIsShow(false);
  };

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage);
  };

  return (
    <ModalCustom
      title={
        dataZone?.isEdit ? t("รายละเอียดแก้ไขโซน") : t("รายละเอียดเพิ่มโซน")
      }
      // fullscreen="sm-down"
      size="lg"
      closeButton
      onReset={() => props.setIsShow(false)}
      onSubmit={() => onSubmit()}
      textBtnCancel={t("BUTTON.CANCEL")}
      textBtnConfirm={t("PREFIX.BUTTON.SAVE")}
      component={
        <>
          <Row className={`mx-0 pt-2 pt-lg-0`}>
            <Col lg={2} className="m-auto">
              {t("ชื่อโซน")}
            </Col>
            <Col lg={10}>
              <InputTextField
                key="name-zone"
                helperText={errorMessage.NAME_ZONE}
                onchange={(event) => {
                  setNameZone(event.target.value);
                  clearErrorMessage();
                }}
                value={nameZone}
              />
            </Col>
          </Row>
          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col lg={2} className="m-auto">
              {t("ขนาดโซน")}
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={2} className="m-auto">
                  {"ความกว้าง :"}
                </Col>
                <Col lg={2} style={{ width: 100 }} className="m-auto">
                  <InputTextField
                    key="height-zone"
                    helperText={errorMessage.HEIGHT_ZONE}
                    onchange={(event) => {
                      setHeightZone(event.target.value);
                      clearErrorMessage();
                    }}
                    value={heightZone}
                    inputProps={{ inputProps: { min: 0} }}
                    type="tel"
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col lg={2} className="m-auto">
                  {t("เมตร")}
                </Col>
                <Col lg={2} className="m-auto">
                  {"ความยาว :"}
                </Col>
                <Col lg={2} style={{ width: 100 }} className="m-auto">
                  <InputTextField
                    key="width-zone"
                    helperText={errorMessage.WIDTH_ZONE}
                    onchange={(event) => {
                      setWidthZone(event.target.value);
                      clearErrorMessage();
                    }}
                    value={widthZone}
                    inputProps={{ inputProps: { min: 0} }}
                    type="tel"
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Col>
                <Col lg={2} className="m-auto">
                  {t("เมตร")}
                </Col>
              </Row>
            </Col>
          </Row>
          {dataZone?.isEdit !== true && (
            <>
              <hr className="my-2" />

              <Row className={`mx-0 pt-2 pt-lg-4`}>
                <Col lg={2} className="mb-auto pt-2">
                  {t("จำนวนแผงค้า")}
                </Col>
                <Col lg={10}>
                  <Row>
                    <Col
                      lg={2}
                      style={{ whiteSpace: "nowrap" }}
                      className="my-auto"
                    >
                      {"แถวแนวตั้ง :"}
                    </Col>
                    <Col lg={6}>
                      <InputTextField
                        key="vertical-stall"
                        type="tel"
                        onchange={(event) => {
                          setVertical(event.target.value);
                          clearErrorMessage();
                        }}
                        value={vertical}
                        inputProps={{ inputProps: { min: 0} }}
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                      />
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col
                      lg={2}
                      style={{ whiteSpace: "nowrap" }}
                      className="my-auto"
                    >
                      {"แถวแนวนอน :"}
                    </Col>
                    <Col lg={6}>
                      <InputTextField
                        key="horizontal-stall"
                        type="tel"
                        onchange={(event) => {
                          setHorizontal(event.target.value);
                          clearErrorMessage();
                        }}
                        value={horizontal}
                        inputProps={{ inputProps: { min: 0} }}
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={`mx-0 pt-2 pt-lg-4`}>
                <Col lg={2} xs={6}>
                  {t("ประเภทแผงค้า (ตั้งต้น)")}
                </Col>
                <Col lg={7} xs={6}>
                  <FilterSelect
                    onchange={(event) => {
                      const value = event.target.value;
                      setStallTypeId(value);
                    }}
                    renderValue={() =>
                      `${
                        _.find(
                          props.stallType,
                          (p: any) => p.stallTypeId === stallTypeId
                        )?.stallTypeNameTh
                      }`
                    }
                    label="ประเภทแผงค้า"
                    selectId="select-stallType"
                    labelId="label-stallType"
                    value={stallTypeId}
                    options={[
                      _.map(props.stallType, (props: any, i: number) => (
                        <MenuItem key={i} value={props.stallTypeId}>
                          {props.stallTypeNameTh}
                        </MenuItem>
                      )),
                    ]}
                  />
                </Col>
              </Row>
              <Row className={`mx-0 pt-2 pt-lg-4`}>
                <Col lg={2} xs={12} className="m-auto">
                  {t("ขนาดแผงค้า (ตั้งต้น)")}
                </Col>
                <Col lg={10} xs={10}>
                  <Row>
                    <Col lg={2} xs={6} className="m-auto">
                      {t("MARKET_MAP.POPUP.ADD_DETAIL.WIDTH")}
                    </Col>
                    <Col
                      lg={2}
                      xs={5}
                      style={{ width: 100 }}
                      className="m-auto"
                    >
                      <InputTextField
                        key="height-zone"
                        type="tel"
                        onchange={(event) => {
                          setHeightStall(event.target.value);
                          clearErrorMessage();
                        }}
                        value={heightStall}
                        inputProps={{ inputProps: { min: 0} }}
                    
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                      />
                    </Col>
                    <Col lg={2} xs={1} className="m-auto">
                      {t("MARKET_MAP.POPUP.ADD_DETAIL.UNIT")}
                    </Col>
                    <Col lg={2} xs={6} className="m-auto">
                      {t("MARKET_MAP.POPUP.ADD_DETAIL.HEIGHT")}
                    </Col>
                    <Col
                      lg={2}
                      xs={5}
                      style={{ width: 100 }}
                      className="m-auto"
                    >
                      <InputTextField
                        key="width-zone"
                        type="tel"
                        onchange={(event) => {
                          setWidthStall(event.target.value);
                          clearErrorMessage();
                        }}
                        value={widthStall}
                        inputProps={{ inputProps: { min: 0} }}
                    onkeypress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                      />
                    </Col>
                    <Col lg={2} xs={1} className="m-auto">
                      {t("MARKET_MAP.POPUP.ADD_DETAIL.UNIT")}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </>
      }
    />
  );
}
