import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'lease-agreement'

export interface LeaseAgreementInterface {
    appointmentId: number
    leaseAgreementNo: string
    leaseAgreementDaft: string
    branchId: number
    leaseAgreementDate: Date
    leaseAgreementRemark: string
    customerId: number
    firstname: string
    lastname: string
    idCard: string
    birthDay: Date
    mobilePhone: string
    email: string
    taxId: string
    houseNumber: string
    moo: string
    village: string
    soi: string
    road: string
    provinceId: number
    districtId: number
    subDistrictId: number
    zipCode: string
    leaseAgreementTypeId: number
    leaseAgreementDateStart: Date
    leaseAgreementDateEnd: Date
    amount: number
    leaseAgreementApprove: {
        approveDate: Date
        leaseAgreementId: number
        depositPayId: number
        bidderId: number
        remark: string
        employeeId: number
        status: string
        leaseTypeId: number
    }
    status: string
}

export default class LeaseAgreementApi extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(payload: LeaseAgreementInterface): Promise<any> {
        return this.api.post(path, payload)
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`).then((res) => res)
    }

    static update(id: number, body: LeaseAgreementInterface): Promise<any> {
        return this.api.patch(`${path}/${id}`, body)
    }
    static updateInList(id: number, body: any): Promise<any> {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status })
    }

    static delete(id: number): Promise<any> {
        return this.api.delete(`${path}/${id}`)
    }

    static findNotCancel(id: number): Promise<any> {
        return this.api.get(`${path}/${id}/lease-without-cancel`)
    }
}
