import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Divider, Grid, Typography, MenuItem, Button, Box, Tooltip, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

/** COMPONENT */
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import TableCustom from "../../../component/table/tableCustom";
import TableRowCommon from "../../../component/table/TableRowCommon";
import AddHeir from "./addHeir";
import InputDatePicker from "../../../component/input/inputDatePicker";

/** API, UTIL */
import { debouncedApiCall, getProfile, handleTypingNumber } from "../../../utils/app.utils";
import UserApi from "../../../api/auth/users.api";
import ProvincesApi from "../../../api/setting/provinces/provinces.api";
import PrefixApi from "../../../api/setting/prefix/prefix.api";
import BidderApi from "../../../api/stall/bidder/bidder.api";
import { dateToView } from "../../../utils/date.utils";
import { numberFormat } from "../../../utils/common";

/** STYLE */
import * as Style from "./submitStall.style";
import {
  faCirclePlus,
  faEye,
  faTrashAlt,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";

import { imgs } from "../../../constants/images";
import CustomerHeirApi from "../../../api/customer-heir/customerHeir.api";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";
import { Col, Row } from "react-bootstrap";
import LeaseAgreementApi from "../../../api/stall/leaseAgreement/leaseAgreement.api";
import { useDropzone } from "react-dropzone";
import { decode } from 'js-base64';
import { colors } from "../../../constants/colors";
import RequestToRentStall from "./RequestToRentStall";
import RelationshipsApi from "../../../api/setting/relationship/relationship.api";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import HelperText from "../../../component/input/helperText";
import Loading from "../../../component/loading/loading";


export type requestToRentStallType = {
  customerId?: number;
  prefixId?: number | null;
  lastname?: string;
  firstname?: string;
  idCard: string;
  birthDay?: string;
  mobilePhone?: string;
  email?: string;
  taxId: string;
  houseNumber?: string;
  moo?: string;
  village?: string;
  soi?: string;
  road?: string;
  provinceId?: number;
  districtId?: number;
  subDistrictId?: number;
  relationshipsId?: number;
  zipCode?: string;
  imageFile: File | null
  status: "ACTIVE";
  isShowImage?: boolean;
}

export type errorMessageRequestToRentStallForm = {
  customerId?: string;
  prefixId?: string;
  firstname?: string;
  lastname?: string;
  idCard?: string;
  birthDay?: string;
  mobilePhone?: string;
  email?: string;
  taxId?: string;
  houseNumber?: string;
  moo?: string;
  village?: string;
  soi?: string;
  road?: string;
  provinceId?: string;
  districtId?: string;
  subDistrictId?: string;
  zipCode?: string;
  status?: string;
  relationshipId?: string;
  file?: string;
}
const initialErrorMessage: errorMessageRequestToRentStallForm = {
  birthDay: ``,
  customerId: ``,
  districtId: "",
  email: "",
  firstname: "",
  houseNumber: "",
  idCard: "",
  lastname: "",
  mobilePhone: "",
  moo: "",
  prefixId: "",
  provinceId: "",
  road: "",
  soi: "",
  status: "",
  subDistrictId: "",
  taxId: "",
  village: "",
  zipCode: "",
  relationshipId: "",
  file: ``
}

export default function SubmitStall() {

  const { t }: any = useTranslation();
  const history = useHistory();
  const location: any = useLocation()
  const DataTable = location?.state?.params?.dataTable
  const roundNo = location?.state?.params?.round
  const announceId = location?.state?.params?.announceId
  // console.log(announceId)

  const [errorMessage, setErrorMessage] = useState<errorMessageRequestToRentStallForm>(initialErrorMessage)
  const [dataTable, setDataTable] = useState<any[]>(DataTable)
  const profile: any = JSON.parse(getProfile() || '{}')

  const [prefixData, setPrefixData] = useState<any[]>([])
  const [prefix, setPrefix] = useState<string>('นาย')

  const [provinceData, getProvinceData] = useState<any[]>([])
  const [districtsData, getDistrictsData] = useState<any[]>([])
  const [subDistrictsData, getSubDistrictsData] = useState<any[]>([])

  const [profileImg, setProfileImg] = useState<any>(null)
  const [customerId, setCustomerId] = useState<number>(JSON.parse(decode(profile))?.userId)
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [birthDay, setBirthDay] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [moo, setMoo] = useState<string>("");
  const [village, setVillage] = useState<string>("");
  const [soi, setSoi] = useState<string>("");
  const [road, setRoad] = useState<string>("");
  const [province, setProvince] = useState(null)
  const [district, setDistrict] = useState(null)
  const [subDistrict, setSubDistrict] = useState(null)
  const [zipCode, setZipCode] = useState<string>("");
  const [components, setComponents] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [postbidder, setPostBidder] = useState<any[]>([])
  const [heirList, setHeirList] = useState<any>([])
  const [leaseAgreement, seLeaseAgreement] = useState<any[]>([])
  const [seeCard, setSeeCard] = useState<boolean>(false)
  const [idCardImg, setIdCardImg] = useState<string>("")
  const renEffect = useRef(false)
  const [fileType, setFileType] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileIdCard, setFileIdCard] = useState<any | File>(null);

  const [detailData, setDetailData] = useState<any>(null);


  const [requestToRentStallForm, setRequestToRentStallForm] = useState<requestToRentStallType[]>([])
  const [searchCustomerHair, setSearchCustomerHair] = useState<string>("");
  const [errorMessageIdCard, setErrorMessageIdCard] = useState<string>("");
  const [userId, setUserId] = useState<number>(Number);
  const [userDetail, setUserDetail] = useState<any>(null);



  const handleAddRequestToRentStall = () => {
    if (JSON.parse(decode(profile))?.userId === userId) {
      notiError('ไม่สามารถเพิ่มตนเองให้เป็นผู้สืบทอดได้')
      setUserDetail(null)
      return;
    } else if (requestToRentStallForm?.some((list) => list.idCard === userDetail?.idCard)) {
      notiError('กรุณาเลือกบุคคลอื่น')
      setUserDetail(null)
      return;
    }
    let newRow: requestToRentStallType = {
      customerId: userDetail?.customerId || JSON.parse(decode(profile))?.customerId || 0,
      prefixId: userDetail?.prefixId || 0,
      firstname: userDetail?.firstname || "",
      idCard: userDetail?.idCard || "",
      lastname: userDetail?.lastname || "",
      birthDay: userDetail?.birthDay || "",
      districtId: userDetail?.districtId || 0,
      email: userDetail?.email || "",
      houseNumber: userDetail?.houseNumber || "",
      mobilePhone: userDetail?.mobilePhone || "",
      moo: userDetail?.moo || "",
      provinceId: userDetail?.provinceId || 0,
      road: userDetail?.road || "",
      soi: userDetail?.soi || "",
      subDistrictId: userDetail?.subDistrictId || "",
      taxId: userDetail?.taxId || "",
      village: userDetail?.village || "",
      zipCode: userDetail?.zipCode || "",
      imageFile: null,
      isShowImage: false,
      status: "ACTIVE"
    }
    setRequestToRentStallForm((prev) => {
      return [...prev, newRow]
    })
    setUserDetail(null)
  }
  const handleDeleteRequestToRentStallForm = (index: number) => {
    setRequestToRentStallForm((prev) => {
      return prev?.filter((_, i: number) => i !== index)
    })
  }
  const handleChangeRequestToRentStallForm = (name: string, value: any, index: number) => {
    setRequestToRentStallForm((prev) => {
      return prev?.map((list, i: number) => {
        if (i === index) {
          return { ...list, [name]: value }
        } else return list
      })
    })
  }
  const clearErrorMessage = () => setErrorMessage(initialErrorMessage)



  const validateValue = (): boolean => {
    let validateCheck: boolean = false;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!idCard.split("-").join("").replaceAll("_", "")) {
      setErrorMessage((prev) => ({ ...prev, idCard: 'ระบุเลขบัตรประจำตัวประชาชน' }));
      return validateCheck = true;
    } else if (idCard.split("-").join("").replaceAll("_", "").length !== 13) {
      setErrorMessage((prev) => ({ ...prev, idCard: 'เลขบัตรประจำตัวประชาชนต้องมี 13 หลัก' }));
      return validateCheck = true;
    } else if (taxId && taxId.split("-").join("").replaceAll("_", "").length !== 13 && taxId.split("-").join("").replaceAll("_", "").length !== 0) {
      setErrorMessage((prev) => ({ ...prev, taxId: 'ระบุเลขภาษีให้ครบ 13 หลัก' }))
      return validateCheck = true;
    } else if (!houseNumber) {
      setErrorMessage((prev) => ({ ...prev, houseNumber: 'ระบุที่อยู่' }))
      return validateCheck = true;
    } else if (!province) {
      setErrorMessage((prev) => ({ ...prev, provinceId: 'ระบุจังหวัด' }))
      return validateCheck = true;
    } else if (!district) {
      setErrorMessage((prev) => ({ ...prev, districtId: 'ระบุอำเภอ' }))
      return validateCheck = true;
    } else if (!subDistrict) {
      setErrorMessage((prev) => ({ ...prev, subDistrictId: 'ระบุอำเภอ' }))
      return validateCheck = true;
    } else if (requestToRentStallForm?.some((list) => !list.prefixId)) {
      setErrorMessage((prev) => ({ ...prev, prefixId: 'ระบุคำนำหน้า' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.firstname)) {
      setErrorMessage((prev) => ({ ...prev, firstname: 'ระบุชื่อ' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.lastname)) {
      setErrorMessage((prev) => ({ ...prev, lastname: 'ระบุนามสกุล' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.idCard)) {
      setErrorMessage((prev) => ({ ...prev, idCard: 'ระบุเลขประจำตัวประชาชน' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.birthDay)) {
      setErrorMessage((prev) => ({ ...prev, birthDay: 'ระบุวันเกิด' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.relationshipsId)) {
      setErrorMessage((prev) => ({ ...prev, relationshipId: 'ระบุความสัมพันธ์' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.mobilePhone)) {
      setErrorMessage((prev) => ({ ...prev, mobilePhone: 'ระบุหมายเลขโทรศัพท์' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.email)) {
      setErrorMessage((prev) => ({ ...prev, email: 'ระบุอีเมล' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.provinceId)) {
      setErrorMessage((prev) => ({ ...prev, provinceId: 'ระบุจังหวัด' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.districtId)) {
      setErrorMessage((prev) => ({ ...prev, districtId: 'ระบุอำเภอ' }))
      return validateCheck = true
    } else if (requestToRentStallForm?.some((list) => !list.subDistrictId)) {
      setErrorMessage((prev) => ({ ...prev, subDistrictId: 'ระบุตำบล' }))
      return validateCheck = true
    }
    // else if (!fileIdCard) {
    //   notiError('กรุณาอัพโหลดไฟล์บัตรประจำตัวประชาชน')
    //   return validateCheck = true
    // }

    requestToRentStallForm.forEach((list) => {
      const conditionTest = emailRegex.test(list?.email || "");
      if (!conditionTest) {
        setErrorMessage((prev) => ({ ...prev, email: 'รูปแบบอีเมลไม่ถูกต้อง' }))
        return validateCheck = true
      }
      if (list.idCard.split("-").join("").replaceAll('_', '').length !== 13) {
        setErrorMessage((prev) => ({ ...prev, idCard: 'ระบุเลขบัตรประจำตัวประชาชนให้ครบถ้วน' }))
        return validateCheck = true
      }
      // if (!list.imageFile) {
      //   notiError('อัพโหลดสำเนาบัตรประชาชนให้ครบถ้วน')
      //   return validateCheck = true
      // }
    })
    return validateCheck;
  }

  const updateMember = async () => {
    const condition: any = {
      prefixId: prefix,
      firstname: firstname,
      lastname: lastname,
      idCard: idCard ? idCard.split("-").join("") : "",
      birthDay: birthDay,
      mobilePhone: mobilePhone,
      email: email,
      taxId: taxId ? taxId.split("-").join("") : "",
      houseNumber: houseNumber,
      moo: moo,
      village: village,
      soi: soi,
      road: road,
      provinceId: province,
      districtId: district,
      subDistrictId: subDistrict,
      zipCode: zipCode,
      status: "ACTIVE"
    }
    setIsLoading(true)
    await UserApi.update(condition, JSON.parse(decode(profile))?.userId)
      .then((res) => {
        if (res.status === 200) {
          notiSuccess(t('อัพเดตข้อมูล สำเร็จ'))
        } else {
          notiError(t("อัพเดตข้อมูล ไม่สำเร็จ"), "", null, null);
        }

      })
      .catch(() => {
        notiError(t("อัพเดตข้อมูล ไม่สำเร็จ"), "", null, null);
      })
      .finally(() => setIsLoading(false))
  }

  const submitBidder = async () => {
    setIsLoading(true)
    setBidderValue()
    // console.log(postbidder)

    await BidderApi.createbulk(postbidder)
      .then((res) => {
        if (res.status === 201) {
          // notiSuccess(t('ยื่นนประสงค์เช่าแผงค้า สำเร็จ'))
          // notiSuccess(t('ยื่นนประสงค์เช่าแผงค้า สำเร็จ'), 'go', "/request-stall", null)
          res?.data?.map(async (list: any) => {
            setIsLoading(false)
            if (requestToRentStallForm?.length !== 0) {
              updateHeir(list?.refId)
            } else {
              notiSuccess(t('ยื่นนประสงค์เช่าแผงค้า สำเร็จ'), 'go', "/request-stall", null)
            }
            // const formData = new FormData();
            // formData.append('file', fileIdCard)
            // formData.append('refId', list?.refId)
            // formData.append('fileType', 'customer' || list?.fileType)
            // await FileuploadApi.uploadfile(formData)
            //   .then(res => {
            //     if (res.status === 201) {
            //       // notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
            //       // notiSuccess(t('ยื่นนประสงค์เช่าแผงค้า สำเร็จ'), 'go', "/request-stall", null)
            //     }
            //   })
            //   .catch(error => {
            //     notiError(t('อัพโหลดไฟล์เอกสารไม่สำเร็จ'))
            //   })
            //   .finally(() => {
            //     setIsLoading(false)
            //     if (requestToRentStallForm?.length !== 0){
            //       updateHeir(list?.refId)
            //     } else {
            //       notiSuccess(t('ยื่นนประสงค์เช่าแผงค้า สำเร็จ'), 'go', "/request-stall", null)
            //     }
            //   })
          })
        } else {
          notiError(t("อัพเดตข้อมูล ไม่สำเร็จ"), "", null, null);
        }
      })
      .catch(() => {
        notiError(t("อัพเดตข้อมูล ไม่สำเร็จ"), "", null, null);
      })
      .finally(() => {
        setIsLoading(false)
        // history.push('/request-stall')
      })
  }

  // console.log(detailData)


  const onSubmit = async () => {
    let check: boolean = validateValue()
    if (check) {
      return false
    } else {
      if (requestToRentStallForm?.length !== 0) {
        await updateMember()
        // await updateHeir()
        await submitBidder()
      } else {
        await updateMember()
        await submitBidder()
      }
      // console.log(requestToRentStallForm)
    }
  }






  const EmptyGoBack = () => {
    if (dataTable.length === 0) {
      history.goBack()
    }
  }

  useEffect(() => {
    EmptyGoBack()
  }, [dataTable])

  const setBidderValue = () => {
    // let biddervalue = {}
    // DataTable.forEach((element: any) => {
    //   console.log(element.stallId)
    //   biddervalue =
    //   {
    //     userId: JSON.parse(decode(profile))?.userId,
    //     branchId: element?.branchId,
    //     roundId: roundNo.find((e: any) => e?.stall?.stallId === element.stallId)?.roundId,
    //     // roundId: Number(roundNo.filter((e: any) => e?.stall?.stallId === element.stallId)?.map((value: any) => value.roundId)),
    //     bidderDate: moment(Date.now()).format("YYYY-MM-DD"),
    //     bidTypeId: 1,
    //     zoneId: element.zoneId,
    //     stallId: element.stallId,
    //     stallTypeName: element.stallType.stallTypeNameTh,
    //     bidderAmount: Number(element.bidderAmount),
    //     announceId: announceId,
    //     status: "PENDING"
    //   }
    //   setPostBidder(prev => [...prev, biddervalue])
    // })
    const newArray = [...DataTable]
    const data = newArray.map((element: any) => {
      return {
        userId: JSON.parse(decode(profile))?.userId,
        branchId: element?.branchId,
        roundId: roundNo.find((e: any) => e?.stall?.stallId === element.stallId)?.roundId,
        // roundId: Number(roundNo.filter((e: any) => e?.stall?.stallId === element.stallId)?.map((value: any) => value.roundId)),
        bidderDate: moment(Date.now()).format("YYYY-MM-DD"),
        bidTypeId: 1,
        zoneId: element.zoneId,
        stallId: element.stallId,
        stallTypeName: element.stallType.stallTypeNameTh,
        bidderAmount: Number(element.bidderAmount),
        announceId: announceId,
        status: "PENDING"
      }
    })
    setPostBidder(data);
  }

  useEffect(() => {
    if (renEffect.current === false) {
      setBidderValue()
      renEffect.current = true
    }
  }, [])

  const [customerHeir, setCustomerHeir] = useState({
    profile: "",
    customerId: JSON.parse(decode(profile))?.customerId,
    lastname: "",
    firstname: "",
    idCard: "",
    birthDay: "",
    mobilePhone: "",
    email: "",
    taxId: "",
    houseNumber: "",
    moo: "",
    village: "",
    soi: "",
    road: "",
    zipCode: "",
    districtId: null,
    prefixId: null,
    provinceId: null,
    subDistrictId: null,
    status: "ACTIVE"
  });

  const getPrefixes = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5
    }

    const res = await PrefixApi.findAll(condition)
    setPrefixData(res.data)
  }

  useEffect(() => {
    getPrefixes()
    setCustomerId(JSON.parse(decode(profile))?.userId)
  }, [])

  const getCustomerDetail = async () => {
    const condition: any = {
      id: customerId
    }

    await UserApi.getDetail(condition)
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          setDetailData(res?.data)
          setPrefix(res.data?.prefixId)
          setFirstname(res.data.firstname)
          setLastname(res.data.lastname)
          setIdCard(res.data.idCard)
          setBirthDay(res.data.birthDay)
          setMobilePhone(res.data.mobilePhone)
          setEmail(res.data.email)
          setTaxId(res.data.taxId)
          setHouseNumber(res.data.houseNumber)
          setMoo(res.data.moo)
          setVillage(res.data.village)
          setSoi(res.data.soi)
          setRoad(res.data.road)
          setDistrict(res.data.districtId)
          setSubDistrict(res.data.subDistrictId)
          setProvince(res.data.provinceId)
          setZipCode(res.data.zipCode)
          setHeirList(res.data.customerHier)
        }
      })
      .catch(error => {
        throw new Error("Can't fetch data", error)
      })
  }

  useEffect(() => {
    getCustomerDetail()
  }, [])

  const getProvince = async () => {

    const res = await ProvincesApi.findAll()
    getProvinceData(res.data)
  }

  useEffect(() => {
    getProvince()
  }, [])

  const getDistricts = async () => {
    getDistrictsData([])
    const condition: any = {
      provinceId: Number(province)

    }
    const res = await ProvincesApi.districts(condition)
    if (res.status === 200) {
      getDistrictsData(res.data)
    } else {
      getDistrictsData([])
    }
  }

  const getSubDistricts = async () => {
    getSubDistrictsData([])
    const condition: any = {
      districtId: Number(district)
    }
    const res = await ProvincesApi.subDistricts(condition)
    if (res.status === 200) {
      getSubDistrictsData(res.data)
    } else {
      getSubDistrictsData([])
    }
  }
  useEffect(() => {
    getDistricts();
  }, [province]);

  useEffect(() => {
    getSubDistricts();
  }, [district]);

  const loadLeaseAgreement = async () => {
    const condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: JSON.parse(decode(profile))?.userId
    }
    LeaseAgreementApi.findAll(condition)
      .then((res) => {
        if (res.status === 200) {
          seLeaseAgreement(res.data)
        } else {
          seLeaseAgreement([])
        }
      })
      .catch(() => {
        seLeaseAgreement([])
      })
  }

  useEffect(() => {
    loadLeaseAgreement()
  }, [])




  const updateHeir = async (ref: number) => {
    const condition = requestToRentStallForm?.map((list) => {
      return {
        ...list,
        bidderId: ref,
        idCard: list?.idCard.split("-").join("") || "",
        taxId: list?.taxId ? list?.taxId.split("-").join("") : "",
        isShowImage: undefined,
        imageFile: undefined,
      }
    })
    setIsLoading(true)
    // await condition.forEach(async (list, i: number) => {
    //   await CustomerHeirApi.createHeir(list)
    //     .then(async (res) => {
    //       if (res.status === 201) {
    //         const file: File | null = requestToRentStallForm[i].imageFile
    //         if (file) {
    //           const formData = new FormData();
    //           formData.append('file', file)
    //           formData.append('refId', res.data?.refId)
    //           formData.append('fileType', res.data?.fileType)
    //           await FileuploadApi.uploadfile(formData)
    //             .then(res => {
    //               if (res.status === 200) {
    //                 notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
    //               }
    //             })
    //             .catch(error => {
    //               notiError(t('อัพโหลดไฟล์เอกสารไม่สำเร็จ'))
    //             })
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       notiError(t("อัพเดตข้อมูลผู้สืบทอด ไม่สำเร็จ"), "", null, null);
    //     })
    //     .finally(() => setIsLoading(false))
    // })
    await CustomerHeirApi.createHeirBulk(condition)
      .then(async (res) => {
        if (res.status === 201) {
          // const file: File | null = requestToRentStallForm[i].imageFile
          // if (file) {
          //   const formData = new FormData();
          //   formData.append('file', file)
          //   formData.append('refId', res.data?.refId)
          //   formData.append('fileType', res.data?.fileType)
          //   await FileuploadApi.uploadfile(formData)
          //     .then(res => {
          //       if (res.status === 200) {
          //         notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
          //       }
          //     })
          //     .catch(error => {
          //       notiError(t('อัพโหลดไฟล์เอกสารไม่สำเร็จ'))
          //     })
          // }
          // notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
          notiSuccess(t('ยื่นนประสงค์เช่าแผงค้า สำเร็จ'), 'go', "/request-stall", null)
        } else {
          notiError(t('อัพโหลดไฟล์เอกสารไม่สำเร็จ'))
        }
      })
      .catch(() => {
        notiError(t("อัพเดตข้อมูลผู้สืบทอด ไม่สำเร็จ"), "", null, null);
      })
      .finally(() => setIsLoading(false))
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileimg = acceptedFiles[0];

      const fileName = fileimg?.name.split(".");
      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {
        setFileIdCard(fileimg)
        setIdCardImg(URL.createObjectURL(acceptedFiles[0]));
        setFileType(fileName[fileName?.length - 1])
        setSeeCard(true)
      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
    },
    [idCardImg]
  );

  const onDropProfile = useCallback(
    (acceptedFiles) => {
      const fileimg = acceptedFiles[0];

      const fileName = fileimg?.name.split(".");
      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {

        setProfileImg(URL.createObjectURL(acceptedFiles[0]));

      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
    },
    [profileImg]
  );

  const onDropHeir = useCallback(
    (acceptedFiles) => {
      const fileimg = acceptedFiles[0];

      const fileName = fileimg?.name.split(".");
      console.log(fileName[fileName?.length - 1]);
      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {

        setCustomerHeir({ ...customerHeir, profile: URL.createObjectURL(acceptedFiles[0]) })

      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
    },
    [customerHeir]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { getRootProps: getRootProfileProps, getInputProps: getInputProfileProps, } = useDropzone({ onDrop: onDropProfile })
  // const { getRootProps: getRootHeirProps, getInputProps: getInputHeirProps, } = useDropzone({ onDrop: onDropHeir })

  const headCells = [
    { id: "no", label: t("STALL.TABLE.LIST"), align: "left", colSpan: 5 },
    {
      id: "date",
      label: t("STALL.TABLE.SELECT") + " " + `${DataTable?.length || 0}` + " " + t("STALL.TABLE.UNIT"),
      align: "center",
      colSpan: 2,

    },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { bidderDate, zoneName, stallName, stallType, bidderAmount, bidderTypeName, bidderId } = objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        {
          option: "COMPONENT",
          align: "center",
          component: <Style.NumberInTable>{no}</Style.NumberInTable>,

          width: 80,
        },
        { option: "TEXT", align: "left", label: t("STALL.ROW.ZONE") + zoneName },
        { option: "TEXT", align: "left", label: t("STALL.ROW.STALL") + stallName },
        {
          option: "TEXT",
          align: "left",
          label: t("STALL.ROW.TYPE_STALL") + stallType?.stallTypeNameTh,
        },
        {
          option: "TEXT",
          align: "left",
          label: t("STALL.ROW.BIDDERAMOUNT") + " " + numberFormat(bidderAmount),
        },
        {
          option: "TEXT",
          align: "left",
          label: t("STALL.ROW.EXPIRE_DATE") + dateToView(bidderDate),
        },
        {
          option: "COMPONENT",
          align: "left",
          width: 50,
          component: (
            <Tooltip title="ลบรายการ">
              <IconButton onClick={() => handleDelete(objData)}>
                <DeleteIcon color='error' />
                {/* <FontAwesomeIcon icon={faTrashAlt} color="red" /> */}
              </IconButton>
            </Tooltip>
            // <FontAwesomeIcon icon={faTrashAlt} color="red" onClick={() => { handleDelete(objData) }} />
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };



  const handleDelete = async (x: any) => {
    if (dataTable.includes(x)) {
      setDataTable(dataTable.filter((item: any) => item.stallId !== x.stallId))
    }
  };

  const findIdCards = async (idCard: string) => {
    await UserApi.findIdcard(idCard)
      .then(res => {
        if (res.status === 200) {
          setUserDetail(res?.data)
          setUserId(res.data?.userId)
          // return res.data?.userId || 0
        } else {
          setErrorMessageIdCard("ไม่พบข้อมูลผู้ใช้จากหมายเลขบัตรประจำตัวประชาชนนี้ หรือกรณีไม่เป็นสมาชิก กรุณาสมัครสมาชิก")
          // setErrorMessageIdCard("กรุณาสมัครสมาชิกผู้ใช้งาน")
          setUserDetail(null)
        }
      })
  }

  // console.log(userDetail)


  return (
    <Style.MainContainer>
      {isLoading && <Loading show />}
      <Style.SliderContainer>
        <TableCustom
          headCells={headCells}
          customScroll
          page={1}
          pageLimit={1}
          sortType={"ASC"}
          sortBy=""
          rowCount={1}
          rowsData={dataTable && dataTable.map((d: any, i: any) => {
            return renderData(d, i);
          })}
          onSort={() => { }}
          setPageLimit={(value: number) => setPageLimit(value)}
          setPage={(value: number) => setPage(value)}
          hidePagination
        />
      </Style.SliderContainer>

      <Style.Topic>{t("STALL.TEXT.SUBMIT_RENTAL")}</Style.Topic>

      <Style.InformationContainer>
        <>
          <Style.SubtitleContainer>
            <Style.FlexRow className="mb-2">
              <Style.Rentaller>
                {t("STALL.TEXT.INFORMATION_RENTAL")}
              </Style.Rentaller>
              <Style.CountStall>
                {t("STALL.TEXT.NUMBER_STALL")} {"(" + leaseAgreement.length + ")"}
              </Style.CountStall>
            </Style.FlexRow>
          </Style.SubtitleContainer>
          <Divider />
          <Style.InformationRenter>
            <Style.BgInformation>
              <Grid container spacing={2}>
                {/* <Grid
                  item
                  lg={3}
                  sm={12}
                  xs={12}
                  className="w-100 d-flex justify-content-center"
                  {...getRootProfileProps()}
                >
                  <input {...getInputProfileProps()} />
                  <Style.PicRenter src={profileImg || imgs.default_Avatar} alt="" />
                </Grid> */}
                <Style.GridInput item lg={4} sm={4} xs={12}>
                  <div className="">
                    <Style.FlexRow>
                      <Style.Detail>{t("STALL.DETAIL.PREFIX")}</Style.Detail>
                      <Style.RequestDetail>*</Style.RequestDetail>
                    </Style.FlexRow>

                    <FilterSelect
                      onchange={(e: any) => {
                        setPrefix(e.target.value)

                      }}
                      options={prefixData && prefixData.map((e: any) => {
                        return <MenuItem value={e.prefixId} >{e.prefixNameTh || e.prefixNameEn}</MenuItem>;
                      })}
                      selectId="select-prefix"
                      value={prefix}
                      labelId="label-prefixId"
                      required
                      disabled
                    />
                  </div>
                </Style.GridInput>
                <Style.GridInput item lg={4} sm={4} xs={12}>
                  <div>
                    <InputTextField
                      disabled
                      value={firstname}
                      onchange={(e: any) => setFirstname(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.NAME")}
                    />
                  </div>
                </Style.GridInput>
                <Style.GridInput item lg={4} sm={4} xs={12}>
                  <div>
                    <InputTextField
                      disabled
                      value={lastname}
                      onchange={(e: any) => setLastname(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.SUR_NAME")}
                    />
                  </div>
                </Style.GridInput>
                <Grid item lg={3} sm={3} xs={12}>
                  <Col>
                    <div className="d-flex flex-row align-items-center">
                      <Style.Heading className="pt-1">{t("AGREEMENT.INPUT.ID_CARD")}</Style.Heading>
                      <p style={{ color: colors.red }} >*</p>
                    </div>
                    <Style.CustomPatternFormat
                      format={"#-####-#####-#-##"}
                      mask={"_"}
                      allowEmptyFormatting={true}
                      value={idCard}
                      onChange={(e: any) => {
                        setIdCard(e.target.value)
                        clearErrorMessage()
                      }}
                    />
                    {errorMessage.idCard && <HelperText label={errorMessage.idCard} />}
                  </Col>

                </Grid>
                <Grid item lg={4} sm={5}>
                  <div className="mt-2">
                    <InputDatePicker
                      // disabled
                      required
                      allowClear
                      onClear={() => setBirthDay("")}
                      disableFuture
                      value={birthDay}
                      key={'date'}
                      onChange={(e: any) => setBirthDay(moment(e).format('YYYY-MM-DD'))}
                      heading={t("STALL.DETAIL.BIRTH_DATE")}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} sm={2}>
                  <div className="d-flex flex-column mt-2">
                    <Style.FlexRow>
                      <Style.Detail>{t("STALL.DETAIL.AGE")} {" "}</Style.Detail>
                      <Style.RequestDetail>*</Style.RequestDetail>
                    </Style.FlexRow>
                    <Style.BoxInputAge>
                      <InputTextField
                        disabled
                        value={birthDay && moment().diff(birthDay, "years") || "0"}
                        inputTextAlign="center"
                        size="medium"

                      />
                      <Typography className="ml-2">
                        {t("STALL.DETAIL.YEAR")}
                      </Typography>
                    </Style.BoxInputAge>
                  </div>
                </Grid>
                <Grid item lg={4} sm={6}>
                  <div className="mt-2">
                    <div
                      className="pb-2"
                      style={{ textAlign: "left" }}
                    >
                      {t("STALL.DETAIL.TEL")}
                    </div>
                    <Style.CustomPatternFormat
                      style={{ backgroundColor: colors.black_12 }}
                      disabled
                      format={"###-###-####"}
                      mask={"_"}
                      allowEmptyFormatting={true}
                      value={mobilePhone}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} sm={6}>
                  <div className="mt-2">
                    <InputTextField
                      disabled
                      value={email}
                      // onchange={(e: any) => setEmail(e.target.value)}
                      size="medium"
                      required
                      heading={t("STALL.DETAIL.EMAIL")}
                    />
                  </div>
                </Grid>
                <Grid item lg={4} sm={6}>
                  <div className="mt-2">
                    <Col>
                      <Style.Heading>{t("STALL.DETAIL.TAXPAYER")}</Style.Heading>
                      <Style.CustomPatternFormat
                        format={"#-####-#####-#-##"}
                        mask={"_"}
                        allowEmptyFormatting={true}
                        value={taxId}
                        onChange={(e: any) => {
                          setTaxId(e.target.value)
                          clearErrorMessage()
                        }}
                      />
                    </Col>
                    {errorMessage.taxId && <HelperText label={errorMessage.taxId} />}
                  </div>
                </Grid>
                <Grid item lg={3} sm={6}>
                  <div className="mt-2">
                    <InputTextField
                      required
                      value={houseNumber}
                      onchange={(e: any) => {
                        setHouseNumber(e.target.value)
                        clearErrorMessage()
                      }}
                      helperText={errorMessage.houseNumber}
                      size="medium"
                      heading={t("STALL.DETAIL.HOUSE_NUMBER")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={6}>
                  <div className=" mt-2">
                    <InputTextField

                      value={moo}
                      onchange={(e: any) => setMoo(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.MOO")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={6}>
                  <div className=" mt-2">
                    <InputTextField

                      value={village}
                      onchange={(e: any) => setVillage(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.VILLAGE")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={4}>
                  <div className=" mt-2">
                    <InputTextField

                      value={soi}
                      onchange={(e: any) => setSoi(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.SOI")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={4}>
                  <div className=" mt-2">
                    <InputTextField
                      // required
                      value={road}
                      onchange={(e: any) => setRoad(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.ROAD")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={4}>
                  <div className=" mt-2">
                    <AutocompleteSelect
                      options={provinceData}
                      getOptionLabel={(option) => option.provinceNameTh || option.provinceNameEn}
                      onChange={(event, value) => {
                        clearErrorMessage()
                        if (value) {
                          setProvince(value.provinceId);
                          setDistrict(null)
                          setSubDistrict(null)
                          setZipCode("")

                        } else {
                          setProvince(null);
                          setDistrict(null)
                          setSubDistrict(null)
                          setZipCode("")

                        }
                      }}
                      helperText={errorMessage.provinceId}
                      value={provinceData.find((d) => d.provinceId === province) || province || null}
                      labelId="label-provinceId"
                      required={true}
                      heading={t("STALL.DETAIL.CITY")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={4}>
                  <div className=" mt-2">

                    <AutocompleteSelect
                      helperText={errorMessage.districtId}
                      options={districtsData}
                      getOptionLabel={(option) => option.districtNameTh || option.districtNameEn}
                      onChange={(event, value) => {
                        clearErrorMessage()
                        if (value) {
                          setDistrict(value.districtId);
                          setSubDistrict(null)
                        } else {
                          setDistrict(null);
                          setSubDistrict(null)
                          setZipCode("")

                        }
                      }}
                      value={districtsData.find((d) => d.districtId === district) || district || null}
                      labelId="label-districts"
                      required={true}
                      heading={t("STALL.DETAIL.DISTRICT")}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} sm={4}>
                  <div className=" mt-2">
                    <AutocompleteSelect
                      helperText={errorMessage.subDistrictId}
                      options={subDistrictsData}
                      getOptionLabel={(option) => option.subDistrictNameTh || option.subDistrictNameEn}
                      onChange={(event, value) => {
                        clearErrorMessage()
                        if (value !== null) {
                          setSubDistrict(value.subDistrictId);

                          const selectedSubDistrict = subDistrictsData.find((d) => d.subDistrictId === value?.subDistrictId || null);
                          const { postcode } = selectedSubDistrict || {};
                          setZipCode(postcode || "");

                        } else {
                          setSubDistrict(null);
                          setZipCode('');
                        }
                      }}
                      value={subDistrictsData.find((d) => d.subDistrictId === subDistrict) || subDistrict}
                      labelId="label-subDistricts"
                      required={true}
                      heading={t("STALL.DETAIL.SUB_DISTRICT")}
                    />

                  </div>
                </Grid>
                <Grid item lg={3} sm={4}>
                  <div className=" mt-2">
                    <InputTextField
                      disabled
                      required
                      value={zipCode}
                      onchange={(e: any) => setZipCode(e.target.value)}
                      size="medium"
                      heading={t("STALL.DETAIL.ZIP_CODE")}
                    />
                  </div>
                </Grid>
              </Grid>
            </Style.BgInformation>
          </Style.InformationRenter>
          {/* <Style.GreenButtonInformation>
            <Style.FlexRow className="d-flex align-items-center justify-content-between">
              <Style.AddImage {...getRootProps()}>
                <FontAwesomeIcon icon={faCirclePlus} className="mr-4" />
                <Typography>{t("STALL.BUTTON.UPLOPD_ID_CARD")}</Typography>
                <Style.RequestDetail>*</Style.RequestDetail>
              </Style.AddImage>

            </Style.FlexRow>
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={seeCard ? faEyeSlash : faEye} onClick={() => {
              if (fileType === "pdf" || fileType === "application/pdf") {
                window.open(idCardImg, "_blank");
              } else {
                setSeeCard(!seeCard)
              }
            }} />
          </Style.GreenButtonInformation> */}

          <input {...getInputProps()} />
          {seeCard && idCardImg !== "" ? <img src={idCardImg} alt="preview-img" style={{ width: '100%', height: 600, objectFit: 'contain' }} /> : <></>}
        </>


        {/* new fix */}
        <Row className="mt-2">
          <Row className="mt-4">
            <Col sm={12} md={4} lg={5}>
              <InputTextField
                value={searchCustomerHair}
                onkeypress={handleTypingNumber}
                onchange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 13) {
                    return;
                  }
                  setSearchCustomerHair(e.target.value.trim())
                  setErrorMessageIdCard("")
                }}
                heading="ค้นหาผู้สืบทอด"
                helperText={errorMessageIdCard}
              />
            </Col>
            <Col className="d-flex align-items-end" style={{ marginBottom: "4px" }} sm={12} md={3} lg={3}>
              <Button
                disabled={!searchCustomerHair || requestToRentStallForm.length > 2}
                onClick={() => findIdCards(searchCustomerHair)}
                sx={{ backgroundColor: colors.themeMainColor, '&:hover': { backgroundColor: colors.themeMainColor } }}
                variant="contained">{"ค้นหาจากบัตรประชาชน"}
              </Button>
            </Col>
          </Row>
          {!errorMessageIdCard && userDetail && (
            <span className="w-100 px-2 py-2 mt-4">
              <Col className="px-2" sm={12} md={12} lg={12}>
                <h6>{"รายละเอียด"}</h6>
              </Col>
              <Col className="px-2" sm={12} md={4} lg={4}>
                {`ชื่อ : ${userDetail?.firstname || "-"} นามสกุล : ${userDetail?.lastname || "-"}`}
              </Col>
              <Col className="px-2" sm={12} md={4} lg={4}>
                {`อีเมล : ${userDetail?.email}`}
              </Col>
            </span>
          )}
          {requestToRentStallForm.length < 3 && !errorMessageIdCard && userDetail && (
            <Col sm={12} md={12} lg={12}>
              <Style.WhiteButtonInformation sx={{
                '&:hover': {
                  opacity: .6,
                  cursor: "pointer"
                }
              }} onClick={() => handleAddRequestToRentStall()}>
                <Style.FlexRow className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faCirclePlus} className="mr-4" />
                  <Typography>{t("STALL.BUTTON.ADD_SUCCESSOR")}</Typography>
                </Style.FlexRow>
              </Style.WhiteButtonInformation>
            </Col>
          )}
          {requestToRentStallForm.length > 0 && (
            <Col sm={12} md={12} lg={12}>
              {requestToRentStallForm.map((list, index: number) => {
                return (
                  <>
                    <RequestToRentStall
                      index={index}
                      onClearErrorMessage={clearErrorMessage}
                      onDeleteForm={() => handleDeleteRequestToRentStallForm(index)}
                      onChange={handleChangeRequestToRentStallForm}
                      errorMessage={errorMessage}
                      provinceData={provinceData}
                      districtsData={districtsData}
                      prefixData={prefixData}
                      data={list}
                    />
                  </>
                )
              })}
            </Col>
          )}
        </Row>
      </Style.InformationContainer>

      <Style.ContainerButtonSubmit onClick={() => onSubmit()}>
        <Style.SubmitButton>{t("STALL.BUTTON.SUBMIT")}</Style.SubmitButton>
      </Style.ContainerButtonSubmit>
    </Style.MainContainer >
  );
}
