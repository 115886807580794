/** STYLE, CONSTANT */
import { useTranslation } from "react-i18next";
import HeaderCard from "../../../component/cardCustom/headerCard";
import * as Style from "../../store/report-stall/stallReport.style";
import { Divider, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { imgs } from "../../../constants/images";
import { useCallback, useRef } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import {
  dateTimeToView,
  fullDate,
} from "../../../utils/date.utils";
import moment from "moment";
import HeaderLabel from "../../../component/cardCustom/headerLabel";
import { routeName } from "../../../routes/routers-name";
export default function RequesterDetail() {
  const imgIdCardRef: any = useRef();
  const { t }: any = useTranslation();
  const params: any = useLocation();
  const state = params.state.params;
  return (
    <>
      <HeaderLabel
          text={t("REQUESTER_DETAIL.HEADER")}
          navigator={{ previousTo: routeName.requestForStall, previousTitle: t("REQUEST_STALL.TITlE"), currentTitle:  t("REQUESTER_DETAIL.HEADER")}}
          goBack={routeName.requestForStall}
          noAction
        />
      <Style.MainContainer>
        <div>
          <Row>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.DOC_DATE") +
                  " " +
                  dateTimeToView(state.createDate)}
              </Style.Detail>
            </Col>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.REQ_NO") + " " + state.bidNo}
              </Style.Detail>
            </Col>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.ZONE") + " " + state.zoneName}
              </Style.Detail>
            </Col>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.STALL") + " " + state.stallName}
              </Style.Detail>
            </Col>
          </Row>
        </div>
        <Style.InformationContainer>
          <div>
            <Style.FlexRow className="mb-2">
              <Style.Rentaller>
                {t("REQUESTER_DETAIL.TEXT.INFORMATION_RENTAL")}
              </Style.Rentaller>
              {/* <Style.CountStall>
                {t("REQUESTER_DETAIL.TEXT.NUMBER_STALL")} (
                {state?.userDetail.customerHier.length})
              </Style.CountStall> */}
            </Style.FlexRow>
          </div>
          <Divider className="mx-0" />

          <Style.BoxInfo>
            <Col lg={4} className="d-flex justify-content-center">
              <Style.PicRenter src={imgs.default_Avatar} alt="" />
            </Col>
            <Col lg={10}>
              <Row className="m-auto">
                <Col lg={3}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.NAME")}
                  </Style.HeadText>
                </Col>
                <Col lg={5}>
                  <Style.Detail>
                    {state?.userDetail.firstname} {state?.userDetail.lastname}
                  </Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={3}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.BIRTH_DAY")}
                  </Style.HeadText>
                </Col>
                <Col lg={5}>
                  <Style.Detail>
                    {fullDate(state?.userDetail.birthDay)}
                  </Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.AGE")}
                  </Style.HeadText>
                </Col>
                <Col lg={1}>
                  <Style.Detail>
                    {moment().diff(state?.userDetail.birthDay, "years")}
                  </Style.Detail>
                </Col>
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.YEAR")}
                  </Style.HeadText>
                </Col>
                <Col lg={2}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.ID_CARD")}
                  </Style.HeadText>
                </Col>
                <Col lg={5}>
                  <Style.Detail>{state?.userDetail.idCard}</Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={2}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.TEL")}
                  </Style.HeadText>
                </Col>
                <Col lg={3}>
                  <Style.Detail>{state?.userDetail.mobilePhone}</Style.Detail>
                </Col>
                <Col lg={2}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.EMAIL")}
                  </Style.HeadText>
                </Col>
                <Col lg={3}>
                  <Style.Detail>{state?.userDetail.email}</Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={3}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.TAX_ID")}
                  </Style.HeadText>
                </Col>
                <Col lg={5}>
                  <Style.Detail>{state?.userDetail.taxId}</Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.ADRESS")}
                  </Style.HeadText>
                </Col>
                <Col lg={5}>
                  <Style.Detail>{state?.userDetail.village}</Style.Detail>
                </Col>
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.TROK")}
                  </Style.HeadText>
                </Col>
                <Col lg={3}>
                  <Style.Detail>{state?.userDetail.soi}</Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.ROAD")}
                  </Style.HeadText>
                </Col>
                <Col lg={2}>
                  <Style.Detail>{state?.userDetail.road}</Style.Detail>
                </Col>
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.PROVINCE")}
                  </Style.HeadText>
                </Col>
                <Col lg={2}>
                  <Style.Detail>{state?.userDetail.province.provinceNameTh || ""}</Style.Detail>
                </Col>
                <Col lg={1}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.DISTRICT")}
                  </Style.HeadText>
                </Col>
                <Col lg={2}>
                  <Style.Detail>{state?.userDetail.district.districtNameTh || ""}</Style.Detail>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col lg={2}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.SUB_DISTRICT")}
                  </Style.HeadText>
                </Col>
                <Col lg={2}>
                  <Style.Detail>{state?.userDetail.subDistrict.subDistrictNameTh || ""}</Style.Detail>
                </Col>
                <Col lg={2}>
                  <Style.HeadText>
                    {t("REQUESTER_DETAIL.HEAD_TEXT.ZIPCODE")}
                  </Style.HeadText>
                </Col>
                <Col lg={2}>
                  <Style.Detail>{state?.userDetail.zipCode}</Style.Detail>
                </Col>
              </Row>
            </Col>
          </Style.BoxInfo>

          {/* <Style.GreenButtonInformation>
            <Style.FlexRow className="d-flex align-items-center justify-content-between">
              <Typography>{t("STALL.BUTTON.UPLOPD_ID_CARD")}</Typography>
            </Style.FlexRow>
            <FontAwesomeIcon icon={faEye || faEyeSlash} />
          </Style.GreenButtonInformation> */}

          {/* <input
            type="file"
            name="file"
            onChange={onChangeIdCardImg}
            ref={imgIdCardRef}
            style={{ display: "none" }}
          /> */}
        </Style.InformationContainer>
      </Style.MainContainer>
    </>
  );
}
