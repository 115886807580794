import { useTranslation } from "react-i18next";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import * as Styles from "../transferStall.style";
import { Box, Divider } from "@mui/material";
import { colors } from "../../../constants/colors";
import { Col, Row } from "react-bootstrap";
import InputDatePicker from "../../../component/input/inputDatePicker";
import InputTextField from "../../../component/input/inputTextField";
import { dateToView } from "../../../utils/date.utils";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";
import BranchApi from "../../../api/setting/branch/branch.api";
import { useCallback, useEffect, useState } from "react";
import { debouncedApiCall } from "../../../utils/app.utils";

type TransferDetailPopupProps = {
  onsubmit?: any,
  onclose?: any,
  onClearTransferDate?: () => void;
  onchangeTransferPlace?: any,
  onchangeTransferDate?: any,
  onchangeTel?: (e: any) => void,
  transferPlace?: string,
  transferDate?: string,
  tel?: string,
  data?: any,
  branchData?: any
}

export default function SubmitTransferPopup(props: TransferDetailPopupProps) {
  const { t } = useTranslation();


  const [branchData, setBranchData] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const getBranch = useCallback(async () => {
    let condition: any = {
      page: 1,
      pageLimit: 10
    }
    if (search) condition = { ...condition, search: search }
    await BranchApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          setBranchData(res.data)
        } else {
          setBranchData([])
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [search])

  useEffect(() => {
    getBranch();
  }, [getBranch])

  return (
    <ModalCustom
      title={t('โอนสิทธิเช่าแผงค้า')}
      size="xl"
      closeButton
      onClose={() => props.onclose}
      onSubmit={props.onsubmit}
      textBtnConfirm={t("ยืนยัน")}
      component={
        <Styles.ContainerModal>
          <Divider className="mx-0 my-2" />
          <Row>
            <Col lg={12} xs={12}>
              <Styles.DetailModal>
                {t("TRANSFER_STALL.MODAL.DETAIL")}
              </Styles.DetailModal>
            </Col>
          </Row>
          <Row>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.PETITION")} : 
              </Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>{props?.data?.transferNo}</Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.NAME_SUBNAME")} :
              </Styles.SubDetail>
            </Col>
            <Col lg={4} xs={6}>
              <Styles.SubDetail>{props?.data?.customerReceive?.firstname || "-"} {props?.data?.customerReceive?.lastname || "-"}</Styles.SubDetail>
            </Col>

          </Row>
          <Row className="mt-2">
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.APPLICATION_DATE")} :
              </Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>{dateToView(props?.data?.createDate || new Date())}</Styles.SubDetail>
            </Col>
            <Col lg={2} xs={2} className="d-xs-hidden" />
            <Col lg={2} xs={2} className="d-xs-hidden" />
          </Row>
          <Row className="mt-2">
            <Col lg={3} xs={3}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.ZONE")} :
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={3}>
              <Styles.SubDetail>{props?.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || '-'}</Styles.SubDetail>
            </Col>
            <Col lg={3} xs={3}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.STALL")} :
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={3}>
              <Styles.SubDetail>{props?.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || '-'}</Styles.SubDetail>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.ZONE_TYPE")} :
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>{props?.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName}</Styles.SubDetail>
            </Col>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.PAYMENT_CYCLE")} :
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>{props?.data?.leaseAgreement?.leasesTypeId !== 1 ? "รายเดือน" : 'รายวัน'}</Styles.SubDetail>
            </Col>
          </Row>
          <Divider className="mx-0 my-2" />
          <Row>
            <Col lg={12} xs={12}>
              <Box>
                {t("แจ้งประกาศวันที่นัดทำสัญญา")}
              </Box>
            </Col>
          </Row>
          <Row>
            <Col lg={2} xs={6} className="mt-2 d-flex justify-content-start align-items-center">
              {t("วันที่นัด")}
            </Col>
            <Col lg={4} xs={6} className="mt-2">
              <InputDatePicker
                allowClear
                onClear={props.onClearTransferDate}
                value={props.transferDate}
                key={'date'}
                onChange={props.onchangeTransferDate}
              />
            </Col>
            <Col lg={2} xs={6} className="mt-2 d-flex justify-content-start align-items-center">
              {t("สถานที่")}
            </Col>
            <Col lg={4} xs={6} className="mt-2">
              {/* <InputTextField
                      value={props.transferPlace}
                      onchange={props.onchangeTransferPlace}
                      inputTextAlign="center"
                      size="medium"
                      disabled
                    /> */}
              <AutocompleteSelect
                options={branchData || []}
                onInputChange={(_, value) => {
                  debouncedApiCall({ searchItem: value, setItem: setSearch })
                }}
                getOptionLabel={(option) => option.branchNameTh || option.branchNameEn || ""}
                onChange={
                  props.onchangeTransferPlace
                }
                value={props.transferPlace}
                labelId="label-subDistricts"
              // heading={t("STALL.DETAIL.SUB_DISTRICT")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={2} xs={6} className="mt-2 d-flex justify-content-start align-items-center">
              {t("โทร")}
            </Col>
            <Col lg={4} xs={6} className="mt-2">
              <InputTextField
                // value={props.tel}
                value={"022796215"}
                onchange={props.onchangeTel}
                inputTextAlign="center"
                size="medium"
                disabled
              />
            </Col>
          </Row>

        </Styles.ContainerModal>
      }
    />
  );
}
