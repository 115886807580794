import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../../constants/colors";
import { Col } from "react-bootstrap";

export const MainContainer = styled("div")(({ theme }) => ({
    padding: "24px 48px",
    height: '100vh',
  }));

export const TableContainer = styled(Box)(({theme}) => ({
    marginTop: 24,
    
}))

export const InformationContainer = styled(Box)(({ theme }) => ({
    marginTop: 16,
    padding: '16px 16px',
    backgroundColor: colors.white,
    border: `1px solid ${colors.bgText}`,
    borderRadius: 8,
    [theme.breakpoints.down('lg')]: {
      padding: '0px 24px 24px 24px',
      backgroundColor: colors.white,
    }
}));

export const FlexRow = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5
}))

export const Rentaller = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: colors.black,
    fontSize: 16,
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
}));

export const BoxInfo = styled(Box)(({theme}) => ({
    padding: 16,
    backgroundColor: colors.lightGrayBG,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8
}))

export const CountStall = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: colors.danger,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
}));

export const PicRenter = styled('img')(({theme}) => ({
    width: 200,
    height: 200
}))

export const HeadText = styled('p')(({theme}) => ({
    fontSize: 14,
    fontWeight: 600
}))

export const Detail = styled('p')(({theme}) => ({
    fontSize: 14,
    fontWeight: 400
}))

export const GreenButtonInformation = styled(Box)(({theme}) => ({
    marginTop: 24,
    backgroundColor: colors.themeMainColor,
    borderRadius: 4,
    width: '100%',
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.white,
    padding: '16px 16px',
    fontSize: 18,
    fontWeight: 400
  }))