import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import _ from "lodash";

/** TRANSITION */
import "./i18n.js";

/** COMPONENT */
import { routeName } from './routes/routers-name';
import Login from './pages/auth/login';
import routes from './router';
import Header from './component/header/header';
import Sidebar from './component/sidebar/sidebar';
import PageNotFound from './pages/pageNotFound/pageNotFound';
import { STORAGE_KEY } from "./utils/enum";
import { getPermissionMenus, getPermissions, getProfile, getToken, logout, getBranch } from './utils/app.utils';
/**CONSTANTS */
import { scale } from "./constants/scale";

/**STYLE */
import { styled } from '@mui/material/styles'
import SelectBranches from "./pages/auth/selectBreanches";
import { useDispatch, useSelector } from "react-redux";
import { selectBranch } from "./app/slice/user.slice";
import { encode } from "js-base64";
import { showModal } from "./app/slice/modal.slice";
import { decode } from 'js-base64';
import InvoiceApi from './api/invoid/invoid.api';
import PopupNotPayments from "./pages/payBill/popup/PopupNotPayments";
import { Box, Typography } from "@mui/material";
import { colors } from "./constants/colors";
import moment from "moment";

const ContainerComponent = styled('div')(({ theme }) => ({
  '&.no-appBar': {
    top: '0 !important',
    height: '100vh !important',
  },
  // overflow:'auto',

}))
const ContainerMenu = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '0',
  zIndex: 1200,
  // [theme.breakpoints.down("md")]: {
  //   width: "100%",
  //   maxWidth: `calc(${scale.sidebarSize})`,
  //   // maxWidth: '248px'
  // },
  [theme.breakpoints.up("md")]: {
    width: "100%",
    maxWidth: `calc(${scale.sidebarSize})`,
    // maxWidth: '248px',
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
    // maxWidth: `calc(${scale.sidebarSize})`,
    maxWidth: '248px',
  },
  [theme.breakpoints.up("xl")]: {
    width: "100%",
    // maxWidth: scale.sidebarSize,
    maxWidth: '248px'
  },
}));
const ContainerContent = styled("div")<{ appBar?: boolean }>(({ theme, appBar }) => ({
  width: "100%",
  height:'100vh',
  overflow:'auto',
  [theme.breakpoints.up("md")]: {
    maxWidth: `calc(100% - ${appBar ? scale.sidebarSize : 0})`,
  },
  "> div": {
    width: "100% !important",
    display: "block !important",
    minHeight: "unset !important",
    height:'auto',
    border: "none !important",
    boxShadow: "none important",
    marginTop: "0 !important",
  },
  "& .card-header": {
    width: "100% !important",
    height: "auto !important",
  },
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const MainRedText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: colors.red,
  textAlign: "center",
}));
const BlackText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.black,
}));
const ClickPayDebt = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.red,
  marginLeft: 4,
  ":hover": {
    textDecoration: "underline",
    cursor: "pointer"
  },
}));

export function App() {
  const pathName: string = window.location.pathname;
  const token = getToken();
  const checkedDateRejectUser = localStorage.getItem('STATUS_CHECKED_REJECT_USER') || null;
  const profile: any = token ? JSON.parse(getProfile() || "{}") : '{}';
  const branch = getBranch()
  const currPath = window.location.href;
  const [menuPermissions, setMenuPermissions] = useState([]);
  const [menuKeys, setMenuKeys] = useState<string[]>([]);
  const [loadPermission, setLoadPermission] = useState(true);
  const permissionMenus = getPermissionMenus()
  // const [profile, setProfile] = useState(true);
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

  const [roleId, setRoleId] = useState<number>(Number);
  const [checkedDateToRejectUser, setCheckedDateToRejectUser] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      whoami();
    }
  }, [token]);
  useEffect(() => {
    if (profile && token) {
      setRoleId(profile ? JSON.parse(decode(profile || ''))?.roleId : 0)
    }
  }, [profile, token])

  const whoami = () => {
    if (!currPath.includes("login")) {
      window.location.href = routeName.login;
      return <Redirect to={routeName.login} />;
    } else if (currPath.includes("register")) {
      window.location.href = routeName.register;
      return <Redirect to={routeName.register} />;
    } else if (currPath.includes("select-branch")) {
      return <Redirect to={routeName.selectBranches} />;
    }
  };


  const checkInvoice = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 10,
      userId: JSON.parse(decode(profile || ''))?.userId,
      status: "NOT_PAY"
    }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      const newData = res.data.filter((item:any) => item.invoiceDateEnd < moment(new Date().setMonth(new Date().getMonth() - 3)).format('yyyy-MM-DD'))
      if (newData.length === 1) {
        localStorage.setItem('STATUS_CHECKED_REJECT_USER', 'OVERDUE')
        setCheckedDateToRejectUser(true)
      } else if (newData.length > 1) {
        localStorage.setItem('STATUS_CHECKED_REJECT_USER', 'SUSPEND')
        setCheckedDateToRejectUser(true)
      }
      if (pathName === '/pay-bill' || newData.length < 1) {
        localStorage.setItem('STATUS_CHECKED_REJECT_USER', '')
      }
    }
  }

  useEffect(() => {
    if (pathName === '/pay-bill') {
      localStorage.removeItem('STATUS_CHECKED_REJECT_USER')
      setCheckedDateToRejectUser(false);
    }
  }, [pathName])

  useEffect(() => {
    if (roleId === 4 || token) {
      checkInvoice();
    }
  }, [checkedDateRejectUser, token])


  // const whoami = useCallback(() => {
  //   LoginApi.profile()
  //     .then(({ data }) => {
  //       setProfile(false)
  //       if (data) dispatch(editProfile({ value: data, key: 'selectProfile' }))
  //       else autoLogout()
  //     })
  //     .catch((e) => autoLogout())
  // }, [])

  // const autoLogout = () => {
  //   logout()
  //   if (!currPath.includes('login')) window.location.href = routeName.login
  //   return <Redirect to={routeName.login} />
  // }

  function PrivateRoute({ exact, path, key, keyName, component: Component }: any) {
    // if (profile) {
    // whoami()
    // }
    const menuPermissionByKey = _.find(menuPermissions, { menuFirstKey: keyName }) || _.find(menuPermissions, { menuSecondKey: keyName }) || _.find(menuPermissions, { menuThirdKey: keyName })
    const permissions = { ...getPermissions(), activeMenu: menuPermissionByKey }
    localStorage.setItem(STORAGE_KEY.PERMISSION, encode(JSON.stringify(permissions)))
    return <Route exact={exact} path={path} key={key}>{Component}</Route>
  }

  function PickRoute({ exact, path, key, component: Component }: any) {
    if (token && (!permissionMenus || !branch)) {
      return (
        <>
          <Route exact={exact} path={path} key={key} component={Component} />
          <Redirect to={path} />
        </>
      )
    } else {
      logout()
      return <Redirect to={routeName.login} />
    }
  }

  const handleLoadMenus = (menus: any) => {
    const allMenusKey: any = []
    const menuPermission: any = []
    _.map(menus, (menu, indexMenus1) => {
      allMenusKey.push(menu.menuFirstKey)
      menuPermission.push(_.omit(menu, 'menuSeconds'))
      _.map(menu.menuSeconds, (menu2, indexMenus2) => {
        allMenusKey.push(menu2.menuSecondKey)
        menuPermission.push(_.omit(menu2, 'menuThirds'))
        _.map(menu2.menuThirds, (menu3, indexMenus3) => {
          allMenusKey.push(menu3.menuThirdKey)
          menuPermission.push(menu3)
        })
      })
    })
    setMenuPermissions(menuPermission)
    setMenuKeys([...menuKeys, ...allMenusKey])
    setLoadPermission(false)
  }
  useEffect(() => {
    handleLoadMenus(permissionMenus)
  }, []);

  useEffect(() => {
    if (token && !JSON.parse(decode(profile || "") || "")?.isIdCard && JSON.parse(decode(profile || "") || "")?.roleId === 4 && window.location.pathname !== routeName.editProfile) {
      window.location.href = routeName.editProfile
    }
  }, [])

  return (
    <>
      {(checkedDateRejectUser === 'OVERDUE' && roleId === 4 && checkedDateToRejectUser) && <PopupNotPayments show={checkedDateToRejectUser} />}
      {(checkedDateRejectUser === 'SUSPEND' && roleId === 4 && checkedDateToRejectUser) && <PopupNotPayments show={checkedDateToRejectUser} suspend={true} />}
      <GoogleOAuthProvider clientId={googleClientId || '423635009992-jste802enleki812ni57i4jf9bg9337k.apps.googleusercontent.com'}>
        <Router>
          <Switch>
            {/* อยู่หลัง branch */}
            {/* || JSON.parse(atob(profile))?.roleId !== 2 */}
            {
            token && (branch || JSON.parse(atob(profile))?.roleId !== 2) && 
            (
              routes.map(({ name, path, component, appBar, textName, key }, idx) => (
                <PrivateRoute exact path={path} key={name || idx} keyName={key} component={
                  <>
                    <Header menuName={textName} />
                    <ContainerComponent className={`div-component d-flex ${appBar ? 'flex-row' : 'flex-column no-appBar'}`}>
                      {appBar && <ContainerMenu className={'container-menu'}><Sidebar /></ContainerMenu>}
                      <ContainerContent className={`container-content pb-5`} appBar={appBar}>
                        {!loadPermission && (
                          component && React.createElement(component)
                         )}

                      </ContainerContent>
                    </ContainerComponent>
                  </>
                } />
              ))
            )
              ||
              <>
                <Route key="login" path={routeName.login} component={Login} />
                <PickRoute key="select-branch" path={routeName.selectBranches} component={SelectBranches} />
              </>
            }
            <Route key="PageNotFound" component={PageNotFound} />
          </Switch>
        </Router>
      </GoogleOAuthProvider>
      <ToastContainer enableMultiContainer />
    </>
  );
}
