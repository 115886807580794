import ModalCustom from "./modalCustom";
import { styled } from "@mui/material/styles";
import { colors } from "../../constants/colors";
import { Box, Typography } from "@mui/material";
import { imgs } from "../../constants/images";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/slice/modal.slice";

const ModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const MainRedText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: colors.red,
  textAlign: "center",
}));
const BlackText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.black,
}));
const ClickPayDebt = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.red,
  marginLeft: 4,
  ":hover": {
    textDecoration: "underline",
    cursor: "pointer"
  },
}));

export default function ModalHaveDept() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <ModalCustom
        title=""
        size="xl"
        component={
          <div>
            <ModalContainer>
              <img src={imgs.AlertContractDebt} />
              <Box>
                <MainRedText>{t("HOME.ALERT.HAVE_DEBT")}</MainRedText>
                <Box className="d-flex flex-row">
                  <BlackText>{t("HOME.ALERT.PAY_FOR_SERVICE")}</BlackText>
                  <ClickPayDebt onClick={() => {
                    dispatch(closeModal())
                    history.push("/pay-bill")}}>
                    {t("HOME.ALERT.PAY_DEBT")}
                  </ClickPayDebt>
                </Box>
              </Box>
            </ModalContainer>
          </div>
        }
      />
    </>
  );
}
