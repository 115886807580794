import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {
  BoxText,
  Boxbackground,
  Boxconteiner,
  Boxdash,
  Boxfilearray,
  Boxinsite,
  Boxupload,
} from "./addRepairStyle";
import InputTextField from "../../../component/input/inputTextField";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonCustom from "../../../component/button/buttonCustom";
import HeaderCard from "../../../component/cardCustom/headerCard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Col, Row } from "react-bootstrap";
import ZoneApi from "../../../api/zone/zone.api";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import RepairTypeApi from "../../../api/setting/repair/repairType.api";
import RepairApi from "../../../api/repair/repair.api";
import { getProfile } from "../../../utils/app.utils";
import { decode } from "js-base64";
import moment from "moment";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import Loading from "../../../component/loading/loading";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HelperText from "../../../component/input/helperText";
type errorMessageType = {
  repairTypeId: string;
  zoneCheck: string;
  centralDescription: string;
  description: string;
}
const initialErrorMessage = {
  repairTypeId: '',
  zoneCheck: '',
  centralDescription: '',
  description: ''
}

export default function AddRepair() {
  const { t } = useTranslation();
  const profile: any = JSON.parse(getProfile() || "{}");
  const [file, setFile] = useState<any>([]);
  const [select, setSelectfile] = useState<any>();
  const [zone, setZone] = useState<any>([]);
  const [stall, setStall] = useState<any>([]);
  const [description, setDescription] = useState<string>()
  const [zoneId, setZoneId] = useState<any>("")
  const [stallId, setStallId] = useState<any>("")
  const [centralCheck, setCentralCheck] = useState<boolean>(false)
  const [zoneCheck, setZoneCheck] = useState<boolean>(false)
  const [centralDescription, setCentralDescription] = useState<string>()
  const [repairTypeData, setRepairTypeData] = useState<any[]>([])
  const [repairTypeId, setRepairTypeId] = useState<number>()
  const [leaseAgreementId, setLeaseAgreementId] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [errorMessage, setErrorMessage] = useState<errorMessageType>(initialErrorMessage)

  const clearErrorMessage = () => setErrorMessage(initialErrorMessage)

  const getZone = async () => {
    const condition = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT",
      skipcalculation: 0
    };
    await ZoneApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setZone(res?.data);
      }
    });
  };

  useEffect(() => {
    getZone();
  }, []);

  const loadRepairTypeData = useCallback(async () => {
    let condition: any = {
      page: 1,
      pageLimit: 10
    }

    await RepairTypeApi.findAll(condition)
      .then((res: any) => {
        if (res.status === 200) {
          setRepairTypeData(res?.data)
        } else {
          setRepairTypeData([])
        }
      })
  }, [])

  useEffect(() => {
    loadRepairTypeData()
  }, [])

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext === "jpg" ||
        detailImage.ext === "pdf"
      ) {
        setFile([...file, acceptedFiles[0]]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'image/*': [] }, onDropRejected: (reject) => console.log(reject) });
  const handlepreview = (x: any) => {
    const name = x.type.split(".");

    if (name[name?.length - 1] === "application/pdf") {

      window.open(URL.createObjectURL(x), "_blank");
    } else {
      setSelectfile(URL.createObjectURL(x));
    }
  };
  const handledelete = (x: any) => {
    setFile(file.filter((y: any) => y.path !== x.path));
    setSelectfile(null);
  };

  const validateForm = (): boolean => {
    let validateCheck: boolean = false;
    if (!repairTypeId) {
      setErrorMessage((prev) => ({ ...prev, repairTypeId: 'ระบุประเภทงาน' }))
      return validateCheck = true
    }
    if (!zoneCheck && !centralCheck) {
      notiError("กรุณาเลือก")
      return validateCheck = true
    }
    if (zoneCheck && (!zoneId || !stallId)) {
      setErrorMessage((prev) => ({ ...prev, zoneCheck: 'ระบุโซน/แผง' }))
      return validateCheck = true
    }
    if (centralCheck && !centralDescription) {
      setErrorMessage((prev) => ({ ...prev, centralDescription: 'ระบุ' }))
      return validateCheck = true
    }
    if (centralCheck && !centralDescription) {
      setErrorMessage((prev) => ({ ...prev, centralDescription: 'ระบุส่วนกลาง' }))
      return validateCheck = true
    }
    if (!description) {
      setErrorMessage((prev) => ({ ...prev, description: 'ระบุเรื่องที่แจ้ง' }))
      return validateCheck = true
    }
    return validateCheck
  }

  const createRepair = async () => {
    const validateData: boolean = validateForm();
    let condition: any = {
      branchId: JSON.parse(decode(profile))?.branchId || 1,
      repairDate: String(moment(Date.now()).format("YYYY-MM-DD")),
      repairTypeId: repairTypeId,
      title: "แจ้งซ่อม",
      description: description,
      zoneCheck: String(zoneCheck).toLocaleUpperCase(),
      leaseAgreementId: leaseAgreementId || null,
      zoneId: null,
      stallId: null,
      centralCheck: String(centralCheck).toLocaleUpperCase(),
      centralDescription: centralDescription
    }

    if (zoneId) condition = { ...condition, zoneId: Number(zoneId), }
    if (stallId) condition = { ...condition, stallId: Number(stallId.stallId), }

    if (validateData) {
      return;
    } else {
      setIsLoading(true)
      const res = await RepairApi.create(condition)
      if (res.status === 201) {
        const uploadDocument = new FormData();
        uploadDocument.append("refId", `${res.data.refId}`);
        uploadDocument.append("fileType", "repair");
        file.forEach((data: any, i: any) => {
          uploadDocument.append(`files[]`, data);
        });
        await FileuploadApi.uploadfilearray(uploadDocument).then((result) => {
          // notiSuccess("แจ้งซ่อมสำเร็จ")
          notiSuccess("แจ้งซ่อมสำเร็จ", "", "", () => {
            window.location.href = '/repair'
            setTimeout(() => {
              setIsLoading(false)
            }, 3000);

          })


        })
      }
      else {
        setIsLoading(false)
        notiError("แจ้งซ่อมไม่สำเร็จ")
      }
    }

  }

  return (
    <Box sx={{ position: "relative" }}>
      {isLoading && <Loading show />}
      <HeaderCard text={t("ADDREPAIR.HEADER.HEADTOP")} />
      <Container maxWidth="xl">
        <Boxinsite>
          <Boxbackground>
            <Boxconteiner>
              <Row>
                <Col className="my-2" md={1} xs={12} style={{whiteSpace:'nowrap'}}>
                  {t("ADDREPAIR.DETAIL.TYPE")} :
                </Col>
                <Col item md={11} xs={12} className="my-2">
                  <AutocompleteSelect
                    helperText={errorMessage.repairTypeId}
                    options={repairTypeData}
                    value={repairTypeData && repairTypeData.find((e: any) => e.repairTypeId === repairTypeId)}
                    onChange={(e: any, val: any) => {
                      clearErrorMessage();
                      setRepairTypeId(val?.repairTypeId)
                    }}
                    getOptionLabel={(option: any) => option?.repairTypeName}
                    labelId="add"
                  />
                </Col>
                <Col className="my-2" md={1} sm={12} xs={12}>
                  <label>
                    <input type="radio" name="myRadio" checked={zoneCheck} value="option1" className="mr-2" onChange={() => {
                      setZoneCheck(true)
                      setCentralCheck(false)
                      setCentralDescription("")
                    }} />
                    {t("ADDREPAIR.DETAIL.ZONE")} :
                  </label>
                  {/* <InputCheckbox disabled={centralCheck} checked={zoneCheck}  onChange={(e) =>setZoneCheck(e.target.checked)} label={t("ADDREPAIR.DETAIL.ZONE")} /> */}
                </Col>
                <Col className="my-2" md={5} xs={12}>
                  <AutocompleteSelect
                    options={zone}
                    value={zone?.find((e: any) => e.zoneId === zoneId) || null}
                    labelId="add"
                    getOptionLabel={(option: any) => option.zoneName}
                    onChange={(event, newValue) => {
                      setZoneCheck(true)
                      setZoneId(newValue?.zoneId)
                      setStall(newValue?.stall);
                      setStallId('');
                      setLeaseAgreementId(Number)
                      clearErrorMessage()
                    }}
                    disabled={centralCheck}
                  />
                </Col>
                <Col className="my-2" md={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.STALL")} :
                </Col>
                <Col className="my-2" md={5} xs={12}>
                  <AutocompleteSelect
                    options={stall}
                    getOptionLabel={(option: any) => option.stallName}
                    value={stallId || null}
                    disabled={centralCheck}
                    labelId="add"
                    onChange={(event, newValue) => {
                      setStallId(newValue)
                      setLeaseAgreementId(newValue?.leaseAgreementId)
                      clearErrorMessage()
                    }}
                  />
                </Col>
                {errorMessage?.zoneCheck && <><Col xs={1}></Col><Col xs={11}><HelperText label={errorMessage?.zoneCheck || ''} /></Col></>}
                {JSON.parse(decode(profile))?.roleId !== 4 &&
                  <><Col className="my-2" lg={1} xs={12}>
                    <label style={{whiteSpace:'nowrap'}}>
                      <input type="radio" name="myRadio" value="option1" className="mr-2" onChange={(e) => {
                        setZoneCheck(false)
                        setCentralCheck(true)
                        setStall([])
                        setStallId("")
                        setZoneId("")
                      }} />
                      {t("ADDREPAIR.DETAIL.CENTRAL")} :
                    </label>
                    {/* <InputCheckbox checked={centralCheck} disabled={zoneCheck} onChange={(e) => setCentralCheck(e.target.checked)} label={t("ADDREPAIR.DETAIL.CENTRAL")} /> */}
                  </Col>
                    <Col className="my-2" md={11} xs={12}>
                      <InputTextField disabled={zoneCheck} value={centralDescription} onchange={(e) => {
                        setCentralDescription(e.target.value)
                        clearErrorMessage()
                      }} helperText={errorMessage.centralDescription}
                      />
                    </Col></>}
                <Col className="my-2" md={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.CONTENT")} :
                </Col>
                <Col className="my-2" md={11} xs={12}>
                  <InputTextField value={description} onchange={(e) => {
                    setDescription(e.target.value)
                    clearErrorMessage()
                  }} helperText={errorMessage.description} />
                </Col>
                <Col item lg={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.ATTACH_FILE")} 
                </Col>
                <Col className="my-2" item md={11} xs={12}>
                  <Boxupload style={{ position: "relative" }}>
                    {" "}
                    <Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />

                        {select ? (
                          <>
                            {/* <IconButton sx={{ position: 'absolute', top:"10px", right:"10px" }} onClick={() => setSelectfile(null)}><HighlightOffIcon /></IconButton> */}
                            <img src={select} alt="Thumb" height="300px" style={{ cursor: 'pointer' }} />
                          </>
                        ) : (

                          <Box
                            sx={{
                              flexDirection: "column",
                              display: "flex",
                              alignItems: "center",
                              cursor: 'pointer'
                            }}
                          >
                            <AddPhotoAlternateIcon style={{ fontSize: 60 }} />
                            <BoxText>
                              <Typography
                                sx={{
                                  marginTop: "24px",
                                  fontSize: "18px",
                                  fontFamily: "kanit",
                                }}
                              >
                                แนบไฟล์รูปภาพ JPG, PNG
                              </Typography>
                            </BoxText>
                          </Box>

                        )}
                      </div>
                    </Boxdash>
                  </Boxupload>
                </Col>

                {file?.map((x: any, index: any) => (
                  <>
                    <Col md={1} xs={12}></Col>
                    <Col md={1} xs={12} className="my-2 d-flex justify-content-end align-items-center">
                      ลำดับที่ {index + 1}
                    </Col>
                    <Col item md={10} xs={12} className="my-2">
                      <Boxfilearray>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>{x.name}</Box>

                          <Box>
                            <VisibilityIcon
                              onClick={() => handlepreview(x)}
                              sx={{ cursor: "pointer", marginRight: "10px" }}
                            />
                            <DeleteIcon
                              onClick={() => handledelete(x)}
                              color="error"
                              sx={{ cursor: "pointer" }}
                            />
                          </Box>
                        </Box>
                      </Boxfilearray>
                    </Col>
                  </>
                ))}

                <Grid item lg={12} xs={12}>
                  <ButtonCustom
                    disabled={isLoading}
                    onClick={createRepair}
                    textButton={t("REGISTER.BUTTON.SUMMIT")}
                    btnStyle={{
                      fontSize: "16px",
                      width: "100%",
                      padding: "0px",
                      marginTop: "32px",
                      marginBottom: "14px",
                    }}
                  />
                </Grid>
              </Row>
            </Boxconteiner>
          </Boxbackground>
        </Boxinsite>
      </Container>
    </Box>
  );
}
