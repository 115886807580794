import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, Divider, Grid, MenuItem, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../component/input/inputTextField";
import InputDatePicker from "../../component/input/inputDatePicker";

/** API, UTIL */
import FilterSelect from "../../component/select/filterSelect";
import PrefixApi from "../../api/setting/prefix/prefix.api";

/** STYLE, CONSTANT */
import * as Style from "../stall/stall-agreement/stallAgreement.style";
import { imgs } from "../../constants/images";
import ProvincesApi from "../../api/setting/provinces/provinces.api";
import Cloudupload from "../../assets/icon/cloudupload";
import { BoxText } from "../repair/add/addRepairStyle";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router-dom";
import LeaseAgreementApi from "../../api/stall/leaseAgreement/leaseAgreement.api";
import UserApi from "../../api/auth/users.api";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import { getProfile } from "../../utils/app.utils";
import ButtonCustom from "../../component/button/buttonCustom";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileuploadApi from "../../api/uploadfile/upload.api";
import { decode } from 'js-base64';
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import { routeName } from "../../routes/routers-name";
import TranferApi from "../../api/transfer-lease-agreement/transfer-lease-agreement";
import AppointmentApi from "../../api/stall/appointment/appointment";

const initStateErrorMessage = {
  START_DATE: ``,
  END_DATE: ``,
  AMOUNT: ``,
  PAYMENT: ``
};

export default function ContractTransfer() {
  const { t }: any = useTranslation();
  const history: any = useHistory();
  const location: any = useLocation();
  const data: any = location?.state?.params
  const profile: any = JSON.parse(getProfile() || "{}");
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);

  const [prefixData, setPrefixData] = useState<any[]>([]);
  const [prefix, setPrefix] = useState<string>("นาย");
  const [prefixId, setPrefixId] = useState<number>();

  const [provinceData, getProvinceData] = useState<any[]>([]);
  const [districtsData, getDistrictsData] = useState<any[]>([]);
  const [subDistrictsData, getSubDistrictsData] = useState<any[]>([]);
  const [provinceId, setProvinceId] = useState<string>("");
  const [districtsId, setDistrictsId] = useState<string>("");
  const [subDistrictsId, setSubDistrictsId] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [birthDay, setBirthDay] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [moo, setMoo] = useState<string>("");
  const [village, setVillage] = useState<string>("");
  const [soi, setSoi] = useState<string>("");
  const [road, setRoad] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>(data?.leaseAgreement?.leaseAgreementDateEnd);
  const [recoment, setRecoment] = useState<string>(data?.leaseAgreement?.leaseAgreementApprove?.remark);
  const [select, setSelectfile] = useState<any>();
  const [file, setFile] = useState<any>([]);
  const [paymentCycleData, setPaymentCycleData] = useState<any[]>([])
  const [paymentCycle, setPaymentCycle] = useState<number>(data?.leaseAgreement?.leasesTypeId)
  const [amount, setAmount] = useState<number>(data?.leaseAgreement?.amount)
  const [appointmentData, setAppointmentData] = useState<any>({})
  const [districtsDataOld, setDistrictDataOld] = useState<any[]>([])
  const [subDistrictsDataOld, setSubDistrictsDataOld] = useState<any[]>([]);

  const getDistrictsOld = async () => {
    const condition: any = {
      provinceId: Number(data?.leaseAgreement?.provinceId),
    };
    const res = await ProvincesApi.districts(condition);
    if (res.status === 200) {
      setDistrictDataOld(res.data);
    } else {
      setDistrictDataOld([]);
    }
  };

  const getSubDistrictsOld = async () => {
    const condition: any = {
      districtId: Number(data?.leaseAgreement?.districtId),
    };
    const res = await ProvincesApi.subDistricts(condition);
    if (res.status === 200) {
      setSubDistrictsDataOld(res.data);
    } else {
      setSubDistrictsDataOld([]);
    }
  };

  useEffect(() => {
    getDistrictsOld()
    getSubDistrictsOld()
  }, []);

  const getAppointmentData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 4000,
      userId: data?.userId,
      sortBy: "appointmentId",
      sortType: "DESC"
    }
    const res = await AppointmentApi.findAll(condition)
    if (res.status === 200) {
      setAppointmentData(res.data.find((item: any) => item?.appointmentType === "TRANSFER"))
    }
  }

  const getDetailUser = async () => {
    const res = await UserApi.findById(data?.customerReceive?.userId)
    if (res.status === 200) {
      setPrefixId(res?.data?.prefixId)
      setFirstname(res?.data?.firstname)
      setLastname(res?.data?.lastname)
      setIdCard(res?.data?.idCard)
      setBirthDay(res?.data?.birthDay)
      setMobilePhone(res?.data?.mobilePhone)
      setEmail(res?.data?.email)
      setTaxId(res?.data?.taxId)
      setHouseNumber(res?.data?.houseNumber)
      setMoo(res?.data?.moo)
      setVillage(res?.data?.village)
      setSoi(res?.data?.soi)
      setRoad(res?.data?.road)
      setProvinceId(res?.data?.provinceId)
      setDistrictsId(res?.data?.districtId)
      setSubDistrictsId(res?.data?.subDistrictId)
      setZipCode(res?.data?.zipCode)
    }
  }

  useEffect(() => {
    getAppointmentData()
    getDetailUser()
  }, [data])
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf"
      ) {
        // Do something with the files
        setFile([...file, acceptedFiles[0]]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }

      // setFile(URL.createObjectURL(acceptedFiles[0]));
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handlepreview = (x: any) => {
    const name = x.type.split(".");

    if (name[name?.length - 1] === "application/pdf") {
      window.open(URL.createObjectURL(x), "_blank");
    } else {
      setSelectfile(URL.createObjectURL(x));
    }
  };
  const handledelete = (x: any) => {
    setFile(file?.filter((y: any) => y.path !== x.path));
    setSelectfile(null);
  };
  const getPrefixes = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5,
    };

    const res = await PrefixApi.findAll(condition);
    setPrefixData(res.data);
  };

  useEffect(() => {
    getPrefixes()
  }, []);

  const getProvince = async () => {
    const res = await ProvincesApi.findAll();
    getProvinceData(res.data);
  };

  useEffect(() => {
    getProvince();
  }, []);
  const getDistricts = async () => {
    getDistrictsData([]);
    const condition: any = {
      provinceId: Number(provinceId),
    };
    const res = await ProvincesApi.districts(condition);
    if (res.status === 200) {
      getDistrictsData(res.data);
    } else {
      getDistrictsData([]);
    }
  };

  const getSubDistricts = async () => {
    getSubDistrictsData([]);
    const condition: any = {
      districtId: Number(districtsId),
    };
    const res = await ProvincesApi.subDistricts(condition);
    if (res.status === 200) {
      getSubDistrictsData(res.data);
    } else {
      getSubDistrictsData([]);
    }
  };
  useEffect(() => {
    getDistricts();
  }, [provinceId]);

  useEffect(() => {
    getSubDistricts();
  }, [districtsId]);

  const loadPaymentData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5
    }
    await LeasesTypeApi.findAll(condition)
      .then((res: any) => {
        if (res.status === 200) {
          setPaymentCycleData(res?.data)
        } else {
          setPaymentCycleData([])
        }
      })
  }

  useEffect(() => {
    loadPaymentData()
  }, [])

  const onsubmitApprove = async () => {
    const condition: any = {
      transferApproveDate: data?.transferDate,
      transferId: data?.transferId,
      remark: recoment,
      employeeId: JSON.parse(decode(profile))?.empId,
      status: "APPROVE",
      leaseAgreement: {
        leaseAgreementId: data?.leaseAgreementId,
        appointmentId: appointmentData?.appointmentId,
        leaseAgreementNo: data?.leaseAgreement?.leaseAgreementNo,
        leaseAgreementDaft: "no",
        branchId: data?.branchId,
        leaseAgreementDate: moment(Date.now()).format("YYYY-MM-DD"),
        leaseAgreementRemark: recoment,
        userId: data?.customerReceive?.userId,
        prefixId: prefixId,
        firstname: firstname,
        lastname: lastname,
        idCard: idCard,
        birthDay: moment(birthDay).format("YYYY-MM-DD"),
        mobilePhone: mobilePhone,
        email: email,
        taxId: taxId || "",
        houseNumber: houseNumber,
        moo: moo || "",
        village: village || "",
        soi: soi || "",
        road: road,
        provinceId: provinceId,
        districtId: districtsId,
        subDistrictId: subDistrictsId,
        zipCode: zipCode,
        leaseAgreementTypeId: paymentCycle,
        leaseAgreementDateStart: startDate,
        leaseAgreementDateEnd: endDate,
        amount: Number(amount),
        leasesTypeId: paymentCycle,
        leaseAgreementApprove: {
          approveDate: moment(Date.now()).format("YYYY-MM-DD"),
          remark: recoment,
          employeeId: JSON.parse(decode(profile))?.empId,
          status: "ACTIVE",
          stallId: data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallId,
          transferBidderId: data?.leaseAgreement?.leaseAgreementApprove?.bidder?.bidderId
        },
      }
    }
    const res = await TranferApi.approve(condition)
    if (res.status === 201) {
      notiSuccess(t('TRANSFER_STALL.NOTI.SUCCESS'), 'go', '/transfer-stall', null)

      const formData = new FormData();

      let ref = res?.data?.refId || null;

      formData.append("refId", `${ref}`);
      formData.append("fileType", `${res?.data?.fileType}`);
      file.forEach((data: any, i: any) => {
        formData.append(`files[]`, data);
      });

      FileuploadApi.uploadfilearray(formData)
    } else {
      notiError(t('TRANSFER_STALL.NOTI.ERROR'))
    }
  }

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage);

  return (
    <>
      <Style.MainContainer>
        <Style.InformationRenter>
          <Style.BgInformation>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">ผู้โอนสิทธิ์</Typography>
              </Grid>
              <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className="w-100 d-flex justify-content-center"
              >
                <Style.PicRenter src={imgs.defaultAvatar} alt="" />
              </Grid>
              <Style.GridInput item lg={3} sm={4}>
                <div className="">
                  <FilterSelect
                    onchange={(e: any) => {
                      setPrefix(e.target.value);
                      setPrefixId(e);
                    }}
                    options={
                      prefixData &&
                      prefixData.map((e: any) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              setPrefixId(e.prefixId);
                              setPrefix(e.prefixNameTh || e.prefixNameEn);
                            }}
                          >
                            {e.prefixNameTh || e.prefixNameEn}
                          </MenuItem>
                        );
                      })
                    }
                    selectId="select-prefix"
                    value={data?.leaseAgreement?.prefixId}
                    renderValue={() => prefix}
                    labelId="label-prefixId"
                    required
                    disabled={true}
                    heading={t("AGREEMENT.INPUT.PREFIX")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={3} sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={data?.leaseAgreement?.firstname}
                    onchange={(e: any) => setFirstname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={3} sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={data?.leaseAgreement?.lastname}
                    onchange={(e: any) => setLastname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.LAST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Grid item lg={4} sm={5}>
                <div className=" mt-2">
                  <InputTextField
                    disabled={true}
                    value={data?.leaseAgreement?.idCard}
                    onchange={(e: any) => setIdCard(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.ID_CARD")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={5}>
                <div className="mt-2">
                  <InputDatePicker
                    disabled={true}
                    value={data?.leaseAgreement?.birthDay}
                    key={"date"}
                    onChange={(e: any) =>
                      setBirthDay(moment(e).format("YYYY-MM-DD"))
                    }
                    heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={2}>
                <div className="d-flex flex-column mt-2">
                  <Style.FlexRow>
                    <Style.Detail>{t("AGREEMENT.INPUT.AGE")} </Style.Detail>
                    <Style.RequestDetail>*</Style.RequestDetail>
                  </Style.FlexRow>
                  <Style.BoxInputAge>
                    <InputTextField
                      disabled={true}
                      value={data?.leaseAgreement?.birthDay && moment().diff(data?.leaseAgreement?.birthDay, "years")}
                      inputTextAlign="center"
                      size="medium"
                    />
                    <Typography className="ml-2">
                      {t("AGREEMENT.INPUT.YEAR")}
                    </Typography>
                  </Style.BoxInputAge>
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={data?.leaseAgreement?.mobilePhone}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.TEL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={data?.leaseAgreement?.email}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.EMAIL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    value={data?.leaseAgreement?.taxId}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.TAX_ID")}
                    disabled={true}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    required
                    value={data?.leaseAgreement?.houseNumber}
                    disabled={true}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ADRESS")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    value={data?.leaseAgreement?.moo}
                    disabled={true}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.MOO")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    value={data?.leaseAgreement?.village}
                    disabled={true}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.VILLAGE")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    value={data?.leaseAgreement?.soi}
                    disabled={true}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.SOI")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    value={data?.leaseAgreement?.road}
                    disabled={true}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ROAD")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  {/* <AutocompleteSelect
                    options={provinceData}
                    getOptionLabel={(option) =>
                      option.provinceNameTh || option.provinceNameEn || ""
                    }
                    onChange={(event, value) => { }}
                    value={
                      provinceData?.length > 0 && provinceData.find((d) => d?.provinceId === data?.leaseAgreement?.provinceId) 
                    }
                    labelId="label-provinceId"
                    required={true}
                    disabled={true}
                    heading={t("STALL.DETAIL.CITY")}
                  /> */}
                  <InputTextField
                    required
                    value={provinceData?.length > 0 ? provinceData.find((d) => d?.provinceId === data?.leaseAgreement?.provinceId)?.provinceNameTh : ''}
                    disabled={true}
                    size="medium"
                    heading={t("STALL.DETAIL.CITY")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  {/* <AutocompleteSelect
                    options={districtsDataOld}
                    getOptionLabel={(option) =>
                      option.districtNameTh || option.districtNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setDistrictsId(value.districtId);
                      }
                    }}
                    value={
                      districtsDataOld.length > 0 &&
                      districtsDataOld.find(
                        (d) => d?.districtId === data?.leaseAgreement?.districtsId
                      )
                    }
                    labelId="label-districts"
                    required={true}
                    disabled={true}
                    heading={t("STALL.DETAIL.DISTRICT")}
                  /> */}
                  <InputTextField
                    required
                    value={districtsDataOld.length > 0 ?
                      districtsDataOld.find(
                        (d) => d?.districtId === data?.leaseAgreement?.districtId
                      )?.districtNameTh : ''}
                    disabled={true}
                    size="medium"
                    heading={t("STALL.DETAIL.DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  {/* <AutocompleteSelect
                    options={subDistrictsDataOld}
                    getOptionLabel={(option) =>
                      option.subDistrictNameTh || option.subDistrictNameEn || ""
                    }
                    onChange={(event, value) => {
                    }}
                    value={
                      subDistrictsDataOld.length > 0 &&
                      subDistrictsDataOld.find(
                        (d) => d.subDistrictId === data?.leaseAgreement?.subDistrictsId
                      )
                    }
                    labelId="label-subDistricts"
                    disabled={true}
                    required={true}
                    heading={t("STALL.DETAIL.SUB_DISTRICT")}
                  /> */}
                  <InputTextField
                    required
                    value={
                      subDistrictsDataOld.length > 0 ?
                        subDistrictsDataOld.find(
                          (d) => d.subDistrictId === data?.leaseAgreement?.subDistrictId
                        )?.subDistrictNameTh : ''}
                    disabled={true}
                    size="medium"
                    heading={t("STALL.DETAIL.DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    disabled={true}
                    value={data?.leaseAgreement?.zipCode}
                    onchange={(e: any) => setZipCode(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ZIPCODE")}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider className="px-0 mx-0 my-4" />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">ผู้รับสิทธิ์</Typography>
              </Grid>
              <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className="w-100 d-flex justify-content-center"
              >
                <Style.PicRenter src={imgs.defaultAvatar} alt="" />
              </Grid>
              <Style.GridInput item lg={3} sm={4}>
                <div className="">
                  <FilterSelect
                    onchange={(e: any) => {
                      setPrefix(e.target.value);
                      setPrefixId(e);
                    }}
                    options={
                      prefixData &&
                      prefixData.map((e: any) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              setPrefixId(e.prefixId);
                              setPrefix(e.prefixNameTh || e.prefixNameEn);
                            }}
                          >
                            {e.prefixNameTh || e.prefixNameEn}
                          </MenuItem>
                        );
                      })
                    }
                    selectId="select-prefix"
                    value={prefix}
                    renderValue={() => prefix}
                    labelId="label-prefixId"
                    required
                    disabled={true}
                    heading={t("AGREEMENT.INPUT.PREFIX")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={3} sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={firstname}
                    onchange={(e: any) => setFirstname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={3} sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={lastname}
                    onchange={(e: any) => setLastname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.LAST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Grid item lg={4} sm={5}>
                <div className=" mt-2">
                  <InputTextField
                    disabled={true}
                    value={idCard}
                    onchange={(e: any) => setIdCard(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.ID_CARD")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={5}>
                <div className="mt-2">
                  <InputDatePicker
                    disabled={true}
                    value={birthDay}
                    key={"date"}
                    onChange={(e: any) =>
                      setBirthDay(moment(e).format("YYYY-MM-DD"))
                    }
                    heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={2}>
                <div className="d-flex flex-column mt-2">
                  <Style.FlexRow>
                    <Style.Detail>{t("AGREEMENT.INPUT.AGE")} </Style.Detail>
                    <Style.RequestDetail>*</Style.RequestDetail>
                  </Style.FlexRow>
                  <Style.BoxInputAge>
                    <InputTextField
                      disabled={true}
                      value={birthDay && moment().diff(birthDay, "years")}
                      inputTextAlign="center"
                      size="medium"
                    />
                    <Typography className="ml-2">
                      {t("AGREEMENT.INPUT.YEAR")}
                    </Typography>
                  </Style.BoxInputAge>
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={mobilePhone}
                    onchange={(e: any) => setMobilePhone(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.TEL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={email}
                    onchange={(e: any) => setEmail(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.EMAIL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    value={taxId}
                    onchange={(e: any) => setTaxId(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.TAX_ID")}
                  // disabled={true}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    required
                    value={houseNumber}
                    onchange={(e: any) => setHouseNumber(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ADRESS")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    value={moo}
                    onchange={(e: any) => setMoo(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.MOO")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    value={village}
                    onchange={(e: any) => setVillage(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.VILLAGE")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    value={soi}
                    onchange={(e: any) => setSoi(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.SOI")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    value={road}
                    onchange={(e: any) => setRoad(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ROAD")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={provinceData}
                    getOptionLabel={(option) =>
                      option.provinceNameTh || option.provinceNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setProvinceId(value.provinceId);
                      }
                    }}
                    value={
                      provinceData.find((d) => d?.provinceId === provinceId) ||
                      provinceId ||
                      null
                    }
                    labelId="label-provinceId"
                    required={true}
                    heading={t("STALL.DETAIL.CITY")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={districtsData}
                    getOptionLabel={(option) =>
                      option.districtNameTh || option.districtNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setDistrictsId(value.districtId);
                      }
                    }}
                    value={
                      (districtsData &&
                        districtsData.find(
                          (d) => d?.districtId === districtsId
                        )) ||
                      districtsId ||
                      null
                    }
                    labelId="label-districts"
                    required={true}
                    heading={t("STALL.DETAIL.DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={subDistrictsData}
                    getOptionLabel={(option) =>
                      option.subDistrictNameTh || option.subDistrictNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value !== null) {
                        setSubDistrictsId(value.subDistrictId);

                        const selectedSubDistrict = subDistrictsData.find(
                          (d) =>
                            d.subDistrictId === value?.subDistrictId || null
                        );
                        const { postcode } = selectedSubDistrict || {};
                        setZipCode(postcode || "");
                      } else {
                        setZipCode("");
                      }
                    }}
                    value={
                      (subDistrictsData &&
                        subDistrictsData.find(
                          (d) => d.subDistrictId === subDistrictsId
                        )) ||
                      subDistrictsId
                    }
                    labelId="label-subDistricts"
                    required={true}
                    heading={t("STALL.DETAIL.SUB_DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    disabled={true}
                    value={zipCode}
                    onchange={(e: any) => setZipCode(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ZIPCODE")}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider className="my-4" sx={{ marginLeft: "0px !important", marginRight: "0px !important" }} />
            <Grid container spacing={2}>
              <Grid item lg={12} sm={12} className="auto">
                <Typography variant="h6">{t("AGREEMENT.DETAIL.HEAD")}</Typography>
              </Grid>
              {/*  */}
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                //  className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.STALL_NUMBER")}: </Typography>
              </Grid>
              <Grid item lg={10} sm={9} className="auto">
                <Typography>{data ? data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stall?.stallNumber : "-"}</Typography>
              </Grid>
              {/*  */}
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                //  className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.ZONE")}: </Typography>
              </Grid>
              <Grid item lg={4} sm={3} className="auto">
                <Typography>{data ? data?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName : "-"}</Typography>
              </Grid>
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                // className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.STALL")}: </Typography>
              </Grid>
              <Grid item lg={4} sm={3} className="auto">
                <Typography>{data ? data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName : "-"}</Typography>
              </Grid>
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                //  className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.AGREEMENT_TYPE")}: </Typography>
              </Grid>
              <Grid item lg={4} sm={3} className="auto">
                <FilterSelect
                  onchange={(e: any) => {
                    setPaymentCycle(e.target.value)
                    clearErrorMessage()
                  }}
                  options={paymentCycleData && paymentCycleData.map((e: any) => {
                    return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                  })}
                  selectId="select-prefix"
                  value={paymentCycle}
                  labelId="label-prefixId"
                  required
                  helperText={errorMessage.PAYMENT}
                  disabled={true}
                />
              </Grid>
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                // className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.PRICE")}: </Typography>
              </Grid>
              <Grid item lg={4} sm={3} className="auto">
                <InputTextField
                  value={amount}
                  onchange={(e: any) => {
                    setAmount(e.target.value)
                    clearErrorMessage()
                  }}
                  size="medium"
                  required
                  helperText={errorMessage.AMOUNT}
                  disabled={true}
                />
              </Grid>
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                //  className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.STATRT_DATE")}: </Typography>
              </Grid>
              <Grid item lg={4} sm={3} className="auto">
                <InputDatePicker
                  allowClear
                  onClear={() => setStartDate("")}
                  disableEndDate={endDate}
                  value={startDate}
                  key={"start-date"}
                  onChange={(e: any) => {
                    setStartDate(moment(e).format("YYYY-MM-DD"))
                    clearErrorMessage()
                  }
                  }
                  // disablePast
                  helperText={errorMessage.AMOUNT}
                  required
                />
              </Grid>
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                //  className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.END_DATE")}: </Typography>
              </Grid>
              <Grid item lg={4} sm={3} className="auto">
                <InputDatePicker
                  disablePast
                  value={endDate}
                  key={"end-date"}
                  onChange={(e: any) => {
                    setEndDate(moment(e).format("YYYY-MM-DD"))
                    clearErrorMessage()
                  }
                  }
                  helperText={errorMessage.AMOUNT}
                  required
                  disabled={true}
                />
              </Grid>
              <Grid item lg={2} sm={3} className="auto">
                <Typography
                //  className="d-flex justify-content-end"
                >{t("AGREEMENT.DETAIL.RECCOMENT")}: </Typography>
              </Grid>
              <Grid item lg={10} sm={3} className="auto">
                <InputTextField
                  required
                  value={recoment}
                  multiline={true}
                  maxRows={4}
                  onchange={(e: any) => setRecoment(e.target.value)}
                  size="medium"
                  style={{ height: 80, outerHeight: 80 }}
                  disabled={true}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                {t("ADDREPAIR.DETAIL.ATTACH_FILE")}
              </Grid>
              <Grid className="my-2" item lg={10} xs={12}>
                <Style.Boxupload>
                  {" "}
                  {select ? (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <img src={select} alt="Thumb" height="300px" />
                    </div>
                  ) : (
                    <Style.Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />

                        <Box
                          sx={{
                            flexDirection: "column",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Cloudupload />
                          <BoxText>
                            <Typography
                              sx={{
                                marginTop: "24px",
                                fontSize: "18px",
                                fontFamily: "kanit",
                              }}
                            >
                              แนบไฟล์เอกสาร PDF,JPG, PNG
                            </Typography>
                          </BoxText>
                        </Box>
                      </div>
                    </Style.Boxdash>
                  )}
                </Style.Boxupload>
              </Grid>

              {file?.map((x: any, index: any) => (
                <>
                  <Grid item lg={2} xs={12}></Grid>
                  <Grid item lg={1} xs={12} className="my-2">
                    อันดับที่: {index + 1}
                  </Grid>
                  <Grid key={index} item lg={9} xs={12} className="my-2">
                    <Style.Array>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{x.path}</Box>

                        <Box>
                          <VisibilityIcon
                            onClick={() => handlepreview(x)}
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          <DeleteIcon
                            onClick={() => handledelete(x)}
                            color="error"
                            sx={{ cursor: "pointer" }}
                          />
                        </Box>
                      </Box>
                    </Style.Array>
                  </Grid>
                </>
              ))}
            </Grid>
          </Style.BgInformation>
        </Style.InformationRenter>
        <ButtonCustom
          onClick={() => { onsubmitApprove() }}
          textButton={t("AGREEMENT.BUTTON.SAVE")}
          btnStyle={{
            fontSize: "16px",
            width: "100%",
            padding: "0px",
            marginTop: "32px",
          }}
        />
        <ButtonCustom
          onClick={() => history.push(routeName.transferStall)}
          // type="reset"
          mode="del"
          textButton={t("AGREEMENT.BUTTON.CANCEL")}
          btnStyle={{
            fontSize: "16px",
            width: "100%",
            padding: "0px",
            marginTop: "16px",
            marginBottom: "14px",
          }}
        />
      </Style.MainContainer>
    </>
  );
}
