import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, Divider, Grid, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../../component/input/inputTextField";
import InputDatePicker from "../../../component/input/inputDatePicker";

/** STYLE, CONSTANT */
import * as Style from "./stallAgreement.style";
import { imgs } from "../../../constants/images";
import { useLocation } from "react-router-dom";
import PreviewImage from "../../../component/image/PreviewImage";


export default function MoreDetail() {
  const { t }: any = useTranslation();
  const location: any = useLocation()
  
  const [prefixId, setPrefixId] = useState<number>();

  const [provinceId, setProvinceId] = useState<string>("");
  const [districtsId, setDistrictsId] = useState<string>("");
  const [subDistrictsId, setSubDistrictsId] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [birthDay, setBirthDay] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [moo, setMoo] = useState<string>("");
  const [village, setVillage] = useState<string>("");
  const [soi, setSoi] = useState<string>("");
  const [road, setRoad] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [recoment, setRecoment] = useState<string>("");
  const [file, setFile] = useState<any>();
  const state = location.state.params.state

  useEffect(() => {
    if(state){
        setFirstname(state?.firstname)
        setLastname(state?.lastname)
        setIdCard(state?.idCard)
        setBirthDay(state?.birthDay)
        setMobilePhone(state?.mobilePhone)
        setEmail(state?.email)
        setTaxId(state?.taxId)
        setHouseNumber(state?.houseNumber)
        setMoo(state?.moo)
        setVillage(state?.village)
        setSoi(state?.soi)
        setRoad(state?.road)
        setProvinceId(state?.province?.provinceNameTh)
        setDistrictsId(state?.district?.districtNameTh)
        setSubDistrictsId(state?.subDistrict?.subDistrictNameTh)
        setZipCode(state?.zipCode)
        setPrefixId(state?.prefix?.prefixNameTh)
        setStartDate(state?.leaseAgreementDateStart)
        setEndDate(state?.leaseAgreementDateEnd)
        setRecoment(state?.leaseAgreementApprove?.remark)
        setFile(state?.leaseAgreementApprove?.file)
    }
  }, [state]);

  return (
    <>
      <Style.MainContainer>
        <Style.Topic>{t("รายละเอียดเพิ่มเติม")}</Style.Topic>
        <Divider className="mx-0" />

        <Style.InformationRenter>
          <Style.BgInformation>
            <Grid container spacing={2}>
              <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className="w-100 d-flex justify-content-center"
              >
                <Style.PicRenter src={imgs.defaultAvatar} alt="" />
              </Grid>
              <Style.GridInput item lg={3} sm={4}>
                <div className="">
                 
                  <InputTextField
                    disabled={true}
                    value={prefixId}
                    onchange={(e: any) => setPrefixId(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.PREFIX")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={3} sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={firstname}
                    onchange={(e: any) => setFirstname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={3} sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={lastname}
                    onchange={(e: any) => setLastname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.LAST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Grid item lg={4} sm={5}>
                <div className=" mt-2">
                  <InputTextField
                    disabled={true}
                    value={idCard}
                    onchange={(e: any) => setIdCard(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.ID_CARD")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={5}>
                <div className="mt-2">
                  <InputDatePicker
                    disabled={true}
                    value={birthDay}
                    key={"date"}
                    onChange={(e: any) =>
                      setBirthDay(moment(e).format("YYYY-MM-DD"))
                    }
                    heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={2}>
                <div className="d-flex flex-column mt-2">
                  <Style.FlexRow>
                    <Style.Detail>{t("AGREEMENT.INPUT.AGE")} </Style.Detail>
                    <Style.RequestDetail>*</Style.RequestDetail>
                  </Style.FlexRow>
                  <Style.BoxInputAge>
                    <InputTextField
                      disabled={true}
                      value={ birthDay && moment().diff(birthDay, "years")}
                      inputTextAlign="center"
                      
                      size="medium"
                    />
                    <Typography className="ml-2">
                      {t("AGREEMENT.INPUT.YEAR")}
                    </Typography>
                  </Style.BoxInputAge>
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={mobilePhone}
                    onchange={(e: any) => setMobilePhone(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.TEL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={email}
                    onchange={(e: any) => setEmail(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.EMAIL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    required
                    value={taxId}
                    onchange={(e: any) => setTaxId(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.TAX_ID")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    required
                    value={houseNumber}
                    onchange={(e: any) => setHouseNumber(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ADRESS")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    value={moo}
                    onchange={(e: any) => setMoo(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.MOO")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    value={village}
                    onchange={(e: any) => setVillage(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.VILLAGE")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    value={soi}
                    onchange={(e: any) => setSoi(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.SOI")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    value={road}
                    onchange={(e: any) => setRoad(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ROAD")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    value={provinceId}
                    heading={t("AGREEMENT.INPUT.PROVINCE")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                    <InputTextField
                    value={subDistrictsId}
                    heading={t("AGREEMENT.INPUT.DISTRICT")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                <InputTextField
                    value={districtsId}
                    heading={t("AGREEMENT.INPUT.SUB_DISTRICT")}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    disabled={true}
                    value={zipCode}
                    onchange={(e: any) => setZipCode(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ZIPCODE")}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={12} sm={12} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.HEAD")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.ZONE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{state?.bidder?.zoneName}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.STALL")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{state?.bidder?.stallName}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.STATRT_DATE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <InputDatePicker
                  disabled={true}
                  value={startDate}
                  key={"start-date"}
                  onChange={(e: any) =>
                    setStartDate(moment(e).format("YYYY-MM-DD"))
                  }
                />
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.END_DATE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <InputDatePicker
                  disabled={true}
                  value={endDate}
                  key={"end-date"}
                  onChange={(e: any) =>
                    setEndDate(moment(e).format("YYYY-MM-DD"))
                  }
                />
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.RECCOMENT")}</Typography>
              </Grid>
              <Grid item lg={9} sm={3} className="auto">
                <InputTextField
                  value={recoment}
                  multiline={true}
                  maxRows={4}
                  onchange={(e: any) => setRecoment(e.target.value)}
                  size="medium"
                  style={{ height: 80, outerHeight: 80 }}
                  disabled
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                {t("ADDREPAIR.DETAIL.ATTACH_FILE")}
              </Grid>
              <Grid className="my-2" item lg={9} xs={12}>
                <Style.Boxupload>
                
                  {file ? (
                    // <img src={file} alt="" height="300px" />
                    <PreviewImage imageFile={file} />
                  ) : <></>}
                </Style.Boxupload>
              </Grid>
              
            </Grid>
            
          </Style.BgInformation>
        </Style.InformationRenter>

      </Style.MainContainer>
    </>
  );
}
