import { useTranslation } from "react-i18next";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { Col, Row } from "react-bootstrap";
import * as Style from './popup.style'
import { imgs } from "../../../constants/images";
import ButtonCustom from "../../../component/button/buttonCustom";
import { colors } from "../../../constants/colors";

type popup = {
  isShow?: boolean | false
  data: any;
  setIsShow?: (show: boolean) => void
  onSubmit?: () => void
  onUpload?: () => void
}

export default function PopupResson(props: popup) {
  const { t } = useTranslation();

  const onSubmit = () => {
    if (props.onSubmit) props.onSubmit()
  }

  return (
    <ModalCustom
      title={t("อนุมัติเบื่องต้น")}
      // title={t("REQUEST_STALL.POPUP.TITLE")}
      size="xl"
      closeButton
      // onSubmit={() => onSubmit()}
      // textBtnConfirm={t('REQUEST_STALL.POPUP.SAVE')}
      component={
        <>

          <Style.ContainerUpload>
            <Style.ContainerBoxUpload>
              <Style.BoxModal>
                <Row>
                  <Style.SubDetail>{t("REQUEST_STALL.POPUP.PRELIMINARY")}</Style.SubDetail>
                </Row>

                {props.data.map((item: any) => (
                  <>
                    <Row>
                      <Col lg={3}>
                        <Style.SubDetail>

                          {item.bidderBy.firstname}  {item.bidderBy.lastname}
                        </Style.SubDetail>
                      </Col>
                      <Col lg={2}>
                        <Style.SubDetail>
                          {item.zoneName}</Style.SubDetail>
                      </Col>
                      <Col lg={2}>
                        <Style.SubDetail>
                          {t("CANCELCONTRACT.POPUP.STALL")}{"   "}
                          {"   "}
                          {item.stallName}
                        </Style.SubDetail>
                      </Col>
                      {/* <Col lg={5}>
                        <Style.SubDetail>
                          {t("CANCELCONTRACT.POPUP.BIDDERAMOUNT")}
                          {" "}
                          {item.bidderAmount}


                        </Style.SubDetail>
                      </Col> */}
                    </Row>
                  </>
                ))}
              </Style.BoxModal>

            </Style.ContainerBoxUpload>
          </Style.ContainerUpload>
          <div className="d-flex justify-content-center w-100">
            <ButtonCustom textButton={t('REQUEST_STALL.POPUP.SAVE')} style={{
              margin: '0px 10px',
              width: '200px',
            }} onClick={() => onSubmit()} />
            <ButtonCustom textButton={t('ยกเลิก')} style={{
              margin: '0px 10px',
              width: '200px',
              '&.MuiButton-contained': {
                backgroundColor: `${colors.red} !important`,
                ':hover': {
                  backgroundColor: `${colors.red} !important`,
                }
              }
            }} onClick={() => {
              props.setIsShow && props.setIsShow(false)
            }} />
          </div>
        </>
      }
    />
  )
}