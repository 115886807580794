import { useTranslation } from "react-i18next";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../../../component/input/inputTextField";
import { useEffect, useState } from "react";
import { Divider, MenuItem, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { colors } from "../../../../constants/colors";
import FilterSelect from "../../../../component/select/filterSelect";
import _, { groupBy } from "lodash";
import { IMeterNumber, IStall, IZone } from "../../../../api/zone/zone.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint } from "@fortawesome/free-solid-svg-icons";

const initStateErrorMessage = {
  HEIGHT_ZONE: "",
  STALL_NAME: "",
  WIDTH_ZONE: "",
  STALL_NUMBER: "",
  METER: [],
};

type popup = {
  data: IStall;
  zone?: IZone;
  stallType: any;
  isShow: boolean | false;
  setIsShow: (show: boolean) => void;
  onclose: any
  onprintElectricity: any
  onprintWater: any
};
let initMeters: IMeterNumber[] = [
  { meterNumber: "", meterType: "ELECTRICITY" },
  { meterNumber: "", meterType: "WATER" },
];
export default function ViewPopupStall(props: popup) {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [stallName, setStallName] = useState<string>("");
  const [stallNumber, setStallNumber] = useState<string>('');
  const [stallTypeId, setStallTypeId] = useState<number | null>(null);
  const [heightZone, setHeightZone] = useState<number | null>();
  const [widthZone, setWidthZone] = useState<number | null>();
  const [meters, setMeters] = useState<IMeterNumber[]>(initMeters);

  useEffect(() => {
    init();
    // const meters = groupBy(payload.data)
    // const { stallName, stallNumber, stallTypeId, realHeight, realWidth, meterNumber } =
    //   props?.data
    setStallName(props?.data?.stallName);
    setStallNumber(props?.data?.stallNumber)
    setStallTypeId(props?.data?.stallTypeId);
    setHeightZone(props?.data?.realHeight);
    setWidthZone(props?.data?.realWidth);
    const tempInit = [...initMeters];
    const meterWater = _.filter(
        props?.data?.meterNumber,
      (meter: IMeterNumber) => meter.meterType === "WATER"
    );
    const meterElectric = _.filter(
        props?.data?.meterNumber,
      (meter: IMeterNumber) => meter.meterType === "ELECTRICITY"
    );
    setMeters([
      ...(meterElectric.length > 0 ? meterElectric : [tempInit[0]]),
      ...(meterWater.length > 0 ? meterWater : [tempInit[1]]),
    ]);
  }, [props.data]);

  const init = () => {
    setStallName("");
    setStallNumber('')
    setStallTypeId(null);
    setHeightZone(undefined);
    setWidthZone(undefined);
    initMeters = [
      { meterNumber: "", meterType: "ELECTRICITY" },
      { meterNumber: "", meterType: "WATER" },
    ];
    setMeters(initMeters);
  };

  useEffect(() => {
    console.log(stallNumber)
  }, [stallNumber]);
  const renderMeters = (mType: "WATER" | "ELECTRICITY") => {
    return _.map(
      meters,
      (meter: IMeterNumber, i: number) =>
        meter.meterType === mType && (
          <Row className="d-flex flex-row">
            <Col className="m-auto">
              {/* <InputTextField
                key="horizontal-stall"
                type="number"
                value={meter.meterNumber}
                helperText={errorMessage.METER[i]}
                onchange={(event) => {
                  setMeters(
                    meters.map((m, index) => {
                      if (i === index) {
                        m.meterNumber = event.target.value;
                      }
                      return m;
                    })
                  );
                  clearErrorMessage();
                }}
              /> */}
              <Typography>{meter.meterNumber || "0"}</Typography>
            </Col>
            
              <Col
                className="m-auto"
                lg={1}
                xs={1}
                onClick={() => {}}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={
                    mType === "ELECTRICITY" ?
                        props?.onprintElectricity
                    : mType === "WATER" ?
                        props?.onprintWater : ""
                    
                  }
                />
              </Col>
            
          </Row>
        )
    );
  };
  return (
    <ModalCustom
      title={t("MARKET_MAP.POPUP.ADD_DETAIL.HEADER")}
      size="lg"
      closeButton
      onClose={props.onclose}
      component={
        <>
          <Row className={`mx-0 pt-2 pt-lg-0`}>
            <Col lg={6} xs={12} className="m-auto">
              {t("MARKET_MAP.POPUP.ADD_DETAIL.ZONE_NAME")}
              {props.zone?.zoneName}
            </Col>
            <Col lg={6} xs={12} className="d-flex">
              <div>
                {t("MARKET_MAP.POPUP.ADD_DETAIL.STALL_NAME")}
              </div>
              <Typography>{stallName}</Typography>
            </Col>
          </Row>

          <Row className={`mx-0 pt-2 pt-lg-4 `}>
            <Col lg={6} xs={12} className="d-flex">
              <div>
                {t("MARKET_MAP.POPUP.ADD_DETAIL.STALL_NUMBER")}
              </div>
              <Typography>{stallNumber}</Typography>
            </Col>
            <Col lg={6} xs={12} className="d-flex">
              <div>
                {t("MARKET_MAP.POPUP.ADD_DETAIL.TYPE")}
              </div>
              <Typography>{`${_.find(
                    props.stallType,
                    (p: any) => p.stallTypeId === stallTypeId
                  )?.stallTypeNameTh
                  }`}</Typography>
            </Col>
          </Row>

          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col lg={2} xs={12} className="m-auto">
              {t("MARKET_MAP.POPUP.ADD_DETAIL.STALL_SIZE")}
            </Col>
            <Col lg={10} xs={10}>
              <Row>
                <Col lg={2} xs={6} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.WIDTH")}
                </Col>
                <Col lg={2} xs={5} style={{ width: 100 }} className="m-auto">
                  <Typography>{heightZone}</Typography>
                </Col>
                <Col lg={2} xs={1} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.UNIT")}
                </Col>
                <Col lg={2} xs={6} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.HEIGHT")}
                </Col>
                <Col lg={2} xs={5} style={{ width: 100 }} className="m-auto">
                  <Typography>{widthZone}</Typography>
                </Col>
                <Col lg={2} xs={1} className="m-auto">
                  {t("MARKET_MAP.POPUP.ADD_DETAIL.UNIT")}
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider className="mx-0 mt-2" />

          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col className="m-auto" lg={3} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.ELECT_TYPE")}
            </Col>
            <Col className="m-auto" lg={3} xs={6}>
              <div>ตามมิเตอร์</div>
            </Col>
            <Col className="m-auto" lg={2} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.METER_NUMBER")}
            </Col>
            <Col className="m-auto" lg={4} xs={6}>
              {renderMeters("ELECTRICITY")}
            </Col>
          </Row>

          <Row className={`mx-0 pt-2 pt-lg-4`}>
            <Col className="m-auto" lg={3} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.WATER_TYPE")}
            </Col>
            <Col className="m-auto" lg={3} xs={6}>
              <div>ตามมิเตอร์</div>
            </Col>
            <Col className="m-auto" lg={2} xs={6}>
              {t("MARKET_MAP.POPUP.ADD_DETAIL.METER_NUMBER")}
            </Col>
            <Col className="m-auto" lg={4} xs={6}>
              {renderMeters("WATER")}
            </Col>
          </Row>
        </>
      }
    />
  );
}
