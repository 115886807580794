import { menuFirsts } from "../../constants/menu"
import Repair from "../../pages/repair/repair"
import AddRepair from "../../pages/repair/add/addRepair"
import { routeName } from "../routers-name"
const routeRepair = [
    {
      ...menuFirsts.REPAIR,
      key: menuFirsts.REPAIR.key,
      name: menuFirsts.REPAIR.name,
      textName: menuFirsts.REPAIR.textName,
      active: false,
      appBar: true,
      component: Repair
    } ,
    {
      ...menuFirsts.REPAIR,
      key: menuFirsts.REPAIR.key,
      name: menuFirsts.REPAIR.name,
      textName: menuFirsts.REPAIR.textName,
      path: routeName.repair + "/add-repair",
      active: false,
      appBar: true,
      component: AddRepair
    } 
  ]
  
  export default routeRepair