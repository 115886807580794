import { styled } from "@mui/material/styles";
import { colors } from "../../constants/colors";
import { Box, Button, Divider, Typography } from "@mui/material";

export const PageContainer = styled('div')(({ theme }) => ({
  height:'100dvh',
  flex:1,
  overflow:'hidden'
  

}));

export const BoxMainContainer = styled(Box)(({ theme }) => ({
   height: "100%",
  padding: "24px 24px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden", 
}));

export const BoxContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: colors.lightGrayBG,
  display: "flex",
  flexDirection: "column",
  padding: '20px 20px',
  width: '100%',
  overflow: "hidden"
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between',
  alignItems:'center',
  padding:'0px 20px',
  height:'60px',
  backgroundColor:colors.themeMainColor,
  color:colors.white,
  fontFamily:'kanit',
  fontSize:'20px',
  fontWeight:'bold'
}));
  
export const NumberInTable = styled(Box)(({ theme }) => ({
  backgroundColor: colors.themeMainColor,
  width: 20,
  color: colors.white,
  borderRadius: 2,
}));
export const Tab = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",

  [theme.breakpoints.down("md")]: {
    display: "none ",
  },
}));
export const Tabbutton = styled(Box)(({ theme }) => ({
  display: "none",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    width: "100%",
  },
}));

export const ListPaymentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:'row'
  ,
  [theme.breakpoints.down("xs")]: {
    display: "flex",
    width: "100%",
    flexDirection:'column'
    
  },

  [theme.breakpoints.down("md")]: {
    display: "flex",
    width: "100%",
    flexDirection:'column',
    flexWrap: 'wrap'   
  },
 
 
}));

export const ResultContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:'row'
 
}));



export const ListPaymentItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:'column',
  padding:'5px 5px',
  width:'50%',
  marginBottom:'5px',
  
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    padding:'0px 0px',
    whiteSpace:'nowrap',
    marginTop:'10px'
    
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding:'0px 0px',
    whiteSpace:'nowrap',
    marginTop:'10px'
          
  }, 
  
}));

export const ListPaymentItem1 = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:'column',
  padding:'5px 5px',
  width:'100%',
  marginBottom:'5px',
  
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    padding:'0px 0px',
    whiteSpace:'nowrap',
    marginTop:'10px'
    
  },
  
  
}));

export const BoxListPayment = styled(Box)(({ theme }) => ({
    height:'100%',
  
}));

export const BoxResult = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:'row',
  gap:'20px',
  height:'50px',
  justifyContent:'left',
  alignItems:'center'
}));

export const BoxMonth = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection:'row',
  gap:'340px',
  justifyContent:'left',
  alignItems:'center',
  color:colors.red
}));


export const Tabbuttonprint = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",

  [theme.breakpoints.down("xs")]: {},
}));
export const HeaderMessage = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "bold",
  fontFamily: "kanit",
}));

export const ButtonInTable = styled(Button)(({ theme }) => ({
  backgroundColor: colors.green,
  borderRadius: 4,
  color: colors.white,
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
}));

export const ContainerModal = styled(Box)(({ theme }) => ({
  paddingLeft: 80,
  paddingRight: 80,
  [theme.breakpoints.down("lg")]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
}));



export const MainContainer = styled("div")(({ theme }) => ({
  padding: "12px 24px",
  height: "100%",
  boxSizing:'border-box'
  
}));

export const MainContainer1 = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  background: "rgba(41, 48, 46, 0.3)",
  left: 0,
  right: 9,
  top: 0,
  bottom: 0,
  zIndex: 99999,
  [theme.breakpoints.down("md")]: {
    height: "200vh",
  },
}));

export const PopupContainer = styled(Box)(({ theme }) => ({
  height: 768,
  width: 1120,
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
  backgroundColor: colors.white,
  padding: 40,
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "90%",
  },
}));

export const BoxUploadImgContainer = styled(Box)(({theme}) => ({
    marginTop: 24,
    backgroundColor: colors.lightGrayBG,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'space-around',
    padding: 24,
    [theme.breakpoints.down('lg')]: {
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    }
}))

export const ContainerUpload = styled(Box)(({theme}) => ({
    padding: '32px 8px',
    display: 'flex',
    justifyContent:'center',
    flexDirection: 'column'
}))

export const ContainerBoxUpload = styled(Box)(({theme}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
}))


export const BoxUpload = styled(Box)(({theme}) => ({
    borderStyle: 'dashed',
    borderColor: colors.disabledGray,
    width: 360,
    height: 304,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))

export const BoxUploadImage = styled('img')(({ theme }) => ({
  width: 355,
  height: 300,
}));



export const TextAmount = styled(Typography)(({ theme }) => ({
  backgroundColor: colors.bgText,
  padding: 8,
  borderRadius: 8,
}));

export const TextBoxUpload = styled(Box)(({ theme }) => ({
  marginTop: 24,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 8,
  marginBottom: 8,
}));

export const TextBoxAlert = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  paddingLeft: 8,
  color: colors.red,
  fontSize: 20,
  fontWeight: 400,
}));

export const ContainerQR = styled(Box)(({theme}) => ({
    backgroundColor: colors.white,
    width: '50%',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    borderRadius: 4,
    [theme.breakpoints.down('md')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

export const ImageQR = styled("img")(({ theme }) => ({
  borderRadius: 4,
  width: 304,
  height: 304,
  border: `2px solid ${colors.disabledGray}`,
  [theme.breakpoints.down("md")]: {
    width: 280,
    height: 280,
  },
  [theme.breakpoints.down("sm")]: {
    width: 200,
    height: 200,
  },
}));

export const BoxTextQR = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 48,
}));

export const BoxContainer = styled("div")(({ theme }) => ({
  backgroundColor: colors.lightGrayBG,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  padding: "30px",
  marginBottom:'100px'
}));

export const BoxHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  whiteSpace:'nowrap',
  alignContent:'center',
  marginBottom:'10px',
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap'
    
  },
  
}));

export const BoxPayment = styled("div")(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  transform: "translateX(333px)",
  [theme.breakpoints.down("md")]: {
    display: 'flex',
    flexDirection: 'row',
    transform: "translateX(0px)",
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.down("xs")]: {
    display: 'flex',
    flexDirection: 'column',
    transform: "translateX(0px)",
    whiteSpace: 'nowrap',
  },
}));

export const BoxOtherPayment = styled("div")(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  flexDirection: "row",
  gap: "20px",
 }));

export const BoxContent = styled(Box)(({ theme }) => ({
  height: "50px",
  alignContent: "center",
  alignItems: "center",
}));

export const MainModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  
}));

export const MainBoxModal = styled(Box)(({ theme }) => ({
  backgroundColor: colors.lightGrayBG,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down('lg')]: {
    flexDirection: "column",
  }
  
}));

export const BoxModal = styled(Box)(({ theme }) => ({
  backgroundColor: colors.white,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: "1px",
  marginRight: "30px",
  justifyContent: "start",
  padding: "20px 20px",
  height:'700px',
  [theme.breakpoints.down('lg')]: {
    height: 'auto'
  }
}));

export const BoxItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: "20px",
}));

export const BoxImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "700px",
  backgroundColor: colors.lightGrayBG,
  [theme.breakpoints.down('lg')]: {
    width: "100%",
  }
}));

export const HeadMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  }
}));

export const SubDetail = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  display: "flex",
  alignItems: "center",
  lineHeight: "30px",
  // whiteSpace:'nowrap'
}));


export const SubDetail1 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  lineHeight: "30px",
}));

export const SubColorRed = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  color: colors.red
}));

export const SubMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
}));

export const TypographyHead = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "kanit",
  fontWeight: "bold",
}));

export const IconContainer = styled("img")(({ theme }) => ({
  top: 1500,
  right: 65,
  position: "absolute",
}));

export const ImageSlips = styled("img")(({ theme }) => ({
  width: "100%",
  height:'100%'
}));

export const ImageQrcode = styled("img")(({ theme }) => ({
  width: "50%",
  height: "50%",
}));

export const CloseIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  right: -10,
  top: -40,
}));

export const ContentHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: colors.headerBackground,
  borderRadius: 4,
  width: "620px",
  height: "30pxx",
  lineHeight: "30px",
}));

export const ContentHeader1 = styled(Typography)(({ theme }) => ({
  backgroundColor: colors.headerBackground,
  borderRadius: 4,
  width: "100%",
  height: "30pxx",
  lineHeight: "30px",
  display: 'flex',
  justifyContent: 'space-between'
}));

export const ButtonStyle = styled(Button)(({theme}) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  ':hover' : {
      opacity: 0.7
  }
}))

export const ButtonCancel = styled(Button)(({theme}) => ({
  backgroundColor: colors.danger,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  marginTop: 8,
  gap:'10px',
  ':hover' : {
      backgroundColor: colors.red
  }
}))

export const BoxDate = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap:'15px',
  transform: "translateX(245px)",
   [theme.breakpoints.down('md')]: {
    transform: "translateX(5px)",
  }
  
}));

export const BoxButton = styled(Box)(({ theme }) => ({
    transform: "translateX(379px) translateY(-5px)",
    [theme.breakpoints.down('sm')]: {
    
    transform: "translateX(0px)",
    },
    [theme.breakpoints.down('md')]: {
    
      transform: "translateX(20px)",
    }
}));

export const ButtonInTableStyle = styled(Button)(({theme}) => ({
  backgroundColor: "#F0B760",
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  // ':hover' : {
  //     backgroundColor: colors.green
  // }
}))

export const ButtonInTableOverdue = styled(Button)(({theme}) => ({
  backgroundColor: colors.red,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  // ':hover' : {
  //     backgroundColor: colors.green
  // }
}))

export const ButtonInTableVerify = styled(Button)(({theme}) => ({
  backgroundColor: "#F0B760",
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  whiteSpace:'nowrap',
  textDecoration:'none'
  // ':hover' : {
  //     backgroundColor: colors.green
  // }
}))

export const ButtonInTableComplete= styled(Button)(({theme}) => ({
  backgroundColor: colors.themeMainColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  gap:'10px',
  // ':hover' : {
  //     backgroundColor: colors.green
  // }
}))

export const ButtonContainer = styled(Box)(({theme}) => ({
  marginTop:'10px',
  display:'flex',
  justifyContent:'center',
  width:'100%'

}))

export const ButtonCreate = styled(Button)(({theme}) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  ':hover' : {
      backgroundColor: colors.blue
  }
}))

export const CheckboxContainer = styled(Box)(({theme}) => ({
  display:'flex',
  flexDirection:'row',
  fontSize:20,
  fontWeight:400,
  alignContent:'center',
  alignItems:'center'
}));



export const DividerStyle = styled(Divider)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  orientation:'vertical',
  [theme.breakpoints.down("md")]: {
    display: 'flex',
    flexDirection: 'column',   
   
  },
  [theme.breakpoints.down("xs")]: {
    display: 'flex',
    flexDirection: 'column',
    
  },
}));

export const ButtonSubmit = styled('button')(({theme}) => ({
  color: colors.white,
  width: '100%',
  padding: '16px 0',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colors.themeMainColor,
  fontSize: 18,
  borderRadius: 8,
  marginTop: 16,
  border: 'none',
  ':hover': {
      opacity: 0.9
  }
}))

export const ThaiQR = styled(Box)(({theme}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGray}`,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
}))

export const TextQR = styled("div")(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize:10
  },
}));

export const ButtonDowload = styled('button')(({theme}) => ({
    width: '120px',
    height:'36px',
    alignSelf: 'center',
    border: `1px solid ${colors.themeMainColor}`,
    borderRadius: 4,
    color: colors.white,
    backgroundColor: colors.themeMainColor,
    textDecoration: 'none',
    padding: '4px',
    margin: '8px 0px'
}))