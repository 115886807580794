import logoMof from '../assets/logo/logoMof.svg'
import management_system from '../assets/logo/manage_system.svg'
import loginBackground from '../assets/logo/loginBackground.svg'
import homePageBackground from '../assets/logo/bg.svg'
import bgadmin from '../assets/logo/bgadmin.svg'
import qrCode from '../assets/images/qrCode.png'
import AlertSuspense from '../assets/images/alertSuspense.svg'
import notfound from '../assets/images/404.png'
import backgroundLogin from '../assets/images/backgroundLogin.svg'
import testslip from '../assets/images/testslips.jpg'
import officers from '../assets/images/officer.png'
import upload from '../assets/images/upload.png'
import OTK_Logo from '../assets/logo/OTK_Logo.png'
import BG_Login from '../assets/images/BG_Login.svg'
import waterMark from '../assets/images/waterMark.svg'
import overdue from '../assets/images/overdue.png'
import thai_qr from '../assets/images/thai_qr_payment.png'
/** DEFAULT */
import defaultAddImage from '../assets/images/add-image.svg'
import defaultAvatar from '../assets/default/defaultAvatar.svg'
import errNoImg from '../assets/images/image-not-load.png'
import defaultProfile from '../assets/default/profile.png'
import AlertContractDebt from '../assets/images/alertContractDebt.svg'
import default_Avatar from '../assets/default/default_avatar_square.svg'

/** ICONS */
import iconAddPhoto from '../assets/icon/iconAddPhoto.svg'
import iconTrash from '../assets/icon/iconTrash.svg'
import previoes_slice from '../assets/icon/previous_slice.svg'
import next_slice from '../assets/icon/next_slice.svg'
import meatball from '../assets/icon/meatball'
import dropdownEdit from '../assets/icon/dropdownEdit.svg'
import dropdownTrash from '../assets/icon/dropdownTrash.svg'
import close from '../assets/icon/close.svg'
import print from '../assets/icon/print.svg'
import add from '../assets/icon/add.svg'
import Google from '../assets/icon/google.png'
import Shuffle from '../assets/icon/shuffle.svg'
import dropdown from '../assets/icon/dropdown.svg'

import notPayAlert from '../assets/images/notpay3month.svg'
import pdfPreview from '../assets/images/file-pdf.svg'

export const logo = {
    logoMof: logoMof,
    management_system: management_system,
    OTK_Logo: OTK_Logo
}

export const background = {
    loginBackground: loginBackground,
    homePageBackground: homePageBackground,
    homePageBackgroundadmin: bgadmin,
    notfound: notfound,
    backgroundLogin: backgroundLogin,
    BG_Login: BG_Login,
    waterMark: waterMark
}

export const imgs = {
    errNoImg: errNoImg,
    defaultProfile: defaultProfile,
    defaultAddImage: defaultAddImage,
    defaultAvatar: defaultAvatar,
    qrCode: qrCode,
    AlertSuspense: AlertSuspense,
    AlertContractDebt: AlertContractDebt,
    testslip: testslip,
    officers: officers,
    upload: upload,
    default_Avatar: default_Avatar,
    overdue: overdue,
    thai_qr: thai_qr,
    notPayAlert: notPayAlert,
    pdfPreview: pdfPreview
}

export const icons = {
    iconAddPhoto: iconAddPhoto,
    iconTrash: iconTrash,
    previoes_slice: previoes_slice,
    next_slice: next_slice,
    meatball: meatball,
    dropdownEdit: dropdownEdit,
    dropdownTrash: dropdownTrash,
    close: close,
    print: print,
    add: add,
    Google: Google,
    Shuffle: Shuffle,
    dropdown: dropdown
}

export const onProfileError = ({ currentTarget }: any) => {
    currentTarget.onerror = null // prevents looping
    currentTarget.src = imgs.defaultAvatar
}

export const onImgError = ({ currentTarget }: any) => {
    currentTarget.onerror = null // prevents looping
    currentTarget.src = imgs.errNoImg
}