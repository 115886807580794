import axios from "axios";
import { getToken, logout } from "../utils/app.utils";


const token = getToken();
export const fileUrl = process.env.REACT_APP_FILE_URL;
export const apiUrl = process.env.REACT_APP_BASE_URL;
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

instance.interceptors.request.use(
  async (config) => {
    return config;
  },
  function (error) {
    console.log(error);
  }
);

if (token) {
  instance.interceptors.response.use(
    async (resp: any) => {
      return resp;
    },
    async (error) => {
      // return validate;
      console.log(error?.response?.status);
      if (error?.response?.status === 401 ) {
        logout();
        window.location.reload()
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
}
export default class BaseAPI {
  static api = instance;
}

