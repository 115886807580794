import { styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../../constants/colors";
import { PatternFormat } from "react-number-format";
import { Padding } from '@mui/icons-material';

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "32px 56px",
  height: '100vh !important',
  overflow: 'auto !important'
}));

export const CorasolBox = styled("div")(({ theme }) => ({
marginBottom: 40
}));

export const SliderContainer = styled(Box)(({ theme }) => ({
  marginTop: 64,
  width: "100%",
  borderRadius: 8,
}));

export const Topic = styled(Typography)(({ theme }) => ({
  color: colors.black,
  fontSize: 24,
  fontWeight: 400,
  textDecoration: "underline",
  textDecorationColor: colors.themeMainColor,
  textDecorationThickness: 5,
  margin: "40px 0px",
}));

export const InformationContainer = styled(Box)(({ theme }) => ({
  padding: '0px 64px 48px 64px',
  backgroundColor: colors.white,
  border: `1px solid ${colors.bgText}`,
  borderRadius: 8,
  [theme.breakpoints.down('lg')]: {
    padding: '0px 24px 24px 24px',
    backgroundColor: colors.white,
  }
}));

export const BgInformation = styled(Box)(({theme}) => ({
  backgroundColor: colors.lightGrayBG,
  borderRadius: 8,
  padding: 32
}))

export const Rentaller = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: colors.black,
    fontSize: 18,
    marginRight: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
}));

export const CountStall = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: colors.danger,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
}));

export const InformationRenter = styled(Box)(({theme}) => ({
    borderRadius: 8,
    backgroundColor: colors.white,
    marginTop:16,
    height: '100%'
}))

export const PicRenter = styled('img')(({theme}) => ({
    width: 200,
    height: 200
}))

export const SubtitleContainer = styled(Box)(({theme}) => ({
  paddingTop: 32
}))

export const FlexRow = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5
}))

export const Detail = styled(Typography)(({theme}) => ({
  color: colors.black,
  fontSize: 16
}))

export const RequestDetail = styled(Typography)(({theme}) => ({
  color: colors.danger,
  fontSize: 16
}))

export const GridInput = styled(Grid)(({theme}) => ({
  marginTop: 80,
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: 16,
  }
}))

export const GreenButtonInformation = styled(Box)(({theme}) => ({
  marginTop: 24,
  backgroundColor: colors.themeMainColor,
  borderRadius: 4,
  width: '100%',
  display: "flex",
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.white,
  padding: '16px 16px',
  fontSize: 18,
  fontWeight: 400
}))

export const WhiteButtonInformation = styled(Box)(({theme}) => ({
  marginTop: 24,
  backgroundColor: colors.white,
  border: `1px solid ${colors.themeMainColor}`,
  borderRadius: 4,
  width: '100%',
  display: "flex",
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.themeMainColor,
  padding: '16px 16px',
  fontSize: 18,
  fontWeight: 400
}))

export const ContainerButtonSubmit = styled(Box)(({theme}) => ({
  margin: '72px 0px',
  padding:'0px 64px'
}))

export const SubmitButton = styled('button')(({theme}) => ({
  width: '100%',
  backgroundColor: colors.themeMainColor,
  color: colors.white,
  borderRadius: 4,
  padding: 16,
  border: 'none',
  ':hover': {
    opacity: 0.9
  }
}))

export const BoxInputAge = styled(Box)(({theme}) => ({
  width: 120,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const NumberInTable = styled(Box)(({theme}) => ({
  backgroundColor: colors.themeMainColor,
  width:20,
  color: colors.white,
  borderRadius: 2
}))

export const AddImage = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))

export const CustomPatternFormat = styled(PatternFormat)(({theme}) => ({
  width:'100%',
  backgroundColor: colors.white,
  top: "-5px !important",
  borderRadius: 8,
  height: 43,
  borderColor: `${colors.black_12}`,
  padding: "0 1rem",
  textOverflow: "ellipsis",
  ":focus": {
    outline: ` 2px solid ${colors.themeMainColor} !important`,    
  },  
  ":before": {
    backgroundColor: colors.white,
  }
}))


export const Heading = styled(Typography)(({theme}) => ({
  fontSize: 16,
  color: `${colors.textPrimary}`,
  marginBottom: 8,

}))

