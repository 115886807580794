import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

export const Header = styled(`div`)(({ theme }) => ({
  backgroundColor: colors.white,
  padding: '20px 15px',
  '& .btn-discard': {
    width: '100%',
    backgroundColor: `${colors.white} !important `,
    color: `${colors.themeMainColor} !important `,
    border: `solid 1px ${colors.themeMainColor}  !important`,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: `${colors.white} !important `,
      color: `${colors.themeMainColor} !important `,
      border: `solid 1px ${colors.themeMainColor}  !important`
    }
  },
  '& .menu-navigation': {
    fontSize: '0.875rem'
  }
}))

interface NavigatorInterface {
  previousTo: string
  previousTitle: string
  currentTitle: string
}

type HeaderProps = {
  text?: string
  menuNavigation?: any
  inputStatus?: any
  goBack?: string
  disabledSend?: boolean
  disabledDelete?: boolean
  unDisplayButton?: boolean
  unDisplayCancel?: boolean
  navigator?: NavigatorInterface
  isEditPersonnel?: boolean
  componentBtnCustom?: any
  ondelete?: (val: any) => void
  onSubmit?: (value: any) => void
  onEditPersonnel?: (value: any) => void
  noAction?: boolean
  submitText?: string
  submitTheme?: 'solid' | 'outlined'
}

const ButtonBase = styled(Button)(({ theme }) => ({
  height: 44,
  fontSize: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.875rem',
  }
}))

const ButtonSave = styled(ButtonBase)<{ typeTheme?: 'solid' | 'outlined' }>(({ theme, typeTheme }) => ({
  backgroundColor: typeTheme === 'outlined' ? `${colors.white} !important` : `${colors.themeMainColor} !important`,
  color: typeTheme === 'outlined' ? `${colors.themeMainColor} !important` : `${colors.white} !important`,
  border: typeTheme === 'outlined' ? `1px solid ${colors.themeMainColor} !important` : 'none'
}))

const ButtonClear = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${colors.themeMainColor} !important`,
  color: `${colors.themeMainColor} !important`
}))

const ButtonEdit = styled(ButtonClear)(({ theme }) => ({
  width: 'auto'
}))

const ButtonDelete = styled(ButtonBase)(({ theme }) => ({
  width: 'auto'
}))

export default function HeaderLabel(props: HeaderProps) {
  const { t } = useTranslation()

  return (
    <Header>
      <Row className={'mx-0'}>
        <Col xs={12} sm={props.isEditPersonnel || !props.noAction ? 12 : 6} md={6} lg={7}>
          <Row>
            <Col sm={12} className="mt-1 menu-navigation">
              {props.menuNavigation && <div className={` menu-navigation `}>{props.menuNavigation}</div>}
              {props.navigator && (
                <>
                  <Link to={props.navigator.previousTo} className={'ml-0 p-0 link-nav w-auto'} style={{ textDecoration: 'none' }}>
                    {props.navigator.previousTitle}
                  </Link>
                  {props.navigator.currentTitle && (
                    <span>
                      <span className="px-2">{'>'}</span> {props.navigator.currentTitle}
                    </span>
                  )}
                </>
              )}
            </Col>
            <Col sm={12} className="mt-2 mb-1" style={{ fontSize: '1.5rem', fontWeight: 500 }}>
              {props.text}
            </Col>
          </Row>
        </Col>
        {props.noAction !== true && (
          <>
            {props?.componentBtnCustom ? (
              <Col xs={12} sm={12} md={6} lg={5} className="flex-row flex-end ml-auto">
                {props?.componentBtnCustom}
              </Col>
            ) : (
              <Col xs={12} sm={12} md={6} lg={5} className="flex-row flex-end ml-auto">
                {props.isEditPersonnel && (
                  <ButtonEdit onClick={props.onEditPersonnel} type="button" className={'mr-2 mr-md-3 px-2 px-md-3'}>
                    {t('EDIT_PASSWORD')}
                  </ButtonEdit>
                )}
                {props.inputStatus}
                {props.goBack && (
                  <Link to={props.goBack} className={`mr-2 mr-md-3 p-0 w-auto`} style={{ textDecoration: 'none' }}>
                    <ButtonClear style={{ display: props.unDisplayButton ? 'none' : '' }}>{t('BUTTON.DISCARD')}</ButtonClear>
                  </Link>
                )}
                <ButtonSave onClick={props.onSubmit} disabled={props.disabledSend || false} type="submit" typeTheme={props.submitTheme || 'solid'} style={{ display: props.unDisplayButton ? 'none' : '' }}>
                  {props.submitText || t('BUTTON.SAVE')}
                </ButtonSave>
                <ButtonDelete
                  disabled={props.disabledDelete || false}
                  style={{ border: `${props.disabledDelete ? `1px solid ${colors.disabledGray}` : `1px solid ${colors.themeMainColor}`}`, color: `${props.disabledDelete ? colors.disabledGray : colors.themeMainColor}`, display: props.unDisplayCancel ? '' : 'none', minWidth: 'fit-content' }}
                  onClick={props.ondelete}>
                  {t('BUTTON.CANCEL_DOCUMENT')}
                </ButtonDelete>
              </Col>
            )}
          </>
        )}
      </Row>
    </Header>
  )
}
