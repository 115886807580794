import BaseAPI from "../api";

const path = "repair";

export interface RepairInterface {
  page?: number;
  pageLimit?: number;
}

export interface createRepairInterface {
  branchId: number,
  repairNo: string,
  repairDate: string,
  repairTypeId: number,
  workingId: number,
  receivedDate: string,
  succeedDate: string,
  title: string,
  description: string,
  zoneCheck: string,
  leaseAgreementId: number,
  zoneId: number,
  stallId: number,
  centralCheck: string,
  centralDescription: string
}

interface ApproveRepairInterface {
  repairId: number,
  approveDate: string,
  sequential: number,
  refUserId: number
}
interface UpdateRepairInterface {
  repairDate: string,
  remark: string,
  status: string
}

export default class RepairApi extends BaseAPI {
  static findAll(params: RepairInterface): Promise<any> {
    return this.api.get(path, { params: { ...params } }).then((res) => res);
  }

  static create(params: createRepairInterface): Promise<any> {
    return this.api.post(path, { ...params })
  }

  static approve(params: ApproveRepairInterface): Promise<any> {
    return this.api.post(`${path}/approve`, { ...params })
  }

  static updateStatus(id: number, status: any): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, { status })
  }

  static update(id: number, props: UpdateRepairInterface): Promise<any> {
    return this.api.patch(`${path}/${id}`, { ...props })
  }
}
