import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Checkbox, Grid } from "@mui/material";

//** COMPONENT */
import TableRowCommon from "../../component/table/TableRowCommon";
import HeaderCard from "../../component/cardCustom/headerCard";
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import FilterSelect from "../../component/select/filterSelect";

//** STYLE */
import * as Style from './payMentStyle'

//** API */
import ZoneApi from "../../api/zone/zone.api";
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import moment from "moment";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";


export default function CreatePaybill() {
  const { t } = useTranslation();
  const [zone, setZone] = useState<string>('')
  const [stall, setStall] = useState<string>('')
  const [role, setRole] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([]);
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [leasesOption, setLeasesOption] = useState<any[]>([]);
  const [searchZone, setSearchZone] = useState<number>()
  const [searchStall, setSearchStall] = useState<number>()
  const [searchLeasesType, setSearchLeasesType] = useState<number>()



  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      skipcalculation: 0
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
    } else {
      setZoneOption([])
    }
  };

  const getLeasesType = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "ACTIVE"
    };
    const res = await LeasesTypeApi.findAll(condition);
    if (res.status === 200) {
      setLeasesOption(res.data);
    } else {
      setLeasesOption([])
    }

  };

  useEffect(() => {
    getZone();
    getLeasesType();
  }, []);


  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeLeasesType = (newLeases: number) => {
    setSearchLeasesType(newLeases);
  };

  const handleChangeZone = (newZone: number) => {
    setSearchZone(newZone);
  };

  const handleChangeStall = (newStall: number) => {
    setSearchStall(newStall);
  };

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const handleRangeDate = (value: any) => {
    setSelectedDateRange(value);
    setDateStart(moment(value[0]).format("YYYY-MM-DD"));
    setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
    setPage(1)
  };

  const handleClearRangeDate = () => {
    setSelectedDateRange([]);
    setDateStart("");
    setDateEnd("");
  };


  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#" },
    { id: "date", disablePadding: false, label: t("วันที่เอกสาร") },
    { id: "billId", disablePadding: false, label: t("เลขที่ใบแจ้งหนี้") },
    { id: "name", disablePadding: false, label: t("ชื่อผู้เช่า") },
    { id: "zone", disablePadding: false, label: t("โซน") },
    { id: "stall", disablePadding: false, label: t("แผงค้า") },
    { id: "overdue", disablePadding: false, label: t("วันที่ค้างชำระ") },
    { id: "amount", disablePadding: false, label: t("จำนวนเงิน") },
    { id: "status", disablePadding: false, label: t("สถานะ") },
    { id: "action", disablePadding: false, label: t("Action") },
  ];
  const data = [
    {
      no: 1,
      date: "19/05/2566 13:22",
      requestNo: "112051266",
      name: "สมชาย เก่งกาจ",
      zone: "11",
      stall: "A1",
      Type: "อาหารแห้ง",
      status: "wait",
      action: "อนุมัติ",
    },
  ];

  const dataAdmin = [
    {
      no: 1,
      date: "19/05/2566 13:22",
      requestNo: "112051266",
      name: "สมชาย เก่งกาจ",
      zone: "11",
      stall: "A1",
      amount: "5000",

    },
  ];

  const renderDataAdmin = (objData: any) => {

    const { no, date, billId, name, zone, stall, overdue, amount, status, action } =
      objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "left", label: date },
        { option: "TEXT", align: "left", label: billId || "-" },
        { option: "TEXT", align: "left", label: name || "-" },
        { option: "TEXT", align: "left", label: zone || "-" },
        { option: "TEXT", align: "left", label: stall || "-" },
        { option: "TEXT", align: "left", label: overdue || "-" },
        { option: "TEXT", align: "left", label: amount || "-" },
        {
          option: "COMPONENT",
          align: "center",
          label: status,
          component: (
            <Box>
              <Style.ButtonInTableVerify>
                {t("PAYBILL.BUTTON.VERIFY")}
              </Style.ButtonInTableVerify>
            </Box>
          ),
        },
        {
          option: "ACTION",
          align: "center",
          label: "action",
          values: [
            {
              option: "PRINT",
              label: t(`พิมเอกสาร`),
            },
            {
              option: "DOCUMENTDETAIL",
              label: t(`ดูรายละเอียดเอกสาร`),
            },

          ],
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  return (
    <>
      <HeaderCard text={t("PAYBILL.TITLE_CREATE")} />


      <Style.MainContainer>
        <Grid container spacing={2}>
          <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
              options={leasesOption}
              getOptionLabel={(option: any) => option.leasesTypeNameTh}
              onChange={(e: any, val: any) => {
                handleChangeLeasesType(val.leasesTypeId)
                setPage(1)
              }}
              labelId="leases-name"
              placeholder={t("STATUS.TYPE_DEBT")}
              required
            />
          </Grid>

          <Grid item lg={2} md={6} xs={12}>
            <InputTextField
              placeholder={t("TRACK_BILL.HEADER.SEARCH")}
              label={t("PAYBILL.INPUT.SEARCH")}
              value={search}
              onchange={(event) => {
                setSearch(event.target.value)
                setPage(1)
              }}
            />
          </Grid>
          <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
              options={zoneOption}
              getOptionLabel={(option: any) => option.zoneName}
              onChange={(e: any, val: any) => {
                handleChangeZone(val.zoneId)
                setPage(1)
              }}
              labelId="zone-name"
              placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
              required
            />
          </Grid>

          <Grid item lg={2} md={6} xs={12}>
            <FilterSelect
              onchange={() => { }}
              renderValue={() => { }}
              label="ค้นหาแผง"
              selectId="select-rental"
              labelId="label-rental"
              value={stall}
              options={() => { }}
            />
          </Grid>
              
        
      </Grid>


      <Style.TableContainer>
        <TableCustom
          headCells={headCellsAdmin}
          customScroll
          page={1}
          pageLimit={1}
          sortType={"ASC"}
          sortBy=""
          rowCount={1}
          rowsData={dataAdmin.map((d: any) => {
            return renderDataAdmin(d);
          })}
          onSort={() => { }}
          setPageLimit={() => { }}
          setPage={() => { }}
        />
      </Style.TableContainer>
      <Style.CheckboxContainer>
        <Checkbox
          // value={}
          onChange={() => { }}
        />
        {t('ข้อมูลของท่านผ่านการตรวจสอบแล้ว')}
      </Style.CheckboxContainer>

      <Style.ButtonContainer>
        <Style.ButtonCreate>
          {t("ออกใบแจ้งหนี้")}
        </Style.ButtonCreate>
      </Style.ButtonContainer>

    </Style.MainContainer >
    
    </>
  );
}
