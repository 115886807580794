import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

/** TRANSLATION */
import { useTranslation } from "react-i18next";

import * as UseStyled from "./styles";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import CardService from "./cardService";

/** STYLE */
import { styled } from "@mui/material/styles";
import { colors } from "../../../../constants/colors";

export const SliderItem = styled("div")(({ theme }) => ({
  flex: "0 0 323px",
  width: 323,
  maxWidth: 323,
  border: `1px solid ${colors.white}`,
  background: colors.gray_06,
  borderRadius: 8,
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    flex: "0 0 277px",
    width: 277,
    maxWidth: 277,
  },
}));

export const Header = styled("div")(({ theme }) => ({
  position: "sticky",
  margin: "0 -1rem",
  padding: "0 0.5rem",
  height: 56,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  fontSize: 20,
  fontWeight: 600,
  color: colors.white,
  "span::selection": {
    background: "transparent",
  },
  [theme.breakpoints.down("sm")]: {
    height: 48,
    fontSize: 18,
  },
}));

export const Body = styled("div")(({ theme }) => ({
  overflowY: "auto",
  paddingBottom: "1rem",
  height: "calc(100% - 56px)",
  [theme.breakpoints.down("sm")]: {
    height: "calc(100% - 48px)",
  },
}));

type ServicePoints = {
  service?: any;
  hasCollapse?: boolean;
  renderService?: any;
};

function UseScroll(
  parentClass: any,
  childClass: any,
  scrollUnit = 10,
  scrollRange = scrollUnit / 4
) {
  const [hideArrow, setHideArrow] = useState<"" | "prev" | "next" | "both">("");
  const [scrollBehavior, setScrollBehavior] = useState<"unset" | "smooth">(
    "smooth"
  );
  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onPressArrow = (type: "onPrev" | "onNext" | "onLoad") => {
    const slider: any = document.querySelector(`${parentClass}`);
    const childSlider: any = document.querySelector(`${childClass}`);

    if (type === "onLoad") {
      // console.log(childSlider, "childSlider");
      handleScroll(
        slider.offsetWidth,
        slider.scrollLeft,
        childSlider.offsetWidth
      );
    } else {
      type === "onNext"
        ? (slider.scrollLeft += scrollUnit)
        : (slider.scrollLeft -= scrollUnit);

      setTimeout(() => {
        handleScroll(
          Math.round(slider.offsetWidth),
          Math.round(slider.scrollLeft),
          Math.round(childSlider.offsetWidth)
        );
      }, 500);
    }
  };

  const handleScroll = (
    width: number,
    scroll: number,
    childOffsetWidth: number
  ) => {
    const slider: any = document.querySelector(`${parentClass}`);
    if (childOffsetWidth <= width) {
      setHideArrow("both");
    } else if (scroll < scrollRange && width !== childOffsetWidth) {
      slider.scrollLeft = 0;
      setHideArrow("prev");
    } else if (width + scroll >= childOffsetWidth - scrollRange) {
      setHideArrow("next");
      slider.scrollLeft = childOffsetWidth;
    } else setHideArrow("");
  };

  const startDragging = (event: any) => {
    const slider: any = document.querySelector(`${parentClass}`);
    setMouseDown(true);
    setStartX(event.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const stopDragging = (event: any) => {
    setMouseDown(false);
    setScrollBehavior("smooth");
  };

  const handleDragging = (event: any) => {
    const slider: any = document.querySelector(`${parentClass}`);
    const childSlider: any = document.querySelector(`${childClass}`);
    event.preventDefault();
    setScrollBehavior("unset");
    if (!mouseDown) return;
    const x = event.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
    handleScroll(
      slider.offsetWidth,
      slider.scrollLeft,
      childSlider.offsetWidth
    );
  };

  return {
    hideArrow,
    scrollBehavior,
    onPressArrow,
    handleScroll,
    startDragging,
    stopDragging,
    handleDragging,
  };
}

export default function SliderService(props: ServicePoints) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    hideArrow,
    scrollBehavior,
    onPressArrow,
    startDragging,
    stopDragging,
    handleDragging,
  } = UseScroll(".service-slider-container", ".service-slider-wrap", 700);

  useEffect(() => {
    onPressArrow("onLoad");
  }, [props.hasCollapse, props.service]);

  return (
    <>
      {/* <UseStyled.AreaArrow
        className={`arrow-prev ${
          hideArrow === "prev" || hideArrow === "both" ? "d-none" : ""
        }`}
        onClick={() => onPressArrow("onPrev")}
      >
        <UseStyled.CustomIconButton>
          <ArrowBack />
        </UseStyled.CustomIconButton>
      </UseStyled.AreaArrow>
      <UseStyled.AreaArrow
        className={`arrow-next ${
          hideArrow === "next" || hideArrow === "both" ? "d-none" : ""
        }`}
        onClick={() => onPressArrow("onNext")}
      >
        <UseStyled.CustomIconButton>
          <ArrowForward />
        </UseStyled.CustomIconButton>
      </UseStyled.AreaArrow> */}
      <UseStyled.SliderContainer
        onMouseDown={(event) => startDragging(event)}
        onMouseUp={(event) => stopDragging(event)}
        onMouseLeave={(event) => stopDragging(event)}
        onMouseMove={(event) => handleDragging(event)}
        className={"service-slider-container h-100"}
        style={{ overflow: "hidden", scrollBehavior: scrollBehavior }}
      >
        <div className={"d-flex service-slider-wrap"}>
          {props.renderService}

          {/* {_.map(props.service, (service: any, index: number) => {
            return (
              <SliderItem className={'slider-area'}>
                <Header style={{ background: colors.themeSecondColor }}>
                  <span className={'text-ellipsis'}>{service?.name || '-'}</span>
                </Header>
                <Body className={'custom-scroll'}>
                  {_.map(_.orderBy(service.type, ['createdAt'], ['asc', 'desc']), (data: any, index: number) => {
                    return (
                      <CardService
                        key={index}
                        number={Number(index)}
                        onClick={() => console.log()}
                      />
                    )
                  })}
                </Body>
              </SliderItem>
            )
          })} */}
        </div>
      </UseStyled.SliderContainer>
    </>
  );
}
