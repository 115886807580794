import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'
import { Box, Button, Typography } from '@mui/material'
import { Height } from '@mui/icons-material'
import { PatternFormat } from 'react-number-format'

export const TypographyHead = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontFamily: 'kanit',
    fontWeight: 'bold'
}))
export const TypographyDetail = styled(Typography)(({ theme }) => ({

    fontSize: '14px',
    fontFamily: 'kanit',
}))
export const Tab = styled(Box)(({ theme }) => ({
    display: "flex", justifyContent: "space-between", width: '100%',

    [theme.breakpoints.down('md')]: {

        display: 'none '
    }
}))
export const Boximg = styled(Box)(({ theme }) => ({

    padding: '24px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'

}))
export const Boxcamera = styled(Box)(({ theme }) => ({

    border: '1px solid #D9D9D9', padding: '32px', borderRadius: '8px', backgroundColor: '#F3F3F3'


}))
export const Boxcheck = styled(Box)(({ theme }) => ({


    display: 'flex', marginBottom: '8px', justifyContent: 'space-between', backgroundColor: '#E8EBF0', alignItems: 'center', paddingLeft: '8px', paddingRight: '8px', borderRadius: '8px'

}))
export const Boxright = styled(Box)(({ theme }) => ({

    padding: '24px', display: 'flex', backgroundColor: '#F3F3F3'

}))
export const Tabbutton = styled(Box)(({ theme }) => ({
    display: "none", width: '100%',

    [theme.breakpoints.down('md')]: {
        display: "flex", width: '100%',
    }
}))
export const NumberInTable = styled(Box)(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    width: 20,
    color: colors.white,
    borderRadius: 2
}))
export const Boxconteiner = styled(Box)(({ theme }) => ({
    paddingLeft: '45px',
    paddingRight: '45px',

}))
export const Boxconteinerin = styled(Box)(({ theme }) => ({
    paddingLeft: '45px',
    paddingRight: '45px',
    overflow: "auto",
    // height: '780px'


}))
export const Boxsolid = styled(Box)(({ theme }) => ({
    border: '1px solid #f8f8f8', marginTop: '24px',



}))
export const CustomPatternFormat = styled(PatternFormat)(({theme}) => ({
    width:'100%',
    backgroundColor: colors.white,
    marginTop:"7px",
    borderRadius: 8,
    height: 43,
    borderColor: `${colors.black_12}`,
    padding: "0 1rem",
    textOverflow: "ellipsis",
    ":focus": {
      outline: ` 2px solid ${colors.themeMainColor} !important`,    
    },  
    ":before": {
      backgroundColor: colors.white,
    },
    ":disabled":{
        backgroundColor:"#80808038",
    }
  }))
export const Boxsigpad = styled(Box)(({ theme }) => ({
    width: "100%",
    height: " 100%"


}))
export const BoxTabSearch = styled(Box)(({ theme }) => ({
    display: "flex", marginTop: '24px', marginBottom: '24px'
}))
export const Typographymodal = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'kanit',
}))
export const ButtonInTable = styled(Button)(({ theme }) => ({
    backgroundColor: colors.green,
    borderRadius: 4,
    color: colors.white
}))

