import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'
import { Box, Button, Typography } from '@mui/material'

export const TypographyHead = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontFamily: 'kanit',
}))
export const TypographyDetail = styled(Typography)(({ theme }) => ({

    fontSize: '14px',
    fontFamily: 'kanit',
}))
export const Tab = styled(Box)(({ theme }) => ({
    display: "flex", justifyContent: "space-between", width: '100%',

    [theme.breakpoints.down('md')]: {

        display: 'none '
    }
}))
export const Boxw = styled(Box)(({ theme }) => ({
    backgroundColor: "#FAFAFA",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
}))
export const Boxgray = styled(Box)(({ theme }) => ({
    backgroundColor: "#D9D9D9",
    borderRadius: "8px",
    padding: "24px",

}))
export const Tabbutton = styled(Box)(({ theme }) => ({
    display: "none", width: '100%',

    [theme.breakpoints.down('md')]: {
        display: "flex", width: '100%',
    }
}))
export const Boxuploadimg = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "14px",
}))
export const NumberInTable = styled(Box)(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    width: 20,
    color: colors.white,
    borderRadius: 2
}))
export const BoxContainer = styled(Box)(({ theme }) => ({

    paddingLeft: '45px',
    paddingRight: '45px',
}))
export const Typographymodal = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'kanit',
}))
export const ButtonInTable = styled(Button)(({ theme }) => ({
    backgroundColor: colors.green,
    borderRadius: 4,
    color: colors.white
}))
