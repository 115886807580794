import IconProps from "./iconProps"
import { colors } from "../../constants/colors"
export default function Sue(props: IconProps) {
    return (
<svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_460_29401)">
        <path d="M28.5003 35.6253C27.8704 35.6253 27.2663 35.375 26.8209 34.9296C26.3755 34.4842 26.1253 33.8801 26.1253 33.2503V14.2503C26.1253 13.6204 26.3755 13.0163 26.8209 12.5709C27.2663 12.1255 27.8704 11.8753 28.5003 11.8753C29.1301 11.8753 29.7342 12.1255 30.1796 12.5709C30.625 13.0163 30.8753 13.6204 30.8753 14.2503V33.2503C30.8753 33.8801 30.625 34.4842 30.1796 34.9296C29.7342 35.375 29.1301 35.6253 28.5003 35.6253ZM30.3836 56.4303C35.5136 54.3688 52.2503 46.2629 52.2503 28.6024V16.3213C52.2529 13.8252 51.4678 11.3919 50.0068 9.36801C48.5458 7.34414 46.4835 5.83288 44.1135 5.04951L29.2484 0.121385C28.7631 -0.0424148 28.2374 -0.0424148 27.7521 0.121385L12.887 5.04951C10.517 5.83288 8.45465 7.34414 6.99368 9.36801C5.5327 11.3919 4.74762 13.8252 4.75025 16.3213V28.6024C4.75025 44.1871 21.3871 53.7204 26.4886 56.2711C27.1339 56.579 27.8076 56.8232 28.5003 57.0003C29.1442 56.8686 29.7747 56.6778 30.3836 56.4303ZM42.6173 9.55726C44.0388 10.0285 45.2756 10.9357 46.1522 12.1499C47.0288 13.3641 47.5005 14.8237 47.5003 16.3213V28.6024C47.5003 43.287 33.0436 50.241 28.6119 52.0223C24.1279 49.7803 9.50025 41.4654 9.50025 28.6024V16.3213C9.50006 14.8237 9.97174 13.3641 10.8483 12.1499C11.7249 10.9357 12.9618 10.0285 14.3833 9.55726L28.5003 4.87851L42.6173 9.55726ZM28.5003 40.3753C28.0305 40.3753 27.5713 40.5145 27.1808 40.7755C26.7902 41.0365 26.4858 41.4074 26.306 41.8414C26.1263 42.2754 26.0792 42.7529 26.1709 43.2136C26.2625 43.6743 26.4887 44.0975 26.8209 44.4296C27.153 44.7618 27.5762 44.988 28.0369 45.0796C28.4976 45.1713 28.9752 45.1242 29.4091 44.9445C29.8431 44.7647 30.214 44.4603 30.475 44.0697C30.736 43.6792 30.8753 43.22 30.8753 42.7503C30.8753 42.1204 30.625 41.5163 30.1796 41.0709C29.7342 40.6255 29.1301 40.3753 28.5003 40.3753Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
    </g>
    <defs>
        <clipPath id="clip0_460_29401">
            <rect width="57" height="57" fill="white" />
        </clipPath>
    </defs>
</svg>
    )}