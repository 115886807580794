import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../constants/colors";

export const PageContainer = styled("div")(({ theme }) => ({
  height:'100vh',
  width:'100%',
}));

export const MainContainer = styled("div")(({ theme }) => ({
    padding: "24px 48px",
    height: '100%',
    color: colors.white,
    fontSize: 16
  }));
  
  export const TableContainer = styled(Box)(({theme}) => ({
      marginTop: 16,
  
          
  }));
  
  export const Title = styled(Typography)(({theme}) => ({
    fontSize: 24,
    fontWeight: 400,
    color:colors.green,
     
  }))