import moment from "moment";
import _ from "lodash";
import { Box } from "@mui/material";

/** TRANSLATION */
import { useTranslation } from "react-i18next";

/** CONSTANTS */
import { colors } from "../../../../constants/colors";
/** STYLE */
import { styled } from "@mui/material/styles";
import ButtonCustom from "../../../../component/button/buttonCustom";
import { dateToView } from "../../../../utils/date.utils";
import InputTextField from "../../../../component/input/inputTextField";
import { isCreate, isDelete, isUpdate, isView } from "../../../../utils/permission.utils"

export const Card = styled("div")(({ theme }) => ({
  margin: "1rem 1rem 0",
  borderRadius: 4,
  boxShadow: `0 3px 6px 0 ${colors.black_16}`,
  backgroundColor: colors.white,
  color: colors.textPrimary,
  cursor: "pointer",
  "& p": {
    margin: 0,
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.75rem",
    marginRight: "0.75rem",
  },
}));

export const CardBody = styled("div")(({ theme }) => ({
  position: "relative",
  padding: "1.5rem 1rem 0.5rem 1.25rem",
  border: `1px solid ${colors.extraLightGray}`,
  borderRadius: 4,
  "& .number": {
    width: "30px",
    height: "25px",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#14378A",
  },
  "& .req-number": {
    width: "120px",
    height: "25px",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#14378A",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
}));

export const CardFooter = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  textAlign: "center",
  padding: "1rem",
  cursor: "pointer",
  borderRadius: "0px 0px 5px 5px",
  "> div": {
    width: "100%",
    color: "#fff",
    ".MuiButton-outlined": {
      width: "100%",
      borderRadius: 0,
      borderColor: `${colors.extraLightGray} !important`,
      fontSize: 16,
      textTransform: "capitalize",
      height: 49,
      img: {
        width: 16,
        height: 16,
      },
      ".MuiButton-startIcon": {
        marginLeft: 0,
      },
    },
    "&:first-of-type .MuiButton-outlined": {
      borderBottomLeftRadius: 4,
    },
    "&:last-of-type .MuiButton-outlined": {
      borderBottomRightRadius: 4,
    },
    ".btn-payment": {
      borderColor: "transparent !important",
      paddingLeft: 0,
    },
  },
  ".btn-icon-group": {
    display: "flex",
    justifyContent: "flex-end",
    "> div": {
      margin: "0 !important",
      "&:first-of-type .MuiButton-outlined": {
        borderRadius: "0 !important",
      },
    },
    ".btn-icon": {
      borderColor: "transparent !important",
      minWidth: 48,
      padding: 0,
      ".MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    button: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

type CardServicePointType = {
  number: number;
  req_number?: number;
  name?: string;
  bidNo?: string;
  hideFooterAction?: boolean;
  deposit?: IDeposit;
  remark?: string;
  showRemark?: boolean;
  footerType?: "PUBLIC" | "CHECK" | "APPOINTMENT";
  onClick?: (val: any) => void;
  onCancel?: (val: any) => void;
  onchangeRemark?: (val: any) => void;
};

export interface IDeposit {
  depositId: number;
  userId: number;
  branchId: number;
  bidderId: number;
  depositNo: string;
  depositDate: string;
  depositDateEnd: string;
  depositAmount: string;
  appointmentId?: any;
  status: "WAITING" | "PROGRESS" | "SUCCESS";
  createId?: any;
  createDate: string;
  modifiedId?: any;
  modifiedDate: string;
  bidder: IBidder;
  depositPay?: IDepositPay;
  appointment?: IAppointment;
  createdBy?: any;
  modifiedBy?: any;
  depositBy: IDepositBy;
  fileUpload?: any
  round?: any
  payment?: any
}

interface IDepositBy {
  firstname: string;
  lastname: string;
}

interface IBidder {
  bidderId: number;
  userId: number;
  branchId: number;
  roundId: number;
  pageId: number;
  bidderDate: string;
  bidNo: string;
  bidTypeId: number;
  bidderTypeName: string;
  zoneId: number;
  zoneName: string;
  stallId: number;
  stallName: string;
  bidderAmount: string;
  status: string;
  createId?: any;
  createDate: string;
  modifiedId?: any;
  modifiedDate: string;
  round?: any
}

interface IAppointment {
  appointmentId: number;
  customerId: number;
  bidderId: number;
  appointmentDate: string;
  branchId: number;
  appointmentType: string;
  phoneNumber: string;
  status: string;
  createId?: any;
  createDate: string;
  modifiedId?: any;
  modifiedDate: string;
}

interface IDepositPay {
  depositPayId: number;
  branchId: number;
  depositId: number;
  depositTypeId: number;
  depositPayDate: string;
  depositRef: string;
  depositPayAmount: string;
  createId?: any;
  createDate: string;
  modifiedId?: any;
  modifiedDate: string;
  depositType: IDepositType;
  fileUpload?: any
}
interface IDepositType {
  depositTypeId: number;
  depositTypeName: string;
  status: string;
  createId?: any;
  createDate: string;
  modifiedId?: any;
  modifiedDate: string;
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function CardService(props: CardServicePointType) {
  const { t } = useTranslation();

  const { deposit, footerType } = props;

  const getLabelFooter = (): string => {
    let labelFooter = "";
    switch (footerType) {
      case "PUBLIC":
        labelFooter = t("ประกาศผล");
        break;
      case "CHECK":
        labelFooter = t("ตรวจสอบ");
        break;
      case "APPOINTMENT":
        labelFooter = t("นัดหมาย");
        break;
    }
    return labelFooter;
  };

  const getLabelStatus = (deposit: IDeposit) => {
    let labelStatus = "";
    let colorStatus = "";
    switch (deposit.status) {
      case "WAITING":
        labelStatus = t("รอชำระเงิน");
        colorStatus = "#EC1D27";
        break;
      case "PROGRESS":
        labelStatus = t("กำลังตรวจสอบ");
        colorStatus = "#EF9C1F";
        break;
      case "SUCCESS":
        labelStatus = t("นัดหมาย");
        colorStatus = "#008060";
        if (!deposit.appointment) {
          labelStatus = t("รอนัดหมาย")
          colorStatus = "#EF9C1F";
        }
        break;
    }
    return { labelStatus, colorStatus };
  };

  return (
    <Card>
      <CardBody className={"d-flex flex-column"}>
        <p className={"number"}>{String(props.number).padStart(2, "0")}</p>
       {deposit?.status === "SUCCESS" && (<p className={"req-number"}>{String(deposit?.depositNo)}</p>)}
        <div className={"d-flex mt-3"}>
          {t("ชื่อ")}: {deposit?.depositBy?.firstname}{" "}
          {deposit?.depositBy?.lastname}
        </div>
        <div className={"d-flex"}>
          {t("เลขที่ประกาศ")}: {deposit?.bidder?.round?.announce?.announceNo}
        </div>
       {deposit?.status !== "SUCCESS" && <div className={"d-flex"}>
          {t("เลขคำร้อง")}: {deposit?.depositNo}
        </div>}
        <div className={"d-flex"}>
          {t("สถานะ")}:{" "}
          <span
            className="mx-1"
            style={{
              color: deposit ? getLabelStatus(deposit).colorStatus : "#000",
            }}
          >
            {new Date(deposit?.depositDateEnd || '') < new Date() && deposit?.status === 'WAITING' ? 'เกินระยะเวลา' : deposit && getLabelStatus(deposit).labelStatus}
          </span>
        </div>
        <div className={"d-flex"}>
          {t("โซน")}: {deposit?.bidder.zoneName} {t("แผงค้า")}:{" "}
          {deposit?.bidder?.stallName}
        </div>
        <div className={"d-flex"}>
          {t("วันที่ยื่นคำร้อง")}: {dateToView(deposit?.depositDate)}
        </div>
        {deposit?.status === "SUCCESS" && (
          <>
            <div className={"d-flex"}>
              {t("วันชำระเงิน")}:{" "}
              {dateToView(deposit?.depositPay?.depositPayDate)}
            </div>
            <div className={"d-flex"}>
              {t("วันที่นัด")}:{" "}
              {deposit?.appointment?.appointmentDate
                ? dateToView(deposit.appointment?.appointmentDate)
                : "-"}
            </div>
          </>
        )}
        {props.showRemark && <div className={"d-flex"}>
          {t("เหตุผลไม่อนุมัติ")}: <InputTextField value={props.remark} onchange={props.onchangeRemark}/>
        </div>}
      </CardBody>
      {props.hideFooterAction === false && (
        <CardFooter
          onClick={props.onClick}
          style={{
            backgroundColor: footerType === "PUBLIC" ? "#14378A" : "#EF9C1F",
          }}
          hidden={permissions.isDelete.disabled}
        >
         <div>{getLabelFooter()}</div>
        </CardFooter>
      )}
      {/* {new Date(deposit?.depositDateEnd || '') < new Date() && deposit?.status === 'WAITING' &&
      (
        <CardFooter
          onClick={() => window.location.href = '/deposit-pay'}
          style={{
            backgroundColor: 'red',
          }}
          hidden={permissions.isDelete.disabled}
        >
         <div>{'เกินระยะเวลา'}</div>
        </CardFooter>
      ) } */}
    </Card>
  );
}
