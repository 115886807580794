import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../../../component/input/inputTextField";

/** API, UTIL */
import FilterSelect from "../../../../component/select/filterSelect";

/** STYLE, CONSTANT */
import * as Style from ".././stallAgreement.style";
import { imgs } from "../../../../constants/images";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { dateToView } from "../../../../utils/date.utils";

export default function PopupViewDetailCustomer(props: any) {
  const { t }: any = useTranslation();
  const { data } = props
  return (
    <>
      <ModalCustom
        title={""}
        size="xl"
        closeButton
        isShowProps={props?.show}
        onClose={() => props?.onClose ? props?.onClose(false) : {}}
        component={
          <Style.MainContainer>
            <div>
              <div>
                <Grid container spacing={2}>
                  {/* <Grid
                    item
                    lg={3}
                    sm={12}
                    xs={12}
                    className="w-100 d-flex justify-content-center"
                  >
                    <Style.PicRenter src={imgs.defaultAvatar} alt="" />
                  </Grid> */}
                  <Style.GridInput item
                    // lg={3} 
                    sm={4}>
                    <div className="">
                      <InputTextField
                        disabled={true}
                        value={data?.prefix?.prefixNameTh}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.PREFIX")}
                      />
                    </div>
                  </Style.GridInput>
                  <Style.GridInput item
                    // lg={3}
                    sm={4}>
                    <div>
                      <InputTextField
                        disabled={true}
                        value={data?.firstname}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                      />
                    </div>
                  </Style.GridInput>
                  <Style.GridInput item
                    // lg={3}
                    sm={4}>
                    <div>
                      <InputTextField
                        disabled={true}
                        value={data?.lastname}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.LAST_NAME")}
                      />
                    </div>
                  </Style.GridInput>
                  <Grid item lg={4} sm={5}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.idCard}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ID_CARD")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={5}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={dateToView(data?.birthDay)}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={2}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.birthDay && moment().diff(data?.birthDay, "years") + t("AGREEMENT.INPUT.YEAR")}
                        inputTextAlign="center"
                        size="medium"
                        heading={t("AGREEMENT.INPUT.AGE")}
                      />

                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.mobilePhone}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.TEL")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.email}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.EMAIL")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        value={data?.taxId}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.TAX_ID")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.houseNumber}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ADRESS")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6}>
                    <div className=" mt-2">
                      <InputTextField
                        value={data?.moo}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.MOO")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6}>
                    <div className=" mt-2">
                      <InputTextField
                        value={data?.village}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.VILLAGE")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        value={data?.soi}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.SOI")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.road}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ROAD")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.province?.provinceNameTh}
                        size="medium"
                        heading={t("STALL.DETAIL.CITY")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.district?.districtNameTh}
                        size="medium"
                        heading={t("STALL.DETAIL.DISTRICT")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.subDistrict?.subDistrictNameTh}
                        size="medium"
                        heading={t("STALL.DETAIL.SUB_DISTRICT")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        required
                        disabled={true}
                        value={data?.zipCode}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ZIPCODE")}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={12} sm={12} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.HEAD")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.ZONE")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{data?.leaseAgreementApprove?.bidder?.zoneName}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.STALL")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{data?.leaseAgreementApprove?.bidder?.stallName}</Typography>
                  </Grid>
                  {/* <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.AGREEMENT_TYPE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
              <FilterSelect
                  onchange={(e: any)=> {
                    setPaymentCycle(e.target.value)
                  }}
                    options={paymentCycleData && paymentCycleData.map((e: any) => {
                      return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                    })}
                    selectId="select-prefix"
                    value={paymentCycle}
                    labelId="label-prefixId"
                    required
                    helperText={errorMessage.PAYMENT}
                  />
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.PRICE")}</Typography>
              </Grid> */}
                  {/* <Grid item lg={3} sm={3} className="auto">
              <InputTextField
                    value={amount}
                    onchange={(e: any) => {
                      setAmount(e.target.value)
                      
                    }}
                    size="medium"
                    required
                    helperText={errorMessage.AMOUNT}
                  />
              </Grid> */}
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.STATRT_DATE")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{dateToView(data?.leaseAgreementDateStart) || "-"}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.END_DATE")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{dateToView(data?.leaseAgreementDateEnd) || "-"}</Typography>
                  </Grid>
                  <Grid item sm={12} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.RECCOMENT")}</Typography>
                  </Grid>
                  <Grid item sm={12} className="auto">
                    <Typography>{data?.leaseAgreement?.leaseAgreementRemark || "-"}</Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Style.MainContainer>
        }
      />


    </>
  );
}
