import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Divider, Grid, MenuItem, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../component/input/inputTextField";
import InputDatePicker from "../../component/input/inputDatePicker";

/** API, UTIL */
import FilterSelect from "../../component/select/filterSelect";
import PrefixApi from "../../api/setting/prefix/prefix.api";

/** STYLE, CONSTANT */
import * as Style from "../stall/stall-agreement/stallAgreement.style";
import { imgs } from "../../constants/images";
import ProvincesApi from "../../api/setting/provinces/provinces.api";
import UserApi from "../../api/auth/users.api";
import { useDropzone } from "react-dropzone";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { getProfile, setProfile } from "../../utils/app.utils";
import ButtonCustom from "../../component/button/buttonCustom";
import { Col } from "react-bootstrap";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { decode } from 'js-base64';
import { colors } from "../../constants/colors";
import HelperText from "../../component/input/helperText";
import Loading from "../../component/loading/loading";
import LoginApi from "../../api/auth/auth.api";
import { encode } from 'js-base64';
import { swalError } from "../../component/notification/swal";


type errorMessage = {
  idCard: string;
  birthDay: string;
  taxId: string;
  provinceId: string;
  disTrictId: string;
  subDistrictId: string;
  houseNumber: string;
}
const initialErrorMessage: errorMessage = {
  idCard: ``,
  provinceId: ``,
  birthDay: ``,
  taxId: ``,
  disTrictId: ``,
  subDistrictId: ``,
  houseNumber: ``
}


export default function EditProfile() {
  const { t }: any = useTranslation();
  const profile: any = JSON.parse(getProfile() || '{}')

  const [errorMessage, setErrorMessage] = useState<errorMessage>(initialErrorMessage)
  const clearErrorMessage = () => setErrorMessage(initialErrorMessage);

  const [prefixData, setPrefixData] = useState<any[]>([]);
  const [prefix, setPrefix] = useState<string>("นาย");

  const [provinceData, getProvinceData] = useState<any[]>([]);
  const [districtsData, getDistrictsData] = useState<any[]>([]);
  const [subDistrictsData, getSubDistrictsData] = useState<any[]>([]);
  const [provinceId, setProvinceId] = useState<string>("");
  const [districtsId, setDistrictsId] = useState<string>("");
  const [subDistrictsId, setSubDistrictsId] = useState<number>(Number);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [birthDay, setBirthDay] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [moo, setMoo] = useState<string>("");
  const [village, setVillage] = useState<string>("");
  const [soi, setSoi] = useState<string>("");
  const [road, setRoad] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [file, setFile] = useState<any>('')
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPrefixes = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5,
      status: "ACTIVE"
    };

    const res = await PrefixApi.findAll(condition);
    setPrefixData(res.data);
  };

  const getUserProfile = async () => {
    const condition: any = {
      id: JSON.parse(decode(profile))?.userId
    }
    await UserApi.getDetail(condition).then((res) => {
      if (res?.status === 200) {
        setFirstname(res?.data?.firstname)
        setFile(res?.data?.pictureProfile)
        setLastname(res?.data?.lastname)
        setIdCard(res?.data?.idCard)
        setBirthDay(res?.data?.birthDay)
        setMobilePhone(res?.data?.mobilePhone)
        setEmail(res?.data?.email)
        setTaxId(res?.data?.taxId)
        setHouseNumber(res?.data?.houseNumber)
        setMoo(res?.data?.moo)
        setVillage(res?.data?.village)
        setSoi(res?.data?.soi)
        setRoad(res?.data?.road)
        setProvinceId(res?.data?.provinceId)
        setDistrictsId(res?.data?.districtId)
        setSubDistrictsId(res?.data?.subDistrictId)
        setZipCode(res?.data?.zipCode)
        setPrefix(res?.data?.prefixId)
        setFile(res?.data?.profile)
      }
    })
  }
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileName = file?.name.split(".");
      const detailImage = {
        fileId: null,
        base64: "",
        fileExtension: fileName[fileName?.length - 1],
        fileSize: file.size.toString(),
      };
      const reader: any = new FileReader();
      reader.addEventListener("load", () => {
        const base64 = reader.result.split(",");
        setFile(reader.result);
        detailImage.base64 =
          base64.length > 0 ? base64[base64?.length - 1] : reader?.result;
      });
      reader.readAsDataURL(file);

    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId === 4) {
      getUserProfile()
    }
  }, []);
  useEffect(() => {
    getPrefixes();
  }, []);

  const getProvince = async () => {
    await ProvincesApi.findAll()
      .then((res) => {
        if (res.status === 200) {
          getProvinceData(res.data);
        } else {
          getProvinceData([])
        }
      })
  }

  useEffect(() => {
    getProvince()
  }, [])
  const getDistricts = useCallback(async () => {
    const condition: any = {
      provinceId: Number(provinceId)
    };
    await ProvincesApi.districts(condition)
      .then((res) => {
        if (res.status === 200) {
          getDistrictsData(res.data);
        } else {
          getDistrictsData([])
        }
      })

  }, [provinceId]);

  const getSubDistricts = useCallback(async () => {
    const condition: any = {
      districtId: Number(districtsId)
    };

    await ProvincesApi.subDistricts(condition)
      .then((res) => {
        if (res.status === 200) {
          getSubDistrictsData(res.data);
        } else {
          getSubDistrictsData([])
        }
      })
  }, [districtsId]);

  useEffect(() => {
    getDistricts();
  }, [provinceId]);

  useEffect(() => {
    getSubDistricts();
  }, [districtsId]);


  const validation = (): boolean => {
    let validationChecked: boolean = false;
    if (!idCard.split("-").join("").replaceAll("_", "")) {
      setErrorMessage((prev) => ({ ...prev, idCard: 'ระบุเลขบัตรประจำตัวประชาชน' }));
      return validationChecked = true;
    } else if (idCard.split("-").join("").replaceAll("_", "").length !== 13) {
      setErrorMessage((prev) => ({ ...prev, idCard: 'เลขบัตรประจำตัวประชาชนต้องมี 13 หลัก' }));
      return validationChecked = true;
    } else if (!birthDay) {
      setErrorMessage((prev) => ({ ...prev, birthDay: 'ระบุวัน/เดือน/ปีเกิด' }))
      return validationChecked = true;
    } else if (taxId && taxId.split("-").join("").replaceAll("_", "").length !== 13 && taxId.split("-").join("").replaceAll("_", "").length !== 0) {
      setErrorMessage((prev) => ({ ...prev, taxId: 'ระบุเลขภาษีให้ครบ 13 หลัก' }))
      return validationChecked = true;
    } else if (!houseNumber) {
      setErrorMessage((prev) => ({ ...prev, houseNumber: 'ระบุที่อยู่' }))
      return validationChecked = true;
    } else if (!provinceId) {
      setErrorMessage((prev) => ({ ...prev, provinceId: 'ระบุจังหวัด' }))
      return validationChecked = true;
    } else if (!districtsId) {
      setErrorMessage((prev) => ({ ...prev, disTrictId: 'ระบุอำเภอ' }))
      return validationChecked = true;
    } else if (!subDistrictsId) {
      setErrorMessage((prev) => ({ ...prev, subDistrictId: 'ระบุตำบล' }))
      return validationChecked = true;
    }
    return validationChecked
  }

  const onSubmit = async () => {

    const validate: boolean = validation();
    if (validate) {
      return;
    } else {
      const condition: any = {
        prefixId: Number(prefix),
        firstname: firstname,
        lastname: lastname,
        idCard: idCard?.split("-").join(""),
        birthDay: birthDay,
        mobilePhone: mobilePhone ? mobilePhone?.split("-").join("") : "",
        email: email,
        taxId: taxId ? taxId.includes('_____') ? null : taxId?.split("-").join("") : "",
        houseNumber: houseNumber,
        moo: moo,
        village: village,
        soi: soi,
        road: road,
        provinceId: provinceId,
        districtId: districtsId,
        subDistrictId: subDistrictsId,
        zipCode: zipCode,
        status: "ACTIVE",
        // profile: file
      }
      console.log(condition);
      setIsLoading(true)
      await UserApi.update(condition, JSON.parse(decode(profile))?.userId)
        .then(async (res) => {
          if (res.status < 300) {
            await LoginApi.profile()
              .then((res: any) => {
                if (res.status === 200) {
                  setProfile(encode(JSON.stringify(res.data)))
                  setIsLoading(false)
                  notiSuccess(t('อัพเดตข้อมูล สำเร็จ'), 'go', '/')
                } else {
                  setIsLoading(false)
                  notiError(t("อัพเดตข้อมูล ไม่สำเร็จ"), 'reload');
                }
              })
              .catch(error => {
                setIsLoading(false)
                swalError(error.response.data.error_description || 'อัพเดตข้อมูล ไม่สำเร็จ');

              })
          }

        })
        .catch((error) => {
          setIsLoading(false)
          swalError(error.response.data.error_description || 'อัพเดตข้อมูล ไม่สำเร็จ');
        })
    }
  }

  return (
    <div className="w-100 position-relative">
      {isLoading && <Loading show />}
      <Style.MainContainer>
        <Style.Topic>{t("ADMIN.PROFILE")}</Style.Topic>
        <Divider className="mx-0" />

        <Style.InformationRenter>
          <Style.BgInformation>
            <Grid container spacing={2}>
              {/* <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className="w-100 d-flex justify-content-center"
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {file ? <Style.PicRenter src={file} alt="" /> : <Style.PicRenter src={imgs.defaultAvatar} alt="" />}

                </div>
              </Grid> */}
              <Style.GridInput item lg={4} sm={4} xs={4}>
                <div className="">
                  <FilterSelect
                    onchange={(e: any) => {
                      setPrefix(e.target.value)
                    }}
                    options={prefixData && prefixData.map((e: any) => {
                      return <MenuItem value={e.prefixId} >{e.prefixNameTh || e.prefixNameEn}</MenuItem>;
                    })}
                    selectId="select-prefix"
                    value={prefix}
                    labelId="label-prefixId"
                    required

                    heading={t("AGREEMENT.INPUT.PREFIX")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={4} sm={4} xs={8}>
                <div>
                  <InputTextField

                    value={firstname}
                    onchange={(e: any) => setFirstname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item lg={4} md={4} sm={4} xs={12}>
                <div>
                  <InputTextField

                    value={lastname}
                    onchange={(e: any) => setLastname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.LAST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Grid item lg={4} md={4} sm={5} xs={12}>
                <div className="mt-2">
                  <Style.FlexRow>
                    <Style.Detail>{t("AGREEMENT.INPUT.ID_CARD")} </Style.Detail>
                    <Style.RequestDetail>*</Style.RequestDetail>
                  </Style.FlexRow>
                  <Style.CustomPatternFormat
                    format={"#-####-#####-#-##"}
                    mask={"_"}
                    allowEmptyFormatting={true}
                    value={idCard}
                    onChange={(e: any) => {
                      setIdCard(e.target.value)
                      clearErrorMessage()
                    }}
                    required
                  />
                  {errorMessage.idCard && <HelperText label={errorMessage.idCard} />}
                </div>
              </Grid>
              <Grid item lg={4} md={6} sm={5} sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
                <div className="mt-2 w-100">
                  <InputDatePicker
                    required
                    disableFuture
                    value={birthDay}
                    allowClear
                    onClear={() => setBirthDay("")}
                    key={"date"}
                    onChange={(e: any) => {
                      setBirthDay(moment(e).format("YYYY-MM-DD"))
                      clearErrorMessage()
                    }}
                    helperText={errorMessage.birthDay}
                    heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                  />
                </div>
                <div className="d-flex flex-column mt-2">
                  <Style.FlexRow>
                    <Style.Detail>{t("AGREEMENT.INPUT.AGE")} </Style.Detail>
                    {/* <Style.RequestDetail>*</Style.RequestDetail> */}
                  </Style.FlexRow>
                  <Style.BoxInputAge>
                    <InputTextField
                      disabled
                      value={birthDay && moment().diff(birthDay, "years")}
                      inputTextAlign="center"
                      size="medium"
                    />
                    <Typography className="ml-2">
                      {t("AGREEMENT.INPUT.YEAR")}
                    </Typography>
                  </Style.BoxInputAge>
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="mt-2">
                  <Style.Heading>{t("AGREEMENT.INPUT.TEL")}</Style.Heading>
                  <Style.CustomPatternFormat
                    format={"###-###-####"}
                    mask={"_"}
                    allowEmptyFormatting={true}
                    value={mobilePhone}
                    onChange={(e: any) => setMobilePhone(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="mt-2">
                  <InputTextField
                    disabled
                    value={email}
                    onchange={(e: any) => setEmail(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.EMAIL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="mt-2">
                  <Col>
                    <Style.Heading sx={{ display: "flex", gap: 1 }}>{t("AGREEMENT.INPUT.TAX_ID")} <div className="text-danger">{"(ถ้ามี)"}</div></Style.Heading>
                    <Style.CustomPatternFormat
                      format={"#-####-#####-#-##"}
                      mask={"_"}
                      allowEmptyFormatting={true}
                      value={taxId}
                      onChange={(e: any) => {
                        setTaxId(e.target.value)
                        clearErrorMessage()
                      }}
                    />
                  </Col>
                  {errorMessage.taxId && <HelperText label={errorMessage.taxId} />}
                </div>
              </Grid>
              <Grid item lg={4} md={3} sm={6} xs={12}>
                <div className="mt-2">
                  <InputTextField
                    helperText={errorMessage.houseNumber}
                    required
                    value={houseNumber}
                    onchange={(e: any) => {
                      setHouseNumber(e.target.value)
                      clearErrorMessage()
                    }}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ADRESS")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <div className=" mt-2">
                  <InputTextField
                    value={moo}
                    onchange={(e: any) => setMoo(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.MOO")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <div className=" mt-2">
                  <InputTextField
                    value={village}
                    onchange={(e: any) => setVillage(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.VILLAGE")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={12}>
                <div className=" mt-2">
                  <InputTextField
                    value={soi}
                    onchange={(e: any) => setSoi(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.SOI")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4} xs={12}>
                <div className=" mt-2">
                  <InputTextField
                    value={road}
                    onchange={(e: any) => setRoad(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ROAD")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4} xs={12}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={provinceData}
                    getOptionLabel={(option) => option.provinceNameTh || option.provinceNameEn || ""}
                    onChange={(event, value) => {
                      clearErrorMessage()
                      if (value) {
                        setProvinceId(value.provinceId)
                        setDistrictsId("");
                        setSubDistrictsId(0)
                        setZipCode("")
                      }
                    }}
                    helperText={errorMessage.provinceId}
                    value={provinceData.find((d) => d?.provinceId === provinceId) || provinceId || null}
                    labelId="label-provinceId"
                    required={true}
                    heading={t("STALL.DETAIL.CITY")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} md={4} sm={4} xs={12}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={districtsData}
                    getOptionLabel={(option) => option.districtNameTh || option.districtNameEn || ""}
                    onChange={(event, value) => {
                      clearErrorMessage()
                      if (value) {
                        setDistrictsId(value.districtId);
                        setSubDistrictsId(0)
                      }
                    }}
                    helperText={errorMessage.disTrictId}
                    value={districtsData && districtsData.find((d) => d?.districtId === districtsId) || districtsId || null}
                    labelId="label-districts"
                    required={true}
                    heading={t("STALL.DETAIL.DISTRICT")}
                  />

                </div>
              </Grid>
              <Grid item lg={3} md={4} sm={4} xs={12}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={subDistrictsData}
                    getOptionLabel={(option) => option.subDistrictNameTh || option.subDistrictNameEn || ""}

                    onChange={(event, value) => {
                      clearErrorMessage()
                      if (value !== null) {
                        setSubDistrictsId(value.subDistrictId);
                        const selectedSubDistrict = subDistrictsData.find((d) => d.subDistrictId === value?.subDistrictId || null);
                        const { postcode } = selectedSubDistrict || {};
                        setZipCode(postcode || "");

                      } else {
                        setZipCode('');
                      }
                    }}
                    helperText={errorMessage.subDistrictId}
                    value={subDistrictsData && subDistrictsData.find((d) => d.subDistrictId === subDistrictsId) || subDistrictsId}
                    labelId="label-subDistricts"
                    required={true}
                    heading={t("STALL.DETAIL.SUB_DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} md={4} sm={4} xs={12}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    disabled
                    value={zipCode}
                    onchange={(e: any) => setZipCode(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ZIPCODE")}
                  />
                </div>
              </Grid>
            </Grid>
          </Style.BgInformation>
        </Style.InformationRenter>


        <ButtonCustom
          textButton={t("AGREEMENT.BUTTON.SAVE")}
          style={{ width: "100%" }}
          onClick={() => onSubmit()}
        />

      </Style.MainContainer>
    </div>
  );
}
