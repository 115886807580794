import { useTranslation } from "react-i18next";
/** COMPONENT */
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from '../cancel.Style'
import { Col, Row } from "react-bootstrap";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ButtonCustom from "../../../component/button/buttonCustom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Cloudupload from "../../../assets/icon/cloudupload";
/** UTIL */
import { dateToView } from "../../../utils/date.utils";

type PropsDetail = {
  data?: any,
  status?: string,
  getRootProps?: any,
  getInputProps?: any,
  handlepreview?: any,
  handledelete?: any,
  selectFile?: any,
  file?: any,
  onsubmit?: any
}

export default function AttachFile(props: PropsDetail) {
  const { t } = useTranslation();
  const d = props?.data
  return (
    <ModalCustom
      title={t("CANCELCONTRACT.TITLE_CANCEL_STALL")}
      size="lg"
      closeButton={true}
      modalScroll
      onSubmit={props.onsubmit}
      textBtnConfirm={t("CANCELCONTRACT.BUTTON.SAVE")}
      component={
        <div className="pb-2">

          <Style.ContainerBoxUpload>
            <Style.BoxModal>
              <Row>
                <span style={{ fontSize: 16, fontWeight: 700 }}>
                  {t("CANCELCONTRACT.POPUP.CANCEL_AGREEMENT_DETAIL")}
                </span>
              </Row>

              <>
                <Row>
                  <Col lg={12}>
                    <Style.SubDetail>
                      <span style={{ fontSize: 14, fontWeight: 500, marginRight: 16 }}>{t("CANCELCONTRACT.POPUP.ZONE")}</span>
                      {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</Style.SubDetail>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Style.SubDetail>
                      <span style={{ fontSize: 14, fontWeight: 500, marginRight: 16 }}>{t("CANCELCONTRACT.POPUP.STALL")}</span>
                      {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}
                    </Style.SubDetail>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Style.SubDetail>
                      <span style={{ fontSize: 14, fontWeight: 500, marginRight: 16 }}>{t("CANCELCONTRACT.POPUP.APPROVE_DATE_SUBMIT")} : </span>
                      {dateToView(d?.leaseAgreement?.leaseAgreementApprove?.approveDate)}
                    </Style.SubDetail>
                  </Col>
                </Row>

              </>

            </Style.BoxModal>

            <Style.BoxUpload >
              {props.selectFile ? (
                <div {...props.getRootProps}>
                  <input {...props.getInputProps} />
                  <img src={props.selectFile} alt="Thumb" height="300px" style={{cursor:"pointer"}}/>
                </div>
              ) : (
                <Style.Boxdash>
                  <div {...props.getRootProps}>
                    <input {...props.getInputProps} />

                    <Box
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "center",
                        cursor:'pointer'
                      }}
                    >
                      <Cloudupload />
                      <Style.BoxText>
                        <Typography
                          sx={{
                            marginTop: "24px",
                            fontSize: "18px",
                            fontFamily: "kanit",
                          }}
                        >
                          แนบไฟล์เอกสาร PDF,JPG, PNG
                        </Typography>
                      </Style.BoxText>
                    </Box>
                  </div>
                </Style.Boxdash>
              )}
              {props.file?.map((x: any, index: any) => (
                <>
                  <Grid item lg={3} xs={12}></Grid>
                  <Grid item lg={1} xs={12} className="my-2">
                    {index + 1}
                  </Grid>
                  <Grid key={index} item lg={8} xs={12} className="my-2">
                    <Style.Array>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{x.path}</Box>

                        <Box>
                          <VisibilityIcon
                            onClick={() => props.handlepreview(x)}
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          <DeleteIcon
                            onClick={() => props.handledelete(x)}
                            color="error"
                            sx={{ cursor: "pointer" }}
                          />
                        </Box>
                      </Box>
                    </Style.Array>
                  </Grid>
                </>
              ))}

            </Style.BoxUpload>

          </Style.ContainerBoxUpload>
        </div>
      }
    />
  )
}