import { useTranslation } from "react-i18next";
import { Box, Button, Checkbox, Divider, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _, { now, reverse } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
/** COMPONENT */
import HeaderCard from "../../component/cardCustom/headerCard";
import TableRowCommon from "../../component/table/TableRowCommon";
import InputTextField from "../../component/input/inputTextField";
import FilterSelect from "../../component/select/filterSelect";
import TableCustom from "../../component/table/tableCustom";
import CheckHeir from "./checkHeir";
import TransferDetailPopup from "./popup/transferDetailPopup";
import ModalCustom from "../../component/modalCustom/modalCustom";
import ButtonCustom from "../../component/button/buttonCustom";
import SubmitTransferPopup from "./popup/submitTransferPopup";
import AcceptTransfer from "./popup/acceptTransfer";
/** STYLE, CONSTANT */
import { colors } from "../../constants/colors";
import * as Styles from "./transferStall.style";
import * as Style from "../payBill/payMentStyle";
import { icons, imgs } from "../../constants/images";
import { routeName } from "../../routes/routers-name";

/** API, UTIL */
import { closeModal, showModal } from "../../app/slice/modal.slice";
import { debouncedApiCall, getProfile } from "../../utils/app.utils";
import TranferApi from "../../api/transfer-lease-agreement/transfer-lease-agreement";
import LeaseAgreementApi from "../../api/stall/leaseAgreement/leaseAgreement.api";
import { dateToView, fullDate } from "../../utils/date.utils";
import UserApi from '../../api/auth/users.api';
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import ZoneApi from "../../api/zone/zone.api";
import InputDatePicker from "../../component/input/inputDatePicker";
import { ExportProps } from "../../constants/reportConstant";
import { useFunctionPrint } from "../../component/Print/print";
import Loading from "../../component/loading/loading";
import { decode } from 'js-base64';
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faCheck, faCirclePlus, faFile, faPrint, faShuffle } from "@fortawesome/free-solid-svg-icons";
import InvoiceApi from "../../api/invoid/invoid.api";
import InputCheckbox from "../../component/input/inputCheckbox";
import { useDropzone } from "react-dropzone";
import FileuploadApi from "../../api/uploadfile/upload.api";
import BranchApi from "../../api/setting/branch/branch.api";
import AppointmentApi from "../../api/stall/appointment/appointment";
import ModalHaveDept from "../../component/modalCustom/modalHaveDept";
import DetailReceiver from "./detailReceiver";
import PaymentTypeApi from "../../api/setting/debttype/debttype.api";
import AnnounceApi from "../../api/home/announce.api";
import { swalActive } from "../../component/notification/swal";
import { notificationSwal } from "../../constants/ืnotification.swal";
import FeePopup from "./popup/FeePopup";
import { isCreate, isApporve, isDelete, isExport, isUpdate } from '../../utils/permission.utils';
import IssueAndInvoicePopup from "./popup/IssueAndInvoicePopup";
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import CustomerHeirApi from "../../api/customer-heir/customerHeir.api";
import BidderApi from "../../api/stall/bidder/bidder.api";
import RelationshipsApi from "../../api/setting/relationship/relationship.api";
import HelperText from "../../component/input/helperText";

const permissions = {
  isCreate,
  isApporve,
  isDelete,
  isExport,
  isUpdate
}
const initialErrorMessage = {
  relationshipId: "",
}

export default function TransferStall() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef<HTMLDivElement>(null);

  // const isUser: boolean = useSelector((state: any) => state?.statusUser?.isUser);
  const [errorMessage, setErrorMessage] = useState<any>(initialErrorMessage)

  const profile: any = JSON.parse(getProfile() || "{}");

  const [firstHeir, setFirstHeir] = useState<boolean>(false);
  const [secondHeir, setSecondHeir] = useState<boolean>(false);
  const [thirdHeir, setThirdHeir] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>();
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [accept, setAccept] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [transferStall, setTransferStall] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('ALL')
  const [searchDate, setSearchDate] = useState<string>("")
  const [detatailagreement, setDetatailagreement] = useState<any>({});
  const [dataTranfer, setDataTarnfer] = useState<any>([]);
  const [dataLease, setDataLease] = useState<any[]>([])
  const [zone, setZone] = useState<any>([]);
  const [stall, setStall] = useState<any>([]);
  const [searchZone, setSearchZone] = useState<number | null>(null);
  const [searchStall, setSearchStall] = useState<number | null>(null);

  const [acceptTransfer, setAcceptTransfer] = useState<boolean>(false)
  const [transferDetail, setTransferDetail] = useState<boolean>(false)

  const [confirmTransfer, setConfirmTransfer] = useState<boolean>(false)

  const [transferApproveDate, setTransferApproveDate] = useState<string>("")
  const [transferApprovePlace, setTransferApprovePlace] = useState<string>("")
  const [transferApprovePlaceId, setTransferApprovePlaceId] = useState<number>()
  const [tel, setTel] = useState<string>("")
  const [customerHierId, setCustomerHierId] = useState<number>()
  const [typePrint, setTypePrint] = useState<string>("")
  const [printData, setPrintData] = useState<any>()

  const [loading, setLoading] = useState<boolean>(false);
  const [openSearchId, setOpenSearchId] = useState<boolean>(false)
  const [openSearchAddHairId, setOpenSearchAddHairId] = useState<boolean>(false)
  const [searchIdcard, setSearchIdcard] = useState<string>("")
  const [searchAddHairIdcard, setSearchAddHairIdcard] = useState<string>("")
  const [findIdcardData, setFindIdcardData] = useState<any>("")
  const [findAddHairIdcardData, setFindAddHairIdcardData] = useState<any>("")
  const [selectSuccessor, setSelectSuccessor] = useState<boolean>(false)
  const [dataById, setDataById] = useState<any>("")
  const [branchData, setBranchData] = useState<any[]>([])
  const [file, setFile] = useState<any>();
  const [select, setSelect] = useState<any>("")
  const [appointmentData, setAppointmentData] = useState<any[]>([])
  const [depted, setDepted] = useState<boolean>(false)
  const [transferStallAdmin, setTransferStallAdmin] = useState<boolean>(false)
  const [dataDetaiiTransfer, setDataDetailTransfer] = useState<any>()
  const [detailRecive, setDetailReceive] = useState<any>()

  const [paybill, setPayBill] = useState<boolean>(false)
  const [stallOptionModal, setStallOptionModal] = useState<any[]>([]);
  const [zoneOptionModal, setZoneOptionModal] = useState<any[]>([]);
  const [checkAccept, setCheckAccept] = useState<boolean>(false);
  const [appointmentDate, setAppointmentDate] = useState<string>("");
  const [stallId, setStallId] = useState<string>("");
  const [zoneId, setZoneId] = useState<string>("");
  const [leaseAgreementTypeId, setLeaseAgreementTypeId] = useState<number>();
  const [leaseAgreementId, setLeaseAgreementId] = useState<number>();
  const [endDateModal, setEndDateModal] = useState<string>("")
  const [services, setServices] = useState<any[]>([]);
  const [debtTypeData, setDebtTypeData] = useState<any[]>([])
  const [disCountData, setDiscountData] = useState<any[]>([])
  const [isShowFee, setIsShowFee] = useState<boolean>(false);
  const [isShowIssueInvoice, setIsShowIssueInvoice] = useState<boolean>(false);
  const [dataForPrinting, setDataForPrinting] = useState<any[]>([])

  const isUser: boolean = JSON.parse(decode(profile))?.roleId === 4 ? true : false;
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(Number);
  const [keywordLeasesTypeData, setKeywordLeasesTypeData] = useState<string>("")
  // const [customerHairData, setCustomerHairData] = useState<any[]>([]);
  const [customerHeirData, setCustomerHeirData] = useState<any[]>([])

  const [customerHairDetail, setCustomerHairDetail] = useState<any>(null)

  const [isCustomerHair, setIsCustomerHair] = useState<boolean>(false);
  const [detailCustomerSend, setDetailCustomerSend] = useState<any>(null);
  const [detailCustomerReceive, setDetailCustomerReceive] = useState<any>(null);

  const [relationshipData, setRelationShipData] = useState<any[]>([]);

  const [keywordLoading, setKeywordLoading] = useState<boolean>(false)
  const [searchRelationship, setSearchRelationship] = useState<string>("")
  const [relationship, setRelationship] = useState<any>()
  // 
  const [dataBider, setDataBider] = useState<any>({})


  const handleGetRelationShip = async () => {
    let condition: any = {}
    // if (searchRelationship) condition = { ...condition, search: searchRelationship }
    setKeywordLoading(true)
    await RelationshipsApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          setKeywordLoading(false)
          setRelationShipData(res.data)
        } else {
          setKeywordLoading(false)
          setRelationShipData([])
        }
      })
      .catch(error => {
        setKeywordLoading(false)
        console.log(error)
      })
  }
  useEffect(() => {
    handleGetRelationShip();
  }, [])


  // useEffect(() => {
  //   const handleGetCustomerHair = async () => {
  //     let condition = {
  //       page: 1,
  //       limit: 5
  //     }
  //     await CustomerHeirApi.findAll(condition)
  //       .then((res: any) => {
  //         if (res.status === 200) {
  //           // setCustomerHairData(res?.data)
  //           const data = res?.data?.map((list: any) => ({ ...list, isShowImage: false }))
  //           const lastIndex = data?.length - 1;
  //           const threeCustomerHeir = data?.slice(Math.max(lastIndex - 2, 0), lastIndex + 1) || [];
  //           setCustomerHairData(threeCustomerHeir)
  //         }
  //       })
  //   }
  //   handleGetCustomerHair();
  // }, [])

  const handleShowPreviewImageCustomerHair = (index: number) => {
    setCustomerHeirData((prev: any) => {
      return prev?.map((list: any, i: number) => {
        if (i === index) {
          return { ...list, isShowImage: !list?.isShowImage }
        } else return list
      })
    })
  }
  const handleGetDetailCustomerHairDetail = async (id: number) => {
    await CustomerHeirApi.findById(id)
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          setCustomerHairDetail(res.data)
        }
      })
  }


  const handleGetLeasesTypeData = useCallback(async () => {
    let condition: any = {}
    if (keywordLeasesTypeData) condition = { ...condition, search: keywordLeasesTypeData }
    await LeasesTypeApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          // console.log(res.data)
          setLeasesTypeData(res?.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [keywordLeasesTypeData])
  useEffect(() => {
    // if (!isUser) {
    handleGetLeasesTypeData();
    // }
  }, [handleGetLeasesTypeData])

  const checkInvoice = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 10,
      userId: JSON.parse(decode(profile))?.userId,
      status: "NOT_PAY"
    }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      if (res.data.length > 0) {
        setDepted(true)
        dispatch(showModal())
      }
    } else {

    }
  }

  useEffect(() => {
    checkInvoice()
  }, [])

  const getAppointmentData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 4000
    }
    const res = await AppointmentApi.findAll(condition)
    if (res.status === 200) {
      setAppointmentData(res.data.filter((item: any) => item?.appointmentType === "TRANSFER"))
    }
  }
  const getBranch = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100
    }
    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setBranchData(res.data)
    } else {
      setBranchData([])
    }
  }

  useEffect(() => {
    getBranch()
    getAppointmentData()
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const fileName = file?.name.split(".");
      const detailImage = {
        fileId: null,
        base64: "",
        fileExtension: fileName[fileName?.length - 1],
        fileSize: file.size.toString(),
      };
      const reader: any = new FileReader();
      reader.addEventListener("load", () => {
        const base64 = reader.result.split(",");
        setFile(reader.result);
        detailImage.base64 =
          base64.length > 0 ? base64[base64?.length - 1] : reader?.result;
      });
      reader.readAsDataURL(file);
    },

    [file]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
  }, []);


  const loadDiscount = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 1,
      newstypeId: 3,
      sortBy: "endDate",
      status: "APPROVE",
      sortType: "DESC"
    }
    const res = await AnnounceApi.findAll(condition)
    if (res.status === 200) {
      if (res.data[0]?.endDate < Date.now()) {
        setDiscountData([])
      } else {
        setDiscountData(res.data)
      }
    } else {
      setDiscountData([])
    }
  }

  useEffect(() => {
    loadDiscount()
  }, []);

  const loadDataPaymentType = useCallback(async () => {

    let condition: any = {
      page: 1,
      pageLimit: 50,
      status: "ACTIVE"
    }
    if (search) condition = { ...condition, search: search }

    const res = await PaymentTypeApi.findAll(condition)

    if (res.status === 200) {
      setDebtTypeData(res.data)

    } else {
      setDebtTypeData([])
    }
  }, [search, status])

  useEffect(() => {
    loadDataPaymentType()
  }, [loadDataPaymentType])

  const handleServiceAdd = () => {
    const paymentListLabel = 1
    const amountLabel = "";
    const detailLabel = "";

    setServices((prevServices: any) => [
      ...prevServices,
      {
        // invoiceId: null,
        sequential: 1,
        paymentServiceId: paymentListLabel,
        amount: amountLabel,
        description: detailLabel,
        // invoiceDetailDiscount: null,
        // utilitiesId: null
      },
    ]);
  };

  const handleServiceDelete = (index: number) => {
    setServices((prevServices) => prevServices.filter((_, i) => i !== index));
  };

  const handleServiceUpdate = (e: any, id: any, propertyName: any) => {
    if (propertyName === "amount") {
      const { value } = e.target;
      setServices((prevData) => {
        const newData = [...prevData];
        newData[id] = { ...newData[id], [propertyName]: value }
        return newData;
      });
    } else if (propertyName === "paymentServiceId") {
      setServices((prevData) => {
        const newData = [...prevData];
        newData[id] = { ...newData[id], [propertyName]: e }
        return newData;
      })
    } else {
      const { value } = e.target;
      setServices((prevData) => {
        const newData = [...prevData];
        newData[id] = { ...newData[id], [propertyName]: value }
        return newData;
      });
    }

  };

  const loadZoneData = async () => {
    const condition = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT",
    };
    await ZoneApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setZone(res?.data);
        setZoneOptionModal(res.data)
      } else {
        setZone([]);
        setZoneOptionModal([])
      }
    });
  };

  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId !== 4) {
      loadZoneData()
    }
  }, [])

  const loadUserData = async () => {
    const condition: any = {
      id: JSON.parse(decode(profile))?.userId,
    }
    await UserApi.getDetail(condition)
      .then((res) => {
        if (res.status === 200) {
          // setCustomerHeirData(res?.data?.customerHier)
          // console.log(res.data?.customerHier)
          const lastIndex = res.data?.customerHier?.length - 1;
          const threeCustomerHeir = res.data?.customerHier?.slice(Math.max(lastIndex - 2, 0), lastIndex + 1) || [];
          // console.log(res?.data?.customerHier)
          setCustomerHeirData(threeCustomerHeir)
        } else {
          setCustomerHeirData([])
        }
      })
  }

  const loadBidderData = async (id: number) => {
    await BidderApi.findById(id)
      .then((res) => {
        if (res.status === 200) {
          setCustomerHeirData(res.data?.customerHier || [])
        } else {
          setCustomerHeirData([])
        }
      })
  }

  // useEffect(() => {
  //   if (JSON.parse(decode(profile))?.roleId === 4) {
  //     loadUserData()
  //   }
  // }, [])

  const loadData = useCallback(async () => {
    let condition: any = {
      sortBy: "transferDate",
      sortType: "DESC"
    };
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search !== "") condition = { ...condition, search: search };
    if (searchZone !== null) condition = { ...condition, zoneId: searchZone };
    if (searchStall !== null) condition = { ...condition, stallId: searchStall };
    if (status !== "ALL") condition = { ...condition, status: status }
    const res = await TranferApi.findAll(condition);

    if (res.status === 200) {
      setRowCount(res.headers["total"]);
      setDataTarnfer(res.data);
    } else {
      setRowCount(0);
      setDataTarnfer([]);
    }
  }, [page, pageLimit, search, searchZone, searchStall, status]);

  const loadDatauserdata = useCallback(async () => {
    let condition: any = { userId: profile?.userId };
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search !== "") condition = { ...condition, search: search };
    if (searchZone !== null) condition = { ...condition, zoneId: searchZone };
    if (searchStall !== null) condition = { ...condition, stallId: searchStall };
    if (JSON.parse(decode(profile))?.roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }
    const res = await LeaseAgreementApi.findAll(condition);

    if (res.status === 200) {
      setRowCount(res.headers["total"]);
      setDataLease(res.data);
    } else {
      setRowCount(0);
      setDataLease([]);
    }
  }, [page, pageLimit, search, searchZone, searchStall, status]);

  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId === 4) {
      loadDatauserdata();
    }
  }, [page, pageLimit, search, searchZone, searchStall, status]);
  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId !== 4) {
      loadData();
    }
  }, [page, pageLimit, search, searchZone, searchStall, status]);



  const updateHeir = async (ref: number) => {
    if (!relationship) {
      return setErrorMessage((prev: any) => ({ ...prev, relationshipId: 'กรุณาระบุความสัมพันธ์' }))
    }
    if (customerHeirData.filter((item: any) => item?.customerId === findAddHairIdcardData?.customerId)?.length > 0) {
      return notiError('ไม่ผู้สืบทอดซ้ำได้')
    }
    const condition = {
      // ...findAddHairIdcardData,
      bidderId: ref,
      // relationshipsId: relationship,
      // idCard: findAddHairIdcardData?.idCard.split("-").join("") || "",
      // taxId: findAddHairIdcardData?.taxId ? findAddHairIdcardData?.taxId.split("-").join("") : "",
      // isShowImage: undefined,
      // imageFile: undefined,
      // userId: undefined,
      // refFile: undefined,

      customerId: findAddHairIdcardData?.customerId,
      prefixId: findAddHairIdcardData?.prefixId,
      firstname: findAddHairIdcardData?.firstname,
      lastname: findAddHairIdcardData?.lastname,
      idCard: findAddHairIdcardData?.idCard,
      birthDay: findAddHairIdcardData?.birthDay,
      mobilePhone: findAddHairIdcardData?.mobilePhone,
      email: findAddHairIdcardData?.email,
      taxId: findAddHairIdcardData?.taxId,
      houseNumber: findAddHairIdcardData?.houseNumber,
      moo: findAddHairIdcardData?.moo,
      village: findAddHairIdcardData?.village,
      soi: findAddHairIdcardData?.soi,
      road: findAddHairIdcardData?.road,
      relationshipsId: relationship,
      provinceId: findAddHairIdcardData?.provinceId,
      districtId: findAddHairIdcardData?.districtId,
      subDistrictId: findAddHairIdcardData?.subDistrictId,
      zipCode: findAddHairIdcardData?.zipCode,
      status: "ACTIVE"
    }

    // setIsLoading(true)
    // await condition.forEach(async (list, i: number) => {
    //   await CustomerHeirApi.createHeir(list)
    //     .then(async (res) => {
    //       if (res.status === 201) {
    //         const file: File | null = requestToRentStallForm[i].imageFile
    //         if (file) {
    //           const formData = new FormData();
    //           formData.append('file', file)
    //           formData.append('refId', res.data?.refId)
    //           formData.append('fileType', res.data?.fileType)
    //           await FileuploadApi.uploadfile(formData)
    //             .then(res => {
    //               if (res.status === 200) {
    //                 notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
    //               }
    //             })
    //             .catch(error => {
    //               notiError(t('อัพโหลดไฟล์เอกสารไม่สำเร็จ'))
    //             })
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       notiError(t("อัพเดตข้อมูลผู้สืบทอด ไม่สำเร็จ"), "", null, null);
    //     })
    //     .finally(() => setIsLoading(false))
    // })
    await CustomerHeirApi.createHeir(condition)
      .then(async (res) => {
        if (res.status === 201) {
          // const file: File | null = requestToRentStallForm[i].imageFile
          // if (file) {
          //   const formData = new FormData();
          //   formData.append('file', file)
          //   formData.append('refId', res.data?.refId)
          //   formData.append('fileType', res.data?.fileType)
          //   await FileuploadApi.uploadfile(formData)
          //     .then(res => {
          //       if (res.status === 200) {
          //         notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
          //       }
          //     })
          //     .catch(error => {
          //       notiError(t('อัพโหลดไฟล์เอกสารไม่สำเร็จ'))
          //     })
          // }
          // notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
          notiSuccess(t('อัพเดตข้อมูลผู้สืบทอด สำเร็จ'))
          loadBidderData(ref)
          setConfirmTransfer(false)
          setFirstHeir(false)
          setSecondHeir(false)
          setThirdHeir(false)
          // setDetatailagreement({})
          setCustomerHierId(Number)
          setSearchIdcard("")
          setFindIdcardData("")
          setSearchAddHairIdcard("")
          setFindAddHairIdcardData("")
          setRelationship(null)
          setOpenSearchAddHairId(false)
          setOpenSearchId(false)
        } else {
          notiError(t('อัพเดตข้อมูลผู้สืบทอด ไม่สำเร็จ'))
        }
      })
      .catch(() => {
        notiError(t("อัพเดตข้อมูลผู้สืบทอด ไม่สำเร็จ"), "", null, null);
      })
    // .finally(() => setIsLoading(false))
  }


  // console.log(customerHierId)
  const submitCustomerTransfer = async () => {
    let statusChecked: boolean[] = [firstHeir, secondHeir, thirdHeir, selectSuccessor];

    if (!statusChecked?.some((list) => list)) {
      notiError("กรุณาระบุผู้สืบทอด")
      return;
    }

    const filterCustomerHierId: string = customerHeirData?.find((list: any) => list?.customerHierId === customerHierId)?.idCard || '';

    let userIdFind: number = filterCustomerHierId &&  await findIdCards(filterCustomerHierId);
    let userIdSelect: number = searchIdcard && await findIdCards(searchIdcard);

    let condition: any = {
      branchId: detatailagreement?.branchId,
      transferDate: String(moment(Date.now()).format("YYYY-MM-DD")),
      leaseAgreementId: detatailagreement?.leaseAgreementId,
      customerSentId: JSON.parse(decode(profile))?.userId,
      customerReceiveId: selectSuccessor ? userIdSelect : userIdFind,
      customerHierId: customerHierId,
      status: "WAITING"
    }

    // หากเลือกโอนสิทให้บุคคลอื่น
    if (selectSuccessor) {
      setLoading(true)
      await UserApi.findIdcard(searchIdcard)
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            // setIsShowFee(true)
            createTransferStall(condition)
          } else {
            setFindIdcardData("")
            setLoading(false)
            swalActive(
              notificationSwal.NOT_HAVE_ACCOUNT, '',
              (status: any) => {
                if (status) {
                  history.push(routeName.transferStall + "/register-from-transfer")
                } else {
                  return;
                }
              },
              'info',
              'สมัครสมาชิก')
          }
        })
        .catch(error => {
          throw new Error("Can't fetch data from api", error)
          // notiError('ไม่มี2')
        })
    } else {
      setLoading(true)
      await UserApi.findIdcard(filterCustomerHierId)
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            createTransferStall(condition)
          } else {
            setFindIdcardData("")
            setLoading(false)
            swalActive(
              notificationSwal.NOT_HAVE_ACCOUNT, '',
              (status: any) => {
                if (status) {
                  history.push(routeName.transferStall + "/register-from-transfer")
                } else {
                  return;
                }
              },
              'info',
              'สมัครสมาชิก')
          }
        })
        .catch(error => {
          throw new Error("Can't fetch data from api", error)
          // notiError('ไม่มี2')
        })
    }
  }

  const createTransferStall = async (condition: any) => {
    setLoading(true)
    const res = await TranferApi.create(condition)
    if (res.status === 201) {
      setLoading(false)
      dispatch(closeModal())
      notiSuccess(t('TRANSFER_STALL.NOTI.SUCCESS'), '', null, null)
      setFirstHeir(false)
      setSecondHeir(false)
      setThirdHeir(false)
      setDetatailagreement({})
      setCustomerHierId(Number)
      setSearchIdcard("")
      setFindIdcardData("")
    } else {
      setLoading(false)
      notiError(t('TRANSFER_STALL.NOTI.ERROR'), '', null, null)
    }
  }
  // console.log(customerHeirData)

  const PrintData = useCallback(async (props: any) => {
    try {
      setTypePrint("PRINT")
      if (JSON.parse(decode(profile))?.roleId === 4) {
        const resLease = await LeaseAgreementApi.findById(props);

        const [testResult] = await Promise.all([
          resLease,
        ]);

        setPrintData(testResult.data);
      }
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [LeaseAgreementApi])

  const getDetailUser = async () => {
    const res = await UserApi.findById(dataDetaiiTransfer?.customerReceive?.userId)
    // console.log(res.data)
    if (res.status === 200) {
      setDetailReceive(res.data)
    }
  }

  useEffect(() => {
    if (dataDetaiiTransfer?.customerReceive) {
      getDetailUser()
    }
  }, [dataDetaiiTransfer])

  const handlePrint = useFunctionPrint({
    content: () => printRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printData && typePrint === "PRINT") {
      handlePrint();
    }
  }, [printData, typePrint])

  const getLeaseAgreementData = async (props: number) => {
    const res = await LeaseAgreementApi.findById(props)
    if (res.status === 200) {
      setLeaseAgreementTypeId(res.data.leasesTypeId)
    } else {
      setLeaseAgreementTypeId(1)
    }
  }

  const headCells = [
    {
      id: "no", disablePadding: false, label: "#", align: "center",
    },

    {
      id: "date",
      disablePadding: false,
      label: t("TRANSFER_STALL.TABLE.DATE_TIME"),
      align: "center",

    },
    {
      id: "documentNumber",
      disablePadding: false,
      label: t("TRANSFER_STALL.TABLE.DOC_NO"),
      align: "center",
    },
    // {
    //   id: "name",
    //   disablePadding: false,
    //   label: t("TRANSFER_STALL.TABLE.RENTER_NAME"),
    //   align: "center",
    // },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.ZONE"),
    },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.STALL"),
    },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.EXPIRE_DATE"),
    },
    {
      id: "recipient",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.TRANFER"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.STATUS"),
    },
    {
      id: "updateBy",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.UPDATEBY"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.ACTION"),
    },
  ];
  const headCellsAdmin = [
    {
      id: "no", disablePadding: false, label: "#", align: "center",
    },

    {
      id: "date",
      disablePadding: false,
      label: t("TRANSFER_STALL.TABLE.DATE_TIME"),
      align: "center",

    },
    {
      id: "documentNumber",
      disablePadding: false,
      label: t("TRANSFER_STALL.TABLE.DOC_NO"),
      align: "center",
    },
    {
      id: "name",
      disablePadding: false,
      label: t("TRANSFER_STALL.TABLE.RENTER_NAME"),
      align: "center",
    },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.ZONE"),
    },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.STALL"),
    },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.EXPIRE_DATE"),
    },
    {
      id: "recipient",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.TRANFER"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.STATUS"),
    },
    {
      id: "updateBy",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.UPDATEBY"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("TRANSFER_STALL.TABLE.ACTION"),
    },
  ];

  const handletranfer = (leaseAgreement: any) => {
    setDetatailagreement(leaseAgreement)
    dispatch(showModal());
    setTransferStall(true);
  };

  const onAprroveORDis = async (id: any) => {
    // console.log(id)
    const res = await TranferApi.findById(Number(id))
    // console.log(res)
    if (res.status === 200) {
      setDataById(res.data)
      // setTransferDetail(true)
      if (isCustomerHair) {
        setTransferDetail(true)
        setTransferStallAdmin(false)
      } else {
        setPayBill(true)
        setTransferStallAdmin(false)
      }
      dispatch(showModal())
    }
    // console.log('awmdoawdmoi')
  }

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;

    const {
      leaseAgreementId,
      lastname,
      leaseAgreementDate,
      leaseAgreementNo,
      firstname,
      leaseAgreementApprove,
      dateBill,
      modifiedBy,
      leaseAgreementDateEnd,
      stall,
      status,
      createDate,
      customerHier
    } = objData;

    // const setState =
    //   status === "ACTIVE"
    //     ? t("TRANSFER_STALL.STATUS.ACTIVE")
    //     : status === "INACTIVE"
    //       ? t("TRANSFER_STALL.STATUS.INACTIVE")
    //       : t("TRANSFER_STALL.STATUS.CANCLE");

    const setStatus =
      status === "ACTIVE"
        ? t("STALL_AGREEMENT.STATUS.REBRAL")
        : status === "INACTIVE"
          ? t("STALL_AGREEMENT.STATUS.INACTIVE")
          : status === "CANCEL"
          ? t("STALL_AGREEMENT.STATUS.CANCEL")
          : t("STALL_AGREEMENT.STATUS.TRANSFER");

    const objRenderData = {
      key: leaseAgreementId,
      id: leaseAgreementId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreementDate),
        },
        { option: "TEXT", align: "center", label: leaseAgreementNo || "-" },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   label: `${firstname} ${lastname}` || "-",
        // },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.zoneName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stallName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreementApprove?.bidder?.stall?.stallNumber || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreementDateEnd) || "-",
        },
        { option: "TEXT", align: "center", label: dateBill || "-" },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              sx={{
                color:
                  status === "ACTIVE"
                    ? colors.green
                    : status === "INACTIVE"
                      ? colors.red
                      : colors.gray,
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              {setStatus}
            </Typography>
            // <Button
            //   sx={{
            //     backgroundColor:
            //       status === "ACTIVE"
            //         ? colors.green
            //         : status === "INACTIVE"
            //           ? colors.red
            //           : colors.gray,
            //     color: colors.white,
            //     margin: "auto",
            //     "&.MuiButtonBase-root:hover": {
            //       backgroundColor:
            //         status === "ACTIVE"
            //           ? colors.green
            //           : status === "INACTIVE"
            //             ? colors.red
            //             : colors.gray,
            //       color: colors.white,
            //       margin: "auto",
            //     },
            //   }}
            // >
            //   {setStatus}
            // </Button>
            // <Dropdown>
            //   <Dropdown.Toggle
            //     variant="link"
            //     bsPrefix="p-0  "
            //     className="btn-table-action text-black-50 shadow-none"
            //   >
            //     <Button
            //       sx={{
            //         backgroundColor:
            //           status === "ACTIVE"
            //             ? colors.green
            //             : status === "INACTIVE"
            //               ? colors.red
            //               : colors.gray,
            //         color: colors.white,
            //         margin: "auto",
            //         "&.MuiButtonBase-root:hover": {
            //           backgroundColor:
            //             status === "ACTIVE"
            //               ? colors.green
            //               : status === "INACTIVE"
            //                 ? colors.red
            //                 : colors.gray,
            //           color: colors.white,
            //           margin: "auto",
            //         },
            //       }}
            //     >
            //       {setStatus}
            //     </Button>
            //   </Dropdown.Toggle>
            //   <DropdownMenu className="dropdown-table-action position-fixed">
            //     {status !== "ACTIVE" && (
            //       <DropdownItem>
            //         {t("TRANSFER_STALL.STATUS.ACTIVE")}
            //       </DropdownItem>
            //     )}
            //     <DropdownItem>
            //       {t("TRANSFER_STALL.STATUS.INACTIVE")}
            //     </DropdownItem>
            //     <DropdownItem>{t("TRANSFER_STALL.STATUS.CANCLE")}</DropdownItem>
            //   </DropdownMenu>
            // </Dropdown>
          ),
        },
        {
          option: "UPDATE_BY",
          align: "left",
          label: {
            updatedBy: `${modifiedBy && modifiedBy.firstname && modifiedBy.lastname
              ? modifiedBy.firstname + " " + modifiedBy.lastname
              : modifiedBy?.firstname || modifiedBy?.lastname || "-"
              }`,
            updatedAt: createDate,
          },
        },
        // {
        //   option: "ACTION",
        //   align: "center",
        //   label: "action",
        //   style: { color: colors.themeMainColor },
        //   values: [
        //     {
        //       option: "PRINT",
        //       label: t("TRANSFER_STALL.TABLE.PRINT"),
        //     },
        //     {
        //       option: "STATUS_ACTIVE",
        //       label: t("TRANSFER_STALL.TABLE.TRANSFER"),
        //     },
        //   ],
        // },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="โอนสิทธิ">
                <IconButton disabled={status === "INACTIVE" || status === "CANCEL"} onClick={() => {
                  handletranfer(objData)
                  // setCustomerHeirData(leaseAgreementApprove?.bidder?.customerHier || [])
                  // loadUserData();
                  loadBidderData(leaseAgreementApprove?.bidder?.bidderId)
                }}>
                  <FontAwesomeIcon
                    icon={faShuffle}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "INACTIVE" || status === "CANCEL" ? colors.gray : colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faShuffle}
                  style={{
                    cursor: "pointer"
                    , color: status === "INACTIVE" ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => {
                    if (status === "INACTIVE") {

                    } else {
                      handletranfer(objData)
                      // setCustomerHeirData(leaseAgreementApprove?.bidder?.customerHier || [])
                      // loadUserData();
                      loadBidderData(leaseAgreementApprove?.bidder?.bidderId)
                    }

                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };

    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => handletranfer(objData)}
        onprint={() => PrintData(objData?.leaseAgreementId)}
      />
    );
  };


  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;

    const {
      leaseAgreementId,
      createdBy,
      transferNo,
      leaseAgreementApprove,
      leaseAgreement,
      modifiedBy,
      status,
      stall,
      createDate,
      customerReceive,
      customerHeir,
      step
      // customerHierId
    } = objData;
    console.log(step);
    const setState =
      status === "APPROVE"
        ? t("TRANSFER_STALL.STATUS.APPROVE")
        : status === "NOT_APPROVE"
          ? t("TRANSFER_STALL.STATUS.NOT_APPROVE") :
          status === "WAITING" && appointmentData?.map((d: any) => { return d.bidderId === leaseAgreement?.leaseAgreementApprove?.bidder?.birrderId }) ?
            t("TRANSFER_STALL.STATUS.DOCUMENT") :
            status === "WAITING" ?
              t("TRANSFER_STALL.STATUS.WAITING") :
              status === "WAITING_APPOINTMENT" ?
              t("รอการนัดทำสัญญา") 
              : t("TRANSFER_STALL.STATUS.CANCLE")

    const objRenderData = {
      key: no,
      id: leaseAgreementId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(createDate),
        },
        { option: "TEXT", align: "center", label: transferNo || "-" },
        {
          option: "TEXT",
          align: "center",
          label: `${createdBy?.firstname} ${createdBy?.lastname}` || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: leaseAgreement?.leaseAgreementApprove?.bidder?.stall?.stallNumber || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(leaseAgreement?.leaseAgreementDateEnd) || "-",
        },
        {
          option: "TEXT", align: "center",
          label: customerHeir ? customerHeir?.firstname + " " + customerHeir?.lastname || "-" :
            customerReceive ? customerReceive?.firstname + " " + customerReceive?.lastname || "-" : '-'
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              sx={{
                color:
                  status === "APPROVE"
                    ? colors.green
                    : status === "NOT_APPROVE"
                      ? colors.red : status === "WAITING" ?
                        colors.yellow : colors.gray,
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              {setState}
            </Typography>
            // <Dropdown>
            //   <Dropdown.Toggle
            //     variant="link"
            //     bsPrefix="p-0  "
            //     className="btn-table-action text-black-50 shadow-none"
            //   >
            //     <Button
            //       sx={{
            //         backgroundColor:
            //           status === "APPROVE"
            //             ? colors.green
            //             : status === "NOT_APPROVE"
            //               ? colors.red : status === "WAITING" ?
            //                 colors.yellow : colors.gray,
            //         color: colors.white,
            //         margin: "auto",
            //       }}
            //     >
            //       {setState}
            //     </Button>
            //   </Dropdown.Toggle>
            // </Dropdown>
          ),
        },
        {
          option: "UPDATE_BY",
          align: "center",
          label: {
            updatedBy: `${modifiedBy && modifiedBy.firstname && modifiedBy.lastname
              ? modifiedBy.firstname + " " + modifiedBy.lastname
              : modifiedBy?.firstname || modifiedBy?.lastname || "-"
              }`,
            updatedAt: createDate,
          },
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton disabled={permissions.isExport().disabled} onClick={async () => {
                  await getDetailUserById(objData?.customerReceive?.userId, 'recieve')
                  await getDetailUserById(objData?.customerSent?.userId, 'send')
                  setTypePrint("PRINT")
                  setDataForPrinting([objRenderData.obj])
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: permissions.isExport().disabled ? '#8f8f8f' : colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: permissions.isExport().disabled ? '#8f8f8f' : colors.themeMainColor
                  }}
                  onClick={async () => {
                    if (permissions.isExport().disabled) {
                      return;
                    } else {
                      // console.log(objData)
                      await getDetailUserById(objData?.customerReceive?.userId, 'recieve')
                      await getDetailUserById(objData?.customerSent?.userId, 'send')
                      setTypePrint("PRINT")
                      setDataForPrinting([objRenderData.obj])
                    }

                  }}
                />
              </div> */}
              <Tooltip title="การอนุมัติ">
                <IconButton disabled={status === "WAITING" && permissions.isApporve().disabled || step} onClick={async () => {
                  if (status === "WAITING"){
                    setIsCustomerHair(objData?.customerHeir?.length > 0 || !!objData?.customerHeir)
                    // console.log(objData)
                    // console.log(customerHierId)
                    // handleGetDetailCustomerHairDetail(customerHierId)
                    setDataDetailTransfer(objData)
                    dispatch(showModal());
                    setTransferStallAdmin(true);
                    findDataForApprove(objData.transferId)
                    // findDataById(objData.transferId)

                  }
                }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "WAITING" ?
                        permissions.isApporve().disabled || step ? colors.gray
                          : colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    cursor: "pointer"
                    ,
                    // color: status === "WAITING" ? colors.themeMainColor : colors.gray
                    color: status === "WAITING" ?
                      permissions.isApporve().disabled || step ? colors.gray
                        : colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "WAITING") {
                      if (permissions.isApporve().disabled || step) {
                        return;
                      } else {
                        setIsCustomerHair(objData?.customerHeir?.length > 0 || !!objData?.customerHeir)
                        // console.log(objData)
                        // console.log(customerHierId)
                        // handleGetDetailCustomerHairDetail(customerHierId)
                        setDataDetailTransfer(objData)
                        dispatch(showModal());
                        setTransferStallAdmin(true);
                      }
                    }

                    // setDataDetailTransfer(objData)
                    // dispatch(showModal());
                    // setTransferStallAdmin(true);

                    // setCustomerHierId()
                    // if(status === "WAITING"){
                    //   history.push(routeName.transferStall + "/contract-transfer", {params: objData})
                    // }
                  }}
                />
              </div> */}
              <Tooltip title="นัดทำสัญญา">
                <IconButton disabled={
                        status === "WAITING" && permissions.isUpdate().disabled || step !== 2} 
                        onClick={() => {
                          if (status === "WAITING"){
                            onAprroveORDis(objData.transferId)
                            setDataDetailTransfer(objData)
                          }
                        }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "WAITING" ?
                        permissions.isUpdate().disabled || step !== 2 ? colors.gray
                          : colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer",
                    // color: status === "WAITING" ? colors.themeMainColor : colors.gray
                    color: status === "WAITING" ?
                      permissions.isUpdate().disabled || step !== 2 ? colors.gray
                        : colors.themeMainColor : colors.gray

                  }}

                  onClick={() => {
                    if (status === "WAITING") {
                      if (!permissions.isUpdate().disabled && step === 2) {
                        onAprroveORDis(objData.transferId)
                        setDataDetailTransfer(objData)
                      }

                    }
                    // onAprroveORDis(objData.transferId)
                  }}
                />
              </div> */}
              <Tooltip title="นัดทำสัญญา">
                <IconButton disabled={status === "WAITING_APPOINTMENT" && permissions.isApporve().disabled} onClick={() => {
                  if (status === "WAITING_APPOINTMENT"){
                    history.push(routeName.transferStall + "/contract-transfer", { params: objData })
                  }
                }}>
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: ["WAITING_APPOINTMENT"].includes(status) && !permissions.isUpdate().disabled  
                      ? colors.themeMainColor 
                      : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  style={{
                    cursor: "pointer",
                    // color: status === "WAITING" ? colors.themeMainColor : colors.gray
                    color: status === "WAITING" ?
                      permissions.isApporve().disabled || step !== 3 ? colors.gray
                        : colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "WAITING") {
                      if (permissions.isApporve().disabled || step !== 3) {
                        return;
                      } else {
                        findDataById(objData.transferId)
                      }
                    }
                    // setTransferStallAdmin(true);
                    if (status === "WAITING") {
                      history.push(routeName.transferStall + "/contract-transfer", { params: objData })
                    }

                    // findDataById(objData.transferId)
                    // setDataDetailTransfer(objData);
                    // dispatch(showModal());
                    // setAcceptTransfer(true)

                    // setTransferStallAdmin(true);
                    // if(status === "WAITING"){
                    //   history.push(routeName.transferStall + "/contract-transfer", {params: objData})
                    // }
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };

    return (
      <TableRowCommon
        {...objRenderData}
        oninactive={() => {
          findDataById(objData.transferId)
        }}
        onprint={() => { }}
        ondocumentdetail={() => {
          setTransferDetail(true)
          dispatch(showModal())
        }}
      />
    );
  };
  // console.log(dataById)
  const findIdcard = async () => {
    const res = await UserApi.findIdcard(searchIdcard)
    if (res.status === 200) {
      setFindIdcardData(res.data)
    } else {
      setFindIdcardData("Notfound")
    }
  }
  const findAddHairIdcard = async () => {
    const res = await UserApi.findIdcard(searchAddHairIdcard)
    if (res.status === 200) {
      setFindAddHairIdcardData(res.data)
    } else {
      setFindAddHairIdcardData("Notfound")
    }
  }
  const findIdCards = async (idCard: string) => {
    const res = await UserApi.findIdcard(idCard)
    if (res.status === 200) {
      return res.data?.userId || 0
    } else {
      return;
    }
  }

  const findDataById = async (id: any) => {
    setLoading(true)
    const res = await TranferApi.findById(Number(id))
    if (res.status === 200) {
      setLoading(false)
      setDataById(res.data)
      setAcceptTransfer(true)
      dispatch(showModal())
    } else {
      setLoading(false)
    }
  }

  const findDataForApprove = async (id: any) => {
    const res = await TranferApi.findById(Number(id))
    if (res.status === 200) {
      setDataBider(res.data)
    } else {
      setLoading(false)
    }
  }

  const handlepreview = (x: any) => {
    const name = x.type.split(".");

    if (name[name?.length - 1] === "application/pdf") {

      window.open(URL.createObjectURL(x), "_blank");
    } else {
      setSelect(URL.createObjectURL(x));
    }

  };

  const handledelete = async (id: number, idCustomerHeir: number) => {
    const res = await CustomerHeirApi.delete(idCustomerHeir)
    // console.log(res)
    if (res.status === 200) {
      notiSuccess(t('ลบสำเร็จ'))
      loadBidderData(id)
      setFirstHeir(false)
      setSecondHeir(false)
      setThirdHeir(false)
      setSelectSuccessor(false)
      setCustomerHierId(undefined)
    } else {
      notiError(t('ลบไม่สำเร็จ'))
    }
  };

  const onSaveAppointment = async (type: string) => {
    let condition : any = {
      branchId: 0,
      userId: 0,
      bidderId: 0,
      appointmentType: "TRANSFER",
      appointmentDate: "",
      phoneNumber: ""
    }

    if (type === "transfer") {
      condition = {
        branchId: Number(JSON.parse(decode(profile))?.branchId),
        userId: dataBider?.customerReceive?.userId,
        bidderId: dataBider?.leaseAgreement?.leaseAgreementApprove?.bidderId,
        appointmentType: "TRANSFER",
        appointmentDate: transferApproveDate,
        phoneNumber: JSON.parse(decode(profile))?.mobilePhone,
        transferId: dataBider?.transferId,
      }
    } else{
      condition = {
        branchId: Number(JSON.parse(decode(profile))?.branchId),
        userId: dataById?.customerReceive?.userId,
        bidderId: dataById?.leaseAgreement?.leaseAgreementApprove?.bidderId,
        appointmentType: "TRANSFER",
        appointmentDate: transferApproveDate,
        phoneNumber: JSON.parse(decode(profile))?.mobilePhone,
        transferId: dataBider?.transferId,
      }
    }

    const res = await AppointmentApi.create(
      condition.branchId,
      condition.userId,
      condition.bidderId,
      condition.appointmentType,
      condition.appointmentDate,
      condition.phoneNumber,
      condition.transferId
    )

    if (res.status === 201) {
      notiSuccess(t('TRANSFER_STALL.NOTI.APPOINTMENT_SUCCESS'))
      dispatch(closeModal())
      setConfirmTransfer(false)
      loadData();
    } else {
      notiError(t('TRANSFER_STALL.NOTI.APPOINTMENT_ERROR'))
      setConfirmTransfer(false)
    }
  };

  const closemodal = () => { dispatch(closeModal()); };

  const validateInvoiceTransfer = (): boolean => {
    let validatedStatus: boolean = false;
    if (!appointmentDate) {
      notiError('ระบุวันชำระหนี้')
      return validatedStatus = true;
    } else if (!endDateModal) {
      notiError('ระบุวันสิ้นสุดชำระหนี้')
      return validatedStatus = true;
      // } else if (!zoneId) {
      //   notiError('ระบุโซน')
      //   return validatedStatus = true;
      // } else if (!stallId) {
      //   notiError('ระบุแผงค้า')
      //   return validatedStatus = true;
      // } else if (!leasesTypeId) {
      //   notiError('ระบุสัญญา')
      //   return validatedStatus = true;
    }
    return validatedStatus
  }
  const onsubmitCreateBill = async () => {
    const validateStatus: boolean = validateInvoiceTransfer();
    if (validateStatus) {
      return;
    } else {
      let condition: any = {
        branchId: Number(localStorage.getItem("BRANCH") || 0),
        invoiceDateStart: appointmentDate,
        invoiceDateEnd: endDateModal,
        // checkAll: String(checkAccept).toLocaleUpperCase(),
        leaseAgreementId: dataDetaiiTransfer?.leaseAgreementId,
        leaseAgreementTypeId: dataDetaiiTransfer?.leaseAgreement?.leaseAgreementTypeId,
        zoneId: dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneId,
        stallId: dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallId,
        // zoneId: zoneId,
        // stallId: stallId,
        status: "NOT_PAY",
      }
      if (services.length > 0) condition = { ...condition, invoiceDetail: [].concat(...(services || [])) }
      if (services.length === 0) condition = { ...condition, invoiceDetail: null }

      // console.log(condition)
      setLoading(true)
      await InvoiceApi.transferInvoice(condition)
        .then(res => {
          if (res.status === 201) {
            setLoading(false)
            setPayBill(false)
            // dispatch(closeModal())
            notiSuccess(t('PAYBILL.INVOICE.SUCCESS'))
            // setDataTarnfer(dataTranfer.map((item: any) => {
            //   item.step = item.transferId === dataDetaiiTransfer?.transferId ? 3 : item?.step
            //   return item
            // }))
            setConfirmTransfer(true)
          } else {
            setLoading(false)
            notiError(t('PAYBILL.INVOICE.ERROR'))
          }
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
          notiError(t('PAYBILL.INVOICE.ERROR'))
        })
        // .finally(() => loadData())
    }

  }

  const getDetailUserById = async (userId: number, type: 'send' | 'recieve') => {
    switch (type) {
      case 'send': {
        setLoading(true)
        await UserApi.findById(userId)
          .then(res => {
            if (res.status === 200) {
              setLoading(false)
              setDetailCustomerSend(res.data)
              // console.log(res.data)
            }
          })
          .finally(() => setLoading(false))
      }
        break;
      case 'recieve': {
        await UserApi.findById(userId)
          .then(res => {
            if (res.status === 200) {
              setLoading(false)
              setDetailCustomerReceive(res.data)
              // console.log(res.data)
            }
          })
          .finally(() => setLoading(false))
      }
    }
  }

  const handlePrintdataAppointment = useFunctionPrint({
    content: () => printRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (dataForPrinting && typePrint === "PRINT") {
      handlePrintdataAppointment();
    }
  }, [dataForPrinting, typePrint])

  // console.log(dataForPrinting)
  // console.log(detailCustomerReceive)
  // console.log(detailCustomerSend)
  return (
    <div>
      {/* {isShowFee &&
        <FeePopup
          isShow={isShowFee} onSubmit={() => { }}
          onClose={(status: boolean) => setIsShowFee(status)}
          onReset={(status: boolean) => setIsShowFee(status)}
        />} */}
      {isShowIssueInvoice &&
        <IssueAndInvoicePopup
          isShow={isShowIssueInvoice}
          onClose={() => setIsShowIssueInvoice(false)}
          onReset={() => setIsShowIssueInvoice(false)}
          onSubmit={() => { }}
        />}
      <HeaderCard text={t("TRANSFER_STALL.HEADER")} />
      <Styles.MainContainer sx={{ position: 'relative' }}>
        {loading && <Loading show type="fullLoading" />}
        <Box>
          <Row>
            <Col lg={2} md={JSON.parse(decode(profile))?.roleId !== 4 ? 6 : 12} xs={12} className="my-2">
              <InputTextField
                label="ค้นหา"
                value={search}
                onchange={(e: any) => setSearch(e.target.value)}
              />
            </Col>
            {/* {JSON.parse(decode(profile))?.roleId !== 4 && <Col lg={2} md={6} xs={12}  className="my-2">
            <InputDatePicker
              dateFormat="DD/MM/YY"
              key={"date-appointment"}
              label={t("TRANSFER_STALL.FILTER.DATE")}
              value={searchDate}
              allowClear
              onChange={(e: any) =>
                setSearchDate(moment(e).format("YYYY-MM-DD"))
              }
            />
            </Col>} */}
            {JSON.parse(decode(profile))?.roleId !== 4 && <Col lg={2} md={6} xs={12} className="my-2">
              <AutocompleteSelect
                options={zone || []}
                value={zone?.find(
                  (data: any) => data?.zoneId === searchZone
                )
                }
                placeholder={t("TRANSFER_STALL.FILTER.ZONE")}
                getOptionLabel={(option: any) => option.zoneName}
                labelId="search-zone"
                onChange={(event, newValue) => {
                  setSearchZone(newValue?.zoneId);
                  setStall(newValue?.stall)
                }}
              />
            </Col>}
            {JSON.parse(decode(profile))?.roleId !== 4 && <Col lg={2} md={6} xs={12} className="my-2">
              <AutocompleteSelect
                options={stall || []}
                value={stall?.find(
                  (data: any) => data?.stallId === searchStall
                )}
                placeholder={t("TRANSFER_STALL.FILTER.STALL")}
                getOptionLabel={(option: any) => option.stallName}
                labelId="search-stall"
                onChange={(event, newValue) => {
                  setSearchStall(newValue?.stallId);
                }}
              />
            </Col>}
            {JSON.parse(decode(profile))?.roleId !== 4 && <Col lg={2} md={6} xs={12} className="my-2">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value;
                  if (value) {
                    setStatus(value)
                    setPage(1);
                  }
                }}
                renderValue={() =>
                  `${t("STATUS.LABEL")}: ${t(`STATUS.${status}`)}`
                }
                label={t("TRANSFER_STALL.FILTER.STALL")}
                selectId="select-status"
                labelId="label-status"
                value={status}
                options={[
                  <MenuItem key="1" value="ALL">
                    {t("STATUS.ALL")}
                  </MenuItem>,
                  <MenuItem key="2" value="WAITING">
                    {t("STATUS.WAITING")}
                  </MenuItem>,
                  <MenuItem key="3" value="APPROVE">
                    {t("STATUS.APPROVE")}
                  </MenuItem>,
                  <MenuItem key="4" value="NOT_APPROVE">
                    {t("STATUS.NOT_APPROVE")}
                  </MenuItem>,
                ]}
              />
            </Col>}
            {JSON.parse(decode(profile))?.roleId === 4 && <Col lg={8} md={6} xs={12} className="" />}
            {JSON.parse(decode(profile))?.roleId !== 4 && <Col lg={2} md={6} xs={12} className="" />}
            {JSON.parse(decode(profile))?.roleId !== 4 && <Col lg={2} md={JSON.parse(decode(profile))?.roleId !== 4 ? 6 : 12} xs={12} className="my-2">
              <ButtonCustom
                disabled={permissions.isExport().disabled}
                textButton={t("STALL_AGREEMENT.BUTTON.PRINT")}
                style={{ width: "100%" }}
                endIcon={<img src={icons.print} />}
                onClick={() => dispatch(showModal())}
              />
            </Col>}
          </Row>
        </Box>
        {roleId === 4 && <Styles.TableContainer>
          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={dataLease && dataLease?.map((d: any, index: any) => {
              return renderData(d, index);
            })}
            onSort={() => { }}
            setPageLimit={(newValue) => setPageLimit(newValue)}
            setPage={(newValue) => setPage(newValue)}
            tableMinWidth={1400}
          />
        </Styles.TableContainer>}
        {roleId !== 4 && <Styles.TableContainer>
          <TableCustom
            headCells={headCellsAdmin}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={dataTranfer && dataTranfer?.map((d: any, index: any) => {
              return renderDataAdmin(d, index);
            })}
            onSort={() => { }}
            setPageLimit={(newValue) => setPageLimit(newValue)}
            setPage={(newValue) => setPage(newValue)}
            tableMinWidth={1400}
          />
        </Styles.TableContainer>}
      </Styles.MainContainer>

      {transferStall && <ModalCustom
        title={
          transferStall
            ? t("TRANSFER_STALL.MODAL.TITLE")
            : t("TRANSFER_STALL.MODAL.TITLE_CANCEL")
        }
        size="xl"
        closeButton
        onSubmit={closemodal}
        textBtnCancel={
          roleId !== 4 && !accept ? t("TRANSFER_STALL.MODAL.TITLE") : ""
        }
        onClose={() => {
          setConfirmTransfer(false)
          setFirstHeir(false)
          setSecondHeir(false)
          setThirdHeir(false)
          setDetatailagreement({})
          setCustomerHierId(Number)
          setSearchIdcard("")
          setFindIdcardData("")
          setSearchAddHairIdcard("")
          setFindAddHairIdcardData("")
          setRelationship(null)
          setOpenSearchAddHairId(false)
          setOpenSearchId(false)
          setTransferStall(false)
        }}
        onCancel={() => {
          setConfirmTransfer(false)
          setFirstHeir(false)
          setSecondHeir(false)
          setThirdHeir(false)
          setDetatailagreement({})
          setCustomerHierId(Number)
          setSearchIdcard("")
          setFindIdcardData("")
          setRelationship(null)
          setSearchAddHairIdcard("")
          setFindAddHairIdcardData("")
          setOpenSearchAddHairId(false)
          setOpenSearchId(false)
          setTransferStall(false)
        }}
        textBtnConfirm={roleId !== 4 && !accept ? t("ยกเลิก") : ""}
        modalScroll
        component={
          <>
            <Styles.ContainerModal>
              <Divider className="mx-0 my-2" />
              <Row>
                <Col lg={12} xs={12}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.DETAIL")}
                  </Styles.DetailModal>
                </Col>
              </Row>
              <Row>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.PETITION")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>{detatailagreement?.leaseAgreementNo}</Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.NAME_SUBNAME")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={4} xs={6}>
                  <Styles.SubDetail>{detatailagreement?.firstname} {detatailagreement?.lastname}</Styles.SubDetail>
                </Col>

              </Row>
              <Row className="mt-2">
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.APPLICATION_DATE")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>{dateToView(detatailagreement?.leaseAgreementDate)}</Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.RENT_DATE")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>{dateToView(detatailagreement?.leaseAgreementDateStart)}</Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.EXPIRE_DATE")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={2} xs={6}>
                  <Styles.SubDetail>{dateToView(detatailagreement?.leaseAgreementDateEnd)}</Styles.SubDetail>
                </Col>
                <Col lg={2} xs={2} className="d-xs-hidden" />
                <Col lg={2} xs={2} className="d-xs-hidden" />
              </Row>
              <Row className="mt-2">
                <Col lg={3} xs={3}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.ZONE")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={3} xs={3}>
                  <Styles.SubDetail>{detatailagreement?.leaseAgreementApprove?.bidder?.zoneName}</Styles.SubDetail>
                </Col>
                <Col lg={3} xs={3}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.STALL")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={3} xs={3}>
                  <Styles.SubDetail>{detatailagreement?.leaseAgreementApprove?.bidder?.stallName}</Styles.SubDetail>
                </Col>
              </Row>
              <Row>
                <Col lg={3} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.ZONE_TYPE")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={3} xs={6}>
                  <Styles.SubDetail>{detatailagreement?.leaseAgreementApprove?.bidder?.stallTypeName}</Styles.SubDetail>
                </Col>
                <Col lg={3} xs={6}>
                  <Styles.SubDetail>
                    {t("TRANSFER_STALL.MODAL.PAYMENT_CYCLE")}
                  </Styles.SubDetail>
                </Col>
                <Col lg={3} xs={6}>
                  <Styles.SubDetail>{leasesTypeData && leasesTypeData?.find((item: any) => item?.leasesTypeId === detatailagreement?.leasesTypeId)?.leasesTypeNameTh || '-'}</Styles.SubDetail>
                </Col>
              </Row>
              <Divider className="mx-0 my-2" />
              <Row>
                <Col lg={12} xs={12}>
                  <Styles.DetailModal>
                    {t("รายละเอียดผู้รับโอน")}
                    {/* {t("TRANSFER_STALL.MODAL.HEIR_DETAIL")} */}
                  </Styles.DetailModal>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <Styles.DetailModal sx={{ color: colors.red }}>
                    {t("TRANSFER_STALL.MODAL.SUB_DETAIL")}
                  </Styles.DetailModal>
                </Col>
              </Row>
              {_.map(customerHeirData, (d: any, i: number) => {

                return (
                  <>
                    <CheckHeir
                      title={d?.firstname + " " + d?.lastname}
                      // title={t("TRANSFER_STALL.MODAL.CUSTOMER_HEIR") + (i + 1)}
                      value={i === 0 ? firstHeir : i === 1 ? secondHeir : thirdHeir}
                      // disable={firstHeir}
                      onChange={() => {
                        if (i === 0) {
                          setFirstHeir(!firstHeir)
                          setSecondHeir(false)
                          setThirdHeir(false)
                          setSelectSuccessor(false)
                        } else if (i === 1) {
                          setFirstHeir(false)
                          setThirdHeir(false)
                          setSecondHeir(!secondHeir)
                          setSelectSuccessor(false)
                        } else {
                          setSecondHeir(false)
                          setFirstHeir(false)
                          setThirdHeir(!thirdHeir)
                          setSelectSuccessor(false)
                        }
                        setCustomerHierId(d?.customerHierId)
                      }}
                      onDelete={() => {
                        swalActive('ต้องการลบผู้สืบทอดหรือไม่?', '', () => {
                          handledelete(detatailagreement?.leaseAgreementApprove?.bidder?.bidderId, d.customerHierId)
                        })
                      }}
                      pathImage={d?.fileUpload ? process.env.REACT_APP_FILE_URL + d?.fileUpload?.filePath
                        + d?.fileUpload?.fileName : null}
                      isShowImage={d?.isShowImage}
                      index={i}
                      handleShowPreviewImageCustomerHair={handleShowPreviewImageCustomerHair}
                      img={imgs.errNoImg}
                      heirName={d?.firstname + " " + d?.lastname}
                      birthDate={fullDate(d?.birthDay)}
                      year={d?.birthDay && moment().diff(d?.birthDay, "years")}
                      id_number={d?.idCard}
                      email={d?.email}
                      tel={d?.mobilePhone}
                      address={"เลขที่" + d?.houseNumber + " " + "หมู่" + d?.moo + " " + "หมู่บ้าน" + d?.village + " " + "ซอย" + d?.soi
                        + " " + "ตำบล/แขวง" + d?.subDistrict?.subDistrictNameTh + " " + "อำเภอ/เขต" + d?.district?.districtNameTh
                        + " " + "จังหวัด" + d?.province?.provinceNameTh + " " + d?.zipCode
                      }
                    />
                  </>
                )
              })}
              <Divider className="mx-0 my-2" />
              {customerHeirData.length < 3 && <>

                <Box sx={{ marginTop: 4 }}>
                  <Styles.CheckBox onClick={() => {
                    setFindAddHairIdcardData('')
                    setSearchAddHairIdcard('')
                    setRelationship('')
                    setOpenSearchAddHairId(!openSearchAddHairId)
                  }}>
                    {t("เพิ่มผู้สืบทอด")}
                    <img src={icons.Shuffle} style={{ marginRight: 16, cursor: 'pointer' }} />
                  </Styles.CheckBox>
                </Box>
                {openSearchAddHairId && <div>
                  <Row>
                    <Col lg={3} md={5} xs={12} className="my-2">
                      {t("TRANSFER_STALL.MODAL.SEARCH_USER")}
                    </Col>
                    <Col lg={6} md={7} xs={12} className="my-2">
                      <InputTextField
                        label=""
                        value={searchAddHairIdcard}
                        onchange={(e: any) => {
                          if (e.target.value.length > 13) {
                            return;
                          }
                          setSearchAddHairIdcard(e.target.value)
                        }}
                      />
                      <p style={{ fontSize: 13, color: colors.danger }}>{t("ค้นหาด้วยเลขบัตรประชาชน")}</p>
                      {/* <p style={{ fontSize: 13, color: colors.danger }}>{t("TRANSFER_STALL.MODAL.CAUTION_SEARCH")}</p> */}
                    </Col>
                    <Col lg={3} md={12} xs={12} className="my-2">
                      <ButtonCustom
                        textButton={t("TRANSFER_STALL.MODAL.SEARCH_USER")}
                        style={{ width: "100%" }}
                        onClick={() => findAddHairIdcard()}
                        disabled={
                          searchAddHairIdcard === detatailagreement?.idCard
                        }
                      />
                      {
                         searchAddHairIdcard === detatailagreement?.idCard ?
                          <HelperText label={t("ไม่สามารถเพิ่มตัวเองเป็นผู้สืบทอดได้")} />
                          : null
                      }
                    </Col>
                  </Row>
                  {findAddHairIdcardData !== "" && findAddHairIdcardData !== "Notfound" &&
                    <Row className="my-1">
                      <Col xs={12}>
                        {/* <div className="d-flex flex-row w-100">
                        <InputCheckbox checked={selectSuccessor} onChange={() => {
                          setSelectSuccessor(!selectSuccessor)
                          setFirstHeir(false)
                          setSecondHeir(false)
                          setThirdHeir(false)
                        }} />
                      </div> */}

                        <Styles.ContainerHeir>
                          <Box className="w-100">
                            <Row>
                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.NAME_SUBNAME")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={3} md={6}>
                                <Styles.SubDetail>{findAddHairIdcardData?.firstname} {findAddHairIdcardData?.lastname}</Styles.SubDetail>
                              </Col>
                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.BIRTH_DAY")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={3} md={6}>
                                <Styles.SubDetail>{dateToView(findAddHairIdcardData?.birthDay)}</Styles.SubDetail>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.AGE")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={1} md={3}>
                                <Styles.SubDetail>{findAddHairIdcardData?.birthDay && moment().diff(findAddHairIdcardData?.birthDay, "years")}</Styles.SubDetail>
                              </Col>
                              <Col lg={2} md={3}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.OLD")}
                                </Styles.DetailModal>
                              </Col>

                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.ID_NUMBER")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={3} md={6}>
                                <Styles.SubDetail>{findAddHairIdcardData?.idCard}</Styles.SubDetail>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.TEL")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={3} md={6}>
                                <Styles.SubDetail>{findAddHairIdcardData?.mobilePhone}</Styles.SubDetail>
                              </Col>

                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.EMAIL")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={3} md={6}>
                                <Styles.SubDetail>{findAddHairIdcardData?.email}</Styles.SubDetail>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col lg={3} md={6}>
                                <Styles.DetailModal>
                                  {t("TRANSFER_STALL.MODAL.HEIR.RELATIONSHIP")}
                                </Styles.DetailModal>
                              </Col>
                              <Col lg={3} md={6}>
                                {/* <Styles.SubDetail>{'-'}</Styles.SubDetail> */}
                                <AutocompleteSelect
                                  // disabled
                                  loading={keywordLoading}
                                  onInputChange={(_, value) => {
                                    debouncedApiCall({ searchItem: value, setItem: setSearchRelationship })
                                  }}
                                  options={relationshipData}
                                  getOptionLabel={(option) => option?.relationshipsNameTh || ''}
                                  onChange={(_, value) => {
                                    if (value) {
                                      setRelationship(value?.relationshipsId)
                                    } else {
                                      setRelationship(null)
                                    }
                                  }}
                                  value={relationshipData?.find((d) => d?.relationshipsId === relationship) || null}
                                  labelId="label-relationshipId"
                                  required={true}
                                // heading={"ความสัมพันธ์"}
                                />
                                {errorMessage.relationshipId && <HelperText label={errorMessage.relationshipId} />}
                              </Col>
                              <Col lg={3} md={6}>
                                <ButtonCustom
                                  // disabled={!findIdcardData}
                                  variant="contained"
                                  onClick={() => { updateHeir(detatailagreement?.leaseAgreementApprove?.bidder?.bidderId) }}
                                  textButton={t("เพิ่มผู้สืบทอด")}
                                  btnStyle={{
                                    fontSize: "16px",
                                    // width: "100%",
                                    padding: "8px 25px",
                                  }}
                                />
                              </Col>
                            </Row>
                            {/* <Row className="mt-2">
                            <Col lg={2} md={12}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.ADDRESS")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={8} md={12}>
                              <Styles.SubDetail>{"เลขที่" + findAddHairIdcardData?.houseNumber + " " + "หมู่" + findAddHairIdcardData?.moo + " " + "หมู่บ้าน" + findAddHairIdcardData?.village + " " + "ซอย" + findAddHairIdcardData?.soi
                                + " " + "ตำบล/แขวง" + findAddHairIdcardData?.subDistrict?.subDistrictNameTh + " " + "อำเภอ/เขต" + findAddHairIdcardData?.district?.districtNameTh
                                + " " + "จังหวัด" + findAddHairIdcardData?.province?.provinceNameTh + " " + findAddHairIdcardData?.zipCode}</Styles.SubDetail>
                            </Col>
                          </Row> */}

                          </Box>
                        </Styles.ContainerHeir>
                        {/* <Box className="w-100">
                        <Styles.GreenButtonInformation>
                          <Typography>
                            {t("TRANSFER_STALL.MODAL.HEIR.IMG_ID_CARD")}
                          </Typography>
                          <FontAwesomeIcon onClick={() => findAddHairIdcardData.handleShowPreviewImageCustomerHair(props.index)} icon={props.isShowImage ? faEyeSlash : faEye} />
                        </Styles.GreenButtonInformation>
                        {
                          props.isShowImage && (
                            <Box sx={{ overflow: 'hidden' }}>
                              <img style={{ objectFit: "contain", width: '100%' }} src={props.pathImage || ""} alt="preview-image" />
                            </Box>
                          )
                        }
                      </Box> */}
                        {/* <div className="mt-1">ชื่อ : {findAddHairIdcardData?.firstname} สกุล : {findAddHairIdcardData?.lastname}</div> */}
                      </Col>
                    </Row>}
                  {findAddHairIdcardData === "Notfound" && <Row className="my-1">
                    <Col xs={6} style={{ marginTop: '8px', fontSize: 12, color: colors.red }}>
                      {t("TRANSFER_STALL.MODAL.NOT_FIND_IDCARD")}
                    </Col>
                    {/* <Col xs={6} style={{ marginTop: '8px', fontSize: 12, color: colors.red }}>
                    <ButtonCustom
                      textButton={t("REGISTER.HEADER.REGISTER")}
                      onClick={() => history.push(routeName.transferStall + "/register-from-transfer",)}
                      btnStyle={{
                        marginBottom: "14px",
                        justtifyContent: "center",
                      }}
                    />
                  </Col> */}
                  </Row>}
                </div>}
                <Divider className="mx-0 my-2" />

              </>}
              <Box sx={{ marginTop: 4 }}>
                <Styles.CheckBox onClick={() => {
                  setSearchIdcard('')
                  setFindIdcardData('')
                  setSelectSuccessor(false)
                  setOpenSearchId(!openSearchId)
                }}>
                  {t("TRANSFER_STALL.MODAL.TRANSFER_CONTRACT")}
                  <img src={icons.Shuffle} style={{ marginRight: 16, cursor: 'pointer' }} />
                </Styles.CheckBox>
              </Box>

              {openSearchId && <div>

                <Row>
                  <Col lg={3} md={5} xs={12} className="my-2">
                    {t("TRANSFER_STALL.MODAL.SEARCH_USER")}
                  </Col>
                  <Col lg={6} md={7} xs={12} className="my-2">
                    <InputTextField
                      label=""
                      value={searchIdcard}
                      onchange={(e: any) => {
                        if (e.target.value.length > 13) {
                          return;
                        }
                        setSearchIdcard(e.target.value)
                      }}
                    />
                    <p style={{ fontSize: 13, color: colors.danger }}>{t("ค้นหาด้วยเลขบัตรประชาชน")}</p>
                    {/* <p style={{ fontSize: 13, color: colors.danger }}>{t("TRANSFER_STALL.MODAL.CAUTION_SEARCH")}</p> */}
                  </Col>
                  <Col lg={3} md={12} xs={12} className="my-2">
                    <ButtonCustom
                      textButton={t("TRANSFER_STALL.MODAL.SEARCH_USER")}
                      style={{ width: "100%" }}
                      onClick={() => findIdcard()}
                    />
                  </Col>
                </Row>

                {findIdcardData !== "" && findIdcardData !== "Notfound" && <Row className="my-1">
                  <Col xs={12}>
                    {/* <div className="d-flex flex-row">
                      <InputCheckbox checked={selectSuccessor} onChange={() => {
                        setSelectSuccessor(!selectSuccessor)
                        setFirstHeir(false)
                        setSecondHeir(false)
                        setThirdHeir(false)
                      }} />
                      <div className="mt-1">ชื่อ : {findIdcardData?.firstname} สกุล : {findIdcardData?.lastname}</div>
                    </div> */}
                    <Styles.CheckBox >
                      {findIdcardData?.firstname} {findIdcardData?.lastname}
                      {/* <input type="checkbox" value={props.value} onChange={props.onChange} /> */}
                      <InputCheckbox checked={selectSuccessor} onChange={() => {
                        setSelectSuccessor(!selectSuccessor)
                        setFirstHeir(false)
                        setSecondHeir(false)
                        setThirdHeir(false)
                      }} />
                    </Styles.CheckBox>
                    {selectSuccessor &&
                      <Styles.ContainerHeir>
                        <Box className="w-100">
                          <Row>
                            <Col lg={3} md={6}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.NAME_SUBNAME")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={3} md={6}>
                              <Styles.SubDetail>{findIdcardData?.firstname} {findIdcardData?.lastname}</Styles.SubDetail>
                            </Col>
                            <Col lg={3} md={6}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.BIRTH_DAY")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={3} md={6}>
                              <Styles.SubDetail>{dateToView(findIdcardData.birthDay)}</Styles.SubDetail>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col lg={3} md={6}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.AGE")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={1} md={3}>
                              <Styles.SubDetail>{findIdcardData?.birthDay && moment().diff(findIdcardData?.birthDay, "years")}</Styles.SubDetail>
                            </Col>
                            <Col lg={2} md={3}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.OLD")}
                              </Styles.DetailModal>
                            </Col>

                            <Col lg={3} md={6}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.ID_NUMBER")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={3} md={6}>
                              <Styles.SubDetail>{findIdcardData.idCard}</Styles.SubDetail>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col lg={3} md={6}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.TEL")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={3} md={6}>
                              <Styles.SubDetail>{findIdcardData.mobilePhone}</Styles.SubDetail>
                            </Col>

                            <Col lg={3} md={6}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.EMAIL")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={3} md={6}>
                              <Styles.SubDetail>{findIdcardData.email}</Styles.SubDetail>
                            </Col>
                          </Row>

                          {/* <Row className="mt-2">
                            <Col lg={2} md={12}>
                              <Styles.DetailModal>
                                {t("TRANSFER_STALL.MODAL.HEIR.ADDRESS")}
                              </Styles.DetailModal>
                            </Col>
                            <Col lg={8} md={12}>
                              <Styles.SubDetail>{"เลขที่" + findAddHairIdcardData?.houseNumber + " " + "หมู่" + findAddHairIdcardData?.moo + " " + "หมู่บ้าน" + findAddHairIdcardData?.village + " " + "ซอย" + findAddHairIdcardData?.soi
                                + " " + "ตำบล/แขวง" + findAddHairIdcardData?.subDistrict?.subDistrictNameTh + " " + "อำเภอ/เขต" + findAddHairIdcardData?.district?.districtNameTh
                                + " " + "จังหวัด" + findAddHairIdcardData?.province?.provinceNameTh + " " + findAddHairIdcardData?.zipCode}</Styles.SubDetail>
                            </Col>
                          </Row> */}

                        </Box>
                      </Styles.ContainerHeir>}
                  </Col>
                </Row>}
                {findIdcardData === "Notfound" && <Row className="my-1">
                  <Col xs={6} style={{ marginTop: '8px', fontSize: 12, color: colors.red }}>
                    {t("TRANSFER_STALL.MODAL.NOT_FIND_IDCARD")}
                  </Col>
                  {/* <Col xs={6} style={{ marginTop: '8px', fontSize: 12, color: colors.red }}>
                    <ButtonCustom
                      textButton={t("REGISTER.HEADER.REGISTER")}
                      onClick={() => history.push(routeName.transferStall + "/register-from-transfer",)}
                      btnStyle={{
                        marginBottom: "14px",
                        justtifyContent: "center",
                      }}
                    />
                  </Col> */}
                </Row>}
              </div>}
              <Box>
                <ButtonCustom
                  // disabled={!findIdcardData}
                  variant="contained"
                  onClick={() => submitCustomerTransfer()}
                  textButton={t("TRANSFER_STALL.MODAL.SAVE")}
                  btnStyle={{
                    fontSize: "16px",
                    width: "100%",
                    padding: "8px 25px",
                  }}
                />
              </Box>
            </Styles.ContainerModal>
          </>
        }
      />}
      {
        transferDetail && roleId !== 4 && <TransferDetailPopup
          data={dataById}
          onclose={() => {
            setTransferDetail(false)
            setConfirmTransfer(false)
            setAcceptTransfer(false)
            setTransferStallAdmin(false)
            setPayBill(false)
            dispatch(closeModal())
          }}
          onsubmit={() => {
            setTransferDetail(false)
            setAcceptTransfer(false)
            setConfirmTransfer(true)
            setTransferStallAdmin(false)

          }}
          disapprove={() => { }}
        />
      }
      {
        confirmTransfer && roleId !== 4 && <SubmitTransferPopup
          onsubmit={() => {
            setConfirmTransfer(false)
          // TODO: add props
            onSaveAppointment('transfer')
            setTransferStallAdmin(false)
            setDataTarnfer(dataTranfer.map((item: any) => {
              item.step = item.transferId === dataDetaiiTransfer?.transferId ? 3 : item?.step
              return item
            }))
          }}
          onclose={() => {
            setTransferDetail(false)
            setConfirmTransfer(false)
            setAcceptTransfer(false)
            setTransferStallAdmin(false)
            dispatch(closeModal())
          }}
          onClearTransferDate={() => setTransferApproveDate("")}
          onchangeTransferDate={(e: any) => setTransferApproveDate(moment(e).format('YYYY-MM-DD'))}
          onchangeTransferPlace={(e: any, value: any) => {
            setTransferApprovePlaceId(value?.branchId)
            setTransferApprovePlace(value)
          }}
          onchangeTel={(e: any) => setTel(e.target.value)}
          transferDate={transferApproveDate}
          transferPlace={transferApprovePlace}
          tel={JSON.parse(decode(profile))?.mobilePhone}
          data={dataDetaiiTransfer}
          branchData={branchData}
        />
      }

      {
        acceptTransfer && <AcceptTransfer
          data={dataById}
          // TODO: add props
          onsubmit={() => { onSaveAppointment('accept') }}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          select={file}
          onchangeTransferDate={(e: any) => setTransferApproveDate(moment(e).format('YYYY-MM-DD'))}
          transferDate={transferApproveDate}
          onclose={() => {
            dispatch(closeModal())
            setAcceptTransfer(false)
          }}
        />
      }

      {depted ? <ModalHaveDept /> : <></>}

      {
        transferStallAdmin &&
        <ModalCustom
          title={t("TRANSFER_STALL.MODAL.TITLE")}
          size="xl"
          closeButton
          onSubmit={async () => {
            setTransferStallAdmin(false)
            if (isCustomerHair) {
              setTransferDetail(true)
              setTransferStallAdmin(false)
              onAprroveORDis(dataDetaiiTransfer?.transferId)
            } else {
              setPayBill(true)
              setTransferStallAdmin(false)
            }
            setDataTarnfer(dataTranfer.map((item: any) => {
              item.step = item.transferId === dataDetaiiTransfer?.transferId ? 2 : item?.step
              return item
            }))
          }}
          // textBtnCancel="ยกเลิก"
          onClose={() => {
            setDetailReceive({})
            setDataDetailTransfer({})
            setTransferStallAdmin(false)
            dispatch(closeModal())
            setFindIdcardData("")
            setConfirmTransfer(false)
            setAcceptTransfer(false)
          }}
          // onCancel={() => {
          //   setConfirmTransfer(false)
          //   setAcceptTransfer(false)
          //   setTransferDetail(false)
          //   setDetailReceive({})
          //   setDataDetailTransfer({})
          //   setTransferStallAdmin(false)
          //   dispatch(closeModal())
          //   setFindIdcardData("")
          // }}
          textBtnConfirm="อนุมัติ"
          modalScroll
          component={
            <>
              <Styles.ContainerModal>
                <Divider className="mx-0 my-2" />
                <Row>
                  <Col lg={12} xs={12}>
                    <Styles.DetailModal>
                      {t("TRANSFER_STALL.MODAL.DETAIL")}
                    </Styles.DetailModal>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.PETITION")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>{dataDetaiiTransfer?.transferNo}</Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.NAME_SUBNAME")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={4} xs={6}>
                    <Styles.SubDetail>{dataDetaiiTransfer?.customerSent?.firstname} {dataDetaiiTransfer?.customerSent?.lastname}</Styles.SubDetail>
                  </Col>

                </Row>
                <Row className="mt-2">
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.APPLICATION_DATE")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>{dateToView(dataDetaiiTransfer?.leaseAgreementDate)}</Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.RENT_DATE")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>{dateToView(dataDetaiiTransfer?.leaseAgreementDateStart)}</Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.EXPIRE_DATE")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={6}>
                    <Styles.SubDetail>{dateToView(dataDetaiiTransfer?.leaseAgreementDateEnd)}</Styles.SubDetail>
                  </Col>
                  <Col lg={2} xs={2} className="d-xs-hidden" />
                  <Col lg={2} xs={2} className="d-xs-hidden" />
                </Row>
                <Row className="mt-2">
                  <Col lg={3} xs={3}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.ZONE")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={3} xs={3}>
                    <Styles.SubDetail>{dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</Styles.SubDetail>
                  </Col>
                  <Col lg={3} xs={3}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.STALL")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={3} xs={3}>
                    <Styles.SubDetail>{dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}</Styles.SubDetail>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.ZONE_TYPE")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={3} xs={6}>
                    <Styles.SubDetail>{dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName}</Styles.SubDetail>
                  </Col>
                  <Col lg={3} xs={6}>
                    <Styles.SubDetail>
                      {t("TRANSFER_STALL.MODAL.PAYMENT_CYCLE")}
                    </Styles.SubDetail>
                  </Col>
                  <Col lg={3} xs={6}>
                    <Styles.SubDetail>{leasesTypeData && leasesTypeData?.find((item: any) => item?.leasesTypeId === dataDetaiiTransfer?.leaseAgreement?.leasesTypeId)?.leasesTypeNameTh || '-'}</Styles.SubDetail>
                  </Col>
                </Row>
                <Divider className="mx-0 my-2" />
                <Row>
                  <Col lg={12} xs={12}>
                    <Styles.DetailModal>
                      {t("TRANSFER_STALL.MODAL.HEIR_DETAIL")}
                    </Styles.DetailModal>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} xs={12}>
                    <Styles.DetailModal sx={{ color: colors.red }}>
                      {t("TRANSFER_STALL.MODAL.SUB_DETAIL")}
                    </Styles.DetailModal>
                  </Col>
                </Row>
                {/* {_.map(customerHeirData, (d: any, i: number) => {
                  
                  return (
                    <CheckHeir
                    title={t("TRANSFER_STALL.MODAL.CUSTOMER_HEIR") + (i+1)}
                    value={i === 0 ? firstHeir : i === 1 ? secondHeir : thirdHeir}
                    // disable={firstHeir}
                    onChange={() => {
                      if(i === 0){
                        setFirstHeir(!firstHeir)
                      }else if(i === 1) {
                        setSecondHeir(!secondHeir)
                      } else {setThirdHeir(!thirdHeir)}
                      setCustomerHierId(d?.customerHierId)
                    }}
                    img={imgs.errNoImg}
                    heirName={d?.firstname + " " + d?.lastname}
                    birthDate={fullDate(d?.birthDay)}
                    year={d?.birthDay && moment().diff(d?.birthDay, "years")}
                    id_number={d?.idCard}
                    email={d?.email}
                    tel={d?.mobilePhone}
                    address={"เลขที่" + d?.houseNumber + " " + "หมู่" + d?.moo + " " + "หมู่บ้าน" + d?.village + " " + "ซอย" + d?.soi 
                    + " " + "ตำบล/แขวง" + d?.subDistrict?.subDistrictNameTh + " " + "อำเภอ/เขต" + d?.district?.districtNameTh
                    + " " + "จังหวัด" + d?.province?.provinceNameTh + " " + d?.zipCode
                  }
                  />
                  )
                })} */}

                <Box sx={{ marginTop: 4 }}>
                  <Styles.CheckBox style={{ backgroundColor: colors.disabledLightGray, }}>
                    โอนสิทธิให้กับบุคคลอื่น {" "} (แผงค้า{dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName} )
                  </Styles.CheckBox>
                </Box>
                <DetailReceiver
                  img={imgs.errNoImg}
                  heirName={detailRecive?.firstname + " " + detailRecive?.lastname}
                  birthDate={fullDate(detailRecive?.birthDay)}
                  year={detailRecive?.birthDay && moment().diff(detailRecive?.birthDay, "years")}
                  id_number={detailRecive?.idCard}
                  email={detailRecive?.email}
                  tel={detailRecive?.mobilePhone}
                  address={"เลขที่" + detailRecive?.houseNumber + " " + "หมู่" + detailRecive?.moo + " " + "หมู่บ้าน" + detailRecive?.village + " " + "ซอย" + detailRecive?.soi
                    + " " + "ตำบล/แขวง" + detailRecive?.subDistrict?.subDistrictNameTh + " " + "อำเภอ/เขต" + detailRecive?.district?.districtNameTh
                    + " " + "จังหวัด" + detailRecive?.province?.provinceNameTh + " " + detailRecive?.zipCode
                  }

                />
              </Styles.ContainerModal>
            </>
          }
        />
      }

      {
        paybill &&
        <ModalCustom
          title={t("PAYBILL.TITLE")}
          size="xl"
          onSubmit={() => {
            onsubmitCreateBill()
            setPayBill(false)
          }}
          onClose={() => {
            setTransferDetail(false)
            setConfirmTransfer(false)
            setAcceptTransfer(false)
            setTransferStallAdmin(false)
            setPayBill(false)
            setDetailReceive({})
            setDataDetailTransfer({})
            dispatch(closeModal())
          }}
          // onCancel={() => {
          //   setPayBill(false)
          //   setTransferDetail(false)
          //   setConfirmTransfer(false)
          //   setAcceptTransfer(false)
          //   setTransferStallAdmin(false)
          //   setDetailReceive({})
          //   setDataDetailTransfer({})
          //   dispatch(closeModal())
          // }}
          closeButton
          // textBtnCancel={t("PAYBILL.BUTTON.CANCEL")}
          textBtnConfirm={t("PAYBILL.BUTTON.SAVE")}
          component={
            <div className="pb-2">

              <Style.ContainerModal>
                <Row className="mt-lg-3">
                  <Col lg={4} xs={5}>
                    <Style.TypographyHead>
                      {t("PAYBILL.CREATE.STALL_DETAILS")}
                    </Style.TypographyHead>
                  </Col>
                </Row>

                <Row className="mt-2 mb-3">
                  <Col lg={3} xs={1}>
                    <Style.SubDetail>{t("LEASES.TITLE")}</Style.SubDetail>
                  </Col>


                  <Col className="my-2" lg={3}>
                    <AutocompleteSelect
                      options={leasesTypeData || []}
                      value={leasesTypeData
                        ? leasesTypeData?.find(
                          (data: any) => data?.leasesTypeId === dataDetaiiTransfer?.leaseAgreement?.leasesTypeId
                        )
                        : {}}
                      // onInputChange={(_, value) => {
                      //   debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                      // }}
                      placeholder={t("ประเภทผู้ใช้งาน")}
                      getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                      labelId="leasesType"
                      onChange={(_, newValue) => {
                        setLeasesTypeId(newValue?.leasesTypeId);
                      }}
                      disabled
                    />
                  </Col>

                  <Col lg={3} className="my-2">
                    <InputTextField value={dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName} disabled />
                    {/* <AutocompleteSelect
                      options={zoneOptionModal || []}
                      getOptionLabel={(option: any) => option.zoneName}
                      onChange={(e: any, val: any) => {
                        setZoneId(val?.zoneId)
                        setStallOptionModal(val?.stall)
                      }}
                      value={zoneOptionModal?.find((list: any) => list?.zoneId === zoneId) || null}
                      labelId="zone-name"
                      disabled={!checkAccept ? false : true}
                      placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
                    /> */}
                  </Col>

                  <Col lg={3} className="my-2">
                    <InputTextField value={dataDetaiiTransfer?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName} disabled />

                    {/* <AutocompleteSelect
                      options={stallOptionModal || []}
                      getOptionLabel={(option: any) => option.stallName}
                      onChange={(e: any, val: any) => {
                        setStallId(val.stallId)
                        setLeaseAgreementId(val?.leaseAgreementId)
                        getLeaseAgreementData(val?.leaseAgreementId)
                      }}
                      value={stallOptionModal?.find((list: any) => list?.stallId === stallId) || null}
                      labelId="stall-name"
                      disabled={!checkAccept ? false : true}
                      placeholder={t("PAYBILL.INPUT.SEARCH_STALL")}
                    /> */}
                  </Col>
                </Row>

                <Divider
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  className="w-100 mx-0"
                />

                <Row className="mt-2 mb-3">
                  <Col>
                    <Style.SubDetail>
                      {t("PAYBILL.CREATE.ANNOUNCEMENT_DATE")}
                    </Style.SubDetail>
                  </Col>
                </Row>

                <Row className="mt-2 mb-3">
                  <Col lg={3} xs={6} className="mt-3">
                    <Style.SubDetail>
                      {t("วันชำระหนี้")}
                    </Style.SubDetail>
                  </Col>

                  <Col className="my-2">
                    <InputDatePicker
                      dateFormat="DD/MM/YY"
                      key={"date-appointment"}
                      label="วัน"
                      value={appointmentDate}
                      allowClear
                      onClear={() => { }}
                      onChange={(e: any) =>
                        setAppointmentDate(moment(e).format('YYYY-MM-DD'))
                      }
                      disablePast
                    />
                  </Col>

                  <Col lg={3} xs={6} className="mt-3">
                    <Style.SubDetail>
                      {t("วันสิ้นสุดชำระหนี้")}
                    </Style.SubDetail>
                  </Col>

                  <Col className="my-2">
                    <InputDatePicker
                      dateFormat="DD/MM/YY"
                      key={"date-appointment"}
                      label="วัน"
                      value={endDateModal}
                      allowClear
                      onClear={() => { }}
                      onChange={(e: any) =>
                        setEndDateModal(moment(e).format('YYYY-MM-DD'))
                      }
                      disablePast
                    />
                  </Col>
                </Row>

                <Divider
                  sx={{ marginTop: 3, marginBottom: 3 }}
                  className="w-100 mx-0"
                />

                <Row className="my-2 mt-2 mb-3">

                  <Col lg={12} xs={12} md={12}>
                    <Style.ContentHeader1>{t("PAYBILL.CREATE.OTHER_PAYMENT")}
                      <FontAwesomeIcon icon={faCirclePlus}
                        style={{ color: colors.themeMainColor, marginRight: 8, marginTop: 8, cursor: 'pointer' }} onClick={() => handleServiceAdd()} />
                    </Style.ContentHeader1>
                  </Col>
                </Row>

                {services.map((service, index: any) => (
                  <div key={index}>

                    <Row>
                      <Col className="d-flex flex-row mt-2" lg={6} md={12} xs={12}>
                        <img src={icons.iconTrash} onClick={() => handleServiceDelete(index)} style={{ cursor: 'pointer' }} />
                        <Style.SubDetail className="mr-2 ml-2">{index + 1}</Style.SubDetail>
                        <Style.SubDetail className="mr-2 ">{t("PAYBILL.REPORT.PAYMENT_LIST")}</Style.SubDetail>
                        <div className="w-100">
                          <AutocompleteSelect
                            options={debtTypeData}
                            getOptionLabel={(option) => option.paymentName || option.paymentNameEn}
                            onChange={(e: any, value: any) =>
                              handleServiceUpdate(value?.paymentServiceId, index, 'paymentServiceId')}
                            value={debtTypeData.find((d) => d.paymentServiceId === Number(service?.paymentServiceId)) || null}
                            labelId={`label-${index}`}
                          />
                        </div>


                      </Col>
                      <Col className="d-flex flex-row mt-2" lg={6} md={12} xs={12}>

                        <>
                          <Style.SubDetail className="mx-3">{t("PAYBILL.REPORT.AMOUNT")}</Style.SubDetail>
                          <InputTextField
                            label=""
                            style={{ width: "100%" }}
                            onchange={(e: any) => handleServiceUpdate(e, index, 'amount')}
                            value={service?.amount}
                          />
                          <Style.SubDetail className="ml-2">{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                        </>

                      </Col>
                    </Row>

                    <>
                      <Row>
                        <Style.BoxOtherPayment>
                          <Col className="d-flex flex-row">
                            <Style.SubDetail className="ml-3 mr-3">{t("PAYBILL.REPORT.DETAIL")}</Style.SubDetail>
                            <InputTextField
                              label=""
                              style={{ width: "100%" }}
                              onchange={(e: any) => handleServiceUpdate(e, index, 'description')}
                              value={service?.description}
                            />
                          </Col>
                        </Style.BoxOtherPayment>
                      </Row>
                    </>

                  </div>
                ))}

                {disCountData.length > 0 && <Row className="my-2 mt-2 mb-3">
                  <Col lg={12} xs={12} md={12}>
                    <Style.ContentHeader1>{t("PAYBILL.CREATE.DISCOUNT")} </Style.ContentHeader1>
                  </Col>
                </Row>}

                {disCountData[0]?.discountPrice?.map((d: any) => {
                  return (
                    <Row className="my-2">
                      <Col xs={3} >
                        <Style.SubDetail>
                          {debtTypeData?.find(
                            (item: any) =>
                              item?.paymentServiceId === d.paymentServiceId
                          )?.paymentName
                          }
                        </Style.SubDetail>
                      </Col>
                      <Col xs={4}>
                        <Style.SubDetail>
                          {d?.amount}
                        </Style.SubDetail>
                      </Col>
                      <Col xs={4}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                      </Col>
                    </Row>
                  )
                })}


              </Style.ContainerModal >
            </div >
          }
        />
      }
      {/* 
      <div className="print-show" ref={printRef}>
        <ExportData
          headCells={""}
          componant={
            <>
              {typePrint === "PRINT" && dataForPrinting.map((item: any, i: number) => {
                return (
                  <Styles.ContainerModal>
                    <div className="row">
                      <div className="col-12">
                        <h5>
                          {t("TRANSFER_STALL.MODAL.DETAIL")}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <span>
                          {t("TRANSFER_STALL.MODAL.PETITION")}
                        </span>
                      </div>
                      <div className="col-2">
                        <span>{printData?.leaseAgreementNo || "-"}</span>
                      </div>
                      <div className="col-2">
                        <span>
                          {t("TRANSFER_STALL.MODAL.NAME_SUBNAME")}
                        </span>
                      </div>
                      <div className="col-4">
                        <span>{printData?.firstname} {printData?.lastname}</span>
                      </div>

                    </div>
                    <div className="row mt-2">
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.APPLICATION_DATE")}
                        </span>
                      </div>
                      <div className="col-2">
                        <span>{dateToView(printData?.leaseAgreementDate)}</span>
                      </div>
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.RENT_DATE")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span>{dateToView(printData?.leaseAgreementDateStart)}</span>
                      </div>
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.EXPIRE_DATE")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span>{dateToView(printData?.leaseAgreementDateEnd)}</span>
                      </div>
                      <Col lg={2} xs={2} className="d-xs-hidden" />
                      <Col lg={2} xs={2} className="d-xs-hidden" />
                    </div>
                    <div className="row mt-2">
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.ZONE")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span>{t("โซน 11")}</span>
                      </div>
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.STALL")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span>{t("a11")}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.ZONE_TYPE")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span>{t("อาหารแห้ง")}</span>
                      </div>
                      <div className="col-3">
                        <span>
                          {t("TRANSFER_STALL.MODAL.PAYMENT_CYCLE")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span>{t("รายเดือน")}</span>
                      </div>
                    </div>
                    <Divider className="mx-0 my-2" />
                    <div className="row mt-2">
                      <div className="col-12">
                        <Styles.DetailModal>
                          {t("TRANSFER_STALL.MODAL.HEIR_DETAIL")}
                        </Styles.DetailModal>
                      </div>
                    </div>

                    {customerHeirData && customerHeirData.filter((item: any) => item.customerHierId === printData)
                      .map((d: any) => {
                        return (
                          <>

                          </>
                        )
                      })}
                  

                  </Styles.ContainerModal>
                )
              })
              }
            </>
          }
        />
      </div> */}
      <div className="print-show" ref={printRef}>
        <ExportData
          headCells={""}
          componant={

            <>
              {typePrint === "PRINT" && detailCustomerSend && detailCustomerReceive && <div>

                {/* {DataForPrinting.map((d: any) => {
                      return (
                        <>
                        <div className="row mt-lg-3">
                      <div className="col-12">
                        <h5>{t('CANCELCONTRACT.POPUP.DETAIL')}</h5>
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_NO')}</span>
                        <span>{d?.cancelAgreementNo}</span>
                      </div>

                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.NAME')}</span>
                        <span className="mr-2">{d?.leaseAgreement?.firstname}</span>
                        <span>{d?.leaseAgreement?.lastname}</span>
                      </div>

                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_DATE')}</span>
                        <span>{dateToView(d?.cancelAgreementDate)}</span>
                      </div>
                    </div>

                    <div className="mt-2 row">
                      <div className="d-flex flex-row col-4">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.RENT_DATE')}</span>
                        <span>{dateToView(d?.leaseAgreement?.leaseAgreementDateStart)}</span>
                      </div>

                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.CONTRACT_END_DATE')}</span>
                        <span style={{color: 'red'}}>{dateToView(d?.leaseAgreement?.leaseAgreementDateEnd)}</span>
                      </div>
                    </div>

                    <div className="mt-3 row">
                      <div className="d-flex flex-row col-6">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.ZONE')}</span>
                        <div className="col-3" />
                        <span>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</span>
                      </div>

                      <div className="d-flex flex-row col-6">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.STALL')}</span>
                        <div className="col-3" />
                        <span>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}</span>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="d-flex flex-row col-6">
                        <span className="mr-lg-5">{t('CANCELCONTRACT.POPUP.TYPEZONE')}</span>
                        <span>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName}</span>
                      </div>

                      <div className="d-flex flex-row col-6">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.PAYMENT_CYCLE')}</span>
                        <span>{t('รายเดือน')}</span>
                      </div>
                    </div>
                        </>
                      )
                    })} */}
                {dataForPrinting.map((d: any) => {
                  return (
                    <div className="px-5 print-pdf-sarabun" style={{ pageBreakAfter: 'always', fontSize: '12px' }}>
                      <div className="row" style={{ margin: '20px 0px' }}>
                        <div className="row">
                          <div className="col-12 d-flex flex-column align-items-end" style={{ lineHeight: "10px" }}>
                            <p>ทำที่....................................................</p>
                            <p>วันที่............เดือน.........................พ.ศ.............</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 d-flex flex-column align-items-start" style={{ lineHeight: "10px" }}>
                            <p>เรื่อง การขอโอนสิทธิการเช่าสถานที่ประกอบการค้า</p>
                            <p>เรียน ผู้อำนวยการองค์การตลาดเพื่อเกษตรกร</p>
                          </div>
                        </div>
                        <div className="col-12 mt-1">
                          <p className="text-center" style={{ fontSize: "14px", fontWeight: "500" }}>
                            ผู้ขอโอนสิทธิ
                          </p>
                        </div>
                        <div className="row mt-2">
                          <div className="d-flex flex-row col-7">
                            <p className="ml-3">ข้าพเจ้า</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerSend?.firstname} {detailCustomerSend?.lastname}</p>
                          </div>

                          <div className="col-3 d-flex flex-row">
                            <p className="text-nowrap">อยู่บ้านเลขที่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerSend?.houseNumber || ""}</p>
                          </div>
                          <div className="col-2 d-flex flex-row">
                            <p className="text-nowrap">หมู่ที่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerSend?.moo || ""}</p>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">ตรอก/ซอย</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerSend?.soi || ""}</p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">ถนน</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerSend?.road || ""}</p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">แขวง/ตำบล</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {detailCustomerSend?.district?.districtNameTh || ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">เขต/อำเภอ</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {detailCustomerSend?.subDistrict?.subDistrictNameTh || "-"}
                            </p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">จังหวัด</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerSend?.province?.provinceNameTh || "-"}</p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">โทรศัพท์</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', overflow: 'hidden' }}>
                              {detailCustomerSend?.mobilePhone || ""}
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-5 d-flex flex-row">
                            <p className="text-nowrap">เป็นผู้ประกอบการค้าตลาด</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {branchData?.length > 0 ? branchData?.find((x: any) => x.branchId === d?.leaseAgreement?.branchId)?.branchNameTh : "-"}
                            </p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">ประเภทสินค้า</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName || ""}
                            </p>
                          </div>
                          <div className="col-3 d-flex flex-row">
                            <p className="text-nowrap">อัตราค่าเช่าวันละ</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 10, width: '100%' }}>
                              {`${d?.leaseAgreement?.leaseAgreementApprove?.bidder?.bidderAmount}` || "-"}
                            </p>
                            <p className="text-nowrap"> {'บาท'}</p>
                          </div>


                        </div>
                        <div className="row">
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">แผง/คูหาเลขที่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || ""}
                            </p>
                          </div>
                          <div className="col-8">
                            <p>มีความประสงค์จะโอนสิทธิการเช่าสถานที่ประกอบการค้าดังกล่าวข้างต้น</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5 d-flex flex-row">
                            <p className="text-nowrap">ให้แก่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {detailCustomerReceive?.firstname || ""} {detailCustomerReceive?.lastname || ""}
                            </p>
                          </div>
                          <div className="col-7">
                            <p className="text-nowrap">ซึ่งการโอนสิทธิดังกล่าวนั้นต้องได้รับอนุมัติจากองค์การตลาดเพื่อ</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-nowrap">เกษตรกรและข้าพเจ้าไม่มีภาระหนี้สินค้างชำระต่อองค์การตลาดเพื่อเกษตรกรใดๆทั้งสิ้นก่อนวันที่ได้รับอนุมัติ</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p className="ml-5 text-nowrap">จึงเรียนมาเพื่อโปรดพิจารณาอนุมัติ การขอโอนสิทธิการเช่าสถานที่ประกอบการค้าให้แก่ข้าพเจ้าด้วย</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p>จักขอบคุณยิ่ง</p>
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: "-20px" }}>
                          <div className="col-12">
                            <p className="text-right" style={{ marginRight: '65px' }}>ขอแสดงความนับถือ</p>
                          </div>
                          <div className="col-12">
                            <p className="ml-5 text-right">(ลงชื่อ)...........................................................ผู้ยื่นคำร้อง</p>
                          </div>
                        </div>
                        <div className="row">
                          <div style={{ borderBottom: "1px dotte #000" }} className="col-12">
                            <p className="text-right" style={{ marginRight: 56 }}>
                              {`(${detailCustomerSend?.firstname} ${detailCustomerSend?.lastname})`}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 mt-2">
                          <p className="text-center" style={{ fontSize: "14px", fontWeight: "500" }}>
                            ผู้รับโอนสิทธิ
                          </p>
                        </div>
                        <div className="row mt-1">
                          <div className="d-flex flex-row col-7">
                            <p className="ml-3">ข้าพเจ้า</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerReceive?.firstname} {detailCustomerReceive?.lastname}</p>
                          </div>

                          <div className="col-3 d-flex flex-row">
                            <p className="text-nowrap">อยู่บ้านเลขที่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerReceive?.houseNumber || "-"}</p>
                          </div>
                          <div className="col-2 d-flex flex-row">
                            <p className="text-nowrap">หมู่ที่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerReceive?.moo || "-"}</p>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">ตรอก/ซอย</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerReceive?.soi || "-"}</p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">ถนน</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerReceive?.road || "-"}</p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">แขวง/ตำบล</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {detailCustomerReceive?.district?.districtNameTh || ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">เขต/อำเภอ</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {detailCustomerReceive?.subDistrict?.subDistrictNameTh || ""}
                            </p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">จังหวัด</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{detailCustomerReceive?.province?.provinceNameTh || ""}</p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">โทรศัพท์</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', overflow: 'hidden' }}>
                              {detailCustomerReceive?.mobilePhone || ""}
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-12 d-flex flex-row">
                            <p className="text-nowrap">ได้รับทราบและตกลงรับโอนสิทธิการเช่าสถานที่ประกอบการค้าต่อจาก</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {detailCustomerSend?.firstname} {detailCustomerSend?.lastname}
                            </p>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-5 d-flex flex-row">
                            <p className="text-nowrap">เป็นผู้ประกอบการค้าตลาด</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {branchData?.length > 0 ? branchData?.find((x: any) => x.branchId === d?.leaseAgreement?.branchId)?.branchNameTh : "-"}
                            </p>
                          </div>
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">ประเภทสินค้า</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName || ""}
                            </p>
                          </div>
                          <div className="col-3 d-flex flex-row">
                            <p className="text-nowrap">อัตราค่าเช่าวันละ</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.bidderAmount || ""}
                            </p>
                            <p className="text-nowrap">บาท</p>
                          </div>


                        </div>
                        <div className="row">
                          <div className="col-4 d-flex flex-row">
                            <p className="text-nowrap">แผง/คูหาเลขที่</p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                              {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || ""}
                            </p>
                          </div>
                          <div className="col-8">
                            <p>ตั้งแต่วันที่องค์การตลาดเพื่อเกษตรกรได้อนุมัติเป็นต้นไป และข้าพเจ้</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-nowrap">ยินยอมชำระเงินเป็นค่าธรรมเนียมการโอนสิทธิการเช่าสถานที่ประกอบการค้าและเงินอื่นๆ ที่พึงมี ตามที่องค์การ</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-nowrap">ตลาดเพื่อเกษตรกรได้กำหนดไว้ให้เป็นที่เรียบร้อยจนเสร็จสิ้น ณ วันทำสัญญาและข้าพเจ้าจะจำหน่ายสินค้าตาม</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-nowrap">ประเภทการประกอบการค้าตามที่ได้รับโอนสิทธิในครั้งนี้ต่อไป</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p className="ml-5 text-nowrap">ข้าพเจ้ายินยอมปฏิบัติตามระเบียบ ข้อบังคับ ข้อกำหนด ประกาศ และเงื่อนไขต่างๆ ขององค์การ</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p>ตลาดเพื่อเกษตรกรที่มีอยู่ในปัจจุบันและจะมีขึ้นในอนาคตทุกประการ</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <p className="text-right" style={{ marginRight: '65px' }}>ขอแสดงความนับถือ</p>
                          </div>
                          <div className="col-12">
                            <p className="ml-5 text-right">(ลงชื่อ)...........................................................ผู้รับโอนสิทธิ</p>
                          </div>
                        </div>
                        <div className="row" >
                          <div className="col-12">
                            <p className=" text-right" style={{ marginRight: 56 }}>{`${detailCustomerReceive?.firstname} ${detailCustomerReceive?.lastname}`}</p>
                          </div>
                        </div>
                        <div className="col-12 mt-2">
                          <p className="text-left" style={{ fontSize: "14px", fontWeight: "600", textDecoration: '1px solid #000' }}>
                            หลักเกณฑ์และอัตราค่าธรรมเนียมในการโอนสิทธิ์การประกอบการค้าและการเช่าในสถานที่ขององค์การ
                            ตลาดเพื่อเกษตรกร
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            1.ผู้โอนสิทธิ ต้องเป็นผู้ประกอบการค้าในทะเบียนของ กบต. องค์การตลาดเพื่อเกษตรกร
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            2.ผู้โอนสิทธิ ต้องไม่มีหนี้สินค้างชำระ หรือค่าทดแทนอื่นใดทั้งสิ้น
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            3.ผู้โอนสิทธิ ต้องยื่นคำร้องตามแบบฟอร์มที่ผู้ให้เช่ากำหนด
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            4.ผู้โอนสิทธิ ต้องเป็นผู้ประกอบการค้าประเภทที่ กบต. องค์การตลาดเพื่อเกษตรกร ระบุให้มีการโอนสิทธิได้ในสัญญาเช่าสถานที่เท่านั้น
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            5.ผู้โอนสิทธิ ต้องส่งมอบสถานที่เช่าพร้อมสิ่งก่อสร้างเพิ่มเติม(ถ้ามี) ที่อยู่ในสภาพดีตามปกติแก่ผู้ให้เช่าและผู้รับ โอนสิทธิได้รับมอบสถานที่เช่าดังกล่าว จากผู้ให้เช่าในวันที่มีการโอนสิทธิโดยถูกต้อง
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            6.ผู้รับโอนสิทธิ มีสิทธิทำสัญญาเช่าสถานที่ได้ในช่วงระยะเวลาที่เหลือของสัญญาเช่าที่ได้รับโอน
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            7.ผู้รับโอนสิทธิ ต้องชำระเงินค่าธรรมเนียมในการโอนสิทธิแก่ผู้ให้เช่า ตามระเบียบหรือประกาศที่ผู้ให้เช่ากำหนดไว้
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            8.ผู้รับโอนสิทธิ ต้องปฏิบัติตามข้อผูกพันที่มีอยู่และจะมีขึ้นในอนาคตตามที่องค์การตลาดเพื่อเกษตรกรได้กำหนดไว้
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            9.การโอนสิทธิ จะสมบูรณ์ต่อเมื่อได้รับอนุมัติจากผู้อำนวยการเป็นที่เรียบร้อยแล้ว
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            10.การโอนสิทธิ กระทำโดยความสมัครใจทั้งผู้โอนและผู้รับโอน ฉะนั้น กบต. องค์การตลาดเพื่อเกษตรกรจะไม่รับผิดชอบความเสียหายใดๆ อันเกิดจากการโอนสิทธิของผู้ประกอบการค้า
                          </p>
                        </div>
                        <div className="col-12 ">
                          <p className="text-left">
                            11.กบต. จะไม่รับผิดชอบความเสียหายในทรัพย์สินใดๆ ของผู้โอนสิทธิหลังจากที่ผู้โอนสิทธิได้โอนสิทธิแก่ผู้รับโอนสิทธิแล้ว
                          </p>
                        </div>

                      </div>
                    </div>
                  )
                })}

              </div>}
            </>
          }
        />
      </div>

      {loading && <Loading show={loading} type="fullLoading" />}
    </div >
  );
}

function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
