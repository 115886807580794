
/** STYLE, CONSTANT */
import { useTranslation } from 'react-i18next';
import * as Style from '../../store/report-stall/stallReport.style'
import { Divider, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { imgs } from '../../../constants/images';
import { useEffect, useRef, useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderLabel from '../../../component/cardCustom/headerLabel';
import { routeName } from '../../../routes/routers-name';
import { dateTimeToView, fullDate } from '../../../utils/date.utils';
import { useLocation } from 'react-router-dom';
import UserApi from '../../../api/auth/users.api';
import moment from 'moment';
import ProvincesApi from '../../../api/setting/provinces/provinces.api';

export default function RenterDetail () {
    const imgIdCardRef: any = useRef();
    const { t }: any = useTranslation();
    const params: any = useLocation();
    const state = params.state.params;

    const [detailUser, setDetailuser] = useState<any>();
    const [provinceData, getProvinceData] = useState<any []>([])
    const [districtsData, getDistrictsData] = useState<any []>([])
    const [subDistrictsData, getSubDistrictsData] = useState<any []>([])
    
    const onChangeIdCardImg = (event: any) => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();
    
        formData.append("file", fileUploaded);
      };

      const getUser = async () => {
        const condition = {
          id: state?.userId,
        };
        const res = await UserApi.getDetail(condition);
        setDetailuser(res.data)
      };
      useEffect(() => {
        if(state.userId){
            getUser();
        }
      }, []);

      const getProvince = async () => {
    
        const res = await ProvincesApi.findAll()
        getProvinceData(res.data)
      }
    
      useEffect(() => {
        getProvince()
      },[])
    
      const getDistricts = async () => {
        getDistrictsData([])
        const condition : any = {
          provinceId: Number(detailUser?.provinceId)
        }
            const res = await ProvincesApi.districts(condition)
            if(res.status === 200) {
              getDistrictsData(res.data)
            }else {
              getDistrictsData([])
            }
      }
    
      const getSubDistricts = async () => {
        getSubDistrictsData([])
        const condition : any = {
          districtId: Number(detailUser?.districtId)
        }
                const res = await ProvincesApi.subDistricts(condition)
            if(res.status === 200) {
              getSubDistrictsData(res.data)
            }else {
              getSubDistrictsData([])
            }
        }
    
      useEffect(() => {
        getDistricts();
      }, [detailUser?.provinceId]); 
      
      useEffect(() => {
        getSubDistricts();
      }, [detailUser?.districtId]); 

    return (
        <>
        {/* <HeaderCard text={t('RENTER_DETAIL.HEADER')}/> */}
        <HeaderLabel
          text={t("REQUESTER_DETAIL.HEADER")}
          navigator={{ previousTo: routeName.requestForStall, previousTitle: t("REQUEST_STALL.TITlE"), currentTitle:  t("REQUESTER_DETAIL.HEADER")}}
          goBack={routeName.requestForStall}
          noAction
        />
        <Style.MainContainer>
        <div>
          <Row>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.DOC_DATE") +
                  " " +
                  dateTimeToView(state.createDate)}
              </Style.Detail>
            </Col>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.REQ_NO") + " " + state.bidNo}
              </Style.Detail>
            </Col>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.ZONE") + " " + state.zoneName}
              </Style.Detail>
            </Col>
            <Col lg={3}>
              <Style.Detail>
                {t("REQUESTER_DETAIL.ROW.STALL") + " " + state.stallName}
              </Style.Detail>
            </Col>
          </Row>
        </div>

        <Style.InformationContainer>
            <Style.BoxInfo>
                
            {/* <Col lg={4} className='d-flex justify-content-center'>
                <Style.PicRenter src={imgs.default_Avatar} alt="" />
            </Col> */}
            <Col lg={12}>
                <Row className='m-auto'>
                    <Col lg={3}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.NAME')}</Style.HeadText>
                    </Col>
                    <Col lg={5}>
                    <Style.Detail>{detailUser?.firstname || "-"} {detailUser?.lastname || "-"}</Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={3}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.BIRTH_DAY')}</Style.HeadText>
                    </Col>
                    <Col lg={5}>
                    <Style.Detail>{fullDate(detailUser?.birthDay) || "-"}</Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.AGE')}</Style.HeadText>
                    </Col>
                    <Col lg={1}>
                    <Style.Detail>{detailUser?.birthDay && moment().diff(detailUser?.birthDay, "years") || "-"}</Style.Detail>
                    </Col>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.YEAR')}</Style.HeadText>
                    </Col>
                    <Col lg={2}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.ID_CARD')}</Style.HeadText>
                    </Col>
                    <Col lg={5}>
                    <Style.Detail>{detailUser?.idCard || "-"}</Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={2}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.TEL')}</Style.HeadText>
                    </Col>
                    <Col lg={3}>
                    <Style.Detail>{detailUser?.mobilePhone || "-"}</Style.Detail>
                    </Col>
                    <Col lg={2}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.EMAIL')}</Style.HeadText>
                    </Col>
                    <Col lg={3}>
                    <Style.Detail>{detailUser?.email || "-"}</Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={3}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.TAX_ID')}</Style.HeadText>
                    </Col>
                    <Col lg={5}>
                    <Style.Detail>{detailUser?.taxId || "-"}</Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.ADRESS')}</Style.HeadText>
                    </Col>
                    <Col lg={5}>
                    <Style.Detail>{detailUser?.houseNumber + " " || "-"} { t('RENTER_DETAIL.HEAD_TEXT.MOO')} {detailUser?.moo || "-"}</Style.Detail>
                    </Col>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.TROK')}</Style.HeadText>
                    </Col>
                    <Col lg={3}>
                    <Style.Detail>{detailUser?.soi || "-"} </Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.ROAD')}</Style.HeadText>
                    </Col>
                    <Col lg={2}>
                    <Style.Detail>{detailUser?.road || "-"}</Style.Detail>
                    </Col>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.PROVINCE')}</Style.HeadText>
                    </Col>
                    <Col lg={2}>
                    <Style.Detail>{provinceData?.find(d => d?.provinceId === detailUser?.provinceId)?.provinceNameTh || "-"}</Style.Detail>
                    </Col>
                    <Col lg={1}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.DISTRICT')}</Style.HeadText>
                    </Col>
                    <Col lg={2}>
                    <Style.Detail>{districtsData?.find(d => d?.districtId === detailUser?.districtId)?.districtNameTh || "-"}</Style.Detail>
                    </Col>
                </Row>
                <Row className='m-auto'>
                    <Col lg={2}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.SUB_DISTRICT')}</Style.HeadText>
                    </Col>
                    <Col lg={2}>
                    <Style.Detail>{subDistrictsData?.find(d => d?.subDistrictId === detailUser?.subDistrictId)?.subDistrictNameTh || "-"}</Style.Detail>
                    </Col>
                    <Col lg={2}>
                    <Style.HeadText>{t('RENTER_DETAIL.HEAD_TEXT.ZIPCODE')}</Style.HeadText>
                    </Col>
                    <Col lg={2}>
                    <Style.Detail>{detailUser?.zipCode || "-"}</Style.Detail>
                    </Col>
                </Row>
            </Col>

            </Style.BoxInfo>

            <Style.GreenButtonInformation>
            <Style.FlexRow className="d-flex align-items-center justify-content-between">
            <Typography>{t("STALL.BUTTON.UPLOPD_ID_CARD")}</Typography>
            </Style.FlexRow>
            <FontAwesomeIcon icon={faEye || faEyeSlash} />
            </Style.GreenButtonInformation>
        
            <input
            type="file"
            name="file"
            onChange={onChangeIdCardImg}
            ref={imgIdCardRef}
            style={{ display: "none"  }}
            />

            </Style.InformationContainer>

            
       
        
        </Style.MainContainer>
        </>
    )
}