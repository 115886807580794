import Cookies from "universal-cookie";
import { menuFirsts, menuSeconds, menuThirds } from "../constants/menu";
import { STORAGE_KEY } from "./enum";
import _, { debounce } from "lodash";
import { decode } from "js-base64";

export const getToken = () => localStorage.getItem("TOKEN");

export const setToken = (value: string) => localStorage.setItem("TOKEN", value);

export const getRefreshToken = () => localStorage.getItem("REFRESH_TOKEN");

export const setRefreshToken = (value: string) => localStorage.setItem("REFRESH_TOKEN", value);

export const setOTPType = (value: any) => localStorage.setItem("OTP", value);

export const getOTPType = () => localStorage.getItem("OTP");

export const setProfile = (value: any) =>
  localStorage.setItem("PROFILE", JSON.stringify(value));

export const getProfile = (): any | IProfile => localStorage.getItem("PROFILE");

export const getPermissionMenus = () => getPermissions();

export const getPermissionActiveMenu = () =>
  _.get(getPermissions(), "activeMenu");

export const setBranch = (value: any) => localStorage.setItem("BRANCH", value);

export const getBranch = () => localStorage.getItem("BRANCH");

export const setApprove = (value: string) => localStorage.setItem("AP", value);

export const getApprove = () => localStorage.getItem("AP");

export const geteMenuNameNav = () => {
  const menu: any = localStorage.getItem("PERMISSION");
  let menuName = "";
  if (menu) {
    const { menuFirstKey, menuSecondKey, menuThirdKey } = JSON.parse(menu);
    menuName = menuSecondKey
      ? menuSeconds[menuSecondKey].textName
      : menuThirdKey
        ? menuThirds[menuThirdKey].textName
        : menuFirsts[menuFirstKey].textName;
  }
  return menuName;
};

export const logout = (): void => {
  localStorage.removeItem("BRANCH_ID");
  localStorage.removeItem("BRANCH");
  localStorage.removeItem(STORAGE_KEY.PROFILE);
  localStorage.removeItem(STORAGE_KEY.PERMISSION);
  localStorage.removeItem("TOKEN");
  localStorage.removeItem("REFRESH_TOKEN");
  const cookies = new Cookies();
  cookies.remove("TOKEN");
};

export const getPermissions = () => {
  const permissions: any = localStorage.getItem(STORAGE_KEY.PERMISSION);
  if (permissions) return JSON.parse(decode(permissions));

};

export const removeBranchStorage = () => {
  localStorage.removeItem("BRANCH_ID");
  localStorage.removeItem(STORAGE_KEY.PERMISSION);
};

export interface IProfile {
  userId: number;
  email: string;
  mobilePhone: string;
  roleId: number;
  firstname: string;
  lastname: string;
  roleName: string;
  customerId: number;
  empId?: any;
}

interface searchProps {
  searchItem: string;
  setItem: (value: string) => void;
}
export const debouncedApiCall = debounce((props: searchProps) => {
  props.setItem(props.searchItem);
}, 400);


export const handleTypingNumber = (even: any) => {
  if (!/[0-9.]/.test(even.key)) {
    even.preventDefault();
  }
}

