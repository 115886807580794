/** COMPONENT */
import { Card, Col, Row } from "react-bootstrap";
import FilterSelect from "../../component/select/filterSelect";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faFill,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";
import { Chart } from "primereact/chart";

/** UTIL/API */
import { numberFormat } from "../../utils/common";

/** CONSTANT */
import { colors } from "../../constants/colors";

/** STYLE */
import * as Styles from "./calculationIncome.style";
import InputDatePicker from "../../component/input/inputDatePicker";
import moment from "moment";
import InvoiceApi from "../../api/invoid/invoid.api";
import { decode } from "js-base64";
import { getProfile } from "../../utils/app.utils";
import UtilitieApi from "../../api/utilitie/utilities.api";
import { monthOnly } from "../../utils/date.utils";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import LeasesTypeApi from "../../api/setting/leases/leases.api";

export default function CalculateIncome() {
  const profile: any = JSON.parse(getProfile() || "{}");
  const { t } = useTranslation();

  const [date, setDate] = useState<any>();
  const [roleId, setRoleId] = useState<number>(
    JSON.parse(decode(profile))?.roleId
  );
  const [graphData, setGraphData] = useState<any[]>([]);

  const [chartData, setChartData] = useState<any>({});
  const [elecchartData, setElecchartData] = useState<any>({});
  const [waterchartData, setWaterchartData] = useState<any>({});
  const [stallDataUser, setStallDataUser] = useState<any>([]);
  const [elecchartDataUser, setElecchartDataUser] = useState<any>([]);
  const [waterchartDataUser, setWaterchartDataUser] = useState<any>([]);
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(Number);


  const handleGetLeasesTypeApi = async () => {
    let condition: any = {};
    await LeasesTypeApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          setLeasesTypeData(res.data)
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    handleGetLeasesTypeApi();
  }, [])

  const [lightOptions] = useState({
    plugins: {
      legend: {
        position: "none",
        labels: {
          color: "#495057",
        },
      },
    },
  });

  const percentRental =
    (Number(graphData?.map((value: any) => Number(value.rentalPay))) /
      Number(
        graphData?.map(
          (value: any) => Number(value.rentalPay) + Number(value.rentalNotPay)
        )
      )) *
    100;
  const percentWater =
    (Number(graphData?.map((value: any) => Number(value.waterPay))) /
      Number(
        graphData?.map(
          (value: any) => Number(value.waterPay) + Number(value.waterNotPay)
        )
      )) *
    100;
  const percentElectric =
    (Number(graphData?.map((value: any) => Number(value.eletrictPay))) /
      Number(
        graphData?.map(
          (value: any) =>
            Number(value.eletrictPay) + Number(value.eletrictNotPay)
        )
      )) *
    100;

  const rentalAmount: any = stallDataUser
    ?.map((item: any) =>
      item?.invoicePay?.invoicePayAmount ? parseFloat(item?.invoicePay?.invoicePayAmount) : 0

    )
    ?.reduce((acc: any, value: any) => acc + value, 0);
  const electricityAmount: any = elecchartDataUser
    ?.map((item: any) =>
      item?.utilitiesDetail?.amount
        ? parseFloat(item?.utilitiesDetail?.amount)
        : 0
    )
    ?.reduce((acc: any, value: any) => acc + value, 0);

  const waterAmount: any = waterchartDataUser
    ?.map((item: any) =>
      item.utilitiesDetail.amount ? parseFloat(item.utilitiesDetail.amount) : 0
    )
    ?.reduce((acc: any, value: any) => acc + value, 0);

  const [data, setData]: any = useState({});

  const loadData = useCallback(async () => {
    let condition: any = {
      dateStart: moment(Date.now()).startOf("year").format("YYYY-MM-DD"),
      dateEnd: moment(Date.now()).endOf("year").format("YYYY-MM-DD"),
      branchId: JSON.parse(decode(profile))?.branchId,
    };
    if (leasesTypeId) condition = { ...condition, leaseAgreementTypeId: leasesTypeId }
    const res = await InvoiceApi.graph(condition);
    const dataRental: any = [];
    const dataWater: any = [];
    const dataEletrict: any = [];
    const dataRentalPay: any = [];
    const dataWaterPay: any = [];
    const dataEletrictPay: any = [];
    let rentalPay: number = 0;
    let rentalNotPay: number = 0;
    let waterPay: number = 0;
    let waterNotPay: number = 0;
    let eletrictPay: number = 0;
    let eletrictNotPay: number = 0;

    if (res.status === 200) {
      setGraphData(res.data);
      const summary: any[] = res.data;
      if (!_.isEmpty(summary)) {
        _.map(summary, (x, i: number) => {
          eletrictNotPay = Number(x.eletrictNotPay);
          eletrictPay = Number(x.eletrictPay);
          waterPay = Number(x.waterPay);
          waterNotPay = Number(x.waterNotPay);
          rentalPay = Number(x.rentalPay);
          rentalNotPay = Number(x.rentalNotPay);
          if (!_.isEmpty(x.graph)) {
            _.map(x.graph, (z: any) => {
              dataRental.push(Number(z.rental));
              dataWater.push(Number(z.water));
              dataEletrict.push(Number(z.eletrict));
              dataRentalPay.push(Number(z.rentalPay));
              dataWaterPay.push(Number(z.waterPay));
              dataEletrictPay.push(Number(z.eletrictPay));
            });
          }
        });
      }
    }
    setData({
      labels: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "June",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: t("CALCULATION.PIE_CHART.HEADER.STALL"),
          data: dataRentalPay,
          fill: true,
          backgroundColor: colors.yellow,
          borderRadius: 10,
        },
        {
          label: t("ค้างชำระค่าเช่าแผง"),
          data: dataRental,
          fill: true,
          backgroundColor: colors.red,
          borderRadius: 10,
        },
        {
          label: t("CALCULATION.PIE_CHART.HEADER.WATER"),
          data: dataWaterPay,
          fill: true,
          backgroundColor: colors.blue,
          borderRadius: 10,
        },
        {
          label: t("ค้างชำระค่าน้ำ"),
          data: dataWater,
          fill: false,
          backgroundColor: colors.sky,
          borderRadius: 10,
        },
        {
          label: t("CALCULATION.PIE_CHART.HEADER.ELECTRICT"),
          data: dataEletrictPay,
          fill: true,
          backgroundColor: colors.darkOrange,
          borderRadius: 10,
        },
        {
          label: t("ค้างชำระค่าไฟ"),
          data: dataEletrict,
          fill: false,
          backgroundColor: colors.orange,
          borderRadius: 10,
        },

      ],
    });
    setElecchartData({
      labels: ["ชำระแล้ว", "ค้างชำระ"],
      datasets: [
        {
          data: [eletrictPay, eletrictNotPay],
          backgroundColor: [colors.blue, colors.disabledGray],
          hoverBackgroundColor: [colors.blue, colors.disabledGray],
        },
      ],
    });
    setWaterchartData({
      labels: ["ชำระแล้ว", "ค้างชำระ"],
      datasets: [
        {
          data: [waterPay, waterNotPay],
          backgroundColor: [colors.blue, colors.disabledGray],
          hoverBackgroundColor: [colors.blue, colors.disabledGray],
        },
      ],
    });
    setChartData({
      labels: ["ชำระแล้ว", "ค้างชำระ"],
      datasets: [
        {
          data: [rentalPay, rentalNotPay],
          backgroundColor: ["#FFCE56", colors.disabledGray],
          hoverBackgroundColor: ["#FFCE56", colors.disabledGray],
        },
      ],
    });
  }, [leasesTypeId]);



  const [dataUser, setDataUser]: any = useState({});

  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId !== 4) {
      loadData();
    }
  }, [date, leasesTypeId]);

  const loadElectricityData = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      utilitiesTypeId: 1,
      userId: JSON.parse(decode(profile))?.userId,
    };
    const res = await UtilitieApi.findAll(condition);
    if (res.status === 200) {
      setElecchartDataUser(res.data);
    } else {
      setElecchartData([]);
    }
  }, []);

  const loadWater = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      utilitiesTypeId: 2,
      userId: JSON.parse(decode(profile))?.userId,
    };

    const res = await UtilitieApi.findAll(condition);
    if (res.status === 200) {
      setWaterchartDataUser(res.data);
    } else {
      setWaterchartDataUser([]);
    }
  }, []);

  const loadStall = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      userId: JSON.parse(decode(profile))?.userId,
    };
    const res = await InvoiceApi.findAll(condition);
    console.log(res);
    if (res.status === 200) {
      setStallDataUser(res.data);
    } else {
      setStallDataUser([]);
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId === 4) {
      loadElectricityData();
      loadWater();
      loadStall();
    }
  }, []);

  useEffect(() => {
    setDataUser({
      labels: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "June",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        // {
        //   label: t("CALCULATION.PIE_CHART.HEADER.STALL"),
        //   data: Array.from({ length: 12 }, (_, i) => {
        //     const matchingData = elecchartDataUser?.find(
        //       (d: any) => d?.utilitiesMonth === i + 1
        //     );
        //     return matchingData
        //       ? Number(matchingData?.utilitiesDetail?.amount)
        //       : 0;
        //   }),
        //   fill: true,
        //   backgroundColor: colors.yellow,
        //   borderRadius: 10,
        // },
        {
          label: t("CALCULATION.PIE_CHART.HEADER.ELECTRICT"),
          data: Array.from({ length: 12 }, (_, i) => {
            const matchingData = elecchartDataUser?.find(
              (d: any) => d?.utilitiesMonth === i + 1
            );
            return matchingData
              ? Number(matchingData?.utilitiesDetail?.amount)
              : 0;
          }),
          fill: true,
          backgroundColor: colors.themeMainColor,
          borderRadius: 10,
        },
        {
          label: t("CALCULATION.PIE_CHART.HEADER.WATER"),
          data: Array.from({ length: 12 }, (_, i) => {
            const matchingData = waterchartDataUser?.find(
              (d: any) => d?.utilitiesMonth === i + 1
            );
            return matchingData
              ? Number(matchingData?.utilitiesDetail?.amount)
              : 0;
          }),
          fill: true,
          backgroundColor: colors.blue,
          borderRadius: 10,
        },
      ],
    });
  }, [elecchartDataUser, waterchartDataUser]);

  const options = {
    plugins: {
      responsive: true,
      title: {
        display: true,
        text: t("CALCULATION.HEADER.PAY_DETAIL"),
        font: {
          size: 16,
        },
      },
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: colors.lightGray,
        },
        ticks: {
          color: colors.textExtraLightGray,
          fontSize: 16,
          // stepSize: 000,
          borderRadius: 8,
          callback: function (val: any, index: number) {
            return val + "บาท";
          },
        },
      },
    },
  };

  return (
    <>
      {roleId === 4 && (
        <Styles.MainContainer>
          <Card.Body className="px-5 mb-5">
            <Card.Title>

            </Card.Title>
            <Styles.CalculationBox>
              <Row>
                <Col lg={4} xl={4} sm={6} xs={12}>
                  <Styles.SummaryBox>
                    <Styles.IconsBackground
                      sx={{ backgroundColor: colors.yellow }}
                    >
                      <FontAwesomeIcon
                        icon={faStore}
                        color={colors.white}
                        fontSize={32}
                      />
                    </Styles.IconsBackground>
                    <Styles.BoxContent>
                      <Styles.TotleText>
                        {t("CALCULATION.HEADER.PAY_STALL")}
                      </Styles.TotleText>
                      <Styles.Price>{numberFormat(rentalAmount)}</Styles.Price>

                      <div>
                        <ul>
                          {elecchartDataUser.map((d: any) => {
                            return <li>เดือน {monthOnly(d?.utilitiesMonth)}</li>;
                          })}
                        </ul>
                      </div>
                    </Styles.BoxContent>
                  </Styles.SummaryBox>
                </Col>
                <Col lg={4} xl={4} sm={6} xs={12}>
                  <Styles.SummaryBox>
                    <Styles.IconsBackground
                      sx={{ backgroundColor: colors.themeMainColor }}
                    >
                      <FontAwesomeIcon
                        icon={faBatteryThreeQuarters}
                        color={colors.white}
                        fontSize={32}
                      />
                    </Styles.IconsBackground>
                    <Styles.BoxContent>
                      <Styles.TotleText>
                        {t("CALCULATION.HEADER.ELECTRICT")}
                      </Styles.TotleText>
                      <Styles.Price>
                        {numberFormat(electricityAmount)} บาท
                      </Styles.Price>
                      <div>
                        <ul>
                          {elecchartDataUser.map((d: any) => {
                            return (
                              <li>เดือน {monthOnly(d?.utilitiesMonth)}</li>
                            );
                          })}
                        </ul>
                      </div>
                    </Styles.BoxContent>
                  </Styles.SummaryBox>
                </Col>
                <Col lg={4} xl={4} sm={6} xs={12}>
                  <Styles.SummaryBox>
                    <Styles.IconsBackground
                      sx={{ backgroundColor: colors.blue }}
                    >
                      <FontAwesomeIcon
                        icon={faFill}
                        color={colors.white}
                        fontSize={32}
                      />
                    </Styles.IconsBackground>
                    <Styles.BoxContent>
                      <Styles.TotleText>
                        {t("CALCULATION.HEADER.WATER")}
                      </Styles.TotleText>
                      <Styles.Price>{numberFormat(waterAmount)} บาท</Styles.Price>
                      <div>
                        <ul>
                          {waterchartDataUser.map((d: any) => {
                            return (
                              <li>เดือน {monthOnly(d?.utilitiesMonth)}</li>
                            );
                          })}
                        </ul>
                      </div>
                    </Styles.BoxContent>
                  </Styles.SummaryBox>
                </Col>
              </Row>
            </Styles.CalculationBox>
            {/* <Row className="mt-5">
              <Col lg={3}>
                <InputDatePicker
                  required
                  key={"date"}
                  label={t("text")}
                  value={date}
                  onChange={(e: any) => setDate(moment(e).format("YYYY-MM-DD"))}
                />
              </Col>
            </Row> */}
            <Styles.ChartContainer className="mb-5">
              <Chart type="bar" data={dataUser} options={options} />
            </Styles.ChartContainer>
          </Card.Body>
        </Styles.MainContainer>
      )}
      {roleId !== 4 && (
        <Styles.MainContainer>

          <Card.Body className="px-5 mb-5">
            <Row>
              <Col lg={3} xs={6}>
                <AutocompleteSelect
                  placeholder="เลือกกลุ่มผู้ใช้งาน"
                  heading="กลุ่มผู้ใช้งาน"
                  labelId="SEARCH_DATA"
                  options={leasesTypeData || []}
                  getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                  value={leasesTypeData?.find((list: any) => list?.leasesTypeId === leasesTypeId) || null}
                  onChange={(_, value) => {
                    setLeasesTypeId(value ? value?.leasesTypeId : undefined)
                  }}
                />
              </Col>

            </Row>
            <Box className="w-100 mt-5 d-flex flex-row justify-content-between">
              <Styles.PiechartBox>
                <Styles.BoxContent>
                  <Typography>
                    {t("CALCULATION.PIE_CHART.HEADER.STALL")}
                  </Typography>
                  <br />
                  <Box className={`d-flex justify-content-center`}>
                    <Styles.PercentText style={{ color: colors.yellow }}>
                      {percentRental ? numberFormat(percentRental) || 0 : 0}%
                    </Styles.PercentText>
                  </Box>
                  <br />
                  <Box className={`d-flex flex-column`}>
                    <Box
                      className={`d-flex flex-row align-item-center justify-content-center`}
                    >
                      <Styles.LabelColor
                        style={{ backgroundColor: colors.yellow }}
                      />
                      <Styles.Label>
                        {percentRental ? numberFormat(percentRental) || 0 : 0}%{" "}
                        {t("CALCULATION.PIE_CHART.TITLE.COMPLETE_PAY")}
                      </Styles.Label>
                    </Box>
                    <Box
                      className={`d-flex flex-row align-item-center justify-content-center`}
                    >
                      <Styles.LabelColor
                        style={{ backgroundColor: colors.disabledLightGray }}
                      />
                      <Styles.Label>
                        {percentRental ? numberFormat(100 - (percentRental || 0)) : 0}%{" "}
                        {t("CALCULATION.PIE_CHART.TITLE.OVERDUE")}
                      </Styles.Label>
                    </Box>
                  </Box>
                </Styles.BoxContent>
                <Styles.PieChartContainer>
                  <Chart
                    type="doughnut"
                    data={chartData}
                    options={lightOptions}
                  />
                </Styles.PieChartContainer>
              </Styles.PiechartBox>

              <Styles.PiechartBox>
                <Styles.BoxContent>
                  <Typography>
                    {t("CALCULATION.PIE_CHART.HEADER.ELECTRICT")}
                  </Typography>
                  <br />
                  <Box className={`d-flex justify-content-center`}>
                    <Styles.PercentText
                      style={{ color: colors.themeMainColor }}
                    >
                      {percentElectric ? numberFormat(percentElectric) || 0 : 0} %
                    </Styles.PercentText>
                  </Box>
                  <br />
                  <Box className={`d-flex flex-column`}>
                    <Box
                      className={`d-flex flex-row align-item-center justify-content-center`}
                    >
                      <Styles.LabelColor
                        style={{ backgroundColor: colors.themeMainColor }}
                      />
                      <Styles.Label>
                        {percentElectric ? numberFormat(percentElectric) || 0 : 0}%{" "}
                        {t("CALCULATION.PIE_CHART.TITLE.COMPLETE_PAY")}
                      </Styles.Label>
                    </Box>
                    <Box
                      className={`d-flex flex-row align-item-center justify-content-center`}
                    >
                      <Styles.LabelColor
                        style={{ backgroundColor: colors.disabledLightGray }}
                      />
                      <Styles.Label>
                        {percentElectric ? numberFormat(100 - (percentElectric || 0)) : 0}%{" "}
                        {t("CALCULATION.PIE_CHART.TITLE.OVERDUE")}
                      </Styles.Label>
                    </Box>
                  </Box>
                </Styles.BoxContent>
                <Styles.PieChartContainer>
                  <Chart
                    type="doughnut"
                    data={elecchartData}
                    options={lightOptions}
                  />
                </Styles.PieChartContainer>
              </Styles.PiechartBox>

              <Styles.PiechartBox>
                <Styles.BoxContent>
                  <Typography>
                    {t("CALCULATION.PIE_CHART.HEADER.WATER")}
                  </Typography>
                  <br />
                  <Box className={`d-flex justify-content-center`}>
                    <Styles.PercentText style={{ color: colors.blue }}>
                      {percentWater ? numberFormat(percentWater) || 0 : 0} %
                    </Styles.PercentText>
                  </Box>
                  <br />
                  <Box className={`d-flex flex-column`}>
                    <Box
                      className={`d-flex flex-row align-item-center justify-content-center`}
                    >
                      <Styles.LabelColor
                        style={{ backgroundColor: colors.blue }}
                      />
                      <Styles.Label>
                        {percentWater ? numberFormat(percentWater) || 0 : 0}%{" "}
                        {t("CALCULATION.PIE_CHART.TITLE.COMPLETE_PAY")}
                      </Styles.Label>
                    </Box>
                    <Box
                      className={`d-flex flex-row align-item-center justify-content-center`}
                    >
                      <Styles.LabelColor
                        style={{ backgroundColor: colors.disabledLightGray }}
                      />
                      <Styles.Label>
                        {percentWater ? numberFormat(100 - (percentWater || 0)) : 0}%{" "}
                        {t("CALCULATION.PIE_CHART.TITLE.OVERDUE")}
                      </Styles.Label>
                    </Box>
                  </Box>
                </Styles.BoxContent>
                <Styles.PieChartContainer>
                  <Chart
                    type="doughnut"
                    data={waterchartData}
                    options={lightOptions}
                  />
                </Styles.PieChartContainer>
              </Styles.PiechartBox>
            </Box>

            <Styles.ChartContainer className="mb-5">
              <Chart type="bar" data={data} options={options} />
            </Styles.ChartContainer>
          </Card.Body>
        </Styles.MainContainer>
      )}
    </>
  );
}
