import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Checkbox } from '@mui/material';
import { colors } from "../../constants/colors";


export const PageContainer = styled("div")(({ theme }) => ({
    display:'flex',
    flexDirection:'column',
    height:'100vh'
}));

export const MainContainer = styled("div")(({ theme }) => ({
    height:'100%'
}));
  
export const TableContainer = styled(Box)(({theme}) => ({
      marginTop:'10px'
  
          
}));

export const ChartContainer = styled(Box)(({theme}) => ({
  border: `1px solid ${colors.disabledLightGray}`,
  borderRadius: 8,
  marginTop: 10,
  width: '100%',
  padding:'0px 16px'
  }))

  export const CheckboxContainer = styled(Box)(({theme}) => ({
    display:'flex',
    flexDirection:'row',
   
  }));

  export const TitleContainer = styled(Box)(({theme}) => ({
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
    
  }));



  
  export const Title = styled(Typography)(({theme}) => ({
    marginTop:'10px',
    fontSize: 16,
    fontWeight: 400,
    
}));



