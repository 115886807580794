import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material'

export const TypographyHead = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontFamily: 'kanit',
}))
export const TypographyDetail = styled(Typography)(({ theme }) => ({

    fontSize: '14px',
    fontFamily: 'kanit',
}))
export const TypographyDetailsum = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontFamily: 'kanit',
    fontWeight: 'bold'
}))
export const Accordions = styled(Accordion)(({ theme }) => ({
    padding: 0,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius:'4px',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))
export const AccordionDetail = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))


export const AccordionSummarys = styled(AccordionSummary)(({ theme }) => ({
    borderRadius:'4px',
    backgroundColor: 'rgba(232, 235, 240, 1)',

    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

export const NumberInTable = styled(Box)(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    width: 20,
    color: colors.white,
    borderRadius: 2
}))
export const Tab = styled(Box)(({ theme }) => ({
    display: "flex", justifyContent: "space-between", width: '100%',

    [theme.breakpoints.down('md')]: {

        display: 'none '
    }
}))
export const Boxcontainer = styled(Box)(({ theme }) => ({

    paddingLeft: '45px',
    paddingRight: '45px',

}))
export const Boxgray = styled(Box)(({ theme }) => ({

    backgroundColor: "#D9D9D9",
    borderRadius: "8px",
    padding: "24px",

}))
export const Typographymodal = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'kanit',
}))
export const ButtonInTable = styled(Button)(({ theme }) => ({
    backgroundColor: colors.green,
    borderRadius: 4,
    color: colors.white
}))

const useStyles = makeStyles({
    boxcontainer: {
        paddingLeft: '45px',
        paddingRight: '45px',
    },
    boxend: {
        display: 'flex', justifyContent: 'end'
    },

    headerannoce: {
        display: 'flex',
        backgroundColor: '#1B4BBB0D',
        paddingTop: '29px',
        paddingBottom: '29px',
        paddingLeft: '45px',
        paddingRight: '45px',
    },
    flexmarginTop: {
        display: 'flex', marginTop: '24px', marginBottom: '24px'
    },
    boxjusttify: {
        display: "flex", justifyContent: "space-between", width: '100%'
    },
    boxupload: {
        border: '1px solid #D9D9D9', borderRadius: '8px', display: 'flex', justifyContent: 'center', padding: '51px', backgroundColor: '#fff'
    },
    boxinsite: {
        border: "1px solid #D9D9D9",
        borderRadius: "5px",
        padding: "42px",
        marginTop: "27px",
    },
    boxinsitebackground: {

        backgroundColor: "#F8F8F8",
        borderRadius: "8px",
        padding: "24px",

    },
    filearray: {

        border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'

    },

    typographySuccess: {
        fontFamily: 'Kanit',
        fontSize: '16px',
        textAlign: 'left',
        marginBottom: '20px',
        color: '#707070'
    },
    flex: {
        display: 'flex',

    },

    boxgray: {
        backgroundColor: "#D9D9D9",
        borderRadius: "8px",
        padding: "24px",

    },
    flexcolumcenter: {
        display: 'flex', justifyContent: 'center', flexDirection: 'column'

    },
    flexHeader: {
        display: 'flex',
        boxShadow: '1px 2px 9px #707070',

        padding: '1em',

    },
    inputCheckBox: {
        '& $label': {
            fontFamily: 'Kanit',
            fontSize: '16px',
            fontWeight: 500
        },
        '& $span': {
            fontSize: '16px'
        }
    },
    card: {
        backgroundColor: colors.white,
        margin: 'auto',
        maxWidth: 400,
        width: '100%',
        borderRadius: `10px !important`,
        opacity: 1,
        '& .card-body': {
            backgroundColor: colors.white,
            borderRadius: 10
        },
        '&.forget-password .btn-submit': {
            lineHeight: '44px',
            marginTop: 14,
            boxShadow: 'none',
            fontSize: '1rem'
        }
    },
    tabsearch: {
        display: "flex", marginTop: '24px', marginBottom: '24px'
    },
    cardBody: {
        backgroundColor: colors.white,
        margin: 'auto',
        width: '300px',
        padding: '30px 15px'
    },
    checked: {
        color: colors.green
    },

    license: {
        padding: 20
    },
    hide: {
        marginTop: '24px', height: '250px'
    },
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        paddingBottom: '15px',
        marginTop: '25px',

    },
    carRegister: {
        border: '1px solid rgba(0,0,0,0.3)',
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
        marginTop: '15px',
        marginBottom: '35px',
        backgroundColor: colors.white,
        padding: '24px'
    },
    carInputRegister: {
        width: '100%', height: 'auto', backgroundColor: '#fafafa',
        marginTop: '15px',
        padding: '24px'
    }
})

export default useStyles
