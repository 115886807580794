import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'stall-type'

export interface StallInterface {
    stallTypeId?: number
    stallTypeNameTh?: string
    stallTypeNameEn?: string
    status: string
  }

export default class StallTypeApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(stallTypeNameTh: string, stallTypeNameEn: string) {
        return this.api.post(path , { stallTypeNameTh, stallTypeNameEn })
    }

    static findById(id: number): Promise<StallInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: StallInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
