import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box, Checkbox, Divider, Grid, MenuItem, Typography, Button, Tooltip, IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Dropdown, Row } from "react-bootstrap";
import { decode } from 'js-base64';
//**COMPONENT */
import { routeName } from "../../routes/routers-name";
import { colors } from "../../constants/colors";
import TableRowCommon from "../../component/table/TableRowCommon";
import { closeModal, showModal } from "../../app/slice/modal.slice";
import HeaderCard from "../../component/cardCustom/headerCard";
import ButtonCustom from "../../component/button/buttonCustom";
import { icons } from "../../constants/images";
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import ModalCustom from "../../component/modalCustom/modalCustom";
import FilterSelect from "../../component/select/filterSelect";
import InputDatePicker from "../../component/input/inputDatePicker";
import PopupCheckPayment from "./popup/popupCheckPayment";
import { saveAs } from 'file-saver'
import { getProfile } from "../../utils/app.utils";
import { useFunctionPrint } from "../../component/Print/print";
import { ExportProps } from "../../constants/reportConstant";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { useDropzone } from "react-dropzone";
import { dateTimeToView, dateToView, fullDate } from "../../utils/date.utils";
import moment from "moment";
import InputNewRangePicker from "../../component/input/InputRangePicker";

//**STYLE */
import * as Style from "./payMentStyle";

//** API */
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import ZoneApi from "../../api/zone/zone.api";
import InvoiceApi from "../../api/invoid/invoid.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns, faCircleInfo, faCirclePlus, faFile, faFileInvoiceDollar, faMoneyCheckDollar, faPen, faPrint, faQrcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../component/loading/loading";
import { numberFormat } from "../../utils/common";
import AnnounceApi from "../../api/home/announce.api";
import PopupPaymentBank from "../store/popup/popupPaymentBank";
import PopupPaymentStall from "../store/popup/popupPaymentStall";
import FileuploadApi from "../../api/uploadfile/upload.api";
import PaymentTypeApi from "../../api/setting/debttype/debttype.api";
import LeaseAgreementApi from "../../api/stall/leaseAgreement/leaseAgreement.api";
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Service {
  label: string;
  amountLabel: string;
  descriptionLabel: string;
}

export default function PayBill() {
  const componentApproval = useRef<HTMLDivElement>(null);
  const profile: any = JSON.parse(getProfile() || "{}");
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [role, setRole] = useState<boolean>(false);
  const [checkAccept, setCheckAccept] = useState<string>('zone');
  const [periodDate, setPeriodDate] = useState<string>("");
  const [dateApprove, setDateApprove] = useState<string>("");
  const [timeApprove, setTimeApprove] = useState<string>("");
  const [appointmentDate, setAppointmentDate] = useState<string>("");
  const [endDateModal, setEndDateModal] = useState<string>("")
  const [payQrcode, setPayQrcode] = useState<boolean>(false);
  const [payBank, setPayBank] = useState<boolean>(false);
  const [includeUtility, setIncludeUtility] = useState<boolean>(false);
  const [payment, setPayment] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any[]>([])
  const [printRequested, setPrintRequested] = useState<boolean>(false);
  const [leasesOption, setLeasesOption] = useState<any[]>([]);
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [stallOption, setStallOption] = useState<any[]>([]);
  const [zoneOptionModal, setZoneOptionModal] = useState<any[]>([]);
  const [stallOptionModal, setStallOptionModal] = useState<any[]>([]);
  const [searchZone, setSearchZone] = useState(Number);
  const [searchStall, setSearchStall] = useState(Number);
  const [zone, setZone] = useState<string>("")
  const [stallId, setStallId] = useState<string>("");
  const [imageBill, setImageBill] = useState<any | null>(null)
  const [file, setFile] = useState<any>(null);
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>(1)
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [payBillData, setpayBillData] = useState<any>();
  const [leaseAgreementId, setLeaseAgreementId] = useState<number>();
  const [leaseAgreementTypeId, setLeaseAgreementTypeId] = useState<number>();
  const [typePrint, setTypePrint] = useState<string>("")
  const [printData, setPrintData] = useState<any[]>([])
  const [qrCode, setQrCode] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const [debtTypeData, setDebtTypeData] = useState<any[]>([])
  const [disCountData, setDiscountData] = useState<any[]>([])
  const [fileCheckPayment, setFileCheckPayment] = useState<any>(null)
  const [imageCheckPayment, setImageCheckPayment] = useState<any>(null)
  const [remarkNotApprove, setRemarkNotApprove] = useState<string>("")
  const [checkRemarkNotApprove, setCheckRemarkNotApprove] = useState<boolean>(false)

  const handleChangeZone = (newZone: number) => setSearchZone(newZone);

  const handleChangeStatus = (newStatus: string) => setStatus(newStatus)

  const loadDataPaymentType = useCallback(async () => {

    let condition: any = {
      page: 1,
      pageLimit: 50,
      status: "ACTIVE"
    }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }

    const res = await PaymentTypeApi.findAll(condition)

    if (res.status === 200) {
      setDebtTypeData(res.data)

    } else {
      setDebtTypeData([])
    }
  }, [search, sortBy, sortType, status])

  useEffect(() => {
    loadDataPaymentType()
  }, [loadDataPaymentType])

  const loadDiscount = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 1,
      newstypeId: 3,
      sortBy: "endDate",
      status: "APPROVE",
      sortType: "DESC"
    }

    const res = await AnnounceApi.findAll(condition)

    if (res.status === 200) {
      if (res.data[0]?.endDate < Date.now()) {
        setDiscountData([])
      } else {
        setDiscountData(res.data)
      }

    } else {
      setDiscountData([])
    }
  }

  useEffect(() => {
    loadDiscount()
  }, []);

  const getLeaseAgreementData = async (props: number) => {
    const res = await LeaseAgreementApi.findById(props)
    if (res.status === 200) {
      setLeaseAgreementTypeId(res.data.leasesTypeId)
    } else {
      setLeaseAgreementTypeId(1)
    }
  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  // const handleRangeDate = (value: any) => {
  //   setSelectedDateRange(value);
  //   setDateStart(moment(value[0]).format("YYYY-MM-DD"));
  //   setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
  //   setPage(1)
  // };

  const handleClearRangeDate = () => {
    setSelectedDateRange([]);
    setDateStart("");
    setDateEnd("");
  }

  const getLeasesType = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "ACTIVE"
    };
    const res = await LeasesTypeApi.findAll(condition);
    if (res.status === 200) {
      setLeasesOption(res.data);
    } else {
      setLeasesOption([])
    }

  };

  useEffect(() => {
    getLeasesType();
  }, []);

  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT",
      skipcalculation: 0
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
      setZoneOptionModal(res.data)
    } else {
      setZoneOption([])
      setZoneOptionModal([])
    }
  };

  useEffect(() => {
    getZone();

  }, []);


  const loadData = useCallback(async () => {
    let condition: any = {
      sortBy: "invoiceDate",
      sortType: "DESC"
    }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (JSON.parse(decode(profile))?.roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }
    if (status !== "ALL") condition = { ...condition, status: status };
    if (searchStall) condition = { ...condition, stallId: searchStall };
    if (searchZone) condition = { ...condition, zoneId: searchZone }
    if (search !== "") condition = { ...condition, search: search }
    if (dateStart !== "") condition = { ...condition, periodDate: dateStart }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res?.headers["total"]);
      setInvoiceData(res.data)
    } else {
      setInvoiceData([])
    }
  }, [page, pageLimit, status, searchStall, searchZone, search, dateStart])

  useEffect(() => {
    loadData()
  }, [page, pageLimit, status, searchStall, searchZone, search, dateStart])

  const printById = useCallback(async (props: any) => {
    try {
      setTypePrint("GET_BY_ID")

      setLoading(true);
      const bidderPremilinary = await InvoiceApi.detail(props)
      const [testResult] = await Promise.all([
        bidderPremilinary,
      ]);

      setPrintData(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [InvoiceApi])

  const printAll = useCallback(async () => {
    try {
      setTypePrint("PRINT_ALL")

      setLoading(true);

      let condition: any = {}
      if (page) condition = { ...condition, page: page }
      if (pageLimit) condition = { ...condition, pageLimit: pageLimit }


      const res = await InvoiceApi.findAll(condition)

      const [testResult] = await Promise.all([
        res,
      ]);

      setPrintData(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [InvoiceApi])

  const handlePrintData = useFunctionPrint({
    content: () => componentApproval.current,
    pageStyle: () => `@page { size:1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  const handlePrintDataVertical = useFunctionPrint({
    content: () => componentApproval.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printData && typePrint === "GET_BY_ID") {
      handlePrintData();
    }
    if (printData && typePrint === "PRINT_ALL" && JSON.parse(decode(profile))?.roleId === 4) {
      handlePrintDataVertical();
    }
  }, [printRequested, typePrint]);

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
  }, []);

  const headCells = [
    { id: "no", disablePadding: false, label: "#", align: 'center', },
    { id: "documentDate", disablePadding: false, label: t("PAYBILL.HEADCELL.DOCUMENTDATE"), align: "center", },
    { id: "billID", disablePadding: false, label: t("งวดวันที่"), align: "center", },
    { id: "billID", disablePadding: false, label: t("PAYBILL.HEADCELL.BILLID"), align: "center", },
    { id: "zone", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.ZONE"), },
    { id: "stall", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STALL"), },
    { id: "stall", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STALL_NUMBER"), },
    // { id: "overdue", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.OVERDUE"), },
    { id: "price", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.PRICE"), },
    // { id: "price", disablePadding: false, align: "center", label: t("ค่าปรับ"), },
    { id: "invoiceDate", disablePadding: false, align: "center", label: t("ระยะเวลาการชำระหนี้"), },
    { id: "status", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STATUS"), },
    { id: "action", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.ACTION"), },];

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#" },
    { id: "documentDate", disablePadding: false, label: t("PAYBILL.HEADCELL.DOCUMENTDATE"), align: "center" },
    { id: "billID", disablePadding: false, label: t("งวดวันที่"), align: "center", },
    { id: "billID", disablePadding: false, label: t("PAYBILL.HEADCELL.BILLID"), align: "center" },
    { id: "name", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.NAME"), },
    { id: "zone", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.ZONE"), },
    { id: "stall", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STALL"), },
    { id: "stall", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STALL_NUMBER"), },
    { id: "overdue", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.OVERDUE"), },
    { id: "price", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.PRICE"), },
    { id: "status", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.STATUS"), },
    { id: "action", disablePadding: false, align: "center", label: t("PAYBILL.HEADCELL.ACTION"), },
  ];

  const viewDebtReport = async (obj: any) => {
    const res = await InvoiceApi.detail(obj.invoiceId)
    if (res.status === 200) {
      history.push(routeName.payBill + "/list-payment", {
        params: {
          data: res.data
        },
      })
    }
  }

  const onPayWithQR = async (data: any) => {
    setpayBillData(data)
    const res = await InvoiceApi.findById(data?.invoiceId)
    if (res.status === 200) {
      setQrCode(res?.data?.qrcode)
    }
    setPayQrcode(true);
    setPayBank(false);
    setRole(false);
    dispatch(showModal());
  };

  const onPayWithBank = async (data: any) => {
    setpayBillData(data)
    setPayQrcode(false);
    setPayBank(true);
    setRole(false);
    dispatch(showModal());
  };

  const ondowloadPdf = async () => {
    setLoading(true)
    const res = await InvoiceApi.bank(payBillData?.invoiceId)
    if (res.status === 200) {
      const data = res.data
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, 'แบบฟอร์มชำระเงินผ่านธนาคาร');
    }
    setLoading(false)
  }
  const onDowloadReceipt = async (invoiceId: number) => {
    setLoading(true)
    const res = await InvoiceApi.receipt(invoiceId)
    if (res.status === 200) {
      const data = res.data
      const blob = new Blob([data], { type: 'application/pdf' });
      saveAs(blob, roleId === 4 ? 'ใบเสร็จ' : 'สำเนาใบเสร็จ');
    }
    setLoading(false)
  }

  const CreatePayBill = () => {
    setRole(true);
    setPayment(false);
    dispatch(showModal());
  };

  const CheckPayment = async (data: any) => {
    setpayBillData(data)
    setPayQrcode(false);
    setRole(true);
    setPayment(true);
    dispatch(showModal());
    await setImageCheckPayment(process.env.REACT_APP_FILE_URL + data?.invoicePay?.fileUpload?.filePath + data?.invoicePay?.fileUpload?.fileName)
  };

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { status, invoiceDateEnd, periodDate, leaseAgreement, createDate, invoiceNo, stall, invoiceDetail, invoiceDateStart, payment, receipt } =
      objData;
    const sumAll = invoiceDetail.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
    const setState =
      status === "ACTIVE"
        ? t("STATUS.CHECK")
        : status === "WAITING"
          ? t("STATUS.WAITING")
          : status === "NOT_PAY"
            ? t("STATUS.OVERDUE") :
            status === "PROGRESS" ? t("STATUS.PROGRESS") :
              status === "NOT_APPROVE" ? "ไม่อนุมัติ" :
                status === "PRO_SECUTE" ? t("STATUS.PRO_SECUTE") :
                  status === "PAY_APPROVE" ? t("STATUS.PAY")
                    : t("STATUS.PROGRESS")

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(createDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(periodDate) || "-" },
        { option: "TEXT", align: "center", label: invoiceNo || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallNumber || "-" },
        // { option: "TEXT", align: "center", label: dateToView(invoiceDateEnd), style: { color: colors.red }, },
        // { option: "TEXT", align: "center", label: numberFormat(sumAll) || "0" },
        { option: "TEXT", align: "center", label: numberFormat(sumAll) || "0" },
        { option: "TEXT", align: "center", label: `${dateToView(invoiceDateStart)} - ${dateToView(invoiceDateEnd)}` || "-" },
        {
          option: "COMPONENT", align: "center", label: status,
          component: (

            <Typography
              style={{
                color:
                  status === "ACTIVE"
                    ? colors.lightOrange
                    : status === "NOT_PAY"
                      ? colors.red : status === "PROGRESS" ?
                        colors.yellow : status === "WAITING" ?
                          colors.warning : status === "PRO_SECUTE" ? colors.danger :
                            status === "NOT_APPROVE" ? colors.danger :
                              status === "PAY_APPROVE" ? colors.green
                                : colors.yellow,
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {setState}
            </Typography>
            // <Button
            //   style={{
            //     backgroundColor:
            //       status === "ACTIVE"
            //         ? colors.lightOrange
            //         : status === "NOT_PAY"
            //           ? colors.red : status === "PROGRESS" ?
            //             colors.yellow : status === "WAITING" ?
            //               colors.warning : status === "PRO_SECUTE" ? colors.danger :
            //                 status === "NOT_APPROVE" ? colors.danger
            //                   : colors.green,
            //     color: colors.white,
            //     margin: "auto",
            //     borderRadius: "4px",
            //     width: "100%",
            //     height: "36px",
            //     whiteSpace: 'nowrap',
            //   }}
            // >
            //   {setState}
            // </Button>
          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="รายละเอียด">
                <IconButton disabled={!(status === "PAY" || status === "PAY_APPROVE" || status === "PROGRESS")} onClick={() => {
                  CheckPayment(objData)
                  // getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: (status === "PAY" || status === "PAY_APPROVE" || status === "PROGRESS") ? colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{
                    cursor: "pointer"
                    , color: (status === "PAY" || status === "PROGRESS") ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "PAY" || status === "PROGRESS") {
                      CheckPayment(objData)
                    }
                  }}
                />
              </div> */}
              <Tooltip title="ชำระเงิน">
                <IconButton disabled={!(status === "NOT_PAY" || status === "WAITING")} onClick={() => {
                  onPayWithQR(objData)
                  // getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faQrcode}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: (status === "NOT_PAY" || status === "WAITING") ? colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faQrcode}
                  style={{
                    cursor: "pointer"
                    , color: status === "NOT_PAY" || status === "WAITING" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "NOT_PAY" || status === "WAITING") {
                      onPayWithQR(objData)
                    }
                  }}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  style={{
                    cursor: "pointer"
                    , color: status === "NOT_PAY" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "NOT_PAY") {
                      onPayWithBank(objData)
                    }
                  }}
                />
              </div> */}
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  viewDebtReport(objData)
                  // getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => viewDebtReport(objData)}
                />
              </div> */}
              {status === "PAY_APPROVE" &&
                <Tooltip title="ใบเสร็จ">
                  <IconButton onClick={() => {
                    onDowloadReceipt(objData.invoiceId)
                    // getTestPdfData(utilitiesId)
                  }}>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      style={{
                        fontSize: "20px",
                        width: "22px",
                        color: colors.themeMainColor
                      }}
                    />
                  </IconButton>
                </Tooltip>
                // <div className="pr-3">
                //   <FontAwesomeIcon
                //     icon={faFileInvoiceDollar}
                //     style={{
                //       cursor: "pointer"
                //       , color: colors.themeMainColor
                //     }}
                //     onClick={() => onDowloadReceipt(objData.invoiceId)}
                //   />
                //   <FontAwesomeIcon
                //     icon={faPrint}
                //     style={{
                //       cursor: "pointer"
                //       , color: colors.themeMainColor
                //     }}
                //     onClick={() => printById(objData.invoiceId)}
                //   /> 
                //  </div> 
              }

            </div>
          ),
        },
      ],
    };

    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { createDate, periodDate, invoiceDateEnd, status, invoiceNo, leaseAgreement, stall, invoiceDetail, receipt } =
      objData;
    const sumAll = invoiceDetail.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
    const setState =
      status === "ACTIVE"
        ? t("STATUS.CHECK")
        : status === "NOT_PAY"
          ? t("STATUS.OVERDUE")
          : status === "WAITING"
            ? t("STATUS.WAITING") :
            status === "PROGRESS" ? t("STATUS.PROGRESS") :
              status === "PRO_SECUTE" ? t("STATUS.PRO_SECUTE") :
                status === "NOT_APPROVE" ? "ไม่อนุมัติ" :
                  status === "PAY_APPROVE" ? t("STATUS.PAY")
                    : t("STATUS.PROGRESS")

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(createDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(periodDate) || "-" },
        { option: "TEXT", align: "center", label: invoiceNo || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.firstname + " " + leaseAgreement?.lastname },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallNumber || "-" },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(invoiceDateEnd),
          style: { color: colors.red },
        },
        { option: "TEXT", align: "center", label: numberFormat(sumAll) || "0" },
        {
          option: "COMPONENT",
          align: "center",
          label: status,
          component: (
            <Typography
              style={{
                color:
                  status === "ACTIVE"
                    ? colors.lightOrange
                    : status === "NOT_PAY"
                      ? colors.red : status === "PROGRESS" ?
                        colors.yellow : status === "WAITING" ?
                          colors.warning : status === "PRO_SECUTE" ? colors.danger :
                            status === "NOT_APPROVE" ? colors.danger :
                              status === "PAY_APPROVE" ? colors.green
                                : colors.yellow,
                textAlign: 'center',
                whiteSpace: 'nowrap'
              }}
            >
              {setState}

            </Typography>
            // <Button
            //   style={{
            //     backgroundColor:
            //       status === "ACTIVE"
            //         ? colors.lightOrange
            //         : status === "NOT_PAY"
            //           ? colors.red : status === "PROGRESS" ?
            //             colors.yellow : status === "WAITING" ?
            //               colors.warning : status === "PRO_SECUTE" ? colors.danger :
            //                 status === "NOT_APPROVE" ? colors.danger
            //                   : colors.green,
            //     color: colors.white,
            //     margin: "auto",
            //     borderRadius: "4px",
            //     width: "100%",
            //     height: "36px",
            //     whiteSpace: 'nowrap'
            //   }}
            // >
            //   {setState}

            // </Button>
          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="รายละเอียด">
                <IconButton disabled={!(status === "PAY" || status === "PAY_APPROVE" || status === "PROGRESS" || status === "NOT_PAY")} onClick={() => {
                  CheckPayment(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "PAY" || status === "PAY_APPROVE" || status === "PROGRESS" || status === "NOT_PAY" ? colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{
                    cursor: "pointer"
                    , color: status === "PAY" || status === "PROGRESS" || status === "NOT_PAY" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "PAY" || status === "PROGRESS" || status === "NOT_PAY") {
                      CheckPayment(objData)
                    }
                  }}
                />
              </div> */}
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  viewDebtReport(objData)
                  // getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => viewDebtReport(objData)}
                />
              </div> */}
              <Tooltip title="แก้ไขการชำระเงิน">
                <IconButton disabled={status !== "NOT_PAY"} onClick={() => {
                  history.push(routeName.payBill + "/edit-payment", {
                    params: {
                      data: objData,
                      status: "NOT_PAY"
                    },
                  })
                }}>
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "NOT_PAY" ? colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">

                <FontAwesomeIcon
                  icon={faPen}
                  style={{
                    cursor: "pointer"
                    , color: status === "NOT_PAY" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "NOT_PAY") {
                      history.push(routeName.payBill + "/edit-payment", {
                        params: {
                          data: objData,
                          status: "NOT_PAY"
                        },
                      })
                    }
                  }}
                />
              </div> */}
              {status === "PAY_APPROVE" &&
                <Tooltip title="สำเนาใบเสร็จ">
                  <IconButton onClick={() => {
                    onDowloadReceipt(objData.invoiceId)
                    // getTestPdfData(utilitiesId)
                  }}>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      style={{
                        fontSize: "20px",
                        width: "22px",
                        color: colors.themeMainColor
                      }}
                    />
                  </IconButton>
                </Tooltip>
                // <div className="pr-3">
                //   <FontAwesomeIcon
                //     icon={faFileInvoiceDollar}
                //     style={{
                //       cursor: "pointer"
                //       , color: colors.themeMainColor
                //     }}
                //     onClick={() => onDowloadReceipt(objData.invoiceId)}
                //   />
                //   <FontAwesomeIcon
                //     icon={faPrint}
                //     style={{
                //       cursor: "pointer"
                //       , color: colors.themeMainColor
                //     }}
                //     onClick={() => printById(objData.invoiceId)}
                //   />
                // </div>
              }
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const handleServiceAdd = () => {
    const paymentListLabel = 1
    const amountLabel = "";
    const detailLabel = "";

    setServices((prevServices: any) => [
      ...prevServices,
      {
        // invoiceId: data?.invoiceId,
        sequential: 1,
        paymentServiceId: paymentListLabel,
        amount: amountLabel,
        description: detailLabel,
        invoiceDetailDiscount: null,
        utilitiesId: null
      },
    ]);
  };

  const handleServiceDelete = (index: number) => {
    setServices((prevServices) => prevServices.filter((_, i) => i !== index));
  };

  const handleServiceUpdate = (e: any, id: any, propertyName: any) => {
    if (propertyName === "amount") {
      const { value } = e.target;
      setServices((prevData) => {
        const newData = [...prevData];
        newData[id] = { ...newData[id], [propertyName]: value }
        return newData;
      });
    } else if (propertyName === "paymentServiceId") {
      setServices((prevData) => {
        const newData = [...prevData];
        newData[id] = { ...newData[id], [propertyName]: e }
        return newData;
      })
    } else {
      const { value } = e.target;
      setServices((prevData) => {
        const newData = [...prevData];
        newData[id] = { ...newData[id], [propertyName]: value }
        return newData;
      });
    }

  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileImg = acceptedFiles[0];

      const fileName = fileImg?.name.split(".");

      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {
        setFile(acceptedFiles[0]);
        setImageBill(URL.createObjectURL(acceptedFiles[0]));
      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
    },
    [file, imageBill]

  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onDropCheckPayment = useCallback(
    (acceptedFiles) => {
      const fileImg = acceptedFiles[0];

      const fileName = fileImg?.name.split(".");

      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {
        setFileCheckPayment(acceptedFiles[0]);
        setImageCheckPayment(URL.createObjectURL(acceptedFiles[0]));
      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
    },
    [file, imageBill]
  );
  const { getRootProps: propCheckPayment, getInputProps: inputCheckPayment } = useDropzone({ onDrop: onDropCheckPayment });

  const onsubmitCreateBill = async () => {
    let condition: any = {
      branchId: 1,
      invoiceDateStart: appointmentDate,
      invoiceDateEnd: endDateModal,
      includeUtility: includeUtility,
      periodDate: periodDate,
      // checkAll: String(checkAccept).toLocaleUpperCase(),
      nowBill: "FALSE",
      status: "ACTIVE",
      leaseAgreementId: null,
      leaseAgreementTypeId: null,
      zoneId: null,
      stallId: null,
    }
    if (checkAccept === 'zone') {
      condition = {
        ...condition,
        leaseAgreementId: leaseAgreementId,
        leaseAgreementTypeId: leaseAgreementTypeId,
        zoneId: Number(zone),
        // stallId: Number(stallId),
      }
    } else {
      condition = {
        ...condition,
        leaseAgreementId: leaseAgreementId,
        leaseAgreementTypeId: leaseAgreementTypeId,
        zoneId: Number(zone),
        stallId: Number(stallId),
      }
    }
    if (services.length > 0) condition = { ...condition, invoiceDetail: [].concat(...(services || [])) }
    if (services.length === 0) condition = { ...condition, invoiceDetail: null }

    const res = await InvoiceApi.creatInvoice(condition)
    if (res.status === 201) {
      dispatch(closeModal())
      notiSuccess(t('PAYBILL.INVOICE.SUCCESS'))
      history.push(routeName.payBill + "/invoice", { periodDate: periodDate })
    } else {
      notiError(t('PAYBILL.INVOICE.ERROR'))
    }
  }

  const onsubmitPayWithQrcode = async () => {
    if (imageBill === null) {
      notiError(t(`กรุณาแนบใบเสร็จ`))
    } else {
      const condition: any = {
        branchId: payBillData?.branchId,
        invoiceId: payBillData?.invoiceId,
        invoiceTypeId: 1,
        invoicePayDate: moment(Date.now()).format('YYYY-MM-DD'),
        invoicePayAmount: payBillData?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
      }
      const res = await InvoiceApi.pay(condition)
      if (res.status === 201) {
        const formDatasignature = new FormData();
        formDatasignature.append("refId", `${res.data.refId}`);
        formDatasignature.append("fileType", "invoice-pay");
        formDatasignature.append("file", file);
        FileuploadApi.uploadfile(formDatasignature).then((result) => {
          notiSuccess(t(`PAYBILL.ALERT.SUCCESS_PAY`));

          setRole(false)
          setPayQrcode(false)
          setImageBill(null)
        });
        dispatch(closeModal())
        loadData()
      } else {
        notiError(t(`ชำระไม่เงินสำเร็จ`))
        setImageBill(null)
      }
    }

  }

  const onsubmitPayWithBank = async () => {
    if (imageBill === null) {
      notiError(t(`กรุณาแนบใบเสร็จ`))
    } else {
      const condition: any = {
        branchId: payBillData?.branchId,
        invoiceId: payBillData?.invoiceId,
        invoiceTypeId: 2,
        invoicePayDate: moment(Date.now()).format('YYYY-MM-DD'),
        invoicePayAmount: payBillData?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
      }
      const res = await InvoiceApi.pay(condition)
      if (res.status === 201) {
        const formDatasignature = new FormData();
        formDatasignature.append("refId", `${res.data.refId}`);
        formDatasignature.append("fileType", "invoice-pay");
        formDatasignature.append("file", file);
        FileuploadApi.uploadfile(formDatasignature).then((result) => {
          notiSuccess(t(`PAYBILL.ALERT.SUCCESS_PAY`));

          setRole(false)
          setPayBank(false)
          setImageBill(null)
        });
        dispatch(closeModal())
        loadData()
      } else {
        notiError(t(`ชำระไม่เงินสำเร็จ`))
        setImageBill(null)
      }
    }
  }

  const submitApprove = async () => {
    const res = await InvoiceApi.updateStatus(payBillData?.invoiceId, "PAY")
      .then(res => {
        if (res.status === 200) {
          notiSuccess(t('PAYBILL.INVOICE.UPDATE'))
          dispatch(closeModal())
        }
      })
      .catch(() => {
        notiError('ไม่สามารถบันทึกได้')
      })
      .finally(() => loadData())

  }
  const handleSubmitApprove = async () => {
    if (dateApprove === '') {
      return notiError(t(`กรุณาเพิ่มวัน`))
    }
    if (timeApprove === '') {
      return notiError(t(`กรุณาเพิ่มเวลา`))
    }
    const body = {
      date: dateApprove,
      time: timeApprove
    }

    const res = await InvoiceApi.updateApprove(payBillData?.invoiceId, body)
      .then(res => {
        if (res.status === 200) {
          notiSuccess(t('PAYBILL.INVOICE.UPDATE'))
          dispatch(closeModal())
          setDateApprove('')
          setTimeApprove('')
        } else {
          notiError('ไม่สามารถบันทึกได้')
        }
      })
      .catch(() => {
        notiError('ไม่สามารถบันทึกได้')
      })
      .finally(() => loadData())

  }

  const onsubmitPayFromAdmin = async () => {
    if (imageCheckPayment === null) {
      notiError(t(`กรุณาแนบใบเสร็จ`))
    } else {
      const condition: any = {
        branchId: payBillData?.branchId,
        invoiceId: payBillData?.invoiceId,
        invoiceTypeId: 3,
        invoicePayDate: moment(Date.now()).format('YYYY-MM-DD'),
        invoicePayAmount: payBillData?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
      }
      const res = await InvoiceApi.pay(condition)
      if (res.status === 201) {
        const formDataimageCheckpayment = new FormData();
        formDataimageCheckpayment.append("refId", `${res.data.refId}`);
        formDataimageCheckpayment.append("fileType", "invoice-pay");
        formDataimageCheckpayment.append("file", fileCheckPayment);
        FileuploadApi.uploadfile(formDataimageCheckpayment).then((result) => {
          notiSuccess(t(`PAYBILL.ALERT.SUCCESS_PAY`));
          setRole(false);
          setPayment(false);
          setImageCheckPayment(null)
          setFileCheckPayment(null)
        });
        dispatch(closeModal())
        loadData()
      } else {
        notiError(t(`ชำระเงินไม่สำเร็จ`))
      }
    }
  }

  const onNotApprove = async () => {
    const res = await InvoiceApi.updateStatus(payBillData?.invoiceId, "NOT_APPROVE")
      .then(res => {
        if (res.status === 200) {
          notiSuccess(t('PAYBILL.INVOICE.UPDATE'))
          dispatch(closeModal())
        }
      })
      .catch(() => {
        notiError('ไม่สามารถบันทึกได้')
      })
      .finally(() => loadData())
  }
  const shouldDisableDate = (date: any) => {
    const day = new Date(date).getDate();
    // อนุญาตให้เลือกวันที่ 1 และ 16 เท่านั้น
    return day !== 1 && day !== 16;
  };
  return (
    <>
      <HeaderCard text={t("PAYBILL.TITLE")} />
      <Style.PageContainer>
        {roleId === 4 && (
          <Style.MainContainer>
            <Row spacing={2}>
              <Col lg={2} md={12} xs={12} className="my-1">
                <InputTextField
                  placeholder={t("PAYBILL.INPUT.SEARCH")}
                  label={t("PAYBILL.INPUT.SEARCH")}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                />
              </Col>

              <Col lg={2} md={12} xs={12} className="my-1">
                <AutocompleteSelect
                  options={zoneOption || []}
                  value={zoneOption.find((e) => e.zoneId === searchZone)}
                  getOptionLabel={(option: any) => option.zoneName}
                  onChange={(e: any, val: any) => {
                    handleChangeZone(val?.zoneId)
                    setPage(1)
                    setStallOption(val?.stall)
                  }}
                  labelId="zone-name"
                  placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
                />
              </Col>
              <Col lg={2} md={12} xs={12} className="my-1">
                <AutocompleteSelect
                  placeholder={t("PAYBILL.INPUT.SEARCH_STALL")}
                  onChange={(e: any, val: any) => { setSearchStall(val) }}
                  getOptionLabel={(option: any) => option.stallNumber}
                  labelId="label-rental"
                  value={stallOption?.find((e: any) => e?.zoneId === searchStall)}
                  options={stallOption || []}
                />
              </Col>
              <Col lg={4} md={12} xs={12}></Col>
              {/* <Col lg={2} md={12} xs={12} className="my-1">
                  <ButtonCustom
                    type="button"
                    textButton={t("PAYBILL.BUTTON.PRINT")}
                    endIcon={<img src={icons.print} />}
                    onClick={() => printAll()}
                    style={{width: '100%'}}
                  />
              </Col> */}
            </Row>

            <Style.TableContainer>
              <TableCustom
                headCells={headCells}
                page={page}
                pageLimit={pageLimit}
                sortType={"ASC"}
                sortBy=""
                rowCount={rowCount}
                rowsData={invoiceData && invoiceData.map((d: any, i: number) => {
                  return renderData(d, i);
                })}
                onSort={() => { }}
                setPageLimit={(value: number) => setPageLimit(value)}
                setPage={(value: number) => setPage(value)}
                tableMinWidth={1200}
              />
            </Style.TableContainer>
          </Style.MainContainer>
        )}

        {roleId !== 4 && (
          <Style.MainContainer>
            <Row>
              <Col lg={2} md={6} xs={12} className="my-2">
                <InputTextField
                  placeholder={t("PAYBILL.INPUT.SEARCH")}
                  label={t("PAYBILL.INPUT.SEARCH")}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                />
              </Col>
              <Col lg={2} md={6} xs={12} className="my-2">
                {/* <InputNewRangePicker
                  value={selectedDateRange}
                  onchange={handleRangeDate}
                  onClear={handleClearRangeDate}
                  label={t("PAYBILL.INPUT.DATE")}
                  allowClear
                /> */}
                <InputDatePicker
                  dateFormat="DD/MM/YY"
                  key={"date-appointment"}
                  label="งวดวันที่"
                  value={dateStart}
                  allowClear
                  onClear={() => setDateStart("")}
                  shouldDisableDate={shouldDisableDate}
                  onChange={(e: any) =>
                    setDateStart(moment(e).format('YYYY-MM-DD'))
                  }
                />
              </Col>

              <Col lg={2} md={6} xs={12} className="my-2">
                <AutocompleteSelect
                  options={zoneOption || []}
                  getOptionLabel={(option: any) => option?.zoneName}
                  onChange={(e: any, val: any) => {
                    handleChangeZone(val?.zoneId)
                    setPage(1)
                    setStallOption(val?.stall)
                  }}
                  labelId="zone-name"
                  placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
                  value={zoneOption.find((e: any) => e.zoneId === searchZone)}
                />
              </Col>

              <Col lg={2} md={6} xs={12} className="my-2">
                <AutocompleteSelect
                  placeholder={t("PAYBILL.INPUT.SEARCH_STALL")}
                  onChange={(e: any, val: any) => { setSearchStall(val?.stallId) }}
                  getOptionLabel={(option: any) => option.stallNumber}
                  labelId="label-rental"
                  value={stallOption?.find((e: any) => e.stallId === searchStall)}
                  options={stallOption || []}
                />
              </Col>

              <Col lg={2} md={6} xs={12} className="my-2">
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      handleChangeStatus(value)
                      setPage(1)
                    }
                  }}
                  // renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                  label={t('STATUS.LABEL')}
                  selectId="select-status"
                  value={status}
                  labelId="label-status"
                  options={[
                    <MenuItem key="1" value="ALL">
                      {t('STATUS.ALL')}
                    </MenuItem>,
                    <MenuItem key="2" value="PAY">
                      {t('STATUS.PROGRESS')}
                    </MenuItem>,
                    <MenuItem key="4" value="WAITING">
                      {t('STATUS.WAITING')}
                    </MenuItem>,
                    <MenuItem key="2" value="NOT_PAY">
                      {t('STATUS.NOT_PAY')}
                    </MenuItem>,
                    <MenuItem key="3" value="PAY_APPROVE">
                      {t('STATUS.PAY')}
                    </MenuItem>,

                  ]}
                />
              </Col>

              <Col lg={2} md={6} xs={12} className="my-2">
                <ButtonCustom
                  onClick={() => CreatePayBill()}
                  textButton={t("PAYBILL.BUTTON.CREATE_PAYBILL")}
                  endIcon={<img src={icons.add} />}
                  style={{ width: '100%', }}
                />
                {/* <ButtonCustom
                  onClick={() => window.location.href = '/pay-bill/invoice'}
                  textButton={t("ยืนยันการออกใบแจ้งหนี่")}
                  style={{ width: '100%' }}
                /> */}
              </Col>
            </Row>

            <Style.TableContainer>
              <TableCustom
                headCells={headCellsAdmin}
                customScroll
                page={page}
                pageLimit={pageLimit}
                sortType={"ASC"}
                sortBy=""
                rowCount={rowCount}
                rowsData={invoiceData && invoiceData.map((d: any, i: number) => {
                  return renderDataAdmin(d, i);
                })}
                onSort={() => { }}
                setPageLimit={(value: number) => setPageLimit(value)}
                setPage={(value: number) => setPage(value)}
                tableMinWidth={1400}
              />
            </Style.TableContainer>
          </Style.MainContainer>
        )}

        <ModalCustom
          title={
            !role && payQrcode && !payBank
              ? t("PAYBILL.POPUP.PAYMENT_QRCODE")
              : !role && !payQrcode && payBank
                ? t("PAYBILL.POPUP.PAYMENT_BANK")
                : role && payment
                  ? t("PAYBILL.TITLE_CHECK_PAYMENT")
                  : role && !payment
                    ? t("PAYBILL.TITLE_CREATE")
                    : t("PAYBILL.TITLE")
          }
          size="xl"
          onSubmit={() => {
            if (role && !payment) {
              onsubmitCreateBill()
            }
          }}
          closeButton
          textBtnCancel={
            role && payment
              ? ""
              : !role && !payQrcode && payBank ?
                "" : !role && payQrcode && !payBank ?
                  "" :
                  t("PAYBILL.BUTTON.CANCEL")
          }
          textBtnConfirm={
            !role && payQrcode && !payBank
              ? ""
              : !role && !payQrcode && payBank
                ? ""
                : role && payment
                  ? ""
                  : role && !payment
                    ? t("PAYBILL.BUTTON.SAVE_COMPLETED")
                    : t("PAYBILL.BUTTON.SAVE")
          }

          component={
            <div className="pb-2">
              {role && !payment && (
                <Style.ContainerModal>
                  <Row className="mt-lg-3">
                    <Col lg={4} xs={5}>
                      <Style.TypographyHead>
                        {t("PAYBILL.CREATE.STALL_DETAILS")}
                      </Style.TypographyHead>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} xs={1}>
                    </Col>
                    <Col lg={10} xs={12}>
                      {/* <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Checkbox
                          value={checkAccept}
                          onChange={() => {
                            setCheckAccept(!checkAccept)
                            setStallId("")
                            setZone("")
                          }}
                          style={{ marginRight: 16 }}
                        />
                        <Typography>ออกบิลทั้งหมด</Typography>
                      </Box> */}
                      <FormControl sx={{ marginTop: "8px", }}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={checkAccept}
                          onChange={(e) => {
                            setCheckAccept(e.target.value)
                            setStallId("")
                          }}
                        >
                          <FormControlLabel value="zone" control={<Radio />} label="ทั้งโซน" />
                          <FormControlLabel value="stall" control={<Radio />} label="รายแผง" />

                        </RadioGroup>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-1">
                    <Col lg={2} xs={1} className="d-flex justify-content-end">
                      <Style.SubDetail>{t("เลือกโซน")}</Style.SubDetail>
                      {/* <Style.SubDetail>{t("LEASES.TITLE")}</Style.SubDetail> */}
                    </Col>

                    <Col lg={4} className="mt-2">
                      <AutocompleteSelect
                        options={zoneOptionModal || []}
                        getOptionLabel={(option: any) => option.zoneName}
                        onChange={(e: any, val: any) => {
                          setZone(val.zoneId)
                          setStallOptionModal(val?.stall)
                        }}
                        labelId="zone-name"
                        placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
                      />
                    </Col>
                    {checkAccept !== 'zone' && <><Col lg={2} xs={1} className="d-flex justify-content-end">
                      <Style.SubDetail >{t("เลือกแผง")}</Style.SubDetail>
                      {/* <Style.SubDetail>{t("LEASES.TITLE")}</Style.SubDetail> */}
                    </Col>
                      <Col lg={4} className="mt-2">

                        <AutocompleteSelect
                          options={stallOptionModal || []}
                          getOptionLabel={(option: any) => `${option.stallNumber} (${option.stallName})`}
                          onChange={(e: any, val: any) => {
                            setStallId(val.stallId)
                            setLeaseAgreementId(val?.leaseAgreementId)
                            getLeaseAgreementData(val?.leaseAgreementId)
                          }}
                          labelId="stall-name"
                          placeholder={t("PAYBILL.INPUT.SEARCH_STALL")}
                        />
                      </Col></>}
                  </Row>


                  <Divider
                    sx={{ marginTop: 3, marginBottom: 3 }}
                    className="w-100 mx-0"
                  />

                  <Row className="mt-2 mb-2">
                    <Col>
                      <Style.SubDetail className="fw-bolder">
                        {t("PAYBILL.CREATE.ANNOUNCEMENT_DATE")}
                      </Style.SubDetail>
                    </Col>
                  </Row>

                  <Row className="mt-2 mb-3">
                    <Col lg={2} xs={6} className="d-flex justify-content-end">
                      <Style.SubDetail>
                        {t("วันชำระหนี้")}
                      </Style.SubDetail>
                    </Col>

                    <Col lg={4} className="my-2">
                      <InputDatePicker
                        dateFormat="DD/MM/YY"
                        key={"date-appointment"}
                        label="วัน"
                        value={appointmentDate}
                        allowClear
                        onClear={() => setAppointmentDate("")}
                        onChange={(e: any) =>
                          setAppointmentDate(moment(e).format('YYYY-MM-DD'))
                        }
                        disablePast
                      />
                    </Col>

                    <Col lg={2} xs={6} className="d-flex justify-content-end">
                      <Style.SubDetail>
                        {t("วันสิ้นสุดชำระหนี้")}
                      </Style.SubDetail>
                    </Col>

                    <Col lg={4} className="my-2">
                      <InputDatePicker
                        dateFormat="DD/MM/YY"
                        key={"date-appointment"}
                        label="วัน"
                        value={endDateModal}
                        allowClear
                        onClear={() => setEndDateModal("")}
                        onChange={(e: any) =>
                          setEndDateModal(moment(e).format('YYYY-MM-DD'))
                        }
                        disablePast
                      />
                    </Col>
                    <Col lg={2} xs={6} className="d-flex justify-content-end">
                      <Style.SubDetail>
                        {t("งวดวันที่")}
                      </Style.SubDetail>
                    </Col>

                    <Col lg={4} className="my-2">
                      <InputDatePicker
                        dateFormat="DD/MM/YY"
                        key={"date-appointment"}
                        label="วัน"
                        value={periodDate}
                        allowClear
                        onClear={() => setPeriodDate("")}
                        shouldDisableDate={shouldDisableDate}
                        onChange={(e: any) =>
                          setPeriodDate(moment(e).format('YYYY-MM-DD'))
                        }
                      />
                    </Col>
                    <Col lg={2} xs={6} className="d-flex justify-content-end">

                    </Col>

                    <Col lg={4} className="my-2">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: '10px',
                          marginTop: "8px",
                        }}
                      >
                        <Checkbox
                          value={includeUtility}
                          onChange={() => {
                            setIncludeUtility(!includeUtility)

                          }}
                        // style={{ marginRight: 16 }}
                        />
                        <Typography>รวมค่าน้ำค่าไฟ</Typography>

                      </Box>
                    </Col>
                  </Row>

                  <Divider
                    sx={{ marginTop: 3, marginBottom: 3 }}
                    className="w-100 mx-0"
                  />

                  <Row className="my-2 mt-2 mb-3">

                    <Col lg={12} xs={12} md={12}>
                      <Style.ContentHeader1 style={{ padding: '5px', display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: '500' }}>
                          {t("PAYBILL.CREATE.OTHER_PAYMENT")}
                        </Typography>
                        <Tooltip title="เพิ่มรายการ">
                          <IconButton onClick={() => handleServiceAdd()}>
                            <FontAwesomeIcon icon={faCirclePlus}
                              style={{ color: colors.themeMainColor }} />
                          </IconButton>

                        </Tooltip>

                      </Style.ContentHeader1>
                    </Col>
                  </Row>

                  {services.map((service, index: any) => (
                    <div key={index}>

                      <Row>
                        <Col className="d-flex flex-row mt-2" lg={6} md={12} xs={12}>
                          <Style.SubDetail className="mr-2 ml-2">{index + 1}.</Style.SubDetail>
                          <Style.SubDetail className="mr-2 ">{t("PAYBILL.REPORT.PAYMENT_LIST")}</Style.SubDetail>
                          <div className="w-100">
                            <AutocompleteSelect
                              options={debtTypeData}
                              getOptionLabel={(option) => option.paymentName || option.paymentNameEn}
                              onChange={(e: any, value: any) =>
                                handleServiceUpdate(value?.paymentServiceId, index, 'paymentServiceId')}
                              value={debtTypeData.find((d) => d.paymentServiceId === Number(service?.paymentServiceId))}
                              labelId={`label-${index}`}
                            />
                          </div>


                        </Col>
                        <Col className="d-flex flex-row mt-2" lg={5} md={12} xs={12}>

                          <>
                            <Style.SubDetail className="mx-3">{t("PAYBILL.REPORT.AMOUNT")}</Style.SubDetail>
                            <InputTextField
                              label=""
                              style={{ width: "100%" }}
                              onchange={(e: any) => handleServiceUpdate(e, index, 'amount')}
                              value={service?.amount}
                            />
                            <Style.SubDetail className="ml-2">{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                          </>

                        </Col>
                        <Col className="d-flex flex-row mt-2" lg={1}>
                          <Tooltip title="ลบรายการ">
                            <IconButton onClick={() => handleServiceDelete(index)}>
                              <HighlightOffIcon color='error' />
                            </IconButton>
                          </Tooltip>
                          {/* <img src={icons.iconTrash} style={{ cursor: 'pointer' }} /> */}
                        </Col>
                      </Row>

                      <>
                        <Row>
                          <Style.BoxOtherPayment>
                            <Col className="d-flex flex-row">
                              <Style.SubDetail className="ml-3 mr-3">{t("PAYBILL.REPORT.DETAIL")}</Style.SubDetail>
                              <InputTextField
                                label=""
                                style={{ width: "100%" }}
                                onchange={(e: any) => handleServiceUpdate(e, index, 'description')}
                                value={service?.description}
                              />
                            </Col>
                          </Style.BoxOtherPayment>
                        </Row>
                      </>
                      <Divider className="my-3" />
                    </div>
                  ))}

                  {disCountData.length > 0 && <Row className="my-2 mt-2 mb-3">
                    <Col lg={12} xs={12} md={12}>
                      <Style.ContentHeader1>{t("PAYBILL.CREATE.DISCOUNT")} </Style.ContentHeader1>
                    </Col>
                  </Row>}

                  {disCountData[0]?.discountPrice?.map((d: any) => {
                    return (
                      <Row className="my-2">
                        <Col xs={3} >
                          <Style.SubDetail>
                            {debtTypeData?.find(
                              (item: any) =>
                                item?.paymentServiceId === d.paymentServiceId
                            )?.paymentName
                            }
                          </Style.SubDetail>
                        </Col>
                        <Col xs={4}>
                          <Style.SubDetail>
                            {d?.amount}
                          </Style.SubDetail>
                        </Col>
                        <Col xs={4}>
                          <Style.SubDetail>{t("ร้อยละ %")}</Style.SubDetail>
                          {/* <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail> */}
                        </Col>
                      </Row>
                    )
                  })}


                </Style.ContainerModal>
              )}

              {!role && payQrcode && !payBank && <PopupPaymentStall
                amount={payBillData?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)}
                image={imageBill}
                qrCode={qrCode}
                time={fullDate(payBillData?.invoiceDateEnd)}
                onsubmit={() => {
                  onsubmitPayWithQrcode()
                }}
                getInputProps={getInputProps()}
                getRootProps={getRootProps()}
                detail={payBillData}
              />}

              {!role && !payQrcode && payBank && (<PopupPaymentBank
                amount={payBillData?.invoiceDetail?.reduce((a: number, v: any) => a = a + Number(v.amount), 0)}
                image={imageBill}
                qrCode={qrCode}
                time={fullDate(payBillData?.invoiceDateEnd)}
                onsubmit={() => {
                  onsubmitPayWithBank()
                }}
                ondowload={() => {
                  ondowloadPdf()
                }}
                getInputProps={getInputProps()}
                getRootProps={getRootProps()}
              />)}
            </div>
          }
        />

        {role && payment && <ModalCustom //afsdgasg
          title={t("PAYBILL.TITLE_CHECK_PAYMENT")}
          closeButton
          size="xl"
          // textCancel={roleId !== 4 && payBillData.status === "PROGRESS" ? "ไม่อนุมัติ" : ""}
          // onCancel={() => {
          //   if (roleId !== 4 && payBillData.status === "PROGRESS" && !checkRemarkNotApprove) {
          //     setCheckRemarkNotApprove(true)
          //   } else if (roleId !== 4 && payBillData.status === "PROGRESS" && checkRemarkNotApprove) {
          //     onNotApprove()
          //   }
          // }}
          onClose={() => {
            setDateApprove('')
            setTimeApprove('')
          }}
          textBtnConfirm={roleId !== 4 && payBillData.status === "PROGRESS" ? t("ยืนยัน") : roleId !== 4 && payBillData.status === "NOT_PAY" ? "ชำระเงิน" : roleId !== 4 && payBillData.status === "PAY" ? "ยืนยันการชำระเงิน" : ""}
          onSubmit={() => {
            if (roleId !== 4 && payBillData.status === "PROGRESS") {
              submitApprove()
            } else if (roleId !== 4 && payBillData.status === "NOT_PAY") {
              onsubmitPayFromAdmin()
            } else if (roleId !== 4 && payBillData.status === "PAY") {
              handleSubmitApprove()
            }
          }}
          component={
            <PopupCheckPayment
              amount={payBillData?.invoiceDetail
                .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
                .reduce((acc: any, value: any) => acc + value, 0)}
              time={dateToView(payBillData?.receipt?.receiptdate)}
              receipt={payBillData?.invoiceNo}
              image={imageCheckPayment}
              getInputProps={inputCheckPayment()}
              getRootProps={propCheckPayment()}
              showInput={checkRemarkNotApprove}
              inputvalue={remarkNotApprove}
              payment={payBillData?.payment}
              onChangeInput={(e: any) => setRemarkNotApprove(e.target.value)}
              data={payBillData}
              roleId={roleId}
              dateApprove={dateApprove}
              timeApprove={timeApprove}
              setDateApprove={setDateApprove}
              setTimeApprove={setTimeApprove}
            />

          } />}
      </Style.PageContainer>

      <div className="print-show" ref={componentApproval}>
        <ExportData
          componant={
            <>

            </>
          }
        />
      </div>
      {loading && <Loading show={loading} type="fullLoading" />}
    </>
  );
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
