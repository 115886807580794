import BaseAPI from "../api";

const path = 'stall'

export interface StallInterface {
    search?: string,
    stallTypeId?: number
    zoneId?: number
    stallId?: number
    status?: string
    branchId?: number
    page?: number
    pageLimit? : number
}

export interface IStall {
    stallId?: number | null;
    zoneId?: number | null;
    stallName: string;
    stallNumber: string
    branchId?: number
    x: number;
    y: number;
    width: number;
    height: number;
    realWidth: number | null;
    realHeight: number | null;
    color: string;
    stallTypeId: number | null;
    // status?: "OPEN" | "BLANK" | "RESERVE" | "CONTRACT" | "DELETED";
    status?: string
    meterNumber?: IMeterNumber[];

    noStall?: number;
    isEdit?: boolean;
}

export interface IMeterNumber {
    meterId?: number;
    stallId?: number;
    meterNumber: string;
    meterType: "WATER" | "ELECTRICITY";
}

export default class StallApi extends BaseAPI {

    static findAll(props: StallInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static update(id: number, body: IStall): Promise<any> {
        return this.api.patch(`${path}/${id}`, body).then((res) => res);
    }

    static remove(id: number): Promise<any> {
        return this.api.delete(`${path}/${id}`).then((res) => res);
    }
}