import { useTranslation } from "react-i18next";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { IMeterNumber, IStall, IZone } from "../../../../api/zone/zone.api";

type popup = {
  qrcode: any;
  isShow: boolean | false;
  setIsShow: (show: boolean) => void;
  type: string
  data: any
  zone: any
};

export default function PopupDowloadQr(props: popup) {
  const { t } = useTranslation();
  const cardRef: any = useRef<HTMLElement>(null);

  const [image, setImage] = useState<string>("")
  useEffect(() => {
    console.log(props.data)
    console.log(props.zone)
  }, [props.data]);
  const { stallName, stallNumber } = props.data;
  const { zoneName } = props.zone
  console.log(props)
  useEffect(() => {
    setImage(props.qrcode)
  }, [props.qrcode]);

  const onSubmit = async () => {
    const cardElement = cardRef.current;
    if (!cardElement) return;
    try {
      const html2canvas = await import(
        "html2canvas"
      );
      const result = await html2canvas.default(cardElement);
      const asURL = result.toDataURL("image/jpeg");
      const anchor = document.createElement("a");
      anchor.href = asURL;
      anchor.download = `Qrcode-${props.type}-${zoneName}-ชื่อแผงค้า:${stallName}.jpeg`;
      // ${}-${}
      anchor.click();
      anchor.remove();
    } catch (reason) {
      console.log(reason);
    }
  };

  return (
    <ModalCustom
      title=""
      size="lg"
      closeButton
      onSubmit={() => onSubmit()}
      onClose={() => { props.setIsShow(false) }}
      textBtnConfirm={t("ดาวน์โหลด QR code")}
      component={
        <div className="w-100 p-2" ref={cardRef} >
          <div className="border border-secondary border-3 mt-2 py-2 px-5 d-flex flex-column justify-content-center align-items-center">
            <p className="fs-4 fw-bolder mt-2">{props.type === 'ELECTRICITY' ? 'มิเตอร์ค่าไฟ' : 'มิเตอร์ค่าน้ำ'}</p>
            <img src={image} style={{ width: "100%" }} />
            <div className="fs-5 mt-2 text-center">
              <p>
                {`ชื่อแผงค้า: ${stallName}`}
              </p>
              <p>{`เลขที่: ${stallNumber}`}</p>
            </div>
          </div>
        </div>
      }
    />
  );
}
