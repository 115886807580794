import { useTranslation } from "react-i18next";
import moment from "moment";
import { Autocomplete, Box, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../../../component/input/inputTextField";

/** STYLE, CONSTANT */
import * as Style from ".././stallAgreement.style";
import { imgs } from "../../../../constants/images";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { dateToView } from "../../../../utils/date.utils";
import AutocompleteSelect from "../../../../component/select/autoCompleteSelect";
import FilterSelect from "../../../../component/select/filterSelect";
import { useCallback, useEffect, useState } from "react";
import { notiError, notiSuccess } from "../../../../component/notification/notifications";
import { useDropzone } from "react-dropzone";
import Cloudupload from "../../../../assets/icon/cloudupload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ButtonCustom from "../../../../component/button/buttonCustom";
import InputNewRangePicker from "../../../../component/input/InputRangePicker";
import UserApi from "../../../../api/auth/users.api";
import StallApi from "../../../../api/stall/stall.api";
import LeasesTypeApi from "../../../../api/setting/leases/leases.api";
import AppointmentApi from "../../../../api/stall/appointment/appointment";
import { decode } from "js-base64";
import { getProfile, handleTypingNumber } from "../../../../utils/app.utils";
import FileuploadApi from "../../../../api/uploadfile/upload.api";
import { closeModal } from "../../../../app/slice/modal.slice";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import InputCheckbox from "../../../../component/input/inputCheckbox";
import InputDatePicker from "../../../../component/input/inputDatePicker";
import { debounce } from "lodash";
import LeaseAgreementApi from "../../../../api/stall/leaseAgreement/leaseAgreement.api";
import ZoneApi from "../../../../api/zone/zone.api";
const initStateErrorMessage = {
  USER: ``,
  STALL: ``,
  LEASESTYPE: ``,
  AMOUNT: ``,
  DATE: ``,
}

export default function PopupAgreementCreate(props: any) {
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage);
  const { t }: any = useTranslation();
  const { data, setData, loadData, onClose } = props
  const dispatch = useDispatch();
  const profile: any = JSON.parse(getProfile() || "{}");
  const [select, setSelectfile] = useState<any>();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([]);
  const [amount, setAmount] = useState<any>();
  const [file, setFile] = useState<any[]>([]);
  const [userData, setUserData] = useState<any[]>([]);
  const [user, setUser] = useState<any>();
  const [stallData, setStallData] = useState<any[]>([]);
  const [stall, setStall] = useState<any>();
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [typeDetail, setTypeDetail] = useState<any>();
  const [agreementNo, setAgreementNo] = useState<any>();
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [zoneId, setZoneId] = useState<string>('');
  const [zoneOptionModal, setZoneOptionModal] = useState<any[]>([]);
  const [stallOptionModal, setStallOptionModal] = useState<any[]>([]);

  const [leasesTypeId, setLeasesTypeId] = useState<number>();
  const [yearType, setYearType] = useState<string>('1');

  const [rentalRate, setRentalRate] = useState<number>();
  const [rentalRate2, setRentalRate2] = useState<number>();
  const [rentalRate3, setRentalRate3] = useState<number>();
  const [rentalPenaltyRate, setRentalPenaltyRate] = useState<number>();
  const [moveOutPenaltyRate, setMoveOutPenaltyRate] = useState<number>();
  const [damageInsuranceLimit, setDamageInsuranceLimit] = useState<number>();
  const [inputValue, setInputValue] = useState('');

  const getLeasesType = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5
    }
    await LeasesTypeApi.findAll(condition)
      .then((res: any) => {
        if (res.status === 200) {
          setLeasesTypeData(res?.data)
        } else {
          setLeasesTypeData([])
        }
      })
  }
  const getUser = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 1000,
    }
    const res = await UserApi.findAll(condition)
    if (res.status === 200) {
      setUserData(res.data)
    } else {
      setUserData([])
    }
  }
  // const getStall = async () => {
  //   const condition: any = {
  //     page: 1,
  //     pageLimit: 10000,
  //   }
  //   const res = await StallApi.findAll(condition)
  //   if (res.status === 200) {
  //     setStallData(res.data)
  //   } else {
  //     setStallData([])
  //   }
  // }
  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 1000,
      skipcalculation: 0
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
      setZoneOptionModal(res.data)
    } else {
      setZoneOption([])
      setZoneOptionModal([])
    }
  };
  const fetchData = useCallback(debounce(async (query) => {
    const condition: any = {
      search: query,
      page: 1,
      pageLimit: 10000,
      zoneId: zoneId || undefined
    }
    const response = await StallApi.findAll(condition)
    if (response.status === 200) {
      setStallData(response.data.filter((x: any) => x.stallNumber))
      setStallOptionModal(response.data.filter((x: any) => x.stallNumber))
    } else {
      setStallData([])
      setStallOptionModal([])
    }
  }, 300), [zoneId]);

  useEffect(() => {
    // if (inputValue.length > 0) {
    fetchData(inputValue);
    // }
  }, [inputValue, fetchData]);

  useEffect(() => {
    getUser()
    getZone()
    // getStall()
    getLeasesType()

  }, [])

  useEffect(() => {
    // getUser()
    // getStall()
    // getLeasesType()
    if (data && userData.length > 0 && stallData.length > 0) {
      setUser(data?.customer)
      setStall(data?.bidder?.stall)
      setAgreementNo(data?.leaseAgreement?.leaseAgreementNo)
      setAmount(data?.leaseAgreement?.amount)
      // setRangeDate([data?.leaseAgreement?.leaseAgreementDateStart, data?.leaseAgreement?.leaseAgreementDateEnd])
      setLeasesTypeId(data?.leaseAgreement?.leasesTypeId)
      setTypeDetail(leasesTypeData.find((item: any) => item.leasesTypeId === data?.leaseAgreement?.leasesTypeId))
      setYearType(data?.leaseAgreement?.yearType)
      setRentalRate(data?.leaseAgreement?.rentalRate)
      setRentalRate2(data?.leaseAgreement?.rentalRate2)
      setRentalRate3(data?.leaseAgreement?.rentalRate3)
      setStartDate(data?.leaseAgreement?.leaseAgreementDateStart)
      setEndDate(data?.leaseAgreement?.leaseAgreementDateEnd)
      setRentalPenaltyRate(data?.leaseAgreement?.rentalPenaltyRate)
      setMoveOutPenaltyRate(data?.leaseAgreement?.moveOutPenaltyRate)
      setDamageInsuranceLimit(data?.leaseAgreement?.damageInsuranceLimit)

    }
  }, [data, userData, stallData])

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf" ||
        detailImage.ext === "jpeg"
      ) {
        // Do something with the files
        setFile([...file, acceptedFiles[0]]);

      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }

      // setFile(URL.createObjectURL(acceptedFiles[0]));
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  // const handlepreview = (x: any) => {
  //   const name = x.type.split(".");

  //   if (name[name?.length - 1] === "application/pdf") {
  //     window.open(URL.createObjectURL(x), "_blank");
  //   } else {
  //     setSelectfile(URL.createObjectURL(x));
  //   }
  // };
  // const handledelete = (index: number) => {
  //   setFile(file?.filter((_: any, i: number) => i !== index));
  //   setSelectfile(null);
  // };

  const handlepreview = (x: any) => {
    if (!x?.fileName && !x?.filePath) {
      const name = x.type.split(".");

      if (name[name?.length - 1] === "application/pdf") {

        window.open(URL.createObjectURL(x), "_blank");
      } else {
        setSelectfile(URL.createObjectURL(x));
      }
    } else {
      if (x?.mimetype === "application/pdf") {

        window.open(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName, "_blank");
      } else {
        setSelectfile(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName);
      }
    }
  };
  const handledelete = (x: any) => {
    setFile(file.filter((y: any, i: number) => i !== x));
    setSelectfile(null);
  };
  const handleSubmit = async () => {
    if (!stall)
      return setErrorMessage({
        ...errorMessage,
        ...{ STALL: 'กรุณาเลือกแผง' },
      });
    if (!user)
      return setErrorMessage({
        ...errorMessage,
        ...{ USER: 'กรุณาเลือกผู้เช่า' },
      });

    if (!leasesTypeId)
      return setErrorMessage({
        ...errorMessage,
        ...{ LEASESTYPE: 'กรุณาเลือกประเภท' },
      });
    // if (!rentalRate) return setErrorMessage({ ...errorMessage, ...{ RENTALRATE: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalRate2 && yearType == '3') return setErrorMessage({ ...errorMessage, ...{ RENTALRATE2: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalRate3 && yearType == '3') return setErrorMessage({ ...errorMessage, ...{ RENTALRATE3: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalPenaltyRate) return setErrorMessage({ ...errorMessage, ...{ RENTAL_PENALTY_RATE: t('กรุณากรอกค่าปรับค่าเช่า') } })
    // if (!moveOutPenaltyRate) return setErrorMessage({ ...errorMessage, ...{ MOVE_OUT_PENALTY_RATE: t('กรุณากรอกค่าปรับกรณีไม่ยอมออกจากพื้นที่') } })
    // if (!damageInsuranceLimit) return setErrorMessage({ ...errorMessage, ...{ DAMAGE_INSURANCE_LIMIT: t('กรุณากรอกวงเงินประกันความเสียหาย') } })
    if (!startDate)
      return setErrorMessage({
        ...errorMessage,
        ...{ STARTDATE: 'กรุณาระบุวันที่' },
      });
    if (!endDate)
      return setErrorMessage({
        ...errorMessage,
        ...{ ENDDATE: 'กรุณาระบุวันที่' },
      });


    if (data) {
      const body = {
        branchId: Number(JSON.parse(decode(profile))?.branchId),
        userId: +user?.userId,
        leasesTypeId: leasesTypeId,
        // stallId: stall.stallId,
        amount: +(rentalRate || 0),
        leaseAgreementDateStart: startDate || undefined,
        leaseAgreementDateEnd: endDate || undefined,
        // agreementNo: agreementNo || undefined,
        refFileId: undefined,
        yearType: +yearType,
        rentalRate: +(rentalRate || 0),
        rentalRate2: +(rentalRate2 || 0),
        rentalRate3: +(rentalRate3 || 0),
        rentalPenaltyRate: +(rentalPenaltyRate || 0),
        moveOutPenaltyRate: +(moveOutPenaltyRate || 0),
        damageInsuranceLimit: +(damageInsuranceLimit || 0),
        paymentType: typeDetail?.paymentType,
        payAtDate: typeDetail?.payAtDate || '',
        payAtEndOfMonth: typeDetail?.payAtEndOfMonth,
      }
      const res = await LeaseAgreementApi.updateInList(data.leaseAgreement.leaseAgreementId, body)
      if (res.status === 200) {
        // const res = await RepairApi.updateStatus(dataById?.repairId, "RECEIVE")
        let ref = res.data.refId || null;
        const uploadBillImg = new FormData();
        uploadBillImg.append("refId", `${ref}`);
        uploadBillImg.append("fileType", res.data?.fileType);
        if (file.length > 0) {
          file.forEach((data: any, i: any) => {
            uploadBillImg.append(`files[]`, data);
          });
          FileuploadApi.uploadfilearray(uploadBillImg).then((result) => {
            dispatch(closeModal())
            notiSuccess('แก้ไขสัญญาสำเร็จ')
            loadData()
            setFile([])
            setSelectfile(null)
            setData(null)
            setTypeDetail(null)
            setYearType('1')
            setRentalRate(undefined)
            setRentalRate2(undefined)
            setRentalRate3(undefined)
            setRentalPenaltyRate(undefined)
            setMoveOutPenaltyRate(undefined)
            setDamageInsuranceLimit(undefined)
            setAgreementNo(undefined)
            setStartDate('')
            setEndDate('')
          })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {

            })
        } else {
          notiSuccess('แก้ไขสัญญาสำเร็จ')
          dispatch(closeModal())
          loadData()
        }
        setAmount('')
        setLeasesTypeId(undefined)
        setUser(null)
        setStall(null)
        setRangeDate([])
        setData(null)
        setTypeDetail(null)
        setYearType('1')
        setRentalRate(undefined)
        setRentalRate2(undefined)
        setRentalRate3(undefined)
        setRentalPenaltyRate(undefined)
        setMoveOutPenaltyRate(undefined)
        setDamageInsuranceLimit(undefined)
        setAgreementNo(undefined)
        setStartDate('')
        setEndDate('')

      } else {
        notiError('แก้ไขสัญญาไม่สำเร็จ')
      }
    } else {
      const body = {
        branchId: Number(JSON.parse(decode(profile))?.branchId),
        userId: +user?.userId,
        leasesTypeId: leasesTypeId,
        stallId: stall.stallId,
        amount: +(rentalRate || 0),
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        agreementNo: agreementNo || undefined,
        refFileId: undefined,
        yearType: +yearType,
        rentalRate: +(rentalRate || 0),
        rentalRate2: +(rentalRate2 || 0),
        rentalRate3: +(rentalRate3 || 0),
        rentalPenaltyRate: +(rentalPenaltyRate || 0),
        moveOutPenaltyRate: +(moveOutPenaltyRate || 0),
        damageInsuranceLimit: +(damageInsuranceLimit || 0),
        paymentType: typeDetail?.paymentType,
        payAtDate: typeDetail?.payAtDate || '',
        payAtEndOfMonth: typeDetail?.payAtEndOfMonth,

      }
      const res = await AppointmentApi.createAgreement(body)
      if (res.status === 201) {
        // const res = await RepairApi.updateStatus(dataById?.repairId, "RECEIVE")
        let ref = res.data.refId || null;
        const uploadBillImg = new FormData();
        uploadBillImg.append("refId", `${ref}`);
        uploadBillImg.append("fileType", res.data?.fileType);
        if (file.length > 0) {
          file.forEach((data: any, i: any) => {
            uploadBillImg.append(`files[]`, data);
          });
          FileuploadApi.uploadfilearray(uploadBillImg).then((result) => {
            dispatch(closeModal())
            notiSuccess('เพิ่มสัญญาสำเร็จ')
            loadData()
            setFile([])
            setSelectfile(null)
            setData(null)
            setTypeDetail(null)
            setYearType('1')
            setRentalRate(undefined)
            setRentalRate2(undefined)
            setRentalRate3(undefined)
            setRentalPenaltyRate(undefined)
            setMoveOutPenaltyRate(undefined)
            setDamageInsuranceLimit(undefined)
            setAgreementNo(undefined)
            setStartDate('')
            setEndDate('')
          })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {

            })
        } else {
          notiSuccess('เพิ่มสัญญาสำเร็จ')
          dispatch(closeModal())
          loadData()
        }
        setAmount('')
        setLeasesTypeId(undefined)
        setUser(null)
        setStall(null)
        setRangeDate([])
        setData(null)
        setTypeDetail(null)
        setYearType('1')
        setRentalRate(undefined)
        setRentalRate2(undefined)
        setRentalRate3(undefined)
        setRentalPenaltyRate(undefined)
        setMoveOutPenaltyRate(undefined)
        setDamageInsuranceLimit(undefined)
        setAgreementNo(undefined)
        setStartDate('')
        setEndDate('')

      } else {
        notiError("เพิ่มสัญญาไม่สำเร็จ")
      }
    }

  }
  const handleFileUpload = async () => {

    if (file.length > 0) {
      const uploadBillImg = new FormData();
      uploadBillImg.append("refId", `${data?.leaseAgreement?.leaseAgreementId}`);
      uploadBillImg.append("fileType", 'lease-agreement');
      file.forEach((data: any, i: any) => {
        uploadBillImg.append(`files[]`, data);
      });
      FileuploadApi.uploadfilearray(uploadBillImg).then((result) => {
        dispatch(closeModal())
        notiSuccess('แก้ไขสัญญาสำเร็จ')
        loadData()
        setFile([])
        setSelectfile(null)
      })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {

        })
    } else {
      notiSuccess('แก้ไขสัญญาสำเร็จ')
      dispatch(closeModal())
      loadData()
    }
  }
  return (
    <>
      <ModalCustom
        title={data?.leaseAgreement?.refFile ? 'แก้ไขสัญญา' : "เพิ่มสัญญา"}
        size="lg"
        closeButton
        onClose={() => {
          setAmount('')
          setStartDate('')
          setEndDate('')
          setLeasesTypeId(undefined)
          setUser(null)
          setStall(null)
          setFile([])
          setData(null)
          setSelectfile(null)
          setRangeDate([])
          setTypeDetail(null)
          setRentalRate(undefined)
          setRentalPenaltyRate(undefined)
          setMoveOutPenaltyRate(undefined)
          setDamageInsuranceLimit(undefined)
          setAgreementNo(undefined)
          onClose()
        }}
        component={
          <Style.MainContainer>
            <div>
              <div>
                <Row>
                  {!data && 
                  <Col xs={12}>
                    <div className=" mt-2">
                    <AutocompleteSelect
                        options={zoneOptionModal || []}
                        getOptionLabel={(option: any) => option.zoneName}
                        onChange={(e: any, val: any) => {
                          if(val){
                            setZoneId(val?.zoneId)
                            // setStallOptionModal(val?.stall)
                          } else {
                            setZoneId('')
                            // setStallOptionModal(stallData)
                          }
                        }}
                        heading="โซน"
                        labelId="zone-name"
                        placeholder={t("PAYBILL.INPUT.SEARCH_ZONE")}
                      />
                    </div>
                  </Col>}
                  <Col xs={12}>
                    <div className=" mt-2">
                      <AutocompleteSelect
                        options={stallOptionModal}
                        // options={stallData?.filter(
                        //   (item: any) => item.status === "ACTIVE"
                        // )}
                        getOptionLabel={(option: any) => `${option.stallNumber} (${option.stallName})`}
                        placeholder="เลขที่แผง"
                        labelId="addmin-type"
                        value={
                          stall
                        }
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        heading={t("เลขที่แผง")}
                        onChange={(event, newValue) => {
                          clearErrorMessage();
                          setStall(newValue);
                        }}
                        required
                        helperText={errorMessage.STALL || ''}
                        disabled={data}

                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className=" mt-2">
                      <AutocompleteSelect
                        options={userData?.filter(
                          (item: any) => item.status === "ACTIVE"
                        )}
                        getOptionLabel={(option: any) => `${option.firstname} ${option.lastname}`}
                        placeholder="ผู้เช่า"
                        labelId="addmin-type"
                        value={
                          user || null
                        }
                        heading={t("ผู้เช่า")}
                        onChange={(event, newValue) => {
                          clearErrorMessage();
                          if (newValue) {
                            setUser(newValue);
                          } else {
                          }
                        }}
                        required
                        helperText={errorMessage.USER}
                        disabled={data}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="mt-2">
                      <InputTextField value={agreementNo} onchange={(e) => {
                        setAgreementNo(e.target.value)
                      }} heading="เลขที่สัญญา" disabled={data} />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="mt-2">
                      <FilterSelect labelId={""} selectId={""}
                        value={leasesTypeId || ''}
                        onchange={(e) => {
                          setLeasesTypeId(e.target.value)
                          clearErrorMessage();
                          setTypeDetail(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value))
                          setYearType(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).yearType)
                          setRentalRate(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).rentalRate)
                          setRentalRate2(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).rentalRate2)
                          setRentalRate3(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).rentalRate3)
                          setRentalPenaltyRate(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).rentalPenaltyRate)
                          setMoveOutPenaltyRate(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).moveOutPenaltyRate)
                          setDamageInsuranceLimit(leasesTypeData.find((item: any) => item.leasesTypeId === e.target.value).damageInsuranceLimit)
                        }}
                        options={leasesTypeData && leasesTypeData.map((e: any) => {
                          return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                        })} heading="รูปแบบสัญญาเช่า"
                        helperText={errorMessage.LEASESTYPE || ""}
                        disabled={data} />
                    </div>
                  </Col>
                  {typeDetail && <>
                    <Col xs={12} className="auto mt-2">
                      <Typography>{t("Rate ค่าเช่า")}</Typography>
                    </Col>
                    <Col xs={12} className="auto">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={typeDetail?.paymentType || ''}
                          row
                        >
                          <FormControlLabel value="MONTHLY" control={<Radio sx={{
                            '&.Mui-checked': {
                              color: '#14378A !important',
                            },
                          }} disabled />} label={'รายเดือน'} />
                          <FormControlLabel value="DAILY" control={<Radio sx={{
                            '&.Mui-checked': {
                              color: '#14378A !important',
                            },
                          }} disabled />} label={'รายวัน'} />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                    <Col className="my-2" md={3} xs={12} style={{ whiteSpace: 'nowrap' }}>
                      {t("ระยะสัญญา")} :
                    </Col>
                    <Col item md={9} xs={12} className="my-2">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={yearType}
                          onChange={(e) => setYearType(e.target.value)}
                          row
                        >
                          <FormControlLabel disabled value="1" control={<Radio sx={{
                            '&.Mui-checked': {
                              color: '#14378A !important',
                            },
                          }} />} label={'1ปี'} />
                          <FormControlLabel disabled value="3" control={<Radio sx={{
                            '&.Mui-checked': {
                              color: '#14378A !important',
                            },
                          }} />} label={'3ปี'} />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                    <Col xs={12} className="auto mt-2">
                      <Typography>{t("กำหนดการชำระเงิน")}</Typography>
                    </Col>
                    <Col xs={12} className="auto">
                      <div className='d-flex gap-3'>
                        <InputCheckbox disabled checked={typeDetail?.payAtDate} onChange={(e) => {
                          // setPayAtDateCheck(e.target.checked)
                          // clearErrorMessage()
                        }} label={<Typography sx={{ whiteSpace: 'nowrap' }}>ทุกวันที่</Typography>} />
                        <InputTextField value={typeDetail?.payAtDate}
                          //  onchange={(e) => setPayAtDate(e.target.value)} 
                          // style={{ width: '150px' }}
                          disabled />
                        <InputCheckbox disabled checked={typeDetail?.payAtEndOfMonth} onChange={(e) => {
                          // setPayAtEndOfMonth(e.target.checked)
                          // clearErrorMessage()
                        }} label={<Typography sx={{ whiteSpace: 'nowrap' }} >สิ้นเดือน</Typography>} />
                      </div>
                    </Col>
                    {yearType == '1' ? <>
                      <Col className="my-2" xs={12} style={{ whiteSpace: 'nowrap' }}>
                        {t("อัตราค่าเช่า")} :
                      </Col>
                      <Col item xs={12} className="my-2 d-flex align-items-center gap-2">
                        <InputTextField value={rentalRate} onchange={(e) => {
                          setRentalRate(e.target.value)
                          clearErrorMessage()
                        }}
                          helperText={errorMessage.RENTALRATE || ''} style={{ width: "180px" }}
                          onkeypress={handleTypingNumber}
                        /> <Typography>บาท</Typography>
                      </Col></> : <>
                      <Col className="my-2" xs={12} style={{ whiteSpace: 'nowrap' }}>
                        {t("อัตราค่าเช่าปีที่ 1")} :
                      </Col>
                      <Col item xs={12} className="my-2 d-flex align-items-center gap-2">
                        <InputTextField value={rentalRate} onchange={(e) => {
                          setRentalRate(e.target.value)
                          clearErrorMessage()
                        }}
                          helperText={errorMessage.RENTALRATE || ''}
                          onkeypress={handleTypingNumber}
                        /> <Typography>บาท</Typography>
                      </Col>
                      <Col className="my-2" xs={12} style={{ whiteSpace: 'nowrap' }}>
                        {t("อัตราค่าเช่าปีที่ 2")} :
                      </Col>
                      <Col item xs={12} className="my-2 d-flex align-items-center gap-2">
                        <InputTextField value={rentalRate2} onchange={(e) => {
                          setRentalRate2(e.target.value)
                          clearErrorMessage()
                        }}
                          helperText={errorMessage.RENTALRATE2 || ''}
                          onkeypress={handleTypingNumber}
                        /> <Typography>บาท</Typography>
                      </Col>
                      <Col className="my-2" xs={12} style={{ whiteSpace: 'nowrap' }}>
                        {t("อัตราค่าเช่าปีที่ 3")} :
                      </Col>
                      <Col item xs={12} className="my-2 d-flex align-items-center gap-2">
                        <InputTextField value={rentalRate3} onchange={(e) => {
                          setRentalRate3(e.target.value)
                          clearErrorMessage()
                        }}
                          helperText={errorMessage.RENTALRATE3 || ''}
                          onkeypress={handleTypingNumber}
                        /> <Typography>บาท</Typography>
                      </Col>
                    </>}
                    <Col xs={12} className="auto mt-2">
                      <Typography>{t("ค่าปรับค่าเช่า (ร้อยละ)")}</Typography>
                    </Col>
                    <Col xs={12} className="auto d-flex align-items-center gap-2">
                      <InputTextField value={rentalPenaltyRate || ''} onchange={(e) => {
                        setRentalPenaltyRate(e.target.value)
                        clearErrorMessage()
                      }}
                        // disabled={data}
                        helperText={errorMessage.RENTAL_PENALTY_RATE || ''}
                        onkeypress={handleTypingNumber}
                      // style={{ width: "180px" }}
                      />
                      {/* <Typography>บาท</Typography> */}
                    </Col>
                    <Col xs={12} className="auto mt-2">
                      <Typography>{t("ค่าปรับกรณีไม่ยอมออกจากพื้นที่")}</Typography>
                    </Col>
                    <Col xs={12} className="auto d-flex align-items-center gap-2">
                      <InputTextField value={moveOutPenaltyRate || ''} onchange={(e) => {
                        setMoveOutPenaltyRate(e.target.value)
                        clearErrorMessage()
                      }} helperText={errorMessage.MOVE_OUT_PENALTY_RATE || ''}
                        // disabled={data}
                        onkeypress={handleTypingNumber}
                      // style={{ width: "180px" }}
                      />
                      {/* <Typography>บาท</Typography> */}
                    </Col>
                    <Col xs={12} className="auto mt-2">
                      <Typography>{t("วงเงินประกันความเสียหาย")}</Typography>
                    </Col>
                    <Col xs={12} className="auto d-flex align-items-center gap-2">
                      <InputTextField value={damageInsuranceLimit || ''} onchange={(e) => {
                        setDamageInsuranceLimit(e.target.value)
                        clearErrorMessage()
                      }} helperText={errorMessage.DAMAGE_INSURANCE_LIMIT || ''}
                        // disabled={data}
                        onkeypress={handleTypingNumber}
                      // style={{ width: "180px" }}
                      />
                      {/* <Typography>บาท</Typography> */}
                    </Col>
                  </>}

                  <Col xs={12}>
                    <div className="mt-2">
                      {/* <InputNewRangePicker value={rangeDate}
                        onchange={(val) => {
                          setRangeDate([
                            moment(val[0]).format("YYYY-MM-DD"),
                            moment(val[1]).format("YYYY-MM-DD"),
                          ])
                          clearErrorMessage();
                        }
                        } placeholder={`${t('วว/ดด/ปปปป')}  »  ${t('วว/ดด/ปปปป')}`} heading={`${t('วันเริ่มสัญญา')}  »  ${t('ระบุวันสิ้นสุดสัญญา')}`}
                        helperText={errorMessage.DATE || ""}
                        disabled={data} /> */}

                      <InputDatePicker
                        required
                        value={startDate}
                        allowClear
                        onClear={() => setStartDate("")}
                        key={"date"}
                        onChange={(e: any) => {
                          setStartDate(moment(e).format("YYYY-MM-DD"))
                          clearErrorMessage()
                        }}
                        helperText={errorMessage.STARTDATE}
                        heading={t("วันเริ่มสัญญา")}
                      />

                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mt-2">
                      {/* <InputNewRangePicker value={rangeDate}
                        onchange={(val) => {
                          setRangeDate([
                            moment(val[0]).format("YYYY-MM-DD"),
                            moment(val[1]).format("YYYY-MM-DD"),
                          ])
                          clearErrorMessage();
                        }
                        } placeholder={`${t('วว/ดด/ปปปป')}  »  ${t('วว/ดด/ปปปป')}`} heading={`${t('วันเริ่มสัญญา')}  »  ${t('ระบุวันสิ้นสุดสัญญา')}`}
                        helperText={errorMessage.DATE || ""}
                        disabled={data} /> */}
                      <InputDatePicker
                        required
                        value={endDate}
                        allowClear
                        onClear={() => setEndDate("")}
                        key={"date"}
                        onChange={(e: any) => {
                          setEndDate(moment(e).format("YYYY-MM-DD"))
                          clearErrorMessage()
                        }}
                        helperText={errorMessage.ENDDATE}
                        heading={t("ระบุวันสิ้นสุดสัญญา")}
                      />

                    </div>
                  </Col>
                  {/* <Col xs={12}>
                    <div className="mt-2">
                      <InputTextField value={amount} onchange={(e) => {
                        setAmount(e.target.value)
                        clearErrorMessage();
                      }} heading="ราคาเช่า" helperText={errorMessage.AMOUNT || ''} disabled={data} />
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <div className="mt-2">
                      ไฟล์แนบ
                    </div>
                  </Col>
                  <Grid className="my-2" item xs={12}>
                    <Style.Boxupload>
                      {" "}
                      {select ? (
                        <div {...getRootProps()} style={{ width: '100%' }}>
                          <input {...getInputProps()} />
                          <img src={select} alt="Thumb" width={'100%'} height="300px" style={{ cursor: 'pointer', objectFit: 'contain' }} />
                        </div>
                      ) : (
                        <Style.Boxdash>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <Box
                              sx={{
                                flexDirection: "column",
                                display: "flex",
                                alignItems: "center",
                                cursor: 'pointer'
                              }}
                            >
                              <Cloudupload />
                              <div>
                                <Typography
                                  sx={{
                                    marginTop: "24px",
                                    fontSize: "18px",
                                    fontFamily: "kanit",
                                  }}
                                >
                                  {"แนบไฟล์เอกสาร PDF,JPG,JPEG,PNG"}
                                </Typography>
                              </div>
                            </Box>
                          </div>
                        </Style.Boxdash>
                      )}
                    </Style.Boxupload>
                  </Grid>

                  {file?.map((x: any, index: number) => (
                    <>
                      <Grid item lg={2} xs={12} className="my-2">
                        อันดับ {index + 1}
                      </Grid>
                      <Grid key={index} item lg={10} xs={12} className="my-2">
                        <Style.Array>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{x.path}</Box>

                            <Box>
                              <VisibilityIcon
                                onClick={() => handlepreview(x)}
                                sx={{ cursor: "pointer", marginRight: "10px" }}
                              />
                              <DeleteIcon
                                onClick={() => handledelete(index)}
                                color="error"
                                sx={{ cursor: "pointer" }}
                              />
                            </Box>
                          </Box>
                        </Style.Array>
                      </Grid>
                    </>
                  ))}
                </Row>

              </div>
              <ButtonCustom
                textButton="บันทีก/เสร็จสิ้น"
                onClick={() => handleSubmit()}
                btnStyle={{ width: "100%", marginTop: "24px" }}
              />
            </div>
          </Style.MainContainer>
        }
      />


    </>
  );
}
