import { Box, Container, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import HeaderCard from "../../component/cardCustom/headerCard";
import {
  Boxcamera,
  Boxconteinerin,
  Boximg,
  Boxright,
  Boxsolid,
  CustomPatternFormat,
  TypographyHead,
} from "../admin/adminStyle";
import { Col, Row } from "react-bootstrap";
import Camera from "../../assets/icon/camera";
import InputTextField from "../../component/input/inputTextField";
import { useTranslation } from "react-i18next";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import InputPasswordField from "../../component/input/inputPasswordField";
import { useDropzone } from "react-dropzone";
import ButtonCustom from "../../component/button/buttonCustom";
import RoleApi from "../../api/admin/role/role.api";
import InputDatePicker from "../../component/input/inputDatePicker";
import moment from "moment";
import PrefixApi from "../../api/setting/prefix/prefix.api";
import BranchApi from "../../api/setting/branch/branch.api";
import SignatureCanvas from "react-signature-canvas";
import EmployeeApi from "../../api/admin/employee/employee.api";
import { useParams } from "react-router-dom";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import { getPermissions, getProfile } from "../../utils/app.utils";
import FileuploadApi from "../../api/uploadfile/upload.api";
import { fileUrl } from "../../api/api";
import { decode } from 'js-base64';
import { swalError } from "../../component/notification/swal";

const initStateErrorMessage = {
  PRFIX_NAME: ``,
  ROLDID: ``,
  NAME: ``,
  EMPLOYEE_CODE: ``,
  LASTNAME: ``,
  PHONE_NUBER: ``,
  EMAIL: ``,
  USERNAME: ``,
  PASSOWORD: ``,
  CONFIRM_PASSWORD: ``,
  IDCARD: ``,
};

export default function EditProfileAdmin() {
  // const permission1 = getPermissions();
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [addminType, setAddminType] = useState<any>([]);
  const [prefixData, setPrefixData] = useState<any>([]);
  const [employeeCode, setEmployeeCode] = useState<string>("");
  const [registerDate, setRegisterDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [birthday, setBirthDay] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  // const [idCard, setTaxId] = useState<string>("");
  const [roleId, setRoleId] = useState<Number>(1);
  const [branchId, setBranchId] = useState<Number>(0);
  const [prefixId, setPrefixId] = useState<Number>(0);
  const [branch, setBranch] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [SignatureDataURL, setSignatureDataURL] = useState<any>(null);
  const [sigimg, setSigimg] = useState<any>();
  const [profileimg, setProFile] = useState<any>();
  const [checkfile, setCheckfile] = useState<number>(1);
  const [permission, setPermission] = useState<any>()
  const profile: any = JSON.parse(getProfile() || "{}");
  const { t } = useTranslation();
  const { id: id } = useParams<any>();
  const singpad = useRef<SignatureCanvas>(null);
  const getDetail = async () => {

    await EmployeeApi.getdetail(Number(JSON.parse(decode(profile))?.empId)).then((res: any) => {
      if (res.status === 200) {
       
        setEmail(res?.data.email);
        setEmployeeCode(res?.data.employeeCode);
        setFirstName(res?.data.firstname);
        setLastName(res?.data.lastname);
        setIdCard(res?.data.idCard);
        setPrefixId(res?.data.prefixId);
        setBirthDay(res?.data.dob);
        setMobilePhone(res?.data.mobilePhone);
        setRoleId(res?.data.roleId);
        setPermission(res?.data?.permission)
        setBranchId(res?.data.permission[0].branchId);
        if (res.data.fileUpload !== null) {
          setFile(
            fileUrl +
              res.data.fileUpload.filePath +
              res.data.fileUpload.fileName
          );
        }
        if (res?.data?.fileUploadSignature !== null) {
          setSigimg(
            fileUrl +
              res?.data?.fileUploadSignature?.filePath +
              res.data?.fileUploadSignature?.fileName
          );
        }
      } else {
      }
    });
  };
  const handleSubmit = async () => {
    const condition = {
      roleId: +roleId,
      prefixId: +prefixId,
      employeeCode: employeeCode,
      firstname: firstName,
      branchId: branchId,
      lastname: lastName,
      registerDate: registerDate,
      idCard: idCard.split("-").join(""),
      dob: birthday,
      email: email,
      mobilePhone: mobilePhone,
      // permission: Object.keys(permission).map((key) => permission[key]),
      permission: permission,

      status: "ACTIVE",
    };

   await EmployeeApi.updateDetail(condition, JSON.parse(decode(profile))?.empId).then((res) => {
      if (res.status === 200) {

          if (res?.data?.refId) {
            let ref = res.data.refId || null;

            if (SignatureDataURL && file) {
              const formDatasignature = new FormData();
              formDatasignature.append("refId", `${ref}`);
              formDatasignature.append("fileType", "employee-signature");
              formDatasignature.append("file", SignatureDataURL);
              FileuploadApi.uploadfile(formDatasignature).then((result) => {});

              const formData = new FormData();
              formData.append("refId", `${ref}`);
              formData.append("fileType", `${res?.data?.fileType}`);
              formData.append("file", profileimg);

              FileuploadApi.uploadfile(formData).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else if (file !== null) {
              const formData = new FormData();
              formData.append("refId", `${ref}`);
              formData.append("fileType", `${res?.data?.fileType}`);
              formData.append("file", profileimg);

              FileuploadApi.uploadfile(formData).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else if (SignatureDataURL !== null) {
              const formDatasignature = new FormData();
              formDatasignature.append("refId", `${ref}`);
              formDatasignature.append("fileType", "employee-signature");
              formDatasignature.append("file", SignatureDataURL, "image.jpg");
              FileuploadApi.uploadfile(formDatasignature).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else {
              notiSuccess(t(`ADMIN.NOTI.SUCESSS`), "go", "/admin-manage", null);
            }
          } else {
            notiError(t("ADMIN.NOTI.UNSUCESSS"));
            // notiSuccess(t(`ADMIN.NOTI.SUCESSS`), "go", "/admin-manage", null);
          }
      } else {
        notiError(t("ADMIN.NOTI.UNSUCESS"));
      }
    }).catch((error) => {
      swalError(error.response.data.error_description || 'อัพเดตข้อมูล ไม่สำเร็จ');
    })
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileName = file?.name.split(".");
      const detailImage = {
        fileId: null,
        base64: "",
        fileExtension: fileName[fileName?.length - 1],
        fileSize: file.size.toString(),
      };
      if (
        detailImage.fileExtension === "png" ||
        detailImage.fileExtension == "jpg" ||
        detailImage.fileExtension == "pdf"
      ) {
        setFile(URL.createObjectURL(acceptedFiles[0]));
        setProFile(acceptedFiles[0]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onDropSignature = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileName = file?.name.split(".");
      const detailImage = {
        fileId: null,
        base64: "",
        fileExtension: fileName[fileName?.length - 1],
        fileSize: file.size.toString(),
      };
      if (
        detailImage.fileExtension === "png" ||
        detailImage.fileExtension == "jpg" ||
        detailImage.fileExtension == "pdf"
      ) {
        setSigimg(URL.createObjectURL(acceptedFiles[0]));
        setSignatureDataURL(acceptedFiles[0]);
        // setSignatureDataURL(null)
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [sigimg]
  );
  const { getRootProps: getPropsSignature, getInputProps: getInputPropsSignature } = useDropzone({ onDrop: onDropSignature });
    
  const handleEnd = () => {
    if (singpad.current) {
      const dataUrl = singpad.current.toDataURL();
      const data = dataURItoBlob(dataUrl);
      console.log(dataUrl)
      setSignatureDataURL(dataUrl);
    }
  };
  const dataURItoBlob = (dataURI: string) => {
    const byteString = decode(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const handleUploadsig = () => {
    setCheckfile(2);
  };
  const handleClear = () => {
    if (singpad.current) {
      singpad.current.clear();
      setSignatureDataURL("");
    } else {
      setCheckfile(0);
      setSigimg("");
    }
  };

  const getBranch = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
      status: "ACTIVE",
    };
    const res = await BranchApi.findAll(condition);
    if (res.status === 200) {
      setBranch(res.data);
    }
  };
  const getRole = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
    };
    const res = await RoleApi.findAll(condition);
    setAddminType(res.data);
  };
  const getPrefix = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
    };
    const res = await PrefixApi.findAll(condition);
    setPrefixData(res.data);
  };

  useEffect(() => {
    getRole();
    getPrefix();
    getBranch();
  }, []);
  useEffect(() => {
    if (JSON.parse(decode(profile))?.userId) {
      getDetail();
    }
  }, []);

  return (
    <>
      <HeaderCard text={t("ADMIN.PROFILE")} />
      <Box>
        <Boxconteinerin>
          <Boxsolid>
            <Row>
              <Col lg={3} xs={12}>
                <Boximg>
                  {file ? (
                    <img src={file} width="80" height="80" />
                  ) : (
                    <Boxcamera>
                      <Camera />
                    </Boxcamera>
                  )}

                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {file == null && (
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          marginTop: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {t("CREATEADMIN.ADDIMG")}
                      </Typography>
                    )}
                  </div>
                  {file !== null && (
                    <Typography
                      onClick={() => {
                        setProFile(null)
                        setFile(null)
                      }}
                      sx={{
                        textDecoration: "underline",
                        marginTop: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {t("CREATEADMIN.DELETEIMG")}
                    </Typography>
                  )}
                  {id && (
                    <>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          marginTop: "32px",
                          fontSize: "18px",
                        }}
                      >
                        {firstName} {lastName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "15px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          รหัสพนักงาน: {employeeCode}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          อีเมล: {email}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          เบอร์โทร: {mobilePhone}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Boximg>
              </Col>
              <Col lg={9} xs={12}>
                <Boxright>
                  <Container maxWidth="lg" sx={{paddingBottom:"40px"}}>
                    <TypographyHead>
                      {t("CREATEADMIN.ADMIN_DETAIL")}
                    </TypographyHead>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <AutocompleteSelect
                          options={addminType?.filter(
                            (item: any) => item.status === "ACTIVE"
                          )}
                          getOptionLabel={(option: any) => option.roleName}
                          placeholder="กรุณาเลือกบทบาท"
                          labelId="addmin-type"
                          disabled={true}
                          value={
                            addminType?.find(
                              (data: any) => data.roleId === roleId
                            ) || null
                          }
                          heading={t("CREATEADMIN.ADMIN_TYPE")}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setRoleId(newValue.roleId);
                            }
                          }}
                          required
                          helperText={errorMessage.ROLID}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="userid"
                          required={true}
                          value={employeeCode}
                          onchange={(e) => setEmployeeCode(e.target.value)}
                          helperText={errorMessage.EMPLOYEE_CODE}
                          heading={t("CREATEADMIN.USER_ID")}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          required={true}
                          heading={t("CREATEADMIN.CREATE_DATE")}
                          key={"date-appointment"}
                          label=""
                          disabled
                          value={moment(registerDate).format("DD/MM/YYYY")}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col lg={2} md={12} xs={12}>
                        <AutocompleteSelect
                          options={prefixData}
                          getOptionLabel={(option: any) => option.prefixNameTh}
                          labelId="prefix-name"
                          //disabled={location.state.params.isView}
                          value={
                            prefixId
                              ? prefixData.find(
                                  (data: any) => data?.prefixId === prefixId
                                )
                              : null
                          }
                          heading={t("CREATEADMIN.PREFIX")}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setPrefixId(newValue.prefixId);
                            }
                          }}
                          required
                          helperText={errorMessage.PRFIX_NAME}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="name"
                          required={true}
                          //disabled={location.state.params.isView}
                          value={firstName}
                          onchange={(e) => setFirstName(e.target.value)}
                          helperText={errorMessage.NAME}
                          heading={t("CREATEADMIN.NAME")}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="lastname"
                          value={lastName}
                          //disabled={location.state.params.isView}
                          onchange={(e) => setLastName(e.target.value)}
                          required={true}
                          heading={t("CREATEADMIN.LAST_NAME")}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <Typography>
                          {t("CREATEADMIN.CARD_ID")}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>

                        <CustomPatternFormat
                          format={"#-####-#####-#-##"}
                          mask={"_"}
                          allowEmptyFormatting={true}
                          value={idCard}
                          onChange={(e: any) => setIdCard(e.target.value)}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputDatePicker
                          dateFormat="DD/MM/YYYY"
                          required={true}
                          key={"BIRTHDAY"}
                          //disabled={location.state.params.isView}
                          label=""
                          value={birthday}
                          onClear={() => setBirthDay("")}
                          onChange={(e: any) =>
                            setBirthDay(moment(e).format("YYYY-MM-DD"))
                          }
                          heading={t("CREATEADMIN.BIRTHDAY")}
                        />
                      </Col>
                      <Col lg={2} md={12} xs={12}>
                        <InputTextField
                          key="year"
                          required={true}
                          value={
                            birthday ? moment().diff(birthday, "years") : null
                          }
                          disabled={true}
                          heading={t("CREATEADMIN.YEAR")}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="phonenumber"
                          required={true}
                          //disabled={location.state.params.isView}
                          value={mobilePhone}
                          inputProps={{
                            maxlength: 10,
                          }}
                          onchange={(e) => setMobilePhone(e.target.value)}
                          onkeypress={(even) => {
                            if (!/[0-9]/.test(even.key)) {
                              even.preventDefault();
                            }
                          }}
                          helperText={errorMessage.NAME}
                          heading={t("CREATEADMIN.PHONE_NUMBER")}
                        />
                      </Col>

                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="email"
                          required={true}
                          type="email"
                          value={email}
                          //disabled={location.state.params.isView}
                          helperText={errorMessage.NAME}
                          onchange={(e) => setEmail(e.target.value)}
                          heading={t("CREATEADMIN.EMAIL")}
                        />
                      </Col>

                      <Col lg={4} md={12} xs={12}>
                        <AutocompleteSelect
                          options={branch}
                          //disabled={location.state.params.isView}
                          getOptionLabel={(option: any) => option.branchNameTh}
                          labelId="Branch-name"
                          value={
                            branchId
                              ? branch?.find(
                                  (data: any) => data?.branchId === branchId
                                )
                              : null
                          }
                          heading={t("CREATEADMIN.BRANCH")}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setBranchId(newValue.branchId);
                            }
                          }}
                          required
                          helperText={errorMessage.PREFIX}
                        />
                      </Col>
                    </Row>
                    <TypographyHead>
                      {t("PASSWORD.SET_PASSWORD")}
                    </TypographyHead>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <InputPasswordField
                          value={password}
                          //disabled={location.state.params.isView}
                          required={true}
                          onchange={(e) => setPassword(e.target.value)}
                          helperText={errorMessage.NAME}
                          heading={t("PASSWORD.PASSWORD")}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputPasswordField
                          key="confirm"
                          //disabled={location.state.params.isView}
                          required={true}
                          value={passwordConfirm}
                          onchange={(e) => setPasswordConfirm(e.target.value)}
                          helperText={errorMessage.NAME}
                          heading={t("PASSWORD.CONFIRM_PASSWORD")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} xs={12}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "250px",
                          }}
                        >
                          {sigimg ? (
                            <Box sx={{ display: "flex", width: "100%" }}>
                              <img
                                src={sigimg}
                                style={{ width: "100%", maxWidth: "100%" }}
                              />{" "}
                            </Box>
                          ) : (
                            <SignatureCanvas
                              backgroundColor="#ffff"
                              canvasProps={{ className: "sigCanvas" }}
                              ref={singpad}
                              onEnd={handleEnd}
                            />
                          )}
                        </div>
                      </Col>
                      <Col lg={2} md={3} xs={4} className="my-2">
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <ButtonCustom
                            textButton="Clear"
                            onClick={handleClear}
                          
                            btnStyle={{
                              marginBottom: "14px",
                              justtifyContent: "center",
                            }}
                          />
                          <div {...getPropsSignature()}>
                            <input {...getInputPropsSignature()} />

                            <ButtonCustom
                              textButton="Upload"
                              onClick={handleUploadsig}
                              
                              btnStyle={{
                                marginBottom: "14px",
                                justtifyContent: "center",
                              }}
                            />
                          </div>
                        </Box>
                      </Col>
                    </Row>
                    <ButtonCustom
                      textButton="เสร็จสิ้น"
                      btnStyle={{ width: "100%" }}
                      onClick={handleSubmit}
                      //disabled={location.state.params.isView}
                    />
                  </Container>
                </Boxright>
              </Col>
            </Row>
          </Boxsolid>
        </Boxconteinerin>
      </Box>
    </>
  );
}
