import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from '@mui/material';
import { colors } from "../../../constants/colors";
import { background } from '../../../constants/images';
import Header from '../../../component/header/header';
import { Row } from 'react-bootstrap';

export const BoxUploadImgContainer = styled(Box)(({ theme }) => ({
    marginTop: 24,
    backgroundColor: colors.lightGrayBG,
    borderRadius: 2,
    display: "flex",
    justifyContent: "space-around",
    padding: 24,
    [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
}));

export const ContainerUpload = styled(Box)(({ theme }) => ({
    padding: "32px 8px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
}));

export const ContainerBoxUpload = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
}));

export const BoxModal = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "start",
}));

export const SubDetail = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: 16,
    whiteSpace:'nowrap'
}));

export const BoxUpload = styled(Box)(({ theme }) => ({
    // borderStyle: "dashed",
    borderColor: colors.disabledGray,
    width: '100%',
    height: 304,
    objectFit: "contain"
}));

export const BoxUploadImage = styled('img')(({ theme }) => ({
    width: '100%',
    height: 300,
    objectFit: "contain"
}));