import BaseAPI from "../api";

const path = "invoice";

export interface InvoiceInterface {
  page?: number;
  pageLimit?: number;
  dateStart?: string;
}

interface graphInterface {
  dateStart: string
  dateEnd: string
  branchId: number
  leasesTypeId: number
}

interface InvoicePayInterface {
  branchId: number,
  invoiceId: number,
  invoiceTypeId: number,
  invoicePayDate: string,
  invoicePayAmount: number
}
interface invoiceDetailType {
  sequential: number,
  paymentServiceId: number,
  description: string,
  amount: number;
}
interface invoiceTransferType {
  branchId: number,
  invoiceDateStart: string | Date,
  invoiceDateEnd: string | Date,
  leaseAgreementId: number,
  leaseAgreementTypeId: number,
  zoneId: number,
  stallId: number,
  status: string,
  invoiceDetail: invoiceDetailType[]
}

export default class InvoiceApi extends BaseAPI {
  static findAll(params: InvoiceInterface): Promise<any> {
    return this.api.get(path, { params: { ...params } }).then((res) => res);
  }
  static creatInvoice(props: any): Promise<any> {
    return this.api.post(path, props).then((res) => res);
  }
  static transferInvoice(props: invoiceTransferType): Promise<any> {
    return this.api.post(`${path}/tranfer`, props).then(res => res);
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`${path}/${id}`).then((res) => res);
  }

  static updateAll(props: any): Promise<any> {
    return this.api.patch(`${path}/status`, props).then((res) => res);
  }

  static updateById(props: any, id: number): Promise<any> {
    return this.api.patch(`${path}/${id}`, props)
  }

  static detail(id: any): Promise<any> {
    return this.api.get(`${path}/${id}`, id).then((res) => res);
  }

  static graph(params: graphInterface): Promise<any> {
    return this.api.get(`${path}/calculation`, { params: { ...params } }).then((res) => res);
  }

  static bank(id: number): Promise<any> {
    return this.api.get(`${path}/${id}/bank`, { responseType: 'arraybuffer' });
  }

  static pay(payload: InvoicePayInterface): Promise<any> {
    return this.api.post(`${path}/pay`, payload);
  }

  static updateStatus(id: number, payload: any): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, { status: payload })
  }
  static updateApprove(id: number, payload: any): Promise<any> {
    return this.api.patch(`${path}/${id}/approve`, payload)
  }

  static updateStatusNotApprove(id: number, payload: any): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, payload)
  }

  static receipt(id: number): Promise<any> {
    return this.api.get(`${path}/${id}/receipt`, { responseType: 'arraybuffer' })
  }
}