import { useTranslation } from "react-i18next";
/** COMPONENT */
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from '../cancel.Style'
import { Col, Row } from "react-bootstrap";
import { Divider } from "@mui/material";

/** UTIL */
import { dateToView } from "../../../utils/date.utils";

type PropsDetail = {
  data?: any,
  status?: string
}

export default function Detail(props: PropsDetail) {
  const { t } = useTranslation();
  const d = props?.data
 
  return (
    <ModalCustom
      title={t("CANCELCONTRACT.TITLE_CANCEL_STALL")}
      size="xl"
      closeButton={true}
      modalScroll
      component={
        <div className="pb-2">

          <Style.ContainerModal>
            <div>
              <Row className="mt-lg-3">
                <Col>
                  <Style.Title>{t('CANCELCONTRACT.POPUP.DETAIL')}</Style.Title>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_NO')}</Style.SubDetail>
                  <Style.SubDetail>{d?.cancelAgreementNo}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.NAME')}</Style.SubDetail>
                  <Style.SubDetail className="mr-2">{d?.leaseAgreement?.firstname}</Style.SubDetail>
                  <Style.SubDetail>{d?.leaseAgreement?.lastname}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_DATE')}</Style.SubDetail>
                  <Style.SubDetail>{dateToView(d?.cancelAgreementDate)}</Style.SubDetail>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.RENT_DATE')}</Style.SubDetail>
                  <Style.SubDetail>{dateToView(d?.leaseAgreement?.leaseAgreementDateStart)}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.CONTRACT_END_DATE')}</Style.SubDetail>
                  <Style.SubDetailAlert>{dateToView(d?.leaseAgreement?.leaseAgreementDateEnd)}</Style.SubDetailAlert>
                </Col>
              </Row>

              <Row className="mt-3 ">
                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.ZONE')}</Style.SubDetail>
                  <Col lg={3} />
                  <Style.SubDetail>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.STALL')}</Style.SubDetail>
                  <Col lg={3} />
                  <Style.SubDetail>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}</Style.SubDetail>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-lg-5">{t('CANCELCONTRACT.POPUP.TYPEZONE')}</Style.SubDetail>
                  <Style.SubDetail>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName}</Style.SubDetail>
                </Col>


                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.PAYMENT_CYCLE')}</Style.SubDetail>
                  <Style.SubDetail>{t('รายเดือน')}</Style.SubDetail>
                </Col>
              </Row>
          
            </div>

          </Style.ContainerModal>
        </div>
      }
    />
  )
}