import IconProps from "./iconProps"
import { colors } from "../../constants/colors"
export default function Repair(props: IconProps) {
    return (
<svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_460_29382)">
        <path d="M54.6254 7.12421H30.8754C30.2455 7.12421 29.6414 7.37443 29.196 7.81983C28.7506 8.26523 28.5004 8.86932 28.5004 9.49921V17.2654L22.8004 7.38546C22.3845 6.66008 21.7844 6.05735 21.0609 5.63819C20.3373 5.21903 19.516 4.99829 18.6798 4.99829C17.8436 4.99829 17.0222 5.21903 16.2987 5.63819C15.5752 6.05735 14.9751 6.66008 14.5592 7.38546L0.309157 32.0617C0.100707 32.4227 -0.0090332 32.8323 -0.0090332 33.2492C-0.0090332 33.6661 0.100707 34.0756 0.309157 34.4367C0.518404 34.7991 0.819706 35.0998 1.18255 35.3083C1.5454 35.5169 1.95691 35.6258 2.37541 35.6242H12.5642C12.103 37.1652 11.871 38.7656 11.8754 40.3742C11.8754 44.7834 13.627 49.012 16.7448 52.1298C19.8626 55.2476 24.0912 56.9991 28.5004 56.9991C32.9096 56.9991 37.1383 55.2476 40.2561 52.1298C43.3739 49.012 45.1254 44.7834 45.1254 40.3742C45.1299 38.7656 44.8978 37.1652 44.4367 35.6242H54.6254C55.2553 35.6242 55.8594 35.374 56.3048 34.9286C56.7502 34.4832 57.0004 33.8791 57.0004 33.2492V9.49921C57.0004 8.86932 56.7502 8.26523 56.3048 7.81983C55.8594 7.37443 55.2553 7.12421 54.6254 7.12421ZM6.48416 30.8742L18.6679 9.76046L26.8142 23.7492C24.439 24.0135 22.1487 24.7868 20.0995 26.0164C18.0502 27.2459 16.2901 28.9028 14.9392 30.8742H6.48416ZM28.5004 52.2492C26.1518 52.2492 23.8558 51.5527 21.903 50.2479C19.9502 48.943 18.4281 47.0884 17.5293 44.9185C16.6305 42.7487 16.3954 40.361 16.8536 38.0575C17.3118 35.754 18.4428 33.638 20.1035 31.9773C21.7643 30.3165 23.8802 29.1856 26.1837 28.7274C28.4872 28.2692 30.8749 28.5043 33.0448 29.4031C35.2146 30.3019 37.0693 31.8239 38.3741 33.7768C39.679 35.7296 40.3754 38.0255 40.3754 40.3742C40.3754 43.5236 39.1243 46.5441 36.8973 48.7711C34.6703 50.998 31.6499 52.2492 28.5004 52.2492ZM52.2504 30.8742H42.1329C39.9761 27.7949 36.8483 25.5285 33.2504 24.4379V11.8742H52.2504V30.8742Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
    </g>
    <defs>
        <clipPath id="clip0_460_29382">
            <rect width="57" height="57" fill="white" />
        </clipPath>
    </defs>
</svg>
    )}