import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {mofLocaleTH} from './locales/th/mof_th.locale'
import {mofLocaleEN} from './locales/en/mof_en.locale'

const resources = {
    th: {
      translation: mofLocaleTH
    },
    en: {
      translation: mofLocaleEN
    }
  }
  
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: 'th',
      interpolation: {
        escapeValue: false 
      }
    })
  
  export default i18n