// route


import CancelContact from "../assets/slide-menu.tsx/cancel-contact";
import Certificate from "../assets/slide-menu.tsx/certificate";
import ElectricityBill from "../assets/slide-menu.tsx/electricity-bill";
import LeaseHold from "../assets/slide-menu.tsx/leasehold";
import PayBill from "../assets/slide-menu.tsx/pay-bill";
import Stall from "../assets/slide-menu.tsx/stall";
import Repair from "../assets/slide-menu.tsx/repair";
import Store from "../assets/slide-menu.tsx/store";
import Sue from "../assets/slide-menu.tsx/sue";
import TrackBill from "../assets/slide-menu.tsx/track-bill";
import { routeName } from "../routes/routers-name";
import Announce from "../assets/slide-menu.tsx/announce";
import Admin from "../assets/slide-menu.tsx/admin";
import Settings from "../assets/slide-menu.tsx/settings";
import SystemLog from "../assets/slide-menu.tsx/systemLog";
import Statistic from "../assets/slide-menu.tsx/statistic";
import TransferApporve from "../assets/slide-menu.tsx/tranfer-apporve";
import Report from "../assets/slide-menu.tsx/report";


export enum MENUS_FIRST {
    STORE = 'STORE',
    STALL = 'STALL',
    TRANFER_APPOVE = 'TRANFER_APPOVE',
    UTILITY_BILL = 'UTILITY_BILL',
    PAY_BILL = 'PAY_BILL',
    TRACK_BILL = 'TRACK_BILL',
    REPAIR = 'REPAIR',
    TRANSFER_STALL = 'TRANSFER_STALL',
    CERTIFICATE = 'CERTIFICATE',
    SUE = 'SUE',
    ANNOUNCE = 'ANNOUNCE',
    CANCEL_CONTACT = 'CANCEL_CONTACT',
    SETTINGS = 'SETTINGS',
    SYSTEM_LOG = 'SYSTEM_LOG',
    STATISTIC = 'STATISTIC',
    REPORT = 'REPORT',
}

export const defaultMenu: any = {
    HOME: { key: 'HOME', name: 'Home', textName: 'Home', active: false, path: routeName.home },
}

export const menuFirsts: any = {
    STORE: { key: 'STORE', name: 'Store', textName: 'MENU.STORE', icon: <Store color="#14378A"/>, active: false, path: '' },
    STALL: { key: 'STALL', name: 'Stall', textName: 'MENU.STALL', icon: <Stall />, active: false, path: '' },
    TRANFER_APPOVE: { key: 'TRANFER_APPOVE', name: 'TranferApporve', textName: 'MENU.TRANFER_APPOVE', icon: <TransferApporve />, active: false, path: routeName.tranferApporve },
    UTILITY_BILL: { key: 'UtilityBill', name: 'UtilityBill', textName: 'MENU.UTILITY_BILL', icon: <ElectricityBill />, active: false, path: '' },
    PAY_BILL: { key: 'PAY_BILL', name: 'PayBill', textName: 'MENU.PAY_BILL', icon: <PayBill />, active: false, path: routeName.payBill },
    TRACK_BILL: { key: 'TRACK_BILL', name: 'TrackBill', textName: 'MENU.TRACK_BILL', icon: <TrackBill />, active: false, path: routeName.trackBill },
    REPAIR: { key: 'REPAIR', name: 'Repair', textName: 'MENU.REPAIR', icon: <Repair />, active: false, path: routeName.repair },
    TRANSFER_STALL: { key: 'TRANSFER_STALL', name: 'TransferStall', textName: 'MENU.TRANSFER_STALL', icon: <LeaseHold />, active: false, path: routeName.transferStall },
    CERTIFICATE: { key: 'CERTIFICATE', name: 'Certificate', textName: 'MENU.CERTIFICATE', icon: <Certificate />, active: false, path: routeName.certificate },
    SUE: { key: 'SUE', name: 'Sue', textName: 'MENU.SUE', icon: <Sue />, active: false, path: routeName.sue },
    ANNOUNCE: { key: 'ANNOUNCE', name: 'Announce', textName: 'MENU.ANNOUNCE', icon: <Announce />, active: false, path: routeName.announce },
    CANCEL_CONTACT: { key: 'CANCEL_CONTACT', name: 'CancelContract', textName: 'MENU.CANCEL_CONTRACT', icon: <CancelContact />, active: false, path: routeName.cancelContract },
    ADMIN: { key: 'ADMIN', name: 'Admin', textName: 'MENU.ADMIN', icon: <Admin />, active: false, path: '' },
    SETTINGS: { key: 'SETTINGS', name: 'settings', textName: 'MENU.SETTINGS', icon: <Settings />, active: false, path: '' },
    SYSTEM_LOG: { key: 'SYSTEM_LOG', name: 'SystemLog', textName: 'System Log ', icon: <SystemLog />, active: false, path: routeName.systemLog },
    STATISTIC: { key: 'STATISTIC', name: 'Statistic', textName: 'MENU.STATISTIC', icon: <Statistic />, active: false, path: routeName.statistic },
    REPORT: { key: 'REPORT', name: 'Report', textName: 'MENU.REPORT', icon: <Report />, active: false, path: routeName.report }
};

export const menuSeconds: any = {
    HOME: { key: 'HOME', name: 'Home', textName: 'MENU.HOME', icon: null, active: false, disabled: true, isHidden: true },
    CONTACT_US: { key: 'CONTACT_US', name: 'ContactUs', textName: 'MENU.CONTACT_US', icon: null, active: false, disabled: true, isHidden: true },
    CALCULATION: { key: 'CALCULATION', name: 'Calculation', textName: 'MENU.CALCULATION', icon: null, active: false, disabled: true, isHidden: true },
    EDIT_PROFILE: { key: 'EDIT_PROFILE', name: 'EditProfile', textName: 'MENU.EDIT_PROFILE', icon: null, active: false, disabled: true, isHidden: true },
    DETAIL_INFO: { key: 'DETAIL_INFO', name: 'DetailInfo', textName: 'MENU.DETAIL_INFO', icon: null, active: false, disabled: true, isHidden: true },
    // SELECT_BRANCH: {key: 'SELECT_BRANCH', name: 'selectBrach', textName: 'MENU.CALCULATION', icon: null, active: false, disabled: true, isHidden: true },

    /**  STORE */
    MARKET_MAP: { key: 'MARKET_MAP', name: 'MarketMap', textName: 'MENU.MARKET_MAP', active: false, path: routeName.marketMap },
    STALL_REPORT: { key: 'STALL_REPORT', name: 'StallReport', textName: 'MENU.STALL_REPORT', active: false, path: routeName.stallReport },

    // NOTI_RENT_STALL: { key: 'NOTI_RENT_STALL', name: 'NotiRentStall', textName: 'MENU.NOTI_RENT_STALL', active: false, path: routeName.notiRentStall },
    /**  AGREEMENT */
    REQUEST_FOR_STALL: { key: 'REQUEST_FOR_STALL', name: 'RequestForStall', textName: 'MENU.REQUEST_FOR_STALL', active: false, path: routeName.requestForStall },
    LIST_RENT_STALL: { key: 'LIST_RENT_STALL', name: 'ListRentStall', textName: 'MENU.LIST_RENT_STALL', active: false, path: routeName.listRentStall },
    DEPOSIT_PAY: { key: 'DEPOSIT_PAY', name: 'DepositPay', textName: 'MENU.DEPOSIT_PAY', active: false, path: routeName.depositPay },
    STALL_AGREEMENT: { key: 'STALL_AGREEMENT', name: 'StallAgreement', textName: 'MENU.STALL_AGREEMENT', active: false, path: routeName.stallAgreement },
    REPORT_RENT_STALL: { key: 'REPORT_RENT_STALL', name: 'ReportRentStall', textName: 'MENU.REPORT_RENT_STALL', active: false, path: routeName.reportRentStall },
    /**  ELECTRICITY_BILL */
    MANAGEMENT_UTILITY: { key: 'MANAGEMENT_UTILITY', name: 'MANAGEMENT_UTILITY', textName: 'MENU.MANAGEMENT_UTILITY', active: false, path: routeName.management_utility },
    WATER_BILL: { key: 'WATER_BILL', name: 'WaterBill', textName: 'MENU.WATER_BILL', active: false, path: routeName.waterBill },
    ELECTRICITY_BILL: { key: 'ELECTRICITY_BILL', name: 'ElectrucityBill', textName: 'MENU.ELECTRICITY_BILL', active: false, path: routeName.electrucityBill },

    PAY_BILL_LIST: { key: 'PAY_BILL_LIST', name: 'PayBillList', textName: 'รายการชำระเงิน', active: false, path: routeName.payBill },
    PAY_BILL_INVOICE: { key: 'PAY_BILL_INVOICE', name: 'PayBillInvoice', textName: 'ยืนยันการออกใบแจ้งหนี้', active: false, path: routeName.payBill + "/invoice"},

    // /**  PAY_BILL */
    // PAY_BILL: { key: 'PAY_BILL', name: 'PayBill', textName: 'MENU.PAY_BILL', active: false, path: routeName.payBill },

    /**  TRACK_BILL */
    // TRACK_BILL: { key: 'TRACK_BILL', name: 'TrackBill', textName: 'MENU.TRACK_BILL', active: false, path: routeName.trackBill },

    /**  REPAIR */
    // REPAIR: { key: 'REPAIR', name: 'Repair', textName: 'MENU.REPAIR', active: false, path: routeName.repair },

    /**  TRANSFER_STALL */
    // TRANSFER_STALL: { key: 'TRANSFER_STALL', name: 'TransferStall', textName: 'MENU.TRANSFER_STALL', active: false, path: routeName.transferStall },

    /**  CERTIFICATE */
    // CERTIFICATE: { key: 'CERTIFICATE', name: 'Certificate', textName: 'MENU.CERTIFICATE', active: false, path: routeName.certificate },

    /**  SUE */
    // SUE: { key: 'SUE', name: 'Sue', textName: 'MENU.SUE', active: false, path: routeName.sue },

    /**  ANNOUNCE */
    // ANNOUNCE: { key: 'ANNOUNCE', name: 'Announce', textName: 'MENU.ANNOUNCE', active: false, path: routeName.announce },

    /**  CANCEL_CONTACT */
    // CANCEL_CONTACT: { key: 'CANCEL_CONTACT', name: 'CancelContact', textName: 'MENU.CANCEL_CONTACT', active: false, path: routeName.cancelContact },

    
    /**  ADMIN */
    ADMIN_MANAGEMENT: { key: 'ADMIN_MANAGEMENT', name: 'AdminManagement', textName: 'MENU.ADMIN', active: false, path: routeName.adminManage },
    ADMIN_TYPE: { key: 'ADMIN_TYPE', name: 'AdminType', textName: 'MENU.ADMIN_TYPE', active: false, path: routeName.adminType },
    CUSTOMER_MANAGEMENT: { key: 'CUSTOMER_MANAGEMENT', name: 'CUSTOMER_MANAGEMENT', textName: 'MENU.CUSTOMER_MANAGEMENT', active: false, path: routeName.customer },

    /**  SETTINGS */
    UTILITY_MANAGEMENT: { key: 'UTILITY_MANAGEMENT', name: 'UtiltyManagement', textName: 'MENU.UTILITY_MANAGEMENT', active: false, path: '' },
    DEFAULT_USER_MANAGEMENT: { key: 'DEFAULT_USER_MANAGEMENT', name: 'WaterManagement', textName: 'MENU.DEFAULT_USER_MANAGEMENT', active: false, path: '' },
    STALL_MANAGERMENT: { key: 'STALL_MANAGERMENT', name: 'StallManagement', textName: 'MENU.STALL_MANAGERMENT', active: false, path: '' },
    BRANCH_MANAGERMENT: { key: 'BRANCH_MANAGERMENT', name: 'BranchManagement', textName: 'MENU.BRANCH_MANAGERMENT', active: false, path: '' },
    ANNOUNCE_MANAGERMENT: { key: 'ANNOUNCE_MANAGERMENT', name: 'AnnounceManagement', textName: 'MENU.ANNOUNCE_MANAGERMENT', active: false, path: '' },


    /**  SYSTEM_LOG */
    // SYSTEM_LOG: { key: 'SYSTEM_LOG', name: 'SystemLog', textName: 'MENU.SYSTEM_LOG', active: false, path: routeName.systemLog },
    /**  STATISTIC */
    // STATISTIC: { key: 'STATISTIC', name: 'Statistic', textName: 'MENU.STATISTIC', active: false, path: routeName.statistic },
}

export const menuThirds: any = {
    WATER_MANAGEMENT: { key: 'WATER_MANAGE', name: 'WaterManagement', textName: 'MENU.WATER_MANAGEMENT', active: false, path: routeName.waterManagement },
    ELECTRUCITY_MANAGEMENT: { key: 'ELECTRUCITY_MANAGE', name: 'ElectrucityManagement', textName: 'MENU.ELECTRUCITY_MANAGEMENT', active: false, path: routeName.electrucityManagement },
    OPEN_ROUND: { key: 'OPEN_ROUND', name: 'Open_Round', textName: 'MENU.OPEN_ROUND', active: false, path: routeName.openRound },

    USER_PREFIX: { key: 'USER_PREFIX', name: 'UserPrefixes', textName: 'MENU.USER_PREFIX', active: false, path: routeName.userPrefixes },
    USER_RELATIONSHIPS: { key: 'USER_RELATIONSHIPS', name: 'UserRelationships', textName: 'MENU.USER_RELATIONSHIPS', active: false, path: routeName.userRelationships },

    DEBT_TYPE: { key: 'DEBT_TYPE', name: 'DebtType', textName: 'MENU.DEBT_TYPE', active: false, path: routeName.debtType },
    LEASES_TYPE: { key: 'LEASES_TYPE', name: 'LeasesType', textName: 'MENU.LEASES_TYPE', active: false, path: routeName.leasesType },
    MAINTENANCE_TYPE: { key: 'MAINTENANCE_TYPE', name: 'MaintenanceType', textName: 'MENU.MAINTENANCE_TYPE', active: false, path: routeName.maintenanceType },

    STALL_TYPE: { key: 'STALL_TYPE', name: 'StallType', textName: 'MENU.STALL_TYPE', active: false, path: routeName.stallType },
    BIDDER_TYPE: { key: 'BIDDER_TYPE', name: 'BidderType', textName: 'MENU.BIDDER_TYPE', active: false, path: routeName.bidderType },


    BRANCH: { key: 'BRANCH', name: 'branch', textName: 'MENU.BRANCH', active: false, path: routeName.branch },
    NEWS_TYPE: { key: 'NEWS_TYPE', name: 'NewsType', textName: 'MENU.NEWS_TYPE', active: false, path: routeName.newsType },

    REPAIR_MANAGEMENT: { key: 'REPAIR_MANAGE', name: 'RepairManagement', textName: 'MENU.REPAIR_MANAGEMENT', active: false, path: routeName.repairManagement },
    FILE_MANAGEMENT: { key: 'FILE_MANAGE', name: 'FileManagement', textName: 'MENU.FILE_MANAGEMENT', active: false, path: routeName.repairManagement },

};

export const menuInside: any = {};
