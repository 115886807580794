import { useTranslation } from "react-i18next";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import * as Styles from "../transferStall.style";
import { Divider } from "@mui/material";
import { colors } from "../../../constants/colors";
import { Col, Row } from "react-bootstrap";
import { dateTimeToView, dateToView } from "../../../utils/date.utils";

type TransferDetailPopupProps = {
  onclose?: any,
  onsubmit?: any,
  disapprove?: any
  data: any
}

export default function TransferDetailPopup(props: TransferDetailPopupProps) {
  const { t } = useTranslation();

  return (
    <ModalCustom
      title={t('โอนสิทธิเช่าแผงค้า')}
      size="xl"
      closeButton
      onClose={props.onclose}
      onSubmit={props.onsubmit}
      // onReset={props.disapprove}
      // textBtnCancel={t("TRANSFER_STALL.BUTTON.NOT_ACCEPT")}
      textBtnConfirm={t("TRANSFER_STALL.BUTTON.APPOINTMENT")}
      component={
        <Styles.ContainerModal>
          <Divider className="mx-0 my-2" />
          <Row>
            <Col lg={12} xs={12}>
              <Styles.DetailModal>
                {t("TRANSFER_STALL.MODAL.DETAIL")}
              </Styles.DetailModal>
            </Col>
          </Row>
          <Row>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.PETITION")}
              </Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>{props.data?.transferNo || "-"}</Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.NAME_SUBNAME")}
              </Styles.SubDetail>
            </Col>
            <Col lg={4} xs={6}>
              <Styles.SubDetail>{props.data?.leaseAgreement?.firstname || ""} {props.data?.leaseAgreement?.lastname || ""}</Styles.SubDetail>
            </Col>

          </Row>
          <Row className="mt-2">
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.APPLICATION_DATE")}
              </Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>{dateToView(props.data?.transferDate || new Date())}</Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.RENT_DATE")}
              </Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>{dateToView(props?.data?.leaseAgreement?.leaseAgreementDateStart || new Date())}</Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.EXPIRE_DATE")}
              </Styles.SubDetail>
            </Col>
            <Col lg={2} xs={6}>
              <Styles.SubDetail>{dateToView(props?.data?.leaseAgreement?.leaseAgreementDateEnd || new Date())}</Styles.SubDetail>
            </Col>
            <Col lg={2} xs={2} className="d-xs-hidden" />
            <Col lg={2} xs={2} className="d-xs-hidden" />
          </Row>
          <Row className="mt-2">
            <Col lg={3} xs={3}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.ZONE")}
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={3}>
              <Styles.SubDetail>{props.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-"}</Styles.SubDetail>
            </Col>
            <Col lg={3} xs={3}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.STALL")}
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={3}>
              <Styles.SubDetail>{props.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-"}</Styles.SubDetail>
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.ZONE_TYPE")}
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>{props.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName || "-"}</Styles.SubDetail>
            </Col>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>
                {t("TRANSFER_STALL.MODAL.PAYMENT_CYCLE")}
              </Styles.SubDetail>
            </Col>
            <Col lg={3} xs={6}>
              <Styles.SubDetail>{props?.data?.leaseAgreement?.leasesTypeId !== 1 ? "รายเดือน" : 'รายวัน'}</Styles.SubDetail>
            </Col>
          </Row>
          <Divider className="mx-0 my-2" />
          <Row>
            <Col lg={12} xs={12}>
              <Styles.DetailModal>
                {t("TRANSFER_STALL.MODAL.HEIR_DETAIL")}
              </Styles.DetailModal>
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs={12}>
              <Styles.DetailModal sx={{ color: colors.red }}>
                {t("*โปรดตรวจสอบรายการค้างชำระค่าโอนแผงค้าก่อนทำการนัดทำสัญญา")}
              </Styles.DetailModal>
            </Col>
          </Row>
          {/* {_.map(customerHeirData, (d: any, i: number) => {
                  return (
                    <CheckHeir
                    title={t("TRANSFER_STALL.MODAL.CUSTOMER_HEIR") + (i+1)}
                    value={i === 0 ? firstHeir : i === 1 ? secondHeir : thirdHeir}
                    onChange={() => {
                      if(i === 0){
                        setFirstHeir(!firstHeir)
                      }else if(i === 1) {
                        setSecondHeir(!secondHeir)
                      } else {setThirdHeir(!thirdHeir)}
                    }}
                    img={imgs.errNoImg}
                    heirName={d?.firstname + " " + d?.lastname}
                    birthDate={fullDate(d?.birthDay)}
                    year={d?.birthDay && moment().diff(d?.birthDay, "years")}
                    id_number={d?.idCard}
                    email={d?.email}
                    tel={d?.mobilePhone}
                    address={"เลขที่" + d?.houseNumber + " " + "หมู่" + d?.moo + " " + "หมู่บ้าน" + d?.village + " " + "ซอย" + d?.soi 
                    + " " + "ตำบล/แขวง" + d?.subDistrict?.subDistrictNameTh + " " + "อำเภอ/เขต" + d?.district?.districtNameTh
                    + " " + "จังหวัด" + d?.province?.provinceNameTh + " " + d?.zipCode
                  }
                  />
                  )
                })} */}
        </Styles.ContainerModal>
      }
    />
  );
}
