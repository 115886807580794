import HeaderCard from "../../component/cardCustom/headerCard";
import { isApporve, isCreate, isDelete, isExport, isUpdate, isView } from "../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";
import { useCallback, useEffect, useState, } from "react";

/** COMPONENT */
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import FilterSelect from "../../component/select/filterSelect";
import TableRowCommon from "../../component/table/TableRowCommon";
import ButtonCustom from '../../component/button/buttonCustom';
import ModalCustom from "../../component/modalCustom/modalCustom";
import { swalActive } from "../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { icons } from "../../constants/images";

/** STYLE */
import * as Style from "./tranferApporve.style"
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import ApproveApi from "../../api/transferApporve/tranferApprove.api";
import { dateToView } from "../../utils/date.utils";
import EmployeeApi from "../../api/admin/employee/employee.api";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { debouncedApiCall, getProfile } from "../../utils/app.utils";
import InputNewRangePicker from "../../component/input/InputRangePicker";
import moment from "moment";
import { decode } from "js-base64";

const initStateErrorMessage = {
    TO_EMP: ``,
    DATE: ``,
}

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView(),
    isExport: isExport(),
    isApporve: isApporve()
}

export default function TranferApporve() {
    const { t } = useTranslation()
    const profile: any = JSON.parse(getProfile() || "{}");
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
    const [approveId, setApproveId] = useState(Number)
    const [fromEmployeeId, setFromEmployeeId] = useState<number>(0)
    const [toEmployeeId, setToEmployeeId] = useState<number>(0)
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [appoveStatus, setAppoveStatus] = useState<string>('ACTIVE')
    const [selectedDateRange, setSelectedDateRange] = useState<string[]>([]);

    const [approveData, setApporveData] = useState<any[]>([])
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(5)
    const [rowCount, setRowCount] = useState<number>()
    const [sortBy, setSortBy] = useState<string>('modifiedDate')
    const [sortType, setSortType] = useState<string>('ASC')
    const [status, setStatus] = useState<string>('ALL')
    const [searchFromEmployeeId, setSearchFromEmployeeId] = useState<number>(0)
    const [searchToEmployeeId, setSearchToEmployeeId] = useState<number>(0)
    const [selectFromEmployeeId, setSelectFromEmployeeId] = useState<string>("")
    const [keywordLeasesTypeData, setKeywordLeasesTypeData] = useState<string>("")
    const [listAdmin, setListAdmin] = useState<any>([]);

    const loadDataEmployee = useCallback(async () => {
        let condition: any = {
            page: 1,
            pageLimit: 20
        };
        if (keywordLeasesTypeData) condition = { ...condition, search: keywordLeasesTypeData };
        const res = await EmployeeApi.findAll(condition);
        if (res?.status === 200) {
            setListAdmin(res?.data);
        } else {
            setListAdmin([]);
        }
    }, [keywordLeasesTypeData]);

    useEffect(() => {
        loadDataEmployee();
    }, [loadDataEmployee]);


    const loadData = useCallback(async () => {

        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
        if (searchFromEmployeeId) condition = { ...condition, fromEmployee: searchFromEmployeeId }
        if (searchToEmployeeId) condition = { ...condition, toEmployee: searchToEmployeeId }
        const res = await ApproveApi.findAll(condition)
        if (res.status === 200) {
            setRowCount(res.headers['total'])
            setApporveData(res.data)

        } else {
            setRowCount(0)
            setApporveData([])
        }
    }, [page, pageLimit, sortBy, sortType, status, searchFromEmployeeId, searchToEmployeeId])

    useEffect(() => {
        loadData()
    }, [loadData])


    const headCells = [
        { id: "transferApporveId", disablePadding: false, label: "NO", },
        { id: "fromEmployeeId", disablePadding: false, label: 'ผู้โอนสิทธิ', sortable: true },
        { id: "toEmployeeId", disablePadding: false, label: 'ผู้รับโอนสิทธิ', sortable: true },
        { id: "date", disablePadding: false, align: 'center', label: 'วันที่สามารถลงนามได้', sortable: true },
        { id: "status", disablePadding: false, align: 'center', label: 'สถานะ', sortable: true },
        { id: "modifiedId", disablePadding: false, align: 'center', label: 'ผู้บันทึก', sortable: true },
        { id: "action", disablePadding: false, label: 'Action' }
    ];

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { transferApporveId, fromEmployee, toEmployee, startDate, endDate, modifiedBy, modifiedDate, status } = objData;
        const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
        const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
        const objRenderData = {
            key: transferApporveId,
            id: transferApporveId,
            obj: objData,
            columns: [
                { option: "TEXT", align: "left", label: no },
                { option: "TEXT", align: "left", label: `${(modifiedBy && (fromEmployee.firstname && fromEmployee.lastname)) ? fromEmployee.firstname + ' ' + fromEmployee.lastname : fromEmployee?.firstname || fromEmployee?.lastname || '-'}` || "-" },
                { option: "TEXT", align: "left", label: `${(modifiedBy && (toEmployee.firstname && toEmployee.lastname)) ? toEmployee.firstname + ' ' + toEmployee.lastname : toEmployee?.firstname || toEmployee?.lastname || '-'}` || "-" },
                { option: "TEXT", align: "left", label: dateToView(startDate) + '-' + dateToView(endDate) || "-" },
                { option: "STATUS", align: "center", label: status || "-" },
                {
                    option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
                },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
                    ]
                }
            ],

        };
        return (
            <TableRowCommon
                {...objRenderData}
                onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                onedit={() => onEdit(objRenderData.obj)}
                ondelete={() => onDelete(objRenderData.obj)}
            />
        )
    };

    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BIDDERTYPE.ALERT.CONFIRM_STATUS')}</p>`,
            null, (res: any) => {
                if (res) {
                    ApproveApi.updateStatus(id, valueStatus)
                        .then((res) => {
                            notiSuccess(t('BIDDERTYPE.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)

                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('BIDDERTYPE.MESSAGE.ERROR'), '', null, null)

                        })
                }
            })
    }
    const onDelete = (data: any) => {
        const { bidTypeId, bidTypeName, bidTypeNameEn } = data

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BIDDERTYPE.ALERT.CONFIRM_DELETE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BIDDERTYPE.ALERT.CONFIRM_DELETE', {
                bidTypeName: bidTypeName, bidTypeNameEn: bidTypeNameEn ? ' ' + bidTypeNameEn + '' : ''
            })}</p>`,
            (res: any) => {
                if (res) {
                    ApproveApi.delete(bidTypeId)
                        .then((res) => {
                            notiSuccess(t('BIDDERTYPE.MESSAGE.SUCCESS.DELETE'), '', null, null)
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('BIDDERTYPE.MESSAGE.ERROR'), '', null, null)
                        })
                }
            }
        )
    }

    const onEdit = (data: any) => {
        const { fromEmployeeId, toEmployeeId, startDate, endDate, transferApporveId } = data
        setApproveId(transferApporveId)
        setFromEmployeeId(fromEmployeeId)
        setToEmployeeId(toEmployeeId)
        setStartDate(startDate)
        setSelectFromEmployeeId(toEmployeeId)
        setEndDate(endDate)
        dispatch(showModal())
    }

    const resetForm = () => {
        setSelectedDateRange([])
        setFromEmployeeId(0)
        setToEmployeeId(0)
        setStartDate('')
        setEndDate('')
        setAppoveStatus('ACTIVE')
        clearErrorMessage()
    }

    const clearErrorMessage = () => {
        setErrorMessage(initStateErrorMessage)
    }

    const submit = () => {
        if (!toEmployeeId) return setErrorMessage({ ...errorMessage, ...{ TO_EMP: 'กรุณาระบุผู้รับโอน' } })
        if (!startDate) return setErrorMessage({ ...errorMessage, ...{ DATE: 'กรุณาระบุวันที่' } })
        if (!endDate) return setErrorMessage({ ...errorMessage, ...{ DATE: 'กรุณาระบุวันที่' } })

        const payload = {
            fromEmployeeId: JSON.parse(decode(profile))?.empId,
            toEmployeeId: toEmployeeId,
            startDate: startDate,
            endDate: endDate,
            status: appoveStatus
        }
        dispatch(submitModal())
        ApproveApi.create(payload)
            .then((res: any) => {
                if (res.status !== undefined && res.status === 201) {
                    notiSuccess(t('BIDDERTYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })

                    if (err.statusCode === 400) {
                        if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_TH')) {
                            setErrorMessage({
                                ...errorMessage,
                                ...{
                                    BIDDER_TYPE_NAME_TH: t(`${t(err.error_description)}`),

                                }
                            })
                        } else if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_EN')) {
                            setErrorMessage({
                                ...errorMessage,
                                ...{
                                    BIDDER_TYPE_NAME_EN: t(`${t(err.error_description)}`),

                                }
                            })
                        }
                    }
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })

                if (err.statusCode === 400) {
                    if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_TH')) {
                        setErrorMessage({
                            ...errorMessage,
                            ...{
                                BIDDER_TYPE_NAME_TH: t(`${t(err.error_description)}`),

                            }
                        })
                    } else if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_EN')) {
                        setErrorMessage({
                            ...errorMessage,
                            ...{
                                BIDDER_TYPE_NAME_EN: t(`${t(err.error_description)}`),

                            }
                        })
                    }
                }
                dispatch(unSubmitModal())
            })

    }

    const submitEdit = () => {
        if (!toEmployeeId) return setErrorMessage({ ...errorMessage, ...{ TO_EMP: 'กรุณาระบุผู้รับโอน' } })
        if (!startDate) return setErrorMessage({ ...errorMessage, ...{ DATE: 'กรุณาระบุวันที่' } })
        if (!endDate) return setErrorMessage({ ...errorMessage, ...{ DATE: 'กรุณาระบุวันที่' } })

        dispatch(submitModal())
        const payload = {
            fromEmployeeId: JSON.parse(decode(profile))?.empId,
            toEmployeeId: toEmployeeId,
            startDate: startDate,
            endDate: endDate,
            status: appoveStatus
        }
        ApproveApi.update(approveId, payload)
            .then((res: any) => {
                if (res.status !== undefined && res.status === 205) {
                    notiSuccess(t(`BIDDERTYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })
                dispatch(unSubmitModal())
            })
    }

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handlePageLimitChange = (newPageLimit: number) => {
        setPageLimit(newPageLimit);
    };

    const handleChangeStatus = (newStatus: string) => {
        setStatus(newStatus)
    }

    const onRequestSort = (sortBy: string, sortType: string) => {
        setSortType(sortType)
        setSortBy(sortBy)
    }

    const handleRangeDate = (value: any) => {
        setSelectedDateRange(value);
        setStartDate(moment(value[0]).format("YYYY-MM-DD"));
        setEndDate(moment(value[1]).format("YYYY-MM-DD"));
    };

    const handleClearRangeDate = () => {
        setSelectedDateRange([]);
        setStartDate("");
        setEndDate("");
    };

    return (
        <>
            <HeaderCard text={'โอนสิทธิลงนาม'} />
            <Style.MainContainer>
                <Row>
                    <Col lg={2} md={6} xs={12} className="my-1">
                        <AutocompleteSelect
                            options={listAdmin}
                            getOptionLabel={(option: any) => option.firstname + option.lastname}
                            labelId="list-admin"
                            value={
                                listAdmin
                                    ? listAdmin?.find(
                                        (data: any) => data?.employeeId === searchFromEmployeeId
                                    )
                                    : null
                            }
                            onChange={(event, newValue) => {
                                clearErrorMessage();
                                if (newValue) {
                                    setSearchFromEmployeeId(newValue.employeeId);
                                }
                            }}
                            inputLabel={'ผู้โอน'}
                        // onInputChange={(_, value) => {
                        //     debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                        // }}
                        />
                    </Col>
                    <Col lg={2} md={6} xs={12} className="my-1">
                        <AutocompleteSelect
                            options={listAdmin}
                            getOptionLabel={(option: any) => option.firstname + option.lastname}
                            labelId="list-admin"
                            value={
                                listAdmin
                                    ? listAdmin?.find(
                                        (data: any) => data?.employeeId === searchToEmployeeId
                                    )
                                    : null
                            }
                            onChange={(event, newValue) => {
                                clearErrorMessage();
                                if (newValue) {
                                    setSearchToEmployeeId(newValue.employeeId);
                                }
                            }}
                            inputLabel={'ผู้รับโอน'}
                        // onInputChange={(_, value) => {
                        //     debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                        // }}
                        />
                    </Col>
                    <Col lg={2} md={6} xs={12} className="my-1">
                        <FilterSelect
                            onchange={(event) => {
                                const value = event.target.value
                                if (value) {
                                    handleChangeStatus(value)
                                    setPage(1)
                                }
                            }}
                            renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                            label={t('STATUS.LABEL')}
                            selectId="select-status"
                            value={status}
                            labelId="label-status"
                            options={[
                                <MenuItem key="1" value="ALL">
                                    {t('STATUS.ALL')}
                                </MenuItem>,
                                <MenuItem key="2" value="ACTIVE">
                                    {t('STATUS.ACTIVE')}
                                </MenuItem>,
                                <MenuItem key="3" value="INACTIVE">
                                    {t('STATUS.INACTIVE')}
                                </MenuItem>
                            ]}
                        />
                    </Col>
                    <Col lg={3} md={6} xs={12}></Col>
                    <Col lg={3} md={6} xs={12} className="my-1">
                        <ButtonCustom style={{ width: '100%' }}
                            disabled={permissions.isCreate.disabled}
                            type="button"
                            textButton={t('เพิ่มโอนสิทธิลงนาม')}
                            endIcon={<img src={icons.add} />}
                            onClick={() => dispatch(showModal())}
                        />
                    </Col>
                </Row>

                <Style.TableContainer>

                    <TableCustom
                        headCells={headCells}
                        customScroll
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        rowCount={rowCount}
                        rowsData={approveData.map((data: any, index: number) => {
                            return renderData(data, index);
                        })}
                        onSort={onRequestSort}
                        setPageLimit={handlePageLimitChange}
                        setPage={handlePageChange}
                        tableMinWidth={1000}
                    />
                </Style.TableContainer>

            </Style.MainContainer>

            <ModalCustom
                title={approveId ? t('แก้ไขโอนสิทธิลงนาม') : t('เพิ่มโอนสิทธิลงนาม')}
                component={
                    <div className="pb-2">
                        <AutocompleteSelect
                            options={listAdmin}
                            getOptionLabel={(option: any) => option.firstname + option.lastname}
                            labelId="list-admin"
                            value={
                                listAdmin
                                    ? listAdmin?.find(
                                        (data: any) => data?.employeeId === selectFromEmployeeId
                                    )
                                    : null
                            }
                            onChange={(event, newValue) => {
                                clearErrorMessage();
                                if (newValue) {
                                    setToEmployeeId(newValue.employeeId);
                                }
                            }}
                            helperText={errorMessage.TO_EMP}
                            heading={t('ผู้รับโอนสิทธิลงนาม')}
                        // onInputChange={(_, value) => {
                        //     debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                        // }}
                        />
                        <div className="pt-2">
                            <InputNewRangePicker
                                value={selectedDateRange}
                                onchange={handleRangeDate}
                                onClear={handleClearRangeDate}
                                allowClear
                                heading={t("ระหว่างวันที่")}
                                label={t("ลงนามได้วันที่")}
                                helperText={errorMessage.DATE}
                            />
                        </div>
                    </div>
                }
                onReset={resetForm}
                onSubmit={approveId ? submitEdit : submit}
                textBtnCancel={t('ยกเลิก')}
                textBtnConfirm={t('บันทึก')}
            />
        </>
    )
}