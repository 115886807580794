import { useTranslation } from "react-i18next";

//**COMPONENT */
import { Col, Row } from "react-bootstrap";
import { colors } from "../../constants/colors";

//**STYLE */
import * as Style from "./cancel.Style"


interface PopupCancelContractProps {
    firstname?: string
    lastname?: string
    zoneName?: string
    stallName?: string
    stallTypeName?:string
    leaseAgreementDateStart?: string
    leaseAgreementDateEnd?: string
    leaseAgreementNo?: string

    isShow: boolean | false;
    setIsShow: (show: boolean) => void;
    // onSubmit: (data: any) => void; 
}

export default function PopupReqCancelContract (props: PopupCancelContractProps) {
  const { t } = useTranslation();

  return  (
    <>
    <Style.ContainerModal>
    <Row className="mt-3">
        <Col lg={2} xs={6} >
            <Style.SubDetail>{t('CERTIFICATE.MODAL.DETAIL')}</Style.SubDetail>
        </Col>
        <Col lg={6} xs={6}>
            <Style.SubDetail>{t('CANCELCONTRACT.POPUP.CANCEL_CONTRACT_STALL')}</Style.SubDetail>
        </Col>
    </Row>
    <Row className="mt-3">
        <Col lg={2} xs={6}>
            <Style.SubDetail>{t('CERTIFICATE.MODAL.NAME')}</Style.SubDetail>
        </Col>

        <Col lg={6} xs={6}>
            <Style.SubDetail>{props.firstname + " " + props.lastname}</Style.SubDetail>
        </Col>
    </Row>

    <Row className="mt-3">
        <Col lg={2} xs={6}>
            <Style.SubDetail>{t('CANCELCONTRACT.POPUP.LEASES_NUMBER')}</Style.SubDetail>
        </Col>
        <Col lg={2} xs={6}>
            <Style.SubDetail>{props.leaseAgreementNo || "-"}</Style.SubDetail>
        </Col>
    </Row>

    <Row className="mt-3">
        <Col lg={5} xs={6}  >
            <Style.SubDetail style={{ backgroundColor: colors.headerBackground, borderRadius: 4, width: 593 }}>
                {t('CANCELCONTRACT.POPUP.DETAIL')}
            </Style.SubDetail>
        </Col>

    </Row>

    <Row className="mt-3">
    
        <Col lg={2} xs={6}>
            <Style.SubDetail>{t('CERTIFICATE.MODAL.ZONE')}</Style.SubDetail>
        </Col>
        <Col lg={2} xs={6}>
            <Style.SubDetail>{props.zoneName || "-"}</Style.SubDetail>
        </Col>
        
        <Col lg={2} xs={6}>
            <Style.SubDetail>{t('CERTIFICATE.MODAL.ZONE_TYPE')}</Style.SubDetail>
        </Col>
        <Col lg={2} xs={6}>
            <Style.SubDetail>{props.stallTypeName || "-"}</Style.SubDetail>
        </Col>
    </Row>

    <Row className="mt-3">
        <Col lg={2} xs={6}>
            <Style.SubDetail>{t('CERTIFICATE.MODAL.STALL')}</Style.SubDetail>
        </Col>

        <Col lg={2} xs={6}>
            <Style.SubDetail>{props.stallName || "-"}</Style.SubDetail>
        </Col>
    </Row>

    <Row className="mt-3 mb-5">
        <Col lg={3} xs={6}>
            <Style.SubDetail>{t('CERTIFICATE.MODAL.START_DATE')}</Style.SubDetail>
        </Col>
        <Col lg={3} xs={6}>
            <Style.SubDetail>{props.leaseAgreementDateStart || "-"}</Style.SubDetail>
        </Col>

        <Col lg={3} xs={6}>
            <Style.SubDetail>{t('CERTIFICATE.MODAL.EXPIRE_DATE')}</Style.SubDetail>
        </Col>
        <Col lg={3} xs={6}>
            <Style.SubDetail>{props.leaseAgreementDateEnd || "-"}</Style.SubDetail>
        </Col>
    </Row>
    </Style.ContainerModal>
    </>
  );
}
