import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ListItemIcon, Typography } from '@mui/material'
import { Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faUserPen, faRightFromBracket, faLocationDot } from '@fortawesome/free-solid-svg-icons'

/** COMPONENT */
import { colors } from '../../constants/colors'
import { imgs } from '../../constants/images'

import { getProfile, logout } from '../../utils/app.utils'
import { routeName } from '../../routes/routers-name'
import { fileUrl } from '../../api/api'
import { decode } from 'js-base64';

const ButtonProfile = styled(Button)(({ theme }) => ({
  minWidth: 'unset !important',
  backgroundColor: 'transparent !important',
  zIndex: 1,
  '&[aria-controls="menu-list-grow"] svg': {
    transform: 'rotate(180deg)'
  },
  '& svg': {
    color: colors.black
  },
  '& .avatar': {
    width: '2.625rem !important',
    height: '2.625rem !important',
    boxShadow: `0 0 6px 0px ${colors.white}`,
    border: `2px solid ${colors.themeMainColor}`,
    [theme.breakpoints.down('sm')]: {
      width: '1.875rem !important',
      height: '1.875rem !important'
    }
  },
  '& .buttonLabel': {
    textAlign: 'left',
    paddingLeft: '.5rem',
    color: colors.textPrimary,
    maxWidth: '180px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    '& .user-fullName': {
      textTransform: 'capitalize',
      fontSize: '14px'
    },
    '& .pick-detail': {
      fontSize: '14px',
      marginTop: '-3px'
    }
  }
}))

const MenuDropdown = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${colors.lightGray}`,
    boxShadow: 'none',
    borderRadius: '3px',
    marginTop: '.375rem',
    [theme.breakpoints.up('sm')]: {
      left: 'auto !important',
      right: '1.5rem'
    }
  },
  '& .MuiList-root': {
    maxWidth: '208px',
    padding: '1rem .5rem .5rem'
  }
}))

const TextInformation = styled(Typography)(({ theme }) => ({
  lineHeight: 'normal',
  '& .user-fullName': {
    fontSize: '14px',
    color: colors.textPrimary
  },
  '& .pick-detail': {
    fontSize: '12px',
    color: colors.textLightGray,
    borderBottom: `1px solid ${colors.lightGray}`
  }
}))

const MenuItemList = styled(MenuItem)(({ theme }) => ({
  margin: '0 -.5rem !important',
  padding: '.5rem 1rem !important',
  display: 'flex !important',
  justifyContent: 'flex-start !important',
  '& .MuiListItemIcon-root': {
    fontSize: '14px',
    minWidth: '28px',
    '& path': {
      fill: colors.themeMainColor
    },
    '& img': {
      width: '14px'
    },
    '& img.icon-signOut': {
      width: '17px'
    }
  }
}))


export default function UserMenu() {
  const { t } = useTranslation()
  const history = useHistory()
  const userProfile : any = JSON.parse(getProfile() || '{}')
  
  const [profile, setProfile]: any = useState({
    fullname: JSON.parse(decode(userProfile))?.firstname + " " + JSON.parse(decode(userProfile))?.lastname,
    positionName: JSON.parse(decode(userProfile))?.roleName,
    roleName: ``,
  })

  useEffect(() => {
    setProfile({
      ...profile,
      fullname: JSON.parse(decode(userProfile))?.firstname + " " + JSON.parse(decode(userProfile))?.lastname,
      positionName: JSON.parse(decode(userProfile))?.roleName,
      roleName: ``,
      pictureProfile: fileUrl +userProfile?.profile?.filePath + userProfile?.profile?.fileName,
    })
  }, [fileUrl])

  function doLogout() {
    logout()
    window.location.reload()
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderImage = (img: any) => {
    if (img !== (fileUrl + "undefinedundefined") ) return `${img}`
    else return imgs.defaultAvatar
  }

  const goEditProfile = async () => {
    if (JSON.parse(decode(userProfile))?.roleId === 4) {
      history.push(routeName.editProfile)
      
    } else {

      history.push(routeName.editProfile)
    }

  }

  const goChangeBranch = async () => {
    localStorage.removeItem("BRANCH");
    window.location.href = routeName.selectBranches
  }

  return (
    <>
      <ButtonProfile id="profile-button" aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleToggle} className={'py-0 pr-0 pl-3'} disableRipple>
        <Avatar alt="Profile" className={'avatar'} sx={{ backgroundColor: colors.white }}>
          <img src={renderImage(profile?.pictureProfile)} alt='' className="w-100 h-100" />
        </Avatar>
        <div className={'buttonLabel'}>
          <div className={'user-fullName text-ellipsis'}>{profile.fullname}</div>
          <div className={'pick-detail text-ellipsis'}>{profile.positionName}</div>
        </div>
        <FontAwesomeIcon icon={faCaretDown} className={'ml-2'} />
      </ButtonProfile>

      <MenuDropdown
        id="menu-list-grow"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button'
        }}
      >
        {/* <TextInformation variant="inherit">
          <p className={'user-fullName m-0 px-2'}>{profile.fullname}</p>

        </TextInformation> */}
        <MenuItemList onClick={() => goEditProfile()}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUserPen} />
          </ListItemIcon>
          <Typography variant="inherit">{t('NAV.EDIT_PROFILE')}</Typography>
        </MenuItemList>
        {JSON.parse(decode(userProfile))?.roleId === 2 && <MenuItemList onClick={() => goChangeBranch()}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLocationDot} />
          </ListItemIcon>
          <Typography variant="inherit">{t('NAV.CHANGE_BRANCH')}</Typography>
        </MenuItemList>}
        <MenuItemList onClick={doLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </ListItemIcon>
          <Typography variant="inherit">{t('BUTTON.SIGN_OUT')}</Typography>
        </MenuItemList>
      </MenuDropdown>
    </>
  )
}
