import { getProfile } from "../utils/app.utils";
import { decode } from 'js-base64';

const profile: any = JSON.parse(getProfile() || '{}')
const role : any = profile.length > 3 ? JSON.parse(decode(profile)) : '{}'
export const colors = {
  transparent: "transparent",
  themeMainColor: `${role.roleId ? role.roleId === 4 ?'#42A18A' : '#14378A' : '#42A18A'}`,
  themeSecondColor : "#14378A",
  themeThirdColor: '#42A18A',
  paleBlue: "#d8e8f4",
  themeResetColor: "#FC8F8F",
  statusActiveColor: "#1CB99A",
  statusInactiveColor: "#B00020",
  borderHeaderCard: "#f3f1f1",
  blueadminfade:"#14378A0D",

  /** ! BG_COLORS */
  bgMainColor: "#29302E",
  bgSecondColor: "#1CB99A1A",
  white: "#ffffff",
  black: "#000000",
  bgBase: "#F9FAFC",
  green: "#1CB99A",
  lightGreen: "#008060",
  red: "#B00020",
  brown: "#29302E",
  extraLightBlue: "#f1f9ff",
  blue: "#007bff",
  sky: "#469acc",
  indigo: "#6610f2",
  purple: "#6f42c1",
  pink: "#e83e8c",
  orange: "#fd7e14",
  lightOrange:"#F0B760",
  darkOrange: "#EA4D2C",
  yellow: "#ffc107",
  lightYellow: "#FFF7D4",
  teal: "#20c997",
  cyan: "#17a2b8",
  extraLightGray: "#f5f5f5",
  lightGray: "#e5e5e5",
  disabledLightGray: "#c5c5c5",
  disabledGray: "#9e9e9e",
  gray: "#6c757d",
  darkGray: "#343a40",
  primary: "#007bff",
  secondary: "#6c757d",
  success: "#28a745",
  lightSuccess: "#68BB86",
  info: "#17a2b8",
  warning: "#f88f41",
  darkWarning: "#e67e22",
  danger: "#dc3545",
  light: "#f8f9fa",
  dark: "#343a40",
  lightGrayBG: '#f8f8f8',
  bgModal: '#F0F0F0',

  // opacity
  white_30: 'rgba(255, 255, 255, 0.3)',
  white_70: 'rgba(255, 255, 255, 0.7)',
  white_80: 'rgba(255, 255, 255, 0.8)',
  white_90: 'rgba(255, 255, 255, 0.9)',
  black_05: 'rgba(0, 0, 0, 0.05)',
  black_08: 'rgba(0, 0, 0, 0.08)',
  black_10: 'rgba(0, 0, 0, 0.10)',
  black_12: 'rgba(0, 0, 0, 0.12)',
  black_16: 'rgba(0, 0, 0, 0.16)',
  black_20: 'rgba(0, 0, 0, 0.2)',
  black_25: 'rgba(0, 0, 0, 0.25)',
  black_30: 'rgba(0, 0, 0, 0.3)',
  black_60: 'rgba(0, 0, 0, 0.6)',
  green_06: 'rgba(28, 185, 154, 0.06)',
  green_10: 'rgba(28, 185, 154, 0.1)',
  green_30: 'rgba(28, 185, 154, 0.3)',
  red_10: 'rgba(176, 0, 32, 0.1)',
  gray_06: 'rgba(47, 66, 105, 0.06)',

  filterDropdown: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.53), rgba(242, 242, 242, 0.34))',

  
  // text
  textInput: '#666666',
  textExtraLightGray: '#929292',
  textLightGray: '#707070',
  textPrimary: '#29302E',
  bgText:'#D9D9D9',

  /** INPUT */
  borderInput: "#d3d3d4",
  inputProps: "#00000099",
  inputLabelProps: "#00000099",

  text: '#1B1F23',
  regisText: '#434855',

  divider: "rgb(94, 94, 94)",

  /** HEADER */
  headerBackground: '#0080600D'
};
