import { Box } from "@mui/material";
import HeaderCard from "../../component/cardCustom/headerCard";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Row } from "react-bootstrap";
import Meatball from "../../assets/icon/meatball";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import TableRowCommon, {
  DropdownItem,
} from "../../component/table/TableRowCommon";
import { colors } from "../../constants/colors";
import { useDispatch } from "react-redux";
import { showModal } from "../../app/slice/modal.slice";
import ButtonCustom from "../../component/button/buttonCustom";
import  { BoxTabSearch, Boxconteiner, Tab } from "./adminStyle";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import TableCustom from "../../component/table/tableCustom";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import RoleApi from "../../api/admin/role/role.api";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { isCreate, isDelete, isUpdate, isView } from "../../utils/permission.utils";


const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};
export default function AdminType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
 
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [listAdmin, setListAdmin] = useState<any>([]);
  const [rowCount, setRowCount] = useState<number>();
  const [search, setSearch] = useState<string>("");

  const handleClick = () => {
    dispatch(showModal());
  };
  const handledisStatus = (id:any) => {
    const condition ={
      
      status:"INACTIVE"
    }
    RoleApi.status(condition,+id).then((res) =>{
      if(res.status === 200){
        notiSuccess(t(`ADMIN.NOTI.SUCESSS`),'',null,null)
        loadData()
      }
      else{
        notiError(t(`ADMIN.NOTI.UNSUCESSS`),'',null,null)
      }
    })
  }
    
  const handleStatus = (id:any) => {
    const condition ={
      
      status:"ACTIVE"
    }
    RoleApi.status(condition,+id).then((res) =>{
      if(res.status === 200){
        notiSuccess(t(`ADMIN.NOTI.SUCESSS`),'',null,null)
        loadData()
      }
      else{
        notiError(t(`ADMIN.NOTI.UNSUCESSS`),'',null,null)
      }
    })
    
  };

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
 
    {
      id: "typr",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.Role"),
    },

   
    {
      id: "status",
      disablePadding: false,
      align: "left",
      label: t("ADMIN.HEADCELL.STATUS"),
    },
   
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.ACTION"),
    },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;
   
    const {
        roleId,
        status,
        roleName,
        isEdit,
        createDate,
    } = objData;

    const objRenderData = {
      key: no,
      id: roleId,
      obj: objData,

      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
        },
     
        {
          option: "TEXT",
          align: "center",
          label: roleName,

        },

        {
          option: "STATUS",
          align: "left",
          label: status,
        
        },
      

        {
          option: "COMPONENT",
          align: "center",

          style: { color: colors.themeMainColor },
          component: (
            <Box>
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  bsPrefix="p-0  "
                  className="btn-table-action text-black-50 shadow-none"
                >
                  <Meatball />
                </Dropdown.Toggle>
                <DropdownMenu className="dropdown-table-action position-fixed">
                 
                  <DropdownItem disabled={permissions?.isView.disabled} onClick={() => history.push(`admin-type/edit-admin-type/${roleId}`,{params:{isView:true}})}>{t("ADMIN.BUTTON.DETAIL")}</DropdownItem>
                 
                  {/* {isEdit === "FALSE" ? "": */}
                  <DropdownItem disabled={permissions?.isUpdate.disabled} onClick={() => history.push(`admin-type/edit-admin-type/${roleId}`,{params:{isView:false}})}>{t("ADMIN.BUTTON.EDIT")}</DropdownItem>
                  {/* } */}
                 
                 {isEdit === "TRUE" ?  status === "ACTIVE" ? <DropdownItem  disabled={permissions?.isUpdate.disabled} onClick={() =>handledisStatus(roleId)}>{t("ADMIN.BUTTON.STATUS")}</DropdownItem>:<DropdownItem onClick={() =>handleStatus(roleId)}>{t("ADMIN.BUTTON.STATUSOPEN")}</DropdownItem>:""} 
                </DropdownMenu>
              </Dropdown>
            </Box>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };
    
  
  const loadData = useCallback(async () => {
    let condition: any = {};
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search) condition = { ...condition, search: search };

    const res = await RoleApi.findAll(condition);
    console.log(res)
    if (res.status === 200) {
      setRowCount(res?.headers['total']);
      setListAdmin(res.data);
    } else {
      setRowCount(0);
      setListAdmin([]);
    }
  }, [page, pageLimit, search]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  
  return (
    <Box >
      <HeaderCard text={t("ADMIN_TYPE.HEADER")} />
      <Boxconteiner>

  
              <Row>
                <Col className="my-2" lg={4} md={12} xs={12}>
                  <InputTextField
                    placeholder={t("ADMIN.INPUT.SEARCH")}
                    onchange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Col>
                <Col lg={5} md={12} xs={12} />

                <Col className="my-2" lg={3} md={12} xs={12} >
                <ButtonCustom
                textButton={t("ADMIN.BUTTON.ADD_ADMIN_TYPE")}
                style={{ width: "100%" }}
                mode="add"
                disabled={permissions?.isCreate.disabled}
                onClick={() => history.push("/create-admintype",{params:{isView:false}})}
              />
                </Col>
              </Row>

          {/* <Tabbutton>
            <Row>
              <Col className="my-1" lg={4} md={12} xs={12}>
                {" "}
                <InputTextField
                  placeholder={t("ADMIN.INPUT.SEARCH")}
                  style={{ marginRight: "24px" }}
                />
              </Col>

              <Col className="my-1" lg={4} md={12} xs={12}>
                <AutocompleteSelect
                  options={prefixData}
                  // onChange={(data: any) => setPrefixId(data.value)}
                  labelId="status"
                  placeholder={t("ADMIN.INPUT.SEARCH_ZONE")}
                />
              </Col>

              <Col className="my-1" lg={4} md={12} xs={12}>
                <AutocompleteSelect
                  options={prefixData}
                  // onChange={(data: any) => setPrefixId(data.value)}
                  labelId="rental"
                  placeholder={t("ADMIN.INPUT.SEARCH_STALL")}
                />
              </Col>
              <Col className="my-1" lg={4} md={12} xs={12}>
                <ButtonCustom
                  textButton={t("ADMIN.BUTTON.ADD_ADMIN")}
                  style={{ width: "100%" }}
                  mode="add"
                />
              </Col>
            </Row>
          </Tabbutton> */}

              
        <TableCustom
          headCells={headCells}
          customScroll
          page={page}
          pageLimit={pageLimit}
          sortType={"ASC"}
          sortBy=""
          rowCount={1}
          rowsData={ listAdmin?.map((d: any, index: any) => {
            return renderData(d, index);
          })}
          onSort={() => {}}
          setPageLimit={(value: number) => setPageLimit(value)}
          setPage={(value: number) => setPage(value)}
        />
      </Boxconteiner>
    </Box>
  );
}
