import { useState } from 'react'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons'

/** CONSTANTS */
import { colors } from '../../constants/colors'

/** STYLE */
import { styled } from '@mui/material/styles'
import './style.css'
import HelperText from '../input/helperText'

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    backgroundColor: colors.white,
    height: 43,
    alignItems: 'center !important',
    borderRadius: 8,
    '.MuiButtonBase-root svg': {
      fontSize: 16,
      path: {
        fill: colors.black
      }
    },
    '&.Mui-disabled': {
      backgroundColor: `${colors.black_12} !important`,
      '.MuiButtonBase-root svg': {
        path: {
          fill: colors.black_60
        }
      },
    },
    '&.MuiInputBase-sizeSmall': {
      '.MuiAutocomplete-input': {
        paddingRight: '16px'
      }
    }
  },
  '.MuiInputLabel-root, .MuiInputLabel-root span': {
    fontSize: 16,
    color: `${colors.black_60} !important`,
    backgroundColor: 'transparent !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 3,
      top: '50%',
      zIndex: -1
    },
    '&[data-shrink="true"]': {
      '&:before': {
        backgroundColor: colors.white
      },
      span: {
        color: `${colors.statusInactiveColor} !important`
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    top: '-5px !important',
    borderColor: `${colors.black_12} !important`
  },
  '.MuiOutlinedInput-input': {
    color: `${colors.textPrimary} !important`,
    '-webkit-text-fill-color': 'unset !important',
    '&.Mui-disabled': {
      '-webkit-text-fill-color': 'unset',
      color: `${colors.black_60} !important`,
      '&::placeholder': {
        opacity: 1
      }
    }
  },
  '.MuiAutocomplete-clearIndicator': {
    backgroundColor: 'transparent !important',
    svg: {
      fontSize: 20
    },
    path: {
      fill: `${colors.black_60} !important`
    }
  },
  'span.MuiAutocomplete-tag': {
    height: 23,
    minWidth: 36,
    backgroundColor: colors.disabledGray,
    borderRadius: 100,
    color: colors.white,
    textAlign: 'center'
  },
  '&.Mui-enabled-border': {
    '.MuiOutlinedInput-root': {
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: `${colors.themeMainColor} !important`
        }
      }
    },
    '.MuiInputLabel-root, .MuiInputLabel-root span': {
      '&[data-shrink="true"]': {
        '&.Mui-focused': {
          color: `${colors.themeMainColor} !important`
        }
      }
    }
  },
  '&.Mui-error': {
    '&.Mui-enabled-border, &.Mui-disabled-border, .MuiOutlinedInput-root.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.statusInactiveColor} !important`
      },
      '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span': {
        color: `${colors.statusInactiveColor} !important`
      }
    }
  },
  '.MuiInputBase-inputMultiline': {
    height: '90% !important'
  },
  '&.Mui-multiple': {
    '.MuiOutlinedInput-root': {
      paddingTop: '10px !important'
    },
    '.MuiAutocomplete-clearIndicator': {
      marginRight: '0.375rem'
    }
  }
}))

const CustomHelperText = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: 4,
  marginLeft: 14,
  svg: {
    marginTop: 5
  },
  path: {
    fill: colors.statusInactiveColor
  },
  span: {
    color: colors.statusInactiveColor,
    fontSize: 12,
    paddingLeft: 4
  }
}))

type SelectSearch = {
  labelId: string
  inputLabel?: string
  options: any
  size?: 'medium' | 'small'
  defaultValue?: any
  noOptionsText?: string
  disableClearable?: boolean
  popupIcon?: any
  clearIcon?: any
  open?: boolean
  disabled?: boolean
  renderOption?: (props: any, option: any) => any
  renderTags?: (props: any, option: any) => any
  getOptionLabel?: (option: any) => any
  filterOptions?: (option: any) => any
  onChange?: (event: any, value: any, reason: string) => void
  onInputChange?: (event: any, value: any) => void
  onOpen?: (event: any) => void
  onClose?: (event: any, reason: any) => void
  onBlur?: (event: any) => void
  value?: any
  disabledBorder?: boolean
  required?: boolean
  helperText?: string
  multiple?: boolean
  multiline?: boolean
  freeSolo?: boolean
  height?: number | string
  limitTags?: number
  classesOption?: string
  placeholder?: string
  inputValue?: any
  isOptionEqualToValue?: any
  inputProps?: any
  loading?: boolean
  loadingText?: any
  heading?: string
  headingAlign?: string
}

export default function AutocompleteSelect(props: SelectSearch) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)

  return (
    <>
      {props.heading &&
        <div className='pb-2' style={{ textAlign: 'left' || props.headingAlign }}>
          {props.heading} {props.required && <span style={{ color: colors.red }}>*</span>}
        </div>
      }
      <CustomAutocomplete
        id={props.labelId}
        open={props.open}
        options={props.options}
        renderOption={props.renderOption}
        renderTags={props.renderTags}
        getOptionLabel={props.getOptionLabel}
        renderInput={(params) => <TextField {...params} {...props.inputProps} label={props.inputLabel} placeholder={props.placeholder} required={props.required} multiline={props.multiline} />}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        onOpen={props.onOpen}
        onClose={props.onClose}
        onBlur={props.onBlur}
        limitTags={props.limitTags}
        size={props.size || 'small'}
        popupIcon={props.popupIcon || <FontAwesomeIcon icon={faCaretDown} />}
        clearIcon={props.clearIcon || <FontAwesomeIcon icon={faTimes} />}
        classes={{ popper: `style-filter-select-search-wrap ${props.classesOption}`, paper: 'style-filter-select-search', listbox: props.multiple ? 'Mui-multiple custom-scroll' : 'custom-scroll' }}
        sx={{
          '.MuiOutlinedInput-root': {
            paddingTop: props.multiple ? '10px !important' : '',
            minHeight: props.multiple ? props.height : 'unset',
            height: props.multiple ? 'auto' : props.height ? props.height : 43,
            alignItems: props.multiple ? 'start !important' : 'center  !important',
          }
        }}
        className={`${props.disabledBorder ? 'Mui-disabled-border' : 'Mui-enabled-border'} ${props.helperText ? 'Mui-error' : ''} ${props.multiple ? 'Mui-multiple' : ''}`}
        filterOptions={createFilterOptions({
          stringify: props.filterOptions
        })}
        noOptionsText={props.noOptionsText}
        defaultValue={props.defaultValue}
        disableClearable={props.disableClearable}
        inputValue={props.inputValue}
        value={props.value}
        disabled={props.disabled}
        multiple={props.multiple}
        freeSolo={props.freeSolo}
        isOptionEqualToValue={props.isOptionEqualToValue}
        loading={props.loading}
        loadingText={t('SEARCHING')}
        blurOnSelect
      />
      {props.helperText && (
        <HelperText label={props.helperText} />
      )}
    </>
  )
}
