import * as utils from './function-table'

import { RenderExcel, ExcelReturn } from './interfaces/render-excel'
import { Model } from './interfaces/model'
import { IoadDatatoTable } from './function-table/create-table'

function download(fileName: string, model: Model): boolean {
  const element = window.document.createElement('a')
  element.href = utils.uri + utils.base64(utils.format(utils.template, model))
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  return true
}

function getTable(tableExportRef?: any, loadDatatoTable?: IoadDatatoTable) {
  if (tableExportRef) {
    const cloneTable = tableExportRef.cloneNode(true)
    return cloneTable.outerHTML
  }

  if (loadDatatoTable) return utils.createTable(loadDatatoTable)
}

function handleDownload({ fileName, sheetName, loadDatatoTable }: { fileName: string; sheetName: string; loadDatatoTable?: IoadDatatoTable }, tableExportRef?: HTMLElement) {
  const table = getTable(tableExportRef, loadDatatoTable)
  const model: Model = {
    worksheet: sheetName || 'Worksheet',
    table
  }

  return download(fileName, model)
}

function excel({ tableExportRef, filename, sheetName }: RenderExcel): ExcelReturn {
  function onDownload(): boolean {
    const table = getTable(tableExportRef)
    const fileName = `${filename}.xls`
    const model: Model = {
      worksheet: sheetName || 'Worksheet',
      table
    }
    return download(fileName, model)
  }
  return { onDownload }
}

export { excel, handleDownload as downloadExcel }
