import _ from "lodash";

/** TRANSLATION */
import { useTranslation } from "react-i18next";

/** CONSTANTS */
import { colors } from "../../../../constants/colors";
/** STYLE */
import { styled } from "@mui/material/styles";
import { dateToView } from "../../../../utils/date.utils";

export const Card = styled("div")(({ theme }) => ({
  margin: "1rem 1rem 0",
  borderRadius: 4,
  boxShadow: `0 3px 6px 0 ${colors.black_16}`,
  backgroundColor: colors.white,
  color: colors.textPrimary,
  cursor: "pointer",
  "& p": {
    margin: 0,
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.75rem",
    marginRight: "0.75rem",
  },
}));

export const CardBody = styled("div")(({ theme }) => ({
  position: "relative",
  padding: "1.5rem 1rem 0.5rem 1.25rem",
  border: `1px solid ${colors.extraLightGray}`,
  borderRadius: 4,
  "& .number": {
    width: "30px",
    height: "25px",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    position: "absolute",
    top: "8px",
    right: "8px",
    backgroundColor: "#14378A",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
}));

export const CardFooter = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  textAlign: "center",
  padding: "1rem",
  cursor: "pointer",
  borderRadius: "0px 0px 5px 5px",
  "> div": {
    width: "100%",
    color: "#fff",
    ".MuiButton-outlined": {
      width: "100%",
      borderRadius: 0,
      borderColor: `${colors.extraLightGray} !important`,
      fontSize: 16,
      textTransform: "capitalize",
      height: 49,
      img: {
        width: 16,
        height: 16,
      },
      ".MuiButton-startIcon": {
        marginLeft: 0,
      },
    },
    "&:first-of-type .MuiButton-outlined": {
      borderBottomLeftRadius: 4,
    },
    "&:last-of-type .MuiButton-outlined": {
      borderBottomRightRadius: 4,
    },
    ".btn-payment": {
      borderColor: "transparent !important",
      paddingLeft: 0,
    },
  },
  ".btn-icon-group": {
    display: "flex",
    justifyContent: "flex-end",
    "> div": {
      margin: "0 !important",
      "&:first-of-type .MuiButton-outlined": {
        borderRadius: "0 !important",
      },
    },
    ".btn-icon": {
      borderColor: "transparent !important",
      minWidth: 48,
      padding: 0,
      ".MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    button: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

type CardServiceBidderProps = {
  number: number;
  name?: string;
  bidNo?: string;
  hideFooterAction?: boolean;
  bidder?: IBidder;
  footerType?: "PUBLIC" | "CHECK" | "APPOINTMENT";
  annouceNo?: number;
  onClick?: (val: any) => void;
  onCancel?: (val: any) => void;
};

export interface IBidder {
  bidderId: number;
  userId: number;
  branchId: number;
  roundId: number;
  pageId: number;
  bidderDate: string;
  bidNo: string;
  bidTypeId: number;
  bidderTypeName: string;
  zoneId: number;
  zoneName: string;
  stallId: number;
  stallName: string;
  stallTypeName: string;
  bidderAmount: string;
  status: string;
  createId?: any;
  createDate: string;
  modifiedId?: any;
  modifiedDate: string;
  createdBy?: any;
  modifiedBy?: any;
  bidderBy: IBidderBy;
  round: any
}

interface IBidderBy {
  firstname: string;
  lastname: string;
}

export default function CardServiceBidder(props: CardServiceBidderProps) {
  const { t } = useTranslation();

  const { bidder, footerType } = props;
  return (
    <Card>
      <CardBody className={"d-flex flex-column"}>
        <p className={"number"}>{String(props.number).padStart(2, "0")}</p>
        <div className={"d-flex"}>
          {t("ชื่อ")}: {bidder?.bidderBy?.firstname}{" "}
          {bidder?.bidderBy?.lastname}
        </div>
        <div className={"d-flex"}>
          {t("เลขที่ประกาศ")}: {bidder?.round?.announce?.announceNo}
        </div>
        <div className={"d-flex"}>
          {t("เลขคำร้อง")}: {bidder?.bidNo}
        </div>
        <div className={"d-flex"}>
          {t("สถานะ")}:{" "}
          <span className="mx-1" style={{ color: "#EF9C1F" }}>
            {t("รอประกาศผล")}
          </span>
        </div>
        <div className={"d-flex"}>
          {t("โซน")}: {bidder?.zoneName} {t("แผงค้า")}: {bidder?.stallName}
        </div>
        <div className={"d-flex"}>
          {t("วันที่ยื่นคำร้อง")}: {dateToView(bidder?.bidderDate)}
        </div>
      </CardBody>
      {props.hideFooterAction === false && (
        <CardFooter
          onClick={props.onClick}
          style={{
            backgroundColor: footerType === "PUBLIC" ? "#14378A" : "#EF9C1F",
          }}
        >
          <div>{t("ประกาศผล")}</div>
        </CardFooter>
      )}
    </Card>
  );
}
