import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../../constants/colors";


export const PageContainer = styled("div")(({ theme }) => ({  
  height: '100vh',
  width:'100%',
  
}));

export const MainContainer = styled("div")(({ theme }) => ({  
  height: '100%',
  width:'100%',
  overflow:'auto',
  padding: '24px 48px'
}));


export const ItemContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: '100%',
}));

export const TableContainer = styled(Box)(({theme}) => ({
    marginTop: 24,
    whiteSpace:'nowrap',
        
}));

export const Title = styled(Typography)(({theme}) => ({
  fontSize: 24,
  fontWeight: 400,
  color:colors.green,
   
}))

export const TableStyle = styled("div")(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  whiteSpace:'nowrap'

  
}));

export const TextInTable = styled("div")(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  gap:'10px'
  
}));
export const TextStyle = styled(Typography)(({theme}) => ({
  fontSize: 16,
  fontWeight: 400,
 
}))

export const ButtonAdd = styled(Button)(({theme}) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  ':hover' : {
      backgroundColor: colors.blue
  }
}))


export const Head = styled(Typography)(({theme}) => ({
  fontSize: 16,
  fontWeight: 400,
  
   
}))

export const SubMessage = styled(Typography)(({theme}) => ({
  fontSize: 14,
  fontWeight: 400,
  
   
}))

export const UnitBox = styled(Box)(({theme}) => ({
  transform: "translateX(240px) translateY(-46px)"
      
}));

export const BahtBox = styled(Box)(({theme}) => ({
  transform: "translateX(240px) translateY(-46px)"
      
}));






  
  


