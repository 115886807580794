import { menuSeconds } from "./constants/menu";
import Home from "./pages/home/home";
import routerCancelContract from "./routes/cancelContract/cancelContract.route";
import routeCertificate from "./routes/certificate/certificate.route";
import routeAnnounce from "./routes/announce/announce.route";
import routePayBill from "./routes/payBill/payBill.route";
import routeRepair from "./routes/repair/repair.route";
import routeDepositPay from "./routes/stall/depositPay.route";
import routeRentStall from "./routes/stall/listRentStall.route";
import routeStallAgreement from "./routes/stall/stallAgreement.route";
import routeMarketMap from "./routes/store/marketMap.route";
import routeRequsetForStall from "./routes/stall/requestForStall.route";
import routeStore from "./routes/stall/requestForStall.route";
import routeSue from "./routes/sue/sue.route";
import routeTrackBill from "./routes/trackBill/trackBill.route";
import routeTransferStall from "./routes/transferStall/transferStall.route";
import routeUtility from "./routes/utilityBill/utilityBill.route";
import routeSettings from "./routes/setting/settings.route";
import routeSystemLog from "./routes/systemlog/systemlog.route";
// import routeRequestCertificate from "./routes/requestcertifacate/requestcertificate.route";
// import routeSubmitStall from "./routes/submitStall/submitStall.route";
import routeBranches from "./routes/selectbranch/selectbranch";
import routeCalculation from "./routes/calculation/calculation.route";
import routeStallReport from "./routes/store/stallReport.route";
import routeAdminmanage from "./routes/admin/admin.route";
import routeStatistic from "./routes/statistic/statistic.route";
import routeReportRentStall from "./routes/stall/reportRentStall";
import routeEditProfile from "./routes/editProfile/editProfile.route";
import routeDetailinfo from "./routes/detailinfo/info.route";
import SelectBranches from "./pages/auth/selectBreanches";
import routeTransferApporve from "./routes/tranferApprove/transferApporveroute";
import routeReport from "./routes/report/report.route";

const routes = [
  {
    key: menuSeconds.HOME.key,
    name: menuSeconds.HOME.name,
    textName: menuSeconds.HOME.textName,
    component: Home,
    path: "/",
    appBar: true,
  },
  
  ...routeStore,
  ...routerCancelContract,
  ...routeRentStall,
  ...routeStallReport,
  ...routeCertificate,
  ...routeDepositPay,
  ...routeAnnounce,
  ...routeMarketMap,
  ...routeTransferApporve,
  ...routeReportRentStall,
  ...routeRepair,
  ...routeRequsetForStall,
  ...routeStallAgreement,
  ...routeSue,
  ...routeTrackBill,
  ...routePayBill,
  ...routeUtility,
  ...routeTransferStall,
  ...routeSettings,
  ...routeSystemLog,
  ...routeStatistic,
  ...routeAdminmanage,
  ...routeDetailinfo,
  ...routeEditProfile,
  ...routeReport,
  ...routeCalculation,
];

export default routes;
