import BaseAPI from '../api'
import { FindAllInterface } from '../apiModel'

const path = 'auth'

export interface LoginApiProps {
  token?: string
  otp?: string,
  otpType?: string
  otpRefer?: string
  email?: string
  id_token?: string
}

export default class LoginApi extends BaseAPI {

  static login(mobileOrEmail: string, password: string, reCaptcha: string): Promise<any> {
    return this.api.post(`${path}/login`, { mobileOrEmail, password, reCaptcha }, {})
      // .then(({ data: { accessToken } }: any) => accessToken)
      .then((res) => res)
  }

  static validate(payload: LoginApiProps) {
    return this.api.post(`${path}/otp/validate`, payload).then((res: any) => res)
  }

  static forgot(payload: LoginApiProps) {
    return this.api.post(`${path}/forgotpassword`, payload).then((res: any) => res)
  }

  static password(payload: LoginApiProps) {
    return this.api.patch(`${path}/password`, payload).then((res: any) => res)
  }

  static menu() {
    return this.api.get(`${path}/menu`).then((res: any) => res)
  }
  static google(payload: LoginApiProps) {
    return this.api.post(`${path}/login/google`, payload).then((res: any) => res)
  }

  static profile() {
    return this.api.get(`${path}/profile`).then((res: any) => res)
  }

  static rolePermissions() {
    return this.api.get(`${path}/rolePermissions`)
  }

  static logFile(props: FindAllInterface) {
    return this.api.get(`${path}/logfile`, { params: { ...props } }).then((res: any) => res)
  }
  
  static statistic(props: FindAllInterface) {
    return this.api.get(`${path}/logfile-static`, { params: { ...props } }).then((res: any) => res)
  }

  static statisticLease(props: FindAllInterface) {
    return this.api.get(`${path}/logfile-lease`, { params: { ...props } }).then((res: any) => res)
  }
}
