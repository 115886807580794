import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'relationships'

export interface RelationshipsInterface {
    relationshipsId?: number
    relationshipsNameTh?: string
    relationshipsNameEn?: string
    search?:string;
    status: string
  }

export default class RelationshipsApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(relationshipsNameTh: string, relationshipsNameEn: string) {
        return this.api.post(path , { relationshipsNameTh, relationshipsNameEn })
    }

    static findById(id: number): Promise<RelationshipsInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: RelationshipsInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
