import { menuFirsts } from "../../constants/menu"
import ReportContainer from "../../pages/reports"

const routeReport = [
    {
      ...menuFirsts.REPORT,
      key: menuFirsts.REPORT.key,
      name: menuFirsts.REPORT.name,
      textName: menuFirsts.REPORT.textName,
      active: false,
      appBar: true,
      component: ReportContainer
    } 
  ]
  
  export default routeReport