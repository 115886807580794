import { menuThirds } from "../../constants/menu"
import Branch from "../../pages/setting/branch/branch"
import NewsType from "../../pages/setting/news/news-type"
import DebtType from "../../pages/setting/stall/payment-type"
import LeasesType from "../../pages/setting/stall/leases-type"
import MaintenanceType from "../../pages/setting/stall/maintenance-type"
import UserPrefixes from "../../pages/setting/user-default/user-prefixes"
import UserRelationships from "../../pages/setting/user-default/user-relashionships"
import WaterManagement from "../../pages/setting/utility/waterManagement"
import ElectricityManagement from "../../pages/setting/utility/electricityManagement"
import StallType from "../../pages/setting/stall/stall-type"
import BidderType from "../../pages/setting/bidder/bidder-type"
import OpenRound from "../../pages/setting/utility/openRound"
import DetailLeasesType from "../../pages/setting/stall/detail-leases-type"

const routeSettings = [
    {
        ...menuThirds.STALL_TYPE,
        key: menuThirds.STALL_TYPE.key,
        name: menuThirds.STALL_TYPE.name,
        textName: menuThirds.STALL_TYPE.textName,
        active: false,
        appBar: true,
        component: StallType
    },
    {
        ...menuThirds.WATER_MANAGEMENT,
        key: menuThirds.WATER_MANAGEMENT.key,
        name: menuThirds.WATER_MANAGEMENT.name,
        textName: menuThirds.WATER_MANAGEMENT.textName,
        active: false,
        appBar: true,
        component: WaterManagement
    },
    {
        ...menuThirds.ELECTRUCITY_MANAGEMENT,
        key: menuThirds.ELECTRUCITY_MANAGEMENT.key,
        name: menuThirds.ELECTRUCITY_MANAGEMENT.name,
        textName: menuThirds.ELECTRUCITY_MANAGEMENT.textName,
        active: false,
        appBar: true,
        component: ElectricityManagement
    },
    {
        ...menuThirds.OPEN_ROUND,
        key: menuThirds.OPEN_ROUND.key,
        name: menuThirds.OPEN_ROUND.name,
        textName: menuThirds.OPEN_ROUND.textName,
        active: false,
        appBar: true,
        component: OpenRound
    },
    {
        ...menuThirds.USER_PREFIX,
        key: menuThirds.USER_PREFIX.key,
        name: menuThirds.USER_PREFIX.name,
        textName: menuThirds.USER_PREFIX.textName,
        active: false,
        appBar: true,
        component: UserPrefixes
    },
    {
        ...menuThirds.USER_RELATIONSHIPS,
        key: menuThirds.USER_RELATIONSHIPS.key,
        name: menuThirds.USER_RELATIONSHIPS.name,
        textName: menuThirds.USER_RELATIONSHIPS.textName,
        active: false,
        appBar: true,
        component: UserRelationships
    },
    {
        ...menuThirds.DEBT_TYPE,
        key: menuThirds.DEBT_TYPE.key,
        name: menuThirds.DEBT_TYPE.name,
        textName: menuThirds.DEBT_TYPE.textName,
        active: false,
        appBar: true,
        component: DebtType
    },
    {
        ...menuThirds.LEASES_TYPE,
        key: menuThirds.LEASES_TYPE.key,
        name: menuThirds.LEASES_TYPE.name,
        textName: menuThirds.LEASES_TYPE.textName,
        active: false,
        appBar: true,
        component: LeasesType
    },
    {
        ...menuThirds.LEASES_TYPE,
        key: menuThirds.LEASES_TYPE.key,
        name: menuThirds.LEASES_TYPE.name,
        textName: menuThirds.LEASES_TYPE.textName,
        active: false,
        appBar: true,
        path: menuThirds.LEASES_TYPE.path + '/add',
        component: DetailLeasesType
    },
    {
        ...menuThirds.LEASES_TYPE,
        key: menuThirds.LEASES_TYPE.key,
        name: menuThirds.LEASES_TYPE.name,
        textName: menuThirds.LEASES_TYPE.textName,
        active: false,
        appBar: true,
        path: menuThirds.LEASES_TYPE.path + '/:id',
        component: DetailLeasesType
    },
    {
        ...menuThirds.MAINTENANCE_TYPE,
        key: menuThirds.MAINTENANCE_TYPE.key,
        name: menuThirds.MAINTENANCE_TYPE.name,
        textName: menuThirds.MAINTENANCE_TYPE.textName,
        active: false,
        appBar: true,
        component: MaintenanceType  
    },
    {
        ...menuThirds.BRANCH,
        key: menuThirds.BRANCH.key,
        name: menuThirds.BRANCH.name,
        textName: menuThirds.BRANCH.textName,
        active: false,
        appBar: true,
        component: Branch
    },
    {
        ...menuThirds.NEWS_TYPE,
        key: menuThirds.NEWS_TYPE.key,
        name: menuThirds.NEWS_TYPE.name,
        textName: menuThirds.NEWS_TYPE.textName,
        active: false,
        appBar: true,
        component: NewsType
    },
    {
        ...menuThirds.BIDDER_TYPE,
        key: menuThirds.BIDDER_TYPE.key,
        name: menuThirds.BIDDER_TYPE.name,
        textName: menuThirds.BIDDER_TYPE.textName,
        active: false,
        appBar: true,
        component: BidderType
    },

    
]

export default routeSettings