import { menuSeconds } from "../../constants/menu"
import RentStall from "../../pages/stall/list-rent-stall/rentStall"

const routeRentStall = [
  {
    ...menuSeconds.LIST_RENT_STALL,
    key: menuSeconds.LIST_RENT_STALL.key,
    name: menuSeconds.LIST_RENT_STALL.name,
    textName: menuSeconds.LIST_RENT_STALL.textName,
    active: false,
    appBar: true,
    component: RentStall
  },
]

export default routeRentStall