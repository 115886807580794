import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'
import Certificate from '../../../assets/slide-menu.tsx/certificate';

const path = 'certificate'

export interface CertificateInterface {
    certificateId?: Number
    certificateNo?: Number
    branchId?: number
    certificateDate: string
    certificateDateEnd: string
    userId?: number
    status?: string
    CertificateApprove: {
        certificateId?: Number
        approveDate: Date
        remark: string
        employeeId: number
        status?: string
    }
}

export default class CertificateAPI extends BaseAPI {

    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(leaseAgreementId: number, branchId: number, userId: number, status: string, certificateDate: string, certificateDateEnd: string) {
        return this.api.post(path, { leaseAgreementId, branchId, userId, status, certificateDate, certificateDateEnd })
    }

    static createAndRequestDetail(leaseAgreementId: number, branchId: number, userId: number, status: string, certificateDate: string, certificateDateEnd: string, requestDetail: string) {
        return this.api.post(path, { leaseAgreementId, branchId, userId, status, certificateDate, certificateDateEnd, requestDetail })
    }

    static approve(approveDate: string, certificateId: number, remark: string, employeeId: number, noDoc: string, reason: string, status: string) {
        return this.api.post(`${path}/approve`, { approveDate, certificateId, remark, employeeId, noDoc, reason, status })
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: CertificateInterface): Promise<any> {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status })
    }

    static delete(id: number): Promise<any> {
        return this.api.delete(`${path}/${id}`)
    }
}
