import { menuFirsts, menuSeconds } from "../../constants/menu"
import PayBill from "../../pages/payBill/payBill"
import ListPayment from "../../pages/payBill/listPayment"
import CreatePaybill from "../../pages/payBill/createPaybill"
import { routeName } from "../routers-name"
import OverdueListPayment from "../../pages/payBill/overduelistpayment"
import Invoice from "../../pages/payBill/invoice"
import EditPayment from "../../pages/payBill/editPayment"
const routePayBill = [
    {
      ...menuFirsts.PAY_BILL,
      key: menuFirsts.PAY_BILL.key,
      name: menuFirsts.PAY_BILL.name,
      textName: menuFirsts.PAY_BILL.textName,
      active: false,
      appBar: true,
      component: PayBill
    },
    {
      ...menuFirsts.PAY_BILL,
      key: menuFirsts.PAY_BILL.key,
      name: menuFirsts.PAY_BILL.name,
      textName: menuFirsts.PAY_BILL.textName,
      path: routeName.payBill + '/list-payment',
      active: false,
      appBar: true,
      component: ListPayment
    },

    {
      ...menuFirsts.PAY_BILL,
      key: menuFirsts.PAY_BILL.key,
      name: menuFirsts.PAY_BILL.name,
      textName: menuFirsts.PAY_BILL.textName,
      path: routeName.payBill + '/create-paybill',
      active: false,
      appBar: true,
      component: CreatePaybill
    },

    {
      ...menuFirsts.PAY_BILL,
      key: menuFirsts.PAY_BILL.key,
      name: menuFirsts.PAY_BILL.name,
      textName: menuFirsts.PAY_BILL.textName,
      path: routeName.payBill + '/over',
      active: false,
      appBar: true,
      component: OverdueListPayment
    },

    {
      ...menuSeconds.PAY_BILL_INVOICE,
      key: menuSeconds.PAY_BILL_INVOICE.key,
      name: menuSeconds.PAY_BILL_INVOICE.name,
      textName: menuSeconds.PAY_BILL_INVOICE.textName,
      path: routeName.payBill + '/invoice',
      active: false,
      appBar: true,
      component: Invoice
    },
    {
      ...menuFirsts.PAY_BILL,
      key: menuFirsts.PAY_BILL.key,
      name: menuFirsts.PAY_BILL.name,
      textName: menuFirsts.PAY_BILL.textName,
      path: routeName.payBill + '/edit-payment',
      active: false,
      appBar: true,
      component: EditPayment
    }


  ]
  
  export default routePayBill