import React from 'react'
import ModalCustom from '../../../component/modalCustom/modalCustom'
import { Col, Row } from 'react-bootstrap'
import { Divider, Typography } from '@mui/material'
import AutocompleteSelect from '../../../component/select/autoCompleteSelect'
import InputDatePicker from '../../../component/input/inputDatePicker'

type Props = {
    isShow: boolean
    onSubmit: () => void;
    onClose: (status: boolean) => void;
    onReset: (status: boolean) => void;
}

const IssueAndInvoicePopup = (props: Props) => {
    return (
        <>
            <ModalCustom
                title='ออกใบแจ้งหนี้'
                isShowProps={props.isShow}
                size='lg'
                onSubmit={props.onSubmit}
                onClose={() => props.onClose(false)}
                onReset={() => props.onReset(false)}
                textBtnConfirm='ยืนยัน'
                textBtnCancel='ยกเลิก'
                component={
                    <>
                        <Row>
                            <Col>
                                <Typography variant='h6'>{"รายละเอียดแผงค้า"}</Typography>
                            </Col>
                        </Row>
                        <section className='w-100 d-flex justify-content-center align-items-center'>
                            <article style={{ width: 129 }}>
                                {"ประเภทสัญญา"}
                            </article>
                            <article className='w-100'>
                                <Row className='px-1'>
                                    <Col className='px-2'>
                                        <AutocompleteSelect
                                            labelId='selectId-2'
                                            options={[]}
                                            placeholder=''
                                        />
                                    </Col>
                                    <Col className='px-2'>
                                        <AutocompleteSelect
                                            labelId='selectId-3'
                                            options={[]}
                                            placeholder='โซนแผงค้า'
                                        />
                                    </Col>
                                    <Col className='px-2'>
                                        <AutocompleteSelect
                                            labelId='selectId-3'
                                            options={[]}
                                            placeholder='แผงค้า'
                                        />
                                    </Col>
                                </Row>
                            </article>
                        </section>
                        <Row className='mt-4 mb-4'>
                            <Divider />
                        </Row>
                        <Row sx={{ mt: 2 }}>
                            <Col>
                                <Typography variant='h6'>{"วันที่แจ้งประกาศ"}</Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} md={6} lg={2} className='d-flex'> {"วันที่เอกสาร"}</Col>
                            <Col sm={6} md={6} lg={4} className='d-flex '>
                                <InputDatePicker
                                    onChange={() => { }}
                                    value={""}
                                />
                            </Col>
                            <Col sm={6} md={6} lg={2} className='d-flex '>
                                {"วันที่สิ้นสุด"}
                            </Col>
                            <Col sm={6} md={6} lg={4} className='d-flex '>
                                <InputDatePicker
                                    onChange={() => { }}
                                    value={""}
                                /></Col>
                        </Row>
                    </>
                }
            />
        </>
    )
}

export default IssueAndInvoicePopup