import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, Divider, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../../component/input/inputTextField";
import InputDatePicker from "../../../component/input/inputDatePicker";

/** API, UTIL */
import FilterSelect from "../../../component/select/filterSelect";
import PrefixApi from "../../../api/setting/prefix/prefix.api";

/** STYLE, CONSTANT */
import * as Style from "./stallAgreement.style";
import { imgs } from "../../../constants/images";
import ProvincesApi from "../../../api/setting/provinces/provinces.api";
import Cloudupload from "../../../assets/icon/cloudupload";
import { BoxText } from "../../repair/add/addRepairStyle";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router-dom";
import LeaseAgreementApi from "../../../api/stall/leaseAgreement/leaseAgreement.api";
import UserApi from "../../../api/auth/users.api";
import {
  notiError,
  notiSuccess,
} from "../../../component/notification/notifications";
import { getProfile, handleTypingNumber } from "../../../utils/app.utils";
import ButtonCustom from "../../../component/button/buttonCustom";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import { decode } from 'js-base64';
import LeasesTypeApi from "../../../api/setting/leases/leases.api";
import { routeName } from "../../../routes/routers-name";
import { colors } from "../../../constants/colors";
import InputCheckbox from "../../../component/input/inputCheckbox";

const initStateErrorMessage = {
  START_DATE: ``,
  END_DATE: ``,
  AMOUNT: ``,
  PAYMENT: ``
};

export default function AgreementAdmin() {
  const { t }: any = useTranslation();
  const history: any = useHistory();
  const location: any = useLocation();
  const profile: any = JSON.parse(getProfile() || "{}");
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);

  const [prefixData, setPrefixData] = useState<any[]>([]);
  const [prefix, setPrefix] = useState<string>("นาย");
  const [prefixId, setPrefixId] = useState<number>();

  const [provinceData, getProvinceData] = useState<any[]>([]);
  const [districtsData, getDistrictsData] = useState<any[]>([]);
  const [subDistrictsData, getSubDistrictsData] = useState<any[]>([]);
  const [provinceId, setProvinceId] = useState<string>("");
  const [districtsId, setDistrictsId] = useState<string>("");
  const [subDistrictsId, setSubDistrictsId] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [birthDay, setBirthDay] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [houseNumber, setHouseNumber] = useState<string>("");
  const [moo, setMoo] = useState<string>("");
  const [village, setVillage] = useState<string>("");
  const [soi, setSoi] = useState<string>("");
  const [road, setRoad] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [recoment, setRecoment] = useState<string>("");
  const [select, setSelectfile] = useState<any>();
  const [file, setFile] = useState<any>([]);
  const [paymentCycleData, setPaymentCycleData] = useState<any[]>([])
  const [paymentCycle, setPaymentCycle] = useState<number>(0)
  const [amount, setAmount] = useState<number>()
  const [typeDetail, setTypeDetail] = useState<any>();
  const [yearType, setYearType] = useState<string>('1');
  const [rentalRate, setRentalRate] = useState<number>();
  const [rentalRate2, setRentalRate2] = useState<number>();
  const [rentalRate3, setRentalRate3] = useState<number>();
  const [rentalPenaltyRate, setRentalPenaltyRate] = useState<number>();
  const [moveOutPenaltyRate, setMoveOutPenaltyRate] = useState<number>();
  const [damageInsuranceLimit, setDamageInsuranceLimit] = useState<number>();

  const state = location.state.params.state;

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf" ||
        detailImage.ext === "jpeg"
      ) {
        // Do something with the files
        setFile([...file, acceptedFiles[0]]);

      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }

      // setFile(URL.createObjectURL(acceptedFiles[0]));
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handlepreview = (x: any) => {
    const name = x.type.split(".");

    if (name[name?.length - 1] === "application/pdf") {
      window.open(URL.createObjectURL(x), "_blank");
    } else {
      setSelectfile(URL.createObjectURL(x));
    }
  };
  const handledelete = (index: number) => {
    setFile(file?.filter((_: any, i: number) => i !== index));
    setSelectfile(null);
  };
  const getPrefixes = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5,
    };

    const res = await PrefixApi.findAll(condition);
    setPrefixData(res.data);
  };
  const getDetilargeement = async () => {
    const condition: any = {
      id: state?.userId,
    };
    if (state?.leaseAgreement === null) {
      await UserApi.getDetail(condition).then((res) => {
        if (res?.status === 200) {
          setFirstname(res?.data?.firstname);
          setLastname(res?.data?.lastname);
          setIdCard(res?.data?.idCard);
          setBirthDay(res?.data?.birthDay);
          setMobilePhone(res?.data?.mobilePhone);
          setEmail(res?.data?.email);
          setTaxId(res?.data?.taxId);
          setHouseNumber(res?.data?.houseNumber);
          setMoo(res?.data?.moo);
          setVillage(res?.data?.village);
          setSoi(res?.data?.soi);
          setRoad(res?.data?.road);
          setProvinceId(res?.data?.provinceId);
          setDistrictsId(res?.data?.districtId);
          setSubDistrictsId(res?.data?.subDistrictId);
          setZipCode(res?.data?.zipCode);
          setPrefixId(res?.data?.prefixId);
          setStartDate(res?.data?.leaseAgreementDateStart);
        }
      });
    } else {
      setFirstname(state?.leaseAgreement?.firstname);
      setLastname(state?.leaseAgreement?.lastname);
      setIdCard(state?.leaseAgreement?.idCard);
      setBirthDay(state?.leaseAgreement?.birthDay);
      setMobilePhone(state?.leaseAgreement?.mobilePhone);
      setEmail(state?.leaseAgreement?.email);
      setTaxId(state?.leaseAgreement?.taxId);
      setHouseNumber(state?.leaseAgreement?.houseNumber);
      setMoo(state?.leaseAgreement?.moo);
      setVillage(state?.leaseAgreement?.village);
      setSoi(state?.leaseAgreement?.soi);
      setRoad(state?.leaseAgreement?.road);
      setProvinceId(state?.customer?.provinceId);
      setDistrictsId(state?.customer?.districtId);
      setSubDistrictsId(state?.customer?.subDistrictId);
      setZipCode(state?.leaseAgreement?.zipCode);
      setPrefixId(state?.customer?.prefixId);
      setStartDate(state?.leaseAgreement?.leaseAgreementDateStart);
      setEndDate(state?.leaseAgreement?.leaseAgreementDateEnd);
      setRecoment(state?.leaseAgreement?.leaseAgreementRemark);
      // setFile(state?.leaseAgreement?.leaseAgreementApprove?.file);
      //
    }
  };

  useEffect(() => {
    if (state) {
      getDetilargeement();
    }

    getPrefixes();
  }, [state]);
  const getProvince = async () => {
    const res = await ProvincesApi.findAll();
    getProvinceData(res.data);
  };

  useEffect(() => {
    getProvince();
  }, []);
  const getDistricts = async () => {
    getDistrictsData([]);
    const condition: any = {
      provinceId: Number(provinceId),
    };
    const res = await ProvincesApi.districts(condition);
    if (res.status === 200) {
      getDistrictsData(res.data);
    } else {
      getDistrictsData([]);
    }
  };
  const loadPaymentData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5
    }
    await LeasesTypeApi.findAll(condition)
      .then((res: any) => {
        if (res.status === 200) {
          setPaymentCycleData(res?.data)
          console.log(res.data)
        } else {
          setPaymentCycleData([])
        }
      })
  }

  useEffect(() => {
    loadPaymentData()
  }, [])


  const getSubDistricts = async () => {
    getSubDistrictsData([]);
    const condition: any = {
      districtId: Number(districtsId),
    };
    const res = await ProvincesApi.subDistricts(condition);
    if (res.status === 200) {
      getSubDistrictsData(res.data);
    } else {
      getSubDistrictsData([]);
    }
  };
  useEffect(() => {
    getDistricts();
  }, [provinceId]);

  useEffect(() => {
    getSubDistricts();
  }, [districtsId]);

  const onSubmit = async () => {
    const condition: any = {
      appointmentId: state?.appointmentId,
      leaseAgreementDaft: "no",
      branchId: state?.bidder?.branchId,
      leaseAgreementDate: moment(Date.now()).format("YYYY-MM-DD"),
      leaseAgreementRemark: recoment,
      userId: state.userId,
      prefixId: prefixId,
      firstname: firstname,
      lastname: lastname,
      idCard: idCard,
      birthDay: moment(birthDay).format("YYYY-MM-DD"),
      mobilePhone: mobilePhone,
      email: email,
      taxId: taxId || "",
      houseNumber: houseNumber,
      moo: moo || "",
      village: village || "",
      soi: soi || "",
      road: road || "",
      provinceId: provinceId,
      districtId: districtsId,
      subDistrictId: subDistrictsId,
      zipCode: zipCode,
      leaseAgreementTypeId: paymentCycle,
      leaseAgreementDateStart: startDate,
      leaseAgreementDateEnd: endDate,
      amount: +(rentalRate || 0),
      leasesTypeId: paymentCycle,
      leaseAgreementApprove: {
        approveDate: moment(Date.now()).format("YYYY-MM-DD"),
        bidderId: state.bidderId,
        remark: recoment,
        employeeId: JSON.parse(decode(profile))?.empId,
        status: "ACTIVE",
        stallId: state?.bidder?.stallId,
      },
      yearType: yearType,
      rentalRate: +(rentalRate || 0),
      rentalRate2: +(rentalRate2 || 0),
      rentalRate3: +(rentalRate3 || 0),
      rentalPenaltyRate: +(rentalPenaltyRate || 0),
      moveOutPenaltyRate: +(moveOutPenaltyRate || 0),
      damageInsuranceLimit: +(damageInsuranceLimit || 0),
      paymentType: typeDetail?.paymentType,
      payAtDate: typeDetail?.payAtDate || '',
      payAtEndOfMonth: typeDetail?.payAtEndOfMonth,
    };
    if (paymentCycle === 0)
      return setErrorMessage({
        ...errorMessage,
        ...{ PAYMENT: t(`AGREEMENT.TEXTALERT.PAYMENT`) },
      });
    // if (!rentalRate) return setErrorMessage({ ...errorMessage, ...{ RENTALRATE: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalRate2) return setErrorMessage({ ...errorMessage, ...{ RENTALRATE2: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalRate3) return setErrorMessage({ ...errorMessage, ...{ RENTALRATE3: t('กรุณากรอกอัตราค่าเช่า') } })
    // if (!rentalPenaltyRate) return setErrorMessage({ ...errorMessage, ...{ RENTAL_PENALTY_RATE: t('กรุณากรอกค่าปรับค่าเช่า') } })
    // if (!moveOutPenaltyRate) return setErrorMessage({ ...errorMessage, ...{ MOVE_OUT_PENALTY_RATE: t('กรุณากรอกค่าปรับกรณีไม่ยอมออกจากพื้นที่') } })
    // if (!damageInsuranceLimit) return setErrorMessage({ ...errorMessage, ...{ DAMAGE_INSURANCE_LIMIT: t('กรุณากรอกวงเงินประกันความเสียหาย') } })

    // if (!amount)
    //   return setErrorMessage({
    //     ...errorMessage,
    //     ...{ AMOUNT: t(`AGREEMENT.TEXTALERT.AMOUNT`) },
    //   });
    if (!startDate)
      return setErrorMessage({
        ...errorMessage,
        ...{ START_DATE: t(`AGREEMENT.TEXTALERT.START_DATE`) },
      });
    if (!endDate)
      return setErrorMessage({
        ...errorMessage,
        ...{ END_DATE: t(`AGREEMENT.TEXTALERT.END_DATE`) },
      });
    if (file?.length === 0) {
      notiError('กรุณาอัพโหลดไฟล์อย่างน้อย 1 ไฟล์')
      return;
    }
    await LeaseAgreementApi.create(condition)
      .then((res) => {
        if (res.status === 201) {
          const formData = new FormData();

          let ref = res?.data?.refId || null;

          formData.append("refId", `${ref}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          file?.forEach((data: any, i: any) => {
            formData.append(`files[]`, data);
          });

          FileuploadApi.uploadfilearray(formData).then((result) => {
            notiSuccess(
              "สร้างสัญญา สำเร็จ",
              "go", '/stall-agreement', null
            );
          });
        } else {
          notiError("สร้างสัญญา ไม่สำเร็จ");
        }
      })
      .catch(() => {
        notiError("สร้างสัญญา ไม่สำเร็จ");
      });
  };
  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage);

  return (
    <>
      <Style.MainContainer>
        <Style.Topic>{t("AGREEMENT.HEADER")}</Style.Topic>
        <Divider className="mx-0" />

        <Style.InformationRenter>
          <Style.BgInformation>
            <Grid container spacing={2}>
              {/* <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className="w-100 d-flex justify-content-center"
              >
                <Style.PicRenter src={imgs.defaultAvatar} alt="" />
              </Grid> */}
              <Style.GridInput item
                sm={4}>
                <div className="">
                  <FilterSelect
                    onchange={(e: any) => {
                      setPrefix(e.target.value);
                      setPrefixId(e);
                    }}
                    options={
                      prefixData &&
                      prefixData.map((e: any) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              setPrefixId(e.prefixId);
                              setPrefix(e.prefixNameTh || e.prefixNameEn);
                            }}
                          >
                            {e.prefixNameTh || e.prefixNameEn}
                          </MenuItem>
                        );
                      })
                    }
                    selectId="select-prefix"
                    value={prefix}
                    renderValue={() => prefix}
                    labelId="label-prefixId"
                    required
                    disabled={true}
                    heading={t("AGREEMENT.INPUT.PREFIX")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item
                // lg={3} 
                sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={firstname}
                    onchange={(e: any) => setFirstname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Style.GridInput item
                //  lg={3} 
                sm={4}>
                <div>
                  <InputTextField
                    disabled={true}
                    value={lastname}
                    onchange={(e: any) => setLastname(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.LAST_NAME")}
                  />
                </div>
              </Style.GridInput>
              <Grid item lg={4} sm={5}>
                <div className=" mt-2">
                  <InputTextField
                    disabled={true}
                    value={idCard}
                    onchange={(e: any) => setIdCard(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.ID_CARD")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={5}>
                <div className="mt-2">
                  <InputDatePicker
                    disabled={true}
                    value={birthDay}
                    key={"date"}
                    onChange={(e: any) =>
                      setBirthDay(moment(e).format("YYYY-MM-DD"))
                    }
                    heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={2}>
                <div className="d-flex flex-column mt-2">
                  <Style.FlexRow>
                    <Style.Detail>{t("AGREEMENT.INPUT.AGE")} </Style.Detail>
                    <Style.RequestDetail>*</Style.RequestDetail>
                  </Style.FlexRow>
                  <Style.BoxInputAge>
                    <InputTextField
                      disabled={true}
                      value={birthDay && moment().diff(birthDay, "years")}
                      inputTextAlign="center"
                      size="medium"
                    />
                    <Typography className="ml-2">
                      {t("AGREEMENT.INPUT.YEAR")}
                    </Typography>
                  </Style.BoxInputAge>
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={mobilePhone}
                    onchange={(e: any) => setMobilePhone(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.TEL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    disabled={true}
                    value={email}
                    onchange={(e: any) => setEmail(e.target.value)}
                    size="medium"
                    required
                    heading={t("AGREEMENT.INPUT.EMAIL")}
                  />
                </div>
              </Grid>
              <Grid item lg={4} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    value={taxId}
                    onchange={(e: any) => setTaxId(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.TAX_ID")}
                  // disabled={true}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className="mt-2">
                  <InputTextField
                    required
                    value={houseNumber}
                    onchange={(e: any) => setHouseNumber(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ADRESS")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    value={moo}
                    onchange={(e: any) => setMoo(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.MOO")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={6}>
                <div className=" mt-2">
                  <InputTextField
                    value={village}
                    onchange={(e: any) => setVillage(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.VILLAGE")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    value={soi}
                    onchange={(e: any) => setSoi(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.SOI")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    // required
                    value={road}
                    onchange={(e: any) => setRoad(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ROAD")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={provinceData}
                    getOptionLabel={(option) =>
                      option.provinceNameTh || option.provinceNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setProvinceId(value.provinceId);
                      }
                    }}
                    value={
                      provinceData.find((d) => d?.provinceId === provinceId) ||
                      provinceId ||
                      null
                    }
                    labelId="label-provinceId"
                    required={true}
                    heading={t("STALL.DETAIL.CITY")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={districtsData}
                    getOptionLabel={(option) =>
                      option.districtNameTh || option.districtNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setDistrictsId(value.districtId);
                      }
                    }}
                    value={
                      (districtsData &&
                        districtsData.find(
                          (d) => d?.districtId === districtsId
                        )) ||
                      districtsId ||
                      null
                    }
                    labelId="label-districts"
                    required={true}
                    heading={t("STALL.DETAIL.DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <AutocompleteSelect
                    options={subDistrictsData}
                    getOptionLabel={(option) =>
                      option.subDistrictNameTh || option.subDistrictNameEn || ""
                    }
                    onChange={(event, value) => {
                      if (value !== null) {
                        setSubDistrictsId(value.subDistrictId);

                        const selectedSubDistrict = subDistrictsData.find(
                          (d) =>
                            d.subDistrictId === value?.subDistrictId || null
                        );
                        const { postcode } = selectedSubDistrict || {};
                        setZipCode(postcode || "");
                      } else {
                        setZipCode("");
                      }
                    }}
                    value={
                      (subDistrictsData &&
                        subDistrictsData.find(
                          (d) => d.subDistrictId === subDistrictsId
                        )) ||
                      subDistrictsId
                    }
                    labelId="label-subDistricts"
                    required={true}
                    heading={t("STALL.DETAIL.SUB_DISTRICT")}
                  />
                </div>
              </Grid>
              <Grid item lg={3} sm={4}>
                <div className=" mt-2">
                  <InputTextField
                    required
                    disabled={true}
                    value={zipCode}
                    onchange={(e: any) => setZipCode(e.target.value)}
                    size="medium"
                    heading={t("AGREEMENT.INPUT.ZIPCODE")}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={12} sm={12} className="auto mt-3">
                <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>{t("AGREEMENT.DETAIL.HEAD")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.ZONE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{state?.bidder?.zoneName}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.STALL")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{state?.bidder?.stallName}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("รูปแบบสัญญาเช่า")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <FilterSelect
                  onchange={(e: any) => {
                    setPaymentCycle(e.target.value)
                    clearErrorMessage()
                    setTypeDetail(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value))
                    setYearType(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).yearType)

                    setRentalRate(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).rentalRate)
                    setRentalRate2(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).rentalRate2)
                    setRentalRate3(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).rentalRate3)
                    setRentalPenaltyRate(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).rentalPenaltyRate)
                    setMoveOutPenaltyRate(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).moveOutPenaltyRate)
                    setDamageInsuranceLimit(paymentCycleData.find((item: any) => item.leasesTypeId === e.target.value).damageInsuranceLimit)
                  }}
                  options={paymentCycleData && paymentCycleData.map((e: any) => {
                    return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                  })}
                  selectId="select-prefix"
                  value={paymentCycle}
                  labelId="label-prefixId"
                  required
                  helperText={errorMessage.PAYMENT}
                />
              </Grid>
              {typeDetail && <>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("Rate ค่าเช่า")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={typeDetail?.paymentType || ''}
                      row
                    >
                      <FormControlLabel value="MONTHLY" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} disabled />} label={'รายเดือน'} />
                      <FormControlLabel value="DAILY" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} disabled />} label={'รายวัน'} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  {t("ระยะสัญญา")} :
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={yearType}
                      onChange={(e) => setYearType(e.target.value)}
                      row
                    >
                      <FormControlLabel value="1" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} />} label={'1ปี'} />
                      <FormControlLabel value="3" control={<Radio sx={{
                        '&.Mui-checked': {
                          color: '#14378A !important',
                        },
                      }} />} label={'3ปี'} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("กำหนดการชำระเงิน")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <div className='d-flex gap-3'>
                    <InputCheckbox disabled checked={typeDetail?.payAtDate} onChange={(e) => {
                      // setPayAtDateCheck(e.target.checked)
                      // clearErrorMessage()
                    }} label={<Typography sx={{ whiteSpace: 'nowrap' }}>ทุกวันที่</Typography>} />
                    <InputTextField value={typeDetail?.payAtDate}
                      //  onchange={(e) => setPayAtDate(e.target.value)} 
                      style={{ width: '150px' }} disabled />
                    <InputCheckbox disabled checked={typeDetail?.payAtEndOfMonth} onChange={(e) => {
                      // setPayAtEndOfMonth(e.target.checked)
                      // clearErrorMessage()
                    }} label={<Typography sx={{ whiteSpace: 'nowrap' }} >สิ้นเดือน</Typography>} />
                  </div>
                </Grid>
                {yearType == '1' ? <>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("อัตราค่าเช่า")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate} onchange={(e) => {
                    setRentalRate(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE || ''}
                    // style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid></> : <>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("อัตราค่าเช่าปีที่ 1")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate} onchange={(e) => {
                    setRentalRate(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE || ''}
                    // style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("อัตราค่าเช่าปีที่ 2")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate2} onchange={(e) => {
                    setRentalRate2(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE2 || ''}
                    // style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("อัตราค่าเช่าปีที่ 3")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={rentalRate3} onchange={(e) => {
                    setRentalRate3(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTALRATE3 || ''}
                    // style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid>
                </>}
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("ค่าปรับค่าเช่า (ร้อยละ)")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={rentalPenaltyRate || ''} onchange={(e) => {
                    setRentalPenaltyRate(e.target.value)
                    clearErrorMessage()
                  }}
                    helperText={errorMessage.RENTAL_PENALTY_RATE || ''}
                    // style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("ค่าปรับกรณีไม่ยอมออกจากพื้นที่")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={moveOutPenaltyRate || ''} onchange={(e) => {
                    setMoveOutPenaltyRate(e.target.value)
                    clearErrorMessage()
                  }} helperText={errorMessage.MOVE_OUT_PENALTY_RATE || ''}
                    // style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid>
                <Grid item lg={3} sm={3} className="auto">
                  <Typography>{t("วงเงินประกันความเสียหาย")}</Typography>
                </Grid>
                <Grid item lg={3} sm={3} className="auto d-flex align-items-center gap-2">
                  <InputTextField value={damageInsuranceLimit || ''} onchange={(e) => {
                    setDamageInsuranceLimit(e.target.value)
                    clearErrorMessage()
                  }} helperText={errorMessage.DAMAGE_INSURANCE_LIMIT || ''}
                    //  style={{ width: "180px" }}
                    onkeypress={handleTypingNumber}
                  />
                  {/* <Typography>บาท</Typography> */}
                </Grid>
              </>}
              {/* <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.PRICE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <InputTextField
                  onkeypress={handleTypingNumber}
                  value={amount}
                  onchange={(e: any) => {
                    setAmount(e.target.value)
                    clearErrorMessage()
                  }}
                  size="medium"
                  required
                  helperText={errorMessage.AMOUNT}
                />
              </Grid> */}
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.STATRT_DATE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <InputDatePicker
                  allowClear
                  onClear={() => setStartDate("")}
                  disableEndDate={endDate}
                  value={startDate}
                  key={"start-date"}
                  onChange={(e: any) => {
                    setStartDate(moment(e).format("YYYY-MM-DD"))
                    clearErrorMessage()
                  }
                  }
                  disablePast
                  helperText={errorMessage.START_DATE}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.END_DATE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <InputDatePicker
                  allowClear
                  onClear={() => setEndDate("")}
                  disableStartDate={startDate}
                  disablePast
                  value={endDate}
                  key={"end-date"}
                  onChange={(e: any) => {
                    setEndDate(moment(e).format("YYYY-MM-DD"))
                    clearErrorMessage()
                  }
                  }
                  helperText={errorMessage.END_DATE}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.RECCOMENT")}</Typography>
              </Grid>
              <Grid item lg={9} sm={3} className="auto">
                <InputTextField
                  required
                  value={recoment}
                  multiline={true}
                  maxRows={4}
                  onchange={(e: any) => setRecoment(e.target.value)}
                  size="medium"
                  style={{ height: 80, outerHeight: 80 }}
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                {t("ADDREPAIR.DETAIL.ATTACH_FILE")}
              </Grid>
              <Grid className="my-2" item lg={9} xs={12}>
                <Style.Boxupload>
                  {" "}
                  {select ? (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <img src={select} alt="Thumb" height="300px" style={{ cursor: 'pointer' }} />
                    </div>
                  ) : (
                    <Style.Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />

                        <Box
                          sx={{
                            flexDirection: "column",
                            display: "flex",
                            alignItems: "center",
                            cursor: 'pointer'
                          }}
                        >
                          <Cloudupload />
                          <BoxText>
                            <Typography
                              sx={{
                                marginTop: "24px",
                                fontSize: "18px",
                                fontFamily: "kanit",
                              }}
                            >
                              {"แนบไฟล์เอกสาร PDF,JPG,JPEG,PNG"}
                            </Typography>
                          </BoxText>
                        </Box>
                      </div>
                    </Style.Boxdash>
                  )}
                </Style.Boxupload>
              </Grid>

              {file?.map((x: any, index: number) => (
                <>
                  <Grid item lg={3} xs={12}></Grid>
                  <Grid item lg={1} xs={12} className="my-2">
                    อันดับ {index + 1}
                  </Grid>
                  <Grid key={index} item lg={8} xs={12} className="my-2">
                    <Style.Array>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{x.path}</Box>

                        <Box>
                          <VisibilityIcon
                            onClick={() => handlepreview(x)}
                            sx={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          <DeleteIcon
                            onClick={() => handledelete(index)}
                            color="error"
                            sx={{ cursor: "pointer" }}
                          />
                        </Box>
                      </Box>
                    </Style.Array>
                  </Grid>
                </>
              ))}
            </Grid>
          </Style.BgInformation>
        </Style.InformationRenter>
        <ButtonCustom
          onClick={onSubmit}
          textButton={t("AGREEMENT.BUTTON.SAVE")}
          btnStyle={{
            fontSize: "16px",
            width: "100%",
            padding: "0px",
            marginTop: "32px",
          }}
        />
        <ButtonCustom
          onClick={() => history.push(routeName.stallAgreement)}
          // type="reset"
          mode="del"
          textButton={t("AGREEMENT.BUTTON.CANCEL")}
          btnStyle={{
            fontSize: "16px",
            width: "100%",
            padding: "0px",
            marginTop: "16px",
            marginBottom: "14px",
          }}
          style={{
            '&.MuiButton-contained': {
              // backgroundColor: `${colors.red} !important`,
              ':hover': {
                backgroundColor: `${colors.red} !important`,
              }
            }
          }}
        />
      </Style.MainContainer>
    </>
  );
}
