import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../component/input/inputTextField";
import {
  DateInBox,
  DateInTable,
  NewsBox,
  NewsHeading,
  NewsTitle,
  Readmore,
} from "../home/home.style";
import TableRowCommon from "../../component/table/TableRowCommon";
import { useTranslation } from "react-i18next";
import TableCustom from "../../component/table/tableCustom";
import { colors } from "../../constants/colors";
import { SdCardAlertSharp } from "@mui/icons-material";
import ModalCustom from "../../component/modalCustom/modalCustom";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../app/slice/modal.slice";
import ButtonCustom from "../../component/button/buttonCustom";
import HeaderCard from "../../component/cardCustom/headerCard";

//** STYLE */
import * as Style from "./payMentStyle";
import FilterSelect from "../../component/select/filterSelect";
import { icons } from "../../constants/images";
import { Size } from "../../component/dragAndDrop/resize";
import { width } from "@mui/system";
interface Service {
  label: string;
  filterSelectOptions: any[];
  amountLabel: string;
  descriptionLabel: string;
}

interface PopupPaymentProps {
  zone?: string
  stall?: string
  name?: string
  receipt?: string
  date?: string
  electricityType?: string
  electricityRate?: number
  electricityConsumption?: number
  electricityBill?: number
  electricitymeterimage?: string
  amountElectricityBill?: number
  waterType?: string
  waterRate?: number
  waterConsumption?: number
  waterBill?: number
  watermeterimage?: string
  amountWaterBill?: number
  leasesType?: string
  rentalFee?: number
  stallBill?: number



}


export default function OverdueListPayment(props: PopupPaymentProps) {
  const [listPayment, setListPayment] = useState<boolean>(false);
  const [addPayment, setAddPayment] = useState([]);
  const [role, setRole] = useState<boolean>(false);
  const { t } = useTranslation();

  const [services, setServices] = useState<Service[]>([]);
  const handleServiceAdd = () => {
    const paymentListLabel = t("PAYBILL.REPORT.PAYMENT_LIST");
    const amountLabel = t("PAYBILL.REPORT.AMOUNT");
    const detailLabel = t("PAYBILL.REPORT.DETAIL");

    setServices((prevServices) => [
      ...prevServices,
      {
        label: paymentListLabel,
        filterSelectOptions: [],
        amountLabel: amountLabel,
        descriptionLabel: detailLabel,
      },
    ]);
  };

  const handleServiceDelete = (index: number) => {
    setServices((prevServices) => prevServices.filter((_, i) => i !== index));
  };

  return (
    <>
      <HeaderCard text={t("PAYBILL.TITLE")} />



      <Style.MainContainer>
        <Style.BoxContainer>
          <Style.BoxHeader>
            <Col lg={2} md={4} className="d-flex flex-row">
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.ZONE")}</Style.SubDetail>
              <Style.SubDetail className="mr-3">{t("11")}</Style.SubDetail>
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.STALL")}</Style.SubDetail>
              <Style.SubDetail>{t("A2")}</Style.SubDetail>
            </Col>
            <Col lg={2} md={4} className="d-flex flex-row">
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.NAME")}</Style.SubDetail>
              <Style.SubDetail>{t("สมชาย เก่งกาจ")}</Style.SubDetail>
            </Col>
            <Col lg={2} md={4} className="d-flex flex-row">
              <Style.SubDetail className="mr-3">{t("PAYBILL.REPORT.RECEIPT_NUMBER")}</Style.SubDetail>
              <Style.SubDetail>{t("CMCT-00001")}</Style.SubDetail>
            </Col>

            <Col lg={2} />
            <Col lg={2} md={4} className="d-flex flex-row mt-md-2">
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.DOCUMENTDATE")}</Style.SubDetail>
              <Style.SubDetail>{t("20/03/2566")}</Style.SubDetail>
            </Col>

            <Col>
              <ButtonCustom
                type="button"
                textButton={t("PAYBILL.BUTTON.PRINT")}
                endIcon={<img src={icons.print} />}
                onClick={() => { }}
              />
            </Col>
          </Style.BoxHeader>
          <Divider className="w-100 mx-0" />

          <Row style={{ marginTop: "10px" }}>
            <Style.SubDetail1>{t("PAYBILL.REPORT.ELECTRICITY_USAGE_DETAILS")}</Style.SubDetail1>
          </Row>

          <Style.ListPaymentContainer>
            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
                
                <Col lg={6} xs={6} md={3} className="d-flex flex-row ">
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE")}</Style.SubDetail>
                </Col>
                <Col xs={6}>
                  <Style.SubDetail>{t("ตามมิเตอร์ อกต")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_RATE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_CONSUMPTION")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_ELECTRIC_METER")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t(".png")}</Style.SubDetail>
                </Col>
              </Row>

            </Style.ListPaymentItem>

            <Style.DividerStyle orientation="vertical" flexItem />

            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
                
                <Col lg={6} xs={6} md={3} className="d-flex flex-row ">
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE")}</Style.SubDetail>
                </Col>
                <Col xs={6}>
                  <Style.SubDetail>{t("ตามมิเตอร์ อกต")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_RATE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_CONSUMPTION")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_ELECTRIC_METER")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t(".png")}</Style.SubDetail>
                </Col>
              </Row>

            </Style.ListPaymentItem>

            <Style.DividerStyle orientation="vertical" flexItem />

            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
                
                <Col lg={6} xs={6} md={3} className="d-flex flex-row ">
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE")}</Style.SubDetail>
                </Col>
                <Col xs={6}>
                  <Style.SubDetail>{t("ตามมิเตอร์ อกต")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_RATE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_CONSUMPTION")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_ELECTRIC_METER")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t(".png")}</Style.SubDetail>
                </Col>
              </Row>

            </Style.ListPaymentItem>

          </Style.ListPaymentContainer>

          <Style.BoxResult>
            <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_ELECTRICITY_ฺBILL")}</Style.HeadMessage>
            <Style.HeadMessage>{t("11")}</Style.HeadMessage>
            <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
          </Style.BoxResult>

          <Divider className="w-100 mx-0" />

          <Row style={{ marginTop: "10px" }}>
            <Style.SubDetail1>{t("PAYBILL.REPORT.WATER_USAGE_DETAILS")}</Style.SubDetail1>
          </Row>


          <Style.ListPaymentContainer>
            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
             
                <Col lg={6} xs={6} md={3} >
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE")}</Style.SubDetail>
                </Col>
                <Col xs={6}>
                  <Style.SubDetail>{t("ตามมิเตอร์ อกต")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_RATE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_CONSUMPTION")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_WATER_METER")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t(".png")}</Style.SubDetail>
                </Col>
              </Row>
            </Style.ListPaymentItem>

            <Divider orientation="vertical" flexItem />

            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
             
                <Col lg={6} xs={6} md={3} >
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE")}</Style.SubDetail>
                </Col>
                <Col xs={6}>
                  <Style.SubDetail>{t("ตามมิเตอร์ อกต")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_RATE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_CONSUMPTION")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_WATER_METER")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t(".png")}</Style.SubDetail>
                </Col>
              </Row>
            </Style.ListPaymentItem>

            <Divider orientation="vertical" flexItem />

            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
             
                <Col lg={6} xs={6} md={3} >
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE")}</Style.SubDetail>
                </Col>
                <Col xs={6}>
                  <Style.SubDetail>{t("ตามมิเตอร์ อกต")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_RATE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_CONSUMPTION")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{t("11")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
              
                <Col lg={6} xs={6} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_WATER_METER")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t(".png")}</Style.SubDetail>
                </Col>
              </Row>
            </Style.ListPaymentItem>

          </Style.ListPaymentContainer>

          <Style.BoxResult>
            <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_WATER_ฺBILL")}</Style.HeadMessage>
            <Style.HeadMessage>{t("11")}</Style.HeadMessage>
            <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
          </Style.BoxResult>

          <Divider className="w-100 mx-0" />

          <Row style={{ marginTop: "10px" }}>
            <Style.SubDetail1>{t("PAYBILL.CREATE.STALL_DETAILS")}</Style.SubDetail1>
          </Row>
          <Style.ListPaymentContainer>
            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 1 05/12/2566 ")}</Style.SubColorRed>
              <Row>
              <Col lg={6} xs={7} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.LEASES_TYPE")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={3} md={3}>
                <Style.SubDetail>{t("รายเดือน")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={7} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.STALL_RENTAL_FEE")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={2} md={3}>
                <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={2} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>
            </Style.ListPaymentItem>

            <Divider orientation="vertical" flexItem />

            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 2 05/12/2566 ")}</Style.SubColorRed>
              <Row>
              <Col lg={6} xs={7} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.LEASES_TYPE")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={3} md={3}>
                <Style.SubDetail>{t("รายเดือน")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={7} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.STALL_RENTAL_FEE")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={2} md={3}>
                <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={2} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>
            </Style.ListPaymentItem>

            <Divider orientation="vertical" flexItem />

            <Style.ListPaymentItem>
              <Style.SubColorRed className="mb-2">{t("งวดที่ 3 05/12/2566 ")}</Style.SubColorRed>
              <Row>
              <Col lg={6} xs={7} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.LEASES_TYPE")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={3} md={3}>
                <Style.SubDetail>{t("รายเดือน")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={6} xs={7} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.STALL_RENTAL_FEE")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={2} md={3}>
                <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={2} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>
            </Style.ListPaymentItem>

          </Style.ListPaymentContainer>

          <Style.BoxResult>
            <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_STALL_BILL")}</Style.HeadMessage>
            <Style.HeadMessage>{t("11")}</Style.HeadMessage>
            <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
          </Style.BoxResult>

          <Divider className="w-100 mx-0" />

          <Row style={{ marginTop: "20px" }}>
            <Style.SubDetail1>{t("PAYBILL.REPORT.PAYMENT_DETAILS")}</Style.SubDetail1>
          </Row>

          <Style.ListPaymentItem1>
          <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={5} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={3} md={3}>
                <Style.SubDetail>{t("1000")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={2}/>
              <Col lg={3} xs={5} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={3} md={3}>
                <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={5} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.STALL_BILL")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={3} md={3}>
                <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={5} md={3}>
                <Style.SubDetail>{t("PAYBILL.CREATE.OTHER_PAYMENT")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={3} md={3}>
                <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={2}/>
              <Col lg={3} xs={5} md={3}>
                <Style.SubDetail>{t('PAYBILL.REPORT.DISCOUNT')}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={3} md={3}>
              <Style.SubDetail>{t("11")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
              <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row className="my-3">
              <Col lg={3} md={2} />
              <Col lg={3} xs={5} md={3}>
                <Style.HeadMessage>{t("PAYBILL.REPORT.TOTAL_AMOUNT")}</Style.HeadMessage>
              </Col>
              <Col lg={2} xs={3} md={3}>
                <Style.HeadMessage>{t("100000")}</Style.HeadMessage>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
              </Col>
            </Row>
          </Style.ListPaymentItem1>
          <Box>
            <ButtonCustom
              variant="contained"
              onClick={() => { }}
              textButton={t("PAYBILL.BUTTON.PAYOFF_DEBT")}
              btnStyle={{
                fontSize: "16px",
                width: "100%",
                padding: "8px 25px",
              }}
            />
          </Box>
          
        </Style.BoxContainer>
      </Style.MainContainer>



    </>
  );
}
