
export const routeName = {
  login: '/login',
  register: '/register',
  newsletter: '/newsletter',
  info: '/info',
  selectBranches: '/select-branch',

  //**HEADER */
  home: '/',
  income: '/income',
  contactUs: '/contact-us',
  Calculation: '/calculation',
  editProfile: '/edit-profile',
  detailinfo: '/detail-info',
  //**STORE */
  marketMap: '/market-map',
  stallReport: '/stall-report',
  // notiRentStall: '/noti-rent-stall',

  //**AGREEMENT */
  requestForStall: '/request-stall',
  listRentStall: '/list-rent-stall',
  depositPay: '/deposit-pay',
  stallAgreement: '/stall-agreement',
  reportRentStall: '/report-rent-stall',
  /**  ELECTRICITY_BILL */
  management_utility: "/management-utillity",
  waterBill: "/water-bill",
  electrucityBill: "/electrucity-bill",

  /**  PAY_BILL */
  payBill: "/pay-bill",
  ListPayment: '/list-payment',
  CreatePaybill: '/create-paybill',
  OverdueListPayment: '/over',

  /**  TRANFER_APPOVE */
  tranferApporve: "/tranfer-apporve",

  /**  TRACK_BILL */
  trackBill: "/track-bill",

  /**  REPAIR */
  repair: "/repair",

  /**  TRANSFER_STALL */
  transferStall: "/transfer-stall",

  /**  CERTIFICATE */
  certificate: "/certificate",

  /**  SUE */
  sue: "/sue",

  /**  ANNOUNCE */
  announce: "/announce",

  /**  CANCEL_CONTACT */
  cancelContract: "/cancel-contract",

  /**  ADMIN */
  adminManage: "/admin-manage",
  adminType: '/admin-type',
  customer: '/customer-manager',

  /**  SETTINGS */
  waterManagement: "/water-management",
  electrucityManagement: "/electrucity-management",
  openRound: "/open-round",
  userPrefixes: '/user-prefixes',
  userRelationships: '/user-relationships',
  debtType: '/debt-type',
  leasesType: '/leases-type',
  maintenanceType: '/maintenance-type',
  paymentCycle: '/payment-cycle',
  petitionType: '/petition-type',
  branch: '/branch',
  newsType: '/news-type',
  stallType: '/stall-type',
  bidderType: '/bidder-type',
  user: '/user',
  repairManagement: "/repair-management",

  /**system log */
  systemLog: '/system-log',



  /**  STATISTIC */
  statistic: '/statistic',
  transferStallRegister: '/transfer-stall/register-form-transfer',

  /**  REPORT */
  report: '/report',
};
