import { useTranslation } from "react-i18next";
/** COMPONENT */
import ModalCustom from "../../../component/modalCustom/modalCustom";
import InputDatePicker from "../../../component/input/inputDatePicker";
import InputTextField from "../../../component/input/inputTextField";

/** STYLE */
import * as Style from '../cancel.Style'
import { Col, Row } from "react-bootstrap";
import { Divider, Typography } from "@mui/material";

/** UTIL */
import { dateToView } from "../../../utils/date.utils";
import BranchApi from "../../../api/setting/branch/branch.api";
import { useEffect, useState } from "react";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";

type PropsAdmincheck = {
  data?: any,
  status?: string,
  onsubmit?: any,
  onChangeAppointmentDate?: any,
  AppointmentDate?: string,
  errorMessageAppointDate?: string;
  onchangePlace?: any,
  place?: number,
  tel?: string,
  remark?: string,
  onchangeRemark?: any,
  noDoc: string;
  onChangeNoDoc: (value: string) => void
  errorMessageNoDoc: string;
  reason: string;
  onChangeReason: (value: string) => void
  errorMessageReason: string;
}

export default function ConfirmCancelContract(props: PropsAdmincheck) {
  const { t } = useTranslation();
  const d = props?.data

  const [branchData, setBranchData] = useState<any[]>([])

  const loadBranchData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50
    }

    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setBranchData(res.data)
    } else {
      setBranchData([])
    }
  }

  useEffect(() => {
    loadBranchData()
  }, []);

  return (
    <ModalCustom
      title={t("CANCELCONTRACT.TITLE_CANCEL_STALL")}
      size="xl"
      closeButton={true}
      onSubmit={props.onsubmit}
      textBtnConfirm={t("CANCELCONTRACT.BUTTON.SAVE")}
      modalScroll
      component={
        <div className="pb-2">

          <Style.ContainerModal>
            <Row className="mt-lg-3">
              <Col>
                <span style={{ fontSize: 16, fontWeight: 700 }}>{t('CANCELCONTRACT.POPUP.DETAIL')}</span>
              </Col>
            </Row>
            <div>

              <Row className="mt-3">
                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_NO')}</Style.SubDetail>
                  <Style.SubDetail>{d?.cancelAgreementNo}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.NAME')}</Style.SubDetail>
                  <Style.SubDetail className="mr-2">{d?.leaseAgreement?.firstname}</Style.SubDetail>
                  <Style.SubDetail>{d?.leaseAgreement?.lastname}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_DATE')}</Style.SubDetail>
                  <Style.SubDetail>{dateToView(d?.cancelAgreementDate)}</Style.SubDetail>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.RENT_DATE')}</Style.SubDetail>
                  <Style.SubDetail>{dateToView(d?.leaseAgreement?.leaseAgreementDateStart)}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.SubDetail className="mr-2">{t('CANCELCONTRACT.POPUP.CONTRACT_END_DATE')}</Style.SubDetail>
                  <Style.SubDetailAlert>{dateToView(d?.leaseAgreement?.leaseAgreementDateEnd)}</Style.SubDetailAlert>
                </Col>
              </Row>

              <Row className="mt-3 ">
                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.ZONE')}</Style.SubDetail>
                  <Col lg={3} />
                  <Style.SubDetail>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</Style.SubDetail>
                </Col>

                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.STALL')}</Style.SubDetail>
                  <Col lg={3} />
                  <Style.SubDetail>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}</Style.SubDetail>
                </Col>
              </Row>

              <Row className="mt-3 mb-3">
                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-lg-5">{t('CANCELCONTRACT.POPUP.TYPEZONE')}</Style.SubDetail>
                  <Style.SubDetail>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName}</Style.SubDetail>
                </Col>


                <Col className="d-flex flex-row" lg={6}>
                  <Style.SubDetail className="mr-4">{t('CANCELCONTRACT.POPUP.PAYMENT_CYCLE')}</Style.SubDetail>
                  <Style.SubDetail>{t('รายเดือน')}</Style.SubDetail>
                </Col>
              </Row>

            </div>
            <Divider className="mx-0 my-2" />

            <div>
              <Row>
                <Col>
                  <span style={{ fontSize: 16, fontWeight: 700 }}>{t('CANCELCONTRACT.POPUP.ANNOUNCEMENT_DATE')}</span>
                </Col>
              </Row>
              <Row>
                <Col lg={2} md={6} xs={6} className="mt-2">
                  <Typography>{t('CANCELCONTRACT.POPUP.APPOINTMENT_DATE')}</Typography>
                </Col>
                <Col lg={4} md={6} xs={6}>
                  <InputDatePicker
                    helperText={props.errorMessageAppointDate}
                    dateFormat="DD/MM/YYYY"
                    onChange={props.onChangeAppointmentDate}
                    value={props.AppointmentDate}
                  />
                </Col>
                <Col lg={2} md={6} xs={6} className="mt-2">
                  <Typography>{t('CANCELCONTRACT.POPUP.MEET_PLACE')}</Typography>
                </Col>
                <Col lg={4} md={6} xs={6}>
                  <AutocompleteSelect
                    options={branchData || []}
                    value={props.place && branchData.length > 0 ? branchData?.find((e: any) => e.branchId === props.place) : {}}
                    getOptionLabel={(option: any) => option.branchNameTh}
                    onChange={props.onchangePlace}
                    disabled
                    labelId={""} />
                </Col>
              </Row>
              <Row className="my-2">
                <Col lg={2} md={6} xs={6} className="mt-2">
                  <Typography>{t('CANCELCONTRACT.POPUP.TELEPHONE')}</Typography>
                </Col>
                <Col lg={4} md={6} xs={6}>
                  <InputTextField
                    value={props.tel}
                    disabled
                  />
                </Col>
                <Col lg={2} md={6} xs={6} className="mt-2">
                  <Typography>{t('เลขที่เอกสาร')}</Typography>
                </Col>
                <Col lg={4} md={6} xs={6}>
                  <InputTextField
                    value={props.noDoc}
                    onchange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChangeNoDoc(e.target.value.trim())}
                    helperText={props.errorMessageNoDoc}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={2} md={6} xs={6} className="mt-2">
                  <Typography>{t('รายละเอียด')}</Typography>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  <InputTextField

                    multiline
                    maxRows={3}
                    inputHeight={40}
                    value={props.reason}
                    onchange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChangeReason(e.target.value.trim())}
                    helperText={props.errorMessageReason}
                  />
                </Col>
              </Row>
              {/* <Row className="my-2">
                <Col xs={12} className="mt-2">
                  <Typography>{t(' จุดประสงสัยการยกเลิกสัญญา')}</Typography>
                </Col>
                <Col xs={12}>
                  <InputTextField
                    value={props.remark}
                    onchange={props.onchangeRemark}
                  />
                </Col>
              </Row> */}

            </div>

          </Style.ContainerModal>
        </div>
      }
    />
  )
}