import BaseAPI from '../../api'

const path = 'provinces'

export interface ProvincesInterface {
    provinceId?: number
    districtId?: number
  }

export default class ProvincesApi extends BaseAPI {
    static findAll(): Promise<any> {
        return this.api.get(`${path}/provinces`).then((res) => res)
    }

    static districts(payload: ProvincesInterface): Promise<any> {
        return this.api.get(`${path}/districts/${payload.provinceId}`, {params: {...payload}}).then((res) => res)
    }

    static subDistricts(payload: ProvincesInterface): Promise<any> {
        return this.api.get(`${path}/subdistrict/${payload.districtId}`, {params: {...payload}}).then((res) => res)
    }
}
