import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from "./userDefault.style"

/** API */
import PrefixApi from "../../../api/setting/prefix/prefix.api";
import { swalActive } from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView, isApporve, isExport } from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";



const initStateErrorMessage = {
  PREFIX_NAME_TH: ``,
  IS_DUPLICATE_PREFIX_NAME_TH: ``,
  PREFIX_NAME_TH_STRING_BASE: ``,
  PREFIX_NAME_TH_STRING_EMPTY: ``,
  PREFIX_NAME_TH_MAXIMUM_LENGTH: ``,
  PREFIX_NAME_TH_ANY_REQUIRED: ``,

  PREFIX_NAME_EN: ``,
  IS_DUPLICATE_PREFIX_NAME_EN: ``,
  PREFIX_NAME_EN_STRING_BASE: ``,
  PREFIX_NAME_EN_STRING_EMPTY: ``,
  PREFIX_NAME_EN_MAXIMUM_LENGTH: ``,
  PREFIX_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
  isExport: isExport(),
  isApporve: isApporve()
}

export default function UserPrefixes() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [prefixId, setPrefixId] = useState(Number)
  const [prefixNameTh, setPrefixNameTh] = useState<string>('')
  const [prefixNameEn, setPrefixNameEn] = useState<string>('')
  const [prefixStatus, setPrefixStatus] = useState<string>('ACTIVE')
  const [prefixData, setPrefixData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await PrefixApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setPrefixData(res.data)

    } else {
      setRowCount(0)
      setPrefixData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])


  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)

  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "prefixId", disablePadding: false, label: "NO", },
    { id: "prefixNameTh", disablePadding: false, label: t("PREFIX.HEADCELL.PREFIXNAMETH"), sortable: true },
    { id: "prefixNameEn", disablePadding: false, label: t("PREFIX.HEADCELL.PREFIXNAMEEN"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("PREFIX.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("PREFIX.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("PREFIX.HEADCELL.ACTION") }
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PREFIX.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          PrefixApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('PREFIX.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)

              loadData()
            })
            .catch((e) => {
              notiError(t('PREFIX.MESSAGE.ERROR'), '', null, null)

            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { prefixId, prefixNameTh, prefixNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PREFIX.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PREFIX.ALERT.CONFIRM_DELETE', {
        prefixNameTh: prefixNameTh, prefixNameEn: prefixNameEn ? ' ' + prefixNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          PrefixApi.delete(prefixId)
            .then((res) => {
              notiSuccess(t('PREFIX.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('PREFIX.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    const { prefixId, prefixNameTh, prefixNameEn, status } = data
    setPrefixId(prefixId)
    setPrefixNameTh(prefixNameTh)
    setPrefixNameEn(prefixNameEn)
    setStatus(status)
    dispatch(showModal())
  }

  const resetForm = () => {
    setPrefixId(0)
    setPrefixNameTh('')
    setPrefixNameEn('')
    setPrefixStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { prefixId, prefixNameTh, prefixNameEn, modifiedBy, modifiedDate, status } = objData;

    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;

    const objRenderData = {
      key: prefixId,
      id: prefixId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: prefixNameTh || "-" },
        { option: "TEXT", align: "left", label: prefixNameEn || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        {
          option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };


  const submit = () => {
    if (!prefixNameTh) return setErrorMessage({ ...errorMessage, ...{ PREFIX_NAME_TH: t('PREFIX.MESSAGE.PREFIX_NAME_TH') } })
    if (!prefixNameEn) return setErrorMessage({ ...errorMessage, ...{ PREFIX_NAME_EN: t('PREFIX.MESSAGE.PREFIX_NAME_EN') } })

    dispatch(submitModal())
    PrefixApi.create(prefixNameTh, prefixNameEn)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('PREFIX.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`PREFIX.MESSAGE.${err.error_description}`) } })

          if (err.statusCode === 400) {
            if (err.error_description === t('PREFIX.MESSAGE.IS_DUPLICATE_PREFIX_NAME_TH')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  PREFIX_NAME_TH: t(`${t(err.error_description)}`),

                }
              })
            } else if (err.error_description === t('PREFIX.MESSAGE.IS_DUPLICATE_PREFIX_NAME_EN')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  PREFIX_NAME_EN: t(`${t(err.error_description)}`),

                }
              })
            }
          }
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`PREFIX.MESSAGE.${err.error_description}`) } })

        if (err.statusCode === 400) {
          if (err.error_description === t('PREFIX.MESSAGE.IS_DUPLICATE_PREFIX_NAME_TH')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                PREFIX_NAME_TH: t(`${t(err.error_description)}`),

              }
            })
          } else if (err.error_description === t('PREFIX.MESSAGE.IS_DUPLICATE_PREFIX_NAME_EN')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                PREFIX_NAME_EN: t(`${t(err.error_description)}`),

              }
            })
          }
        }
        dispatch(unSubmitModal())
      })

  }

  const submitEdit = () => {
    if (!prefixNameTh) return setErrorMessage({ ...errorMessage, ...{ PREFIX_NAME_TH: t('PREFIX.MESSAGE.PREFIX_NAME_TH') } })
    if (!prefixNameEn) return setErrorMessage({ ...errorMessage, ...{ PREFIX_NAME_EN: t('PREFIX.MESSAGE.PREFIX_NAME_EN') } })
    dispatch(submitModal())
    PrefixApi.update(prefixId, { prefixNameTh, prefixNameEn, status: prefixStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`PREFIX.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`PREFIX.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`PREFIX.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  return (
    <>
      <HeaderCard text={t("PREFIX.TITLE")} />
      <Style.MainContainer>
        <Row>
          <Col lg={3} md={6} xs={12} className="my-1" >
            <InputTextField label={t("PREFIX.INPUT.SEARCH")}
              value={search}
              onchange={(event) => {
                setSearch(event.target.value)
                setPage(1)
              }}
            />
          </Col>
          <Col lg={3} md={6} xs={12} className="my-1">
            <FilterSelect
              onchange={(event) => {
                const value = event.target.value
                if (value) {
                  handleChangeStatus(value)
                  setPage(1)
                }
              }}
              renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
              label={t('STATUS.LABEL')}
              selectId="select-status"
              value={status}
              labelId="label-status"
              options={[
                <MenuItem key="1" value="ALL">
                  {t('STATUS.ALL')}
                </MenuItem>,
                <MenuItem key="2" value="ACTIVE">
                  {t('STATUS.ACTIVE')}
                </MenuItem>,
                <MenuItem key="3" value="INACTIVE">
                  {t('STATUS.INACTIVE')}
                </MenuItem>
              ]}
            />
          </Col>
          <Col lg={3} md={6} xs={12} ></Col>
          <Col lg={3} md={6} xs={12} className="my-1" >
            <ButtonCustom style={{ width: '100%' }}
              type="button"
              disabled={permissions.isCreate.disabled}
              textButton={t('PREFIX.BUTTON.ADD')}
              endIcon={<img src={icons.add} />}
              onClick={() => dispatch(showModal())}
            />
          </Col>

        </Row>

        <Style.TableContainer>
          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={prefixData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}
            tableMinWidth={1200}
          />
        </Style.TableContainer>

      </Style.MainContainer>
      <ModalCustom
        title={prefixId ? t('PREFIX.TITLE_UPDATE') : t('PREFIX.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
              onchange={(event) => {
                setPrefixNameTh(event.target.value)
                clearErrorMessage()
              }}
              value={prefixNameTh}
              helperText={
                errorMessage.PREFIX_NAME_TH ||
                errorMessage.IS_DUPLICATE_PREFIX_NAME_TH ||
                errorMessage.PREFIX_NAME_TH_STRING_BASE ||
                errorMessage.PREFIX_NAME_TH_STRING_EMPTY ||
                errorMessage.PREFIX_NAME_TH_MAXIMUM_LENGTH ||
                errorMessage.PREFIX_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('PREFIX.INPUT.PREFIX_NAME_TH')}
            />
            <InputTextField
              key="prefixNameEn"
              onchange={(event) => {
                setPrefixNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={prefixNameEn}
              helperText={
                errorMessage.PREFIX_NAME_EN ||
                errorMessage.IS_DUPLICATE_PREFIX_NAME_EN ||
                errorMessage.PREFIX_NAME_EN_STRING_BASE ||
                errorMessage.PREFIX_NAME_EN_STRING_EMPTY ||
                errorMessage.PREFIX_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.PREFIX_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('PREFIX.INPUT.PREFIX_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setPrefixStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${prefixStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={prefixId ? submitEdit : submit}
        textBtnCancel={t('PREFIX.BUTTON.CANCEL')}
        textBtnConfirm={t('PREFIX.BUTTON.SAVE')}
      />

    </>
  );
}
