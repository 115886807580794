import React, { useEffect, useState } from "react";
import * as Styles from "./report.style";
import { Card, Col, Row } from "react-bootstrap";
import { Box, Button, ButtonGroup, Tab, Tabs } from "@mui/material";
import { colors } from "../../constants/colors";
import moment from "moment";
import { useTranslation } from "react-i18next";
import InputDatePicker, {
  PICKER_VIEWS,
} from "../../component/input/inputDatePicker";
import ButtonCustom from "../../component/button/buttonCustom";
import { icons } from "../../constants/images";
import ContractReport from "./components/Contract";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ReportApi from "../../api/reports/reports.api";
import { getFileExtension } from "../../utils/fileTypeDownload";
import { saveAs } from "file-saver";
import EmptySpaceReport from "./components/EmptySpace";
import RevenueCollectionReport from "./components/RevenueCollection";
import WaterElectricBillReport from "./components/WaterElectricBill";
import Loading from "../../component/loading/loading";

const ReportContainer = () => {
  const { t } = useTranslation();

  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");

  const [tabsValue, setTabsValue] = useState<string>("CONTRACT");
  const [searchType, setSearchType] = useState<"DATE" | "MONTH" | "YEAR">(
    "DATE"
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeTabs = (event: React.SyntheticEvent, newValue: string) => {
    setTabsValue(newValue);
  };

  const handleDateChange = (type: "DATE" | "MONTH" | "YEAR") => {
    const date = new Date();

    switch (type) {
      case "DATE":
        const startOfDay = moment(date).startOf("day").format("YYYY-MM-DD");
        const endOfDay = moment(date)
          .startOf("day")
          .add(30, "days")
          .format("YYYY-MM-DD");
        setDateStart(startOfDay);
        setDateEnd(endOfDay);
        break;

      case "MONTH":
        // For "MONTH", add 1 month
        const startOfMonth = moment(date).startOf("month").format("YYYY-MM-DD");
        const endOfMonth = moment(date)
          .endOf("month")
          .add(1, "month")
          .format("YYYY-MM-DD");
        setDateStart(startOfMonth);
        setDateEnd(endOfMonth);
        break;

      case "YEAR":
        // For "YEAR", use the current year start and end
        const startOfYear = moment(date).startOf("year").format("YYYY-MM-DD");
        const endOfYear = moment(date).endOf("year").format("YYYY-MM-DD");
        setDateStart(startOfYear);
        setDateEnd(endOfYear);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    handleDateChange(searchType);
  }, [searchType]);

  const handleDownloadFile = async (
    start_date: string,
    end_date: string,
    report_id: string,
    searh_type: string,
    download_type: string
  ) => {
    if (report_id) {
      try {
        setLoading(true);
        let res = null as any;
        const query = {
          start_date: start_date,
          end_date: end_date,
          type: searh_type,
          type_doc: download_type ?? "pdf",
        };

        if (report_id === "CONTRACT") {
          res = await ReportApi.downloadAgreement(query);
        } else if (report_id === "EMPTY_SPACE") {
          res = await ReportApi.downloadBankContract(query);
        } else if (report_id === "REVENUE_COLLECTION") {
          res = await ReportApi.downloadRevenueCollection(query);
        } else if (report_id === "WATER_ELECTRIC_BILL") {
          res = await ReportApi.downloadUtilities(query);
        } else {
          console.error("Error fetching report not macth id");
        }

        if (res.status < 300) {
          const response = res.data;

          // Get the content type from the server's response headers
          const contentType = res.headers["content-type"];

          // Create a Blob with the content type from the response
          const blob = new Blob([response], { type: contentType });

          // Extract file extension based on content type
          const fileExtension = getFileExtension(contentType || "");

          // Generate a filename with the correct extension (you can also modify this to use a custom name)
          const name_file = `${report_id}_${new Date().getTime()}`;
          const fileName = `${name_file}.${fileExtension}`;

          // Save the file
          saveAs(blob, fileName);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching report:", error);
        setLoading(false);
      }
    } else {
      console.error(`No API function mapped for id: ${report_id}`);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <Loading show={loading} type="fullLoading" />
        </div>
      )}

      <Styles.MainContainer>
        <Styles.PageContainer>
          <Card.Body className="px-5">
            <Tabs
              value={tabsValue}
              onChange={onChangeTabs}
              TabIndicatorProps={{ sx: { backgroundColor: "transparent" } }}
              variant="scrollable"
              sx={{
                borderBottom: `1px solid ${colors.lightGray}`,
                "& button": {
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  backgroundColor: colors.lightGray,
                  marginRight: 1,
                  minHeight: "41px",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                },
                "& button:focus": {
                  backgroundColor: colors.themeMainColor,
                  color: colors.white,
                  // height: 40,
                },
              }}
            >
              <Tab
                value="CONTRACT"
                onClick={() => setTabsValue("CONTRACT")}
                iconPosition="start"
                label={t("รายงานสัญญา")}
                sx={{
                  "&.Mui-selected": {
                    color: colors.white,
                    backgroundColor: colors.themeMainColor,
                  },
                }}
              />
              <Tab
                value="EMPTY_SPACE"
                onClick={() => setTabsValue("EMPTY_SPACE")}
                iconPosition="start"
                label="รายงานพื้นที่ว่าง"
                sx={{
                  "&.Mui-selected": {
                    color: colors.white,
                    backgroundColor: colors.themeMainColor,
                  },
                }}
              />
              <Tab
                value="REVENUE_COLLECTION"
                onClick={() => setTabsValue("REVENUE_COLLECTION")}
                iconPosition="start"
                label="รายงานการจัดเก็บรายได้"
                sx={{
                  "&.Mui-selected": {
                    color: colors.white,
                    backgroundColor: colors.themeMainColor,
                  },
                }}
              />
              <Tab
                value="WATER_ELECTRIC_BILL"
                onClick={() => setTabsValue("WATER_ELECTRIC_BILL")}
                iconPosition="start"
                label="รายการค่าน้ำค่าไฟ"
                sx={{
                  "&.Mui-selected": {
                    color: colors.white,
                    backgroundColor: colors.themeMainColor,
                  },
                }}
              />
            </Tabs>

            <Row>
              <Col className="my-2" lg={2} md={12} xs={12}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="Basic button group"
                  sx={{
                    marginTop: "4px",
                  }}
                >
                  <Button
                    onClick={() => setSearchType("DATE")}
                    variant={searchType === "DATE" ? "contained" : "outlined"}
                    sx={{
                      width: "75px",
                    }}
                  >
                    วัน
                  </Button>
                  <Button
                    onClick={() => setSearchType("MONTH")}
                    variant={searchType === "MONTH" ? "contained" : "outlined"}
                    sx={{
                      width: "75px",
                    }}
                  >
                    เดือน
                  </Button>
                  <Button
                    onClick={() => setSearchType("YEAR")}
                    variant={searchType === "YEAR" ? "contained" : "outlined"}
                    sx={{
                      width: "75px",
                    }}
                  >
                    ปี
                  </Button>
                </ButtonGroup>
              </Col>
              <Col className="my-2" lg={3} md={6} sm={12} xs={12}>
                {searchType === "DATE" ? (
                  <InputDatePicker
                    required
                    disableFuture
                    value={dateStart}
                    allowClear
                    onClear={() => setDateStart("")}
                    key={"date"}
                    onChange={(e: any) => {
                      setDateStart(moment(e).format("YYYY-MM-DD"));
                    }}
                    dateFormat="YYYY-MM-DD"
                  />
                ) : searchType === "MONTH" ? (
                  <InputDatePicker
                    value={dateStart}
                    allowClear
                    onClear={() => setDateStart("")}
                    key={"date"}
                    onChange={(e: any) => {
                      console.log("e", e);
                      setDateStart(moment(e).format("YYYY-MM"));
                    }}
                    views={[PICKER_VIEWS.MONTH, PICKER_VIEWS.YEAR]}
                    dateFormat="YYYY-MM"
                  />
                ) : searchType === "YEAR" ? (
                  <InputDatePicker
                    value={dateStart}
                    allowClear
                    onClear={() => setDateStart("")}
                    key={"date"}
                    onChange={(e: any) => {
                      setDateStart(moment(e).format("YYYY"));
                    }}
                    views={[PICKER_VIEWS.YEAR]}
                    dateFormat="YYYY"
                  />
                ) : null}
              </Col>
              <Col className="my-2 text-center" lg={1} md={1} sm={12} xs={12}>
                <p
                  style={{
                    marginTop: "12px",
                  }}
                >
                  หา
                </p>
              </Col>
              <Col className="my-2" lg={3} md={6} sm={12} xs={12}>
                {searchType === "DATE" ? (
                  <InputDatePicker
                    required
                    disableStartDate={dateStart}
                    value={dateEnd}
                    allowClear
                    onClear={() => setDateEnd("")}
                    key={"date"}
                    onChange={(e: any) => {
                      setDateEnd(moment(e).format("YYYY-MM-DD"));
                    }}
                    dateFormat="YYYY-MM-DD"
                  />
                ) : searchType === "MONTH" ? (
                  <InputDatePicker
                    value={dateEnd}
                    allowClear
                    onClear={() => setDateEnd("")}
                    key={"date"}
                    onChange={(e: any) => {
                      console.log("e", e);
                      setDateEnd(moment(e).format("YYYY-MM"));
                    }}
                    views={[PICKER_VIEWS.MONTH, PICKER_VIEWS.YEAR]}
                    dateFormat="YYYY-MM"
                  />
                ) : searchType === "YEAR" ? (
                  <InputDatePicker
                    value={dateEnd}
                    allowClear
                    onClear={() => setDateEnd("")}
                    key={"date"}
                    onChange={(e: any) => {
                      setDateEnd(moment(e).format("YYYY"));
                    }}
                    views={[PICKER_VIEWS.YEAR]}
                    dateFormat="YYYY"
                  />
                ) : null}
              </Col>
              <Col className="my-2" lg={3} md={6} sm={12} xs={12}>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <ButtonCustom
                      onClick={() =>
                        handleDownloadFile(
                          dateStart,
                          dateEnd,
                          tabsValue,
                          searchType,
                          "xlsx"
                        )
                      }
                      textButton={t("Excel")}
                      style={{
                        width: "100%",
                        "&.MuiButton-contained": {
                          // backgroundColor:  `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                          backgroundColor: `${colors.lightOrange} !important`,
                          ":hover": {
                            // backgroundColor: `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                            backgroundColor: `${colors.lightOrange} !important`,
                          },
                        },
                      }}
                      startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={6}>
                    <ButtonCustom
                      style={{ width: "100%" }}
                      type="button"
                      textButton={t("SYSTEM.BUTTON.PRINT")}
                      endIcon={<img src={icons.print} />}
                      onClick={() =>
                        handleDownloadFile(
                          dateStart,
                          dateEnd,
                          tabsValue,
                          searchType,
                          "pdf"
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <InputTextField
                  placeholder={t("SUE.FILTER.SEARCH")}
                  label={t("SUE.FILTER.SEARCH")}
                  onchange={(event) => {

                  }}
                  type="month"
                /> */}
            {tabsValue === "CONTRACT" && (
              <ContractReport
                date_start={dateStart}
                date_end={dateEnd}
                search_type={searchType}
              />
            )}
            {tabsValue === "EMPTY_SPACE" && (
              <EmptySpaceReport
                date_start={dateStart}
                date_end={dateEnd}
                search_type={searchType}
              />
            )}
            {tabsValue === "REVENUE_COLLECTION" && (
              <RevenueCollectionReport
                date_start={dateStart}
                date_end={dateEnd}
                search_type={searchType}
              />
            )}
            {tabsValue === "WATER_ELECTRIC_BILL" && (
              <WaterElectricBillReport
                date_start={dateStart}
                date_end={dateEnd}
                search_type={searchType}
              />
            )}
          </Card.Body>
        </Styles.PageContainer>
      </Styles.MainContainer>
    </>
  );
};

export default ReportContainer;
