import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box,Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from "./stall.style"

/** API */
import { swalActive} from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError,notiSuccess } from "../../../component/notification/notifications";
import StallTypeApi from "../../../api/setting/stall/stall.api";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";

const initStateErrorMessage = {
    STALLTYPE_NAME_TH: ``,
  IS_DUPLICATE_STALLTYPE_NAME_TH: ``,
  STALLTYPE_NAME_TH_STRING_BASE: ``,
  STALLTYPE_NAME_TH_STRING_EMPTY: ``,
  STALLTYPE_NAME_TH_MAXIMUM_LENGTH: ``,
  STALLTYPE_NAME_TH_ANY_REQUIRED: ``,

  STALL_NAME_EN: ``,
  IS_DUPLICATE_STALLTYPE_NAME_EN: ``,
  STALLTYPE_NAME_EN_STRING_BASE: ``,
  STALLTYPE_NAME_EN_STRING_EMPTY: ``,
  STALLTYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  STALLTYPE_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function StallType() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [stallTypeId, setStallTypeId] = useState(Number)
  const [stallTypeNameTh, setStallTypeNameTh] = useState<string>('')
  const [stallTypeNameEn, setStallTypeNameEn] = useState<string>('')
  const [stallTypeStatus, setStallTypeStatus] = useState<string>('ACTIVE')
  const [stallTypeData, setStallTypeData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(25)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    

    const res = await StallTypeApi.findAll(condition)
    
    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setStallTypeData(res.data)
      
    }else{
      setRowCount(0)
      setStallTypeData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])
    

  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)
    
  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "stallTypeId", disablePadding: false, label: "NO"},
    { id: "stallTypeNameTh", disablePadding: false, label: t("STALLTYPE.HEADCELL.STALLTYPENAMETH"), sortable: true },
    { id: "stallTypeNameEn", disablePadding: false, label: t("STALLTYPE.HEADCELL.STALLTYPENAMEEN"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("STALLTYPE.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("STALLTYPE.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("STALLTYPE.HEADCELL.ACTION")}
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('STALLTYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
            StallTypeApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('STALLTYPE.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)
              
              loadData()
            })
            .catch((e) => {
              notiError(t('STALLTYPE.MESSAGE.ERROR'), '', null, null)
              
            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { stallTypeId, stallTypeNameTh, stallTypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('STALLTYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('STALLTYPE.ALERT.CONFIRM_DELETE', {
        stallTypeNameTh: stallTypeNameTh, stallTypeNameEn: stallTypeNameEn ? '' + stallTypeNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          StallTypeApi.delete(stallTypeId)
            .then((res) => {
              notiSuccess(t('STALLTYPE.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('STALLTYPE.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    const { stallTypeId, stallTypeNameTh, stallTypeNameEn, status } = data
    setStallTypeId(stallTypeId)
    setStallTypeNameTh(stallTypeNameTh)
    setStallTypeNameEn(stallTypeNameEn)
    setStatus(status)
    dispatch(showModal())
  }

  const resetForm = () => {
    setStallTypeId(0)
    setStallTypeNameTh('')
    setStallTypeNameEn('')
    setStallTypeStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { stallTypeId, stallTypeNameTh, stallTypeNameEn, modifiedBy, modifiedDate, status } = objData;
    
    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: stallTypeId,
      id: stallTypeId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: stallTypeNameTh || "-" },
        { option: "TEXT", align: "left", label: stallTypeNameEn || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        { 
          option: "UPDATE_BY", align: "left", label: {updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`,updatedAt: modifiedDate}
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };

  const submit = () => {
    if (!stallTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ STALLTYPE_NAME_TH: t('STALLTYPE.MESSAGE.STALLTYPE_NAME_TH') } })
    if (!stallTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ STALLTYPE_NAME_EN: t('STALLTYPE.MESSAGE.STALLTYPE_NAME_EN') } })
    
    dispatch(submitModal())
    StallTypeApi.create(stallTypeNameTh, stallTypeNameEn)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('STALLTYPE.MESSAGE.SUCCESS.CREATE'),'',null,null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({
            ...errorMessage,
            ...{
              [err.error_description]: t(`STALLTYPE.MESSAGE.${err.error_description}`)
            }
          })
          if (err.statusCode === 400){
            if(err.error_description === t('STALLTYPE.MESSAGE.IS_DUPLICATE_STALLTYPE_NAME_TH')){
              setErrorMessage({          
                ...errorMessage,
                ...{ STALLTYPE_NAME_TH: t(`${t(err.error_description)}`),
                
            }})
            } else if (err.error_description === t('STALLTYPE.MESSAGE.IS_DUPLICATE_STALLTYPE_NAME_TH')){
              setErrorMessage({          
                ...errorMessage,
                ...{ STALLTYPE_NAME_EN: t(`${t(err.error_description)}`),
                
            }})
            } 
          }
          dispatch(unSubmitModal())
          }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`STALLTYPE.MESSAGE.${err.error_description}`) } })
        if (err.statusCode === 400){
          if(err.error_description === t('STALLTYPE.MESSAGE.IS_DUPLICATE_STALLTYPE_NAME_TH')){
            setErrorMessage({          
              ...errorMessage,
              ...{ STALLTYPE_NAME_TH: t(`${t(err.error_description)}`),
              
          }})
          } else if (err.error_description === t('STALLTYPE.MESSAGE.IS_DUPLICATE_STALLTYPE_NAME_EN')){
            setErrorMessage({          
              ...errorMessage,
              ...{ STALLTYPE_NAME_EN: t(`${t(err.error_description)}`),
              
          }})
          } 
        }
        
        dispatch(unSubmitModal())
      })
      
  }

  const submitEdit = () => {
    if (!stallTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ STALLTYPE_NAME_TH: t('STALLTYPE.MESSAGE.STALLTYPE_NAME_TH') } })
    if (!stallTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ STALLTYPE_NAME_EN: t('STALLTYPE.MESSAGE.STALLTYPE_NAME_EN') } })
    dispatch(submitModal())
    StallTypeApi.update(stallTypeId, { stallTypeNameTh, stallTypeNameEn, status: stallTypeStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`STALLTYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`STALLTYPE.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`STALLTYPE.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {setErrorMessage(initStateErrorMessage)}

  return (
    <>
      <HeaderCard text={t("STALLTYPE.TITLE")} />
      <Style.MainContainer>
        
          <Row>
            <Col lg={3} md={6} xs={12}  className="my-1">
              <InputTextField label={t("STALLTYPE.INPUT.SEARCH")}
                value={search}  
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col lg={3} md={6} xs={12}  className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value)
                    setPage(1)
                  }
                }}
                renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status"
                value={status}
                labelId="label-status"
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </Col>
            <Col lg={3} md={6} xs={12}></Col>
            <Col lg={3} md={6} xs={12}  className="my-1">
            <ButtonCustom style={{width: '100%'}}
                  type="button"
                  disabled={permissions.isCreate.disabled}
                  textButton={t('STALLTYPE.BUTTON.ADD')}
                  endIcon={<img src={icons.add} />}
                  onClick={() => dispatch(showModal())}
                />
            </Col>
          </Row>
        
        <Style.TableContainer>

          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={stallTypeData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}
            tableMinWidth={1200}
          />
        </Style.TableContainer>

      </Style.MainContainer>

      <ModalCustom
        title={stallTypeId ? t('STALLTYPE.TITLE_UPDATE') : t('STALLTYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
                key="stallTypeNameTh"
              onchange={(event) => {
                setStallTypeNameTh(event.target.value)
                clearErrorMessage()
              }}
              value={stallTypeNameTh}
              helperText={
                errorMessage.STALLTYPE_NAME_TH || 
                errorMessage.IS_DUPLICATE_STALLTYPE_NAME_TH || 
                errorMessage.STALLTYPE_NAME_TH_STRING_BASE || 
                errorMessage.STALLTYPE_NAME_TH_STRING_EMPTY || 
                errorMessage.STALLTYPE_NAME_TH_MAXIMUM_LENGTH || 
                errorMessage.STALLTYPE_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('STALLTYPE.INPUT.STALLTYPE_NAME_TH')}
            />
            <InputTextField
              key="stallTypeNameEn"
              onchange={(event) => {
                setStallTypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={stallTypeNameEn}
              helperText={
                errorMessage.STALLTYPE_NAME_EN ||
                errorMessage.IS_DUPLICATE_STALLTYPE_NAME_EN ||
                errorMessage.STALLTYPE_NAME_EN_STRING_BASE ||
                errorMessage.STALLTYPE_NAME_EN_STRING_EMPTY ||
                errorMessage.STALLTYPE_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.STALLTYPE_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('STALLTYPE.INPUT.STALLTYPE_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setStallTypeStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${stallTypeStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={stallTypeId ? submitEdit : submit}
        textBtnCancel={t('STALLTYPE.BUTTON.CANCEL')}
        textBtnConfirm={t('STALLTYPE.BUTTON.SAVE')}
      />

    </>
  );
}
