import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box,Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { swalActive} from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError,notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";

/** STYLE */
import * as Style from "./stall.style"

/** API */
import LeasesTypeApi from "../../../api/setting/leases/leases.api";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { routeName } from "../../../routes/routers-name";

const initStateErrorMessage = {
  LEASES_NAME_TH: ``,
  IS_DUPLICATE_LEASES_NAME_TH: ``,
  LEASES_NAME_TH_STRING_BASE: ``,
  LEASES_NAME_TH_STRING_EMPTY: ``,
  LEASES_NAME_TH_MAXIMUM_LENGTH: ``,
  LEASES_NAME_TH_ANY_REQUIRED: ``,

  LEASES_NAME_EN: ``,
  IS_DUPLICATE_LEASES_NAME_EN: ``,
  LEASES_NAME_EN_STRING_BASE: ``,
  LEASES_NAME_EN_STRING_EMPTY: ``,
  LEASES_NAME_EN_MAXIMUM_LENGTH: ``,
  LEASES_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}
export default function LeasesType() {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [leasesTypeId, setLeasesTypeId] = useState(Number)
  const [leasesTypeNameTh, setLeasesTypeNameTh] = useState<string>('')
  const [leasesTypeNameEn, setLeasesTypeNameEn] = useState<string>('')
  const [leasesTypeStatus, setLeasesTypeStatus] = useState<string>('ACTIVE')
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await LeasesTypeApi.findAll(condition)
    
    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setLeasesTypeData(res.data)
      
    }else{
      setRowCount(0)
      setLeasesTypeData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])
    

  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)
    
  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "leasesTypeId", disablePadding: false, label: "#"},
    { id: "leasesTypeNameTh", disablePadding: false, label: t("LEASES.HEADCELL.LEASESTYPENAMETH"), sortable: true },
    { id: "leasesTypeNameEn", disablePadding: false, label: t("Rate ค่าเช่า"), sortable: true },
    { id: "leasesTypeNameEn", disablePadding: false, label: t("กำหนดการชำระเงิน"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("LEASES.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("LEASES.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("LEASES.HEADCELL.ACTION")}
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LEASES.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          LeasesTypeApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('LEASES.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)
              
              loadData()
            })
            .catch((e) => {
              notiError(t('LEASES.MESSAGE.ERROR'), '', null, null)
              
            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { leasesTypeId, leasesTypeNameTh, leasesTypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LEASES.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LEASES.ALERT.CONFIRM_DELETE', {
        leasesTypeNameTh: leasesTypeNameTh, leasesTypeNameEn: leasesTypeNameEn ? ' ' + leasesTypeNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          LeasesTypeApi.delete(leasesTypeId)
            .then((res) => {
              notiSuccess(t('LEASES.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('LEASES.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    const { leasesTypeId, leasesTypeNameTh, leasesTypeNameEn, status } = data
    // setLeasesTypeId(leasesTypeId)
    // setLeasesTypeNameTh(leasesTypeNameTh)
    // setLeasesTypeNameEn(leasesTypeNameEn)
    // setStatus(status)
    // dispatch(showModal())
    history.push(routeName.leasesType + "/" + leasesTypeId)
  }

  const resetForm = () => {
    setLeasesTypeId(0)
    setLeasesTypeNameTh('')
    setLeasesTypeNameEn('')
    setLeasesTypeStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { leasesTypeId, leasesTypeNameTh, payAtEndOfMonth,payAtDate, paymentType, modifiedBy, modifiedDate, status } = objData;
    
    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: leasesTypeId,
      id: leasesTypeId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: leasesTypeNameTh || "-" },
        { option: "TEXT", align: "left", label: paymentType === 'MONTHLY' ? 'รายเดือน' : 'รายวัน' || "-" },
        { option: "TEXT", align: "left", label: payAtEndOfMonth && payAtDate ? `จ่ายเงินทุกวันที่ ${payAtDate} และสิ้นเดือน` : payAtEndOfMonth ? 'จ่ายทุกสิ้นเดือน' : payAtDate ? 'จ่ายเงินทุกวันที่ ' + payAtDate  : "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        { 
          option: "UPDATE_BY", align: "left", label: {updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`,updatedAt: modifiedDate}
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };


  const submit = () => {
    // if (!leasesTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ LEASES_NAME_TH: t('LEASES.MESSAGE.LEASES_NAME_TH') } })
    // if (!leasesTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ LEASES_NAME_EN: t('LEASES.MESSAGE.LEASES_NAME_EN') } })
    
    // dispatch(submitModal())
    // LeasesTypeApi.create(leasesTypeNameTh, leasesTypeNameEn)
    //   .then((res: any) => {
    //     if (res.status !== undefined && res.status === 201) {
    //       notiSuccess(t('LEASES.MESSAGE.SUCCESS.CREATE'),'',null,null)
    //       resetForm()
    //       loadData()
    //       dispatch(resetModal())
    //     } else {
    //       const err = res.response.data
    //       setErrorMessage({
    //         ...errorMessage,
    //         ...{
    //           [err.error_description]: t(`LEASES.MESSAGE.${err.error_description}`)
    //         }
    //       })
    //       if (err.statusCode === 400){
    //         if(err.error_description === t('LEASES.MESSAGE."IS_DUPLICATE_LEASES_NAME_TH')){
    //           setErrorMessage({          
    //             ...errorMessage,
    //             ...{ LEASES_NAME_TH: t(`${t(err.error_description)}`),
                
    //         }})
    //         } else if (err.error_description === t('LEASES.MESSAGE."IS_DUPLICATE_LEASES_NAME_EN')){
    //           setErrorMessage({          
    //             ...errorMessage,
    //             ...{ LEASES_NAME_EN: t(`${t(err.error_description)}`),
                
    //         }})
    //         }
  
    //       }
    //       dispatch(unSubmitModal())
    //       }
    //   })
    //   .catch((e) => {
    //     const err = e.response.data
    //     setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`LEASES.MESSAGE.${err.error_description}`) } })
    //     if (err.statusCode === 400){
    //       if(err.error_description === t('LEASES.MESSAGE."IS_DUPLICATE_LEASES_NAME_TH')){
    //         setErrorMessage({          
    //           ...errorMessage,
    //           ...{ LEASES_NAME_TH: t(`${t(err.error_description)}`),
              
    //       }})
    //       } else if (err.error_description === t('LEASES.MESSAGE."IS_DUPLICATE_LEASES_NAME_EN')){
    //         setErrorMessage({          
    //           ...errorMessage,
    //           ...{ LEASES_NAME_EN: t(`${t(err.error_description)}`),
              
    //       }})
    //       }

    //     }
    //     dispatch(unSubmitModal())
    //   })
      
  }

  const submitEdit = () => {
    if (!leasesTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ LEASES_NAME_TH: t('LEASES.MESSAGE.LEASES_NAME_TH') } })
    if (!leasesTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ LEASES_NAME_EN: t('LEASES.MESSAGE.LEASES_NAME_EN') } })
    dispatch(submitModal())
    LeasesTypeApi.update(leasesTypeId, { leasesTypeNameTh, leasesTypeNameEn, status: leasesTypeStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`LEASES.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`LEASES.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`LEASES.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  return (
    <>
      <HeaderCard text={t("LEASES.TITLE")} />
      <Style.MainContainer>
        
          <Row>
            <Col lg={3} md={6} xs={12} className="my-1">
              <InputTextField label={t("LEASES.INPUT.SEARCH")}
                value={search}  
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col lg={3} md={6} xs={12} className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value)
                    setPage(1)
                  }
                }}
                renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status"
                value={status}
                labelId="label-status"
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </Col>
            <Col lg={3} md={6} xs={12}></Col>
            <Col lg={3} md={6} xs={12} className="my-1">
            <ButtonCustom style={{width: '100%'}}
                  disabled={permissions.isCreate.disabled}
                  type="button"
                  textButton={t('LEASES.BUTTON.ADD')}
                  endIcon={<img src={icons.add} />}
                  onClick={() => {
                    history.push(routeName.leasesType + "/add"
                    // , {
                    //   params: {
                    //     state: objData,
                    //   },
                    // }
                  )
                  }}
                  // onClick={() => dispatch(showModal())}
                />
            </Col>
          </Row>
        
        <Style.TableContainer>

          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={leasesTypeData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}
            tableMinWidth={1000}
          />
        </Style.TableContainer>

      </Style.MainContainer>

      <ModalCustom
        title={leasesTypeId ? t('LEASES.TITLE_UPDATE') : t('LEASES.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
              key = "leasesTypenameTh"
              onchange={(event) => {
                setLeasesTypeNameTh(event.target.value)
                clearErrorMessage()
              }}
              value={leasesTypeNameTh}
              helperText={
                errorMessage.LEASES_NAME_TH || 
                errorMessage.IS_DUPLICATE_LEASES_NAME_TH || 
                errorMessage.LEASES_NAME_TH_STRING_BASE || 
                errorMessage.LEASES_NAME_TH_STRING_EMPTY || 
                errorMessage.LEASES_NAME_TH_MAXIMUM_LENGTH || 
                errorMessage.LEASES_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('LEASES.INPUT.LEASES_NAME_TH')}
            />
            <InputTextField
              key="leasesTypeNameEn"
              onchange={(event) => {
                setLeasesTypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={leasesTypeNameEn}
              helperText={
                errorMessage.LEASES_NAME_EN ||
                errorMessage.IS_DUPLICATE_LEASES_NAME_EN ||
                errorMessage.LEASES_NAME_EN_STRING_BASE ||
                errorMessage.LEASES_NAME_EN_STRING_EMPTY ||
                errorMessage.LEASES_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.LEASES_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('LEASES.INPUT.LEASES_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setLeasesTypeStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${leasesTypeStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={leasesTypeId ? submitEdit : submit}
        textBtnCancel={t('LEASES.BUTTON.CANCEL')}
        textBtnConfirm={t('LEASES.BUTTON.SAVE')}
      />

    </>
  );
}
