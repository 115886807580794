import { menuSeconds } from "../../constants/menu"
import Admin from "../../pages/admin/admin"
import { routeName } from "../routers-name"
import CreateAdmin from '../../pages/admin/createAdmin'
import CreateAdminType from "../../pages/admin/createAdminType"
import AdminType from "../../pages/admin/adminType"
import User from "../../pages/setting/user/user"
const routeAdminmanage = [
  {
    ...menuSeconds.ADMIN_MANAGEMENT,
    key: menuSeconds.ADMIN_MANAGEMENT.key,
    name: menuSeconds.ADMIN_MANAGEMENT.name,
    textName: menuSeconds.ADMIN_MANAGEMENT.textName,
    active: false,
    appBar: true,
    component: Admin
  },
  {
    ...menuSeconds.ADMIN_TYPE,
    key: menuSeconds.ADMIN_TYPE.key,
    name: menuSeconds.ADMIN_TYPE.name,
    textName: menuSeconds.ADMIN_TYPE.textName,
    active: false,
    appBar: true,
    component: AdminType
  },

  {
    ...menuSeconds.ADMIN_TYPE,
    key: menuSeconds.ADMIN_TYPE.key,
    name: menuSeconds.ADMIN_TYPE.name,
    textName: menuSeconds.ADMIN_TYPE.textName,
    active: false,
    appBar: true,
    path: '/create-admintype',

    component: CreateAdminType
  },
  {
    ...menuSeconds.ADMIN_TYPE,
    key: menuSeconds.ADMIN_TYPE.key,
    name: menuSeconds.ADMIN_TYPE.name,
    textName: menuSeconds.ADMIN_TYPE.textName,
    active: false,
    appBar: true,
    path: routeName.adminType + '/edit-admin-type/:id',

    component: CreateAdminType
  },
  {
    ...menuSeconds.ADMIN_MANAGEMENT,
    key: menuSeconds.ADMIN_MANAGEMENT.key,
    name: menuSeconds.ADMIN_MANAGEMENT.name,
    textName: menuSeconds.ADMIN_MANAGEMENT.textName,
    active: false,
    appBar: true,
    path: routeName.adminManage + '/edit-admin/:id',

    component: CreateAdmin
  },
  {
    ...menuSeconds.ADMIN_MANAGEMENT,
    key: menuSeconds.ADMIN_MANAGEMENT.key,
    name: menuSeconds.ADMIN_MANAGEMENT.name,
    textName: menuSeconds.ADMIN_MANAGEMENT.textName,
    active: false,
    appBar: true,
    path: routeName.adminManage + '/create-admin',

    component: CreateAdmin
  },

  {
    ...menuSeconds.CUSTOMER_MANAGEMENT,
    key: menuSeconds.CUSTOMER_MANAGEMENT.key,
    name: menuSeconds.CUSTOMER_MANAGEMENT.name,
    textName: menuSeconds.CUSTOMER_MANAGEMENT.textName,
    active: false,
    appBar: true,
    component: User
  },
]

export default routeAdminmanage