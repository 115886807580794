import React from 'react'
import ModalCustom from '../../../component/modalCustom/modalCustom'
import InputTextField from '../../../component/input/inputTextField'

type Props = {
    isShow: boolean
    onClose: (status: boolean) => void;
    onSubmit: () => void;
    remark: string;
    setRemark: (value: string) => void
    errorMessageRemark:string;
}

const InactiveReasonModal = (props: Props) => {
    return (
        <>
            <ModalCustom
                title='ปิดการใช้งาน'
                closeButton
                isShowProps={props.isShow}
                onClose={() => props.onClose(false)}
                onReset={() => props.onClose(false)}
                onSubmit={() => props.onSubmit()}
                textBtnConfirm='ตกลง'
                textBtnCancel='ยกเลิก'
                size='xl'
                component={
                    <div className='w-100'>
                        <InputTextField
                            required
                            helperText={props.errorMessageRemark}
                            value={props.remark}
                            onchange={(e: React.ChangeEvent<HTMLInputElement>) => props.setRemark(e.target.value)}
                            heading='เหตุผลในการปิดการใช้งาน'
                        />
                    </div>
                }
            />
        </>
    )
}

export default InactiveReasonModal