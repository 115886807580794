import { useState } from "react"
import { useTranslation } from "react-i18next"
import HeaderLabel from "../../../component/cardCustom/headerLabel"
import { routeName } from "../../../routes/routers-name"
import { useLocation } from "react-router-dom"


export default function HistoryApproval () {
    const {t} = useTranslation()
    const location = useLocation()
    console.log(location)

    
    return (
        <>
        <HeaderLabel
          text={t("REQUESTER_DETAIL.HEADER")}
          navigator={{ previousTo: routeName.requestForStall, previousTitle: t("REQUEST_STALL.TITlE"), currentTitle:  t("REQUESTER_DETAIL.HEADER")}}
          goBack={routeName.requestForStall}
          noAction
        />
        </>
    )
}