import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'water-type'

export interface WaterInterface {
    waterTypeId?: number
    waterUsed?:number
    amount?:number
    pricePerUnit?:number    
    dateUsed: string
    status: string
    dateEnd?: string
  }

export default class WaterApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(waterUsed: number, amount: number,pricePerUnit:number, dateUsed:string ) {
        return this.api.post(path , { waterUsed, amount, pricePerUnit, dateUsed})
    }

    static findById(id: number): Promise<WaterInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: WaterInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
