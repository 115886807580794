import _ from 'lodash'
import { routeName } from '../routes/routers-name'
import { MENUS_FIRST } from '../constants/menu'
/** ROUTE NAME */

const subStringRouteName = (name: string) => {
    const preName = name.substring(1).split('/')
    if (!_.isEmpty(preName)) return preName[0]
    else return ''
}

export const matchRoute = (name: any) => {
    if (
        name === subStringRouteName(routeName.marketMap) ||
        name === subStringRouteName(routeName.requestForStall)
    ) return MENUS_FIRST.STORE
    else if (
        name === subStringRouteName(routeName.listRentStall) ||
        name === subStringRouteName(routeName.depositPay) ||
        name === subStringRouteName(routeName.stallAgreement)
    ) return MENUS_FIRST.STALL
    else if (
        name === subStringRouteName(routeName.tranferApporve)
    ) return MENUS_FIRST.TRANFER_APPOVE
    else if (
        name === subStringRouteName(routeName.waterBill) ||
        name === subStringRouteName(routeName.electrucityBill)
    ) return MENUS_FIRST.UTILITY_BILL
    else if (
        name === subStringRouteName(routeName.payBill) ||
        name === subStringRouteName(routeName.ListPayment)
    ) return MENUS_FIRST.PAY_BILL
    else if (
        name === subStringRouteName(routeName.trackBill)
    ) return MENUS_FIRST.TRACK_BILL
    else if (
        name === subStringRouteName(routeName.repair)
    ) return MENUS_FIRST.REPAIR
    else if (
        name === subStringRouteName(routeName.transferStall)
    ) return MENUS_FIRST.TRANSFER_STALL
    else if (
        name === subStringRouteName(routeName.certificate)
    ) return MENUS_FIRST.CERTIFICATE
    else if (
        name === subStringRouteName(routeName.sue)
    ) return MENUS_FIRST.SUE
    else if (
        name === subStringRouteName(routeName.announce)
    ) return MENUS_FIRST.ANNOUNCE
    else if (
        name === subStringRouteName(routeName.cancelContract)
    ) return MENUS_FIRST.CANCEL_CONTACT
    else if (
        name === subStringRouteName(routeName.waterManagement) ||
        name === subStringRouteName(routeName.electrucityManagement) ||
        name === subStringRouteName(routeName.userPrefixes) ||
        name === subStringRouteName(routeName.userRelationships) ||
        name === subStringRouteName(routeName.debtType) ||
        name === subStringRouteName(routeName.leasesType) ||
        name === subStringRouteName(routeName.maintenanceType) ||
        name === subStringRouteName(routeName.paymentCycle) ||
        name === subStringRouteName(routeName.petitionType) ||
        name === subStringRouteName(routeName.branch) ||
        name === subStringRouteName(routeName.newsType) ||
        name === subStringRouteName(routeName.stallType)
    ) return MENUS_FIRST.SETTINGS
    else if (
        name === subStringRouteName(routeName.systemLog)
    ) return MENUS_FIRST.SYSTEM_LOG
    else if (
        name === subStringRouteName(routeName.statistic)
    ) return MENUS_FIRST.STATISTIC
    else if (
        name === subStringRouteName(routeName.report)
    ) return MENUS_FIRST.REPORT

}
