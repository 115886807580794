import {
  Box,
  Checkbox,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import logo from "../../assets/logo/logotest.png";
import {
  Boxflex,
  BoxflexHeader,
  CarInputRegister,
  CarRegister,
  Headder,
  Language,
  Languageres,
  PolicyText,
  CardRegister
} from "./style";
import ButtonCustom from "../../component/button/buttonCustom";
import { useTranslation } from "react-i18next";
import InputTextField from "../../component/input/inputTextField";
import { routeName } from "../../routes/routers-name";
import { colors } from "../../constants/colors";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import ReCAPTCHA from "react-google-recaptcha";

/** API */
import InputPasswordField from "../../component/input/inputPasswordField";

const initStateErrorMessage = {
  PRFIX_NAME: ``,
  NAME: ``,
  LASTNAME: ``,
  PHONE_NUBER: ``,
  EMAIL: ``,
  USERNAME: ``,
  PASSOWORD: ``,
  CONFIRM_PASSWORD: ``
};

interface RegisterProps {
  onsubmit?: () => void
  onreturn?: () => void
  onModal?: () => void
  firstname?: string
  lastname?: string
  phoneNumber?: string | null
  email?: string | null
  password?: string
  confirmpassword?: string
  refRecaptcha?: any
  checkaccept?: boolean
  prefixOption?: any
  onchangefirstname?: (e: any) => void
  onchangelastname?: (e: any) => void
  onchangephoneNumber?: (e: any) => void
  onchangeemail?: (e: any) => void
  onchangeusername?: (e: any) => void
  onchangecheckaccept?: (e: any) => void
  onchangeRecaptcha?: (value: any) => void
  onchagepassword?: (e: any) => void
  onchangeConfirmpassword?: (e: any) => void
  onchangePrefixId?: (e: any, val: any) => void
}

export default function Register(props: RegisterProps) {

  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const login = () => {
    window.location.href = routeName.login;
  };

  return (
    <CardRegister>
      <BoxflexHeader>
        <Container maxWidth="lg">
          <Headder>
            <Box>
              <img src={logo} alt="Logo" width={160} height={50} />
            </Box>
            <Boxflex>
              <Language>
                <Typography>TH |</Typography>
                <Typography sx={{ marginLeft: "4px" }}>EN</Typography>
              </Language>
              <Box>
                <ButtonCustom
                  variant="outlined"
                  textButton={t("REGISTER.BUTTON.LOGIN")}
                  onClick={login}
                  btnStyle={{
                    fontSize: "16px",
                    width: "100%",
                    padding: "8px 25px",
                  }}
                />
              </Box>
              <Box sx={{ marginLeft: "14px" }}>
                <ButtonCustom
                  type="submit"
                  textButton={t("สมัครสมาชิก")}
                  disabled={true}
                  btnStyle={{ fontSize: "16px", width: "100%", padding: "0px" }}
                />
              </Box>
            </Boxflex>
            <Languageres>
              <Typography>TH |</Typography>
              <Typography sx={{ marginLeft: "4px" }}>EN</Typography>
            </Languageres>
          </Headder>
        </Container>
      </BoxflexHeader>
      <Box>
        <Container maxWidth="lg" >
          <Box sx={{ paddingTop: "85px" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "500",
                textDecoration: "underline",
                textDecorationColor: "green",
              }}
            >
              {t("REGISTER.HEADER.REGISTER")}
            </Typography>
          </Box>
          <CarRegister>
            <Container maxWidth="lg" style={{ backgroundColor: colors.white }}>
              <Typography sx={{ fontSize: "18px" }}>
                {t("REGISTER.HEADER.PERSONAL_CUSTOMER")}
              </Typography>
              <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
              <CarInputRegister>
                <Boxflex>
                  <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} className="w-100">
                      <AutocompleteSelect
                        options={props.prefixOption}
                        getOptionLabel={(option: any) => option.prefixNameTh}
                        onChange={props.onchangePrefixId}
                        labelId="prefix-name"
                        heading={t("REGISTER.INPUT.PREFIX")}
                        required
                        helperText={errorMessage.PREFIX}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12} className="w-100">
                      <InputTextField
                        key="name"
                        value={props.firstname}
                        onchange={props.onchangefirstname}
                        required={true}
                        helperText={errorMessage.NAME}
                        heading={t("REGISTER.INPUT.NAME")}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12} className="w-100">
                      <InputTextField
                        key="lastName"
                        value={props.lastname}
                        onchange={props.onchangelastname}
                        helperText={errorMessage.LAST_NAME}
                        required={true}
                        heading={t("REGISTER.INPUT.LAST_NAME")}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12} className="w-100">
                      <InputTextField
                        key="phoneNumber"
                        type="number"
                        hideControl
                        value={props.phoneNumber}
                        onchange={props.onchangephoneNumber}
                        required={true}
                        helperText={errorMessage.PHONE_NUBER}
                        inputProps={{
                          maxLength: 10,
                        }}
                        heading={t("REGISTER.INPUT.PHONE_NUMBER")}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12} className="w-100">
                      <InputTextField
                        key="email"
                        required={true}
                        value={props.email}
                        onchange={props.onchangeemail}
                        heading={t("REGISTER.INPUT.EMAIL")}
                      />
                    </Grid>
                  </Grid>
                </Boxflex>
              </CarInputRegister>
              <Typography sx={{ fontSize: "18px", marginTop: "40px" }}>
                {t("REGISTER.HEADER.SET_PASSWORD")}
              </Typography>
              <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
              <CarInputRegister>
                <Grid container spacing={2}>
                  {/* <Grid item lg={4} xs={12} className="w-100">
                    <InputTextField
                      required={true}
                      value={props.username}
                      onchange={props.onchangeusername}
                      heading={t("REGISTER.INPUT.USERNAME")}
                      helperText={errorMessage.USERNAME}
                    />
                  </Grid> */}
                  <Grid item lg={4} xs={12} className="w-100">
                    <InputPasswordField
                      value={props.password}
                      onchange={props.onchagepassword}
                      required={true}
                      helperText={errorMessage.PASSOWORD}
                      heading={t("REGISTER.INPUT.PASSWORD")}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12} className="w-100">
                    <InputPasswordField
                      value={props.confirmpassword}
                      onchange={props.onchangeConfirmpassword}
                      required={true}
                      helperText={errorMessage.CONFIRM_PASSWORD}
                      heading={t("REGISTER.INPUT.CONFIRM_PASSWORD")}
                    />
                  </Grid>
                </Grid>
              </CarInputRegister>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                <ReCAPTCHA
                  ref={props.refRecaptcha}
                  sitekey="6LdiP1UpAAAAADUyq1e1TahP4w0JFZEgDPCQE7dl"
                  onChange={props.onchangeRecaptcha}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                <Checkbox value={props.checkaccept} onChange={props.onchangecheckaccept} />
                <Box className='d-flex flex-row'>
                  <Typography>ข้าพเจ้ายอมรับข้อกำหนดการใช้งาน รับทราบและตกลงให้มีการเก็บรวบรวมใช้ และเปิดเผยข้อมูลส่วนบุคคลได้ </Typography>
                  <PolicyText className="px-2" onClick={props.onModal}>นโยบายคุ้มครอง</PolicyText>
                </Box>
              </Box>
              <Box>
                <ButtonCustom
                  type="submit"
                  disabled={props.checkaccept ? false : true}
                  textButton={t("REGISTER.BUTTON.SUMMIT")}
                  onClick={props.onsubmit}
                  btnStyle={{
                    fontSize: "16px",
                    width: "100%",
                    padding: "0px",
                    marginTop: "32px",
                    marginBottom: "14px",
                  }}
                />
              </Box>
            </Container>
          </CarRegister>
        </Container>
      </Box>
    </CardRegister>
  );
}
