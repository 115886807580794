import { menuFirsts } from "../../constants/menu"
import Sue from "../../pages/sue/sue"

const routeSue = [
    {
      ...menuFirsts.SUE,
      key: menuFirsts.SUE.key,
      name: menuFirsts.SUE.name,
      textName: menuFirsts.SUE.textName,
      active: false,
      appBar: true,
      component: Sue
    } 
  ]
  
  export default routeSue