export const fileExtensionMap = [
  { contentType: "application/pdf", extension: "pdf" },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: "xlsx",
  },
  {
    contentType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    extension: "docx",
  },
  { contentType: "application/vnd.ms-excel", extension: "xls" },
  { contentType: "image/jpeg", extension: "jpg" },
  { contentType: "image/png", extension: "png" },
  { contentType: "text/plain", extension: "txt" },
  { contentType: "application/zip", extension: "zip" },
];

export const getFileExtension = (contentType: any) => {
  const match = fileExtensionMap.find(
    (item) => item.contentType === contentType
  );
  return match ? match.extension : "bin"; // Default to 'bin' if not found
};
