import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Button, Grid, Typography, Input, Box } from "@mui/material";

import { colors } from "../../constants/colors";
import { background } from "../../constants/images";

export const CardNot = styled(`div`)(({ theme }) => ({
  backgroundColor: ` ${colors.bgBase} !important`,
  height: "100vh",
  width: "100%",
  backgroundImage: `url(${background.notfound})`,
  background: `url(${background.notfound}), ${colors.bgBase}`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 90%",
  backgroundPositionX: "1rem",
  color: "unset !important",
  backgroundPosition: "6% bottom",
  "& .card-text": {
    fontSize: "16px",
    textAlign: "left",
  },
  [theme.breakpoints.down("lg")]: {
    backgroundSize: "auto 75%",
  },
  [theme.breakpoints.down("md")]: {
    backgroundPositionX: "0rem",
    backgroundSize: "auto 50%",
  },
  [theme.breakpoints.down(960)]: {
    backgroundImage: "none",
    backgroundSize: "auto 25%",
  },
  [theme.breakpoints.down("sm")]: {
    backgroundImage: "none",
  },
}));
export const Texthaead = styled(Typography)(({ theme }) => ({
  fontFamily: "kanit",
  fontSize: "32px",
  fontWeight: "500",
  lineHeight: "23px",
  marginTop: "32px",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
}));
export const Boxnot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "32px",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
}));
export const TextDetail = styled(Typography)(({ theme }) => ({
  fontFamily: "kanit",
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "23px",
  marginTop: "32px",
  marginBottom: "32px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    marginTop: "18px",
    marginBottom: "18px",
  },
}));
const useStyles = makeStyles({
  Boxnot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
});
export default useStyles;
