import { Box, MenuItem } from "@mui/material";
import HeaderCard from "../../component/cardCustom/headerCard";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Row } from "react-bootstrap";
import Meatball from "../../assets/icon/meatball";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import TableRowCommon, {
  DropdownItem,
} from "../../component/table/TableRowCommon";
import { colors } from "../../constants/colors";
import { useDispatch } from "react-redux";
import { showModal } from "../../app/slice/modal.slice";
import ButtonCustom from "../../component/button/buttonCustom";
import { BoxTabSearch, Boxconteiner, Tab, Tabbutton } from "./adminStyle";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import TableCustom from "../../component/table/tableCustom";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import EmployeeApi from "../../api/admin/employee/employee.api";
import RoleApi from "../../api/admin/role/role.api";
import FilterSelect from "../../component/select/filterSelect";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import {
  isApporve,
  isCreate,
  isDelete,
  isExport,
  isUpdate,
  isView,
} from "../../utils/permission.utils";
import FunctionPrint, { useFunctionPrint } from "../../component/Print/print";
import { ExportProps } from "../../constants/reportConstant";
import { imgs } from "../../constants/images";
import Loading from "../../component/loading/loading";
import moment from "moment";
import InactiveReasonModal from "./modal/InactiveReasonModal";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
  isExport: isExport(),
  isApporve: isApporve()
};
export default function Admin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectStatus, setSelectStatus] = useState<string>("ALL");
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [empId, setempId] = useState<number>(0);
  const [listAdmin, setListAdmin] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [rowCount, setRowCount] = useState<number>();
  const [addminType, setAddminType] = useState<any>([]);
  const [roleId, setRoleId] = useState<Number>(0);
  const [print, setPrint] = useState<any>();
  const [employeeId, setEmployeeId] = useState<number>(Number);

  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("");
  const [errorMessageRemark, setErrorMessageRemark] = useState<string>("")

  const handleChangeRemark = (value: string) => {
    setRemark(value)
    setErrorMessageRemark("")
  };

  const loadData = useCallback(async () => {
    let condition: any = {};
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search) condition = { ...condition, search: search };
    if (selectStatus)
      condition = {
        ...condition,
        status: selectStatus === "ALL" ? "" : selectStatus,
      };
    const res = await EmployeeApi.findAll(condition);

    if (res?.status === 200) {
      setRowCount(res?.headers["total"]);
      setListAdmin(res?.data);
    } else {
      setRowCount(0);
      setListAdmin([]);
    }
  }, [page, pageLimit, search, selectStatus]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      label: t("ADMIN.HEADCELL.START_DATE"),
    },
    {
      id: "username",
      disablePadding: false,
      label: t("ADMIN.HEADCELL.ADMIN_NAME"),
    },

    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.STAFF_ID"),
    },
    {
      id: "rental",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.TYPE"),
    },

    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.STATUS"),
    },
    {
      id: "name",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.RECORDER"),
    },
    {
      id: "remark",
      disablePadding: false,
      align: "center",
      label: t("หมายเหตุ"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("ADMIN.HEADCELL.ACTION"),
    },
  ];
  const handledisStatus = async (id: any) => {
    if (!remark) {
      setErrorMessageRemark("ระบุเหตุผลในการปิดการใช้งาน")
      return;
    }
    const condition = {
      remark: remark,
      status: "INACTIVE",
    };
    await EmployeeApi.updateStatus(condition, +id).then((res) => {
      if (res.status === 200) {
        handleClosingModal(false)
        notiSuccess(t(`ADMIN.NOTI.SUCESSS`), "", null, null);
        loadData();
      } else {
        notiError(t(`ADMIN.NOTI.UNSUCESSS`), "", null, null);
      }
    });
  };

  const handleStatus = (id: any) => {
    const condition = {
      status: "ACTIVE",
      remark: "",
    };
    EmployeeApi.updateStatus(condition, +id).then((res) => {
      if (res.status === 200) {
        notiSuccess(t(`ADMIN.NOTI.SUCESSS`), "", null, null);
        loadData();
      } else {
        notiError(t(`ADMIN.NOTI.UNSUCESSS`), "", null, null);
      }
    });
  };

  // const [dataLoaded, setDataLoaded] = useState(false);
  // const onBeforeGetContentResolve = useRef<any>(null);
  const componentRef = useRef<any>(null);

  // const handleOnBeforeGetContent = useCallback(async () => {
  //   return new Promise(async (resolve) => {
  //     await EmployeeApi.getdetail(empId).then((res) => {
  //       if (res.status === 200) {
  //         setPrint(res?.data);
  //         onBeforeGetContentResolve.current = resolve;
  //         setDataLoaded(true);
  //       }
  //     });
  //   });
  // }, [empId]);

  // const handlePrint = useFunctionPrint({
  //   content: () => componentRef.current,
  //   beforeGetContent: handleOnBeforeGetContent,
  // });

  // useEffect(() => {
  //   if (dataLoaded) {
  //     onBeforeGetContentResolve.current();
  //   }
  // }, [dataLoaded, onBeforeGetContentResolve]);
  // useEffect(() => {
  //   handleOnBeforeGetContent();
  // }, [handleOnBeforeGetContent]);
  const [loading, setLoading] = useState<boolean>(false);

  const getTestPdfData = useCallback(
    async (employeeId: number) => {
      try {
        setLoading(true);
        const getTestDetails = EmployeeApi.getdetail(employeeId);

        const [testResult] = await Promise.all([getTestDetails]);
        setPrint(testResult.data);
        // console.log(testResult.data);
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    },
    [EmployeeApi]
  );

  const handlePrint = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () =>
      `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`,
  });

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;

    const {
      registerDate,
      employeeId,
      status,
      employeeCode,
      firstname,
      modifiedBy,
      createDate,
      lastname,
      Developer,
      remark,
      createdBy,
      modifiedDate,
      userId,
    } = objData;

    const objRenderData = {
      key: employeeId,
      id: employeeId,
      obj: objData,

      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
        },
        {
          option: "TEXT",
          align: "left",
          label: registerDate,
        },

        {
          option: "TEXT",
          align: "left",
          label: `${firstname}  ${lastname}`,
          style: { color: colors.green },
        },
        {
          option: "TEXT",
          align: "center",
          label: employeeCode,
        },
        {
          option: "TEXT",
          align: "center",
          label: Developer,
        },

        { option: "STATUS", align: "center", label: status || "-" },

        {
          option: "UPDATE_BY",
          align: "left",
          label: {
            updatedBy: `${modifiedBy && modifiedBy.firstname && modifiedBy.lastname
              ? modifiedBy.firstname + " " + modifiedBy.lastname
              : modifiedBy?.firstname || modifiedBy?.lastname || "-"
              }`,
            updatedAt: createDate,
          },
        },
        { option: "TEXT", align: "center", label: remark ? remark : "-" },
        {
          option: "COMPONENT",
          align: "center",

          style: { color: colors.themeMainColor },
          component: (
            <Box>
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  bsPrefix="p-0  "
                  className="btn-table-action text-black-50 shadow-none"
                >
                  <Meatball />
                </Dropdown.Toggle>
                <DropdownMenu className="dropdown-table-action position-fixed">
                  <DropdownItem
                    disabled={permissions.isExport.disabled}
                    onClick={() => {
                      getTestPdfData(employeeId);
                    }}
                  >
                    {" "}
                    {t("ADMIN.BUTTON.PRINT")}
                  </DropdownItem>
                  {/* <FunctionPrint
                    content={() => componentRef?.current}
                    onBeforeGetContent={async () => {
                      // setempId(employeeId);
                      await getTestPdfData(employeeId);
                    }}
                    documentTitle={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)}
                    removeAfterPrint
                    trigger={() => (
                      <DropdownItem> {t("ADMIN.BUTTON.PRINT")}</DropdownItem>
                    )}
                    pageStyle={`@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`}
                  /> */}

                  <DropdownItem
                    onClick={() =>
                      history.push(`admin-manage/edit-admin/${employeeId}`, {
                        params: { isView: true },
                      })
                    }
                    disabled={permissions?.isView.disabled}
                  >
                    {t("ADMIN.BUTTON.DETAIL")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      history.push(`admin-manage/edit-admin/${employeeId}`, {
                        params: { isView: false },
                      })
                    }
                    disabled={permissions?.isUpdate.disabled}
                  >
                    {t("ADMIN.BUTTON.EDIT")}
                  </DropdownItem>
                  {status === "ACTIVE" ? (
                    <DropdownItem
                      disabled={permissions?.isUpdate.disabled}
                      onClick={() => {
                        setIsInactive(true)
                        setEmployeeId(Number(employeeId))
                      }}
                    >
                      {t("ADMIN.BUTTON.STATUS")}
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      disabled={permissions?.isUpdate.disabled}
                      onClick={() => handleStatus(employeeId)}
                    >
                      {t("ADMIN.BUTTON.STATUSOPEN")}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Box>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const handleChangeStatus = (newStatus: string) => {
    setSelectStatus(newStatus);
  };

  const handleClosingModal = (status: boolean) => {
    setRemark("");
    setIsInactive(status)
  }

  return (
    <Box>
      {isInactive &&
        <InactiveReasonModal
          remark={remark}
          setRemark={handleChangeRemark}
          errorMessageRemark={errorMessageRemark}
          onSubmit={() => handledisStatus(employeeId)}
          onClose={handleClosingModal}
          isShow={isInactive} />
      }
      {loading && <Loading show={loading} type="fullLoading" />}
      <HeaderCard text={t("ADMIN.HEADER")} />
      <Boxconteiner>
        <Row>
          <Col className="my-2" lg={2} md={12} xs={12}>
            <InputTextField
              placeholder={t("ADMIN.INPUT.SEARCH")}
              onchange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Col>

          <Col className="my-2" lg={2} md={12} xs={12}>
            <FilterSelect
              onchange={(event) => {
                const value = event.target.value;
                if (value) {
                  handleChangeStatus(value);
                  setPage(1);
                }
              }}
              renderValue={() =>
                `${t("STATUS.LABEL")}: ${t(`STATUS.${selectStatus}`)}`
              }
              label={t("STATUS.LABEL")}
              selectId="select-status"
              value={selectStatus}
              labelId="label-status"
              options={[
                <MenuItem key="1" value="ALL">
                  {t("STATUS.ALL")}
                </MenuItem>,
                <MenuItem key="2" value="ACTIVE">
                  {t("STATUS.ACTIVE")}
                </MenuItem>,
                <MenuItem key="3" value="INACTIVE">
                  {t("STATUS.INACTIVE")}
                </MenuItem>,
              ]}
            />
          </Col>
          <Col lg={4} md={12} xs={12}></Col>
          <Col className="my-2" lg={4} md={12} xs={12}>
            <ButtonCustom
              textButton={t("ADMIN.BUTTON.ADD_ADMIN")}
              style={{ width: "100%" }}
              mode="add"
              disabled={permissions?.isCreate.disabled}
              onClick={() =>
                history.push("/admin-manage/create-admin", {
                  params: { isView: false },
                })
              }
            />
          </Col>
        </Row>

        <TableCustom
          headCells={headCells}
          customScroll
          page={page}
          pageLimit={pageLimit}
          sortType={"ASC"}
          sortBy=""
          rowCount={rowCount}
          rowsData={listAdmin?.map((d: any, index: any) => {
            return renderData(d, index);
          })}
          onSort={() => { }}
          setPageLimit={(value: number) => setPageLimit(value)}
          setPage={(value: number) => setPage(value)}
          tableMinWidth={1000}
        />
      </Boxconteiner>

      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={""}
          componant={
            <>
              <div className="pt-3 text-align-center ">
                <>
                  <div className="row d-flex justify-content-center">
                    <img
                      src={print?.profile}
                      alt=""
                      style={{ width: 200, height: 200 }}
                    />
                  </div>
                  <div className="row mt-4 ">
                    <div className="col-4 d-flex flex-column align-items-center ">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.ROLE")}
                      </p>
                      <div className=""></div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.EMPLOYEEID")}
                      </p>
                      <div className="">{print?.employeeCode}</div>
                    </div>

                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.START_DATE")}
                      </p>
                      <div className="">
                        {moment(print?.registerDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.PREFIX")}
                      </p>
                      <div className="">s</div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.NAME")}
                      </p>
                      <div className="">{print?.firstname}</div>
                    </div>

                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.LAST_NAME")}
                      </p>
                      <div className="">{print?.lastname}</div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.IDCARD")}
                      </p>
                      <div className="">{print?.idCard}</div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.BIRTH_DAY")}
                      </p>
                      <div className="">
                        {moment(print?.dob).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>{t("ADMIN.PRINT.OLD")}</p>
                      <div className="">{moment().diff(print?.dob, "years")}</div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.PHONE")}
                      </p>
                      <div className="">{print?.mobilePhone}</div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.EMAIL")}
                      </p>
                      <div className="">{print?.email}</div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ADMIN.PRINT.BRANCH")}
                      </p>
                      <div className="">s</div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-end">
                    <img
                      src={print?.signature}
                      alt=""
                      style={{ width: 200, height: 200 }}
                    />
                  </div>
                </>
              </div>
            </>
          }
        />
      </div>
      {loading && <Loading show={loading} type="fullLoading" />}
    </Box>
  );
}
export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
