import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../../constants/colors";

export const MainContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  background: "rgba(41, 48, 46, 0.3)",
  left: 0,
  right: 9,
  top: 0,
  bottom: 0,
  zIndex: 99999,
  [theme.breakpoints.down("md")]: {
    height: "200vh",
  },
}));

export const PopupContainer = styled(Box)(({ theme }) => ({
  height: 768,
  width: 1120,
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
  backgroundColor: colors.white,
  padding: 40,
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "90%",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 400,
}));

export const CloseIcon = styled("img")(({ theme }) => ({
  position: "absolute",
  right: 10,
  top: 12,
}));

export const BoxUploadImgContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  backgroundColor: colors.lightGrayBG,
  borderRadius: 2,
  display: "flex",
  justifyContent: "space-around",
  padding: 24,
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const ContainerUpload = styled(Box)(({ theme }) => ({
  padding: "32px 8px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
}));

export const ContainerBoxUpload = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: 8,
}));

export const BoxUpload = styled(Box)(({ theme }) => ({
  borderStyle: "dashed",
  borderColor: colors.disabledGray,
  width: 360,
  height: 304,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const TextAmount = styled(Typography)(({ theme }) => ({
  backgroundColor: colors.bgText,
  padding: 8,
  borderRadius: 8,
}));

export const TextBoxUpload = styled(Box)(({ theme }) => ({
  marginTop: 24,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 8,
  marginBottom: 8,
}));

export const TextBoxAlert = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  paddingLeft: 8,
  color: colors.red,
  fontSize: 20,
  fontWeight: 400,
}));

export const ContainerQR = styled(Box)(({ theme }) => ({
  backgroundColor: colors.white,
  width: "50%",
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
  borderRadius: 4,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export const ImageQR = styled("img")(({ theme }) => ({
  alignSelf: "center",
  maxWidth: "304px",
  width: "100%",
  //   height: 304,
  //   [theme.breakpoints.down("md")]: {
  //     width: 280,
  //     height: 280,
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     width: 200,
  //     height: 200,
  //   },
}));
export const TextQR = styled("div")(({ theme }) => ({
  fontSize: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize:10
  },
}));

export const BoxTextQR = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 48,
}));

export const ButtonSubmit = styled("button")(({ theme }) => ({
  color: colors.white,
  width: "100%",
  padding: "16px 0",
  display: "flex",
  justifyContent: "center",
  backgroundColor: colors.themeMainColor,
  fontSize: 18,
  borderRadius: 8,
  marginTop: 16,
  border: "none",
  ":hover": {
    opacity: 0.9,
  },
}));

export const ThaiQR = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",

  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
}));

export const ButtonDowload = styled("button")(({ theme }) => ({
  width: "120px",
  height: "36px",
  alignSelf: "center",
  border: `1px solid ${colors.themeMainColor}`,
  borderRadius: 4,
  color: colors.white,
  backgroundColor: colors.themeMainColor,
  textDecoration: "none",
  padding: "4px",
  margin: "8px 0px",
}));

export const ButtonDowloadPdf = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "36px",
  alignSelf: "center",
  border: `1px solid ${colors.themeMainColor}`,
  borderRadius: 4,
  color: colors.white,
  backgroundColor: colors.themeMainColor,
  textDecoration: "none",
  padding: "4px",
  margin: "8px 0px",
  cursor: "pointer",
}));
