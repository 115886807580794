import { menuSeconds } from "../../constants/menu"
import StallAgreement from "../../pages/stall/stall-agreement/stallAgreement"
import AgreementAdmin from "../../pages/stall/stall-agreement/agreementAdmin"
import MoreDetail from "../../pages/stall/stall-agreement/moreDetail"
import { routeName } from "../routers-name"
import { getProfile } from "../../utils/app.utils"
import { decode } from 'js-base64';

const profile: any = JSON.parse(getProfile() || '{}')
const role : any = profile.length > 3 ? JSON.parse(decode(profile)) : '{}'

const routeStallAgreement = [
  {
    ...menuSeconds.STALL_AGREEMENT,
    key: menuSeconds.STALL_AGREEMENT.key,
    name: menuSeconds.STALL_AGREEMENT.name,
    textName: menuSeconds.STALL_AGREEMENT.textName,
    active: false,
    appBar: true,
    component: StallAgreement
  } , 
  {
    ...menuSeconds.STALL_AGREEMENT,
    key: menuSeconds.STALL_AGREEMENT.key,
    name: menuSeconds.STALL_AGREEMENT.name,
    textName: menuSeconds.STALL_AGREEMENT.textName,
    path: routeName.stallAgreement + '/agreement',
    active: false,
    appBar: true,
    component: role.roleId === 4 ? MoreDetail : AgreementAdmin
  } , 
  ]
  
  export default routeStallAgreement