import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { faCheck, faEye, faFile, faPrint } from "@fortawesome/free-solid-svg-icons";
import { Grid, MenuItem, Button, Tooltip, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";

/** COMPONENT */
import { colors } from "../../constants/colors";
import TableRowCommon from "../../component/table/TableRowCommon";
import { closeModal, resetModal, showModal, submitModal, unSubmitModal } from "../../app/slice/modal.slice";
import HeaderCard from "../../component/cardCustom/headerCard";
import ButtonCustom from "../../component/button/buttonCustom";
import { icons, imgs } from "../../constants/images";
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import FilterSelect from "../../component/select/filterSelect";
import { dateToView } from "../../utils/date.utils";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { getProfile } from "../../utils/app.utils";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import Detail from "./popup/detail";
import Admincheck from "./popup/adminCheck";
import ConfirmCancelContract from "./popup/confirmCancelContrant";
import AttachFile from "./popup/attachFile";
import CreateCancelContract from "./popup/createCancelContract";
//**STYLE */
import * as Style from "./cancel.Style"

//**API */
import CancelContractApi from "../../api/stall/cancelcontract/cancelcontract";
import AppointmentApi from '../../api/stall/appointment/appointment';
import ZoneApi from "../../api/zone/zone.api";
import FileuploadApi from "../../api/uploadfile/upload.api";
import { ExportProps } from "../../constants/reportConstant";
import Loading from "../../component/loading/loading";
import { useFunctionPrint } from "../../component/Print/print";
import { decode } from 'js-base64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InvoiceApi from "../../api/invoid/invoid.api";
import ModalHaveDept from "../../component/modalCustom/modalHaveDept";
import BranchApi from "../../api/setting/branch/branch.api";

const initStateErrorMessage = {
  MEET_PLACE: ``,
  MEET_PLACE_ANY_REQUIRED: ``,

  APPOINTMENT_DATE: ``,
  APPOINTMENT_DATE_ANY_REQUIRED: ``,

  PHONE_NUMBER: ``,
  PHONE_NUMBER_ANY_REQUIRED: ``,
  PHONE_NUMBER_NUMBER_EMPTY: ``,
  PHONE_NUMBER_NUMBER_BASE: ``,

  REMARK: ``,
  REMARK_ANY_REQUIRED: ``,

  SAVE_DATE: ``,
  SAVE_DATE_ANY_REQUIRED: ``
}

export default function CancelContract() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const printRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [cancelContractData, setCancelContractData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>("ALL");
  const [appointDate, setAppointDate] = useState<string>("");
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [stallOption, setStallOption] = useState<any[]>([]);
  const [PopupType, setPopupType] = useState<string>("")
  const profile: any = JSON.parse(getProfile() || '{}')
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId)
  const [file, setFile] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);
  const [zoneId, setZoneId] = useState<any>();
  const [stallId, setStallId] = useState<any>();
  const [modalData, setModalData] = useState<any>()
  const [DataForPrinting, setDataForPrinting] = useState<any[]>([]);
  const [typePrint, setTypePrint] = useState<string>("")
  const [disApproveRemark, setDisApproveRemark] = useState<string>("")
  const [showRemark, setShowRemark] = useState<boolean>(false)
  const [noDoc, setNoDoc] = useState<string>("")
  const [errorMessageNoDoc, setErrorMessageNoDoc] = useState<string>("")
  const [reason, setReason] = useState<string>("")
  const [errorMessageReason, setErrorMessageReason] = useState<string>("")
  const [errorMessageAppointDate, setErrorMessageAppointDate] = useState<string>("");


  const [selectFile, setSelectFile] = useState<any>()

  const [loading, setLoading] = useState<boolean>(false);
  const [depted, setDepted] = useState<boolean>(false)

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId)
  }, [])

  const checkInvoice = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 10,
      userId: JSON.parse(decode(profile))?.userId,
      status: "NOT_PAY"
    }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      if (res.data.length >= 1) {
        setDepted(true)
        dispatch(showModal())
      }
    } else {

    }
  }
  const loadBranchData = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50
    }

    const res = await BranchApi.findAll(condition)
    if (res.status === 200) {
      setBranchData(res.data)
    } else {
      setBranchData([])
    }
  }

  useEffect(() => {
    checkInvoice()
    loadBranchData()
  }, [])
  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search !== "") condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }
    if (JSON.parse(decode(profile))?.roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }
    if (zoneId) condition = { ...condition, zoneId: zoneId?.zoneId }
    if (stallId) condition = { ...condition, stallId: stallId?.stallId }

    const res = await CancelContractApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setCancelContractData(res.data)

    } else {
      setRowCount(0)
      setCancelContractData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status, zoneId, stallId])

  useEffect(() => {
    loadData();
  }, [page, pageLimit, search, sortBy, sortType, status, zoneId, stallId])

  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT",
      skipcalculation: 0
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
    } else {
      setZoneOption([])
    }
  };

  useEffect(() => {
    getZone();
  }, [])

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handlePageLimitChange = (newPageLimit: number) => setPageLimit(newPageLimit);

  const handleChangeStatus = (newStatus: string) => setStatus(newStatus)

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "cancelLeaseAgreementId", disablePadding: false, label: "#", align: "center" },
    { id: "cancelAgreementDate", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.DOCUMENTDATE"), sortable: true },
    { id: "cancelAgreementNo", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.REQ_NO"), sortable: true },
    // { id: "name", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.NAME") },
    { id: "zoneName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.ZONE") },
    { id: "stallName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.STALL") },
    { id: "stallName", disablePadding: false, align: "center", label: t("เลขที่แผง") },
    { id: "stallTypeName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.TYPEZONE"), },
    { id: "stallTypeName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.LEASES_DATE"), },
    { id: "stallTypeName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.CANCEL_DATE"), },
    { id: "approveDate", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.APPOINTMENT_DATE"), },
    { id: "status", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.STATUS") },
    { id: "action", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.ACTION"), },
  ];
  const headCellsAdmin = [
    { id: "cancelLeaseAgreementId", disablePadding: false, label: "#", align: "center" },
    { id: "cancelAgreementDate", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.DOCUMENTDATE"), sortable: true },
    { id: "cancelAgreementNo", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.REQ_NO"), sortable: true },
    { id: "name", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.NAME") },
    { id: "zoneName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.ZONE") },
    { id: "stallName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.STALL") },
    { id: "stallName", disablePadding: false, align: "center", label: t("เลขที่แผง") },
    { id: "stallTypeName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.TYPEZONE"), },
    { id: "stallTypeName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.LEASES_DATE"), },
    { id: "stallTypeName", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.CANCEL_DATE"), },
    { id: "approveDate", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.APPOINTMENT_DATE"), },
    { id: "status", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.STATUS") },
    { id: "action", disablePadding: false, align: "center", label: t("CANCELCONTRACT.HEADCELL.ACTION"), },
  ];

  const ViewDoc = (data: any) => {
    setModalData(data)
    setPopupType("DETAIL")
    dispatch(showModal());
  }
  const downloadFile = (file: any) => {
    file?.map((item: any) => {
      const url = process.env.REACT_APP_FILE_URL + item?.filePath + item?.fileName
      // const blob = new Blob([file], { type: file.mimetype });
      window.open(url, "_blank");
    })

  }
  const adminCheck = (data: any) => {
    setModalData(data)

    if (!data.appointDate) {
      setPopupType("ADMIN_CHECK")
    } else {
      setPopupType("ATTACH_FILE")
    }
    dispatch(showModal());
  }
  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { cancelAgreementDate, cancelAgreementNo, leaseAgreement, appointmentDate, stall, status } = objData;
    const setState =
      status === "WAITING"
        ? t("CANCELCONTRACT.STATUS.VERIFY")
        : status === "PROGRESS"
          ? t("CANCELCONTRACT.STATUS.PENDING")
          : status === "APPROVE"
            ? t("CANCELCONTRACT.STATUS.COMPLETED")
            : t("CANCELCONTRACT.STATUS.DISAPPROVED");

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(cancelAgreementDate) || "-" },
        { option: "TEXT", align: "center", label: cancelAgreementNo || "-" },
        // { option: "TEXT", align: "center", label: `${(leaseAgreement && (leaseAgreement.firstname && leaseAgreement.lastname)) ? leaseAgreement.firstname + ' ' + leaseAgreement.lastname : leaseAgreement?.firstname || leaseAgreement?.lastname || '-'}` },
        { option: "TEXT", align: "center", label: `${leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName ?? '-'}` },
        { option: "TEXT", align: "center", label: `${leaseAgreement?.leaseAgreementApprove?.bidder?.stallName ?? '-'}` },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.stall?.stallNumber || '-' },
        { option: "TEXT", align: "center", label: `${leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName ?? '-'}` },
        { option: "TEXT", align: "center", label: dateToView(leaseAgreement?.leaseAgreementApprove?.approveDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(cancelAgreementDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(appointmentDate) || "-" },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              style={{
                color:
                  status === "WAITING" || status === "PROGRESS"
                    ? colors.lightOrange
                    : status === "APPROVE"
                      ? colors.green
                      : colors.red,
                textAlign: "center",
                whiteSpace: 'nowrap'
              }}
            >
              {setState}

            </Typography>
            // <Button
            //   style={{
            //     backgroundColor:
            //       status === "WAITING" || status === "PROGRESS"
            //         ? colors.lightOrange
            //         : status === "APPROVE"
            //           ? colors.green
            //           : colors.red,
            //     color: colors.white,
            //     margin: "auto",
            //     borderRadius: "4px",
            //     width: "150px",
            //     height: "36px"
            //   }}
            // >
            //   {setState}

            // </Button>
          ),
        },
        // {
        //   option: "ACTION",
        //   align: "center",
        //   label: "action",
        //   values: [
        //     {
        //       option: "DOCUMENTDETAIL",
        //       label: t(`BUTTON.DOCUMENTDETAIL`),
        //     },
        //     { option: "PRINT", label: t(`BUTTON.PRINT`), disabled: false },
        //   ],
        // },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  ViewDoc(objRenderData.obj)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="พิมพ์เอกสาร">
                <IconButton disabled={status !== "APPROVE"} onClick={() => {
                  setNoDoc(objRenderData.obj?.cancelLeaseAgreementApprove?.noDoc || "")
                  setReason(objRenderData.obj?.cancelLeaseAgreementApprove?.reason || "")
                  downloadFile(objData.fileUpload)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: status === "APPROVE" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => ViewDoc(objRenderData.obj)}
                />

              </div>
              <div>
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: status === "APPROVE" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "APPROVE") {
                      // PrintData()
                      setNoDoc(objRenderData.obj?.cancelLeaseAgreementApprove?.noDoc || "")
                      setReason(objRenderData.obj?.cancelLeaseAgreementApprove?.reason || "")
                      downloadFile(objData.fileUpload)
                    }
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    )
  };

  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { cancelAgreementDate, cancelAgreementNo, status, stall, leaseAgreement, appointmentDate } = objData;
    const setState =
      status === "WAITING"
        ? t("CANCELCONTRACT.STATUS.VERIFY")
        : status === "PROGRESS"
          ? t("CANCELCONTRACT.STATUS.PENDING")
          : status === "APPROVE"
            ? t("CANCELCONTRACT.STATUS.APPROVE")
            : t("CANCELCONTRACT.STATUS.DISAPPROVED");

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(cancelAgreementDate) || "-" },
        { option: "TEXT", align: "center", label: cancelAgreementNo || "-" },
        { option: "TEXT", align: "center", label: `${(leaseAgreement && (leaseAgreement.firstname && leaseAgreement.lastname)) ? leaseAgreement.firstname + ' ' + leaseAgreement.lastname : leaseAgreement?.firstname || leaseAgreement?.lastname || '-'}` },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || '-' },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || '-' },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.stall?.stallNumber || '-' },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName || '-' },
        { option: "TEXT", align: "center", label: dateToView(leaseAgreement?.leaseAgreementApprove?.approveDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(cancelAgreementDate) || "-" },
        { option: "TEXT", align: "center", label: dateToView(appointmentDate) || "-" },
        {
          option: "COMPONENT",
          align: "center",
          component:
            <Typography
              style={{
                color:
                  status === "WAITING" || status === "PROGRESS"
                    ? colors.lightOrange
                    : status === "APPROVE"
                      ? colors.green
                      : colors.red,
                textAlign: "center",
                whiteSpace: 'nowrap'
              }}
            >
              {setState}
            </Typography>
          // <Button
          //   style={{
          //     backgroundColor:
          //       status === "WAITING" || status === "PROGRESS"
          //         ? colors.lightOrange
          //         : status === "APPROVE"
          //           ? colors.green
          //           : colors.red,
          //     color: colors.white,
          //     margin: "auto",
          //     borderRadius: "4px",
          //     width: "150px",
          //     height: "36px"
          //   }}
          // >
          //   {setState}
          // </Button>
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="ตรวจสอบ">
                <IconButton disabled={status !== "WAITING"} onClick={() => {
                  adminCheck(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "WAITING" ? colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    cursor: status === "WAITING" ? "pointer" : "auto"
                    , color: status === "WAITING" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "WAITING") {
                      adminCheck(objData)
                    } else { }
                  }
                  }
                />
              </div> */}
              <Tooltip title="อนุมัติ">
                <IconButton disabled={status !== "PROGRESS"} onClick={() => {
                  setModalData(objData)
                  setPopupType("ATTACH_FILE")
                  dispatch(showModal())
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "PROGRESS" ? colors.themeMainColor : colors.gray
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: status === "PROGRESS" ? "pointer" : "auto"
                    // cursor: status === "APPROVE" ? "none" :"pointer"
                    , color: status === "PROGRESS" ? colors.themeMainColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "PROGRESS") {
                      setModalData(objData)
                      setPopupType("ATTACH_FILE")
                      dispatch(showModal())
                    }
                  }}
                />
              </div> */}
              <Tooltip title="รายละเอียด">
                <IconButton disabled={status === "APPROVE"} onClick={() => {
                  ViewDoc(objRenderData.obj)
                }}>
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: status === "APPROVE" ? colors.gray : colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    cursor: status === "APPROVE" ? "auto" : "pointer"
                    , color: status === "APPROVE" ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => {
                    if (status !== "APPROVE") {
                      ViewDoc(objRenderData.obj)
                    }
                  }}
                />
              </div> */}
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton onClick={() => {
                  setTypePrint("PRINT")
                  setDataForPrinting([objRenderData.obj])
                  setNoDoc(objRenderData.obj?.cancelLeaseAgreementApprove?.noDoc || "")
                  setReason(objRenderData.obj?.cancelLeaseAgreementApprove?.reason || "")
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div>
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    // PrintData()

                    setTypePrint("PRINT")
                    setDataForPrinting([objRenderData.obj])
                    setNoDoc(objRenderData.obj?.cancelLeaseAgreementApprove?.noDoc || "")
                    setReason(objRenderData.obj?.cancelLeaseAgreementApprove?.reason || "")
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };

    return (
      <TableRowCommon {...objRenderData} />
    )

  };


  const onApprove = async () => {
    if (!appointDate) {
      setErrorMessageAppointDate("ระบุวันที่นัด")
      return;
    }
    else if (!noDoc) {
      setErrorMessageNoDoc("ระบุเลขที่เอกสาร")
      return;
    } else if (!reason) {
      setErrorMessageReason('ระบุรายละเอียด')
      return;
    }
    const res = await CancelContractApi.approve(moment(Date.now()).format("YYYY-MM-DD"), modalData?.cancelLeaseAgreementId, disApproveRemark, noDoc, reason, JSON.parse(decode(profile))?.empId)
    if (res.status === 201) {
      CancelContractApi.updateStatus(modalData?.cancelLeaseAgreementId, "PROGRESS")
      dispatch(closeModal())
      AppointmentApi.create(
        modalData?.leaseAgreement?.branchId,
        modalData?.leaseAgreement?.userId,
        modalData?.leaseAgreement?.leaseAgreementApprove?.bidder?.bidderId,
        "CANCEL_CONTRACT",
        moment(appointDate).format("YYYY-MM-DD"),
        JSON.parse(decode(profile))?.mobilePhone)
        .then((res: any) => {
          if (res.status === 201) {
            notiSuccess(t(`CANCELCONTRACT.MESSAGE.SUCCESS.CREATE`), '', null, null)
            loadData()
          } else {
            const err = res.response.data
            setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`) } })
          }
        })
        .catch((e) => {
          const err = e.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`) } })
        })
    }

  }

  const onDisapproved = () => {
    CancelContractApi.updateStatus(modalData?.cancelLeaseAgreementId, "NOT_APPROVE",)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`CANCELCONTRACT.MESSAGE.SUCCESS.CREATE`), '', null, null)
          loadData()
          dispatch(closeModal())
        } else {
          const err = res?.response?.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
          loadData()
        }
      })
      .catch((e) => {
        console.log(e);
        const err = e?.response?.data
        setErrorMessage({ ...errorMessage, ...{ [err?.error_description]: t(`CANCELCONTRACT.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const onUploadDocument = async () => {

    const condition: any = {
      branchId: 1,
      leaseAgreementId: modalData?.leaseAgreementId,
      status: "APPROVE"
    }

    await CancelContractApi.update(modalData?.cancelLeaseAgreementId, condition)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.refId) {
            let ref = res.data.refId || null;
            const uploadDocument = new FormData();
            uploadDocument.append("refId", `${ref}`);
            uploadDocument.append("fileType", "cancel-lease-agreement");
            file.forEach((data: any, i: any) => {
              uploadDocument.append(`files[]`, data);
            });

            FileuploadApi.uploadfilearray(uploadDocument).then((result) => {
              notiSuccess(t(`CANCELCONTRACT.ALERT.UPLOAD_SUCCESS`), "", "", null);
              loadData()
              dispatch(resetModal())
            });
          }

        } else {
          notiError(t(`CANCELCONTRACT.ALERT.UPLOAD_FAILED`))
        }
      })
  }

  const handledelete = (x: any) => {
    setFile(file?.filter((y: any) => y.path !== x.path));
    setSelectFile(null);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf"
      ) {
        setFile([...file, acceptedFiles[0]]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handlepreview = (x: any) => {
    const name = x.type.split(".");

    if (name[name?.length - 1] === "application/pdf") {
      window.open(URL.createObjectURL(x), "_blank");
    } else {
      setSelectFile(URL.createObjectURL(x));
    }
  };

  const PrintData = useCallback(async () => {
    try {
      setTypePrint("PRINT")
      let condition: any = {}
      if (page) condition = { ...condition, page: page }
      if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
      if (search) condition = { ...condition, search: search }
      if (sortBy) condition = { ...condition, sortBy: sortBy }
      if (sortType) condition = { ...condition, sortType: sortType }
      if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

      const resCancelcontract = await CancelContractApi.findAll(condition)

      const [testResult] = await Promise.all([
        resCancelcontract,
      ]);

      setDataForPrinting(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [CancelContractApi])

  const handlePrintdataAppointment = useFunctionPrint({
    content: () => printRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (DataForPrinting && typePrint === "PRINT") {
      handlePrintdataAppointment();
    }
  }, [DataForPrinting, typePrint])

  return (
    <div>
      <HeaderCard text={t("CANCELCONTRACT.TITLE")} />
      <Style.PageContainer>

        {roleId === 4 && (<Style.MainContainer>
          <Row >
            <Col lg={2} md={6} xs={12} className="my-1">
              <InputTextField
                label={t("CANCELCONTRACT.FILTER.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>

            <Col lg={2} md={6} xs={12} className="my-1">
              <AutocompleteSelect
                options={zoneOption || []}
                value={zoneOption ? zoneOption?.find((e: any) => e.zoneId === zoneId?.zoneId) : null}
                labelId="search-zone"
                getOptionLabel={(option: any) => option.zoneName}
                placeholder={t("CANCELCONTRACT.FILTER.ZONE")}
                onChange={(event, newValue) => {
                  setZoneId(newValue)
                  setStallOption(newValue?.stall);
                }}
              />
            </Col>

            <Col lg={2} md={6} xs={12} className="my-1">
              <AutocompleteSelect
                options={stallOption || []}
                getOptionLabel={(option: any) => option.stallName}
                placeholder={t("CANCELCONTRACT.FILTER.STALL")}
                onChange={(event, value) => {
                  setStallId(value)
                }}
                labelId="label-stall"
                value={stallOption ? stallOption.find((e: any) => e.stallId === stallId?.stallId) : null}
              />

            </Col>

            <Col lg={2} md={6} xs={12} className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value)
                    setPage(1)
                  }
                }}
                renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status"
                labelId="label-status"
                value={status}
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="PROGRESS">
                    {t('CANCELCONTRACT.STATUS.PENDING')}
                  </MenuItem>,
                  <MenuItem key="2" value="APPROVE">
                    {t('CANCELCONTRACT.STATUS.APPROVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="NOT_APPROVE">
                    {t('CANCELCONTRACT.STATUS.DISAPPROVED')}
                  </MenuItem>
                ]}
              />
            </Col>
            <Col lg={1} md={6} xs={12}></Col>
            <Col lg={3} md={6} xs={12} className="my-1">
              <ButtonCustom
                type="button"
                textButton={t('CANCELCONTRACT.BUTTON.CANCEL_REQ')}
                endIcon={<img src={icons.add} />}
                style={{ width: '100%' }}
                onClick={() => {
                  setPopupType("CREATE")
                  dispatch(showModal())
                }}
              />
            </Col>

          </Row>

          <Style.TableContainer>
            <TableCustom
              headCells={headCells}
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={cancelContractData && cancelContractData.map((data: any, index: number) => {
                return renderData(data, index);
              })}
              onSort={onRequestSort}
              setPageLimit={handlePageLimitChange}
              setPage={handlePageChange}
              tableMinWidth={1800}
            />
          </Style.TableContainer>
        </Style.MainContainer>)}

        {roleId !== 4 && (<Style.MainContainer>
          <Row spacing={2}>
            <Col lg={2} md={6} xs={12} className="my-1">
              <InputTextField
                label={t("CANCELCONTRACT.FILTER.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>

            <Col lg={2} md={6} xs={12} className="my-1">
              <AutocompleteSelect
                options={zoneOption || []}
                value={zoneOption ? zoneOption?.find((e: any) => e.zoneId === zoneId?.zoneId) : null}
                labelId="search-zone"
                getOptionLabel={(option: any) => option.zoneName}
                placeholder={t("CANCELCONTRACT.FILTER.ZONE")}
                onChange={(event, newValue) => {
                  setZoneId(newValue)
                  setStallOption(newValue?.stall);
                }}
              />
            </Col>

            <Col lg={2} md={6} xs={12} className="my-1">
              <AutocompleteSelect
                options={stallOption || []}
                getOptionLabel={(option: any) => option.stallName}
                placeholder={t("CANCELCONTRACT.FILTER.STALL")}
                onChange={(event, value) => {
                  setStallId(value)
                }}
                labelId="label-stall"
                value={stallOption ? stallOption.find((e: any) => e.stallId === stallId?.stallId) : null}
              />
            </Col>

            <Col lg={2} md={6} xs={12} className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value)
                    setPage(1)
                  }
                }}
                renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status"
                labelId="label-status"
                value={status}
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="WAITING">
                    {t('CANCELCONTRACT.STATUS.VERIFY')}
                  </MenuItem>,
                  <MenuItem key="2" value="APPROVE">
                    {t('CANCELCONTRACT.STATUS.APPROVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="NOT_APPROVE">
                    {t('CANCELCONTRACT.STATUS.DISAPPROVED')}
                  </MenuItem>
                ]}
              />
            </Col>
            <Col lg={4} md={6} xs={12}></Col>

          </Row>

          <Style.TableContainer>
            <TableCustom
              headCells={headCellsAdmin}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={cancelContractData && cancelContractData.map((data: any, index: number) => {
                return renderDataAdmin(data, index);
              })}
              onSort={onRequestSort}
              setPageLimit={handlePageLimitChange}
              setPage={handlePageChange}
              tableMinWidth={1400}
            />
          </Style.TableContainer>
        </Style.MainContainer>)}

        {PopupType === "DETAIL" && <Detail data={modalData} />}

        {PopupType === "ADMIN_CHECK" && JSON.parse(decode(profile))?.roleId !== 4 && <Admincheck
          data={modalData}
          onApprove={() => setPopupType("CONFIRM_CANCEL")}
          onDisapprove={() => {
            // if(!disApproveRemark && !showRemark){
            //   setShowRemark(true)
            // }else if(disApproveRemark && showRemark){
            //   onDisapproved()
            //   dispatch(closeModal())
            // }
            onDisapproved()
            dispatch(closeModal())
          }}
          showRemark={showRemark}
          remark={disApproveRemark}
          onchangeRemark={(e: any) => setDisApproveRemark(e.target.value)}
        />}

        {PopupType === "CONFIRM_CANCEL" && JSON.parse(decode(profile))?.roleId !== 4 && <ConfirmCancelContract
          data={modalData}
          noDoc={noDoc}
          onChangeNoDoc={(value: string) => {
            setNoDoc(value)
            setErrorMessageNoDoc("")
          }}
          errorMessageReason={errorMessageReason}
          reason={reason}
          onChangeReason={(value: string) => {
            setReason(value)
            setErrorMessageReason("")
          }}
          errorMessageNoDoc={errorMessageNoDoc}
          AppointmentDate={appointDate}
          onChangeAppointmentDate={(e: any) => {
            setAppointDate(moment(e).format("YYYY-MM-DD"))
            setErrorMessageAppointDate("")
          }}
          errorMessageAppointDate={errorMessageAppointDate}
          place={modalData && modalData?.leaseAgreement?.branchId}
          tel={JSON.parse(decode(profile))?.mobilePhone}
          remark={disApproveRemark}
          onchangeRemark={(e: any) => setDisApproveRemark(e.target.value)}
          onsubmit={() => {
            onApprove()
          }} />}

        {PopupType === "ATTACH_FILE" && JSON.parse(decode(profile))?.roleId !== 4 && <AttachFile
          data={modalData}
          selectFile={selectFile}
          file={file}
          getInputProps={getInputProps()}
          getRootProps={getRootProps()}
          handledelete={handledelete}
          handlepreview={handlepreview}
          onsubmit={onUploadDocument}
        />}

        {PopupType === "CREATE" && JSON.parse(decode(profile))?.roleId === 4 && <CreateCancelContract />}

        {depted ? <ModalHaveDept /> : <></>}

      </Style.PageContainer>

      <div className="print-show" ref={printRef}>
        <ExportData
          headCells={""}
          componant={

            <>
              {typePrint === "PRINT" && DataForPrinting && <div>

                {/* {DataForPrinting.map((d: any) => {
                      return (
                        <>
                        <div className="row mt-lg-3">
                      <div className="col-12">
                        <h5>{t('CANCELCONTRACT.POPUP.DETAIL')}</h5>
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_NO')}</span>
                        <span>{d?.cancelAgreementNo}</span>
                      </div>

                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.NAME')}</span>
                        <span className="mr-2">{d?.leaseAgreement?.firstname}</span>
                        <span>{d?.leaseAgreement?.lastname}</span>
                      </div>

                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.REQ_DATE')}</span>
                        <span>{dateToView(d?.cancelAgreementDate)}</span>
                      </div>
                    </div>

                    <div className="mt-2 row">
                      <div className="d-flex flex-row col-4">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.RENT_DATE')}</span>
                        <span>{dateToView(d?.leaseAgreement?.leaseAgreementDateStart)}</span>
                      </div>

                      <div className="d-flex flex-row col-4">
                        <span className="mr-2">{t('CANCELCONTRACT.POPUP.CONTRACT_END_DATE')}</span>
                        <span style={{color: 'red'}}>{dateToView(d?.leaseAgreement?.leaseAgreementDateEnd)}</span>
                      </div>
                    </div>

                    <div className="mt-3 row">
                      <div className="d-flex flex-row col-6">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.ZONE')}</span>
                        <div className="col-3" />
                        <span>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</span>
                      </div>

                      <div className="d-flex flex-row col-6">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.STALL')}</span>
                        <div className="col-3" />
                        <span>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}</span>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="d-flex flex-row col-6">
                        <span className="mr-lg-5">{t('CANCELCONTRACT.POPUP.TYPEZONE')}</span>
                        <span>{d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName}</span>
                      </div>

                      <div className="d-flex flex-row col-6">
                        <span className="mr-4">{t('CANCELCONTRACT.POPUP.PAYMENT_CYCLE')}</span>
                        <span>{t('รายเดือน')}</span>
                      </div>
                    </div>
                        </>
                      )
                    })} */}
                {DataForPrinting.map((d: any) => {
                  return (
                    <div className="px-3 print-pdf-sarabun" style={{ pageBreakAfter: 'always' }}>

                      <div className="row" style={{ margin: '40px 0px' }}>
                        <div className="col-12">
                          <p className="text-center fs-5 fw-bold">
                            แบบคำร้องขอยกเลิกแผงค้า
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-12 d-flex flex-column align-items-end">
                          <div className="d-flex gap-1">
                            <span>เลขที่ </span>
                            <span>
                              <p style={{ borderBottom: noDoc ? '1px dotted #000' : '' }}> {noDoc ? noDoc : d?.cancelLeaseAgreementApprove?.noDoc || '..............................'}</p>
                            </span>
                          </div>
                          <p>กลุ่มบริหารงานตลาด</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-12 d-flex flex-row justify-content-end">
                          <p>วันที่</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '200px' }}>{dateToView(new Date())}</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="d-flex flex-row col-6">
                          <p className="ml-3">ข้าพเจ้า</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.leaseAgreement?.prefix?.prefixNameTh || ''} {d?.leaseAgreement?.firstname || ""} {d?.leaseAgreement?.lastname || ""}</p>
                        </div>
                        <div className="col-2 d-flex flex-row">
                          <p>อายุ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.leaseAgreement?.birthDay && moment().diff(d?.leaseAgreement?.birthDay, "years")}</p>
                          <p>ปี</p>
                        </div>
                        <div className="col-2 d-flex flex-row">
                          <p className="text-nowrap">สัญชาติ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                        </div>
                        <div className="col-2 d-flex flex-row">
                          <p className="text-nowrap">เชื้อชาติ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                        </div>
                      </div>
                      <div className="row mt-1">

                        <div className="col-3 d-flex flex-row">
                          <p className="text-nowrap">อยู่บ้านเลขที่</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.leaseAgreement?.houseNumber + " " || ""}</p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="text-nowrap">หมู่ที่</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.leaseAgreement?.moo + " " || ""}</p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="text-nowrap">ถนน</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.leaseAgreement?.road + " " || ""}</p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="text-nowrap">แขวง/ตำบล</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.leaseAgreement?.district?.districtNameTh || ""}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1">

                        <div className="col-4 d-flex flex-row">
                          <p className="text-nowrap">เขต/อำเภอ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: "nowrap" }}>
                            {d?.leaseAgreement?.subDistrict?.subDistrictNameTh || ""}
                          </p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p className="text-nowrap">จังหวัด</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.leaseAgreement?.province?.provinceNameTh || ""}</p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p className="text-nowrap">โทรศัพท์</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.leaseAgreement?.mobilePhone || ""}
                          </p>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-5 d-flex flex-row">
                          <p className="text-nowrap">เป็นผู้ประกอบการค้าตลาด</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: "nowrap" }}>
                            {branchData?.length > 0 ? branchData?.find((e: any) => e.branchId === d?.leaseAgreement?.branchId)?.branchNameTh : ""}
                          </p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p className="text-nowrap">ประเภทสินค้า</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallTypeName || ""}
                          </p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="text-nowrap">แผง/คูหาเลขที่</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || ""}
                          </p>
                        </div>

                      </div>
                      <div className="row mt-1">
                        <div className="col-12">
                          <p>ขอยื่นคำร้องต่อหัวหน้ากลุ่มบริหารงานตลาด องค์การตลาดเพื่อเกษตรกร โดยมีความประสงค์ ดังนี้</p>
                        </div>
                        <div className="col-12  mt-3">
                          <p style={{ borderBottom: '1px dotted black', textIndent: 0, width: '100%' }}>
                            {reason ? reason : 'ยังไม่ระบุรายละเอียด'}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                          </p>
                        </div>

                      </div>
                      <div className="row mt-1">
                        <div className="col-12">
                          <p ><span className="ml-3"></span>ข้าพเจ้ายินดีปฏิบัติตามระเบียบข้อบังคับ คำสั่ง คำแนะนำ ประกาศขององค์การตลาดเพื่อเกษตรกร และ หรือ การพิจารณาของหัวหน้ากลุ่มบริหารงานตลาด ทุกประการ โดยไม่มีเงื่อนไขใดๆทั้งสิ้น และขอรับรองว่าข้อความข้างต้นนี้เป็นความจริง
                            <p className="fw-bolder">*** ข้าพเจ้าได้รับประกาศองค์การตลาดเพื่อเกษตรกร เรื่อง นโยบายคุ้มครองข้อมูลส่วนบุคคล(Personal Data Privacy Policy)ในเว็บไซต์ mof.or.th ประกาศ ณ วันที่ ๒๐ พฤษภาคม ๒๕๖๕</p></p>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-12">
                          <p className="ml-5">จึงเรียนมาเพื่อโปรดพิจารณา</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <p className="ml-5 text-right">ลงชื่อ...........................................................ผู้ยื่นคำร้อง</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <p className="text-right" style={{ marginRight: 56 }}>{`(${d?.leaseAgreement?.prefix?.prefixNameTh || ''} ${d?.leaseAgreement?.firstname || ""} ${d?.leaseAgreement?.lastname || ""})`}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>}
            </>
          }
        />
      </div>



      {loading && <Loading show={loading} type="fullLoading" />}
    </div>
  );
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
