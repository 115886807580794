
import { useEffect, useState } from "react";
import { getProfile } from "../../../utils/app.utils";
import Admin from "./display/admin";
import Customer from "./display/customer";
import { decode } from 'js-base64';
import CustomerNew from "./display/customerNew";
import { Typography, useMediaQuery } from "@mui/material";
import { imgs } from "../../../constants/images";

export default function MarketMap() {
    const profile: any = JSON.parse(getProfile() || '{}')
    const [roleId, setRoleId] = useState<number>(0)
    const isMobile = useMediaQuery('(max-width: 950px)')

    useEffect(() => {
        setRoleId(JSON.parse(decode(profile))?.roleId)
    }, []);
    return (
        roleId != 4 && (
            <>{isMobile ? <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '100%', height: '80vh' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                        <img src={imgs.AlertSuspense} style={{ width: '100%', objectFit: 'contain' }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: '500', textAlign: 'center' }}>ขออภัย สามารถใช้งานฟังชั่นได้เฉพาะ PC เท่านั้น</Typography>
                        <Typography onClick={() => window.location.href = '/'} sx={{
                            fontSize: "18px", fontWeight: '500', textAlign: 'center', color: "red", textDecoration: 'underline red', cursor:'pointer'
                        }}>กลับหน้าแรก</Typography>
                    </div>

                </div>
            </div> : <Admin />}</>
        ) || (
            <CustomerNew />
        )
    )
}