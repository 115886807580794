import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//** COMPONENT */
import { colors } from "../../constants/colors";
import { Col, Dropdown, Row } from "react-bootstrap";
import TableRowCommon from "../../component/table/TableRowCommon";
import HeaderCard from "../../component/cardCustom/headerCard";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import TableCustom from "../../component/table/tableCustom";
import { debouncedApiCall, getProfile } from "../../utils/app.utils";
import { routeName } from "../../routes/routers-name";
import { Box, Divider } from "@mui/material";
import { decode } from 'js-base64';

//** STYLE */
import { Boxconteiner } from "./payMentStyle";

//** API */
import StallTypeApi from "../../api/setting/stall/stall.api";
import ZoneApi from "../../api/zone/zone.api";
import InvoiceApi from "../../api/invoid/invoid.api";
import { dateToView } from "../../utils/date.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMoneyBill1Wave, faPrint } from "@fortawesome/free-solid-svg-icons";
import { numberFormat } from "../../utils/common";
import LeasesTypeApi from "../../api/setting/leases/leases.api";
import TableButton from "../../component/table/tableButton";

export default function TrackBill() {
  const { t } = useTranslation();
  const history = useHistory();
  const profile: any = JSON.parse(getProfile() || '{}')
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId)
  const [stallTypeOption, setStallTypeOption] = useState<any[]>([]);
  const [zoneOption, setZoneOption] = useState<any[]>([]);
  const [stallOption, setStallOption] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [stallId, setStallId] = useState(Number)
  const [zoneId, setZoneId] = useState(Number)
  const [stallTypeId, setStallTypeId] = useState(Number)
  const [invoiceData, setInvoiceData] = useState<any[]>([])

  const isUser: boolean = JSON.parse(decode(profile))?.roleId === 4 ? true : false;
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(Number);
  const [keywordLeasesTypeData, setKeywordLeasesTypeData] = useState<string>("")

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }
  const getStallType = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "ACTIVE"
    };
    const res = await StallTypeApi.findAll(condition);
    if (res.status === 200) {
      setStallTypeOption(res.data);
    } else {
      setStallTypeOption([])
    }
  };

  const getZone = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT"
    };
    const res = await ZoneApi.findAll(condition)

    if (res.status === 200) {
      setZoneOption(res.data);
    } else {
      setZoneOption([])
    }
  };

  useEffect(() => {
    getZone();
    getStallType();
  }, []);


  const PayoffDebt = () => {
    history.push(routeName.payBill);
  };


  const handleGetLeasesTypeData = useCallback(async () => {
    let condition: any = {}
    if (keywordLeasesTypeData) condition = { ...condition, search: keywordLeasesTypeData }
    await LeasesTypeApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          setLeasesTypeData(res?.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [keywordLeasesTypeData])

  useEffect(() => {
    if (!isUser) {
      handleGetLeasesTypeData();
    }
  }, [handleGetLeasesTypeData])


  const loadData = useCallback(async () => {
    let condition: any = {
      status: "NOT_PAY",
      sortBy: "invoiceDate",
      sortType: "DESC"
    }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (JSON.parse(decode(profile))?.roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }
    if (stallId) condition = { ...condition, stallId: stallId };
    if (zoneId) condition = { ...condition, zoneId: zoneId }
    if (search !== "") condition = { ...condition, search: search }
    if (leasesTypeId) condition = { ...condition, leaseAgreementTypeId: leasesTypeId }
    const res = await InvoiceApi.findAll(condition)

    if (res.status === 200) {
      setInvoiceData(res.data)
      setRowCount(res.headers['total'])
    } else {
      setInvoiceData([])
    }
  }, [page, pageLimit, stallId, zoneId, search, leasesTypeId])

  useEffect(() => {
    loadData()
  }, [page, pageLimit, stallId, zoneId, search, leasesTypeId])
  const headCells = [
    { id: "no", disablePadding: false, label: "#", align: "center", },
    { id: "date", disablePadding: false, label: t("TRACK_BILL.DETAIL.DATE_DOC"), align: "center", },
    { id: "date", disablePadding: false, label: t("TRACK_BILL.DETAIL.DOCUMENT_ID"), align: "center", },
    { id: "zoneName", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.ZONE"), },
    { id: "stallName", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.STALL"), },
    { id: "stallName", disablePadding: false, align: "center", label: t("เลขที่แผง"), },
    { id: "typeBill", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.TYPE_DEBT"), },
    // { id: "enddate", disablePadding: false, align: "left", label: t("TRACK_BILL.DETAIL.DATE_OVERDUE"), },
    { id: "price", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.SUM"), },
    { id: "invoiceDate", disablePadding: false, align: "center", label: t("ระยะเวลาการชำระหนี้"), },
    { id: "status", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.STATUS"), },
    { id: "action", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.ACTION"), },
  ];

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#", align: "center" },
    { id: "date", disablePadding: false, label: t("TRACK_BILL.DETAIL.DATE_DOC"), align: "center" },
    { id: "date", disablePadding: false, label: t("TRACK_BILL.DETAIL.DOCUMENT_ID"), align: "center" },
    { id: "name", disablePadding: false, label: t("TRACK_BILL.DETAIL.USER_NAME"), align: "center" },
    { id: "zoneName", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.ZONE"), },
    { id: "stallName", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.STALL"), },
    { id: "stallName", disablePadding: false, align: "center", label: t("เลขที่แผง"), },
    { id: "typeBill", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.TYPE_DEBT"), },
    { id: "enddate", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.DATE_OVERDUE"), },
    { id: "price", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.SUM"), },
    { id: "status", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.STATUS"), },
    { id: "action", disablePadding: false, align: "center", label: t("TRACK_BILL.DETAIL.ACTION"), },
  ];

  const viewDebtReport = async (obj: any) => {
    const res = await InvoiceApi.detail(obj.invoiceId)
    if (res.status === 200) {
      history.push(routeName.trackBill + "/list-payment", {
        params: {
          data: res?.data
        },
      })
    }
  }

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { createDate, price, leaseAgreement, invoiceNo, status, stall, leasesType, invoiceDateStart, invoiceDetail, invoiceDateEnd } = objData;
    const sumAll = invoiceDetail.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
    const setState =
      status === "ACTIVE"
        ? t("STATUS.WAITING")
        : status === "NOT_PAY"
          ? t("STATUS.OVERDUE")
          : t("STATUS.PAY");
    const objRenderData = {
      key: no,
      id: no,
      obj: objData,

      columns: [
        { option: "TEXT", align: "center", label: no, },
        { option: "TEXT", align: "center", label: dateToView(createDate) || "-" },
        { option: "TEXT", align: "center", label: invoiceNo || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallNumber || "-" },
        { option: "TEXT", align: "center", label: leasesType?.leasesTypeNameTh || "-", },
        // { option: "TEXT", align: "center", label: dateToView(invoiceDateStart), style: { color: colors.red }, },
        { option: "TEXT", align: "center", label: numberFormat(sumAll) || "0" },
        { option: "TEXT", align: "center", label: `${dateToView(invoiceDateStart)} - ${dateToView(invoiceDateEnd)}` || "-" },
        { option: "TEXT", align: "center", label: setState || "-", style: { color: colors.red, textDecoration: "underline" }, },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <TableButton title="ชำระเงิน" disabled={status === "PAY"} icon={faMoneyBill1Wave} onClick={() => {
                PayoffDebt()
              }} />
              <TableButton title="รายละเอียด" icon={faFile} onClick={() => {
                viewDebtReport(objData)
              }} />
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faMoneyBill1Wave}
                  style={{
                    cursor: status === "PAY" ? "none" : "pointer"
                    , color: status === "PAY" ? colors.gray : colors.themeMainColor
                  }}
                  onClick={PayoffDebt}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => viewDebtReport(objData)}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { invoiceNo, leasesType, price, invoiceDetail, createDate, status, stall, leaseAgreement, rental, invoiceDateStart, invoiceDateEnd, typepayment, } = objData;
    const sumAll = invoiceDetail.reduce((a: number, v: any) => a = a + Number(v.amount), 0)
    const setState =
      status === "ACTIVE"
        ? t("STATUS.WAITING")
        : status === "NOT_PAY"
          ? t("STATUS.OVERDUE")
          : t("STATUS.PAY");
    const objRenderData = {
      key: no,
      id: no,
      obj: objData,

      columns: [
        { option: "TEXT", align: "center", label: no, },
        { option: "TEXT", align: "center", label: dateToView(createDate) || "-" },
        { option: "TEXT", align: "center", label: invoiceNo || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.firstname + " " + leaseAgreement?.lastname || "-" },
        { option: "TEXT", align: "center", label: leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallNumber || "-" },
        { option: "TEXT", align: "center", label: leasesType?.leasesTypeNameTh || "-", },
        { option: "TEXT", align: "center", label: dateToView(invoiceDateEnd), style: { color: colors.red }, },
        { option: "TEXT", align: "center", label: numberFormat(sumAll) || "0" },
        { option: "TEXT", align: "center", label: setState || "-", style: { color: colors.red, textDecoration: "underline" }, },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              {/* <TableButton title="ชำระเงิน" disabled={status === "PAY"} icon={faMoneyBill1Wave} onClick={() => {
                PayoffDebt()
              }} /> */}
              <TableButton title="รายละเอียด" icon={faFile} onClick={() => {
                viewDebtReport(objData)
              }} />
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => viewDebtReport(objData)}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  }

  return (
    <Box>
      <HeaderCard text={t("TRACK_BILL.HEADER.TRACK_BILL")} />
      {roleId === 4 && (

        <Boxconteiner>
          <Row>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <InputTextField
                placeholder={t("TRACK_BILL.HEADER.SEARCH")}
                label={t("PREFIX.INPUT.SEARCH")}
                value={search}
                style={{ marginRight: "24px" }}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={zoneOption || []}
                getOptionLabel={(option: any) => option.zoneName}
                onChange={(e: any, val: any) => {
                  setZoneId(val?.zoneId)
                  setStallOption(val?.stall)
                }}
                labelId="zone-name"
                placeholder={t("TRACK_BILL.HEADER.SEARCH_ZONE")}
                value={zoneOption?.find((e: any) => e.zoneId === zoneId)}
              />
            </Col>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={stallOption || []}
                getOptionLabel={(option: any) => option.stallName}
                onChange={(e: any, val: any) => {
                  setStallId(val?.stallId)
                  setPage(1)
                }}
                labelId="stallType-name"
                placeholder={t("TRACK_BILL.HEADER.SEARCH_STALL")}
                value={stallOption?.find((e: any) => e.stallId === stallId)}
              />
            </Col>

            <Col className="my-2" lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={stallTypeOption || []}
                getOptionLabel={(option: any) => option.stallTypeNameTh}
                onChange={(e: any, val: any) => {
                  setStallId(val?.stallTypeId)
                  setPage(1)
                }}
                labelId="rental"
                placeholder={t("TRACK_BILL.HEADER.TYPE_STALL")}
                value={stallTypeOption?.find((e: any) => e.stallTypeId === stallTypeId)}
              />
            </Col>
          </Row>


          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={invoiceData && invoiceData.map((d: any, i: number) => {
              return renderData(d, i);
            })}
            onSort={() => { }}
            setPageLimit={(value: number) => setPageLimit(value)}
            setPage={(value: number) => setPage(value)}
            tableMinWidth={1400}
          />
        </Boxconteiner>

      )}
      {roleId !== 4 && (

        <Boxconteiner>
          <Row>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <InputTextField
                placeholder={t("TRACK_BILL.HEADER.SEARCH")}
                label={t("PREFIX.INPUT.SEARCH")}
                value={search}
                style={{ marginRight: "24px" }}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={zoneOption || []}
                getOptionLabel={(option: any) => option.zoneName}
                onChange={(e: any, val: any) => {
                  setZoneId(val?.zoneId)
                  setStallOption(val?.stall)
                }}
                labelId="zone-name"
                placeholder={t("TRACK_BILL.HEADER.SEARCH_ZONE")}
                value={zoneOption.find((e: any) => e.zoneId === zoneId)}
              />

            </Col>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={stallOption || []}
                getOptionLabel={(option: any) => option.stallName}
                onChange={(e: any, val: any) => {
                  setStallId(val?.stallId)
                  setPage(1)
                }}
                labelId="stallType-name"
                placeholder={t("TRACK_BILL.HEADER.SEARCH_STALL")}
                value={stallOption?.find((e: any) => e.stallId === stallId)}
              />
            </Col>
            <Col className="my-2" lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={stallTypeOption || []}
                getOptionLabel={(option: any) => option.stallTypeNameTh}
                onChange={(e: any, val: any) => {
                  setStallId(val?.stallTypeId)
                  setPage(1)
                }}
                labelId="rental"
                placeholder={t("TRACK_BILL.HEADER.TYPE_STALL")}
                value={stallTypeOption?.find((e: any) => e.stallTypeId === stallTypeId)}
              />
            </Col>
            {/* <Col className="my-2" lg={4} md={6} xs={12}></Col> */}
            {!isUser && (
              <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  options={leasesTypeData || []}
                  value={leasesTypeData
                    ? leasesTypeData?.find(
                      (data: any) => data?.leasesTypeId === leasesTypeId
                    )
                    : null}
                  onInputChange={(_, value) => {
                    debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                  }}
                  placeholder={t("ประเภทผู้ใช้งาน")}
                  getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                  labelId="leasesType"
                  onChange={(_, newValue) => {
                    setLeasesTypeId(newValue?.leasesTypeId);
                  }}
                />
              </Col>
            )}
          </Row>

          <TableCustom
            headCells={headCellsAdmin}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={invoiceData && invoiceData.map((d: any, i: number) => {
              return renderDataAdmin(d, i);
            })}
            onSort={() => { }}
            setPageLimit={(value: number) => setPageLimit(value)}
            setPage={(value: number) => setPage(value)}
            tableMinWidth={1400}
          />
        </Boxconteiner>

      )}

    </Box>
  );
}
