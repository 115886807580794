import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";
import PopupStall from "../market-map/popup/popupStall";
import { swalActive } from "../../../component/notification/swal";
import { notiError, notiSuccess } from "../../../component/notification/notifications";

/** API, UTIL */
import StallApi, { IMeterNumber, IStall } from "../../../api/stall/stall.api";
import StallTypeApi from "../../../api/setting/stall/stall.api";
import ZoneApi, { IZone, paramsFindAll } from "../../../api/zone/zone.api";
import { dateToView } from "../../../utils/date.utils";



/** STYLE, CONSTANT */
import * as Style from './stallReport.style'
import { colors } from "../../../constants/colors";
import { getBranch } from "../../../utils/app.utils";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { useDispatch } from "react-redux";

import {
  isCreate,
  isDelete,
  isUpdate,
  isView,
} from "../../../utils/permission.utils";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};


const initStateErrorMessage = {

};

export default function StallReport() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [stallData, getStallData] = useState<any[]>([])
  const [stallName, setStallName] = useState<string>("")
  const [x, setX] = useState(Number);
  const [y, setY] = useState(Number);
  const [width, setWidth] = useState(Number);
  const [height, setHeight] = useState(Number);
  const [realWidth, setRealWidth] = useState(Number);
  const [realHeight, setRealHeight] = useState(Number);
  const [color, setColor] = useState<string>("")
  const [stallTypeId, setStallTypeId] = useState<number>(0);
  const [stallStatus, setStallStatus] = useState<string>("OPEN");
  const [status, setStatus] = useState<string>("OPEN");
  const [stallId, setStallId] = useState<number>(0);
  const [meterNumber, setMeterNumber] = useState<any[]>([{
    meterNumber: "",
    meterType: ""
  }])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(25)
  const [search, setSearch] = useState<string>("");
  const branchId: number = Number(getBranch() || 1);
  const [stallType, setStallType] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(0);
  const [popupStall, setPopupStall] = useState<boolean>(false);
  const [mapData, setMapData] = useState<IZone[]>([]);
  const [dataZone, setDataZone] = useState<IZone | any>({});
  const [dataStall, setDataStall] = useState<any>({});
  const [rowCount, setRowCount] = useState<number>();


  const loadDataStall = useCallback(async () => {
    const condition: any = {
      page: page,
      pageLimit: pageLimit,
      search: search || "",
      branchId,
      ...(stallTypeId ? { stallTypeId: stallTypeId } : undefined),
    }
    const res = await StallApi.findAll(condition)
    if (res.status === 200) {
      getStallData(res.data)
      setRowCount(res.headers["total"]);
    }
  }, [page, pageLimit, search])

  useEffect(() => {
    loadDataStall()
  }, [loadDataStall])

  const loadStallType = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      sortBy: "modifiedDate",
      sortType: "ASC",
      status: "ACTIVE",
    };

    const res = await StallTypeApi.findAll(condition);
    if (res.status === 200) {
      setStallType(res.data);
    }
  }, []);

  useEffect(() => {
    loadStallType();
  }, [loadStallType]);

  const resetForm = () => {
    setStallTypeId(0)
    setStallName("")
    setX(0)
    setY(0)
    setWidth(0)
    setHeight(0)
    setRealWidth(0)
    setRealHeight(0)
    setColor("")
    setStatus("")
    clearErrorMessage()
  }

  const showPopupStall = (
    obj: any,
    option: "EDIT",
    no?: number
  ) => {
    setDataStall({
      ...obj,
      ...(no ? { noStall: no - 1 } : undefined),
      isEdit: option === "EDIT",
    });
    setPopupStall(true);
    dispatch(showModal());
  };

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage);
  };


  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    { id: "date", disablePadding: false, label: t("วันที่เอกสาร") },
    { id: "zoneName", disablePadding: false, align: 'center', label: t("โซนแผงค้า") },
    { id: "stall", disablePadding: false, align: 'center', label: t("แผงค้า") },
    { id: "stall", disablePadding: false, align: 'center', label: t("เลขแผงค้า") },
    { id: "stallType", disablePadding: false, align: 'left', label: t("ประเภท") },
    { id: "status", disablePadding: false, label: t("สถานะ") },
    { id: "updateBy", disablePadding: false, align: 'center', label: t("ผู้บันทึก") },
    { id: "action", disablePadding: false, align: 'center', label: t("Action") },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const { stallId, createDate, zone, stallType, stallName,stallNumber, modifiedBy, modifiedDate, status } =
      objData;

    const objRenderData = {
      key: stallId,
      id: stallId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "left", label: dateToView(createDate) },
        { option: "TEXT", align: "center", label: zone?.zoneName || "-" },
        { option: "TEXT", align: "center", label: stallName || "-" },
        { option: "TEXT", align: "center", label: stallNumber || "-" },
        { option: "TEXT", align: "left", label: stallType.stallTypeNameTh || "-" },
        { option: "TEXT", align: "center", label: t(`STALL_REPORT.STATUS.${status}`) || "-" },
        {
          option: "UPDATE_BY", align: "center", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        {
          option: "ACTION",
          align: "center",
          label: "action",
          style: { color: colors.themeMainColor },
          values: [
            // {
            //   option: "PRINT",
            //   label: t(`ดูรายละเอียด`),
            // },
            {
              option: "EDIT",
              label: t(`แก้ไขแผงค้า`),
              disabled: permissions?.isUpdate.disabled
            },
            { option: 'DIVIDER', label: '', disabled: false },
            {
              option: "DELETE",
              label: t(`ลบแผงค้า`),
              disabled: status === 'CONTRACT' || permissions?.isDelete.disabled
            },
          ],
        },
      ],
    };
    return (<TableRowCommon {...objRenderData}
      onprint={() => { }}
      ondelete={() => onDelete(objRenderData.obj)}
      onedit={() => showPopupStall(objRenderData.obj, "EDIT", no)}
    />
    )
  };

  const onDelete = (data: any) => {
    const { stallId, stallName, stallType } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('STALL_REPORT.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('STALL_REPORT.ALERT.CONFIRM_DELETE', {
        stallName: stallName, stallType: stallType.stallTypeName ? ' ' + stallType.stallTypeName + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          StallApi.remove(stallId)
            .then((res) => {
              notiSuccess(t('STALL_REPORT.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadDataStall()
            })
            .catch((e) => {
              notiError(t('STALL_REPORT.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }
  const submitEdit = (data: any) => {
    const { stallId, stallName, stallNumber, zoneId, branchId, x, y, width, height, realWidth, realHeight, color, status, stallTypeId } = data;
    const { meterNumber } = data;

    dispatch(submitModal())
    StallApi.update(stallId, {
      zoneId, stallName, stallNumber, branchId, x, y, width, height, realWidth, realHeight, color, status, stallTypeId,

      meterNumber: meterNumber ? meterNumber.map((meter: any) => {
        const payloadMeter: IMeterNumber = {
          meterNumber: meter.meterNumber,
          meterType: meter.meterType
        };
        return payloadMeter;
      })
        : [],
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`STALL_REPORT.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          // loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`STALL_REPORT.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`STALL_REPORT.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  return (
    <>
      <HeaderCard text={t("STALL_REPORT.HEADER")} />
      <Style.MainContainer>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={3} md={6} xs={12}>
              <InputTextField label="ค้นหา" value={search}
                onchange={(e: any) => setSearch(e.target.value)} />
            </Grid>
            {/* <Grid item lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => { }}
                placeholder={t('DEPOSIT.FILTER.STALL.SEARCH')}
                labelId="stall"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => { }}
                placeholder={t('DEPOSIT.FILTER.STALL.SEARCH')}
                labelId="stall"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
              <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => { }}
                placeholder={t('DEPOSIT.FILTER.STATUS.SEARCH')}
                labelId="zone"
              />
            </Grid> */}
          </Grid>
        </Box>
        <Style.TableContainer>
          <TableCustom
            headCells={headCells}
            customScroll={true}
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={rowCount}
            rowsData={stallData && stallData.map((d: any, i: any) => {
              return renderData(d, i);
            })}
            onSort={() => { }}
            setPageLimit={(value: number) => setPageLimit(value)}
            setPage={(value: number) => setPage(value)}
            tableMinWidth={1024}
          />
        </Style.TableContainer>
      </Style.MainContainer>
      {popupStall && (
        <PopupStall
          data={dataStall}
          zone={dataZone}
          stallType={stallType}
          isShow={popupStall}
          setIsShow={(show: boolean) => setPopupStall(show)}
          onSubmit={(data: any) => submitEdit(data)}
        />
      )}
    </>
  );
}
