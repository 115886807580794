export default function Meatball() {
  return (
    <svg data-name="Component 100 – 1125" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path data-name="Rectangle 3798" style={{ fill: 'none' }} d="M0 0h24v24H0z" />
      <g data-name="Icon feather-more-vertical">
        <path data-name="Path 5758" d="M1.382.691A.691.691 0 1 1 .691 0a.691.691 0 0 1 .691.691z" transform="rotate(90 .609 11.61)" style={{ stroke: '#29302e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 3, fill: 'none' }} />
        <path data-name="Path 5759" d="M1.382.691A.691.691 0 1 1 .691 0a.691.691 0 0 1 .691.691z" transform="rotate(90 3.027 14.028)" style={{ stroke: '#29302e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 3, fill: 'none' }} />
        <path data-name="Path 5760" d="M1.382.691A.691.691 0 1 1 .691 0a.691.691 0 0 1 .691.691z" transform="rotate(90 -1.81 9.191)" style={{ stroke: '#29302e', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 3, fill: 'none' }} />
      </g>
    </svg>
  )
}
