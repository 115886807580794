import { menuFirsts } from "../../constants/menu"
import TranferApporve from "../../pages/transferApporve/tranferApporve"


const routeTransferApporve = [
    {
      ...menuFirsts.TRANFER_APPOVE,
      key: menuFirsts.TRANFER_APPOVE.key,
      name: menuFirsts.TRANFER_APPOVE.name,
      textName: menuFirsts.TRANFER_APPOVE.textName,
      active: false,
      appBar: true,
      component: TranferApporve
    },
  ]
  
  export default routeTransferApporve