import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { colors } from '../../constants/colors'

type PropsType = {
    disabled?: boolean,
    onClick?: () => void,
    icon: any,
    title?: string,
}

export default function TableButton(props: PropsType) {
    const { disabled, onClick, icon, title } = props

    return (
        <Tooltip title={title}>
            <IconButton disabled={disabled} onClick={onClick}>
                <FontAwesomeIcon
                    icon={icon}
                    style={{
                        fontSize: "20px",
                        width: "22px",
                        color: disabled ? colors.gray : colors.themeMainColor
                    }}
                />
            </IconButton>
        </Tooltip>
    )
}
