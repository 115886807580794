import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, MenuItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import _, { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  handleShow,
  resetModal,
  showModal,
} from "../../../../app/slice/modal.slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPenSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import HeaderCard from "../../../../component/cardCustom/headerCard";

/** API, UTIL */
import { numberFormat } from "../../../../utils/common";
import { dateToView } from "../../../../utils/date.utils";
import LeasesTypeApi from "../../../../api/setting/leases/leases.api";
import StallApi from "../../../../api/stall/stall.api";
/** COMPONENT */
import TableRowCommon from "../../../../component/table/TableRowCommon";
import TableCustom from "../../../../component/table/tableCustom";
import InputTextField from "../../../../component/input/inputTextField";
import ButtonCustom from "../../../../component/button/buttonCustom";
import Map from "../../../../component/marketMap/map";
import PopupZone from "../popup/popupZone";
import FilterSelect from "../../../../component/select/filterSelect";
import PopupStall from "../popup/popupStall";
import ViewPopupStall from "../popup/popupViewStall";
import PopupDowloadQr from "../popup/popupDowloadQr";
/** STYLE, CONSTANT */
import * as UseStyle from "../marketMap.style";
import { colors } from "../../../../constants/colors";
import StallTypeApi from "../../../../api/setting/stall/stall.api";
import { getBranch } from "../../../../utils/app.utils";
import ZoneApi, {
  IMeterNumber,
  IStall,
  IZone,
  paramsFindAll,
} from "../../../../api/zone/zone.api";
import {
  swalDelete,
  swalError,
  swalSuccess,
  swalWarning,
} from "../../../../component/notification/swal";
import {
  notiError,
  notiSuccess,
} from "../../../../component/notification/notifications";
import {
  isCreate,
  isDelete,
  isUpdate,
  isView,
} from "../../../../utils/permission.utils";
import { icons } from "../../../../constants/images";
import Loading from "../../../../component/loading/loading";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function Admin() {
  const dispatch = useDispatch();
  const modal: boolean = useSelector(handleShow);
  const { t } = useTranslation();

  const branchId: number = Number(getBranch() || 1);

  const [mapData, setMapData] = useState<IZone[]>([]);
  const [status, setStatus] = useState<string>("ALL");
  const [search, setSearch] = useState<string>("");
  const [leasesType, setLeasesType] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(0);

  const [stallType, setStallType] = useState<any[]>([]);

  const [dataZone, setDataZone] = useState<IZone | any>({});
  const [dataStall, setDataStall] = useState<any>({});

  const [popupZone, setPopupZone] = useState<boolean>(false);
  const [popupStall, setPopupStall] = useState<boolean>(false);
  const [popupQrCode, setPopupQrCode] = useState<boolean>(false);
  const [popupQrCodeType, setPopupQrCodeType] = useState<string>('WATER');
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [popupViewStall, setPopupViewStall] = useState<boolean>(false)
  // const [popupElectricity, setPopupElectricity] = useState<boolean>(false)
  // const [popupWater, setPopupWater] = useState<boolean>(false)
  const [electricityData, setElectricityData] = useState<any>()
  const [waterData, setWaterData] = useState<any>()
  const [meterNumber, setMeterNumber] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [editZone, setEditZone] = useState<boolean>(false);

  useEffect(() => {
    // console.log(dataZone)
  }, [dataZone]);
  const loadData = useCallback(async () => {
    setDataZone({});
    setDataStall({});
    setLoading(true)
    let condition: paramsFindAll = {
      page: page,
      pageLimit: 4000,
      search: search || "",
      branchId,
      ...(leasesTypeId ? { leasesType: leasesTypeId } : undefined),
      ...{ status: status === 'ALL' ? '' : status }
    };
    // if (search) condition = { ...condition, search: search };
    // if (leasesTypeId) condition = { ...condition, leasesTypeId: leasesTypeId };
    // const res = await StallApi.findAll(condition);
    const res = await ZoneApi.findAll(condition);
    if (res.status === 200) {
      setMapData(res.data);
      setLoading(false)
    } else {
      setMapData([]);
      setLoading(false)
    }
  }, [page, pageLimit]);

  const handleChangeStatus = (value: any) => setLeasesTypeId(value);

  useEffect(() => {
    loadData();
  }, [search, leasesTypeId, status]);

  const loadFilter = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      sortBy: "modifiedDate",
      sortType: "ASC",
      status: "ACTIVE",
    };

    const res = await LeasesTypeApi.findAll(condition);
    if (res.status === 200) {
      setLeasesType(res.data);
    }
  }, []);

  useEffect(() => {
    loadFilter();
  }, [loadFilter]);

  const loadStallType = useCallback(async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      sortBy: "modifiedDate",
      sortType: "ASC",
      status: "ACTIVE",
    };

    const res = await StallTypeApi.findAll(condition);
    if (res.status === 200) {
      setStallType(res.data);
    }
  }, []);

  useEffect(() => {
    loadStallType();
  }, [loadStallType]);

  const showPopupZone = (type: "ADD" | "EDIT" | "DELETE") => {
    switch (type) {
      case "ADD":
        setDataZone({});
        break;
      case "EDIT":
        setDataZone({ ...dataZone, isEdit: true });
        break;
      case "DELETE":
        onDeleteZone();
        break;
    }
    if (["ADD", "EDIT"].includes(type)) {
      setPopupZone(true);
      setPopupStall(false);
      dispatch(showModal());
    }
  };

  const showPopupStall = (
    obj: any,
    option: "EDIT" | "ADD" | "DELETE",
    no?: number
  ) => {
    setDataStall({
      ...obj,
      ...(no ? { noStall: no - 1 } : undefined),
      isEdit: option === "EDIT",
    });
    setElectricityData(obj?.meterNumber?.find((i: any) => i.meterType === "ELECTRICITY")?.qrCode)
    setWaterData(obj?.meterNumber?.find((i: any) => i.meterType === "WATER")?.qrCode)
    setPopupStall(true);
    setPopupZone(false);
    dispatch(showModal());
  };

  const showPopupViewStall = (
    obj: any,
    no?: number
  ) => {
    setDataStall({
      ...obj,
      ...(no ? { noStall: no - 1 } : undefined),

    });
    setElectricityData(obj?.meterNumber?.find((i: any) => i.meterType === "ELECTRICITY")?.qrCode)
    setWaterData(obj?.meterNumber?.find((i: any) => i.meterType === "WATER")?.qrCode)
    setPopupStall(false);
    setPopupZone(false);
    setPopupViewStall(true)
    dispatch(showModal());
  };

  useEffect(() => {
    if (!modal) {
      setPopupZone(false);
      dispatch(resetModal());
    }
  }, [dispatch, modal]);

  const updateMap = (
    valZone: any,
    valStall: any,
    d: any,
    ref: any,
    position: any,
    index: number,
    indexStall: any
  ) => {
    if (indexStall >= 0 && indexStall != null) {
      setMapData(
        mapData.map((zone: IZone) => {
          if (zone === mapData[index]) {
            let stall = zone?.stall || [];
            if (zone?.stall) {
              stall = zone.stall.map((st, i) => {
                if (i === indexStall) {
                  st = {
                    ...st,
                    x: position ? position.x : d.x,
                    y: position ? position.y : d.y,
                    width: ref ? ref.offsetWidth : valStall.width,
                    height: ref ? ref.offsetHeight : valStall.height,
                  };
                }
                return st;
              });
            }
            zone = {
              ...zone,
              stall: stall,
            };
          }
          console.log(zone)
          return zone;
        })
      );
    } else {
      let newArr = [...mapData];
      newArr[index] = {
        ...newArr[index],
        x: position ? position.x : d.x,
        y: position ? position.y : d.y,
        width: ref ? ref.offsetWidth : valZone.width,
        height: ref ? ref.offsetHeight : valZone.height,
        isChange: true,
      };
      setDataZone(newArr[index]);
      setMapData(newArr);
    }
  };

  const zone = (dataZoneFrom: any) => {
    if (dataZoneFrom) {
      console.log(dataZoneFrom)
      let arrStall: IStall[] = [];
      const width = 48.0;
      const height = 32.0;
      let itemX = 48;
      let itemY = 32;
      let countItemX = 1;
      let countItemY = 1;
      let stall = dataZoneFrom.vertical * dataZoneFrom.horizontal;
      for (let i = 1; i <= stall; i++) {
        const data: IStall = {
          stallId: null,
          // stallName: "A" + i,
          stallName: dataZoneFrom.stallName || '',
          // stallNumber: dataZoneFrom.stallNumber || '',
          stallNumber: "A" + i,
          x: itemX,
          y: itemY,
          width: width,
          height: height,
          color: colors.themeSecondColor,
          realWidth: dataZoneFrom.widthStall || null,
          realHeight: dataZoneFrom.heightStall || null,
          zoneId: null,
          stallTypeId: dataZoneFrom.stallTypeId || null,
          meterNumber: [],
          status: "BLANK",
        };
        arrStall.push(data);
        if (countItemX === dataZoneFrom.horizontal) {
          itemX = width;
          countItemY++;
          itemY = height * countItemY;
          countItemX = 1;
        } else {
          itemX += width;
          countItemX++;
        }
      }

      const tempZone: IZone = {
        zoneName: dataZoneFrom.nameZone,
        x: 0,
        y: 0,
        width: (width * dataZoneFrom?.horizontal) + 70,
        height: (height * dataZoneFrom?.vertical) + 70,
        color: colors.themeSecondColor,
        branchId: Number(branchId),
        realWidth: dataZoneFrom.widthZone,
        realHeight: dataZoneFrom.heightZone,
        stall: dataZone.isEdit === true ? dataZone.stall : arrStall,
        isChange: true,
      };

      if (dataZone.isEdit === true) {
        const index = _.findIndex(
          mapData,
          (md: IZone) => md.zoneName === dataZone.zoneName
        );
        updateMapDataWithUpdateZone(
          {
            ...dataZone,
            ...{
              zoneName: dataZoneFrom.nameZone,
              realWidth: dataZoneFrom.widthZone,
              realHeight: dataZoneFrom.heightZone,
              isChange: true,
            },
          },
          index
        );
      } else {
        setMapData([...mapData, tempZone]);
      }
      setDataZone(tempZone);
    }
  };

  console.log(dataZone.stall);
  const stall = (dataStall: IStall) => {
    const arrStall: IStall[] = dataZone.stall.map(
      (st: IStall, index: number) => {
        if (index === dataStall.noStall) {
          st = dataStall;
        }
        return st;
      }
    );
    if (dataStall.isEdit !== true) {
      arrStall.push({
        ...dataStall,
        stallId: null,
        x: 30.0,
        y: 30.0,
        width: 40.0,
        height: 40.0,
        color: colors.themeSecondColor,
        status: "BLANK",
        zoneId: dataZone.zoneId,
      });
    }
    const tempDataZone: IZone = {
      ...dataZone,
      stall: arrStall,
    };
    setDataZone(tempDataZone);
    updateMapDataWithUpdateZone(tempDataZone);
  };

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("MARKET_MAP.HEADCELL.ZONE"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("เลขแผงค้า"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("MARKET_MAP.HEADCELL.STALL"),
    },

    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("ประเภทแผงค้า"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "center",
      label: t("สถานะแผงค้า"),
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("MARKET_MAP.HEADCELL.STATUS"),
    },
    {
      id: "rental",
      disablePadding: false,
      align: "center",
      label: t("MARKET_MAP.HEADCELL.ACTION"),
    },

    // {
    //   id: "empty",
    //   disablePadding: false,
    //   align: "center",
    //   label: t(""),
    // },
  ];

  const handleDelete = async (objData: any, no: number) => {
    swalDelete(
      t("ลบแผงค้า"),
      `คุณต้องการแผงค้า ${objData.stallName} ใช่หรือไม่`,
      async (confirm: boolean) => {
        if (confirm === true) {
          // if (dataZone.zoneId) {
          const tempDataZone = {
            ...dataZone,
            stall: dataZone.stall.filter((_: any, i: any) => i !== no - 1),
          };
          setDataZone(tempDataZone);
          updateMapDataWithUpdateZone(tempDataZone);
          // }
        }
      }
    );
  };

  const renderDataAdmin = (objData: any, no: any) => {
    no = no + 1;
    const { stallName, stallNumber, stallTypeId, realWidth, realHeight, status } = objData;
    const whatStatus =
      stallTypeId !== null && stallName != '' && stallNumber !== '' && realWidth !== null && realHeight !== null
        ? "ข้อมูลครบถ้วน"
        : "ข้อมูลไม่ครบถ้วน";
    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
          width: 80,
        },
        { option: "TEXT", align: "center", label: dataZone?.zoneName || "" },
        // { option: "TEXT", align: "center", label: createZone[0].zoneName },
        { option: "TEXT", align: "center", label: stallNumber || "-" },
        { option: "TEXT", align: "center", label: stallName || "-" },
        {
          option: "TEXT", align: "center", label:
            stallTypeId ? `${_.find(stallType, (p: any) => p.stallTypeId === stallTypeId)?.stallTypeNameTh}` : "-"
        },
        { option: "TEXT", align: "center", label: `${t(`MARKET_MAP.FILTER.OPTION.${status}`)}` || "-" },
        {
          option: "TEXT",
          align: "center",
          style: {
            color:
              whatStatus === "ข้อมูลครบถ้วน"
                ? colors.green
                : colors.danger,
          },
          label: whatStatus,
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-between mx-auto w-50">
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPenSquare}
                  style={{
                    cursor: (status === "BLANK" || status === "NON_INFO") ? "pointer" : 'none'
                    , color: (status === "BLANK" || status === "NON_INFO") ? colors.themeSecondColor : colors.gray
                  }}
                  onClick={() => {
                    if (status === "NON_INFO" || status === "BLANK") {
                      showPopupStall(objData, "EDIT", no)
                    }
                  }}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeSecondColor
                  }}
                  onClick={() => {
                    showPopupViewStall(objData, no)
                  }}
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    cursor: (status === "CONTRACT") ? "none" : 'pointer'
                    , color: (status === "CONTRACT") ? colors.gray : colors.danger
                  }}
                  onClick={() => {
                    if (status === "CONTRACT") {

                    } else { handleDelete(objData, no) }
                  }}
                />
              </div>
            </div>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const updateMapDataWithUpdateZone = (tempDataZone: any, index?: number) => {
    setMapData(
      mapData.map((cz, i) => {
        if (
          (cz.zoneName === tempDataZone.zoneName &&
            (cz.zoneId ? cz.zoneId === tempDataZone.zoneId : true)) ||
          index === i
        ) {
          cz = tempDataZone;
          cz.isChange = true;
        }
        return cz;
      })
    );
  };

  const onSave = async () => {
    setLoading(true)
    let flagSave = true;
    for (const data of _.filter(mapData, (md: IZone) => md.isChange === true)) {
      const notSave: boolean =
        _.filter(data.stall, (st: IStall) => st?.stallTypeId === null).length >
        0;
      if (notSave)
        return swalError(`ข้อมูลที่จะบันทึกไม่มีประเภทแผงค้า : โซน${data.zoneName}`);
      const payload: IZone = {
        zoneName: data.zoneName,
        branchId: Number(branchId),
        x: data.x,
        y: data.y,
        width: data.width,
        height: data.height,
        realWidth: data.realWidth,
        realHeight: data.realHeight,
        color: data.color,
        status: "ACTIVE",
        stall: data?.stall
          ? data?.stall.map((stall: any) => {
            const payloadStall: IStall = {
              stallName: stall.stallName,
              stallNumber: stall.stallNumber,
              x: Number(stall.x),
              y: Number(stall.y),
              width: Number(stall.width),
              height: Number(stall.height),
              realWidth: Number(stall.realWidth),
              realHeight: Number(stall.realHeight),
              color: stall.color,
              stallTypeId: Number(stall.stallTypeId),
              status: stall.stallName && stall.stallNumber && Number(stall.stallTypeId) && Number(stall.realWidth) && Number(stall.realHeight) ? stall.status !== 'NON_INFO' ? stall.status : "BLANK" : 'NON_INFO',
              meterNumber: stall?.meterNumber
                ? stall?.meterNumber.map((meter: any) => {
                  const payloadMeter: IMeterNumber = {
                    meterNumber: meter.meterNumber,
                    meterType: meter.meterType,
                  };
                  return payloadMeter;
                })
                : [],
            };
            return payloadStall;
          })
          : [],
      };
      if (data.zoneId) {
        const res = await ZoneApi.update(data.zoneId, payload);
        if (res.status !== 200) flagSave = false;
      } else {
        const res = await ZoneApi.create(payload);
        if (res.status !== 201) flagSave = false;
      }
      if (!flagSave) {
        setLoading(false)
        swalError(t("บันทึกไม่สำเร็จ"));
      }
    }
    if (flagSave) {
      setLoading(false)
      swalSuccess("บันทึกสำเร็จ");
      loadData();
    }
  };

  const onDeleteZone = async () => {
    if (dataZone) {
      if (dataZone.zoneId > 0) {
        const canDelete: boolean =
          _.filter(dataZone.stall, (s: IStall) => s.status === ("OPEN" || "RESERVE" || "CONTRACT" || "DELETED"))
            .length === 0
        if (!canDelete) return swalError(t("ไม่สามารถลบได้"));
      }
      swalDelete(
        t("ลบโซน"),
        `คุณต้องการลบโซน ${dataZone.zoneName} ใช่หรือไม่`,
        async (confirm: boolean) => {
          if (confirm === true) {
            if (dataZone.zoneId) {
              await ZoneApi.remove(dataZone.zoneId)
                .then((res) => {
                  notiSuccess(
                    t(`ลบโซน ${dataZone.zoneName} เรียบร้อย`),
                    "",
                    null,
                    null
                  );
                })
                .catch((e) => {
                  notiError(
                    t(`ลบโซน ${dataZone.zoneName} ไม่สำเร็จ`),
                    "",
                    null,
                    null
                  );
                });
            }
            setMapData([
              ..._.filter(
                mapData,
                (md: IZone) =>
                  !(
                    md.zoneId === dataZone.zoneId &&
                    md.zoneName === dataZone.zoneName
                  )
              ),
            ]);
            setDataZone({});
          }
        }
      );
    }
  };

  return (
    <UseStyle.MainContainer>
      <Card className="border-0">
        <div
          className={"mb-0 pl-xl-3 py-3"}
          style={{ backgroundColor: colors.themeSecondColor }}
        >
          <div className="d-flex justify-content-center">
            <div className="pr-3" style={{ width: 300 }}>
              <InputTextField
                label="ค้นหา"
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </div>
            <div className="pr-3" style={{ width: 300 }}>
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value;
                  handleChangeStatus(value);
                }}
                renderValue={() =>
                  `${t("ประเภท")}: ${_.find(
                    leasesType,
                    (p: any) => p.leasesTypeId === leasesTypeId
                  )?.xrayFilmTypeName || t("ทั้งหมด")
                  }`
                }
                label={t("MARKET_MAP.FILTER.LABEL_TYPE")}
                selectId="select-status"
                value={leasesTypeId}
                labelId="label-status"
                options={[
                  <MenuItem key="ALL" value={0}>
                    {t("STATUS.ALL")}
                  </MenuItem>,
                  _.map(leasesType, (props: any, i: number) => (
                    <MenuItem key={i} value={props.leasesTypeId}>
                      {props.leasesTypeNameTh}
                    </MenuItem>
                  )),
                ]}
                formControlStyle={{ maxWidth: "100% !important" }}
              />
            </div>
            <div className="pr-3" style={{ width: 300 }}>
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value;
                  if (value) {
                    setStatus(value)
                  }
                }}
                renderValue={() =>
                  `${t("STATUS.LABEL")}: ${t(`MARKET_MAP.FILTER.OPTION.${status}`)}`
                }
                label={t("STATUS.LABEL")}
                selectId="select-status"
                value={status}
                labelId="label-status"
                options={
                  [
                    <MenuItem key="ALL" value="ALL">
                      {t("MARKET_MAP.FILTER.OPTION.ALL")}
                    </MenuItem>,
                    <MenuItem key="1" value="OPEN">
                      {t("MARKET_MAP.FILTER.OPTION.OPEN")}
                    </MenuItem>,
                    <MenuItem key="2" value="BLANK">
                      {t("MARKET_MAP.FILTER.OPTION.BLANK")}
                    </MenuItem>,
                    <MenuItem key="3" value="RESERVE">
                      {t("MARKET_MAP.FILTER.OPTION.RESERVE")}
                    </MenuItem>,
                    <MenuItem key="4" value="CONTRACT">
                      {t("MARKET_MAP.FILTER.OPTION.CONTRACT")}
                    </MenuItem>,
                    <MenuItem key="5" value="NON_INFO">
                      {t("MARKET_MAP.FILTER.OPTION.NON_INFO")}
                    </MenuItem>,
                  ]}
              />
            </div >

            {editZone && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  bsPrefix="p-0"
                  className="btn-table-action text-black-50 shadow-none"
                >
                  <ButtonCustom
                    textButton={t("MARKET_MAP.BUTTON.MANAGE_STALL")}
                  />
                </Dropdown.Toggle>
                <DropdownMenu className="dropdown-table-action position-fixed">
                  {/* {permissions.isUpdate.disabled && (
                   <DropdownItem
                     disabled={true}
                     onClick={() => showPopupStall(null, "EDIT")}
                   >
                     {t("MARKET_MAP.BUTTON.DROPDOWN.EDIT_STALL")}
                   </DropdownItem>
                 )} */}
                  {/* {permissions.isCreate.disabled && ( */}
                  <DropdownItem
                    disabled={
                      permissions.isCreate.disabled || _.isEmpty(dataZone)
                    }
                    onClick={() => showPopupStall(null, "ADD")}
                  >
                    {t("MARKET_MAP.BUTTON.DROPDOWN.ADD_STALL")}
                  </DropdownItem>
                  {/* )} */}
                  {/* {permissions.isDelete.disabled && (
                   <DropdownItem
                     disabled={true}
                     onClick={() => showPopupStall(null, "DELETE")}
                   >
                     {t("MARKET_MAP.BUTTON.DROPDOWN.REMOVE_STALL")}
                   </DropdownItem>
                 )} */}
                </DropdownMenu>
              </Dropdown>
            ) || (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    bsPrefix="p-0"
                    className="btn-table-action text-black-50 shadow-none"
                  >
                    <ButtonCustom textButton="จัดการโซน" />
                  </Dropdown.Toggle>
                  <DropdownMenu className="dropdown-table-action position-fixed">
                    <DropdownItem
                      onClick={() => showPopupZone("EDIT")}
                      disabled={
                        permissions.isUpdate.disabled || _.isEmpty(dataZone)
                      }
                    >
                      {t("MARKET_MAP.BUTTON.DROPDOWN.EDIT_ZONE")}
                    </DropdownItem>
                    <DropdownItem
                      disabled={permissions.isCreate.disabled}
                      onClick={() => showPopupZone("ADD")}
                    >
                      {t("MARKET_MAP.BUTTON.DROPDOWN.ADD_ZONE")}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => showPopupZone("DELETE")}
                      disabled={
                        permissions.isDelete.disabled || _.isEmpty(dataZone)
                      }
                    >
                      {t("MARKET_MAP.BUTTON.DROPDOWN.REMOVE_ZONE")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}

          </div >
        </div >

        <Card.Body className="overflow-auto mb-3">
          <div className="text-center py-3 " style={{ fontSize: "18px" }}>
            {t("MARKET_MAP.TITLE")}
          </div>
          <Map
            mapData={mapData}
            updateMap={updateMap}
            dragGridZone={true}
            enableResizingZone={true}
            disableDraggingZone={false}
            dragGridStall={true}
            enableResizingStall={true}
            disableDraggingStall={false}
            onClickEditZone={(flag: boolean) => setEditZone(flag)}
          />
          {editZone && (
            <div className="my-5">
              <UseStyle.tableAdmin>
                <HeaderCard text={t("MARKET_MAP.HEADERCARD")} />
                {/* <div className="my-3">
                <Row>
                  <Col lg={3} md={6} xs={12}>
                    <InputTextField label="ค้นหา" type="search" />
                  </Col>
                  <Col lg={3} md={6} xs={12}>
                    <AutocompleteSelect
                      options={[]}
                      getOptionLabel={(option: any) => {}}
                      placeholder={t("MARKET_MAP.FILTER.SEARCH_ZONE")}
                      labelId="zone"
                    />
                  </Col>
                  <Col lg={3} md={6} xs={12}>
                    <AutocompleteSelect
                      options={[]}
                      getOptionLabel={(option: any) => {}}
                      placeholder={t("MARKET_MAP.FILTER.SEARCH_STALL")}
                      labelId="stall"
                    />
                  </Col>
                  <Col lg={3} md={6} xs={12} />
                </Row>
              </div> */}
                <div className="pt-2">
                  <TableCustom
                    headCells={headCellsAdmin}
                    customScroll
                    page={page}
                    pageLimit={pageLimit}
                    sortType={"ASC"}
                    sortBy=""
                    rowCount={dataZone?.stall?.length}
                    rowsData={
                      dataZone.stall?.length > 0
                        ? dataZone?.stall?.map((item: any, i: number) => {
                          if (i <= (pageLimit * page - 1) && i >= ((pageLimit * (page - 1))))
                            return renderDataAdmin(item, i);
                        })
                        : []
                    }
                    onSort={() => { }}
                    setPageLimit={(value: number) => setPageLimit(value)}
                    setPage={(value: number) => setPage(value)}
                    tableMinWidth={1000}
                  />
                </div>

              </UseStyle.tableAdmin>
            </div>
          )}
          <ButtonCustom
            variant="contained"
            // onClick={() => history.push(routeName.marketMap + "/submit-stall")}
            onClick={() => onSave()}
            textButton={t("MARKET_MAP.BUTTON.SAVE_MANAGE_ZONE")}
            btnStyle={{
              fontSize: "16px",
              width: "100%",
              padding: "8px 25px",
            }}
          />

          {popupZone && (
            <PopupZone
              data={dataZone}
              isShow={popupZone}
              stallType={stallType}
              setIsShow={(show: boolean) => setPopupZone(show)}
              onSubmit={(data: any) => zone(data)}
            />
          )}

          {popupStall && (
            <PopupStall
              data={dataStall}
              zone={dataZone}
              stallType={stallType}
              isShow={popupStall}
              setIsShow={(show: boolean) => setPopupStall(show)}
              onSubmit={(data: any) => stall(data)}
              onprintElectricity={() => {
                // setPopupElectricity(true)
                setPopupStall(false)
                setPopupQrCodeType('ELECTRICITY')
                setPopupQrCode(true)
              }}
              onprintWater={() => {
                // setPopupWater(true)
                setPopupStall(false)
                setPopupQrCodeType('WATER')
                setPopupQrCode(true)
              }}
            />
          )}
          {popupQrCode && (
            <PopupDowloadQr
              data={dataStall}
              zone={dataZone}
              qrcode={popupQrCodeType === 'ELECTRICITY' ? electricityData : waterData}
              isShow={popupQrCode}
              setIsShow={(show: boolean) => setPopupQrCode(show)}
              type={popupQrCodeType}
            />
          )}
          {/* {popupElectricity && (
            <PopupDowloadQr
              data={dataStall}
              qrcode={electricityData}
              zone={dataZone}
              stallType={stallType}
              isShow={popupStall}
              setIsShow={(show: boolean) => setPopupStall(show)}
              onclose={() => setPopupElectricity(false)}
            />
          )}

          {popupWater && (
            <PopupDowloadQr
              data={dataStall}
              qrcode={waterData}
              zone={dataZone}
              stallType={stallType}
              isShow={popupStall}
              setIsShow={(show: boolean) => setPopupStall(show)}
              onclose={() => setPopupWater(false)}
            />
          )} */}

          {popupViewStall && (
            <ViewPopupStall
              data={dataStall}
              zone={dataZone}
              stallType={stallType}
              isShow={popupStall}
              setIsShow={(show: boolean) => setPopupStall(show)}
              onclose={() => setPopupViewStall(false)}
              onprintElectricity={() => {
                // setPopupElectricity(true)
                setPopupViewStall(false)
                setPopupQrCodeType('ELECTRICITYƒ')
                setPopupQrCode(true)
              }}
              onprintWater={() => {
                // setPopupWater(true)
                setPopupViewStall(false)
                setPopupQrCodeType('WATER')
                setPopupQrCode(true)
              }}
            />
          )}
        </Card.Body>
      </Card >
      {loading && <Loading show={loading} type="fullLoading" />}
    </UseStyle.MainContainer >
  );
}
