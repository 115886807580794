import { menuFirsts } from "../../constants/menu"
import TrackBill from "../../pages/trackBill/trackBill"
import {TrackBillDetail} from "../../pages/trackBill/trackBillDetail"
import { routeName } from "../routers-name"

const routeTrackBill = [
    {
      ...menuFirsts.TRACK_BILL,
      key: menuFirsts.TRACK_BILL.key,
      name: menuFirsts.TRACK_BILL.name,
      textName: menuFirsts.TRACK_BILL.textName,
      active: false,
      appBar: true,
      component: TrackBill
    },
    {
      ...menuFirsts.TRACK_BILL,
      key: menuFirsts.TRACK_BILL.key,
      name: menuFirsts.TRACK_BILL.name,
      textName: menuFirsts.TRACK_BILL.textName,
      path: routeName.trackBill + '/list-payment',
      active: false,
      appBar: true,
      component: TrackBillDetail
    }
  ]
  
  export default routeTrackBill