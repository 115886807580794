import { styled } from '@mui/material/styles'
import { colors } from '../../constants/colors'
import { Box, Button, Typography } from '@mui/material'
import { Card } from 'react-bootstrap'

export const MainContainer = styled(Card)(({theme}) => ({
    width: '100%',
    height: '100%',
    overflow: 'auto',
}))

export const CalculationBox = styled(Box)(({ theme }) => ({
    width: '100% !important',
    borderRadius: 8,
    backgroundColor: colors.bgBase,
    padding:'20px',
    flexDirection: 'row',
    marginTop:24
}))

export const SummaryBox = styled(Box)(({theme}) => ({
    display:'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width:'100%',
    padding: 20
}))

export const IconsBackground = styled(Box)(({theme}) => ({
    height: '104px',
    width: '138px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '80%',
    marginRight:20
}))

export const BoxContent = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
}))

export const TotleText = styled(Typography)(({theme}) => ({
    fontWeight:400,
    fontSize: 14,
    color: colors.disabledLightGray
}))

export const Price = styled(Typography)(({theme}) => ({
    fontSize: 18,
    fontWeight:600,
    color:colors.red
}))

export const ChartContainer = styled(Box)(({theme}) => ({
    border: `1px solid ${colors.disabledLightGray}`,
    borderRadius: 8,
    marginTop: 16
}))

export const PiechartBox = styled(Box)(({theme}) => ({
    borderRadius: 20,
    border:`1px solid ${colors.disabledLightGray}`,
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginRight: 20
}))

export const PieChartContainer = styled(Box)(({theme}) => ({
    width:154,
    height:154,
}))

export const PercentText = styled(Typography)(({theme}) => ({
    fontSize:36,
    fontWeight: 700,
    marginLeft: 16
}))

export const LabelColor = styled(Box)(({theme}) => ({
    width:16,
    height: 8,
    borderRadius: 3,
    marginTop:4
}))

export const Label = styled(Typography)(({theme}) => ({
    fontSize:12,
    fontWeight: 500,
    marginLeft: 8
}))