import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { colors } from '../../../constants/colors'
import { Box, Button, Typography } from '@mui/material'

export const TypographyHead = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontFamily: 'kanit',
}))
export const NumberInTable = styled(Box)(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    width: 20,
    color: colors.white,
    borderRadius: 2
}))
export const Boxconteiner = styled(Box)(({ theme }) => ({
    paddingLeft: '45px',
    paddingRight: '45px',
}))
export const BoxTabSearch = styled(Box)(({ theme }) => ({
    display: "flex", marginTop: '24px', marginBottom: '24px'
}))
export const Boxupload = styled(Box)(({ theme }) => ({
    border: '1px solid #D9D9D9', 
    borderRadius: '8px', 
    display: 'flex', 
    justifyContent: 'center', 
    padding: '8px', 
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: "center",
    }
}))
export const Boxdash = styled(Box)(({ theme }) => ({
    border: "2px dashed #000",
    width: "80%",
    height: "auto",
    padding: "24px",
    display: 'flex',
    textAlign: "center",
    justifyContent: 'center',
    paddingTop: '24px',

}))
export const Typographymodal = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'kanit',
}))
export const ButtonInTable = styled(Button)(({ theme }) => ({
    backgroundColor: colors.green,
    borderRadius: 4,
    color: colors.white
}))
export const Boxcol = styled(Box)(({ theme }) => ({
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
}))

export const BoxTakePhoto = styled('div')(({theme}) => ({
    border: "2px dashed #000",
    width: "80%",
    height: "auto",
    padding: "24px",
    display: 'flex',
    textAlign: "center",
    justifyContent: 'center',
    paddingTop: '24px',
    flexDirection: 'column'
}))