import IconProps from "./iconProps"
import { colors } from "../../constants/colors"
export default function LeaseHold(props: IconProps) {
    return (
<svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_460_29421)">
        <path d="M55.6253 39.3825L47.8092 31.5663C47.5913 31.3343 47.3291 31.1485 47.038 31.0199C46.7469 30.8912 46.433 30.8224 46.1148 30.8174C45.7966 30.8125 45.4806 30.8715 45.1857 30.9911C44.8908 31.1106 44.6229 31.2882 44.3979 31.5134C44.173 31.7385 43.9956 32.0065 43.8762 32.3015C43.7569 32.5965 43.698 32.9125 43.7032 33.2307C43.7084 33.5489 43.7774 33.8628 43.9063 34.1538C44.0352 34.4448 44.2212 34.7069 44.4533 34.9246L49.8921 40.3728C46.9355 40.3796 44.0075 39.7947 41.2804 38.6526C38.5533 37.5105 36.0823 35.8344 34.0128 33.7228C33.783 33.5112 33.513 33.3479 33.2189 33.2426C32.9248 33.1373 32.6125 33.0922 32.3006 33.1099C31.9887 33.1276 31.6835 33.2079 31.4032 33.3458C31.1229 33.4837 30.8732 33.6765 30.6688 33.9128L30.638 33.9603C30.2387 34.4248 30.0343 35.0256 30.0675 35.6373C30.1006 36.2489 30.3689 36.824 30.8161 37.2426C33.3093 39.7641 36.2825 41.7605 39.5601 43.114C42.8377 44.4675 46.3532 45.1505 49.8992 45.1228L44.4533 50.5687C44.2052 50.7822 44.0038 51.0446 43.8619 51.3395C43.72 51.6345 43.6406 51.9556 43.6286 52.2827C43.6167 52.6098 43.6725 52.9359 43.7925 53.2404C43.9126 53.5449 44.0943 53.8213 44.3262 54.0522C44.5582 54.2832 44.8354 54.4637 45.1404 54.5825C45.4454 54.7012 45.7717 54.7556 46.0987 54.7423C46.4258 54.729 46.7465 54.6482 47.0409 54.505C47.3352 54.3619 47.5968 54.1594 47.8092 53.9103L55.6253 46.0942C56.5143 45.2036 57.0137 43.9967 57.0137 42.7383C57.0137 41.48 56.5143 40.273 55.6253 39.3825Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
        <path d="M49.8917 16.6239L44.453 22.0627C44.4067 22.1252 44.3639 22.1902 44.3247 22.2574C43.9415 22.7247 43.7524 23.3214 43.7967 23.9241C43.841 24.5269 44.1152 25.0895 44.5626 25.4958C45.0101 25.9021 45.5965 26.1208 46.2007 26.1069C46.8049 26.093 47.3806 25.8474 47.8088 25.4209L51.8606 21.3739L55.6273 17.6096C56.2642 16.9677 56.7046 16.1575 56.8969 15.274C57.0891 14.3905 57.0252 13.4705 56.7127 12.6221C56.482 11.9726 56.1105 11.3822 55.625 10.8931L47.8088 3.08644C47.592 2.85087 47.3298 2.66156 47.038 2.52991C46.7461 2.39825 46.4306 2.32697 46.1105 2.32033C45.7904 2.3137 45.4723 2.37186 45.1753 2.49131C44.8782 2.61077 44.6084 2.78905 44.382 3.01545C44.1556 3.24184 43.9773 3.51167 43.8578 3.80873C43.7384 4.10578 43.6802 4.42392 43.6869 4.74402C43.6935 5.06412 43.7648 5.37958 43.8964 5.67143C44.0281 5.96328 44.2174 6.2255 44.453 6.44232L49.8988 11.8858C38.183 11.9143 31.761 18.2366 25.9375 24.8866C20.589 18.8493 14.2501 12.8239 3.53177 11.9832L2.3894 11.8739C1.75951 11.8739 1.15542 12.1242 0.710025 12.5696C0.264626 13.015 0.0144043 13.6191 0.0144043 14.2489C0.0265646 14.875 0.280691 15.4721 0.72348 15.9149C1.16627 16.3577 1.76332 16.6118 2.3894 16.6239C2.5604 16.6239 3.07103 16.7023 3.07103 16.7023C12.324 17.4029 17.6345 22.6137 22.8001 28.4989C17.6654 34.3557 12.3905 39.6614 3.21353 40.2789L2.26353 40.3288C1.63836 40.3679 1.05432 40.6537 0.639874 41.1233C0.225431 41.593 0.0145387 42.2081 0.0535918 42.8333C0.0926448 43.4584 0.378444 44.0425 0.848116 44.4569C1.31779 44.8714 1.93286 45.0822 2.55803 45.0432C2.92853 45.0266 3.53177 45.0123 3.82865 44.9886C15.7511 44.0694 22.2183 36.4386 27.9847 29.7672C33.8082 23.0364 39.3087 16.6667 49.8917 16.6239Z"  fill={props.color || `${colors.themeMainColor || "currentColor"}`} />
    </g>
    <defs>
        <clipPath id="clip0_460_29421">
            <rect width="57" height="57" fill="white" />
        </clipPath>
    </defs>
</svg>
    )
}
