import { menuSeconds } from "../../constants/menu"
import DepositPay from "../../pages/stall/deposit-pay/depositPayt"

const routeDepositPay = [
  {
    ...menuSeconds.DEPOSIT_PAY,
    key: menuSeconds.DEPOSIT_PAY.key,
    name: menuSeconds.DEPOSIT_PAY.name,
    textName: menuSeconds.DEPOSIT_PAY.textName,
    active: false,
    appBar: true,
    component: DepositPay
  } ,
  ]
  
  export default routeDepositPay