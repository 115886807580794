import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../component/input/inputTextField";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../component/button/buttonCustom";
import HeaderCard from "../../component/cardCustom/headerCard";


//** STYLE */
import * as Style from "./payMentStyle";
import FilterSelect from "../../component/select/filterSelect";
import { icons, imgs } from "../../constants/images";
import { Size } from "../../component/dragAndDrop/resize";
import { width } from "@mui/system";
import { useLocation } from "react-router-dom";
import { dateToView } from "../../utils/date.utils";
import { numberFormat } from "../../utils/common";
import WaterApi from "../../api/setting/water/water.api";
import ElectricityApi from "../../api/setting/electricity/electricity.api";
import AnnounceApi from "../../api/home/announce.api";
import PaymentTypeApi from "../../api/setting/debttype/debttype.api";
interface Service {
  label: string;
  filterSelectOptions: any[];
  amountLabel: string;
  descriptionLabel: string;
}

interface PopupPaymentProps {
  zone?: string;
  stall?: string;
  name?: string;
  receipt?: string;
  date?: string;
  electricityType?: string;
  electricityRate?: number;
  electricityConsumption?: number;
  electricityBill?: number;
  electricitymeterimage?: string;
  amountElectricityBill?: number;
  waterType?: string;
  waterRate?: number;
  waterConsumption?: number;
  waterBill?: number;
  watermeterimage?: string;
  amountWaterBill?: number;
  leasesType?: string;
  rentalFee?: number;
  stallBill?: number;
  listPayment?: boolean;
}

export default function ListPayment(props: PopupPaymentProps) {
  const location: any = useLocation()
  const data: any = location?.state?.params?.data
  const check: any = location?.state?.params?.check

  const totalAmount = data?.invoiceDetail
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);

  const totalElectricity = data?.invoiceDetail
    .filter((item: any) => item.paymentServiceId === 1)
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);

  const totalWater = data?.invoiceDetail
    .filter((item: any) => item.paymentServiceId === 2)
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);

  const totalMulct = data?.invoiceDetail
    .filter((item: any) => item.paymentServiceId === 5)
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);

  const [listPayment, setListPayment] = useState<boolean>(check);
  const { t } = useTranslation();
  const [waterType, setWaterType] = useState<any[]>([]);
  const [electricityType, setElectricityType] = useState<any[]>([]);
  const [disCountData, setDiscountData] = useState<any[]>([])
  const [services, setServices] = useState<Service[]>([]);
  const [debtTypeData, setDebtTypeData] = useState<any[]>([])
  const loadDataPaymentType = useCallback(async () => {

    let condition: any = {
      page: 1,
      pageLimit: 50,
      status: "ACTIVE"
    }


    const res = await PaymentTypeApi.findAll(condition)

    if (res.status === 200) {
      setDebtTypeData(res.data)

    } else {
      setDebtTypeData([])
    }
  }, [])

  useEffect(() => {
    loadDataPaymentType()
  }, [loadDataPaymentType])
  const loadDiscount = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 1,
      newstypeId: 3,
      sortBy: "endDate",
      status: "APPROVE",
      sortType: "DESC"
    }

    const res = await AnnounceApi.findAll(condition)

    if (res.status === 200) {
      if (res.data[0]?.endDate < Date.now()) {
        setDiscountData([])
      } else {
        setDiscountData(res.data)
      }

    } else {
      setDiscountData([])
    }
  }

  useEffect(() => {
    loadDiscount()
  }, []);

  const getWaterType = async () => {
    const condition = {
      page: 1,
      pageLimit: 5,
      sortType: "ASC",
      status: "ACTIVE",
      utilitiesTypeId: 2,

    };
    await WaterApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setWaterType(res.data)
      } else {
        setWaterType([])
      }
    });
  };
  const getElectricityType = async () => {
    const condition = {
      page: 1,
      pageLimit: 5,
      sortType: "ASC",
      status: "ACTIVE",
      utilitiesTypeId: 1,

    };
    await ElectricityApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setElectricityType(res.data)
      } else {
        setElectricityType([])
      }
    });
  };

  useEffect(() => {
    getElectricityType()
    getWaterType()
  }, []);

  const handleServiceAdd = () => {
    const paymentListLabel = t("PAYBILL.REPORT.PAYMENT_LIST");
    const amountLabel = t("PAYBILL.REPORT.AMOUNT");
    const detailLabel = t("PAYBILL.REPORT.DETAIL");

    setServices((prevServices) => [
      ...prevServices,
      {
        label: paymentListLabel,
        filterSelectOptions: [],
        amountLabel: amountLabel,
        descriptionLabel: detailLabel,
      },
    ]);
  };

  const handleServiceDelete = (index: number) => {
    setServices((prevServices) => prevServices.filter((_, i) => i !== index));
  };

  return (
    <>

      <HeaderCard text={t("PAYBILL.TITLE")} />

      <Style.BoxMainContainer>
        <Style.BoxContentContainer>
          <Style.BoxHeader>
            <Row className="w-100">
              <Col lg={4} md={4} className="d-flex flex-row">
                <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.ZONE")}</Style.SubDetail>
                <Style.SubDetail className="mr-3">{data?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName || "-"}</Style.SubDetail>
              </Col>
              <Col lg={4} md={4} className="d-flex flex-row">
                <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.STALL")}</Style.SubDetail>
                <Style.SubDetail>{data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName || "-"}</Style.SubDetail>
              </Col>
              <Col lg={4} md={4} className="d-flex flex-row">
                <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.NAME")}</Style.SubDetail>
                <Style.SubDetail>{data?.leaseAgreement?.firstname + " " + data?.leaseAgreement?.lastname}</Style.SubDetail>
              </Col>
              <Col lg={4} md={4} className="d-flex flex-row">
                <Style.SubDetail className="mr-3">{t("PAYBILL.REPORT.RECEIPT_NUMBER")}</Style.SubDetail>
                <Style.SubDetail>{data?.invoiceNo}</Style.SubDetail>
              </Col>
              <Col lg={4} md={4} className="d-flex flex-row mt-md-2">
                <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.DOCUMENTDATE")}</Style.SubDetail>
                <Style.SubDetail>{dateToView(data?.createDate)}</Style.SubDetail>
              </Col>
            </Row>



            {/* <Col>
                <ButtonCustom
                  type="button"
                  textButton={t("PAYBILL.BUTTON.PRINT")}
                  endIcon={<img src={icons.print} />}
                  onClick={() => {}}
                 />             
              </Col> */}
          </Style.BoxHeader>

          <Divider className="w-100 mx-0" />

          <Style.BoxListPayment>
            {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 1) && <div>

              {data?.invoiceDetail?.filter((value: any) => value.paymentServiceId === 1).map((d: any, i: number) => {
                return (
                  <div>
                    <Row className="my-2">
                      <Col>
                        <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE_DETAILS")} {t("PAYBILL.REPORT.METER")} {i + 1}</Style.SubDetail>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3} className="d-flex flex-row ">
                        <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE")}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={6}>
                        <Style.SubDetail>{t("-")}</Style.SubDetail>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_RATE")}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                        <Style.SubDetail>{numberFormat(Number(electricityType[0]?.pricePerUnit))}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={4} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_CONSUMPTION")}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                        <Style.SubDetail>{d?.utilities?.utilitiesDetail?.number ? numberFormat(d?.utilities?.utilitiesDetail?.number || 0, 0) : 0}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={4} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                        <Style.SubDetail>{numberFormat(d?.amount) || 0.00}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={4} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    {d?.utilities?.utilitiesDetail?.fileUpload && <Row>
                      <Col lg={3} md={2} />
                      <Col lg={2} xs={12} md={12}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_ELECTRIC_METER")}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={12} md={12}>
                        <img src={process.env.REACT_APP_FILE_URL + d?.utilities?.utilitiesDetail?.fileUpload?.filePath
                          + d?.utilities?.utilitiesDetail?.fileUpload?.fileName} alt="" style={{ width: '100%', height: 200 }} />
                      </Col>
                    </Row>}
                  </div>
                )
              })}
              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 1).length !== 0 && <div>
                <Row className="my-3">
                  <Col lg={3} md={2} />
                  <Col lg={3} xs={7} md={3}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_ELECTRICITY_ฺBILL")}</Style.HeadMessage>
                  </Col>
                  <Col lg={2} xs={2} md={3}>
                    <Style.HeadMessage>{numberFormat(totalElectricity)}</Style.HeadMessage>
                  </Col>
                  <Col lg={3} xs={2} md={3}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                  </Col>
                </Row>

                <Divider className="w-100 mx-0" />
              </div>
              }
            </div>}

            {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2) && <div>
              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2).map((d: any, i: number) => {
                return (
                  <div>
                    <Row className="my-2">
                      <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE_DETAILS")} {t("PAYBILL.REPORT.METER")} {i + 1}</Style.SubDetail>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3} >
                        <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE")}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={6}>
                        <Style.SubDetail>{t("-")}</Style.SubDetail>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.WATER_RATE")}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                        <Style.SubDetail>{numberFormat(waterType[0]?.pricePerUnit)}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={4} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.WATER_CONSUMPTION")}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                      <Style.SubDetail>{d?.utilities?.utilitiesDetail?.number ? numberFormat(d?.utilities?.utilitiesDetail?.number || 0, 0) : 0}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={2} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                        <Style.SubDetail>{numberFormat(d?.amount) || 0.00}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={2} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    {d?.utilities?.utilitiesDetail?.fileUpload && <Row>
                      <Col lg={3} md={2} />
                      <Col lg={2} xs={12} md={12}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_WATER_METER")}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={12} md={12}>
                        <img src={process.env.REACT_APP_FILE_URL + d?.utilities?.utilitiesDetail?.fileUpload?.filePath
                          + d?.utilities?.utilitiesDetail?.fileUpload?.fileName} alt="" style={{ width: '100%', height: 200 }} />

                      </Col>
                    </Row>}
                  </div>
                )
              })}
              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2).length !== 0 && <div>
                <Row className="my-3">
                  <Col lg={3} md={2} />
                  <Col lg={3} xs={7} md={3}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_WATER_ฺBILL")}</Style.HeadMessage>
                  </Col>
                  <Col lg={2} xs={2} md={3}>
                    <Style.HeadMessage>{numberFormat(totalWater)}</Style.HeadMessage>
                  </Col>
                  <Col lg={3} xs={2} md={3}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                  </Col>
                </Row>
                <Divider className="w-100 mx-0" />
              </div>
              }
            </div>}

            {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3) && <div>
              <Row className="my-2">
                <Style.SubDetail>{t("PAYBILL.CREATE.STALL_DETAILS")}</Style.SubDetail>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={7} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.LEASES_TYPE")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={3} md={3}>
                  <Style.SubDetail>{data?.leasesType?.leasesTypeNameTh}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={7} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.STALL_RENTAL_FEE")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={2} md={3}>
                  <Style.SubDetail>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row className="my-3">
                <Col lg={3} md={2} />
                <Col lg={3} xs={7} md={4}>
                  <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_STALL_BILL")}</Style.HeadMessage>
                </Col>
                <Col lg={2} xs={2} md={2}>
                  <Style.HeadMessage>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))}</Style.HeadMessage>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                </Col>
              </Row>

              <Divider className="w-100 mx-0" />
            </div>}
            {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 5) && <div>
              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 5).map((d: any, i: number) => {
                return (
                  <div>
                    <Row className="my-2">
                      <Style.SubDetail>{t("รายละเอียด") + d?.paymentService?.paymentName} {i + 1}</Style.SubDetail>
                    </Row>

                    <Row>
                      <Col lg={3} md={2} />
                      <Col lg={3} xs={6} md={3}>
                        <Style.SubDetail>{d?.paymentService?.paymentName}</Style.SubDetail>
                      </Col>
                      <Col lg={2} xs={2} md={3}>
                        <Style.SubDetail>{numberFormat(d?.amount) || 0.00}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={2} md={3}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                      </Col>
                    </Row>

                    {d?.utilities?.utilitiesDetail?.fileUpload && <Row>
                      <Col lg={3} md={2} />
                      <Col lg={2} xs={12} md={12}>
                        <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_WATER_METER")}</Style.SubDetail>
                      </Col>
                      <Col lg={3} xs={12} md={12}>
                        <img src={process.env.REACT_APP_FILE_URL + d?.utilities?.utilitiesDetail?.fileUpload?.filePath
                          + d?.utilities?.utilitiesDetail?.fileUpload?.fileName} alt="" style={{ width: '100%', height: 200 }} />

                      </Col>
                    </Row>}
                  </div>
                )
              })}
              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 5).length !== 0 && <div>
                <Row className="my-3">
                  <Col lg={3} md={2} />
                  <Col lg={3} xs={7} md={3}>
                    <Style.HeadMessage>{t("จำนวนเงินค่าปรับทั้งหมด")}</Style.HeadMessage>
                  </Col>
                  <Col lg={2} xs={2} md={3}>
                    <Style.HeadMessage>{numberFormat(totalMulct)}</Style.HeadMessage>
                  </Col>
                  <Col lg={3} xs={2} md={3}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                  </Col>
                </Row>
                <Divider className="w-100 mx-0" />
              </div>
              }
            </div>}
          </Style.BoxListPayment>

          <div>
            <Row className="my-2">
              <Style.SubDetail>{t("PAYBILL.REPORT.PAYMENT_DETAILS")}</Style.SubDetail>
            </Row>

            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.SubDetail>{numberFormat(totalElectricity)}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.SubDetail>{numberFormat(totalWater)}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.STALL_BILL")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.SubDetail>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("ค่าปรับ")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.SubDetail>{numberFormat(totalMulct)}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.CREATE.OTHER_PAYMENT")}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.SubDetail>{t("-")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>

            {data?.invoiceDetail?.map((d: any) => d.invoiceDetailDiscount).some((e: any) => e === 0) && <Row>
              <Col lg={3} md={2} />
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t('PAYBILL.REPORT.DISCOUNT')}</Style.SubDetail>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.SubDetail>{t("-")}</Style.SubDetail>
              </Col>
              <Col lg={3} xs={4} md={3}>
                <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
              </Col>
            </Row>}

            {data?.invoiceDetail?.filter((value: any) => value.paymentServiceId === 1)?.filter((value: any) => value?.invoiceDetailDiscount.length !== 0)?.map((d: any, i: number) => {
              return (
                <div>
                  <Row>
                    <Col lg={3} md={2} />
                    <Col lg={3} xs={4} md={3}>
                      <Style.SubDetail>{t('PAYBILL.REPORT.DISCOUNT_ELECTRICT')}</Style.SubDetail>
                    </Col>
                    <Col lg={2} xs={4} md={3}>
                      <Style.SubDetail>{d?.invoiceDetailDiscount[0]?.amount}</Style.SubDetail>
                    </Col>
                    <Col lg={3} xs={4} md={3}>
                      <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                    </Col>
                  </Row>
                </div>
              )
            })}

            {data?.invoiceDetail?.filter((value: any) => value.paymentServiceId === 3)?.filter((value: any) => value?.invoiceDetailDiscount.length !== 0)?.map((d: any, i: number) => {
              return (
                <div>
                  <Row>
                    <Col lg={3} md={2} />
                    <Col lg={3} xs={4} md={3}>
                      <Style.SubDetail>{t('PAYBILL.REPORT.DISCOUNT_STALL')}</Style.SubDetail>
                    </Col>
                    <Col lg={2} xs={4} md={3}>
                      <Style.SubDetail>{d?.invoiceDetailDiscount[0]?.amount}</Style.SubDetail>
                    </Col>
                    <Col lg={3} xs={4} md={3}>
                      <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                    </Col>
                  </Row>
                </div>
              )
            })}

            {data?.invoiceDetail?.filter((value: any) => value.paymentServiceId === 2)?.filter((value: any) => value?.invoiceDetailDiscount.length !== 0)?.map((d: any, i: number) => {
              return (
                <div>
                  <Row>
                    <Col lg={3} md={2} />
                    <Col lg={3} xs={4} md={3}>
                      <Style.SubDetail>{t('PAYBILL.REPORT.DISCOUNT_WATER')}</Style.SubDetail>
                    </Col>
                    <Col lg={2} xs={4} md={3}>
                      <Style.SubDetail>{d?.invoiceDetailDiscount[0]?.amount}</Style.SubDetail>
                    </Col>
                    <Col lg={3} xs={4} md={3}>
                      <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                    </Col>
                  </Row>
                </div>
              )
            })}

            {/* <Row className="my-3">
              <Col lg={3} md={2} />
              <Col lg={3} xs={6} md={3}>
                <Style.HeadMessage>{t("PAYBILL.REPORT.TOTAL_AMOUNT")}</Style.HeadMessage>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.HeadMessage>{numberFormat(totalAmount)}</Style.HeadMessage>
              </Col>
              <Col lg={3} xs={2} md={3}>
                <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
              </Col>
            </Row> */}

            {/* <Row>
              <ButtonCustom
                variant="contained"
                onClick={() => {}}
                textButton={t("PAYBILL.BUTTON.PAYOFF_DEBT")}
                btnStyle={{
                  fontSize: "16px",
                  width: "100%",
                  padding: "8px 25px",
                }}
              />
            </Row> */}
          </div>



          <div>
            {/* <Row className="my-2">
              <Col lg={3} />
              <Col lg={3} xs={7} md={3}>
                <Style.ContentHeader>{t("PAYBILL.CREATE.OTHER_PAYMENT")}</Style.ContentHeader>
              </Col>
              <Col lg={3} md={6} xs={3} />
              <Col>
                <img className="ml-5" src={icons.add} onClick={() => handleServiceAdd()} />
              </Col>
            </Row> */}

            {/* {services.map((service, index) => (
              <div key={index}>
               
                <Style.BoxPayment>
                  <Col lg={3} xs={5} className="d-flex flex-row">                  
                    <Style.SubDetail className="mr-2 ml-2">{index+1}</Style.SubDetail>
                    <Style.SubDetail className="mr-2">{t(service.label)}</Style.SubDetail>
                    {service.filterSelectOptions && (
                    <Style.SubDetail>
                      <FilterSelect
                        onchange={() => {}}
                        renderValue={() => {}}
                        label={t("PAYBILL.REPORT.PAYMENT")}
                        style={{ width: "180px" }}
                        selectId={`select-${index}`}
                        labelId={`label-${index}`}
                        options={() => {}}
                      />
                    </Style.SubDetail>
                    
                  )}
                  </Col>
                  <Col lg={3} className="d-flex flex-row">
                  {service.amountLabel && (
                    <>
                      <Style.SubDetail className="mr-2">{t(service.amountLabel)}</Style.SubDetail>
                      <InputTextField
                        label=""
                        style={{ width: "150px"}}
                        onchange={() => {}}
                      />
                      <Style.SubDetail className="ml-2 mr-5">{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                    </>
                  )}
                  <img src={icons.iconTrash} onClick={() => handleServiceDelete(index)} />
                  </Col>
                  
                </Style.BoxPayment>
                {service.descriptionLabel && (
                  <>
                    <Row>                     
                      <Style.BoxPayment>
                      <Col className="d-flex flex-row">
                        <Style.SubDetail className="ml-3 mr-3">{t(service.descriptionLabel)}</Style.SubDetail>
                        <InputTextField
                          label=""  
                          style={{ width: "480px" }}
                          onchange={() => {}}
                        />    
                        </Col>                    
                      </Style.BoxPayment>                      
                    </Row>
                  </>
                )}
                
              </div>
            ))} */}

            {/* {disCountData.length > 0 && <Row className="my-2 mt-2 mb-3">
                    <Col xs={12} lg={2} />
                    <Col  xs={12} lg={7}>
                      <Style.ContentHeader1>{t("PAYBILL.CREATE.DISCOUNT")} </Style.ContentHeader1>
                    </Col>
                    <Col xs={12} lg={3}/>
                  </Row>}
                  
                    {disCountData[0]?.discountPrice?.map((d: any) => {
                      return (
                        <Row className="my-2">
                            <Col xs={12} md={2} />
                          <Col xs={3} >
                    <Style.SubDetail>
                    {debtTypeData?.find(
                        (item: any) =>
                          item?.paymentServiceId === d.paymentServiceId
                      )?.paymentName
                    }
                      </Style.SubDetail>
                    </Col>
                    <Col xs={3}>
                    <Style.SubDetail>
                      {d?.amount}
                    </Style.SubDetail>
                    </Col>
                    <Col xs={3}>
                    <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                    </Col>
                    </Row>
                      )
                    })} */}
            <Row className="my-3">
              <Col lg={3} md={2} />
              <Col lg={3} xs={6} md={3}>
                <Style.HeadMessage>{t("PAYBILL.REPORT.TOTAL_AMOUNT")}</Style.HeadMessage>
              </Col>
              <Col lg={2} xs={4} md={3}>
                <Style.HeadMessage>{numberFormat(totalAmount)}</Style.HeadMessage>
              </Col>
              <Col lg={3} xs={2} md={3}>
                <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
              </Col>
            </Row>

          </div>


        </Style.BoxContentContainer>
      </Style.BoxMainContainer>

    </>
  );
}
