import BaseAPI from "../../api";

const path = "role";
export interface RoleInterface {
  roleName?: string;
  level?: number;
  isEdit?: string;
  registerDate?: string;
  prefixId?: number;
  firstname?: string;
  lastname?: string;
  idCard?: string;
  dob?: string;
  email?: string;
  signature?: string;
  permission?: any;
  status?: string;
  page?: number;
  pageLimit?: number;
  createId?: number;
  id?: number;
  createDate?: string;
  search?: string;
  modifiedId?: number;
  modifiedDate?: string;
}

export default class RoleApi extends BaseAPI {
  static findAll(props: RoleInterface): Promise<any> {
    return this.api.get(path, { params: { ...props } }).then((res) => res);
  }
  static createRole(props: RoleInterface): Promise<any> {
    return this.api.post(path, props).then((res) => res);
  }
  static getDetail(props: RoleInterface): Promise<any> {
    return this.api
      .get(`${path}/${props.id}`, { params: props  })
      .then((res) => res);
  }
  static patchDetail(props: RoleInterface, id:number): Promise<any> {
    return this.api
      .patch(`${path}/${id}`,    props   )
      .then((res) => res);
  }
  static status(props: RoleInterface,id:number): Promise<any> {
    return this.api
      .patch(`${path}/${id}/status`,  props)
      .then((res) => res);
  }
}
