import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import PopupClipDoc from "../popup/popupClipDoc";
import PopupResson from "../popup/popupResson";
import { useFunctionPrint } from "../../../component/Print/print";
import Loading from "../../../component/loading/loading";

/** STYLE */
import * as Style from "./requsetForStall.style";
import { colors } from "../../../constants/colors";
import { icons, imgs, logo } from "../../../constants/images";
import { Col, Row } from "react-bootstrap";
import BidderApi from "../../../api/stall/bidder/bidder.api";
import { dateTimeToView, dateToView } from "../../../utils/date.utils";
import { useHistory } from "react-router-dom";
import { routeName } from "../../../routes/routers-name";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "../../../app/slice/modal.slice";
import { getProfile } from "../../../utils/app.utils";
import ButtonCustom from "../../../component/button/buttonCustom";
import UserApi from "../../../api/auth/users.api";
import HeaderLabel from "../../../component/cardCustom/headerLabel";
import {
  notiError,
  notiSuccess,
} from "../../../component/notification/notifications";
import { ExportProps } from "../../../constants/reportConstant";
import moment from "moment";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { decode } from 'js-base64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faFile, faPrint } from "@fortawesome/free-solid-svg-icons";
import PopupRemark from "../popup/PopupRemark";
import BranchApi from "../../../api/setting/branch/branch.api";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function RequsetForStall() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const CustomerBidder = useRef<HTMLDivElement>(null);
  const profile: any = JSON.parse(getProfile() || "{}");

  const [bidderData, getBidderData] = useState<any[]>([]);
  const [AdminData, getAdminData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [pageChoose, setPageChoose] = useState<number>(1);
  const [pageLimitChoose, setPageLimitChoose] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("ALL");
  const [dataDetail, setDataDetail] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>()

  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId);
  const [docDetail, setDocDetail] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<any[]>([]);
  const [popupDetail, setPopupDetail] = useState<boolean>(false);
  const [popupResson, setPopupResson] = useState<boolean>(false);
  const [typeAccept, setTypeAccept] = useState<string>("");
  const [detailUser, setDetailuser] = useState<any>();
  const [detailDate, setDetailDate] = useState<string>();
  const [bidNo, setBidNo] = useState<string>("");
  const [typePrint, setTypePrint] = useState<string>("")

  const [printSelectBidder, setPrintSelectBidder] = useState<any>();
  const [printPremilinary, setPrintPremilinary] = useState<any>();
  const [printApproval, setPrintApproval] = useState<any>();
  const [printCustomer, setPrintCustomer] = useState<any>();

  const [notApprove, setNotApprove] = useState<boolean>(false);
  const [remarkNotApprove, setRemarkNotApprove] = useState<string>("");

  const [rowCountPending, setRowCountPending] = useState<number>(Number);
  const [rowCountPreliminary, setRowCountPreliminary] = useState<number>(Number);

  const [pageLimitPending, setPageLimitPending] = useState<number>(10)
  const [pagePending, setPagePending] = useState<number>(1);
  const [pageLimitPreliminary, setPageLimitPreliminary] = useState<number>(10)
  const [pagePreliminary, setPagePreliminary] = useState<number>(1);
  const [detailChecked, setDetailChecked] = useState<number[]>([]);
  const [branch, setBranch] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [announceId, setAnnounceId] = useState<number>(Number);
  const [statusCheck, setStatusCheck] = useState<string>("")


  // const [user, setUser] = useState<any[]>([])
  const getBranch = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
      status: "ACTIVE",
    };
    const res = await BranchApi.findAll(condition);
    if (res.status === 200) {
      setBranch(res.data);
    }
  };
  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
    getBranch()
  }, []);

  const getUser = async () => {
    const condition = {
      id: JSON.parse(decode(profile))?.userId,
    };
    const res = await UserApi.getDetail(condition);
    setDetailuser(res.data);
  };
  useEffect(() => {
    if (roleId === 4) {
      getUser();
    }
  }, [JSON.parse(decode(profile))?.roleId]);

  const getBidder = useCallback(async () => {
    let condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: JSON.parse(decode(profile))?.userId,
      sortType: "DESC",
    };
    if (search !== "") condition = { ...condition, search: search };
    if (status !== "ALL") condition = { ...condition, status: status };

    let conditionAdmin: any = {
      page: pageChoose,
      pageLimit: pageLimitChoose,
      sortType: "DESC",
    };
    if (search !== "") conditionAdmin = { ...condition, search: search };
    if (roleId && roleId === 4) {
      await BidderApi.findAll(condition).then((res) => {
        if (res.status === 200) {
          setRowCount(res.headers['total'])
          getBidderData(res.data);
        } else {
          getBidderData([]);
        }
      });
      // } else {
      // await BidderApi.findAllAdmin(conditionAdmin).then((res) => {
      //   if (res.status === 200) {
      //     getBidderData(res.data);
      //     // console.log(res.data)
      //     setRowCount(res.headers['total'])
      //   } else {
      //     getBidderData([]);
      //   }
      // });
      // }
    }
  }, [bidNo, search, status, page, pageLimit,]);




  const getDataAdmin = useCallback(async () => {
    const condition: any = {
      page: page,
      pageLimit: pageLimit,
    };
    const res = await BidderApi.findAllAdmin(condition);
    if (res.status === 200) {
      getAdminData(res.data);
      setRowCount(res?.headers['total'])
    }
  }, [page, pageLimit]);


  // console.log(typeAccept)



  useEffect(() => {
    if (roleId !== 4) {
      getDataAdmin();
    }
  }, [page, pageLimit]);

  useEffect(() => {
    getBidder();
  }, [bidNo, search, status, page, pageLimit]);

  const findById = useCallback(async (props: any) => {
    try {
      setTypePrint("FIND_BY_ID")
      setLoading(true);
      const bidderDetails = await BidderApi.findById(props)

      const [testResult] = await Promise.all([
        bidderDetails,
      ]);
      console.log(testResult.data);
      setPrintSelectBidder(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [BidderApi])

  const handlePrintfindById = useFunctionPrint({
    content: () => CustomerBidder.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printSelectBidder && typePrint === "FIND_BY_ID") {
      handlePrintfindById();
    }
  }, [printSelectBidder])

  // const dataPremilinary = useCallback(async () => {
  //   try {
  //     setTypePrint("PERMILINARY")
  //     let conditionAdmin: any = {
  //       page: page,
  //       pageLimit: pageLimit,
  //       status: "PENDING"
  //     };
  //     setLoading(true);
  //     const bidderPremilinary = await BidderApi.findAll(conditionAdmin)

  //     const [testResult] = await Promise.all([
  //       bidderPremilinary,
  //     ]);
  //     setPrintPremilinary(testResult.data);
  //   } catch (err: any) {

  //   } finally {
  //     setLoading(false);
  //   }

  // }, [BidderApi])

  const handlePrintdataPremilinary = useFunctionPrint({
    content: () => CustomerBidder.current,
    pageStyle: () => `@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printPremilinary && typePrint === "PERMILINARY") {
      handlePrintdataPremilinary();
    }
  }, [printPremilinary])

  const dataApproval = useCallback(async () => {
    try {
      setTypePrint("APPROVAL")
      let conditionAdmin: any = {
        page: page,
        pageLimit: pageLimit,
        status: "PRELIMINARY"
      };
      setLoading(true);
      const bidderPremilinary = await BidderApi.findAll(conditionAdmin)

      const [testResult] = await Promise.all([
        bidderPremilinary,
      ]);
      setPrintApproval(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [BidderApi])

  const handlePrintdataApproval = useFunctionPrint({
    content: () => CustomerBidder.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printApproval && typePrint === "APPROVAL") {
      handlePrintdataApproval();
    }
  }, [printApproval])

  // const dataCustomer = useCallback(async (props: any) => {
  //   try {
  //     let condition: any = {
  //       page: page,
  //       pageLimit: pageLimit,
  //       userId: JSON.parse(decode(profile))?.userId,
  //       bidNo: props
  //     };
  //     console.log('condition pending')
  //     setLoading(true);
  //     const bidderPremilinary = await BidderApi.findAll(condition)

  //     const [testResult] = await Promise.all([
  //       bidderPremilinary,
  //     ]);
  //     setTypePrint("CUSTOMER")
  //     setPrintCustomer(testResult.data);
  //     console.log(testResult.data)
  //   } catch (err: any) {

  //   } finally {
  //     setLoading(false);
  //   }

  // }, [BidderApi])

  const handlePrintdataCustomer = useFunctionPrint({
    content: () => CustomerBidder.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px  ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printCustomer && typePrint === "CUSTOMER") {
      handlePrintdataCustomer();
    }
  }, [printCustomer])

  // const GetUserData = async () => {
  //   const condition: any = {
  //     page: 1,
  //     pageLimit: 50
  //   }
  //   await UserApi.getAll(condition)
  //     .then((res) => {
  //       setUser(res.data)
  //     })
  // }

  // useEffect(() => {
  //   GetUserData()
  // }, [])

  const onCheck = (event: any, obj: any) => {
    const { checked, value } = event;

    if (checked) {
      setDataDetail([...dataDetail, obj]);
      setIsChecked([...isChecked, Number(value)]);
    } else {
      setIsChecked(
        _.filter(isChecked, (i: number) => {
          return i !== Number(value);
        })
      );
      setDataDetail(
        dataDetail.filter((item: any) => item.bidderId !== obj.bidderId)
      );
    }
  };
  console.log(detailChecked)
  console.log(dataDetail)

  const showPopupDetail = () => {
    setPopupDetail(true);
    dispatch(showModal());
  };

  const showPopupResson = () => {
    setPopupResson(true);
    dispatch(showModal());
  };

  const onPreSubmit = async () => {
    const condition: any = {
      arrayBidderId: isChecked,
      status: remarkNotApprove ? "NOT_APPROVE" : "PRELIMINARY",
      remark: remarkNotApprove ? remarkNotApprove : undefined
    };

    // console.log(condition)
    setLoading(true)
    await BidderApi.updateStatus(condition)
      .then((res) => {
        notiSuccess(t("REQUEST_STALL.SWAL.SUCCESS"), "go", '/request-stall', null);
        setPopupDetail(false);
        getBidder();
        if (remarkNotApprove) {
          setNotApprove(false)
          setRemarkNotApprove("")
        }
        dispatch(closeModal());
      })
      .catch(() => {
        notiError(t("REQUEST_STALL.SWAL.FAIL"));
        dispatch(closeModal());
      })
      .finally(() => {
        setLoading(false)
        setPopupDetail(false);
        setDocDetail(false);
        setDataDetail([]);
        getDataAdmin();
        setIsChecked([]);
        // window.location.reload()
      });
  };

  const onSubmitApprove = async () => {
    const condition: any = {
      arrayBidderId: isChecked,
      status: "APPROVE",
    };
    await BidderApi.updateStatus(condition)
      .then((res) => {
        notiSuccess(t("REQUEST_STALL.SWAL.SUCCESS"), "reload", null, null);
        setPopupDetail(false);
        getBidder();
        dispatch(closeModal());
      })
      .catch(() => {
        notiError(t("REQUEST_STALL.SWAL.FAIL"));
        dispatch(closeModal());
      })
      .finally(() => {
        setPopupDetail(false);
        setDocDetail(false);
        setDataDetail([]);
        getDataAdmin();
        setIsChecked([]);
      });
  };

  const ondocumentdetail = (obj: any) => {
    setDetailDate(obj.bidderDate);
    setBidNo(obj?.announce[0]?.announceNo);
    setDocDetail(true);
    // handleGetBidderAdminCheck(obj?.announceId, 'PRELIMINARY')
    setStatusCheck('PRELIMINARY')
    setAnnounceId(obj?.announceId)
    setTypeAccept("PENDING");
  };

  const onresson = async (obj: any) => {
    setDetailDate(obj.bidderDate);
    setBidNo(obj?.announce[0]?.announceNo);
    // handleGetBidderAdminCheck(obj?.announceId, 'PENDING')
    setStatusCheck('PENDING')
    setAnnounceId(obj?.announceId)
    setTypeAccept("PRELIMINARY");
    setDocDetail(true);
  };

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "message",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.DOC_DATE"),
      align: 'center'
    },
    {
      id: "message",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.REQ_NO"),
      align: 'center'
    },
    // {
    //   id: "message",
    //   disablePadding: false,
    //   label: t("REQUEST_STALL.HEADCELL.NAME"),
    //   align:'center'
    // },
    {
      id: "message",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.ZONE"),
      align: 'center'
    },
    {
      id: "message",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.STALL"),
      align: 'center'
    },
    {
      id: "message",
      disablePadding: false,
      label: t("เลขที่แผง"),
      align: 'center'
    },
    {
      id: "message",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.TYPE"),
      align: 'center'
    },
    {
      id: "message",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.STATUS"),
      align: 'center'
    },
    {
      id: "message",
      disablePadding: false,
      align: "center",
      label: t("REQUEST_STALL.HEADCELL.ACTION"),
    },
  ];

  const headCellsAdminChoosed = [
    { id: "approve", disablePadding: false, label: "อนุมัติ", align: 'center' },
    { id: "no", disablePadding: false, label: "#", align: 'center' },
    { id: "date", disablePadding: false, label: t("REQUEST_STALL.HEADCELL.DOC_DATE_REQ"), align: 'center' },
    { id: "requestNo", disablePadding: false, label: t("เลขที่คำร้อง"), align: 'center' },
    { id: "name", disablePadding: false, label: t("ผู้ยื่นประสงค์เช่า"), align: 'center' },
    { id: "zone", disablePadding: false, label: t("REQUEST_STALL.HEADCELL.ZONE"), align: 'center' },
    { id: "stall", disablePadding: false, label: t("REQUEST_STALL.HEADCELL.STALL"), align: 'center' },
    { id: "stallname", disablePadding: false, label: t("เลขที่แผง"), align: 'center' },
    // { id: "amount", disablePadding: false, label: t("REQUEST_STALL.HEADCELL.MONEY"), align:'center' },
    { id: "action", disablePadding: false, label: t("Action"), align: 'center' },
  ];

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.DOC_DATE"),
      align: 'center'
    },
    {
      id: "zone",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.ANNOUNCE_NO"),
      align: 'center'
    },
    {
      id: "stall",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.NUMBER_REQUESTER"),
      align: 'center'
    },
    {
      id: "zoneType",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.NUMBER_PRE"),
      align: 'center'
    },
    {
      id: "pre-approve",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.NUMBER_APPORVAL"),
      align: 'center'
    },
    {
      id: "action",
      disablePadding: false,
      label: t("REQUEST_STALL.HEADCELL.ACTION"),
      align: 'center'
    },
  ];

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      bidderBy,
      bidNo,
      zoneName,
      stallName,
      stall,
      status,
      createDate,
      stallTypeName,
      bidderId
    } = objData;
    const setState =
      status === "PENDING"
        ? t("REQUEST_STALL.STATUS.PENDING")
        : status === "PRELIMINARY"
          ? t("REQUEST_STALL.STATUS.PRELIMINARY")
          : status === "APPROVE"
            ? t("REQUEST_STALL.STATUS.APPROVE")
            : t("REQUEST_STALL.STATUS.NOT_APPROVE");
    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(createDate) },
        { option: "TEXT", align: "center", label: bidNo || "-" },
        // {
        //   option: "TEXT",
        //   align: "center",
        //   label: bidderBy.firstname + " " + bidderBy.lastname || "-",
        // },
        { option: "TEXT", align: "center", label: zoneName || "-" },
        { option: "TEXT", align: "center", label: stallName || "-" },
        { option: "TEXT", align: "center", label: stall?.stallNumber || "-" },
        { option: "TEXT", align: "center", label: stallTypeName || "-" },

        {
          option: "COMPONENT",
          align: "center",
          component: (
            <Typography
              sx={{
                color:
                  status === "PENDING"
                    ? colors.yellow
                    : status === "APPROVE"
                      ? colors.green
                      : status === "PRELIMINARY"
                        ? colors.themeSecondColor
                        : colors.red,

                whiteSpace: "nowrap",
                textAlign: 'center'
              }}
            >
              {setState}
            </Typography>
            // <Button
            //   sx={{
            //     backgroundColor:
            //       status === "PENDING"
            //         ? colors.yellow
            //         : status === "APPROVE"
            //           ? colors.green
            //           : status === "PRELIMINARY"
            //             ? colors.themeSecondColor
            //             : colors.red,
            //     color: colors.white,
            //     margin: "auto",
            //     width: '100%',
            //     whiteSpace: "nowrap",
            //     "&.MuiButtonBase-root:hover": {
            //       backgroundColor:
            //         status === "PENDING"
            //           ? colors.yellow
            //           : status === "APPROVE"
            //             ? colors.green
            //             : status === "PRELIMINARY"
            //               ? colors.themeSecondColor
            //               : colors.red,
            //       color: colors.white,
            //     }
            //   }}
            // >
            //   {setState}
            // </Button>
          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton disabled={permissions.isView.disabled === true} onClick={() => {
                  setTypePrint("CUSTOMER")
                  setPrintCustomer([objData]);
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="รายละเอียด">
                <IconButton disabled={permissions.isView.disabled === true} onClick={() =>
                  history.push(routeName.requestForStall + "/requester", {
                    params: {
                      createDate: objData.createDate,
                      zoneName: objData.zoneName,
                      stallName: objData.stallName,
                      bidNo: objData.bidNo,
                      userDetail: detailUser,
                    },
                  })}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">

                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: permissions.isView.disabled === true ? "auto" : "pointer"
                    , color: permissions.isView.disabled === true ? colors.themeMainColor : colors.themeMainColor
                  }}
                  onClick={() => {
                    // dataCustomer(bidNo)
                    setTypePrint("CUSTOMER")
                    setPrintCustomer([objData]);
                  }
                  }
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: permissions.isView.disabled === true ? "auto" : "pointer"
                    , color: permissions.isView.disabled === true ? colors.themeMainColor : colors.themeMainColor
                  }}
                  onClick={() =>
                    history.push(routeName.requestForStall + "/requester", {
                      params: {
                        createDate: objData.createDate,
                        zoneName: objData.zoneName,
                        stallName: objData.stallName,
                        bidNo: objData.bidNo,
                        userDetail: detailUser,
                      },
                    })}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const renderDataAdminChoosed = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      bidderBy,
      bidNo,
      zoneName,
      stallName,
      createDate,
      bidderAmount,
      stallTypeName,
      stallNumber,
      bidderId,
      announce
    } = objData;

    const rowCheck = _.some(isChecked, (i: number) => i === bidderId);

    const objRenderData = {
      key: bidderId,
      id: bidderId,
      obj: objData,
      rowSelect: rowCheck,
      columns: [
        { option: "CHECKBOX", align: "center", label: bidderId },
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateTimeToView(createDate) },
        { option: "TEXT", align: "center", label: bidNo || "-" },
        {
          option: "TEXT",
          align: "center",
          label: bidderBy?.firstname + " " + bidderBy?.lastname || "-",
        },
        { option: "TEXT", align: "center", label: zoneName || "-" },
        { option: "TEXT", align: "center", label: stallName || "-" },
        { option: "TEXT", align: "center", label: stallNumber || "-" },
        // { option: "TEXT", align: "center", label: bidderAmount || "-" },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton onClick={() => {
                  findById(bidderId)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  history.push(routeName.requestForStall + "/renter", {
                    params: {
                      createDate: objData.createDate,
                      zoneName: objData.zoneName,
                      stallName: objData.stallName,
                      bidNo: objData.bidNo,
                      userId: objData.userId,
                    },
                  })
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => findById(bidderId)}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() =>
                    history.push(routeName.requestForStall + "/renter", {
                      params: {
                        createDate: objData.createDate,
                        zoneName: objData.zoneName,
                        stallName: objData.stallName,
                        bidNo: objData.bidNo,
                        userId: objData.userId,
                      },
                    })}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} onCheck={(event) => onCheck(event.target, objData)} />
    );
  };

  const handleGetBidderAdminCheck = useCallback(async () => {
    let condition = {
      page: statusCheck !== "PRELIMINARY" ? pagePending : pageChoose,
      pageLimit: statusCheck !== "PRELIMINARY" ? pageLimitPending : pageLimitChoose,
      announceId: announceId,
      status: statusCheck
    }
    await BidderApi.findAllAdminCheck(condition)
      .then(res => {
        if (res.status === 200) {
          // console.log(res.headers['total'])
          statusCheck !== "PRELIMINARY" ? setRowCountPending(res.headers['total']) : setRowCountPreliminary(res.headers['total'])
          getBidderData(res?.data)
        } else {
          getBidderData([])
        }
      })
      .catch(error => {
        throw new Error('Fetch data error', error)
      })
  }, [statusCheck, announceId, pageChoose, pageLimitChoose, pageLimitPending, pagePending])

  useEffect(() => {
    if (roleId !== 4) {
      handleGetBidderAdminCheck()
    }
  }, [handleGetBidderAdminCheck])



  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;

    const {
      bidderDate,
      bidNo,
      bidderId,
      pageId,
      announceId,
      cnt_preliminary,
      cnt,
      cnt_approve,
      announce
    } = objData;

    const objRenderData = {
      key: bidderId,
      id: bidderId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "center", label: dateToView(bidderDate) },
        { option: "TEXT", align: "center", label: <Typography sx={{ textDecoration: 'underline', cursor: "pointer" }} onClick={() => window.open('/detail-info/' + announceId, '_blank')}>{announce[0]?.announceNo}</Typography> || "-" },
        { option: "TEXT", align: "center", label: cnt || "-" },
        { option: "TEXT", align: "center", label: cnt_preliminary },
        { option: "TEXT", align: "center", label: cnt_approve },
        // {
        //   option: "ACTION",
        //   align: "center",
        //   label: "action",
        //   style: { color: colors.themeMainColor },
        //   values: [
        //     // {
        //     //   option: "PRINT",
        //     //   label: t(`REQUEST_STALL.STATUS.PRINT`),
        //     // },
        //     {
        //       option: "STATUS_ACTIVE",
        //       label: t(`REQUEST_STALL.STATUS.DETAIL`),
        //       disabled: permissions.isUpdate.disabled
        //     },
        //     {
        //       option: "DOCUMENTDETAIL",
        //       label: t(`REQUEST_STALL.STATUS.APPROVE_CONTRACT`),
        //       disabled: permissions.isUpdate.disabled
        //     },
        //     {
        //       option: "DELETE",
        //       label: t(`REQUEST_STALL.STATUS.DELETE`),
        //       disabled: true
        //       // permissions.isDelete.disabled ||
        //     },
        //   ],
        // },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="อนุมัติเบื่องต้น">
                <IconButton onClick={() => {
                  setAnnounceId(announceId)
                  onresson(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="ผู้บริหารอนุมัติทำสัญญา">
                <IconButton onClick={() => {
                  setAnnounceId(announceId)
                  ondocumentdetail(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    setAnnounceId(announceId)
                    onresson(objData)
                  }}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => {
                    setAnnounceId(announceId)
                    ondocumentdetail(objData)
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon
        {...objRenderData}
        onprint={() => { }}
        ondelete={() => { }}
        onactive={() => onresson(objData)}
        ondocumentdetail={() => ondocumentdetail(objData)}
      />
    );
  };

  // console.log(bidderData)

  return (
    <div style={{ position: "relative" }}>
      {typeAccept === "" && <HeaderCard text={t("REQUEST_STALL.TITlE")} />}
      {roleId === 4 && (
        <Style.MainContainer>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <InputTextField
                  label="ค้นหา"
                  value={search}
                  onchange={(e: any) => setSearch(e.target.value)}
                />
              </Grid>
              {/* <Grid item lg={2} md={6} xs={12}>
                <AutocompleteSelect
                  options={[]}
                  getOptionLabel={(option: any) => {}}
                  placeholder={t("REQUEST_STALL.FILTER.ZONE")}
                  labelId="zone"
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <AutocompleteSelect
                  options={[]}
                  // options={stallType}
                  // getOptionLabel={(option: any) => option.stallTypeNameTh}
                  placeholder={t("REQUEST_STALL.FILTER.STALL")}
                  labelId="stall"
                  // value={stallTypeId}
                />
              </Grid> */}
              <Grid item lg={2} md={6} xs={12}>
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value;
                    if (value) { setStatus(value) }
                  }}
                  label={t("REQUEST_STALL.FILTER.STATUS")}
                  labelId="status"
                  selectId="status"
                  fullWidth
                  value={status}
                  renderValue={() => `${t(`REQUEST_STALL.STATUS.${status}`)}`}
                  options={[
                    <MenuItem key="ALL" value="ALL">
                      {t("REQUEST_STALL.STATUS.ALL")}
                    </MenuItem>,
                    <MenuItem key="1" value="PENDING">
                      {t("REQUEST_STALL.STATUS.PENDING")}
                    </MenuItem>,
                    <MenuItem key="2" value="PRELIMINARY">
                      {t("REQUEST_STALL.STATUS.PRELIMINARY")}
                    </MenuItem>,
                    <MenuItem key="3" value="APPROVE">
                      {t("REQUEST_STALL.STATUS.APPROVE")}
                    </MenuItem>,
                    <MenuItem key="4" value="NOT_APPROVE">
                      {t("REQUEST_STALL.STATUS.NOT_APPROVE")}
                    </MenuItem>,
                    // <MenuItem key="5" value="DELETE">
                    //   {t("REQUEST_STALL.STATUS.DELETE")}
                    // </MenuItem>,
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <Style.TableContainer>
            <TableCustom
              headCells={headCells}
              customScroll
              page={page}
              pageLimit={pageLimit}

              sortType={"ASC"}
              sortBy=""
              rowsData={bidderData?.map((d: any, i: any) => {
                return renderData(d, i);
              })}
              rowCount={rowCount}
              onSort={() => { }}
              setPageLimit={(value: number) => setPageLimit(value)}
              setPage={(value: number) => setPage(value)}
            // tableMinWidth={1400}
            />
          </Style.TableContainer>
        </Style.MainContainer>
      )}

      {roleId !== 4 && !docDetail && (
        <Style.MainContainer>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <InputTextField
                  label="ค้นหา"
                  value={search}
                  onchange={(e: any) => setSearch(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
          <Style.TableContainer>
            <TableCustom
              headCells={headCellsAdmin}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={"ASC"}
              sortBy=""
              rowCount={rowCount}
              rowsData={
                AdminData &&
                AdminData.map((d: any, i: any) => {
                  return renderDataAdmin(d, i);
                })
              }
              onSort={() => { }}
              setPageLimit={(value: number) => setPageLimit(value)}
              setPage={(value: number) => setPage(value)}
              tableMinWidth={1400}
            />
          </Style.TableContainer>
        </Style.MainContainer>
      )}

      {roleId !== 4 && docDetail && typeAccept === "PENDING" && (
        <>
          <HeaderLabel
            text={t("ผู้บริหารอนุมัติทำสัญญา")}
            onSubmit={() => {
              setDocDetail(false);
              setTypeAccept("");
              setIsChecked([]);
              setPageChoose(1)
              setPageLimitChoose(10)
              setPage(1)
              setPageLimit(10)
              setPagePending(1)
              setPageLimitPending(10)
              setIsChecked([]);
              setDataDetail([])
            }}
            submitText={t("REQUEST_STALL.BUTTON.CANCEL")}
            submitTheme="outlined"
          />
          <Style.MainContainer>
            <Row>
              <Col className="d-flex flex-row " xl={3} md={6} xs={12}>
                <Style.Title>{t("REQUEST_STALL.DETAIL.DOC_DATE")}</Style.Title>
                <Style.Title>{dateToView(detailDate)}</Style.Title>
              </Col>

              <Col className="d-flex flex-row " xl={3} md={6} xs={12}>
                <Style.Title>
                  {t("REQUEST_STALL.DETAIL.ANNOUCE_NO")}
                </Style.Title>
                <Style.Title>{bidNo}</Style.Title>
              </Col>

              <Col className="d-flex flex-row " xl={3} md={6} xs={12}>
                <Style.Title>
                  {t("REQUEST_STALL.DETAIL.NUMBER_RENTER")}
                </Style.Title>
                <Style.Title>
                  {
                    bidderData.filter(
                      (item: any) => item.status === "PRELIMINARY"
                    ).length
                  }
                </Style.Title>
              </Col>

              <Col xl={3} md={6} xs={12}>
                <ButtonCustom
                  textButton={t("STALL_AGREEMENT.BUTTON.PRINT")}
                  style={{ width: "100%" }}
                  endIcon={<img src={icons.print} />}
                  className="w-auto ml-auto d-flex mt-auto"
                  onClick={() => dataApproval()}
                />
              </Col>

            </Row>

            <Style.TableContainer>
              <TableCustom
                headCells={headCellsAdminChoosed}
                customScroll={true}
                page={pageChoose}
                pageLimit={pageLimitChoose}
                sortType={"ASC"}
                sortBy=""
                rowCount={rowCountPreliminary}
                rowsData={bidderData
                  .filter((item: any) => item.status === "PRELIMINARY")
                  .map((d: any, i: any) => {
                    return renderDataAdminChoosed(d, i);
                  })}
                onSort={() => { }}
                setPageLimit={(value: number) => setPageLimitChoose(value)}
                setPage={(value: number) => setPageChoose(value)}
                tableMinWidth={1200}
              />
            </Style.TableContainer>

            {/* <ButtonCustom
              btnStyle={{ width: "100%" }}
              style={{ width: "100%" }}
              textButton={t("REQUEST_STALL.BUTTON.APPROVE")}
              onClick={() => showPopupDetail()}
              disabled={permissions.isUpdate.disabled || isChecked.length <= 0 || isChecked.length > 1}
            /> */}
            <Row className="w-100 d-flex justify-content-center align-items-center gap-2" style={{ margin: 0 }}>
              <Col xs={12} md={3} lg={3}>
                <ButtonCustom
                  btnStyle={{ width: "100%" }}
                  style={{ width: "100%" }}
                  textButton={t("REQUEST_STALL.BUTTON.APPROVE")}
                  onClick={() => showPopupDetail()}
                  disabled={permissions.isUpdate.disabled || isChecked.length <= 0 || isChecked.length > 1}
                />
              </Col>
              <Col xs={12} md={3} lg={3}>
                <ButtonCustom
                  type={'button'}
                  mode={'del'}
                  btnStyle={{
                    width: "100%", borderRadius: "8px",
                    '&:hover': {
                      backgroundColor: `${colors.red} !important`
                    }
                  }}
                  style={{
                    width: "100%",
                    '&.MuiButton-contained': {
                      // backgroundColor: `${colors.red} !important`,
                      ':hover': {
                        backgroundColor: `${colors.red} !important`,
                      }
                    }
                  }}
                  textButton={"ไม่อนุมัติ"}
                  onClick={() => {
                    setNotApprove(true)
                  }}
                  disabled={permissions.isUpdate.disabled || isChecked.length <= 0 || isChecked.length > 1}
                />
              </Col>
            </Row>
          </Style.MainContainer>
        </>
      )}

      {roleId !== 4 && docDetail && typeAccept === "PRELIMINARY" && (
        <>
          <HeaderLabel
            text={t("อนุมัติผู้ประสงค์ขอเช่าเบื้องต้น")}
            onSubmit={() => {
              setDocDetail(false);
              setTypeAccept("");
              setIsChecked([]);
              setPageChoose(1)
              setPageLimitChoose(10)
              setPage(1)
              setPageLimit(10)
              setPagePending(1)
              setPageLimitPending(10)
              setIsChecked([]);
              setDataDetail([])
            }}
            submitText={t("REQUEST_STALL.BUTTON.CANCEL")}
            submitTheme="outlined"
          />
          <Style.MainContainer>
            <Row>
              <Col className="d-flex flex-row " xl={4} md={6} xs={12}>
                <Style.Title>{t("REQUEST_STALL.DETAIL.DOC_DATE")}</Style.Title>
                <Style.Title>{dateToView(detailDate)}</Style.Title>
              </Col>

              <Col className="d-flex flex-row " xl={4} md={6} xs={12}>
                <Style.Title>
                  {t("REQUEST_STALL.DETAIL.ANNOUCE_NO")}
                </Style.Title>
                <Style.Title>{bidNo}</Style.Title>
              </Col>

              <Col className="d-flex flex-row " xl={4} md={6} xs={12}>
                <Style.Title>
                  {t("REQUEST_STALL.DETAIL.NUMBER_RENTER")}
                </Style.Title>
                <Style.Title>
                  {
                    bidderData.filter((item: any) => item.status === "PENDING")
                      .length
                  }
                </Style.Title>
              </Col>
            </Row>
            <Style.TableContainer>
              <TableCustom
                headCells={headCellsAdminChoosed}
                customScroll={true}
                page={pagePending}
                pageLimit={pageLimitPending}
                sortType={"ASC"}
                sortBy=""
                rowCount={rowCountPending}
                rowsData={bidderData
                  .filter((item: any) => item.status === "PENDING")
                  .map((d: any, i: any) => {
                    return renderDataAdminChoosed(d, i);
                  })}
                onSort={() => { }}
                setPageLimit={(value: number) => setPageLimitPending(value)}
                setPage={(value: number) => setPagePending(value)}
              />
            </Style.TableContainer>

            <Row className="w-100 d-flex justify-content-center align-items-center gap-2" style={{ margin: 0 }}>
              <Col xs={12} md={3} lg={3}>
                <ButtonCustom
                  btnStyle={{ width: "100%" }}
                  style={{ width: "100%" }}
                  textButton={t("REQUEST_STALL.BUTTON.SUBMIT")}
                  onClick={() => showPopupResson()}
                  disabled={permissions.isUpdate.disabled || isChecked.length <= 0}
                />
              </Col>
              <Col xs={12} md={3} lg={3}>
                <ButtonCustom
                  type={'button'}
                  mode={'del'}
                  btnStyle={{
                    width: "100%", borderRadius: "8px",
                    '&:hover': {
                      backgroundColor: `${colors.red} !important`
                    }
                  }}
                  style={{
                    width: "100%",
                    '&.MuiButton-contained': {
                      // backgroundColor: `${colors.red} !important`,
                      ':hover': {
                        backgroundColor: `${colors.red} !important`,
                      }
                    }
                  }}
                  textButton={"ไม่อนุมัติ"}
                  onClick={() => {
                    setNotApprove(true)
                  }}
                  disabled={permissions.isUpdate.disabled || isChecked.length <= 0}
                />
              </Col>
            </Row>

          </Style.MainContainer>
        </>
      )}

      {(popupDetail && roleId !== 4 && typeAccept === "PENDING" && notApprove !== true && (
        <PopupClipDoc
          isShow={popupDetail}
          data={dataDetail}
          setIsShow={(show: boolean) => setPopupDetail(show)}
          onSubmit={() => onSubmitApprove()}
          onUpload={(file) => console.log(file)}
        />
      )) || <></>}

      {(popupResson && roleId !== 4 && typeAccept === "PRELIMINARY" && notApprove !== true && (
        <PopupResson
          isShow={popupResson}
          data={dataDetail}
          setIsShow={(show: boolean) => setPopupResson(show)}
          onSubmit={() => onPreSubmit()}
          onUpload={() => { }}
        />
      )) || <></>}

      {(notApprove === true && roleId !== 4 && (
        <PopupRemark
          isShow={notApprove}
          remark={remarkNotApprove}
          onChange={(remark: string) => setRemarkNotApprove(remark)}
          setIsShow={(show: boolean) => setNotApprove(show)}
          onSubmit={() => onPreSubmit()}
          onUpload={() => { }}
        />
      ))}

      <div className="print-show" ref={CustomerBidder}>
        <ExportData
          // headCells={ t("REQUEST_STALL.STATUS.APPROVE_CONTRACT")}
          headCells={""}
          componant={
            <>
              {typePrint === "CUSTOMER" && <Style.Styles className="pt-3">{printCustomer && printCustomer.map((d: any, i: any) => {
                return (
                  <div className="print-pdf-sarabun">
                    <div className="row">
                      <div className="col-6 d-flex flex-row">
                        <p>วันที่ ...........................................</p>
                      </div>
                      <div className="col-6 d-flex justify-content-end">
                        <div className="d-flex flex-column">
                          <p className="text-right mr-2">กลุ่มบริหารงานตลาด</p>
                          <img src={logo.OTK_Logo} alt="" width="180" height="80" />
                        </div>

                      </div>
                    </div>
                    <div className="row" style={{ margin: '40px 0px' }}>
                      <div className="col-12">
                        <p className="text-center">
                          แบบคำร้องทำสัญญาเช่า
                        </p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="d-flex flex-row col-9">
                        <p className="ml-5">ข้าพเจ้า</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.prefix?.prefixNameTh + " "} {d?.bidderBy?.firstname} {d?.bidderBy?.lastname}</p>
                      </div>
                      <div className="col-3 d-flex flex-row">
                        <p>อายุ</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.birthDay && moment().diff(d?.bidderBy?.birthDay, "years")}</p>
                        <p>ปี</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3 d-flex flex-row">
                        <p>สัญชาติ</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                      </div>
                      <div className="col-3 d-flex flex-row">
                        <p className="w-50">เชื้อชาติ</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                      </div>
                      <div className="col-3 d-flex flex-row">
                        <p className="w-75">บ้านเลขที่</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.houseNumber + " " || "-"}</p>
                      </div>
                      <div className="col-3 d-flex flex-row">
                        <p className="w-50">หมู่ที่</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.moo + " " || "-"}</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-42 d-flex flex-row">
                        <p>ถนน</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.road + " " || "-"}</p>
                      </div>
                      <div className="col-4 d-flex flex-row">
                        <p >แขวง/ตำบล</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: 'nowrap' }}>
                          {d?.bidderBy?.district?.districtNameTh || "-"}
                        </p>
                      </div>
                      <div className="col-4 d-flex flex-row">
                        <p >เขต/อำเภอ</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: 'nowrap' }}>
                          {d?.bidderBy?.subDistrict?.subDistrictNameTh || "-"}
                        </p>
                      </div>

                    </div>
                    <div className="row ">
                      <div className="col-4 d-flex flex-row">
                        <p>จังหวัด</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: 'nowrap' }}>{d?.bidderBy?.province?.provinceNameTh || "-"}</p>
                      </div>
                      <div className="col-4 d-flex flex-row">
                        <p>โทรศัพท์</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                          {d?.bidderBy?.mobilePhone || "-"}
                        </p>
                      </div>
                      <div className="col-4 d-flex flex-row">
                        <p>อีเมล</p>
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                          {d?.bidderBy?.email || "-"}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-3">
                        <p>มีความประสงค์</p>
                      </div>
                      <div className="col-1">
                        <div>
                          <input type="checkbox" checked style={{ width: 20, height: 20 }} />
                        </div>
                      </div>
                      <div className="col-8">
                        <div>
                          <div className="d-flex flex-row">
                            <p className="w-100">ขอเช่าพื้นที่ตลาด </p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                          </div>
                          <div className="d-flex flex-row">
                            <p className="w-25">ประเภทสินค้า </p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-5">
                      <div className="col-3">

                      </div>
                      <div className="col-1">
                        <div>
                          <input type="checkbox" style={{ width: 20, height: 20 }} />
                        </div>
                      </div>
                      <div className="col-8">
                        <div>
                          <div className="d-flex flex-row">
                            <p className="w-100">ขอต่อสัญญา (สัญญาเลขที่................/................. ลงวันที่.........................................) </p>
                          
                          </div>
                          <div className="d-flex flex-row">
                            <p className="w-100">ประเภทสินค้า </p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                            <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="row mt-3">
                      <div className="col-12">
                        <p>จึงยื่นคำร้องต่อหัวหน้ากลุ่มบริหารงานตลาด องค์การตลาดเพื่อเกษตรกร โดยยินดีปฏิบัติตามระเบียบ ข้อบังคับ
                          คำสั่ง คำแนะนำ ประกาศ ขององค์การตลาดเพื่อเกษตรกร และหรือการพิจารณาของหัวหน้ากลุ่มบริหารงาน
                          ตลาดทุกประการ โดยไม่มีเงื่อนไขใดๆ ทั้งสิ้น</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className="ml-5">จึงเรียนมาเพื่อโปรดพิจารณา</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className="ml-5 text-right">ลงชื่อ...........................................................ผู้ยื่นคำร้อง</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className=" text-right" style={{ marginRight: 56 }}>(.............................................................)</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <p className=" text-right" style={{ marginRight: 56 }}>.............../....................../.........................</p>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div>
                        <p style={{ marginRight: 56 }}>หมายเลขติดต่อ</p>
                      </div>
                    </div>
                    <div className="row mb-2" style={{ pageBreakAfter: 'always' }}>
                      <div>
                        <p style={{ marginRight: 56 }}>โทร. 02-279-6215</p>
                      </div>
                    </div>
                    <div className="row" ></div>
                    <div className="row mt-5" >
                      <div className="col-12 d-flex flex-row">
                        <p style={{ fontWeight: 700 }}>Ⅰ.</p>
                        <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-row">
                          <p className="ml-3">กลุ่มวางแผนจัดเก็บรายได้ ตรวจสอบยอดหนี้ค่าเช่าและค่าอื่นๆของผู้ประกอบการแผงค้าที่..................................................</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <p >ชื่อ-นามสกุล</p>
                      </div>
                      <div className="col-5">
                        <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.prefix?.prefixNameTh + " "} {d?.bidderBy?.firstname} {d?.customer?.lastname}</p>
                      </div>
                      <div className="col-5">
                        <p>ที่ต้องชำระในวันทำสัญญาตามรายการดังนี้</p>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-6">
                        <p>1.ค่าเช่าตั้งแต่.......................................................</p>
                      </div>
                      <div className="col-6">
                        <p>7.ค่าประกันความเสียหาย.............................บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>.......................................................................บาท</p>
                      </div>
                      <div className="col-6">
                        <p>8.ค่าประกันการผิดนัดค่าเช่า.........................บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>2.ค่าปรับ.......................................................บาท</p>
                      </div>
                      <div className="col-6">
                        <p>9.ค่าประกันอัคคีภัย.......................................บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>3.ค่าภาษีโรงเรือน.................................................</p>
                      </div>
                      <div className="col-6">
                        <p>10.ค่าใบอนุญาต............................................บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>.......................................................................บาท</p>
                      </div>
                      <div className="col-6">
                        <p>11.ค่าโอนสิทธิ...............................................บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>4.ค่าน้ำประปา...............................................บาท</p>
                      </div>
                      <div className="col-6">
                        <p>12.อื่นๆ.........................................................บาท<span style={{ color: '#fff' }}>................................</span></p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>5.ค่าไฟฟ้า.....................................................บาท</p>
                      </div>
                      <div className="col-6">
                        <p className="pl-3">รวมเป็นเงินทั้งสิ้น......................................บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                        <p>6.ค่าเช่าจ่ายล่วงหน้า 15 วัน..................... .....บาท</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <p className="pl-3">ลงชื่อ..................................................ผู้ตรวจสอบ</p>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <p className="pl-5">(...................................................)</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <div className="d-flex flex-row">
                          <p style={{ fontWeight: 700 }}>ⅠⅠ.</p>
                          <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex flex-row">
                          <p>เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <p className="pl-5">การเงินและบัญชีได้รับเงิน.......................รายการ</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5">บริหารตลาดได้ตรวจสอบเอกสารหลักฐาน</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <p>เป็นเงิน.............................บาท ตามใบเสร็จรับเงินเล่มที่</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <p>เรียบร้อยแล้ว เห็นควรอนุญาติให้ทำสัญญาเช่า</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <p>............เลขที่...........ไว้เรียบร้อยแล้ว และโปรดพิจารณา</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <p className="pl-5">ลงชื่อ........................................ผู้รับเงิน</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5">ลงชื่อ.........................................</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">(.........................................)</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">(.........................................)</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">............../............./..............</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">............../............./..............</p>
                        </div>
                      </div>
                    </div>
                    <p style={{ borderBottom: '1px dotted black', width: '100%' }}></p>
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className="d-flex flex-row">
                          <p style={{ fontWeight: 700 }}>ⅠⅠⅠ.</p>
                          <p className="ml-2">การพิจารณา</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="d-flex flex-row justify-content-center">
                          <input type="checkbox" style={{ width: 20, height: 20 }} />
                          <p className="ml-3">อนุญาต</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="d-flex flex-row justify-content-center">
                          <input type="checkbox" style={{ width: 20, height: 20 }} />
                          <p className="ml-3">ไม่อนุญาต</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5">ลงชื่อ.........................................</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">(.........................................)</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">หัวหน้ากลุ่มบริหารงานตลาด</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                      </div>
                      <div className="col-6">
                        <div>
                          <p className="pl-5 ml-5">............../............./..............</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
              </Style.Styles>}


              {typePrint === "FIND_BY_ID" && <Style.Styles className="pt-3">
                <div className="print-pdf-sarabun">
                  <div className="row">
                    <div className="col-6 d-flex flex-row">
                      <p>วันที่ ...........................................</p>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <div className="d-flex flex-column">
                        <p className="text-right mr-2">กลุ่มบริหารงานตลาด</p>
                        <img src={logo.OTK_Logo} alt="" width="180" height="80" />
                      </div>

                    </div>
                  </div>
                  <div className="row" style={{ margin: '40px 0px' }}>
                    <div className="col-12">
                      <p className="text-center">
                        แบบคำร้องทำสัญญาเช่า
                      </p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="d-flex flex-row col-9">
                      <p className="ml-5">ข้าพเจ้า</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.prefix?.prefixNameTh + " "} {printSelectBidder?.bidderBy?.firstname} {printSelectBidder?.bidderBy?.lastname}</p>
                    </div>
                    <div className="col-3 d-flex flex-row">
                      <p>อายุ</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.birthDay && moment().diff(printSelectBidder?.bidderBy?.birthDay, "years")}</p>
                      <p>ปี</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-3 d-flex flex-row">
                      <p>สัญชาติ</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                    </div>
                    <div className="col-3 d-flex flex-row">
                      <p className="w-50">เชื้อชาติ</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                    </div>
                    <div className="col-3 d-flex flex-row">
                      <p className="w-75">บ้านเลขที่</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.houseNumber + " " || "-"}</p>
                    </div>
                    <div className="col-3 d-flex flex-row">
                      <p className="w-50">หมู่ที่</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.moo + " " || "-"}</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-4 d-flex flex-row">
                      <p>ถนน</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.road + " " || "-"}</p>
                    </div>
                    <div className="col-4 d-flex flex-row">
                      <p style={{ whiteSpace: 'nowrap' }}>แขวง/ตำบล</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: "nowrap" }}>
                        {printSelectBidder?.bidderBy?.district?.districtNameTh || "-"}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-row">
                      <p style={{ whiteSpace: 'nowrap' }}>เขต/อำเภอ</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: "nowrap" }}>
                        {printSelectBidder?.bidderBy?.subDistrict?.subDistrictNameTh || "-"}
                      </p>
                    </div>

                  </div>
                  <div className="row ">
                    <div className="col-4 d-flex flex-row">
                      <p>จังหวัด</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.province?.provinceNameTh || "-"}</p>
                    </div>
                    <div className="col-4 d-flex flex-row">
                      <p>โทรศัพท์</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                        {printSelectBidder?.bidderBy?.mobilePhone || "-"}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-row">
                      <p>อีเมล</p>
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                        {printSelectBidder?.bidderBy?.email || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-3">
                      <p>มีความประสงค์</p>
                    </div>
                    <div className="col-1">
                      {/* <div>
                        <input type="checkbox" checked style={{ width: 20, height: 20 }} />
                      </div> */}
                    </div>
                    <div className="col-8">
                      <div>
                        <div className="d-flex flex-row">
                          <p className="w-100">ขอเช่าพื้นที่ตลาด </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {branch?.find((item: any) => item?.branchId === printSelectBidder?.branchId)?.branchNameTh || "-"}
                          </p>
                          <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {printSelectBidder?.stallNumber || "-"}
                          </p>
                        </div>
                        <div className="d-flex flex-row">
                          <p className="w-25">ประเภทสินค้า </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {printSelectBidder?.stallTypeName || "-"}

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-5">
                    <div className="col-3">

                    </div>
                    <div className="col-1">
                      <div>
                        <input type="checkbox" style={{ width: 20, height: 20 }} />
                      </div>
                    </div>
                    <div className="col-8">
                      <div>
                        <div className="d-flex flex-row">
                          <p className="w-100">ขอต่อสัญญา (สัญญาเลขที่................/................. ลงวันที่.........................................) </p>
                          
                        </div>
                        <div className="d-flex flex-row">
                          <p className="w-100">ประเภทสินค้า </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                          <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-3">
                    <div className="col-12">
                      <p>จึงยื่นคำร้องต่อหัวหน้ากลุ่มบริหารงานตลาด องค์การตลาดเพื่อเกษตรกร โดยยินดีปฏิบัติตามระเบียบ ข้อบังคับ
                        คำสั่ง คำแนะนำ ประกาศ ขององค์การตลาดเพื่อเกษตรกร และหรือการพิจารณาของหัวหน้ากลุ่มบริหารงาน
                        ตลาดทุกประการ โดยไม่มีเงื่อนไขใดๆ ทั้งสิ้น</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <p className="ml-5">จึงเรียนมาเพื่อโปรดพิจารณา</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <p className="ml-5 text-right">ลงชื่อ...........................................................ผู้ยื่นคำร้อง</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <p className=" text-right" style={{ marginRight: 56 }}>(.............................................................)</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <p className=" text-right" style={{ marginRight: 56 }}>.............../....................../.........................</p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div>
                      <p style={{ marginRight: 56 }}>หมายเลขติดต่อ</p>
                    </div>
                  </div>
                  <div className="row mb-2" style={{ pageBreakAfter: 'always' }}>
                    <div>
                      <p style={{ marginRight: 56 }}>โทร. 02-279-6215</p>
                    </div>
                  </div>
                  <div className="row" ></div>
                  <div className="row mt-5" >
                    <div className="col-12 d-flex flex-row">
                      <p style={{ fontWeight: 700 }}>Ⅰ.</p>
                      <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex flex-row">
                        <p className="ml-3">กลุ่มวางแผนจัดเก็บรายได้ ตรวจสอบยอดหนี้ค่าเช่าและค่าอื่นๆของผู้ประกอบการแผงค้าที่..................................................</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <p >ชื่อ-นามสกุล</p>
                    </div>
                    <div className="col-5">
                      <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{printSelectBidder?.bidderBy?.prefix?.prefixNameTh + " "} {printSelectBidder?.bidderBy?.firstname} {printSelectBidder?.bidderBy?.lastname}</p>
                    </div>
                    <div className="col-5">
                      <p>ที่ต้องชำระในวันทำสัญญาตามรายการดังนี้</p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <p>1.ค่าเช่าตั้งแต่...................................................................</p>
                    </div>
                    <div className="col-6">
                      <p>7.ค่าประกันความเสียหาย........................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>.....................................................................................บาท</p>
                    </div>
                    <div className="col-6">
                      <p>8.ค่าประกันการผิดนัดค่าเช่า....................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>2.ค่าปรับ.......................................................................บาท</p>
                    </div>
                    <div className="col-6">
                      <p>9.ค่าประกันอัคคีภัย.................................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>3.ค่าภาษีโรงเรือน............................................................</p>
                    </div>
                    <div className="col-6">
                      <p>10.ค่าใบอนุญาต.....................................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>....................................................................................บาท</p>
                    </div>
                    <div className="col-6">
                      <p>11.ค่าโอนสิทธิ........................................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>4.ค่าน้ำประปา..............................................................บาท</p>
                    </div>
                    <div className="col-6">
                      <p>12.อื่นๆ..................................................................บาท<span style={{ color: '#fff' }}>................................</span></p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>5.ค่าไฟฟ้า....................................................................บาท</p>
                    </div>
                    <div className="col-6">
                      <p className="pl-3">รวมเป็นเงินทั้งสิ้น................................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                      <p>6.ค่าเช่าจ่ายล่วงหน้า 15 วัน...........................................บาท</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <p className="pl-3">ลงชื่อ..................................................ผู้ตรวจสอบ</p>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <p className="pl-5">(...................................................)</p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <div className="d-flex flex-row">
                        <p style={{ fontWeight: 700 }}>ⅠⅠ.</p>
                        <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row">
                        <p>เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <p className="pl-5">การเงินและบัญชีได้รับเงิน.......................รายการ</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5">บริหารตลาดได้ตรวจสอบเอกสารหลักฐาน</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <p>เป็นเงิน.............................บาท ตามใบเสร็จรับเงินเล่มที่</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <p>เรียบร้อยแล้ว เห็นควรอนุญาติให้ทำสัญญาเช่า</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <p>............เลขที่...........ไว้เรียบร้อยแล้ว และโปรดพิจารณา</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <p className="pl-5">ลงชื่อ........................................ผู้รับเงิน</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5">ลงชื่อ.........................................</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">(.........................................)</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">(.........................................)</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">............../............./..............</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">............../............./..............</p>
                      </div>
                    </div>
                  </div>
                  <p style={{ borderBottom: '1px dotted black', width: '100%' }}></p>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="d-flex flex-row">
                        <p style={{ fontWeight: 700 }}>ⅠⅠⅠ.</p>
                        <p className="ml-2">การพิจารณา</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="d-flex flex-row justify-content-center">
                        <input type="checkbox" style={{ width: 20, height: 20 }} />
                        <p className="ml-3">อนุญาต</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-row justify-content-center">
                        <input type="checkbox" style={{ width: 20, height: 20 }} />
                        <p className="ml-3">ไม่อนุญาต</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5">ลงชื่อ.........................................</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">(.........................................)</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">หัวหน้ากลุ่มบริหารงานตลาด</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6">
                      <div>
                        <p className="pl-5 ml-5">............../............./..............</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Style.Styles>}

              {typePrint === "APPROVAL" && <Style.Styles className="pt-3">
                {printApproval && printApproval.map((d: any, i: any) => {
                  return (
                    <div className="print-pdf-sarabun">
                      <div className="row">
                        <div className="col-6 d-flex flex-row">
                          <p>วันที่ ...........................................</p>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          <div className="d-flex flex-column">
                            <p className="text-right mr-2">กลุ่มบริหารงานตลาด</p>
                            <img src={logo.OTK_Logo} alt="" width="180" height="80" />
                          </div>

                        </div>
                      </div>
                      <div className="row" style={{ margin: '40px 0px' }}>
                        <div className="col-12">
                          <p className="text-center">
                            แบบคำร้องทำสัญญาเช่า
                          </p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="d-flex flex-row col-9">
                          <p className="ml-5">ข้าพเจ้า</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.prefix?.prefixNameTh + " "} {d?.bidderBy?.firstname} {d?.bidderBy?.lastname}</p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p>อายุ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.birthDay && moment().diff(d?.bidderBy?.birthDay, "years")}</p>
                          <p>ปี</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-3 d-flex flex-row">
                          <p>สัญชาติ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="w-50">เชื้อชาติ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="w-75">บ้านเลขที่</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.houseNumber + " " || "-"}</p>
                        </div>
                        <div className="col-3 d-flex flex-row">
                          <p className="w-50">หมู่ที่</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.moo + " " || "-"}</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-4 d-flex flex-row">
                          <p>ถนน</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.road + " " || "-"}</p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p style={{ whiteSpace: 'nowrap' }}>แขวง/ตำบล</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: 'nowrap' }}>
                            {d?.bidderBy?.district?.districtNameTh || "-"}
                          </p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p style={{ whiteSpace: 'nowrap' }}>เขต/อำเภอ</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%', whiteSpace: 'nowrap' }}>
                            {d?.bidderBy?.subDistrict?.subDistrictNameTh || "-"}
                          </p>
                        </div>

                      </div>
                      <div className="row ">
                        <div className="col-4 d-flex flex-row">
                          <p >จังหวัด</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.province?.provinceNameTh || "-"}</p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p>โทรศัพท์</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.bidderBy?.mobilePhone || "-"}
                          </p>
                        </div>
                        <div className="col-4 d-flex flex-row">
                          <p>อีเมล</p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.bidderBy?.email || "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-3">
                          <p>มีความประสงค์</p>
                        </div>
                        <div className="col-1">
                          {/* <div>
                            <input type="checkbox" style={{ width: 20, height: 20 }} />
                          </div> */}
                        </div>
                        <div className="col-8">
                      <div>
                        <div className="d-flex flex-row">
                          <p className="w-100">ขอเช่าพื้นที่ตลาด </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {branch?.find((item: any) => item?.branchId === d?.branchId)?.branchNameTh || "-"}
                          </p>
                          <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.stallNumber || "-"}
                          </p>
                        </div>
                        <div className="d-flex flex-row">
                          <p className="w-25">ประเภทสินค้า </p>
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>
                            {d?.stallTypeName || "-"}

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                        {/* <div className="col-8">
                          <div>
                            <div className="d-flex flex-row">
                              <p className="w-100">ขอเช่าพื้นที่ตลาด </p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                              <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            </div>
                            <div className="d-flex flex-row">
                              <p className="w-25">ประเภทสินค้า </p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="row mt-5">
                        <div className="col-3">

                        </div>
                        <div className="col-1">
                          <div>
                            <input type="checkbox" style={{ width: 20, height: 20 }} />
                          </div>
                        </div>
                        <div className="col-8">
                          <div>
                            <div className="d-flex flex-row">
                              <p className="w-100">ขอต่อสัญญา (สัญญาเลขที่................/................. ลงวันที่.........................................) </p>
                            </div>
                            <div className="d-flex flex-row">
                              <p className="w-100">ประเภทสินค้า </p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                              <p className="w-100" style={{ whiteSpace: 'nowrap' }}>แผง/ห้อง/คูหาเลขที่ </p>
                              <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}></p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="row mt-3">
                        <div className="col-12">
                          <p>จึงยื่นคำร้องต่อหัวหน้ากลุ่มบริหารงานตลาด องค์การตลาดเพื่อเกษตรกร โดยยินดีปฏิบัติตามระเบียบ ข้อบังคับ
                            คำสั่ง คำแนะนำ ประกาศ ขององค์การตลาดเพื่อเกษตรกร และหรือการพิจารณาของหัวหน้ากลุ่มบริหารงาน
                            ตลาดทุกประการ โดยไม่มีเงื่อนไขใดๆ ทั้งสิ้น</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <p className="ml-5">จึงเรียนมาเพื่อโปรดพิจารณา</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <p className="ml-5 text-right">ลงชื่อ...........................................................ผู้ยื่นคำร้อง</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <p className=" text-right" style={{ marginRight: 56 }}>(.............................................................)</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <p className=" text-right" style={{ marginRight: 56 }}>.............../....................../.........................</p>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div>
                          <p style={{ marginRight: 56 }}>หมายเลขติดต่อ</p>
                        </div>
                      </div>
                      <div className="row mb-2" style={{ pageBreakAfter: 'always' }}>
                        <div>
                          <p style={{ marginRight: 56 }}>โทร. 02-279-6215</p>
                        </div>
                      </div>
                      <div className="row" ></div>
                      <div className="row mt-5" >
                        <div className="col-12 d-flex flex-row">
                          <p style={{ fontWeight: 700 }}>Ⅰ.</p>
                          <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-row">
                            <p className="ml-3">กลุ่มวางแผนจัดเก็บรายได้ ตรวจสอบยอดหนี้ค่าเช่าและค่าอื่นๆของผู้ประกอบการแผงค้าที่..................................................</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <p >ชื่อ-นามสกุล</p>
                        </div>
                        <div className="col-5">
                          <p style={{ borderBottom: '1px dotted black', textIndent: 20, width: '100%' }}>{d?.bidderBy?.prefix?.prefixNameTh + " "} {d?.bidderBy?.firstname} {printSelectBidder?.bidderBy?.lastname}</p>
                        </div>
                        <div className="col-5">
                          <p>ที่ต้องชำระในวันทำสัญญาตามรายการดังนี้</p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-6">
                          <p>1.ค่าเช่าตั้งแต่...................................................................</p>
                        </div>
                        <div className="col-6">
                          <p>7.ค่าประกันความเสียหาย........................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>.....................................................................................บาท</p>
                        </div>
                        <div className="col-6">
                          <p>8.ค่าประกันการผิดนัดค่าเช่า....................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>2.ค่าปรับ.......................................................................บาท</p>
                        </div>
                        <div className="col-6">
                          <p>9.ค่าประกันอัคคีภัย.................................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>3.ค่าภาษีโรงเรือน............................................................</p>
                        </div>
                        <div className="col-6">
                          <p>10.ค่าใบอนุญาต.....................................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>....................................................................................บาท</p>
                        </div>
                        <div className="col-6">
                          <p>11.ค่าโอนสิทธิ........................................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>4.ค่าน้ำประปา..............................................................บาท</p>
                        </div>
                        <div className="col-6">
                          <p>12.อื่นๆ..................................................................บาท<span style={{ color: '#fff' }}>................................</span></p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>5.ค่าไฟฟ้า....................................................................บาท</p>
                        </div>
                        <div className="col-6">
                          <p className="pl-3">รวมเป็นเงินทั้งสิ้น................................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                          <p>6.ค่าเช่าจ่ายล่วงหน้า 15 วัน...........................................บาท</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                          <p className="pl-3">ลงชื่อ..................................................ผู้ตรวจสอบ</p>
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                          <p className="pl-5">(...................................................)</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <div className="d-flex flex-row">
                            <p style={{ fontWeight: 700 }}>ⅠⅠ.</p>
                            <p className="ml-2">เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex flex-row">
                            <p>เรียน   หัวหน้ากลุ่มบริหารงานตลาด</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p className="pl-5">การเงินและบัญชีได้รับเงิน.......................รายการ</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5">บริหารตลาดได้ตรวจสอบเอกสารหลักฐาน</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p>เป็นเงิน.............................บาท ตามใบเสร็จรับเงินเล่มที่</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <p>เรียบร้อยแล้ว เห็นควรอนุญาติให้ทำสัญญาเช่า</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p>............เลขที่...........ไว้เรียบร้อยแล้ว และโปรดพิจารณา</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p className="pl-5">ลงชื่อ........................................ผู้รับเงิน</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5">ลงชื่อ.........................................</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">(.........................................)</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">(.........................................)</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">............../............./..............</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">............../............./..............</p>
                          </div>
                        </div>
                      </div>
                      <p style={{ borderBottom: '1px dotted black', width: '100%' }}></p>
                      <div className="row mt-2">
                        <div className="col-12">
                          <div className="d-flex flex-row">
                            <p style={{ fontWeight: 700 }}>ⅠⅠⅠ.</p>
                            <p className="ml-2">การพิจารณา</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex flex-row justify-content-center">
                            <input type="checkbox" style={{ width: 20, height: 20 }} />
                            <p className="ml-3">อนุญาต</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex flex-row justify-content-center">
                            <input type="checkbox" style={{ width: 20, height: 20 }} />
                            <p className="ml-3">ไม่อนุญาต</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5">ลงชื่อ.........................................</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">(.........................................)</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">หัวหน้ากลุ่มบริหารงานตลาด</p>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ pageBreakAfter: 'always' }}>
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                          <div>
                            <p className="pl-5 ml-5">............../............./..............</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Style.Styles>}
            </>
          }
        />
      </div>
      {loading && <Loading show={loading} type="fullLoading" />}
    </div>
  );
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
