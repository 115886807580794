import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Button, Grid, Typography, Input, Box } from '@mui/material'
import { colors } from '../../constants/colors'
import { background } from '../../constants/images'

export const CardLogin = styled(`div`)(({ theme }) => ({
  backgroundColor: ` ${colors.bgBase} !important`,
  height: '100vh',
  width: '100%',
  overflow: 'auto',
  backgroundImage: `url(${background.BG_Login})`,
  background: `url(${background.BG_Login})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // backgroundPositionX: '5rem',
  backgroundPositionY: '-10rem',
  color: 'unset !important',
  '& .card-text': {
    fontSize: '16px',
    textAlign: 'left'
  },
  [theme.breakpoints.down('lg')]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
  },
  [theme.breakpoints.down(960)]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
    
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
  }
}))

export const CardRegister = styled(`div`)(({ theme }) => ({
  backgroundColor: ` ${colors.bgBase} !important`,
  height: '100vh',
  width: '100%',
  overflow: 'auto',
  backgroundImage: `url(${background.waterMark})`,
  background: `url(${background.waterMark})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // backgroundPositionX: '5rem',
  backgroundPositionY: '-5rem',
  color: 'unset !important',
  '& .card-text': {
    fontSize: '16px',
    textAlign: 'left'
  },
  [theme.breakpoints.down('lg')]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
  },
  [theme.breakpoints.down(960)]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
    
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${background.waterMark})`,
    backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '2rem',
  }
}))

export const UploadImg = styled(`div`)(({ theme }) => ({

  display: 'flex',
  marginRight: "35px",
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    marginRight: "auto",
  },

}))
export const UploadImgres = styled(`div`)(({ theme }) => ({
  display: 'none',



  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    marginRight: "auto",
    width: '100%',
    marginBottom: '16px',
  },

}))
export const Language = styled(`div`)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '32px',



  [theme.breakpoints.down('sm')]: {
    display: 'none',

  },

}))
export const Languageres = styled(`div`)(({ theme }) => ({

  display: 'none',



  [theme.breakpoints.down('sm')]: {
    display: 'flex',

    alignItems: 'center',
    marginTop: '14px',
    justtifyContent: 'center',

  },

}))
export const CardFormLogin = styled(Grid)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: '15%',
  maxWidth: 450,
  [theme.breakpoints.down('md')]: {
    minWidth:'320px',
    padding:'20px',
    marginRight: 'auto',
    'img[alt="Logo"]': {
      maxWidth: 150
    }
  },
  [theme.breakpoints.up('xl')]: {
    marginRight: '20%',
    textAlign: 'center'
  }
}))
export const TitalForgetPassword = styled(Typography)(({ theme }) => ({
  fontFamily: 'Kanit',
  marginTop: '24px !important',
  marginBottom: '0 !important',
  fontSize: '1.125rem !important',
  fontWeight: 600
}))
export const ButtonGoBack = styled(Button)(({ theme }) => ({
  paddingTop: 16,
  fontSize: 16,
  '&:hover': {
    backgroundColor: 'unset !important',
    textDecoration: 'underline !important'
  }
}))
export const ButtonForgetPassword = styled('a')(({ theme }) => ({
  color: colors.textPrimary,
  height: '100%',
  display: 'flex',
  // paddingRight: 16,
  margin: 'auto',
  fontSize: '16px',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    color: `${colors.green} !important`
  }
}))

export const Logo = styled('img')(({ theme }) => ({
  width: 240,
  marginBottom: '40px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px'
  }
}))
export const Headder = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  paddingBottom: '15px',
  marginTop: '25px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: '8px',
  }
}))

export const Label = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: colors.black
}))

export const PolicyText = styled(Typography)(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  fontWeight: 600,
  color: colors.red,
  
  ':hover': {
    textDecoration: 'underline',
    textDecorationColor: colors.red
  }
}))

export const BGModal = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'start'
}))
export const BoxflexHeader = styled(Box)(({ theme }) => ({

  display: 'flex',
  boxShadow: '1px 2px 9px #707070',
  backgroundColor: colors.white,
  padding: '1em',

}))
export const Boxflex = styled(Box)(({ theme }) => ({

  display: 'flex',


}))
export const CarInputRegister = styled(Box)(({ theme }) => ({

  width: '100%', height: 'auto', backgroundColor: '#fafafa',
  marginTop: '15px',
  padding: '24px'

}))
export const CarRegister = styled(Box)(({ theme }) => ({

  border: '1px solid rgba(0,0,0,0.3)',
  width: '100%',
  height: 'auto',
  borderRadius: '5px',
  marginTop: '15px',
  marginBottom: '35px',
  backgroundColor: colors.white,
  padding: '24px'
}))
// export const License = styled('div')(({ theme }) => ({
//   [theme.breakpoints.down('sm')]: {
//     paddingTop: 16
//   }
// }))

export const SelectbranchContainer = styled(Box)(({ theme }) => ({
  padding: '96px',
  display: 'flex',
  height: '100%',
  overflow: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection:'column',
  backgroundImage: `url(${background.homePageBackgroundadmin})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    padding: '0px',
  }
}))

export const SelectbranchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: 8,
  boxShadow: '2px 8px 15px -2px rgba(0, 0, 0, 0.30)',
  marginTop: 24,
  padding: '56px 56px 20px 56px',
  backgroundColor: colors.white,
  [theme.breakpoints.down('md')]: {
    // height: '100%',
    padding: '20px',
  }
}))

export const Slicker = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.white,
  [theme.breakpoints.down('md')]: {
    height: '100%',
    padding: '0px 20px',
  }
}))

export const Divbranch = styled(`div`)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    overflowX: 'scroll'
  }
}))

export const Cardstill = styled(Box)(({ theme }) => ({
  height: 240,
  width: 280,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // border: '1px solid #D9D9D9',
  cursor: 'pointer',
  color: colors.themeMainColor,
  marginTop: 8,
  padding: '16px',

}))

export const GridLogin = styled(Grid)(({ theme }) => ({
 
    justifyItems: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    display: 'flex'
  
}))

export const CardBranch = styled(Box)(({ theme}) => ({
  alignSelf:'center',
  // border: '1px solid red',
  border: '1px solid #D9D9D9',
  width:'100%',
  height: '100%',
  padding: 16,
  borderRadius: 4,
    ':hover': {
    background: colors.themeMainColor,
    '& svg':{
      color: colors.white,
    },
    color: colors.white,
    boxShadow: '2px 8px 15px -2px rgba(0, 0, 0, 0.30)',
  }
}))

const useStyles = makeStyles({
  login: {
    justifyItems: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    display: 'flex'
  },
  loginText: {
    '&.login-text': {
      fontFamily: 'Kanit',
      fontSize: '18px',
      fontWeight: 500,
      marginBottom: '10px',
      marginTop: '10px',

    }
  },

  typographySuccess: {
    fontFamily: 'Kanit',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '20px',
    color: '#707070'
  },
  flex: {
    display: 'flex',

  },
  flexHeader: {
    display: 'flex',
    boxShadow: '1px 2px 9px #707070',

    padding: '1em',

  },
  inputCheckBox: {
    '& $label': {
      fontFamily: 'Kanit',
      fontSize: '16px',
      fontWeight: 500
    },
    '& $span': {
      fontSize: '16px'
    }
  },
  card: {
    backgroundColor: colors.white,
    margin: 'auto',
    maxWidth: 400,
    width: '100%',
    borderRadius: `10px !important`,
    opacity: 1,
    '& .card-body': {
      backgroundColor: colors.white,
      borderRadius: 10
    },
    '&.forget-password .btn-submit': {
      lineHeight: '44px',
      marginTop: 14,
      boxShadow: 'none',
      fontSize: '1rem'
    }
  },
  cardBody: {
    backgroundColor: colors.white,
    margin: 'auto',
    width: '300px',
    padding: '30px 15px'
  },
  checked: {
    color: colors.green
  },

  license: {
    padding: 20
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '15px',
    marginTop: '25px',

  },
  carRegister: {
    border: '1px solid rgba(0,0,0,0.3)',
    width: '100%',
    height: 'auto',
    borderRadius: '5px',
    marginTop: '15px',
    marginBottom: '35px',
    backgroundColor: colors.white,
    padding: '24px'
  },
  carInputRegister: {
    width: '100%', height: 'auto', backgroundColor: '#fafafa',
    marginTop: '15px',
    padding: '24px'
  }
})

export default useStyles
