import BaseAPI from "../api";

const path = "utilities";

interface utilities {
  page: number;
  pageLimit:number;
  sortType: string;
  sortBy:string;
  search:string;
  status:string
  branchId: number;
  utilitiesNo: string;
  customerId:number;
  utilitiesTypeId: number;
  utilitiesDate: string;
  utilitiesMonth: number;
  leaseAgreementId: number;
  zoneId: number;
  stallId: number;
  utilitiesDetail: any;
  
  leasesTypeId?:number;
}

export default class UtilitieApi extends BaseAPI {
  static findAll(props: utilities): Promise<any> {
    return this.api.get(path, { params: { ...props } }).then((res) => res);
  }
  static create(props:any): Promise<any> {
    return this.api.post(path, props ).then((res) => res);
  }
  static update(props:any,id:any): Promise<any> {
    return this.api.patch(`${path}/${id}`, props ).then((res) => res);
  }
  static detail(id:number): Promise<any> {
    return this.api.get(`${path}/${id}`).then((res) => res);
  }
  static delete(id: number) {
    return this.api.delete(`${path}/${id}`)
  }
  static day() {
    return this.api.get(`utilities-day`)
  }
}
