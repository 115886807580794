import BaseAPI from "../api";

const path = "file-upload";

export interface fileUploadInterface {
  refId?: number;
  fileType?: string;
  file?: any
  files?: any
}
export default class FileuploadApi extends BaseAPI {
  static uploadfile(props: any): Promise<any> {
    return this.api.post(path, props).then((res) => res);
  }

  static uploadfilearray(props: any): Promise<any> {
    return this.api.post(`${path}/multiple`, props).then((res) => res);
  }
}
