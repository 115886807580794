import BaseAPI from "../../api";

const path = "deposit";

export interface DepositInterface {
  page?: number;
  pageLimit?: number;
  id?: number;
  userId?: number;
  branchId?: number;
  bidderId?: number;
  depositNo?: string;
  depositDate?: string;
  depositDateEnd?: string;
  depositAmount?: number;
  depositRef?: string;
  depositTypeId?: number;
  depositPayDate?: string;
  depositId?: number;
  appointmentId?: number;
  stallId?: number;
  refId?:number
  fileType?:string
}

export interface IPayloadCreate {
  userId: number;
  branchId: number;
  bidderId: number;
  depositDate: Date;
  depositDateEnd: Date;
  depositAmount: number;
}
export interface IParamsFindAll {
  page?: number;
  pageLimit?: number;
  status?: "WAITING" | "PROGRESS" | "SUCCESS" | "NOT_APPROVE";
  search?: string;
}

export default class DepositApi extends BaseAPI {
  static findAll(params: IParamsFindAll): Promise<any> {
    return this.api.get(path, { params: { ...params } }).then((res) => res);
  }
  static findAllAdmin(params: IParamsFindAll): Promise<any> {
    return this.api.get(`${path}/admin`, { params: { ...params } }).then((res) => res);
  }

  static create(payload: IPayloadCreate): Promise<any> {
    return this.api.post(path, payload);
  }

  static createPay(payload: DepositInterface): Promise<any> {
    return this.api.post(`${path}/pay`, payload);
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`${path}/${id}`).then((res) => res);
  }

  static update(id: number, body: DepositInterface): Promise<any> {
    return this.api.patch(`${path}/${id}`, body);
  }

  static updateSuccess(
    id: number,
    body: { appointmentId: number }
  ): Promise<any> {
    return this.api.patch(`${path}/${id}/success`, body);
  }

  static updateStatus(
    id: number,
    body: { status: "WAITING" | "PROGRESS" | "SUCCESS" | "NOT_APPROVE" }
  ): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, body);
  }

  static bank(id: number): Promise<any> {
    return this.api.get(`${path}/${id}/bank`,{ responseType: 'arraybuffer' });
  }
  static receipt(id: number): Promise<any> {
    return this.api.get(`${path}/${id}/receipt`,{ responseType: 'arraybuffer' });
  }
}
