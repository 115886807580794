import { menuFirsts } from "../../constants/menu"
import CancelContract from "../../pages/cancelContract/cancelContract"


const routerCancelContract = [
    {
      ...menuFirsts.CANCEL_CONTACT,
      key: menuFirsts.CANCEL_CONTACT.key,
      name: menuFirsts.CANCEL_CONTACT.name,
      textName: menuFirsts.CANCEL_CONTACT.textName,
      active: false,
      appBar: true,
      component: CancelContract
    } 
  ]
  
  export default routerCancelContract