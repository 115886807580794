import { useState } from 'react'
import { AppBar, ButtonBase, Toolbar, Typography } from '@mui/material'

/** ASSETS */
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { logo, onImgError } from '../../constants/images'

/** CONSTANTS */
import { colors } from '../../constants/colors'
import { scale } from '../../constants/scale'

/** COMPONENT */
import UserMenu from './UserMenu'
import { routeName } from '../../routes/routers-name'
import { menuSeconds } from '../../constants/menu'
import { Home } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { decode } from 'js-base64'
import { getProfile } from '../../utils/app.utils'

const AppBarMain = styled(AppBar)(({ theme }) => ({
  height: scale.navbarHeight,
  padding: 0,
  margin: 0,
  top: 0,
  position: 'sticky',
  display: 'block',
  alignContent: 'center',
  justifyContent: 'center',
  background: colors.white,
  backdropFilter: 'saturate(1) blur(40px)',
  boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
  border: 'none',
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down('xl')]: {
    height: `calc(${scale.navbarHeight} - 1rem)`
  },
  [theme.breakpoints.down('lg')]: {
    height: `calc(${scale.navbarHeight} - 1.5rem)`
  },
  [theme.breakpoints.down('sm')]: {
    height: `calc(${scale.navbarHeight} - 2.5rem)`
  },
  '&.header .MuiToolbar-root': {
    minHeight: '0',
    width: '100%'
  },
  '& .logo-clinic': {
    maxHeight: 70,
    maxWidth: 120,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 50,
      maxWidth: 95,
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 40,
      maxWidth: 80,
    }
  },
  '& .menu-header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginLeft: 'auto',
    textAlign: 'end',
    border: 'none !important',
    [theme.breakpoints.down('lg')]: {
      '& .div-label-menu': {
        display: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '& .iconMenu': {
      width: 25,
      height: 25,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%'
    },
    '& a:nth-of-type(3) .iconMenu': {
      width: 32
    },
    '& a:nth-of-type(5) .iconMenu': {
      width: 21
    }
  },
  '& .menuName': {
    paddingLeft: '2rem',
    fontSize: '24px !important',
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  '& .menuLabel': {
    color: 'black !important',
    fontSize: `16px !important`,
    border: 'none !important'
  },
  '& .benTo': {
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    '& button': {
      padding: 0,
      minWidth: 'unset'
    }
  },
  '& .menuIconHem': {
    color: colors.themeMainColor,
    marginRight: '.75rem',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  '& .sizeIcon': {
    width: '26px',
    height: '26px'
  },
  '& .btn-menu-nav': {
    borderRadius: '0.4rem !important'
  },
  '& .btn-menu-nav:hover': {
    backgroundColor: colors.green_06
  },
  '& .btn-menu-nav.active': {
    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 1%, rgba(242, 242, 242, 0.8) 171%)'
  }
}))


type HeaderProps = {
  menuName?: string | null
}

export default function Header(props: HeaderProps) {
  const { t } = useTranslation();
  const profile: any = JSON.parse(getProfile() || "{}");

  // const [headerBar, setHeaderBar] = useState<null | HTMLElement>(null)
  // const openHeaderBar = Boolean(headerBar)

  // const handleClickHeader = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setHeaderBar(event.currentTarget)
  // }
  // const handleCloseHeader = () => {
  //   setHeaderBar(null)
  // }

  const [sideBar, setSideBar] = useState(false)

  function containerMenu() {
    const sidebarHTML: any = document.querySelector('.container-menu .menu-first')
    if (!sideBar) {
      sidebarHTML.classList.add('show')
      setSideBar(true)
    } else {
      sidebarHTML.classList.remove('show')
      setSideBar(false)
    }
  }

  const menus = [
    {
      key: menuSeconds.HOME.key,
      label: 'หน้าหลัก',
      disabled: false,
      icon: Home,
      // icon_active: HomeActive,
      active: true,
      route: '/'
    },
    // {
    //   key: menuSeconds.INCOME.key,
    //   packageKey: menuSeconds.INCOME.packageKey,
    //   label: menuSeconds.INCOME.textName,
    //   disabled: false,
    //   // icon: Chart,
    //   // icon_active: ChartActive,
    //   active: true,
    //   // route: routeName.dashboard
    // },
    // {
    //   key: menuSeconds.CONTACT_US.key,
    //   label: menuSeconds.CONTACT_US.textName,
    //   disabled: false,
    //   icon: FeatherUser,
    //   icon_active: FeatherUserActive,
    //   active: true,
    //   route: routeName.register
    // },
    {
      key: menuSeconds.CALCULATION.key,
      label: menuSeconds.CALCULATION.textName,
      disabled: JSON.parse(decode(profile))?.roleId === 4,
      // icon: FeatherUser,
      // icon_active: FeatherUserActive,
      active: true,
      route: routeName.Calculation
    },
  ]

  const isActive = (route: string): boolean => {
    return route === window.location.pathname ? true : false
  }

  return (
    <>
      <AppBarMain position='static' className={'header top-0 d-flex'} >
        <Toolbar variant="regular" className={`px-3 px-md-4 m-0`} sx={{ justifyContent: 'space-between' }}>
          <div
            className="menuIconHem"
            onClick={(e) => {
              containerMenu()
            }}
          >
            <MenuIcon />
          </div>
          <img className={`logo-clinic cursor-pointer`} src={logo.logoMof} alt="logo-clinic" onClick={() => window.location.href = routeName.home} onError={onImgError} />
          <div className={'menu-header'}>
            {menus.map(({ label, disabled, icon: Icon, icon_active: IconActive, active, key, packageKey, ...rest }: any, index) => (
              <>
                {!disabled && <ButtonBase
                  href={rest.route || '#'}
                  style={{
                    margin: '0 5px',
                    transform: 'unset'
                  }}
                  key={label}
                  className={`p-2 mx-2 rounded btn-menu-nav ${isActive(rest.route || 'POST') ? 'active' : ''}`}
                  disableRipple
                >
                  <div className={'flex-center'}>
                    <div>
                      {/* <div className={'iconMenu'}>{(isActive(rest.route || '#') && <IconActive disabled={disabled} active={isActive(rest.route || '#')} />) || <Icon disabled={disabled} active={isActive(rest.route || '#')} />}</div> */}
                    </div>
                    <div className={'div-label-menu'}>
                      <Typography className={'menuLabel pl-2'} noWrap>
                        {t(label)}
                      </Typography>
                    </div>
                  </div>
                </ButtonBase>}
              </>

            ))}
          </div>
          <UserMenu />
        </Toolbar>
      </AppBarMain>
    </>
  )
}
