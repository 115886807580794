import React, { useCallback, useEffect, useState } from "react";
import TableCustom from "../../../component/table/tableCustom";
import ReportApi from "../../../api/reports/reports.api";
import TableRowCommon from "../../../component/table/TableRowCommon";
import { numberFormat } from "../../../utils/common";

const RevenueCollectionReport = ({
  date_start,
  date_end,
  search_type,
}: {
  date_start: string;
  date_end: string;
  search_type: string;
}) => {
  const [data, setData] = useState<any>([]);

  const handleGetData = useCallback(async () => {
    let condition: any = {
      start_date: date_start ?? "",
      end_date: date_end ?? "",
      type: search_type.toLowerCase() ?? "",
    };

    try {
      const res = await ReportApi.getRevenueCollection(condition);

      if (res.status === 200 && res.data) {
        setData(res.data.data?.revenue ?? []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [date_start, date_end, search_type]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const headCells = [
    {
      id: "no",
      disablePadding: false,
      label: "#",
      align: "center",
    },
    {
      id: "zone",
      disablePadding: false,
      label: "พื้นที่",
      align: "center",
    },
    {
      id: "storage_plan",
      disablePadding: false,
      label: "แผนจัดเก็บ",
      align: "center",
    },
    {
      id: "storage_results",
      disablePadding: false,
      label: "ผลจัดเก็บ",
      align: "center",
    },
    {
      id: "overdue",
      disablePadding: false,
      label: "ค้างชำระ",
      align: "center",
    },
    {
      id: "fine",
      disablePadding: false,
      label: "ค่าปรับ",
      align: "center",
    },
  ];

  const renderData = (objData: any, no: any) => {
    const {
      type,
      zoneId,
      z_zoneName,
      zoneName,
      total,
      pay_approve,
      not_pay,
      penalty,
      pay_approve_percent,
      not_pay_percent,
      total_percent,
    } = objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no + 1 },
        {
          option: "TEXT",
          align: "center",
          label: type && type === "total" ? zoneName : z_zoneName,
        },
        {
          option: "TEXT",
          align: "center",
          label:
            type && type === "total"
              ? numberFormat(total_percent, 0, 0)
              : numberFormat(total),
        },
        {
          option: "TEXT",
          align: "center",
          label:
            type && type === "total"
              ? numberFormat(pay_approve_percent, 0, 0)
              : numberFormat(pay_approve),
        },
        {
          option: "TEXT",
          align: "center",
          label:
            type && type === "total"
              ? numberFormat(not_pay_percent, 0, 0)
              : numberFormat(not_pay),
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(penalty, 0, 0) ?? null,
        },
      ],
    };

    return <TableRowCommon {...objRenderData} />;
  };

  return (
    <div>
      <TableCustom
        headCells={headCells}
        customScroll
        page={1}
        pageLimit={1}
        hidePagination
        sortType={"ASC"}
        sortBy=""
        rowCount={0}
        rowsData={
          data &&
          data?.map((d: any, index: any) => {
            return renderData(d, index);
          })
        }
        onSort={() => {}}
        setPageLimit={(newValue) => {}}
        setPage={(newValue) => {}}
      />
    </div>
  );
};

export default RevenueCollectionReport;
