import { useTranslation } from "react-i18next";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import * as Style from "../transferStall.style";
import { imgs } from "../../../constants/images";
import { Col, Row } from "react-bootstrap";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import InputDatePicker from "../../../component/input/inputDatePicker";

type PopupProps = {
  onclose?: any,
  onsubmit?: any,
  data?: any,
  getRootProps?: any,
  getInputProps?: any,
  select?: any,
  file?: any
  transferDate?: any
  onchangeTransferDate?: any
}

export default function AcceptTransfer(props: PopupProps) {
  const { t } = useTranslation();

  return (
    <ModalCustom
      title=""
      size="xl"
      closeButton
      onClose={props.onclose}
      onSubmit={props.onsubmit}
      textBtnConfirm={t("TRANSFER_STALL.BUTTON.ACCEPT")}
      component={
        <>
          <Style.ContainerUpload>
            <Style.ContainerBoxUpload>
              <Style.BoxModal>
                <Row>
                  <Style.SubDetail>
                    {t("รายละเอียดสัญญาโอนสิทธิ")}
                  </Style.SubDetail>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex flex-row">
                      <Style.SubDetail>
                        {t('TRANSFER_STALL.MODAL.ZONE')}
                      </Style.SubDetail>
                      <Style.SubDetail>{" "}{props.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</Style.SubDetail>
                    </div>

                  </Col>
                  <Col lg={12}>
                    <div className="d-flex flex-row">
                      <Style.SubDetail>
                        {t('TRANSFER_STALL.MODAL.STALL')}
                      </Style.SubDetail>
                      <Style.SubDetail>
                        {" "}{props.data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}
                      </Style.SubDetail>
                    </div>
                  </Col>
                  <Col lg={3} xs={6} className="mt-2">
                    {t("วันที่ทำสัญญา")}
                  </Col>
                  <Col lg={9} xs={6} className="mt-2">
                    <InputDatePicker
                      value={props.transferDate}
                      key={'date'}
                      onChange={props.onchangeTransferDate}
                    />
                  </Col>
                  <Col lg={3} xs={6} className="mt-2">
                    {t("วันที่สิ้นสุด")}
                  </Col>
                  <Col lg={3} xs={6} className="mt-2">
                    {props.data?.leaseAgreement?.leaseAgreementDateEnd}
                  </Col>
                </Row>

              </Style.BoxModal>

              <Style.BoxUpload>
                <div {...props?.getRootProps()}>
                  <input {...props?.getInputProps()} />
                  {props?.select ? (
                    <img
                      src={props?.select}
                      alt="Thumb"
                      style={{ width: "100%", height: "250px" }}
                    />
                  ) : (
                    <Style.BoxUploadImage
                      src={imgs.upload}
                    // onClick={() => props.onUpload(img)}
                    />
                  )}


                </div>

              </Style.BoxUpload>
            </Style.ContainerBoxUpload>
          </Style.ContainerUpload>
        </>
      }
    />
  );
}
