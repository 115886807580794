import { useEffect, useState } from "react";
import {
  BoxImg,
  BoxImgTop,
  CardHomepage,
  Cardstill,
  GridNew,
  TypographyTextres,
  DateInTable,
  DateInBox,
  NewsHeading,
  NewsBox,
  NewsTitle,
  Readmore,
  DateHeading,
  Cardinfo,
  BoxInfo,
  BoxZone,
  BoxFlex,
} from "./home.style";
import {
  Box,
  Container,
  Grid,
  TableContainer,
  Typography,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import logo from "../../assets/logo/logotest.png";
import Store from "../../assets/slide-menu.tsx/store";
import Rental from "../../assets/slide-menu.tsx/stall";
import ElectricityBill from "../../assets/slide-menu.tsx/electricity-bill";
import PayBill from "../../assets/slide-menu.tsx/pay-bill";
import SystemLog from "../../assets/slide-menu.tsx/systemLog";
import TrackBill from "../../assets/slide-menu.tsx/track-bill";
import Repair from "../../assets/slide-menu.tsx/repair";
import LeaseHold from "../../assets/slide-menu.tsx/leasehold";
import Certificate from "../../assets/slide-menu.tsx/certificate";
import Sue from "../../assets/slide-menu.tsx/sue";
import Help from "../../assets/slide-menu.tsx/announce";
import CancelContact from "../../assets/slide-menu.tsx/cancel-contact";
import NewsIcon from "../../assets/slide-menu.tsx/News_icon";
import TableRowCommon from "../../component/table/TableRowCommon";
import ModalCustom from "../../component/modalCustom/modalCustom";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import { useTranslation } from "react-i18next";
import { routeName } from "../../routes/routers-name";
import { imgs } from "../../constants/images";
import { useHistory } from "react-router-dom";
import AnnounceApi from "../../api/home/announce.api";
import {
  dateToView,
  fullDate,
  lnessDatemonth,
  shortnessDate,
} from "../../utils/date.utils";
import { colors } from "../../constants/colors";
import LeaseAgreementApi from "../../api/stall/leaseAgreement/leaseAgreement.api";
import { getPermissionMenus, getProfile, setApprove } from "../../utils/app.utils";
import BidderApi from "../../api/stall/bidder/bidder.api";
import { numberFormat } from "../../utils/common";
import _ from "lodash";
import TableCustom from "../../component/table/tableCustom";
import { editProfile } from "../../app/slice/user.slice";
import { useDispatch } from "react-redux";
import { decode } from "js-base64";
import { showModal } from "../../app/slice/modal.slice";
import ModalBlockAccount from "../../component/modalCustom/modalBlockAccount";
import InvoiceApi from "../../api/invoid/invoid.api";
import ModalHaveDept from "../../component/modalCustom/modalHaveDept";
import ApproveApi from "../../api/transferApporve/tranferApprove.api";

export default function Home() {
  const history = useHistory();
  const { t } = useTranslation();
  const profile: any = JSON.parse(getProfile() || "{}");
  const dispatch = useDispatch();
  const [newsData, setNewsData] = useState<any[]>([]);
  const [BookmarkData, getBookmarkData] = useState<any[]>([]);
  const [agreement, setAgreement] = useState<any[]>([]);
  const [bidderData, setBidderData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowcount, setRowCount] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [blockAccount, setBlockAccount] = useState<boolean>(false)
  const [depted, setDepted] = useState<boolean>(false)
  const [userId, setUserId] = useState<number>(
    JSON.parse(decode(profile))?.userId
  );
  const [roleId, setRoleId] = useState<number>(
    JSON.parse(decode(profile))?.roleId
  );
  const permissionMenus = getPermissionMenus();

  useEffect(() => {
    setUserId(JSON.parse(decode(profile))?.userId);
    setRoleId(JSON.parse(decode(profile))?.roleId);
    dispatch(editProfile(JSON.parse(decode(profile))));
  }, []);

  const getApprove = async () => {
    await ApproveApi.findRight().then((res: any) => {
      if (res.status == 200) {
        setApprove(res.data)
      }
    });
  };

  useEffect(() => {
    getApprove()
  }, []);

  const getNews = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 50,
      status: "APPROVE",
      sortBy: "createDate",
      sortType: "DESC",
    };
    const res = await AnnounceApi.findAll(condition);
    if (res.status === 200) {
      setRowCount(res?.headers["total"]);
      setNewsData(res.data);
    }
  };

  const getBookmark = async () => {
    const res = await AnnounceApi.findBookmark();
    if (res.status === 200) {
      getBookmarkData(res.data);
    } else {
      getBookmarkData([]);
    }
  };

  useEffect(() => {
    getNews();
    getBookmark();
  }, [page, pageLimit]);

  const getAgreement = async () => {
    const condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: JSON.parse(decode(profile))?.userId,
    };
    await LeaseAgreementApi.findAll(condition)
      .then((res) => {
        if (res.status === 200) {
          setAgreement(res.data);
        } else {
          setAgreement([]);
        }
      })
      .catch(() => {
        setAgreement([]);
      });
  };

  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId === 4) {
      getAgreement();
    }
  }, []);

  const loadBidder = async () => {
    const condition: any = {
      page: page,
      pageLimit: pageLimit,
      userId: JSON.parse(decode(profile))?.userId,
    };
    await BidderApi.findAll(condition)
      .then((res) => {
        if (res.status === 200) {
          setBidderData(res.data);
        } else {
          setBidderData([]);
        }
      })
      .catch(() => {
        setBidderData([]);
      });
  };

  useEffect(() => {
    if (JSON.parse(decode(profile))?.roleId === 4) {
      loadBidder();
    }
  }, []);

  const checkInvoice = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 10,
      userId: JSON.parse(decode(profile))?.userId,
      status: "NOT_PAY"
    }
    const res = await InvoiceApi.findAll(condition)
    if (res.status === 200) {
      if (res.data.length > 4) {
        setBlockAccount(true)
        dispatch(showModal())
      }
      if (res.data.length > 3 && res.data.length <= 4) {
        setDepted(true)
        dispatch(showModal())
      }

    } else {

    }
  }

  useEffect(() => {
    checkInvoice()
  }, [])

  const renderData = (objData: any) => {
    // no = page * pageL  imit - pageLimit + no + 1

    const { startDate, title, announceTypeId, detail, announceId, newsType } =
      objData;

    const objRenderData = {
      key: announceId,
      id: announceId,
      obj: objData,
      columns: [
        {
          option: "COMPONENT",
          align: "center",
          width: 20,
          component: (
            <DateInTable>
              <DateInBox>{shortnessDate(startDate).substring(0, 2)}</DateInBox>
              <DateInBox>{lnessDatemonth(startDate)}</DateInBox>
            </DateInTable>
          ),
        },
        {
          option: "COMPONENT",
          width: 500,
          component: (
            <NewsBox>
              <div className="d-flex flex-row">
                <NewsHeading>{newsType.newstypeNameTh} </NewsHeading>
                <DateHeading>
                  <AccessTimeFilledIcon
                    style={{
                      color: JSON.parse(decode(profile))?.roleId
                        ? JSON.parse(decode(profile))?.roleId == 4
                          ? colors.themeMainColor
                          : colors.themeSecondColor
                        : colors.themeMainColor,
                      marginRight: 8,
                      fontSize: "18px",
                    }}
                  />
                  {t("HOME.TABLE.ANNOUNCE_DATE")} {fullDate(startDate)}
                </DateHeading>
              </div>
              <NewsTitle> {title}</NewsTitle>
            </NewsBox>
          ),
        },

        {
          option: "COMPONENT",
          align: "left",
          component: (
            <NewsBox
              className="d-flex align-item-end"
              onClick={() =>
                history.push(`${routeName.detailinfo}/${announceId}`)
              }
            >
              <Readmore>{t("HOME.TEXT.READ_MORE")}</Readmore>
            </NewsBox>
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };
  return (
    <>
      {/* {blockAccount ?
        <ModalBlockAccount />
        : */}
        <CardHomepage>
          {BookmarkData.length !== 0 && (
            <Cardinfo>
              <Container maxWidth="lg">
                <Box sx={{ marginTop: "25px" }}>
                  <Typography sx={{ fontSize: "24px" }}>
                    {t("HOME.DETAIL.NEWS")}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "30px" }}>
                  <Carousel
                    renderIndicator={(
                      onClickHandler,
                      isSelected,
                      index,
                      label
                    ) => {
                      const defStyle = {
                        marginLeft: 10,
                        marginRight: 10,
                        color: JSON.parse(decode(profile))?.roleId
                          ? JSON.parse(decode(profile))?.roleId == 4
                            ? colors.themeMainColor
                            : colors.blueadminfade
                          : colors.themeMainColor,
                        cursor: "pointer",
                        fontSize: "24px",
                      };
                      const style = isSelected
                        ? {
                          ...defStyle,
                          color: JSON.parse(decode(profile))?.roleId
                            ? JSON.parse(decode(profile))?.roleId == 4
                              ? colors.themeMainColor
                              : colors.themeSecondColor
                            : colors.themeMainColor,
                        }
                        : { ...defStyle };
                      return (
                        <span
                          style={style}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          key={index}
                          role="button"
                          tabIndex={0}
                          aria-label={`${label} ${index + 1}`}
                        >
                          ●
                        </span>
                      );
                    }}
                    showStatus={false}
                    showArrows={false}
                    autoPlay={true}
                    transitionTime={1000}
                    interval={10000}
                    infiniteLoop={true}
                  >
                    {BookmarkData &&
                      BookmarkData?.map((e: any, index: any) => (
                        <>
                          {e?.announceType === "RENT" && (
                            <BoxInfo key={index}>
                              <Box sx={{ display: "flex", width: "100%" }}>
                                {/* <BoxImg>
                                  <img src={logo} alt="logo" width={30} />
                                </BoxImg> */}
                                <BoxZone>
                                  <Grid container spacing={2}>
                                    <Grid xs={12}>
                                      <BoxImgTop>
                                        {/* <img src={logo} alt="logo" /> */}
                                      </BoxImgTop>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                      <BoxFlex>
                                        <TypographyTextres>
                                          {/* {t("HOME.DETAIL.NEWS")} */}
                                          {e?.newsType?.newstypeNameTh}
                                        </TypographyTextres>
                                        <TypographyTextres>{ }</TypographyTextres>
                                      </BoxFlex>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                      {e?.round && (
                                        <BoxFlex>
                                          <TypographyTextres
                                            sx={{ marginRight: "15px" }}
                                          >
                                            {t("HOME.DETAIL.NUMBER_STALL")}
                                          </TypographyTextres>
                                          <TypographyTextres>
                                            {e?.round?.length}
                                          </TypographyTextres>
                                        </BoxFlex>
                                      )}
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                      {e?.round?.map(
                                        (value: any) =>
                                          value?.stall?.stallType?.stallTypeNameTh
                                      ) && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: { "sm-down": "end" },
                                            }}
                                          >
                                            <TypographyTextres>
                                              {t("HOME.DETAIL.TYPE")}
                                            </TypographyTextres>
                                            <TypographyTextres>
                                              {Array.from(new Set(e?.round.map(
                                                (value: any) =>
                                                  value?.stall?.stallType
                                                    ?.stallTypeNameTh)))?.map(
                                                (value: any) =>
                                                  value
                                              )?.join(', ')}


                                              {/* {e?.round?.map(
                                                (value: any) =>
                                                  value?.stall?.stallType
                                                    ?.stallTypeNameTh + ","
                                              )} */}
                                            </TypographyTextres>
                                          </Box>
                                        )}
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                      {e?.round?.map(
                                        (value: any) => value?.stall?.stallName
                                      ) && (
                                          <BoxFlex>
                                            <TypographyTextres sx={{whiteSpace:"nowrap"}}>
                                              {t("HOME.DETAIL.STALL")}
                                            </TypographyTextres>
                                            <TypographyTextres sx={{
                                              display: "-webkit-box",
                                              WebkitBoxOrient: "vertical",
                                              WebkitLineClamp: "1",
                                              overflow: "hidden",
                                            }}>
                                              {e?.round?.map(
                                                (value: any) =>
                                                  value?.stall?.stallName
                                              )?.join(', ')}
                                             
                                            </TypographyTextres>
                                          </BoxFlex>
                                        )}
                                    </Grid>
                                    {/* <Grid item lg={6} xs={12}>
                                      {e?.price && (
                                        <BoxFlex>
                                          <TypographyTextres>
                                            {" "}
                                            {t("HOME.DETAIL.PRICE")}
                                          </TypographyTextres>
                                          <TypographyTextres>
                                            {numberFormat(e?.price)}
                                          </TypographyTextres>
                                        </BoxFlex>
                                      )}
                                    </Grid> */}
                                    <Grid item lg={4} xs={12}>
                                      <BoxFlex>
                                        <TypographyTextres>
                                          {t("HOME.DETAIL.RENT_TIME")}
                                        </TypographyTextres>
                                      </BoxFlex>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                      {e?.round && (
                                        <BoxFlex>
                                          <TypographyTextres
                                            sx={{
                                              color: JSON.parse(decode(profile))
                                                ?.roleId
                                                ? JSON.parse(decode(profile))
                                                  ?.roleId == 4
                                                  ? colors.themeMainColor
                                                  : colors.themeSecondColor
                                                : colors.themeMainColor,
                                            }}
                                          >
                                            {dateToView(e?.round[0]?.startDate)}{" "}
                                            {"--->"}{" "}
                                            {dateToView(e?.round[0]?.endDate)}
                                          </TypographyTextres>
                                        </BoxFlex>
                                      )}
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                        onClick={() =>
                                          history.push(
                                            `${routeName.detailinfo}/${e?.announceId}`
                                          )
                                          // history.push(routeName.marketMap, {
                                          //   announceId: e?.announceId,
                                          //   round: e?.round,
                                          // })
                                        }
                                      >
                                        <TypographyTextres
                                          sx={{
                                            color: JSON.parse(decode(profile))
                                              ?.roleId
                                              ? JSON.parse(decode(profile))
                                                ?.roleId == 4
                                                ? colors.themeMainColor
                                                : colors.themeSecondColor
                                              : colors.themeMainColor,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {t("HOME.DETAIL.READ_MORE")}
                                        </TypographyTextres>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </BoxZone>
                              </Box>
                            </BoxInfo>
                          )}
                          {e?.announceType === "HELP" && (
                            <BoxInfo key={index}>
                              <Box sx={{ display: "flex", width: "100%" }}>
                                <BoxImg>
                                  {/* <img src={logo} alt="logo" width={30} /> */}
                                </BoxImg>
                                <BoxZone>
                                  <Grid container spacing={2}>
                                    <Grid xs={12}>
                                      <BoxImgTop>
                                        {/* <img src={logo} alt="logo" /> */}
                                      </BoxImgTop>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                      <BoxFlex>
                                        <TypographyTextres>
                                          {/* {t("HOME.DETAIL.NEWS")} */}
                                          {e?.newsType?.newstypeNameTh}
                                        </TypographyTextres>
                                        <TypographyTextres>{ }</TypographyTextres>
                                      </BoxFlex>
                                    </Grid>

                                    <Grid item lg={6} xs={12}>
                                      <BoxFlex>
                                        <TypographyTextres>
                                          {e?.title}
                                        </TypographyTextres>
                                      </BoxFlex>
                                    </Grid>

                                    <Grid item lg={3} xs={6}>
                                      <BoxFlex>
                                        <TypographyTextres>
                                          {t("HOME.DETAIL.DISCOUNT_TIME")} :
                                        </TypographyTextres>
                                      </BoxFlex>
                                    </Grid>
                                    <Grid item lg={4} xs={6}>
                                      <BoxFlex>
                                        <TypographyTextres
                                          sx={{
                                            color: JSON.parse(decode(profile))
                                              ?.roleId
                                              ? JSON.parse(decode(profile))
                                                ?.roleId == 4
                                                ? colors.themeMainColor
                                                : colors.themeSecondColor
                                              : colors.themeMainColor,
                                          }}
                                        >
                                          {dateToView(e?.startDate)} {"--->"}{" "}
                                          {dateToView(e?.endDate)}
                                        </TypographyTextres>
                                      </BoxFlex>
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                        onClick={() =>
                                          history.push(
                                            `${routeName.detailinfo}/${e?.announceId}`
                                          )
                                        }
                                      >
                                        <TypographyTextres
                                          sx={{
                                            color: JSON.parse(decode(profile))
                                              ?.roleId
                                              ? JSON.parse(decode(profile))
                                                ?.roleId == 4
                                                ? colors.themeMainColor
                                                : colors.themeSecondColor
                                              : colors.themeMainColor,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {t("HOME.DETAIL.READ_MORE")}
                                        </TypographyTextres>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </BoxZone>
                              </Box>
                            </BoxInfo>
                          )}
                        </>
                      ))}
                  </Carousel>
                </Box>
              </Container>
            </Cardinfo>
          )}
          {((roleId === 4 && agreement.length !== 0) || roleId !== 4) && (
            <Container maxWidth="lg">
              <Box sx={{ marginTop: "25px" }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    textDecoration: "underline",
                    textDecorationColor: JSON.parse(decode(profile))?.roleId
                      ? JSON.parse(decode(profile))?.roleId == 4
                        ? colors.themeMainColor
                        : colors.themeSecondColor
                      : colors.themeMainColor,
                  }}
                >
                  บริการ
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "35px",
                  paddingBottom: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GridNew container spacing={2}>
                  {_.map(permissionMenus, (value: any) => {
                    return (
                      <>
                        {value?.menuFirstId === 1 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.marketMap)}
                            >
                              <Store color={colors.themeMainColor}/>

                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.STALL_INFO")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 2 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() =>
                                history.push(routeName.stallAgreement)
                              }
                            >
                              <Rental />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.CONTRACT")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 3 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.waterBill)}
                            >
                              <ElectricityBill />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.WATER_ELECTRICT_BILL")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}

                        {value?.menuFirstId === 4 && (
                          <Grid item lg={3}>
                            {" "}
                            <Cardstill
                              onClick={() => history.push(routeName.payBill)}
                            >
                              <PayBill />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.PAY_OFF_DEBT")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 5 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.trackBill)}
                            >
                              <TrackBill />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.FOLLOW_DEBT")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 6 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.repair)}
                            >
                              <Repair />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.REPAIR")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 7 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() =>
                                history.push(routeName.transferStall)
                              }
                            >
                              <LeaseHold />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.TRANSFER")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}

                        {value?.menuFirstId === 8 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.certificate)}
                            >
                              <Certificate />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.REQUEST_CERTIFICATE")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 9 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.sue)}
                            >
                              <Sue />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.SUE")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 10 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.announce)}
                            >
                              <Help />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.HELP")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 14 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.systemLog)}
                            >
                              <SystemLog />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.SYSTEM_LOG")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 11 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() =>
                                history.push(routeName.cancelContract)
                              }
                            >
                              <CancelContact />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.CANCEL_CONTRACT")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                        {value?.menuFirstId === 10 && (
                          <Grid item lg={3}>
                            <Cardstill
                              onClick={() => history.push(routeName.announce)}
                            >
                              <NewsIcon />
                              <Typography sx={{ marginTop: "15px" }}>
                                {t("HOME.MENU.NEWS")}
                              </Typography>
                            </Cardstill>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </GridNew>
              </Box>
            </Container>
          )}
          {agreement.length === 0 && roleId === 4 && (
            <Container maxWidth="lg">
              <Box sx={{ marginTop: "25px" }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    textDecoration: "underline",
                    textDecorationColor: "green",
                  }}
                >
                  {t("HOME.TEXT.OTHER_NEWS")}
                </Typography>
              </Box>
              <TableContainer>
                <TableCustom
                  headCells={[]}
                  customScroll
                  page={page}
                  pageLimit={pageLimit}
                  sortType={"ASC"}
                  sortBy=""
                  rowCount={rowcount}
                  rowsData={_.map(newsData, (d: any) => {
                    return renderData(d);
                  })}
                  onSort={() => { }}
                  setPageLimit={(value) => setPageLimit(value)}
                  setPage={(value) => setPage(value)}
                />
              </TableContainer>
            </Container>
          )}
          {depted ? <ModalHaveDept /> : <></>}


        </CardHomepage>
      {/* } */}


    </>
  );
}
