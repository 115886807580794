/** TRANSLATION */
import { useTranslation } from "react-i18next";
import * as UseStyled from "./styles";
import CollapseContainer from "./container";
import SliderService, { Body, Header, SliderItem } from "./sliderService";
import CardService, { IDeposit } from "./cardService";
import InputTextField from "../../../../component/input/inputTextField";
import DepositApi, {
  IParamsFindAll,
} from "../../../../api/stall/deposit/deposit.api";
import { useCallback, useEffect, useState } from "react";
import _, { filter, map } from "lodash";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "../../../../app/slice/modal.slice";
import { dateToView } from "../../../../utils/date.utils";
import { colors } from "../../../../constants/colors";
import InputDatePicker from "../../../../component/input/inputDatePicker";
import { IProfile, getBranch, getProfile } from "../../../../utils/app.utils";
import moment from "moment";
import AppointmentApi from "../../../../api/stall/appointment/appointment";
import styled from "styled-components";
import BidderApi from "../../../../api/stall/bidder/bidder.api";
import CardServiceBidder, { IBidder } from "./cardServiceBidder";
import { Button } from "@mui/material";
import { isCreate, isDelete, isUpdate, isView } from "../../../../utils/permission.utils"
import PreviewImage from "../../../../component/image/PreviewImage";
import { fileUrl } from "../../../../api/api";
import { decode } from 'js-base64';
import { notiError } from "../../../../component/notification/notifications";

export const DivOverflow = styled("div")<{ left?: boolean }>(
  ({ theme, left }) => ({
    marginTop: left ? "0rem" : "1rem",
    height: `calc(100vh - ${left ? 220 : 230}px)`,
    overflowY: "auto",
    backgroundColor: "#F8F8F8",
  })
);
const ButtonCustom = styled(Button)<{ typeBtn: "DANGER" | "SECOND" | "MAIN" }>(
  ({ theme, typeBtn }) => {
    let bgColor = "";
    switch (typeBtn) {
      case "MAIN":
        bgColor = colors.themeMainColor;
        break;
      case "SECOND":
        bgColor = colors.themeSecondColor;
        break;
      case "DANGER":
        bgColor = colors.danger;
        break;
    }
    return {
      fontSize: "16px",
      height: "44px",
      color: "#fff",
      opacity: 0.8,
      backgroundColor: bgColor,
      "&:hover": {
        opacity: 1,
        backgroundColor: bgColor,
      },
    };
  }
);

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function Admin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile: any = JSON.parse(getProfile() || '{}')

  const [menus, setMenus] = useState<any[]>([
    { id: 1, name: "จ่ายค่ามัดจำ", status: "WAITING", deposits: [] },
    { id: 2, name: "ตรวจสอบค่ามัดจำ", status: "PROGRESS", deposits: [] },
    { id: 3, name: "จ่ายค่ามัดจำสำเร็จ", status: "SUCCESS", deposits: [] },
  ]);

  const branchId = getBranch() || 1;

  const [bidders, setBidders] = useState<IBidder[]>([]);

  const [filterSearch, setFilterSearch] = useState<string>("");

  const [depositSelect, setDepositSelect] = useState<IDeposit>();
  const [bidderSelect, setBidderSelect] = useState<IBidder>();
  const [openPopupPublic, setOpenPopupPublic] = useState<boolean>(false);
  const [openPopupCheck, setOpenPopupCheck] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("")
  const [showRemark, setShowRemark] = useState<boolean>(false)
  const [openPopupAppointment, setOpenPopupAppointment] =
    useState<boolean>(false);
  /** form */
  const initPayloadAppointment = {
    appointmentDate: "",
    phone: JSON.parse(decode(profile))?.mobilePhone,
  };
  const initPayloadPublic = {
    depositDate: "",
    depositDateEnd: "",
    depositAmount: null,
  };

  const [payloadAppointment, setPayloadAppointment] = useState<{
    appointmentDate: any;
    phone: string;
  }>(initPayloadAppointment);

  const [payloadPublic, setPayloadPublic] = useState<{
    depositDate: any;
    depositDateEnd: any;
    depositAmount: number | null;
  }>(initPayloadPublic);
  console.log(bidderSelect)

  const loadData = useCallback(async () => {
    let condition: IParamsFindAll = { page: 1, pageLimit: 100 };

    BidderApi.findAll({
      page: 1,
      pageLimit: 100,
      search: filterSearch,
      status: "APPROVE",
      isNotDeposit: "TRUE",
    }).then((res) => {
      const { data } = res;
      setBidders(data);
    });

    await DepositApi.findAllAdmin(condition).then((res) => {
      const { data } = res;
      setMenus([
        ...map(menus, (menu) => {
          const tempMenu = { ...menu };
          switch (menu.id) {
            case 1:
              tempMenu.deposits = filter(
                data,
                (item) => item.status === "WAITING"
              );
              break;
            case 2:
              tempMenu.deposits = filter(
                data,
                (item) => item.status === "PROGRESS"
              );
              break;
            case 3:
              tempMenu.deposits = filter(
                data,
                (item) => item.status === "SUCCESS"
              );
              break;
          }

          return tempMenu;
        }),
      ]);
    });
  }, [filterSearch]);

  useEffect(() => {
    loadData();
  }, [loadData, filterSearch]);

  const init = () => {
    setOpenPopupPublic(false);
    setOpenPopupCheck(false);
    setOpenPopupAppointment(false);
    setShowRemark(false)
    setRemark("")
    setDepositSelect(undefined);
    setBidderSelect(undefined);

    setPayloadAppointment(initPayloadAppointment);
    setPayloadPublic(initPayloadPublic);

    loadData();
  };

  const onClickCard = (
    deposit: IDeposit,
    option?: "PUBLIC" | "CHECK" | "APPOINTMENT" | null
  ) => {
    setDepositSelect(deposit);
    if (option)
      switch (option) {
        case "PUBLIC":
          setOpenPopupPublic(true);
          break;
        case "CHECK":
          setOpenPopupCheck(true);
          break;
        case "APPOINTMENT":
          setOpenPopupAppointment(true);
          break;
      }
    dispatch(showModal());
  };

  const onClickCardBidder = (bidder: IBidder) => {
    setBidderSelect(bidder);
    setOpenPopupPublic(true);
    dispatch(showModal());
  };

  const onCheck = async (deposit: IDeposit) => {
    await DepositApi.updateStatus(deposit.depositId, { status: "SUCCESS" });
    loadData();
    dispatch(closeModal());
  };

  const onNotApprove = async (deposit: IDeposit) => {
    await DepositApi.updateStatus(deposit.depositId, { status: "NOT_APPROVE" });
    loadData();
    dispatch(closeModal());
  };

  const onSavePublic = async (bidder: IBidder) => {
    const res = await DepositApi.create({
      userId: bidder.userId,
      branchId: bidder.branchId,
      bidderId: bidder.bidderId,
      depositDate: payloadPublic.depositDate,
      depositDateEnd: payloadPublic.depositDateEnd,
      depositAmount: Number(payloadPublic.depositAmount || 0),
    })
    if (res?.status === 201) {
      init();
      dispatch(closeModal());
    } else {
      notiError('กรุณาระบุข้อมูลให้ครบ')
    }
    console.log(res);

  };

  const onSaveAppointment = async (deposit: IDeposit) => {
    const appointmentCreated = await AppointmentApi.create(
      Number(branchId),
      deposit.userId,
      deposit.bidderId,
      "BIDDER",
      payloadAppointment.appointmentDate,
      payloadAppointment.phone
    )
      .then(() => true)
      .catch(() => false);
    console.log("appointmentCreated", appointmentCreated);
    if (appointmentCreated) {
      const appointments: any[] = await AppointmentApi.findAll({
        page: 1,
        pageLimit: 100,
        sortType: "DESC",
        sortBy: "appointmentId",
      }).then((res) => res.data);
      console.log("appointments", appointments, appointments[0]);
      if (appointments[0]?.appointmentId)
        await DepositApi.updateSuccess(deposit.depositId, {
          appointmentId: appointments[0].appointmentId,
        });
    }
    dispatch(closeModal());
    init();
  };

  const onClose = () => {
    init();
    dispatch(closeModal());
  };

  return (
    <div className={"h-100 overflow-hidden"}>
      <CollapseContainer
        tooltipExpand={t("HOME.TOOLTIP.EXPAND_SCHEDULE")}
        tooltipShorten={t("HOME.TOOLTIP.SHORTEN_SCHEDULE")}
        styleComponentLeft={{ height: "100%" }}
        styleComponentRight={{ height: "100%" }}
        // callBackStatusCollapse={handleCollapse}
        componentLeft={
          <div
            className={"schedule h-100 d-flex flex-column"}
            style={{ minHeight: "90vh" }}
          >
            <UseStyled.Container className="">
              <UseStyled.Header className={"d-flex align-items-center"}>
                <UseStyled.TypoHeader className={"text-ellipsis mb-0"}>
                  {t("รายการผู้มีสิทธิเช่าแผงค้า")}
                </UseStyled.TypoHeader>
              </UseStyled.Header>
              <InputTextField
                value={filterSearch}
                placeholder={t("ค้นหาจากเลขที่คำร้อง")}
                onchange={(e) => setFilterSearch(String(e.target.value))}
              />
              <DivOverflow>
                {_.map(bidders, (bidder, i) => (
                  <CardServiceBidder
                    key={i}
                    number={Number(i + 1)}
                    hideFooterAction={permissions.isUpdate.disabled}
                    footerType="PUBLIC"
                    bidder={bidder}
                    onClick={() => onClickCardBidder(bidder)}
                  />
                ))}
              </DivOverflow>
            </UseStyled.Container>
          </div>
        }
        componentRight={
          <div className={"h-100 pb-4 d-flex flex-column"}>
            <UseStyled.Container>
              <UseStyled.Header className={"d-flex align-items-center"}>
                <UseStyled.TypoHeader className={"text-ellipsis mb-0"}>
                  {t("ชำระค่ามัดจำแผงค้า")}
                </UseStyled.TypoHeader>
              </UseStyled.Header>
            </UseStyled.Container>
            <UseStyled.Container
              className={"pr-0 h-100 overflow-hidden"}
              style={{ position: "relative" }}
            >
              <SliderService
                renderService={map(menus, (menu) => (
                  <SliderItem className={"slider-area"}>
                    <Header style={{ background: colors.themeSecondColor }}>
                      <span className={"text-ellipsis"}>
                        {menu?.name || "-"}
                      </span>
                    </Header>
                    <Body className={"custom-scroll"}>
                      <DivOverflow left={true}>
                        {map(
                          menu.deposits,
                          (deposit: IDeposit, iSlider: number) => {
                            let optionType:
                              | "PUBLIC"
                              | "CHECK"
                              | "APPOINTMENT"
                              | null = null;

                            if (menu.id === 2) {
                              optionType = "CHECK";
                            } else if (
                              menu.id === 3 &&
                              deposit.appointment === null
                            ) {
                              optionType = "APPOINTMENT";
                            }

                            return (
                              <CardService
                                key={iSlider}
                                number={Number(iSlider + 1)}
                                req_number={Number("0002")}
                                hideFooterAction={optionType === null || permissions.isUpdate.disabled}
                                footerType={optionType || undefined}
                                deposit={deposit}
                                onClick={() => onClickCard(deposit, optionType)}
                              />
                            );
                          }
                        )}
                      </DivOverflow>
                    </Body>
                  </SliderItem>
                ))}
              />
            </UseStyled.Container>
          </div>
        }
      ></CollapseContainer>

      {bidderSelect && openPopupPublic ? (
        <ModalCustom
          title={t("กำหนดค่ามัดจำแผงค้า")}
          size="xl"
          closeButton
          classTitle="text-left"
          component={
            <div className="pb-2" style={{ backgroundColor: "#F8F8F8" }}>
              <div className="text-center">
                {t("รายละเอียดกำหนดค่ามัดจำแผงค้า")}
              </div>

              <div className="w-50 mx-auto px-3 py-5">
                <div className="row">
                  <div className="col-auto col-md-4">{t("โซนแผงค้า")}: </div>
                  <div className="col col-md-8 mb-4">
                    {bidderSelect.zoneName}
                  </div>
                  <div className="col-auto col-md-4">{t("แผงค้า")}: </div>
                  <div className="col col-md-8 mb-4">
                    {bidderSelect.stallName}
                  </div>
                  <div className="col-auto col-md-4">{t("ประเภทแผงค้า")}: </div>
                  <div className="col col-md-8 mb-4">
                    {bidderSelect.stallTypeName}
                  </div>
                  <div className="col-auto col-md-4">{t("จำนวนเงินค่ามัดจำ")}: </div>
                  <div className="col col-md-6 mb-4">
                    <InputTextField
                      value={payloadPublic.depositAmount}
                      type={"number"}
                      size="small"
                      onchange={(e) =>
                        setPayloadPublic({
                          ...payloadPublic,
                          depositAmount: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col col-md-2">{t("บาท")}</div>
                  <div className="col-auto col-md-4">
                    {t("ชำระได้ตั้งแต่วัน")}:{" "}
                  </div>
                  <div className="col col-md-8 mb-4">
                    <InputDatePicker
                      dateFormat="DD/MM/YYYY"
                      // label=""
                      onChange={(e: any) =>
                        setPayloadPublic({
                          ...payloadPublic,
                          depositDate: moment(e).format("YYYY-MM-DD"),
                        })
                      }
                      disablePast
                      value={payloadPublic.depositDate}
                    />
                  </div>
                  <div className="col-auto col-md-4">
                    {t("สิ้นสุดวันชำระ")}:{" "}
                  </div>
                  <div className="col col-md-8 mb-4">
                    <InputDatePicker
                      dateFormat="DD/MM/YYYY"
                      // label=""
                      onChange={(e: any) =>
                        setPayloadPublic({
                          ...payloadPublic,
                          depositDateEnd: moment(e).format("YYYY-MM-DD"),
                        })
                      }
                      disablePast
                      value={payloadPublic.depositDateEnd}
                    />
                  </div>
                </div>
              </div>

              <ButtonCustom
                fullWidth
                typeBtn="SECOND"
                onClick={() => {
                  onSavePublic(bidderSelect)
                  setOpenPopupCheck(false)
                }}
              >
                {t("บันทึก")}
              </ButtonCustom>
            </div>
          }
          onClose={() => onClose()}
        />
      ) : (
        <></>
      )}

      {depositSelect && openPopupCheck ? (
        <ModalCustom
          title={t("ตรวจสอบชำระมัดจำแผงค้า")}
          size="xl"
          closeButton
          classTitle="text-left"
          component={
            <div className="pb-2" style={{ backgroundColor: "#F8F8F8" }}>
              <div className="row mx-0 px-2">
                <div className="col-12 col-sm-7 px-3 py-5 ">
                  <div className=" mb-4">
                    {t("รายละเอียดกำหนดค่ามัดจำแผงค้า")}
                  </div>
                  <div className="row">
                    <div className="col-auto col-md-4">{t("โซนแผงค้า")}: </div>
                    <div className="col col-md-8 mb-4">
                      {depositSelect.bidder.zoneName}
                    </div>
                    <div className="col-auto col-md-4">{t("แผงค้า")}: </div>
                    <div className="col col-md-8 mb-4">
                      {depositSelect.bidder.stallName}
                    </div>
                    <div className="col-auto col-md-4">{t("จำนวนเงินค่ามัดจำ")}: </div>
                    <div className="col col-md-8 mb-4">
                      {depositSelect.depositAmount} {t("บาท")}
                    </div>
                    <div className="col-auto col-md-4">
                      {t("วันที่สิ้นสุด")}:{" "}
                    </div>
                    <div className="col col-md-8 mb-4">
                      {dateToView(depositSelect.depositDateEnd)}
                    </div>
                    <div className="col-auto col-md-4">{t("วันที่ชำระ")}: </div>
                    <div className="col col-md-8 mb-4">
                      {dateToView(depositSelect?.depositPay?.depositPayDate)}
                    </div>
                    <div className="col-auto col-md-4">{t("สถานะ")}: </div>
                    <div className="col col-md-8 mb-4" style={{fontWeight:'500', fontSize: '16px',color: depositSelect?.payment ? colors.green : colors.red}}>{depositSelect?.payment ? 'ยืนยันการชำระเงิน' : 'ยังไม่ได้รับรายการชำระเงิน'}</div>
                    {showRemark && (<div className="col col-md-8 mb-4">
                      <InputTextField
                        value={remark}
                        onchange={(e: any) => setRemark(e.target.value)}
                        heading={t("เหตุผลไม่อนุมัติ")}
                      />
                    </div>)}
                  </div>
                </div>
                <div className="col-12 col-sm-5 px-3 py-5 ">
                  <div className="h-100 bg-white text-center">
                    <UseStyled.BoxUpload>
                      <PreviewImage imageFile={fileUrl + depositSelect.depositPay?.fileUpload?.filePath + depositSelect.depositPay?.fileUpload?.fileName} />
                    </UseStyled.BoxUpload>

                  </div>
                </div>

                <div className="row px-0 mx-0">
                  {/* <div className="col-12 col-sm-6 px-1">
                    <ButtonCustom
                      fullWidth
                      typeBtn="DANGER"
                      onClick={() => {
                        if (!remark && !showRemark) {
                          setShowRemark(true)
                        } else if (remark && showRemark) {
                          init()
                          onNotApprove(depositSelect)
                        }
                      }
                      }
                    >
                      {t("ไม่อนุมัติ")}
                    </ButtonCustom>
                  </div> */}
                  <div className="col-12 col-sm-6 px-1 m-auto">
                    <ButtonCustom
                      fullWidth
                      typeBtn="MAIN"
                      // disabled={!depositSelect?.payment}
                      onClick={() => onCheck(depositSelect)}
                    >
                      {t("อนุมัติ")}
                    </ButtonCustom>
                  </div>
                </div>
              </div>
            </div>
          }
          onClose={() => onClose()}
        />
      ) : (
        <></>
      )}

      {depositSelect && openPopupAppointment ? (
        <ModalCustom
          title={t("ประกาศวันที่ทำสัญญา")}
          size="xl"
          closeButton
          classTitle="text-left"
          component={
            <div className="pb-2" style={{ backgroundColor: "#F8F8F8" }}>
              <div className="col col-md-8 mx-auto px-3 py-4">
                <h5>{t("รายละเอียดกำหนดค่ามัดจำแผงค้า")}</h5>
                <div className="row">
                  <div className="col-auto col-md-3">
                    <b>{t("โซนแผงค้า")}</b>:{" "}
                  </div>
                  <div className="col col-md-3 mb-4">
                    {depositSelect.bidder.zoneName}
                  </div>
                  <div className="col-auto col-md-3">
                    <b>{t("จำนวนเงิน")}</b>:{" "}
                  </div>
                  <div className="col col-md-3 mb-4">
                    {depositSelect.depositAmount} {t("บาท")}
                  </div>
                  <div className="col-auto col-md-3">
                    <b>{t("แผงค้า")}</b>:{" "}
                  </div>
                  <div className="col col-md-3 mb-4">
                    {depositSelect.bidder.stallName}
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto col-md-3">
                    {t("วันที่สิ้นสุด")}:{" "}
                  </div>
                  <div className="col col-md-3 mb-4">
                    {dateToView(depositSelect.depositDateEnd)}
                  </div>
                  <div className="col-auto col-md-3">{t("วันที่ชำระ")}: </div>
                  <div className="col col-md-3 mb-4">
                    {dateToView(depositSelect.depositPay?.depositPayDate)}
                  </div>
                </div>
                <hr className="mb-2" />
                <h5>{t("แจ้งประกาศวันที่นัดทำสัญญา")}</h5>
                <div className="row">
                  <div className="col col-md-3">{t("วันที่นัด")}</div>
                  <div className="col col-md-3 mb-4">
                    <InputDatePicker
                      dateFormat="DD/MM/YYYY"
                      // label=""
                      onChange={(e: any) =>
                        setPayloadAppointment({
                          ...payloadAppointment,
                          appointmentDate: moment(e).format("YYYY-MM-DD"),
                        })
                      }
                      disablePast
                      value={payloadAppointment.appointmentDate}
                    />
                  </div>
                  <div className="col col-md-3">{t("สถานที่")}</div>
                  <div className="col col-md-3 mb-4">
                    <InputTextField value={"อตก กทม"} disabled />
                  </div>
                  <div className="col col-md-3">{t("โทร")}</div>
                  <div className="col col-md-3 mb-4">
                    <InputTextField
                      value={payloadAppointment.phone}
                      onchange={(e) =>
                        setPayloadAppointment({
                          ...payloadAppointment,
                          phone: e.target.value,
                        })
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>

              <ButtonCustom
                fullWidth
                typeBtn="SECOND"
                onClick={() => onSaveAppointment(depositSelect)}
              >
                {t("บันทึก /เสร็จสิ้น")}
              </ButtonCustom>
            </div>
          }
          onClose={() => onClose()}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
