import { makeStyles, styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../constants/colors";
import { theme } from "antd";
import styleds from 'styled-components'


export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: '100%',
  color: colors.white,
  fontSize: 16
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  whiteSpace: 'nowrap'


}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 74,
  fontWeight: 500,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 54,
  fontWeight: 300,
}));

export const SubMessage = styled(Typography)(({ theme }) => ({
  fontSize: 44,
  fontWeight: 300,
}));

export const Message = styled(Typography)(({ theme }) => ({
  fontSize: 34,
  fontWeight: 300,
}));


export const ContainerModal = styled(Box)(({ theme }) => ({
  paddingLeft: 80,
  paddingRight: 80,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 40,
    paddingRight: 40,
  }
}));

export const SubDetail = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
}));

export const CloseIcon = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: -10,
  top: -40

}))

export const ContainerImg = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'

}));



export const Logo = styled('img')(({ theme }) => ({
  width: 500,
  height: 300,
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 24,
  
}))


export const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))

export const MainRedText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: colors.red,
  textAlign: 'center'
}))

export const BlackText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.black
}))

export const ClickPayDebt = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.red,
  marginLeft: 4,
  ':hover': {
    textDecoration: "underline"
  }
}))

export const ButtonStatus = styled(Button)(({ theme }) => ({
  // backgroundColor: colors.lightOrange,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  textDecoration:'none'
  // ':hover' : {
  //     backgroundColor: colors.blue
  // }
}))

export const Styles = styleds.div`
  padding: 1px;

  table {
    border-spacing: 0;
    
    th,
    td {
      margin: 0;
      padding: 1px;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  } 
`