import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'prefixes'

export interface PrefixInterface {
    prefixId?: number
    prefixNameTh: string
    prefixNameEn?: string
    status: string
    }

export default class PrefixApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(prefixNameTh: string, prefixNameEn: string) {
        return this.api.post(path , { prefixNameTh, prefixNameEn })
    }

    static findById(id: number): Promise<PrefixInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: PrefixInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
