import { menuFirsts } from "../../constants/menu"
import Statistic from "../../pages/statistic/statistic"

const routeStatistic = [
    {
      ...menuFirsts.STATISTIC,
      key: menuFirsts.STATISTIC.key,
      name: menuFirsts.STATISTIC.name,
      textName: menuFirsts.STATISTIC.textName,
      active: false,
      appBar: true,
      component: Statistic
    }
  ]
  
  export default routeStatistic