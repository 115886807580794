import { useTranslation } from "react-i18next";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { Col, Row } from "react-bootstrap";
import * as Style from "./popup.style";
import { imgs } from "../../../constants/images";
import { Box } from "@mui/material";
import Pdf from '../../../assets/images/imgpdf.png'
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ButtonCustom from "../../../component/button/buttonCustom";
import { colors } from "../../../constants/colors";

type popup = {
  isShow: boolean | false;
  data: any;
  setIsShow: (show: boolean) => void;
  onSubmit: () => void;
  onUpload: (file: any) => void;
};

export default function PopupClipDoc(props: popup) {
  const [img, setImg] = useState<any>();
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const fileName = file?.name.split(".");
      const detailImage = {
        fileId: null,
        base64: "",
        fileExtension: fileName[fileName?.length - 1],
        fileSize: file.size.toString(),
      };
      const reader: any = new FileReader();
      reader.addEventListener("load", () => {
        const base64 = reader.result.split(",");
        setImg(reader.result);
        detailImage.base64 =
          base64.length > 0 ? base64[base64?.length - 1] : reader?.result;
      });
      reader.readAsDataURL(file);
    },

    [img]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSubmit = () => {
    if (props.onSubmit) props.onSubmit();
  };
  return (
    <ModalCustom
      title={t("REQUEST_STALL.POPUP.TITLE")}
      size="xl"
      // closeButton
      // onSubmit={() => onSubmit()}
      // textBtnConfirm={t("REQUEST_STALL.POPUP.SAVE")}
      component={
        <>
          <Style.ContainerUpload>
            <Style.ContainerBoxUpload>
              <Style.BoxModal>
                <Row>
                  <Style.SubDetail style={{ fontSize: '18px', fontWeight: '500' }}>
                    {t("REQUEST_STALL.POPUP.AGREEMENT")}
                  </Style.SubDetail>
                </Row>
                {props.data.map((item: any) => (
                  <>
                    <Row>
                      <Col lg={12}>
                        <Style.SubDetail>
                          {item.bidderBy.firstname} {item.bidderBy.lastname}
                        </Style.SubDetail>
                      </Col>
                      <Col lg={6}>
                        <Style.SubDetail> 
                          {t("CANCELCONTRACT.POPUP.ZONE")}:
                          {"   "}
                          {"   "}{item.zoneName}</Style.SubDetail>
                      </Col>
                      <Col lg={6}>
                        <Style.SubDetail>
                          {t("CANCELCONTRACT.POPUP.STALL")}:
                          {"   "}
                          {"   "}
                          {item.stallName}
                        </Style.SubDetail>
                      </Col>
                      {/* <Col lg={6}>
                        <Style.SubDetail>
                          {t("CANCELCONTRACT.POPUP.BIDDERAMOUNT")}{" "}
                          {item.bidderAmount}
                        </Style.SubDetail>
                      </Col> */}
                    </Row>
                  </>
                ))}
              </Style.BoxModal>

              <Style.BoxUpload>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {img?.startsWith("data:application") ? (
                    <Style.BoxUploadImage
                      src={Pdf}
                      onClick={() => props.onUpload(img)}
                    />
                  ) : (
                    <Style.BoxUploadImage
                      src={img ? img : imgs.upload}
                      onClick={() => props.onUpload(img)}
                    />
                  )}
                </div>
              </Style.BoxUpload>
            </Style.ContainerBoxUpload>
          </Style.ContainerUpload>
          <div className="d-flex justify-content-center w-100">
            <ButtonCustom textButton={t('REQUEST_STALL.POPUP.SAVE')} style={{
              margin: '0px 10px',
              width: '200px',
            }}
              onClick={() => onSubmit()} />
            <ButtonCustom textButton={t('ยกเลิก')} style={{
              margin: '0px 10px',
              width: '200px',
              '&.MuiButton-contained': {
                backgroundColor: `${colors.red} !important`,
                ':hover': {
                  backgroundColor: `${colors.red} !important`,
                }
              }
            }} onClick={() => {
              props.setIsShow && props.setIsShow(false)
            }} />
          </div>
        </>
      }
    />
  );
}
