import BaseAPI from '../api'

const path = 'customer-hier'

export interface CustomerHeirInterface {
    page?: number
    pageLimit?: number
    id?: number
}

export default class CustomerHeirApi extends BaseAPI {
    static findAll(props: CustomerHeirInterface) {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`).then(res => res);
    }
    static createHeir(props: any) {
        return this.api.post(path, props)
    }

    static createHeirBulk(props: any) {
        return this.api.post(`${path}/bulk`, props).then((res) => res)
    }

    static getDetail(props: CustomerHeirInterface) {
        return this.api.get(`${path}/${props.id}`, { params: { ...props } }).then((res) => res)
    }

    static updateDetail(props: CustomerHeirInterface) {
        return this.api.patch(`${path}/${props.id}`, { params: { ...props } }).then((res) => res)
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`).then((res) => res)
    }

    static updateHeirStatus(props: CustomerHeirInterface) {
        return this.api.patch(`${path}/${props.id}/status`, { params: { ...props } }).then((res) => res)
    }
}
