import { Box, Container, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import HeaderCard from "../../component/cardCustom/headerCard";

import {
  Boxcamera,
  Boxcheck,
  Boxconteinerin,
  Boximg,
  Boxright,
  Boxsolid,
  CustomPatternFormat,
  TypographyHead,
} from "./adminStyle";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Camera from "../../assets/icon/camera";
import InputTextField from "../../component/input/inputTextField";
import { useTranslation } from "react-i18next";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import InputPasswordField from "../../component/input/inputPasswordField";
import { useDropzone } from "react-dropzone";
import ButtonCustom from "../../component/button/buttonCustom";
import InputCheckbox from "../../component/input/inputCheckbox";
import SignatureCanvas from "react-signature-canvas";
import LoginApi from "../../api/auth/auth.api";
import RoleApi from "../../api/admin/role/role.api";
import InputDatePicker from "../../component/input/inputDatePicker";
import moment from "moment";
import PrefixApi from "../../api/setting/prefix/prefix.api";
import BranchApi from "../../api/setting/branch/branch.api";
import EmployeeApi from "../../api/admin/employee/employee.api";
import { useLocation, useParams } from "react-router-dom";
import {
  notiError,
  notiSuccess,
} from "../../component/notification/notifications";
import FileuploadApi from "../../api/uploadfile/upload.api";
import { env } from "process";
import { fileUrl } from "../../api/api";

const initStateErrorMessage = {
  PRFIX_NAME: ``,
  ROLDID: ``,
  NAME: ``,
  EMPLOYEE_CODE: ``,
  LASTNAME: ``,
  PHONE_NUBER: ``,
  EMAIL: ``,
  USERNAME: ``,
  PASSOWORD: ``,
  CONFIRM_PASSWORD: ``,
  BARNCH: ``,
  PW: ``,
  IDCARD: ``,
};

export default function CreateAdmin() {
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [menu, setMenu] = useState<any>([]);
  const [addminType, setAddminType] = useState<any>([]);
  const [prefixData, setPrefixData] = useState<any>([]);
  const [employeeCode, setEmployeeCode] = useState<string>("");
  const [registerDate, setRegisterDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [birthday, setBirthDay] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [sigimg, setSigimg] = useState<string>("");
  const [idCard, setIdCard] = useState<string>("");
  const [SignatureDataURL, setSignatureDataURL] = useState<any>();
  const [roleId, setRoleId] = useState<Number>(0);
  const [branchId, setBranchId] = useState<Number>(0);
  const [prefixId, setPrefixId] = useState<Number>(0);
  const singpad = useRef<SignatureCanvas>(null);

  const [permission, setPermission] = useState<any>([]);
  const [branch, setBranch] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [fileupload, setFileUplaod] = useState<any>(null);
  const [profile, setProFile] = useState<any>(null);
  const [checkfile, setCheckfile] = useState<number>(1);
  const { t } = useTranslation();
  const { id: id } = useParams<any>();
  const location = useLocation<any>();
  const updateisAdd = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isCreate: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isCreate: false };
        }

        return obj;
      });
      console.log(add);
      setPermission(add);
    }
  };
  const updateisEdit = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isUpdate: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isUpdate: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisDelete = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isDelete: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isDelete: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisExport = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isExport: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isExport: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisApporve = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isApporve: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isApporve: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisAddSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isCreate: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isCreate: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisEditSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isUpdate: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isUpdate: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisDeleteSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isDelete: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isDelete: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisExportSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isExport: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isExport: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisApporveSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isApporve: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isApporve: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisAddThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isCreate: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isCreate: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisEditThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isUpdate: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isUpdate: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisDeleteThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isDelete: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isDelete: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisExportThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isExport: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isExport: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisApporveThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isApporve: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isApporve: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const addpermission = (e: any, id: any) => {
    if (e.target.checked) {
      const a = {
        branchId: +branchId,
        menuFirstId: id?.menuFirstId,
        menuSecondId: null,
        menuThirdId: null,
        isView: true,
      };
      setPermission([...permission, a]);
    } else {
      const dispermission = permission.filter(
        (item: any) => item.menuFirstId !== id.menuFirstId
      );
      setPermission(dispermission);
    }
  };
  const addpermissionSecond = (e: any, id: any) => {
    if (e.target.checked) {
      const a = {
        branchId: +branchId,
        menuFirstId: id?.menuFirstId,
        menuSecondId: id?.menuSecondId,
        menuThirdId: null,
        isView: true,
      };
      setPermission([...permission, a]);
    } else {
      const dispermissionSeconid = permission.filter(
        (item: any) => item.menuSecondId !== id.menuSecondId
      );
      setPermission(dispermissionSeconid);
    }
  };
  const addpermissionThirdId = (e: any, id: any) => {
    if (e.target.checked) {
      const a = {
        branchId: +branchId,
        menuFirstId: id?.menuFirstId,
        menuSecondId: id?.menuSecondId,
        menuThirdId: id?.menuThirdId,
        isView: true,
      };

      setPermission([...permission, a]);
    } else {
      const poppermission = permission?.filter(
        (obj: any) => obj.menuThirdId !== id.menuThirdId
      );
      setPermission(poppermission);
      console.log(permission);
    }
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileimg = acceptedFiles[0];

      const fileName = fileimg?.name.split(".");

      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png" ||
        fileName[fileName?.length - 1] == "pdf"
      ) {
        if (checkfile === 2) {
          setSigimg(URL.createObjectURL(acceptedFiles[0]));
          setSignatureDataURL(acceptedFiles[0]);
        } else {
          setFile(URL.createObjectURL(acceptedFiles[0]));
          setProFile(acceptedFiles[0]);
        }
      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png pdf เท่านั้น",
          "",
          null,
          null
        );
      }
      const detailImage = {
        fileId: null,
        base64: "",
        fileExtension: fileName[fileName?.length - 1],
        fileSize: file.size.toString(),
      };
    },
    [file]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClear = () => {
    if (singpad.current) {
      singpad.current.clear();
      setSignatureDataURL("");
    } else {
      setCheckfile(0);
      setSigimg("");
    }
  };
  const handleUploadsig = () => {
    setCheckfile(2);
  };
  const handleEnd = () => {
    if (singpad.current) {
      const dataUrl = singpad.current.toDataURL(); // Get base64 data URL
      const data = dataURItoBlob(dataUrl);

      setSignatureDataURL(data);
    }
  };
  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage);
  const loadMenu = async () => {
    const res = await LoginApi.menu();
    if (res.status == 200) {
      setMenu(res.data);
    }
  };
  const getBranch = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
      status: "ACTIVE",
    };
    const res = await BranchApi.findAll(condition);
    if (res.status === 200) {
      setBranch(res.data);
    }
  };
  const getRole = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
    };
    const res = await RoleApi.findAll(condition);
    console.log(res.data);
    setAddminType(res.data);
  };
  const getPrefix = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
    };
    const res = await PrefixApi.findAll(condition);
    setPrefixData(res.data);
  };
  const handleSubmit = async () => {
    if (id) {
      const condition = {
        roleId: +roleId,
        prefixId: +prefixId,
        employeeCode: employeeCode,
        firstname: firstName,
        branchId: +branchId,
        lastname: lastName,
        registerDate: registerDate,
        idCard: idCard.split("-").join(""),
        dob: birthday,
        email: email,
        mobilePhone: mobilePhone,

        permission: permission,
        status: "ACTIVE",
      };

      EmployeeApi.updateDetail(condition, +id).then((res) => {
        if (res.status === 200) {
          if (res?.data?.refId) {
            let ref = res.data.refId || null;

            if (SignatureDataURL && file) {
              const formDatasignature = new FormData();
              formDatasignature.append("refId", `${ref}`);
              formDatasignature.append("fileType", "employee-signature");
              formDatasignature.append("file", SignatureDataURL, "image.jpg");
              FileuploadApi.uploadfile(formDatasignature).then((result) => { });

              const formData = new FormData();
              formData.append("refId", `${ref}`);
              formData.append("fileType", `${res?.data?.fileType}`);
              formData.append("file", profile);

              FileuploadApi.uploadfile(formData).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else if (file) {
              const formData = new FormData();
              formData.append("refId", `${ref}`);
              formData.append("fileType", `${res?.data?.fileType}`);
              formData.append("file", profile);

              FileuploadApi.uploadfile(formData).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else if (SignatureDataURL) {
              const formDatasignature = new FormData();
              formDatasignature.append("refId", `${ref}`);
              formDatasignature.append("fileType", "employee-signature");
              formDatasignature.append("file", SignatureDataURL, "image.jpg");
              FileuploadApi.uploadfile(formDatasignature).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else {
              notiSuccess(t(`ADMIN.NOTI.SUCESSS`), "go", "/admin-manage", null);
            }
          } else {
            notiError(t("ADMIN.NOTI.UNSUCESSS"));
          }
        } else {
          notiError(t("ADMIN.NOTI.UNSUCESSS"));
        }
      });
    } else {
      if (!roleId)
        return setErrorMessage({
          ...errorMessage,
          ...{ ROLID: t(`TEXTALERT.ROLID`) },
        });
      if (!prefixId)
        return setErrorMessage({
          ...errorMessage,
          ...{ PRFIX_NAME: t(`TEXTALERT.PREFIX`) },
        });
      if (!employeeCode)
        return setErrorMessage({
          ...errorMessage,
          ...{ EMPLOYEE_CODE: t(`TEXTALERT.EMPLOYEE`) },
        });
      if (!firstName)
        return setErrorMessage({
          ...errorMessage,
          ...{ NAME: t(`TEXTALERT.NAME`) },
        });
      if (!lastName)
        return setErrorMessage({
          ...errorMessage,
          ...{ LASTNAME: t(`TEXTALERT.LASTNAME`) },
        });
      if (!idCard)
        return setErrorMessage({
          ...errorMessage,
          ...{ IDCARD: t(`TEXTALERT.IDCARD`) },
        });

      if (!birthday)
        return setErrorMessage({
          ...errorMessage,
          ...{ BIRTHDAY: t(`TEXTALERT.BIRTHDAY`) },
        });
      if (!email)
        return setErrorMessage({
          ...errorMessage,
          ...{ EMAIL: t(`TEXTALERT.EMAIL`) },
        });
      if (!mobilePhone)
        return setErrorMessage({
          ...errorMessage,
          ...{ PHONE_NUBER: t(`TEXTALERT.MOBILE`) },
        });
      if (roleId != 4) {
        if (!branchId)
          return setErrorMessage({
            ...errorMessage,
            ...{ BRANCH: t(`TEXTALERT.BRANCH`) },
          });
      }
      if (!password)
        return setErrorMessage({
          ...errorMessage,
          ...{ PW: t(`TEXTALERT.PASSWORD`) },
        });

      if (password !== passwordConfirm)
        return setErrorMessage({
          ...errorMessage,
          ...{ CONFIRM_PASSWORD: t(`TEXTALERT.CHECKPASSWORD`) },
        });
      const condition = {
        roleId: +roleId,
        prefixId: +prefixId,
        employeeCode: employeeCode,
        firstname: firstName,
        branchId: +branchId,
        lastname: lastName,
        registerDate: registerDate,
        idCard: idCard.split("-").join(""),
        dob: birthday,
        email: email,
        mobilePhone: mobilePhone,
        password: password,
        confirmPassword: passwordConfirm,

        permission: permission,
      };
      EmployeeApi.createEmployee(condition).then((res) => {
        if (res.status === 201) {
          if (res?.data?.refId) {
            let ref = res.data.refId || null;
            if (SignatureDataURL && file) {
              const formDatasignature = new FormData();
              formDatasignature.append("refId", `${ref}`);
              formDatasignature.append("fileType", "employee-signature");
              formDatasignature.append("file", SignatureDataURL, "image.jpg");
              FileuploadApi.uploadfile(formDatasignature).then((result) => { });
              const formData = new FormData();
              formData.append("refId", `${ref}`);
              formData.append("fileType", `${res?.data?.fileType}`);
              formData.append("file", profile);

              FileuploadApi.uploadfile(formData).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else if (file) {
            } else if (SignatureDataURL) {
              const formDatasignature = new FormData();
              formDatasignature.append("refId", `${ref}`);
              formDatasignature.append("fileType", "employee-signature");
              formDatasignature.append("file", SignatureDataURL, "image.jpg");
              FileuploadApi.uploadfile(formDatasignature).then((result) => {
                notiSuccess(
                  t(`ADMIN.NOTI.SUCESSS`),
                  "go",
                  "/admin-manage",
                  null
                );
              });
            } else {
              notiSuccess(t(`ADMIN.NOTI.SUCESSS`), "go", "/admin-manage", null);
            }
          }
        } else {
          notiError(t("BRANCH.MESSAGE.ERROR"), "", null, null);
        }
      });
    }
  };
  const getDetail = async () => {
    const condition = {
      id: +id,
    };

    await EmployeeApi.getdetail(+id).then((res: any) => {
      if (res.status === 200) {
        setEmail(res.data.email);
        setEmployeeCode(res.data.employeeCode);
        setFirstName(res.data.firstname);
        setLastName(res.data.lastname);
        setIdCard(res.data.idCard);
        setPrefixId(res.data.prefixId);
        setBirthDay(res.data.dob);
        setMobilePhone(res.data.mobilePhone);
        setPermission(res.data.permission);
        setRoleId(res.data.roleId);

        setBranchId(res.data.branchId);
        if (res?.data?.fileUploadSignature !== null) {
          setSigimg(
            fileUrl +
            res?.data?.fileUploadSignature?.filePath +
            res.data?.fileUploadSignature?.fileName
          );
        }
        if (res.data.fileUpload !== null) {
          setFile(
            fileUrl +
            res.data.fileUpload.filePath +
            res.data.fileUpload.fileName
          );
        }
      }
    });
  };
  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  useEffect(() => {
    loadMenu();
    getRole();
    getPrefix();
    getBranch();
  }, []);

  return (
    <>
      <HeaderCard text={t("CREATEADMIN.CREATE")} />
      <Box>
        <Boxconteinerin>
          <Boxsolid>
            <Row>
              <Col lg={3} xs={12}>
                <Boximg>
                  {file ? (
                    <img src={file} width="80" height="80" />
                  ) : (
                    <Boxcamera>
                      <Camera />
                    </Boxcamera>
                  )}

                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {file == null && (
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          marginTop: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {t("CREATEADMIN.ADDIMG")}
                      </Typography>
                    )}
                  </div>
                  {file !== null && !location.state.params.isView && (
                    <Typography
                      onClick={() => {
                        setProFile(null);
                        setFile(null);
                        setCheckfile(0);
                      }}
                      sx={{
                        textDecoration: "underline",
                        marginTop: "14px",
                        cursor: "pointer",
                      }}
                    >
                      {t("CREATEADMIN.DELETEIMG")}
                    </Typography>
                  )}
                  {id && (
                    <>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          marginTop: "32px",
                          fontSize: "18px",
                        }}
                      >
                        {firstName} {lastName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "15px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          รหัสพนักงาน: {employeeCode}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          อีเมล: {email}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          เบอร์โทร: {mobilePhone}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Boximg>
              </Col>
              <Col lg={9} xs={12}>
                <Boxright>
                  <Container maxWidth="lg">
                    <TypographyHead>
                      {t("CREATEADMIN.ADMIN_DETAIL")}
                    </TypographyHead>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <AutocompleteSelect
                          options={addminType?.filter(
                            (item: any) => item.status === "ACTIVE"
                          )}
                          disabled={location?.state.params.isView}
                          getOptionLabel={(option: any) => option.roleName}
                          placeholder="กรุณาเลือกบทบาท"
                          labelId="addmin-type"
                          value={
                            addminType.find(
                              (data: any) => data.roleId === roleId
                            ) || null
                          }
                          heading={t("CREATEADMIN.ADMIN_TYPE")}
                          onChange={(event, newValue) => {
                            clearErrorMessage();
                            if (newValue) {
                              setPermission(newValue.permission);
                              setRoleId(newValue.roleId);
                            } else {
                              setPermission([]);
                            }
                          }}
                          required
                          helperText={errorMessage.ROLID}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="userid"
                          required={true}
                          disabled={location.state.params.isView}
                          value={employeeCode}
                          onchange={(e) => {
                            setEmployeeCode(e.target.value);
                          }}
                          helperText={errorMessage.EMPLOYEE_CODE}
                          heading={t("CREATEADMIN.USER_ID")}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          required={true}
                          heading={t("CREATEADMIN.CREATE_DATE")}
                          key={"date-appointment"}
                          label=""
                          disabled
                          value={moment(registerDate).format("DD/MM/YYYY")}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col lg={2} md={12} xs={12}>
                        <AutocompleteSelect
                          options={prefixData}
                          getOptionLabel={(option: any) => option.prefixNameTh}
                          labelId="prefix-name"
                          disabled={location.state.params.isView}
                          value={
                            prefixId
                              ? prefixData.find(
                                (data: any) => data?.prefixId === prefixId
                              )
                              : null
                          }
                          heading={t("CREATEADMIN.PREFIX")}
                          onChange={(event, newValue) => {
                            clearErrorMessage();
                            if (newValue) {
                              setPrefixId(newValue.prefixId);
                            }
                          }}
                          required
                          helperText={errorMessage.PRFIX_NAME}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="name"
                          required={true}
                          disabled={location.state.params.isView}
                          value={firstName}
                          onchange={(e) => {
                            clearErrorMessage();
                            setFirstName(e.target.value);
                          }}
                          helperText={errorMessage.NAME}
                          heading={t("CREATEADMIN.NAME")}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="lastname"
                          value={lastName}
                          disabled={location.state.params.isView}
                          onchange={(e) => {
                            clearErrorMessage();
                            setLastName(e.target.value);
                          }}
                          required={true}
                          heading={t("CREATEADMIN.LAST_NAME")}
                          helperText={errorMessage.LASTNAME}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <Typography>
                          {t("CREATEADMIN.CARD_ID")}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <CustomPatternFormat
                          format={"#-####-#####-#-##"}
                          mask={"_"}
                          disabled={location.state.params.isView}
                          allowEmptyFormatting={true}
                          value={idCard}
                          onChange={(e: any) => setIdCard(e.target.value)}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputDatePicker
                          dateFormat="DD/MM/YYYY"
                          required={true}
                          key={"BIRTHDAY"}
                          disabled={location.state.params.isView}
                          label=""
                          value={birthday}
                          onClear={() => setBirthDay("")}
                          onChange={(e: any) => {
                            clearErrorMessage();
                            setBirthDay(moment(e).format("YYYY-MM-DD"));
                          }}
                          heading={t("CREATEADMIN.BIRTHDAY")}
                          helperText={errorMessage.BIRTHDAY}
                        />
                      </Col>
                      <Col lg={2} md={12} xs={12}>
                        <InputTextField
                          key="year"
                          required={true}
                          value={
                            birthday ? moment().diff(birthday, "years") : null
                          }
                          disabled={true}
                          heading={t("CREATEADMIN.YEAR")}
                        />
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="phonenumber"
                          required={true}
                          disabled={location.state.params.isView}
                          value={mobilePhone}
                          inputProps={{
                            maxlength: 10,
                          }}
                          onchange={(e) => {
                            clearErrorMessage();
                            setMobilePhone(e.target.value);
                          }}
                          onkeypress={(even) => {
                            if (!/[0-9]/.test(even.key)) {
                              even.preventDefault();
                            }
                          }}
                          helperText={errorMessage.PHONE_NUBER}
                          heading={t("CREATEADMIN.PHONE_NUMBER")}
                        />
                      </Col>

                      {roleId != 4 && <Col lg={4} md={12} xs={12}>
                        <AutocompleteSelect
                          options={branch}
                          disabled={location.state.params.isView}
                          getOptionLabel={(option: any) => option.branchNameTh}
                          labelId="Branch-name"
                          value={
                            branchId
                              ? branch?.find(
                                (data: any) => data?.branchId === branchId
                              )
                              : null
                          }
                          heading={t("CREATEADMIN.BRANCH")}
                          onChange={(event, newValue) => {
                            clearErrorMessage();
                            if (newValue) {
                              setBranchId(newValue.branchId);
                            }
                          }}
                          required
                          helperText={errorMessage.BRANCH}
                        />
                      </Col>}
                    </Row>
                    <TypographyHead>
                      {t("PASSWORD.SET_PASSWORD")}
                    </TypographyHead>
                    <Row className="my-4">
                      <Col lg={4} md={12} xs={12}>
                        <InputTextField
                          key="email"
                          required={true}
                          type="email"
                          value={email}
                          disabled={location.state.params.isView}
                          helperText={errorMessage.EMAIL}
                          onchange={(e) => {
                            clearErrorMessage();
                            setEmail(e.target.value);
                          }}
                          heading={t("CREATEADMIN.EMAIL")}
                        />
                      </Col>

                      <Col lg={4} md={12} xs={12}>
                        <InputPasswordField
                          disabled={location.state.params.isView}
                          required={true}
                          value={password}
                          onchange={(e) => setPassword(e.target.value)}
                          helperText={errorMessage.PW}
                          heading={t("PASSWORD.PASSWORD")}
                        />
                      </Col>
                      <Col lg={4} md={12} xs={12}>
                        <InputPasswordField
                          key="confirm"
                          disabled={location.state.params.isView}
                          required={true}
                          value={passwordConfirm}
                          onchange={(e) => setPasswordConfirm(e.target.value)}
                          helperText={errorMessage.CONFIRM_PASSWORD}
                          heading={t("PASSWORD.CONFIRM_PASSWORD")}
                        />
                      </Col>
                    </Row>
                    <TypographyHead>
                      {t("PASSWORD.SETTING_SYSTEM")}
                    </TypographyHead>

                    {menu?.map((x: any, index: any) => (
                      <>
                        <Col lg={12}>
                          <Box
                            key={index}
                            sx={{
                              flex: "flex",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                              }}
                            >
                              {t(`MENU.${x.menuFirstKey}`)}
                            </Typography>
                            <Boxcheck>
                              <Typography>
                                {t(`MENU.${x.menuFirstKey}`)}
                              </Typography>
                              <InputCheckbox
                                onChange={(e: any) => addpermission(e, x)}
                                disabled={location.state.params.isView}
                                checked={
                                  permission?.find(
                                    (item: any) =>
                                      item?.menuFirstId === x?.menuFirstId
                                  )?.isView || false
                                }
                              />
                            </Boxcheck>
                            {x.menuSeconds.length === 0 &&
                              permission
                                ?.filter(
                                  (data: any) =>
                                    data.menuFirstId == x.menuFirstId
                                )
                                .map(
                                  (item: any, index: any) =>
                                    item.menuFirstId === x.menuFirstId && (
                                      <>
                                        <InputCheckbox
                                          key={index}
                                          label="เพิ่ม"
                                          disabled={
                                            location.state.params.isView
                                          }
                                          style={{ marginLeft: "10px" }}
                                          onChange={(e: any) =>
                                            updateisAdd(e, x)
                                          }
                                          checked={item.isCreate}
                                        />
                                        <InputCheckbox
                                          label="ลบ"
                                          disabled={
                                            location.state.params.isView
                                          }
                                          onChange={(e: any) =>
                                            updateisDelete(e, x)
                                          }
                                          checked={item.isDelete}
                                        />
                                        <InputCheckbox
                                          label="แก้ไข"
                                          disabled={
                                            location.state.params.isView
                                          }
                                          onChange={(e: any) =>
                                            updateisEdit(e, x)
                                          }
                                          checked={item.isUpdate}
                                        />
                                        <InputCheckbox
                                          label="รายงาน"
                                          disabled={
                                            location.state.params.isView
                                          }
                                          onChange={(e: any) =>
                                            updateisExport(e, x)
                                          }
                                          checked={item.isExport}
                                        />
                                        <InputCheckbox
                                          label="อนุมัติ"
                                          disabled={
                                            location.state.params.isView
                                          }
                                          onChange={(e: any) =>
                                            updateisApporve(e, x)
                                          }
                                          checked={item.isApporve}
                                        />
                                      </>
                                    )
                                )}
                            <>
                              {x?.menuSeconds?.map((y: any) => (
                                <>
                                  {permission
                                    ?.filter(
                                      (data: any) =>
                                        data.menuFirstId === x.menuFirstId
                                    )
                                    ?.filter(
                                      (obj: any) =>
                                        obj.menuSecondId == x.menuSecondId
                                    )
                                    ?.map(
                                      (item: any, index: any) =>
                                        item.isView && (
                                          <Boxcheck
                                            key={index}
                                            sx={{
                                              marginLeft: "10px",
                                              transform: "0.2s",
                                            }}
                                          >
                                            <Typography>
                                              {t(`MENU.${y?.menuSecondKey}`)}
                                            </Typography>
                                            <InputCheckbox
                                              onChange={(e: any) => {
                                                addpermissionSecond(e, y);
                                              }}
                                              disabled={
                                                location.state.params.isView
                                              }
                                              checked={
                                                permission.find(
                                                  (item: any) =>
                                                    item?.menuSecondId ===
                                                    y?.menuSecondId
                                                )?.isView || false
                                              }
                                            />
                                          </Boxcheck>
                                        )
                                    )}

                                  {y.menuThirds.length === 0 &&
                                    permission
                                      ?.filter(
                                        (data: any) =>
                                          data.menuFirstId == x.menuFirstId
                                      )
                                      ?.map(
                                        (item: any, index: any) =>
                                          item.menuSecondId ===
                                          y.menuSecondId && (
                                            <>
                                              <InputCheckbox
                                                key={index}
                                                label="เพิ่ม"
                                                style={{ marginLeft: "10px" }}
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisAddSecon(e, y)
                                                }
                                                checked={item.isCreate}
                                              />
                                              <InputCheckbox
                                                label="ลบ"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisDeleteSecon(e, y)
                                                }
                                                checked={item.isDelete}
                                              />
                                              <InputCheckbox
                                                label="แก้ไข"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisEditSecon(e, y)
                                                }
                                                checked={item.isUpdate}
                                              />
                                              <InputCheckbox
                                                label="รายงาน"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisExportSecon(e, y)
                                                }
                                                checked={item.isExport}
                                              />
                                              <InputCheckbox
                                                label="อนุมัติ"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisApporveSecon(e, y)
                                                }
                                                checked={item.isApporve}
                                              />
                                            </>
                                          )
                                      )}

                                  {y.menuThirds.map((z: any) => (
                                    <>
                                      {permission
                                        ?.filter(
                                          (data: any) =>
                                            data.menuFirstId === y.menuFirstId
                                        )

                                        ?.filter(
                                          (obj: any) =>
                                            obj.menuSecondId === y.menuSecondId
                                        )

                                        ?.map(
                                          (item: any, index: any) =>
                                            item.isView &&
                                            !item.menuThirdId && (
                                              <>
                                                <Boxcheck
                                                  key={index}
                                                  sx={{
                                                    marginLeft: "30px",
                                                    transform: "0.2s",
                                                  }}
                                                >
                                                  <Typography>
                                                    {t(
                                                      `MENU.${z?.menuThirdKey}`
                                                    )}
                                                  </Typography>
                                                  <InputCheckbox
                                                    onChange={(e) =>
                                                      addpermissionThirdId(e, z)
                                                    }
                                                    disabled={
                                                      location.state.params
                                                        .isView
                                                    }
                                                    checked={
                                                      permission?.find(
                                                        (item: any) =>
                                                          item.menuThirdId ===
                                                          z.menuThirdId
                                                      )?.isView || false
                                                    }
                                                  />
                                                </Boxcheck>
                                              </>
                                            )
                                        )}
                                      {permission
                                        ?.filter(
                                          (data: any) =>
                                            data.menuSecondId == z.menuSecondId
                                        )
                                        ?.map(
                                          (item: any, index: any) =>
                                            item.menuThirdId ===
                                            z.menuThirdId && (
                                              <>
                                                <InputCheckbox
                                                  key={index}
                                                  label="เพิ่ม"
                                                  style={{ marginLeft: "20px" }}
                                                  onChange={(e: any) =>
                                                    updateisAddThird(e, z)
                                                  }
                                                  checked={item.isCreate}
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                />
                                                <InputCheckbox
                                                  label="ลบ"
                                                  onChange={(e: any) =>
                                                    updateisDeleteThird(e, z)
                                                  }
                                                  checked={item.isDelete}
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                />
                                                <InputCheckbox
                                                  label="แก้ไข"
                                                  onChange={(e: any) =>
                                                    updateisEditThird(e, z)
                                                  }
                                                  checked={item.isUpdate}
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                />
                                                <InputCheckbox
                                                  label="รายงาน"
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                  onChange={(e: any) =>
                                                    updateisExportThird(e, z)
                                                  }
                                                  checked={item.isExport}

                                                />
                                                <InputCheckbox
                                                  label="อนุมัติ"
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                  onChange={(e: any) =>
                                                    updateisApporveThird(e, z)
                                                  }
                                                  checked={item.isApporve}
                                                />
                                              </>
                                            )
                                        )}
                                    </>
                                  ))}
                                </>
                              ))}
                            </>
                          </Box>
                        </Col>
                      </>
                    ))}

                    <TypographyHead>
                      {t("PASSWORD.ADD_SIGNATURE")}
                    </TypographyHead>
                    <Row>
                      <Col lg={12} xs={12}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "250px",
                          }}
                        >
                          {sigimg ? (
                            <Box sx={{ display: "flex", width: "100%" }}>
                              <img
                                src={sigimg}
                                style={{ width: "100%", maxWidth: "100%" }}
                              />{" "}
                            </Box>
                          ) : (
                            <SignatureCanvas
                              backgroundColor="#ffff"
                              canvasProps={{ className: "sigCanvas" }}
                              ref={singpad}
                              onEnd={handleEnd}
                            />
                          )}
                        </div>
                      </Col>
                      <Col lg={2} xs={12} className="my-2">
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <ButtonCustom
                            textButton="Clear"
                            onClick={handleClear}
                            disabled={location.state.params.isView}
                            btnStyle={{
                              marginBottom: "14px",
                              justtifyContent: "center",
                            }}
                          />
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <ButtonCustom
                              textButton="Upload"
                              onClick={handleUploadsig}
                              disabled={location.state.params.isView}
                              btnStyle={{
                                marginBottom: "14px",
                                justtifyContent: "center",
                              }}
                            />
                          </div>
                        </Box>
                      </Col>
                    </Row>

                    <ButtonCustom
                      textButton="เสร็จสิ้น"
                      btnStyle={{ width: "100%" }}
                      onClick={handleSubmit}
                      disabled={location.state.params.isView}
                    />
                  </Container>
                </Boxright>
              </Col>
            </Row>
          </Boxsolid>
        </Boxconteinerin>
      </Box>
    </>
  );
}
