import React, { useCallback, useEffect, useState } from "react";
import TableCustom from "../../../component/table/tableCustom";
import ReportApi from "../../../api/reports/reports.api";
import TableRowCommon from "../../../component/table/TableRowCommon";
import { numberFormat } from "../../../utils/common";

const WaterElectricBillReport = ({
  date_start,
  date_end,
  search_type,
}: {
  date_start: string;
  date_end: string;
  search_type: string;
}) => {
  const [data, setData] = useState<any>([]);

  const handleGetData = useCallback(async () => {
    let condition: any = {
      start_date: date_start ?? "",
      end_date: date_end ?? "",
      type: search_type.toLowerCase() ?? "",
    };

    try {
      const res = await ReportApi.getUtilities(condition);

      if (res.status === 200 && res.data) {
        const waterData = res.data.data?.water.filter(
          (item: any) => item.type !== "total"
        );
        const electricityData = res.data.data?.electricity.filter(
          (item: any) => item.type !== "total"
        );

        // Format the filtered water and electricity data
        const formattedData = waterData.map((item: any, index: number) => {
          const electricItem = electricityData.find(
            (electric: any) => electric.z_zoneId === item.z_zoneId
          );

          return {
            no: index + 1, // Incremental number for 'no'
            zone: item.z_zoneName ?? "Unknown Zone",
            water_total: item.total ?? 0,
            water_pay_approve: item.pay_approve ?? 0,
            water_not_pay: item.not_pay ?? 0,
            electric_total: electricItem?.total ?? 0,
            electric_pay_approve: electricItem?.pay_approve ?? 0,
            electric_not_pay: electricItem?.not_pay ?? 0,
          };
        });

        // Add the 'total' data for both water and electricity
        const waterTotal = res.data.data?.water.find(
          (item: any) => item.type === "total"
        );
        const electricityTotal = res.data.data?.electricity.find(
          (item: any) => item.type === "total"
        );

        // If 'total' data exists, add it to the formatted data
        if (waterTotal && electricityTotal) {
          const totalRow = {
            no: formattedData.length + 1, // Incremental number for 'no'
            zone: "รวมทั้งหมด",
            water_total: waterTotal.total ?? 0,
            water_pay_approve: waterTotal.pay_approve ?? 0,
            water_not_pay: waterTotal.not_pay ?? 0,
            electric_total: electricityTotal.total ?? 0,
            electric_pay_approve: electricityTotal.pay_approve ?? 0,
            electric_not_pay: electricityTotal.not_pay ?? 0,
          };

          formattedData.push(totalRow);
        }

        // Update the state with the formatted data
        setData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [date_start, date_end, search_type]);

  console.log("data", data);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const headCells = [
    {
      id: "no",
      disablePadding: false,
      label: "#",
      align: "center",
      rowSpan: 2,
    },
    {
      id: "zone",
      disablePadding: false,
      label: "พื้นที่",
      align: "center",
      rowSpan: 2,
    },
    {
      id: "water",
      disablePadding: false,
      label: "ค่าน้ำ",
      align: "center",
      colSpan: 3,
    },
    {
      id: "electric",
      disablePadding: false,
      label: "ค่าไฟ",
      align: "center",
      colSpan: 3,
    },
    {
      id: "w_storage_plan",
      disablePadding: false,
      label: "แผนจัดเก็บ",
      align: "center",
      row2: true,
    },
    {
      id: "w_storage_results",
      disablePadding: false,
      label: "ผลจัดเก็บ",
      align: "center",
      row2: true,
    },
    {
      id: "w_overdue",
      disablePadding: false,
      label: "ค้างชำระ",
      align: "center",
      row2: true,
    },
    {
      id: "e_storage_plan",
      disablePadding: false,
      label: "แผนจัดเก็บ",
      align: "center",
      row2: true,
    },
    {
      id: "e_storage_results",
      disablePadding: false,
      label: "ผลจัดเก็บ",
      align: "center",
      row2: true,
    },
    {
      id: "e_overdue",
      disablePadding: false,
      label: "ค้างชำระ",
      align: "center",
      row2: true,
    },
  ];

  const renderData = (objData: any, no: any) => {
    const {
      electric_not_pay,
      electric_pay_approve,
      electric_total,
      water_not_pay,
      water_pay_approve,
      water_total,
      zone,
    } = objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no + 1 },
        {
          option: "TEXT",
          align: "center",
          label: zone,
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(water_total) ?? 0,
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(water_pay_approve) ?? 0,
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(water_not_pay) ?? 0,
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(electric_total) ?? 0,
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(electric_pay_approve) ?? 0,
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(electric_not_pay) ?? 0,
        },
      ],
    };

    return <TableRowCommon {...objRenderData} />;
  };

  return (
    <div>
      <TableCustom
        headCells={headCells}
        customScroll
        page={1}
        pageLimit={1}
        hidePagination
        sortType={"ASC"}
        sortBy=""
        rowCount={0}
        rowsData={
          data &&
          data?.map((d: any, index: any) => {
            return renderData(d, index);
          })
        }
        onSort={() => {}}
        setPageLimit={(newValue) => {}}
        setPage={(newValue) => {}}
      />
    </div>
  );
};

export default WaterElectricBillReport;
