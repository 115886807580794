

import IconProps from "./iconProps"
import { colors } from "../../constants/colors"
export default function TransferApporve(props: IconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1763_180172)">
                <path d="M18.656 0.932025L6.46402 13.124C5.99836 13.5872 5.6292 14.1381 5.3779 14.7449C5.1266 15.3517 4.99817 16.0023 5.00002 16.659V18.002C5.00002 18.2672 5.10538 18.5216 5.29291 18.7091C5.48045 18.8967 5.7348 19.002 6.00002 19.002H7.34302C7.99978 19.0039 8.65039 18.8754 9.25718 18.6241C9.86396 18.3728 10.4149 18.0037 10.878 17.538L23.07 5.34602C23.6544 4.7602 23.9826 3.9665 23.9826 3.13903C23.9826 2.31155 23.6544 1.51785 23.07 0.932025C22.4757 0.363919 21.6852 0.046875 20.863 0.046875C20.0408 0.046875 19.2503 0.363919 18.656 0.932025ZM21.656 3.93203L9.46402 16.124C8.90015 16.6845 8.13803 16.9999 7.34302 17.002H7.00002V16.659C7.0021 15.864 7.31759 15.1019 7.87802 14.538L20.07 2.34603C20.2836 2.14198 20.5676 2.02812 20.863 2.02812C21.1584 2.02812 21.4424 2.14198 21.656 2.34603C21.866 2.55654 21.9839 2.84172 21.9839 3.13903C21.9839 3.43633 21.866 3.72151 21.656 3.93203Z" fill="#374957" />
                <path d="M23 8.979C22.7348 8.979 22.4804 9.08436 22.2929 9.27189C22.1054 9.45943 22 9.71379 22 9.979V15H18C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18V22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H14.042C14.3072 2 14.5616 1.89464 14.7491 1.70711C14.9366 1.51957 15.042 1.26522 15.042 1C15.042 0.734784 14.9366 0.48043 14.7491 0.292893C14.5616 0.105357 14.3072 0 14.042 0L5 0C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H16.343C16.9999 24.0019 17.6507 23.8735 18.2576 23.6222C18.8646 23.3709 19.4157 23.0017 19.879 22.536L22.535 19.878C23.0008 19.4149 23.37 18.864 23.6215 18.2572C23.873 17.6504 24.0016 16.9998 24 16.343V9.979C24 9.71379 23.8946 9.45943 23.7071 9.27189C23.5196 9.08436 23.2652 8.979 23 8.979ZM18.465 21.122C18.063 21.523 17.5547 21.8006 17 21.922V18C17 17.7348 17.1054 17.4804 17.2929 17.2929C17.4804 17.1054 17.7348 17 18 17H21.925C21.8013 17.5535 21.524 18.0609 21.125 18.464L18.465 21.122Z" fill="#374957" />
            </g>
            <defs>
                <clipPath id="clip0_1763_180172">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

