import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon, { DropdownItem } from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from "./stall.style"

/** API */
import PaymentTypeApi from "../../../api/setting/debttype/debttype.api";
import { swalActive } from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { Col, Dropdown, Row } from "react-bootstrap";
import { colors } from "../../../constants/colors";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import Meatball from "../../../assets/icon/meatball";



const initStateErrorMessage = {
  DEBTTYPE_NAME_TH: ``,
  IS_DUPLICATE_DEBTTYPE_NAME_TH: ``,
  DEBTTYPE_NAME_TH_STRING_BASE: ``,
  DEBTTYPE_NAME_TH_STRING_EMPTY: ``,
  DEBTTYPE_NAME_TH_MAXIMUM_LENGTH: ``,
  DEBTTYPE_NAME_TH_ANY_REQUIRED: ``,

  DEBTTYPE_NAME_EN: ``,
  IS_DUPLICATE_DEBTTYPE_NAME_EN: ``,
  DEBTTYPE_NAME_EN_STRING_BASE: ``,
  DEBTTYPE_NAME_EN_STRING_EMPTY: ``,
  DEBTTYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  DEBTTYPE_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function PaymentType() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [debtTypeId, setDebtTypeId] = useState(Number)
  const [debtTypeNameTh, setDebtTypeNameTh] = useState<string>('')
  const [debtTypeNameEn, setDebtTypeNameEn] = useState<string>('')
  const [debtTypeStatus, setDebtTypeStatus] = useState<string>('ACTIVE')
  const [debtTypeData, setDebtTypeData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status !== "ALL") condition = { ...condition, status: status }

    const res = await PaymentTypeApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setDebtTypeData(res.data)

    } else {
      setRowCount(0)
      setDebtTypeData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])


  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)

  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "debtTypeId", disablePadding: false, label: "NO" },
    { id: "debtTypeNameTh", disablePadding: false, label: t("DEBTTYPE.HEADCELL.DEBTTYPENAMETH"), sortable: true },
    { id: "debtTypeNameEn", disablePadding: false, label: t("DEBTTYPE.HEADCELL.DEBTTYPENAMEEN"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("DEBTTYPE.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("DEBTTYPE.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("DEBTTYPE.HEADCELL.ACTION") }
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DEBTTYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          PaymentTypeApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('DEBTTYPE.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)

              loadData()
            })
            .catch((e) => {
              notiError(t('DEBTTYPE.MESSAGE.ERROR'), '', null, null)

            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { paymentServiceId, paymentName, paymentNameEn } = data
    // const { debtTypeId, debtTypeNameTh, debtTypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DEBTTYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('DEBTTYPE.ALERT.CONFIRM_DELETE', {
        debtTypeNameTh: paymentName, debtTypeNameEn: paymentNameEn ? '' + paymentNameEn + '' : ''
        // debtTypeNameTh: debtTypeNameTh, debtTypeNameEn: debtTypeNameEn ? '' + debtTypeNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          PaymentTypeApi.delete(paymentServiceId)
            .then((res) => {
              notiSuccess(t('DEBTTYPE.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('DEBTTYPE.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    console.log(data);
    const { paymentServiceId, paymentName, paymentNameEn, status } = data
    setDebtTypeId(paymentServiceId)
    setDebtTypeNameTh(paymentName)
    setDebtTypeNameEn(paymentNameEn)
    // const { debtTypeId, debtTypeNameTh, debtTypeNameEn, status } = data
    // setDebtTypeId(debtTypeId)
    // setDebtTypeNameTh(debtTypeNameTh)
    // setDebtTypeNameEn(debtTypeNameEn)
    setStatus(status)
    dispatch(showModal())
  }

  const resetForm = () => {
    setDebtTypeId(0)
    setDebtTypeNameTh('')
    setDebtTypeNameEn('')
    setDebtTypeStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { paymentServiceId, paymentName, paymentNameEn, modifiedBy, modifiedDate, status, isEdit } = objData;

    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: paymentServiceId,
      id: paymentServiceId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: paymentName || "-" },
        { option: "TEXT", align: "left", label: paymentNameEn || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        {
          option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        // {
        //   option: 'ACTION',
        //   align: 'center',
        //   label: 'action',
        //   values: [
        //     { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
        //     { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
        //     { option: 'DIVIDER', label: '', disabled: false },
        //     { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
        //     { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
        //   ]
        // }
        {
          option: "COMPONENT",
          align: "center",

          style: { color: colors.themeMainColor },
          component: (
            <Box>
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  bsPrefix="p-0  "
                  className="btn-table-action text-black-50 shadow-none"
                  disabled={isEdit === "FALSE" ? true : false}
                >
                  <Meatball />
                </Dropdown.Toggle>
                {isEdit === "FALSE" ? "" : <DropdownMenu className="dropdown-table-action position-fixed">
                  <DropdownItem disabled={statusBtnActive} onClick={() => onActive(Number(objRenderData.id), 'ACTIVE')}>{t(`STATUS.ACTIVE`)}</DropdownItem>
                  <DropdownItem disabled={statusBtnInActive} onClick={() => onActive(Number(objRenderData.id), 'INACTIVE')}>{t(`STATUS.INACTIVE`)}</DropdownItem>
                  <Divider className="my-2"/>
                  <DropdownItem disabled={permissions.isUpdate.disabled} onClick={() => onEdit(objRenderData.obj)}><img src={icons.dropdownEdit} className="align-baseline mr-2" />{t(`BUTTON.EDIT`)}</DropdownItem>
                  <DropdownItem disabled={permissions.isDelete.disabled} onClick={() => onDelete(objRenderData.obj)}><img src={icons.dropdownTrash} className="align-baseline mr-2" />{t(`BUTTON.DELETE`)}</DropdownItem>
                </DropdownMenu>}
              </Dropdown>
            </Box>
          ),
        },
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };


  const submit = () => {
    if (!debtTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ DEBTTYPE_NAME_TH: t('DEBTTYPE.MESSAGE.DEBTTYPE_NAME_TH') } })
    if (!debtTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ DEBTTYPE_NAME_EN: t('DEBTTYPE.MESSAGE.DEBTTYPE_NAME_EN') } })

    dispatch(submitModal())
    PaymentTypeApi.create(debtTypeNameTh, debtTypeNameEn)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('DEBTTYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({
            ...errorMessage,
            ...{
              [err.error_description]: t(`DEBTTYPE.MESSAGE.${err.error_description}`)
            }
          })
          if (err.statusCode === 400) {
            if (err.error_description === t('DEBTTYPE.MESSAGE.IS_DUPLICATE_DEBTTYPE_NAME_TH')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  DEBTTYPE_NAME_TH: t(`${t(err.error_description)}`),

                }
              })
            } else if (err.error_description === t('DEBTTYPE.MESSAGE.IS_DUPLICATE_DEBTTYPE_NAME_EN')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  DEBTTYPE_NAME_EN: t(`${t(err.error_description)}`),

                }
              })
            }
          }
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`DEBTTYPE.MESSAGE.${err.error_description}`) } })
        if (err.statusCode === 400) {
          if (err.error_description === t('DEBTTYPE.MESSAGE.IS_DUPLICATE_DEBTTYPE_NAME_TH')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                DEBTTYPE_NAME_TH: t(`${t(err.error_description)}`),

              }
            })
          } else if (err.error_description === t('DEBTTYPE.MESSAGE.IS_DUPLICATE_DEBTTYPE_NAME_EN')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                DEBTTYPE_NAME_EN: t(`${t(err.error_description)}`),

              }
            })
          }
        }

        dispatch(unSubmitModal())
      })

  }

  const submitEdit = () => {
    if (!debtTypeNameTh) return setErrorMessage({ ...errorMessage, ...{ DEBTTYPE_NAME_TH: t('DEBTTYPE.MESSAGE.DEBTTYPE_NAME_TH') } })
    if (!debtTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ DEBTTYPE_NAME_EN: t('DEBTTYPE.MESSAGE.DEBTTYPE_NAME_EN') } })
    dispatch(submitModal())
    const condition: any = {
      paymentName: debtTypeNameTh,
      paymentNameEn: debtTypeNameEn,
      status: debtTypeStatus
    }
    PaymentTypeApi.update(debtTypeId, condition)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`DEBTTYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`DEBTTYPE.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data

        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`DEBTTYPE.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  return (
    <>
      <HeaderCard text={t("DEBTTYPE.TITLE")} />
      <Style.MainContainer>
        <Box>
          <Row>
            <Col lg={3} md={6} xs={12} className="my-1">
              <InputTextField label={t("DEBTTYPE.INPUT.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col lg={3} md={6} xs={12} className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value)
                    setPage(1)
                  }
                }}
                renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status"
                value={status}
                labelId="label-status"
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </Col>
            <Col lg={3} md={6} xs={12}></Col>
            <Col lg={3} md={6} xs={12} className="my-1">
              <ButtonCustom style={{ width: '100%' }}
                disabled={permissions.isCreate.disabled}
                type="button"
                textButton={t('DEBTTYPE.BUTTON.ADD')}
                endIcon={<img src={icons.add} />}
                onClick={() => dispatch(showModal())}
              />
            </Col>
          </Row>
        </Box>
        <Style.TableContainer>

          <TableCustom
            headCells={headCells}
            customScroll-
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={debtTypeData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}
            tableMinWidth={1000}
          />
        </Style.TableContainer>

      </Style.MainContainer>

      <ModalCustom
        title={debtTypeId ? t('DEBTTYPE.TITLE_UPDATE') : t('DEBTTYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
              onchange={(event) => {
                setDebtTypeNameTh(event.target.value)
                clearErrorMessage()
              }}
              value={debtTypeNameTh}
              helperText={
                errorMessage.DEBTTYPE_NAME_TH ||
                errorMessage.IS_DUPLICATE_DEBTTYPE_NAME_TH ||
                errorMessage.DEBTTYPE_NAME_TH_STRING_BASE ||
                errorMessage.DEBTTYPE_NAME_TH_STRING_EMPTY ||
                errorMessage.DEBTTYPE_NAME_TH_MAXIMUM_LENGTH ||
                errorMessage.DEBTTYPE_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('DEBTTYPE.INPUT.DEBTTYPE_NAME_TH')}
            />
            <InputTextField
              key="debtTypeNameEn"
              onchange={(event) => {
                setDebtTypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={debtTypeNameEn}
              helperText={
                errorMessage.DEBTTYPE_NAME_EN ||
                errorMessage.IS_DUPLICATE_DEBTTYPE_NAME_EN ||
                errorMessage.DEBTTYPE_NAME_EN_STRING_BASE ||
                errorMessage.DEBTTYPE_NAME_EN_STRING_EMPTY ||
                errorMessage.DEBTTYPE_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.DEBTTYPE_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('DEBTTYPE.INPUT.DEBTTYPE_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setDebtTypeStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${debtTypeStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={debtTypeId ? submitEdit : submit}
        textBtnCancel={t('DEBTTYPE.BUTTON.CANCEL')}
        textBtnConfirm={t('DEBTTYPE.BUTTON.SAVE')}
      />

    </>
  );
}
