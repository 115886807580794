import common from './common.json'
import login from './auth/login.json'
import resgister from './auth/register.json'
import home from './home/home.json'
import menu from './menu.json'
import submitStall from './store/submitStall.json'
import deposit from './store/depositPay.json'
import stallAgreement from './store/stallArgeement.json'
import prefix from './settings/prefix.json' 
import transferStall from './transferStall/transferStall.json'
import branch from './settings/branch.json'
import debttype from './settings/debttype.json'
import leases from './settings/leasestype.json'
import maintenancetype from './settings/maintenancetype.json'
import newstype from './settings/newstype.json'
import payment from './settings/payment.json'
import petitiontype from './settings/petitiontype.json'
import relashionship from './settings/relashionship.json'
import certificate from './certificate/certificate.json'
import admin from './admin/admin.json'
import paybill from './paybill/paybill.json'
import calculation from './calcultion/calculation.json'
import repair from './repair/repair.json'
import addrepair from './repair/add/addRepair.json'
import createadmin from './admin/createAdmin.json'
import sue from './sue/sue.json'
import trackbill from './trackBill/trackBill.json'

import electricitybill from './utilityBill/electricitybill.json'
import WaterBill from './utilityBill/waterbill.json'
import addadmin from './admin/createAdmin.json'
import systemlog from './systemlog/system.json'
import water from './settings/water.json'
import electricity from './settings/electricity.json'
import user from './settings/user.json'
import statistic from './statistic/statistic.json'
import marketMap from './stallInfo/marketMap.json'
import addminType from './admin/adminType.json'
import stalltype from './settings/stall.json'
import rentStall from './stall/rentStall.json'
import requestStall from './stall/requestStall.json'
import cancelContract from './cancelcontract/cancelcontract.json'
import announce from './announce/announce.json'
import biddertype from './settings/biddertype.json'
import stallReport from './stallInfo/stallReport.json'
import renterDetail from './stallInfo/renterDetail.json'
import agreement from './store/agreement.json'
import requesterDetail from './stall/requesterDetail.json'
import reportRentStall from './stall/reportRentStall.json'
import repairTypeManagement from './utilityBill/repairTypeManagement.json'
import detailInfo from './home/detailInfo.json'
import management_utility from './utilityBill/management_utility.json'
import report from './reports/reports.json'
// import repair from './repair/repair.json'
// import addrepair from './repair/add/addRepair.json'
// import electricitybill from './utilityBill/electricitybill.json'
// import WaterBill from './utilityBill/waterbill.json'
// import addadmin from './admin/createAdmin.json'
// import systemlog from './systemlog/system.json'
// import waterbill from './utilityBill/waterbill.json'

export const mofLocaleTH = {
  ...login,
  ...resgister,
  ...common,
  ...menu,
  ...home,
  ...submitStall,
  ...deposit,
  ...stallAgreement,
  ...prefix,
  ...transferStall,
  ...branch,
  ...debttype,
  ...leases,
  ...maintenancetype,
  ...newstype,
  ...payment,
  ...petitiontype,
  ...relashionship,
  ...certificate,
  ...admin,
  ...trackbill,
  ...electricitybill,
  ...repair,
  ...addrepair,
  ...sue,
  ...addadmin,
  ...WaterBill,
  ...paybill,
  ...calculation,
  ...systemlog,
  ...water,
  ...electricity,
  ...statistic,
  ...marketMap,
  ...addminType,
  ...stalltype,
  ...rentStall,
  ...requestStall,
  ...cancelContract,
  ...announce,
  ...biddertype,
  ...user,
  ...stallReport,
  ...renterDetail,
  ...agreement,
  ...requesterDetail,
  ...reportRentStall,
  ...repairTypeManagement,
  ...detailInfo,
  ...management_utility,
  ...report
  // ...trackbill,
  // ...electricitybill,
  // ...repair,
  // ...addrepair,
  // ...sue,
  // ...addadmin,
  // ...WaterBill,
  // ...paybill,
  // ...calculation,
  // ...systemlog,
  // ...calculation,
  // ...waterbill
}
