import BaseAPI from '../api'
import { FindAllInterface } from '../apiModel'

export interface createUser {
  prefixId: number,
  firstname: string,
  lastname: string,
  email: string,
  mobilePhone: string,
  username: string,
  password: string,
  roleId: number,
  status: string,
  reCaptcha: string,
  id_token: string
}

export interface updatemrmber {
  prefixId?: number
  firstname?: string,
  lastname?: string,
  email?: string,
  idCard?: string
  mobilePhone: string,
  birthDay?: string,
  taxId?: string,
  houseNumber?: string,
  moo?: string,
  village?: string,
  soi?: string,
  road?: string,
  provinceId?: number,
  districtId?: number,
  subDistrictId?: number,
  zipCode?: string
  status?: string,
  profile?: string
}

export interface updateStatusType {
  status: string;
  remark: string | null;
}

const path = 'users'

export default class UserApi extends BaseAPI {

  static create(payload: createUser): Promise<any> {
    return this.api.post(`${path}`, payload).then((res) => res)
  }

  static createGoogle(payload: createUser): Promise<any> {
    return this.api.post(`${path}/google`, payload).then((res) => res)
  }

  static update(payload: updatemrmber, id: number): Promise<any> {
    return this.api.patch(`${path}/${id}`, payload).then((res) => res)
  }

  static getDetail(payload: any): Promise<any> {
    return this.api.get(`${path}/${payload.id}`).then((res) => res)
  }

  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(path, { params: { ...props } }).then((res) => res)
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`${path}/${id}`)
  }

  static delete(id: number) {
    return this.api.delete(`${path}/${id}`)
  }
  static getAll(payload: any): Promise<any> {
    return this.api.get(`${path}`, payload).then((res) => res)
  }

  static findIdcard(id: string): Promise<any> {
    return this.api.get(`${path}/id-card${id}`)
  }
  static updateStatus(id: number, props: updateStatusType): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, props).then(res => res);
  }

}
