import { Box, Typography } from "@mui/material";
import { BGModal } from "./style";

export default function Policy() {
  return (
    <>
      <BGModal>
        <Box className="w-100 d-flex justify-content-center align-item-center">
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            ประกาศองค์การตลาดเพื่อเกษตรกร
          </Typography>
        </Box>
        <Box className="w-100 d-flex justify-content-center align-item-center">
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            เรื่อง นโยบายคุ้มครองข้อมูลส่วนบุคคล (Personal Data Privacy Policy){" "}
          </Typography>
        </Box>
        <Box className="w-100 d-flex justify-content-center align-item-center">
          <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
            ฉบับปรับปรุง พ.ศ. ๒๕๖๖
          </Typography>
        </Box>
        <br />
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;องค์การตลาดเพื่อเกษตรกร (อ.ต.ก.)
          ในฐานะผู้ควบคุมข้อมูลส่วนบุคคลตามพระราชบัญญัติ คุ้มครองข้อมูลส่วนบุคคล
          พ.ศ. ๒๕๖๒
          ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคลที่มีประสิทธิภาพ
          สอดคล้องกับหลักการพื้นฐานการคุ้มครองข้อมูลส่วนบุคคล คือ หลักความจำเป็น
          ความได้สัดส่วน และการเคารพ
          สิทธิขั้นพื้นฐานของเจ้าของข้อมูลส่วนบุคคลตามรัฐธรรมนูญ และมาตรฐานสากล
          จึงได้จัดทำนโยบายคุ้มครองข้อมูล
          ส่วนบุคคลเพื่อให้เจ้าของข้อมูลส่วนบุคคล สาธารณชนรับทราบ
          และเข้าใจถึงวัตถุประสงค์ หลักการ และนโยบาย เกี่ยวกับการเก็บรวบรวม ใช้
          และเปิดเผยข้อมูลส่วนบุคคล เพื่อดำเนินการบริหารตลาด
          และดำเนินการตามกฎหมาย อื่นที่กำหนดให้เป็นหน้าที่และอำนาจของ อ.ต.ก.
          ตลอดจนเพื่อบริหารจัดการภายในของ อ.ต.ก. จึงประกาศนโยบาย
          คุ้มครองข้อมูลส่วนบุคคล (Personal Data Privacy Policy) ฉบับปรับปรุง
          พ.ศ. ๒๕๖๖ ดังต่อไปนี้
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 700, textAlign: "start" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;คำนิยาม
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“อ.ต.ก.” หมายความว่า
          องค์การตลาดเพื่อเกษตรกร
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“ข้อมูลส่วนบุคคล”
          หมายความว่า ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
          ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“ผู้ควบคุมข้อมูลส่วนบุคคล”
          หมายความว่า บุคคลหรือนิติบุคคลซึ่งมีอำนาจหน้าที่ตัดสินใจ
          เกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“ผู้ประมวลผลข้อมูลส่วนบุคคล”
          หมายความว่า บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับ การเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล
          ทั้งนี้ บุคคล
          หรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“ข้อมูลส่วนบุคคลอ่อนไหว”
          หมายความว่า ข้อมูลส่วนบุคคลที่กฎหมายจัดประเภทเป็น
          ข้อมูลส่วนบุคคลอ่อนไหว ตามมาตรา ๒๖
          แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.๒๕๖๒ เช่น เชื้อชาติ
          เผ่าพันธุ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา
          พฤติกรรมทางเพศ ประวัติ, อาชญากรรม ข้อมูลสุขภาพ ความพิการ
          ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ
          หรือข้อมูลอื่นใดซึ่งกระทบต่อ
          เจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลจะประกาศกำหนด
          โดยไม่ได้ รับความยินยอมชัดแจ้งงจากเจ้าของข้อมูลส่วนบุคคล
        </Typography>
        <br />
        <Typography sx={{ fontSize: 18, fontWeight: 700, textAlign: "start" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;การเก็บรวบรวมข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๑ อ.ต.ก.
          จะดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลที่สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรง
          หรือทางอ้อม (Identified or Identifiable Person) อาทิ ชื่อ นามสกุล
          รูปถ่าย บัตรประจำตัวประชาชน รายละเอียด ที่อยู่และการติดต่อ
          ข้อมูลทางการเงิน ข้อมูลประวัติส่วนตัว เป็นต้น
          ทั้งจากเจ้าของข้อมูลที่ได้รับมาโดยตรงและจาก
          แหล่งอื่นที่ไม่ใช่จากเจ้าของข้อมูลโดยตรง เช่น
          การสืบค้นข้อมูลส่วนบุคคลผ่านระบบเว็บไซต์ หรือการสอบถาม จากบุคคลที่สาม
          โดย อ.ต.ก. จะแจ้งให้เจ้าของข้อมูลทราบโดยไม่ชักช้าภายใน ๓0 วัน
          นับแต่วันที่ดำเนินการเก็บ รวบรวมข้อมูลจากแหล่งดังกล่าว ทั้งนี้
          การเก็บรวบรวมข้อมูลส่วนบุคคลนั้นจะกระทำเท่าที่จำเป็นภายใต้วัตถุประสงค์
          เช่น
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๑) เพื่อการบริหารจัดการตลาดและสินค้าเกษตร
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;อ.ต.ก.
          มีหน้าที่ในการบริหารตลาดให้เป็นไปตามมาตรฐานสากล เพื่อให้เป็นแหล่งกลาง
          ในการซื้อขายผลิตผลหรือผลิตภัณฑ์ทางการเกษตร และสินค้าอื่น ๆ ซึ่ง อ.ต.ก.
          มีความจำเป็นต้องเก็บรวบรวมข้อมูล
          รวมถึงข้อมูลส่วนบุคคลของผู้ที่เกี่ยวข้องในการบริหารจัดการตลาด
          เพื่อประโยชน์ไนการติดต่อประสานงานหน่วยงาน
          ภาครัฐและเอกชนในการซื้อขายผลิตผล ผลิตภัณฑ์ทางการเกษตร
          หรือดำเนินการอื่นที่เกี่ยวข้องตามพระราชกฤษฎีกา
          จัดตั้งองค์การตลาดเพื่อเกษตรกร พ.ศ. ๒๕๑๗
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๒) เพื่อกำกับ
          ตรวจสอบการบริหารจัดการตลาดและสินค้าเกษตร
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;อ.ต.ก. มีหน้าที่ในการกำกับ ดูแล
          ตรวจสอบการบริหารจัดการตลาดและสินค้าเกษตร ให้เป็นไปตามกฎ
          ระเบียบที่เกี่ยวข้อง จึงมีความจำเป็นที่จะต้องเก็บรวบรวมข้อมูล
          รวมถึงข้อมูลส่วนบุคคล สำหรับใช้ กำกับ ติดตาม ตรวจสอบ
          ประเมินผลการบริหารจัดการร้านค้าและสินค้าของเกษตรกร กลุ่มเกษตรสหกรณ์
          วิสาหกิจ ชุมชน ผู้ประกอบการ ให้ได้มาตรฐานตามที่กำหนด
          รวมทั้งข้อมูลทางการเงิน ข้อมูลรายได้ ข้อมูลหนี้สิน เพื่อใช้เป็น
          ข้อมูลประกอบการวิเคราะห้
          กำหนดนโยบายหรือมาตรการที่เกี่ยวข้องในการแกไขปัญหา การพัฒนาการดำเนินงาน
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๓) เพื่อกำกับ
          ดูแลและพัฒนาระบบการให้บริการสั่งซื้อสินค้า ระบบการชำระเงิน
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;อ.ต.ก.
          มีความจำเป็นต้องเก็บรวบรวมข้อมูลส่วนบุคคลในการทำหน้าที่กำกับ ดูแล
          และพัฒนาระบบการให้บริการสั่งซื้อสินค้า ระบบการชำระเงินใท้มีความมั่นคง
          ปลอดภัย และมีประสิทธิภาพ
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๔) เพื่อส่งเสริม
          ประชาสัมพันธ์ผลิตผลหรือผลิตภัณฑ์ทางการเกษตร
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;อ.ต.ก.
          มีความจำเป็นต้องเก็บรวบรวมข้อมูลส่วนบุคคลของเกษตรกร กลุ่มเกษตร สหกรณ์
          วิสาหกิจชุมชน ผู้ประกอบการ
          เพื่อประโยชน์ในการติดต่อประสานงานหน่วยงานภาครัฐและเอกชนในการส่งเสริม
          ประชาสัมพันธ์ผลิตผลหรือผลิตภัณฑ์ทางการเกษตร
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๕)
          เพื่อการดำเนินกิจการอันเป็นประโยชน์ใดยชอบด้วยกฎหมายสำหรับประชาชน
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;อ.ต.ก.
          ให้บริการตลาดในการเป็นแหล่งซื้อขายผลิตผลหรือผลิตภัณฑ์ทางการเกษตร
          และให้ความรู้แก่เกษตรกร กลุ่มเกษตร สหกรณ์ วิสาหกิจชุมชน ผู้ประกอบการ
          และผู้มาใช้บริการ ผ่านโครงการ กิจกรรม ประกาศต่าง ๆ
          ซึ่งจำเป็นต้องเก็บรวบรวมข้อมูลส่วนบุคคลเพื่อประโยชน์ในการให้บริการและการดำเนินการ
          ดังกล่าวให้บรรลุตามวัตถุประสงค์ข้างต้น
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๖) เพื่อรักษาความปลอดภัยของบุคคล สถานที่
          และทรัพย์สินของ อ.ต.ก.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;การรักษาความปลอดภัยของบุคคล สถานที่
          และทรัพย์สิน อ.ต.ก. จำเป็นต้องได้รับข้อมูล
          ส่วนบุคคลของผู้ที่เข้ามาในพื้นที่ อาทิ ชื่อ นามสถุล หน่วยงาน รูปถ่าย
          รวมทั้งมีการบันทึกภาพผ่านทางโทรทัศน์ วงจรปิด (Closed-circuit
          television : CCTV)
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;(๗) เพื่อการบริหารงานบุคคล
          และการบริหารจัดการภายในองค์กร
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๒ อ.ต.ก.
          จะกระทำการเก็บรวบรวมข้อมูลส่วนบุคคลเมื่อได้รับความยินยอมจากเจ้าของข้อมูล
          ส่วนบุคคลไว้ก่อนหรือในขณะนั้น
          เว้นแต่บทบัญญัติแห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
          หรือ กฎหมายอื่นบัญญัติไห้กระทำได้
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;กรณีที่เจ้าของข้อมูลส่วนบุคคลเป็นผู้เยาว์ซึ่งยังไม่บรรลุนิติภาวะโดยการสมรส
          หรือไม่มีฐานะ เสมือนดังบุคคลซึ่งบรรลุนิติภาวะแล้วตามมาตรา ๒๗
          แห่งประมวลกฎหมายแพ่งและพาณิชย์ อ.ต.ก. จะกระทำการ
          เก็บรวบรวมข้อมูลส่วนบุคคลเมื่อได้รับความยินยอมจากผู้ใช้อำนาจปกครองที่มีอำนาจกระทำการแทนผู้เยาว์
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 700, textAlign: "start" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;การใช้หรือเปิดเผยข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๓ อ.ต.ก.
          จะใช้และเปิดเผยข้อมูลส่วนบุคคลเท่าที่จำเป็นภายใต้วัตถุประสงค์ตามข้อ ๑
          หรือ
          ดำเนินการอื่นที่เกี่ยวข้องตามพระราชกฤษฎีกาจัดตั้งองค์การตลาดเพื่อเกษตรกร
          พ.ศ. ๒๕๑๗
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๔ อ.ต,ก. จะไม่ใช้
          และไม่เปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับความยินยอมจากเจ้าของข้อมูล
          ส่วนบุคคลไว้ก่อนหรือในขณะนั้นเว้นแต่บทบัญญัติแห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.๒๕๖๒
          หรือ กฎหมายอื่นบัญญัติให้กระทำได้
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 700, textAlign: "start" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;สิทธิของเจ้าของข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๕
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับตน
          หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอม
          เว้นแต่บทบัญญัติ แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. ๒๕๖๒
          หรือกฎหมายอื่นบัญญัติให้ผู้ควบคุมข้อมูลส่วนบุคล (อ.ต.ก.) ปฏิเสธคำขอได้
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๖
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิคัดค้านการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลที่ เกี่ยวกับตน
          เว้นแต่ข้อมูลนั้นมีเหตุอันชอบด้วยกฎหมาย
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๗
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการขอแก่ไขข้อมูลที่ไม่ถูกต้อง
          ไม่เป็นปัจจุบัน ไม่สมบูรณ์ และข้อมูลที่อาจก่อให้เกิดความเข้าใจผิด
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๘
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคล
          ที่ได้ให้ความยินยอมไว้
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๙
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการให้โอนย้ายข้อมูลไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๑๐
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการระงับการใช้ข้อมูลส่วนบุคคลได้ระหว่างรอตรวจสอบ
          ความถูกต้องของข้อมูลตามที่ได้ร้องขอให้แก่ไขข้อมูลดังกล่าวให้ถูกต้อง
          หรือขอให้ระงับการใช้แทนการลบข้อมูล ส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๑๑
          เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการลบข้อมูลส่วนบุคคล
          หรือทำลายข้อมูลส่วนบุคคล
          หากพบว่าไม่มีความจำเป็นที่จะเก็บรักษาไว้ตามวัตถุประสงค์ในการเก็บรวบรวม
          ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้
          การใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลและการพิจารณาดำเนินการของ อ.ต.ก.
          ดังกล่าวข้างต้น ต้องเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
          ๒๕๖๒ หรือกฎหมายอื่นที่เกี่ยวข้อง อ.ต.ก.
          อาจปฏิเสธในการดำเนินการตามคำขอในกรณีที่มีเหตุตามที่กฎหมายกำหนด
          โดยเจ้าของข้อมูลส่วนบุคคล ที่ประสงค์จะใช้สิทธิดังกล่าว
          สามารถติดต่อไปยังเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลตามช่องทางการติดต่อที่ระบุไว้
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 700, textAlign: "start" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;การรักษาความปลอดภัยสำหรับข้อมูลส่วนบุคคล
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๑๒ อ.ต.ก.
          ได้จัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลที่เหมาะสม
          ตามาตรฐานสากล โดยมีการนำเทคโนโลยีป้องกันการรั่วไหลของข้อมูล (Data Leak
          Prevention) มาประยุกต์ใช้ กับข้อมูลทุกประเภทเพื่อป้องกันการสูญหาย
          การเข้าถึง การใช้ การเปลี่ยนแปลง การแก่ไข หรือการเปิดเผยข้อมูลส่วน
          บุคคล โดยไม่มีอำนาจ หรือโดยขัดต่อกฎหมายซึ่งสอดคล้องกับนโยบาย
          และวิธีปฏิบัติด้านความมั่นคงปลอดภัย สารสนเทศ
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๑๓ อ.ต.ก. จะดำเนินการทำลาย ลบ
          หรือทำให้ข้อมูลดังกล่าวไม่สามารถระบุตัวตนบุคคล
          ของเจ้าของข้อมูลส่วนบุคคลได้เมื่อดำเนินการตามวัตถุประสงค์ในข้อ ๑
          เสร็จเรียบร้อยแล้ว หรือเมื่อไม่มีเหตุ จำเป็นในการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลดังกล่าว
          การทบทวนและเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล ของ อ.ต.ก.
        </Typography>
        <Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;ข้อ ๑๔ อ.ต.ก. อาจทำการทบทวน
          ปรับปรุงหรือแก่ไขนโยบายคุ้มครองข้อมูลส่วนบุคคล (Personal Data Privacy
          Policy) เป็นครั้งคราวเพื่อให้สอดคล้องกับข้อกำหนดตามกฎหมาย ข้อบังคับ
          แนวปฏิบัติที่เกี่ยวข้อง รวมทั้งการเปลี่ยนแปลงการดำเนินงานขององค์กร
          ข้อเสนอแนะและความคิดเห็นจากหน่วยงานต่าง ๆ โดย อ.ต.ก.
          จะเผยแพร่ประกาศการเปลี่ยนแปลงให้ทราบบนเว็บไซต์ของ อ.ต.ก.
          ก่อนจะเริ่มดำเนินการเปลี่ยนแปลง
        </Typography>
        
      </BGModal>
    </>
  );
}