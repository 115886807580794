import { Box, Button, Container, IconButton, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  Boxjusttify,
  TypographyHead,
  Typographymodal,
} from "./announceStyle";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import TableRowCommon from "../../component/table/TableRowCommon";
import { useTranslation } from "react-i18next";
import TableCustom from "../../component/table/tableCustom";
import { colors } from "../../constants/colors";
import ModalCustom from "../../component/modalCustom/modalCustom";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../app/slice/modal.slice";
import ButtonCustom from "../../component/button/buttonCustom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Col, Dropdown, Row } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Star from "../../assets/icon/bookmark/star.svg";
import Notfavorite from "../../assets/icon/bookmark/notfavorite.svg";
import PreviewImage from "../../component/image/PreviewImage";
import AnnounceApi from "../../api/home/announce.api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
/** STYLE */
import * as Style from "./announceStyle";
import NewsTypeApi from "../../api/setting/news/news.api";
import {
  dateTimeToView,
  dateToView,
} from "../../utils/date.utils";
import { notiError } from "../../component/notification/notifications";
import { getProfile } from "../../utils/app.utils";
import { decode } from 'js-base64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPrint } from "@fortawesome/free-solid-svg-icons";
import { routeName } from "../../routes/routers-name";

export default function Announce() {
  const profile: any = JSON.parse(getProfile() || "{}");
  const roleId = JSON.parse(decode(profile))?.roleId;

  const [open, setOpen] = useState<boolean>(true);
  const [listAnnounce, setListAnnounce] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [pageround, setPageRound] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(5);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [newtypeId, setNewTyperId] = useState<number>();
  const [rowCount, setRowCount] = useState<number>();
  const [listNewtype, setNewtype] = useState<any>([]);
  const [sortBy, setSortBy] = useState<string>("modifiedDate");
  const [sortType, setSortType] = useState<string>("ASC");
  const [search, setSearch] = useState<string>("");
  const [detail, setDetail] = useState<any>();
  const [listBookmark, setListBookmark] = useState<any>([]);
  const [modalType, setModalType] = useState<string>("")
  const [select, setSelectfile] = useState<any>();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const headCells = [
    { id: "no", disablePadding: false, label: "#",align: "center", },
    {
      id: "date",
      disablePadding: false,
      label: t("ANNOUNCE.TABLEHEAD.DATEDOC"),
      align: "center",
    },
    {
      id: "type",
      disablePadding: false,
      label: t("ANNOUNCE.TABLEHEAD.TYPE"),
      align: "center",
    },
    {
      id: "datetime",
      disablePadding: false,
      label: t("ANNOUNCE.TABLEHEAD.DATE_TIME"),
      align: "center",
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("ANNOUNCE.TABLEHEAD.STATUS"),
    },
    {
      id: "datesumit",
      disablePadding: false,
      align: "center",
      label: t("ANNOUNCE.TABLEHEAD.UPDATEBY"),
    },
    {
      id: "favorite",
      disablePadding: false,
      align: "center",
      label: t("Bookmark"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("Action"),
    },
  ];
  const headCellUser = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      label: t("ANNOUNCE.TABLEHEAD.DATEDOC"),
      align: "center",
    },
    {
      id: "type",
      disablePadding: false,
      label: t("ANNOUNCE.TABLEHEAD.TYPE"),
      align: "center",
    },
    {
      id: "datetime",
      disablePadding: false,
      label: t("ANNOUNCE.TABLEHEAD.DATE_TIME"),
      align: "center",
    },
    {
      id: "datesumit",
      disablePadding: false,
      align: "center",
      label: t("ANNOUNCE.TABLEHEAD.UPDATEBY"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("Action"),
    },
  ];
  const handlePageChange = (newPage: number) => setPage(newPage);

  const handleStatusChange = (x: any, id: any) => {
    const condition = {
      status: x,
    };

    AnnounceApi.updateStatus(condition, parseInt(id)).then((res) => {
      if (res.status === 200) {
        loadData();
      }
    });
  };

  const getBookMark = () => {
    AnnounceApi.findBookmark().then((res) => {
      setListBookmark(res.data);
    });
    // const condition = {
    //   bookmark: "FALSE",
    // };
  };
  const handleUpdateBookmark = (id: any, check: any) => {
    if (JSON.parse(decode(profile))?.roleId !== 4) {
      if (listBookmark?.length == 5) {
        if (check === "FALSE") {
          const condition = {
            bookmark: check,
          };
          AnnounceApi.updateBookmark(condition, +id).then((res) => {
            if (res.status === 205) {
              loadData();
              getBookMark();
            }
          });
        } else {
          notiError(`${t("ANNOUNCE.NOTI.BOOKMARK")}`, "", null, null);
        }
      } else {
        const condition = {
          bookmark: check,
        };
        AnnounceApi.updateBookmark(condition, +id).then((res) => {
          if (res.status === 205) {
            loadData();
            getBookMark();
          }
        });
      }
    }
  };
  const handlePageLimitChange = (newPageLimit: number) => setLimitPage(newPageLimit);

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType);
    setSortBy(sortBy);
  };

  const handleClick = (id: any) => {
    AnnounceApi.details(id).then((res) => {
      if (res.status === 200) {
        setDetail(res?.data);
        setModalType("DETAIL")
        dispatch(showModal());
      }
    });
  };

  const getnewType = async () => {
    const codition = {
      page: page,
      pageLimit: pageLimit,
    };
    await NewsTypeApi.findAll(codition).then((res) => {
      if (res.status === 200) {
        setNewtype(res?.data);
      }
    });
  };

  const renderData = (objData: any, no: any) => {
    // no = page * pageLimit - pageLimit + no + 1
    no = page * limitPage - limitPage + no + 1;
    const {
      startDate,
      endDate,
      status,
      modifiedDate,
      newsType,
      createDate,
      modifiedBy,
      announceId,
      bookmark,
      announceType,
    } = objData;
    const setState =
      status === "PENDING"
        ? t("ANNOUNCE.STATUS.PENDING")
        : status === "NON_APPROVE"
          ? t("ANNOUNCE.STATUS.NON_APPROVE")
          : status === "APPROVE"
            ? t("ANNOUNCE.STATUS.APPROVE")
            : t("ANNOUNCE.STATUS.CANCLE");

    const objRenderData = {
      key: announceId,
      id: announceId,
      obj: objData,

      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
        },
        {
          option: "TEXT",
          align: "center",
          label: moment(createDate).format("DD/MM/YYYY"),
        },
        {
          option: "TEXT",
          align: "center",
          label: newsType?.newstypeNameTh,
        },
        {
          option: "TEXT",
          align: "center",
          label:
            dateToView(startDate) + " " + ">>>" + " " + dateToView(endDate) ||
            "-",
        },
        roleId !== 4 && {
          option: "COMPONENT",
          align: "center",
          component: (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                bsPrefix="p-0  "
                className="btn-table-action text-black-50 shadow-none"
                disabled={JSON.parse(decode(profile))?.roleId === 4 ? true : false}
              >
                <Button
                  sx={{
                    backgroundColor:
                      status === "PENDING"
                        ? colors.yellow
                        : status === "ACTIVE"
                          ? colors.green
                          : status === "APPROVE"
                            ? colors.green
                            : colors.red,
                    color: colors.white,
                    margin: "auto",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor:
                        status === "PENDING"
                          ? colors.yellow
                          : status === "APPROVE"
                            ? colors.green
                            : status === "PRELIMINARY"
                              ? colors.themeSecondColor
                              : colors.red,
                      color: colors.white,
                    },
                  }}
                >
                  {setState}
                </Button>
              </Dropdown.Toggle>
              <DropdownMenu className="dropdown-table-action position-fixed">
                {status === "PENDING" && (
                  <DropdownItem
                    onClick={() => handleStatusChange("APPROVE", announceId)}
                  >
                    {t("ANNOUNCE.STATUS.APPROVE")}
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => handleStatusChange("NON_APPROVE", announceId)}
                >
                  {t("ANNOUNCE.STATUS.NON_APPROVE")}
                </DropdownItem>
                <DropdownItem
                  onClick={() => handleStatusChange("CANCEL", announceId)}
                >
                  {t("ANNOUNCE.STATUS.CANCLE")}
                </DropdownItem>
                {announceType === 'RENT' &&
                  <DropdownItem
                    onClick={() => history.push('/create-announce-rental', { type: announceType, id: announceId })}
                  >
                    {t("แก้ไข")}
                  </DropdownItem>
                 } 
                {announceType !== 'RENT' &&
                  <DropdownItem
                    onClick={() => history.push('/create-announce', { type: announceType, id: announceId })}
                  >
                    {t("แก้ไข")}
                  </DropdownItem>
                 } 
              </DropdownMenu>
            </Dropdown>
          ),
        },
        {
          option: "UPDATE_BY",
          align: "center",
          label: {
            updatedBy: `${modifiedBy && modifiedBy.firstname && modifiedBy.lastname
              ? modifiedBy.firstname + " " + modifiedBy.lastname
              : modifiedBy?.firstname || modifiedBy?.lastname || "-"
              }`,
            updatedAt: modifiedDate,
          },
        },
        roleId !== 4 && {
          option: "COMPONENT",
          align: "center",
          component: (
            <Box sx={{ cursor: "pointer" }}>
              {bookmark ? (
                <img
                  src={Star}
                  onClick={() => handleUpdateBookmark(announceId, "FALSE")}
                />
              ) : (
                <img
                  src={Notfavorite}
                  onClick={() => handleUpdateBookmark(announceId, "TRUE")}
                />
              )}
            </Box>

          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  history.push(`${routeName.detailinfo}/${announceId}`)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  // onClick={() => handleClick(announceId)}
                  onClick={() =>
                    history.push(`${routeName.detailinfo}/${announceId}`)
                  }
                />
              </div> */}
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton onClick={() => {
                  // history.push(`${routeName.detailinfo}/${announceId}`)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                // onClick={() => onViewDocument(objRenderData.obj)}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };
  const renderDataRound = (objData: any, no: any) => {
    no = pageround * pageLimit - pageLimit + no + 1;
    const {
      date,
      price,
      status,
      stall,
      stallType,
      zoneName,
      stallId,
      selectedDateRange,
    } = objData;
    const statusBtnActive = status === "ACTIVE" ? true : false;
    const statusBtnInActive = status === "INACTIVE" ? true : false;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        {
          option: "COMPONENT",
          align: "center",
          component: <Style.NumberInTable>{no}</Style.NumberInTable>,
        },
        {
          option: "TEXT",
          align: "center",
          label: t("STALL.ROW.ZONE") + stall?.zone?.zoneName?.substring(3),
        },
        {
          option: "TEXT",
          align: "center",
          label: t("STALL.ROW.STALL") + stall?.stallName,
        },
        {
          option: "TEXT",
          align: "center",
          label: t("STALL.ROW.TYPE_STALL") + stall?.stallType?.stallTypeNameTh,
        },

        {
          option: "TEXT",
          align: "center",
          label: `${price} ${t("ANNOUNCE.CREATE.BATH")}`,

          width: "15%",
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const loadData = useCallback(async () => {
    let condition: any = {
      sortBy: "createDate",
      sortType: "DESC"
    };
    if (page) condition = { ...condition, page: page };
    if (limitPage) condition = { ...condition, pageLimit: limitPage };
    if (search) condition = { ...condition, search: search };
    if (newtypeId)
      condition = {
        ...condition,
        newstypeId: newtypeId === null ? "" : newtypeId,
      };

    const res = await AnnounceApi.findAll(condition);

    if (res?.status === 200) {
      setRowCount(res?.headers["total"]);
      setListAnnounce(res?.data);
    } else {
      setRowCount(0);
      setListAnnounce([]);
    }
  }, [page, limitPage, search, newtypeId]);

  const handlepreview = (x: any) => {
    if (x?.mimetype === "application/pdf") {

      window.open(process.env.REACT_APP_FILE_URL +
        x?.filePath +
        x?.fileName, "_blank");
    } else {
      setSelectfile(process.env.REACT_APP_FILE_URL +
        x?.filePath +
        x?.fileName);
    }

  };

  useEffect(() => {
    loadData();
    getBookMark();
    getnewType();
  }, [loadData]);

  return (
    <Box>
      <Style.headerannoce>
        <TypographyHead>{t("ANNOUNCE.HEAD.ANNOUNCE")}</TypographyHead>
      </Style.headerannoce>
      <Style.boxcontainer>

        <Row className="my-2">
          <Col item lg={2} md={12} xs={12} className="my-2">
            <InputTextField
              placeholder={t("ANNOUNCE.HEAD.SEARCH")}
              onchange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Col>
          <Col item lg={2} md={12} xs={12} className="my-2">
            <AutocompleteSelect
              options={listNewtype}
              placeholder="กรุณาเลือกประเภทรายการ"
              value={
                newtypeId
                  ? listNewtype.find(
                    (data: any) => data.newstypeId === newtypeId
                  )
                  : null
              }
              getOptionLabel={(option: any) => option.newstypeNameTh}
              // onChange={(data: any) => setPrefixId(data.value)}
              onChange={(event, newValue) => {
                setNewTyperId(newValue?.newstypeId);
              }}
              labelId="add"
            />
          </Col>
          <Col item lg={4} md={12} xs={12} className=""></Col>
          {JSON.parse(decode(profile))?.roleId !== 4 &&
            <Col item lg={4} md={12} xs={12} className="my-2 ml-auto">
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  bsPrefix="p-0  "
                  className="btn-table-action text-black-50 shadow-none"
                  style={{ width: '100%' }}
                >
                  <ButtonCustom
                    textButton={t("ANNOUNCE.HEAD.ADDANNOUNCE")}
                    mode="add"
                    style={{ width: '100%' }}
                  />
                </Dropdown.Toggle>
                <DropdownMenu className="w-100">
                  <DropdownItem
                    onClick={() => history.push("/create-announce-rental")}
                  >
                    {t("ANNOUNCE.HEAD.ADDANNOUNCE")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => history.push("/create-announce", { type: 'HELP' })}
                  >
                    {t("ANNOUNCE.HEAD.HELP")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => history.push("/create-announce", { type: 'GENERAL' })}
                  >
                    {t("ประกาศจากระบบ")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>}
        </Row>

        <TableCustom
          headCells={roleId === 4 ? headCellUser : headCells}
          customScroll
          page={page}
          pageLimit={limitPage}
          sortType={sortType}
          sortBy={sortBy}
          rowCount={rowCount}
          rowsData={listAnnounce.map((d: any, index: any) => {
            return renderData(d, index);
          })}
          onSort={onRequestSort}
          setPageLimit={handlePageLimitChange}
          setPage={handlePageChange}
          tableMinWidth={1400}
        />
      </Style.boxcontainer>

      {modalType === "DETAIL" && <ModalCustom
        title={t("ANNOUNCE.CREATE.ADDANNOUNCE")}
        fullscreen="sm-down"
        size="xl"
        closeButton={true}
        component={
          <Box >
            <Container maxWidth="lg">
              <Box>
                <Row className="d-flex justify-content-end">
                  <Col lg={9} xs={6} md={7}></Col>
                  <Col lg={3} xs={6} md={5}>
                    <ButtonCustom
                      textButton={t("ANNOUNCE.CREATE.VIEW_DOC")}
                      endIcon={<RemoveRedEyeIcon />}
                      disabled={detail?.fileUpload.length > 0 ? false : true}
                      onClick={() => setModalType("FILE_UPLOAD")}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col item lg={3} xs={6} className="my-1">
                    <Typographymodal>
                      {t("ANNOUNCE.HEAD.ANNOUNCEAC")} :
                    </Typographymodal>
                  </Col>
                  <Col item lg={3} xs={6} className="my-1">
                    <Typography>
                      {detail?.modifiedBy &&
                        detail?.modifiedBy.firstname &&
                        detail?.modifiedBy.lastname
                        ? detail?.modifiedBy.firstname +
                        " " +
                        detail?.modifiedBy.lastname
                        : detail?.modifiedBy?.firstname ||
                        detail?.modifiedBy?.lastname ||
                        "-"}
                    </Typography>
                  </Col>
                  <Col item lg={3} xs={6} className="my-1">
                    <Typographymodal>
                      {t("ANNOUNCE.CREATE.DATE")} :
                    </Typographymodal>
                  </Col>
                  <Col item lg={3} xs={6} className="my-1">
                    <Typography>
                      {dateTimeToView(detail?.createDate)}
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col item lg={3} xs={6} className="my-1">
                    <Typographymodal>
                      {t("ANNOUNCE.CREATE.NEW_TYPE")} :
                    </Typographymodal>
                  </Col>
                  <Col item lg={9} xs={6} className="my-1">
                    <Typography>{detail?.newsType?.newstypeNameTh}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col item lg={2} xs={6} className="my-1">
                    <Typographymodal>
                      {t("ANNOUNCE.CREATE.HEAD")} :
                    </Typographymodal>
                  </Col>
                  <Col item lg={10} xs={6} className="my-1">
                    <Typography>{detail?.title}</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col item lg={2} xs={12} className="my-1">
                    <Typographymodal>
                      {t("ANNOUNCE.CREATE.DETAILCREATE")} :
                    </Typographymodal>
                  </Col>
                  <Col item lg={10} xs={12} className="my-1">
                    <Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: detail?.detail }}
                      />
                    </Typography>
                  </Col>
                </Row>
              </Box>
              {open && detail?.round?.length !== 0 ? (
                <Box sx={{ marginTop: "48px" }}>
                  <Style.flex>
                    <Typographymodal>{t("จำนวนแผงค้า")} :</Typographymodal>
                    <Typographymodal sx={{ marginLeft: "15px" }}>
                      {" "}
                      {detail?.round?.length} {t("แผง")}
                    </Typographymodal>
                  </Style.flex>

                  <TableCustom
                    headCells={[]}
                    customScroll
                    page={pageround}
                    pageLimit={pageLimit}
                    sortType={sortType}
                    sortBy={sortType}
                    rowCount={1}
                    rowsData={detail?.round?.map((d: any, index: any) => {
                      return renderDataRound(d, index);
                    })}
                    onSort={() => ""}
                    setPageLimit={(newValue) => setPageLimit(newValue)}
                    setPage={(newValue) => setPageRound(newValue)}
                  />
                </Box>
              ) : (
                // <Style.Hide>
                //   <PreviewImage imageFile="https://cdn.britannica.com/16/234216-050-C66F8665/beagle-hound-dog.jpg" />
                // </Style.Hide>
                <></>
              )}
            </Container>
          </Box>
        }
      />}
    </Box>
  );
}
