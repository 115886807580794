import {
    Box,
    Checkbox,
    Container,
    Divider,
    Grid,
    Typography,
  } from "@mui/material";
  import { useEffect, useRef, useState } from "react";
  import  {
    Boxflex,
    CarInputRegister,
    CarRegister,
    PolicyText,
    CardRegister
  } from "../auth/style";
  import ButtonCustom from "../../component/button/buttonCustom";
  import { useTranslation } from "react-i18next";
  import InputTextField from "../../component/input/inputTextField";
  import { colors } from "../../constants/colors";
  import AutocompleteSelect from "../../component/select/autoCompleteSelect";
  import ReCAPTCHA from "react-google-recaptcha";
  
  /** API */
  import InputPasswordField from "../../component/input/inputPasswordField";
import { swalError, swalSuccess } from "../../component/notification/swal";
import UserApi from "../../api/auth/users.api";
import PrefixApi from "../../api/setting/prefix/prefix.api";
import { showModal } from "../../app/slice/modal.slice";
import ModalCustom from "../../component/modalCustom/modalCustom";
import Policy from "../auth/policy";
import { useDispatch } from "react-redux";
import Loading from "../../component/loading/loading";
  
  const initStateErrorMessage = {
    PRFIX_NAME: ``,
    NAME: ``,
    LASTNAME: ``,
    PHONE_NUBER: ``,
    EMAIL: ``,
    USERNAME: ``,
    PASSOWORD: ``,
    CONFIRM_PASSWORD: ``
  };
  
  export default function RegisterFromTransfer() {
    const recaptchaRef: any = useRef(null);
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
    const [prefixId, setPrefixId] = useState<number>(1);
    const [PrefixOption, setPrefixOption] = useState<any[]>([]);
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [mobilePhone, setMobilePhone] = useState<string>("");
    const [reCaptcha, setReCaptcha] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [email, setEmail] = useState<string | null>(null);
    const [checkAccept, setCheckAccept] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getPrefixes = async () => {
        const condition: any = {
          page: 1,
          pageLimit: 5,
          status: "ACTIVE"
        };
        const res = await PrefixApi.findAll(condition);
        setPrefixOption(res.data);
      };
    
      useEffect(() => {
        getPrefixes();
      }, []);
    
    const onChangeRecapcha = async (value: any) => setReCaptcha(value);

    const onSubmitRegister = async () => {
        const condition: any = {
          prefixId: prefixId,
          firstname: firstname,
          lastname: lastname,
          email: email,
          mobilePhone: mobilePhone,
          password: password,
          status: "ACTIVE",
          reCaptcha: reCaptcha,
        };
        if (!firstname || !lastname || !mobilePhone) {
          swalError(`${t("LOGIN.SWAL.NO_COMPLETE")}`);
        } else if (mobilePhone.length !== 10) {
          swalError(`${t("LOGIN.SWAL.PHONENUMBER_FAIL")}`);
        } else
        if (!email) {
          swalError(`${t("LOGIN.SWAL.EMAIL_FAIL")}`)
        }else if (password !== confirmPassword) {
          swalError(`${t("LOGIN.SWAL.NO_MATCH")}`);
        } else if (password.length <= 7) {
          swalError(`${t("LOGIN.SWAL.PASSWORD")}`);
        } else if (!reCaptcha) {
          swalError(`${t("LOGIN.SWAL.RECAPTCHA")}`);
        } else {
          setLoading(true);
          await UserApi.create(condition)
            .then((res) => {
              if (res.status === 201) {
                swalSuccess("สมัครสมาชิกสำเร็จ กรุณาออกจากระบบ และเข้าสู้ระบบด้วยอีเมลที่สมัครและยืนยันตัวตน");
                setLoading(false)
              } else {
                setLoading(false)
                swalError(res.error_description);
              }
            })
            .catch((e) => {
              setLoading(false)
              swalError("สมัครสมาชิกไม่สำเร็จ")
            })
        }
      };

    return (
      <CardRegister>
        <Box>
          <Container maxWidth="lg" >
            <Box sx={{ paddingTop: "20px" }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "500",
                  textDecoration: "underline",
                  textDecorationColor: "green",
                }}
              >
                {t("REGISTER.HEADER.REGISTER")}
              </Typography>
            </Box>
            <CarRegister>
              <Container maxWidth="lg" style={{backgroundColor: colors.white}}>
                <Typography sx={{ fontSize: "18px" }}>
                  {t("REGISTER.HEADER.PERSONAL_CUSTOMER")}
                </Typography>
                <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <CarInputRegister>
                  <Boxflex>
                    <Grid container spacing={2}>
                      <Grid item lg={4} xs={12} className="w-100">
                        <AutocompleteSelect
                          options={PrefixOption}
                          getOptionLabel={(option: any) => option.prefixNameTh}
                          onChange={(e: any, val: any) => setPrefixId(val.prefixId)}
                          labelId="prefix-name"
                          heading={t("REGISTER.INPUT.PREFIX")}
                          required
                          helperText={errorMessage.PREFIX}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12} className="w-100">
                        <InputTextField
                          key="name"
                          value={firstname}
                          onchange={(e: any) => setFirstname(e.target.value)}
                          required={true}
                          helperText={errorMessage.NAME}
                          heading={t("REGISTER.INPUT.NAME")}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12} className="w-100">
                        <InputTextField
                          key="lastName"
                          value={lastname}
                          onchange={(e: any) => setLastname(e.target.value)}
                          helperText={errorMessage.LAST_NAME}
                          required={true}
                          heading={t("REGISTER.INPUT.LAST_NAME")}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12} className="w-100">
                        <InputTextField
                          key="phoneNumber"
                          value={mobilePhone}
                          onchange={(e: any) => setMobilePhone(e.target.value)}
                          required={true}
                          helperText={errorMessage.PHONE_NUBER}
                          inputProps={{ maxLength: 10 }}
                          heading={t("REGISTER.INPUT.PHONE_NUMBER")}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12} className="w-100">
                        <InputTextField
                          key="email"
                          required={true}
                          value={email}
                          onchange={(e: any) => setEmail(e.target.value)}
                          heading={t("REGISTER.INPUT.EMAIL")}
                        />
                      </Grid>
                    </Grid>
                  </Boxflex>
                </CarInputRegister>
                <Typography sx={{ fontSize: "18px", marginTop: "40px" }}>
                  {t("REGISTER.HEADER.SET_PASSWORD")}
                </Typography>
                <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                <CarInputRegister>
                  <Grid container spacing={2}>
                    <Grid item lg={4} xs={12} className="w-100">
                      <InputPasswordField
                        value={password}
                        onchange={(e: any) => setPassword(e.target.value)}
                      
                      required={true}
                      helperText={errorMessage.PASSOWORD}
                      heading={t("REGISTER.INPUT.PASSWORD")}
                    />
                    </Grid>
                    <Grid item lg={4} xs={12} className="w-100">
                      <InputPasswordField
                        value={confirmPassword}
                        onchange={(e: any) => setConfirmPassword(e.target.value)}
                        required={true}
                        helperText={errorMessage.CONFIRM_PASSWORD}
                        heading={t("REGISTER.INPUT.CONFIRM_PASSWORD")}
                      />
                    </Grid>
                  </Grid>
                </CarInputRegister>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LdiP1UpAAAAADUyq1e1TahP4w0JFZEgDPCQE7dl"
                  onChange={onChangeRecapcha}
                />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                  <Checkbox value={checkAccept} onChange={() => setCheckAccept(!checkAccept)}/>
                  <Box className='d-flex flex-row'>
                    <Typography>ข้าพเจ้ายอมรับข้อกำหนดการใช้งาน รับทราบและตกลงให้มีการเก็บรวบรวมใช้ และเปิดเผยข้อมูลส่วนบุคคลได้ </Typography>
                    <PolicyText className="px-2" onClick={() => dispatch(showModal())}>นโยบายคุ้มครอง</PolicyText>
                    </Box>
                </Box>
                <Box>
                  <ButtonCustom
                    type="submit"
                    disabled={checkAccept ? false: true}
                    textButton={t("REGISTER.BUTTON.SUMMIT")}
                    onClick={() => onSubmitRegister()}
                    btnStyle={{
                      fontSize: "16px",
                      width: "100%",
                      padding: "0px",
                      marginTop: "32px",
                      marginBottom: "14px",
                    }}
                  />
                </Box>
              </Container>
            </CarRegister>
          </Container>
        </Box>
        <ModalCustom
        title={""}
        size="lg"
        closeButton={true}
        modalScroll={true}
        component={
          <>
            <Policy />
          </>}/>
          {loading && <Loading show={loading} type="fullLoading" />}
      </CardRegister>
    );
  }
  