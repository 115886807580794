import { useTranslation } from "react-i18next";

import * as Style from "./Styles";
import { Box, Divider, Typography } from "@mui/material";
import { icons, imgs } from "../../../constants/images";
import { numberFormat } from "../../../utils/common";
import Cloudupload from "../../../assets/icon/cloudupload";
import PreviewImage from "../../../component/image/PreviewImage";
import ButtonCustom from "../../../component/button/buttonCustom";
import { useRef } from "react";
import moment from "moment";

interface PopupPaymentProps {
  open?: boolean;
  amount?: string;
  time?: string;
  image?: string;
  qrCode?: string
  onClose?: () => void;
  onUploadBill?: (img: any) => void;
  onsubmit?: () => void
  getRootProps: any
  getInputProps: any
  detail?:any
}

export default function PopupPaymentStall(props: PopupPaymentProps) {
  console.log(props);
  const { t } = useTranslation();
  const cardRef = useRef<HTMLElement>(null);

  const prepareURL = async () => {
    const cardElement = cardRef.current;
    if (!cardElement) return;
    try {
      const html2canvas = await import(
        "html2canvas"
      );

      const result = await html2canvas.default(cardElement);

      const asURL = result.toDataURL("image/jpeg");

      const anchor = document.createElement("a");
      anchor.href = asURL;
      anchor.download = `Qrcode_${props.detail?.stall?.stallNumber ? props.detail?.stall?.stallNumber.replace("/", '-') : ''}_${props.detail?.stall?.stallNumber ? moment().format('D-MM-YYYY'): moment().format('D-MM-YYYY_hh-mm-ss')}.jpeg`;
      anchor.click();
      anchor.remove();
    } catch (reason) {
      console.log(reason);
    }
  };
  return (
    <>
      <Style.BoxUploadImgContainer>
        <Style.ContainerUpload>
          <Style.ContainerBoxUpload>
            <Style.BoxUpload {...props.getRootProps}>

              <input {...props.getInputProps} />
              {props.image ? (

                <PreviewImage imageFile={props.image} />
              ) : (
                <div className="d-flex justify-content-center flex-column align-item-center">
                  <Cloudupload />
                  <div>แนบไฟล์ภาพ</div>
                </div>

              )}

            </Style.BoxUpload>

          </Style.ContainerBoxUpload>

          <Style.TextBoxUpload>
            <Typography>{t("STALL.POPUP.TRANSFORM_AMOUNT")}</Typography>
            <Typography>{numberFormat(props.amount)}</Typography>
            <Typography>{t("STALL.POPUP.BATH_UNIT")}</Typography>
          </Style.TextBoxUpload>
          <Style.TextBoxAlert>
            <Typography>{t("STALL.POPUP.PAYMENT_DATE")}{" "}{props.time}</Typography>
          </Style.TextBoxAlert>
        </Style.ContainerUpload>

        {
          props.amount && props.amount !== "0" ? (
            <Style.ContainerQR>
            <Style.ThaiQR ref={cardRef}>
              <img src={imgs.thai_qr} />
              <Style.ImageQR src={props.qrCode} alt="" />
              <Style.TextQR className="w-100 d-flex justify-content-center mb-2">องค์การตลาดเพื่อเกษตรกร</Style.TextQR>
              {/* <Style.TextQR className="w-100 d-flex justify-content-center mb-2">099400016552800</Style.TextQR> */}
            </Style.ThaiQR>
            {/* <Style.ButtonDowload href={dowloadRef.current} download={dowloadRef.current} >ดาวน์โหลด</Style.ButtonDowload> */}
            <Style.ButtonDowload onClick={prepareURL}>ดาวน์โหลดQr code</Style.ButtonDowload>
          </Style.ContainerQR>
          ) : null
        }


      </Style.BoxUploadImgContainer>

      <Style.ButtonSubmit onClick={props.onsubmit}>บันทึก</Style.ButtonSubmit>
    </>
  );
}
