import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import HeaderCard from "../../component/cardCustom/headerCard";
import { Boxcheck, Boxconteinerin, Boxright, Boxsolid, TypographyHead } from "./adminStyle";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../component/input/inputTextField";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../component/button/buttonCustom";
import InputCheckbox from "../../component/input/inputCheckbox";
import LoginApi from "../../api/auth/auth.api";
import RoleApi from "../../api/admin/role/role.api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { notiError, notiSuccess } from "../../component/notification/notifications";
const initStateErrorMessage = {
  PRFIX_NAME: ``,
  NAME: ``,
  LASTNAME: ``,
  PHONE_NUBER: ``,
  EMAIL: ``,
  USERNAME: ``,
  PASSOWORD: ``,
  CONFIRM_PASSWORD: ``,
};

export default function CreateAdminType() {
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [menu, setMenu] = useState<any>([]);
  const [addminType, setAddminType] = useState<any>([]);
  const [IsEidit, setIsEdit] = useState<boolean>(false);
  const [IsDelete, setIsDelete] = useState<boolean>(false);
  const [IsAdd, setIsAdd] = useState<boolean>(false);
  const [branchId, setBranchId] = useState<number>(1);
  const [roleName, setRoleName] = useState<string>("");
  const [permission, setPermission] = useState<any>([]);

  const { t } = useTranslation();
  const { id: id } = useParams<any>();
  const location = useLocation<any>();

  const updateisAdd = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isCreate: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isCreate: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisEdit = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isUpdate: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isUpdate: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisDelete = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isDelete: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isDelete: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisExport = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isExport: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isExport: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisApporve = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isApporve: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuFirstId === id.menuFirstId) {
          return { ...obj, isApporve: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisAddSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isCreate: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isCreate: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisEditSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isUpdate: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isUpdate: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisDeleteSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isDelete: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isDelete: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisExportSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isExport: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isExport: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisApporveSecon = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isApporve: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuSecondId === id.menuSecondId) {
          return { ...obj, isApporve: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisAddThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isCreate: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isCreate: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisEditThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isUpdate: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isUpdate: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisDeleteThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isDelete: true };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        // 👇️ if id equals 2, update country property
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isDelete: false };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      setPermission(add);
    }
  };
  const updateisExportThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isExport: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isExport: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const updateisApporveThird = (e: any, id: any) => {
    if (e.target.checked) {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isApporve: true };
        }

        return obj;
      });

      setPermission(add);
    } else {
      const add = permission.map((obj: any) => {
        
        if (obj.menuThirdId === id.menuThirdId) {
          return { ...obj, isApporve: false };
        }

        return obj;
      });

      setPermission(add);
    }
  };
  const addpermission = (e: any, id: any) => {
    if (e.target.checked) {
      const a = {
        branchId: branchId,
        menuFirstId: id?.menuFirstId,
        menuSecondId: null,
        menuThirdId: null,
        isView: true,
        isCreate: IsAdd,
        isUpdate: IsEidit,
        isDelete: IsDelete,
      };
      setPermission([...permission, a]);
    } else {
      const dispermission = permission.filter(
        (item: any) => item.menuFirstId !== id.menuFirstId
      );
      setPermission(dispermission);
    }
  };
  const addpermissionSecond = (e: any, id: any) => {
    if (e.target.checked) {
      const a = {
        branchId: branchId,
        menuFirstId: id?.menuFirstId,
        menuSecondId: id?.menuSecondId,
        menuThirdId: null,
        isView: true,
        isCreate: IsAdd,
        isUpdate: IsEidit,
        isDelete: IsDelete,
      };
      setPermission([...permission, a]);
    } else {

      const dispermissionSeconid = permission.filter(
        (item: any) => item.menuSecondId !== id.menuSecondId
      );
      setPermission(dispermissionSeconid);
    }
  };
  const addpermissionThirdId = (e: any, id: any) => {
    if (e.target.checked) {
      const a = {
        branchId: branchId,
        menuFirstId: id?.menuFirstId,
        menuSecondId: id?.menuSecondId,
        menuThirdId: id?.menuThirdId,
        isView: true,
        isCreate: IsAdd,
        isUpdate: IsEidit,
        isDelete: IsDelete,
      };

      setPermission([...permission, a]);
    } else {
      const poppermission = permission.filter(
        (obj: any) => obj.menuThirdId !== id.menuThirdId
      );
      setPermission(poppermission);

    }
  };
  const getDetail = async () => {
    const condition = {
      id: +id
    }
    RoleApi.getDetail(condition).then((res) => {

      if (res.status === 200) {
        setRoleName(res.data.roleName)
        setPermission(res.data.permission)
      }
    })
  }
  const handlesubmit = async () => {
    const condition = {
      roleName: roleName,
      level: 1,
      isEdit: "TRUE",
      status: "ACTIVE",
      permission: permission,
    };
    if (id) {
      await RoleApi.patchDetail(condition, +id).then((res) => {
        if (res.status === 200) {
          notiSuccess(t(`ADMIN.NOTI.SUCESSS`), 'go', '/admin-type', null)

        }
        else {
          notiError(t('ADMIN.NOTI.UNSUCESS'))
        }
      })
    } else {
      if (!roleName)
        return setErrorMessage({
          ...errorMessage,
          ...{ NAME: t(`TEXTALERT.NAME`) },
        });
      await RoleApi.createRole(condition).then((res) => {
        if (res.status === 201) {
          notiSuccess(t(`ADMIN.NOTI.SUCESSSCREATE`), 'go', '/admin-type', null)
        } else {
          notiError(t('ADMIN.NOTI.UNSUCESSS'))
        }
      });
    }

  };

  const loadMenu = async () => {
    const res = await LoginApi.menu();
    console.log(res);
    if (res.status == 200) {
      setMenu(res?.data);
    }
  };

  const getRole = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
    };
    const res = await RoleApi.findAll(condition);
    setAddminType(res?.data);
  };
  useEffect(() => {
    if (id) {
      getDetail()
    }
  }, [id])
  useEffect(() => {
    loadMenu();
    getRole();
  }, []);

  return (
    <>
      <HeaderCard text={t("CREATEADMIN.CREATE")} />
      <Box className='mb-5'>
        <Boxconteinerin >
          <Boxsolid>
            <Row>
              <Col lg={12} xs={12}>
                <Boxright>
                  <Container maxWidth="md">
                    <TypographyHead>
                      {t("CREATEADMIN.ADMIN_DETAIL")}
                    </TypographyHead>
                    <Row className="my-4">
                      <Col lg={6} md={12} xs={12}>
                        <InputTextField
                          value={roleName}
                          disabled={location?.state.params.isView}
                          onchange={(e) => setRoleName(e.target.value)}
                          heading={t("CREATEADMIN.ROLENAME")}
                          required
                          helperText={errorMessage.NAME}
                        />
                      </Col>
                    </Row>
                    <TypographyHead>
                      {t("PASSWORD.SETTING_SYSTEM")}
                    </TypographyHead>

                    {menu?.map((x: any, index: any) => (
                      <>
                        <Col lg={12}>
                          <Box
                            key={index}
                            sx={{
                              flex: "flex",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "8px",
                              }}
                            >
                              {t(`MENU.${x.menuFirstKey}`)}
                            </Typography>
                            <Boxcheck>
                              <Typography>
                                {t(`MENU.${x.menuFirstKey}`)}
                              </Typography>
                              <InputCheckbox
                                onChange={(e: any) => addpermission(e, x)}
                                disabled={location.state.params.isView}
                                checked={
                                  permission
                                    ?.find(
                                      (item: any) =>
                                        item?.menuFirstId === x?.menuFirstId
                                    )?.isView || false

                                }

                              />
                            </Boxcheck>
                            {x.menuSeconds.length === 0 &&
                              permission?.filter(
                                (data: any) =>
                                  data.menuFirstId == x.menuFirstId
                              )
                                ?.map(
                                  (item: any, index: any) =>
                                    item.menuFirstId === x.menuFirstId && (
                                      <>
                                        <InputCheckbox
                                          key={index}
                                          label="เพิ่ม"
                                          style={{ marginLeft: "10px" }}
                                          onChange={(e: any) =>
                                            updateisAdd(e, x)
                                          }
                                          checked={item.isCreate}
                                          disabled={location.state.params.isView}
                                        />
                                        <InputCheckbox
                                          label="ลบ"
                                          onChange={(e: any) =>
                                            updateisDelete(e, x)
                                          }
                                          disabled={location.state.params.isView}
                                          checked={item.isDelete}
                                        />
                                        <InputCheckbox
                                          label="แก้ไข"
                                          onChange={(e: any) =>
                                            updateisEdit(e, x)
                                          }
                                          disabled={location.state.params.isView}
                                          checked={item.isUpdate}
                                        />
                                          <InputCheckbox
                                                label="รายงาน"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisExport(e, x)
                                                }
                                                checked={item.isExport}
                                              />
                                              <InputCheckbox
                                                label="อนุมัติ"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisApporve(e, x)
                                                }
                                                checked={item.isApporve}
                                              />
                                      </>
                                    )
                                )}
                            <>
                              {x?.menuSeconds?.map((y: any) => (
                                <>
                                  {permission?.filter(

                                    (data: any) =>
                                      data.menuFirstId === x.menuFirstId
                                  )
                                    ?.filter(
                                      (obj: any) =>
                                        obj.menuSecondId == x.menuSecondId
                                    )
                                    ?.map(
                                      (item: any, index: any) =>
                                        item.isView && (
                                          <Boxcheck
                                            key={index}

                                            sx={{
                                              marginLeft: "10px",
                                              transform: "0.2s",
                                            }}
                                          >
                                            <Typography>
                                              {t(`MENU.${y?.menuSecondKey}`)}
                                            </Typography>
                                            <InputCheckbox
                                              onChange={(e: any) => {
                                                addpermissionSecond(e, y);
                                              }}
                                              disabled={location.state.params.isView}
                                              checked={
                                                permission.find(
                                                  (item: any) =>
                                                    item?.menuSecondId ===
                                                    y?.menuSecondId
                                                )?.isView || false
                                              }
                                            />
                                          </Boxcheck>
                                        )
                                    )}

                                  {y.menuThirds.length === 0 &&
                                    permission
                                      ?.filter(
                                        (data: any) =>
                                          data.menuFirstId == x.menuFirstId
                                      )
                                      ?.map(
                                        (item: any, index: any) =>
                                          item.menuSecondId ===
                                          y.menuSecondId && (
                                            <>
                                              <InputCheckbox
                                                key={index}
                                                label="เพิ่ม"
                                                style={{ marginLeft: "10px" }}
                                                onChange={(e: any) =>
                                                  updateisAddSecon(e, y)
                                                }
                                                checked={item.isCreate}
                                                disabled={location.state.params.isView}
                                              />
                                              <InputCheckbox
                                                label="ลบ"
                                                onChange={(e: any) =>
                                                  updateisDeleteSecon(e, y)
                                                }
                                                checked={item.isDelete}
                                                disabled={location.state.params.isView}
                                              />
                                              <InputCheckbox
                                                label="แก้ไข"
                                                disabled={location.state.params.isView}
                                                onChange={(e: any) =>
                                                  updateisEditSecon(e, y)
                                                }
                                                checked={item.isUpdate}
                                              />
                                            
                                               <InputCheckbox
                                                label="รายงาน"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisExportSecon(e, y)
                                                }
                                                checked={item.isExport}
                                              />
                                              <InputCheckbox
                                                label="อนุมัติ"
                                                disabled={
                                                  location.state.params.isView
                                                }
                                                onChange={(e: any) =>
                                                  updateisApporveSecon(e, y)
                                                }
                                                checked={item.isApporve}
                                              />
                                            </>
                                          )
                                      )}

                                  {y.menuThirds.map((z: any) => (
                                    <>
                                      {permission
                                        ?.filter(
                                          (data: any) =>
                                            data.menuFirstId === y.menuFirstId
                                        )

                                        ?.filter(
                                          (obj: any) =>
                                            obj.menuSecondId === y.menuSecondId
                                        )

                                        ?.map(
                                          (item: any, index: any) =>
                                            item.isView &&
                                            !item.menuThirdId && (
                                              <>
                                                <Boxcheck
                                                  key={index}
                                                  sx={{
                                                    marginLeft: "30px",
                                                    transform: "0.2s",
                                                  }}
                                                >
                                                  <Typography>
                                                    {t(
                                                      `MENU.${z?.menuThirdKey}`
                                                    )}
                                                  </Typography>
                                                  <InputCheckbox
                                                    onChange={(e) =>
                                                      addpermissionThirdId(e, z)
                                                    }
                                                    disabled={location.state.params.isView}
                                                    checked={
                                                      permission
                                                        ?.find(
                                                          (item: any) =>
                                                            item.menuThirdId ===
                                                            z.menuThirdId
                                                        )
                                                        ?.isView || false

                                                    }
                                                  />
                                                </Boxcheck>
                                              </>
                                            )
                                        )}
                                      {permission
                                        ?.filter(
                                          (data: any) =>
                                            data.menuSecondId == z.menuSecondId
                                        )
                                        ?.map(
                                          (item: any, index: any) =>
                                            item.menuThirdId ===
                                            z.menuThirdId && (
                                              <>
                                                <InputCheckbox
                                                  key={index}
                                                  label="เพิ่ม"
                                                  style={{ marginLeft: "20px" }}
                                                  onChange={(e: any) =>
                                                    updateisAddThird(e, z)
                                                  }
                                                  disabled={location.state.params.isView}
                                                  checked={item.isCreate}
                                                />
                                                <InputCheckbox
                                                  label="ลบ"
                                                  onChange={(e: any) =>
                                                    updateisDeleteThird(e, z)
                                                  }
                                                  disabled={location.state.params.isView}
                                                  checked={item.isDelete}
                                                />
                                                <InputCheckbox
                                                  label="แก้ไข"
                                                  disabled={location.state.params.isView}
                                                  onChange={(e: any) =>
                                                    updateisEditThird(e, z)
                                                  }
                                                  checked={item.isUpdate}
                                                />
                                                  <InputCheckbox
                                                  label="รายงาน"
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                  onChange={(e: any) =>
                                                    updateisExportThird(e, z)
                                                  }
                                                  checked={item.isExport}

                                                />
                                                <InputCheckbox
                                                  label="อนุมัติ"
                                                  disabled={
                                                    location.state.params.isView
                                                  }
                                                  onChange={(e: any) =>
                                                    updateisApporveThird(e, z)
                                                  }
                                                  checked={item.isApporve}
                                                />
                                              </>
                                            )
                                        )}
                                    </>
                                  ))}
                                </>
                              ))}
                            </>
                          </Box>
                        </Col>
                      </>
                    ))}

                    <ButtonCustom
                      textButton="เสร็จสิ้น"
                      disabled={location?.state.params.isView}
                      onClick={handlesubmit}
                      btnStyle={{ width: "100%" }}
                    />
                  </Container>
                </Boxright>
              </Col>
            </Row>
          </Boxsolid>
        </Boxconteinerin>
      </Box>
    </>
  );
}
