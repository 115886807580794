import BaseAPI from "../api";

const path = "report";

export interface ReportInterface {
  start_date?: string;
  end_date?: string;
  type?: string;
  type_doc?: string;
}

export default class ReportApi extends BaseAPI {
  static getAgreement(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/agreement", { params: { ...params } })
      .then((res) => res);
  }

  static getBankContract(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/bank-contract", { params: { ...params } })
      .then((res) => res);
  }

  static getRevenueCollection(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/revenue-collection", { params: { ...params } })
      .then((res) => res);
  }

  static getUtilities(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/utilities", { params: { ...params } })
      .then((res) => res);
  }
  //
  static downloadAgreement(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/download-agreement", {
        params: { ...params },
        responseType: "arraybuffer",
      })
      .then((res) => res);
  }

  static downloadBankContract(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/download-bank-contract", {
        params: { ...params },
        responseType: "arraybuffer",
      })
      .then((res) => res);
  }

  static downloadRevenueCollection(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/download-revenue-collection", {
        params: { ...params },
        responseType: "arraybuffer",
      })
      .then((res) => res);
  }

  static downloadUtilities(params: ReportInterface): Promise<any> {
    return this.api
      .get(path + "/download-utilities", {
        params: { ...params },
        responseType: "arraybuffer",
      })
      .then((res) => res);
  }
}
