import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
/** COMPONENT */
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from '../cancel.Style'

/** UTIL */
import { dateToView } from "../../../utils/date.utils";

import CancelContractApi from "../../../api/stall/cancelcontract/cancelcontract";
import LeaseAgreementApi from "../../../api/stall/leaseAgreement/leaseAgreement.api";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import TableRowCommon from "../../../component/table/TableRowCommon";
import TableCustom from "../../../component/table/tableCustom";
import { submitModal } from "../../../app/slice/modal.slice";
import { decode } from "js-base64";
import { getProfile } from "../../../utils/app.utils";
import InputTextField from "../../../component/input/inputTextField";
import { useDropzone } from "react-dropzone";
import { Box, Grid, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Cloudupload from "../../../assets/icon/cloudupload";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import { Col, Row } from "react-bootstrap";

type PropsAdmincheck = {
  onChangeAppointmentDate?: any,
  AppointmentDate?: string,
  onchangePlace?: any,
  place?: number,
  tel?: string,
  onclose?: any
}

export default function CreateCancelContract(props: PropsAdmincheck) {
  const profile: any = JSON.parse(getProfile() || "{}");
  const { t } = useTranslation();
  const [leaseAgreementData, setLeaseAgreementData] = useState<any[]>([])
  const [isChecked, setIsChecked] = useState<any[]>([]);
  const [dataDetail, setDataDetail] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);

  const loadData = async () => {
    const res = await LeaseAgreementApi.findNotCancel(JSON.parse(decode(profile))?.userId)
    if (res.status === 200) {
      setLeaseAgreementData(res.data)
    } else {
      setLeaseAgreementData([])
    }
  }

  useEffect(() => {
    loadData()
  }, []);

  const onCheck = (event: any, obj: any) => {
    const { checked, value } = event;

    if (checked) {
      setDataDetail(obj);
      setIsChecked([Number(value)]);
    } else {
      setIsChecked(
        _.filter(isChecked, (i: number) => {
          return i !== Number(value);
        })
      );
      setDataDetail({});
    }
  };

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "transferNo",
      disablePadding: false,
      align: "left",
      label: t("STALL_AGREEMENT.HEADER.DOC_NUMBER"),
    },
    {
      id: "zone",
      disablePadding: false,
      align: "left",
      label: t("STALL_AGREEMENT.HEADER.ZONE"),
    },
    {
      id: "stall",
      disablePadding: false,
      align: "left",
      label: t("STALL_AGREEMENT.HEADER.STALL"),
    },
    {
      id: "zoneType",
      disablePadding: false,
      align: "left",
      label: t("STALL_AGREEMENT.HEADER.START_DATE"),
    },
    {
      id: "dataBill",
      disablePadding: false,
      align: "left",
      label: t("STALL_AGREEMENT.HEADER.EXPIRE_DATE"),
    },
  ];

  const renderData = (objData: any, no: any) => {
    const {
      leaseAgreementApprove,
      leaseAgreementDateStart,
      leaseAgreementDateEnd,
      leaseAgreementId,
      leaseAgreementNo
    } = objData;
    const rowCheck = _.some(isChecked, (i: number) => i === leaseAgreementId);
    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      rowSelect: rowCheck,
      columns: [
        { option: "CHECKBOX", align: "center", label: leaseAgreementId },
        { option: "TEXT", align: "left", label: leaseAgreementNo },
        {
          option: "TEXT",
          align: "left",
          label: leaseAgreementApprove?.bidder?.zoneName || "-",
        },
        {
          option: "TEXT",
          align: "left",
          label: leaseAgreementApprove?.bidder?.stallName || "-",
        },
        {
          option: "TEXT",
          align: "left",
          label: dateToView(leaseAgreementDateStart),
        },
        {
          option: "TEXT",
          align: "left",
          label: dateToView(leaseAgreementDateEnd),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} onCheck={(event) => { onCheck(event.target, objData) }} />
    );
  };

  const onSubmitRequestCancelContract = () => {
    setLoading(true)
    CancelContractApi.create(dataDetail?.branchId, dataDetail?.leaseAgreementId)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t("ทำการยื่นขอยกเลิกเช่าแผงค้าเรียบร้อย"),'reload');
          // notiSuccess(t("CANCELCONTRACT.MESSAGE.SUCCESS.CREATE"),'reload');
        } else {
          const err = res.response.data;
          notiError(t(`CANCELCONTRACT.MESSAGE.${err.error_description}`))
        }
      })
      .catch((e) => {
        const err = e.response.data;
        notiError(t(`CANCELCONTRACT.MESSAGE.${err.error_description}`))
      });
    setLoading(false)
  };

  return (
    <ModalCustom
      title={t("CANCELCONTRACT.BUTTON.CANCEL_REQ")}
      size="xl"
      closeButton={true}
      onClose={() => {
        setIsChecked([])
        setDataDetail(null)
      }}
      onSubmit={() => {
        if (loading) {

        } else { onSubmitRequestCancelContract() }
      }}
      textBtnConfirm={loading ? "Loading..." : t("CANCELCONTRACT.BUTTON.CANCEL_REQ")}
      modalScroll
      component={
        <div className="pb-2">
          <TableCustom
            headCells={headCells}
            page={1}
            pageLimit={5}
            sortType={""}
            sortBy={""}
            rowCount={1}
            rowsData={leaseAgreementData && _.map(leaseAgreementData, (d: any, i: any) => {
              return renderData(d, i);
            })}
            onSort={() => { }}
            setPageLimit={() => { }}
            setPage={() => { }}
            hidePagination
          />
         
        </div>
      }
    />
  )
}