import { Card } from "react-bootstrap";
import { Grid } from "@mui/material";

/** TRANSLATION */
import { useTranslation } from "react-i18next";

/** STYLE */
import useStyles, { TitalForgetPassword } from "./style";
import ButtonCustom from "../../component/button/buttonCustom";
import InputPasswordField from "../../component/input/inputPasswordField";

type Props = {
  onClick?: (value: any) => void;
  submit?: (value: any) => void;
  onChangePassword?: (value: any) => void;
  onChangeConFirm?: (value: any) => void;
  password?: string;
  confirm?: string;

  helperTextPassword?: string;
  helperTextConfirm?: string;
};
export default function ChangePassword(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={`shadow ${classes.card} forget-password`}>
      <Card.Body>
        <Card.Title>
          <TitalForgetPassword>
            {t("CHANGE_PASSWORD.TITLE")}
          </TitalForgetPassword>
        </Card.Title>
        <Card.Body>
          <>
            <Grid>
              <Card.Text>{`${t("CHANGE_PASSWORD.PLEASE")}`}</Card.Text>
            </Grid>
            <Grid>
              <br />
              <div>
              <InputPasswordField
                key="password"
                value={props.password}
                required={true}
                onchange={props.onChangePassword}
                helperText={props.helperTextPassword}
                heading={t("CHANGE_PASSWORD.INPUT.PASSWORD")}
              />
              </div>
              <br />
              <div className="flex-column mt-3">
              <InputPasswordField
                key="password"
                value={props.confirm}
                required={true}
                onchange={props.onChangeConFirm}
                helperText={props.helperTextConfirm}
                heading={t("CHANGE_PASSWORD.INPUT.CONFIRM")}
              />
              </div>
              <br />
            </Grid>
            <Grid className="pb-2">
              <ButtonCustom
                onClick={props.submit}
                type="submit"
                textButton={t("CHANGE_PASSWORD.BUTTON.SUBMIT")}
                disabled={props.password !== props.confirm ? true : false}
              />
            </Grid>
          </>
        </Card.Body>
      </Card.Body>
    </Card>
  );
}
