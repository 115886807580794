import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from '@mui/material';
import { colors } from "../../../constants/colors";
import { background } from '../../../constants/images';
import Header from '../../../component/header/header';
import { Row } from 'react-bootstrap';


export const PageContainer = styled("div")(({ theme }) => ({
  
  height: '100vh',
  width:'100%',
  overflow:'auto'
}));

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: '100%',
  width:'100%'
}));

export const TableContainer = styled(Box)(({theme}) => ({
    marginTop: 24,
    
}))

export const ButtonInTable = styled(Button)(({theme}) => ({
  backgroundColor: colors.green,
  borderRadius: '8px',
  color: colors.white
}))

export const MainPopupContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(41, 48, 46, 0.3)',
  left: 0,
  right: 9,
  top: 0,
  bottom: 0,
  zIndex: 99999,
  [theme.breakpoints.down('md')]: {
      height: '200vh'
  }
}));

export const PopupContainer = styled(Box)(({ theme }) => ({
  height: 500,
  width: 700,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: "auto",
  backgroundColor: colors.white,
  padding:20,
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "90%",
  },
}));


export const HeaderContainer = styled("div")(({ theme }) => ({
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    
}));

export const ItemContainer = styled("div")(({ theme }) => ({
  padding:'30px',
  marginTop:'20px',
  display:'flex',
  flexDirection:'column',
  width:1000,
  height:500,
  backgroundColor:colors.lightGrayBG
  
}));

export const ContentContainer = styled("div")(({ theme }) => ({
  
  
}));



export const PopupItem = styled("div")(({ theme }) => ({
  marginTop:'50px',
  display:'flex',
  flexDirection: "column",
  justifyContent:'center',
  alignItems:'center',
  textAlign: 'center'
}));

export const CloseIcon = styled('img')(({theme}) => ({
  transform: 'translateX(200px)',
  right: 10,
  top:12
  
}))

export const PictureProfile = styled('img')(({theme}) => ({
  height: 130,
  objectFit: 'cover',
  width: 130,
  borderRadius:'50%',
  marginBottom:'20px'
  
  
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 400,
  fontFamily:'Kanit'
  
}));

export const OfficerName = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  fontFamily: 'kanit',
  marginBottom:'20px'
}));

export const ButtonSubmit = styled(Button)(({theme}) => ({
  color: colors.white,
  width: '100%',
  padding: '24px 0',
  display: 'flex',
  justifyContent: 'center',
  fontSize: 16,
  fontWeight: 500,
  borderRadius: 4,
  marginTop: 50,
  backgroundColor:colors.themeSecondColor,
  height:'50px',
  }))

export const SubMessage = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 20,
}));

export const SubDetail = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
}));

export const Editdate = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  fontFamily: 'kanit',
  color:colors.themeSecondColor,
  textDecoration:'underline'
}));

export const BoxEditDate = styled("div")(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  gap:'10px'
  
}));

export const RowStyle = styled(Row)(({theme}) => ({
   marginTop:'40px',
   marginBottom:'40px',
  [theme.breakpoints.down('md')]: {
    marginTop: '0px',
    marginBottom:'0px',

    },
}))

export const ButtonPrint = styled(Button)(({theme}) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  ':hover' : {
      backgroundColor: colors.blue
  }
}))