import React, { useCallback, useEffect, useState } from "react";
import TableCustom from "../../../component/table/tableCustom";
import ReportApi from "../../../api/reports/reports.api";
import TableRowCommon from "../../../component/table/TableRowCommon";
import { numberFormat } from "../../../utils/common";

const EmptySpaceReport = ({
  date_start,
  date_end,
  search_type,
}: {
  date_start: string;
  date_end: string;
  search_type: string;
}) => {
  const [data, setData] = useState<any>([]);

  const handleGetData = useCallback(async () => {
    let condition: any = {
      start_date: date_start ?? "",
      end_date: date_end ?? "",
      type: search_type.toLowerCase() ?? "",
    };

    try {
      const res = await ReportApi.getBankContract(condition);

      if (res.status === 200 && res.data) {
        setData(res.data.data?.bank_contract ?? []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [date_start, date_end, search_type]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const headCells = [
    {
      id: "no",
      disablePadding: false,
      label: "#",
      align: "center",
    },
    {
      id: "zone",
      disablePadding: false,
      label: "พื้นที่",
      align: "center",
    },
    {
      id: "empty",
      disablePadding: false,
      label: "พื้นที่ว่าง",
      align: "center",
    },
    {
      id: "not_empty",
      disablePadding: false,
      label: "พื้นที่ไม่ว่าง",
      align: "center",
    },
  ];

  const renderData = (objData: any, no: any) => {
    const { z_zoneName, contract, bank } = objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no + 1 },
        {
          option: "TEXT",
          align: "center",
          label: z_zoneName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(bank, 0, 0) || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: numberFormat(contract, 0, 0) || "-",
        },
      ],
    };

    return <TableRowCommon {...objRenderData} />;
  };

  return (
    <div>
      <TableCustom
        headCells={headCells}
        customScroll
        page={1}
        pageLimit={1}
        hidePagination
        sortType={"ASC"}
        sortBy=""
        rowCount={0}
        rowsData={
          data &&
          data?.map((d: any, index: any) => {
            return renderData(d, index);
          })
        }
        onSort={() => {}}
        setPageLimit={(newValue) => {}}
        setPage={(newValue) => {}}
      />
    </div>
  );
};

export default EmptySpaceReport;
