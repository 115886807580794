import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'branch'

export interface BranchInterface {
    branchId?: number
    branchNameTh: string
    branchNameEn?: string
    companyId:null
    status: string
  }

export default class BranchApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(branchNameTh: string, branchNameEn: string, companyId: null) {
        return this.api.post(path , { branchNameTh, branchNameEn, companyId })
    }

    static findById(id: number): Promise<BranchInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: BranchInterface) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
