import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
/** STYLE */
import * as Style from "../request-for-stall/requsetForStall.style";
import { colors } from "../../../constants/colors";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";

import BidderApi from "../../../api/stall/bidder/bidder.api";
import _ from "lodash";
import { getProfile } from "../../../utils/app.utils";
import { dateToView } from "../../../utils/date.utils";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { decode } from 'js-base64';

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function ReportRentStall() {
  const { t } = useTranslation();
  const profile: any = JSON.parse(getProfile() || "{}");

  const [AdminData, getAdminData] = useState<any[]>([]);
  const [stallType, setStallType] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("ALL");

  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId);
  const [docDetail, setDocDetail] = useState<boolean>(false);
  const [detailUser, setDetailuser] = useState<any>();
  const [detailDate, setDetailDate] = useState<string>(" ");
  const [bidNo, setBidNo] = useState<string>(" ");

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.roleId);
  }, []);

  const getDataAdmin = useCallback(async () => {
    const condition: any = {
      page: page,
      pageLimit: pageLimit,
      status: "APPROVE"
    };
    const res = await BidderApi.findAll(condition);
    if (res.status === 200) {
      console.log(res.data)
      getAdminData(res.data);
    }
  }, [page, pageLimit]);

  useEffect(() => {
    getDataAdmin();
  }, []);

  const headCellsAdmin = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.DOC_DATE_AD"),
    },
    {
      id: "name",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.REQUEST_NO"),
    },
    {
      id: "zone",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.REQUEST_TYPE"),
    },
    {
      id: "stall",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.NAME"),
    },
    {
      id: "zone",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.ZONE"),
    },
    {
      id: "stall",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.STALL"),
    },
    {
      id: "zone",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.TYPE"),
    },
    {
      id: "zoneType",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.STATUS"),
    },
    {
      id: "action",
      disablePadding: false,
      label: t("REPORT_RENT_STALL.HEADCELL.ACTION"),
    },
  ];

  const renderDataAdmin = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1;

    const { bidderDate, bidNo,bidderTypeName, bidderId,bidderBy, stallName, zoneName, stallTypeName, status } = objData;
    const setState =
    status === "PENDING"
      ? t("REPORT_RENT_STALL.STATUS.PENDING")
      : status === "PRELIMINARY"
      ? t("REPORT_RENT_STALL.STATUS.PRELIMINARY")
      : status === "APPROVE"
      ? t("REPORT_RENT_STALL.STATUS.APPROVE")
      : t("REPORT_RENT_STALL.STATUS.NOT_APPROVE");

    const objRenderData = {
      key: bidderId,
      id: bidderId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "left", label: dateToView(bidderDate)|| "-"  },
        { option: "TEXT", align: "left", label: bidNo || "-" },
        { option: "TEXT", align: "left", label: bidderTypeName || "-" },
        { option: "TEXT", align: "left", label: bidderBy.firstname + " " + bidderBy.lastname || "-" },
        { option: "TEXT", align: "left", label: zoneName|| "-"  },
        { option: "TEXT", align: "left", label: stallName || "-" },
        { option: "TEXT", align: "left", label: stallTypeName || "-" },
        {
          option: "COMPONENT",
          align: "left",
          component: (
            <Button
              sx={{
                backgroundColor:
                  status === "PENDING"
                    ? colors.yellow
                    : status === "ACTIVE"
                    ? colors.green
                    : status === "APPROVE"
                    ? colors.green
                    : colors.red,
                color: colors.white,
                margin: "auto",
              }}
            >
              {setState}
            </Button>
          ),
        },
        {
          option: "ACTION",
          align: "center",
          label: "action",
          style: { color: colors.themeMainColor },
          values: [
            {
              option: "PRINT",
              label:  t(`REPORT_RENT_STALL.STATUS.PRINT`),
              disabled: permissions.isView.disabled
            },
            {
              option: "STATUS_ACTIVE",
              label: t(`REPORT_RENT_STALL.STATUS.DETAIL`),
              disabled: permissions.isUpdate.disabled
            },
            {
              option: "DOCUMENTDETAIL",
              label: t(`REPORT_RENT_STALL.STATUS.APPROVE_CONTRACT`),
              disabled: permissions.isUpdate.disabled
            },
            {
              option: "DELETE",
              label: t(`REPORT_RENT_STALL.STATUS.DELETE`),
              disabled: permissions.isDelete.disabled
            },
          ],
        },
      ],
    };
    return (
      <TableRowCommon
        {...objRenderData}
        onprint={() => {}}
        ondelete={() => {}}
      />
    );
  };

  return (
    <>
      <HeaderCard text={t("REPORT_RENT_STALL.TITlE")} />

      {roleId !== 4 && (
        <Style.MainContainer>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} xs={12}>
                <InputTextField
                  label="ค้นหา"
                  value={search}
                  onchange={(e: any) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <AutocompleteSelect
                  options={[]}
                  getOptionLabel={(option: any) => {}}
                  placeholder={t("REPORT_RENT_STALL.FILTER.ZONE")}
                  labelId="zone"
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <AutocompleteSelect
                  options={[]}
                  // options={stallType}
                  // getOptionLabel={(option: any) => option.stallTypeNameTh}
                  placeholder={t("REPORT_RENT_STALL.FILTER.STALL")}
                  labelId="stall"
                  // value={stallTypeId}
                />
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value;
                    if (value) {
                    }
                  }}
                  label={t("REPORT_RENT_STALL.FILTER.STATUS")}
                  labelId="status"
                  selectId="status"
                  fullWidth
                  value={status}
                  renderValue={() => `${t(`STATUS.${status}`)}`}
                  options={[
                    <MenuItem key="ALL" value={0}>
                      {t("REPORT_RENT_STALL.STATUS.ALL")}
                    </MenuItem>,
                    <MenuItem key="1" value="reservation">
                      {t("REPORT_RENT_STALL.STATUS.PENDING")}
                    </MenuItem>,
                    <MenuItem key="2" value="emtpy">
                      {t("REPORT_RENT_STALL.STATUS.PRELIMINARY")}
                    </MenuItem>,
                    <MenuItem key="3" value="reserved">
                      {t("REPORT_RENT_STALL.STATUS.APPROVE")}
                    </MenuItem>,
                    <MenuItem key="4" value="contract">
                      {t("REPORT_RENT_STALL.STATUS.NOT_APPROVE")}
                    </MenuItem>,
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
          <Style.TableContainer>
            <TableCustom
              headCells={headCellsAdmin}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={"ASC"}
              sortBy=""
              rowCount={1}
              rowsData={
                AdminData &&
                AdminData.map((d: any, i: any) => {
                  return renderDataAdmin(d, i);
                })
              }
              onSort={() => {}}
              setPageLimit={(value: number) => setPageLimit(value)}
              setPage={(value: number) => setPage(value)}
            />
          </Style.TableContainer>
        </Style.MainContainer>
      )}

    </>
  );
}
