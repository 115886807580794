import { menuSeconds } from "../../constants/menu"
import MarketMap from "../../pages/store/market-map/marketMap"
import SubmitStall from "../../pages/store/market-map/submitStall"
import { routeName } from "../routers-name"

const routeMarketMap = [
  {
    ...menuSeconds.MARKET_MAP,
    key: menuSeconds.MARKET_MAP.key,
    name: menuSeconds.MARKET_MAP.name,
    textName: menuSeconds.MARKET_MAP.textName,
    active: false,
    appBar: true,
    component: MarketMap
  },
  {
    ...menuSeconds.MARKET_MAP,
    key: menuSeconds.MARKET_MAP.key,
    name: menuSeconds.MARKET_MAP.name,
    textName: menuSeconds.MARKET_MAP.textName,
    path: routeName.marketMap + '/submit-stall',
    active: false,
    appBar: true,
    component: SubmitStall
  }
]

export default routeMarketMap