import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { colors } from '../../../constants/colors'
import { Box, Button, Typography } from '@mui/material'

export const TypographyHead = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontFamily: 'kanit',
}))
export const NumberInTable = styled(Box)(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    width: 20,
    color: colors.white,
    borderRadius: 2
}))
export const Boxupload = styled(Box)(({ theme }) => ({

    border: '1px solid #D9D9D9', borderRadius: '8px', display: 'flex', justifyContent: 'center', padding: '24px', backgroundColor: '#fff'

}))
export const Boxdash = styled(Box)(({ theme }) => ({

    border: "2px dashed #000",
    width: "80%",
    height: "305px",
    padding: "24px",
    display: 'flex',
    paddingTop: '24px',
    justifyContent: 'center',
    alignItems: 'center'


}))
export const Boxjusttify = styled(Box)(({ theme }) => ({
    display: "flex", justifyContent: "space-between", width: '100%',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
}))
export const Typographymodal = styled(Typography)(({ theme }) => ({

    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'kanit',
}))
export const BoxText = styled(Box)(({ theme }) => ({

    [theme.breakpoints.down('sm')]:
    {
        display: "none"
    }
}))
export const Boxinsite = styled(Box)(({ theme }) => ({

    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "42px",
    marginTop: "27px",
    [theme.breakpoints.down('md')]: {
        padding: "4px",
    }
}))
export const Boxconteiner = styled(Box)(({ theme }) => ({
    paddingLeft: '45px',
    paddingRight: '45px',
    [theme.breakpoints.down('md')]: {
        paddingLeft: '4px',
    paddingRight: '4px',
    }
}))
export const Boxbackground = styled(Box)(({ theme }) => ({
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    paddingLeft: '51px',
    paddingRight: '51px',
    paddingTop: '51px',
    paddingBottom: '51px',
    [theme.breakpoints.down('md')]: {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
    }

}))
export const Boxfilearray = styled(Box)(({ theme }) => ({
    border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'



}))
export const ButtonInTable = styled(Button)(({ theme }) => ({
    backgroundColor: colors.green,
    borderRadius: 4,
    color: colors.white
}))

