import { useEffect, useState } from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../component/input/inputTextField";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../component/button/buttonCustom";
import HeaderCard from "../../component/cardCustom/headerCard";


//** STYLE */
import * as Style from "../payBill/payMentStyle";
import FilterSelect from "../../component/select/filterSelect";
import { icons } from "../../constants/images";
import { useLocation } from "react-router-dom";
import { dateToView } from "../../utils/date.utils";
import { numberFormat } from "../../utils/common";
import WaterApi from "../../api/setting/water/water.api";
import ElectricityApi from "../../api/setting/electricity/electricity.api";
interface Service {
  label: string;
  filterSelectOptions: any[];
  amountLabel: string;
  descriptionLabel: string;
}

export const TrackBillDetail = () => {
  const location: any = useLocation();
  const data: any = location?.state?.params?.data
  const service = data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)
  const sumAll = Number(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 1)?.map((value: any) => value.amount)) +
    Number(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2)?.map((value: any) => value.amount)) +
    Number(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))
  const [listPayment, setListPayment] = useState<boolean>(false);
  const { t } = useTranslation();
  const [waterType, setWaterType] = useState<any[]>([]);
  const [electricityType, setElectricityType] = useState<any[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const totalAmount = data?.invoiceDetail
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);

  const totalElectricity = data?.invoiceDetail
    .filter((item: any) => item.paymentServiceId === 1)
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);
    const totalWater = data?.invoiceDetail
    .filter((item: any) => item.paymentServiceId === 2)
    .map((item: any) => item.amount ? parseFloat(item.amount) : 0)
    .reduce((acc: any, value: any) => acc + value, 0);


  const handleServiceAdd = () => {
    const paymentListLabel = t("PAYBILL.REPORT.PAYMENT_LIST");
    const amountLabel = t("PAYBILL.REPORT.AMOUNT");
    const detailLabel = t("PAYBILL.REPORT.DETAIL");

    setServices((prevServices) => [
      ...prevServices,
      {
        label: paymentListLabel,
        filterSelectOptions: [],
        amountLabel: amountLabel,
        descriptionLabel: detailLabel,
      },
    ]);
  };

  const handleServiceDelete = (index: number) => {
    setServices((prevServices) => prevServices.filter((_, i) => i !== index));
  };


  const getWaterType = async () => {
    const condition = {
      page: 1,
      pageLimit: 5,
      sortType: "ASC",
      status: "ACTIVE",
      utilitiesTypeId: 2,

    };
    await WaterApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setWaterType(res.data)
      } else {
        setWaterType([])
      }
    });
  };
  const getElectricityType = async () => {
    const condition = {
      page: 1,
      pageLimit: 5,
      sortType: "ASC",
      status: "ACTIVE",
      utilitiesTypeId: 1,

    };
    await ElectricityApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setElectricityType(res.data)
      } else {
        setElectricityType([])
      }
    });
  };

  useEffect(() => {
    getElectricityType()
    getWaterType()
  }, []);
  console.log(data);
  return (
    <>

      <HeaderCard text={t("PAYBILL.TITLE")} />

      <Style.BoxMainContainer>
        <Style.BoxContentContainer>
          <Style.BoxHeader>
            <Col lg={2} md={4} className="d-flex flex-row">
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.ZONE")}</Style.SubDetail>
              <Style.SubDetail className="mr-3">{data?.leaseAgreement?.leaseAgreementApprove?.bidder?.zoneName}</Style.SubDetail>
              {/* <Style.SubDetail className="mr-3">{t("11")}</Style.SubDetail> */}
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.STALL")}</Style.SubDetail>
              <Style.SubDetail>{data?.leaseAgreement?.leaseAgreementApprove?.bidder?.stallName}</Style.SubDetail>
              {/* <Style.SubDetail>{t("A2")}</Style.SubDetail> */}
            </Col>
            <Col lg={2} md={4} className="d-flex flex-row">
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.NAME")}</Style.SubDetail>
              <Style.SubDetail>{`${data?.leaseAgreement?.firstname} ${data?.leaseAgreement?.lastname}`}</Style.SubDetail>
              {/* <Style.SubDetail>{t("สมชาย เก่งกาจ")}</Style.SubDetail> */}
            </Col>
            <Col lg={2} md={4} className="d-flex flex-row">
              <Style.SubDetail className="mr-3">{t("PAYBILL.REPORT.RECEIPT_NUMBER")}</Style.SubDetail>
              <Style.SubDetail>{data?.invoiceNo}</Style.SubDetail>
            </Col>

            <Col lg={2} />
            <Col lg={2} md={4} className="d-flex flex-row mt-md-2">
              <Style.SubDetail className="mr-3">{t("PAYBILL.HEADCELL.DOCUMENTDATE")}</Style.SubDetail>
              <Style.SubDetail>{dateToView(data?.createDate)}</Style.SubDetail>
            </Col>

            <Col>
              <ButtonCustom
                type="button"
                textButton={t("PAYBILL.BUTTON.PRINT")}
                endIcon={<img src={icons.print} />}
                onClick={() => { }}
              />
            </Col>
          </Style.BoxHeader>

          <Divider className="w-100 mx-0" />

          {!listPayment && (
            <Style.BoxListPayment>
              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 1) && <div>

                {data?.invoiceDetail?.filter((value: any) => value.paymentServiceId === 1).map((d: any, i: number) => {
                  return (
                    <div>
                      <Row className="my-2">
                        <Col>
                          <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE_DETAILS")} {t("PAYBILL.REPORT.METER")} {i + 1}</Style.SubDetail>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3} className="d-flex flex-row ">
                          <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_USAGE")}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={6}>
                          <Style.SubDetail>{t("-")}</Style.SubDetail>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_RATE")}</Style.SubDetail>
                        </Col>
                        <Col lg={2} xs={2} md={3}>
                          <Style.SubDetail>{numberFormat(Number(electricityType[0]?.pricePerUnit))}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={4} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_CONSUMPTION")}</Style.SubDetail>
                        </Col>
                        <Col lg={2} xs={2} md={3}>
                          <Style.SubDetail>{numberFormat(d?.utilities?.utilitiesDetail?.number || 0, 0) || 0}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={4} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
                        </Col>
                        <Col lg={2} xs={2} md={3}>
                          <Style.SubDetail>{numberFormat(d?.amount) || 0.00}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={4} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                        </Col>
                      </Row>

                      {d?.utilities?.utilitiesDetail?.fileUpload && <Row>
                        <Col lg={3} md={2} />
                        <Col lg={2} xs={12} md={12}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_ELECTRIC_METER")}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={12} md={12}>
                          <img src={process.env.REACT_APP_FILE_URL + d?.utilities?.utilitiesDetail?.fileUpload?.filePath
                            + d?.utilities?.utilitiesDetail?.fileUpload?.fileName} alt="" style={{ width: '100%', height: 200 }} />
                        </Col>
                      </Row>}
                    </div>
                  )
                })}
                {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 1).length !== 0 && <div>
                  <Row className="my-3">
                    <Col lg={3} md={2} />
                    <Col lg={3} xs={7} md={3}>
                      <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_ELECTRICITY_ฺBILL")}</Style.HeadMessage>
                    </Col>
                    <Col lg={2} xs={2} md={3}>
                      <Style.HeadMessage>{numberFormat(totalElectricity)}</Style.HeadMessage>
                    </Col>
                    <Col lg={3} xs={2} md={3}>
                      <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                    </Col>
                  </Row>

                  <Divider className="w-100 mx-0" />
                </div>
                }
              </div>}

              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2) && <div>
                {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2).map((d: any, i: number) => {
                  return (
                    <div>
                      <Row className="my-2">
                        <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE_DETAILS")} {t("PAYBILL.REPORT.METER")} {i + 1}</Style.SubDetail>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3} >
                          <Style.SubDetail>{t("PAYBILL.REPORT.WATER_USAGE")}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={6}>
                          <Style.SubDetail>{t("-")}</Style.SubDetail>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.WATER_RATE")}</Style.SubDetail>
                        </Col>
                        <Col lg={2} xs={2} md={3}>
                          <Style.SubDetail>{numberFormat(waterType[0]?.pricePerUnit)}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={4} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.AMOUNT_UNIT")}</Style.SubDetail>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.WATER_CONSUMPTION")}</Style.SubDetail>
                        </Col>
                        <Col lg={2} xs={2} md={3}>
                          <Style.SubDetail>{numberFormat(d?.utilities?.utilitiesDetail?.number || 0, 0) || 0}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={2} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.UNIT")}</Style.SubDetail>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={3} md={2} />
                        <Col lg={3} xs={6} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
                        </Col>
                        <Col lg={2} xs={2} md={3}>
                          <Style.SubDetail>{numberFormat(d?.amount) || 0.00}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={2} md={3}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                        </Col>
                      </Row>

                      {d?.utilities?.utilitiesDetail?.fileUpload && <Row>
                        <Col lg={3} md={2} />
                        <Col lg={2} xs={12} md={12}>
                          <Style.SubDetail>{t("PAYBILL.REPORT.PICTURE_OF_WATER_METER")}</Style.SubDetail>
                        </Col>
                        <Col lg={3} xs={12} md={12}>
                          <img src={process.env.REACT_APP_FILE_URL + d?.utilities?.utilitiesDetail?.fileUpload?.filePath
                            + d?.utilities?.utilitiesDetail?.fileUpload?.fileName} alt="" style={{ width: '100%', height: 200 }} />

                        </Col>
                      </Row>}
                    </div>
                  )
                })}
                {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2).length !== 0 && <div>
                  <Row className="my-3">
                    <Col lg={3} md={2} />
                    <Col lg={3} xs={7} md={3}>
                      <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_WATER_ฺBILL")}</Style.HeadMessage>
                    </Col>
                    <Col lg={2} xs={2} md={3}>
                      <Style.HeadMessage>{numberFormat(totalWater)}</Style.HeadMessage>
                    </Col>
                    <Col lg={3} xs={2} md={3}>
                      <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                    </Col>
                  </Row>
                  <Divider className="w-100 mx-0" />
                </div>
                }
              </div>}

              {data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3) && <div>
                <Row className="my-2">
                  <Style.SubDetail>{t("PAYBILL.CREATE.STALL_DETAILS")}</Style.SubDetail>
                </Row>

                <Row>
                  <Col lg={3} md={2} />
                  <Col lg={3} xs={7} md={3}>
                    <Style.SubDetail>{t("PAYBILL.REPORT.LEASES_TYPE")}</Style.SubDetail>
                  </Col>
                  <Col lg={3} xs={3} md={3}>
                    <Style.SubDetail>{data?.leasesType?.leasesTypeNameTh}</Style.SubDetail>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3} md={2} />
                  <Col lg={3} xs={7} md={3}>
                    <Style.SubDetail>{t("PAYBILL.REPORT.STALL_RENTAL_FEE")}</Style.SubDetail>
                  </Col>
                  <Col lg={2} xs={2} md={3}>
                    <Style.SubDetail>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))}</Style.SubDetail>
                  </Col>
                  <Col lg={3} xs={2} md={3}>
                    <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col lg={3} md={2} />
                  <Col lg={3} xs={7} md={4}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.AMOUNT_OF_STALL_BILL")}</Style.HeadMessage>
                  </Col>
                  <Col lg={2} xs={2} md={2}>
                    <Style.HeadMessage>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))}</Style.HeadMessage>
                  </Col>
                  <Col lg={3} xs={2} md={3}>
                    <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                  </Col>
                </Row>

                <Divider className="w-100 mx-0" />
              </div>}
            </Style.BoxListPayment>
          )}


          {!listPayment && (
            <div>
              <Row className="my-2">
                <Style.SubDetail>{t("PAYBILL.REPORT.PAYMENT_DETAILS")}</Style.SubDetail>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.ELECTRICITY_ฺBILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={4} md={3}>
                  <Style.SubDetail>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 1)?.map((value: any) => value.amount))}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.WATER_BILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={4} md={3}>
                  <Style.SubDetail>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 2)?.map((value: any) => value.amount))}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.STALL_BILL")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={4} md={3}>
                  <Style.SubDetail>{numberFormat(data?.invoiceDetail?.filter((d: any) => d.paymentServiceId === 3)?.map((value: any) => value.amount))}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.CREATE.OTHER_PAYMENT")}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={4} md={3}>
                  <Style.SubDetail>{t("-")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={2} />
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t('PAYBILL.REPORT.DISCOUNT')}</Style.SubDetail>
                </Col>
                <Col lg={2} xs={4} md={3}>
                  <Style.SubDetail>{t("-")}</Style.SubDetail>
                </Col>
                <Col lg={3} xs={4} md={3}>
                  <Style.SubDetail>{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                </Col>
              </Row>

              <Row className="my-3">
                <Col lg={3} md={2} />
                <Col lg={3} xs={6} md={3}>
                  <Style.HeadMessage>{t("PAYBILL.REPORT.TOTAL_AMOUNT")}</Style.HeadMessage>
                </Col>
                <Col lg={2} xs={4} md={3}>
                  <Style.HeadMessage>{numberFormat(sumAll)}</Style.HeadMessage>
                </Col>
                <Col lg={3} xs={2} md={3}>
                  <Style.HeadMessage>{t("PAYBILL.REPORT.BAHT")}</Style.HeadMessage>
                </Col>
              </Row>

              {/* <Row>
              <ButtonCustom
                variant="contained"
                onClick={() => {}}
                textButton={t("PAYBILL.BUTTON.PAYOFF_DEBT")}
                btnStyle={{
                  fontSize: "16px",
                  width: "100%",
                  padding: "8px 25px",
                }}
              />
            </Row> */}
            </div>
          )}

          {listPayment && (
            <div>
              <Row className="my-2">
                <Col lg={3} />
                <Col lg={3} xs={7} md={3}>
                  <Style.ContentHeader>{t("PAYBILL.CREATE.OTHER_PAYMENT")}</Style.ContentHeader>
                </Col>
                <Col lg={3} md={6} xs={3} />
                <Col>
                  <img className="ml-5" src={icons.add} onClick={() => handleServiceAdd()} />
                </Col>
              </Row>

              {services.map((service, index) => (
                <div key={index}>

                  <Style.BoxPayment>
                    <Col lg={3} xs={5} className="d-flex flex-row">
                      <Style.SubDetail className="mr-2 ml-2">{index + 1}</Style.SubDetail>
                      <Style.SubDetail className="mr-2">{t(service.label)}</Style.SubDetail>
                      {service.filterSelectOptions && (
                        <Style.SubDetail>
                          <FilterSelect
                            onchange={() => { }}
                            renderValue={() => { }}
                            label={t("PAYBILL.REPORT.PAYMENT")}
                            style={{ width: "180px" }}
                            selectId={`select-${index}`}
                            labelId={`label-${index}`}
                            options={() => { }}
                          />
                        </Style.SubDetail>

                      )}
                    </Col>
                    <Col lg={3} className="d-flex flex-row">
                      {service.amountLabel && (
                        <>
                          <Style.SubDetail className="mr-2">{t(service.amountLabel)}</Style.SubDetail>
                          <InputTextField
                            label=""
                            style={{ width: "150px" }}
                            onchange={() => { }}
                          />
                          <Style.SubDetail className="ml-2 mr-5">{t("PAYBILL.REPORT.BAHT")}</Style.SubDetail>
                        </>
                      )}
                      <img src={icons.iconTrash} onClick={() => handleServiceDelete(index)} />
                    </Col>

                  </Style.BoxPayment>
                  {service.descriptionLabel && (
                    <>
                      <Row>
                        <Style.BoxPayment>
                          <Col className="d-flex flex-row">
                            <Style.SubDetail className="ml-3 mr-3">{t(service.descriptionLabel)}</Style.SubDetail>
                            <InputTextField
                              label=""
                              style={{ width: "480px" }}
                              onchange={() => { }}
                            />
                          </Col>
                        </Style.BoxPayment>
                      </Row>
                    </>
                  )}

                </div>
              ))}

              <Row className="my-3">
                <Col lg={3} />
                <Col>
                  <Style.ContentHeader>{t("PAYBILL.CREATE.DISCOUNT")}</Style.ContentHeader>
                </Col>
              </Row>

              <Row>
                <Style.ButtonStyle onClick={() => { }} >
                  {t("PAYBILL.BUTTON.SAVE")}
                </Style.ButtonStyle>
              </Row>
            </div>
          )}

        </Style.BoxContentContainer>
      </Style.BoxMainContainer >

    </>
  );
}
