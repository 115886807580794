import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../constants/colors";



export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: '100vh',
  overflow: 'auto',
 
}));

export const PageContainer = styled("div")(({ theme }) => ({  
  height: '100dvh',
  width:'100%',
  
}));

export const TableContainer = styled(Box)(({theme}) => ({
    marginTop: 24,        
}));

export const Title = styled(Typography)(({theme}) => ({
  fontSize: 24,
  fontWeight: 400,
  color:colors.green,
   
}))

export const TableStyle = styled("div")(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  whiteSpace:'nowrap'

  
}));

export const TextInTable = styled("div")(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  gap:'10px'
  
}));
export const TextStyle = styled(Typography)(({theme}) => ({
  fontSize: 16,
  fontWeight: 400,
 
}))








  
  


