import { menuFirsts } from "../../constants/menu"
import Announce from "../../pages/announce/announce"
import Addannounce from "../../pages/announce/addAnnounce"
import AddAnnounceRental from "../../pages/announce/addAnnouncerental"

const routeAnnounce = [
    {
    ...menuFirsts.ANNOUNCE,
    key: menuFirsts.ANNOUNCE.key,
    name: menuFirsts.ANNOUNCE.name,
    textName: menuFirsts.ANNOUNCE.textName,
    active: false,
    appBar: true,
    component: Announce
  },
    {
    ...menuFirsts.ANNOUNCE,
    key: menuFirsts.ANNOUNCE.key,
    name: menuFirsts.ANNOUNCE.name,
    textName: menuFirsts.ANNOUNCE.textName,
    active: false,
    appBar: true,
    path:  '/create-announce',
    component: Addannounce
  }
  ,
    {
    ...menuFirsts.ANNOUNCE,
    key: menuFirsts.ANNOUNCE.key,
    name: menuFirsts.ANNOUNCE.name,
    textName: menuFirsts.ANNOUNCE.textName,
    active: false,
    appBar: true,
    path:  '/create-announce-rental',
    component: AddAnnounceRental
  },
]

export default routeAnnounce