import { menuSeconds } from "../../constants/menu"
import WaterBill from "../../pages/utilityBill/waterBill/waterBill"
import ElectricityBill from "../../pages/utilityBill/electricityBill/electricityBill"
import UtilityBill from "../../pages/utilityBill/managementUtility/utilityBill"

const routeUtility = [
  {
    ...menuSeconds.MANAGEMENT_UTILITY,
    key: menuSeconds.MANAGEMENT_UTILITY.key,
    name: menuSeconds.MANAGEMENT_UTILITY.name,
    textName: menuSeconds.MANAGEMENT_UTILITY.textName,
    active: false,
    appBar: true,
    component: UtilityBill
  } ,
    {
      ...menuSeconds.WATER_BILL,
      key: menuSeconds.WATER_BILL.key,
      name: menuSeconds.WATER_BILL.name,
      textName: menuSeconds.WATER_BILL.textName,
      active: false,
      appBar: true,
      component: WaterBill
    } ,
    {
      ...menuSeconds.ELECTRICITY_BILL,
      key: menuSeconds.ELECTRICITY_BILL.key,
      name: menuSeconds.ELECTRICITY_BILL.name,
      textName: menuSeconds.ELECTRICITY_BILL.textName,
      active: false,
      appBar: true,
      component: ElectricityBill
    } ,
   
  ]
  
  export default routeUtility