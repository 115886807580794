import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Button, Grid, Typography, Box } from '@mui/material'
import homepage from '../../../src/assets/images/Homepage1.png'
import { background } from '../../constants/images';
import carIcon from '../../assets/images/cardstill.png'
import cardadmin from '../../assets/images/cardadmin.png'
import { colors } from '../../constants/colors'
import { decode } from 'js-base64';
import { getProfile } from '../../utils/app.utils'

const profile: any = JSON.parse(getProfile() || '{}')
const role: any = profile.length > 3 ? JSON.parse(decode(profile)) : '{}'

export const CardHomepage = styled(`div`)(({ theme }) => ({
  backgroundColor: ` ${colors.bgBase} !important`,
  width: '100%',
  // height: '100vh',

  // overflow: 'scroll !important',
  backgroundImage: `url(${role.roleId == 4 ? background.homePageBackground : background.homePageBackgroundadmin})`,
  paddingBottom: 50,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // overflowY: 'scroll',

  color: 'unset !important',

  '& .card-text': {
    fontSize: '16px',
    textAlign: 'left'
  },

}))
export const Cardstill = styled(`div`)(({ theme }) => ({

  height: '221px',
  width: '246px',
  backgroundImage: `url(${role.roleId == 4 ? carIcon : cardadmin})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  flexDirection: 'column',
  transition: '0.4s',
  cursor: 'pointer',
  ':hover': {
    border: `solid 3px ${role.roleId ? role.roleId == 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor}  !important`,
    // color: colors.white,
  }
}))

export const BoxImg = styled(`div`)(({ theme }) => ({
  width: "25%",
  [theme.breakpoints.down('md')]: {
    display: 'none',
  }
}))
export const BoxImgTop = styled(`div`)(({ theme }) => ({
  width: "100%",
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    width: "60%",
    justtifyContent: 'center'
  }
}))

export const TypographyTextres = styled(Typography)(({ theme }) => ({
  marginRight: "15px",
  fontFamily: 'Kanit',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px'
  }
}))
export const GridNew = styled(Grid)(({ theme }) => ({

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  }
}))

export const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,

}))
export const Cardinfo = styled(Box)(({ theme }) => ({
  display: "flex", flex: 2, backgroundColor: role.roleId ? role.roleId == 4 ? "#42a18a59" : colors.blueadminfade : "#42a18a59", width: '100%', height: 'auto', padding: '14px'
}))
export const Cardinfodetail = styled(Box)(({ theme }) => ({
  display: "flex", flex: 1, marginBottom: '15px', backgroundColor: role.roleId ? role.roleId == 4 ? "#42a18a59" : colors.blueadminfade : "#42a18a59", width: '100%', height: 'auto', padding: '14px'
}))
export const TextHead = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 'bold',
  fontFamily: 'Kanit',
  padding: 32,
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  }
}))
export const TextDetail = styled(Typography)(({ theme }) => ({
  fontSize: 14,

  fontFamily: 'Kanit',

  [theme.breakpoints.down('md')]: {
    fontSize: 12,
  }
}))
export const DateInTable = styled(Box)(({ theme }) => ({
  height: 56,
  width: 48,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: role.roleId ? role.roleId == 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor,
  flexDirection: 'column',
  borderRadius: 2
}))

export const DateInBox = styled(Typography)(({ theme }) => ({
  color: colors.white,
  fontSize: 16,
  fontWeight: 400,
  textAlign: 'center'
}))

export const NewsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'self-start'
}))

export const NewsHeading = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500
}))

export const NewsTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  whiteSpace: 'nowrap'
}))

export const Readmore = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: role.roleId ? role.roleId == 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor,
  ':hover': {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

export const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))
export const BoxInfo = styled(Box)(({ theme }) => ({

  border: `2px solid ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
  borderRadius: '5px',
  width: "100%",
  height: "auto",
  minHeight:'175px',
  marginBottom: "65px",
  alignItems: 'center',

  display: 'flex',
  padding: '32px',
  marginRight: '25px',
  backgroundColor: '#ffff'


}))
export const BoxZone = styled(Box)(({ theme }) => ({

  display: "flex",
  marginLeft: "10px",
  width: "100%",
  padding: "0 12px",


}))
export const BoxFlex = styled(Box)(({ theme }) => ({

  display: 'flex'

}))
export const BoxFlexinfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',


}))
export const Boxborder = styled(Box)(({ theme }) => ({
  border: ` 1px solid ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
  borderRadius: '5px',
  width: '100%',
  height: 'auto',
  paddingTop: 14,
  paddingBottom: 24,

}))
export const BoxInfodetail = styled(Box)(({ theme }) => ({
  border: ` 2px solid ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
  borderRadius: '5px',
  width: "100%",
  height: "auto",
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '24px',
  display: 'flex',
  padding: '18px',

}))
export const BoxInfodetailhtml = styled(Box)(({ theme }) => ({
  // border: ` 2px solid ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
  borderRadius: '5px',
  width: "100%",
  height: "auto",

  paddingTop: '24px',
  display: 'flex',
  padding: '18px',
  marginTop: '8px'

}))

export const MainRedText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: colors.red,
  textAlign: 'center'
}))

export const BlackText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.black
}))

export const ClickPayDebt = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: colors.red,
  marginLeft: 4,
  ':hover': {
    textDecoration: "underline"
  }
}))

export const DateHeading = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: role.roleId ? role.roleId == 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor,
  marginLeft: 16
}))

export const Filearray = styled(Box)(({ theme }) => ({
  border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'

}))


const useStyles = makeStyles({
  Cardinfo: {
    display: "flex", flex: 2, backgroundColor: "#00806024", width: '100%', height: 'auto', padding: '14px'
  },
  Info: {
    border: ` 2px solid ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
    borderRadius: '5px',
    width: "100%",
    height: "auto",
    marginBottom: "65px",
    alignItems: 'center',

    display: 'flex',
    padding: '32px',
    marginRight: '25px',
    backgroundColor: '#ffff'

  },
  flex: {
    display: 'flex'
  },
  BoxZone: {
    display: "flex",
    marginLeft: "10px",
    width: "100%",
    padding: "0 12px",
  },
  BoxItems: {
    width: '100%', backgroundColor: '#fafafa', height: '221px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'absolute', zIndex: 1

  },
  Flex: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#0080600D',
    marginBottom: 32
  },
  Infodetail: {
    border: ` 2px solid ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
    borderRadius: '5px',
    width: "100%",
    height: "auto",
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '24px',
    display: 'flex',
    padding: '18px',

    backgroundColor: '#ffff'

  },
  Flexinfo: {
    display: 'flex',
    justifyContent: 'center',


  },
  Flexinfodetail: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    flexDirection: 'column'


  },
  BorderBox: {
    border: ` 1px solid  ${role.roleId ? role.roleId == 4 ? colors.green : colors.themeSecondColor : colors.green}`,
    borderRadius: '5px',
    width: '100%',
    height: 'auto',
    paddingTop: 14,
    paddingBottom: 24,


  },
})
export default useStyles
