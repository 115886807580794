import { makeStyles, styled } from "@mui/material/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../../constants/colors";
import styleds from 'styled-components'
import { PatternFormat } from "react-number-format";

export const Container = styled(Box)(({ theme }) => ({
  height: "100% !important",
  overflow: "auto !important",
}));

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  paddingBottom: 40,
  // height: "100vh",
  overflow: 'auto'
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
}));

export const ButtonInTable = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  color: colors.white,
}));

export const ModalBg = styled(Box)(({ theme }) => ({
  backgroundColor: colors.disabledLightGray,
  width: "100%",
  marginTop: 16,
  padding: "72px 32px 72px 32px",
  borderRadius: 8,
  display: "flex",
  justifyContent: "space-between",
}));

export const BoxAddImage = styled(Box)(({ theme }) => ({
  backgroundColor: colors.white,
  width: 384,
  height: 408,
  borderRadius: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ButtonSubmitModal = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 50,
  backgroundColor: colors.themeMainColor,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: colors.white,
}));

export const Topic = styled(Typography)(({ theme }) => ({
  color: colors.black,
  fontSize: 18,
  fontWeight: 400,
}));

// export const InformationContainer = styled(Box)(({ theme }) => ({
//   padding: '0px 64px 48px 64px',
//   backgroundColor: colors.white,
//   border: `1px solid ${colors.bgText}`,
//   borderRadius: 8,
//   [theme.breakpoints.down('lg')]: {
//     padding: '0px 24px 24px 24px',
//     backgroundColor: colors.white,
//   }
// }));

export const BgInformation = styled(Box)(({ theme }) => ({
  backgroundColor: colors.lightGrayBG,
  borderRadius: 8,
  padding: 32,
}));

export const InformationRenter = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: colors.white,
  marginTop: 16,
  marginBottom: 0,
}));

export const PicRenter = styled("img")(({ theme }) => ({
  width: 200,
  height: 200,
}));

export const GridInput = styled(Grid)(({ theme }) => ({
  marginTop: 80,
  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
  },
  [theme.breakpoints.down("lg")]: {
    marginTop: 16,
  },
}));

export const FlexRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: 5,
}));

export const Detail = styled(Typography)(({ theme }) => ({
  color: colors.black,
  fontSize: 16,
}));

export const RequestDetail = styled(Typography)(({ theme }) => ({
  color: colors.danger,
  fontSize: 16,
}));

export const BoxInputAge = styled(Box)(({ theme }) => ({
  width: 120,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const ContainerButtonSubmit = styled(Box)(({ theme }) => ({
  margin: "16px 0px",
  // padding:'0px 64px'
}));
export const Array = styled(Box)(({ theme }) => ({
  border: '1px solid #D9D9D9', backgroundColor: '#fff', padding: '12px', borderRadius: '8px'
}));

export const Boxupload = styled(Box)(({ theme }) => ({
  border: "1px solid #D9D9D9",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  padding: "24px",
  backgroundColor: "#fff",
}));
export const Boxdash = styled(Box)(({ theme }) => ({
  border: "2px dashed #000",
  width: "80%",
  height: "305px",
  padding: "24px",
  display: "flex",
  paddingTop: "24px",
  justifyContent: "center",
  alignItems: "center",
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  borderRadius: 4,
  padding: 16,
}));

export const CustomPatternFormat = styled(PatternFormat)(({theme}) => ({
  width:'100%',
  backgroundColor: colors.white,
  top: "-5px !important",
  borderRadius: 8,
  height: 43,
  borderColor: `${colors.black_12}`,
  padding: "0 1rem",
  textOverflow: "ellipsis",
  ":focus": {
    outline: ` 2px solid ${colors.themeMainColor} !important`,    
  },  
  ":before": {
    backgroundColor: colors.white,
  }
}))

export const Heading = styled(Typography)(({theme}) => ({
  fontSize: 16,
  color: `${colors.textPrimary}`,
  marginBottom: 8,

}))

export const Styles = styleds.div`
  padding: 1px;

  table {
    border-spacing: 0;
    
    th,
    td {
      margin: 0;
      padding: 1px;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  } 
`

// const useStyles = makeStyles({
//   boxupload: {
//     border: '1px solid #D9D9D9', borderRadius: '8px', display: 'flex', justifyContent: 'center', padding: '24px', backgroundColor: '#fff'
// },
// boxdash: {

//   border: "2px dashed #000",
//   width: "80%",
//   height: "305px",
//   padding: "24px",
//   display: 'flex',
//   paddingTop: '24px',
//   justifyContent: 'center',
//   alignItems: 'center'

// },
// })

// export default useStyles
