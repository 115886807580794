import { Box, Container, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

import InputTextField from "../../../component/input/inputTextField";
import { Col, Row } from "react-bootstrap";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";

import TableRowCommon from "../../../component/table/TableRowCommon";
import { useTranslation } from "react-i18next";
import TableCustom from "../../../component/table/tableCustom";

import ModalCustom from "../../../component/modalCustom/modalCustom";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../../../app/slice/modal.slice";
import ButtonCustom from "../../../component/button/buttonCustom";
import { colors } from "../../../constants/colors";
import HeaderCard from "../../../component/cardCustom/headerCard";
import Cloudupload from "../../../assets/icon/cloudupload";
import InputCheckbox from "../../../component/input/inputCheckbox";
import { useDropzone } from "react-dropzone";
import InputDatePicker from "../../../component/input/inputDatePicker";
import {
  BoxTabSearch,
  Boxconteiner,
  Boxjusttify,
  Boxupload,
  Typographymodal,
} from "./meterElectricityStyle";
import UtilitieApi from "../../../api/utilitie/utilities.api";
import moment from "moment";
import { BoxTakePhoto, Boxcol, Boxdash } from "../waterBill/meterWaterStyle";
import { shortnessDatemonth } from "../../../utils/date.utils";
import {
  isCreate,
  isDelete,
  isUpdate,
  isView,
} from "../../../utils/permission.utils";
import ZoneApi from "../../../api/zone/zone.api";
import ElectricityApi from "../../../api/setting/electricity/electricity.api";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import { ExportProps } from "../../../constants/reportConstant";
import { useFunctionPrint } from "../../../component/Print/print";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";
import { debouncedApiCall, getProfile } from "../../../utils/app.utils";
import Loading from "../../../component/loading/loading";
import { decode } from 'js-base64';
import { swalActive } from "../../../component/notification/swal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFile, faFileExcel, faPenToSquare, faPrint, faQrcode, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { QrReader } from "react-qr-reader";
import { Camera } from "react-camera-pro";
import LeasesTypeApi from "../../../api/setting/leases/leases.api";
import { excel } from "../../../component/excel/lib";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};
export default function ElectricityBill() {
  const cameraRef = useRef<any>(null);
  const ref: any = useRef(null);
  const profile: any = JSON.parse(getProfile() || "{}");
  const branchId: number = Number(localStorage.getItem("BRANCH")) || 1;

  const role: any = profile.length > 3 ? JSON.parse(decode(profile)) : '{}'
  const [file, setFile] = useState<any>([]);
  const [electricList, setElectricList] = useState<any>([]);
  const [check, setCheck] = useState<boolean>(false);
  const [utilities, setUtilities] = useState<any>(undefined);

  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>();
  const [search, setSearch] = useState<any>("");
  const [searchZone, setSearchZone] = useState<number | null>(null);
  const [searchDate, setSearchDate] = useState<any>("");
  const [searchStall, setSearchStall] = useState<number | null>(null);
  const [zoneId, setZoneId] = useState<any>("");
  const [stallId, setStaillId] = useState<any>("");
  const [dateModal, setDateModal] = useState<any>("");
  const [meterNumber, setMeterNumber] = useState<any>("");
  const [print, setPrint] = useState<any>();
  const [zone, setZone] = useState<any>([]);
  const [stall, setStall] = useState<any>([]);
  const [img, setImg] = useState<any>("");
  const [sum, setSum] = useState<number>(0);
  const [detailWaterbill, setDetailWaterBill] = useState<any>({});
  const [open, setOpen] = useState<number>(0);
  const { t } = useTranslation();
  const [meterstartDate, setMeterStartDate] = useState<any>(Date.now());
  const [waterType, setWaterType] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [leaseAgreementId, setLeaseAgreementId] = useState<number>(0);
  const componentRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrCodeData, setQrCodeData] = useState<any>("")

  const isUser: boolean = JSON.parse(decode(profile))?.roleId === 4 ? true : false;
  const [leasesTypeData, setLeasesTypeData] = useState<any[]>([]);
  const [leasesTypeId, setLeasesTypeId] = useState<number>(Number);
  const [keywordLeasesTypeData, setKeywordLeasesTypeData] = useState<string>("")
  const [utilitiesDay, setUtilitiesDay] = useState<boolean>(false)
  const [excelData, setExcelData] = useState<any>([])


  const handleGetLeasesTypeData = useCallback(async () => {
    let condition: any = {}
    if (keywordLeasesTypeData) condition = { ...condition, search: keywordLeasesTypeData }
    await LeasesTypeApi.findAll(condition)
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          setLeasesTypeData(res?.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [keywordLeasesTypeData])
  useEffect(() => {
    if (!isUser) {
      handleGetLeasesTypeData();
    }
  }, [handleGetLeasesTypeData])

  const getUtilities = async () => {
    await UtilitieApi.day().then((res: any) => {
      const day = moment().date()
      const getDay = res.data[0]
      setUtilitiesDay(day >= getDay?.startDate && day <= getDay?.endDate)
    }
    ).catch((error: any) => console.log(error))
  }
  useEffect(() => {
    getUtilities()
  }, [])

  const getWaterType = async () => {
    const condition = {
      page: 1,
      pageLimit: 1,
      sortType: "DESC",
      sortBy: "dateUsed",
      status: "ACTIVE",
    };
    await ElectricityApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        // console.log(res.data)
        setWaterType(res.data);
      } else {
        setWaterType([])
      }
    });
  };
  const getTestPdfData = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const getTestDetails = UtilitieApi.detail(id);

        const [testResult] = await Promise.all([getTestDetails]);
        console.log(testResult);

        setPrint(testResult.data);
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    },
    [UtilitieApi]
  );

  const getZone = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
      status: "CONTRACT",
    };
    await ZoneApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setZone(res?.data);
      }
    });
  };

  const handleSubmitedit = (val: any) => {
    const condition = {
      branchId: detailWaterbill?.branchId,
      utilitiesNo: detailWaterbill?.utilitiesNo,
      utilitiesTypeId: detailWaterbill?.utilitiesType.utilitiesTypeId,
      utilitiesDate: detailWaterbill?.utilitiesDate,
      utilitiesMonth: Number(moment(detailWaterbill?.utilitiesDate).format("M")),
      leaseAgreementId: detailWaterbill?.leaseAgreementId,
      zoneId: detailWaterbill.zoneId,
      stallId: detailWaterbill.stallId,
      utilitiesDetail: {
        sequential: +detailWaterbill?.utilitiesDetail.sequential,
        machineId: +detailWaterbill?.utilitiesDetail.machineId,
        serviceId: +detailWaterbill?.utilitiesDetail.serviceId,
        beforeNumber: +detailWaterbill?.utilitiesDetail.beforeNumber,
        afterNumber: +detailWaterbill?.utilitiesDetail.afterNumber,
        number: +detailWaterbill?.utilitiesDetail?.number,
        amount: +detailWaterbill?.utilitiesDetail?.amount,
        file: "",
        status: "ACTIVE",
      },
      status: "ACTIVE",
    };
    UtilitieApi.update(condition, +detailWaterbill.utilitiesId).then((res) => {
      if (res.status === 200) {
        if (res?.data?.refId && img !== "") {
          let ref = res.data.refId || null;
          const formData = new FormData();
          formData.append("refId", `${ref}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          formData.append("file", img);

          FileuploadApi.uploadfile(formData)
        }
        dispatch(closeModal());
        setSum(0);
        setMeterStartDate(meterstartDate);
        setMeterNumber(0);
        setZoneId("");
        setStaillId("");
        setQrCodeData("");
        setCheck(false);
        loadData();
      }
    });
  };

  const loadData = useCallback(async () => {
    let condition: any = { utilitiesTypeId: 2 };
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search) condition = { ...condition, search: search };
    if (searchZone !== null) condition = { ...condition, zoneId: searchZone };
    if (searchStall !== null) condition = { ...condition, stallId: searchStall };
    if (leasesTypeId) condition = { ...condition, leaseAgreementTypeId: leasesTypeId }
    if (JSON.parse(decode(profile))?.roleId === 4) condition = { ...condition, userId: JSON.parse(decode(profile))?.userId }

    const res = await UtilitieApi.findAll(condition);

    if (res.status === 200) {
      setRowCount(res.headers["total"]);
      setElectricList(res.data);
    } else {
      setRowCount(0);
      setElectricList([]);
    }
  }, [page, pageLimit, search, searchZone, searchStall, leasesTypeId]);

  const loadDataExcel = useCallback(async () => {
    let condition: any = { utilitiesTypeId: 2, export: 1 };
    // if (page) condition = { ...condition, page: page };
    // if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search) condition = { ...condition, search: search };
    if (searchZone !== null) condition = { ...condition, zoneId: searchZone };
    if (searchStall !== null) condition = { ...condition, stallId: searchStall };
    if (leasesTypeId) condition = { ...condition, leaseAgreementTypeId: leasesTypeId }

    const res = await UtilitieApi.findAll(condition);

    if (res.status === 200) {
      setExcelData(res.data);
    } else {
      setExcelData([]);
    }
  }, [page, pageLimit, search, searchZone, searchStall, leasesTypeId]);

  useEffect(() => {
    loadData();
    getZone();
    getWaterType();
  }, [page, pageLimit, search, searchZone, searchStall, leasesTypeId]);

  const handleSubmit = () => {
    const condition = {
      branchId: 1,
      utilitiesTypeId: 2,
      utilitiesDate: moment(meterstartDate).format('YYYY-MM-DD'),
      utilitiesMonth: +moment(Date.now()).format("M"),
      leaseAgreementId: +leaseAgreementId,
      zoneId: zoneId?.zoneId || qrCodeData?.zoneId,
      stallId: stallId?.stallId || qrCodeData?.stallId,
      utilitiesDetail: {
        sequential: 1,
        machineId: stallId?.meterNumber?.find(
          (item: any) => item.meterType === "ELECTRICITY"
        ).meterId || +stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
          (item: any) => item.meterType === "ELECTRICITY"
        ).meterId,
        // machineId: stallId?.meterNumber?.find(
        //   (item: any) => item.meterType === "WATER"
        // ).meterId || +stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
        //   (item: any) => item.meterType === "WATER"
        // ).meterId,
        serviceId: 1,
        beforeNumber: (utilities || 0) || Number(stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
          (item: any) => item.meterType === "ELECTRICITY"
        )?.utilities || 0),
        // beforeNumber: Number(stallId?.meterNumber?.find(
        //   (item: any) => item.meterType === "WATER"
        // )?.utilities || 0) || Number(stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
        //   (item: any) => item.meterType === "WATER"
        // )?.utilities || 0),
        afterNumber: Number(meterNumber),
        number: sum,
        amount:
          Number(sum) > Number(waterType[0]?.electricityUsed)
            ? Number(Number(Number(sum) - Number(waterType[0]?.electricityUsed)) * Number(waterType[0]?.pricePerUnit)) + Number(waterType[0]?.amount)
            // +sum > Number(waterType[0]?.waterUsed)
            //   ? Number(Number(sum) * Number(waterType[0]?.pricePerUnit)) + Number(waterType[0]?.amount)
            : Number(waterType[0]?.amount),
        // amount: +sum ,
        
      },
    };
    if (!img) {
      return notiError('กรุณาเพิ่มไฟล์')
    }
    UtilitieApi.create(condition).then((res) => {
      if (res.status == 201) {
        if (res?.data?.refId && img !== "") {
          let ref = res.data.refId || null;
          const formData = new FormData();
          formData.append("refId", `${ref}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          formData.append("file", img, "image.jpg");

          FileuploadApi.uploadfile(formData)
          dispatch(closeModal());
          setSum(0);
          setMeterStartDate(meterstartDate);
          setMeterNumber(undefined);
          setUtilities(undefined)
          setImg(null)
          setZoneId("");
          setStaillId("");
          setQrCodeData("");
          setCheck(false);
          loadData();
        }
      } else {
        notiError('กรุณาใส่ข้อมูลให้ครบ')
      }
    });
  };

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    {
      id: "date",
      disablePadding: false,
      label: t("ELECTRICT.DETAIL.DOCUMENT_DATE"),
    },
    {
      id: "date",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICT.DETAIL.DOCUMENT_ID"),
    },

    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICT.DETAIL.ZONE"),
    },
    {
      id: "rental",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICT.DETAIL.STALL"),
    },
    {
      id: "enddate",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },
    // {
    //   id: "enddate",
    //   disablePadding: false,
    //   align: "center",
    //   label: t("ELECTRICT.DETAIL.DATE_OVERDUE"),
    // },
    {
      id: "price",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICT.DETAIL.SUM"),
    },

    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICT.DETAIL.ACTION"),
    },
  ];

  const handleClick = (val: any) => {
    setOpen(1);
    dispatch(showModal());
  };
  const handleDetail = (id: number) => {
    UtilitieApi.detail(id).then((res) => {
      if (res.status === 200) {
        setDetailWaterBill(res.data);
        dispatch(showModal());
        setOpen(2);
      }
    });
  };
  const handleEdit = (id: number) => {
    UtilitieApi.detail(id).then((res) => {
      if (res.status === 200) {
        setDetailWaterBill(res.data);
        dispatch(showModal());
        setOpen(3);
      }
    });
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "jpeg"
      ) {
        setImg(acceptedFiles[0]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [img]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onDelete = (data: any) => {

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('ELECTRICT.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('ELECTRICT.ALERT.CONFIRM_DELETE')}</p>`,
      (res: any) => {
        if (res) {
          UtilitieApi.delete(data?.utilitiesId)
            .then((res) => {
              if(res.status < 300){
                loadData()
                notiSuccess(t('ELECTRICT.MESSAGE.SUCCESS.DELETE'), '', null, null)
              } else {
                notiError(t('ELECTRICT.MESSAGE.ERROR.DELETE'), '', null, null)
              }
              
            })
            .catch((e) => {
              notiError(t('ELECTRICT.MESSAGE.ERROR.DELETE'), '', null, null)
            })
        }
      }
    )
  }

  const renderData = (objData: any, index: any) => {
    const no = page * pageLimit - pageLimit + index + 1;

    const {
      utilitiesId,
      utilitiesDate,
      utilitiesNo,
      utilitiesDetail,
      stall,
      zone,
      createDate,
    } = objData;

    const objRenderData = {
      key: utilitiesId,
      id: utilitiesId,
      obj: objData,

      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
        },
        {
          option: "TEXT",
          align: "left",
          label: moment(createDate).format("DD/MM/YYYY"),
        },
        {
          option: "TEXT",
          align: "center",

          label: utilitiesNo,
        },
        {
          option: "TEXT",
          align: "center",
          label: zone?.zoneName || "-",
          // label: stall?.map((e: any) => e?.zone?.zoneName) || "-",
        },

        {
          option: "TEXT",
          align: "center",
          label: stall?.stallName || "-",
          // label: stall?.map((e: any) => e?.stallName) || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: stall?.stallNumber || "-",
          // label: stall?.map((e: any) => e?.stallName) || "-",
        },

        // {
        //   option: "TEXT",
        //   align: "center",
        //   style: { color: colors.red },
        //   label: moment(utilitiesDate).format("DD/MM/YYYY"),
        // },

        {
          option: "PRICE",
          align: "center",
          label: utilitiesDetail?.amount,
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              {JSON.parse(decode(profile))?.roleId === 4 ? '' :
                <Tooltip title="พิมพ์เอกสาร">
                  <IconButton onClick={() => {
                    getTestPdfData(utilitiesId)
                  }}>
                    <FontAwesomeIcon
                      icon={faPrint}
                      style={{
                        fontSize: "20px",
                        width: "22px",
                        color: colors.themeMainColor
                      }}
                    />
                  </IconButton>
                </Tooltip>
                // <div className="pr-3">
                //   <FontAwesomeIcon
                //     icon={faPrint}
                //     style={{
                //       cursor: "pointer"
                //       , color: colors.themeMainColor
                //     }}
                //     onClick={() => getTestPdfData(utilitiesId)}
                //   />
                // </div>
              }
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  handleDetail(utilitiesId)
                  // getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => handleDetail(utilitiesId)}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{
                    cursor: JSON.parse(decode(profile))?.roleId === 4 ? "none" : "pointer"
                    , color: JSON.parse(decode(profile))?.roleId === 4 ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => {
                    if (JSON.parse(decode(profile))?.roleId !== 4) handleEdit(utilitiesId)
                  }
                  }

                />
              </div> */}
              {JSON.parse(decode(profile))?.roleId === 4 ? '' :
                <Tooltip title="ลบรายการ">
                  <IconButton disabled={JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay} onClick={() => {
                    onDelete(objData)
                  }}>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{
                        fontSize: "20px",
                        width: "22px",
                        color: (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) ? colors.gray : colors.red
                      }}
                    />
                  </IconButton>
                </Tooltip>
                // <div className="pr-3">
                //   <FontAwesomeIcon
                //     icon={faTrashAlt}
                //     style={{
                //       cursor: (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) ? "auto" : "pointer"
                //       , color: (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) ? colors.gray : colors.red
                //     }}
                //     onClick={() => {
                //       if (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) { } else { onDelete(objData) }
                //     }
                //     }

                //   />
                // </div>
              }
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };
  const handlePrint = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () =>
      `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`,
  });

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  const handleCapture = (dataUri: any) => {
    const binaryImageData = convertToBinary(dataUri, 'captured.jpg');
    setImg(binaryImageData);
  };

  const convertToBinary = (dataUri: any, fileName: string) => {
    const base64Data = dataUri.replace('data:image/jpeg;base64,', '');
    const binaryData = atob(base64Data);
    const byteArray = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    const file = new File([blob], fileName, { type: 'image/jpeg' });

    return file;
  };

  useEffect(() => {
    if (qrCodeData !== "") {
      setLeaseAgreementId(qrCodeData?.leaseAgreementId)
      setZoneId(qrCodeData?.zoneId)
      setStall(zone?.find((item: any) => item?.zoneId === qrCodeData?.zoneId)?.stall)
      setStaillId(qrCodeData?.stallId)
      setOpen(1)
      ref?.current?.stopCamera()
    }
  }, [qrCodeData]);

  const downloadExcel = () => {
    let p = document.getElementById("excel-download");
    excel({ tableExportRef: p, filename: "ค่าไฟ", sheetName: 'ค่าไฟ' }).onDownload()
  }
  useEffect(() => {
    if (excelData?.length > 0) {
      downloadExcel()
    }
  }, [excelData])
  return (
    <Box>
      <HeaderCard text={t("ELECTRICT.HEADER.ELECTRICITY")} />
      <Boxconteiner>

        <Row className="justify-content-end">
          <Col className="my-2" item lg={2} md={6} sm={12} xs={12}>
            <InputTextField
              placeholder={t("ELECTRICT.HEADER.SEARCH")}
              style={{ marginRight: "24px" }}
              onchange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col className="my-2" item lg={2} md={6} sm={12} xs={12}>
            <InputDatePicker
              placeholder={t("ELECTRICT.HEADER.DATE_TIME")}
              value={searchDate}
              dateFormat="DD/MM/YYYY"
              onClear={() => setSearchDate("")}
              onChange={(e) => setSearchDate(e)}
            />
          </Col>

          <Col className="my-2" item lg={2} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              options={zone}
              value={zone
                ? zone?.find(
                  (data: any) => data?.zoneId === searchZone
                )
                : null}
              placeholder={t("ELECTRICT.HEADER.SEARCH_ZONE")}
              getOptionLabel={(option: any) => option?.zoneName}
              labelId="contracttype"
              onChange={(event, newValue) => {
                setSearchZone(newValue?.zoneId);
                setStall(newValue?.stall);
              }}
            />
          </Col>

          <Col className="my-2" item lg={2} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              options={stall || []}
              value={stall
                ? stall?.find(
                  (data: any) => data?.stallId === searchStall
                )
                : null}
              placeholder={t("ELECTRICT.HEADER.SEARCH_STALL")}
              getOptionLabel={(option: any) => option.stallName}
              labelId="contracttype"
              onChange={(event, newValue) => {
                setSearchStall(newValue?.stallId);
              }}
            />
          </Col>
          {isUser ? (
            <Col lg={2} md={6} sm={12} xs={12}>

            </Col>
          ) : (
            <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
              <AutocompleteSelect
                options={leasesTypeData || []}
                value={leasesTypeData
                  ? leasesTypeData?.find(
                    (data: any) => data?.leasesTypeId === leasesTypeId
                  )
                  : null}
                onInputChange={(_, value) => {
                  debouncedApiCall({ searchItem: value, setItem: setKeywordLeasesTypeData })
                }}
                placeholder={t("ประเภทผู้ใช้งาน")}
                getOptionLabel={(option: any) => option?.leasesTypeNameTh || "-"}
                labelId="leasesType"
                onChange={(_, newValue) => {
                  setLeasesTypeId(newValue?.leasesTypeId);
                }}
              />
            </Col>
          )}
          <Col className="my-2 " item lg={2} md={6} sm={12} xs={12}>
            {JSON.parse(decode(profile))?.roleId !== 4 && <ButtonCustom
              onClick={() => {
                loadDataExcel()
                // downloadExcel()
              }}
              textButton={t("Excel")}
              style={{
                width: "100%",
                '&.MuiButton-contained': {
                  // backgroundColor:  `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                  backgroundColor: `${colors.lightOrange} !important`,
                  ':hover': {
                    // backgroundColor: `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                    backgroundColor: `${colors.lightOrange} !important`,
                  }
                }
              }}
              // startIcon={<img src={icons.print} />}
              startIcon={<FontAwesomeIcon icon={faFileExcel} />}
              disabled={permissions?.isCreate.disabled || !utilitiesDay}
            />}
          </Col>
          {JSON.parse(decode(profile))?.roleId !== 4 && <Col className="my-2 " item lg={2} md={6} sm={12} xs={12}>
            <ButtonCustom
              onClick={handleClick}
              textButton={t("ELECTRICT.HEADER.ISSUE_ELECTRICITY")}
              style={{ width: "100%" }}
              endIcon={<img src={icons.add}
              />}
              disabled={permissions?.isCreate.disabled || !utilitiesDay}
            />
          </Col>}

        </Row>

        <TableCustom
          headCells={headCells}
          page={page}
          pageLimit={pageLimit}
          sortType={"ASC"}
          sortBy=""
          rowCount={rowCount}
          rowsData={electricList && electricList?.map((d: any, index: any) => {
            return renderData(d, index);
          })}
          onSort={() => { }}
          setPageLimit={(value: number) => { setPageLimit(value) }}
          setPage={(value: number) => { setPage(value) }}
          tableMinWidth={1200}
        />
      </Boxconteiner>

      {open == 1 && (
        <ModalCustom
          title={t("ELECTRICT.MODAL.INVOICE_ELECTRICT")}
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          onClose={() => {
            setImg(null)
            setZoneId(null)
            setStaillId(null)
            setSum(0)
            setMeterNumber(undefined);
            setUtilities(undefined)
          }}
          component={
            <Box sx={{ marginTop: "19px" }}>
              <Container maxWidth="xl">
                <Box>
                  <Row>
                    <Col xs={12} className="mb-2">
                      <div className="d-flex justify-content-center">
                        <ButtonCustom
                          textButton="สแกน QR code"
                          onClick={() => {
                            setOpen(5)
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={2} sm={5} xs={5} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} :</Typography>
                    </Col>
                    <Col xl={4} sm={7} xs={7} className="my-3">
                      <Typography>{moment(meterstartDate).format("DD/MM/YYYY")}</Typography>
                    </Col>
                    <Col xl={3} sm={5} xs={5} className="my-3">
                      <Typography>{t("บันทึกของเดือน")} :</Typography>
                    </Col>
                    <Col xl={3} sm={7} xs={7} className="my-3">
                      <Typography>{shortnessDatemonth(dateModal)}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>
                        {t("ELECTRICT.MODAL.ZONE_STALL")} :
                      </Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={zone}
                        value={zoneId || null}
                        getOptionLabel={(option: any) => option.zoneName || qrCodeData?.zoneName}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setZoneId(newValue);
                          setStall(newValue?.stall);
                          setStaillId("");
                          setUtilities(undefined)
                          setSum(0)
                          setMeterNumber(undefined)
                        }}
                      />
                    </Col>
                    <Col xl={2} xs={5} className="my-3">
                      <Typography>{t("ELECTRICT.MODAL.STALL")} :</Typography>
                    </Col>
                    <Col xl={4} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={stall || []}
                        value={stallId || null}
                        getOptionLabel={(option: any) => `${option.stallNumber} (${option.stallName})` || `${qrCodeData.stallNumber} (${qrCodeData.stallName})`}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setStaillId(newValue);
                          setLeaseAgreementId(newValue?.leaseAgreementId);
                          setUtilities(newValue?.meterNumber?.find(
                            (item: any) => item.meterType === "ELECTRICITY"
                          )?.utilities?.utilitiesDetail?.afterNumber || undefined)
                          setSum(0)
                          setMeterNumber(undefined)
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.METER_NO")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <Typography>
                        {stallId?.meterNumber
                          ?.filter((item: any) => item.meterType === "WATER")
                          .map((e: any) => e?.meterNumber)}{" "}
                      </Typography>
                    </Col>

                    <Col sm={12}>
                      {/* <div style={{width:'100%', border:'1px solid #CBCFD6',margin:'30px'}}/> */}
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={12} sm={12}>
                      <Typography sx={{ fontWeight: "500" }}>
                        {t("ELECTRICT.MODAL.DETAIL_ELECTRICT")}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.LAST_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {/* {stallId?.meterNumber?.find(
                          (item: any) => item.meterType === "ELECTRICITY"
                        )?.utilities || 0} */}
                        <InputTextField
                          onchange={(e: any) => {
                            setUtilities(e.target.value);
                          }}
                          onkeypress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={stallId?.meterNumber?.find(
                            (item: any) => item.meterType === "ELECTRICITY"
                          )?.utilities}
                          value={utilities || ''}
                        />
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.UNIT_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <InputTextField
                        onchange={(e) => {
                          setMeterNumber(e.target.value);
                          setSum(
                            Number(e.target.value) -
                            +utilities || Number(e.target.value) - stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
                              (item: any) => item.meterType === "ELECTRICITY"
                            ).utilities || 0
                          );
                        }}
                        onkeypress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={meterNumber || ''}
                      />
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.USE_SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>{sum || 0}</Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("DETAIL.SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      {sum && (
                        <Typography>
                          {Number(sum) > Number(waterType[0]?.electricityUsed)
                            ? Number(Number(Number(sum) - Number(waterType[0]?.electricityUsed)) * Number(waterType[0]?.pricePerUnit)) + Number(waterType[0]?.amount)
                            // {+sum > +waterType[0]?.electricityUsed
                            //   ? Number(Number(sum) * Number(waterType[0]?.pricePerUnit)) + Number(waterType[0]?.amount)
                            : Number(waterType[0]?.amount) || 0}
                        </Typography>
                      )}

                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.BATH")}</Typography>
                    </Col>

                  </Row>

                  <Boxupload>
                    <BoxTakePhoto>
                      <Boxcol onClick={() => setOpen(4)}>
                        <FontAwesomeIcon
                          icon={faCamera}
                          style={{
                            height: '50px', width: '50px'
                          }}
                        />
                        <Typography
                          sx={{ marginTop: "24px", fontSize: "18px" }}
                        >
                          ถ่ายรูป
                        </Typography>
                      </Boxcol>
                    </BoxTakePhoto>
                    <Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {img ? (
                          <Boxcol>
                            <img src={URL.createObjectURL(img)} width={250} height={250} alt="" />
                          </Boxcol>
                        ) : (
                          <Boxcol>
                            <Cloudupload />
                            <Typography
                              sx={{ marginTop: "24px", fontSize: "18px" }}
                            >
                              แนบไฟล์เอกสาร JPEG,JPG, PNG
                            </Typography>
                          </Boxcol>
                        )}
                      </div>
                    </Boxdash>
                  </Boxupload>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <InputCheckbox
                      onChange={(e) => setCheck(e.target.checked)}
                    />
                    <Typography sx={{ marginLeft: "12px" }}>
                      {t("MODAL.CHECK_INFO")}
                    </Typography>
                  </Box>
                  <ButtonCustom
                    textButton="บันทีก/เสร็จสิ้น"
                    disabled={check ? false : true}
                    btnStyle={{ width: "100%" }}
                    onClick={handleSubmit}
                  />
                </Box>
              </Container>
            </Box>
          }
        />
      )}
      {open == 2 && (
        <ModalCustom
          title={t("ELECTRICT.MODAL.INVOICE_ELECTRICT")}
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          component={
            <Box sx={{ marginTop: "19px" }}>
              <Container maxWidth="xl">
                <Box sx={{ marginBottom: "24px" }}>
                  <Typographymodal>{t("MODAL.DETAIL_STALL")}</Typographymodal>
                </Box>
                <Box>
                  <Row>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.ZONE_STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={zone}
                        value={
                          zone.find(
                            (e: any) => e.zoneId === detailWaterbill?.zoneId
                          ) || null
                        }
                        getOptionLabel={(option: any) => option.zoneName}
                        labelId="contracttype"
                        disabled={true}
                        onChange={(event, newValue) => {
                          setZoneId(newValue);
                          setStall(newValue?.stall);
                          setStaillId("");
                        }}
                      />
                    </Col>
                    <Col xl={2} xs={5} className="my-3">
                      <Typography>{t("DETAIL.STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={detailWaterbill?.stall || []}
                        value={
                          detailWaterbill?.stall || null
                          // detailWaterbill?.stall?.find(
                          //   (e: any) => e.stallId === detailWaterbill?.stallId
                          // ) || null
                        }
                        getOptionLabel={(option: any) => option.stallName}
                        disabled
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setStaillId(newValue);
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.METER_NO")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <Typography>{detailWaterbill?.utilitiesNo}</Typography>
                    </Col>

                    <Col sm={12}>
                      {/* <div style={{width:'100%', border:'1px solid #CBCFD6',margin:'30px'}}/> */}
                      <Divider className="mx-0 my-4" />
                    </Col>

                    {/* <Col lg={12} md={12} xs={12} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} </Typography>
                    </Col> */}
                    <Col xl={2} sm={5} xs={5} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} :</Typography>
                    </Col>
                    <Col xl={4} sm={7} xs={7} className="my-3">
                      <Typography>
                        {moment(detailWaterbill?.utilitiesDate).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Col>
                    <Col xl={3} sm={5} xs={5} className="my-3">
                      <Typography>{t("บันทึกของเดือน")} :</Typography>
                    </Col>
                    <Col xl={3} sm={7} xs={7} className="my-3">
                      <Typography>
                        {shortnessDatemonth(detailWaterbill?.utilitiesDate)}
                      </Typography>
                    </Col>
                    <Col className="my-3" xl={2} sm={5} xs={5} >
                      {t("ผู้จดบันทึก")} :
                    </Col>
                    <Col item xl={4} sm={7} xs={7} className="my-3">
                      <Typography>{`${detailWaterbill?.createdBy?.firstname} ${detailWaterbill?.createdBy?.lastname}` || "-"}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={12} sm={12}>
                      <Typography sx={{ fontWeight: "500" }}>
                        {t("ELECTRICT.MODAL.DETAIL_ELECTRICT")}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.LAST_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(
                          detailWaterbill?.utilitiesDetail?.beforeNumber
                        )}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.UNIT_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(
                          detailWaterbill?.utilitiesDetail?.afterNumber
                        )}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.USE_SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(detailWaterbill?.utilitiesDetail?.number)}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("DETAIL.SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {detailWaterbill?.utilitiesDetail?.amount}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.BATH")}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                  </Row>
                  {detailWaterbill?.utilitiesDetail?.fileUpload && (
                    <Boxupload>
                      <Boxdash>
                        <Boxcol>
                          <img src={process.env.REACT_APP_FILE_URL + detailWaterbill?.utilitiesDetail?.fileUpload?.filePath
                            + detailWaterbill?.utilitiesDetail?.fileUpload?.fileName
                          } width={250} height={250} alt="" />
                        </Boxcol>
                      </Boxdash>
                    </Boxupload>
                  )}
                </Box>
              </Container>
            </Box>
          }
        />
      )}
      {open == 3 && (
        <ModalCustom
          title={t("ELECTRICT.MODAL.INVOICE_ELECTRICT")}
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          component={
            <Box sx={{ marginTop: "19px" }}>
              <Container maxWidth="xl">
                <Box sx={{ marginBottom: "24px" }}>
                  <Typographymodal>{t("MODAL.DETAIL_STALL")}</Typographymodal>
                </Box>
                <Box>
                  <Row>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.ZONE_STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={zone}
                        value={
                          zone.find(
                            (e: any) => e.zoneId === detailWaterbill?.zoneId
                          ) || null
                        }
                        getOptionLabel={(option: any) => option.zoneName}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setZoneId(newValue);
                          setStall(newValue?.stall);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            zoneId: newValue?.zoneId,
                            stall: newValue?.stall,
                          });

                          setStaillId("");
                        }}
                      />
                    </Col>
                    <Col xl={2} xs={5} className="my-3">
                      <Typography>{t("DETAIL.STALL")}</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={stall || []}
                        value={
                          detailWaterbill?.stall?.find(
                            (e: any) => e.stallId === detailWaterbill?.stallId
                          ) || null
                        }
                        getOptionLabel={(option: any) => option.stallName}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setStaillId(newValue);

                          setDetailWaterBill({
                            ...detailWaterbill,
                            stallId: newValue?.stallId,
                            leaseAgreementId: newValue?.leaseAgreementId,
                            utilitiesNo: newValue?.meterNumber[1]?.meterNumber,
                          });
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.METER_NO")}</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <Typography>{detailWaterbill?.utilitiesNo}</Typography>
                    </Col>

                    <Col sm={12}>
                      {/* <div style={{width:'100%', border:'1px solid #CBCFD6',margin:'30px'}}/> */}
                      <Divider className="mx-0 my-4" />
                    </Col>

                    <Col lg={12} md={12} xs={12} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} </Typography>
                    </Col>
                    <Col xl={2} sm={5} xs={5} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")}</Typography>
                    </Col>
                    <Col xl={4} sm={7} xs={7} className="my-3">
                      <InputDatePicker
                        dateFormat="DD/MM/YY"
                        key={"date-appointment"}
                        label=""
                        value={detailWaterbill?.utilitiesDate}
                        onClear={() => setMeterStartDate("")}
                        onChange={(e: any) => {
                          setMeterStartDate(moment(e).format("YYYY-MM-DD"));
                          setDateModal(e);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            utilitiesDate: moment(e).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    </Col>
                    <Col xl={3} sm={5} xs={5} className="my-3">
                      <Typography>{t("บันทึกของเดือน")}</Typography>
                    </Col>
                    <Col xl={3} sm={7} xs={7} className="my-3">
                      <Typography>
                        {shortnessDatemonth(detailWaterbill?.utilitiesDate)}
                      </Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={12} sm={12}>
                      <Typography>
                        {t("ELECTRICT.MODAL.DETAIL_ELECTRICT")}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.LAST_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(
                          detailWaterbill?.utilitiesDetail?.beforeNumber
                        )}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.UNIT_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <InputTextField
                        value={Math.floor(
                          detailWaterbill?.utilitiesDetail?.afterNumber
                        )}
                        onchange={(e) => {
                          setMeterNumber(e.target.value);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            utilitiesDetail: {
                              ...detailWaterbill.utilitiesDetail,

                              afterNumber: e.target.value,
                              number:
                                +e.target.value -
                                +Math.floor(
                                  detailWaterbill?.utilitiesDetail?.beforeNumber
                                ),
                              amount:
                                +e.target.value -
                                  +Math.floor(
                                    detailWaterbill?.utilitiesDetail
                                      ?.beforeNumber
                                  ) >
                                  +waterType[0].electricityUsed
                                  ? (+e.target.value -
                                    +Math.floor(
                                      detailWaterbill?.utilitiesDetail
                                        ?.beforeNumber
                                    )) *
                                  +waterType[0].pricePerUnit +
                                  +waterType[0].amount
                                  : +waterType[0].amount,
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.USE_SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(detailWaterbill?.utilitiesDetail?.number)}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("DETAIL.SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(detailWaterbill?.utilitiesDetail?.amount)}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.BATH")}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                  </Row>
                  <Boxupload>
                    <Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {img ? (
                          <Boxcol>
                            <img src={img} width={250} height={250} />
                          </Boxcol>
                        ) : (
                          <Boxcol>
                            <Cloudupload />
                            <Typography
                              sx={{ marginTop: "24px", fontSize: "18px" }}
                            >
                              แนบไฟล์เอกสาร PDF,JPG, PNG
                            </Typography>
                          </Boxcol>
                        )}
                      </div>
                    </Boxdash>
                  </Boxupload>
                  <ButtonCustom
                    textButton="บันทีก/เสร็จสิ้น"
                    onClick={handleSubmitedit}
                    btnStyle={{ width: "100%", marginTop: "24px" }}
                  />
                </Box>
              </Container>
              <div className="print-show" ref={componentRef}>
                <ExportData
                  headCells={""}
                  componant={
                    <>
                      <div className="pt-3 text-align-center ">
                        <>
                          <div className="row mt-4 ">
                            <div className="col-12 d-flex flex-column align-items-center ">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("MODAL.DETAIL_STALL")}
                              </p>
                            </div>
                            <div className="col-3 d-flex mt-4  justify-content-center ">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("MODAL.ZONE_STALL")}
                              </p>
                            </div>
                            <div className="col-3 d-flex  mt-4  justify-content-center ">
                              <p style={{ fontSize: 16 }}>{print?.utilitiesNo}</p>
                            </div>
                            <div className="col-3 d-flex mt-4  justify-content-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("DETAIL.STALL")}
                              </p>
                            </div>
                            <div className="col-3 d-flex mt-4  justify-content-center">
                              <p style={{ fontSize: 16 }}>{t("DETAIL.STALL")}</p>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 d-flex justify-content-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("MODAL.METER_NO")}
                              </p>
                            </div>
                            <div className="col-3 d-flex  justify-content-center ">
                              <p style={{ fontSize: 16 }}>{t("ADMIN.PRINT.NAME")}</p>
                            </div>

                            <div className="col-12 d-flex justify-content-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("MODAL.SUMMIT_DATE")}
                              </p>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 d-flex  justify-content-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("MODAL.SUMMIT_DATE")}
                              </p>
                            </div>
                            <div className="col-3 d-flex justify-content-center ">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("ADMIN.PRINT.BIRTH_DAY")}
                              </p>
                            </div>
                            <div className="col-3 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("บันทึกของเดือน")}
                              </p>
                            </div>
                            <div className="col-3 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("บันทึกของเดือน")}
                              </p>
                            </div>
                            <div className="col-12 mt-2 d-flex justify-content-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("ข้อมูลการใช้น้ำ")}
                              </p>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("เลขครั้งก่อน")}
                              </p>
                              <div className="">{print?.mobilePhone}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("ADMIN.PRINT.EMAIL")}
                              </p>
                              <div className="">{print?.email}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("หน่วย")}
                              </p>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("เลขหลังหน่วย")}
                              </p>
                              <div className="">{print?.mobilePhone}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("ADMIN.PRINT.EMAIL")}
                              </p>
                              <div className="">{print?.email}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("หน่วย")}
                              </p>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("จำนวนที่ใช้")}
                              </p>
                              <div className="">{print?.mobilePhone}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("ADMIN.PRINT.EMAIL")}
                              </p>
                              <div className="">{print?.email}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("หน่วย")}
                              </p>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("จำนวนเงิน")}
                              </p>
                              <div className="">{print?.mobilePhone}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("ADMIN.PRINT.EMAIL")}
                              </p>
                              <div className="">{print?.email}</div>
                            </div>
                            <div className="col-4 d-flex flex-column align-items-center">
                              <p style={{ fontSize: 16, fontWeight: 700 }}>
                                {t("หน่วย")}
                              </p>
                            </div>
                          </div>
                          {/* <div className="row d-flex justify-content-end">
                    <img
                      src={print?.signature}
                      alt=""
                      style={{ width: 200, height: 200 }}
                    />
                  </div> */}
                        </>
                      </div>
                    </>
                  }
                />
              </div>
            </Box>
          }
        />
      )}
      {open == 4 && (
        <ModalCustom
          title=""
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          onClose={() => {
            setOpen(1)
            dispatch(showModal())
          }}
          component={
            <Box >
              <ButtonCustom
                textButton="สลับกล้อง"
                onClick={() => {
                  cameraRef.current.switchCamera();
                }}

                btnStyle={{ width: "100%" }}
              />
              <Camera ref={cameraRef}
                errorMessages={{}}
                facingMode='environment'
                aspectRatio={1 / 1}
              />
              <div className="mt-2 w-100 d-flex justify-content-center">
                <ButtonCustom
                  textButton="ถ่ายรูป"
                  onClick={() => handleCapture(cameraRef?.current?.takePhoto())}
                  btnStyle={{ width: "100%" }}
                />
              </div>
            </Box>
          }
        />
      )}

      {open == 5 && (
        <ModalCustom
          title=""
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          onClose={() => {
            setOpen(1)
            dispatch(showModal())
          }}
          component={
            <Box>
              <QrReader
                onResult={(result: any) => {
                  if (result) {
                    setQrCodeData(JSON.parse(result?.text))
                    ref?.current?.stopCamera()
                    setOpen(1)
                  }
                }}
                containerStyle={{ width: '100%' }}
                constraints={{ facingMode: 'environment' }} />
            </Box>
          }
        />
      )}
      {loading && <Loading show={loading} type="fullLoading" />}
      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={""}
          componant={
            <>
              <div className="pt-3 text-align-center ">
                <>
                  <div className="row mt-4 ">
                    <div className="col-12 d-flex flex-column align-items-center ">
                      <p style={{ fontSize: 18, fontWeight: 700 }}>
                        {t("ใบแจ้งค่าไฟ")}
                      </p>
                    </div>
                    <div className="col-12 d-flex flex-column align-items-center ">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.DETAIL_STALL")}
                      </p>
                    </div>
                    <div className="col-3 d-flex mt-4  justify-content-center ">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.ZONE_STALL")}
                      </p>
                    </div>
                    <div className="col-3 d-flex  mt-4  justify-content-center ">
                      <p style={{ fontSize: 16 }}>{print?.zone?.zoneName || '-'}</p>
                    </div>
                    <div className="col-3 d-flex mt-4  justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("DETAIL.STALL")}
                      </p>
                    </div>
                    <div className="col-3 d-flex mt-4  justify-content-center">
                      <p style={{ fontSize: 16 }}>{print?.stall?.stallName || '-'}</p>

                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-3 d-flex justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.METER_NO")}
                      </p>
                    </div>
                    <div className="col-3 d-flex  justify-content-center ">
                      <p style={{ fontSize: 16 }}>{print?.utilitiesNo}</p>
                    </div>


                  </div>

                  <div className="row mt-4">
                    <div className="col-3 d-flex  justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.SUMMIT_DATE")}
                      </p>
                    </div>
                    <div className="col-3 d-flex justify-content-center ">
                      <p style={{ fontSize: 16 }}>
                        {moment(print?.utilitiesDate).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("บันทึกของเดือน")}
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {shortnessDatemonth(print?.utilitiesDate)}
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("สร้างโดย")} :
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {`${print?.createdBy?.firstname} ${print?.createdBy?.lastname}` || "-"}
                      </p>
                    </div>

                    <div className="col-12 mt-2 d-flex justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("ข้อมูลการใช้ไฟ")}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("เลขครั้งก่อน")}
                      </p>

                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {Math.floor(
                          print?.utilitiesDetail?.beforeNumber
                        )}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("เลขหลังหน่วย")}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {Math.floor(
                          print?.utilitiesDetail?.afterNumber
                        )}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("จำนวนที่ใช้")}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {Math.floor(print?.utilitiesDetail?.number)}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("จำนวนเงิน")}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {print?.utilitiesDetail?.amount}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  {/* <div className="row d-flex justify-content-end">
                    <img
                      src={print?.signature}
                      alt=""
                      style={{ width: 200, height: 200 }}
                    />
                  </div> */}
                </>
              </div>
            </>
          }
        />
      </div>
      {excelData && <table className="print-show" id="excel-download">
        <tr><th colSpan={10} align="center">รายการค่าไฟ</th></tr>
        <tr>
          <th>#</th>
          <th>วันที่เอกสาร</th>
          <th>เลขที่เอกสาร</th>
          <th>โซน</th>
          <th>แผง</th>
          <th>สร้างโดย</th>
          <th>เลขครั้งก่อน</th>
          <th>เลขหลังหน่วย</th>
          <th>จำนวนที่ใช้</th>
          <th>จำนวนเงิน</th>
        </tr>
        {excelData?.map((item: any, index: number) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{moment(item?.createDate).format("DD/MM/YYYY")}</td>
              <td>{item?.utilitiesNo}</td>
              <td>{item?.zone?.zoneName}</td>
              <td>{item?.stall?.stallName}</td>
              <td>{`${item?.createdBy?.firstname} ${item?.createdBy?.lastname}`}</td>
              <td>{item?.utilitiesDetail?.beforeNumber}</td>
              <td>{item?.utilitiesDetail?.number}</td>
              <td>{item?.utilitiesDetail?.afterNumber}</td>
              <td>{item?.utilitiesDetail?.amount}</td>
            </tr>
          )
        })}
      </table>}
    </Box>
  );
}
export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
