import { menuSeconds } from "../../constants/menu"
import CalculateIncome from "../../pages/calculation/calculatIncome"

const routeCalculation = [
    {
      ...menuSeconds.CALCULATION,
      key: menuSeconds.CALCULATION.key,
      name: menuSeconds.CALCULATION.name,
      textName: menuSeconds.CALCULATION.textName,
      active: false,
      appBar: true,
      component: CalculateIncome
    }
  ]
  
  export default routeCalculation