import { Box, Button, Container, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BoxContainer, } from "./repairStyle";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import BuildIcon from '@mui/icons-material/Build';

import { colors } from "../../constants/colors";

import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../../app/slice/modal.slice";

import { Col, Dropdown, Row } from "react-bootstrap";
import TableRowCommon from "../../component/table/TableRowCommon";
import HeaderCard from "../../component/cardCustom/headerCard";
import InputTextField from "../../component/input/inputTextField";
import AutocompleteSelect from "../../component/select/autoCompleteSelect";
import TableCustom from "../../component/table/tableCustom";
import ModalCustom from "../../component/modalCustom/modalCustom";
import Cloudupload from "../../assets/icon/cloudupload";
import ButtonCustom from "../../component/button/buttonCustom";
import { useHistory } from "react-router-dom";
import RepairApi from "../../api/repair/repair.api";
import { getProfile } from "../../utils/app.utils";
import ZoneApi from "../../api/zone/zone.api";
import { ExportProps } from "../../constants/reportConstant";
import Loading from "../../component/loading/loading";
import { useFunctionPrint } from "../../component/Print/print";
import { decode } from 'js-base64';
import { dateToView } from "../../utils/date.utils";
import { BoxText, Boxdash, Boxfilearray, Boxinsite, Boxupload } from "./add/addRepairStyle";
import InputDatePicker from "../../component/input/inputDatePicker";
import moment from "moment";
import { notiError, notiSuccess } from "../../component/notification/notifications";
import { useDropzone } from "react-dropzone";
import FileuploadApi from "../../api/uploadfile/upload.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileCirclePlus, faPrint } from "@fortawesome/free-solid-svg-icons";
import InputCheckbox from "../../component/input/inputCheckbox";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { isApporve, isCreate, isDelete, isExport, isUpdate } from "../../utils/permission.utils";


const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isExport: isExport(),
  isApporve: isApporve()
};

export default function Repair() {
  const printRef = useRef<HTMLDivElement>(null);
  const branchId = localStorage.getItem('BRANCH') || null;
  const [file, setFile] = useState<any>([]);
  const [Repair, setRepair] = useState<any>([]);
  const [zoneData, setZoneData] = useState<any[]>([]);
  const [stallData, setStallData] = useState<any[]>([]);
  const [page, setPage] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(5);
  const [rowCount, setRowCount] = useState<number>();
  const [search, setSearch] = useState<string>("");
  const [stallId, setStallId] = useState<string>("");
  const [zoneId, setZoneId] = useState<string>("");
  const [typePrint, setTypePrint] = useState<string>("")
  const [printData, setPrintData] = useState<string>("")
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const profile: any = JSON.parse(getProfile() || "{}");
  const [dataById, setDataById] = useState<any>()
  const [select, setSelectfile] = useState<any>();
  const [adminSelect, setAdminSelectfile] = useState<any>();
  const [appointmentDate, setAppointmentDate] = useState<string>("")
  const [repairDate, setRepairDate] = useState<string>("")
  const [typeAction, setTypeAction] = useState<string>("")
  const [reason, setReason] = useState<string>("");
  const [remark, setRemark] = useState<string>("");

  const [sortBy, setSortBy] = useState<string>("createDate");
  const [sortType, setSortType] = useState<string>("DESC")

  const handleRequestSort = (sortBy: string, sortType: string) => {
    setSortBy(sortBy)
    setSortType(sortType)
  }

  const [loading, setLoading] = useState<boolean>(false);

  const getZone = async () => {
    const condition = {
      page: 1,
      pageLimit: 100,
      status: "CONTRACT",
      skipcalculation: 0
    };
    await ZoneApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setZoneData(res?.data);
      }
    });
  };

  useEffect(() => {
    getZone();
  }, []);

  const loadData = useCallback(async () => {
    setLoading(true)
    let condition: any = {};
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (branchId) condition = { ...condition, branchId: branchId }
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search) condition = { ...condition, search: search };
    if (stallId !== "") condition = { ...condition, stallId: stallId };
    if (zoneId !== "") condition = { ...condition, zoneId: zoneId };
    if (JSON.parse(decode(profile))?.roleId === 4)
      condition = { ...condition, userId: JSON.parse(decode(profile))?.userId };

    await RepairApi.findAll(condition).then((res) => {


      if (res?.status === 200) {
        setRowCount(res?.headers["total"]);
        setRepair(res?.data);
        setLoading(false)

      } else {
        setRowCount(0);
        setRepair([]);
        setLoading(false)
      }
    }).catch((e) => {
      setLoading(false)
      console.log(e)
    })

  }, [sortType, sortBy, page, pageLimit, search, stallId, zoneId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // const onSubmitSuccess = async() => {
  //   const condition :any  =  {
  //     branchId: dataById?.branchId || 1,
  //     repairDate: dataById?.repairDate,
  //     repairTypeId: dataById?.repairTypeId,
  //     title: "แจ้งซ่อม",
  //     description: dataById?.description,
  //     succeedDate: String(moment(Date.now()).format("YYYY-MM-DD")),
  //     zoneCheck: dataById?.zoneCheck,
  //     leaseAgreementId: dataById?.leaseAgreementId,
  //     zoneId: dataById?.zoneId,
  //     stallId: dataById?.stallId,
  //     centralCheck: dataById?.centralCheck,
  //     centralDescription: dataById?.centralDescription
  //   }
  // }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles[0];

      const fileName = files?.name.split(".");
      const detailImage = {
        base64: "",
        filePath: files?.path,
        fileName: files?.name,
        ext: fileName[fileName?.length - 1],
      };
      if (
        detailImage.ext === "png" ||
        detailImage.ext == "jpg" ||
        detailImage.ext == "pdf"
      ) {
        setFile([...file, acceptedFiles[0]]);
      } else {
        notiError(`${t("ANNOUNCE.NOTI.ERROR")}`, "", null, null);
      }
    },
    [file]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const headCells = [
    { id: "no", disablePadding: false, label: "#", align: "center", },
    { id: "date", disablePadding: false, label: t("REPAIR.TABLE.DATE"), align: "center", },
    { id: "date", disablePadding: false, label: t("REPAIR.TABLE.DOCUMENT_ID"), align: "center", },

    {
      id: "zone",
      disablePadding: false,
      align: "center",
      label: t("REPAIR.TABLE.ZONE"),
    },
    {
      id: "rental",
      disablePadding: false,
      align: "center",
      label: t("REPAIR.TABLE.STALL"),
    },
    {
      id: "rental",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },
    {
      id: "typezone",
      disablePadding: false,
      align: "center",
      label: t("REPAIR.TABLE.TYPE_LIST"),
    },
    {
      id: "dateend",
      disablePadding: false,
      align: "center",
      label: t("REPAIR.TABLE.COMPLETION_DATE"),
    },
    {
      id: "main-center",
      disablePadding: false,
      align: "center",
      label: t("ส่วนกลาง"),
    },

    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("REPAIR.TABLE.STATUS"),
    },
    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("REPAIR.TABLE.ACTION"),
    },
  ];

  const PrintData = useCallback(async () => {
    try {
      setTypePrint("PRINT")
      let condition: any = {}
      if (page) condition = { ...condition, page: page };
      if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
      if (search) condition = { ...condition, search: search };
      if (stallId !== null) condition = { ...condition, stallId: stallId };
      if (zoneId !== null) condition = { ...condition, zoneId: zoneId };
      if (JSON.parse(decode(profile))?.roleId === 4)
        condition = { ...condition, userId: JSON.parse(decode(profile))?.userId };

      const resRepair = await RepairApi.findAll(condition);

      const [testResult] = await Promise.all([
        resRepair,
      ]);

      setPrintData(testResult.data);
    } catch (err: any) {

    } finally {
      setLoading(false);
    }

  }, [RepairApi])

  const handlePrintdataAppointment = useFunctionPrint({
    content: () => printRef.current,
    pageStyle: () => `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
  });

  useEffect(() => {
    if (printData && typePrint === "PRINT") {
      handlePrintdataAppointment();
    }
  }, [printData, typePrint])

  const handleClick = (props: any) => {
    handlepreview(props.fileUpload[0])
    if (props?.status === 'SUCESS' && props?.repairApprove?.fileUpload?.length > 0) {
      handlepreviewAdmin(props.repairApprove.fileUpload[0])

    }
    if (props?.repairApprove?.fileUpload?.length > 0) {
      setFile([...props.repairApprove.fileUpload])
    }

    setDataById(props)
    dispatch(showModal())
    setTypeAction("ADD_DOCUMENT")
  };

  const handleReceiveRepair = (props: any) => {
    handlepreview(props.fileUpload[0])
    setDataById(props)
    setTypeAction("RECEIVE_APPROVE")
    dispatch(showModal())
  }

  const onSubmitApprove = async () => {
    const condition: any = {
      repairId: dataById?.repairId,
      approveDate: moment().format('yyyy-MM-DD'),
      sequential: 1,
      refUserId: JSON.parse(decode(profile))?.userId,
      remark: remark ? remark : null
    }

    setLoading(true)
    const res = await RepairApi.approve(condition)
    if (res.status === 201) {
      // const res = await RepairApi.updateStatus(dataById?.repairId, "RECEIVE")
      let ref = res.data.refId || null;
      const uploadBillImg = new FormData();
      uploadBillImg.append("refId", `${ref}`);
      uploadBillImg.append("fileType", "repair-approve");
      file.forEach((data: any, i: any) => {
        uploadBillImg.append(`files[]`, data);
      });
      FileuploadApi.uploadfilearray(uploadBillImg).then((result) => {
        dispatch(closeModal())
        setTypeAction("")
        notiSuccess("รับเรื่องแจ้งซ่อมสำเร็จ")
        setFile([])
        loadData()
      })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setReason("")
          setLoading(false)
        })
    } else {
      notiError("รับเรื่องแจ้งซ่อมไม่สำเร็จ")
    }
  }

  const onsubmitReceive = async () => {
    setLoading(true)
    const body = {
      repairDate: repairDate,
      remark: reason,
      status: 'RECEIVE'
    }
    const res = await RepairApi.update(dataById?.repairId, body)

    if (res.status === 200) {
      notiSuccess("รับเรื่องแจ้งซ่อมสำเร็จ")
      dispatch(closeModal())
      setLoading(false)
      setReason("")
      loadData()
    } else {
      setLoading(false)
      setReason("")
      notiError("รับเรื่องแจ้งซ่อมไม่สำเร็จ")
    }
  }
  const handlepreviewAdmin = (x: any) => {
    if (!x?.fileName && !x?.filePath) {
      const name = x.type.split(".");

      if (name[name?.length - 1] === "application/pdf") {

        window.open(URL.createObjectURL(x), "_blank");
      } else {
        setAdminSelectfile(URL.createObjectURL(x));
      }
    } else {
      if (x?.mimetype === "application/pdf") {

        window.open(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName, "_blank");
      } else {
        setAdminSelectfile(process.env.REACT_APP_FILE_URL +
          x?.filePath +
          x?.fileName);
      }
    }
  };
  const handledeleteAdmin = (x: any) => {
    setFile(file.filter((y: any) => y.path !== x.path));
    setAdminSelectfile(null);
  };

  const renderData = (objData: any, no: any) => {
    no = page * pageLimit - pageLimit + no + 1

    const {
      repairDate,
      repairType,
      zone,
      repairNo,
      stall,
      modifiedDate,
      status,
      centralCheck
    } = objData;

    const setState =
      status === "WAITING"
        ? t("REPAIR.STATUS.WAITING")
        : status === "RECEIVE"
          ? t("REPAIR.STATUS.RECEIVE")
          : JSON.parse(decode(profile))?.roleId === 4 ?
            repairType?.repairTypeId === 5 ? t("REPAIR.STATUS.COMPLETE_REPAIR") : t("REPAIR.STATUS.COMPLETE")
            : t("REPAIR.STATUS.COMPLETE_ADMIN");

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,

      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
        },
        {
          option: "TEXT",
          align: "center",
          label: dateToView(repairDate) || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: repairNo || "-",
        },

        {
          option: "TEXT",
          align: "center",
          label: zone?.zoneName || "-",
        },

        {
          option: "TEXT",
          align: "center",
          label: stall?.stallName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: stall?.stallNumber || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: repairType?.repairTypeName || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: status === "SUCESS" ? dateToView(modifiedDate) || "-" : "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: <InputCheckbox checked={centralCheck === "TRUE" ? true : false} />,
        },
        {
          option: "COMPONENT",
          align: "center",
          label: status,
          component: (
            <Typography
              style={{
                color:
                  status === "WAITING"
                    ? colors.lightOrange
                    : status === "RECEIVE"
                      ? colors.lightOrange
                      : colors.green,
                textAlign: "center",
                whiteSpace: 'nowrap'
              }}
            >
              {setState}

            </Typography>
            // <Button
            //   style={{
            //     backgroundColor:
            //       status === "WAITING"
            //         ? colors.lightOrange
            //         : status === "RECEIVE"
            //           ? colors.lightOrange
            //           : colors.green,
            //     color: colors.white,
            //     margin: "auto",
            //     borderRadius: "4px",
            //     width: "100%",
            //     height: "36px",
            //     whiteSpace: 'nowrap'
            //   }}
            // >
            //   {setState}

            // </Button>
          ),
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                />
              </div>
              {JSON.parse(decode(profile))?.roleId !== 4 && (
                <></>
              )} */}

              {/* <Button
                sx={{
                  width: 40, padding: 0,
                  minWidth: "30px",
                  filter: status === 'WAITING' ? 'grayscale(10%)' : "grayscale(100%)"
                }}
                style={{ border: "none", backgroundColor: 'inherit' }}
                type="button"
                disabled={status === 'WAITING' ? false : true} className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: JSON.parse(decode(profile))?.roleId === 4 ? "auto" : "pointer"
                    , color: JSON.parse(decode(profile))?.roleId === 4 ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => {

                    handleReceiveRepair(objData)
                  }}
                />
              </Button> */}

              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFileCirclePlus}
                  style={{
                    cursor: JSON.parse(decode(profile))?.roleId === 4 ? "auto" : "pointer"
                    , color: JSON.parse(decode(profile))?.roleId === 4 ? colors.gray : colors.themeMainColor
                  }}
                  onClick={() => handleClick(objData)}
                />
              </div> */}
              {JSON.parse(decode(profile))?.roleId === 4 ?
                // <div className="d-flex gap-2">
                <>
                  <Tooltip title="รับเรื่อง">
                    <IconButton
                      onClick={() => handleReceiveRepair(objData)}
                      disabled={status === 'WAITING' ? true : false}
                    >
                      <FontAwesomeIcon style={{
                        fontSize: "20px",
                        width: "22px",
                        color: status === 'WAITING' ? colors.gray : colors.themeMainColor
                      }}
                        icon={faFile} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="ดำเนินการเเล้วเสร็จ">

                    <IconButton
                      onClick={() => handleClick(objData)}
                      disabled={status !== 'SUCESS' ? true : false}
                    >
                      <FontAwesomeIcon style={{
                        fontSize: "20px",
                        width: "22px",
                        color: status !== 'SUCESS' ? colors.gray : colors.themeMainColor
                      }}

                        icon={faFileCirclePlus} />
                    </IconButton>
                  </Tooltip>
                </>
                :
                <>
                  {/* < div className="d-flex gap-2" > */}
                  <Tooltip title="รับเรื่อง">
                    <IconButton
                      onClick={() => handleReceiveRepair(objData)}
                    >
                      <FontAwesomeIcon style={{
                        fontSize: "20px",
                        width: "22px",
                        color: colors.themeMainColor
                      }}
                        icon={faFile} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="ดำเนินการเเล้วเสร็จ">

                    <IconButton
                      onClick={() => handleClick(objData)}
                      disabled={status === 'WAITING' ? true : false}
                    >
                      <FontAwesomeIcon style={{
                        fontSize: "20px",
                        width: "22px",
                        color: status === 'WAITING' ? colors.gray : colors.themeMainColor
                      }}
                        icon={faFileCirclePlus} />
                    </IconButton>
                  </Tooltip>
                </>
              }
            </div >
          ),
        },
      ],
    };
    return <TableRowCommon {...objRenderData} />;
  };

  const handlepreview = (x: any) => {
    if (x?.mimetype === "application/pdf") {

      window.open(process.env.REACT_APP_FILE_URL +
        x?.filePath +
        x?.fileName, "_blank");
    } else {
      setSelectfile(process.env.REACT_APP_FILE_URL +
        x?.filePath +
        x?.fileName);
    }
  };

  return (
    <Box>
      {loading && <Loading show />}
      <HeaderCard text={t("REPAIR.HEADER.REPAIR")} />
      <BoxContainer>
        <Row>
          <Col className="my-2" lg={3} md={12} xs={12}>
            <InputTextField
              value={search}
              placeholder={t("REPAIR.HEADER.SEARCH")}
              onchange={(e) => setSearch(e.target.value)}
            />
          </Col>

          <Col className="my-2" lg={3} md={12} xs={12}>
            <AutocompleteSelect
              options={zoneData || []}
              value={zoneData?.find((e: any) => e.zoneId === zoneId)}
              labelId="search-zone"
              getOptionLabel={(option: any) => option.zoneName}
              placeholder={t("REPAIR.HEADER.SEARCH_ZONE")}
              onChange={(event, newValue) => {
                setZoneId(newValue?.zoneId)
                setStallData(newValue?.stall);
                setStallId("");
                setPage(1)
              }}
            />
          </Col>

          <Col className="my-2" lg={3} md={12} xs={12}>
            <AutocompleteSelect
              placeholder={t("REPAIR.HEADER.SEARCH_STALL")}
              options={stallData || []}
              getOptionLabel={(option: any) => option.stallName}
              value={stallData?.find((e: any) => e?.stallId === stallId)}
              labelId="search-stall"
              onChange={(event, newValue) => {
                setStallId(newValue?.stallId)
              }}
            />
          </Col>
          <Col className="my-2" lg={3} md={12} xs={12}>
            <ButtonCustom
              textButton={t("REPAIR.HEADER.ADD_REPAIR")}
              endIcon={<BuildIcon />}
              style={{
                width: "100%",
                '&.MuiButton-contained': {
                  // backgroundColor:  `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                  backgroundColor: `${colors.red} !important`,
                  ':hover': {
                    // backgroundColor: `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                    backgroundColor: `${colors.red} !important`,
                  }
                }
              }}
              onClick={() => {
                history.push("/repair/add-repair");
              }}
            />
          </Col>
        </Row>

        <TableCustom
          headCells={headCells}
          customScroll
          page={page}
          pageLimit={pageLimit}
          sortType={sortType}
          sortBy={sortBy}
          rowCount={rowCount}
          rowsData={Repair && Repair?.map((d: any, index: number) => {
            return renderData(d, index);
          })}
          onSort={handleRequestSort}
          setPageLimit={(newValue) => setPageLimit(newValue)}
          setPage={(newValue) => setPage(newValue)}
          tableMinWidth={1000}
        />
      </BoxContainer>
      {typeAction === "ADD_DOCUMENT" && <ModalCustom
        closeButton
        title={t("ADDREPAIR.HEADER.HEADTOP")}
        size="xl"
        onClose={() => {
          setAdminSelectfile(null)
          setRemark('')
        }}
        component={
          <Box>
            <Container maxWidth="xl">
              <Boxinsite>
                <Row>
                  <Col className="my-2" lg={3} xs={6}>
                    {t("สร้างโดย")} :
                  </Col>
                  <Col item lg={9} xs={6} className="my-2">
                    <p>{`${dataById?.createdBy?.firstname} ${dataById?.createdBy?.lastname}` || "-"}</p>
                  </Col>
                  <Col className="my-2" lg={3} xs={6}>
                    {t("ADDREPAIR.DETAIL.TYPE")} :
                  </Col>
                  <Col item lg={9} xs={6} className="my-2">
                    <p>{dataById?.repairType?.repairTypeName}</p>
                  </Col>
                  {dataById?.centralCheck !== "TRUE" && (
                    <Col className="my-2" lg={6} xs={12}>
                      <p>{"โซน"} : {dataById?.zone?.zoneName}</p>
                    </Col>
                  )}
                  {dataById?.centralCheck !== "TRUE" && (
                    <Col className="my-2" lg={6} xs={12}>
                      {t("ADDREPAIR.DETAIL.STALL")} : {dataById?.stall?.stallName}
                    </Col>
                  )}
                  {dataById?.centralDescription && <Col className="my-2" lg={3} xs={12}>
                    <p>{t("ADDREPAIR.DETAIL.CENTRAL")} :</p>
                  </Col>}
                  {dataById?.centralDescription && <Col className="my-2" lg={9} xs={12}>
                    <p>{dataById?.centralDescription}</p>
                  </Col>}
                  {/* <Col xs={2} md={2} lg={2}></Col> */}
                  <Col className="my-2" lg={3} xs={12}>
                    {t("ADDREPAIR.DETAIL.CONTENT")} {":"}
                  </Col>
                  <Col className="my-2" lg={9} xs={12}>
                    <p>{`${dataById?.description}`}</p>
                  </Col>
                  <Col lg={3} xs={6}>
                    {dataById?.repairType?.repairTypeId === 5 ? t('REPAIR.MODAL.IMPROVEMENT_DATE') : t('REPAIR.MODAL.APOINTMENT_DATE')}
                  </Col>
                  <Col lg={9} xs={6}>
                    <p>{dateToView(dataById?.repairDate)}</p>
                  </Col>
                  <Col className="my-2" lg={3} xs={12}>
                    {t("รายละเอียด")}  {":"}
                  </Col>
                  <Col className="my-2" lg={9} xs={12}>
                    <p>{dataById?.remark || '-'}</p>
                  </Col>
                  {dataById?.fileUpload.length > 0 &&
                    <>
                      <Col item lg={1} xs={12}>
                        {t("ADDREPAIR.DETAIL.ATTACH_FILE")}
                      </Col>
                      <Col className="my-2" item lg={11} xs={12}>
                        {select && (
                          <Boxupload>
                            <img src={select} height="300px" />
                          </Boxupload>
                        )}
                      </Col>
                      <Col sm={12} md={12} lg={12}><h6>{"ก่อนดำเนินการ"}</h6></Col>
                      {dataById?.fileUpload?.map((x: any, index: number) => (
                        <>
                          {/* <Col lg={2} xs={12}>awdooiwajdoawji</Col> */}
                          <Col lg={2} xs={12} className="my-2 m-auto">
                            {`ภาพที่ : `}&nbsp; {` ${index + 1}`}
                          </Col>
                          <Col item lg={10} xs={12} className="my-2">
                            <Boxfilearray>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>{x.fileName}</Box>

                                <Box>
                                  <VisibilityIcon
                                    onClick={() => handlepreview(x)}
                                    sx={{ cursor: "pointer", marginRight: "10px" }}
                                  />
                                </Box>
                              </Box>
                            </Boxfilearray>
                          </Col>
                        </>
                      ))}
                    </>}

                  <Divider sx={{ my: 3 }} />

                  <Col item lg={12} xs={12}>
                    {t("ADDREPAIR.DETAIL.ADMIN_ADD_FILE")}
                  </Col>


                  <Col className="my-2" item lg={12} xs={12}>
                    <Boxupload style={{ position: "relative" }}>
                      {" "}
                      <Boxdash>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />

                          {adminSelect ? (
                            <>
                              {/* <IconButton sx={{ position: 'absolute', top: "10px", right: "10px" }} onClick={() => setAdminSelectfile(null)}><HighlightOffIcon /></IconButton> */}
                              <img src={adminSelect} height="300px" style={{ cursor: "pointer" }} />
                            </>
                          ) : (

                            <Box
                              sx={{
                                flexDirection: "column",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer"
                              }}
                            >
                              <Cloudupload />
                              <BoxText>
                                <Typography
                                  sx={{
                                    marginTop: "24px",
                                    fontSize: "18px",
                                    fontFamily: "kanit",
                                  }}
                                >
                                  แนบไฟล์เอกสาร PDF,JPG, PNG
                                </Typography>
                              </BoxText>
                            </Box>

                          )}
                        </div>
                      </Boxdash>
                    </Boxupload>
                  </Col>



                  <Col sm={12} md={12} lg={12}><h6>{"หลังดำเนินการ"}</h6></Col>
                  {file?.map((x: any, index: any) => (
                    <>
                      <Col lg={2} xs={12} className="my-2 m-auto">
                        {`ลำดับที่ : `}&nbsp; {` ${index + 1}`}
                      </Col>
                      <Col item lg={10} xs={12} className="my-2">
                        <Boxfilearray>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{x.name || x?.fileName}</Box>

                            <Box>
                              <VisibilityIcon
                                onClick={() => handlepreviewAdmin(x)}
                                sx={{ cursor: "pointer", marginRight: "10px" }}
                              />
                              {JSON.parse(decode(profile))?.roleId !== 4 && dataById?.status !== 'SUCESS' && <DeleteIcon
                                onClick={() => handledeleteAdmin(x)}
                                color="error"
                                sx={{ cursor: "pointer" }}
                              />}
                            </Box>
                          </Box>
                        </Boxfilearray>
                      </Col>
                    </>
                  ))}

                  <Row className="mt-2">
                    <Col className="my-2" lg={3} xs={6}>
                      {t("รับเรื่องโดย")} :
                    </Col>
                    <Col item lg={9} xs={6} className="my-2">
                      <p>{`${dataById?.modifiedBy?.firstname} ${dataById?.modifiedBy?.lastname}` || "-"}</p>
                    </Col>
                    <Col lg={3} xs={6}>
                      {t('รายละเอียด')} {':'}
                      {/* {t('หมายเหตุเพิ่มเติม')} */}
                    </Col>
                    <Col lg={6} xs={6}>
                      {JSON.parse(decode(profile))?.roleId !== 4 && dataById?.status !== 'SUCESS' ? <InputTextField
                        value={remark}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => setRemark(e.target.value)}
                      /> :
                        <p>{dataById?.repairApprove ? dataById?.repairApprove?.remark : "-"}</p>}
                    </Col>
                  </Row>

                  {dataById?.status !== 'SUCESS' && JSON.parse(decode(profile))?.roleId !== 4 && <Grid item lg={12} xs={12}>
                    <ButtonCustom
                      onClick={onSubmitApprove}
                      textButton={t("REPAIR.BUTTON.COMPLETE")}
                      btnStyle={{
                        fontSize: "16px",
                        width: "100%",
                        padding: "0px",
                        marginTop: "32px",
                        marginBottom: "14px",
                      }}
                    />
                  </Grid>}
                </Row>


              </Boxinsite>
            </Container>
          </Box>
        }
      />}

      {typeAction === "RECEIVE_APPROVE" && <ModalCustom
        closeButton
        title={t("ADDREPAIR.HEADER.HEADTOP")}
        size="xl"
        component={
          <Box>
            <Container maxWidth="xl">
              <Boxinsite>


                <Row>
                  <Col className="my-2" lg={2} xs={6}>
                    {t("สร้างโดย")} :
                  </Col>
                  <Col item lg={10} xs={6} className="my-2">
                    <p>{`${dataById?.createdBy?.firstname} ${dataById?.createdBy?.lastname}` || "-"}</p>
                  </Col>
                  <Col className="my-2" lg={2} xs={6}>
                    {t("ADDREPAIR.DETAIL.TYPE")} :
                  </Col>
                  <Col item lg={10} xs={6} className="my-2">
                    <p>{dataById?.repairType?.repairTypeName || "-"}</p>
                  </Col>
                  {dataById?.centralCheck !== "TRUE" && (
                    <Col className="my-2" lg={4} xs={12}>
                      <p>โซน : {dataById?.zone?.zoneName || "-"}</p>
                    </Col>
                  )}
                  {dataById?.centralCheck !== "TRUE" && (
                    <Col className="my-2" lg={4} xs={6}>
                      <p> {t("ADDREPAIR.DETAIL.STALL")} : {dataById?.stall?.stallName}  </p>
                    </Col>
                  )}
                  {dataById?.centralCheck !== "TRUE" && (
                    <Col className="my-2" lg={4} xs={6}>
                      <p> {t("เลขที่แผง")} : {dataById?.stall?.stallNumber}  </p>
                    </Col>
                  )}
                  {dataById?.centralDescription && <Col className="my-2" lg={2} xs={12}>
                    <p>{t("ADDREPAIR.DETAIL.CENTRAL")} :</p>
                  </Col>}
                  {dataById?.centralDescription && <Col className="my-2" lg={10} xs={12}>
                    <p> {dataById?.centralDescription || "-"}</p>
                  </Col>}
                  <Col className="my-2" lg={2} xs={12}>
                    {t("ADDREPAIR.DETAIL.CONTENT")} {':'}
                  </Col>
                  <Col className="my-2" lg={10} xs={12}>
                    <p>{dataById?.description}</p>
                  </Col>
                  {dataById?.fileUpload.length > 0 &&
                    <>
                      <Col item lg={1} xs={12}>
                        {t("ADDREPAIR.DETAIL.ATTACH_FILE")}
                      </Col>
                      <Col className="my-2" item lg={11} xs={12}>
                        <Boxupload>
                          {" "}
                          {select ? (
                            <img src={select} alt="Thumb" height="300px" />
                          ) : (
                            <Boxdash>
                              <Box
                                sx={{
                                  flexDirection: "column",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Cloudupload />
                                <BoxText>
                                  <Typography
                                    sx={{
                                      marginTop: "24px",
                                      fontSize: "18px",
                                      fontFamily: "kanit",
                                    }}
                                  >
                                    ไฟล์เอกสาร PDF,JPG, PNG
                                  </Typography>
                                </BoxText>
                              </Box>

                            </Boxdash>
                          )}
                        </Boxupload>
                      </Col>
                    </>}

                  {dataById?.fileUpload?.map((x: any, index: any) => (
                    <>
                      <Col lg={2} xs={12} className="my-2 d-flex justify-content-end align-items-center">
                        {`ลำดับที่ : `}&nbsp;{index + 1}
                      </Col>
                      <Col item lg={10} xs={12} className="my-2">
                        <Boxfilearray>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>{x.fileName}</Box>

                            <Box>
                              <VisibilityIcon
                                onClick={() => handlepreview(x)}
                                sx={{ cursor: "pointer", marginRight: "10px" }}
                              />
                            </Box>
                          </Box>
                        </Boxfilearray>
                      </Col>
                    </>
                  ))}

                  <Row className="mt-2">
                    <Col lg={2} xs={6}>
                      {dataById?.repairType?.repairTypeId === 5 ? t('REPAIR.MODAL.IMPROVEMENT_DATE') : t('REPAIR.MODAL.APOINTMENT_DATE')} {':'}
                    </Col>
                    <Col lg={6} xs={6}>
                      {JSON.parse(decode(profile))?.roleId !== 4 && dataById?.status !== 'SUCESS' && dataById?.status !== 'RECEIVE' ? <InputDatePicker
                        required
                        key={'date'}
                        label={dataById?.repairType?.repairTypeId === 5 ? t('REPAIR.MODAL.IMPROVEMENT_DATE') : t('REPAIR.MODAL.APOINTMENT_DATE')}
                        value={repairDate}
                        onChange={(e: any) => setRepairDate(moment(e).format('YYYY-MM-DD'))} /> : <p>{dateToView(dataById?.repairDate)}</p>}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={2} xs={6}>
                      {t('รายละเอียด')} {':'}
                    </Col>
                    <Col lg={6} xs={6}>
                      {JSON.parse(decode(profile))?.roleId !== 4 && dataById?.status !== 'SUCESS' && dataById?.status !== 'RECEIVE' ? <InputTextField
                        value={reason}
                        onchange={(e: React.ChangeEvent<HTMLInputElement>) => setReason(e.target.value)}
                      /> : <p>{dataById?.remark || '-'}</p>}
                    </Col>
                  </Row>

                  {JSON.parse(decode(profile))?.roleId !== 4 && dataById?.status !== 'SUCESS' && dataById?.status !== 'RECEIVE' && <Grid item lg={12} xs={12}>
                    <ButtonCustom
                      onClick={onsubmitReceive}
                      textButton={t("REPAIR.BUTTON.APOINTMENT")}
                      btnStyle={{
                        fontSize: "16px",
                        width: "100%",
                        padding: "0px",
                        marginTop: "32px",
                        marginBottom: "14px",
                      }}
                    />
                  </Grid>}
                </Row>


              </Boxinsite>
            </Container>
          </Box>
        }
      />}



      {/* <div className="print-show" ref={printRef}>
        <ExportData
          headCells={""}
          componant={
           <>
                    {typePrint === "PRINT" && printData && <div>
                    
                        <>
                              <Container maxWidth="xl">
        <Boxinsite>
          <Boxbackground>
            <Boxconteiner>
              <Row>
                <Col className="my-2" lg={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.TYPE")}
                </Col>
                <Col item lg={11} xs={12} className="my-2">
                  <AutocompleteSelect
                    options={repairTypeData}
                    value={repairTypeData && repairTypeData.find((e: any) => e.repairTypeId === repairTypeId)}
                    onChange={(data: any) => setRepairTypeId(data?.repairTypeId)}
                    getOptionLabel={(option: any) => option?.repairTypeName}
                    labelId="add"
                  />
                </Col>
                <Col className="my-2" lg={1} sm={12} xs={12}>
                  <InputCheckbox disabled={centralCheck} checked={zoneCheck}  onChange={(e) =>setZoneCheck(e.target.checked)} label={t("ADDREPAIR.DETAIL.ZONE")} />
                </Col>
                <Col className="my-2" lg={5} xs={12}>
                  <AutocompleteSelect
                    options={zone}
                    value={zone?.find((e:any) => e.zoneId === zoneId?.zoneId) || null }
                    labelId="add"
                    getOptionLabel={(option: any) => option.zoneName}
                    onChange={(event, newValue) => {
                      setZoneId(newValue)
                      setStall(newValue?.stall);
                      setStallId("");
                    }}
                    disabled={centralCheck}
                  />
                </Col>
                <Col className="my-2" lg={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.STALL")}
                </Col>
                <Col className="my-2" lg={5} xs={12}>
                  <AutocompleteSelect
                    options={stall}
                    getOptionLabel={(option: any) => option.stallName}
                    value={stall?.find((e:any) => e?.stallId === stallId.stallId)}
                    disabled={centralCheck}
                    labelId="add"
                    onChange={(event, newValue) => {
                      setStallId(newValue)
                     
                    }}
                  />
                </Col>
                <Col className="my-2" lg={1} xs={12}>
                  <InputCheckbox checked={centralCheck} disabled={zoneCheck} onChange={(e) => setCentralCheck(e.target.checked)} label={t("ADDREPAIR.DETAIL.CENTRAL")} />
                </Col>
                <Col className="my-2" lg={11} xs={12}>
                  <InputTextField     disabled={zoneCheck}   value={centralDescription}     onchange={(e) => setCentralDescription(e.target.value)} />
                </Col>
                <Col className="my-2" lg={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.CONTENT")}
                </Col>
                <Col className="my-2" lg={11} xs={12}>
                  <InputTextField  value={description} onchange={(e) => setDescription(e.target.value)} />
                </Col>
                <Col item lg={1} xs={12}>
                  {t("ADDREPAIR.DETAIL.ATTACH_FILE")}
                </Col>
                <Col className="my-2" item lg={11} xs={12}>

                </Col>

              </Row>
            </Boxconteiner>
          </Boxbackground>
        </Boxinsite>
      </Container>
                        </>
                    </div>}
           </>
          }
        />
      </div> */}

      {loading && <Loading show={loading} type="fullLoading" />}
    </Box >
  );
}

function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}