import { useTranslation } from "react-i18next";

import * as Style from "./Styles";
import { Divider, Typography } from "@mui/material";
import { icons, imgs } from "../../../constants/images";
import { numberFormat } from "../../../utils/common";
import Cloudupload from "../../../assets/icon/cloudupload";
import PreviewImage from "../../../component/image/PreviewImage";
import ButtonCustom from "../../../component/button/buttonCustom";

interface PopupPaymentProps {
  open?: boolean;
  amount?: string;
  time?: string;
  image?: string;
  qrCode?: string
  onClose?: () => void;
  onUploadBill?: (img: any) => void;
  onsubmit?: () => void
  ondowload?: () => void;
  getRootProps?: any
  getInputProps?: any
}

export default function PopupPaymentBank(props: PopupPaymentProps) {
  const { t } = useTranslation();

  return (
    <>
        <Style.BoxUploadImgContainer>
          <Style.ContainerUpload>
            <Style.ContainerBoxUpload>
              <Style.BoxUpload {...props.getRootProps}>
    
                  <input {...props.getInputProps} />
                  {props.image ? (
                    
                    <PreviewImage imageFile={props.image}/>
                  ) : (
                    <div className="d-flex justify-content-center flex-column align-item-center">
                    <Cloudupload/>
                    <div>แนบไฟล์ภาพ</div>
                    </div>
                  
                  )}
                
              </Style.BoxUpload>
              
            </Style.ContainerBoxUpload>

            <Style.TextBoxUpload>
              <Typography>{t("STALL.POPUP.TRANSFORM_AMOUNT")}</Typography>
              <Typography>{numberFormat(props.amount)}</Typography>
              <Typography>{t("STALL.POPUP.BATH_UNIT")}</Typography>
            </Style.TextBoxUpload>

            <Style.TextBoxAlert>
              <Typography>{t("STALL.POPUP.PAYMENT_DATE")}{" "}{props.time}</Typography>
            </Style.TextBoxAlert>
          </Style.ContainerUpload>

          <div className="d-flex justify-content-center align-item-center">

            <Style.ButtonDowloadPdf onClick={props.ondowload} >ดาวน์โหลตแบบฟอร์มการชำระเงิน</Style.ButtonDowloadPdf>
              
            {/* <Style.ButtonSubmit onClick={props.ondowload}>ดาวน์โหลด</Style.ButtonSubmit> */}
          </div>
        </Style.BoxUploadImgContainer>

        <Style.ButtonSubmit onClick={props.onsubmit}>บันทึก</Style.ButtonSubmit>
        </>
  );
}
