import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";

/** STYLE */
import * as Style from "./branch.style"

/** API */
import BranchApi from "../../../api/setting/branch/branch.api";
import { swalActive } from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";



const initStateErrorMessage = {
  BRANCH_NAME_TH: ``,
  IS_DUPLICATE_BRANCH_NAME_TH: ``,
  BRANCH_NAME_TH_STRING_BASE: ``,
  BRANCH_NAME_TH_STRING_EMPTY: ``,
  BRANCH_NAME_TH_MAXIMUM_LENGTH: ``,
  BRANCH_NAME_TH_ANY_REQUIRED: ``,

  BRANCH_NAME_EN: ``,
  IS_DUPLICATE_BRANCH_NAME_EN: ``,
  BRANCH_NAME_EN_STRING_BASE: ``,
  BRANCH_NAME_EN_STRING_EMPTY: ``,
  BRANCH_NAME_EN_MAXIMUM_LENGTH: ``,
  BRANCH_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function Branch() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [branchId, setBranchId] = useState(Number)
  const [branchNameTh, setBranchNameTh] = useState<string>('')
  const [branchNameEn, setBranchNameEn] = useState<string>('')
  const [branchStatus, setBranchStatus] = useState<string>('ACTIVE')
  const [branchData, setBranchData] = useState<any[]>([])
  const [companyId, setCompanyId] = useState(null)
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')


  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await BranchApi.findAll(condition)

    if (res.status === 200) {
      setBranchData(res.data)
      setRowCount(res.headers['total'])
    } else {
      setRowCount(0)
      setBranchData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])


  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)

  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "branchId", disablePadding: false, label: "NO" },
    { id: "branchNameTh", disablePadding: false, label: t("BRANCH.HEADCELL.BRANCHNAMETH"), sortable: true },
    { id: "branchNameEn", disablePadding: false, label: t("BRANCH.HEADCELL.BRANCHNAMEEN"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("BRANCH.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("BRANCH.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("BRANCH.HEADCELL.ACTION") }
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BRANCH.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          BranchApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('BRANCH.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)

              loadData()
            })
            .catch((e) => {
              notiError(t('BRANCH.MESSAGE.ERROR'), '', null, null)

            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { branchId, branchNameTh, branchNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BRANCH.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BRANCH.ALERT.CONFIRM_DELETE', {
        branchNameTh: branchNameTh, branchNameEn: branchNameEn ? '' + branchNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          BranchApi.delete(branchId)
            .then((res) => {
              notiSuccess(t('BRANCH.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('BRANCH.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    const { branchId, branchNameTh, branchNameEn, companyId, status } = data
    setBranchId(branchId)
    setBranchNameTh(branchNameTh)
    setBranchNameEn(branchNameEn)
    setCompanyId(null)
    setStatus(status)
    dispatch(showModal())
  }

  const resetForm = () => {
    setBranchId(0)
    setBranchNameTh('')
    setBranchNameEn('')
    setBranchStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { branchId, branchNameTh, branchNameEn, modifiedBy, modifiedDate, status } = objData;

    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: branchId,
      id: branchId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: branchNameTh || "-" },
        { option: "TEXT", align: "left", label: branchNameEn || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        {
          option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };


  const submit = () => {
    if (!branchNameTh) return setErrorMessage({ ...errorMessage, ...{ BRANCH_NAME_TH: t('BRANCH.MESSAGE.BRANCH_NAME_TH') } })
    if (!branchNameEn) return setErrorMessage({ ...errorMessage, ...{ BRANCH_NAME_EN: t('BRANCH.MESSAGE.BRANCH_NAME_EN') } })

    dispatch(submitModal())
    BranchApi.create(branchNameTh, branchNameEn, null)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('BRANCH.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({
            ...errorMessage,
            ...{
              [err.error_description]: t(`BRANCH.MESSAGE.${err.error_description}`)
            }
          })
          if (err.statusCode === 400) {
            if (err.error_description === t('BRANCH.MESSAGE.IS_DUPLICATE_BRANCH_NAME_TH')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  BRANCH_NAME_TH: t(`${t(err.error_description)}`),

                }
              })
            } else if (err.error_description === t('BRANCH.MESSAGE.IS_DUPLICATE_BRANCH_NAME_EN')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  BRANCH_NAME_EN: t(`${t(err.error_description)}`),

                }
              })
            }

          }
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BRANCH.MESSAGE.${err.error_description}`) } })
        if (err.statusCode === 400) {
          if (err.error_description === t('BRANCH.MESSAGE.IS_DUPLICATE_BRANCH_NAME_TH')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                BRANCH_NAME_TH: t(`${t(err.error_description)}`),

              }
            })
          } else if (err.error_description === t('BRANCH.MESSAGE.IS_DUPLICATE_BRANCH_NAME_EN')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                BRANCH_NAME_EN: t(`${t(err.error_description)}`),

              }
            })
          }

        }

        dispatch(unSubmitModal())
      })

  }

  const submitEdit = () => {
    if (!branchNameTh) return setErrorMessage({ ...errorMessage, ...{ BRANCH_NAME_TH: t('BRANCH.MESSAGE.BRANCH_NAME_TH') } })
    if (!branchNameEn) return setErrorMessage({ ...errorMessage, ...{ BRANCH_NAME_EN: t('BRANCH.MESSAGE.BRANCH_NAME_EN') } })
    dispatch(submitModal())
    BranchApi.update(branchId, { branchNameTh, branchNameEn, companyId, status: branchStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`BRANCH.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BRANCH.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BRANCH.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  return (
    <>
      {/* <Style.PageContainer> */}
        <HeaderCard text={t("BRANCH.TITLE")} />
        <Style.MainContainer>
            <Row>
              <Col lg={3} md={6} xs={12}  className="my-1">
                <InputTextField label={t("BRANCH.INPUT.SEARCH")}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value)
                    setPage(1)
                  }}
                />
              </Col>
              <Col lg={3} md={6} xs={12}  className="my-1">
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      handleChangeStatus(value)
                      setPage(1)
                    }
                  }}
                  renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                  label={t('STATUS.LABEL')}
                  selectId="select-status"
                  value={status}
                  labelId="label-status"
                  options={[
                    <MenuItem key="1" value="ALL">
                      {t('STATUS.ALL')}
                    </MenuItem>,
                    <MenuItem key="2" value="ACTIVE">
                      {t('STATUS.ACTIVE')}
                    </MenuItem>,
                    <MenuItem key="3" value="INACTIVE">
                      {t('STATUS.INACTIVE')}
                    </MenuItem>
                  ]}
                />
              </Col>
              <Col lg={3} md={6} xs={12}></Col>
              <Col lg={3} md={6} xs={12}  className="my-1">
              <ButtonCustom style={{ width: '100%' }}
                    disabled={permissions.isCreate.disabled}
                    type="button"
                    textButton={t('BRANCH.BUTTON.ADD')}
                    endIcon={<img src={icons.add} />}
                    onClick={() => dispatch(showModal())}
                  />
              </Col>
            </Row>
          <Style.TableContainer>

            <TableCustom
              headCells={headCells}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={branchData.map((data: any, index: number) => {
                return renderData(data, index);
              })}
              onSort={onRequestSort}
              setPageLimit={handlePageLimitChange}
              setPage={handlePageChange}
              tableMinWidth={1000}
            />

          </Style.TableContainer>

        </Style.MainContainer>
      {/* </Style.PageContainer> */}
      <ModalCustom
        title={branchId ? t('BRANCH.TITLE_UPDATE') : t('BRANCH.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
              onchange={(event) => {
                setBranchNameTh(event.target.value)
                clearErrorMessage()
              }}
              value={branchNameTh}
              helperText={
                errorMessage.BRANCH_NAME_TH ||
                errorMessage.IS_DUPLICATE_BRANCH_NAME_TH ||
                errorMessage.BRANCH_NAME_TH_STRING_BASE ||
                errorMessage.BRANCH_NAME_TH_STRING_EMPTY ||
                errorMessage.BRANCH_NAME_TH_MAXIMUM_LENGTH ||
                errorMessage.BRANCH_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('BRANCH.INPUT.BRANCH_NAME_TH')}
            />
            <InputTextField
              key="branchNameEn"
              onchange={(event) => {
                setBranchNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={branchNameEn}
              helperText={
                errorMessage.BRANCH_NAME_EN ||
                errorMessage.IS_DUPLICATE_BRANCH_NAME_EN ||
                errorMessage.BRANCH_NAME_EN_STRING_BASE ||
                errorMessage.BRANCH_NAME_EN_STRING_EMPTY ||
                errorMessage.BRANCH_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.BRANCH_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('BRANCH.INPUT.BRANCH_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setBranchStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${branchStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={branchId ? submitEdit : submit}
        textBtnCancel={t('BRANCH.BUTTON.CANCEL')}
        textBtnConfirm={t('BRANCH.BUTTON.SAVE')}
      />

    </>
  );
}
