import { menuSeconds } from "../../constants/menu";
import EditProfile from "../../pages/auth/editProfile";
import EditProfileAdmin from "../../pages/auth/editProfileAdmin";
import { getProfile } from "../../utils/app.utils";
import { routeName } from "../routers-name";
import { decode } from 'js-base64';

const profile: any = JSON.parse(getProfile() || '{}')
const role : any = profile.length > 3 ? JSON.parse(decode(profile)) : '{}'

const routeEditProfile = [
 
  {
    ...menuSeconds.EDIT_PROFILE,
    key: menuSeconds.EDIT_PROFILE.key,
    name: menuSeconds.EDIT_PROFILE.name,
    textName: menuSeconds.EDIT_PROFILE.textName,
    path: routeName.editProfile,
    active: false,
    appBar: true,
    component: role.roleId == 4 ? EditProfile : EditProfileAdmin,
  },
];

export default routeEditProfile;
