import { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import { Rnd } from "../dragAndDrop/dragAndDrop";
import * as UseStyle from "./map.style";
import ButtonCustom from "../button/buttonCustom";

type Map = {
  mapData: any;
  updateMap?: (
    valZone: any,
    valStall: any,
    dataXY: any,
    ref: any,
    position: any,
    indexZone: number,
    indexStall: any
  ) => void;
  dragGridZone: any;
  enableResizingZone: boolean;
  disableDraggingZone: boolean;
  dragGridStall: any;
  enableResizingStall: boolean;
  disableDraggingStall: boolean;
  onClick?: (valStall: any, zoneName: string) => void;
  onClickEditZone?: (flag: boolean) => void
  rentStall?: any;
};
export default function Map(props: Map) {
  const [onClick, setOnclick] = useState<number | null>(null);
  const [ins, setsIn] = useState<boolean>(true);
  const [outs, setOuts] = useState<boolean>(true);
  const [check, setCheck] = useState<boolean>(false);
  const [dataZone, setDataZone] = useState<any[]>([])
  const [dataZoneName, setDataZoneName] = useState<any>()
  const [indexZone, setIndexZone] = useState<number>(0)

  const click = (type: any) => {
    if (type == "OUT") {
      if (!check) {
        setCheck(true);
      } else {
        setsIn(true);
        setOuts(false);
        setCheck(false);
      }
    } else if (type == "IN" && !check) {
      setsIn(false);
      setOuts(true);
    }
  };

  const checkStatusColor = (status: string) => {
    if (status == "OPEN") {
      return colors.themeThirdColor;
    } else if (status == "BLANK") {
      return colors.themeSecondColor;
    } else if (status == "RESERVE") {
      return colors.yellow;
    } else if (status == "CONTRACT") {
      return colors.sky;
    } else if (status == "NON_INFO") {
      return colors.danger;
    }
  };

  const setBackgroundZone = (zone: any) => {
    if (props.rentStall) {
      const found = props.rentStall.some((data: any) => zone.stall.map((e: any) => e?.stallId).includes(data.stallId));
      if (found) {
        return colors.white;
      } else {
        return colors.gray;
      }

    } else {
      return colors.white;
    }
  };
  useEffect(() => {
    if (indexZone)
      setDataZone(props.mapData[indexZone].stall)
  }, [props.mapData]);
  return (
    <div>
      <div
        style={{
          border: `1px solid ${colors.gray}`,
          width: "100%",
          height: 700,
          position:'relative'
        }}
      >
        {props.mapData && (
          dataZone?.length > 0 && (
            dataZone.map((valStall: any, indexStall: number) => {
              return (
                <>
                  <Rnd
                    onClick={() => {
                      if (props.onClick) {
                        props.onClick(valStall, dataZoneName);
                      }
                      click("IN");
                      setCheck(false);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "solid 1px #ddd",
                      background: `${checkStatusColor(valStall.status)}`,
                      color: colors.white,
                      fontSize: 12,
                      cursor:"pointer"
                    }}
                    size={{
                      width: valStall.width,
                      height: valStall.height,
                    }}
                    position={{
                      x: valStall.x,
                      y: valStall.y,
                    }}
                    onDragStop={(e, d: any) => {
                      e.stopPropagation();
                      if (props?.updateMap)
                        props?.updateMap(
                          valStall,
                          valStall,
                          d,
                          null,
                          null,
                          indexZone,
                          indexStall
                        );
                    }}
                    onResize={(e, direction, ref, delta, position) => {
                      if (props?.updateMap)
                        props?.updateMap(
                          valStall,
                          valStall,
                          null,
                          ref,
                          position,
                          indexZone,
                          indexStall
                        );
                    }}
                    bounds="parent"
                    enableResizing={props.enableResizingStall}
                    disableDragging={ins || props.disableDraggingStall}
                    dragGrid={props.dragGridStall}
                  >
                    {valStall.stallNumber}
                  </Rnd>
                </>
              );
            })
          ) || (
            props.mapData.map((valZone: any, indexZone: number) => {
              return (
                <>
                  <Rnd
                    onClick={() => {
                      // setDataZone(valZone.stall)
                      // setDataZoneName(valZone.zoneName)
                      setOnclick(indexZone);
                      click("OUT");
                      console.log('object');
                      // setCheck(false);
                      // setIndexZone(indexZone)
                    }}
                    style={{
                      border: `solid 1px ${onClick === indexZone ? colors.danger : colors.divider
                        }`,
                      backgroundColor: `${setBackgroundZone(valZone)}`,
                      display: "flex",
                      flexDirection: 'column',
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    bounds="parent"
                    size={{
                      width: valZone.width,
                      height: valZone.height,
                    }}
                    position={{
                      x: valZone.x,
                      y: valZone.y,
                    }}
                    onDragStop={(e, d: any) => {
                      e.stopPropagation();
                      if (props?.updateMap)
                        props?.updateMap(
                          valZone,
                          valZone.stall,
                          d,
                          null,
                          null,
                          indexZone,
                          null
                        );
                    }}
                    onResize={(e, direction, ref, delta, position) => {
                      if (props?.updateMap)
                        props?.updateMap(
                          valZone,
                          valZone.stall,
                          null,
                          ref,
                          position,
                          indexZone,
                          null
                        );
                    }}
                    enableResizing={props.enableResizingZone}
                    disableDragging={outs || props.disableDraggingZone}
                    dragGrid={props.dragGridZone}
                  >
                    <div>
                      {valZone.zoneName}
                    </div>
                    <div className="pt-2">
                      <ButtonCustom
                        textButton="จัดการโซน"
                        onClick={() => {
                          if (props.onClickEditZone) props.onClickEditZone(true)
                          setDataZone(valZone.stall)
                          setDataZoneName(valZone.zoneName)
                          setOnclick(indexZone);
                          click("OUT");
                          setCheck(false);
                          setIndexZone(indexZone)
                        }}
                      />
                    </div>
                  </Rnd>
                </>
              );
            })
          )
        )
        }
      </div>
      {dataZone?.length > 0 && (
        <div className="pt-2 d-flex justify-content-center">
          <ButtonCustom
            textButton="ย้อนกลับ"
            onClick={() => {
              if (props.onClickEditZone) props.onClickEditZone(false)
              setDataZone([])
              setIndexZone(0)
            }}
          />
        </div>
      )}
      <div className="d-flex justify-content-end py-2">
        <div className="d-flex">
          <UseStyle.BoxStatus color={colors.themeThirdColor} />
          <div className="px-3">ระบบเปิดให้จอง</div>
        </div>
        <div className="d-flex">
          <UseStyle.BoxStatus color={colors.themeSecondColor} />
          <div className="px-3">ว่าง</div>
        </div>
        <div className="d-flex">
          <UseStyle.BoxStatus color={colors.yellow} />
          <div className="px-3">ติดจอง</div>
        </div>
        <div className="d-flex">
          <UseStyle.BoxStatus color={colors.sky} />
          <div className="px-3">ทำสัญญาแล้ว</div>
        </div>
        <div className="d-flex">
          <UseStyle.BoxStatus color={colors.danger} />
          <div className="px-3">ข้อมูลไม่ครบถ้วน</div>
        </div>
        <div className="d-flex ">
          <UseStyle.BoxStatus color={colors.gray} />
          <div className="pl-3">พื้นที่ไม่ได้เปิดจอง</div>
        </div>
      </div>
    </div>
  );
}
