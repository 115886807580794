import { getApprove, getPermissionActiveMenu, getPermissionMenus } from './app.utils'

const permissionMenus = () => {
    return getPermissionActiveMenu() || {}
}
const ap = getApprove()

export const isCreate = () => {
    const permission: any = permissionMenus()
    // console.log(permission,'permissionpermission')
    return {
        style: permission?.isCreate === 1 ? 'create' : 'disable',
        val: permission?.isCreate ? permission.isCreate : 0,
        disabled: permission?.isCreate ? false : true,
    }
}
export const isDelete = () => {
    const permission: any = permissionMenus()

    return {
        style: permission?.isDelete === 1 ? 'delete' : 'disable',
        val: permission?.isDelete ? permission.isDelete : 0,
        disabled: permission?.isDelete ? false : true
    }
}
export const isUpdate = () => {
    const permission: any = permissionMenus()

    return {
        style: permission?.isUpdate === 1 ? 'update' : 'disable',
        val: permission?.isUpdate ? permission.isUpdate : 0,
        disabled: permission?.isUpdate ? false : true
    }
}
export const isView = () => {
    const permission: any = permissionMenus()
    return {
        style: permission?.isView === 1 ? 'view' : 'disable',
        val: permission?.isView ? permission.isView : 0,
        disabled: permission?.isView ? false : true
    }
}

export const isExport = () => {
    const permission: any = permissionMenus()
    return {
        style: permission?.isExport === 1 ? 'export' : 'disable',
        val: permission?.isExport ? permission.isView : 0,
        disabled: permission?.isExport ? false : true
    }
}

export const isApporve = () => {
    const permission: any = permissionMenus()
    return {
        style: permission?.isApporve === 1 ? 'apporve' : 'disable',
        val: permission?.isApporve ? permission.isView : 0,
        disabled: permission?.isApporve ? false : ap ? false : true
    }
}
