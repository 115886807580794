import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../../constants/colors";
import styleds from 'styled-components'

export const MainContainer = styled("div")(({ theme }) => ({
  padding: "24px 48px",
  height: '100vh',
  overflow: 'auto !important'
}));

export const TableContainer = styled(Box)(({theme}) => ({ 
  marginTop: 24,
  
    
}))

export const ButtonInTable = styled(Button)(({theme}) => ({
  backgroundColor: colors.green,
  borderRadius: 4,
  color: colors.white
}))

export const Title = styled(Typography)(({theme}) => ({
  fontSize: 20,
  fontWeight: 400,
  whiteSpace:'nowrap',
  
   
}))

export const ContentContainer = styled(Box)(({theme}) => ({
    display:'flex',
    flexDirection:'row'
  
}))

export const ContentItem = styled(Box)(({theme}) => ({
  display:'flex',
  flexDirection:'row'

}))

export const ButtonContainer = styled(Box)(({theme}) => ({
  marginTop:'10px',
  display:'flex',
  justifyContent:'center',
  width:'100%'

}))

export const ButtonStyle = styled(Button)(({theme}) => ({
  // marginLeft:'60px',
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  ':hover' : {
      backgroundColor: colors.blue
  }
}))
export const Detail = styled('p')(({theme}) => ({
  fontSize: 14,
  fontWeight: 400
}))


export const ButtonApprove = styled(Button)(({theme}) => ({
  backgroundColor: colors.themeSecondColor,
  color: colors.white,
  fontSize: 16,
  display: "flex",
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 4,
  padding: '8px 16px',
  cursor: 'pointer',
  ':hover' : {
      backgroundColor: colors.blue
  }
}))

export const BoxInfo = styled(Box)(({theme}) => ({
  padding: 16,
  backgroundColor: colors.lightGrayBG,
  display: 'flex',
  flexDirection: 'row',
  marginTop: 8
}))

export const PicRenter = styled('img')(({theme}) => ({
  width: 200,
  height: 200
}))

export const HeadText = styled('p')(({theme}) => ({
  fontSize: 14,
  fontWeight: 600
}))


export const Styles = styleds.div`
  padding: 1px;

  table {
    border-spacing: 0;
    
    th,
    td {
      margin: 0;
      padding: 1px;
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  } 
`