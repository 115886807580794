import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, MenuItem } from "@mui/material";
import { useDispatch  } from "react-redux";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from "../../../component/button/buttonCustom";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { swalActive } from "../../../component/notification/swal";
import {
  resetModal,
  showModal,
  submitModal,
  unSubmitModal,
} from "../../../app/slice/modal.slice";
import {
  notiError,
  notiSuccess,
} from "../../../component/notification/notifications";
import InputNewRangePicker from "../../../component/input/InputRangePicker";
import InputDatePicker from "../../../component/input/inputDatePicker";
import { dateToView } from "../../../utils/date.utils";
import moment from "moment";

/** STYLE */
import * as Style from "./utility.style";

/** API */
import ElectricityApi from "../../../api/setting/electricity/electricity.api";
import { icons } from "../../../constants/images";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";
import { numberFormat } from "../../../utils/common";

const initStateErrorMessage = {
  ELECTRICITY_USED: ``,
  ELECTRICITY_USED_NUMBER_BASE: ``,
  ELECTRICITY_USED_STRING_EMPTY: ``,
  ELECTRICITY_USED_ANY_REQUIRED: ``,

  AMOUNT: ``,
  AMOUNT_STRING_BASE: ``,
  AMOUNT_STRING_EMPTY: ``,
  AMOUNT_MAXIMUM_LENGTH: ``,
  AMOUNT_ANY_REQUIRED: ``,

  PRICE_PER_UNIT: ``,
  PRICE_PER_UNIT_NUMBER_BASE: ``,
  PRICE_PER_UNIT_STRING_EMPTY: ``,
  PRICE_PER_UNIT_ANY_REQUIRED: ``,

  DATE_USE: ``,
  DATE_USE_ANY_REQUIRED: ``,
};

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

export default function ElectricityManagement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  

  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [electricityTypeId, setElectricityTypeId] = useState<number>(Number);
  const [electricityUsed, setElectricityUsed] = useState<number | undefined>();
  const [amount, setAmount] = useState<number | undefined>();
  const [dateUsed, setDateUsed] = useState<string>("");
  const [pricePerUnit, setPricePerUnit] = useState<number | undefined>();
  const [status, setStatus] = useState<string>("ACTIVE");
  const [electricityData, setElectricityData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>();
  const [sortBy, setSortBy] = useState<string>("createDate");
  const [sortType, setSortType] = useState<string>("DESC");
  const [search, setSearch] = useState<string>("");
  const [selectStatus, setSelectStatus] = useState<string>("ALL");
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>([]);
  const [dateStart, setDateStart] = useState<string>("");
  const [dateEnd, setDateEnd] = useState<string>("");

  const loadData = useCallback(async () => {
    let condition: any = {};
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search) condition = { ...condition, search: search };
    if (sortBy) condition = { ...condition, sortBy: sortBy };
    if (sortType) condition = { ...condition, sortType: sortType };
    if (dateStart !== "") condition = { ...condition, dateStart: dateStart };
    if (dateEnd !== "") condition = { ...condition, dateEnd: dateEnd };
    if (selectStatus !== "ALL")
      condition = {
        ...condition,
        status: selectStatus,
      };

    const res = await ElectricityApi.findAll(condition);

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setElectricityData(res.data);
    } else {
      setRowCount(0);
      setElectricityData([]);
    }
  }, [
    page,
    pageLimit,
    search,
    sortBy,
    sortType,
    dateStart,
    dateEnd,
    selectStatus,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);


  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setSelectStatus(newStatus);
  };

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType);
    setSortBy(sortBy);
  };

  const handleRangeDate = (value: any) => {
    setSelectedDateRange(value);
    setDateStart(moment(value[0]).format("YYYY-MM-DD"));
    setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
  };

  const handleClearRangeDate = () => {
    setSelectedDateRange([]);
    setDateStart("");
    setDateEnd("");
  };

  const handleDateChange = (date: any) => {
    setDateUsed(date);
    clearErrorMessage();
  };

  const handleClearDate = () => {
    setDateUsed("");
  };

  const headCells = [
    { id: "electricityTypeId", disablePadding: false, label: "NO" },
    {
      id: "createDate",
      disablePadding: false,
      label: t("ELECTRICITY.HEADCELL.CREATEDATE"),
      sortable: true,
    },
    {
      id: "dateUsed",
      disablePadding: false,
      label: t("ELECTRICITY.HEADCELL.DATEUSED"),
    },
    {
      id: "electricityUsed",
      disablePadding: false,
      label: t("ELECTRICITY.HEADCELL.AMOUNT_ELECTRICITY_USED_LIMIT"),
      sortable: true,
    },
    {
      id: "amount",
      disablePadding: false,
      label: t("ELECTRICITY.HEADCELL.AMOUNT"),
      sortable: true,
    },
    {
      id: "pricePerUnit",
      disablePadding: false,
      label: t("ELECTRICITY.HEADCELL.PRICEPERUNIT"),
      sortable: true,
    },
    {
      id: "status",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICITY.HEADCELL.STATUS"),
    },
    {
      id: "modifiedId",
      disablePadding: false,
      align: "center",
      label: t("ELECTRICITY.HEADCELL.MODIFIEDBY"),
      sortable: true,
    },
    {
      id: "action",
      disablePadding: false,
      label: t("ELECTRICITY.HEADCELL.ACTION"),
    },
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t(
        "ELECTRICITY.ALERT.CONFIRM_STATUS"
      )}</p>`,
      null,
      (res: any) => {
        if (res) {
          ElectricityApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(
                t("ELECTRICITY.MESSAGE.SUCCESS.UPDATE_STATUS"),
                "",
                null,
                null
              );

              loadData();
            })
            .catch((e) => {
              notiError(t("ELECTRICITY.MESSAGE.ERROR"), "", null, null);
            });
        }
      }
    );
  };
  const onDelete = (data: any) => {
    const { electricityTypeId } = data;

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t(
        "ELECTRICITY.ALERT.CONFIRM_DELETE"
      )}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t(
        "ELECTRICITY.ALERT.CONFIRM_DELETE",
        {}
      )}</p>`,
      (res: any) => {
        if (res) {
          ElectricityApi.delete(electricityTypeId)
            .then((res) => {
              notiSuccess(
                t("ELECTRICITY.MESSAGE.SUCCESS.DELETE"),
                "",
                null,
                null
              );
              loadData();
            })
            .catch((e) => {
              notiError(t("ELECTRICITY.MESSAGE.ERROR"), "", null, null);
            });
        }
      }
    );
  };

  const onEdit = (data: any) => {
    const { electricityTypeId,electricityUsed, amount, pricePerUnit, dateUsed, status } = data;
    setElectricityTypeId(electricityTypeId);
    setAmount(amount);
    setPricePerUnit(pricePerUnit);
    setDateUsed(dateUsed);
    setElectricityUsed(electricityUsed)
    setStatus(status);
    dispatch(showModal());
  };

  const resetForm = () => {
    setElectricityTypeId(0);
    setAmount(undefined);
    setElectricityUsed(undefined);
    setDateUsed("");
    setPricePerUnit(undefined);
    setStatus("ACTIVE");
    clearErrorMessage();
  };

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1;
    const {
      electricityTypeId,
      dateUsed,
      electricityUsed,
      amount,
      pricePerUnit,
      status,
      modifiedBy,
      modifiedDate,
      createDate,
    } = objData;

    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true : permissions.isUpdate.disabled;
    const objRenderData = {
      key: electricityTypeId,
      id: electricityTypeId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: dateToView(createDate) || "-" },
        { option: "TEXT", align: "left", label: dateToView(dateUsed) || "-" },
        { option: "TEXT", align: "left", label: electricityUsed || "-" },
        { option: "TEXT", align: "left", label: amount || "-" },
        { option: "TEXT", align: "left", label: numberFormat(pricePerUnit) || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        {
          option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        {
          option: "ACTION",
          align: "center",
          label: "action",
          values: [
            {
              option: "STATUS_ACTIVE",
              label: t(`STATUS.ACTIVE`),
              disabled: statusBtnActive,
            },
            {
              option: "STATUS_INACTIVE",
              label: t(`STATUS.INACTIVE`),
              disabled: statusBtnInActive,
            },
            { option: "DIVIDER", label: "", disabled: false },
            { option: "EDIT", label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: "DELETE", label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled },
          ],
        },
      ],
    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), "ACTIVE")}
        oninactive={() => onActive(Number(objRenderData.id), "INACTIVE")}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    );
  };

  const submit = () => {
    if (!electricityUsed) return setErrorMessage({ ...errorMessage, ...{ ELECTRICITY_USED: t("ELECTRICITY.MESSAGE.ELECTRICITY_USED") }, });
    if (!amount) return setErrorMessage({ ...errorMessage, ...{ AMOUNT: t("ELECTRICITY.MESSAGE.AMOUNT") }, });
    if (!pricePerUnit) return setErrorMessage({ ...errorMessage, ...{ PRICE_PER_UNIT: t("ELECTRICITY.MESSAGE.PRICE_PER_UNIT") }, });
    if (!dateUsed) return setErrorMessage({ ...errorMessage, ...{ DATE_USED: t("ELECTRICITY.MESSAGE.DATE_USED") }, });

    dispatch(submitModal());
    ElectricityApi.create(electricityUsed, amount, pricePerUnit, moment(dateUsed).format("YYYY-MM-DD"))
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t("ELECTRICITY.MESSAGE.SUCCESS.CREATE"), "", null, null);
          resetForm();
          loadData();
          dispatch(resetModal());
        } else {
          const err = res.response.data;
          setErrorMessage({
            ...errorMessage,
            ...{
              [err.error_description]: t(
                `ELECTRICITY.MESSAGE.${err.error_description}`
              ),
            },
          });

          dispatch(unSubmitModal());
        }
      })
      .catch((e) => {
        const err = e.response.data;
        setErrorMessage({
          ...errorMessage,
          ...{
            [err.error_description]: t(
              `ELECTRICITY.MESSAGE.${err.error_description}`
            ),
          },
        });

        dispatch(unSubmitModal());
      });
  };

  const submitEdit = () => {
    if (!electricityUsed) return setErrorMessage({ ...errorMessage, ...{ ELECTRICITY_USED: t("ELECTRICITY.MESSAGE.ELECTRICITY_USED") }, });
    if (!amount) return setErrorMessage({ ...errorMessage, ...{ AMOUNT: t("ELECTRICITY.MESSAGE.AMOUNT") }, });
    if (!pricePerUnit) return setErrorMessage({ ...errorMessage, ...{ PRICE_PER_UNIT: t("ELECTRICITY.MESSAGE.PRICE_PER_UNIT") }, });
    if (!dateUsed) return setErrorMessage({ ...errorMessage, ...{ DATE_USED: t("ELECTRICITY.MESSAGE.DATE_USED") }, });
    dispatch(submitModal());
    ElectricityApi.update(electricityTypeId, {
      electricityUsed,
      amount,
      pricePerUnit,
      dateUsed,
      status,
    })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`ELECTRICITY.MESSAGE.SUCCESS.UPDATE`), "", null, null);
          resetForm();
          loadData();
          dispatch(resetModal());
        } else {
          const err = res.response.data;
          setErrorMessage({
            ...errorMessage,
            ...{
              [err.error_description]: t(
                `ELECTRICITY.MESSAGE.${err.error_description}`
              ),
            },
          });
          dispatch(unSubmitModal());
        }
      })
      .catch((e) => {
        const err = e.response.data;
        setErrorMessage({
          ...errorMessage,
          ...{
            [err.error_description]: t(
              `ELECTRICITY.MESSAGE.${err.error_description}`
            ),
          },
        });
        dispatch(unSubmitModal());
      });
  };

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage);
  };

  return (
    <>
      <Style.PageContainer>
        <HeaderCard text={t("ELECTRICITY.TITLE")} />
        <Style.MainContainer>
          <Box>
            <Row>
              <Col lg={3} md={6} xs={12} className="my-1">
                <InputTextField
                  label={t("ELECTRICITY.INPUT.SEARCH")}
                  value={search}
                  onchange={(event) => {
                    setSearch(event.target.value);
                    setPage(1);
                  }}
                />
              </Col>
              <Col lg={3} md={6} xs={12} className="my-1">
                <FilterSelect
                  onchange={(event) => {
                    const value = event.target.value;
                    if (value) {
                      handleChangeStatus(value);
                      setPage(1);
                    }
                  }}
                  renderValue={() =>
                    `${t("STATUS.LABEL")}: ${t(`STATUS.${selectStatus}`)}`
                  }
                  label={t("STATUS.LABEL")}
                  selectId="select-status"
                  value={selectStatus}
                  labelId="label-status"
                  options={[
                    <MenuItem key="1" value="ALL">
                      {t("STATUS.ALL")}
                    </MenuItem>,
                    <MenuItem key="2" value="ACTIVE">
                      {t("STATUS.ACTIVE")}
                    </MenuItem>,
                    <MenuItem key="3" value="INACTIVE">
                      {t("STATUS.INACTIVE")}
                    </MenuItem>,
                  ]}
                />
              </Col>

              <Col lg={3} md={6} xs={12} className="my-1">
                <InputNewRangePicker
                  value={selectedDateRange}
                  onchange={handleRangeDate}
                  onClear={handleClearRangeDate}
                  label={t("ELECTRICITY.BUTTON.DATE_START_AND_END")}
                  allowClear
                />
              </Col>

              <Col lg={3} md={6} xs={12} className="my-1">
                <ButtonCustom
                  type="button"
                  disabled={permissions.isCreate.disabled}
                  textButton={t('ELECTRICITY.BUTTON.ADD')}
                  onClick={() => dispatch(showModal())}
                  endIcon={<img src={icons.add} />}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Box>
          <Style.TableContainer>
            <TableCustom
              headCells={headCells}
              customScroll
              page={page}
              pageLimit={pageLimit}
              sortType={sortType}
              sortBy={sortBy}
              rowCount={rowCount}
              rowsData={electricityData.map((data: any, index: number) => {
                return renderData(data, index);
              })}
              onSort={onRequestSort}
              setPageLimit={handlePageLimitChange}
              setPage={handlePageChange}
            />
          </Style.TableContainer>
        </Style.MainContainer>
      </Style.PageContainer>
      <ModalCustom
        title={
          electricityTypeId
            ? t("ELECTRICITY.TITLE_UPDATE")
            : t("ELECTRICITY.TITLE_CREATE")
        }
        component={
          <div className="pb-2">

            <InputTextField
              key="electricityUsed"
              name={t("ELECTRICITY.INPUT.UNIT")}
              onchange={(event) => {
                setElectricityUsed(event.target.value);
                clearErrorMessage();
              }}
              value={electricityUsed}
              helperText={
                errorMessage.ELECTRICITY_USED ||
                errorMessage.ELECTRICITY_USED_NUMBER_BASE ||
                errorMessage.ELECTRICITY_USED_NUMBER_EMPTY ||
                errorMessage.ELECTRICITY_USED_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: "8px", width: '90%' }}
              heading={t("ELECTRICITY.INPUT.AMOUNT_ELECTRICITY_USED_LIMIT")}
            />
            <Style.UnitBox>
              <Style.SubMessage>{t('ELECTRICITY.INPUT.UNIT')}</Style.SubMessage>
            </Style.UnitBox>

            <InputTextField
              key="amount"
              onchange={(event) => {
                setAmount(event.target.value);
                clearErrorMessage();
              }}
              value={amount}
              helperText={
                errorMessage.AMOUNT ||
                errorMessage.AMOUNT_STRING_BASE ||
                errorMessage.AMOUNT_STRING_EMPTY ||
                errorMessage.AMOUNT_MAXIMUM_LENGTH ||
                errorMessage.AMOUNT_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: "8px", width: '90%' }}
              heading={t("ELECTRICITY.INPUT.AMOUNT")}
            />
            <Style.UnitBox>
              <Style.SubMessage>{t("ELECTRICITY.INPUT.BAHT")}</Style.SubMessage>
            </Style.UnitBox>

            <InputTextField
              key="pricePerUnit"
              onchange={(event) => {
                setPricePerUnit(event.target.value);
                clearErrorMessage();
              }}
              value={pricePerUnit}
              helperText={
                errorMessage.PRICE_PER_UNIT ||
                errorMessage.PRICE_PER_UNIT_USED_NUMBER_BASE ||
                errorMessage.PRICE_PER_UNIT_USED_NUMBER_EMPTY ||
                errorMessage.PRICE_PER_UNIT_USED_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: "8px", width: "90%" }}
              heading={t("ELECTRICITY.INPUT.PRICE_PER_UNIT")}
            />

            <Style.UnitBox>
              <Style.SubMessage>{t("ELECTRICITY.INPUT.BAHT")}</Style.SubMessage>
            </Style.UnitBox>

            <InputDatePicker
              value={dateUsed}
              key={"date"}
              onChange={(e: any) =>
                setDateUsed(moment(e).format("YYYY-MM-DD"))
              }
              heading={t("ELECTRICITY.INPUT.DATE_USED")}
              label={t("ELECTRICITY.INPUT.DATE_USED")}
              helperText={
                errorMessage.DATE_USED || errorMessage.DATE_USED_ANY_REQUIRED
              }
            />

            <div className="mt-3">
              <FilterSelect
                heading={`${t("STATUS.LABEL")}`}
                onchange={(event) => {
                  const value = event.target.value;
                  if (value) {
                    setStatus(value);
                  }
                }}
                renderValue={() => `${t(`STATUS.${status}`)}`}
                label={t("STATUS.LABEL")}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t("STATUS.ACTIVE")}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t("STATUS.INACTIVE")}
                  </MenuItem>,
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={electricityTypeId ? submitEdit : submit}
        textBtnCancel={t("ELECTRICITY.BUTTON.CANCEL")}
        textBtnConfirm={t("ELECTRICITY.BUTTON.SAVE")}
      />
    </>
  );
}
