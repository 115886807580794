import { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ButtonCustom from '../../../component/button/buttonCustom';
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { swalActive } from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { resetModal, showModal, submitModal, unSubmitModal } from "../../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { icons } from "../../../constants/images";

/** STYLE */
import * as Style from "./bidder.style"

/** API */
import BidderTypeApi from "../../../api/setting/bidtype/biddertype.api";
import { isCreate, isDelete, isUpdate, isView } from "../../../utils/permission.utils";
import { Col, Row } from "react-bootstrap";

const initStateErrorMessage = {
  BIDDER_TYPE_NAME_TH: ``,
  IS_DUPLICATE_BIDDER_TYPE_NAME_TH: ``,
  BIDDER_TYPE_NAME_TH_STRING_BASE: ``,
  BIDDER_TYPE_NAME_TH_STRING_EMPTY: ``,
  BIDDER_TYPE_NAME_TH_MAXIMUM_LENGTH: ``,
  BIDDER_TYPE_NAME_TH_ANY_REQUIRED: ``,

  BIDDER_TYPE_NAME_EN: ``,
  IS_DUPLICATE_BIDDER_TYPE_NAME_EN: ``,
  BIDDER_TYPE_NAME_EN_STRING_BASE: ``,
  BIDDER_TYPE_NAME_EN_STRING_EMPTY: ``,
  BIDDER_TYPE_NAME_EN_MAXIMUM_LENGTH: ``,
  BIDDER_TYPE_NAME_EN_ANY_REQUIRED: ``
}

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}


export default function BidderType() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)
  const [bidTypeId, setBidTypeId] = useState(Number)
  const [bidTypeName, setBidTypeName] = useState<string>('')
  const [bidTypeNameEn, setBidTypeNameEn] = useState<string>('')
  const [bidTypeStatus, setBidTypeStatus] = useState<string>('ACTIVE')
  const [bidTypData, setBidTypData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')

  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await BidderTypeApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setBidTypData(res.data)

    } else {
      setRowCount(0)
      setBidTypData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])


  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const handleChangeStatus = (newStatus: string) => {
    setStatus(newStatus)

  }

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "bidTypeId", disablePadding: false, label: "NO", },
    { id: "bidTypeName", disablePadding: false, label: t("BIDDERTYPE.HEADCELL.BIDTYPENAMETH"), sortable: true },
    { id: "bidTypeNameEn", disablePadding: false, label: t("BIDDERTYPE.HEADCELL.BIDTYPENAMEEN"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("BIDDERTYPE.HEADCELL.STATUS"), sortable: true },
    { id: "modifiedId", disablePadding: false, align: 'center', label: t("BIDDERTYPE.HEADCELL.MODIFIEDBY"), sortable: true },
    { id: "action", disablePadding: false, label: t("BIDDERTYPE.HEADCELL.ACTION") }
  ];

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BIDDERTYPE.ALERT.CONFIRM_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          BidderTypeApi.updateStatus(id, valueStatus)
            .then((res) => {
              notiSuccess(t('BIDDERTYPE.MESSAGE.SUCCESS.UPDATE_STATUS'), '', null, null)

              loadData()
            })
            .catch((e) => {
              notiError(t('BIDDERTYPE.MESSAGE.ERROR'), '', null, null)

            })
        }
      })
  }
  const onDelete = (data: any) => {
    const { bidTypeId, bidTypeName, bidTypeNameEn } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BIDDERTYPE.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('BIDDERTYPE.ALERT.CONFIRM_DELETE', {
        bidTypeName: bidTypeName, bidTypeNameEn: bidTypeNameEn ? ' ' + bidTypeNameEn + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {
          BidderTypeApi.delete(bidTypeId)
            .then((res) => {
              notiSuccess(t('BIDDERTYPE.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('BIDDERTYPE.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const onEdit = (data: any) => {
    const { bidTypeId, bidTypeName, bidTypeNameEn, status } = data
    setBidTypeId(bidTypeId)
    setBidTypeName(bidTypeName)
    setBidTypeNameEn(bidTypeNameEn)
    setStatus(status)
    dispatch(showModal())
  }

  const resetForm = () => {
    setBidTypeId(0)
    setBidTypeName('')
    setBidTypeNameEn('')
    setBidTypeStatus('ACTIVE')
    clearErrorMessage()
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { bidTypeId, bidTypeName, bidTypeNameEn, modifiedBy, modifiedDate, status } = objData;

    const statusBtnActive = status === "ACTIVE" ? true : permissions.isUpdate.disabled;
    const statusBtnInActive = status === "INACTIVE" ? true :  permissions.isUpdate.disabled;

    const objRenderData = {
      key: bidTypeId,
      id: bidTypeId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "left", label: bidTypeName || "-" },
        { option: "TEXT", align: "left", label: bidTypeNameEn || "-" },
        { option: "STATUS", align: "center", label: status || "-" },
        {
          option: "UPDATE_BY", align: "left", label: { updatedBy: `${(modifiedBy && (modifiedBy.firstname && modifiedBy.lastname)) ? modifiedBy.firstname + ' ' + modifiedBy.lastname : modifiedBy?.firstname || modifiedBy?.lastname || '-'}`, updatedAt: modifiedDate }
        },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
        oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
        onedit={() => onEdit(objRenderData.obj)}
        ondelete={() => onDelete(objRenderData.obj)}
      />
    )
  };


  const submit = () => {
    if (!bidTypeName) return setErrorMessage({ ...errorMessage, ...{ BIDDER_TYPE_NAME_TH: t('BIDDERTYPE.MESSAGE.BIDDER_TYPE_NAME_TH') } })
    if (!bidTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ BIDDER_TYPE_NAME_EN: t('BIDDERTYPE.MESSAGE.BIDDER_TYPE_NAME_EN') } })

    dispatch(submitModal())
    BidderTypeApi.create(bidTypeName, bidTypeNameEn)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t('BIDDERTYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })

          if (err.statusCode === 400) {
            if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_TH')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  BIDDER_TYPE_NAME_TH: t(`${t(err.error_description)}`),

                }
              })
            } else if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_EN')) {
              setErrorMessage({
                ...errorMessage,
                ...{
                  BIDDER_TYPE_NAME_EN: t(`${t(err.error_description)}`),

                }
              })
            }
          }
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })

        if (err.statusCode === 400) {
          if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_TH')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                BIDDER_TYPE_NAME_TH: t(`${t(err.error_description)}`),

              }
            })
          } else if (err.error_description === t('BIDDERTYPE.MESSAGE.IS_DUPLICATE_BIDDER_TYPE_NAME_EN')) {
            setErrorMessage({
              ...errorMessage,
              ...{
                BIDDER_TYPE_NAME_EN: t(`${t(err.error_description)}`),

              }
            })
          }
        }
        dispatch(unSubmitModal())
      })

  }

  const submitEdit = () => {
    if (!bidTypeName) return setErrorMessage({ ...errorMessage, ...{ BIDDER_TYPE_NAME_TH: t('BIDDERTYPE.MESSAGE.BIDDER_TYPE_NAME_TH') } })
    if (!bidTypeNameEn) return setErrorMessage({ ...errorMessage, ...{ BIDDER_TYPE_NAME_EN: t('BIDDERTYPE.MESSAGE.BIDDER_TYPE_NAME_EN') } })
    dispatch(submitModal())
    BidderTypeApi.update(bidTypeId, { bidTypeName, bidTypeNameEn, status: bidTypeStatus })
      .then((res: any) => {
        if (res.status !== undefined && res.status === 205) {
          notiSuccess(t(`BIDDERTYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          resetForm()
          loadData()
          dispatch(resetModal())
        } else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })
          dispatch(unSubmitModal())
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.error_description]: t(`BIDDERTYPE.MESSAGE.${err.error_description}`) } })
        dispatch(unSubmitModal())
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  return (
    <>
      <HeaderCard text={t("BIDDERTYPE.TITLE")} />
      <Style.MainContainer>
          <Row>
            <Col lg={3} md={6} xs={12}  className="my-1">
              <InputTextField label={t("BIDDERTYPE.INPUT.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Col>
            <Col lg={3} md={6} xs={12}  className="my-1">
              <FilterSelect
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    handleChangeStatus(value)
                    setPage(1)
                  }
                }}
                renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status"
                value={status}
                labelId="label-status"
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('STATUS.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="3" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </Col>
            <Col lg={3} md={6} xs={12}></Col>
            <Col lg={3} md={6} xs={12}  className="my-1">
            <ButtonCustom style={{ width: '100%' }}
                  disabled={permissions.isCreate.disabled}
                  type="button"
                  textButton={t('BIDDERTYPE.BUTTON.ADD')}
                  endIcon={<img src={icons.add} />}
                  onClick={() => dispatch(showModal())}
                />
            </Col>
          </Row>
        
        <Style.TableContainer>

          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={bidTypData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}
            tableMinWidth={1000}
          />
        </Style.TableContainer>

      </Style.MainContainer>
      
      <ModalCustom
        title={bidTypeId ? t('BIDDERTYPE.TITLE_UPDATE') : t('BIDDERTYPE.TITLE_CREATE')}
        component={
          <div className="pb-2">
            <InputTextField
              key="bidTypeName"
              onchange={(event) => {
                setBidTypeName(event.target.value)
                clearErrorMessage()
              }}
              value={(bidTypeName)}
              helperText={
                errorMessage.BIDDER_TYPE_NAME_TH ||
                errorMessage.IS_DUPLICATE_BIDDER_TYPE_NAME_TH ||
                errorMessage.BIDDER_TYPE_NAME_TH_STRING_BASE ||
                errorMessage.BIDDER_TYPE_NAME_TH_STRING_EMPTY ||
                errorMessage.BIDDER_TYPE_NAME_TH_MAXIMUM_LENGTH ||
                errorMessage.BIDDER_TYPE_NAME_TH_ANY_REQUIRED
              }
              required={true}
              style={{ marginBottom: '1rem' }}
              heading={t('BIDDERTYPE.INPUT.BIDDER_TYPE_NAME_TH')}
            />
            <InputTextField
              key="(bidTypeNameEn)"
              onchange={(event) => {
                setBidTypeNameEn(event.target.value)
                clearErrorMessage()
              }}
              value={(bidTypeNameEn)}
              helperText={
                errorMessage.BIDDER_TYPE_NAME_EN ||
                errorMessage.IS_DUPLICATE_BIDDER_TYPE_NAME_EN ||
                errorMessage.BIDDER_TYPE_NAME_EN_STRING_BASE ||
                errorMessage.BIDDER_TYPE_NAME_EN_STRING_EMPTY ||
                errorMessage.BIDDER_TYPE_NAME_EN_MAXIMUM_LENGTH ||
                errorMessage.BIDDER_TYPE_NAME_EN_ANY_REQUIRED
              }
              required={true}
              heading={t('BIDDERTYPE.INPUT.BIDDER_TYPE_NAME_EN')}
            />
            <div className="mt-3">
              <FilterSelect
                heading={`${t('STATUS.LABEL')}`}
                onchange={(event) => {
                  const value = event.target.value
                  if (value) {
                    setBidTypeStatus(value)
                  }
                }}
                renderValue={() => `${t(`STATUS.${bidTypeStatus}`)}`}
                label={t('STATUS.LABEL')}
                selectId="select-status-update"
                value={status}
                labelId="label-status-update"
                options={[
                  <MenuItem key="1" value="ACTIVE">
                    {t('STATUS.ACTIVE')}
                  </MenuItem>,
                  <MenuItem key="2" value="INACTIVE">
                    {t('STATUS.INACTIVE')}
                  </MenuItem>
                ]}
              />
            </div>
          </div>
        }
        onReset={resetForm}
        onSubmit={bidTypeId ? submitEdit : submit}
        textBtnCancel={t('BIDDERTYPE.BUTTON.CANCEL')}
        textBtnConfirm={t('BIDDERTYPE.BUTTON.SAVE')}
      />

    </>
  );
}
