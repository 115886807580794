/** STYLE */
import { styled } from '@mui/material/styles';
import { colors } from '../../../constants/colors';

/** CONSTANTS */

export const MainContainer = styled("div")(({ theme }) => ({
    height: '100vh',
    overflow: 'auto',
    paddingBottom: 50
}));


export const ButtonGroup = styled('div')(({ theme }) => ({
    width: 368,
    height: 48,
    border: `1px solid ${colors.themeSecondColor}`,
    borderRadius: 4,
    backgroundColor: colors.themeSecondColor,
    margin: 'auto',

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
        alignSelf: 'end',
        height: 56,
        width: '376px',
        marginTop: 0,
        marginRight: 8,
        padding: '8px 0',
    },
    [theme.breakpoints.down(382)]: {
        flexDirection: 'row',
        width: '100%',
        padding: '8px 0 8px 0',
        marginRight: 0
    }
}))

export const UpdateListButton = styled('button')(({ theme }) => ({
    width: 176,
    height: 40,
    border: `1px solid ${colors.themeSecondColor}`,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 400,

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    // '&:hover': {
    //     backgroundColor: colors.themeSecondColor06
    // },

    [theme.breakpoints.down(382)]: {
        fontSize: 14
    }
}))

export const ManualButton = styled('button')(({ theme }) => ({
    width: 176,
    height: 40,
    borderRadius: 4,
    border: 'none',
    fontSize: 16,
    fontWeight: 400,

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    '&:hover': {
        opacity: 0.9
    },
    [theme.breakpoints.down(382)]: {
        fontSize: 14
    }
}))

export const NumberInTable = styled('div')(({ theme }) => ({
    backgroundColor: colors.themeMainColor,
    width: 20,
    color: colors.white,
    borderRadius: 2
}))
export const Boxconteiner = styled('div')(({ theme }) => ({
    paddingLeft: '24px',
    paddingRight: '24px'
}))

export const SliderContainer = styled('div')(({ theme }) => ({
    marginTop: 64,
    width: "100%",
    borderRadius: 8,
}));

export const tableAdmin = styled('div')(({ theme }) => ({
    width: "100%",
    padding: "0px 16px"
}))
export const Boxrow = styled('div')(({ theme }) => ({
    display: "flex", flexDirection: "row", justifyContent: 'center'
}))
export const Boxrowal = styled('div')(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
}))
export const Boxcolumn = styled('div')(({ theme }) => ({
    display: "flex", flexDirection: "column"
}))
export const Boxjustify = styled('div')(({ theme }) => ({
    display: 'flex', justifyContent: 'center'
}))
export const Boxfiled = styled('div')(({ theme }) => ({
    display: "flex", width: "100%", marginTop: "25px"
}))
export const Boxflexcolumn = styled('div')(({ theme }) => ({
    display: "flex",
    marginLeft: "15px",
    marginTop: "22px",
    flexDirection: "column",

}))