import BaseAPI from "../api";

const path = "transfer-lease-agreement";

export interface TranferInterface {
  page?: number;
  pageLimit?: number;
  search?: string;
}

interface CreateTransfer {
  branchId: number,
  transferDate: string,
  transferNo: string,
  leaseAgreementId: number,
  customerSentId: number,
  customerReceiveId: number,
  customerHeirId: number,
  status: string,
  transferLeaseAgreementApprove: {
    transferApproveDate: string,
    transferId: number,
    remark: string,
    employeeId: number,
    status: string
  }
}

interface ApproveTransfer {
  transferApproveDate: string,
  transferId: number,
  remark: string,
  employeeId: number,
  status: string,
  leaseAgreement: {
    leaseAgreementId: number,
    appointmentId: number,
    leaseAgreementNo: string,
    leaseAgreementDaft: string,
    branchId: number,
    leaseAgreementDate: string,
    leaseAgreementRemark: string,
    userId: number,
    prefixId: number,
    firstname: string,
    lastname: string,
    idCard: string,
    birthDay: string,
    mobilePhone: string,
    email: string,
    taxId: string,
    houseNumber: string,
    moo: string,
    village: string,
    soi: string,
    road: string,
    provinceId: number,
    districtId: number,
    subDistrictId: number,
    zipCode: string,
    leaseAgreementTypeId: number,
    leaseAgreementDateStart: string,
    leaseAgreementDateEnd: string,
    leasesTypeId: number,
    amount: number,
    status: string,
    createId: number,
    modifiedId: number,
    leaseAgreementApprove: {
      leaseAgreementApproveId: number,
      approveDate: string,
      leaseAgreementId: number,
      bidderId: number,
      remark: string,
      employeeId: number,
      status: string,
      createId: number,
      modifiedId: number
    }
  }
}

export default class  TranferApi extends BaseAPI {
    static findAll(params:  TranferInterface): Promise<any> {
      return this.api.get(path, { params: { ...params } }).then((res) => res);
    }

    static findById(id:  number): Promise<any> {
      return this.api.get(`${path}/${id}`, )
    }

    static create(params:  CreateTransfer): Promise<any> {
      return this.api.post(path,  params );
    }

    static approve(params:  ApproveTransfer): Promise<any> {
      return this.api.post(`${path}/approve`,  params );
    }

    static updateStatus(params: any, id: number): Promise<any> {
      return this.api.post(`${path}/${id}/status`,  {status: params} );
    }
  }

