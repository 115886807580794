import { useTranslation } from "react-i18next";


//**COMPONENT */

//**CONSTANTS */


//**STYLE */
import * as Style from "./../rentStall.style"
import { imgs } from "../../../../constants/images";
import InputPasswordField from "../../../../component/input/inputPasswordField";




interface PopupRentStallProps {
  name ? :string
  image ? : string
  password ?: string
}

export default function PopupOfficerSignature(props: PopupRentStallProps) {
  const { t } = useTranslation();

  return (
        <>
        <Style.PopupItem>
            <Style.PictureProfile src={imgs.officers}/>
            <Style.OfficerName>{t('น้ำใส ใจดี')}</Style.OfficerName>
            
        </Style.PopupItem>
        <Style.SubMessage>{t('รหัสผ่าน')}</Style.SubMessage>
            <InputPasswordField
            onchange={() => {}}
            onkeyup={(event) =>
                event.key === "Enter" }                     
            />
        
         
        </>
  )
}
