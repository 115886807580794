import { Card } from "react-bootstrap";
import { Box, Divider, Grid } from "@mui/material";

/** TRANSLATION */
import { useTranslation } from "react-i18next";

/** CONSTANTS */
import { colors } from "../../constants/colors";

/** STYLE */
import useStyles, {
  ButtonGoBack,
  TitalForgetPassword,
} from "./style";
import ButtonCustom from "../../component/button/buttonCustom";
import OTPInput from "react-otp-input";

type Props = {
  onClick?: (value: any) => void;
  submit?: (value: any) => void;
  onChange?: any
  otp?: any;
  helperText?: string;
  valueLength?: number;
  refer?: string
};

export default function SendOTP(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={`shadow ${classes.card} forget-password`}>
      <Card.Body>
        <Card.Title>
          <TitalForgetPassword>{t("SEND_OTP.TITLE")}</TitalForgetPassword>
        </Card.Title>
        <Card.Body>
          <>
            <Grid>
              <Card.Text>{`${t("SEND_OTP.PLEASE_INPUT_OTP")}`}</Card.Text>
            </Grid>
            <Grid>
              <br />
            <Box className="w-100 d-flex justify-content-start">{t('SEND_OTP.OTP_REFER')}{props.refer}</Box>
              <br/>
            <OTPInput
            value={props.otp}
            onChange={props.onChange}
            numInputs={6}
            renderSeparator={<span style={{marginLeft: '8px'}}></span>}
            renderInput={(props) => <input {...props} />}
            inputType='tel'
            inputStyle={{
              width: '50px', 
              height: '60px', 
              fontSize: '24px',
              fontWeight: 700,
              color: colors.black,
              backgroundColor: colors.white,
              border: `1px solid ${colors.borderInput}`,
              borderRadius: "4px",
              outline:'none',
          }}
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
          }}
          />
              <br />
            </Grid>
            <Grid className="pb-2">
              <ButtonCustom
                onClick={props.submit}
                type="submit"
                textButton={t("SEND_OTP.BUTTON.SUBMIT")}
              />
            </Grid>
          </>
        </Card.Body>
        <Divider />
        <ButtonGoBack onClick={props.onClick} style={{ color: colors.green }}>
          {t("FORGET_PASSWORD.BUTTON.GO_LOGIN")}
        </ButtonGoBack>
      </Card.Body>
    </Card>
  );
}
