import { useCallback, useEffect, useRef, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import { icons } from "../../constants/images";
import TableCustom from "../../component/table/tableCustom";
import InputTextField from "../../component/input/inputTextField";
import HeaderCard from "../../component/cardCustom/headerCard";
import TableRowCommon from "../../component/table/TableRowCommon";
import ButtonCustom from '../../component/button/buttonCustom';

/** STYLE */
import * as Style from "./systemlog.style"
import LoginApi from "../../api/auth/auth.api";
import { dateTimeToView, dateToView } from "../../utils/date.utils";
import { useFunctionPrint } from "../../component/Print/print";
import { ExportProps } from "../../constants/reportConstant";
import Loading from "../../component/loading/loading";
import InputDatePicker from "../../component/input/inputDatePicker";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import InputNewRangePicker from "../../component/input/InputRangePicker";
import { isApporve, isCreate, isDelete, isExport, isUpdate } from "../../utils/permission.utils";


const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isExport: isExport(),
    isApporve: isApporve()
  }
  
export default function SystemLog() {
    const { t } = useTranslation()
    const printref = useRef<HTMLDivElement>(null);

    const [data, setData] = useState<any[]>([])
    const [dateStart, setDateStart] = useState<string>("");
    const [dateEnd, setDateEnd] = useState<string>("");
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [rowCount, setRowCount] = useState<number>()
    const [sortBy, setSortBy] = useState<string>('modifiedDate')
    const [sortType, setSortType] = useState<string>('DESC')
    const [search, setSearch] = useState<string>('')
    const [printData, setPrintData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [printer, setPrinter] = useState<boolean>(false)

    const [selectedDateRange, setSelectedDateRange] = useState<string[]>([

    ]);

    const handleRangeDate = (value: any[]) => {
        setSelectedDateRange(value);
        setDateStart(moment(value[0]).format("YYYY-MM-DD"));
        setDateEnd(moment(value[1]).format("YYYY-MM-DD"));
    };
    const handleClearRangeDate = () => {
        setSelectedDateRange([]);
        setDateStart("");
        setDateEnd("");
    };


    const loadData = useCallback(async () => {
        let condition: any = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (dateStart) condition = { ...condition, dateStart: dateStart }
        if (dateEnd) condition = { ...condition, dateEnd: dateEnd }

        const res = await LoginApi.logFile(condition)

        if (res.status === 200) {
            // console.log(res.data)
            setRowCount(res.headers['total'])
            setData(res.data)

        } else {
            setRowCount(0)
            setData([])
        }
    }, [page, pageLimit, search, sortBy, sortType, dateStart, dateEnd])


    useEffect(() => {
        loadData()
    }, [page, pageLimit, search, sortBy, sortType, dateStart, dateEnd])

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handlePageLimitChange = (newPageLimit: number) => {
        setPageLimit(newPageLimit);
    };

    const onRequestSort = (sortBy: string, sortType: string) => {
        setSortType(sortType)
        setSortBy(sortBy)
    }


    const headCells = [
        { id: "no", disablePadding: false, label: "#" },
        { id: "name", disablePadding: false, align: "left", label: "ชื่อผู้ใช้งาน" },
        { id: "documentDate", disablePadding: false, label: t("วันที่ใช้บริการ"), },
        { id: "activity", disablePadding: false, label: t("SYSTEM.HEADCELL.IP_ADDRESS") },
        { id: "activity", disablePadding: false, label: t("SYSTEM.HEADCELL.ACTIVITY") },
        { id: "method", disablePadding: false, label: t("SYSTEM.HEADCELL.METOD") },
        { id: "service", disablePadding: false, label: t("SYSTEM.HEADCELL.SERVICE") },
        { id: "ipAddress", disablePadding: false, label: t("SYSTEM.HEADCELL.OS") }
    ]


    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { logFileId, modifiedDate, nameEndpoint, webBrowser, os, ipAddress, method, user } = objData;
        const objRenderData = {
            key: logFileId,
            id: logFileId,
            obj: objData,
            columns: [
                { option: "TEXT", align: "center", label: no },
                { option: "TEXT", align: "left", label: user ? user?.firstname + " " + user?.lastname : "-" },
                { option: "TEXT", align: "left", label: dateTimeToView(modifiedDate) },
                { option: "TEXT", align: "left", label: ipAddress },
                { option: "TEXT", align: "left", label: nameEndpoint || "-" },
                { option: "TEXT", align: "left", label: t(`SYSTEM.METHOD.${method}`) },
                { option: "TEXT", align: "left", label: webBrowser },
                { option: "TEXT", align: "left", label: os },
            ],

        }


        return (<TableRowCommon {...objRenderData} />)
    };

    const dataPrint = useCallback(async () => {
        try {
            let condition: any = {}
            if (page) condition = { ...condition, page: page }
            if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
            if (search) condition = { ...condition, search: search }
            if (sortBy) condition = { ...condition, sortBy: sortBy }
            if (sortType) condition = { ...condition, sortType: sortType }

            setLoading(true);
            const bidderPremilinary = await LoginApi.logFile(condition)

            const [testResult] = await Promise.all([
                bidderPremilinary,
            ]);
            setPrinter(true)
            setPrintData(testResult.data);
        } catch (err: any) {

        } finally {
            setLoading(false);
        }

    }, [LoginApi])

    const handlePrintdataPremilinary = useFunctionPrint({
        content: () => printref.current,
        pageStyle: () => `@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`
    });

    useEffect(() => {
        if (printData && printer) {
            handlePrintdataPremilinary();
            setPrinter(false)
        }
    }, [printData])

    return (
        <>
            <HeaderCard text={t("SYSTEM.TITLE")} />
            <Style.MainContainer>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", flexWrap: 'wrap' }}>
                    <Row style={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', marginBottom: "1rem" }}>
                        <Col className="my-1 d-flex justify-content-center align-items-center">
                            <InputTextField
                                label={t("SYSTEM.FILTER.SEARCH")}
                                onchange={(e: any) => setSearch(e.target.value)}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col className="my-1 d-flex justify-content-center align-items-center">
                            <InputNewRangePicker
                                onchange={handleRangeDate}
                                value={selectedDateRange}
                                onClear={handleClearRangeDate}
                                label="วันที่เริ่มต้น - วันที่สิ้นสุด"
                                allowClear
                            // heading="เลือกช่วงเวลา"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonCustom style={{ width: '100%' }}
                                type="button"
                                textButton={t("SYSTEM.BUTTON.PRINT")}
                                endIcon={<img src={icons.print} />}
                                onClick={dataPrint}
                            />
                        </Col>
                    </Row>
                </Box>
                <Style.TableContainer>
                    <TableCustom
                        headCells={headCells}
                        customScroll
                        page={page}
                        pageLimit={pageLimit}
                        sortType={sortType}
                        sortBy={sortBy}
                        rowCount={rowCount}
                        rowsData={data.map((data: any, no: number) => {
                            return renderData(data, no);
                        })}
                        onSort={onRequestSort}
                        setPageLimit={handlePageLimitChange}
                        setPage={handlePageChange}
                        tableMinWidth={1400}
                    />
                </Style.TableContainer>
            </Style.MainContainer>

            <div className="print-show" ref={printref}>
                <ExportData
                    componant={
                        <>
                            {printer && <div>
                                <table className="w-100 mt-2">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                {t("SYSTEM.HEADCELL.DATE")}
                                            </th>
                                            <th>
                                                {t("SYSTEM.HEADCELL.ACTIVITY")}
                                            </th>
                                            <th>
                                                {t("SYSTEM.HEADCELL.METOD")}
                                            </th>
                                            <th>
                                                {t("SYSTEM.HEADCELL.SERVICE")}
                                            </th>
                                            <th>
                                                {t("SYSTEM.HEADCELL.OS")}
                                            </th>
                                        </tr>
                                    </thead>
                                    {printData && printData.map((d: any, i: number) => {
                                        const setMethod = d?.method === "GET" ?
                                            t('SYSTEM.METHOD.GET') : d?.method === "POST" ?
                                                t('SYSTEM.METHOD.POST') : d?.method === "PATCH" ?
                                                    t('SYSTEM.METHOD.PATCH') : t('SYSTEM.METHOD.DELETE')
                                        const no = page * pageLimit - pageLimit + i + 1
                                        return (
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {no}
                                                    </td>
                                                    <td>
                                                        {dateToView(d.modifiedDate)}
                                                    </td>
                                                    <td>
                                                        {d?.route}
                                                    </td>
                                                    <td>
                                                        {setMethod}
                                                    </td>
                                                    <td>
                                                        {d?.webBrowser}
                                                    </td>
                                                    <td>
                                                        {d?.os}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>}
                        </>
                    }
                />
            </div>

            {loading && <Loading show={loading} type="fullLoading" />}
        </>
    )

}

export function ExportData(props: ExportProps) {
    return (
        <>
            <div className="large">{props.headCells}</div>
            {props.componant}
        </>
    );
}
