import { Box, Container } from "@mui/material";

import { Boxnot, TextDetail, Texthaead } from "./pageNotFoundStyle";
import { background } from "../../constants/images";
import ButtonCustom from "../../component/button/buttonCustom";
import { colors } from "../../constants/colors";
import { useTranslation } from "react-i18next";
export default function PageNotFound() {

  const {t} = useTranslation();
  return (
    <Box>
      <Container maxWidth="lg">
        <Boxnot>
          <Box sx={{ height: "535px" }}>
            <img src={background.notfound} />
          </Box>
          <Box>
            <Texthaead>{t('NOT_FOUND.HEAD')}</Texthaead>
          </Box>
          <Box>
            <TextDetail>
              {t('NOT_FOUND.DETAIL.GO_BACK')}{" "}
              <span style={{ color: colors.themeMainColor }}>
                {t('NOT_FOUND.DETAIL.SYSTEM')}
              </span>
            </TextDetail>
          </Box>
          <Box sx={{ width: "70%" }}>
            <ButtonCustom
              onClick={() => window.location.href='/'}
              textButton={t('NOT_FOUND.BUTTON')}
              style={{ width: "100%" }}
            />
          </Box>
        </Boxnot>
      </Container>
    </Box>
  );
}
