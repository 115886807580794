import common from './common.json'
import login from './auth/login.json'
import resgister from './auth/register.json'
import home from './home/home.json'
import submitStall from './store/submitStall.json'
import deposit from './store/depositPay.json'
import stallAgreement from './store/stallAgreement.json'
import transferStall from './transferStall/transferStall.json'
import admin from './admin/admin.json'
import waterbill from '../en/utilityBill/waterbill.json'
import electricitybill from './utilityBill/electricitybill.json'
import trackbill from './trackBill/trackBill.json'
import repair from './repair/repair.json'
import sue from './sue/sue.json'
import addadmin from './admin/createAdmin.json'
import addrepair from './repair/add/addRepair.json'
import branch from './settings/branch.json'
import debttype from './settings/debttype.json'
import electricity from './settings/electricity.json'
import leasestype from './settings/leasestype.json'
import maintenancetype from './settings/maintenancetype.json'
import newstype from './settings/newstype.json'
import payment from './settings/payment.json'
import petitiontype from './settings/petitiontype.json'
import prefix from './settings/prefix.json'
import relashionship from './settings/relashionship.json'
import water from './settings/water.json'
import stalltype from './settings/stall.json'
import rentStall from './stall/rentStall.json'
import requestStall from './stall/requestStall.json'
import stallReport from './stallInfo/stallReport.json'
import renterDetail from './stallInfo/renterDetail.json'
import agreement from './store/agreement.json'
import certificate from './certificate/certificate.json'
import paybill from './paybill/paybill.json'
import repairTypeManagement from './utilityBill/repairTypeManagement.json'
import systemLog from './systemlog/system.json'
import statistic from './statistic/statistic.json'
import ReportRentStall from './stall/reportRentStall.json'
import user from './settings/user.json'
import pageNotFound from './pageNotFound/detailInfo.json'
import announce from './announce/announce.json'
import detailInfo from './home/detailInfo.json'
import management_utility from './utilityBill/management_utility.json'
import report from './reports/reports.json'

export const mofLocaleEN = {
  ...login,
  ...resgister,
  ...common,
  ...home,
  ...submitStall,
  ...deposit,
  ...stallAgreement,
  ...transferStall,
  ...waterbill,
  ...electricitybill,
  ...trackbill,
  ...repair,
  ...sue,
  ...addadmin,
  ...addrepair,
  ...admin,
  ...branch,
  ...debttype,
  ...electricity,
  ...leasestype,
  ...maintenancetype,
  ...newstype,
  ...payment,
  ...petitiontype,
  ...prefix,
  ...relashionship,
  ...water,
  ...stalltype,
  ...rentStall,
  ...requestStall,
  ...stallReport,
  ...renterDetail,
  ...agreement,
  ...certificate,
  ...paybill,
  ...repairTypeManagement,
  ...systemLog,
  ...statistic,
  ...ReportRentStall,
  ...user,
  ...pageNotFound,
  ...announce,
  ...detailInfo,
  ...management_utility,
  ...report
}
