import BaseAPI from "../api";
import { FindAllInterface } from "../apiModel";

const path = "tranfer-apporve";

export interface ApproveInterface {
  fromEmployeeId: number,
  toEmployeeId: number,
  startDate: string,
  endDate: string,
  status: string
}

export default class ApproveApi extends BaseAPI {
  static findAll(props: FindAllInterface): Promise<any> {
    return this.api.get(path, { params: { ...props } }).then((res) => res)
  }
  static create(props: ApproveInterface) {
    return this.api.post(path, props)
  }
  static findById(id: number): Promise<ApproveInterface> {
    return this.api.get(`${path}/${id}`)
  }
  static findRight(): Promise<ApproveInterface> {
    return this.api.get(`${path}/rights`)
  }
  static update(id: number, body: ApproveInterface) {
    return this.api.patch(`${path}/${id}`, body)
  }
  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`${path}/${id}/status`, { id, status })
  }
  static delete(id: number) {
    return this.api.delete(`${path}/${id}`)
  }
}
