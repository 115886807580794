import { Box, Container, Divider, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Boxcol,
  Boxconteiner,
  Boxdash,
  Boxupload,
  Typographymodal,
  BoxTakePhoto
} from "../waterBill/meterWaterStyle";
import InputTextField from "../../../component/input/inputTextField";
import AutocompleteSelect from "../../../component/select/autoCompleteSelect";

import TableRowCommon from "../../../component/table/TableRowCommon";
import { useTranslation } from "react-i18next";
import TableCustom from "../../../component/table/tableCustom";

import ModalCustom from "../../../component/modalCustom/modalCustom";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "../../../app/slice/modal.slice";
import ButtonCustom from "../../../component/button/buttonCustom";
import { Col, Row } from "react-bootstrap";
import { colors } from "../../../constants/colors";
import HeaderCard from "../../../component/cardCustom/headerCard";
import InputDatePicker from "../../../component/input/inputDatePicker";
import Cloudupload from "../../../assets/icon/cloudupload";
import { useDropzone } from "react-dropzone";
import InputCheckbox from "../../../component/input/inputCheckbox";
import moment from "moment";
import UtilitieApi from "../../../api/utilitie/utilities.api";
import { shortnessDatemonth } from "../../../utils/date.utils";
import ZoneApi from "../../../api/zone/zone.api";
import {
  isCreate,
  isDelete,
  isUpdate,
  isView,
} from "../../../utils/permission.utils";
import { getProfile } from "../../../utils/app.utils";
import WaterApi from "../../../api/setting/water/water.api";
import { ExportProps } from "../../../constants/reportConstant";
import { icons, imgs } from "../../../constants/images";
import { useFunctionPrint } from "../../../component/Print/print";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import FileuploadApi from "../../../api/uploadfile/upload.api";
import Loading from "../../../component/loading/loading";
import { decode } from 'js-base64';
import { swalActive } from "../../../component/notification/swal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFile, faFileExcel, faPenToSquare, faPrint, faQrcode, faTrashAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { QrReader } from 'react-qr-reader';
import { Camera } from "react-camera-pro";
import FilterSelect from "../../../component/select/filterSelect";
import { excel } from "../../../component/excel/lib";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};
export default function UtilityBill() {
  const cameraRef = useRef<any>(null);
  const ref: any = useRef(null);
  const [file, setFile] = useState<any>([]);
  const [utilities, setUtilities] = useState<any>(undefined);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [rowCount, setRowCount] = useState<number>();
  const profile: any = JSON.parse(getProfile() || "{}");
  const [meterstartDate, setMeterStartDate] = useState<any>(Date.now());
  const [searchDate, setSearchDate] = useState<any>("");
  const [search, setSearch] = useState<any>("");
  const [searchZone, setSearchZone] = useState<number | null>(null);
  const [searchStall, setSearchStall] = useState<number | null>(null);
  const [zoneId, setZoneId] = useState<any>("");
  const [stallId, setStaillId] = useState<any>("");
  const [dateModal, setDateModal] = useState<any>("");
  const [meterNumber, setMeterNumber] = useState<number>();
  const [datawaterbill, setDataWaterBill] = useState<any>([]);
  const [zone, setZone] = useState<any>([]);
  const [stall, setStall] = useState<any>([]);
  const [sum, setSum] = useState<number>(0);
  const [open, setOpen] = useState<number>(0);
  const [leaseAgreementId, setLeaseAgreementId] = useState<number>(0);
  const [check, setCheck] = useState<boolean>(false);
  const [img, setImg] = useState<any>();
  const [waterType, setWaterType] = useState<any[]>([]);
  const [detailWaterbill, setDetailWaterBill] = useState<any>({});
  const componentRef = useRef<any>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [print, setPrint] = useState<any>();
  const [qrCodeData, setQrCodeData] = useState<any>("")
  const [utilityType, setUtilityType] = useState<any[]>([])
  const [utilityTypeId, setUtilityTypeId] = useState<number>()
  const [utilitiesDay, setUtilitiesDay] = useState<boolean>(false)
  const [excelData, setExcelData] = useState<any>([])

  const getZone = async () => {
    const condition = {
      page: 1,
      pageLimit: 50,
      status: "CONTRACT",
    };
    await ZoneApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setZone(res?.data);
      }
    });
  };

  const getWaterType = async () => {
    const condition = {
      page: 1,
      pageLimit: 1,
      sortType: "ASC",
      status: "ACTIVE",
      utilitiesTypeId: 2,

    };
    await WaterApi.findAll(condition).then((res) => {
      if (res.status === 200) {
        setWaterType(res.data)
      } else {
        setWaterType([])
      }
    });
  };

  const getUtilities = async () => {
    await UtilitieApi.day().then((res: any) => {
      const day = moment().date()
      const getDay = res.data[0]
      setUtilitiesDay(day >= getDay?.startDate && day <= getDay?.endDate)
    }
    ).catch((error: any) => console.log(error))
  }
  useEffect(() => {
    getUtilities()
  }, [])

  const loadData = useCallback(async () => {
    let condition: any = {}
    if (page) condition = { ...condition, page: page };
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search !== "") condition = { ...condition, search: search };
    if (searchZone !== null) condition = { ...condition, zoneId: searchZone };
    if (searchStall !== null) condition = { ...condition, stallId: searchStall };

    const res = await UtilitieApi.findAll(condition);

    if (res.status === 200) {
      setRowCount(res.headers["total"]);
      setDataWaterBill(res.data);
    } else {
      setRowCount(0);
      setDataWaterBill([]);
    }
  }, [page, pageLimit, searchZone, searchStall, search]);

  const loadDataExcel = useCallback(async () => {
    let condition: any = { export: 1 }
    // if (page) condition = { ...condition, page: page };
    // if (pageLimit) condition = { ...condition, pageLimit: pageLimit };
    if (search !== "") condition = { ...condition, search: search };
    if (searchZone !== null) condition = { ...condition, zoneId: searchZone };
    if (searchStall !== null) condition = { ...condition, stallId: searchStall };

    const res = await UtilitieApi.findAll(condition);

    if (res.status === 200) {
      setExcelData(res.data);
    } else {
      setExcelData([]);
    }
  }, [page, pageLimit, searchZone, searchStall, search]);

  useEffect(() => {
    loadData();
    getZone();
    getWaterType();
  }, [page, pageLimit, searchZone, searchStall, search]);

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    { id: "date", disablePadding: false, label: t("DETAIL.DATE_TIME") },
    {
      id: "date",
      disablePadding: false,
      align: "left",
      label: t("DETAIL.NO"),
    },
    { id: "date", disablePadding: false, align: "left", label: t("MANGEMENT_UTILITY.HEADER.UTILITY_TYPE") },
    {
      id: "zone",
      disablePadding: false,
      align: "left",
      label: t("DETAIL.ZONE"),
    },
    {
      id: "rental",
      disablePadding: false,
      align: "left",
      label: t("DETAIL.STALL"),
    },
    {
      id: "enddate",
      disablePadding: false,
      align: "center",
      label: t("เลขที่แผง"),
    },

    // {
    //   id: "enddate",
    //   disablePadding: false,
    //   align: "left",
    //   label: t("DETAIL.DATE_OVERDUE"),
    // },
    {
      id: "price",
      disablePadding: false,
      align: "left",
      label: t("DETAIL.SUM"),
    },

    {
      id: "action",
      disablePadding: false,
      align: "center",
      label: t("DETAIL.ACTION"),
    },
  ];

  const handleClick = (val: any) => {
    dispatch(showModal());
    setOpen(1);
  };
  const handleSubmitedit = (val: any) => {
    const condition = {
      branchId: detailWaterbill?.branchId,
      utilitiesNo: detailWaterbill?.utilitiesNo,
      utilitiesTypeId: detailWaterbill?.utilitiesType.utilitiesTypeId,
      utilitiesDate: detailWaterbill?.utilitiesDate,
      utilitiesMonth: Number(moment(detailWaterbill?.utilitiesDate).format("M")),
      leaseAgreementId: detailWaterbill?.leaseAgreementId,
      zoneId: detailWaterbill.zoneId,
      stallId: detailWaterbill.stallId,
      utilitiesDetail: {
        sequential: +detailWaterbill?.utilitiesDetail.sequential,
        machineId: +detailWaterbill?.utilitiesDetail.machineId,
        serviceId: +detailWaterbill?.utilitiesDetail.serviceId,
        beforeNumber: +detailWaterbill?.utilitiesDetail.beforeNumber,
        afterNumber: +detailWaterbill?.utilitiesDetail.afterNumber,
        number: +detailWaterbill?.utilitiesDetail?.number,
        amount: +detailWaterbill?.utilitiesDetail?.amount,
        file: "",
        status: "ACTIVE",
      },
      status: "ACTIVE",
    };
    UtilitieApi.update(condition, +detailWaterbill.utilitiesId).then((res) => {
      if (res.status === 200) {
        if (res?.data?.refId) {
          let ref = res.data.refId || null;
          const formData = new FormData();
          formData.append("refId", `${ref}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          formData.append("file", img);

          FileuploadApi.uploadfile(formData).then((result) => {
            dispatch(closeModal());
            loadData();
          });
        }
      }
    });
  };
  const handleDetail = (id: number) => {
    UtilitieApi.detail(id).then((res) => {
      if (res.status === 200) {
        setDetailWaterBill(res.data);
        dispatch(showModal());
        setOpen(2);
      }
    });
  };
  const getTestPdfData = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const getTestDetails = UtilitieApi.detail(id);

        const [testResult] = await Promise.all([getTestDetails]);
        setPrint(testResult.data);
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    },
    [UtilitieApi]
  );
  const handleEdit = (id: number) => {
    UtilitieApi.detail(id).then((res) => {
      if (res.status === 200) {
        setDetailWaterBill(res.data);
        dispatch(showModal());
        setOpen(3);
      }
    });
  };
  const handlePrint = useFunctionPrint({
    content: () => componentRef.current,
    pageStyle: () =>
      `@page { size: 793.7007874px 1122.519685px ;  margin: 0.5cm; } @media print { body { -webkit-print-color-adjust: exact; }`,
  });

  useEffect(() => {
    if (print) {
      handlePrint();
    }
  }, [print]);

  const handleSubmit = () => {
    const condition = {
      branchId: 1,
      utilitiesTypeId: utilityTypeId,
      utilitiesDate: moment(meterstartDate).format("YYYY-MM-DD"),
      utilitiesMonth: +moment(Date.now()).format("M"),
      leaseAgreementId: +leaseAgreementId,
      zoneId: stallId?.zoneId || qrCodeData?.zoneId,
      stallId: stallId?.stallId || qrCodeData?.stallId,
      utilitiesDetail: {
        sequential: 1,
        machineId: stallId?.meterNumber?.find(
          (item: any) => item.meterType === utilityType
        ).meterId || +stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
          (item: any) => item.meterType === utilityType
        ).meterId,
        serviceId: utilityTypeId,
        beforeNumber: +utilities|| +stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
          (item: any) => item.meterType === utilityType
        ).utilities || 0,
        afterNumber: Number(meterNumber),
        number: sum,
        amount:
          Number(sum) > Number(waterType[0]?.waterUsed)
            ? Number(Number(sum) - Number(waterType[0]?.waterUsed)) * Number(waterType[0]?.pricePerUnit) +
            Number(waterType[0]?.amount)
            : Number(waterType[0]?.amount) || 0,
        // +sum > +waterType[0]?.waterUsed
        //   ? sum * waterType[0]?.pricePerUnit + +waterType[0]?.amount
        //   : waterType[0]?.amount,
        // amount: +sum ,
      },
    };
    if (!img) {
      return notiError('กรุณาเพิ่มไฟล์')
    }

    UtilitieApi.create(condition).then((res) => {
      if (res.status == 201) {
        if (res?.data?.refId && img) {
          let ref = res.data.refId || null;
          const formData = new FormData();
          formData.append("refId", `${ref}`);
          formData.append("fileType", `${res?.data?.fileType}`);
          formData.append("file", img, "image.jpg");

          FileuploadApi.uploadfile(formData)
          dispatch(closeModal());
          setSum(0);
          setMeterStartDate("");
          setMeterNumber(0);
          setZoneId("");
          setStaillId("");
          setCheck(false);
          setQrCodeData("");
          setUtilities(undefined)
          setImg(null)
          loadData();
        }
      } else {
        notiError('กรุณาใส่ข้อมูลให้ครบ')
      }
    })
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const fileName = file?.name.split(".");
      if (
        fileName[fileName?.length - 1] == "jpg" ||
        fileName[fileName?.length - 1] == "jpeg" ||
        fileName[fileName?.length - 1] == "png"
      ) {
        setImg(acceptedFiles[0]);
      } else {
        notiError(
          "สามารถอัพโหลดเฉพาะ jpg jpeg png เท่านั้น",
          "",
          null,
          null
        );
      }
    },

    [file]
  );

  const onDelete = (data: any) => {

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MANGEMENT_UTILITY.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MANGEMENT_UTILITY.ALERT.CONFIRM_DELETE')}</p>`,
      (res: any) => {
        if (res) {
          UtilitieApi.delete(data?.utilitiesId)
            .then((res) => {
              notiSuccess(t('MANGEMENT_UTILITY.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('MANGEMENT_UTILITY.MESSAGE.ERROR.DELETE'), '', null, null)
            })
        }
      }
    )
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const renderData = (objData: any, index: any) => {
    const no = page * pageLimit - pageLimit + index + 1;

    const {
      utilitiesId,
      utilitiesDate,
      utilitiesNo,
      utilitiesDetail,
      stall,
      zone,
      createDate,
      utilitiesType,
      rental,
    } = objData;

    const objRenderData = {
      key: utilitiesId,
      id: utilitiesId,
      obj: objData,

      columns: [
        {
          option: "TEXT",
          align: "center",
          label: no,
        },
        {
          option: "TEXT",
          align: "left",
          label: moment(createDate).format("DD/MM/YYYY"),
        },
        {
          option: "TEXT",
          align: "left",
          label: utilitiesNo,
        },
        {
          option: "TEXT",
          align: "left",
          label: utilitiesType?.utilitiesTypeName,
        },
        {
          option: "TEXT",
          align: "left",
          label: zone?.zoneName || "-",
          // label: stall?.map((e: any) => e?.zone?.zoneName) || "-",
        },

        {
          option: "TEXT",
          align: "left",

          label: stall?.stallName || "-",
          // label: stall?.map((e: any) => e?.stallName) || "-",
        },
        {
          option: "TEXT",
          align: "center",
          label: stall?.stallNumber,
        },
        // {
        //   option: "TEXT",
        //   align: "left",
        //   style: { color: colors.red },
        //   label: moment(utilitiesDate).format("DD/MM/YYYY"),
        // },

        {
          option: "PRICE",
          align: "left",
          label: utilitiesDetail?.amount,
        },
        {
          option: "COMPONENT",
          align: "center",
          component: (
            <div className="d-flex align-items-center justify-content-center mx-auto w-100">
              <Tooltip title="พิมพ์เอกสาร">
                <IconButton onClick={() => {
                  getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="รายละเอียด">
                <IconButton onClick={() => {
                  handleDetail(utilitiesId)
                  // getTestPdfData(utilitiesId)
                }}>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: colors.themeMainColor
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => getTestPdfData(utilitiesId)}
                />
              </div>
              <div className="pr-3">
                <FontAwesomeIcon
                  icon={faFile}
                  style={{
                    cursor: "pointer"
                    , color: colors.themeMainColor
                  }}
                  onClick={() => handleDetail(utilitiesId)}
                />
              </div> */}
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{
                    cursor: JSON.parse(decode(profile))?.roleId === 4 ? "none" : "pointer"
                    , color: JSON.parse(decode(profile))?.roleId === 4 ? colors.gray :colors.themeMainColor
                  }}
                  onClick={() => handleEdit(utilitiesId)}
                />
              </div> */}
              <Tooltip title="ลบรายการ">
                <IconButton disabled={JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay} onClick={() => {
                  onDelete(objData)
                }}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      color: (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) ? colors.gray : colors.red
                    }}
                  />
                </IconButton>
              </Tooltip>
              {/* <div className="pr-3">
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{
                    cursor: (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) ? "none" : "pointer"
                    , color: (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) ? colors.gray : colors.red
                  }}
                  onClick={() => {
                    if (JSON.parse(decode(profile))?.roleId === 4 || !utilitiesDay) { } else { onDelete(objData) }
                  }}
                />
              </div> */}
            </div>
          ),
        },
      ],
    };
    return (
      <TableRowCommon {...objRenderData} />
    );
  };

  const handleCapture = (dataUri: any) => {
    const binaryImageData = convertToBinary(dataUri, 'captured.jpg');
    setImg(binaryImageData);
  };

  const convertToBinary = (dataUri: any, fileName: string) => {
    const base64Data = dataUri.replace('data:image/jpeg;base64,', '');
    const binaryData = atob(base64Data);
    const byteArray = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    const file = new File([blob], fileName, { type: 'image/jpeg' });

    return file;
  };

  useEffect(() => {
    if (qrCodeData !== "") {
      setLeaseAgreementId(qrCodeData?.leaseAgreementId)
      setZoneId(qrCodeData?.zoneId)
      setStall(zone?.find((item: any) => item?.zoneId === qrCodeData?.zoneId)?.stall)
      setStaillId(qrCodeData?.stallId)

    }
  }, [qrCodeData]);
  const downloadExcel = () => {
    let p = document.getElementById("excel-download");
    excel({ tableExportRef: p, filename: "ค่าน้ำค่าไฟ", sheetName: 'ค่าน้ำค่าไฟ' }).onDownload()
  }
  useEffect(() => {
    if (excelData?.length > 0) {
      downloadExcel()
    }
  }, [excelData])
  return (
    <Box>
      <HeaderCard text={t("MANGEMENT_UTILITY.HEADER.UTILITY")} />
      <Boxconteiner>

        <Row className="justify-content-end">
          <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
            {" "}
            <InputTextField
              placeholder={t("DETAIL.SEARCH")}
              style={{ marginRight: "24px" }}
              onchange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
            {" "}
            <InputDatePicker
              placeholder={t("MANGEMENT_UTILITY.HEADER.DATE_TIME")}
              value={searchDate}
              dateFormat="DD/MM/YYYY"
              onClear={() => setMeterStartDate("")}
              onChange={(e) => setSearchDate(e)}
            />
          </Col>
          <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
            {" "}
            <AutocompleteSelect
              options={zone || []}
              value={zone
                ? stall?.find(
                  (data: any) => data?.zoneId === searchZone
                )
                : null}
              placeholder={t("DETAIL.ZONE_SEARCH")}
              getOptionLabel={(option: any) => option.zoneName}
              labelId="contracttype"
              onChange={(event, newValue) => {
                setSearchZone(newValue?.zoneId);
                setStall(newValue?.stall);
              }}
            />
          </Col>

          <Col className="my-2" lg={2} md={6} sm={12} xs={12}>
            <AutocompleteSelect
              options={stall || []}
              value={stall
                ? stall?.find(
                  (data: any) => data?.stallId === searchStall
                )
                : null}
              placeholder={t("DETAIL.STALL_SEARCH")}
              getOptionLabel={(option: any) => option.stallName}
              labelId="contracttype"
              onChange={(event, newValue) => {
                setSearchStall(newValue?.stallId);
              }}
            />
          </Col>

          <Col lg={2} md={6} sm={12} xs={12}></Col>
          <Col className="my-2 " item lg={2} md={6} sm={12} xs={12}>
            {JSON.parse(decode(profile))?.roleId !== 4 && <ButtonCustom
              onClick={loadDataExcel}
              // onClick={downloadExcel}
              textButton={t("Excel")}
              style={{
                width: "100%",
                '&.MuiButton-contained': {
                  // backgroundColor:  `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                  backgroundColor: `${colors.lightOrange} !important`,
                  ':hover': {
                    // backgroundColor: `${roleId !== 4 ? '#5685f5' : 'red'} !important`,
                    backgroundColor: `${colors.lightOrange} !important`,
                  }
                }
              }}
              // startIcon={<img src={icons.print} />}
              startIcon={<FontAwesomeIcon icon={faFileExcel} />}
              disabled={permissions?.isCreate.disabled || !utilitiesDay}
            />}
          </Col>
          <Col className="my-2 " lg={2} md={6} sm={12} xs={12}>
            {JSON.parse(decode(profile))?.roleId !== 4 && <ButtonCustom
              onClick={handleClick}
              textButton={t("MANGEMENT_UTILITY.BUTTON.CREATE_BILL")}
              style={{ width: "100%" }}
              startIcon={<img src={icons.add} />}
              disabled={permissions?.isCreate.disabled || !utilitiesDay}
            />}
          </Col>

        </Row>


        <TableCustom
          headCells={headCells}
          page={page}
          pageLimit={pageLimit}
          sortType={"ASC"}
          sortBy=""
          rowCount={rowCount}
          rowsData={datawaterbill && datawaterbill.map((d: any, index: any) => {
            return renderData(d, index);
          })}
          onSort={() => { }}
          setPageLimit={(newValue) => setPageLimit(newValue)}
          setPage={(newValue) => setPage(newValue)}
          tableMinWidth={1200}
        />
      </Boxconteiner>
      {open == 1 && (
        <ModalCustom
          title=""
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          onClose={() => {
            setImg(null)
            setZoneId(null)
            setStaillId(null)
            setSum(0)
            setMeterNumber(undefined);
            setUtilities(undefined)

          }}
          component={
            <Box sx={{ marginTop: "19px" }}>
              <Container maxWidth="xl">
                <Box>
                  <Row>
                    <Col xs={12} className="mb-2">
                      <div className="d-flex justify-content-center">
                        <ButtonCustom
                          textButton="สแกน QR code"
                          onClick={() => {
                            setOpen(5)
                          }}
                        />
                      </div>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 mt-4 mb-1" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={2} sm={5} xs={5} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} :</Typography>
                    </Col>
                    <Col xl={4} sm={7} xs={7} className="my-3">
                      <Typography>{moment(meterstartDate).format("DD/MM/YYYY")}</Typography>
                    </Col>
                    <Col xl={3} sm={5} xs={5} className="my-3">
                      <Typography>{t("บันทึกของเดือน")} :</Typography>
                    </Col>
                    <Col xl={3} sm={7} xs={7} className="my-3">
                      <Typography>{shortnessDatemonth(dateModal)}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={3} xs={5} className="my-2">
                      <Typography>{t("MANGEMENT_UTILITY.MODAL.UTILITY_TYPE")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-2">
                      <FilterSelect
                        onchange={(event) => {
                          const value = event.target.value
                          if (value) {
                            setUtilityType(value)

                          }
                        }}
                        renderValue={() => utilityType ? `${t(`STATUS.${utilityType}`)}` : ''}
                        label={t('STATUS.LABEL')}
                        selectId="select-status"
                        value={utilityTypeId}
                        labelId="label-status"
                        options={[
                          <MenuItem key="2" value="WATER" onClick={() => setUtilityTypeId(2)}>
                            {t('STATUS.WATER')}
                          </MenuItem>,
                          <MenuItem key="1" value="ELECTRICITY" onClick={() => setUtilityTypeId(1)}>
                            {t('STATUS.ELECTRICITY')}
                          </MenuItem>,
                        ]}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.ZONE_STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={zone}
                        value={zoneId || null}
                        getOptionLabel={(option: any) => `${option.stallNumber} (${option.stallName})` || `${qrCodeData.stallNumber} (${qrCodeData.stallName})`}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setZoneId(newValue);
                          setStall(newValue?.stall);
                          setStaillId("");
                          setUtilities(undefined)
                          setSum(0)
                          setMeterNumber(undefined)
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("DETAIL.STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={stall || []}
                        value={stallId || null}
                        getOptionLabel={(option: any) => option.stallName || qrCodeData?.stallName}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setLeaseAgreementId(newValue?.leaseAgreementId);
                          setStaillId(newValue);
                          setUtilities(newValue?.meterNumber?.find(
                            (item: any) => item.meterType === utilityType
                          )?.utilities || undefined)
                          setSum(0)
                          setMeterNumber(undefined)
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.METER_NO")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <Typography>
                        {stallId?.meterNumber
                          ?.filter((item: any) => item.meterType === "WATER")
                          .map((e: any) => e?.meterNumber)}{" "}
                      </Typography>
                    </Col>
                    <Col sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>

                    <Col xl={12} sm={12}>
                      <Typography sx={{ fontWeight: "500" }}>{t("ข้อมูลการใช้")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.LAST_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {/* {stallId?.meterNumber?.find(
                          (item: any) => item.meterType === "WATER"
                        )?.utilities || 0} */}
                          <InputTextField
                          onchange={(e: any) => {
                            setUtilities(e.target.value);
                          }}
                          onkeypress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={stallId?.meterNumber?.find(
                            (item: any) => item.meterType === utilityType
                          )?.utilities}
                          value={utilities || ''}
                        />
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.UNIT_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <InputTextField
                        onchange={(e: any) => {
                          setMeterNumber(e.target.value);
                          if (stallId?.meterNumber !== 0) {
                            setSum(
                              Number(e.target.value) -
                              Number(utilities) || Number(e.target.value) - stall?.find((item: any) => item.stallId === qrCodeData?.stallId)?.meterNumber?.find(
                                (item: any) => item.meterType === utilityType
                              ).utilities || 0
                            );
                          }
                        }}
                        onkeypress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.USE_SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>{sum || 0}</Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("DETAIL.SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      {sum && (
                        <Typography>
                          {Number(sum) > Number(waterType[0]?.waterUsed)
                            ? Number(Number(sum) - Number(waterType[0]?.waterUsed)) * Number(waterType[0]?.pricePerUnit) +
                            // ? Number(sum) * Number(waterType[0]?.pricePerUnit) +
                            Number(waterType[0]?.amount)
                            : Number(waterType[0]?.amount) || 0}
                        </Typography>
                      )}
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.BATH")}</Typography>
                    </Col>

                  </Row>

                  <Boxupload>
                    <BoxTakePhoto>
                      <Boxcol onClick={() => setOpen(4)}>
                        <FontAwesomeIcon
                          icon={faCamera}
                          style={{
                            height: '50px', width: '50px'
                          }}
                        />
                        <Typography
                          sx={{ marginTop: "24px", fontSize: "18px" }}
                        >
                          ถ่ายรูป
                        </Typography>
                      </Boxcol>
                    </BoxTakePhoto>
                    <Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {img ? (
                          <Boxcol>
                            <img
                              src={URL.createObjectURL(img)}
                              width={250}
                              height={250}
                            />
                          </Boxcol>
                        ) : (
                          <Boxcol>
                            <Cloudupload />
                            <Typography
                              sx={{ marginTop: "24px", fontSize: "18px" }}
                            >
                              แนบไฟล์เอกสาร JPEG,JPG, PNG
                            </Typography>
                          </Boxcol>
                        )}
                      </div>
                    </Boxdash>
                  </Boxupload>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <InputCheckbox
                      onChange={(e) => setCheck(e.target.checked)}
                    />
                    <Typography sx={{ marginLeft: "12px" }}>
                      {t("MODAL.CHECK_INFO")}
                    </Typography>
                  </Box>
                  <ButtonCustom
                    textButton="บันทีก/เสร็จสิ้น"
                    onClick={handleSubmit}
                    disabled={check ? false : true}
                    btnStyle={{ width: "100%" }}
                  />
                </Box>
              </Container>
            </Box>
          }
        />
      )}
      {open == 2 && (
        <ModalCustom
          title={t("ใบแจ้ง" + detailWaterbill?.utilitiesType?.utilitiesTypeName)}
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          component={
            <Box sx={{ marginTop: "19px" }}>
              <Container maxWidth="xl">
                <Box sx={{ marginBottom: "24px" }}>
                  <Typographymodal>{t("MODAL.DETAIL_STALL")}</Typographymodal>
                </Box>
                <Box>
                  <Row>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.ZONE_STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={zone}
                        value={
                          zone.find(
                            (e: any) => e.zoneId === detailWaterbill?.zoneId
                          ) || null
                        }
                        getOptionLabel={(option: any) => option.zoneName}
                        labelId="contracttype"
                        disabled={true}
                        onChange={(event, newValue) => {
                          setZoneId(newValue);
                          setStall(newValue?.stall);
                          setStaillId("");
                        }}
                      />
                    </Col>
                    <Col xl={2} xs={5} className="my-3">
                      <Typography>{t("DETAIL.STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={detailWaterbill?.stall || []}
                        value={
                          detailWaterbill?.stall || null
                          // detailWaterbill?.stall?.find(
                          //   (e: any) => e.stallId === detailWaterbill?.stallId
                          // ) || null
                        }
                        getOptionLabel={(option: any) => option.stallName}
                        disabled
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setStaillId(newValue);
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.METER_NO")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <Typography>{detailWaterbill?.utilitiesNo}</Typography>
                    </Col>

                    <Col sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>

                    {/* <Col lg={12} md={12} xs={12} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} </Typography>
                    </Col> */}
                    <Col xl={2} sm={5} xs={5} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} :</Typography>
                    </Col>
                    <Col xl={4} sm={7} xs={7} className="my-3">
                      <Typography>
                        {moment(detailWaterbill?.utilitiesDate).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Col>
                    <Col xl={3} sm={5} xs={5} className="my-3">
                      <Typography>{t("บันทึกของเดือน")} :</Typography>
                    </Col>
                    <Col xl={3} sm={7} xs={7} className="my-3">
                      <Typography>
                        {shortnessDatemonth(detailWaterbill?.utilitiesDate)}
                      </Typography>
                    </Col>
                    <Col className="my-3" xl={2} sm={5} xs={5} >
                      {t("สร้างโดย")} :
                    </Col>
                    <Col item xl={4} sm={7} xs={7} className="my-3">
                      <Typography>{`${detailWaterbill?.createdBy?.firstname} ${detailWaterbill?.createdBy?.lastname}` || "-"}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={12} sm={12}>
                      <Typography sx={{ fontWeight: "500" }}>{t("ข้อมูลการใช้")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.LAST_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(
                          detailWaterbill?.utilitiesDetail?.beforeNumber
                        )}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.UNIT_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(
                          detailWaterbill?.utilitiesDetail?.afterNumber
                        )}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.USE_SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(detailWaterbill?.utilitiesDetail?.number)}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("DETAIL.SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {detailWaterbill?.utilitiesDetail?.amount}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.BATH")}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                  </Row>
                  {detailWaterbill?.utilitiesDetail?.fileUpload && (
                    <Boxupload>
                      <Boxdash>
                        <Boxcol>
                          <img src={process.env.REACT_APP_FILE_URL + detailWaterbill?.utilitiesDetail?.fileUpload?.filePath
                            + detailWaterbill?.utilitiesDetail?.fileUpload?.fileName
                          } width={250} height={250} />
                        </Boxcol>
                      </Boxdash>
                    </Boxupload>
                  )}
                </Box>
              </Container>
            </Box>
          }
        />
      )}
      {open == 3 && (
        <ModalCustom
          title={t("MODAL.INVOICE_WATER")}
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          component={
            <Box sx={{ marginTop: "19px" }}>
              <Container maxWidth="xl">
                <Box sx={{ marginBottom: "24px" }}>
                  <Typographymodal>{t("MODAL.DETAIL_STALL")}</Typographymodal>
                </Box>
                <Box>
                  <Row>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.ZONE_STALL")} :</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={zone}
                        value={
                          zone.find(
                            (e: any) => e.zoneId === detailWaterbill?.zoneId
                          ) || null
                        }
                        getOptionLabel={(option: any) => option.zoneName}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setZoneId(newValue);
                          setStall(newValue?.stall);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            zoneId: newValue?.zoneId,
                            stall: newValue?.stall,
                          });

                          setStaillId("");
                        }}
                      />
                    </Col>
                    <Col xl={2} xs={5} className="my-3">
                      <Typography>{t("DETAIL.STALL")}</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <AutocompleteSelect
                        options={stall || []}
                        value={
                          detailWaterbill?.stall?.find(
                            (e: any) => e.stallId === detailWaterbill?.stallId
                          ) || null
                        }
                        getOptionLabel={(option: any) => option.stallName}
                        labelId="contracttype"
                        onChange={(event, newValue) => {
                          setStaillId(newValue);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            stallId: newValue?.stallId,
                            leaseAgreementId: newValue?.leaseAgreementId,
                            utilitiesNo: newValue?.meterNumber[1]?.meterNumber,
                          });
                        }}
                      />
                    </Col>
                    <Col xl={3} xs={5} className="my-3">
                      <Typography>{t("MODAL.METER_NO")}</Typography>
                    </Col>
                    <Col xl={3} xs={7} className="my-3">
                      <Typography>{detailWaterbill?.utilitiesNo}</Typography>
                    </Col>

                    <Col sm={12}>
                      {/* <div style={{width:'100%', border:'1px solid #CBCFD6',margin:'30px'}}/> */}
                      <Divider className="mx-0 my-4" />
                    </Col>

                    <Col lg={12} md={12} xs={12} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")} </Typography>
                    </Col>
                    <Col xl={2} sm={5} xs={5} className="my-3">
                      <Typography>{t("MODAL.SUMMIT_DATE")}</Typography>
                    </Col>
                    <Col xl={4} sm={7} xs={7} className="my-3">
                      <InputDatePicker
                        dateFormat="DD/MM/YY"
                        key={"date-appointment"}
                        label=""
                        value={detailWaterbill?.utilitiesDate}
                        onClear={() => setMeterStartDate("")}
                        onChange={(e: any) => {
                          setMeterStartDate(moment(e).format("YYYY-MM-DD"));
                          setDateModal(e);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            utilitiesDate: moment(e).format("YYYY-MM-DD"),
                          });
                        }}
                      />
                    </Col>
                    <Col xl={3} sm={5} xs={5} className="my-3">
                      <Typography>{t("บันทึกของเดือน")}</Typography>
                    </Col>
                    <Col xl={3} sm={7} xs={7} className="my-3">
                      <Typography>
                        {shortnessDatemonth(detailWaterbill?.utilitiesDate)}
                      </Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                    <Col xl={12} sm={12}>
                      <Typography>{t("MODAL.DETAIL_WATER")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.LAST_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(
                          detailWaterbill?.utilitiesDetail?.beforeNumber
                        )}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.UNIT_NUMBER")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <InputTextField
                        value={detailWaterbill?.utilitiesDetail?.afterNumber}
                        onchange={(e) => {
                          setMeterNumber(e.target.value);
                          setDetailWaterBill({
                            ...detailWaterbill,
                            utilitiesDetail: {
                              ...detailWaterbill.utilitiesDetail,

                              afterNumber: e.target.value,
                              number:
                                +e.target.value -
                                +Math.floor(
                                  detailWaterbill?.utilitiesDetail?.beforeNumber
                                ),

                              amount:
                                +e.target.value -
                                  +Math.floor(
                                    detailWaterbill?.utilitiesDetail
                                      ?.beforeNumber
                                  ) >
                                  +waterType[0].waterUsed
                                  ? (+e.target.value -
                                    +Math.floor(
                                      detailWaterbill?.utilitiesDetail
                                        ?.beforeNumber
                                    )) *
                                  +waterType[0].pricePerUnit +
                                  +waterType[0].amount
                                  : +waterType[0].amount,
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("MODAL.USE_SUM")}</Typography>
                    </Col>
                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {Math.floor(detailWaterbill?.utilitiesDetail?.number)}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.UNIT")}</Typography>
                    </Col>
                  </Row>

                  <Row className="my-2">
                    <Col xl={4} sm={5} xs={5} className="my-2">
                      <Typography>{t("DETAIL.SUM")}</Typography>
                    </Col>

                    <Col xl={4} sm={4} xs={4} className="my-2">
                      <Typography>
                        {detailWaterbill?.utilitiesDetail?.amount}
                      </Typography>
                    </Col>
                    <Col xl={4} sm={3} xs={3} className="my-2">
                      <Typography>{t("MODAL.BATH")}</Typography>
                    </Col>
                    <Col xl={12} sm={12}>
                      <Divider className="mx-0 my-4" />
                    </Col>
                  </Row>
                  <Boxupload>
                    <Boxdash>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {img ? (
                          <Boxcol>
                            <img src={img} width={250} height={250} />
                          </Boxcol>
                        ) : (
                          <Boxcol>
                            <Cloudupload />
                            <Typography
                              sx={{ marginTop: "24px", fontSize: "18px" }}
                            >
                              แนบไฟล์เอกสาร  JPEG,JPG, PNG
                            </Typography>
                          </Boxcol>
                        )}
                      </div>
                    </Boxdash>
                  </Boxupload>
                  <ButtonCustom
                    textButton="บันทีก/เสร็จสิ้น"
                    onClick={handleSubmitedit}
                    btnStyle={{ width: "100%", marginTop: "24px" }}
                  />
                </Box>
              </Container>
            </Box>
          }
        />
      )}
      {open == 4 && (
        <ModalCustom
          title=""
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          onClose={() => {
            setOpen(1)
            dispatch(showModal())
          }}
          component={
            <Box >
              <ButtonCustom
                textButton="สลับกล้อง"
                onClick={() => {
                  cameraRef.current.switchCamera();
                }}

                btnStyle={{ width: "100%" }}
              />
              <Camera ref={cameraRef}
                errorMessages={{}}
                facingMode='environment'
                aspectRatio={1 / 1}
              />
              <div className="mt-2 w-100 d-flex justify-content-center">
                <ButtonCustom
                  textButton="ถ่ายรูป"
                  onClick={() => handleCapture(cameraRef?.current?.takePhoto())}
                  btnStyle={{ width: "100%" }}
                />
              </div>
            </Box>
          }
        />
      )}

      {open == 5 && (
        <ModalCustom
          title=""
          fullscreen="md-down"
          size="lg"
          closeButton={true}
          onClose={() => {
            setOpen(1)
            dispatch(showModal())
          }}
          component={
            <Box>
              <QrReader
                onResult={(result: any) => {
                  if (result) {
                    ref?.current?.stopCamera()
                    setQrCodeData(JSON.parse(result?.text))
                    setOpen(1)

                  }
                }}
                containerStyle={{ width: '100%' }}
                constraints={{ facingMode: 'environment' }} />
            </Box>
          }
        />
      )}
      {loading && <Loading show={loading} type="fullLoading" />}
      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={""}
          componant={
            <>
              <div className="pt-3 text-align-center ">
                <>
                  <div className="row mt-4 ">
                    <div className="col-12 d-flex flex-column align-items-center ">
                      <p style={{ fontSize: 18, fontWeight: 700 }}>
                        {t("ใบแจ้ง" + print?.utilitiesType?.utilitiesTypeName)}
                      </p>
                    </div>
                    <div className="col-12 d-flex flex-column align-items-center ">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.DETAIL_STALL")}
                      </p>
                    </div>
                    <div className="col-3 d-flex mt-4  justify-content-center ">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.ZONE_STALL")}
                      </p>
                    </div>
                    <div className="col-3 d-flex  mt-4  justify-content-center ">
                      <p style={{ fontSize: 16 }}>{print?.zone?.zoneName || '-'}</p>
                    </div>
                    <div className="col-3 d-flex mt-4  justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("DETAIL.STALL")}
                      </p>
                    </div>
                    <div className="col-3 d-flex mt-4  justify-content-center">
                      <p style={{ fontSize: 16 }}>{print?.stall?.stallName || '-'}</p>

                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-3 d-flex justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.METER_NO")}
                      </p>
                    </div>
                    <div className="col-3 d-flex  justify-content-center ">
                      <p style={{ fontSize: 16 }}>{print?.utilitiesNo}</p>
                    </div>

                  </div>

                  <div className="row mt-4">
                    <div className="col-3 d-flex  justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("MODAL.SUMMIT_DATE")}
                      </p>
                    </div>
                    <div className="col-3 d-flex justify-content-center ">
                      <p style={{ fontSize: 16 }}>
                        {moment(print?.utilitiesDate).format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("บันทึกของเดือน")}
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 1 }}>
                        {shortnessDatemonth(print?.utilitiesDate)}
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("สร้างโดย")} :
                      </p>
                    </div>
                    <div className="col-3 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {`${print?.createdBy?.firstname} ${print?.createdBy?.lastname}` || "-"}
                      </p>
                    </div>
                    <div className="col-12 mt-2 d-flex justify-content-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {print?.utilitiesTypeId !== 1 ? t("ข้อมูลการใช้น้ำ") : t("ข้อมูลการใช้ไฟ")}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("เลขครั้งก่อน")}
                      </p>

                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {Math.floor(
                          print?.utilitiesDetail?.beforeNumber
                        )}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("เลขหลังหน่วย")}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {Math.floor(
                          print?.utilitiesDetail?.afterNumber
                        )}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("จำนวนที่ใช้")}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {Math.floor(print?.utilitiesDetail?.number)}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("จำนวนเงิน")}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16 }}>
                        {print?.utilitiesDetail?.amount}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center">
                      <p style={{ fontSize: 16, fontWeight: 700 }}>
                        {t("หน่วย")}
                      </p>
                    </div>
                  </div>
                  {/* <div className="row d-flex justify-content-end">
                    <img
                      src={print?.signature}
                      alt=""
                      style={{ width: 200, height: 200 }}
                    />
                  </div> */}
                </>
              </div>
            </>
          }
        />
      </div>
      <table className="print-show" id="excel-download">
        <tr><th colSpan={10} align="center">รายการค่าน้ำค่าไฟ</th></tr>
        <tr>
          <th>#</th>
          <th>วันที่เอกสาร</th>
          <th>เลขที่เอกสาร</th>
          <th>ประเภทบิล</th>
          <th>โซน</th>
          <th>แผง</th>
          <th>สร้างโดย</th>
          <th>เลขครั้งก่อน</th>
          <th>เลขหลังหน่วย</th>
          <th>จำนวนที่ใช้</th>
          <th>จำนวนเงิน</th>        </tr>
        {excelData?.map((item: any, index: number) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{moment(item?.createDate).format("DD/MM/YYYY")}</td>
              <td>{item?.utilitiesNo}</td>
              <td>{item?.utilitiesType?.utilitiesTypeName}</td>
              <td>{item?.zone?.zoneName}</td>
              <td>{item?.stall?.stallName}</td>
              <td>{item?.utilitiesDetail?.amount}</td>
              <td>{`${item?.createdBy?.firstname} ${item?.createdBy?.lastname}`}</td>
              <td>{item?.utilitiesDetail?.beforeNumber}</td>
              <td>{item?.utilitiesDetail?.number}</td>
              <td>{item?.utilitiesDetail?.afterNumber}</td>
              <td>{item?.utilitiesDetail?.amount}</td>
            </tr>
          )
        })}
      </table>
    </Box>
  );
}
export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  );
}
