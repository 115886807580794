import { menuFirsts } from "../../constants/menu"
import Certificate from "../../pages/certificate/certificate"

const routeCertificate = [
    {
      ...menuFirsts.CERTIFICATE,
      key: menuFirsts.CERTIFICATE.key,
      name: menuFirsts.CERTIFICATE.name,
      textName: menuFirsts.CERTIFICATE.textName,
      active: false,
      appBar: true,
      component: Certificate
    }
  ]
  
  export default routeCertificate