import LeaseAgreementApi from "../../api/stall/leaseAgreement/leaseAgreement.api";
import { menuSeconds } from "../../constants/menu";
import EditProfile from "../../pages/auth/editProfile";
import EditProfileAdmin from "../../pages/auth/editProfileAdmin";
import { routeName } from "../routers-name";
import Detailinfo from "../../pages/home/detailInfo";


const routeDetailinfo = [
  {
    ...menuSeconds.DETAIL_INFO,
    key: menuSeconds.DETAIL_INFO.key,
    name: menuSeconds.DETAIL_INFO.name,
    textName: menuSeconds.DETAIL_INFO.textName,
    path: `${routeName.detailinfo}/:id`,
    active: false,
    appBar: false,
    component: Detailinfo,
  },
];

export default routeDetailinfo;
