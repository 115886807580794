import { Checkbox, Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import InputCheckbox from "../../component/input/inputCheckbox";

/** STYLE */
import * as Styles from "./transferStall.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

interface CheckHeirProps {
  title?: string;
  disable?: boolean;
  onChange?: () => void;
  value?: boolean;
  img?: any;
  heirName?: string;
  birthDate?: string;
  year?: number;
  id_number?: string;
  email?: string;
  tel?: string;
  relation?: string;
  address?: string;
}

export default function DetailReceiver(props: CheckHeirProps) {
  const { t } = useTranslation();
  return (
    <>
        <>
          <Styles.ContainerHeir>
            {window.screen.width > 900 && (
              <Col lg={3}>
                <img src={props.img} />
              </Col>
            )}

            <Box className="w-100">
            {window.screen.width < 900 && (
              <Row>
                <Col lg={12} style={{display: window.screen.width < 900 ? 'flex': 'none', justifyContent: 'center'}}>
                  <img src={props.img} />
                </Col>
              </Row>
            )}
              <Row>
                <Col lg={4} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.NAME_SUBNAME")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={5} md={6}>
                  <Styles.SubDetail>{props.heirName}</Styles.SubDetail>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={4} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.BIRTH_DAY")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={5} md={6}>
                  <Styles.SubDetail>{props.birthDate}</Styles.SubDetail>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={1} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.AGE")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={1} md={3}>
                  <Styles.SubDetail>{props.year}</Styles.SubDetail>
                </Col>
                <Col lg={1} md={3}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.OLD")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={4} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.ID_NUMBER")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={3} md={6}>
                  <Styles.SubDetail>{props.id_number}</Styles.SubDetail>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={2} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.TEL")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={3} md={6}>
                  <Styles.SubDetail>{props.tel}</Styles.SubDetail>
                </Col>
                <Col lg={2} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.EMAIL")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={3} md={6}>
                  <Styles.SubDetail>{props.email}</Styles.SubDetail>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={3} md={6}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.RELATIONSHIP")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={7} md={6}>
                  <Styles.SubDetail>{props.relation || '-'}</Styles.SubDetail>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={2} md={12}>
                  <Styles.DetailModal>
                    {t("TRANSFER_STALL.MODAL.HEIR.ADDRESS")}
                  </Styles.DetailModal>
                </Col>
                <Col lg={8} md={12}>
                  <Styles.SubDetail>{props.address}</Styles.SubDetail>
                </Col>
              </Row>
            </Box>
          </Styles.ContainerHeir>
          <Box className="w-100">
            {/* <Styles.GreenButtonInformation>
              <Typography>
                {t("TRANSFER_STALL.MODAL.HEIR.IMG_ID_CARD")}
              </Typography>
              <FontAwesomeIcon icon={faEye} />
            </Styles.GreenButtonInformation> */}
          </Box>
        </>
    </>
  );
}
