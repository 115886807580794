import { useState } from 'react'

/** CONSTANTS */


/** STYLE */
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'

/** ICON */
import ArrowForward from '@mui/icons-material/ChevronRight'
import ArrowBack from '@mui/icons-material/ChevronLeft'
import { colors } from '../../../../constants/colors'

const scale = {
  navbarHeight: '96px',
  sidebarSize: '80px',
  expiredAlertHeight: '35px',
  secondToggle: '130px',
  secondToggleStore: '198px',
  containerCollapse: {
    leftIsCollapse: '2rem',
    leftSide: '30%',
    rightSide: '70%'
  },
  borderRadiusXs: '4px',
  rangeOfMinute: 3.2
}


const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  '&.collapse-lg': {
    [theme.breakpoints.up('lg')]: {
      display: 'none !important'
    }
  },
  '&.collapse-xs': {
    [theme.breakpoints.up('sm')]: {
      display: 'none !important'
    }
  }
}))

const Layout = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative'
}))

const LayoutLeftSide = styled(Layout)(({ theme }) => ({
  width: scale.containerCollapse.leftSide,
  borderRight: `1px solid ${colors.lightGray}`,
  transition: 'width .5s ease',
  '&:hover': {
    '& .btn-collapse': {
      opacity: 1
    }
  },
  '&.left-isCollapse': {
    width: scale.containerCollapse.leftIsCollapse
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(${scale.containerCollapse.rightSide} - 25%)`
  },
  [theme.breakpoints.down('md')]: {
    width: `calc(${scale.containerCollapse.rightSide} - 15%)`
  },
  [theme.breakpoints.down('sm')]: {
    '&.left-isCollapse': {
      width: `calc(${scale.containerCollapse.leftIsCollapse} - 0.5rem)`
    },
    '&.right-isCollapse': {
      width: '100%'
    }
  }
}))

const LayoutRightSide = styled(Layout)(({ theme }) => ({
  width: scale.containerCollapse.rightSide,
  '&.right-width': {
    width: `calc(100% - ${scale.containerCollapse.leftIsCollapse})`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - (${scale.containerCollapse.leftIsCollapse} - 0.5rem))`
    }
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(${scale.containerCollapse.leftSide} + 25%)`
  },
  [theme.breakpoints.down('md')]: {
    width: `calc(${scale.containerCollapse.leftSide} + 15%)`
  },
  [theme.breakpoints.down('sm')]: {
    '&.right-isCollapse': {
      width: '1.5rem'
    }
  }
}))



const ComponentContainer = styled('div')(({ theme }) => ({
  transition: 'opacity .5s ease'
}))

type ContainerCustomProps = {
  tooltipExpand?: string
  tooltipShorten?: string
  maxSize?: 'xl' | 'lg' | 'xs'
  componentLeft: any
  componentRight: any
  styleComponentLeft?: any
  styleComponentRight?: any
  callBackStatusCollapse?: (status: boolean) => void
}

export default function CollapseContainer(props: ContainerCustomProps) {


  return (
    <Row className={`layout-collapse ${props.maxSize ? `collapse-${props.maxSize}` : 'collapse-xl'}`}>
      <LayoutLeftSide >
        <ComponentContainer className={`layout-left-side-container `} style={{ ...props.styleComponentLeft }}>
          {props.componentLeft}
        </ComponentContainer>
      </LayoutLeftSide>
      <LayoutRightSide >
        <ComponentContainer className={`layout-right-side-container `} style={{ ...props.styleComponentRight }}>
          {props.componentRight}
        </ComponentContainer>
      </LayoutRightSide>
    </Row>
  )
}
