import React from 'react'
import ModalCustom from '../../../component/modalCustom/modalCustom'
import InputTextField from '../../../component/input/inputTextField';

type Props = {
    show: boolean;
    onClose: (status: boolean) => void;
    remark: string;
    onChangeRemark: (value: string) => void;
    errorMessageRemark: string;
    clearErrorMessageRemark: () => void;
    onSubmit: () => void;
}

const ModalStatus = (props: Props) => {

    return (
        <>
            <ModalCustom
                closeButton
                onClose={() => {
                    props.onClose(false)
                    props.onChangeRemark("")
                    props.clearErrorMessageRemark()
                }}
                isShowProps={props.show}
                title='ระบุเหตุผลในการปิดการใช้งาน'
                size='lg'
                onReset={() => {
                    props.onClose(false)
                    props.onChangeRemark("")
                    props.clearErrorMessageRemark()
                }}
                textBtnCancel='ยกเลิก'
                textBtnConfirm='ปิดการใช้งาน'
                onSubmit={props.onSubmit}
                component={
                    <div className='w-100'>
                        <InputTextField
                            helperText={props.errorMessageRemark}
                            required
                            heading='เหตุผล'
                            onchange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.onChangeRemark(e.target.value.trim())
                                props.clearErrorMessageRemark()
                            }}
                            value={props.remark}
                        />
                    </div>
                }
            />
        </>
    )
}

export default ModalStatus