import { menuSeconds } from "../../constants/menu"
import StallReport from "../../pages/store/report-stall/stallReport"

const routeStallReport = [
    {
      ...menuSeconds.STALL_REPORT,
      key: menuSeconds.STALL_REPORT.key,
      name: menuSeconds.STALL_REPORT.name,
      textName: menuSeconds.STALL_REPORT.textName,
      active: false,
      appBar: true,
      component: StallReport
    },
  ]
  
  export default routeStallReport