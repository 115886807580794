import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'leases-type'

export interface LeasesInterface {
    leasesTypeId?: number
    leasesTypeNameTh: string
    leasesTypeNameEn?: string
    status: string
  }

export default class LeasesTypeApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static create(props:any) {
        return this.api.post(path , { ...props })
    }

    static findById(id: number): Promise<LeasesInterface> {
        return this.api.get(`${path}/${id}`)
    }

    static update(id: number, body: any) {
        return this.api.patch(`${path}/${id}`, body)
    }

    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { id, status })
    }

    static delete(id: number) {
        return this.api.delete(`${path}/${id}`)
    }

}
