import { useState, Dispatch, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@mui/material";
import { decode } from 'js-base64';

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import PopupOfficerSignature from "./popup/popupOfficerSignature";
import PopupSetupDeposit from './popup/popupSetupDeposit';

/** STYLE */
import * as Style from "./rentStall.style";
import { colors } from "../../../constants/colors";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import ButtonCustom from "../../../component/button/buttonCustom";
import { useDispatch } from 'react-redux';
import { closeModal, resetModal, showModal, submitModal } from '../../../app/slice/modal.slice';
import AutocompleteSelect from '../../../component/select/autoCompleteSelect';
import { icons } from '../../../constants/images';
import Admin from './display/admin';
import { getProfile } from '../../../utils/app.utils';

export default function RentStall() {
  const { t } = useTranslation();
  const profile: any = JSON.parse(getProfile() || '{}')
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false)
  const [selectModal, setSelectModal] = useState<boolean>(true)
  const [roleId, setRoleId] = useState<number>(JSON.parse(decode(profile))?.roleId)
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(10)

  const dispatch = useDispatch();

  useEffect(() => {
    setRoleId(JSON.parse(decode(profile))?.userId)
  }, [])

  const headCells = [
    { id: "no", disablePadding: false, label: "#" },
    { id: "date", disablePadding: false, label: t("RENTSTALL.HEADCELL.DOC_DATE") },
    { id: "documentNumber", disablePadding: false, label: t("RENTSTALL.HEADCELL.DOC_NO") },
    { id: "name", disablePadding: false, label: t("RENTSTALL.HEADCELL.TYPE") },
    { id: "zone", disablePadding: false, align: 'center', label: t("RENTSTALL.HEADCELL.RENT_NAME") },
    { id: "stall", disablePadding: false, align: 'center', label: t("RENTSTALL.HEADCELL.ZONE") },
    { id: "zoneType", disablePadding: false, label: t("RENTSTALL.HEADCELL.STALL") },
    { id: "dataBill", disablePadding: false, label: t("RENTSTALL.HEADCELL.ZONE_TYPE") },
    { id: "status", disablePadding: false,align: 'center', label: t("RENTSTALL.HEADCELL.STATUS") },
    { id: "action", disablePadding: false,align: 'center', label: t("RENTSTALL.HEADCELL.ACTION") },
  ];
  const data = [
    {
      no: 1,
      date: "19/05/2566 13:22",
      requestNo: "132051266",
      name: "สมชาย เก่งกาจ",
      zone: "11",
      stall: "A1",
      Type: "อาหารแห้ง",
      dateBill: "21/05/2566 13:22",
      status: "wait",
      action: "เปิดใช้งาน",
    },
  ];

  const renderData = (objData: any) => {
    // no = page * pageLimit - pageLimit + no + 1

    const { no, date, requestNo, name, zone, stall, Type, dateBill,  status, action } =
      objData;

    const objRenderData = {
      key: no,
      id: no,
      obj: objData,
      columns: [
        { option: "TEXT", align: "center", label: no },
        { option: "TEXT", align: "left", label: date },
        { option: "TEXT", align: "left", label: requestNo || "-" },
        { option: "TEXT", align: "center", label: name || "-" },
        { option: "TEXT", align: "center", label: zone || "-" },
        { option: "TEXT", align: "left", label: stall || "-" },
        { option: "TEXT", align: "left", label: Type || "-" },
        { option: "TEXT", align: "left", label: dateBill || "-" },
        {
          option: "COMPONENT",
          align: "center",
          component: <Style.ButtonInTable>{action}</Style.ButtonInTable>,
        },
        {
          option: "ACTION",
          align: "center",
          label: "action",
          style: { color: colors.themeMainColor },
          values: [
            {
              option: "PRINT",
              label: t(`RENTSTALL.ACTION.PRINT`),
            },
            {
              option: "DOCUMENTDETAIL",
              label: t(`RENTSTALL.ACTION.DETAIL`),
            },
            {
              option: "STATUS_INACTIVE",
              label: t(`RENTSTALL.ACTION.CANCEL`),
            },
          ],
        },
      ],
    };
    return <TableRowCommon {...objRenderData} 
    ondocumentdetail={() => {}} 
    onprint={() => {}}
    oninactive={() =>{}}
    />;
  };

  const submit = () => {
    dispatch(submitModal())
    setOpenPopup(false)
    setAccept(true)
    setSelectModal(false)
  }

  const onSubmit = () => {
    dispatch(closeModal())
  }

  const SetupDeposit = () =>{
    setOpenPopup(true)
    dispatch(showModal())
  }

  return (
    <>
     {roleId === 4 && ( 
     <>
     <HeaderCard text={t("RENTSTALL.TITLE")} />
      <Style.PageContainer>
      <Style.MainContainer>
        <Box>
          <Grid container spacing={1}>
            <Grid item lg={2} md={6} xs={12}>
              <InputTextField label="ค้นหา"/>
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => {}}
                placeholder={t('RENTSTALL.FILTER.TYPE')}
                labelId="request-type"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => {}}
                placeholder={t('RENTSTALL.FILTER.ZONE')}
                labelId="zone"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => {}}
                placeholder={t('RENTSTALL.FILTER.STALL')}
                labelId="stall"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
            <AutocompleteSelect
                options={[]}
                getOptionLabel={(option: any) => {}}
                placeholder={t('RENTSTALL.FILTER.STATUS')}
                labelId="status"
              />
            </Grid>
            <Grid item lg={2} md={6} xs={12}>
                <Style.ButtonPrint>
                    {t("STALL_AGREEMENT.BUTTON.PRINT")}
                    <img src={icons.print} />
                </Style.ButtonPrint>
            </Grid>
          </Grid>
        </Box>
        <Style.TableContainer>
          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={"ASC"}
            sortBy=""
            rowCount={1}
            rowsData={data.map((d: any) => {
              return renderData(d);
            })}
            onSort={() => { }}
            setPageLimit={(value: number) => setPageLimit(value)}
            setPage={(value: number) => setPage(value)}
          />
        </Style.TableContainer>
        <ButtonCustom
          textButton='openPopup'
          onClick={() => SetupDeposit()}
        />
       
      </Style.MainContainer>
      </Style.PageContainer>

      <ModalCustom
        title={selectModal ? t('กำหนดค่ามัดจำแผงค้า') : t('ลงชื่อเจ้าหน้าที่ลงข้อมูล') }
        size="lg"
        closeButton={true}
        modalScroll={true}
        onSubmit={selectModal ? submit : onSubmit }
        textBtnConfirm ={'บันทึก'}
        component={
          <>
            {!openPopup && accept &&(<PopupOfficerSignature
            />
            )}

            {openPopup  &&(
              <PopupSetupDeposit
                zone="11"
                stall="A2"     
                time='22/05/2566' 
                amount='5000'    
              />
                      )}
            
          </>
        }
        />
        </>
        )}

      {roleId !== 4 && <Admin/>}
    </>
   
  );
  
}
