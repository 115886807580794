import React, { useCallback, useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, MenuItem } from "@mui/material";

/** COMPONENT */
import TableCustom from "../../../component/table/tableCustom";
import InputTextField from "../../../component/input/inputTextField";
import FilterSelect from "../../../component/select/filterSelect";
import HeaderCard from "../../../component/cardCustom/headerCard";
import TableRowCommon from "../../../component/table/TableRowCommon";
import ModalCustom from "../../../component/modalCustom/modalCustom";
import { swalActive } from "../../../component/notification/swal";
import { useDispatch } from "react-redux";
import { showModal } from "../../../app/slice/modal.slice";
import { notiError, notiSuccess } from "../../../component/notification/notifications";
import { dateToView } from "../../../utils/date.utils";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { colors } from "../../../constants/colors";
import ModalStatus from "./ModalStatus";

/** STYLE */
import * as Style from "./userDefault.style"

/** API */
import UserApi, { updateStatusType } from "../../../api/auth/users.api";
import {
  isCreate,
  isDelete,
  isUpdate,
  isView,
} from "../../../utils/permission.utils";
import Loading from "../../../component/loading/loading";

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView(),
};

export default function User() {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading,setIsLoading] = useState<boolean>(false);

  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [idCard, setIdCard] = useState<string>('')
  const [birthDay, setBirthDay] = useState<string>('')
  const [mobilePhone, setMobilePhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [taxId, setTaxId] = useState<string>('')
  const [houseNumber, setHouseNumber] = useState<string>('')
  const [moo, setMoo] = useState<string>('')
  const [village, setVillage] = useState<string>('')
  const [soi, setSoi] = useState<string>('')
  const [road, setRoad] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [userData, setUserData] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageLimit, setPageLimit] = useState<number>(5)
  const [rowCount, setRowCount] = useState<number>()
  const [sortBy, setSortBy] = useState<string>('modifiedDate')
  const [sortType, setSortType] = useState<string>('ASC')
  const [search, setSearch] = useState<string>('')
  const [status, setStatus] = useState<string>('ALL')
  const [prefixNameTh, setPrefixNameTh] = useState<string>('')
  const [provinceNameTh, setProvinceNameTh] = useState<string>('')
  const [districtNameTh, setDistrictNameTh] = useState<string>('')
  const [subDistrictNameTh, setSubDistrictNameTh] = useState<string>('')
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("");
  const [errorMessageRemark, setErrorMessageRemark] = useState<string>("");
  const [statusRemark, setStatusRemark] = useState<string>("");
  const [idUser, setIdUser] = useState<number>(Number);



  const loadData = useCallback(async () => {

    let condition: any = {}
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search) condition = { ...condition, search: search }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

    const res = await UserApi.findAll(condition)

    if (res.status === 200) {
      setRowCount(res.headers['total'])
      setUserData(res.data)

    } else {
      setRowCount(0)
      setUserData([])
    }
  }, [page, pageLimit, search, sortBy, sortType, status])


  useEffect(() => {
    loadData()
  }, [loadData])

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageLimitChange = (newPageLimit: number) => {
    setPageLimit(newPageLimit);
  };

  const onRequestSort = (sortBy: string, sortType: string) => {
    setSortType(sortType)
    setSortBy(sortBy)
  }

  const headCells = [
    { id: "userId", disablePadding: false, label: "NO", },
    { id: "firstname", disablePadding: false, align: 'center', label: t("USER.HEADCELL.NAME"), sortable: true },
    { id: "idCard", disablePadding: false, align: 'center', label: t("USER.HEADCELL.IDCARD"), sortable: true },
    { id: "createDate", disablePadding: false, align: 'center', label: t("USER.HEADCELL.CREATEDATE"), sortable: true },
    { id: "status", disablePadding: false, align: 'center', label: t("สถานะการใช้งาน") },
    { id: "remark", disablePadding: false, align: 'center', label: t("หมายเหตุ") },
    { id: "action", disablePadding: false, label: t("USER.HEADCELL.ACTION") }
  ];

  const onDelete = (data: any) => {
    const { userId, firstname, lastname } = data

    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('USER.ALERT.CONFIRM_DELETE', {
        firstname: firstname, lastname: lastname ? ' ' + lastname + '' : ''
      })}</p>`,
      (res: any) => {
        if (res) {

          UserApi.delete(userId)
            .then((res) => {
              notiSuccess(t('USER.MESSAGE.SUCCESS.DELETE'), '', null, null)
              loadData()
            })
            .catch((e) => {
              notiError(t('USER.MESSAGE.ERROR'), '', null, null)
            })
        }
      }
    )
  }

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { userId, firstname, lastname, idCard, createDate, user } = objData;

    const objRenderData = {
      key: userId,
      id: userId,
      obj: objData,
      columns: [
        { option: "TEXT", align: "left", label: no },
        { option: "TEXT", align: "center", label: firstname + " " + lastname || "-" },
        { option: "TEXT", align: "center", label: idCard || "-" },
        { option: "TEXT", align: "center", label: dateToView(createDate) || "-" },
        {
          option: "TEXT", align: "center", label:
            <div
              className="p-1 text-white rounded-1 m-auto"
              style={{
                width: user?.status === 'ACTIVE' ? 100 : 120,
                backgroundColor: user?.status === 'ACTIVE' ? colors.green : colors.red,
              }}
            >
              {user?.status === "ACTIVE" ? 'เปิดใช้งาน' : 'ปิดการใช้งาน' || "-"}
            </div>
        },
        { option: "TEXT", align: "center", label: user?.remark || "-" },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'DOCUMENTDETAIL', label: t(`BUTTON.DOCUMENTDETAIL`), disabled: permissions?.isView.disabled },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions?.isDelete.disabled },
            {
              option: 'REASON', label:
                <Button
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    backgroundColor: "inherit", '&:hover': {
                      backgroundColor: "inherit"
                    }
                  }}
                  startIcon={<AutorenewIcon />}
                >
                  {t(user?.status === 'ACTIVE' ? `ปิดการใช้งาน` : `เปิดการใช้งาน`)}
                </Button>, disabled: permissions?.isDelete.disabled
            },
          ]
        }
      ],

    };
    return (
      <TableRowCommon
        {...objRenderData}
        onreason={() => {
          if (user?.status === 'ACTIVE') {
            setIsShowModal(true)
            setIdUser(userId)
            onChangeStatus(user?.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
          } else {
            updateStatus(userId)
          }
        }}
        ondelete={() => onDelete(objRenderData.obj)}
        ondocumentdetail={() => onView(objRenderData.obj)}
      />
    )
  };

  const onChangeStatus = (status: 'ACTIVE' | 'INACTIVE') => {
    setStatusRemark(status)
  }
  const handleChangeRemark = (value: string) => {
    setRemark(value)
  }
  const handleSubmitStatus = async () => {
    if (!remark) {
      setErrorMessageRemark("ระบุเหตุผลในการปิดการใช้งาน")
      return;
    }
    let condition: updateStatusType = {
      remark: remark,
      status: statusRemark
    }
    setIsLoading(true)
    await UserApi.updateStatus(idUser, condition)
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false)
          notiSuccess('บันทึกข้อมูลเรียบร้อย')
          setIsShowModal(false)
          setErrorMessageRemark("")
          setRemark("")
        } else {
          setIsLoading(false)
          notiError('เกิดข้อผิดพลาดในการบันทึกข้อมูล')
        }
      })
      .catch(() => {
        setIsLoading(false)
        notiError('เกิดข้อผิดพลาดในการบันทึกข้อมูล')
      })
      .finally(() => loadData())
  }
  const updateStatus = async (userId: number) => {
    let condition: updateStatusType = {
      remark: '',
      status: 'ACTIVE'
    }
    setIsLoading(true)
    await UserApi.updateStatus(userId, condition)
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false)
          notiSuccess('บันทึกข้อมูลเรียบร้อย')
          setIsShowModal(false)
          setErrorMessageRemark("")
          setRemark("")
        } else {
          notiError('เกิดข้อผิดพลาดในการบันทึกข้อมูล')
        }
      })
      .catch(() => {
        setIsLoading(false)
        notiError('เกิดข้อผิดพลาดในการบันทึกข้อมูล')
      })
      .finally(() => loadData())
  }

  const onView = async (data: any) => {

    const { userId } = data
    const condition: any = {
      id: userId
    }

    await UserApi.getDetail(condition)
      .then((res) => {
        setPrefixNameTh(res.data.prefix.prefixNameTh)
        setFirstname(res.data.firstname)
        setLastname(res.data.lastname)
        setIdCard(res.data.idCard)
        setBirthDay(res.data.birthDay)
        setMobilePhone(res.data.mobilePhone)
        setEmail(res.data.email)
        setTaxId(res.data.taxId)
        setHouseNumber(res.data.houseNumber)
        setMoo(res.data.moo)
        setVillage(res.data.village)
        setSoi(res.data.soi)
        setRoad(res.data.road)
        setProvinceNameTh(res.data.province?.provinceNameTh || '');
        setDistrictNameTh(res.data.district?.districtNameTh || '');
        setSubDistrictNameTh(res.data.subDistrict?.subDistrictNameTh || '');
        setZipCode(res.data.zipCode)
        dispatch(showModal())
      })
  }


  return (
    <div className="w-100 position-relative">
      {isLoading && <Loading show />}
      {isShowModal &&
        <ModalStatus
          remark={remark}
          errorMessageRemark={errorMessageRemark}
          onChangeRemark={handleChangeRemark}
          clearErrorMessageRemark={() => setErrorMessageRemark("")}
          onClose={(status: boolean) => setIsShowModal(status)}
          onSubmit={() => handleSubmitStatus()}
          show={isShowModal}
        />}
      <HeaderCard text={t("USER.TITLE")} />
      <Style.MainContainer>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={3} md={6} xs={12}>
              <InputTextField label={t("USER.INPUT.SEARCH")}
                value={search}
                onchange={(event) => {
                  setSearch(event.target.value)
                  setPage(1)
                }}
              />
            </Grid>

          </Grid>
        </Box>
        <Style.TableContainer>

          <TableCustom
            headCells={headCells}
            customScroll
            page={page}
            pageLimit={pageLimit}
            sortType={sortType}
            sortBy={sortBy}
            rowCount={rowCount}
            rowsData={userData.map((data: any, index: number) => {
              return renderData(data, index);
            })}
            onSort={onRequestSort}
            setPageLimit={handlePageLimitChange}
            setPage={handlePageChange}

          />
        </Style.TableContainer>

        <ModalCustom
          title={t("USER.DETAIL_TITLE")}
          size="xl"
          closeButton
          component={
            <div>
              <Row >
                <Col className="d-flex flex-row" lg={4}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.NAME")}</Style.subMessage>
                  <Style.subMessage className="mr-2">{prefixNameTh || "-"}</Style.subMessage>
                  <Style.subMessage className="mr-2">{firstname + " " + lastname || "-"}</Style.subMessage>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.IDCARD")}</Style.subMessage>
                  <Style.subMessage>{idCard || "-"}</Style.subMessage>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.BIRTHDAY")}</Style.subMessage>
                  <Style.subMessage className="mr-2">{dateToView(birthDay)}</Style.subMessage>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.AGE")}</Style.subMessage>
                  {birthDay && <Style.subMessage className="mr-2">{moment().diff(birthDay, "years")}</Style.subMessage>}
                  <Style.subMessage>{t("USER.POPUP.YEARS")}</Style.subMessage>
                </Col>


              </Row>

              <Row className="mt-2">
                <Col className="d-flex flex-row" lg={4}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.PHONE")}</Style.subMessage>
                  <Style.subMessage>{mobilePhone || "-"}</Style.subMessage>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.subMessage className="mr-2" >{t("USER.POPUP.EMAIL")}</Style.subMessage>
                  <Style.subMessage>{email || "-"}</Style.subMessage>
                </Col>

                <Col className="d-flex flex-row" lg={4}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.TAXID")}</Style.subMessage>
                  <Style.subMessage>{taxId || "-"}</Style.subMessage>
                </Col>

              </Row>

              <Row className="mt-2" >
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.ADDRESS")}</Style.subMessage>
                  <Style.subMessage>{houseNumber || "-"}</Style.subMessage>
                </Col>

                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.MOO")}</Style.subMessage>
                  <Style.subMessage>{moo || "-"}</Style.subMessage>
                </Col>

                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.VILLAGE")}</Style.subMessage>
                  <Style.subMessage>{village || "-"}</Style.subMessage>
                </Col>
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.SOI")}</Style.subMessage>
                  <Style.subMessage>{soi || "-"}</Style.subMessage>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.ROAD")}</Style.subMessage>
                  <Style.subMessage>{road || "-"}</Style.subMessage>
                </Col>
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.PROVINCE")}</Style.subMessage>
                  <Style.subMessage>{provinceNameTh || "-"}</Style.subMessage>
                </Col>
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.DISTRICT")}</Style.subMessage>
                  <Style.subMessage>{districtNameTh || "-"}</Style.subMessage>
                </Col>
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.SUBDISTRICT")}</Style.subMessage>
                  <Style.subMessage>{subDistrictNameTh || "-"}</Style.subMessage>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="d-flex flex-row" lg={3}>
                  <Style.subMessage className="mr-2">{t("USER.POPUP.POSTCODE")}</Style.subMessage>
                  <Style.subMessage>{zipCode || "-"}</Style.subMessage>
                </Col>
              </Row>
            </div>
          }

        />

      </Style.MainContainer>

    </div>
  );
}
