import { useTranslation } from "react-i18next";
import moment from "moment";
import { Grid, Typography } from "@mui/material";

/** COMPONENT */
import InputTextField from "../../../../component/input/inputTextField";

/** STYLE, CONSTANT */
import * as Style from ".././stallAgreement.style";
import { imgs } from "../../../../constants/images";
import ModalCustom from "../../../../component/modalCustom/modalCustom";
import { dateToView } from "../../../../utils/date.utils";


export default function PopupViewDetail(props: any) {
  const { t }: any = useTranslation();
  const { data, onClose } = props

  console.log(data);
  return (
    <>
      <ModalCustom
        title={""}
        size="xl"
        closeButton
        onClose={() =>
          onClose()
        }
        component={
          <Style.MainContainer>
            <div>
              <div>
                <Grid container spacing={2}>
                  {/* <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className="w-100 d-flex justify-content-center"
              >
                <Style.PicRenter src={imgs.defaultAvatar} alt="" />
              </Grid> */}
                  <Style.GridInput item
                    // lg={3} 
                    sm={4}>
                    <div className="">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.prefix?.prefixNameTh}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.PREFIX")}
                      />
                    </div>
                  </Style.GridInput>
                  <Style.GridInput item
                    // lg={3}
                    sm={4}>
                    <div>
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.firstname}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.FIRST_NAME")}
                      />
                    </div>
                  </Style.GridInput>
                  <Style.GridInput item
                    // lg={3}
                    sm={4}>
                    <div>
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.lastname}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.LAST_NAME")}
                      />
                    </div>
                  </Style.GridInput>
                  <Grid item lg={4} sm={5}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.idCard}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ID_CARD")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={5}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={dateToView(data?.customer?.birthDay)}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.BIRTH_DAY")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={2}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.birthDay && moment().diff(data?.customer?.birthDay, "years") + t("AGREEMENT.INPUT.YEAR")}
                        inputTextAlign="center"
                        size="medium"
                        heading={t("AGREEMENT.INPUT.AGE")}
                      />

                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.mobilePhone}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.TEL")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.email}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.EMAIL")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={4} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        value={data?.customer?.taxId}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.TAX_ID")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6}>
                    <div className="mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.houseNumber}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ADRESS")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6}>
                    <div className=" mt-2">
                      <InputTextField
                        value={data?.customer?.moo}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.MOO")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={6}>
                    <div className=" mt-2">
                      <InputTextField
                        value={data?.customer?.village}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.VILLAGE")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        value={data?.customer?.soi}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.SOI")}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.road}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ROAD")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.province?.provinceNameTh}
                        size="medium"
                        heading={t("STALL.DETAIL.CITY")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.district?.districtNameTh}
                        size="medium"
                        heading={t("STALL.DETAIL.DISTRICT")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        disabled={true}
                        value={data?.customer?.subDistrict?.subDistrictNameTh}
                        size="medium"
                        heading={t("STALL.DETAIL.SUB_DISTRICT")}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} sm={4}>
                    <div className=" mt-2">
                      <InputTextField
                        required
                        disabled={true}
                        value={data?.customer?.zipCode}
                        size="medium"
                        heading={t("AGREEMENT.INPUT.ZIPCODE")}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={12} sm={12} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.HEAD")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.ZONE")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{data?.bidder?.zoneName}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.STALL")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{data?.bidder?.stallName}</Typography>
                  </Grid>
                  {/* <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.AGREEMENT_TYPE")}</Typography>
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
              <FilterSelect
                  onchange={(e: any)=> {
                    setPaymentCycle(e.target.value)
                  }}
                    options={paymentCycleData && paymentCycleData.map((e: any) => {
                      return <MenuItem value={e.leasesTypeId} >{e.leasesTypeNameTh || e.leasesTypeNameEn}</MenuItem>;
                    })}
                    selectId="select-prefix"
                    value={paymentCycle}
                    labelId="label-prefixId"
                    required
                    helperText={errorMessage.PAYMENT}
                  />
              </Grid>
              <Grid item lg={3} sm={3} className="auto">
                <Typography>{t("AGREEMENT.DETAIL.PRICE")}</Typography>
              </Grid> */}
                  {/* <Grid item lg={3} sm={3} className="auto">
              <InputTextField
                    value={amount}
                    onchange={(e: any) => {
                      setAmount(e.target.value)
                      
                    }}
                    size="medium"
                    required
                    helperText={errorMessage.AMOUNT}
                  />
              </Grid> */}
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.STATRT_DATE")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{dateToView(data?.leaseAgreement?.leaseAgreementDateStart) || "-"}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.END_DATE")}</Typography>
                  </Grid>
                  <Grid item lg={3} sm={3} className="auto">
                    <Typography>{dateToView(data?.leaseAgreement?.leaseAgreementDateEnd) || "-"}</Typography>
                  </Grid>
                  <Grid item sm={12} className="auto">
                    <Typography>{t("AGREEMENT.DETAIL.RECCOMENT")}</Typography>
                  </Grid>
                  <Grid item sm={12} className="auto">
                    <Typography>{data?.leaseAgreement?.leaseAgreementRemark || "-"}</Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Style.MainContainer>
        }
      />


    </>
  );
}
