import BaseAPI from "../../api";

const path = "employee";
export interface EnployeeInterface {
  employeeId?: number;
  employeeCode?: string;
  userId?: number;
  registerDate?: string;
  roleId?: number;
  id?: number;
  prefixId?: number;
  firstname?: string;
  lastname?: string;
  idCard?: string;
  dob?: string;
  email?: string;
  signature?: string;
  permission?: any;
  status?: string;
  page?: number;
  pageLimit?: number;
  createId?: number;
  createDate?: string;
  search?: string;
  password?: string;
  confirmPassword?: string
  modifiedId?: number;
  modifiedDate?: string;
  refId?: number
  fileType?: string
  remark?: string;
}

export default class EmployeeApi extends BaseAPI {
  static findAll(props: EnployeeInterface): Promise<any> {

    return this.api.get(path, { params: { ...props } }).then((res) => res);


  }
  static createEmployee(props: EnployeeInterface) {
    return this.api.post(path, props).then((res) => res)
  }
  static getdetail(employeeId: number): Promise<any> {
    return this.api.get(`${path}/${employeeId}`).then((res) => res)
  }
  static updateDetail(props: EnployeeInterface, id: number) {

    return this.api.patch(`${path}/${id}`, props).then((res) => res)
  }
  static updateStatus(props: EnployeeInterface, id: number) {

    return this.api.patch(`${path}/${id}/status`, props).then((res) => res)
  }
}
