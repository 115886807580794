import BaseAPI from "../api";

const path = "announce";

export interface AnnounceInterface {
  page?: number;
  pageLimit?: number;
}

export default class AnnounceApi extends BaseAPI {
  static findAll(params: AnnounceInterface): Promise<any> {
    return this.api.get(path, { params: { ...params } }).then((res) => res);
  }
  static createAnnounce(props: any): Promise<any> {
    return this.api.post(path, props).then((res) => res);
  }

  static findBookmark(): Promise<any> {
    return this.api.get(`${path}/list-Bookmark-New`).then((res) => res);
  }
  static updateStatus(props: any, id: number): Promise<any> {
    return this.api.patch(`/${path}/${id}/status`, props).then((res) => res);
  }
  static details(id: number): Promise<any> {
    return this.api.get(`/${path}/${id}`).then((res) => res);
  }
  static updateBookmark(props: any, id: number): Promise<any> {
    return this.api.patch(`/${path}/${id}/bookmark`, props).then((res) => res);
  }
  static updateAnnounce(props: any, id: number): Promise<any> {
    return this.api.patch(`/${path}/${id}`, props).then((res) => res);
  }
}
