import { useCallback, useEffect, useState } from 'react'
import { IconButton, Badge, Divider, Button, useMediaQuery } from '@mui/material'
import { Collapse, Nav, Row, Col } from 'react-bootstrap'
import TextEllipsis from './TextEllipsis'


/** CONSTANTS */
import { scale } from '../../constants/scale'
import { colors } from '../../constants/colors'

/** STYLES */
import { styled } from '@mui/material/styles'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpRounded'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded'
import './Style.css'
import { menuFirsts, menuSeconds, menuThirds } from '../../constants/menu'
import { useTranslation } from 'react-i18next'
import { matchRoute } from '../../utils/activeRouter'
import _ from 'lodash'
import { getPermissionActiveMenu, getPermissionMenus, getPermissions } from '../../utils/app.utils'
import { STORAGE_KEY } from '../../utils/enum'

import { getProfile } from '../../utils/app.utils'
import BidderApi from '../../api/stall/bidder/bidder.api'
import LeaseAgreementApi from '../../api/stall/leaseAgreement/leaseAgreement.api'
import { decode, encode } from 'js-base64';
import RepairApi from '../../api/repair/repair.api'
import CancelContractApi from '../../api/stall/cancelcontract/cancelcontract'
import TranferApi from '../../api/transfer-lease-agreement/transfer-lease-agreement'
import InvoiceApi from '../../api/invoid/invoid.api'
import CertificateAPI from '../../api/stall/certificate/certificate'
import DepositApi from '../../api/stall/deposit/deposit.api'

const MenuFirstLevel = styled('div')(({ theme }) => ({
   width: '100%',
   top: scale.navbarHeight,
   zIndex: 1200,
   overflow: 'hidden',
   [theme.breakpoints.up('md')]: {
      maxWidth: `calc(${scale.sidebarSize} - 3px)`
   },
   [theme.breakpoints.up('xl')]: {
      maxWidth: scale.sidebarSize
   },
   // [theme.breakpoints.down('xl')]: {
   //    top: `calc(${scale.navbarHeight} - 1rem)`
   // },
   [theme.breakpoints.down('md')]: {
      top: `calc(${scale.navbarHeight} - 1.5rem)`,
      maxWidth: 0,
      '& .menu-first-level .flex-name': {
         paddingLeft: '.5rem !important',
         paddingRight: '.5rem !important'
      },
      '& .menu-first-level .flex-name .menu-first-text': {
         paddingLeft: '.5rem !important'
      },
      '& .menu-first-level .flex-name .menu-first-icon::before': {
         background: 'transparent'
      }
   },
   [theme.breakpoints.down('sm')]: {
      top: `calc(${scale.navbarHeight} - 2.5rem)`,
   },
   '&.show': {
      maxWidth: '45%',
      overflow: 'unset !important',
      [theme.breakpoints.up('sm')]: {
         maxWidth: '30%'
      },
      [theme.breakpoints.up('md')]: {
         maxWidth: '248px'
      }
   },
   '& .menu-first-level .flex-name svg': {
      width: 24,
      height: 24,
      [theme.breakpoints.up('lg')]: {
         width: 27,
         height: 27
      },
      [theme.breakpoints.up('xl')]: {
         width: 30,
         height: 30
      }
   }
}))
const MenuSecondLevel = styled('div')(({ theme }) => ({
   '&.active': {
      width: '100%',
      height: '100%',
      maxWidth: '248px',
      [theme.breakpoints.down('lg')]: {
         '.menu-second-level .item-second-list': {
            padding: '0.406rem 0.5rem !important'
         }
      }
   },
   // '&.active12': {
   //    width: '100%',
   //    maxWidth: '248px',
   //    top: `calc(calc(${scale.navbarHeight} + ${scale.secondToggleStore}))`,
   //    [theme.breakpoints.down('lg')]: {
   //       '.menu-second-level .item-second-list': {
   //          padding: '0.406rem 0.5rem !important',
   //       }
   //    },
   //    [theme.breakpoints.up('lg')]: {
   //       '.menu-second-level .item-second-list': {
   //          padding: '0.406rem 0.5rem !important',
   //       }
   //    },
   // },
   // '&.active13': {
   //    width: '100%',
   //    maxWidth: '248px',
   //    top: `calc(calc(${scale.navbarHeight} + ${scale.secondToggle}))`,
   //    [theme.breakpoints.down('lg')]: {
   //       '.menu-second-level .item-second-list': {
   //          padding: '0.406rem 0.5rem !important'
   //       }
   //    },
   //    [theme.breakpoints.up('lg')]: {

   //       '.menu-second-level .item-second-list': {
   //          padding: '0.406rem 0.5rem !important',
   //       }
   //    },
   // },
   // '.menu-second-level': {
   //    '.item-second-list': {
   //       '&:hover, &[aria-expanded="true"]': {
   //          backgroundColor: colors.themeMainColor,
   //          color: colors.themeSecondColor
   //       }
   //    }
   // },
}))
const MenuScroll = styled('div')(({ theme }) => ({
   maxHeight: `calc(100vh - ${scale.navbarHeight})`,
   [theme.breakpoints.down('xl')]: {
      maxHeight: `calc(100vh - (${scale.navbarHeight} - 1rem))`
   },
   [theme.breakpoints.down('lg')]: {
      maxHeight: `calc(100vh - (${scale.navbarHeight} - 1.5rem))`,
   },
   [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100vh - (${scale.navbarHeight} - 2.5rem))`
   }
}))
const MenuFirstLevelScroll = styled(MenuScroll)(({ theme }) => ({
   // justifyContent: 'space-between',
   display: 'flex',
   flexDirection: 'column',
   height: '100%',
   [theme.breakpoints.down('lg')]: {
      paddingBottom: '0rem !important'
   }
}))
const SidebarBackdrop = styled('div')(({ theme }) => ({
   display: 'none',
   // [theme.breakpoints.up('lg')]: {
   //    '&.show': {
   //       position: 'fixed',
   //       top: 0,
   //       width: '100%',
   //       height: '100%',
   //       display: 'block',
   //       zIndex: 1199
   //    }
   // }
}))
const CustomBadge = styled(Badge)(({ theme }) => ({
   '.MuiBadge-badge': {
      backgroundColor: colors.themeMainColor,
      fontSize: 9,
      minWidth: 'unset',
      height: 14,
      padding: 0
   }
}))

const MenuThirdLevel = styled('div')(({ theme }) => ({
   '.menu-third-level': {
      '.item-third-list': {
         '&:hover': {
            backgroundColor: colors.green_06,
            color: colors.themeMainColor
         }
      }
   }
}))

const NotiForAdmin = styled('div')(({ theme }) => ({
   width: 30,
   height: 30,
   backgroundColor: colors.danger,
   borderRadius: 50,
   // zIndex: -1,
   position: 'absolute',
   left: 15,
   bottom: 12,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   border: `2px solid ${colors.white}`,
   color: colors.white,
   fontSize: 12
}))

export default function Sidebar() {
   const { t } = useTranslation();
   const matches = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
   const [hover, setHover]: any[] = useState(false)
   const [toggleLevel2, setToggleLevel2] = useState(0)
   const [toggleLevel3, setToggleLevel3]: any[] = useState([])
   const [activeMenuLevel1, setActiveMenuLevel1] = useState<any>('');
   const [bidderData, setBidderData] = useState<any[]>([])
   const [notiTotal, setNotiTotal] = useState<number>(0)
   const [depositData, setDepositData] = useState<any[]>([])
   const [repairData, setRepairData] = useState<any[]>([])
   const [notiDepositTotal, setNotiDepositTotal] = useState<number>(0)
   const [notiTotalRepair, setNotiTotalRepair] = useState<number>(0)
   const [cancelData, setCancelData] = useState<any[]>([])
   const [notiTotalCancel, setNotiTotalCancel] = useState<number>(0)
   const [transferData, setTransferData] = useState<any[]>([])
   const [notiTotalTransfer, setNotiTotalTransfer] = useState<number>(0)
   const [invoiceData, setInvoiceData] = useState<any[]>([])
   const [notiTotalInvoice, setNotiTotalInvoice] = useState<number>(0)
   const [certificateData, setCertificateData] = useState<any[]>([])
   const [notiTotalCertificate, setNotiTotalCertificate] = useState<number>(0)
   const [bidderCustomer, setBidderCustomer] = useState<any[]>([])
   const [leaseCustomer, setLeaseCustomer] = useState<any[]>([])
   const userProfile: any = JSON.parse(getProfile() || '{}')

   // const menus = getPermissionMenus()
   const payBillEntry:any = Object?.entries(getPermissionMenus()|| {})?.find(([key, value]:any) => value.menuFirstKey === 'PAY_BILL');

   const menus = JSON.parse(decode(userProfile))?.roleId === 4 ? getPermissionMenus() : payBillEntry ? {
      ...getPermissionMenus(), [payBillEntry[0]]: {
         menuFirstId: 4,
         menuFirstKey: "PAY_BILL",
         menuFirstSorting: 4,
         menuSeconds: [
            {
               ...payBillEntry[1],
               menuSecondKey: 'PAY_BILL_LIST',
               menuSecondId: 99,
               menuThirds:[]
            },
            {
               ...payBillEntry[1],
               menuSecondKey: 'PAY_BILL_INVOICE',
               menuSecondId: 100,
               menuThirds:[]
            },
         ]
      }

   } : getPermissionMenus()

   


   const onToggle = (index: string) => {
      setToggleLevel3({ [index]: toggleLevel3[index] === undefined ? true : !toggleLevel3[index] })
   }

   const checkActiveMenu = useCallback(() => {
      const activeMenu = getPermissionActiveMenu()
      if (activeMenu?.menuSecondId) onToggle(`menu-second-${activeMenu.menuSecondId}`)
   }, [])

   useEffect(() => {
      checkActiveMenu()
   }, [checkActiveMenu])

   const goToRoute = (route: string, permission: any) => {
      const permissions = { ...getPermissions(), activeMenu: permission }
      localStorage.setItem(STORAGE_KEY.PERMISSION, encode(JSON.stringify(permissions)))
      return (window.location.href = route || '#')
   }

   const isToggleLevel1 = (id: number) => {
      setToggleLevel2(id)
   }

   const hideSidebar = () => {
      setHover(false)
      setToggleLevel2(0)
      setToggleLevel3([])
   }

   const loadBidderAdmin = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         // status: "PENDING"
      }
      await BidderApi.findAllAdmin(condition)
         .then((res) => {
            if (res.status === 200) {
               setBidderData(res.data)
               const pending = res.data.map((e: any) => Number(e.cnt_preliminary))
               let sum = 0
               const sumInarray = pending?.map((x: any) => sum += x)
               const total = sumInarray[sumInarray.length - 1]
               setNotiTotal(total)
            }
         })
         .catch(() => {
            setBidderData([])
         })
   }
   const loadDeposit = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         userId: JSON.parse(decode(userProfile))?.userId,
         // status: "PENDING"
      }
      await DepositApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setDepositData(res.data)
               // const pending = res.data.map((e: any) => Number(e.cnt_preliminary))
               // let sum = 0
               // const sumInarray = pending?.map((x: any) => sum += x)
               // const total = sumInarray[sumInarray.length - 1]
               const pending = res.data.filter((e: any) => e.status === 'WAITING')
               // const pending = res.data

               setNotiDepositTotal(pending?.length)
            }
         })
         .catch(() => {
            setDepositData([])
         })
   }
   const loadRepairAdmin = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         status: "WAITING",
         branchId: 1
      }
      await RepairApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setRepairData(res.data)
               const pending = res.data.filter((e: any) => e.status === 'WAITING')
               // let sum = 0 
               // const sumInarray = pending?.map((x: any) => sum+=x)
               // const total = sumInarray[sumInarray.length -1]
               setNotiTotalRepair(pending.length)
            }
         })
         .catch(() => {
            setRepairData([])
         })
   }
   const loadCancelContractAdmin = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         status: "WAITING",
         branchId: 1
      }
      await CancelContractApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setCancelData(res.data)
               const pending = res.data.filter((e: any) => e.status === 'WAITING')
               // let sum = 0 
               // const sumInarray = pending?.map((x: any) => sum+=x)
               // const total = sumInarray[sumInarray.length -1]
               setNotiTotalCancel(pending.length)
            }
         })
         .catch(() => {
            setRepairData([])
         })
   }
   const loadTransferLeaseAgreement = async () => {
      const condition: any = {
         sortBy: "transferDate",
         sortType: "DESC",
         page: 1,
         pageLimit: 100,
         status: "WAITING",
      }
      await TranferApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setTransferData(res.data)
               const pending = res.data.filter((e: any) => e.status === 'WAITING')
               // let sum = 0 
               // const sumInarray = pending?.map((x: any) => sum+=x)
               // const total = sumInarray[sumInarray.length -1]
               setNotiTotalTransfer(pending.length)
            }
         })
         .catch(() => {
            setRepairData([])
         })
   }
   const loadInvoiceAdmin = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         status: "PROGRESS",
      }
      await InvoiceApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setInvoiceData(res.data)
               const pending = res.data.filter((e: any) => e.status === 'PROGRESS')
               // let sum = 0 
               // const sumInarray = pending?.map((x: any) => sum+=x)
               // const total = sumInarray[sumInarray.length -1]
               setNotiTotalInvoice(pending.length)
            }
         })
         .catch(() => {
            setInvoiceData([])
         })
   }
   const loadInvoice = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         userId: JSON.parse(decode(userProfile))?.userId,
      }
      await InvoiceApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setInvoiceData(res.data)
               const pending = res.data.filter((e: any) => e.status === 'WAITING' || e.status === 'NOT_PAY')
               // let sum = 0 
               // const sumInarray = pending?.map((x: any) => sum+=x)
               // const total = sumInarray[sumInarray.length -1]
               setNotiTotalInvoice(pending.length)
            }
         })
         .catch(() => {
            setInvoiceData([])
         })
   }
   const loadCertificate = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 100,
         status: "WAITING",
      }
      await CertificateAPI.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setCertificateData(res.data)
               const pending = res.data.filter((e: any) => e.status === 'WAITING')
               // let sum = 0 
               // const sumInarray = pending?.map((x: any) => sum+=x)
               // const total = sumInarray[sumInarray.length -1]
               setNotiTotalCertificate(pending.length)
            }
         })
         .catch(() => {
            setCertificateData([])
         })
   }

   useEffect(() => {
      if (JSON.parse(decode(userProfile))?.roleId !== 4) {
         loadBidderAdmin()
         loadRepairAdmin()
         loadCancelContractAdmin()
         loadTransferLeaseAgreement()
         loadInvoiceAdmin()
         loadCertificate()
      } else {
         loadDeposit()
         loadInvoice()
      }
   }, [])

   const getAgreement = async () => {
      const condition: any = {
         page: 1,
         pageLimit: 5,
         userId: JSON.parse(decode(userProfile))?.userId,
      };

      await LeaseAgreementApi.findAll(condition)
         .then((res) => {
            if (res.status === 200) {
               setLeaseCustomer(res.data)
            } else {
               setLeaseCustomer([])
            }
         })

      await BidderApi.findAll(condition).then((res) => {
         if (res.status === 200) {
            setBidderCustomer(res.data)
         } else {
            setBidderCustomer([])
         }
      });
   };

   useEffect(() => {
      if (JSON.parse(decode(userProfile))?.roleId == 4) {
         getAgreement()
      }
   }, [])

   const pathname = window.location.pathname
   const activePath = useCallback(() => {
      const preName = pathname.substring(1).split('/')
      if (preName) {
         const name = preName[0]
         setActiveMenuLevel1(matchRoute(name))
      }
   }, [pathname])

   useEffect(() => {
      activePath()
   }, [activePath]);

   return (
      <>
         <MenuFirstLevel
            className={`menu-first ${matches || hover ? 'show' : ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => {
               setHover(false)
            }}
         >
            <MenuFirstLevelScroll className={'menu-first-wrap'}>
               {menus && (bidderCustomer.length !== 0 || bidderCustomer.length === 0) && leaseCustomer.length === 0 && JSON.parse(decode(userProfile))?.roleId === 4 && (
                  _.map(menus, ({ menuFirstId, menuFirstKey, menuSeconds: mSub }: any, iMFrist: number) => {
                     if (menuFirsts[menuFirstKey])
                        // console.log(mSub, 'menuFirstId')
                        return (
                           (menuFirstId === 1 || menuFirstId === 2) && <>
                              {mSub !== undefined && (
                                 (menuFirsts[menuFirstKey] && mSub.length > 0) && (
                                    <>
                                       <Nav.Item
                                          key={`menu-first-${menuFirstId}`}
                                          aria-controls={menuFirstId}
                                          id={menuFirstId}
                                          className={`flex-row menu-first-level  'd-flex') || 'd-none') : 'd-flex'}`}
                                          onClick={() => isToggleLevel1(Number(menuFirstId))}
                                       // onMouseEnter={() => isToggleLevel1(Number(menuFirstId))}

                                       >
                                          <div className={`flex-name menu-first-arrow ${toggleLevel2 === menuFirstId ? 'active' : ''}`}>
                                             <CustomBadge badgeContent={10} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                                                <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                                   {menuFirsts[menuFirstKey].icon}
                                                </IconButton>
                                                {JSON.parse(decode(userProfile))?.roleId === 4 && menuFirstId === 2 && depositData.length > 0 && notiDepositTotal > 0 && <NotiForAdmin>{notiDepositTotal}</NotiForAdmin>}
                                             </CustomBadge>
                                             <div className="menu-first-text text-ellipsis">
                                                <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                                {/* {notiDepositTotal > 0 && menuFirstId === 2 && <NotiForAdmin>{notiDepositTotal}</NotiForAdmin>} */}
                                             </div>

                                          </div>

                                          {menuFirsts[menuFirstKey] && mSub.length && (
                                             <>
                                                <MenuSecondLevel
                                                   className={`menu-second ${toggleLevel2 === menuFirstId ? 'active' : ''} ${menuFirstId === 12 ? 'active12' : menuFirstId === 13 ? 'active13' : ''}`}>
                                                   <MenuScroll className={'menu-second-wrap'}>
                                                      {menuFirsts[menuFirstKey] &&
                                                         mSub &&
                                                         _.map(mSub, ({ menuSecondId, menuSecondKey, menuSecoundTags, menuThirds: mThirds }: any, iMSub: number) => {

                                                            if (menuSeconds[menuSecondKey])
                                                               return (menuSeconds[menuSecondKey] && mThirds.length) && (
                                                                  <Nav.Item key={`menu-second-${menuSecondId}`} id={menuSecondId} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`}>
                                                                     <Button
                                                                        className={`item-second-list text-ellipsis w-100`}
                                                                        onClick={() => onToggle(`menu-second-${menuSecondId}`)}
                                                                        aria-controls={`menu-second-${menuSecondId}`}
                                                                        aria-expanded={toggleLevel3[`menu-second-${menuSecondId}`]}
                                                                        endIcon={toggleLevel3[`menu-second-${menuSecondId}`] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>
                                                                        <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} ellipsis={menuSecondKey === 'MANAGE_DOCTOR_FEE' || menuSecondKey === 'DEFAULT_PRODUCT'} />
                                                                     </Button>
                                                                     <Collapse in={toggleLevel3[`menu-second-${menuSecondId}`]}>
                                                                        <MenuThirdLevel id={`menu-second-${menuSecondId}`} className="menu-third">
                                                                           <ul className={'menu-third-level'}>
                                                                              {_.map(mThirds, ({ menuThirdId, menuThirdKey, menuThirdTags }: any, iMThirds: number) => {
                                                                                 if (menuThirds[menuThirdKey])
                                                                                    return (
                                                                                       menuThirds[menuThirdKey] && (
                                                                                          <Nav.Item key={`menu-third-${menuThirdId}`} className={`item-third-list ${menuThirdKey === 'CURRENCY' && 'd-none'}`} onClick={() => goToRoute(menuThirds[menuThirdKey].path, mThirds[iMThirds])}>
                                                                                             <li className={'w-100 text-ellipsis'} onClick={() => setToggleLevel3}>
                                                                                                <TextEllipsis
                                                                                                   title={t(menuThirds[`${menuThirdKey}`].textName)} />

                                                                                             </li>
                                                                                          </Nav.Item>
                                                                                       )
                                                                                    )
                                                                              })}
                                                                           </ul>
                                                                        </MenuThirdLevel>
                                                                     </Collapse>
                                                                  </Nav.Item>
                                                               ) || (
                                                                     menuSeconds[menuSecondKey] && (
                                                                        <Nav.Item key={`menu-second-${menuSecondId}`} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`} onClick={() => goToRoute(menuSeconds[menuSecondKey].path, mSub[iMSub])}>
                                                                           <div className={'item-second-list text-ellipsis'}>
                                                                              <CustomBadge
                                                                                 badgeContent={10}
                                                                                 max={10}
                                                                                 color="error"
                                                                                 className="menu-badge w-100"
                                                                                 invisible={!menuSeconds[menuSecondKey].badge}
                                                                                 sx={{ '.MuiBadge-badge:not(.MuiBadge-invisible)': { position: 'relative', top: 5, left: 8, right: 0, bottom: 0, transform: 'none' } }}>
                                                                                 <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} />
                                                                                 {notiDepositTotal > 0 && menuSecondId === 5 && <NotiForAdmin style={{ right: '-7px', left: 'inherit', bottom: 5, position: 'absolute' }}>{notiDepositTotal}</NotiForAdmin>}
                                                                              </CustomBadge>
                                                                           </div>
                                                                        </Nav.Item>
                                                                     )
                                                                  )
                                                         })}
                                                   </MenuScroll>
                                                </MenuSecondLevel>
                                             </>
                                          ) || (
                                                <></>
                                             )}
                                       </Nav.Item>
                                    </>
                                 ) || (
                                    menuFirsts[menuFirstKey] &&
                                    !menuFirsts[menuFirstKey].disabled && (
                                       <Nav.Item
                                          key={`menu-first-${menuFirstId}`}
                                          className={`d-flex flex-row menu-first-level`}
                                          onClick={() => goToRoute(menuFirsts[menuFirstKey].path, menus[iMFrist])}
                                          onMouseEnter={() => isToggleLevel1(Number(0))}>
                                          <div className={'flex-name'}>
                                             <CustomBadge badgeContent={1} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                                                <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                                   {menuFirsts[menuFirstKey].icon}
                                                </IconButton>
                                             </CustomBadge>
                                             <div className="menu-first-text text-ellipsis">
                                                <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                             </div>
                                          </div>
                                       </Nav.Item>
                                    )
                                 )
                              )}
                           </>
                        )
                  })
               )}
               {menus && leaseCustomer.length !== 0 && JSON.parse(decode(userProfile))?.roleId === 4 && (
                  _.map(menus, ({ menuFirstId, menuFirstKey, menuSeconds: mSub }: any, iMFrist: number) => {
                     if (menuFirsts[menuFirstKey])
                        // console.log(mSub, 'menuFirstId')
                        return (
                           menuFirstId && menuFirstKey !== 'SUE' && <>
                              {mSub !== undefined && (
                                 (menuFirsts[menuFirstKey] && menuFirstKey !== 'SUE' && mSub.length > 0) && (
                                    <>
                                       <Nav.Item
                                          key={`menu-first-${menuFirstId}`}
                                          aria-controls={menuFirstId}
                                          id={menuFirstId}
                                          className={`flex-row menu-first-level  'd-flex') || 'd-none') : 'd-flex'}`}
                                          onClick={() => isToggleLevel1(Number(menuFirstId))}
                                       // onMouseEnter={() => isToggleLevel1(Number(menuFirstId))}

                                       >
                                          <div className={`flex-name menu-first-arrow ${toggleLevel2 === menuFirstId ? 'active' : ''}`}>
                                             <CustomBadge badgeContent={10} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge} style={{ position: 'relative' }}>
                                                <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                                   {menuFirsts[menuFirstKey].icon}
                                                </IconButton>
                                                {/* {JSON.parse(decode(userProfile))?.roleId === 4 && menuFirstId === 2 && bidderData.length > 0 && notiDepositTotal > 0 && <NotiForAdmin>{notiDepositTotal}</NotiForAdmin>}
                                                <NotiForAdmin>{notiDepositTotal}</NotiForAdmin> */}
                                                {/* {notiDepositTotal > 0 && menuFirstId === 2 && <NotiForAdmin>{notiDepositTotal}</NotiForAdmin>} */}
                                                {JSON.parse(decode(userProfile))?.roleId === 4 && menuFirstId === 2 && depositData.length > 0 && notiDepositTotal > 0 && <NotiForAdmin>{notiDepositTotal}</NotiForAdmin>}


                                             </CustomBadge>
                                             <div className="menu-first-text text-ellipsis">
                                                <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                             </div>

                                          </div>

                                          {menuFirsts[menuFirstKey] && mSub.length && (
                                             <>
                                                <MenuSecondLevel
                                                   className={`menu-second ${toggleLevel2 === menuFirstId ? 'active' : ''} ${menuFirstId === 12 ? 'active12' : menuFirstId === 13 ? 'active13' : ''}`}>
                                                   <MenuScroll className={'menu-second-wrap'}>
                                                      {menuFirsts[menuFirstKey] &&
                                                         mSub &&
                                                         _.map(mSub, ({ menuSecondId, menuSecondKey, menuSecoundTags, menuThirds: mThirds }: any, iMSub: number) => {

                                                            if (menuSeconds[menuSecondKey])
                                                               return (menuSeconds[menuSecondKey] && mThirds.length) && (
                                                                  <Nav.Item key={`menu-second-${menuSecondId}`} id={menuSecondId} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`}>
                                                                     <Button
                                                                        className={`item-second-list text-ellipsis w-100`}
                                                                        onClick={() => onToggle(`menu-second-${menuSecondId}`)}
                                                                        aria-controls={`menu-second-${menuSecondId}`}
                                                                        aria-expanded={toggleLevel3[`menu-second-${menuSecondId}`]}
                                                                        endIcon={toggleLevel3[`menu-second-${menuSecondId}`] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>
                                                                        <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} ellipsis={menuSecondKey === 'MANAGE_DOCTOR_FEE' || menuSecondKey === 'DEFAULT_PRODUCT'} />
                                                                     </Button>
                                                                     <Collapse in={toggleLevel3[`menu-second-${menuSecondId}`]}>
                                                                        <MenuThirdLevel id={`menu-second-${menuSecondId}`} className="menu-third">
                                                                           <ul className={'menu-third-level'}>
                                                                              {_.map(mThirds, ({ menuThirdId, menuThirdKey, menuThirdTags }: any, iMThirds: number) => {
                                                                                 if (menuThirds[menuThirdKey])
                                                                                    return (
                                                                                       menuThirds[menuThirdKey] && (
                                                                                          <Nav.Item key={`menu-third-${menuThirdId}`} className={`item-third-list ${menuThirdKey === 'CURRENCY' && 'd-none'}`} onClick={() => goToRoute(menuThirds[menuThirdKey].path, mThirds[iMThirds])}>
                                                                                             <li className={'w-100 text-ellipsis'} onClick={() => setToggleLevel3}>
                                                                                                <TextEllipsis
                                                                                                   title={t(menuThirds[`${menuThirdKey}`].textName)} />
                                                                                                {/* {notiDepositTotal > 0 && menuSecondId === 5 && <NotiForAdmin style={{ right: '-5px', bottom: '5px', position: 'absolute' }}>{notiDepositTotal}</NotiForAdmin>} */}

                                                                                             </li>
                                                                                          </Nav.Item>
                                                                                       )
                                                                                    )
                                                                              })}
                                                                           </ul>
                                                                        </MenuThirdLevel>
                                                                     </Collapse>
                                                                  </Nav.Item>
                                                               ) || (
                                                                     menuSeconds[menuSecondKey] && (
                                                                        <Nav.Item key={`menu-second-${menuSecondId}`} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`} onClick={() => goToRoute(menuSeconds[menuSecondKey].path, mSub[iMSub])}>
                                                                           <div className={'item-second-list text-ellipsis'}>
                                                                              <CustomBadge
                                                                                 badgeContent={10}
                                                                                 max={10}
                                                                                 color="error"
                                                                                 className="menu-badge w-100"
                                                                                 invisible={!menuSeconds[menuSecondKey].badge}
                                                                                 sx={{ '.MuiBadge-badge:not(.MuiBadge-invisible)': { position: 'relative', top: 5, left: 8, right: 0, bottom: 0, transform: 'none' } }}>
                                                                                 <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} />
                                                                                 {notiDepositTotal > 0 && menuSecondId === 5 && <NotiForAdmin style={{ right: '-7px', left: 'inherit', bottom: 5, position: 'absolute' }}>{notiDepositTotal}</NotiForAdmin>}
                                                                              </CustomBadge>
                                                                           </div>
                                                                        </Nav.Item>
                                                                     )
                                                                  )
                                                         })}
                                                   </MenuScroll>
                                                </MenuSecondLevel>
                                             </>
                                          ) || (
                                                <></>
                                             )}
                                       </Nav.Item>
                                    </>
                                 ) || (
                                    menuFirsts[menuFirstKey] &&
                                    !menuFirsts[menuFirstKey].disabled && (
                                       <Nav.Item
                                          key={`menu-first-${menuFirstId}`}
                                          className={`d-flex flex-row menu-first-level`}
                                          onClick={() => goToRoute(menuFirsts[menuFirstKey].path, menus[iMFrist])}
                                          onMouseEnter={() => isToggleLevel1(Number(0))}>
                                          <div className={'flex-name'}>
                                             <CustomBadge badgeContent={1} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                                                <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                                   {menuFirsts[menuFirstKey].icon}
                                                </IconButton>
                                                {menuFirstId === 4 && invoiceData.length > 0 && notiTotalInvoice > 0 && <NotiForAdmin>{notiTotalInvoice}</NotiForAdmin>}
                                             </CustomBadge>
                                             <div className="menu-first-text text-ellipsis">
                                                <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                             </div>
                                          </div>
                                       </Nav.Item>
                                    )
                                 )
                              )}
                           </>
                        )
                  })
               )}
               {menus && JSON.parse(decode(userProfile))?.roleId !== 4 && (
                  _.map(menus, ({ menuFirstId, menuFirstKey, menuSeconds: mSub }: any, iMFrist: number) => {

                     if (menuFirsts[menuFirstKey])
                        return (
                           menuFirstId && <>
                              {mSub !== undefined && (
                                 (menuFirsts[menuFirstKey] && mSub.length > 0) && (
                                    <>
                                       <Nav.Item
                                          key={`menu-first-${menuFirstId}`}
                                          aria-controls={menuFirstId}
                                          id={menuFirstId}
                                          className={`flex-row menu-first-level  'd-flex') || 'd-none') : 'd-flex'}`}
                                          onClick={() => isToggleLevel1(Number(menuFirstId))}
                                       // onMouseEnter={() => isToggleLevel1(Number(menuFirstId))}

                                       >
                                          <div className={`flex-name menu-first-arrow ${toggleLevel2 === menuFirstId ? '' : ''}`}>
                                             <CustomBadge badgeContent={10} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                                                <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                                   {menuFirsts[menuFirstKey].icon}
                                                </IconButton>
                                                {JSON.parse(decode(userProfile))?.roleId === 4 && menuFirstId === 2 && bidderData.length > 0 && notiTotal > 0 && <NotiForAdmin>{notiTotal}</NotiForAdmin>}
                                             </CustomBadge>
                                             <div className="menu-first-text text-ellipsis">
                                                <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                             </div>

                                          </div>

                                          {menuFirsts[menuFirstKey] && mSub.length && (
                                             <>
                                                <MenuSecondLevel
                                                   className={`menu-second ${toggleLevel2 === menuFirstId ? 'active' : ''} ${menuFirstId === 12 ? 'active12' : menuFirstId === 13 ? 'active13' : ''}`}>
                                                   <MenuScroll className={'menu-second-wrap'}>
                                                      {menuFirsts[menuFirstKey] &&
                                                         mSub &&
                                                         _.map(mSub, ({ menuSecondId, menuSecondKey, menuSecoundTags, menuThirds: mThirds }: any, iMSub: number) => {
                                                            if (menuSeconds[menuSecondKey])
                                                               return (menuSeconds[menuSecondKey] && mThirds.length) && (
                                                                  <Nav.Item key={`menu-second-${menuSecondId}`} id={menuSecondId} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`}>
                                                                     <Button
                                                                        className={`item-second-list text-ellipsis w-100`}
                                                                        onClick={() => onToggle(`menu-second-${menuSecondId}`)}
                                                                        aria-controls={`menu-second-${menuSecondId}`}
                                                                        aria-expanded={toggleLevel3[`menu-second-${menuSecondId}`]}
                                                                        endIcon={toggleLevel3[`menu-second-${menuSecondId}`] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>
                                                                        <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} ellipsis={menuSecondKey === 'MANAGE_DOCTOR_FEE' || menuSecondKey === 'DEFAULT_PRODUCT'} />
                                                                     </Button>
                                                                     <Collapse in={toggleLevel3[`menu-second-${menuSecondId}`]}>
                                                                        <MenuThirdLevel id={`menu-second-${menuSecondId}`} className="menu-third">
                                                                           <ul className={'menu-third-level'}>
                                                                              {_.map(mThirds, ({ menuThirdId, menuThirdKey, menuThirdTags }: any, iMThirds: number) => {
                                                                                 if (menuThirds[menuThirdKey])
                                                                                    return (
                                                                                       menuThirds[menuThirdKey] && (
                                                                                          <Nav.Item key={`menu-third-${menuThirdId}`} className={`item-third-list ${menuThirdKey === 'CURRENCY' && 'd-none'}`} onClick={() => goToRoute(menuThirds[menuThirdKey].path, mThirds[iMThirds])}>
                                                                                             <li className={'w-100 text-ellipsis'} onClick={() => setToggleLevel3}>
                                                                                                <TextEllipsis
                                                                                                   title={t(menuThirds[`${menuThirdKey}`].textName)} />
                                                                                             </li>
                                                                                          </Nav.Item>
                                                                                       )
                                                                                    )
                                                                              })}
                                                                           </ul>
                                                                        </MenuThirdLevel>
                                                                     </Collapse>
                                                                  </Nav.Item>
                                                               ) || (
                                                                     menuSeconds[menuSecondKey] && (
                                                                        <Nav.Item key={`menu-second-${menuSecondId}`} className={`menu-second-level ${menuSeconds[menuSecondKey].isHidden ? 'd-none' : ''}`} onClick={() => goToRoute(menuSeconds[menuSecondKey].path, mSub[iMSub])}>
                                                                           <div className={'item-second-list text-ellipsis'}>
                                                                              <CustomBadge
                                                                                 badgeContent={10}
                                                                                 max={10}
                                                                                 color="error"
                                                                                 className="menu-badge w-100"
                                                                                 invisible={!menuSeconds[menuSecondKey].badge}
                                                                                 sx={{ '.MuiBadge-badge:not(.MuiBadge-invisible)': { position: 'relative', top: 5, left: 8, right: 0, bottom: 0, transform: 'none' } }}>
                                                                                 <TextEllipsis title={t(menuSeconds[menuSecondKey].textName)} />
                                                                                 {JSON.parse(decode(userProfile))?.roleId !== 4 && menuFirstId === 11 && cancelData.length > 0 && notiTotalCancel > 0 && <NotiForAdmin>{notiTotalCancel}</NotiForAdmin>}
                                                                              </CustomBadge>
                                                                           </div>
                                                                        </Nav.Item>
                                                                     )
                                                                  )
                                                         })}
                                                   </MenuScroll>
                                                </MenuSecondLevel>
                                             </>
                                          ) || (
                                                <></>
                                             )}
                                       </Nav.Item>
                                    </>
                                 ) || (
                                    menuFirsts[menuFirstKey] &&
                                    !menuFirsts[menuFirstKey].disabled && (
                                       <Nav.Item
                                          key={`menu-first-${menuFirstId}`}
                                          className={`d-flex flex-row menu-first-level`}
                                          onClick={() => goToRoute(menuFirsts[menuFirstKey].path, menus[iMFrist])}
                                       // onMouseEnter={() => isToggleLevel1(Number(0))}
                                       >
                                          <div className={'flex-name'}>
                                             <CustomBadge badgeContent={1} max={10} color="error" className="menu-badge" invisible={!menuFirsts[menuFirstKey].badge}>
                                                <IconButton color="primary" component="span" className={`menu-first-icon p-0 ${activeMenuLevel1 === menuFirstKey ? 'active active-level-1' : ''}`}>
                                                   {menuFirsts[menuFirstKey].icon}
                                                </IconButton>
                                                {JSON.parse(decode(userProfile))?.roleId !== 4 && menuFirstId === 4 && invoiceData.length > 0 && notiTotalInvoice > 0 && <NotiForAdmin>{notiTotalInvoice}</NotiForAdmin>}
                                                {JSON.parse(decode(userProfile))?.roleId !== 4 && menuFirstId === 6 && repairData.length > 0 && notiTotalRepair > 0 && <NotiForAdmin>{notiTotalRepair}</NotiForAdmin>}
                                                {JSON.parse(decode(userProfile))?.roleId !== 4 && menuFirstId === 7 && transferData.length > 0 && notiTotalTransfer > 0 && <NotiForAdmin>{notiTotalTransfer}</NotiForAdmin>}
                                                {JSON.parse(decode(userProfile))?.roleId !== 4 && menuFirstId === 8 && certificateData.length > 0 && notiTotalCertificate > 0 && <NotiForAdmin>{notiTotalCertificate}</NotiForAdmin>}
                                                {JSON.parse(decode(userProfile))?.roleId !== 4 && menuFirstId === 11 && cancelData.length > 0 && notiTotalCancel > 0 && <NotiForAdmin>{notiTotalCancel}</NotiForAdmin>}
                                             </CustomBadge>
                                             <div className="menu-first-text text-ellipsis">
                                                <TextEllipsis title={t(menuFirsts[menuFirstKey].textName)} />
                                             </div>
                                          </div>
                                       </Nav.Item>
                                    )
                                 )
                              )}
                           </>
                        )
                  })
               )}
            </MenuFirstLevelScroll>
         </MenuFirstLevel>
         {toggleLevel2 > 0 && <SidebarBackdrop className={`sidebar-backdrop-desktop ${toggleLevel2 > 0 ? 'show' : ''}`} onClick={hideSidebar}></SidebarBackdrop>}
      </>
   )
}
