import BaseAPI from "../api";

const path = "zone";

export interface paramsFindAll {
  page: number;
  pageLimit: number;
  branchId?: number;
  search?: string;
  leasesType?: number;
  status?: string;
}
export interface IZone {
  zoneId?: number;
  zoneName: string;
  branchId: number;
  x: number;
  y: number;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
  color: string;
  status?: string;
  stall: IStall[];

  isEdit?: boolean;
  isChange?: boolean;
  vertical?: number | null;
  horizontal?: number | null;
}

export interface IStall {
  stallId?: number | null;
  zoneId?: number | null;
  stallName: string;
  stallNumber: string
  x: number;
  y: number;
  width: number;
  height: number;
  realWidth: number | null;
  realHeight: number | null;
  color: string;
  stallTypeId: number | null;
  status?: "OPEN" | "BLANK" | "RESERVE" | "CONTRACT" | "DELETED" | "NON_INFO";
  meterNumber?: IMeterNumber[];

  noStall?: number;
  isEdit?: boolean;
}

export interface IMeterNumber {
  meterId?: number;
  stallId?: number;
  meterNumber: string;
  meterType: "WATER" | "ELECTRICITY";
}
export default class ZoneApi extends BaseAPI {
  static findAll(props: paramsFindAll): Promise<any> {
    return this.api.get(path, { params: { ...props } }).then((res) => res);
  }

  static findById(id: number): Promise<any> {
    return this.api.get(`${path}/${id}`)
  }

  static create(body: IZone): Promise<any> {
    return this.api.post(path, body).then((res) => res);
  }

  static update(id: number, body: IZone): Promise<any> {
    return this.api.patch(`${path}/${id}`, body).then((res) => res);
  }

  static remove(id: number): Promise<any> {
    return this.api.delete(`${path}/${id}`).then((res) => res);
  }
}
