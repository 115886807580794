import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Cookies from "universal-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
/** TRANSLATION */
import { useTranslation } from "react-i18next";

/** STYLE */
import { ButtonForgetPassword, GridLogin, Logo } from "./style";
import { CardFormLogin, CardLogin } from "./style";

/** API, UTIL */
import LoginApi from "../../api/auth/auth.api";
import PrefixApi from "../../api/setting/prefix/prefix.api";
import UserApi from '../../api/auth/users.api';
import { loginSuccess } from "../../utils/login.utils";
import { getBranch, getPermissionMenus, getToken, logout, removeBranchStorage, setProfile, setRefreshToken, setToken } from "../../utils/app.utils";
import { showModal } from "../../app/slice/modal.slice";

/**COMPONENT */
import ForgetPassword from "./forgot-password";
import InputCheckbox from "../../component/input/inputCheckbox";
import ButtonCustom from "../../component/button/buttonCustom";
import SendOTP from "./sendOTP";
import InputPasswordField from "../../component/input/inputPasswordField";
import InputUsernameField from "../../component/input/inputUsernameField";
import ModalCustom from "../../component/modalCustom/modalCustom";
import { routeName } from "../../routes/routers-name";
import ChangePassword from "./change-password";
import { swalError, swalSuccess } from "../../component/notification/swal";
import Register from "./register";
import CheckGoogle from "./checkGoogle";
import Policy from "./policy";
import Loading from "../../component/loading/loading";
import { STORAGE_KEY } from "../../utils/enum";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { colors } from "../../constants/colors";
import { logo } from "../../constants/images";
import { apiUrl } from "../../api/api";
import { encode } from 'js-base64';

const initStateErrorMessage = {
  USERNAME: "",
  PASSWORD: "",
  CONFIRM_PASSWORD: "",
  INVALID_USERNAME: ``,
  INVALID_PASSWORD: ``,
  INVALID_EMAIL: ``,
  INVALID_OTP: ``,
  INVALID_CONFIRM: ``,
};

export default function Login() {
  const { t } = useTranslation();
  const recaptchaRef: any = useRef(null);
  const cookies = new Cookies();
  const isMobile = useMediaQuery('(max-width: 900px)')

  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSucceeded, setSucceeded] = useState<boolean>(false);
  const [forgetPassword, setForgetPassword] = useState<boolean>(false);
  const [sendOTP, setSendOTP] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [register, setRegister] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage);
  const [reCaptcha, setReCaptcha] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [otpRefer, setOTPRefer] = useState<string>("");
  const [otpType, setOTPType] = useState<string>("");
  const [tokenvalidate, setTokenValidate] = useState<string>("");
  const [prefixId, setPrefixId] = useState<number>(0);
  const [PrefixOption, setPrefixOption] = useState<any[]>([]);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [mobilePhone, setMobilePhone] = useState<string>("");
  const [checkAccept, setCheckAccept] = useState<boolean>(false);
  const [checkGoogle, setCheckGoogle] = useState<boolean>(false);
  const [credential, setCredential] = useState<string>("");

  const onClickForgetPassword = () => {
    setForgetPassword((forgetPassword) => !forgetPassword);
    setSucceeded(false);
  };

  const isValidEmail = (email: string) => {
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  const onSubmitEmail = async () => {
    const condition: any = {
      email: email,
    };
    if (!email)
      return setErrorMessage({
        ...errorMessage,
        ...{ INVALID_EMAIL: t(`LOGIN.MESSAGE.INVALID_EMAIL`) },
      });
    if (isValidEmail(email)) {
      return swalError(`${t("LOGIN.SWAL.EMAIL_FAIL")}`)
    }
    setLoading(true);
    await LoginApi.forgot(condition)
      .then((res) => {
        setOTPRefer(res.data.otpRefer);
        setForgetPassword(false);
        setSendOTP(true);
        setOTPType("FORGOT_PASSWORD");
      })
      .catch(() => {
        swalError("ไม่พบอีเมลนี้ในระบบ");
        setEmail("")
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        setEmail("")
      })
  };

  const clearErrorMessage = () => setErrorMessage(initStateErrorMessage);

  const handleChangeRememberMe = (event: any) => {
    if (event.target.checked)
      cookies.set("REMEMBER_ME", event.target.checked, { path: "/" });
    else cookies.remove("REMEMBER_ME");
    setRememberMe(event.target.checked);
  };

  useEffect(() => {
    const currPath = window.location.href
    const token = getToken()
    const branchId = getBranch()
    const permissionMenus = getPermissionMenus()
    if (token && (branchId === null || !permissionMenus) && !currPath.includes('selectBranches')) {
      removeBranchStorage()
      // window.location.href = routeName.selectBranches
    }
  }, [])

  const instance = axios.create({
    baseURL: apiUrl,
  });

  const useAxiosAuth = () => {
    instance.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem("TOKEN");

        if (token) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${token}`,
          };
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
    return instance
  }

  const axiosApi = useAxiosAuth()
  const getUserProfile = async () => {
    await axiosApi.get(`auth/profile`)
      .then(async (res: any) => {
        if (res.data) {
          // console.log(res.data)
          setProfile(encode(JSON.stringify(res.data)))
          // dispatch(editProfile(res.data))
          if (res.data.roleId) {
            await axiosApi.get(`auth/rolePermissions`).then((res: any) => {
              if (res.data) {
                localStorage.setItem(STORAGE_KEY.PERMISSION, encode(JSON.stringify(res.data[0]?.menuFirsts)))
              } else {
                autoLogout()
              }
            }).catch(() => autoLogout())
          }
          if (res.data?.roleId === 4) {
            if (res.data?.isIdCard) {
              window.location.href = routeName.home;
            } else {
              window.location.href = routeName.editProfile;
            }
          } else {
            window.location.href = routeName.selectBranches;
          }

        } else {
          autoLogout()
        }
      }).catch(() => autoLogout());
  }

  const submit = async () => {
    if (!username)
      return setErrorMessage({
        ...errorMessage,
        ...{ USERNAME: t("LOGIN.MESSAGE.USERNAME") },
      });
    if (!password)
      return setErrorMessage({
        ...errorMessage,
        ...{ PASSWORD: t("LOGIN.MESSAGE.PASSWORD") },
      });
    setLoading(true);
    await LoginApi.login(username, password, reCaptcha)
      .then(async (res) => {
        if (res.data.otpRefer) {
          setOTPRefer(res.data.otpRefer);
          setSendOTP(true);
          setOTPType("REGISTER");
          setLoading(false);
        }
        if (res.data.accessToken) {
          setToken(res.data.accessToken);
          setRefreshToken(res.data.refreshToken);
          loginSuccess(res.data.accessToken, rememberMe);
          getUserProfile()
          if (rememberMe)
            cookies.set("TOKEN", res.data.accessToken, { path: "/" });
          setLoading(false);
          setUsername("")
          setPassword("")
        }
      })
      .catch((e) => {
        setLoading(false);
        Swal.fire({
          text: e.response.data.error_description || "เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง",
          icon: 'error',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: colors.danger
        }).then((result) => {
          window.location.reload();
        });
      })
  };

  const autoLogout = () => {
    logout()
    const currPath = window.location.href;
    if (!currPath.includes('login')) window.location.href = routeName.login
    return <Redirect to={routeName.login} />
  }
  const getPrefixes = async () => {
    const condition: any = {
      page: 1,
      pageLimit: 5,
      status: "ACTIVE"
    };
    const res = await PrefixApi.findAll(condition);
    setPrefixOption(res.data);
  };

  useEffect(() => {
    getPrefixes();
  }, []);

  const onSubmitRegister = async () => {
    const condition: any = {
      prefixId: prefixId,
      firstname: firstname,
      lastname: lastname,
      email: email,
      mobilePhone: mobilePhone,
      password: password,
      status: "ACTIVE",
      reCaptcha: reCaptcha,
    };
    if (!firstname || !lastname || !mobilePhone) {
      swalError(`${t("LOGIN.SWAL.NO_COMPLETE")}`);
    } else if (mobilePhone.length !== 10) {
      swalError(`${t("LOGIN.SWAL.PHONENUMBER_FAIL")}`);
    } else
      if (!email) {
        swalError(`${t("LOGIN.SWAL.EMAIL_FAIL")}`)
      } else if (password !== confirmPassword) {
        swalError(`${t("LOGIN.SWAL.NO_MATCH")}`);
      } else if (password.length <= 7) {
        swalError(`${t("LOGIN.SWAL.PASSWORD")}`);
      } else if (!reCaptcha) {
        swalError(`${t("LOGIN.SWAL.RECAPTCHA")}`);
      } else {
        setLoading(true);
      //   try {
      //   const res:any = await UserApi.create(condition)
      //   const data = res.data
      //   if (data.status === 201) {

      //     swalSuccess("กรอกรหัสOTP");
      //     setSendOTP(true);
      //     setForgetPassword(false);
      //     setChangePassword(false);
      //     setOTPType("REGISTER");
      //     setOTPRefer(data.data.otpRefer);
      //     setRegister(false);
      //     setLoading(false)
      //   } else {
      //     setLoading(false)
      //     swalError(data.error_description);
      //   }
      //   console.log(res);
      // } catch (error:any) {
      //     if (error.response) {
      //       // Request ส่งสำเร็จ แต่เซิร์ฟเวอร์ตอบกลับด้วยสถานะที่เป็นข้อผิดพลาด (เช่น 400)
      //       console.error('Error response:', error.response);
      //       setLoading(false)
      //       swalError(error.response.data.error_description);
      //     } else {
      //       console.error('Error:', error.message);
      //       setLoading(false)
      //     }
      //   }

     await UserApi.create(condition)
      .then((res) => {
        if (res.status === 201) {

          swalSuccess("กรอกรหัสOTP");
          setSendOTP(true);
          setForgetPassword(false);
          setChangePassword(false);
          setOTPType("REGISTER");
          setOTPRefer(res.data.otpRefer);
          setRegister(false);
          setLoading(false)
        } else {
          setLoading(false)
          swalError(res.error_description);
        }
      })
      .catch((error) => {
        setLoading(false)
        swalError(error.response.data.error_description);
      })
      }
  };

  const onClickReturnLogin = () => {
    setChangePassword(false);
    setForgetPassword(false);
    setSendOTP(false);
  };

  const submitOTP = async () => {
    setLoading(true);
    const condition: any = {
      otp: otp,
      otpType: otpType,
      otpRefer: otpRefer,
    };
    await LoginApi.validate(condition)
      .then((res) => {
        if (res.status === 200 && otpType === "REGISTER") {
          setTokenValidate(res.data.token);
          setSendOTP(false);
          setChangePassword(false);
          setCheckAccept(false)
          setUsername("")
          setPassword("")
          setReCaptcha("")
          setLastname("")
          setEmail("")
          setFirstname("")
          setConfirmPassword("")
          setOtp("")
          setLoading(false);
          swalSuccess(`${t("LOGIN.SWAL.REGIS_SUCCESS")}`);
        } else if (res.status === 200 && otpType === "FORGOT_PASSWORD") {
          setTokenValidate(res.data.token);
          setSendOTP(false);
          setChangePassword(true);
          setOtp("")
          setLoading(false);
        }
      })
      .catch((e: any) => {
        swalError("OTP ไม่ถูกต้อง")
        setOtp("")
        setLoading(false);
      })

  };

  const submitChanePassword = async () => {

    const condition: any = {
      token: tokenvalidate,
      password: password,
    };
    if (!password)
      return setErrorMessage({
        ...errorMessage,
        ...{ PASSWORD: t("LOGIN.MESSAGE.PASSWORD") },
      });
    if (!confirmPassword)
      return setErrorMessage({
        ...errorMessage,
        ...{ CONFIRM_PASSWORD: t("LOGIN.MESSAGE.CONFIRM_PASSWORD") },
      });
    if (password !== confirmPassword)
      return swalError(`${t("LOGIN.SWAL.NO_MATCH")}`);
    if (password.length < 8 && confirmPassword.length < 8)
      return swalError(`${t("LOGIN.SWAL.PASSWORD")}`);
    setLoading(true)
    await LoginApi.password(condition)
      .then((res) => {
        if (res.status === 200) {
          swalSuccess(`${t("LOGIN.SWAL.CHANGE_PASSWORD")}`);
          setChangePassword(false);
          setLoading(false)
          setPassword("")
          setConfirmPassword("")
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false)
        swalError(e.data.errorDescription);
      })
  };

  const onSignUp = () => setRegister(true);

  const onSuccessGoogle = async (credentialResponse: any) => {
    setCredential(credentialResponse.credential);
    const decoded: any = jwt_decode(credentialResponse.credential)
    console.log(decoded);
    setFirstname(decoded?.given_name)
    setLastname(decoded?.family_name)
    setEmail(decoded?.email)
  };

  const checkLoginGoole = async () => {
    const condition: any = {
      id_token: credential,
    };
    setLoading(true);
    await LoginApi.google(condition)
      .then(async (res: any) => {
        console.log(res)
        if (res.data.accessToken) {
          setToken(res.data.accessToken);
          setRefreshToken(res.data.refreshToken);
          loginSuccess(res.data.accessToken, rememberMe);
          getUserProfile()
          if (rememberMe)
            cookies.set("TOKEN", res.data.accessToken, { path: "/" });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.status);
        setCheckGoogle(true);
      });
    setLoading(false);
  }

  useEffect(() => {
    if (credential) {
      checkLoginGoole()
    }
  }, [credential])

  const onChangeRecapcha = async (value: any) => setReCaptcha(value);

  const onsubmitCheckGoogle = async () => {
    const condition: any = {
      prefixId: prefixId,
      firstname: firstname,
      lastname: lastname,
      mobilePhone: mobilePhone,
      email: email,
      status: "ACTIVE",
      reCaptcha: reCaptcha,
      id_token: credential,
    };
    if (!firstname || !lastname || !email || !mobilePhone || !prefixId) {
      swalError(`${t("LOGIN.SWAL.NO_COMPLETE")}`);
    } else if (mobilePhone.length !== 10) {
      swalError(`${t("LOGIN.SWAL.PHONENUMBER_FAIL")}`);
    } else if (isValidEmail(email)) {
      swalError(`${t("LOGIN.SWAL.EMAIL_FAIL")}`);
    } else if (!reCaptcha) {
      swalError(`${t("LOGIN.SWAL.RECAPTCHA")}`);
    } else {
      setLoading(true);
      await UserApi.createGoogle(condition)
        .then((res: any) => {
          if (res.status === 201) {
            const condition: any = {
              id_token: credential,
            };
            LoginApi.google(condition)
              .then(async (res: any) => {
                if (res.data.accessToken) {
                  setToken(res.data.accessToken);
                  setRefreshToken(res.data.refreshToken);
                  loginSuccess(res.data.accessToken, rememberMe);
                  getUserProfile()
                  if (rememberMe)
                    cookies.set("TOKEN", res.data.accessToken, { path: "/" });
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log(error.status);
                setCheckGoogle(true);
              });
          }
          setFirstname("")
          setLastname("")
          setEmail("")
          setMobilePhone("")
          setReCaptcha("")
        })
        .catch(() => {
          setLoading(false);
          setCheckGoogle(true)
          swalError(`${t("LOGIN.SWAL.HAVE_EMAIL")}`)
        })
    }
  };

  return (
    <>
      {!register && !checkGoogle && (
        <CardLogin>
          <Grid style={{ height: "980px" }}>
            <GridLogin item>
              <CardFormLogin xs={9} xl={3} md={5} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={12} xl={12} md={12} className="w-100 h-100">
                    <Logo src={logo.OTK_Logo} alt="Logo" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    xl={12}
                    md={12}
                    className="w-100 mt-3 mx-3 mb-0 m-md-0"
                  >
                    {!forgetPassword && !sendOTP && !changePassword && (

                      <Card>
                        <Card.Body>
                          <Card.Title>
                            {t(`LOGIN.TITLE`)}
                          </Card.Title>
                          <Card.Body>
                            <Grid
                              container
                              spacing={2}
                              className=" justify-content-center align-content-center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                xl={12}
                                md={12}
                                className="w-100"
                              >
                                <InputUsernameField
                                  onchange={(event) => {
                                    setUsername(event.target.value);
                                    clearErrorMessage();
                                  }}
                                  onkeyup={(event) =>
                                    event.key === "Enter" && submit()
                                  }
                                  helperText={
                                    errorMessage.USERNAME ||
                                    errorMessage.INVALID_USERNAME
                                  }
                                  heading={t(`LOGIN.INPUT.USERNAME_EMAIL`)}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                xl={12}
                                md={12}
                                className="w-100"
                              >
                                <InputPasswordField
                                  onchange={(event) => {
                                    setPassword(event.target.value);
                                    clearErrorMessage();
                                  }}
                                  onkeyup={(event) =>
                                    event.key === "Enter" && submit()
                                  }
                                  helperText={
                                    errorMessage.PASSWORD ||
                                    errorMessage.INVALID_PASSWORD
                                  }
                                  heading={t(`LOGIN.INPUT.PASSWORD`)}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              className="pb-2 pt-2 justify-content-end"
                              style={{ paddingLeft: 2 }}
                            >
                              <Grid
                                item
                                xl={8}
                                md={7}
                                xs={7}
                                className="text-sm-left text-md-left text-xs-left text-nowrap"
                              >
                                <InputCheckbox
                                  id="rememberMe"
                                  checked={rememberMe}
                                  onChange={handleChangeRememberMe}
                                  style={{
                                    "& $label": {
                                      fontFamily: "Kanit",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                    },
                                    "& $span": {
                                      fontSize: "16px",
                                    },
                                  }}
                                  label={t(`LOGIN.INPUT.REMEMBER_ME`)}
                                />
                              </Grid>
                              <Grid
                                item
                                xl={4}
                                md={5}
                                xs={5}
                                className="text-sm-right text-md-right text-nowrap"
                              >
                                <ButtonForgetPassword
                                  className="ml-auto justify-content-end"
                                  href="#"
                                  onClick={() => onClickForgetPassword()}
                                  style={{ textDecoration: "underline" }}
                                >
                                  {t(`LOGIN.INPUT.FORGET_PASSWORD`)}
                                </ButtonForgetPassword>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              className="captcha"
                              style={{
                                transform: "scale(1.2, 1.0)",
                                transformOrigin: "0 0",
                              }}
                            >
                              <ReCAPTCHA
                                 key={isMobile ? "compact-recaptcha" : "normal-recaptcha"}
                                ref={recaptchaRef}
                                size={isMobile ? "compact" : "normal"}
                                sitekey="6LdiP1UpAAAAADUyq1e1TahP4w0JFZEgDPCQE7dl"
                                onChange={onChangeRecapcha}

                              />
                            </Grid>
                            <Row>
                              <Col
                                xl={8}
                                md
                                sm
                                xs={12}
                                className="text-sm-left text-md-left text-nowrap"
                              ></Col>
                              <Col
                                xl={4}
                                md
                                sm
                                xs={12}
                                className="text-sm-right text-md-right text-nowrap"
                              ></Col>
                            </Row>
                            <Grid item className="pb-3 pt-2">
                              <ButtonCustom
                                type="submit"
                                onClick={submit}
                                textButton={t("LOGIN.BUTTON.SUBMMIT")}
                                disabled={!reCaptcha}
                                btnStyle={{
                                  fontSize: "16px",
                                  marginTop: "15px",
                                  width: "100%",
                                }}
                              />
                            </Grid>
                            <Row>
                              <Col
                                xl={12}
                                md
                                sm
                                xs={12}
                                className="text-sm-center text-md-center text-nowrap"
                              >
                                {t(`LOGIN.INPUT.OR`)}
                              </Col>
                            </Row>
                            <Grid container className="d-flex justify-content-center">
                              <Grid item xs={12} className="mt-2">
                                <GoogleLogin
                                  onSuccess={onSuccessGoogle}
                                  onError={() => console.log("Login Failed")}
                                  text={t("LOGIN.BUTTON.GOOGLE")}
                                  width={isMobile ? "100%" :"383px"}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              className="pb-2 pt-2 justify-content-center"
                            >
                              <Grid
                                item
                                xl={4}
                                md={5}
                                className="text-sm-right text-md-right text-nowrap"
                              >
                                <ButtonForgetPassword
                                  className="ml-auto justify-content-center"
                                  href="#"
                                  onClick={() => onSignUp()}
                                >
                                  {t(`LOGIN.BUTTON.SIGN_UP`)}
                                </ButtonForgetPassword>
                              </Grid>
                            </Grid>
                          </Card.Body>
                        </Card.Body>
                      </Card>
                    )}
                    {forgetPassword && !sendOTP && !changePassword && (
                      <ForgetPassword
                        onClick={onClickReturnLogin}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          clearErrorMessage();
                        }}
                        submit={onSubmitEmail}
                        helperText={errorMessage.INVALID_EMAIL}
                        isSucceeded={isSucceeded}
                      />
                    )}
                    {sendOTP && !forgetPassword && !changePassword && (
                      <SendOTP
                        onClick={onClickReturnLogin}
                        onChange={setOtp}
                        otp={otp}
                        submit={submitOTP}
                        refer={otpRefer}
                        helperText={errorMessage.INVALID_OTP}
                      />
                    )}
                    {!sendOTP && !forgetPassword && changePassword && (
                      <ChangePassword
                        onClick={onClickReturnLogin}
                        onChangePassword={(e: any) => {
                          setPassword(e.target.value);
                        }}
                        onChangeConFirm={(e: any) => {
                          setConfirmPassword(e.target.value);
                        }}
                        password={password}
                        confirm={confirmPassword}
                        submit={submitChanePassword}
                        helperTextPassword={errorMessage.INVALID_OTP}
                        helperTextConfirm={errorMessage.INVALID_CONFIRM}
                      />
                    )}
                  </Grid>
                </Grid>
              </CardFormLogin>
            </GridLogin>
          </Grid>
        </CardLogin>
      )}
      {register && !checkGoogle && (
        <Register
          onsubmit={() => onSubmitRegister()}
          onModal={() => dispatch(showModal())}
          firstname={firstname}
          lastname={lastname}
          phoneNumber={mobilePhone}
          email={email}
          password={password}
          confirmpassword={confirmPassword}
          refRecaptcha={recaptchaRef}
          checkaccept={checkAccept}
          prefixOption={PrefixOption}
          onchangeemail={(e: any) => setEmail(e.target.value.replace(/\s/g, ''))}
          onchangefirstname={(e: any) => setFirstname(e.target.value)}
          onchangelastname={(e: any) => setLastname(e.target.value)}
          onchangeusername={(e: any) => setUsername(e.target.value)}
          onchangephoneNumber={(e: any) => setMobilePhone(e.target.value)}
          onchangeConfirmpassword={(e: any) =>
            setConfirmPassword(e.target.value)
          }
          onchagepassword={(e: any) => setPassword(e.target.value)}
          onchangecheckaccept={() => setCheckAccept(!checkAccept)}
          onchangeRecaptcha={onChangeRecapcha}
          onchangePrefixId={(e: any, val: any) => {
            if (val != null) {
              setPrefixId(val.prefixId)
            } else {
              setPrefixId(0)
            }
          }}
        />
      )}
      {checkGoogle && !register && (
        <CheckGoogle
          onsubmit={() => onsubmitCheckGoogle()}
          onreturn={() => {
            setCheckGoogle(false)
            setFirstname("")
            setLastname("")
            setMobilePhone("")
            setEmail("")
            setReCaptcha("")
            setCheckAccept(false)
          }}
          onModal={() => dispatch(showModal())}
          firstname={firstname}
          lastname={lastname}
          phoneNumber={mobilePhone}
          email={email}
          refRecaptcha={recaptchaRef}
          checkaccept={checkAccept}
          prefixOption={PrefixOption}
          onchangeemail={(e: any) => setEmail(e.target.value)}
          onchangefirstname={(e: any) => setFirstname(e.target.value)}
          onchangelastname={(e: any) => setLastname(e.target.value)}
          onchangephoneNumber={(e: any) => setMobilePhone(e.target.value)}
          onchangecheckaccept={() => setCheckAccept(!checkAccept)}
          onchangeRecaptcha={onChangeRecapcha}
          onchangePrefixId={(e: any, val: any) => setPrefixId(val.prefixId)}
        />
      )}

      {loading && <Loading show={loading} type="fullLoading" />}

      <ModalCustom
        title={""}
        size="lg"
        closeButton={true}
        modalScroll={true}
        component={
          <>
            <Policy />
          </>
        }
      />
    </>
  );
}
