import BaseAPI from '../../api'
import { FindAllInterface } from '../../apiModel'

const path = 'utilities-day'

interface UtilityProps {
    startDate: number,
    endDate: number
}

export default class UtilityDayApi extends BaseAPI {
    static findAll(): Promise<any> {
        return this.api.get(path).then((res) => res)
    }

    static updateById(id: number, body: UtilityProps) {
        return this.api.patch(`${path}/${id}`, body)
    }

}
