import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  BoxImg,
  BoxImgTop,
  CardHomepage,
  TypographyTextres,
  TextHead,
  TextDetail,
  BoxFlex,
  BoxZone,
  Boxborder,
  BoxInfodetailhtml,
  Cardinfodetail,
  BoxInfo,
  Filearray
} from "./home.style";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo/logotest.png";
import { Carousel } from "react-responsive-carousel";
import { useHistory, useParams } from "react-router-dom";
import AnnounceApi from "../../api/home/announce.api";
import { colors } from "../../constants/colors";
import { dateToView } from "../../utils/date.utils";
import { routeName } from "../../routes/routers-name";
import { getProfile } from "../../utils/app.utils";
import { decode } from 'js-base64';
import { Col, Row } from "react-bootstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewImage from "../../component/image/PreviewImage";
import TableCustom from "../../component/table/tableCustom";
import TableRowCommon from "../../component/table/TableRowCommon";
import { NumberInTable } from "../announce/announceStyle";
import Loading from "../../component/loading/loading";

export default function Detailinfo() {
  const [checkType, seetCheckType] = useState<string>("RENT");
  const [detail, setDetail] = useState<any>([]);
  const [select, setSelectfile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const profile: any = JSON.parse(getProfile() || "{}");

  const { id: id } = useParams<any>();

  const getDatadetail = async () => {
    setLoading(true)
    await AnnounceApi.details(+id).then((res) => {
      setDetail(res.data);
      setLoading(false)
      seetCheckType(res?.data?.announceType);
      if (res.data?.fileUpload[0]?.mimetype !== "application/pdf") {
        handlepreview(res.data?.fileUpload[0])
      } else {
        setLoading(false)
      }
    });
  };
  useEffect(() => {
    if (id) {
      getDatadetail();
    }
  }, [id]);

  const handlepreview = (x: any) => {
    if (x?.mimetype === "application/pdf") {
      window.open(process.env.REACT_APP_FILE_URL +
        x?.filePath +
        x?.fileName, "_blank");
    } else {
      setSelectfile(process.env.REACT_APP_FILE_URL +
        x?.filePath +
        x?.fileName);
    }
  };

  return (
    <div className="my-5">
      {checkType === "RENT" ? (
        <CardHomepage>
          <Cardinfodetail style={{ justifyContent: "center" }}>
            <TextHead style={{ fontSize: '28px' }}>{t('แจ้งประกาศแผงค้า!!')}</TextHead>
            {/* <TextHead>{t('DETAIL_INFO.NEW')}</TextHead> */}
          </Cardinfodetail>
          <Container className="my-5">
            {/* <Carousel
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = {
                  marginLeft: 10,
                  marginRight: 10,
                  color: JSON.parse(decode(profile))?.roleId
                    ? JSON.parse(decode(profile))?.roleId == 4
                      ? colors.themeMainColor
                      : colors.blueadminfade
                    : colors.themeMainColor,
                  cursor: "pointer",
                  fontSize: "24px",
                };
                const style = isSelected
                  ? {
                    ...defStyle,
                    color: JSON.parse(decode(profile))?.roleId
                      ? JSON.parse(decode(profile))?.roleId == 4
                        ? colors.themeMainColor
                        : colors.themeSecondColor
                      : colors.themeMainColor,
                  }
                  : { ...defStyle };
                return (
                  <span
                    style={style}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >

                  </span>
                );
              }}
              showStatus={false}
              showArrows={false}
              autoPlay={true}
              transitionTime={1000}
              interval={10000}
              infiniteLoop={true}
            >
              {detail?.round &&
                detail?.round?.map((e: any, index: any) => (
                  <BoxInfo key={index}>
                    <Box sx={{ display: "flex", width: "100%" }}>
                   
                      <BoxZone>
                        <Grid container spacing={2}>
                          <Grid sm={12} xs={12}>
                            <BoxImgTop>
                            </BoxImgTop>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>
                                {t('DETAIL_INFO.DETAIL.OPENING')}
                              </TypographyTextres>
                              <BoxFlex>
                                <TypographyTextres sx={{ color: "#1CB99A" }}>
                                  {t('DETAIL_INFO.DETAIL.DATE')} {dateToView(e?.startDate)}
                                </TypographyTextres>
                                <TypographyTextres sx={{ color: "#1CB99A" }}>
                                  {t('DETAIL_INFO.DETAIL.TO')}
                                </TypographyTextres>
                                <TypographyTextres sx={{ color: "#1CB99A" }}>
                                  {dateToView(e?.endDate)}
                                </TypographyTextres>
                              </BoxFlex>
                            </BoxFlex>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres sx={{ marginRight: "15px" }}>
                                {t('DETAIL_INFO.DETAIL.NUMBER_STALL')}
                              </TypographyTextres>
                              <TypographyTextres>
                                {detail?.round?.length}
                              </TypographyTextres>
                            </BoxFlex>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>{t('DETAIL_INFO.DETAIL.ZONE')}</TypographyTextres>
                              <TypographyTextres>
                                {e?.stall?.zone?.zoneName}
                              </TypographyTextres>
                            </BoxFlex>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>{t('DETAIL_INFO.DETAIL.STALL')}</TypographyTextres>
                              <TypographyTextres>
                                {e?.stall?.stallName}
                              </TypographyTextres>
                            </BoxFlex>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>{t('DETAIL_INFO.DETAIL.PRICE')}</TypographyTextres>
                              <TypographyTextres>{(+e?.price).toLocaleString()}</TypographyTextres>
                            </BoxFlex>
                          </Grid>
                          <Grid item md={9} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: { "sm-down": "end" },
                              }}
                            >
                              <TypographyTextres>
                                {t('DETAIL_INFO.DETAIL.STALL_TYPE')}
                              </TypographyTextres>
                              <TypographyTextres>
                                {e?.stall?.stallType?.stallTypeNameTh}
                              </TypographyTextres>
                            </Box>
                          </Grid>

                          <Grid item md={3} xs={12}>
                            {new Date(e?.endDate) > new Date() &&
                              <Box
                                onClick={() => history.push(routeName.marketMap, { announceId: detail?.announceId, round: detail?.round })}
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  cursor: "pointer",
                                }}
                              >
                                <TypographyTextres
                                  sx={{
                                    color: "#1CB99A",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {t('DETAIL_INFO.DETAIL.RENT')}
                                </TypographyTextres>
                              </Box>}
                          </Grid>
                        </Grid>
                      </BoxZone>
                    </Box>
                  </BoxInfo>
                ))}
            </Carousel> */}
            <Box className='d-flex align-items-center'>
              <Typography variant="h5">
                {`${detail?.title ? detail.title : '-'}`}
              </Typography>
              {JSON.parse(decode(profile))?.roleId == 4 &&
                <Typography variant="h5" className="ml-4" sx={{
                  color: JSON.parse(decode(profile)) ? JSON.parse(decode(profile))?.roleId === 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor,
                  textDecoration: "underline",
                  cursor: "pointer",
                }} onClick={() => history.push(routeName.marketMap, { announceId: detail?.announceId, round: detail?.round })}>
                  {'>>>'} {t('DETAIL_INFO.DETAIL.RENT')} {'<<<'}
                </Typography>}
            </Box>
            <Box className='d-flex my-2'>
              <Typography sx={{ fontWeight: '500' }}>
                {t("HOME.DETAIL.STALL")}
              </Typography>
              <Typography className="ml-4">{
                detail?.round?.map(
                  (value: any) =>
                    value?.stall?.stallName
                )?.join(', ')}</Typography></Box>
            <BoxFlex>
              <TypographyTextres sx={{ fontWeight: '500' }}>
                {t('DETAIL_INFO.DETAIL.OPENING')}
              </TypographyTextres>
              <BoxFlex style={{
                color: JSON.parse(decode(profile)) ? JSON.parse(decode(profile))?.roleId === 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor
              }}>
                <TypographyTextres sx={{ fontWeight: '500' }}>
                  {t('DETAIL_INFO.DETAIL.DATE')}
                </TypographyTextres>
                <TypographyTextres>
                  {dateToView(detail?.startDate)}
                </TypographyTextres>
                <TypographyTextres sx={{ fontWeight: '500' }}>
                  {t('DETAIL_INFO.DETAIL.TO')}
                </TypographyTextres>
                <TypographyTextres>
                  {dateToView(detail?.endDate)}
                </TypographyTextres>
              </BoxFlex>


            </BoxFlex>
            <div className="mt-3">

            </div>

            <BoxInfodetailhtml className="ck-content">
              <div dangerouslySetInnerHTML={{ __html: detail?.detail }} />
            </BoxInfodetailhtml>
            {/* <div className="my-3 w-100" style={{ minHeight: '70%' }}>
              {select && <div>
                <img src={select} alt="img" style={{ width: "100%", maxHeight: '450px', height: "100%", objectFit: 'contain' }} />
              </div>}
            </div> */}
            {detail?.fileUpload &&
              <div>
                <div className="my-3 w-100" >
                  {detail?.fileUpload?.map((value: any, index: number) => {
                    const fileURL = process.env.REACT_APP_FILE_URL + value?.filePath + value?.fileName
                    return (
                      <>
                        {value.mimetype !== "application/pdf"
                          && <img className="mt-2" src={fileURL} alt="img" style={{ width: "100%", maxHeight: '450px', height: "100%", objectFit: 'contain' }} />
                        }
                      </>

                      // <Row>
                      //   {/* <Col item lg={1} xs={12}></Col> */}
                      //   <Col item lg={2} xs={12} className="my-2">
                      //     <span className="d-flex justify-content-center align-items-center my-3">
                      //       {value?.mimetype === "application/pdf" ? ` เอกสารที่ : ${index + 1}` : ` ภาพที่ : ${index + 1}`}
                      //     </span>
                      //   </Col>
                      //   <Col item lg={10} xs={12} className="my-2">
                      //     <Filearray>
                      //       <Box
                      //         sx={{
                      //           display: "flex",
                      //           justifyContent: "space-between",
                      //         }}
                      //       >
                      //         <Box>{value.fileName}</Box>
                      //         <Box>
                      //           <VisibilityIcon
                      //             onClick={() => handlepreview(value)}
                      //             sx={{ cursor: "pointer", marginRight: "10px" }}
                      //           />
                      //         </Box>
                      //       </Box>
                      //     </Filearray>
                      //   </Col>
                      // </Row>
                    )
                  })}
                </div>

              </div>
            }
            {
              detail?.fileUpload &&
              <>
                {detail?.fileUpload?.filter((item: any) => item?.mimetype === "application/pdf")?.length > 0 && <Typography variant="h5">เอกสารเพิ่มเติม</Typography>}
                <ul className="my-3 ml-2 w-100" >
                  {detail?.fileUpload?.map((value: any, index: number) => {
                    const fileURL = process.env.REACT_APP_FILE_URL + value?.filePath + value?.fileName
                    return (
                      <>
                        {value.mimetype === "application/pdf"
                          &&
                          <li className="my-2" onClick={() => handlepreview(value)}
                            style={{ cursor: "pointer", marginRight: "10px" }}>
                            <a className="d-flex" style={{ fontSize: '16px' }}>
                              {`เอกสารที่ : ${index + 1} ${value.fileName}`}
                            </a>

                          </li>
                        }
                      </>

                      // <Row>
                      //   {/* <Col item lg={1} xs={12}></Col> */}
                      //   <Col item lg={2} xs={12} className="my-2">
                      //     <span className="d-flex justify-content-center align-items-center my-3">
                      //       {value?.mimetype === "application/pdf" ? ` เอกสารที่ : ${index + 1}` : ` ภาพที่ : ${index + 1}`}
                      //     </span>
                      //   </Col>
                      //   <Col item lg={10} xs={12} className="my-2">
                      //     <Filearray>
                      //       <Box
                      //         sx={{
                      //           display: "flex",
                      //           justifyContent: "space-between",
                      //         }}
                      //       >
                      //         <Box>{value.fileName}</Box>
                      //         <Box>
                      //           <VisibilityIcon
                      //             onClick={() => handlepreview(value)}
                      //             sx={{ cursor: "pointer", marginRight: "10px" }}
                      //           />
                      //         </Box>
                      //       </Box>
                      //     </Filearray>
                      //   </Col>
                      // </Row>
                    )
                  })}
                </ul>
              </>
            }
          </Container>
        </CardHomepage>
      ) : (
        <CardHomepage>
          <BoxFlex>
            <Cardinfodetail style={{ justifyContent: "center" }}>
              <TextHead style={{ fontSize: '28px' }}>{t('แจ้งประกาศจากระบบ')}</TextHead>
              {/* <TextHead>{t('DETAIL_INFO.NEW')}</TextHead> */}
            </Cardinfodetail>
          </BoxFlex>
          {/* <Container>
            <Boxborder>
              <Container maxWidth="md">
                <Carousel
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const defStyle = {
                      marginLeft: 10,
                      marginRight: 10,
                      color: JSON.parse(decode(profile))?.roleId
                        ? JSON.parse(decode(profile))?.roleId === 4
                          ? colors.themeMainColor
                          : colors.blueadminfade
                        : colors.themeMainColor,
                      cursor: "pointer",
                      fontSize: "24px",
                    };
                    const style = isSelected
                      ? {
                        ...defStyle,
                        color: JSON.parse(decode(profile))?.roleId
                          ? JSON.parse(decode(profile))?.roleId === 4
                            ? colors.themeMainColor
                            : colors.themeSecondColor
                          : colors.themeMainColor,
                      }
                      : { ...defStyle };
                    return (
                      <span
                        style={style}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        key={index}
                        role="button"
                        tabIndex={0}
                        aria-label={`${label} ${index + 1}`}
                      >

                      </span>
                    );
                  }}
                  showStatus={false}
                  showArrows={false}
                  autoPlay={true}
                  transitionTime={1000}
                  interval={10000}
                  infiniteLoop={true}
                >

                </Carousel>

                <Box>
                  <Typography variant="h5">
                    {`หัวข้อ : ${detail?.title ? detail.title : '-'}`}
                  </Typography>
                </Box>
                <BoxInfodetailhtml>
                  <TextDetail className="ck-content">
                    <div dangerouslySetInnerHTML={{ __html: detail?.detail }} />
                  </TextDetail>
                </BoxInfodetailhtml>
                <div className="my-3 w-100">
                  {select && <div className="d-flex justify-content-center">
                    <img src={select} style={{ height: '300px' }} alt="announe" />
                  </div>}
                </div>

                {detail?.fileUpload && <div>
                  <div className="mt-2">
                    {detail?.fileUpload?.map((value: any, index: number) => {
                      return (
                        <Row>
                          <Col item lg={2} xs={12} className="my-2">
                            {value?.mimetype === "application/pdf" ? ` เอกสารที่ : ${index + 1}` : ` ภาพที่ : ${index + 1}`}
                          </Col>
                          <Col item lg={10} xs={12} className="my-2">
                            <Filearray>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>{value.fileName}</Box>
                                <Box>
                                  <VisibilityIcon
                                    onClick={() => handlepreview(value)}
                                    sx={{ cursor: "pointer", marginRight: "10px" }}
                                  />
                                </Box>
                              </Box>
                            </Filearray>
                          </Col>
                        </Row>
                      )
                    })}
                  </div>

                </div>
                }
                <Box sx={{ marginTop: "50px" }}>
                  {detail?.startDate && (
                    <TextDetail>
                      {t('DETAIL_INFO.DETAIL.RANGE_DATE')}{" "}
                      {`${dateToView(detail?.startDate)}`}{" "}
                      {t('DETAIL_INFO.DETAIL.TO')}{" "}
                      {`${dateToView(detail?.endDate)}`}
                    </TextDetail>
                  )}
                </Box>
              </Container>
            </Boxborder>
          </Container> */}
          <Container className="my-5">
            {/* <Carousel
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const defStyle = {
                  marginLeft: 10,
                  marginRight: 10,
                  color: JSON.parse(decode(profile))?.roleId
                    ? JSON.parse(decode(profile))?.roleId == 4
                      ? colors.themeMainColor
                      : colors.blueadminfade
                    : colors.themeMainColor,
                  cursor: "pointer",
                  fontSize: "24px",
                };
                const style = isSelected
                  ? {
                    ...defStyle,
                    color: JSON.parse(decode(profile))?.roleId
                      ? JSON.parse(decode(profile))?.roleId == 4
                        ? colors.themeMainColor
                        : colors.themeSecondColor
                      : colors.themeMainColor,
                  }
                  : { ...defStyle };
                return (
                  <span
                    style={style}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  >

                  </span>
                );
              }}
              showStatus={false}
              showArrows={false}
              autoPlay={true}
              transitionTime={1000}
              interval={10000}
              infiniteLoop={true}
            >
              {detail?.round &&
                detail?.round?.map((e: any, index: any) => (
                  <BoxInfo key={index}>
                    <Box sx={{ display: "flex", width: "100%" }}>
                   
                      <BoxZone>
                        <Grid container spacing={2}>
                          <Grid sm={12} xs={12}>
                            <BoxImgTop>
                            </BoxImgTop>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>
                                {t('DETAIL_INFO.DETAIL.OPENING')}
                              </TypographyTextres>
                              <BoxFlex>
                                <TypographyTextres sx={{ color: "#1CB99A" }}>
                                  {t('DETAIL_INFO.DETAIL.DATE')} {dateToView(e?.startDate)}
                                </TypographyTextres>
                                <TypographyTextres sx={{ color: "#1CB99A" }}>
                                  {t('DETAIL_INFO.DETAIL.TO')}
                                </TypographyTextres>
                                <TypographyTextres sx={{ color: "#1CB99A" }}>
                                  {dateToView(e?.endDate)}
                                </TypographyTextres>
                              </BoxFlex>
                            </BoxFlex>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres sx={{ marginRight: "15px" }}>
                                {t('DETAIL_INFO.DETAIL.NUMBER_STALL')}
                              </TypographyTextres>
                              <TypographyTextres>
                                {detail?.round?.length}
                              </TypographyTextres>
                            </BoxFlex>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>{t('DETAIL_INFO.DETAIL.ZONE')}</TypographyTextres>
                              <TypographyTextres>
                                {e?.stall?.zone?.zoneName}
                              </TypographyTextres>
                            </BoxFlex>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>{t('DETAIL_INFO.DETAIL.STALL')}</TypographyTextres>
                              <TypographyTextres>
                                {e?.stall?.stallName}
                              </TypographyTextres>
                            </BoxFlex>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <BoxFlex>
                              <TypographyTextres>{t('DETAIL_INFO.DETAIL.PRICE')}</TypographyTextres>
                              <TypographyTextres>{(+e?.price).toLocaleString()}</TypographyTextres>
                            </BoxFlex>
                          </Grid>
                          <Grid item md={9} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: { "sm-down": "end" },
                              }}
                            >
                              <TypographyTextres>
                                {t('DETAIL_INFO.DETAIL.STALL_TYPE')}
                              </TypographyTextres>
                              <TypographyTextres>
                                {e?.stall?.stallType?.stallTypeNameTh}
                              </TypographyTextres>
                            </Box>
                          </Grid>

                          <Grid item md={3} xs={12}>
                            {new Date(e?.endDate) > new Date() &&
                              <Box
                                onClick={() => history.push(routeName.marketMap, { announceId: detail?.announceId, round: detail?.round })}
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  cursor: "pointer",
                                }}
                              >
                                <TypographyTextres
                                  sx={{
                                    color: "#1CB99A",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {t('DETAIL_INFO.DETAIL.RENT')}
                                </TypographyTextres>
                              </Box>}
                          </Grid>
                        </Grid>
                      </BoxZone>
                    </Box>
                  </BoxInfo>
                ))}
            </Carousel> */}
            <Box className='d-flex align-items-center mb-2'>
              <Typography variant="h5">
                {`${detail?.title ? detail.title : '-'}`}
              </Typography>
              {/* {JSON.parse(decode(profile))?.roleId == 4 &&
                <Typography variant="h5" className="ml-4" sx={{
                  color: JSON.parse(decode(profile)) ? JSON.parse(decode(profile))?.roleId === 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor,
                  textDecoration: "underline",
                  cursor: "pointer",
                }} onClick={() => history.push(routeName.marketMap, { announceId: detail?.announceId, round: detail?.round })}>
                  {'>>>'} {t('DETAIL_INFO.DETAIL.RENT')} {'<<<'}
                </Typography>} */}
            </Box>
            {/* <Box className='d-flex my-2'>
              <Typography sx={{ fontWeight: '500' }}>
                {t("HOME.DETAIL.STALL")}
              </Typography>
              <Typography className="ml-4">{
                detail?.round?.map(
                  (value: any) =>
                    value?.stall?.stallName
                )?.join(', ')}</Typography></Box> */}
            <BoxFlex>
              <TypographyTextres sx={{ fontWeight: '500' }}>
                {t('ระยะเวลาประกาศ')}
              </TypographyTextres>
              <BoxFlex style={{
                color: JSON.parse(decode(profile)) ? JSON.parse(decode(profile))?.roleId === 4 ? colors.themeMainColor : colors.themeSecondColor : colors.themeMainColor
              }}>
                <TypographyTextres sx={{ fontWeight: '500' }}>
                  {t('DETAIL_INFO.DETAIL.DATE')}
                </TypographyTextres>
                <TypographyTextres>
                  {dateToView(detail?.startDate)}
                </TypographyTextres>
                <TypographyTextres sx={{ fontWeight: '500' }}>
                  {t('DETAIL_INFO.DETAIL.TO')}
                </TypographyTextres>
                <TypographyTextres>
                  {dateToView(detail?.endDate)}
                </TypographyTextres>
              </BoxFlex>


            </BoxFlex>
            <div className="mt-3">

            </div>

            <BoxInfodetailhtml className="ck-content">
              <div dangerouslySetInnerHTML={{ __html: detail?.detail }} />
            </BoxInfodetailhtml>
            {/* <div className="my-3 w-100" style={{ minHeight: '70%' }}>
              {select && <div>
                <img src={select} alt="img" style={{ width: "100%", maxHeight: '450px', height: "100%", objectFit: 'contain' }} />
              </div>}
            </div> */}
            {detail?.fileUpload &&
              <div>
                <div className="my-3 w-100" >
                  {detail?.fileUpload?.map((value: any, index: number) => {
                    const fileURL = process.env.REACT_APP_FILE_URL + value?.filePath + value?.fileName
                    return (
                      <>
                        {value.mimetype !== "application/pdf"
                          && <img className="mt-2" src={fileURL} alt="img" style={{ width: "100%", maxHeight: '450px', height: "100%", objectFit: 'contain' }} />
                        }
                      </>

                      // <Row>
                      //   {/* <Col item lg={1} xs={12}></Col> */}
                      //   <Col item lg={2} xs={12} className="my-2">
                      //     <span className="d-flex justify-content-center align-items-center my-3">
                      //       {value?.mimetype === "application/pdf" ? ` เอกสารที่ : ${index + 1}` : ` ภาพที่ : ${index + 1}`}
                      //     </span>
                      //   </Col>
                      //   <Col item lg={10} xs={12} className="my-2">
                      //     <Filearray>
                      //       <Box
                      //         sx={{
                      //           display: "flex",
                      //           justifyContent: "space-between",
                      //         }}
                      //       >
                      //         <Box>{value.fileName}</Box>
                      //         <Box>
                      //           <VisibilityIcon
                      //             onClick={() => handlepreview(value)}
                      //             sx={{ cursor: "pointer", marginRight: "10px" }}
                      //           />
                      //         </Box>
                      //       </Box>
                      //     </Filearray>
                      //   </Col>
                      // </Row>
                    )
                  })}
                </div>

              </div>
            }
            {
              detail?.fileUpload &&
              <>
                {detail?.fileUpload?.filter((item: any) => item?.mimetype === "application/pdf")?.length > 0 && <Typography variant="h5">เอกสารเพิ่มเติม</Typography>}
                <ul className="my-3 ml-2 w-100" >
                  {detail?.fileUpload?.map((value: any, index: number) => {
                    const fileURL = process.env.REACT_APP_FILE_URL + value?.filePath + value?.fileName
                    return (
                      <>
                        {value.mimetype === "application/pdf"
                          &&
                          <li className="my-2" onClick={() => handlepreview(value)}
                            style={{ cursor: "pointer", marginRight: "10px" }}>
                            <a className="d-flex" style={{ fontSize: '16px' }}>
                              {`เอกสารที่ : ${index + 1} ${value.fileName}`}
                            </a>

                          </li>
                        }
                      </>

                      // <Row>
                      //   {/* <Col item lg={1} xs={12}></Col> */}
                      //   <Col item lg={2} xs={12} className="my-2">
                      //     <span className="d-flex justify-content-center align-items-center my-3">
                      //       {value?.mimetype === "application/pdf" ? ` เอกสารที่ : ${index + 1}` : ` ภาพที่ : ${index + 1}`}
                      //     </span>
                      //   </Col>
                      //   <Col item lg={10} xs={12} className="my-2">
                      //     <Filearray>
                      //       <Box
                      //         sx={{
                      //           display: "flex",
                      //           justifyContent: "space-between",
                      //         }}
                      //       >
                      //         <Box>{value.fileName}</Box>
                      //         <Box>
                      //           <VisibilityIcon
                      //             onClick={() => handlepreview(value)}
                      //             sx={{ cursor: "pointer", marginRight: "10px" }}
                      //           />
                      //         </Box>
                      //       </Box>
                      //     </Filearray>
                      //   </Col>
                      // </Row>
                    )
                  })}
                </ul>
              </>
            }
          </Container>
        </CardHomepage>
      )}
      {loading && <Loading show={loading} type="fullLoading" />}
    </div>
  );
}
