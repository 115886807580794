import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    selectProfile: {},
    selectBranch: null
  },
  reducers: {
    editProfile: (state: any, action: any) => {
      state.selectProfile = action.payload
    },
    editBranch: (state: any, action: any) => {
      state.selectBranch = action.payload
    }
  }
})

export const selectProfile = (state: any) => state?.users?.selectProfile
export const selectBranch = (state: any) => state?.users?.selectBranch



export const { editProfile, editBranch } = userSlice.actions
export default userSlice.reducer
